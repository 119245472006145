import { bindActionCreators } from 'redux';

import connect from '../../../../../../../logic/connect';
import SelectSource from './SelectSource';
import { 
  setSelectedTeams, 
  setTeamOptions, 
  setSelectedUsers, 
  setUserOptions,
} from '../Model';


const mapStateToProps = ({ pages }) => {
  const page = pages.DASHBOARD_LAYOUT;
  return {
    ...page,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    setSelectedTeams,
    setTeamOptions,
    setSelectedUsers,
    setUserOptions,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectSource);
