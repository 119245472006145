import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../logic/services/api-factory';

const clientProfileLayoutApi = () => getDjangoApi('settings/layout/profile');
const assignToUsersApi = layout => getDjangoApi(`settings/layout/profile/${layout.id}/assign_to_users`);
const assignToTeamsApi = layout => getDjangoApi(`settings/layout/profile/${layout.id}/assign_to_teams`);

const initialState = {
  layout: [],
  profileLayout: null,
  profileLayoutInProgress: false,
  defaultLayout: [],
  defaultLayoutInProgress: false,
  defaultLayoutErrors: null,
  selectedUsers: [],
  selectedTeams: [],
  submitAssignInProgress: false,
  updateInProgress: false,
};

const actionTypes = {
  SET_PROFILE_LAYOUT: 'SET_PROFILE_LAYOUT',
  FETCH_DEFAULT_LAYOUT: 'FETCH_DEFAULT_LAYOUT',
  FETCH_DEFAULT_LAYOUT_PENDING: 'FETCH_DEFAULT_LAYOUT_PENDING',
  FETCH_DEFAULT_LAYOUT_REJECTED: 'FETCH_DEFAULT_LAYOUT_REJECTED',
  FETCH_DEFAULT_LAYOUT_FULFILLED: 'FETCH_DEFAULT_LAYOUT_FULFILLED',
  FETCH_PROFILE_LAYOUT: 'FETCH_PROFILE_LAYOUT',
  FETCH_PROFILE_LAYOUT_PENDING: 'FETCH_PROFILE_LAYOUT_PENDING',
  FETCH_PROFILE_LAYOUT_REJECTED: 'FETCH_PROFILE_LAYOUT_REJECTED',
  FETCH_PROFILE_LAYOUT_FULFILLED: 'FETCH_PROFILE_LAYOUT_FULFILLED',
  ASSIGN_LAYOUT_TO_USERS: 'ASSIGN_LAYOUT_TO_USERS',
  ASSIGN_LAYOUT_TO_TEAMS: 'ASSIGN_LAYOUT_TO_TEAMS',
  SUBMIT_LAYOUT_ASSIGNMENT: 'SUBMIT_LAYOUT_ASSIGNMENT',
  SUBMIT_LAYOUT_ASSIGNMENT_PENDING: 'SUBMIT_LAYOUT_ASSIGNMENT_PENDING',
  SUBMIT_LAYOUT_ASSIGNMENT_FULFILLED: 'SUBMIT_LAYOUT_ASSIGNMENT_FULFILLED',
  SUBMIT_LAYOUT_ASSIGNMENT_REJECTED: 'SUBMIT_LAYOUT_ASSIGNMENT_REJECTED',
  SUBMIT_LAYOUT_UPDATE: 'SUBMIT_LAYOUT_UPDATE',
  SUBMIT_LAYOUT_UPDATE_PENDING: 'SUBMIT_LAYOUT_UPDATE_PENDING',
  SUBMIT_LAYOUT_UPDATE_FULFILLED: 'SUBMIT_LAYOUT_UPDATE_FULFILLED',
  SUBMIT_LAYOUT_UPDATE_REJECTED: 'SUBMIT_LAYOUT_UPDATE_REJECTED',
  SUBMIT_LAYOUT_DELETE: 'SUBMIT_LAYOUT_DELETE', 
  SUBMIT_LAYOUT_DELETE_PENGING: 'SUBMIT_LAYOUT_DELETE', 
  SUBMIT_LAYOUT_DELETE_FULFILLED: 'SUBMIT_LAYOUT_DELETE_FULFILLED', 
  SUBMIT_LAYOUT_DELETE_REJECTED: 'SUBMIT_LAYOUT_DELETE_REJECTED', 
  RESET_PROFILE_LAYOUT: 'RESET_PROFILE_LAYOUT',
};

export const setLayout = createAction(actionTypes.SET_PROFILE_LAYOUT, layout => layout);
export const fetchDefaultLayout = createAction(actionTypes.FETCH_DEFAULT_LAYOUT, async () => {
  const api = clientProfileLayoutApi().all('default');
  const { data } = await api.retrieve();
  return {
    defaultLayout: data,
  };
});
export const fetchProfileLayout = createAction(actionTypes.FETCH_PROFILE_LAYOUT, async (id) => {
  const api = clientProfileLayoutApi();
  const { data } = await api.retrieve(id);
  return {
    profileLayout: data,
  };
});
export const assignToUsers = createAction(actionTypes.ASSIGN_LAYOUT_TO_USERS, users => users);
export const assignToTeams = createAction(actionTypes.ASSIGN_LAYOUT_TO_TEAMS, teams => teams);
export const submitAssign = createAction(actionTypes.SUBMIT_LAYOUT_ASSIGNMENT, async (layout, users, teams) => {
  const getRequest = (key, arr) => ({
    [key]: arr.map(item => item.id),
  });

  await Promise.all([
    ...(users && [assignToUsersApi(layout).create(getRequest('available_to_users', users))]),
    ...(teams && [assignToTeamsApi(layout).create(getRequest('available_to_teams', teams))]),
  ]);
});
export const resetLayout = createAction(actionTypes.RESET_PROFILE_LAYOUT);
export const updateLayout = createAction(actionTypes.SUBMIT_LAYOUT_UPDATE, async (layout) => {
  const api = clientProfileLayoutApi();
  await api.updatePart(layout.id, layout);
});
export const deleteLayout = createAction(actionTypes.SUBMIT_LAYOUT_DELETE, async (layout) => {
  const api = clientProfileLayoutApi();
  await api.destroy(layout.id);
});

const fetchDefaultLayoutFulfiledHandler = (state, { payload: { defaultLayout } }) => ({
  ...state,
  defaultLayout,
  defaultLayoutInProgress: false,
});

const fetchDefaultLayoutRejectedHandler = (state, { payload }) => ({
  ...state,
  defaultLayoutInProgress: false,
  defaultLayoutErrors: payload.data,
});

export default handleActions(
  {
    [actionTypes.SET_PROFILE_LAYOUT]: (state, { payload }) => ({ ...state, layout: payload }),
    [actionTypes.FETCH_DEFAULT_LAYOUT_PENDING]: state => ({ ...state, defaultLayoutInProgress: true }),
    [actionTypes.FETCH_DEFAULT_LAYOUT_FULFILLED]: fetchDefaultLayoutFulfiledHandler,
    [actionTypes.FETCH_DEFAULT_LAYOUT_REJECTED]: fetchDefaultLayoutRejectedHandler,
    [actionTypes.FETCH_PROFILE_LAYOUT_PENDING]: state => ({ ...state, profileLayoutInProgress: true }),
    [actionTypes.FETCH_PROFILE_LAYOUT_FULFILLED]: (state, { payload: { profileLayout } }) => ({ ...state, profileLayout, profileLayoutInProgress: false }),
    [actionTypes.FETCH_PROFILE_LAYOUT_REJECTED]: state => ({ ...state, profileLayoutInProgress: false }),
    [actionTypes.ASSIGN_LAYOUT_TO_USERS]: (state, { payload }) => ({ ...state, selectedUsers: payload }),
    [actionTypes.ASSIGN_LAYOUT_TO_TEAMS]: (state, { payload }) => ({ ...state, selectedTeams: payload }),
    [actionTypes.SUBMIT_LAYOUT_ASSIGNMENT_PENDING]: state => ({ ...state, submitAssignInProgress: true }),
    [actionTypes.SUBMIT_LAYOUT_ASSIGNMENT_FULFILLED]: state => ({ ...state, submitAssignInProgress: false }),
    [actionTypes.SUBMIT_LAYOUT_ASSIGNMENT_REJECTED]: state => ({ ...state, submitAssignInProgress: false }),
    [actionTypes.SUBMIT_LAYOUT_UPDATE_PENDING]: state => ({ ...state, updateInProgress: true }),
    [actionTypes.SUBMIT_LAYOUT_UPDATE_FULFILLED]: state => ({ ...state, updateInProgress: false }),
    [actionTypes.SUBMIT_LAYOUT_UPDATE_REJECTED]: state => ({ ...state, updateInProgress: false }),
    [actionTypes.RESET_PROFILE_LAYOUT]: () => ({ ...initialState }),
  },
  initialState,
);
