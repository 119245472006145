import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { object, bool, array } from 'prop-types';
import { Sidepanel, notifier } from 'tc-biq-design-system';

import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import { fetchBackends, submitApproval, submitChange } from './Model';
import SelectBackend from './SelectBackend';
import { formErrorHandler } from '../../../../../../../components/form/logic/utils';
import { FORM_ID } from './SelectBackend/SelectBackend';

const text = {
  APPROVE: {
    SUCCESS: gettext('IB user successfully approved.'),
    ERROR: gettext('Error approving user.'),
  },
  CHANGE: {
    SUCCESS: gettext('IB backends successfully modified.'),
    ERROR: gettext('Error modifing IB backends.'),
  },
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_ID}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  approvalInProgress: bool.isRequired,
  backends: array,
  editBackends: bool,
  isIb: bool,
};

const defaultProps = {
  user: null,
  actions: null,
  backends: [],
  editBackends: false,
  isIb: false,
};

class IbChangeBackends extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backends: [],
    };
    this.actions = props.actions;
    this.onClose = this.onClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onBackendChange = this.onBackendChange.bind(this);
  }

  componentDidMount() {
    const { user, editBackends } = this.props;
    this.actions.fetchBackends(user, editBackends);
  }

  onClose() {
    const { sidepanelManager } = this.props;
    this.setState({ backends: [] });
    sidepanelManager.close();
  }

  onBackendChange(backends) {
    this.setState({ backends });
  }

  onSubmit() {
    const { editBackends, user, isIb, sidepanelManager } = this.props;

    const selectedBackends = this.getBackends();

    const actionHandler = (action, actionKey) => {
      action.then(() => {
        this.actions.fetchUser(user, isIb);
        notifier.success(text[actionKey].SUCCESS);
        sidepanelManager.close();
        this.actions.fetchBackends(user, editBackends);
      }).catch((e) => {
        formErrorHandler(text[actionKey].ERROR)(e);
      });
    };

    if (!editBackends) {
      actionHandler(this.actions.submitApproval(user, selectedBackends), 'APPROVE');
    } else {
      actionHandler(this.actions.submitChange(user, selectedBackends), 'CHANGE');
    }
  }

  getBackends = () => {
    const { backends: defaultBackends } = this.props;
    const { backends: updatedBackends } = this.state;
    return updatedBackends.length > 0 ? updatedBackends : defaultBackends;
  }

  render() {
    const { approvalInProgress, ...options } = this.props;
    const backends = this.getBackends();
    return (
      <Sidepanel {...options} footerRender={customFooter(this.onSubmit, this.onClose, approvalInProgress)}>
        {backends.length > 0 && <SelectBackend backends={backends} onBackendChange={this.onBackendChange} />}
      </Sidepanel>
    );
  }
}

IbChangeBackends.propTypes = propTypes;
IbChangeBackends.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      fetchBackends,
      submitApproval,
      submitChange,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const action = page.actions.TRADING_BACKENDS;
  return {
    approvalInProgress: action.approvalInProgress,
    backends: action.backends,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IbChangeBackends);
