import { gettext } from '../../../../logic/utilities/languageUtility';

export default {
  PAGE_TITLE: gettext('Withdrawals'),
  FLOW: gettext('Flow'),
  METHODS: gettext('Methods'),
  REASONS: gettext('Reject reasons'),
  CREATE_NEW: gettext('Create new'),
  DELETE_SUCCESS: gettext('Row deleted successfully'),
  DELETE_ERROR: gettext('Error while deleting row'),
  STATE_UPDATE_SUCCESS: gettext('State updated successfully'),
  STATE_UPDATE_ERROR: gettext('Error while updating state'),
  MODIFY: gettext('Modify'),
  DELETE: gettext('Delete'),
  CLONE: gettext('Clone'),
  POPCONFIRM_LABEL: gettext('Are you sure you want to delete this row?'),
  NO: gettext('No'),
  YES: gettext('Yes'),
  STATE: gettext('State'),
  ACTIONS: gettext('Actions'),
};
