import React from 'react';
import { Toggle, Panel } from 'tc-biq-design-system';
import { string, bool, node, func } from 'prop-types';
import classNames from 'classnames';

import './TogglePanel.scss';

const status = (onToggle, checked) => () => <Toggle key="toggle" onChange={onToggle} checked={checked} />;

const propTypes = {
  title: string,
  onToggle: func.isRequired,
  checked: bool,
  children: node,
};

const defaultProps = {
  title: '',
  checked: false,
  children: null,
};

const TogglePanel = ({ title, onToggle, checked, children }) => (
  <div className={classNames('biq-toggle-panel', { 'biq-toggle-panel--open': children && checked })}>
    <Panel title={title} status={status(onToggle, checked)}>
      <div className="biq-toggle-panel__wrapper">
        {children}
      </div>
    </Panel>
  </div>
);

TogglePanel.propTypes = propTypes;
TogglePanel.defaultProps = defaultProps;

export default TogglePanel;
