import React, { Component } from 'react';
import { Select } from 'tc-biq-design-system';
import { func, array, oneOf, bool } from 'prop-types';

import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const text = {
  AVAILABLE_TO_USERS: gettext('Available to users'),
  AVAILABLE_TO_TEAMS: gettext('Available to teams'),
  USERS: gettext('Users'),
  TEAMS: gettext('Teams'),
};

const type = {
  USERS: 'users',
  TEAMS: 'teams',
};

const config = {
  [type.USERS]: {
    LABEL: text.AVAILABLE_TO_USERS,
    API: {
      SERVICE: getDjangoApi('team'),
      PARAMS: input => ({
        username: input,
        type: 'live',
        count_layouts: true,
        limit: 10,
        offset: 0,
      }),
    },
    VALUE_KEY: 'id',
    LABEL_KEY: 'username',
  },
  [type.TEAMS]: {
    LABEL: text.AVAILABLE_TO_TEAMS,
    API: {
      SERVICE: getDjangoApi('teams'),
      PARAMS: input => ({
        name: input,
        count_layouts: true,
        limit: 5,
        offset: 0,
      }),
    },
    VALUE_KEY: 'id',
    LABEL_KEY: 'name',
  },
};

const propTypes = {
  type: oneOf([type.USERS, type.TEAMS]).isRequired,
  onChange: func,
  value: array,
  defaultValue: array,
  disabled: bool,
};

const defaultProps = {
  onChange: null,
  value: [],
  defaultValue: [],
  disabled: false,
};


class AvailableTo extends Component {
  constructor(props) {
    super(props);

    this.config = config[props.type];
    this.state = {
      options: [],
    };

    this.loadOptions = _.debounce(this.loadOptions.bind(this), 250, { leading: true });
  }

  componentDidMount() {
    this.loadOptions();
  }

  onChange = (value) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  }

  formatResult = results => results.map((result) => {
    const { defaultValue } = this.props;
    const isDefault = defaultValue.some(({ id }) => id === result.id);
    return {
      ...result,
      disabled: !isDefault && result.has_profile_layout,
    };
  });

  loadOptions = (input = '') => {
    const { SERVICE, PARAMS } = this.config.API;

    return SERVICE.list(PARAMS(input))
      .then(({ data: { results } }) => this.formatResult(results))
      .then(options => this.setState({
        options,
      }));
  }

  
  render() {
    const { VALUE_KEY, LABEL_KEY, LABEL } = this.config;
    const { options } = this.state;
    const { value, disabled } = this.props;
    
    return (
      <Select
        type="multi"
        label={LABEL}
        options={options}
        onInputChange={this.loadOptions}
        onChange={this.onChange}
        value={value}
        valueKey={VALUE_KEY}
        labelKey={LABEL_KEY}
        disabled={disabled}
      />
    );
  }
}

AvailableTo.propTypes = propTypes;
AvailableTo.defaultProps = defaultProps;

export default AvailableTo;
