/* eslint react/prop-types: 0 */
/* eslint react/sort-comp:0 */
import React, { Fragment, PureComponent } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Tabs, Tab, Toggle } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';

import { getDjangoApi } from '../../../logic/services/api-factory';
import connect from '../../../logic/connect';
import Page from '../../../components/Page';
import { gettext } from '../../../logic/utilities/languageUtility';
import GridFactory from '../../../components/grid';
import overlayActions from '../../../components/overlay';
import archiveActions from './sidepanels/ClientsArchive/actions';
import withUnmountMethod from '../../../components/hoc/withUnmountMethod';
import { getActiveFilters } from '../../../components/grid/GridUtils';
import { hasAccess } from '../../../logic/services/acl';
import isFeatureEnabled from '../../../logic/filters/is-feature-enabled';
import { startPoll, stopPoll } from '../../../logic/services/short-poll';
import Filters from '../../../components/Filters';
import If from '../../../components/If';
import Export from '../../../components/Export';
import { UserPreview, BiqUserPreview } from '../../../components/common/sidepanels';
import ClientsEditTags from './sidepanels/ClientsEditTags';
import ClientsAssignSalesManager from './sidepanels/ClientsAssignSalesManager';
import ClientsAssignRetentionManager from './sidepanels/ClientsAssignRetentionManager';
import ClientsEditSalesStatuses from './sidepanels/ClientsEditSalesStatuses';
import ClientsArchive from './sidepanels/ClientsArchive';
import ClientsAssignCage from './sidepanels/ClientsAssignCage';
import modifiers from './modifiers';
import AddLeads from './AddLeads';
import AddLeadSingle from './sidepanels/AddLeadSingle';
import AddLeadBulk from './sidepanels/AddLeadsBulk';
import appRoutes from '../../../components/App/Router/appRoutes';
import withErrorBoundary from '../../../components/hoc/withErrorBoundary';
import ClientsEditComplianceStatuses from './sidepanels/ClientsEditComplianceStatuses';
import Loader from '../../../components/Loader/Loader';
import { parseFilterKey } from '../../../components/Filters/filterUtils';
import userStates from '../../../logic/enums/user-state';
import { getAssignRetentionManagerApi, getAssignSalesManagerApi } from './utils';
import MassAssignmentPanel from './MassAssignmentPanel';
import { getStore } from '../../../logic/store';
import MassAssignment, { MASS_ASSIGN_TYPES } from '../../../components/common/sidepanels/bulk/MassAssignment/MassAssignment';


const pageConfig = {
  apiUrl: 'users',
  reducerKey: 'CLIENTS',
  tableKey: 'CLIENTS_TABLE',
};

const { GridComponent, actions } = GridFactory(pageConfig);

const text = {
  PAGE_TITLE: state => ({
    Live: gettext('Clients:live'),
    Demo: gettext('Clients:demo'),
  })[state] || gettext('Clients'),
  EDIT_TAGS_LABEL: gettext('Edit tags'),
  EDIT_SALES_STATUTS_LABEL: gettext('Edit sales status'),
  EDIT_COMPLIANCE_STATUTS_LABEL: gettext('Edit compliance status'),
  ASSIGN_SALES_MANAGERS_LABEL: gettext('Assign Sales Manager'),
  ASSIGN_RETENTION_MANAGERS_LABEL: gettext('Assign Retention Manager'),
  ASSIGN_CAGE_LABEL: gettext('Assign Cage'),
  ASSIGN_RETENTION_POOL_DESK_LABEL: gettext('Edit Retention Pool Desk'),
  ARCHIVE_CLIENTS_LABEL: gettext('Archive'),
  AUTO_UPDATE_ONLINE_STATUS: gettext('Auto-update online status'),
};
const bread = [{ label: gettext('Clients'), route: appRoutes.CLIENTS }];

const bulkActionsConf = onClick => [
  ...(!isFeatureEnabled()('MASS_ASSIGNMENT') ? [{
    icon: 'Tag',
    label: text.EDIT_TAGS_LABEL,
    onClick: data => onClick(data, 'EDIT_TAGS'),
  }] : []),
  ...(!isFeatureEnabled()('MASS_ASSIGNMENT') && (hasAccess('sales_status.bulk_set.create') || hasAccess('sales_status.bulk_set.update')) ? [{
    icon: 'Edit',
    label: text.EDIT_SALES_STATUTS_LABEL,
    onClick: data => onClick(data, 'EDIT_SALES_STATUSES'),
  }] : []),
  ...((hasAccess('compliance_status.bulk_set.create') || hasAccess('compliance_status.bulk_set.update')) 
    && isFeatureEnabled()('COMPLIANCE_STATUS_ENABLED') ? [{
      icon: 'Edit',
      label: text.EDIT_COMPLIANCE_STATUTS_LABEL,
      onClick: data => onClick(data, 'EDIT_COMPLIANCE_STATUSES'),
    }] : []),
  ...(!isFeatureEnabled()('MASS_ASSIGNMENT') && (hasAccess('team.clients_unassign.create') || hasAccess('team.clients_unassign.create')) ? [{
    icon: 'Client',
    label: text.ASSIGN_SALES_MANAGERS_LABEL,
    onClick: data => onClick(data, 'ASSIGN_SALES_MANAGER'),
  }] : []),
  ...((!isFeatureEnabled()('MASS_ASSIGNMENT') && (hasAccess('team.clients_unassign.create') || hasAccess('team.clients_unassign.create'))) ? [{
    icon: 'Client',
    label: text.ASSIGN_RETENTION_MANAGERS_LABEL,
    onClick: data => onClick(data, 'ASSIGN_RETENTION_MANAGER'),
  }] : []),
  ...((!isFeatureEnabled()('MASS_ASSIGNMENT') && (hasAccess('user.bulk_cage_assign.create'))) ? [{
    icon: 'Edit',
    label: text.ASSIGN_CAGE_LABEL,
    onClick: data => onClick(data, 'ASSIGN_CAGE'),
  }] : []),
  ...(!isFeatureEnabled()('MASS_ASSIGNMENT') && hasAccess('user.bulk_archive') ? [{
    icon: 'Delete',
    label: text.ARCHIVE_CLIENTS_LABEL,
    onClick: data => onClick(data, 'ARCHIVE_CLIENTS'),
  }] : []),
];

const TabTitle = ({ name, count }) => (
  <span>
    {name}
    <If condition={!['All', 'Live', 'Demo'].includes(name)}>
      <span className="text-black" style={{ position: 'relative', left: '5px' }}>
        {count}
      </span>
    </If>
  </span>
);

export class Clients extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.canAutoUpdate = hasAccess('team.online_auto_update.*') && isFeatureEnabled()('ONLINE_STATUS');
    this.pollingApi = getDjangoApi('team/my_profile/online_auto_update');

    this.tableActions = {
      onPreview: this.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
      onBiqUserPreview: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };

    this.state = {
      bulkActions: bulkActionsConf(this.bulkAction),
      selectedSection: {},
      sections: null,
      pollingEnabled: false,
      selectedUsers: [],
    };

    this.massAssignmentActions = [
      ...(hasAccess('new_bulk.users.add_tags.create') ? [{
        label: text.EDIT_TAGS_LABEL,
        icon: 'Tag',
        onClick: () => getStore().dispatch(overlayActions.open('MASS_ASSIGNMENT', [], null, { type: MASS_ASSIGN_TYPES.EDIT_TAGS })),
      }] : []),
      ...(hasAccess('new_bulk.users.assign_sales_manager.create') ? [{
        label: text.ASSIGN_SALES_MANAGERS_LABEL,
        icon: 'Edit',
        onClick: () => getStore().dispatch(overlayActions.open('MASS_ASSIGNMENT', [], null, { type: MASS_ASSIGN_TYPES.ASSIGN_SALES_MANAGER })),
      }] : []),
      ...(hasAccess('new_bulk.users.set_sales_status.create') ? [{
        label: text.EDIT_SALES_STATUTS_LABEL,
        icon: 'Edit',
        onClick: () => getStore().dispatch(overlayActions.open('MASS_ASSIGNMENT', [], null, { type: MASS_ASSIGN_TYPES.EDIT_SALES_STATUS })),
      }] : []),
      ...(hasAccess('new_bulk.users.assign_cage.create')) ? [{
        icon: 'Edit',
        label: text.ASSIGN_CAGE_LABEL,
        onClick: () => getStore().dispatch(overlayActions.open('MASS_ASSIGNMENT', [], null, { type: MASS_ASSIGN_TYPES.ASSIGN_CAGE })),
      }] : [],
      ...(hasAccess('new_bulk.users.assign_retention_pool_desk.create')) ? [{
        icon: 'Edit',
        label: text.ASSIGN_RETENTION_POOL_DESK_LABEL,
        onClick: () => getStore().dispatch(overlayActions.open('MASS_ASSIGNMENT', [], null, { type: MASS_ASSIGN_TYPES.ASSIGN_RETENTION_POOL_DESK })),
      }] : [],
      ...(hasAccess('new_bulk.users.assign_retention_manager.create')) ? [{
        icon: 'Edit',
        label: text.ASSIGN_RETENTION_MANAGERS_LABEL,
        onClick: () => getStore().dispatch(overlayActions.open('MASS_ASSIGNMENT', [], null, { type: MASS_ASSIGN_TYPES.ASSIGN_MANAGER })),
      }] : [],
      ...(hasAccess('new_bulk.users.archive.create')) ? [{
        icon: 'Edit',
        label: text.ARCHIVE_CLIENTS_LABEL,
        onClick: () => getStore().dispatch(overlayActions.open('MASS_ASSIGNMENT', [], null, { type: MASS_ASSIGN_TYPES.ARCHIVE })),
      }] : [],
    ];
  }

  componentDidMount() {
    const modifier = modifiers(this.tableActions);
    this.actions.fetchOptions(modifier).then(() => { 
      if (this.canAutoUpdate) {
        this.pollingApi.list().then(({ data }) => this.setState(
          { pollingEnabled: data.online_auto_update }, 
          this.onAutoUpdateStatusReceived,
        ));
      } else {
        this.fetchTableData();
      }
    });
  }

  componentDidUpdate(prevProps) {
    const prevSections = _.get(prevProps, 'table.options.sections');
    const sections = _.get(this.props, 'table.options.sections');
    const prevSection = this.getActiveSectionName(prevProps);
    const section = this.getActiveSectionName();

    if ((_.isEmpty(prevSections) && (prevSections !== sections)) || (section !== prevSection)) {
      this.setSections();
    }
  }

  componentWillUnmount() {
    stopPoll('clients');
  }

  onRowSelected = (data) => {
    const { EDIT_TAGS, EDIT_SALES_STATUSES, EDIT_COMPLIANCE_STATUSES,
      ASSIGN_SALES_MANAGER, ASSIGN_RETENTION_MANAGER, ARCHIVE_CLIENTS, ASSIGN_CAGE } = this.props;

    _.forEach({ 
      EDIT_TAGS, 
      EDIT_SALES_STATUSES, 
      EDIT_COMPLIANCE_STATUSES, 
      ASSIGN_SALES_MANAGER, 
      ASSIGN_RETENTION_MANAGER, 
      ARCHIVE_CLIENTS, 
      ASSIGN_CAGE }, (sidepanel, id) => {
      if (sidepanel.visible) this.actions.updateSidepanel(id, data);
    });
    this.setState({ selectedUsers: data });
    this.updateGridHeaders(data);
  };

  getGridApi = (api) => {
    this.gridApi = api;
  };

  onAutoUpdateStatusReceived = () => {
    const { pollingEnabled } = this.state;

    if (pollingEnabled) {
      this.onPollingUpdate();
    } else {
      this.fetchTableData();
    }
  }

  startTableAutoUpdate = () => { // eslint-disable-line
    this.actions.changeOrdering('-online_last_seen');
    startPoll('clients', this.fetchTableData);
  }

  stopTableAutoUpdate = () => {
    stopPoll('clients');
    this.actions.changeOrdering('-id');
  }

  onPollingUpdate = () => {
    const { pollingEnabled } = this.state;
    
    if (pollingEnabled) {
      this.startTableAutoUpdate();
    } else {
      this.stopTableAutoUpdate();
    }
  }

  togglePolling = () => {
    this.setState(({ pollingEnabled }) => ({ pollingEnabled: !pollingEnabled }), () => {
      const { pollingEnabled } = this.state;
      this.onPollingUpdate();
      this.pollingApi.create({ online_auto_update: pollingEnabled });
    });
  }

  getPredefinedFilters = () => {
    const { selectedSection } = this.state;
    const activeSectionName = this.getActiveSectionName();
    if (selectedSection.name === 'All') {
      if (!activeSectionName) return [];
      if (activeSectionName === 'Demo') return { state: [userStates.DEMO] };
    }
    return selectedSection.filters;
  };

  getFilterExcludedFields = () => {
    const { selectedSection } = this.state;
    if (_.isEmpty(selectedSection) || selectedSection.name === 'All') return [];
    return ['state'];
  };

  getActiveSectionName = (props) => {
    const { match: { path } } = props || this.props;

    if (!path.match(/\/clients\//g)) return null;
    return path.replace('/clients/', '');
  }


  setSections = () => {
    const { table } = this.props;
    let sections = _.get(table, 'options.sections', []);
    const allSection = _.get(table, 'options.sections[0]');
    const activeSectionName = this.getActiveSectionName();
    if (allSection) {
      if (activeSectionName === 'Demo') {
        sections = [allSection];
      }

      const renameSection = section => ({
        ...section,
        ...(section.name === 'All' ? { name: activeSectionName || gettext('All') } : {}),
      });

      this.setState({
        sections: sections.map(renameSection),
        selectedSection: allSection,
      });
    }
  }

  headerActions = () => {
    const { exportUrl } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        {(hasAccess('leads.import.upload') || hasAccess('leads.create.*')) && <AddLeads />}
        {hasAccess('user.export.*') && <Export url={exportUrl} />}
      </div>
    );
  };

  closeArchiveSidepanel = () => {
    this.actions.closeSidepanel('ARCHIVE_CLIENTS');
    this.actions.resetArchiveState();
  };

  updateGridHeaders = (data) => {
    const { bulkActions } = this.state;
    const containsOnlyLeads = data.every(user => user.state === 'Lead');
    const archiveConf = bulkActions[bulkActions.length - 1];
    const {
      ARCHIVE_CLIENTS,
    } = this.props;

    if (ARCHIVE_CLIENTS.visible && !containsOnlyLeads) {
      this.closeArchiveSidepanel();
    }

    if (!isFeatureEnabled()('MASS_ASSIGNMENT') && !!archiveConf.disabled === containsOnlyLeads) {
      const newBulkActions = bulkActions.map((item) => {
        if (item.label === text.ARCHIVE_CLIENTS_LABEL) {
          return { ...item, disabled: !item.disabled };
        }

        return item;
      });

      this.setState({
        bulkActions: newBulkActions,
      });
    }
  };

  fetchTableData = () => {
    const { gridApi } = this;
    gridApi && gridApi.deselectAll();
    const { table } = this.props;
    return this.actions.fetchTableData({ ...table.query });
  }

  handleTabChange = (section) => {
    this.setState({ selectedSection: section });
  };

  getPredefinedFiltersWithOperator = () => _.reduce(
    this.getPredefinedFilters(),
    (filters, value, filter) => {
      let { key, operator } = parseFilterKey(filter, false);

      if (!key) {
        key = operator;
        operator = 'exact';
      }

      return {
        ...filters, 
        [key]: { value: value.join(','), operator },
      };
    }, 
    {},
  );

  bulkAction = (data, actionType) => {
    this.actions.openSidepanel(actionType, data);
  };

  render() {
    const { fields, actions, table, fetchInProgress } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const { bulkActions, pollingEnabled, sections, selectedUsers } = this.state;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const assignSalesApi = getAssignSalesManagerApi();
    const assignRetentionApi = getAssignRetentionManagerApi();
    const activeSectionName = this.getActiveSectionName();

    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      predefinedFilters: this.getPredefinedFiltersWithOperator(),
      excluded: this.getFilterExcludedFields(),
      tableModifier: modifiers(this.tableActions),
      fetchInProgress,
    };

    const TableComponent = (
      <GridComponent
        getGridApi={this.getGridApi}
        tableModifier={modifiers(this.tableActions)} 
        checkboxSelection={!!bulkActions.length || isFeatureEnabled()('MASS_ASSIGNMENT')}
        bulkActions={bulkActions}
        onRowSelected={this.onRowSelected}
        preventInternalOptions
        {...this.props}
      />
    );

    return (
      <Page
        bread={bread}
        headerActions={this.headerActions}
        title={text.PAGE_TITLE(activeSectionName)}
      >
        {sections ? (
          <Tabs>
            {sections
              ? sections.map(section => (
                <Tab
                  key={section.name}
                  title={<TabTitle name={section.name} count={section.count} />}
                  onLoad={() => this.handleTabChange(section)}
                >
                  <Fragment>
                    <Filters {...filterProps} />
                    {isFeatureEnabled()('MASS_ASSIGNMENT') && <MassAssignmentPanel actions={this.massAssignmentActions} />}
                    {this.canAutoUpdate && (
                      <div style={{ position: 'relative', left: '160px', top: '20px', display: 'flex', alignItems: 'center' }}>
                        <Toggle checked={pollingEnabled} onChange={this.togglePolling} />
                        <span style={{ margin: '0 5px' }} className="m-l-sm text-neutral-900">{text.AUTO_UPDATE_ONLINE_STATUS}</span>
                      </div>
                    )}
                    {TableComponent}
                  </Fragment>
                </Tab>
              ))
              : null}
          </Tabs>
        ) : <Loader />}

        <ClientsEditTags fetchTableData={this.fetchTableData} gridApi={this.gridApi} />
        <ClientsEditSalesStatuses fetchTableData={this.fetchTableData} gridApi={this.gridApi} />
        <ClientsEditComplianceStatuses 
          fetchTableData={this.fetchTableData} 
          gridApi={this.gridApi}
        />
        <ClientsAssignSalesManager 
          apiAssignSalesManager={assignSalesApi.assignSales}
          apiUnassignSalesManger={assignSalesApi.unAssignSales}
          fetchTableData={this.fetchTableData}
          overlayName="ASSIGN_SALES_MANAGER"
          gridApi={this.gridApi} 
        />
        <ClientsAssignRetentionManager
          apiAssignSalesManager={assignRetentionApi.assignSales}
          apiUnassignSalesManger={assignRetentionApi.unAssignSales}
          fetchTableData={this.fetchTableData}
          overlayName="ASSIGN_RETENTION_MANAGER"
          gridApi={this.gridApi} 
        />
        <ClientsArchive fetchTableData={this.fetchTableData} />
        <ClientsAssignCage fetchTableData={this.fetchTableData} />
        <UserPreview onUserUpdate={this.fetchTableData} />
        <BiqUserPreview />
        <AddLeadSingle fetchTableData={this.fetchTableData} />
        <AddLeadBulk fetchTableData={this.fetchTableData} />
        <MassAssignment users={selectedUsers} fetchTableData={this.fetchTableData} />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages, overlays }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    exportUrl: table.exportUrl,
    EDIT_TAGS: overlays.EDIT_TAGS,
    EDIT_SALES_STATUSES: overlays.EDIT_SALES_STATUSES,
    EDIT_COMPLIANCE_STATUSES: overlays.EDIT_COMPLIANCE_STATUSES,
    ASSIGN_SALES_MANAGER: overlays.ASSIGN_SALES_MANAGER,
    ASSIGN_RETENTION_MANAGER: overlays.ASSIGN_RETENTION_MANAGER,
    ARCHIVE_CLIENTS: overlays.ARCHIVE_CLIENTS,
    ASSIGN_CAGE: overlays.ASSIGN_CAGE,
    fields: table.fields,
    fetchInProgress: table.fetchDataInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changeOrdering: actions.changeOrdering,
      changePageSize: actions.changePageSize,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      fetchTableData: actions.fetchTableData,
      openSidepanel: overlayActions.open,
      updateSidepanel: overlayActions.update,
      closeSidepanel: overlayActions.close,
      resetArchiveState: archiveActions.reset,
    },
    dispatch,
  ),
});

export default {
  component: compose(
    withUnmountMethod,
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
    withErrorBoundary,
    withRouter,
  )(Clients),
  aclFn() {
    return hasAccess('user.list') && isFeatureEnabled()('table-refactor');
  },
  path: appRoutes.CLIENTS,
};
