import React, { Component, Fragment } from 'react';
import { object } from 'prop-types';
import { Button, Space, notifier } from 'tc-biq-design-system';

import { LoginLogo } from '../../../components/Nav/logos';
import FormPanel from '../../../components/form/Components/FormPanel/FormPanel';
import { gettext } from '../../../logic/utilities/languageUtility';

import FormFactory from '../../../components/form';
import to from '../../../logic/utilities/to';
import { login } from '../../../logic/services/authentication';
import Page from '../../../components/Page';
import { auth0RedirectTo, isEnabled } from '../../../logic/services/auth0';
import { getUrlParameter } from '../../../logic/utilities/state-param';
import appRoutes from '../../../components/App/Router/appRoutes';

import './Auth.scss';

export const FORM_KEY = 'LOGIN_FORM';

export const text = {
  USERNAME: {
    LABEL: gettext('Username'),
    PLACEHOLDER: gettext('Email or username'),
  },
  PASSWORD: gettext('Password'),
  LOGIN: gettext('Login'),
  LOGIN_FAILED: gettext('Login failed'),
  KEEP: gettext('Keep me logged in'),
  FORGOT: gettext('Forgot password'),
  WELCOME: gettext('Welcome to BrokerIQ'),
};

const username = {
  type: 'text',
  id: 'username',
  name: 'username',
  placeholder: text.USERNAME.PLACEHOLDER,
  label: text.USERNAME.LABEL,
};

const password = {
  type: 'password',
  id: 'password',
  name: 'password',
  placeholder: text.PASSWORD,
  label: text.PASSWORD,
};

const remember = {
  type: 'checkbox',
  id: 'remember',
  name: 'remember',
  label: text.KEEP,
};

const propTypes = {
  values: object.isRequired,
  actions: object.isRequired,
  history: object.isRequired,
  session: object.isRequired,
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.isOauthEnabled = isEnabled;
    this.error = getUrlParameter('error');
    this.state = {
      submitInProgress: false,
    };
  }

  componentDidMount() {
    if (this.error) notifier.error(this.error);
  }

  onSubmit = async () => {
    this.isOauthEnabled ? this.loginAuth0() : this.login();
  }

  onSuccess() {
    const { actions } = this.props;
    actions.loadAuthSession().then(() => {
      const {
        history,
        session: { postLoginRoute } } = this.props;

      if (postLoginRoute) {
        actions.clearPostLoginRoute();
        history.push(postLoginRoute);
      } else {
        history.push(appRoutes.DASHBOARD);
      }
    });
  }

  onError = ({ data }) => {
    const { actions } = this.props;
    const { non_field_errors, messages } = data;
    actions.setFieldsErrors([]);
    if (non_field_errors) {
      notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
    } else if (messages) {
      notifier.error(data.messages.map(err => <span>{err.text}</span>));
    } else {
      const fields = [password, username, remember];
      if (fields.some(field => !_.isEmpty(data[field.name]))) {
        actions.setFieldsErrors(data);
      } else {
        notifier.error(text.LOGIN_FAILED);
      }
    }
  }

  onForgotPassClick = () => {
    const { history } = this.props;
    history.push(appRoutes.RESET_PASSWORD);
  }

  login = async () => {
    const { values } = this.props;
    this.setState({ submitInProgress: true });
    const [err] = await to(login(values.username || '', values.password || '', values.remember));
    this.setState({ submitInProgress: false });
    err ? this.onError(err) : this.onSuccess();
  }

  loginAuth0 = () => {
    const {
      session: { postLoginRoute },
    } = this.props;
    auth0RedirectTo(postLoginRoute);
  }

  formConfig = () => ({
    form: FORM_KEY,
    customFields: [
      username,
      password,
      remember,
    ],
  })

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const Form = this.renderForm();
    const { submitInProgress } = this.state;
    return (
      <Page style={{}} hideHeader title={text.LOGIN}>
        <div className="auth-page">
          <Form renderForm={formFields => (
            <FormPanel size="small">
              <FormPanel.Section hideBorder>
                <p className="tc-heading-s auth-page__welcome">{text.WELCOME}</p>
                <div className="auth-page__logo">
                  <LoginLogo />
                </div>
                <Space size={30} />
                {!this.isOauthEnabled && (
                  <Fragment>
                    {formFields.username}
                    <Space size={20} />
                    {formFields.password}
                    <Space size={10} />
                    {formFields.remember}
                    <Space size={30} />
                  </Fragment>
                )}
                <div className="auth-page__buttons">
                  {!this.isOauthEnabled && (
                    <>
                      <Button 
                        loading={submitInProgress} 
                        onClick={this.onForgotPassClick} 
                        color="transparent"
                      >
                        {text.FORGOT}
                      </Button>
                      <Space size={20} />
                    </>
                  )}
                  <Button loading={submitInProgress} onClick={this.onSubmit}>{text.LOGIN}</Button>
                </div>
              </FormPanel.Section>
            </FormPanel>
          )} 
          />
        </div>
      </Page>
    );
  }
}

Login.propTypes = propTypes;

export default Login;
