import React from 'react';
import { object } from 'prop-types';

const propTypes = {
  data: object.isRequired,
};

const actionRenderer = (actions, data, actionIcons) => _.map(actions, (handler, type) => _.isFunction(actionIcons[type]) && actionIcons[type](handler, data)).filter(Boolean);

const ActionsCellWrapper = (actions, actionIcons) => ({ data }) => ( // eslint-disable-line
  <div style={{
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '12px',
    height: '100%',
    width: '100%',
  }}
  >
    {actionRenderer(actions, data, actionIcons)}
  </div>
);

ActionsCellWrapper.propTypes = propTypes;

export default ActionsCellWrapper;
