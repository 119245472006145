import { arrayOf, node, oneOfType } from 'prop-types';
import React from 'react';

import './ComboxWrapper.scss';

const propTypes = {
  children: oneOfType([
    node,
    arrayOf(node),
  ]).isRequired,
};

const ComboxWrapper = ({ children }) => <div className="biq-combox__wrapper">{children}</div>;

ComboxWrapper.propTypes = propTypes;

export default ComboxWrapper;
