import React, { Component, Fragment } from 'react';
import { Button, notifier } from 'tc-biq-design-system';
import { object, func } from 'prop-types';

import withTable, { GridTab } from '../../../../components/grid/withTable';
import { gettext } from '../../../../logic/utilities/languageUtility';
import Page from '../../../../components/Page';
import CampaignSidepanel, { SIDEPANEL_ID } from './CampaignSidepanel';
import modifiers from './modifiers';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import { BiqUserPreview } from '../../../../components/common/sidepanels';
import { hasAccess } from '../../../../logic/services/acl';
import CampaignStat from './CampaignStat';
import appRoutes from '../../../../components/App/Router/appRoutes';


const text = {
  TITLE: gettext('Marketing Campaigns'),
  EXPORT: gettext('Export current page'),
  EXPORT_ALL: gettext('Export all'),
  CREATE: gettext('Create new campaign'),
  TOGGLE_SUCCESS_ACTIVATED: gettext('Campaign activated'),
  TOGGLE_SUCCESS_DEATVATED: gettext('Campaign deactivated'),
  TOGGLE_ERROR: gettext('Campaign update problem'),
};

const bread = [{ label: text.TITLE, route: appRoutes.CAMPAIGNS }];

export const reducerKey = 'CAMPAIGNS';

export const tableConfig = () => ({
  reducerKey,
  apiUrl: 'team/campaigns',
  tableKey: 'CAMPAIGNS_TABLE',
});

const api = userId => getDjangoApi(`team/profile/${userId}/campaigns`);

const propTypes = {
  dispatch: func.isRequired,
  table: object.isRequired,
  actions: object.isRequired,
};

class Campaigns extends Component {
  constructor(props) {
    super(props);

    this.Grid = withTable(GridTab, tableConfig, this.gridActions, { withActions: true })();
    this.actions = {
      modify: hasAccess('team.profile.campaigns.update') && this.openSidepanel,
      toggle: hasAccess('team.profile.campaigns.update') && this.onToggle,
      previewBiqUser: props.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  onToggle = ({ id, user }, active) => {
    const { dispatch } = this.props;
    const [, tableActions] = this.Grid;
    dispatch(tableActions.updateRowData(id, { active }, api(user.id))).then(() => {
      notifier.success(active ? text.TOGGLE_SUCCESS_ACTIVATED : text.TOGGLE_SUCCESS_DEATVATED);
    }, () => {
      notifier.error(text.TOGGLE_ERROR);
    });
  }

  gridActions = () => {
    const { table } = this.props;
    const all = _.get(table, 'exportUrl.all');
    const single = _.get(table, 'exportUrl.single');
    return hasAccess('team.campaigns.export') && (
      <Fragment>
        <a href={single}>
          <Button icon="Download" color="ghost">{text.EXPORT}</Button>
        </a>
        <a href={all}>
          <Button>{text.EXPORT_ALL}</Button>
        </a>
      </Fragment>
    );
  }

  openSidepanel = (data) => {
    const { actions } = this.props;
    actions.openSidepanel(SIDEPANEL_ID, data ? { data } : _);
  }

  headerActions = () => hasAccess('team.profile.campaigns.create') && <Button onClick={this.openSidepanel}>{text.CREATE}</Button>

  reloadTable = () => {
    const { dispatch, table } = this.props;
    const [, tableActions] = this.Grid;
    dispatch(tableActions.fetchTableData(table.query));
  }

  render() {
    const [Table] = this.Grid;
    return (
      <Page 
        title={text.TITLE}
        bread={bread}
        headerActions={this.headerActions}
      >
        {hasAccess('team.campaigns.all') && <CampaignStat />}
        <Table
          singleActions
          height="50vh"
          modifier={modifiers(this.actions)}
        />
        <CampaignSidepanel
          onSuccess={this.reloadTable}
        />
        <BiqUserPreview />
      </Page>
    );
  }
}

Campaigns.propTypes = propTypes;

export default Campaigns;
