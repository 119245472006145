/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Tabs, Tab, Button, notifier } from 'tc-biq-design-system';

import connect from '../../../logic/connect';
import { gettext } from '../../../logic/utilities/languageUtility';
import withUnmountMethod from '../../../components/hoc/withUnmountMethod';
import overlayActions from '../../../components/overlay';
import Page from '../../../components/Page';
import { reasonsModifier } from './modifier';
import AddEditTransactionReason, { SIDEPANEL_ID as REASON_SIDEPANEL_ID } from './Sidepanels/AddEditTransactionReason';
import If from '../../../components/If';
import { hasAccess } from '../../../logic/services/acl';
import withTable, { GridTab } from '../../../components/grid/withTable';
import withErrorBoundary from '../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../components/App/Router/appRoutes';

const reducerKey = 'PAYMENT_TRANSACTION_TYPES';

const reasonsConfig = {
  reducerKey,
  apiUrl: 'payment_reasons_settings',
  tableKey: 'REASONS',
};

const text = {
  PAGE_TITLE: gettext('Payment Transaction Types'),
  REASONS: gettext('Reasons'),
  CREATE_NEW: gettext('Create new'),
  STATE_UPDATE_SUCCESS: gettext('State updated successfully'),
  STATE_UPDATE_ERROR: gettext('Error while updating state'),
};

const bread = [{ label: text.PAGE_TITLE, route: appRoutes.SETTINGS_PAYMENT_TRANS_TYPES }];

class PaymentTransactionTypes extends Component {
  constructor(props) {
    super(props);

    this.tableActions = {
      onModifyReasons: hasAccess('payment_reasons_settings.update') && this.onModifyReasons.bind(this),
      onClone: hasAccess('payment_reasons_settings.create') && this.onClone.bind(this),
      onToggleReasons: hasAccess('payment_reasons_settings.update') && this.toggleEnabled.bind(this),
    };

    this.ReasonGrid = withTable(GridTab, () => reasonsConfig, null, { withActions: true })();
    const [, reasonsActions] = this.ReasonGrid;
    const { dispatch } = this.props;
    this.state = {
      active: reasonsConfig.tableKey,
    };

    this.reasonsActions = bindActionCreators({
      fetchTableData: reasonsActions.fetchTableData,
      updateRowData: reasonsActions.updateRowData,
      deleteRow: reasonsActions.deleteRow,
    }, dispatch);
    this.createNewReason = this.createNewReason.bind(this);
    this.onSuccessRequest = this.onSuccessRequest.bind(this);
  } 

  onModifyReasons(data) {
    const { overlay } = this.props;
    overlay.open(REASON_SIDEPANEL_ID, { type: 'edit', data });
  }

  onClone(data) {
    const { overlay } = this.props;
    overlay.open(REASON_SIDEPANEL_ID, { type: 'clone', data });
  }

  onSuccessRequest(actions, table) {
    return () => actions.fetchTableData(table.query); 
  }

  setActiveTab(active) {
    this.setState({ active });
  }

  createNewReason() {
    const { overlay } = this.props;

    return (
      <If condition={hasAccess('payment_reasons_settings.create')}>
        <Button onClick={() => overlay.open(REASON_SIDEPANEL_ID, { type: 'add' })}>
          {text.CREATE_NEW}
        </Button>
      </If>
    );
  }

  toggleEnabled(row, is_active) {
    const { reasonsActions } = this;
    const { id } = row;
    const { active } = this.state;
    const promise = active === reasonsConfig.tableKey 
      ? reasonsActions.updateRowData(id, { is_active })
      : null;

    promise.then(() => {
      notifier.success(text.STATE_UPDATE_SUCCESS);
    }, () => {
      notifier.error(text.STATE_UPDATE_ERROR);
    });
  }

  render() {
    const {
      reasonsTable,
    } = this.props;
    const { reasonsActions } = this;

    const { active } = this.state;
    const headerActions = active === reasonsConfig.tableKey 
      ? this.createNewReason
      : null;

    const [ReasonsTable] = this.ReasonGrid;

    return (
      <Page bread={bread} headerActions={headerActions} title={text.PAGE_TITLE}>
        <Tabs>
          <Tab 
            visible={hasAccess('payment_reasons_settings.list')} 
            title={text.REASONS} 
            onLoad={() => this.setActiveTab(reasonsConfig.tableKey)}
          >
            <ReasonsTable
              singleActions={!!this.tableActions.onClone}
              modifier={reasonsModifier(this.tableActions)}
            />
            <AddEditTransactionReason 
              onSuccess={this.onSuccessRequest(reasonsActions, reasonsTable)} 
            />
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

const mapStateToProps = ({ overlays, pages }) => ({ 
  reasonsTable: pages[reasonsConfig.reducerKey].tables[reasonsConfig.tableKey],
  reasonSidepanel: overlays[REASON_SIDEPANEL_ID],
});

const mapDispatchToProps = dispatch => ({
  overlay: bindActionCreators({
    open: overlayActions.open,
  }, dispatch),
  dispatch,
});

const PaymentTransactionTypesPage = compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(PaymentTransactionTypes);

export default {
  component: PaymentTransactionTypesPage,
  path: appRoutes.SETTINGS_PAYMENT_TRANS_TYPES,
  aclId: 'payment_reasons_settings.list',
};
