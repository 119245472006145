import { createAction, handleActions } from 'redux-actions';

const actionTypes = {
  FETCH_INTEGRATION: 'FETCH_INTEGRATION',
  FETCH_INTEGRATION_PENDING: 'FETCH_INTEGRATION_PENDING',
  FETCH_INTEGRATION_REJECTED: 'FETCH_INTEGRATION_REJECTED',
  FETCH_INTEGRATION_FULFILLED: 'FETCH_INTEGRATION_FULFILLED',
  SUBMIT_INTEGRATION: 'SUBMIT_INTEGRATION',
  SUBMIT_INTEGRATION_PENDING: 'SUBMIT_INTEGRATION_PENDING',
  SUBMIT_INTEGRATION_REJECTED: 'SUBMIT_INTEGRATION_REJECTED',
  SUBMIT_INTEGRATION_FULFILLED: 'SUBMIT_INTEGRATION_FULFILLED',
  RESET_INTEGRATION: 'RESET_INTEGRATION',
  SET_INTEGRATION: 'SET_INTEGRATION',
};

export const fetchIntegration = createAction(actionTypes.FETCH_INTEGRATION, async (integrationApi) => {
  const result = await integrationApi.retrieve();
  
  return result;
});

export const setIntegration = createAction(actionTypes.SET_INTEGRATION, integration => integration);

export const submitIntegration = createAction(actionTypes.SUBMIT_INTEGRATION, async (integrationApi, integration) => {
  await integrationApi.create(integration);
});

export const resetIntegration = createAction(actionTypes.RESET_INTEGRATION);


const initialState = {
  submitInProgress: false,
  submitSuccess: false,
  submitErrors: null,

  integration: null,
  fetchInProgress: false,
  fetchErrors: null,
};

const apiHandlers = (action, data) => {
  const pendingHandler = state => ({ ...state, [`${action}InProgress`]: true });

  const rejectedHandler = (state, { payload }) => ({
    ...state,
    [`${action}InProgress`]: false,
    [`${action}Errors`]: payload,
  });

  const fulfiledHandler = (state, { payload }) => ({
    ...state,
    [`${action}InProgress`]: false,
    [`${action}Errors`]: null,
    ...(!data && { [`${action}Success`]: true }),
    ...(!!data && { [data]: payload.data }),
  });

  return {
    pendingHandler,
    rejectedHandler,
    fulfiledHandler,
  };
};


const fetchHandlers = apiHandlers('fetch', 'integration');
const submitHandler = apiHandlers('submit');

const resetHandler = () => ({ ...initialState });

const setIntegrationHandler = (state, { payload }) => ({
  ...state,
  integration: payload,
});


export default handleActions(
  {
    [actionTypes.FETCH_INTEGRATION_PENDING]: fetchHandlers.pendingHandler,
    [actionTypes.FETCH_INTEGRATION_FULFILLED]: fetchHandlers.fulfiledHandler,
    [actionTypes.FETCH_INTEGRATION_REJECTED]: fetchHandlers.rejectedHandler,
    [actionTypes.SUBMIT_INTEGRATION_PENDING]: submitHandler.pendingHandler,
    [actionTypes.SUBMIT_INTEGRATION_FULFILLED]: submitHandler.fulfiledHandler,
    [actionTypes.SUBMIT_INTEGRATION_REJECTED]: submitHandler.rejectedHandler,
    [actionTypes.RESET_INTEGRATION]: resetHandler,
    [actionTypes.SET_INTEGRATION]: setIntegrationHandler,
  },
  initialState,
);
