import React, { Component } from 'react';
import { notifier, Button, Dropdown, Icon, withSidepanelManager, DashboardCard } from 'tc-biq-design-system';
import { object } from 'prop-types';

import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import session from '../../../../../../../../logic/services/session';
import to from '../../../../../../../../logic/utilities/to';
import { TaskRenderer } from '../../../../../../../../components/common';
import { CreateEditTask, ViewTask } from '../../../../../../../../components/common/sidepanels';

import './Tasks.scss';

const size = { 
  x: 8, 
  y: 5, 
  w: 4, 
  h: 12, 
  minW: 4, 
  maxW: 6, 
  minH: 8, 
  maxH: 12, 
};

const text = {
  TITLE: gettext('Tasks'),
  MY_TASKS: gettext('My tasks'),
  TEAM_TASKS: gettext('Team tasks'),
  CREATE_TASK: gettext('Create task'),
  COMPLETED: gettext('Task successfully completed.'),
};

const formatHeader = (showMemberTasks, text) => toggleHandler => (
  <div className="tasks-widget__dropdown" onClick={toggleHandler} key="tasks-toggle">
    <p className="tc-paragraph-regular">{showMemberTasks ? text.MY_TASKS : text.TEAM_TASKS}</p>
    <Icon name="CaretDown" size="small" />
  </div>
);

const propTypes = {
  sidepanelManager: object.isRequired,
};

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.tasksQuery = { limit: 10, offset: 0, performer__team_team__id: session.team.id };
    this.memberTasksQuery = { limit: 10, offset: 0 };
    this.apiMemberTasks = getDjangoApi(`team/profile/${session.user.id}/tasks`);
    this.apiTasks = getDjangoApi('tasks');

    this.state = {
      teamTasks: [],
      memberTasks: [],
      showMemberTasks: true,
    };
  }

  componentDidMount() {
    this.fetchTasks();
  }

  onComplete = async (data) => {
    const api = getDjangoApi('tasks')
      .one(data.id)
      .all('mark_as_completed');
    const [err] = await to(api.create());
    if (err) {
      notifier.error(err.data.msg);
    } else {
      notifier.success(text.COMPLETED);
      this.fetchTasks();
    }
  }

  onToggle = (showMyTasks) => {
    const { showMemberTasks } = this.state;
    if (showMyTasks !== showMemberTasks) {
      this.setState({
        showMemberTasks: showMyTasks,
      });
    }
  }

  onCreate = () => {
    const { sidepanelManager } = this.props;

    const sidepanelOptions = {
      visible: true,
      onSuccess: this.fetchTasks,
      sidepanelManager,
      parameters: {
        type: 'add',
      },
    };
    sidepanelManager.add(CreateEditTask, sidepanelOptions);
  }

  onView = (data) => {
    const { sidepanelManager } = this.props;

    const sidepanelOptions = {
      visible: true,
      onSuccess: this.fetchTasks,
      sidepanelManager,
      parameters: {
        ...data,
      },
    };
    sidepanelManager.add(ViewTask, sidepanelOptions);
  }

  getList = () => [
    { label: text.MY_TASKS, onClick: () => this.onToggle(true) },
    { label: text.TEAM_TASKS, onClick: () => this.onToggle(false) },
  ]

  fetchTasks = async () => {
    const [teamResponse, memberResponse] = await Promise.all([
      this.apiTasks.list(this.tasksQuery),
      this.apiMemberTasks.list(this.memberTasksQuery),
    ]);

    const teamTasks = _.get(teamResponse, 'data.results') || [];
    const memberTasks = _.get(memberResponse, 'data.results') || [];

    this.setState({
      teamTasks,
      memberTasks,
    });
  }

  render() {
    const { teamTasks, memberTasks, showMemberTasks } = this.state;
    const dropdownList = this.getList();
    const taskList = showMemberTasks ? memberTasks : teamTasks;
    const customHeader = formatHeader(showMemberTasks, text);

    return (
      <DashboardCard
        noPadding
        title={text.TITLE}
        description={text.TITLE}
      >
        <div className="tasks-widget">
          <div className="tasks-widget__header">
            <Dropdown customHeader={customHeader} list={dropdownList} />
            <Button color="ghost" size="small" onClick={this.onCreate}>
              {text.CREATE_TASK}
            </Button>
          </div>
          <div className="tasks-widget__content">
            <div className="tasks-widget__list">
              {taskList.length > 0 && (
                taskList.map(task => <TaskRenderer key={task.id} task={task} viewTask={this.onView} completeTask={this.onComplete} />)
              )}
            </div>
          </div>
        </div>
      </DashboardCard>
    );
  }
}

Tasks.propTypes = propTypes;

export default {
  component: withSidepanelManager(Tasks),
  size,
};
