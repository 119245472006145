const CONFIG = window.config;

export const getSocketUrl = () => {
  const url = _.get(CONFIG, 'api.host');
  const socketUrl = `wss:${url.replace('https:', '').replace('http:', '')}/ws`;
  return socketUrl;
};

export const getBulkActionData = socketRes => JSON.parse(socketRes.data);

export const isBulkActionDataFinished = event => event === 'bulk_action_finished';

export const getBulkActionDataStats = (socketRes) => {
  const { data, event } = JSON.parse(socketRes.data);
  return {
    last_event: event,
    processed: data.processed_count,
    total: data.total_count,
    failed: data.failed_count,
    success: data.success_count,
    skipped: data.skipped_count,
    limit: data.rows_limit,
    action_key: data.action_key,
    action: data.action_name,
    state: data.state, // Success | Started etc.
    result_data: data.result_data, // on case when bulk action finished
  };
};
