/* eslint react/prop-types: 0 */
import React from 'react';
import { Toggle } from 'tc-biq-design-system';
import { bool } from 'prop-types';

const propTypes = {
  value: bool.isRequired,
};

const ToggleCell = onToggle => ({ value, data }) => ( 
  <Toggle disabled={!onToggle} onChange={newVal => onToggle(data, newVal)} checked={value} />
);

ToggleCell.propTypes = propTypes;

export default ToggleCell;
