import React from 'react';
import { DatePickerRange } from 'tc-biq-design-system';
import { array, func, oneOfType, string, bool } from 'prop-types';

const propTypes = {
  value: oneOfType([array, string]),
  onChange: func.isRequired,
  withPortal: bool,
};

const defaultProps = {
  value: [],
  withPortal: false,
};


const DateRange = (props) => {
  const { value, onChange, withPortal } = props;
  const [start, end] = value || [];
  return (
    <DatePickerRange
      {...props}
      withPortal
      portalId="app"
      startDateModifiers={{ portalId: withPortal ? 'app' : null, showYearDropdown: true, showMonthDropdown: true }}
      endDateModifiers={{ portalId: withPortal ? 'app' : null, showYearDropdown: true, showMonthDropdown: true }}
      startDate={start}
      endDate={end}
      onChange={({ startDate, endDate }) => onChange([startDate, endDate])}
    />
  );
};

DateRange.propTypes = propTypes;
DateRange.defaultProps = defaultProps;

export default DateRange;
