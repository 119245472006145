import React from 'react';
import { Sidepanel, Panel, Space } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { array, object } from 'prop-types';

import connect from '../../../../../logic/connect';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import overlayActions from '../../../../../components/overlay';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { StateCell } from '../../../../../components/gridCellRenderers';
import Extra from '../../../../../components/Extra';
import UserInfo from '../../../../../components/UserInfo';

const propTypes = {
  sidepanel: object.isRequired,
  actions: object.isRequired,
};

const text = {
  TITLE: gettext('Trade'),
  PANEL_TITLE: gettext('Position details'),
  INSTRUMENT: gettext('Instrument'),
  VOLUME: gettext('Volume'),
  RATE: gettext('Rate'),
  SIDE: gettext('Side'),
  SSV: gettext('SSV'),
  TIME: gettext('Time'),
};

const formatPanelBodyData = data => [
  { label: text.INSTRUMENT, value: data.instrument.symbol },
  { label: text.VOLUME, value: data.amount },
  { label: text.RATE, value: data.rate },
  { label: text.SIDE, value: data.side },
  { label: text.SSV, value: data.ssv },
  { label: text.TIME, value: moment.utc(data.time).format('MMM Do YYYY, [at] HH:mm') },
];

const PanelBody = ({ tradeData }) => {
  const bodyData = formatPanelBodyData(tradeData);
  return (
    <div className="card-panel-body">
      {
        bodyData.map((line, index) => (
          <div className="panel-body-line" key={line.value + index}>
            <span>{line.label}</span>
            <span>{line.value}</span>
          </div>
        ))
      }
    </div>
  );
};

PanelBody.propTypes = {
  tradeData: array.isRequired,
};

const TradeSingle = ({ sidepanel, actions }) => {
  if (!sidepanel.parameters) return null;
  const data = sidepanel.parameters;

  return (
    <Sidepanel
      visible={sidepanel.visible}
      title={`${text.PANEL_TITLE} ${data.id}`}
      onCloseIconClick={() => actions.closeSidepanel('TRADE_SINGLE')}
      hideFooter
    >
      <UserInfo user={data.user} />
      <Panel title={text.PANEL_TITLE} status={() => <StateCell key={data.position_state} value={data.position_state} />}>
        <PanelBody tradeData={data} />
      </Panel>
      <Space size={16} />
      <Extra rawData={data.extra} />
    </Sidepanel>
  );
};

const mapStateToProps = ({ overlays }) => ({
  sidepanel: overlays.TRADE_SINGLE,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
    },
    dispatch,
  ),
});

TradeSingle.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(TradeSingle));
