import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';
import { pushVerification } from './Model';
import userActionTypes from '../types';
import ConfirmationFactory from '../../Confirmation';

const text = {
  LABEL: gettext('Push verification'),
  QUESTION: gettext('Are you sure you want to push verification of this user?'),
  SUCCESS: gettext('Pushed user for verification.'),
  ERROR: gettext('Error pushing user for verification.'),
};

const ACTION_ICON = 'User';

export default {
  name: 'pushVerification',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, _user, isIb) {
    if (isIb) {
      return false;
    } 
    const hasStatePermission = [
      USER_STATE.UNSUITABLE,
      USER_STATE.UNSUITABLE_REFER,
      USER_STATE.UNSUITABLE_DEMO,
      USER_STATE.UNSUITABLE_REFER_DEMO,
    ].includes(state);
    return hasAccess('user.kyc.push_verification.*') && hasStatePermission && isFeatureEnabled()('PUSH_VERIFICATION');
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const confirmationOptions = {
      apiAction: pushVerification,
      apiParams: [user],
      text,
      reduxActionName: 'PUSH_VERIFICATION',
    };

    const Confirmation = ConfirmationFactory(confirmationOptions);

    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(Confirmation, sidepanelOptions);
  },
};
