import React, { Component } from 'react';

import withFormSidepanel from '../../../../../../../components/FormSidepanel';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

export const SIDEPANEL_ID = 'TRADING_ACCOUNT_TYPE_FORM_SIDEPANEL';
export const FORM_ID = 'TRADING_ACCOUNT_TYPE_FORM';

const apiUrl = 'trading_account_labels';

const text = {
  CREATE: gettext('Create trading account type'),
  EDIT: gettext('Edit trading account type'),
  SUCCESS: {
    create: gettext('Trading account type successfully created'),
    edit: gettext('Trading account type successfully updated'),
  },
};

class AccountTypesSidepanel extends Component {
  constructor(props) {
    super(props);

    this.FormSidepanel = withFormSidepanel(FORM_ID, SIDEPANEL_ID);
  }

  getApi = () => getDjangoApi(apiUrl)

  render() {
    const { FormSidepanel, getApi } = this;
    return (
      <FormSidepanel
        {...this.props}
        options={{
          getApi,
          text,
        }}
      />
    );
  }
}

export default AccountTypesSidepanel;
