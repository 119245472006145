import React, { Component } from 'react';
import { Spinner } from 'tc-biq-design-system';
import { number } from 'prop-types';

import withTable, { GridTab } from '../../../../../components/grid/withTable';
import modifiers from '../../../campaign-single/CampaignSingle/SignupUsersTable/modifiers';

export const reducerKey = 'CAMPAIGNS';

export const tableConfig = ({ userId, campaignId, id }) => ({
  reducerKey,
  apiUrl: `team/profile/${userId}/campaigns/${campaignId}/tracking_links/${id}/signup_users`,
  tableKey: 'SIGNUP_USERS_TABLE',
});

const propTypes = {
  campaignId: number.isRequired,
  userId: number.isRequired,
  id: number.isRequired,
};

class SignupUsersTable extends Component {
  generateGrid = () => {
    const { campaignId, userId, id } = this.props;
    if (!this.Grid) {
      this.Grid = withTable(GridTab, tableConfig, this.gridActions, { withActions: true })({ userId, campaignId, id });
    }
    
    return this.Grid;
  }

  render() {
    const Grid = this.generateGrid();
    const [Table] = Grid || [];
    return Table ? <Table height="50vh" modifier={modifiers()} /> : <Spinner />;
  }
}

SignupUsersTable.propTypes = propTypes;

export default SignupUsersTable;
