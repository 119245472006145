const CONFIG = window.config;

export const BASE = CONFIG.api.host; //eslint-disable-line
export const BO_APP = CONFIG.api.applicationRoot;
export const BO_APP_FULL = urlCombine(BASE, BO_APP);

/**
 * Combines argument strings that are url parts into an absolute url
 * Example service.urlCombine(service.BO_APP_FULL, 'clients', id) returns http(s)://{bo_app}/clients/{id}
 * @returns {String} absolute url to combined url parts
 */
export function urlCombine() {
  if (!(arguments && arguments.length)) {
    return '';
  }

  const urlPieces = [];
  for (const i in arguments) {//eslint-disable-line
    let arg = arguments[i];
    if (arg === undefined || arg === null) {
      continue;//eslint-disable-line
    }
    arg = arg.toString();
    if (arg === '') continue;//eslint-disable-line
    while (arg.startsWith('/')) arg = arg.substr(1);
    while (arg.endsWith('/')) arg = arg.slice(0, -1);
    urlPieces.push(arg);
  }

  return urlPieces.join('/');
}

export default { urlCombine, BASE, BO_APP, BO_APP_FULL };
