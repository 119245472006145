import React from 'react';
import { bindActionCreators } from 'redux';

import SelectSalesStatus from '../SelectStatus';
import bulkActionsActionFactory from '../../../../../../../components/common/sidepanels/bulk/actions';
import connect from '../../../../../../../logic/connect';
import { updateUser } from '../../../Model';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const apiSalesStatuses = getDjangoApi('autocomplete/sales_status');
const apiSalesStatusSubmit = user => getDjangoApi(`users/${user.id}/sales_status`);

const { 
  updateData, 
  resetSubmit, 
  resetInput,
  submit,
} = bulkActionsActionFactory('CLIENT_EDIT_SALES_STATUSES');

const substatusActions = bulkActionsActionFactory('CLIENT_EDIT_SALES_SUBSTATUSES');

const SalesStatus = props => <SelectSalesStatus {...props} apiSales={apiSalesStatuses} apiSalesSubmit={apiSalesStatusSubmit} />;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateData,
      resetSubmit,
      resetInput,
      submit,
      updateUser,
      updateSubStatusData: substatusActions.updateData,
    },
    dispatch,
  ),
});
  
const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const editSalesStatuses = page.actions.EDIT_SALES_STATUSES;
  const { user } = page.userDetail;
  
  return {
    fieldData: editSalesStatuses.fieldData,
    errors: editSalesStatuses.errors,
    submitSuccess: editSalesStatuses.submitSuccess,
    user,
  };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SalesStatus);
