import React, { Component } from 'react';
import { Tabs, Tab, Dropdown, withSidepanelManager } from 'tc-biq-design-system';
import { string } from 'prop-types';

import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../logic/services/acl';
import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import UserActions, { userActionTypes } from '../../UserActions';
import { withRouter } from '../../../../../../components/hoc/withRouter';
import connect from '../../../../../../logic/connect';

const reducerKey = 'USER_SINGLE';

const paymentConfig = id => ({
  reducerKey,
  apiUrl: `ib/${id}/payment_transactions`,
  tableKey: 'IB_PAYMENT_TRANSACTIONS',
});

const internalConfig = id => ({
  reducerKey,
  apiUrl: `ib/${id}/internal_transactions`,
  tableKey: 'IB_INTERNAL_TRANSACTIONS',
});

export const acls = {
  payments: 'ib.payment_transactions.list',
  internal: 'ib.user_internal_transactions.list',
};

const propTypes = {
  id: string.isRequired,
};

const text = {
  PAYMENTS: gettext('PAYMENTS'),
  INTERNAL: gettext('INTERNAL'),
  ACTIONS: gettext('Actions'),
};

const mapActionAttributes = actions => actions.map(({ label, icon, handler }) => ({
  label, icon, onClick: handler,
}));

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  return {
    user: page.userDetail.user,
  };
};

const Actions = props => (
  <UserActions type={userActionTypes.IB_PAYMENTS} DI={{ ...props, isIb: true }}>
    {actions => !_.isEmpty(actions) && <Dropdown openLeft title={text.ACTIONS} list={mapActionAttributes(actions)} />}
  </UserActions>
);

const GridActions = withRouter(
  withSidepanelManager(
    connect(mapStateToProps)(Actions),
  ),
);


class Payments extends Component {
  constructor(props) {
    super(props);

    this.Payment = withTable(GridTab, paymentConfig, GridActions)(props.id);
    this.Internal = withTable(GridTab, internalConfig, GridActions)(props.id);
  }

  render() {
    const { Payment, Internal } = this;

    return (
      <Tabs>
        <Tab visible={hasAccess(acls.payments)} title={text.PAYMENTS}>
          <Payment />
        </Tab>

        <Tab visible={hasAccess(acls.internal)} title={text.INTERNAL}>
          <Internal />
        </Tab>
      </Tabs>
    );
  }
}

Payments.propTypes = propTypes;
export default Payments;
