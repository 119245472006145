import _ from 'lodash';
import queryString from 'query-string';
import { urlCombine } from './api';

export { getExportURL, serialize, getCursor };

function getExportURL(baseEndpoint, query) {
  const qstring = `?${queryString.stringify(query)}`;
  return urlCombine(baseEndpoint, 'export', qstring.replace(/\[]/g, ''));
}

function serialize(params) {
  const serializedParams = {};
  for (const key in params) { //eslint-disable-line
    if (_.isArray(params[key])) {
      serializedParams[key] = params[key].toString();
    } else {
      serializedParams[key] = params[key];
    }
  }
  return serializedParams;
}

function getCursor(url) {
  return queryString.parse(url).cursor;
}
