/* eslint react/prop-types: 0 */
import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators, compose } from 'redux';
import {
  Button,
  Spinner,
  Tabs,
  Tab,
  HyperLink,
  Space,
  Code,
  Popconfirm,
  notifier,
} from 'tc-biq-design-system';
import { generatePath, Link, withRouter } from 'react-router-dom';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import { dateTimeFormatter } from '../../../../logic/utilities/formatters';
import Page from '../../../../components/Page';
import If from '../../../../components/If';
import { hasAccess } from '../../../../logic/services/acl';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import overlayActions from '../../../../components/overlay';
import EditLogsRecord from './sidepanel/EditLogsRecord';
import logsMessageActions from './Model';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

import './LogsMessage.scss';

const logsApi = (id, channel) => getDjangoApi(`${channel}/${id}`);
const deleteMessageApi = (userId, messageId, channel) => getDjangoApi(`users/${userId}/${channel}/${messageId}`);

const text = {
  PAGE_TITLE: context => gettext('View details: {{value}}', context),
  LOADING: gettext('Loading...'),
  MESSAGE: gettext('Message'),
  HTML: gettext('HTML'),
  NOT_SET: gettext('Not set'),
  DELETE: gettext('Delete'),
  EDIT: gettext('Edit'),
  POPCONFIRM_BUTTON_LABELS: {
    cancel: gettext('Cancel'),
    confirm: gettext('Delete'),
  },
  POPCONFIRM_LABEL: gettext('Are you sure you want to delete communication record?'),
  CALL_RECORDING: gettext('Call recording'),
  DOWNLOAD_FILE: gettext('Download file'),
  NO_DOWNLOAD_FILE: gettext('There is no file'),
  SUCCESS: gettext('Record deleted successfully'),
  TO_USER: gettext('To user'),
  FROM: gettext('From'),
  CHANNEL: gettext('Channel'),
  SUBJECT: gettext('Subject'),
  DATE: gettext('Date'),
  ID: gettext('ID'),
  MESSAGE_ID: gettext('Message ID'),
  MESSAGE_TYPE: gettext('Message type'),
  STATUS: gettext('Status'),
  PHONE_NUMBER: gettext('Phone number'),
  CALL_TYPE: gettext('Call type'),
  START_TIME: gettext('Start time'),
  END_TIME: gettext('End time'),
  DURATION: gettext('Duration'),
  SMS_TEXT: gettext('SMS text'),
  CHAT_CONTENT: gettext('Click here to view chat content'),
};

// eslint-disable-next-line
const CHANNEL_TYPES = {
  email: 'messages',
  chat: 'chat',
  sms: 'sms',
  calls: 'calls',
};

const breads = id => [
  { label: gettext('Communication History'), route: appRoutes.COMMUNICATION_HISTORY },
  { label: text.PAGE_TITLE({ value: id }), route: '' },
];
const SIDEPANEL_ID = 'EDIT_LOGS_RECORD_FORM';

const HyperLinkObject = ({ object }) => {
  const href = object.is_staff ? appRoutes.TEAM_MEMBER : appRoutes.CLIENT_PROFILE;
  return (
    <HyperLink>
      <Link href={generatePath(href, { id: object.id })}>{object.username}</Link>
    </HyperLink>
  );
};

const Text = ({ value }) => <div className="tc-paragraph-regular text-neutral-900">{value || text.NOT_SET}</div>;

const DownloadLink = ({ href }) => (
  href ? (
    <HyperLink>
      <a href={href}>{text.DOWNLOAD_FILE}</a>
    </HyperLink>
  ) : <Text value={text.NO_DOWNLOAD_FILE} />
);

const Title = ({ title }) => <div className="tc-micro-strong text-neutral-500">{title}</div>;

const Value = ({ value, download }) => {
  if (value && value.id) return <HyperLinkObject object={value} />;
  if (download) return <DownloadLink href={value} />;
  return <Text value={value} />;
};

const SingleRecord = ({ title, value, download }) => (
  <div className="single-record">
    <Title title={title} />
    <Space size={8} />
    <Value value={value} title={title} download={download} />
  </div>
);

class LogsMessage extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.channel = (props.match.params.channel || 'email').toLowerCase();
    this.id = props.match.params.id;
    this.api = logsApi(this.id, this.channel === 'email' ? 'messages' : this.channel);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.actions.loadData(this.api);
  };

  deleteRecord = () => {
    const { data, history } = this.props;
    const channel = this.channel === 'email' ? 'messages' : this.channel;
    this.actions.deleteRecord(deleteMessageApi(data.to_user.id, data.id, channel)).then(() => {
      notifier.success(text.SUCCESS);
      history.push(appRoutes.COMMUNICATION_HISTORY);
    });
  };

  openEditRecord = () => {
    const { data } = this.props;
    this.actions.openSidepanel(SIDEPANEL_ID, {
      userId: data.to_user.id,
      recordId: data.id,
      channel: CHANNEL_TYPES[this.channel],
    });
  };

  headerActions = channel => () => {
    const deleteAcl = `user.${CHANNEL_TYPES[channel]}.destroy`;
    const updateAcl = `user.${CHANNEL_TYPES[channel]}.update`;
    return (
      <Fragment>
        <If condition={hasAccess(deleteAcl)}>
          <Popconfirm
            type="destructive"
            placement="bottom"
            icon="Delete"
            label={text.POPCONFIRM_LABEL}
            onConfirm={this.deleteRecord}
            buttonLabels={text.POPCONFIRM_BUTTON_LABELS}
          >
            <Button color="destructive">{text.DELETE}</Button>
          </Popconfirm>
        </If>
        <If condition={hasAccess(updateAcl)}>
          <Button onClick={this.openEditRecord}>{text.EDIT}</Button>
        </If>
      </Fragment>
    );
  };

  render() {
    const { isLoading, data } = this.props;
    const { id, channel } = this;
    const iframeContent = `data:text/html;charset=utf-8,${encodeURIComponent(data.body_html)}`;
    return (
      <Page
        title={text.PAGE_TITLE({ value: channel })}
        headerActions={this.headerActions(channel.toLowerCase())}
        bread={breads(id)}
      >
        <div className="biq-logs-message">
          {isLoading && (
            <div className="spinner">
              <Spinner size="large" /> {text.LOADING}
            </div>
          )}
          {!isLoading && !_.isEmpty(data) && (
            <div className="biq-logs-message__container">
              <div className="biq-logs-message__container__table">
                <div className="column">
                  <SingleRecord title={text.TO_USER} value={data.to_user} />
                  <SingleRecord title={text.FROM} value={data.from_user} />
                  <SingleRecord title={text.CHANNEL} value={channel} />
                  <SingleRecord title={text.SUBJECT} value={data.subject} />
                  {channel === CHANNEL_TYPES.calls && <SingleRecord title={text.DURATION} value={data.duration} />}
                  {channel === CHANNEL_TYPES.calls && <SingleRecord title={text.CALL_TYPE} value={data.type} />}
                  {channel === CHANNEL_TYPES.calls && <SingleRecord title={text.CALL_RECORDING} value={data.recording_file} download />}
                </div>
                <div className="column">
                  {channel === CHANNEL_TYPES.calls && <SingleRecord title={text.PHONE_NUMBER} value={data.destination_phone_num} />}
                  {channel !== CHANNEL_TYPES.calls && <SingleRecord title={text.DATE} value={dateTimeFormatter(data.date)} />}
                  {channel === CHANNEL_TYPES.calls && <SingleRecord title={text.DATE} value={dateTimeFormatter(data.start_time, false)} />}
                  {channel === CHANNEL_TYPES.calls && <SingleRecord title={text.START_TIME} value={moment(data.start_time).format('HH:mm:ss')} />}
                  {channel === CHANNEL_TYPES.calls && <SingleRecord title={text.END_TIME} value={moment(data.end_time).format('HH:mm:ss')} />}
                  <SingleRecord title={channel === CHANNEL_TYPES.calls ? text.ID : text.MESSAGE_ID} value={channel === CHANNEL_TYPES.calls ? data.id : data.message_id} />
                  <SingleRecord title={text.MESSAGE_TYPE} value={data.message_type} />
                  <SingleRecord title={text.STATUS} value={data.status} />
                </div>
              </div>
              <Tabs>
                <Tab title={channel === CHANNEL_TYPES.sms ? text.SMS_TEXT : text.MESSAGE}>
                  {channel !== CHANNEL_TYPES.chat && channel !== CHANNEL_TYPES.calls && <Code>{channel === CHANNEL_TYPES.sms ? data.text : data.body_plain}</Code>}
                  {channel === CHANNEL_TYPES.chat && (
                    <a className="text-primary-400" href={data.subject} target="_blank" rel="noopener noreferrer">
                      {text.CHAT_CONTENT}
                    </a>
                  )}
                </Tab>
                <Tab 
                  title={text.HTML}
                  visible={channel !== CHANNEL_TYPES.calls}
                >
                  <iframe
                    title="iframeLogsMessage"
                    src={iframeContent}
                    frameBorder="0"
                    height="400px"
                    width="100%"
                  />
                </Tab>
              </Tabs>
            </div>
          )}
          <EditLogsRecord onSuccess={this.loadData} />
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { data, isLoading } = pages.LOGS_MESSAGE;
  return { data, isLoading };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loadData: logsMessageActions.loadData,
      deleteRecord: logsMessageActions.deleteRecord,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(LogsMessage);
