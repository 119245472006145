
import { bindActionCreators, compose } from 'redux';

import EmailAlerts from './EmailAlerts';
import connect from '../../../../logic/connect';
import overlayActions from '../../../../components/overlay';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

const EmailAlertsPage = compose(
  connect(null, mapDispatchToProps),
  withErrorBoundary,
)(EmailAlerts); 

export default {
  component: EmailAlertsPage,
  aclId: 'emails.notifications.list',
  path: appRoutes.SETTINGS_EMAIL_ALERTS,
};
