export default action => ({
  UPDATE_DATA: `${action}_UPDATE_DATA`,
  RESET_FIELDS: `${action}_RESET_FIELDS`,
  SHOULD_REMOVE_TAGS: `${action}_SHOULD_REMOVE_TAGS`,
  SUBMIT: `${action}_SUBMIT`,
  SUBMIT_PENDING: `${action}_SUBMIT_PENDING`,
  SUBMIT_FULFILLED: `${action}_SUBMIT_FULFILLED`,
  SUBMIT_REJECTED: `${action}_SUBMIT_REJECTED`,
  RESET_SUBMIT: `${action}_RESET_SUBMIT`,
  LOAD_OPTIONS: `${action}_LOAD_OPTIONS`,
  LOAD_OPTIONS_PENDING: `${action}_LOAD_OPTIONS_PENDING`,
  LOAD_OPTIONS_FULFILLED: `${action}_LOAD_OPTIONS_FULFILLED`,
});
