/**
 * I hope, such a storrage getters its much better to use because of couple of reasons
 * - less code when we need to retreive same values in different places
 * - easer to do refactoring (in case of storage structure changes) 
 */

export const getPages = state => state.pages;

export const getUserSinglePage = state => getPages(state).USER_SINGLE;
export const getUserDetails = state => getUserSinglePage(state).userDetail.user;
export const getUserSinglePageActions = state => getUserSinglePage(state).actions;
export const getUserTradingAccount = state => getUserSinglePageActions(state).TRADING_ACCOUNT;

export const getClientsPage = state => getPages(state).CLIENTS;
export const getClientsTable = state => getClientsPage(state).tables.CLIENTS_TABLE;
export const getClientsTableQuery = state => getClientsTable(state).query;
export const getClientsTableOrdering = state => getClientsTableQuery(state).ordering;
export const getClientsTablePagination = state => getClientsTable(state).pagination;
