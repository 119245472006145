import React, { Component, Fragment } from 'react';
import { ComboxFooter, notifier } from 'tc-biq-design-system';
import { bool, func, number, shape, string } from 'prop-types';

import { gettext } from '../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../logic/services/api-factory';
import to from '../../../logic/utilities/to';
import FormFactory from '../../form';
import ComboxWrapper from '../ComboxWrapper';


import {
  loadOptions,
  setContentTypes,
  setRequestDataValues,
} from '../sidepanels/CreateEditTask/CreateEditTaskHelper';

import './CreateTaskForm.scss';

const text = {
  TEXT: gettext('Text'),
  CREATE: gettext('Create'),
  NAME: gettext('Task name'),
  DESCRIPTION: gettext('Task description'),
  ASSIGN_TO: gettext('Assign To'),
  SUCCESS: gettext('Task created successfully'),
  ERROR_GENERAL: gettext('Something went wrong!'),
  CHOOSE_DATE: gettext('Choose Date'),
};
const api = getDjangoApi('tasks');

const formModifiers = {
  name: {
    label: null,
    placeholder: text.NAME,
  },
  due_date: {
    label: null,
  },
};

const assignToField = {
  id: 'performer',
  name: 'performer',
  required: false,
  type: 'search',
  placeholder: text.PLACEHOLDER,
  async: true,
  debounceInterval: 500,
  loadOptions,
  group: true,
  valueKey: 'id',
  labelKey: 'label',
  dataKeys: ['username', 'name'],
};

const description = {
  type: 'textarea',
  id: 'description',
  name: 'description',
  highlight: true,
  placeholder: text.DESCRIPTION,
};

const propTypes = {
  submitInProgress: bool.isRequired,
  formKey: string.isRequired,
  createTask: func.isRequired,
  hideFooter: bool,
  getSubmit: func,
  onSuccess: func,
  user: shape({ id: number }).isRequired,
  shouldResetValuesOnSuccess: bool,
  disableResetOnUnmount: bool,
};

const defaultProps = {
  hideFooter: false,
  getSubmit: null,
  onSuccess: null,
  shouldResetValuesOnSuccess: true,
  disableResetOnUnmount: false,
};

class CreateTaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
    };

    this.doSubmit = this.doSubmit.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  componentDidMount() {
    const { getSubmit } = this.props;
    if (getSubmit) {
      getSubmit(this.doSubmit);
    }
  }

  onClickSubmit() {
    const { isDisabled } = this.state;
    if (isDisabled) return;

    this.setDisabled(true, this.doSubmit);
  }

  onSuccess() {
    const { onSuccess } = this.props;
    notifier.success(text.SUCCESS);
    onSuccess && onSuccess();
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors');
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data');
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  setDisabled(isDisabled, callback) {
    this.setState(() => ({ isDisabled }), () => callback && callback());
  }

  formConfig = () => {
    const { formKey, disableResetOnUnmount, shouldResetValuesOnSuccess } = this.props;
    return {
      disableResetOnUnmount,
      shouldResetValuesOnSuccess,
      form: formKey,
      api,
      excludeFields: [
        'performer_content_type',
        'performer_object_id',
        'all_day',
        'target_content_type',
        'target_object_id',
        'reminder',
      ],
      customFields: [assignToField, description],
    };
  };

  doSubmit = async () => {
    const { createTask, user, shouldResetValuesOnSuccess } = this.props;
    const res = await api.options();
    const options = res.data.actions.POST;
    const requestData = values => ({
      ...setRequestDataValues(values),
      ...setContentTypes(
        options,
        {
          target: user,
          target_object_id: user.id,
          performer: values.performer,
        },
        values.performer,
      ),
    });

    const [err] = await to(createTask(api, requestData, shouldResetValuesOnSuccess));
    err ? this.onError(err) : this.onSuccess();
    this.setDisabled(false);
  };

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  };

  render() {
    const { submitInProgress, hideFooter, formKey } = this.props;
    const { isDisabled } = this.state;
    const Form = this.renderForm();

    return (
      <div className="biq-create-task">
        <ComboxWrapper>
          <Form
            formId={formKey}
            modifiers={formModifiers}
            renderForm={formFields => (
              <Fragment>
                <div className="biq-create-task__section">
                  <span className="biq-create-task__label">{text.ASSIGN_TO}</span>
                  <div className="biq-create-task__value">{formFields.performer}</div>
                </div>
                <div className="biq-create-task__section">
                  <span className="biq-create-task__label">{text.CHOOSE_DATE}</span>
                  <div className="biq-create-task__value">{formFields.due_date}</div>
                </div>
                {formFields.name}
                {formFields.description}
              </Fragment>
            )}
          />
          {!hideFooter && (
            <ComboxFooter
              onConfirm={this.onClickSubmit}
              isLoading={submitInProgress}
              isDisabled={isDisabled}
              confirmText={text.CREATE}
              formId={formKey}
            />
          )}
        </ComboxWrapper>
      </div>
    );
  }
}

CreateTaskForm.propTypes = propTypes;
CreateTaskForm.defaultProps = defaultProps;

export default CreateTaskForm;
