import React, { Component } from 'react';
import { object, func, array, bool } from 'prop-types';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import connect from '../../../../../../logic/connect';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import FormFactory, { ErrorHandler, FormActionsFactory } from '../../../../../../components/form';
import overlayActions from '../../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../../components/common';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import to from '../../../../../../logic/utilities/to';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const propTypes = {
  onSuccess: func,
  actions: object.isRequired,
  sidepanel: object.isRequired,
  fields: array.isRequired,
  submitInProgress: bool,
};

const defaultProps = {
  onSuccess: null,
  submitInProgress: false,
};

const api = getDjangoApi('bonus/reasons');
export const SIDEPANEL_ID = 'ADD_EDIT_BONUS_REASON';

const text = {
  TITLE: gettext('Create reason'),
  TITLE_CREATE: gettext('Create reason'),
  TITLE_EDIT: gettext('Edit reason'),
  CREATE_BUTTON_LABELS: {
    confirm: gettext('Create the reason'),
    cancel: gettext('Discard'),
  },
  EDIT_BUTTON_LABELS: {
    confirm: gettext('Edit the reason'),
    cancel: gettext('Discard'),
  },
  SUCCESS_TEXT: gettext('Reason successfully saved'),
};

const customFooter = (execute, close, submitInProgress, type) => (
  <SidepanelFooter
    execute={execute}
    close={close}
    submitInProgress={submitInProgress}
    confirmColor="primary"
    buttonLabels={type === 'edit' ? text.EDIT_BUTTON_LABELS : text.CREATE_BUTTON_LABELS}
  />
);

class AddEditBonusReason extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.onClose = this.onClose.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidUpdate() {
    const { sidepanel } = this.props;
    const { parameters } = sidepanel;

    if (sidepanel.visible && parameters.type === 'edit') {
      this.actions.setRowData(sidepanel.parameters.data);
    }
  }

  componentWillUnmount() {
    this.onClose();
  }

  onClose(success) {
    const { onSuccess } = this.props;

    this.actions.resetFields();
    this.actions.closeSidepanel(SIDEPANEL_ID);
    if (success) onSuccess();
  }

  async submit() {
    const { fields, sidepanel } = this.props;
    const { parameters } = sidepanel;
    const { data } = parameters;
    const request = data && data.id ? this.actions.update(api, data.id) : this.actions.create(api);
    const [err] = await to(request);
    if (err) {
      new ErrorHandler({ errors: err.data, fields }).showMessages(notifier.error);
    } else {
      notifier.success(text.SUCCESS_TEXT);
      this.onClose(true);
    }
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory({ form: SIDEPANEL_ID, api });
    }
    const { FormComponent } = this;
    return <FormComponent />;
  }

  render() {
    const { sidepanel, submitInProgress } = this.props;

    return (
      <Sidepanel
        title={sidepanel.visible && sidepanel.parameters.type === 'edit' ? text.TITLE_EDIT : text.TITLE_CREATE}
        visible={sidepanel.visible}
        onCloseIconClick={() => this.onClose()}
        footerRender={() => customFooter(this.submit, this.onClose, submitInProgress, sidepanel.parameters.type)}
      >
        { this.renderForm() }
      </Sidepanel>
    );
  }
}

AddEditBonusReason.propTypes = propTypes;
AddEditBonusReason.defaultProps = defaultProps;

const mapStateToProps = ({ overlays, forms }) => ({
  ...forms[SIDEPANEL_ID],
  sidepanel: overlays[SIDEPANEL_ID],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    ...FormActionsFactory(SIDEPANEL_ID), 
    closeSidepanel: overlayActions.close, 
  }, dispatch),
});

export default connect(
  mapStateToProps, 
  mapDispatchToProps,
)(withErrorBoundary(AddEditBonusReason));
