import React, { Component } from 'react';
import { Button, Tabs, Tab } from 'tc-biq-design-system';
import { object } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import Page from '../../../../../components/Page';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../logic/services/acl';
import CommunicationFlow from './CommunicationFlow';
import AutoDialer from './AutoDialer';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const tabs = {
  COMMUNICATION_FLOW: 'communication-flow',
  AUTO_DIALER: 'auto-dialer',
};

const text = {
  TITLE: gettext('Communication flow'),
  CREATE_NEW: gettext('Create new flow'),
  TAB_TITLE: {
    [tabs.COMMUNICATION_FLOW]: gettext('Communication flow'),
    [tabs.AUTO_DIALER]: gettext('Auto dialer'),
  },
};

const bread = [{ label: text.TITLE, route: appRoutes.SETTINGS_SALES_STATUSES_AND_SUBSTATUSES }];

const propTypes = {
  match: object.isRequired,
};

class SalesStatusesAndSubstatuses extends Component {
  constructor(props) {
    super(props);

    this.tab = props.match.params.tab;
    const tabsValues = Object.values(tabs);
    const active = tabsValues.includes(this.tab) ? this.tab : tabs.COMMUNICATION_FLOW;
    this.initialTab = tabsValues.indexOf(active);
    this.state = {
      active,
    };
  }

  setActiveTab = (active) => {
    this.setState({ active });
  }

  headerActions = () => {
    const { active } = this.state;
    return ({
      [tabs.COMMUNICATION_FLOW]: hasAccess('communication_flow.create') && (
        <Link to={generatePath(appRoutes.SETTINGS_SALES_STATUSES_AND_SUBSTATUSES_FORM, {
          tab: 'communication-flow',
          mode: 'add',
        })}
        >
          <Button startPropagation>
            {text.CREATE_NEW}
          </Button>
        </Link>
      ),
      [tabs.AUTO_DIALER]: hasAccess('autodialer_settings.create') && (
        <Link to={generatePath(appRoutes.SETTINGS_SALES_STATUSES_AND_SUBSTATUSES_FORM, {
          tab: 'auto-dialer',
          mode: 'add',
        })} 
        >
          <Button startPropagation>
            {text.CREATE_NEW}
          </Button>
        </Link>
      ),
    })[active];
  }

  render() {
    const { active } = this.state;
    return (
      <Page 
        title={text.TITLE} 
        bread={bread} 
        headerActions={this.headerActions}
        style={{}}
      >
        <Tabs active={this.initialTab}>
          <Tab title={text.TAB_TITLE[tabs.COMMUNICATION_FLOW]} onLoad={() => this.setActiveTab(tabs.COMMUNICATION_FLOW)}>
            <CommunicationFlow active={active} />
          </Tab>
          <Tab title={text.TAB_TITLE[tabs.AUTO_DIALER]} onLoad={() => this.setActiveTab(tabs.AUTO_DIALER)}>
            <AutoDialer active={active} />
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

SalesStatusesAndSubstatuses.propTypes = propTypes;

export default SalesStatusesAndSubstatuses;
