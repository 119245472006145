/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { Row, Col, Space, Button, notifier } from 'tc-biq-design-system';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import Page from '../../../../../components/Page';
import FormFactory from '../../../../../components/form';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { hasAccess } from '../../../../../logic/services/acl';
import to from '../../../../../logic/utilities/to';
import LayoutCard from './LayoutCard';
import AvailableTo from './AvailableTo';
import EditSectionSidepanel, { SIDEPANEL_ID } from './sidepanels/EditSectionName';
import appRoutes from '../../../../../components/App/Router/appRoutes';

import './ClientProfileLayout.scss';

export const FORM_KEY = 'CLIENT_PROFILE_LAYOUT_FORM';

const clientLayoutProfileApi = getDjangoApi('settings/layout/profile');

const text = {
  CUSTOMIZATION_PAGE_TITLE: gettext('Customization'),
  LAYOUTS_PAGE_TITLE: gettext('Client profile layouts'),
  PAGE_TITLE: gettext('Client profile layout'),
  NAME: gettext('Name'),
  DISCARD_CHANGES: gettext('Discard changes'),
  SAVE_LAYOUT: gettext('Save layout'),
  CREATE_LAYOUT: gettext('Create layout'),
  GENERAL_ERROR: gettext('Something went wrong'),
  OTHER: gettext('Other'),
  PERSONAL_INFO: gettext('Personal info'),
  PROFILE_DETAILS: gettext('Profile details'),
  ADDRESS: gettext('Address'),
};

const sectionTitles = {
  address: text.ADDRESS,
  personal_info: text.PERSONAL_INFO,
  personal_details: text.PROFILE_DETAILS,
  other: text.OTHER,
};

const routes = {
  CUSTOMIZATION: appRoutes.SETTINGS_CUSTOMIZATION,
  LAYOUTS: appRoutes.SETTINGS_CUSTOMIZATION_PROFILES,
  NEW_LAYOUT: appRoutes.SETTINGS_CUSTOMIZATION_PROFILE_CREATE,
};

const bread = [
  { label: text.CUSTOMIZATION_PAGE_TITLE, route: routes.CUSTOMIZATION },
  { label: text.LAYOUTS_PAGE_TITLE, route: routes.LAYOUTS },
  { label: text.PAGE_TITLE, route: routes.NEW_LAYOUT },
];

const SortableContainer = sortableContainer(({ children }) => <Row>{children}</Row>);

const SortableElement = sortableElement(props => (
  <Col sm="1/4">
    <LayoutCard
      {...props} 
    />
  </Col>
));

class ClientProfileLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileLayout: {},
    };

    this.id = props.match.params.id;
    this.isEdit = !!this.id;
  }
  
  componentDidMount() {
    const { actions } = this.props;
    
    Promise.all([
      actions.fetchDefaultLayout(),
      ...(this.isEdit ? [actions.fetchProfileLayout(this.id)] : []),
    ]).then(() => {
      const { defaultLayout, profileLayout } = this.props;
      
      if (profileLayout) {
        const savedLayout = profileLayout.layout;
        const mergedLayout = this.mergeDefaultLayout(savedLayout);
        this.setLayout(mergedLayout);
        this.setField('name', profileLayout.name);
        actions.assignToUsers(profileLayout.available_to_users);
        actions.assignToTeams(profileLayout.available_to_teams);
        this.setState({
          profileLayout,
        });
      } else {
        const layout = this.generateLayout(defaultLayout);
        this.setLayout(layout);
      }
    });
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.resetLayout();
  }

  onSortEndSection = ({ newIndex, oldIndex }) => {
    const { layout } = this.props;
    const newLayout = this.moveArray([...layout], newIndex, oldIndex);
    this.setLayout(newLayout);
  }

  onSortEndList = ({ newIndex, oldIndex }, sectionIndex) => {
    const { layout } = this.props;
    const newLayout = layout.map((section, index) => {
      if (index !== sectionIndex) return section;
      const fields = this.moveArray([...section.fields], newIndex, oldIndex);
      return { ...section, fields };
    });
    this.setLayout(newLayout);
  }
  
  onToggleSection = (sectionIndex) => {
    const { layout } = this.props;
    const newLayout = layout.map((section, index) => {
      if (index !== sectionIndex) return section;
      return { ...section, isActive: !section.isActive };
    });
    this.setLayout(newLayout);
  }

  onToggleItem = (itemIndex, sectionIndex) => {
    const { layout } = this.props;
    const newLayout = layout.map((section, currentIndex) => {
      if (currentIndex !== sectionIndex) return section;
      const fields = section.fields.map((field, currentItemIndex) => ({
        ...field,
        ...(currentItemIndex === itemIndex && { isActive: !field.isActive }),
      })); 
      return { ...section, fields };
    });
    this.setLayout(newLayout);
  }

  onEditSection = (sectionIndex) => {
    const { actions } = this.props;
    const { layout } = this.props;
    const name = layout[sectionIndex].title;
    actions.openSidepanel(SIDEPANEL_ID, { sectionIndex, name });
  }

  onUpdateSectionName = (sectionIndex, name) => {
    const { layout } = this.props;
    const newLayout = layout.map((section, index) => {
      if (sectionIndex !== index) return section;

      return { ...section, title: name };
    });
    this.setLayout(newLayout);
  }

  onUsersChange = (users) => {
    const { actions } = this.props;
    actions.assignToUsers(users);
  }

  onTeamsChange = (teams) => {
    const { actions } = this.props;
    actions.assignToTeams(teams);
  }

  onSubmit = async () => {
    const getFormParams = values => values;
    const { actions, selectedUsers, selectedTeams } = this.props;
    const action = () => (this.id ? actions.update(clientLayoutProfileApi, this.id, getFormParams) : actions.create(clientLayoutProfileApi, getFormParams));
    const [formErr, formData] = await to(action());
    const layout = _.get(formData, 'action.payload.response');
    const [assignErr] = await to(actions.submitAssign(layout, selectedUsers, selectedTeams));
    const err = formErr || assignErr;
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    const { actions } = this.props;
    actions.resetLayout();
    this.onRedirect();
  }

  onError({ data }) {
    const { non_field_errors, messages } = data;
    if (non_field_errors) {
      notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
    } else if (messages) {
      notifier.error(data.messages.map(err => <span>{err.text}</span>));
    } else {
      notifier.error(text.GENERAL_ERROR);
    }
  }

  onRedirect = () => {
    const { history } = this.props;
    history.push(routes.LAYOUTS);
  }

  setLayout = (layout) => {
    const { actions } = this.props;
    actions.setLayout(layout);
    this.setField('layout', layout);
  }

  setField = (id, value) => {
    const { actions } = this.props;
    actions.setFieldValue({
      id,
      value,
    });
  }

  generateLayout = (defaultLayout) => {
    const sectionKeys = Object.keys(defaultLayout);
    
    return sectionKeys.map((sectionKey) => {
      const fields = defaultLayout[sectionKey];
      const title = sectionTitles[sectionKey] || sectionKey;
      return {
        title,
        isActive: true,
        fields: fields.map(field => ({ ...field, isActive: true })),
      };
    });
  };

  mergeDefaultLayout = (layout) => {
    const { defaultLayout } = this.props;
    
    const hashedSections = Object.keys(defaultLayout)
      .map(sectionKey => defaultLayout[sectionKey].reduce((acc, field) => ({ ...acc, [field.key]: field }), {}));
    return layout.map((section, index) => {
      const { fields } = section;
      const hashedFields = hashedSections[index];
      fields.forEach(({ key }) => {
        if (hashedFields[key]) delete hashedFields[key];
      });

      const remainingFields = Object.keys(hashedFields).map(key => ({ ...hashedFields[key], isActive: false }));
      return { 
        ...section,
        fields: [...fields, ...remainingFields],
      };
    });
  }

  moveArray = (arr, newIndex, oldIndex) => {
    const cutOut = arr.splice(oldIndex, 1)[0];
    arr.splice(newIndex, 0, cutOut);
    return arr;
  }

  formConfig = () => ({
    form: FORM_KEY,
    customFields: [{
      type: 'text',
      id: 'name',
      name: 'name',
      label: text.NAME,
    }, {
      type: 'text',
      id: 'layout',
      name: 'layout',
    }],
  })

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { layout, submitInProgress, selectedUsers, selectedTeams } = this.props;
    const { profileLayout: { available_to_users, available_to_teams } } = this.state;
    const Form = this.renderForm();

    return (
      <Page bread={bread} title={text.PAGE_TITLE}>
        <Space size={20} />
        <div className="biq-profile-layout">
          <Form 
            formId={FORM_KEY} 
            renderForm={formFields => (
              <Fragment>
                <Row>
                  <Col sm="1/3">
                    {formFields.name}
                  </Col>
                  <Col sm="1/3">
                    <AvailableTo 
                      type="users"
                      onChange={this.onUsersChange}
                      defaultValue={available_to_users || []}
                      value={selectedUsers}
                      disabled={!hasAccess('layout.profile.assign_to_users.*')}
                    />
                  </Col>
                  <Col sm="1/3">
                    <AvailableTo 
                      type="teams" 
                      onChange={this.onTeamsChange} 
                      defaultValue={available_to_teams || []}
                      value={selectedTeams}
                      disabled={!hasAccess('layout.profile.assign_to_teams.*')}
                    />
                  </Col>
                </Row>
                <SortableContainer 
                  axis="x" 
                  lockAxis="x" 
                  onSortEnd={this.onSortEndSection} 
                  useDragHandle
                >
                  {layout.map((section, index) => (
                    <SortableElement
                      key={`section-${section.title}`}
                      onListSortEnd={this.onSortEndList}
                      onToggleSection={this.onToggleSection}
                      onToggleItem={this.onToggleItem}
                      sortIndex={index}
                      index={index}
                      onEdit={this.onEditSection} 
                      {...section}
                    />
                  ))}
                </SortableContainer>
                <div className="biq-profile-layout__buttons">
                  <Button 
                    loading={submitInProgress} 
                    onClick={this.onRedirect} 
                    color="ghost"
                  >
                    {text.DISCARD_CHANGES}
                  </Button>
                  <Button 
                    loading={submitInProgress} 
                    onClick={this.onSubmit}
                    type="submit"
                  >
                    {this.isEdit ? text.SAVE_LAYOUT : text.CREATE_LAYOUT}
                  </Button>
                </div>
              </Fragment>
            )} 
          />
        </div>
        <EditSectionSidepanel onSuccess={this.onUpdateSectionName} />
      </Page>
    );
  }
}

export default ClientProfileLayout;
