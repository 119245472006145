import React, { Component } from 'react';
import { Sidepanel, notifier, Input } from 'tc-biq-design-system';
import { object, func, string } from 'prop-types';
import { bindActionCreators } from 'redux';
import { handleActions, createAction } from 'redux-actions';

import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import overlayActions from '../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../components/common';

const propTypes = {
  api: string.isRequired,
  state: object.isRequired,
  onSuccess: func.isRequired,
  sidepanel: object.isRequired,
  actions: object.isRequired,
};

const actionTypes = {
  UPDATE_REASON_VALUE: 'UPDATE_REASON_VALUE',
  RESET_STATE: 'RESET_STATE',
  SUBMIT: 'SUBMIT',
  SUBMIT_PENDING: 'SUBMIT_PENDING',
  SUBMIT_REJECTED: 'SUBMIT_REJECTED',
  SUBMIT_FULFILLED: 'SUBMIT_FULFILLED',
};

const initialState = {
  status: '',
  submitInProgress: false,
  errors: {},
};

const saveReason = (reason, action, api) => {
  const data = { name: reason.value };
  return action === 'add' ? api.create(data) : api.update(reason.id, data);
};

const updateReason = createAction(actionTypes.UPDATE_REASON_VALUE, value => value);
const resetState = createAction(actionTypes.RESET_STATE);
const submit = createAction(actionTypes.SUBMIT, saveReason);

const onError = (state, { payload }) => ({ 
  ...state, 
  submitInProgress: false, 
  errors: payload.data, 
});

const onSuccess = state => ({ ...state, submitInProgress: false, errors: {} });

export const reducer = handleActions({
  [actionTypes.UPDATE_REASON_VALUE]: (state, { payload }) => ({ ...state, reason: payload }),
  [actionTypes.RESET_STATE]: () => ({ ...initialState }),
  [actionTypes.SUBMIT_PENDING]: state => ({ ...state, submitInProgress: true }),
  [actionTypes.SUBMIT_REJECTED]: onError, 
  [actionTypes.SUBMIT_FULFILLED]: onSuccess, 
}, initialState);

const text = {
  TITLE: gettext('Reject reason'),
  TITLE_CREATE: gettext('Create withdrawal reject reason'),
  TITLE_EDIT: gettext('Edit withdrawal reject reason'),
  INPUT_LABEL: gettext('Withdrawal reject reason'),
  CREATE_BUTTON_LABELS: {
    confirm: gettext('Save reason'),
    cancel: gettext('Discard'),
  },
  EDIT_BUTTON_LABELS: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
  SUCCESS: gettext('Reason successfully saved'),
  ERROR: gettext('Error while saving reason'),
};

const customFooter = (execute, close, submitInProgress, type) => (
  <SidepanelFooter
    execute={execute}
    close={close}
    submitInProgress={submitInProgress}
    confirmColor="primary"
    buttonLabels={type === 'edit' ? text.EDIT_BUTTON_LABELS : text.CREATE_BUTTON_LABELS}
  />
);
class AddEditReason extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.submit = this.submit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.updateReason = this.updateReason.bind(this);
    this.api = getDjangoApi(props.api);
  }

  componentDidMount() {
    const { sidepanel } = this.props;
    const { parameters } = sidepanel;
    if (parameters.type === 'edit') this.actions.updateReason(parameters.data.name);
  }

  onClose(submitSuccess) {
    const { onSuccess } = this.props;
    this.actions.resetState();
    this.actions.closeSidepanel('ADD_EDIT_REASON');
    if (submitSuccess) onSuccess();
  }

  updateReason(event) {
    const { target } = event;
    this.actions.updateReason(target.value);
  } 

  submit() {
    const { state, sidepanel } = this.props;
    const { data, type } = sidepanel.parameters;

    const request = { value: state.reason };
    if (type === 'edit') request.id = data.id;

    this.actions.submit(request, type, this.api)
      .then(() => {
        notifier.success(text.SUCCESS);
        this.onClose(true);
      }, () => {
        notifier.error(text.ERROR);
      });
  }

  render() {
    const { sidepanel, state } = this.props;
    const { errors, submitInProgress, reason } = state;
    const { type } = sidepanel.parameters;

    return (
      <Sidepanel
        icon="Pen"
        title={type === 'edit' ? text.TITLE_EDIT : text.TITLE_CREATE}
        visible={sidepanel.visible}
        onCloseIconClick={() => this.onClose()}
        footerRender={() => customFooter(this.submit, this.onClose, submitInProgress, sidepanel.parameters.type)}
      >
        <Input 
          hasError={!!errors.name}
          helpText={errors.name ? errors.name[0] : null}
          label={text.INPUT_LABEL}
          placeholder={text.INPUT_LABEL} 
          value={reason} 
          onChange={this.updateReason} 
        />
      </Sidepanel>
    );
  }
}

AddEditReason.propTypes = propTypes;

export const mapStateToProps = ({ overlays, pages }) => ({
  state: pages.WITHDRAWALS.actions.ADD_EDIT_REJECT_REASON,
  sidepanel: overlays.ADD_EDIT_REASON,
});

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { 
      closeSidepanel: overlayActions.close, 
      updateReason, 
      submit,
      resetState,
    },
    dispatch,
  ),
});

export default AddEditReason;
