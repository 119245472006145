import activeClients from './ActiveClients';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../../logic/services/acl';

export default {
  ...activeClients,
  key: 'active-clients',
  label: gettext('Active clients'),
  isVisible: () => hasAccess('dashboard.users.*'),
};
