import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { object, number, func, shape } from 'prop-types';
import className from 'classnames';
import { notifier } from 'tc-biq-design-system';
import { withRouter, generatePath } from 'react-router-dom';

import connect from '../../../../../../logic/connect';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import overlayActions from '../../../../../../components/overlay';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';
import appRoutes from '../../../../../../components/App/Router/appRoutes';


const ACTION_TYPES = {
  tc_payments_paymenttransaction: {
    URL: 'payment_transactions',
    SIDEPANEL_ID: 'TRANSACTION_SINGLE',
  },
  payment_withdrawrequest: {
    URL: 'withdrawal_history',
    SIDEPANEL_ID: 'WITHDRAWAL_REQUEST',
  },
  tpi_tradingaccount: {
    URL: 'trading_account_overview',
    SIDEPANEL_ID: 'TRADING_ACCOUNT',
  },
  accounts_userdocument: {
    URL: 'documents',
    SIDEPANEL_ID: 'PREVIEW_DOCUMENT',
  },
  bo_tasks_task: {
    URL: 'tasks',
    SIDEPANEL_ID: 'VIEW_TASK',
  },
  auth_user: {
    URL: 'users',
    SIDEPANEL_ID: 'USER_PREVIEW',
    fetchData: false,
  },
  auth_bo_user: {
    URL: 'team/profile',
    SIDEPANEL_ID: 'BIQ_USER_PREVIEW',
    fetchData: false,
  },
};


const propTypes = {
  activityTypeObject: object,
  actions: object.isRequired,
  targetId: number.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const defaultProps = {
  activityTypeObject: {},
};

class ActionLinkObject extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.executor = { payment_withdrawrequest: this.executeWithdrawals };
  }

  onLinkClick = async () => {
    const { activityTypeObject } = this.props;
    if (!activityTypeObject && !ACTION_TYPES[activityTypeObject.objectType]) return null;
    const activityType = ACTION_TYPES[activityTypeObject.objectType];
    if (!activityType) return null;
    const execute = this.executor[activityTypeObject.objectType];

    return execute ? execute() : this.openSidepanel();
  }

  executeWithdrawals = async () => {
    const { activityTypeObject, history } = this.props;
    const activityType = ACTION_TYPES[activityTypeObject.objectType];
    const [err, res] = await to(this.fetchSidepanelData());
    
    if (err) {
      const errDetail = _.get(err, 'data.detail');
      if (err.status === 404) {
        history.push(generatePath(appRoutes.PENDING_WITHDRAWAL, { id: activityTypeObject.id }));
      } else if (errDetail) {
        notifier.error(errDetail);
      }
    } else {
      this.actions.openSidepanel(activityType.SIDEPANEL_ID, res);
    }
  }

  openSidepanel = async () => {
    const { activityTypeObject } = this.props;
    const activityType = ACTION_TYPES[activityTypeObject.objectType];
    
    const [err, res] = await to(this.fetchSidepanelData());

    if (_.get(err, 'data.detail')) notifier.error(err.data.detail);

    this.actions.openSidepanel(activityType.SIDEPANEL_ID, res);
  }

  formatSidepanelData = (res) => {
    const { activityTypeObject, targetId } = this.props;
    const activityType = ACTION_TYPES[activityTypeObject.objectType];

    switch (activityType.SIDEPANEL_ID) {
      case ACTION_TYPES.accounts_userdocument.SIDEPANEL_ID:
        return {
          document: {
            id: activityTypeObject.id,
            user: { id: targetId },
            file_name_backside: res.file_name_backside,
            file_name: res.file_name,
          },
          content: {
            title: activityTypeObject.displayName,
            icon: 'View',
          },
          action: 'preview',
        };
      case ACTION_TYPES.auth_user.SIDEPANEL_ID: 
      case ACTION_TYPES.auth_bo_user.SIDEPANEL_ID: 
        return { data: res };
      default:
        return res;
    }
  }

  fetchSidepanelData = async () => {
    const { activityTypeObject } = this.props;
    const { URL, fetchData } = ACTION_TYPES[activityTypeObject.objectType];
    if (fetchData === false) return this.formatSidepanelData({ id: activityTypeObject.id });
    const response = await getDjangoApi(URL).retrieve(activityTypeObject.id);
    return this.formatSidepanelData(response.data);
  }

  render() {
    const { activityTypeObject } = this.props;
    if (!activityTypeObject) return null;
    return (
      <span className={className({ 'like-a-link': !!ACTION_TYPES[activityTypeObject.objectType] })} onClick={this.onLinkClick}>{activityTypeObject.displayName}</span>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    openSidepanel: overlayActions.open,
  }, dispatch),
});

ActionLinkObject.propTypes = propTypes;
ActionLinkObject.defaultProps = defaultProps;

export default connect(null, mapDispatchToProps)(withErrorBoundary(withRouter(ActionLinkObject)));
