import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';
import AddToBlacklist from './AddToBlacklist';
import userActionsTypes from '../types';

const text = {
  LABEL: gettext('Add to Blacklist'),
};

const ACTION_ICON = 'Logs';

export default {
  name: 'addToBlacklist',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionsTypes.DROPDOWN,
  hasPermission(state, _, isIb) {
    const hasStatePermission = [
      USER_STATE.FULL,
      USER_STATE.LIMITED,
    ].includes(state);

    return (
      !isIb
      && hasStatePermission
      && isFeatureEnabled()('ADVANCED_PROFILE_STATE_TRANSITIONS')
      && hasAccess('user.add_to_blacklist.create')
    );
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(AddToBlacklist, sidepanelOptions);
  },
};
