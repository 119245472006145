import React, { Component } from 'react';
import { func, object } from 'prop-types';

import withFormSidepanel from '../../../../../components/FormSidepanel';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import session from '../../../../../logic/services/session';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import connect from '../../../../../logic/connect';

export const SIDEPANEL_ID = 'CAMPAIGN_FORM_SIDEPANEL';
export const FORM_ID = 'CAMPAIGN_FORM';

const apiUrl = uid => `team/profile/${uid}/campaigns/`;

const text = {
  CREATE: gettext('Create campaign'),
  EDIT: gettext('Update campaign'),
  SUCCESS: {
    create: gettext('Campaign successfully created'),
    edit: gettext('Campaign successfully updated'),
  },
  NAME: gettext('Name'),
  DESCRIPTION: gettext('Description'),
};

const propTypes = {
  onSuccess: func.isRequired,
  values: object.isRequired,
};

class CampaignSidepanel extends Component {
  constructor(props) {
    super(props);

    this.FormSidepanel = withFormSidepanel(FORM_ID, SIDEPANEL_ID);
  }

  getApi = ({ isEdit }) => {
    const { values } = this.props;
    const profileId = isEdit() ? _.get(values, 'user.id.id') : session.user.id;
    return getDjangoApi(apiUrl(profileId));
  }

  getFormConfig = () => ({
    excludeFields: ['user', 'active'],
  })

  render() {
    const { FormSidepanel, getApi, getFormConfig } = this;
    return (
      <FormSidepanel
        {...this.props}
        options={{
          text,
          getFormConfig,
          getApi,
        }}
      />
    );
  }
}

CampaignSidepanel.propTypes = propTypes;

const mapStateToProps = ({ forms }) => ({ ...forms[FORM_ID] });

export default connect(mapStateToProps)(CampaignSidepanel);
