import React, { Component, Fragment } from 'react';
import { func, arrayOf, object } from 'prop-types';
import { notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import connect from '../../../../../../logic/connect';
import { fetchRiskGroupOptions } from '../Model';
import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import modifiers from './modifiers';
import EditGroupPerGatewaySidepanel, { sidepanelID, api } from './EditPerGatewaySidepanel';
import to from '../../../../../../logic/utilities/to';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../logic/services/acl';

const RISK_GROUPS_PER_GATEWAY_TABLE = 'RISK_GROUPS_PER_GATEWAY_TABLE';
const RISK_RATING_SYSTEM = 'RISK_RATING_SYSTEM';
const text = {
  DELETE_MSG_SUCCESS: gettext('Risk group deleted successfully'),
};
const countryRisksConfig = () => ({
  reducerKey: RISK_RATING_SYSTEM,
  apiUrl: 'settings/risk_groups',
  tableKey: RISK_GROUPS_PER_GATEWAY_TABLE,
});

const defaultProps = {
  fields: null,
};

const propTypes = {
  openSidePanel: func.isRequired,
  closeSidePanel: func.isRequired,
  fields: arrayOf(object),
  dispatch: func.isRequired,
};

class RiskGroupPerGateway extends Component {
  constructor(props) {
    super(props);
    
    this.tableData = withTable(GridTab, countryRisksConfig, null, { withActions: true })();
    const { openSidePanel } = this.props;
    this.actions = {
      modify: hasAccess('risk_groups.settings.update') && ((data) => { openSidePanel(sidepanelID, data); }),
      remove: hasAccess('risk_groups.settings.destroy') && this.onRemove,
    };
  }

  onRemove = async ({ id }) => {
    const { dispatch } = this.props;

    const promise = api.destroy(id);
    const [error] = await to(promise);
  
    if (!error) {
      notifier.success(text.DELETE_MSG_SUCCESS);
      dispatch(this.tableData[1].fetchTableData());
    }
  }

  render() {
    const [RiskGroupPerGatewayTable, actions] = this.tableData;
    const { 
      closeSidePanel, 
      fields, 
    } = this.props;
    
    const gatewayChoices = fields.length && fields.filter(({ key }) => key === 'gateways')[0].choices;

    return (
      <Fragment>
        <RiskGroupPerGatewayTable
          singleActions={!!(this.actions.modify || this.actions.remove)}
          modifier={modifiers({ ...this.actions, gatewayChoices })}
        />
        <EditGroupPerGatewaySidepanel
          closeSidePanel={closeSidePanel}
          fetchTableData={actions.fetchTableData}
          gatewayChoices={gatewayChoices || []}
        />
      </Fragment>
    );
  } 
}

RiskGroupPerGateway.propTypes = propTypes;
RiskGroupPerGateway.defaultProps = defaultProps;

const mapStateToProps = ({ pages: { RISK_RATING_SYSTEM: { tables, general } } }) => ({
  fields: tables[RISK_GROUPS_PER_GATEWAY_TABLE].fields,
  riskGroupOptions: general.riskGroupOptions,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchRiskGroupOptions }, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(RiskGroupPerGateway);
