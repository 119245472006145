import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../../logic/services/acl';
import PendingWithdrawals from '../PendingWidthdrawals/PendingWithdrawals';

export default {
  ...PendingWithdrawals({ removeDateFilters: true }),
  key: 'pending-withdrawals-total',
  label: gettext('Pending withdrawals - total stock'),
  description: gettext('Pending withdrawals stock - regardless of origination date'),
  isVisible: () => hasAccess('dashboard.pending_withdrawals.*'),
  size: {
    w: 2,
    h: 5,
    minW: 2,
    minH: 5,
    maxW: 3,
    maxH: 5,
    y: 13,
    x: 2,
  },
};
