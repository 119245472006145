import hasNewDesign from './has-new-design';
import isFeatureEnabled from './is-feature-enabled';
import { environments } from '../enums/environments';
import  QUERY_OPERATORS from '../enums/query-operators';
import API_DATA_TYPE from '../enums/api-data-type';


const hasMinutesAgo = isFeatureEnabled()('MINUTES_AGO_OPERATOR');
const CONFIG = window.config;

export function operatorsForType(dataType) {
  switch (dataType) {
    case API_DATA_TYPE.STRING:
      const stringOperators = [
        QUERY_OPERATORS.EXACT,
        QUERY_OPERATORS.ICONTAINS,
        QUERY_OPERATORS.ISTARTSWITH,
        QUERY_OPERATORS.IENDSWITH,
        QUERY_OPERATORS.IN,
        QUERY_OPERATORS.NOT_EXACT,
        QUERY_OPERATORS.NOT_ICONTAINS,
        QUERY_OPERATORS.NOT_ISTARTSWITH,
        QUERY_OPERATORS.NOT_IENDSWITH,
        QUERY_OPERATORS.NOT_IN,
      ];
      if (CONFIG.environment === environments.AvaTrade) {
        stringOperators.splice(5, 0, QUERY_OPERATORS.ISNULL);
        stringOperators.splice(11, 0, QUERY_OPERATORS.NOT_ISNULL);
      }
      return stringOperators;
    case API_DATA_TYPE.FIELD:
    case API_DATA_TYPE.EMAIL:
      return [
        QUERY_OPERATORS.EXACT,
        QUERY_OPERATORS.ICONTAINS,
        QUERY_OPERATORS.ISTARTSWITH,
        QUERY_OPERATORS.IENDSWITH,
        QUERY_OPERATORS.IN,
        QUERY_OPERATORS.NOT_EXACT,
        QUERY_OPERATORS.NOT_ICONTAINS,
        QUERY_OPERATORS.NOT_ISTARTSWITH,
        QUERY_OPERATORS.NOT_IENDSWITH,
        QUERY_OPERATORS.NOT_IN,
        QUERY_OPERATORS.IS_EMPTY,
      ];
    case API_DATA_TYPE.DECIMAL:
    case API_DATA_TYPE.FLOAT:
    case API_DATA_TYPE.INTEGER:
      const numberOperator = [
        QUERY_OPERATORS.EXACT,
        QUERY_OPERATORS.GT,
        QUERY_OPERATORS.GTE,
        QUERY_OPERATORS.LT,
        QUERY_OPERATORS.LTE,
        QUERY_OPERATORS.RANGE,
        QUERY_OPERATORS.IN,
        QUERY_OPERATORS.NOT_IN,
        QUERY_OPERATORS.NOT_EXACT,
        QUERY_OPERATORS.NOT_RANGE,
      ];
      if (CONFIG.environment === environments.AvaTrade) {
        numberOperator.push(QUERY_OPERATORS.ISNULL) ;
      };
      return numberOperator;
    case API_DATA_TYPE.DATE:
    case API_DATA_TYPE.DATETIME:
      const operators = [
        QUERY_OPERATORS.EXACT,
        QUERY_OPERATORS.GT,
        QUERY_OPERATORS.GTE,
        QUERY_OPERATORS.LT,
        QUERY_OPERATORS.LTE,
        QUERY_OPERATORS.RANGE,
        ...(hasMinutesAgo ? [QUERY_OPERATORS.MINUTES_AGO] : []),
        QUERY_OPERATORS.NOT_EXACT,
        QUERY_OPERATORS.NOT_RANGE
      ];
      if (CONFIG.environment === environments.AvaTrade) {
        operators.splice(6, 0, QUERY_OPERATORS.MINUTES_AGO);
      }

      return operators;
    case API_DATA_TYPE.CHOICE:
    case API_DATA_TYPE.NESTED_RELATION:
    case API_DATA_TYPE.NESTED_OBJECT:
      return [
        QUERY_OPERATORS.EXACT,
        QUERY_OPERATORS.IN,
        QUERY_OPERATORS.NOT_IN,
        QUERY_OPERATORS.ISNULL,
        QUERY_OPERATORS.NOT_EXACT,
        QUERY_OPERATORS.NOT_ISNULL
      ];
    case API_DATA_TYPE.BOOLEAN:
      return [
        QUERY_OPERATORS.EXACT,
        QUERY_OPERATORS.NOT_EXACT
      ];
    case API_DATA_TYPE.NESTED_LIST:
      return [
        QUERY_OPERATORS.IN,
        QUERY_OPERATORS.NOT_IN
      ];
    case API_DATA_TYPE.LIST:
      return [
        QUERY_OPERATORS.ICONTAINS,
        QUERY_OPERATORS.NOT_ICONTAINS,
        QUERY_OPERATORS.ISNULL,
        QUERY_OPERATORS.NOT_ISNULL,
      ];
    case API_DATA_TYPE.TIMEAGO:
      return [  
        QUERY_OPERATORS.MINUTES_AGO,
      ];
    default:
      return [];
  }
}
