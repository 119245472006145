import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import IB_STATE from '../../../../../../../logic/enums/ib-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionTypes from '../types';
import IbChangeBackends from '../IbChangeBackends/IbChangeBackends';

const env = window.config.environment;

const text = {
  LABEL: gettext('Approve'),
};

const ACTION_ICON = 'Approve';

export default {
  name: 'approveIb',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    if (isIb) {
      return !user.is_archived 
        && hasAccess('ib.approve.*') 
        && state === IB_STATE.PENDING
        && env !== 'AvaTrade';
    } 
    return false;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(IbChangeBackends, sidepanelOptions);
  },
};
