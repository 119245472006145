import React, { Fragment, PureComponent } from 'react';
import { Icon, Panel, Table, Pill, Select, Space } from 'tc-biq-design-system';
import { object, func } from 'prop-types';
import { gettext } from '../../../../../../../../../logic/utilities/languageUtility';

import './Backend.scss';

const text = {
  BACKEND_TITLE: gettext('Select currency and group for backend'),
  GROUP_TITLE: gettext('Group'),
  CURRENCIES_TITLE: gettext('Currencies'),
  NONE: gettext('none'),
};

const renderStatus = (onClick, backend) => () => {
  const onClose = () => onClick(backend);
  
  return (
    <Icon 
      key={backend.id}
      name="Close" 
      colorName="text-brand-100" 
      onClick={onClose} 
    />
  );
};

const renderHeader = (title, server) => (
  <Fragment>
    <div>{text.BACKEND_TITLE}</div>
    <Pill>
      {`(${server}) - ${title}`}
    </Pill>
  </Fragment>
);


const renderGroup = (onChange, currency) => (groups) => {
  // eslint-disable-next-line react/destructuring-assignment
  const selected = groups.find(group => group.selected) || null;

  const onOptionChange = (option) => {
    onChange(option, currency);
  };

  return (
    <Select
      value={selected}
      clearable={false}
      onChange={onOptionChange}
      options={groups}
      labelKey="name"
    />
  );
};

const renderCurrencyGroup = onChange => currency => renderGroup(onChange, currency)(currency.groups);

const renderCurrencies = (currencies, onChange) => {
  const hasGroups = currencies.every(item => !!item.groups);

  const groupCol = { 
    title: text.GROUP_TITLE, 
    key: 'groups', 
    width: '200px',
    render: renderCurrencyGroup(onChange),
  };
  
  const cols = [
    {
      title: text.CURRENCIES_TITLE,
      key: 'name',
    },
    ...(hasGroups && [groupCol]),
  ];

  const indexedCurrencies = currencies.map(currency => ({ ...currency, id: currency.name }));

  return (
    <Fragment>
      <Table
        cols={cols} 
        data={indexedCurrencies} 
      />
    </Fragment>
  );
};

const propTypes = {
  onClose: func,
  onChange: func,
  backend: object,
};

const defaultProps = {
  onClose: null,
  onChange: null,
  backend: [],
};


class Backend extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(option, currency) {
    const { onChange, backend } = this.props;
    onChange(option, backend, currency);
  }

  render() {
    const { backend, onClose } = this.props;
    const { name, type, currencies, groups } = backend;
    
    return (
      <Panel title={renderHeader(name, type)} status={renderStatus(onClose, backend)}>
        <div className="biq-backend_table">
          { renderCurrencies(currencies, this.onChange) }
          {!!groups && <Space size={16} />}
          {!!groups && renderGroup(this.onChange)(groups) }
        </div>
      </Panel>
    );
  }
}

Backend.propTypes = propTypes;
Backend.defaultProps = defaultProps;

export default Backend;
