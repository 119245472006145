/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Button } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';

import connect from '../../../logic/connect';
import { gettext } from '../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../logic/services/api-factory';
import isFeatureEnabled from '../../../logic/filters/is-feature-enabled';
import GridFactory from '../../../components/grid';
import Page from '../../../components/Page';
import Export from '../../../components/Export';
import { getActiveFilters } from '../../../components/grid/GridUtils';
import Filters from '../../../components/Filters';
import overlayActions from '../../../components/overlay';
import modifier from './modifier';
import { UserPreview, TradingAccount } from '../../../components/common/sidepanels';
import { hasAccess } from '../../../logic/services/acl';
import appRoutes from '../../../components/App/Router/appRoutes';
import withErrorBoundary from '../../../components/hoc/withErrorBoundary';
import WhitelistForm, { SIDEPANEL_ID as WHITELIST_SIDEPANEL_ID } from './WhitelistForm';
import { toSingleItemUrl } from '../../../logic/utilities/toPath';

const pageConfig = {
  apiUrl: 'trading_account_overview',
  reducerKey: 'TRADING_ACCOUNTS',
  tableKey: 'TRADING_ACCOUNTS_TABLE',
};

const SIDEPANEL_ID = 'TRADING_ACCOUNT';
const { GridComponent, actions } = GridFactory(pageConfig);
const bread = [{ label: gettext('Trading accounts'), route: appRoutes.TRADING_ACCOUNTS }];

const text = {
  PAGE_TITLE: gettext('Trading accounts'),
};

class TradingAccounts extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    
    this.headerActions = this.headerActions.bind(this);

    this.tableActions = {
      previewUser: this.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
      openTradingAccount: this.openTradingAccountSidepanel.bind(this),
    };

    this.userId = props.match.params.userId;
    this.accountId = props.match.params.accountId;
  }

  componentDidMount() {
    if (this.userId && this.accountId) {
      this.openTradingAccountSidepanel({
        id: this.accountId,
        user: {
          id: this.userId,
        },
      });
    }
  }

  onWhitelistClick = () => {
    this.actions.openSidepanel(WHITELIST_SIDEPANEL_ID);
  }

  async openTradingAccountSidepanel({ id, user }) {
    const { actions } = this.props;
    const response = await getDjangoApi(`users/${user.id}/trading_accounts/${id}`).list();
    actions.openSidepanel(SIDEPANEL_ID, response.data);
    window.history.replaceState(null, '', toSingleItemUrl(id).replace(/trading-accounts.*/, `trading-accounts/${user.id}/account/${id}`));
  }

  headerActions() {
    const { exportUrl } = this.props;
    return (
      <Fragment>
        {isFeatureEnabled()('WHITELIST_TRADING_ACCOUNTS') && hasAccess('integration.iblackstone.whitelist.*') && (
          <Button color="ghost" onClick={this.onWhitelistClick}>{gettext('Whitelist')}</Button>
        )}
        {hasAccess('tpi.trading_accounts_overview.export.*') && <Export url={exportUrl} /> }
      </Fragment>
    );
  }

  render() {  
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions),
    };

    return (
      <Page bread={bread} headerActions={this.headerActions} title={text.PAGE_TITLE}>
        <Filters {...filterProps} />
        <GridComponent
          tableModifier={modifier(this.tableActions)}
          {...this.props}
        />
        <TradingAccount />
        <UserPreview />
        <WhitelistForm onSuccess={() => null} />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => { 
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return { 
    exportUrl: page.tables[tableKey].exportUrl,
    table,  
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default {
  component: compose(
    connect(mapStateToProps, mapDispatchToProps),
    withErrorBoundary,
    withRouter,
  )(TradingAccounts),
  path: appRoutes.TRADING_ACCOUNTS,
  aclId: 'tpi.trading_accounts_overview.list',
};
