import { currentLanguage, gettext } from '../../languageUtility';
import isCurrencyCode from '../../validators/isCurrencyCode';

export default (value, currency) => {
  const text = {
    NA: gettext('N/A'),
  };
  const amount = Number.parseFloat(value);
  if (isCurrencyCode(currency)) {
    return amount.toLocaleString(currentLanguage, { style: 'currency', currency });
  } 
  return `${currency || text.NA} ${amount.toFixed(2)}`;
};
