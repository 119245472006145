import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { object, func, bool } from 'prop-types';
import { Sidepanel, Space, notifier } from 'tc-biq-design-system';

import { gettext } from '../../../../../../logic/utilities/languageUtility';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import connect from '../../../../../../logic/connect';
import overlayActions from '../../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../../components/common';
import { fetchProfileLayout, resetLayout } from '../../ClientProfileLayout/Model';
import FormFactory, { FormActionsFactory } from '../../../../../../components/form';
import to from '../../../../../../logic/utilities/to';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const REDUCER_ID = 'CLIENT_PROFILE_LAYOUT';
export const SIDEPANEL_ID = 'DUPLICATE_PROFILE_LAYOUT_SIDEPANEL';
export const FORM_KEY = 'CLIENT_PROFILE_LAYOUT_FORM';

const { create, setFieldValue, showLoader } = FormActionsFactory(FORM_KEY);

const clientLayoutProfileApi = getDjangoApi('settings/layout/profile');

const text = {
  NAME: gettext('Name'),
  TITLE: gettext('Duplicate item'),
  BUTTON_LABELS: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
};

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
  onSuccess: func.isRequired,
  profileLayout: object,
  submitInProgress: bool,
};

const defaultProps = {
  profileLayout: null,
  submitInProgress: false,
};

const customFooter = (execute, close, submitInProgress) => (
  <SidepanelFooter
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    submitInProgress={submitInProgress}
    buttonLabels={text.BUTTON_LABELS}
  />
);

class DuplicateLayout extends PureComponent {
  componentDidUpdate(prevProps) {
    const { actions } = this.props;
    const prevId = _.get(prevProps, 'sidepanel.parameters.id');
    const id = _.get(this.props, 'sidepanel.parameters.id');

    if (prevId !== id && id) {
      actions.showLoader(true);
      actions.fetchProfileLayout(id).then(() => {
        const { profileLayout: { layout, name } } = this.props;
        this.setField('layout', layout);
        this.setField('name', `Copy ${name}`);
        actions.showLoader(false);
      });
    }
  }

  onSubmit = async () => {
    const { actions } = this.props;
    const getFormParams = values => values;
    const [formErr] = await to(actions.create(clientLayoutProfileApi, getFormParams));
    formErr ? this.onError(formErr) : this.onSuccess();
  }

  onSuccess() {
    const { onSuccess } = this.props;
    this.onClose();
    onSuccess && onSuccess();
  }

  onError({ data }) {
    const { non_field_errors, messages } = data;
    if (non_field_errors) {
      notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
    } else if (messages) {
      notifier.error(data.messages.map(err => <span>{err.text}</span>));
    } else {
      notifier.error(text.GENERAL_ERROR);
    }
  }

  onClose = () => {
    const { actions } = this.props;
    actions.closeSidepanel(SIDEPANEL_ID);
    actions.resetLayout();
  };

  onChange = (e) => {
    const name = e.target.value;
    const { actions, sidepanel: { parameters } } = this.props;
    actions.updateSidepanel(SIDEPANEL_ID, { ...parameters, name });
  }

  setField = (id, value) => {
    const { actions } = this.props;
    actions.setFieldValue({
      id,
      value,
    });
  }

  formConfig = () => ({
    form: FORM_KEY,
    customFields: [{
      type: 'text',
      id: 'name',
      name: 'name',
      label: text.NAME,
    }, {
      type: 'text',
      id: 'layout',
      name: 'layout',
    }],
  })

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { sidepanel, submitInProgress } = this.props;
    if (!sidepanel.visible) return null;
    const Form = this.renderForm();
    return (
      <Sidepanel
        icon="Duplicate"
        title={text.TITLE}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.onSubmit, this.onClose, submitInProgress)}
      >
        <Space size={16} />
        <Form renderForm={formFields => formFields.name} />
      </Sidepanel>
    );
  }
}

DuplicateLayout.propTypes = propTypes;
DuplicateLayout.defaultProps = defaultProps;

const mapStateToProps = ({ overlays, pages, forms }) => {
  const sidepanel = overlays[SIDEPANEL_ID];
  const { submitInProgress } = forms[FORM_KEY];
  const { profileLayout } = pages[REDUCER_ID];
  return {
    profileLayout,
    submitInProgress,
    sidepanel, 
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      updateSidepanel: overlayActions.update,
      fetchProfileLayout,
      resetLayout,
      create,
      showLoader,
      setFieldValue,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(DuplicateLayout));
