import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import SidepanelFooter from '../SidepanelFooter';
import FormFactory, { FormActionsFactory } from '../../../../../../components/form';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../logic/connect';
import withUnmountMethod from '../../../../../../components/hoc/withUnmountMethod';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';

const EDIT_TOTAL_RISK_DEPOSIT = 'EDIT_TOTAL_RISK_DEPOSIT';
const sidepanelID = EDIT_TOTAL_RISK_DEPOSIT;
const formID = EDIT_TOTAL_RISK_DEPOSIT;
const api = getDjangoApi('settings/total_deposit_risk_groups');

const formConfig = {
  form: formID,
  api,
};

const { update, setRowData, resetFields, create } = FormActionsFactory(formID);

const propTypes = {
  visible: bool.isRequired,
  closeSidePanel: func.isRequired,
  parameters: object,
  submitInProgess: bool,
  actions: object.isRequired,
  values: object.isRequired,
  fetchTableData: func.isRequired,
  dispatch: func.isRequired,
};

const defaultProps = {
  parameters: null,
  submitInProgess: false,
};

const text = {
  TITLE: gettext('Total risk'),
  EDIT_SUCCESS_MSG: gettext('Total deposit risk group edited successfully'),
  CREATE_SUCCESS_MSG: gettext('Total deposit risk group added successfully'),
};

class EditTotalRiskSidepanel extends Component {
  constructor(props) {
    super(props);

    this.FormComponent = null;
  }
  

  componentDidUpdate(prevProps) {
    const { actions: { setRowData }, parameters } = this.props;
    if (parameters && !prevProps.parameters) { setRowData(parameters); }
  }

  onSave = async () => {
    const { 
      values,
      actions: { update },
      parameters: { id },
      fetchTableData,
      dispatch } = this.props;
     
    const promise = update(api, id, values);
    const [error] = await to(promise);
  
    if (!error) {
      notifier.success(text.EDIT_SUCCESS_MSG);
      this.closeSidePanel();
      dispatch(fetchTableData());
    }
  }

  onCreate = async () => {
    const { 
      values,
      actions: { create },
      dispatch,
      fetchTableData, 
    } = this.props;

    const promise = create(api, { values });

    const [error] = await to(promise);
    if (!error) {
      notifier.success(text.CREATE_SUCCESS_MSG);
      this.closeSidePanel();
      dispatch(fetchTableData());
    }
  }

  closeSidePanel = () => {
    const { closeSidePanel } = this.props;
    closeSidePanel(sidepanelID);
  }

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    } 
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { visible, submitInProgess, parameters } = this.props;
    const Form = this.renderForm();
    return (
      <Sidepanel 
        title={text.TITLE}
        icon="Edit"
        visible={visible}
        onCloseIconClick={this.closeSidePanel}
        footerRender={() => (
          <SidepanelFooter 
            onCancel={this.closeSidePanel}
            onSave={parameters ? this.onSave : this.onCreate}
            submitInProgess={submitInProgess}
          />
        )} 
      >
        <Form />
      </Sidepanel>
    );
  }
}

EditTotalRiskSidepanel.propTypes = propTypes;
EditTotalRiskSidepanel.defaultProps = defaultProps;

const mapStateToProps = ({ overlays, forms }) => ({
  ...overlays[sidepanelID],
  ...forms[formID],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    update,
    setRowData,
    resetFields,
    create,
  }, dispatch),
  dispatch,
});

export { sidepanelID, api };
export default
withUnmountMethod(connect(mapStateToProps, mapDispatchToProps)(EditTotalRiskSidepanel));
