import React, { Component } from 'react';
import { Row, Col, Space } from 'tc-biq-design-system';
import { object, array, string, func, number, bool } from 'prop-types';

import SalesStatuses from '../SalesStatuses';
import LoadMore from '../../../../../../components/Page/LoadMore';

const propTypes = {
  actions: object.isRequired,
  flows: array.isRequired,
  active: string.isRequired,
  hasPermission: func.isRequired,
  api: object.isRequired,
  currentPage: number.isRequired,
  loaded: bool.isRequired,
};

class CommunicationFlow extends Component {
  componentDidMount() {
    const { actions, api, hasPermission } = this.props;
    if (hasPermission().list) actions.fetchFlows(api);
  }

  componentWillUnmount = () => {
    const { actions } = this.props;
    actions.resetFlow();
  }

  onLoadMore = () => {
    const { actions, api, hasPermission, currentPage, loaded } = this.props;
    if (hasPermission().list && !loaded) actions.fetchFlows(api, currentPage + 1);
  }

  render() {
    const { flows, active, hasPermission, api } = this.props;
    return (
      <LoadMore onLoadMore={this.onLoadMore}>
        <Row>
          <Col sm="1/5" />
          <Col sm="3/5">
            <Space size={24} />
            {flows.map(flow => (
              <SalesStatuses
                api={api}
                key={flow.id}
                flow={flow}
                hasPermission={hasPermission()}
                active={active}
              />
            ))}
          </Col>
        </Row>
      </LoadMore>
    );
  }
}

CommunicationFlow.propTypes = propTypes;

export default CommunicationFlow;
