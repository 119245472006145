import { generatePath } from 'react-router-dom';

import { gettext } from '../utilities/languageUtility';
import { hasPageAccess } from './acl';
import Dashboard from '../../pages/home/dashboard/Dashboard';
import Tasks from '../../pages/home/tasks/Tasks';
import Clients from '../../pages/home/clients/Clients';
import TradingAccounts from '../../pages/home/trading-accounts/TradingAccounts';
import Payments from '../../pages/home/payments/Payments';
import InternalTransactions from '../../pages/home/payments/InternalTransactions';
import PendingWithdrawals from '../../pages/home/payments/PendingWithdrawals';
import WithdrawalHistory from '../../pages/home/payments/WithdrawalHistory';
import OpenPositions from '../../pages/home/trade/OpenPositions';
import ClosedPositions from '../../pages/home/trade/ClosedPositions';
import AllPositions from '../../pages/home/trade/AllPositions';
import Trades from '../../pages/home/trade/Trades';
import Instruments from '../../pages/home/trade/Instruments';
import Audit from '../../pages/home/logs/Audit';
import CommunicationHistory from '../../pages/home/logs/CommunicationHistory';
import IbList from '../../pages/home/ib-system/IbList';
import IbPendingWithdrawals from '../../pages/home/ib-system/IbPendingWithdrawals';
import IbPaymentHistory from '../../pages/home/ib-system/IbPaymentHistory';
import ActiveTraders from '../../pages/home/reports-active-traders/ActiveTraders';
import SalesOverview from '../../pages/home/sales-overview/SalesOverview';
import EmailEvents from '../../pages/home/notification-events/EmailEvents';
import EmailTemplates from '../../pages/home/notification-templates/EmailTemplates';
import SmsEvents from '../../pages/home/notification-events/SmsEvents';
import SmsTemplates from '../../pages/home/notification-templates/SmsTemplates';
import Campaigns from '../../pages/home/campaigns/Campaigns';
import KYCExport from '../../pages/home/kyc/KYCExport';
import KYCHistory from '../../pages/home/kyc/KYCHistory';
import KYCCheckLog from '../../pages/home/kyc/KYCCheckLog';
import DocumentCheckLog from '../../pages/home/kyc/DocumentCheckLog';
import UserStateMapping from '../../pages/home/kyc-user-state-mapping/UserStateMapping';
import KycBlacklist from '../../pages/home/kyc/KYCBlacklist';
import Unsuitable from '../../pages/home/kyc/Unsuitable';
import Exclusion from '../../pages/home/kyc/Exclusion';
import BiqUsers from '../../pages/home/biq-users/BiqUsers';
import Teams from '../../pages/home/teams/Teams';
import ClientVisibilityGroups from '../../pages/home/client-visibility-groups/ClientVisibilityGroups';
import General from '../../pages/home/settings/general/General';
import Customization from '../../pages/home/settings/customization/Customization';
import Documents from '../../pages/home/settings/documents/Documents';
import Bonus from '../../pages/home/settings/bonus/react/Bonus';
import Segments from '../../pages/home/settings/segments/Segments';
import Emails from '../../pages/home/settings/email/Emails';
import CommunicationStatuses from '../../pages/home/settings/communication-statuses/CommunicationStatuses';
import SalesStatuses from '../../pages/home/sales-statuses/SalesStatuses';
import BlacklistReasons from '../../pages/home/blacklist-reasons/BlacklistReasons';
import PaymentTransactionTypes from '../../pages/home/payment-transaction-types/PaymentTransactionTypes';
import Deposit from '../../pages/home/settings/deposits/Deposit';
import Withdrawals from '../../pages/home/settings/withdrawals/Withdrawals';
import PaymentMethods from '../../pages/home/settings/payment-methods/PaymentMethods';
import PaymentGateways from '../../pages/home/settings/payment-gateways/PaymentGateways';
import TradingPlatform from '../../pages/home/settings/trading-platform/TradingPlatform';
import Integrations from '../../pages/home/settings/integrations/react/Integrations';
import EmailAlerts from '../../pages/home/email-alerts-bo-users/EmailAlerts';
import CountriesTable from '../../pages/home/countries-table/CountriesTable';
import TermsAndConditions from '../../pages/home/settings/terms-and-conditions/TermsAndConditions';
import ComplianceStatuses from '../../pages/home/compliance-statuses/ComplianceStatuses';
import AutomaticFees from '../../pages/home/settings/automatic-fee/react';
import PasswordPage from '../../pages/home/settings/passwords/react';
import ActivityStreamPage from '../../pages/home/activity-stream/ActivityStreamPage';
import SalesStatusesAndSubstatuses from '../../pages/home/settings/sales-statuses-and-substatuses/SalesStatusesAndSubstatuses';
import RiskRatingPage from '../../pages/home/settings/risk-rating/react/RiskRatingPage';
import Bonuses from '../../pages/home/payments/Bonuses';
import LiveClients from '../../pages/home/clients/LiveClients';
import DemoClients from '../../pages/home/clients/DemoClients';
import ExchangeRates from '../../pages/home/settings/exchange-rates/ExchangeRates';

const { environment: env } = window.config;

const pages = [
  {
    label: gettext('Dashboard'),
    page: Dashboard,
    icon: 'Dashboard',
  },
  {
    label: gettext('Tasks'),
    page: Tasks,
    icon: 'Tasks',
  },
  {
    label: gettext('Activity Stream'),
    page: ActivityStreamPage,
    icon: 'ActivityStream',
  },
  {
    label: gettext('Clients'),
    icon: 'Client',
    subItems: [
      {
        label: gettext('All'),
        page: Clients,
      },
      {
        label: gettext('Live'),
        page: LiveClients,
      },
      {
        label: gettext('Demo'),
        page: DemoClients,
      },
      {
        label: gettext('Trading Accounts'),
        page: TradingAccounts,
      },
    ],
  },
  {
    label: gettext('Payments'),
    icon: 'Payments',
    subItems: [
      {
        label: gettext('Payment transactions'),
        page: Payments,
      },
      {
        label: gettext('Internal transactions'),
        page: InternalTransactions,
      },
      {
        label: gettext('Pending withdrawals'),
        page: PendingWithdrawals,
      },
      {
        label: gettext('Withdrawal history'),
        page: WithdrawalHistory,
      },
      {
        label: gettext('Bonus'),
        page: Bonuses,
      },
    ],
  },
  {
    label: gettext('Trade'),
    icon: 'Trade',
    subItems: [
      {
        label: gettext('Open positions'),
        page: OpenPositions,
      },
      {
        label: gettext('Closed positions'),
        page: ClosedPositions,
      },
      {
        label: gettext('All positions'),
        page: AllPositions,
      },
      {
        label: gettext('All trades'),
        page: Trades,
      },
      {
        label: gettext('Instruments'),
        page: Instruments,
      },
    ],
  },
  {
    label: gettext('Logs'),
    icon: 'Logs',
    subItems: [
      {
        label: gettext('Communication history'),
        page: CommunicationHistory,
      },
      {
        label: gettext('Audit logs'),
        page: Audit,
      },
    ],
  },
  {
    label: env === 'ICMCapital' ? gettext('Marketing Agent') : gettext('IB System'),
    icon: 'Ib',
    subItems: [
      {
        label: env === 'ICMCapital' ? gettext('Marketing agent list') : gettext('IB list'),
        page: IbList,
      },
      {
        label: gettext('Pending withdrawals'),
        page: IbPendingWithdrawals,
      },
      {
        label: gettext('Payment history'),
        page: IbPaymentHistory,
      },
    ],
  },
  {
    label: gettext('Reports'),
    icon: 'Reports',
    subItems: [
      {
        label: gettext('Active traders'),
        page: ActiveTraders,
      },
      {
        label: gettext('Sales overview'),
        page: SalesOverview,
      },
    ],
  },
  {
    label: gettext('User Notifications'),
    icon: 'Mail',
    subItems: [
      {
        label: gettext('Email events'),
        page: EmailEvents,
      },
      {
        label: gettext('Email templates'),
        page: EmailTemplates,
      },
      {
        label: gettext('SMS Events'),
        page: SmsEvents,
      },
      {
        label: gettext('SMS Templates'),
        page: SmsTemplates,
      },
    ],
  },
  {
    label: gettext('Marketing'),
    icon: 'Marketing',
    subItems: [
      {
        label: gettext('Campaigns'),
        page: Campaigns,
      },
    ],
  },
  {
    label: gettext('KYC'),
    icon: 'KYC',
    subItems: [
      {
        label: gettext('KYC Data export'),
        page: KYCExport,
      },
      {
        label: gettext('KYC History'),
        page: KYCHistory,
      },
      {
        label: gettext('KYC Check Log'),
        page: KYCCheckLog,
      },
      {
        label: gettext('Document Check Log'),
        page: DocumentCheckLog,
      },
      {
        label: gettext('User State Mapping'),
        page: UserStateMapping,
      },
      {
        label: gettext('Blacklist'),
        page: KycBlacklist,
      },
      {
        label: gettext('Unsuitable'),
        page: Unsuitable,
      },
      {
        label: gettext('Exclusion'),
        page: Exclusion,
      },
    ],
  },
  {
    label: gettext('Team'),
    icon: 'Teams',
    subItems: [
      {
        label: gettext('Team members'),
        page: BiqUsers,
      },
      {
        label: gettext('Teams'),
        page: Teams,
      },
      {
        label: gettext('Client Visibility Groups'),
        page: ClientVisibilityGroups,
      },
    ],
  },
  {
    label: gettext('Settings'),
    icon: 'Settings',
    subItems: [
      {
        label: gettext('General'),
        page: General,
      },
      {
        label: gettext('Customization'),
        page: Customization,
      },
      {
        label: gettext('Documents'),
        page: Documents,
      },
      {
        label: gettext('Bonus'),
        page: Bonus,
      },
      {
        label: gettext('Segments'),
        page: Segments,
      },
      {
        label: gettext('Email'),
        page: Emails,
      },
      {
        label: gettext('Password'),
        page: PasswordPage,
      },
      {
        label: gettext('Communication Statuses'),
        page: CommunicationStatuses,
      },
      {
        label: gettext('Sales statuses and substatuses'),
        page: SalesStatusesAndSubstatuses,
      },
      {
        label: gettext('Automatic Fees'),
        page: AutomaticFees,
      },
      {
        label: gettext('Sales Statuses'),
        page: SalesStatuses,
      },
      {
        label: gettext('Blacklist Reasons'),
        page: BlacklistReasons,
      },
      {
        label: gettext('Compliance Statuses'),
        page: ComplianceStatuses,
      },
      {
        label: gettext('Payment Transaction Types'),
        page: PaymentTransactionTypes,
      },
      {
        label: gettext('Deposits'),
        page: Deposit,
      },
      {
        label: gettext('Withdrawals'),
        page: Withdrawals,
      },
      {
        label: gettext('Payment Methods'),
        page: PaymentMethods,
      },
      {
        label: gettext('Payment Gateways'),
        page: PaymentGateways,
      },
      {
        label: gettext('Trading Platform'),
        page: TradingPlatform,
      },
      {
        label: gettext('Integrations'),
        page: Integrations,
      },
      {
        label: gettext('Exchange rates'),
        page: ExchangeRates,
      },
      {
        label: gettext('Email Alerts'),
        page: EmailAlerts,
      },
      {
        label: gettext('Countries'),
        page: CountriesTable,
      },
      {
        label: gettext('Risk rating'),
        page: RiskRatingPage,
      },
      {
        label: gettext('Terms And Conditions'),
        page: TermsAndConditions,
      },
    ],
  },
];

const parsePage = session => (acc, { page, ...params }) => {
  let displaySubitems = true;

  if (params.subItems) {
    displaySubitems = params.subItems.some(({ page: subPage }) => hasPageAccess(subPage, session));
    if (displaySubitems) {
      return [
        ...acc,
        ({
          ...params,
          subItems: params.subItems.reduce(parsePage(session), []),
        }),
      ];
    }
  }

  if (!hasPageAccess(page, session) || !displaySubitems) return acc;

  return [...acc, ({
    route: generatePath(page.path, page.pathContext || {}),
    ...params,
  })];
};

const getNavigationItems = session => pages.reduce(parsePage(session), []);

export default {
  getNavigationItems,
};
