/* eslint-disable react/prop-types */
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Icon, Tooltip, HyperLink } from 'tc-biq-design-system';
import appRoutes from '../../../../components/App/Router/appRoutes';

import { ActionsCellWrapper, StatusCell, UsernameCell, renderNestedColumnsModifiers, StateCell, OnContentClickCell } from '../../../../components/gridCellRenderers';
import deselectCols from '../../../../logic/utilities/deselectCols';
import { currencyFormatter, WalletFormatter } from '../../../../logic/utilities/formatters';
import { gettext } from '../../../../logic/utilities/languageUtility';

const { environment } = window.config;

const text = {
  WITHDRAWAL_REASON: gettext('Withdrawal reason'),
  EXECUTE: gettext('Execute'),
};

const isAva = () => environment === 'AvaTrade';
const isICMCapital = () => environment === 'ICMCapital';

const displayExecute = (data) => {
  const isApproved = _.get(data, 'steps', []).find(step => step.key === 'bo_review' && step.value === 'Approved');
  return isApproved ? (
    <div key="Exectute">
      <Link to={generatePath(appRoutes.PENDING_WITHDRAWAL, {
        tab: 'execute-withdrawal',
        id: data.id,
      })}
      >
        <Tooltip title={text.EXECUTE} placement="top">
          <Icon colorName="text-status01-700" name="Approve" />
        </Tooltip>
      </Link>
    </div>
  ) : null;
};

const iconsTemplates = {
  approve: (actionHandler, data) => (
    !isAva() ? (
      <div key="Approve" onClick={() => actionHandler(data)}>
        <Tooltip title={gettext('Approve')} placement="top">
          <Icon colorName="text-status01-700" name="Approve" />
        </Tooltip>
      </div>
    ) : (
      displayExecute(data)
    )
  ),
  reject: (actionHandler, data) => (
    <div key="Reject" onClick={() => actionHandler(data)}>
      <Tooltip title={gettext('Reject')} placement="top">
        <Icon colorName="text-status04-700" name="Reject" />
      </Tooltip>
    </div>
  ),
};

export const actionIcons = actions => ({
  ...(actions.approve && { approve: iconsTemplates.approve }),
  ...(actions.reject && { reject: iconsTemplates.reject }),
});

const modifier = (singleActions, idAction, { previewUser, previewBiqUser }, tableData) => ({
  ...deselectCols(['cause_object_id']),
  id: {
    // eslint-disable-next-line react/prop-types
    cellRendererFramework: (isAva()
      ? ({ data }) => (
        <HyperLink>
          <Link to={generatePath(appRoutes.PENDING_WITHDRAWAL, {
            tab: 'withdrawal-details',
            id: data.id,
          })} 
          >{ data.id }
          </Link>
        </HyperLink>
      )
      : OnContentClickCell(idAction)),
    width: 80,
  },
  user: {
    cellRendererFramework: UsernameCell(previewUser),
    width: 200,
  },
  withdraw_reason: {
    headerName: text.WITHDRAWAL_REASON,
  },
  wallet: {
    cellRendererFramework: WalletFormatter,
    width: 250,
  },
  ...renderNestedColumnsModifiers('steps', tableData, StateCell),
  assigned_to: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  created_by: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  modified_by: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  status: { cellRendererFramework: StatusCell },
  amount: {
    cellRendererFramework: ({ value, data }) => (
      <span>{currencyFormatter(value, data.currency.symbol)}</span>
    ),
    cellStyle: { 'justify-content': 'flex-end' },
  },
  address: {
    headerName: isICMCapital() ? 'Wallet Address' : 'Address',
  },
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper(singleActions, actionIcons(singleActions)),
    pinned: 'right',
    width: 120,
  },
  referral_ids: {
    quickFilter: {},
  },
});

export default modifier;
