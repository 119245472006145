import { createAction, handleActions } from 'redux-actions';


const actionTypes = {
  FETCH_FLOWS: 'FETCH_FLOWS',
  FETCH_FLOWS_PENDING: 'FETCH_FLOWS_PENDING',
  FETCH_FLOWS_REJECTED: 'FETCH_FLOWS_REJECTED',
  FETCH_FLOWS_FULFILLED: 'FETCH_FLOWS_FULFILLED',
  UPDATE_FLOW: 'UPDATE_FLOW',
  UPDATE_FLOW_FULFILLED: 'UPDATE_FLOW_FULFILLED',
  UPDATE_SUBSTATUS: 'UPDATE_SUBSTATUS',
  UPDATE_SUBSTATUS_FULFILLED: 'UPDATE_SUBSTATUS_FULFILLED',
  RESET_FLOW: 'RESET_FLOW',
};

const PAGE_SIZE = 20;
const ORDERING = '-id';

const initialState = {
  currentPage: 0,
  loaded: false,
  flows: [],
  flowsInProgress: false,

};

const fetchFlows = createAction(actionTypes.FETCH_FLOWS, async (api, currentPage = 0) => {
  const { data: { results, next } } = await api.list({ 
    limit: PAGE_SIZE,
    offset: PAGE_SIZE * currentPage, 
    ordering: ORDERING,
  });
  return {
    results,
    loaded: !next,
    currentPage,
  };
});

const updateFlow = createAction(actionTypes.UPDATE_FLOW, async (api, id, flow) => {
  const { data } = await api.updatePart(id, flow);
  return data;
});

const updateSubstatus = createAction(actionTypes.UPDATE_SUBSTATUS, async (api, flowId, substatusId, substatus) => {
  const { data } = await api
    .one(flowId)
    .all('substatuses')
    .updatePart(substatusId, substatus);
  return {
    flowId,
    substatus: data,
  };
});

const resetFlow = createAction(actionTypes.RESET_FLOW);

export const actions = {
  fetchFlows,
  updateFlow,
  updateSubstatus,
  resetFlow,
};

const handleFlowUpdate = (state, { payload }) => ({ ...state, flows: state.flows.map(flow => (flow.id === payload.id ? payload : flow)) });

const handleSubstatusUpdate = (state, { payload }) => ({ 
  ...state,
  flows: state.flows.map((flow) => {
    if (flow.id === payload.flowId) {
      return {
        ...flow,
        substatuses: flow.substatuses.map(substatus => (substatus.id === payload.substatus.id ? payload.substatus : substatus)),
      };
    }

    return flow;
  }),
});


export default handleActions(
  {
    [actionTypes.FETCH_FLOWS_PENDING]: state => ({ ...state, flowsInProgress: true }),
    [actionTypes.FETCH_FLOWS_REJECTED]: state => ({ ...state, flowsInProgress: false }),
    [actionTypes.FETCH_FLOWS_FULFILLED]: (state, { payload: { results, currentPage, loaded } }) => ({ 
      ...state, 
      flowsInProgress: false, 
      flows: [
        ...state.flows,
        ...results,
      ], 
      loaded,
      currentPage,
    }),
    [actionTypes.UPDATE_FLOW_FULFILLED]: handleFlowUpdate,
    [actionTypes.UPDATE_SUBSTATUS_FULFILLED]: handleSubstatusUpdate,
    [actionTypes.RESET_FLOW]: () => ({ ...initialState }),
  },
  initialState,
);
