export default {
  // Global
  ROOT: '/',
  LOGIN: '/login',
  FORBIDDEN: '/403',
  RESET_PASSWORD: '/auth/reset-password',
  AUTH: '/auth',
  JOIN: '/join/:code',
  // Dashboard
  DASHBOARD: '/dashboard',
  TASKS: '/tasks',
  // AS
  ACTIVITY_STREAM: '/activity-stream',
  // Clients
  CLIENTS_DEMO: '/clients/Demo',
  CLIENTS_LIVE: '/clients/Live',
  CLIENTS: '/clients',
  CLIENT_PROFILE: '/user/:id/:section?',
  // Trading accounts
  TRADING_ACCOUNTS: '/trading-accounts',
  TRADING_ACCOUNT: '/trading-accounts/:userId/account/:accountId',
  // Payments
  PENDING_WITHDRAWAL: '/payments/pending-withdrawals/:id/:tab?',
  PENDING_WITHDRAWALS: '/payments/pending-withdrawals',
  PAYMENT_TRANSACTION: '/payments/transactions/:id',
  PAYMENT_TRANSACTIONS: '/payments/transactions',
  INTERNAL_TRANSACTIONS: '/internal-transactions',
  WITHDRAWAL_HISTORY: '/payments/withdrawal-history/:id?',
  PAYMENT_BONUSES: '/payments/bonuses',
  // Trade
  OPEN_POSITIONS: '/trade/open-pos/:id?',
  // CLOSED_USER_POSITIONS: '/trade/closed-pos/:id',
  CLOSED_POSITIONS: '/trade/closed-pos/:id?',
  ALL_POSITIONS: '/trade/all-pos/:id?',
  ALL_TRADES: '/trade/all-trades/:id?',
  TRADE_INSTRUMENTS: '/trade/instruments',
  // Logs
  COMMUNICATION_HISTORY: '/logs/communication',
  AUDIT_LOGS: '/logs/audit/:id?',
  LOGS_MESSAGE: '/logs/history/:id/:channel?',
  // Ib
  IB_PROFILE: '/ib-system/ib-user/:id/:section?',
  IB_LIST: '/ib-system/ib-list',
  IB_PENDING_WITHDRAWALS: '/ib-system/pending-withdrawals',
  IB_PAYMENT_HISTORY: '/ib-system/payment-history',
  // Reports
  ACTIVE_TRADERS: '/active-traders',
  SALES_OVERIVEW: '/reports/sales-overview',
  // Notifications
  EMAIL_EVENT_CREATE: '/emails/email-events/add-new/:id?',
  EMAIL_EVENT: '/emails/email-events/:id',
  EMAIL_EVENTS: '/emails/email-events',
  EMAIL_TEMPLATE_CREATE: '/emails/email-templates/add-new/:id?',
  EMAIL_TEMPLATE: '/emails/email-templates/:id',
  EMAIL_TEMPLATES: '/emails/email-templates',
  SMS_EVENT_CREATE: '/sms/events/add-new/:id?',
  SMS_EVENT: '/sms/events/:id',
  SMS_EVENTS: '/sms/events',
  SMS_TEMPLATES_CREATE: '/sms/templates/add-new/:id?',
  SMS_TEMPLATE: '/sms/templates/edit/:id',
  SMS_TEMPLATES: '/sms/templates',
  // Campaigns
  CAMPAIGN: '/marketing/campaigns/:id',
  CAMPAIGNS: '/marketing/campaigns',
  TRACKING_LINK: '/marketing/profile/:userId/campaigns/:campaignId/tracking-links/:id',
  // Kyc
  KYC_EXPORT: '/kyc/export',
  KYC_HISTORY: '/kyc/history',
  KYC_CHECK_LOG: '/kyc/check-log',
  KYC_DOCUMENT_CHECK_LOG: '/kyc/document-check-log',
  KYC_USER_STATE_MAPPING: '/kyc-user-state-mapping',
  KYC_BLACKLIST: '/kyc/blacklist',
  KYC_UNSIUTABLE: '/kyc/unsuitable',
  KYC_EXCLUSION: '/kyc/exclusion',
  // Team
  BIQ_USERS: '/settings/biq-users',
  TEAM_MEMBER: '/settings/team/:id/:section?',
  TEAM: '/settings/teams/:id/:section?',
  TEAMS: '/settings/teams',
  CLIENT_VISIBILITY_GROUP_CREATE: '/teams/client-visibility-groups/add-new',
  CLIENT_VISIBILITY_GROUP: '/teams/client-visibility-groups/edit/:id',
  CLIENT_VISIBILITY_GROUPS: '/teams/client-visibility-groups',
  // Settings
  SETTINGS_GENERAL: '/settings/general',
  SETTINGS_CUSTOMIZATION_PROFILE_CREATE: '/settings/customization/client-profile-layouts/new',
  SETTINGS_CUSTOMIZATION_PROFILE: '/settings/customization/client-profile-layouts/:id',
  SETTINGS_CUSTOMIZATION_PROFILES: '/settings/customization/client-profile-layouts',
  SETTINGS_CUSTOMIZATION_DASHBOARD_CREATE: '/settings/customization/dashboard-layouts/new',
  SETTINGS_CUSTOMIZATION_DASHBOARD: '/settings/customization/dashboard-layouts/:id',
  SETTINGS_CUSTOMIZATION_DASHBOARDS: '/settings/customization/dashboard-layouts',
  SETTINGS_CUSTOMIZATION: '/settings/customization',
  SETTINGS_DOCUMENTS: '/settings/documents',
  SETTINGS_BONUS_CAMPAIGN_CREATE: '/settings/bonus/campaign/new',
  SETTINGS_BONUS_CAMPAIGN: '/settings/bonus/campaign/:id',
  SETTINGS_BONUS_CANCELLATION_RULES_CREATE: '/settings/bonus/cancellation-rules/new',
  SETTINGS_BONUS_CANCELLATION_RULES: '/settings/bonus/cancellation-rules/:id',
  SETTINGS_BONUS: '/settings/bonus/:section?',
  SETTINGS_SEGMENTS: '/settings/segments',
  SETTINGS_EMAIL: '/settings/email',
  SETTINGS_COMMUNICATION_STATUSES: '/settings/communication-statuses',
  SETTINGS_AUTOMATIC_FEES: '/settings/automaticfees',
  SETTINGS_SALES_STATUSES: '/settings/sales-statuses',
  SETTINGS_BLACKLIST_REASONS: '/settings/blacklist-reasons',
  SETTINGS_COMPLIANCE_STATUSES: '/settings/compliance-statuses',
  SETTINGS_PAYMENT_TRANS_TYPES: '/settings/payment-transaction-types',
  SETTINGS_PENDING_DEPOSIT_CREATE: '/settings/pending-deposits/add-new',
  SETTINGS_PENDING_DEPOSIT: '/settings/pending-deposits/:id',
  SETTINGS_DEPOSIT_METHODS_RULES_CREATE: '/settings/deposit-method/add',
  SETTINGS_DEPOSIT_METHODS_RULES: '/settings/deposit-method/edit/:id',
  SETTINGS_WITHDRAWABLE_AMOUNTS_CREATE: '/settings/withdrawable-amounts/add/:id?',
  SETTINGS_WITHDRAWABLE_AMOUNTS_EDIT: '/settings/withdrawable-amounts/edit/:id',
  SETTINGS_STATE_RULES_CREATE: '/settings/state-rules/add',
  SETTINGS_STATE_RULES_CLONE: '/settings/state-rules/clone/:id',
  SETTINGS_STATE_RULES: '/settings/state-rules/edit/:id',
  SETTINGS_DEPOSIT_EXPIRY_CREATE: '/settings/deposit-expiry/add',
  SETTINGS_DEPOSIT_EXPIRY_CLONE: '/settings/deposit-expiry/clone/:id',
  SETTINGS_DEPOSIT_EXPIRY: '/settings/deposit-expiry/edit/:id',
  SETTINGS_DEPOSITS: '/settings/deposits',
  SETTINGS_WITDRAWAL_FLOW_CREATE: '/settings/withdrawal-flow/create',
  SETTINGS_WITDRAWAL_FLOW: '/settings/withdrawal-flow/:id',
  SETTINGS_WITHDRAWAL_METHODS_CREATE: '/settings/withdrawal-method/add',
  SETTINGS_WITHDRAWAL_METHODS_CLONE: '/settings/withdrawal-method/clone/:id',
  SETTINGS_WITHDRAWAL_METHODS: '/settings/withdrawal-method/edit/:id',
  SETTINGS_WITHDRAWALS: '/settings/withdrawals',
  SETTINGS_PAYMENT_METHODS: '/settings/payment-methods',
  SETTINGS_PAYMENT_GATEWAYS: '/settings/payment-gateways',
  SETTINGS_TRADING_PLATFORMS: '/settings/trading-platform',
  SETTINGS_INTEGRATIONS_MAILCHIMP: '/settings/integrations/mailchimp',
  SETTINGS_INTEGRATIONS_AVA: '/settings/integrations/avapartners',
  SETTINGS_INTEGRATIONS: '/settings/integrations',
  SETTINGS_EMAIL_ALERTS: '/settings/email-alerts',
  SETTINGS_COUNTRIES: '/settings/countries',
  SETTINGS_TOS: '/settings/tos',
  SETTINGS_PASSWORD_POLICY: '/settings/password-policy',
  SETTINGS_SALES_STATUSES_AND_SUBSTATUSES_FORM: '/settings/sales-statuses-and-substatuses/:tab/:mode/:id?',
  SETTINGS_SALES_STATUSES_AND_SUBSTATUSES: '/settings/sales-statuses-and-substatuses/:tab?',
  SETTINGS_RISK_RATING: '/settings/risk-rating',
  SETTINGS_EXCHANGE_RATES: '/settings/rates',
};
