import { bindActionCreators } from 'redux';

import ClientsArchive from './ClientsArchive';
import connect from '../../../../../logic/connect';
import overlayActions from '../../../../../components/overlay';
import archiveActions from './actions';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      updateSidepanel: overlayActions.update,
      submit: archiveActions.submit,
      resetState: archiveActions.reset,
    },
    dispatch,
  ),
});
  
const mapStateToProps = ({ overlays, pages }) => {
  const archive = pages.CLIENTS.bulkActions.ARCHIVE_CLIENTS;
  
  return {
    sidepanel: overlays.ARCHIVE_CLIENTS,
    submitInProgress: archive.submitInProgress,
    submitSuccess: archive.submitSuccess,
  };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientsArchive);
