import PossibleDuplicates from './PossibleDuplicates';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

export const TAB_ID = 'possible-duplicates';

export default {
  Component: () => PossibleDuplicates,
  label: gettext('Possible Duplicates'),
  id: TAB_ID,
  visibilityFn: (hasAccess, isFeatureEnabled, isIb) => hasAccess('user.duplicate_users.list') 
    && isFeatureEnabled()('RECORD_DUPLICATE_USERS') && !isIb,
};
