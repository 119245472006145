import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  FETCH_LOGIN_TOKEN: 'FETCH_LOGIN_TOKEN',
  FETCH_LOGIN_TOKEN_FULFILLED: 'FETCH_LOGIN_TOKEN_FULFILLED',
};

export const fetchLoginToken = createAction(actionTypes.FETCH_LOGIN_TOKEN, async (id, isIb) => {
  const { data } = await getDjangoApi(`${isIb ? 'ib' : 'users'}/${id}/login_as_url`).list();
  return data;
});


const initialState = {
  token: null,
  domain: null,
};

export default handleActions(
  {
    [actionTypes.FETCH_LOGIN_TOKEN_FULFILLED]: (_state, { payload: { token, domain } }) => ({ token, domain }),
  },
  initialState,
);
