import { createAction, handleActions } from 'redux-actions';

const actionTypes = {
  FETCH_STEPS: 'FETCH_STEPS',
  FETCH_STEPS_FULFILLED: 'FETCH_STEPS_FULFILLED',
};

export const fetchWithdrawalFlow = createAction(
  actionTypes.FETCH_STEPS, 
  async api => api.list(),
);

const initialState = {
  steps: [],
};

const fetchFulfilled = (_state, { payload }) => ({
  steps: payload.data.results,
});

export default handleActions(
  {
    [actionTypes.FETCH_STEPS_FULFILLED]: fetchFulfilled,
  },
  initialState,
);
