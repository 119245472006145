import React, { Component } from 'react';
import { Space, DashboardCard } from 'tc-biq-design-system';

import DashboardNumeric from '../../Numeric';
import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import { currencyFormatter } from '../../../../../../../../logic/utilities/formatters';
import withFilters from '../../hoc/withFilters';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import Info from '../../Info';

const size = {
  w: 2,
  h: 5,
  minW: 2,
  minH: 5,
  maxW: 3,
  maxH: 5,
  y: 0,
  x: 2,
};

const text = {
  TITLE: gettext('Non-FTD'),
  DESCRIPTION: gettext('Non first-time deposits placed during selected time period'),
  TOOLTIP: {
    TITLE: gettext('Non-FTD amount'),
    DESC: gettext('Amount of all non-FTD deposits'),
    LINK_TEXT: gettext('Go to Transactions table'),
  },
};

const api = getDjangoApi('dashboard/overall_deposits');

class NonFTD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noneFtdDeposits: {},
      loading: false,
    };
  }

  onQueryReady = async (query) => {
    this.setState({ loading: true });
    const { data } = await api.list([...query, { is_ftd: false }]);
  
    this.setState({
      noneFtdDeposits: data,
      loading: false,
    });
  }

  render() {
    const { noneFtdDeposits: { amount, currency, number }, loading } = this.state;
    const noneFtdAmount = currencyFormatter(amount, currency);
    const noneFtdNumber = number || 0;
    return (
      <DashboardCard
        title={text.TITLE}
        description={text.DESCRIPTION}
        loading={loading}
      >
        <DashboardNumeric>
          <DashboardNumeric.Wrapper>
            <Space size={5} />
            <Info
              title={text.TOOLTIP.TITLE}
              desc={text.TOOLTIP.DESC}
              link={{
                url: 'payments/transactions',
                text: text.TOOLTIP.LINK_TEXT,
              }}
            >
              <DashboardNumeric.Price>{noneFtdAmount}</DashboardNumeric.Price>
            </Info>
            <Space size={12} />
            <DashboardNumeric.Number iconName="Deposit">{noneFtdNumber}</DashboardNumeric.Number>
          </DashboardNumeric.Wrapper>
        </DashboardNumeric>
      </DashboardCard>
    );
  }
}

export default {
  component: withFilters(NonFTD),
  size,
};
