import React, { Component } from 'react';
import { Sidepanel, notifier, Input } from 'tc-biq-design-system';
import { object, func } from 'prop-types';
import { bindActionCreators } from 'redux';
import { handleActions, createAction } from 'redux-actions';

import { getDjangoApi } from '../../../../logic/services/api-factory';
import { gettext } from '../../../../logic/utilities/languageUtility';
import overlayActions from '../../../../components/overlay';
import { SidepanelFooter } from '../../../../components/common';
import FormEl from '../../../../components/form/Components/FormEl';
import { formErrorHandler } from '../../../../components/form/logic/utils';

const FORM_ID = 'SALES_STATUSES_FORM';

const propTypes = {
  state: object.isRequired,
  onSuccess: func.isRequired,
  sidepanel: object.isRequired,
  actions: object.isRequired,
};

const actionTypes = {
  UPDATE_STATUS_VALUE: 'UPDATE_STATUS_VALUE',
  RESET_STATE: 'RESET_STATE',
  SUBMIT: 'SUBMIT',
  SUBMIT_PENDING: 'SUBMIT_PENDING',
  SUBMIT_REJECTED: 'SUBMIT_REJECTED',
  SUBMIT_FULFILLED: 'SUBMIT_FULFILLED',
};

const initialState = {
  status: '',
  submitInProgress: false,
  errors: {},
};

const updateStatus = createAction(actionTypes.UPDATE_STATUS_VALUE, value => value);
const resetState = createAction(actionTypes.RESET_STATE);
const submit = createAction(actionTypes.SUBMIT, async (status, action, api) => (action === 'add' ? api.create({ name: status.value }) : api.update(status.id, { name: status.value })));

const onError = (state, { payload }) => ({ 
  ...state, 
  submitInProgress: false,
  errors: payload.data, 
});

const onSuccess = state => ({ ...state, submitInProgress: false, errors: {} });

export const reducer = handleActions({
  [actionTypes.UPDATE_STATUS_VALUE]: (state, { payload }) => ({ ...state, status: payload }),
  [actionTypes.RESET_STATE]: () => ({ ...initialState }),
  [actionTypes.SUBMIT_PENDING]: state => ({ ...state, submitInProgress: true }),
  [actionTypes.SUBMIT_REJECTED]: onError, 
  [actionTypes.SUBMIT_FULFILLED]: onSuccess, 
}, initialState);

const text = {
  TITLE_CREATE: gettext('Create sales status'),
  TITLE_EDIT: gettext('Edit sales status'),
  PLACEHOLDER: gettext('Sales status'),
  CREATE_BUTTON_LABELS: {
    confirm: gettext('Save status'),
    cancel: gettext('Discard'),
  },
  EDIT_BUTTON_LABELS: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
  SUCCESS: gettext('Sales status successfully saved'),
  ERROR: gettext('Error while saving sales status'),
};

const customFooter = (execute, close, submitInProgress, type) => (
  <SidepanelFooter 
    execute={execute} 
    close={close} 
    submitInProgress={submitInProgress} 
    confirmColor="primary"
    buttonLabels={type === 'edit' ? text.EDIT_BUTTON_LABELS : text.CREATE_BUTTON_LABELS}
    formId={FORM_ID}
  />
);

class AddEditSalesStatus extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.submit = this.submit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.api = getDjangoApi('sales_status');
  }

  componentDidMount() {
    const { sidepanel } = this.props;
    const { parameters } = sidepanel;
    if (parameters.type === 'edit') this.actions.updateStatus(parameters.data.name);
  }

  onClose(submitSuccess) {
    const { onSuccess } = this.props;
    this.actions.resetState();
    this.actions.closeSidepanel('ADD_EDIT_SALES_STATUS');
    if (submitSuccess) onSuccess();
  }

  updateStatus(event) {
    const { target } = event;
    this.actions.updateStatus(target.value);
  } 

  submit() {
    const { state, sidepanel } = this.props;
    const { data, type } = sidepanel.parameters;

    const request = { value: state.status };
    if (type === 'edit') request.id = data.id;

    this.actions.submit(request, type, this.api)
      .then(() => {
        notifier.success(text.SUCCESS);
        this.onClose(true);
      }, (err) => {
        formErrorHandler(text.ERROR)(err);
      });
  }

  render() {
    const { sidepanel, state } = this.props;
    const { errors, submitInProgress, status } = state;
    const { type } = sidepanel.parameters;

    return (
      <Sidepanel
        icon="Pen"
        title={type === 'edit' ? text.TITLE_EDIT : text.TITLE_CREATE}
        visible={sidepanel.visible}
        onCloseIconClick={() => this.onClose()}
        footerRender={() => customFooter(this.submit, this.onClose, submitInProgress, type)}
      >
        <FormEl formId={FORM_ID}>  
          <Input 
            hasError={!!errors.name}
            helpText={errors.name ? errors.name[0] : null}
            label={text.PLACEHOLDER}
            placeholder={text.PLACEHOLDER} 
            value={status} 
            onChange={this.updateStatus} 
          />
        </FormEl>
      </Sidepanel>
    );
  }
}

AddEditSalesStatus.propTypes = propTypes;

export const mapStateToProps = ({ overlays, pages }) => ({
  state: pages.SALES_STATUSES.actions.ADD_EDIT_SALES_STATUS,
  sidepanel: overlays.ADD_EDIT_SALES_STATUS,
});

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { 
      closeSidepanel: overlayActions.close, 
      updateStatus, 
      submit,
      resetState,
    },
    dispatch,
  ),
});

export default AddEditSalesStatus;
