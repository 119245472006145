
import React, { Component } from 'react';

import withFormSidepanel from '../../../../../../../components/FormSidepanel';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

export const SIDEPANEL_ID = 'NAMES_FORM_SIDEPANEL';
export const FORM_ID = 'NAMES_FORM';

const apiUrl = 'trading_backends/manage_display';

const text = {
  CREATE: gettext('Add new trading platform and server name'),
  EDIT: gettext('Edit platform name'),
  SUCCESS: {
    create: gettext('Platform name successfully created'),
    edit: gettext('Platform name successfully updated'),
  },
};

const formModifiers = {
  name: {
    label: gettext('Trading platform name'),
  },
  backend: {
    label: gettext('Trading platform'),
  },
};

class NamesSidepanel extends Component {
  constructor(props) {
    super(props);

    this.FormSidepanel = withFormSidepanel(FORM_ID, SIDEPANEL_ID);
  }
  
  getFormConfig = helpers => ({
    ...((helpers.isEdit() || !helpers.isAva()) && {
      excludeFields: ['whitelabel', 'server_name'],
    }),
  });

  getApi = () => getDjangoApi(apiUrl)

  getRequestPayload = helpers => ({ name, server_name, backend, whitelabel }) => ({
    ...(name && { name }),
    ...(helpers.isAva() && server_name && { server_name }),
    ...(backend && { backend: backend.value }),
    ...(helpers.isAva() && whitelabel && { whitelabel: whitelabel.name }),
  })

  prepareRowData = () => (data) => {
    const row = { ...data };
    row.backend = row.backend.name;
    delete row.whitelabel;
    delete row.server_name; 
    return row;
  }

  render() {
    const { FormSidepanel, prepareRowData, getRequestPayload, getFormConfig, getApi } = this;
    return (
      <FormSidepanel
        {...this.props}
        options={{
          getApi,
          text,
          prepareRowData,
          getRequestPayload,
          getFormConfig,
          formModifiers,
        }}
      />
    );
  }
}

export default NamesSidepanel;
