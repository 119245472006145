import _ from 'lodash';
import { number, string } from 'prop-types';
import React, { useState } from 'react';

import './ProgressBar.scss';

const ProgressBar = ({ label, percent }) => {
  const id = useState(_.uniqueId('brogressbar-'));

  return (
    <div className="progress_bar">
      <label className="progress_bar__label" htmlFor={id}>{label}</label>
      <progress className="progress_bar__indicator" id={id} value={percent} max="100"> </progress>
    </div>
  );
};

ProgressBar.propTypes = {
  label: string,
  percent: number,
};
ProgressBar.defaultProps = {
  label: '',
  percent: 0,
};

export default ProgressBar;
