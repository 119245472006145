const switchToBO2 = url => `${url.replace('BO3', 'BO2')}?fromRedesign=true`;


function mapBO3ToBO2URL(url) {
  if (typeof url === 'string') {
    const singleUserMatch = url.match(/\/user\/\d+/);
    if (singleUserMatch) {
      return switchToBO2(url.substring(0, singleUserMatch.index + singleUserMatch[0].length));
    }

    const teamClientVisibilityGroupMatch = url.match(/\/teams\/client-visibility-groups/);
    if (teamClientVisibilityGroupMatch) {
      return switchToBO2(url.replace('client-visibility-groups', 'clients-visibility-groups/'));
    }

    const logsHistoryIdMatch = url.match(/logs\/history\/\d+\/Email/);
    if (logsHistoryIdMatch) return switchToBO2(url.replace('/Email', ''));
  } 
  return switchToBO2(url);
}

export { mapBO3ToBO2URL };
