import React from 'react';
import { array, object } from 'prop-types';
import { bindActionCreators } from 'redux';

import PanelTable from '../../../../../components/PanelTable';
import CommunicationModal from './CommunicationModal';
import overlayActions from '../../../../../components/overlay';
import { dateTimeFormatter, currencyFormatter } from '../../../../../logic/utilities/formatters';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import connect from '../../../../../logic/connect';

const propTypes = {
  transactions: array.isRequired,
  actions: object.isRequired,
};

const text = {
  WALLET_TRANSACTIONS: gettext('Wallet transactions'),
  ID: gettext('ID'),
  VALUE: gettext('Value'),
  TPI_COMMUNICATION: gettext('TPI Communication'),
  DATE: gettext('Date'),
  VIEW_COMMUNICATION: gettext('View communication'),
};

const ValueRenderer = ({ value, currency }) => currencyFormatter(value, currency.symbol);
const DateRenderer = ({ date }) => dateTimeFormatter(date);

const CommunicationRenderer = openModal => data => (
  <span 
    className="text-primary-400" 
    onClick={() => openModal('WALLET_TRANSACTION_COMMUNICATION', data, { closeOthers: false })}
    style={{ cursor: 'pointer' }}
  >
    {text.VIEW_COMMUNICATION}
  </span>
);

const cols = ({ openModal }) => [
  { title: text.ID, key: 'id' },
  { title: text.VALUE, key: 'value', render: ValueRenderer },
  { title: text.TPI_COMMUNICATION, key: 'tpi_communication', render: CommunicationRenderer(openModal) },
  { title: text.DATE, key: 'date', render: DateRenderer },
];

const WalletTransactions = ({ transactions, actions }) => (
  <div style={{ flex: 1 }}>
    <PanelTable 
      title={text.WALLET_TRANSACTIONS} 
      cols={cols(actions)} 
      data={transactions} 
    />

    <CommunicationModal />
  </div>
);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { openModal: overlayActions.open },
    dispatch,
  ),
});

WalletTransactions.propTypes = propTypes;
export default connect(null, mapDispatchToProps)(WalletTransactions);
