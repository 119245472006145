/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Tabs, Tab } from 'tc-biq-design-system';
import { compose } from 'redux';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import Page from '../../../../components/Page';
import Export from '../../../../components/Export';
import AllTeams from './Tabs/AllTeams';
import AllTeamMemebers from './Tabs/AllTeamMembers';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const text = {
  PAGE_TITLE: gettext('Sales overview'),
  PER_TEAM_MEMBER: gettext('Per team member'),
  PER_TEAM: gettext('Per team'),
  ALL_TEAM_MEMBERS: gettext('All team members'),
  ALL_TEAMS: gettext('All teams'),
};

const allTeamMemebersPageConfig = {
  reducerKey: 'SALES_OVERVIEW',
  tableKey: 'ALL_TEAM_MEMBERS_TABLE',
};

const allTeamsPageConfig = {
  reducerKey: 'SALES_OVERVIEW',
  tableKey: 'ALL_TEAMS_TABLE',
};

const bread = [{ label: text.PAGE_TITLE, route: appRoutes.SALES_OVERIVEW }];

class SalesOverview extends Component {
  constructor(props) {
    super(props);
    this.headerActions = this.headerActions.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.state = {
      activeTab: '',
    };
  }

  setActiveTab(activeTab) {
    this.setState({ activeTab });
  }

  headerActions() {
    const { allTeamMembersExportUrl, allTeamsExportUrl } = this.props;
    const { activeTab } = this.state;
    const hasExportAccess = hasAccess('reports.sales_overview.export.*');

    if (activeTab === 'all-team-members' && hasExportAccess) return <Export url={allTeamMembersExportUrl} />;
    if (activeTab === 'all-teams' && hasExportAccess) return <Export url={allTeamsExportUrl} />;
    return null;
  }

  render() {
    const {
      allTeamMembersExportUrl, 
      allTeamsExportUrl,
    } = this.props;

    return (
      <Page
        headerActions={this.headerActions}
        bread={bread}
        title={text.PAGE_TITLE}
      >
        <Tabs>
          <Tab title={text.ALL_TEAM_MEMBERS} onLoad={() => this.setActiveTab('all-team-members')}>
            <AllTeamMemebers exportUrl={allTeamMembersExportUrl} {...this.props} />
          </Tab>
          <Tab title={text.ALL_TEAMS} onLoad={() => this.setActiveTab('all-teams')}>
            <AllTeams exportUrl={allTeamsExportUrl} {...this.props} />
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey } = allTeamMemebersPageConfig;
  return {
    allTeamMembersExportUrl: pages[reducerKey].tables[allTeamMemebersPageConfig.tableKey].exportUrl,
    allTeamsExportUrl: pages[reducerKey].tables[allTeamsPageConfig.tableKey].exportUrl,
  };
};

export default compose(
  connect(mapStateToProps),
  withErrorBoundary,
)(SalesOverview); 
