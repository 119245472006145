import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  ADD_USER_TO_EXCLUSION: 'ADD_USER_TO_EXCLUSION',
  ADD_USER_TO_EXCLUSION_FULFILLED: 'ADD_USER_TO_EXCLUSION_FULFILLED',
  ADD_USER_TO_EXCLUSION_PENDING: 'ADD_USER_TO_EXCLUSION_PENDING',
  ADD_USER_TO_EXCLUSION_REJECTED: 'ADD_USER_TO_EXCLUSION_REJECTED',
};

export const addUserToExclusion = createAction(actionTypes.ADD_USER_TO_EXCLUSION, async (user) => {
  const exclusionApi = getDjangoApi(`users/${user.id}/add_to_exclusion`);
  
  await exclusionApi.create({});
});


const initialState = {
  submitInProgress: false,
  errors: null,
};

const pendingHandler = state => ({ ...state, submitInProgress: true });

const fulfilledHandler = state => ({
  ...state,
  submitInProgress: false,
  error: null,
});

const rejectedHandler = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  errors: payload.data,
});

export default handleActions(
  {
    [actionTypes.ADD_USER_TO_EXCLUSION_PENDING]: pendingHandler,
    [actionTypes.ADD_USER_TO_EXCLUSION_FULFILLED]: fulfilledHandler,
    [actionTypes.ADD_USER_TO_EXCLUSION_REJECTED]: rejectedHandler,
  },
  initialState,
);
