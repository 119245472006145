import React, { PureComponent } from 'react';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { object, array, bool } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../../logic/connect';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import withUnmountMethod from '../../../../../../components/hoc/withUnmountMethod';
import overlayActions from '../../../../../../components/overlay';
import { actions } from '../Model';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import { SidepanelFooter } from '../../../../../../components/common';
import to from '../../../../../../logic/utilities/to';
import FormFactory, { ErrorHandler, FormActionsFactory } from '../../../../../../components/form';

const propTypes = {
  sidepanel: object.isRequired,
  actions: object.isRequired,
  fields: array.isRequired,
  submitInProgress: bool.isRequired,
};

const SIDEPANEL_ID = 'CREATE_EDIT_DOCUMENT';
const { errorFields, create, update, resetFields, setRowData } = FormActionsFactory(SIDEPANEL_ID);
const text = {
  TITLE_CREATE: gettext('Create document'),
  TITLE_EDIT: gettext('Edit document'),
  CREATE_BUTTON_LABELS: {
    confirm: gettext('Create document'),
    cancel: gettext('Discard'),
  },
  EDIT_BUTTON_LABELS: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
  SUCCESS: gettext('Document successfully saved'),
  ERROR: gettext('Error while saving documents'),
  INPUT_LABEL: gettext('Name'),
  TWO_SIDES: gettext('Document has two sides'),
};

const api = getDjangoApi('document_types');
const formConfig = () => {
  const excludeFields = [];
  if (!isFeatureEnabled()('PROTRADER')) excludeFields.push('usage');
  if (!isFeatureEnabled()('ONFIDO')) excludeFields.push('is_two_side');
  return {
    form: SIDEPANEL_ID,
    api,
    excludeFields,
  };
};

const customFooter = (execute, close, submitInProgress, type) => (
  <SidepanelFooter
    execute={() => execute(type)}
    close={close}
    submitInProgress={submitInProgress}
    confirmColor="primary"
    buttonLabels={type === 'edit' ? text.EDIT_BUTTON_LABELS : text.CREATE_BUTTON_LABELS}
    formId={SIDEPANEL_ID}
  />
);

class CreateEditDocument extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  componentDidMount() {
    const { sidepanel } = this.props;
    const { parameters } = sidepanel;
    if (parameters && parameters.existingData) this.actions.setRowData(parameters.existingData);
  }

  onClose = (submitSuccess) => {
    if (submitSuccess) this.actions.loadDocuments();
    this.actions.closeSidepanel(SIDEPANEL_ID);
    this.actions.resetFields();
  };

  submit = async () => {
    const { fields, sidepanel } = this.props;
    const { parameters } = sidepanel;
    let docId = null;
    if (parameters && parameters.type === 'edit') {
      docId = parameters.docId;
    }
    const [err] = await (docId
      ? to(this.actions.update(api, docId))
      : to(this.actions.create(api)));

    if (err) {
      new ErrorHandler({ errors: err.data, fields }).showMessages(notifier.error);
    } else {
      notifier.success(text.SUCCESS);
      this.onClose(true);
      this.actions.resetFields();
    }
  };

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig());
    }
    const { FormComponent } = this;
    return <FormComponent formId={SIDEPANEL_ID} modifiers={{ is_two_sided: { label: text.TWO_SIDES } }} />;
  };

  render() {
    const { sidepanel, submitInProgress } = this.props;
    const { parameters } = sidepanel;
    const type = parameters && parameters.type;
    return (
      <Sidepanel
        icon="Pen"
        title={type === 'edit' ? text.TITLE_EDIT : text.TITLE_CREATE}
        visible={sidepanel.visible}
        onCloseIconClick={() => this.onClose()}
        footerRender={() => customFooter(this.submit, this.onClose, submitInProgress, type)}
      >
        {this.renderForm()}
      </Sidepanel>
    );
  }
}

CreateEditDocument.propTypes = propTypes;

export const mapStateToProps = ({ overlays, forms }) => {
  const sidepanel = overlays.CREATE_EDIT_DOCUMENT;
  const { fields, submitInProgress } = forms.CREATE_EDIT_DOCUMENT;
  return { sidepanel, fields, submitInProgress };
};

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      loadDocuments: actions.loadDocuments,
      errorFields,
      create,
      resetFields,
      setRowData,
      update,
    },
    dispatch,
  ),
});

export default withUnmountMethod(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(CreateEditDocument)));
