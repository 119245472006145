import React from 'react';
import { object, node } from 'prop-types';

const { Provider, Consumer } = React.createContext();

const propTypes = {
  routerState: object.isRequired,
  children: node.isRequired,
};

const RouterProvider = ({ routerState, children }) => (
  <Provider
    value={routerState}
  >
    {children}
  </Provider>
);

const withRouter = Comp => props => (
  <Consumer>
    {context => <Comp routerState={context} {...props} />}
  </Consumer>
);

RouterProvider.propTypes = propTypes;

export {
  RouterProvider,
  withRouter,
};
