import React from 'react';
import { string } from 'prop-types';

import ActivityStream from '../../../../activity-stream/ActivityStream';

const propTypes = {
  id: string.isRequired,
};

const style = {
  paddingLeft: '5%',
  paddingRight: '5%',
};

const Activity = ({ id }) => <ActivityStream userId={parseInt(id)} withManuallyLog style={style} />;

Activity.propTypes = propTypes;

export default Activity;
