import { string } from 'prop-types';
import React from 'react';

const TooltipCell = ({ value }) => <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={value}>{value}</div>;

TooltipCell.propTypes = {
  value: string,
};

TooltipCell.defaultProps = {
  value: '',
};

export default TooltipCell;
