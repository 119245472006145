import React from 'react';
import { string } from 'prop-types';
import { Pill } from 'tc-biq-design-system';
import { gettext } from '../../logic/utilities/languageUtility';

const statusPillTypeMap = {
  [gettext('Live')]: 'status01',
  [gettext('Demo')]: 'status03',
};

const propTypes = {
  value: string,
};

const defaultProps = {
  value: gettext('N/A'),
};

const BackendModeCell = ({ value }) => (
  <Pill type={statusPillTypeMap[value]}>
    {value}
  </Pill>
);

BackendModeCell.propTypes = propTypes;
BackendModeCell.defaultProps = defaultProps;
export default BackendModeCell;
