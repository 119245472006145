import _ from 'lodash';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

export const statesLogic = {
  PENDING: {
    id: 1,
    icon: 'Pending',
    color: 'status03-400',
    label: gettext('Pending'),
    isActionAllowed: type => ['review', 'ignore'].includes(type),
  },
  APPROVED: {
    id: 2,
    icon: 'Approve',
    color: 'status01-400',
    label: gettext('Approved'),
    isActionAllowed: type => ['undo', 'deleteDocument'].includes(type),
  },
  REJECTED: {
    id: 3,
    icon: 'Reject',
    color: 'status04-400',
    label: gettext('Rejected'),
    isActionAllowed: type => ['undo', 'deleteDocument'].includes(type),
  },
  IGNORED: {
    id: 4,
    icon: 'Close',
    color: 'status02-400',
    label: gettext('Ignored'),
    isActionAllowed: type => ['undo', 'deleteDocument'].includes(type),
  },
  PRE_APPROVED: {
    id: 5,
    icon: 'Approve',
    color: 'status02-400',
    label: gettext('Pre-Approved'),
    isActionAllowed: type => ['undo', 'deleteDocument', 'review'].includes(type),
  },
};

const NA = {
  id: '',
  icon: '',
  color: 'neutral-400',
  label: gettext('N/A'),
  isActionAllowed: type => [].includes(type),
};

export const getStatus = value => _.find(statesLogic, ({ id }) => id === value) || NA;

export default {
  PENDING: statesLogic.PENDING.id,
  APPROVED: statesLogic.APPROVED.id,
  REJECTED: statesLogic.REJECTED.id,
  IGNORED: statesLogic.IGNORED.id,
  PRE_APPROVED: statesLogic.PRE_APPROVED.id,
};
