import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import TeamProfile from './TeamProfile';
import connect from '../../../../logic/connect';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import { loadTeamMember, resetTeamMember } from './Model';
import appRoutes from '../../../../components/App/Router/appRoutes';

const mapStateToDispatch = ({ pages: { TEAM_PROFILE } }) => ({
  ...TEAM_PROFILE.profile,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loadTeamMember,
      resetTeamMember,
    },
    dispatch,
  ),
});

const TeamProfilePage = compose(
  connect(mapStateToDispatch, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(TeamProfile);


export default {
  component: TeamProfilePage,
  aclId: 'team.retrieve',
  path: appRoutes.TEAM_MEMBER,
};
