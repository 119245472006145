import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { object, func } from 'prop-types';
import { Tabs, Tab, Row, Col, Space, Card } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';

import connect from '../../../logic/connect';
import { gettext } from '../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../logic/services/api-factory';
import isFeatureEnabled from '../../../logic/filters/is-feature-enabled';
import Page from '../../../components/Page';
import { activate, reset } from './Model';
import ExtraData from './Components/ExtraData';
import ExecuteWithdrawal from './Components/ExecuteWithdrawal';
import PaymentTransaction from './Components/PaymentTransaction';
import WithdrawalDetails from './Components/WithdrawalDetails';
import WithdrawalFlow from './Components/WithdrawalFlow';
import { TradingAccount, TransactionSingle } from '../../../components/common/sidepanels';
import CreditCardDetails from './Components/CreditCardDetails';
import withErrorBoundary from '../../../components/hoc/withErrorBoundary';
import WalletTransactions from './Components/WalletTransactions';
import GridComponentFactory from '../../../components/grid';
import If from '../../../components/If';
import overlayActions from '../../../components/overlay';
import { hasAccess } from '../../../logic/services/acl';
import appRoutes from '../../../components/App/Router/appRoutes';
import { SIDEPANEL_ID as TRADING_ACCOUNT_SIDEPANEL_ID } from '../../../components/common/sidepanels/TradingAccount';


import './PendingWithdrawal.scss';

const propTypes = {
  withdrawal: object.isRequired,
  relatedPaymentTransaction: object.isRequired,
  actions: object.isRequired,
  cardInfo: object.isRequired,
  dispatch: func.isRequired,
  history: object.isRequired,
  match: object.isRequired,
};

const env = window.config.environment;

const WITHDRAWAL_DETAILS = 'withdrawal-details';
const EXECUTE_WITHDRAWAL = 'execute-withdrawal';

const tabs = {
  [WITHDRAWAL_DETAILS]: 0,
  [EXECUTE_WITHDRAWAL]: 1,
};

const text = {
  WITHDRAWAL_DETAILS: gettext('Withdrawal details'),
  EXECUTE_WITHDRAWAL: gettext('Execute withdrawal'),
  RELATED_PAYMENT_TRANSACTIONS: gettext('Related payment transactions'),
};

const modifier = openTransaction => ({
  id: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => <div className='like-a-link' onClick={() => openTransaction(data)}>{data.id}</div>,
  },
  wallet: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => <div>{`${data.wallet.display_external_id} ${data.wallet.backend.name}`}</div>,
  },
});

const COL_WIDTH = '50%';

const relatedPaymentesConfig = id => ({
  apiUrl: `withdrawal_history/${id}/related_transactions`,
  tableKey: 'RELATED_PAYMENTS_DETAILS',
  reducerKey: 'RELATED_PAYMENTS',
});

class PendingWithdrawal extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    const { id, tab } = props.match.params;
    this.api = getDjangoApi(`pending_withdrawals/${id}`);
    this.Grid = GridComponentFactory(relatedPaymentesConfig(id));
    this.canSeeExecuteWithdrawal = env === 'AvaTrade' && hasAccess('withdrawals.requests.execute.*');

    this.state = {
      active: tabs[tab],
    };
  }

  componentDidMount() {
    this.actions.activate(this.api);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    const { actions } = this.Grid;
    this.actions.reset();
    dispatch(actions.resetTable());
  }

  onExecuteSuccess = () => {
    const { history } = this.props;
    history.push(appRoutes.PENDING_WITHDRAWALS);
  }

  openSingleTransaction = async (data) => {
    const { actions } = this.props;
    const response = await getDjangoApi('payment_transactions').retrieve(data.id);
    actions.openSidepanel('TRANSACTION_SINGLE', response.data);
  }

  openTradingAccountSidepanel = async ({ id, userId }) => {
    const { actions } = this.props;
    const response = await getDjangoApi(`users/${userId}/trading_accounts/${id}`).list();
    actions.openSidepanel(TRADING_ACCOUNT_SIDEPANEL_ID, response.data);
  }

  render() {
    const { withdrawal, relatedPaymentTransaction, cardInfo } = this.props;
    const { active } = this.state;
    const { wallet_transactions, logs } = withdrawal;
    const { GridComponent } = this.Grid;

    return (
      <Page title={text.WITHDRAWAL_DETAILS} style={{ margin: 0 }}>
        <TransactionSingle />
        <TradingAccount />
        <Tabs active={active}>
          <Tab
            visible
            index={tabs[WITHDRAWAL_DETAILS]}
            title={text.WITHDRAWAL_DETAILS}
            onLoad={() => this.setState({ active: tabs[WITHDRAWAL_DETAILS] }, () => this.actions.activate(this.api))}
          >
            <div className="biq-pending-withdrawal">
              <Row gutter={0}>
                <Col xs={COL_WIDTH} sm={COL_WIDTH} md={COL_WIDTH} lg={COL_WIDTH}>
                  <Row>
                    <PaymentTransaction
                      withdrawal={withdrawal}
                      relatedPaymentTransaction={relatedPaymentTransaction}
                      openSingleTransaction={this.openSingleTransaction}
                      openTradingAccountSidepanel={this.openTradingAccountSidepanel}
                    />
                  </Row>
                  <Space size={20} />
                  <Row>
                    <CreditCardDetails withdrawal={withdrawal} cardInfo={cardInfo} method={withdrawal.method} />
                  </Row>
                  {isFeatureEnabled()('TRANSACTION_EXTRA_DATA') && (
                  <Row>
                    <ExtraData logs={logs} />
                  </Row>
                  )}
                </Col>

                <Col xs={COL_WIDTH} sm={COL_WIDTH} md={COL_WIDTH} lg={COL_WIDTH}>
                  <WithdrawalDetails withdrawal={withdrawal} />
                  <Row>
                    <WithdrawalFlow api={this.api} />
                  </Row>
                  <Row>
                    {!_.isEmpty(wallet_transactions) && (
                    <WalletTransactions transactions={wallet_transactions} />
                    )}
                  </Row>
                  <Space size={15} />
                  <Row>
                    <If condition={env === 'AvaTrade'}>
                      <div style={{ flex: 1 }}>
                        { !!GridComponent && (
                        <Card title={text.RELATED_PAYMENT_TRANSACTIONS}>
                          <GridComponent disableQuickFilter tableModifier={modifier(this.openSingleTransaction)} hasManageOption={false} height="350px" style={{ flex: 1 }} />
                        </Card>
                        )}
                      </div>
                    </If>
                  </Row>
                </Col>
              </Row>
            </div>
          </Tab>
          <Tab
            visible={this.canSeeExecuteWithdrawal}
            index={tabs[EXECUTE_WITHDRAWAL]}
            title={text.EXECUTE_WITHDRAWAL}
            onLoad={() => this.setState({ active: tabs[EXECUTE_WITHDRAWAL] })}
          >
            <div className="biq-pending-withdrawal">
              {!_.isEmpty(withdrawal) && <ExecuteWithdrawal withdrawal={withdrawal} onExecuteSuccess={this.onExecuteSuccess} />}
            </div>
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => pages.PENDING_WITHDRAWAL.withdrawal;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { 
      activate,
      reset,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
  dispatch,
});

PendingWithdrawal.propTypes = propTypes;

const PendingWithdrawalPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(PendingWithdrawal);

export default {
  component: PendingWithdrawalPage,
  aclId: 'withdrawals.requests.retrieve',
  path: appRoutes.PENDING_WITHDRAWAL,
};
