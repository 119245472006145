/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import Page from '../../../../components/Page';
import Export from '../../../../components/Export';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import overlayActions from '../../../../components/overlay';
import { KYCHistoryModifiers } from '../modifiers';
import If from '../../../../components/If';
import { UserPreview, BiqUserPreview } from '../../../../components/common/sidepanels';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const pageConfig = {
  apiUrl: 'kyc/history',
  reducerKey: 'KYC_HISTORY',
  tableKey: 'KYC_HISTORY_TABLE',
};

const text = {
  TITLE: gettext('KYC History'),
};

const { GridComponent, actions } = GridFactory(pageConfig);

const bread = [{ label: text.TITLE, route: appRoutes.KYC_HISTORY }];

class KYCHistory extends Component {
  constructor(props) {
    super(props);

    this.headerActions = this.headerActions.bind(this);

    this.tableActions = {
      previewUser: props.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
      previewBiqUser: props.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  headerActions() {
    const { exportUrl } = this.props;
    return (
      <If condition={hasAccess('kyc.history.export.*')}>
        <Export url={exportUrl} />
      </If>
    );
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: KYCHistoryModifiers(this.tableActions),
    };

    return (
      <Page headerActions={this.headerActions} bread={bread} title={text.TITLE}>
        <Filters {...filterProps} />
        <GridComponent tableModifier={KYCHistoryModifiers(this.tableActions)} {...this.props} />
        <UserPreview />
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(KYCHistory);
