import React from 'react';
import { bool } from 'prop-types';
import { Pill } from 'tc-biq-design-system';

import { gettext } from '../../logic/utilities/languageUtility';

const propTypes = {
  value: bool,
};

const defaultProps = {
  value: gettext('N/A'),
};

const valueGetter = (value) => {
  const map = {
    true: gettext('Yes'),
    false: gettext('No'),
    null: gettext('N/A'),
  };
  return map[value] || value;
};

const types = {
  true: 'status01',
  false: 'status04',
  [gettext('N/A')]: 'neutral',
};

const YesNo = ({ value }) => (
  <Pill type={types[value]}>
    { valueGetter(value) }
  </Pill>
);

YesNo.propTypes = propTypes;
YesNo.defaultProps = defaultProps;
export default YesNo;
