/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { bindActionCreators, compose } from 'redux';
import { notifier } from 'tc-biq-design-system';
import { generatePath } from 'react-router-dom';

import connect from '../../../../../../logic/connect';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../../../components/grid';
import { getActiveFilters } from '../../../../../../components/grid/GridUtils';
import Filters from '../../../../../../components/Filters';
import { BiqUserPreview } from '../../../../../../components/common/sidepanels';
import overlayActions from '../../../../../../components/overlay';
import modifier from '../../modifier';
import { hasAccess } from '../../../../../../logic/services/acl';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../../components/App/Router/appRoutes';


const pageConfig = {
  reducerKey: 'DEPOSITS',
  apiUrl: 'settings/deposit_methods',
  tableKey: 'DEPOSIT_METHOD_RULES',
};

const { GridComponent, actions } = GridFactory(pageConfig);

const text = {
  METHOD_DELETE_SUCCESS: gettext('Deposit method rule deleted successfully'),
  METHOD_DELETE_ERROR: gettext('Error while deleting deposit method rule'),
  STATE_UPDATE_SUCCESS: gettext('State updated successfully'),
  STATE_UPDATE_ERROR: gettext('Error while updating state'),
};

class DepositMethods extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.tableActions = {
      modify: hasAccess('settings.manage_deposit_methods.update') && this.onModify,
      onDelete: hasAccess('settings.manage_deposit_methods.destroy') && this.onDelete,
      toggleEnabled: hasAccess('settings.manage_deposit_methods.update') && this.toggleEnabled,
      previewBiqUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  componentWillUnmount() {
    this.actions.fetchTableData();
  }

  onModify = ({ id }) => generatePath(appRoutes.SETTINGS_DEPOSIT_METHODS_RULES, { id })

  onDelete = (data) => {
    this.actions.deleteRow(data).then(() => {
      notifier.success(text.METHOD_DELETE_SUCCESS);
    }, () => {
      notifier.error(text.METHOD_DELETE_ERROR);
    });
  };

  toggleEnabled = ({ id }, is_enabled) => {
    this.actions.updateRowData(id, { is_enabled }).then(() => {
      notifier.success(text.STATE_UPDATE_SUCCESS);
    }, () => {
      notifier.error(text.STATE_UPDATE_ERROR);
    });
  };

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions),
    };  
    return (
      <Fragment>
        <Filters {...filterProps} />
        <GridComponent
          singleActions={!!(this.tableActions.modify || this.tableActions.onDelete)}
          tableModifier={modifier(this.tableActions)}
          {...this.props}
        />
        <BiqUserPreview />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchTableData: actions.fetchTableData,
    updateRowData: actions.updateRowData,
    deleteRow: actions.deleteRow,
    updateFilter: actions.updateFilter,
    changeSegment: actions.changeSegment,
    fetchOptions: actions.fetchOptions,
    openSidepanel: overlayActions.open,
  }, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(DepositMethods); 
