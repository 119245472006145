import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { refactoredInjectRules, setupFields } from '../../../../../logic/services/query-adapter';

const actionTypes = {
  GET_FIELDS_OPTIONS: 'GET_FIELDS_OPTIONS',
  GET_FIELDS_OPTIONS_PENDING: 'GET_FIELDS_OPTIONS_PENDING',
  GET_FIELDS_OPTIONS_REJECTED: 'GET_FIELDS_OPTIONS_REJECTED',
  GET_FIELDS_OPTIONS_FULFILLED: 'GET_FIELDS_OPTIONS_FULFILLED',
  GET_VISIBILITY_GROUP_INFO: 'GET_VISIBILITY_GROUP_INFO',
  GET_VISIBILITY_GROUP_INFO_PENDING: 'GET_VISIBILITY_GROUP_INFO_PENDING',
  GET_VISIBILITY_GROUP_INFO_REJECTED: 'GET_VISIBILITY_GROUP_INFO_REJECTED',
  GET_VISIBILITY_GROUP_INFO_FULFILLED: 'GET_VISIBILITY_GROUP_INFO_FULFILLED',
  SET_VISIBILITY_GROUP_NAME: 'SET_VISIBILITY_GROUP_NAME',
  SET_VISIBILITY_GROUP_USERS: 'SET_VISIBILITY_GROUP_USERS',
  SET_VISIBILITY_GROUP_CONDITION: 'SET_VISIBILITY_GROUP_CONDITION',
  SUBMIT_VISIBILITY_GROUP: 'SUBMIT_VISIBILITY_GROUP',
  SUBMIT_VISIBILITY_GROUP_PENDING: 'SUBMIT_VISIBILITY_GROUP_PENDING',
  SUBMIT_VISIBILITY_GROUP_REJECTED: 'SUBMIT_VISIBILITY_GROUP_REJECTED',
  SUBMIT_VISIBILITY_GROUP_FULFILLED: 'SUBMIT_VISIBILITY_GROUP_FULFILLED',
  RESET_VISIBILITY_GROUP_PAGE: 'RESET_VISIBILITY_GROUP_PAGE',
};

const initialState = {
  name: '',
  errors: {
    name: [''],
  },
  users: [],
  fields: [],
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  submitInProgress: false,
};

const fieldsOptionsAPI = getDjangoApi('object_acl/meta');
const visibilityGroupsAPI = getDjangoApi('settings/object_acl_group');

const getFieldsOptionsHandler = (state, { payload }) => {
  const { fields } = payload.data.actions.GET;
  const mappedFields = setupFields(fields);
  return {
    ...state,
    fields: mappedFields,
  };
};

const getVisibilityGroupInfoHandler = (state, { payload }) => {
  const { condition, name, team_members } = payload.data;
  const group = refactoredInjectRules(state.fields, condition);

  return {
    ...state,
    group,
    name,
    users: team_members,
  };
};

const setGroupConditionHandler = (state, { payload }) => (
  {
    ...state,
    group: { ...payload },
  }
);

const setGroupNameHandler = (state, { payload }) => (
  {
    ...state,
    name: payload,
    errors: { ...state.errors, name: [''] },
  }
);

const setGroupUsersHandler = (state, { payload }) => (
  {
    ...state,
    users: payload,
  }
);

const submitVisibilityGroupPendingHandler = state => (
  {
    ...state,
    submitInProgress: true,
  }
);

const submitVisibilityGroupSuccessHandler = state => (
  {
    ...state,
    submitInProgress: false,
  }
);

const submitVisibilityGroupRejectHandler = (state, { payload }) => (
  {
    ...state,
    submitInProgress: false,
    errors: { ...state.errors, ...payload.data },
  }
);

const resetStateHandler = () => (
  {
    ...initialState,
  }
);

export const getFieldsOptions = createAction(
  actionTypes.GET_FIELDS_OPTIONS,
  async () => fieldsOptionsAPI.options(),
);

export const getVisibilityGroupInfo = createAction(
  actionTypes.GET_VISIBILITY_GROUP_INFO,
  async id => visibilityGroupsAPI.retrieve(id),
);

export const setGroupCondition = createAction(
  actionTypes.SET_VISIBILITY_GROUP_CONDITION,
  group => group,
);

export const setGroupName = createAction(
  actionTypes.SET_VISIBILITY_GROUP_NAME,
  event => event.target.value,
);

export const setGroupUsers = createAction(
  actionTypes.SET_VISIBILITY_GROUP_USERS,
  value => value,
);

export const submitVisibilityGroup = createAction(
  actionTypes.SUBMIT_VISIBILITY_GROUP,
  async (isEdit, request, id) => (
    isEdit ? visibilityGroupsAPI.updatePart(id, request) : visibilityGroupsAPI.create(request)
  ),
);

export const resetState = createAction(
  actionTypes.RESET_VISIBILITY_GROUP_PAGE,
);

export const teamClientVisibilityGroupsActions = {
  getFieldsOptions,
  getVisibilityGroupInfo,
  setGroupCondition,
  setGroupName,
  setGroupUsers,
  submitVisibilityGroup,
  resetState,
};

export default handleActions(
  {
    [actionTypes.GET_FIELDS_OPTIONS_FULFILLED]: getFieldsOptionsHandler,
    [actionTypes.GET_VISIBILITY_GROUP_INFO_FULFILLED]: getVisibilityGroupInfoHandler,
    [actionTypes.SET_VISIBILITY_GROUP_CONDITION]: setGroupConditionHandler,
    [actionTypes.SET_VISIBILITY_GROUP_NAME]: setGroupNameHandler,
    [actionTypes.SET_VISIBILITY_GROUP_USERS]: setGroupUsersHandler,
    [actionTypes.SUBMIT_VISIBILITY_GROUP_PENDING]: submitVisibilityGroupPendingHandler,
    [actionTypes.SUBMIT_VISIBILITY_GROUP_FULFILLED]: submitVisibilityGroupSuccessHandler,
    [actionTypes.SUBMIT_VISIBILITY_GROUP_REJECTED]: submitVisibilityGroupRejectHandler,
    [actionTypes.RESET_VISIBILITY_GROUP_PAGE]: resetStateHandler,
  },
  initialState,
);
