import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import TeamTable from './TeamTable';
import { loadTeam, setMembers } from '../Model';
import overlayActions from '../../../../../components/overlay';

const mapStateToProps = ({ pages }) => ({ ...pages.TEAM.team });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    loadTeam,
    setMembers,
    openSidepanel: overlayActions.open,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamTable);
