import React, { Component } from 'react';
import { string, func, bool, object, array } from 'prop-types';
import { Button, Icon, Select, Space } from 'tc-biq-design-system';

import connect from '../../logic/connect';
import { gettext } from '../../logic/utilities/languageUtility';
import FilterInput from './FilterInput';
import {
  setVisibleFilter,
  setActiveField,
  setOperator,
  setValue,
  resetDropdownStore,
  setActiveFilter,
  removeActiveFilter,
  setAutocompleteChoice,
} from './FilterRedux';
import If from '../If';
import SelectField from './SelectField';
import { operatorsForType } from '../../logic/filters/operators-for-type';
import { fieldType, fieldsType } from './FilterTypes';
import { formatValue, getFilterValue, isMultipleSelect, revertNegation, setFieldValue } from './filterUtils';
import FormEl from '../form/Components/FormEl';

const propTypes = {
  filterKey: string.isRequired,
  label: string.isRequired,
  selectedField: fieldType.isRequired,
  setVisibleFilter: func.isRequired,
  setActiveFilter: func.isRequired,
  setValue: func.isRequired,
  resetDropdownStore: func.isRequired,
  setAutocompleteChoice: func.isRequired,
  isNewFilter: bool,
  fields: fieldsType,
  visibleFilter: string,
  autocompleteChoices: array.isRequired,
  filter: object,
  // eslint-disable-next-line react/no-unused-prop-types
  setActiveField: func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  setOperator: func.isRequired,
};

const defaultProps = {
  visibleFilter: '',
  isNewFilter: false,
  fields: [],
  filter: {},
};

const text = {
  APPLY_FILTER: gettext('Apply filter'),
  OPERATOR: gettext('Operator'),
};

function isReactSelect(element) {
  if (!element) return false;
  const isCloseIcon = element.classList.contains('Select-value-icon');
  const isReactSelect = element.id.indexOf('react-select') !== -1;
  if (isReactSelect || isCloseIcon) {
    return true;
  }
  return false;
}

class FilterDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onFieldSelect = this.onFieldSelect.bind(this);
    this.onOperatorSelect = this.onOperatorSelect.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  onFieldSelect(field) {
    const { props } = this;
    props.setActiveField(field);
  }

  onOperatorSelect({ value }) {
    const { props } = this;
    props.setOperator(value);
    props.setValue(value === 'range' ? [] : '');
  }

  onValueChange(value) {
    const { setValue, selectedField, setAutocompleteChoice, filter } = this.props;
    if (selectedField.list_url) {
      setAutocompleteChoice({ value, isMultipleSelect: isMultipleSelect(filter.operator) });
    }
    setValue(value);
  }

  getOperatorsForFieldType(field) {
    if (field && field.type) {
      const fieldOperators = _.get(field, 'filterOptions.operators');
      if (fieldOperators) return fieldOperators;
      return operatorsForType(field.type);
    }
    return [];
  }
                
  getOperatorValue = (operators, key) => operators.find(({ value }) => value === key) || '';

  applyFilter() {
    const { filter, selectedField, setActiveFilter, resetDropdownStore } = this.props;
    if (!filter.operator) return;
    const operator = revertNegation(filter.operator);
    const key = `${selectedField.key}__${operator}`;
    const value = getFilterValue({ ...filter, forApi: true, field: selectedField });
    setActiveFilter({ key, value });
    resetDropdownStore();
  }

  handleClick({ target }) {
    const { visibleFilter, filterKey, resetDropdownStore } = this.props;
    const visible = visibleFilter === filterKey;
    if (!visible) return;
    // Ignore clicks inside this component 
    // and on Select component from design system... hack I know :D
    if (this.node.contains(target) || isReactSelect(target)) return;
    resetDropdownStore();
  }

  render() {
    const {
      filterKey,
      setVisibleFilter,
      label,
      isNewFilter,
      fields,
      selectedField,
      filter,
      visibleFilter,
      autocompleteChoices,
    } = this.props;

    const visible = visibleFilter === filterKey;
    const otherStyles = {};
    const { operator } = filter;
    const parrent = document.getElementById(filterKey);

    if (parrent) {
      const { left, top } = parrent.getBoundingClientRect();
      otherStyles.left = left;
      otherStyles.top = top - 7;
    }
    
    const operators = this.getOperatorsForFieldType(selectedField);
    const selectedOperator = this.getOperatorValue(operators, filter.operator);
    const value = setFieldValue(autocompleteChoices,
      filter.value, filter.operator, formatValue, selectedField);

    if (!visible) return null;
    return (
      <div style={{ ...styles.container, ...otherStyles }} ref={(e) => { this.node = e; }}>
        <FormEl>
          <div style={styles.heading}>
            <b>{label}</b>
            <span onClick={() => setVisibleFilter(null)}>
              <Icon name="Close" size="small" />
            </span>
          </div>
          <If condition={isNewFilter}>
            <SelectField
              fields={fields}
              onChange={this.onFieldSelect}
              selectedField={selectedField.key ? selectedField : ''}
            />
            <Space size={8} />
          </If>
          <If condition={!!selectedField.key}>
            <Select
              placeholder={text.OPERATOR}
              type="single"
              clearable={false}
              options={operators}
              value={selectedOperator}
              onChange={this.onOperatorSelect}
              valueKey="value"
            />
          </If>
          <Space size={8} />
          <If condition={!!filter.operator}>
            <FilterInput
              field={selectedField}
              value={value}
              onChange={this.onValueChange}
              operator={operator}
            />
          </If>

          <Button type="submit" size="full-width" style={styles.applyButton} onClick={this.applyFilter}>
            {text.APPLY_FILTER}
          </Button>
        </FormEl>
      </div>
    );
  }
}

const styles = {
  container: {
    position: 'fixed',
    display: 'flex',
    minWidth: '254px',
    flexDirection: 'column',
    background: '#feffff',
    padding: '5px 12px',
    border: '1px solid #c3cdd9',
    borderRadius: '3px',
    zIndex: 11,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '4px 0 8px',
  },
  applyButton: {
    margin: '10px 0',
  },
};

FilterDropdown.propTypes = propTypes;
FilterDropdown.defaultProps = defaultProps;

const mapStateToProps = ({ filters }) => ({
  visibleFilter: filters.visibleFilter,
  selectedField: filters.selectedField,
  filter: filters.filter,
  autocompleteChoices: filters.autocompleteChoices,
});

const mapDispatchToProps = {
  setVisibleFilter,
  setActiveField,
  setOperator,
  setValue,
  resetDropdownStore,
  setActiveFilter,
  removeActiveFilter,
  setAutocompleteChoice,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterDropdown);
