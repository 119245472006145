import React, { Component } from 'react';
import { object, bool, shape, func } from 'prop-types';
import isEqual from 'lodash/isEqual';
import { Row, Col, Button, Pill, notifier } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';
import { generatePath, withRouter } from 'react-router-dom';

import connect from '../../../../logic/connect';
import FormFactory, { FormActionsFactory } from '../../../../components/form';
import { Field } from '../../../../components/form/logic/utils';
import Page from '../../../../components/Page';
import { gettext } from '../../../../logic/utilities/languageUtility';
import to from '../../../../logic/utilities/to';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import SmsDataTagsSidepanel, { sidepanelID } from '../DataTagsSidepanel';
import overlayActions from '../../../../components/overlay';
import { resetPlaceholdersStateAction, fetchTemplateAction } from '../UpdateTemplateModel';


import './SmsTemplateUpdate.scss';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import getID from '../../../../logic/utilities/getID';
import appRoutes from '../../../../components/App/Router/appRoutes';

const formID = 'SMS_TEMPLATE_FORM';
const smsApi = (id = '') => getDjangoApi(`sms/templates/${id}`);
const formApi = id => getDjangoApi(`sms/templates/${id}/content`);
const languagesApi = getDjangoApi('sms/templates/languages');
const { create, setFieldValue, update, setFieldsErrors } = FormActionsFactory(formID);
const triggerRulesApi = getDjangoApi('sms/trigger_rules');

const text = {
  CREATE_SMS_TEMPLATE: gettext('Create SMS template'),
  EDIT_SMS_TEMPLATE: gettext('Edit SMS template'),
  SMS_TITLE: gettext('Sms templates'),
  NAME_LABLE: gettext('Name'),
  NAME_PLACEHOLDER: gettext('Enter template name'),
  LANGUAGE_LABEL: gettext('Language'),
  DATA_TAGS: gettext('Data tags'),
  DISCARD: gettext('Discard'),
  CREATE_TEMPLATE: gettext('Create template'),
  EDIT_TEMPLATE: gettext('Edit template'),
  MULTIPLE_SMS_MESSAGE: gettext('This message will be split into multiple SMS messages'),
  CREATE_SMS_TEMPLATE_SUCCESS: gettext('Sms template created successfully'),
  EDIT_SMS_TEMPLATE_SUCCESS: gettext('Sms template edited successfully'),
  CONTENT_ERROR: gettext('This field may not be blank.'),
};

const bread = (type, id) => [
  { label: text.SMS_TITLE, route: appRoutes.SMS_TEMPLATES },
  { label: type === 'edit' ? text.EDIT_SMS_TEMPLATE : text.CREATE_SMS_TEMPLATE,
    route: '',
    params: { id: id || '' } },
];


const fieldsEnum = {
  NAME: 'name',
  LANGUAGE: 'language',
  CONTENT: 'content',
};

const DEFAULT_LANG = {
  value: 'en',
  display_name: 'English',
};

const formConfig = {
  form: formID,
  customFields: [
    {
      id: fieldsEnum.NAME,
      label: text.NAME_LABLE,
      placeholder: text.NAME_PLACEHOLDER,
      type: 'text',
    },
    {
      id: fieldsEnum.LANGUAGE,
      label: text.LANGUAGE_LABEL,
      type: 'select',
      async: true,
      valueKey: 'value',
      debounceInterval: 500,
      labelKey: 'display_name',
      loadOptions: () => languagesApi.list().then(res => res.data),
    },
    {
      id: fieldsEnum.CONTENT,
      label: null,
      type: 'textarea',
    },
  ],
};

class SmsTemplateUpdate extends Component {
  constructor(props) {
    super(props);

    this.languageValuesMap = {};
    this.editedLanguagesSet = new Set();
    this.FormComponent = null;
    this.maxTemplateCount = 160;
    this.type = this.getType();

    if (this.type !== 'create') this.id = props.match.params.id;
  }

  componentDidMount() {
    if (this.id) {
      this.fetchTemplate(this.id);  
    }
  }

  componentDidUpdate(prevProps) {
    const { values } = this.props;
    const { values: prevValues, submitInProgress } = prevProps;

    if (submitInProgress) {
      return;
    }

    if (!prevValues.language && !values.language) {
      this.setDefautLanguage();
    }

    if (prevValues.content !== values.content) {
      this.languageValuesMap[values.language.value].content = values.content;
    }

    if (values.language && !isEqual(prevValues.language, values.language)) {
      this.setFieldValues(values.language.value);
    }
  }

  setFieldValues = (lang) => {
    const { actions: { setFieldValue } } = this.props;
    
    if (!this.languageValuesMap[lang]) {
      this.languageValuesMap[lang] = {
        name: '',
        content: '',
      };
    }

    setFieldValue({ id: fieldsEnum.CONTENT, value: this.languageValuesMap[lang].content });
  }


  setDefautLanguage = () => {
    const { actions: { setFieldValue } } = this.props;
    setFieldValue({ id: fieldsEnum.LANGUAGE, value: DEFAULT_LANG });
  }
  
  getType = () => {
    const pathRoutes = getID();

    if (_.includes(pathRoutes, 'add-new')) {
      return pathRoutes[pathRoutes.length - 1] === 'add-new' ? 'create' : 'clone';
    } 
      
    return 'edit';
  }

  fetchTemplate = (id) => {
    const { actions } = this.props;
    const templatePromise = smsApi(id).retrieve();
    const contentPromise = actions.fetchTemplateAction(id);

    Promise.all([templatePromise, contentPromise])
      .then(([templateResult, contentResult]) => {
        actions.setFieldValue({ id: fieldsEnum.NAME, value: templateResult.data.name });
        this.languageValuesMap = contentResult
          .value
          .data
          .results
          .reduce((acc, template) => {
            this.editedLanguagesSet.add(template.language);
            return { ...acc,
              [template.language]: {
                content: template.content,
                id: template.id,
              } };
          }, {});
      })
      .finally(() => {
        this.setFieldValues(DEFAULT_LANG.value);
      });
  }

  createTemplate = async () => {
    const { actions: {
      create,
      closeSidepanel,
      resetPlaceholdersStateAction,
      setFieldsErrors,   
    }, values: { name } } = this.props;

    closeSidepanel(sidepanelID);
    resetPlaceholdersStateAction();

    const createSmsTemplatePromise = create(smsApi(), {
      name,
    });

    const [, smsResponse] = await to(createSmsTemplatePromise);
  
    if (smsResponse) {
      const { id } = smsResponse.value.response;
      const arrayOfTemplates = Object.keys(this.languageValuesMap)
        .map(lang => ({
          language: lang,
          name,
          content: this.languageValuesMap[lang].content,
          template: id,  
        }))
        .filter(template => !!template.content);
      

      if (!arrayOfTemplates.length) {
        setFieldsErrors({
          content: [text.CONTENT_ERROR],
        });
        return;
      }  

      const promises = arrayOfTemplates.map(template => create(formApi(id), () => ({
        ...template,
      })));
 
      const [error] = await to(Promise.all(promises));
        
      if (!error) {
        this.goToSmsTemplates();
        notifier.success(text.CREATE_SMS_TEMPLATE_SUCCESS);
      }
    }
  }
  
  editTemplate = async () => {
    const { values: { name }, actions: { update } } = this.props;
    const templateId = this.id;

    const editTemplateNamePromise = update(smsApi(), templateId, () => ({ name }));

    const [, templateResponse] = await to(editTemplateNamePromise);

    if (templateResponse) {
      const promises = Object
        .keys(this.languageValuesMap)
        .map((key) => {
          if (this.editedLanguagesSet.has(key)) {
            if (!this.languageValuesMap[key].content) {
              return formApi(templateId).destroy(this.languageValuesMap[key].id);
            }
            return update(formApi(templateId), this.languageValuesMap[key].id, () => (
              { content: this.languageValuesMap[key].content }
            ), true);    
          }
          
          return create(formApi(templateId), () => ({
            content: this.languageValuesMap[key].content,
            template: templateId,
            language: key,
          }));
        });


      const [error] = await to(Promise.all(promises));

      if (!error) {
        this.goToSmsTemplates();
        notifier.success(text.EDIT_SMS_TEMPLATE_SUCCESS);
      }
    }
  };

  goToSmsTemplates = () => {
    const { history } = this.props;
    history.push(generatePath(appRoutes.SMS_TEMPLATES));
  }

  openTags = () => {
    const { actions } = this.props;
    actions.openSidepanel(sidepanelID);
  }
  
  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    
    const { FormComponent } = this;
    const { values, submitInProgress } = this.props;
    const count = (values.content && values.content.length) || 0;
    const isEditable = this.type === 'edit';

    return (
      <FormComponent 
        formId={formID} 
        renderForm={formFields => (
          <React.Fragment>
            <div className="sms-template-update__card">
              <div className="sms-template-update__card-header">
                <Field>{formFields.name }</Field>
                <Field>{ formFields.language }</Field>
              </div>
              <div className="sms-template-update__card-body">
                <Button onClick={this.openTags} color="ghost">{ text.DATA_TAGS }</Button>
                <Field>{ formFields.content }</Field>
                <div className="sms-template-update__counter">
                  { `${count}/${this.maxTemplateCount}` }
                </div>
                <div className="sms-template-update__info">
                  { count > this.maxTemplateCount && <Pill>{ text.MULTIPLE_SMS_MESSAGE }</Pill> }
                </div>
              </div>
              <div className="sms-template-update__card-footer">
                <Button
                  type="submit"
                  disabled={submitInProgress}
                  loading={submitInProgress}
                  onClick={isEditable ? this.editTemplate : this.createTemplate}
                >
                  { isEditable ? text.EDIT_TEMPLATE : text.CREATE_TEMPLATE }
                </Button>
                <Button
                  onClick={this.goToSmsTemplates}
                  color="ghost"
                >{ text.DISCARD }
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}
      />
    );
  }

  render() {
    const { actions: { closeSidepanel } } = this.props;

    return (
      <Page 
        title={this.type === 'edit' ? text.EDIT_SMS_TEMPLATE : text.CREATE_SMS_TEMPLATE} 
        bread={bread(this.type, this.id)}
      >
        <Row className="sms-template-update__row">
          <Col lg="768px">
            { this.renderForm() }
          </Col>
        </Row>
        <SmsDataTagsSidepanel
          closeSidepanel={closeSidepanel}
          api={triggerRulesApi}
        />
      </Page>
    );
  }
}

const propTypes = {
  values: object,
  actions: object.isRequired,
  submitInProgress: bool.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  match: object.isRequired,
};

const defaultProps = {
  values: {},
};

const mapStateToProps = ({ forms }) => ({
  ...forms[formID],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    create,
    openSidepanel: overlayActions.open,
    closeSidepanel: overlayActions.close,
    setFieldValue,
    resetPlaceholdersStateAction,
    fetchTemplateAction,
    update,
    setFieldsErrors,
  }, dispatch),
});

SmsTemplateUpdate.propTypes = propTypes;
SmsTemplateUpdate.defaultProps = defaultProps;

export { Field };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(SmsTemplateUpdate); 
