import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import { markPendingUser } from './Model';
import userActionTypes from '../types';
import ConfirmationFactory from '../../Confirmation';

const env = window.config.environment;

const text = {
  LABEL: gettext('Move to pending'),
  QUESTION: gettext('Are you sure you want to move this user to pending state?'),
  SUCCESS: gettext('User successfully moved to pending state.'),
  ERROR: gettext('Error while moving user.'),
};

const ACTION_ICON = 'Pending';

export default {
  name: 'moveToPending',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    if (isIb) return false;
    const hasStatePermission = state === USER_STATE.LIMITED;
    return hasAccess('user.mark_pending.create')
    && hasStatePermission
    && env === 'EagleGlobalMarkets';
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const confirmationOptions = {
      apiAction: markPendingUser,
      apiParams: [user, USER_STATE.PENDING],
      text,
      reduxActionName: 'MOVE_TO_PENDING',
    };

    const Confirmation = ConfirmationFactory(confirmationOptions);

    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(Confirmation, sidepanelOptions);
  },
};
