import React, { Component } from 'react';
import { Select, Sidepanel, Space, notifier } from 'tc-biq-design-system';
import { oneOfType, array, func, bool, object, string } from 'prop-types';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import { ClientsList, SidepanelFooter } from '../../..';

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
  fieldData: oneOfType([array, object]),
  submitInProgress: bool,
  submitSuccess: bool,
  fetchTableData: func.isRequired,
  errors: array,
  isIb: bool,
  text: object,
  gridApi: object,
  autocompleteApi: object,
  bulkApi: object,
  sidepanelName: string,
  getPayload: func,
  getError: func,
};

const defaultProps = {
  submitSuccess: false,
  errors: null,
  submitInProgress: false,
  fieldData: null,
  isIb: false,
  text: {},
  gridApi: null,
  autocompleteApi: null,
  bulkApi: null,
  sidepanelName: null,
  getPayload: null,
  getError: null,
};

const text = {
  TITLE: gettext('Edit Sales Statuses'),
  SUBMIT: gettext('Save'),
  CANCEL: gettext('Cancel'),
  PLACEHOLDER: gettext('Search...'),
  UPDATE_SUCCESS: gettext('Status updated'),
  SELECTED_USERS: gettext('Selected users'),
  BUTTON_LABELS: {
    confirm: gettext('Edit status'),
    cancel: gettext('Discard changes'),
  },
  NONE: gettext('None'),
};

const customFooter = (execute, close, submitInProgess) => (
  <SidepanelFooter 
    submitInProgress={submitInProgess}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
); 

class EditStatuses extends Component {
  constructor(props) {
    super(props);
    this.text = {
      ...text,
      ...props.text,
    };
    this.actions = props.actions;
    this.apiSalesStatuses = props.autocompleteApi;
    this.apiSalesStatusesBulk = props.bulkApi;
    this.loadOptions = this.loadOptions.bind(this);
    this.submit = this.submit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidUpdate() {
    const { submitSuccess, fetchTableData, errors } = this.props;
    if (submitSuccess) {
      notifier.success(this.text.UPDATE_SUCCESS);
      this.onClose();
      fetchTableData();
    }
    if (errors && errors.length && !errors[0].sales_status) {
      _.forEach(errors, (error) => {
        notifier.error(error.data[0]);
      });
    }
  }

  onClose() {
    const { gridApi, sidepanelName } = this.props;
    gridApi && gridApi.deselectAll();
    this.actions.closeSidepanel(sidepanelName);
    this.actions.resetInput();
  }

  loadOptions(input = '') {
    return new Promise(resolve => this.apiSalesStatuses
      .list({ is_enabled: true, name: input })
      .then(res => resolve([
        {
          id: null,
          name: this.text.NONE,
        },
        ...res.data.results,
      ])),
    );
  }

  submit() {
    const { sidepanel, fieldData, getPayload } = this.props;
    const requestData = sidepanel.parameters.map(getPayload(fieldData));

    this.actions.submit(this.apiSalesStatusesBulk, requestData);
  }

  render() {
    const { sidepanel, fieldData, submitInProgress, errors, isIb, getError } = this.props;
    const { helpText, hasError } = getError(errors);
    const { visible, parameters } = sidepanel;

    return (
      <Sidepanel
        icon="Pen"
        type="info"
        title={this.text.TITLE}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.submit, this.onClose, submitInProgress)}
      >
        <Space size={16} />
        <Select
          placeholder={this.text.PLACEHOLDER}
          type="search"
          onChange={this.actions.updateData}
          value={fieldData}
          hasError={hasError}
          helpText={helpText}
          async
          debounceInterval={500}
          loadOptions={this.loadOptions}
          valueKey="id"
          labelKey="name"
        />
        <Space size={16} />
        {visible && !_.isEmpty(parameters) ? (
          <h4>{`${this.text.SELECTED_USERS} (${parameters.length})`}</h4>
        ) : null}
        {visible && !_.isEmpty(parameters) ? (
          <ClientsList clients={parameters} hideUserState isIb={isIb} />
        ) : null}
      </Sidepanel>
    );
  }
}

EditStatuses.defaultProps = defaultProps;
EditStatuses.propTypes = propTypes;

export default EditStatuses;
