/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { notifier } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import GridFactory from '../../../../components/grid';
import Page from '../../../../components/Page';
import Export from '../../../../components/Export';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import modifier from './modifiers';
import If from '../../../../components/If';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const pageConfig = {
  apiUrl: 'kyc/exclusion',
  reducerKey: 'KYC_EXCLUSION',
  tableKey: 'KYC_EXCLUSION_TABLE',
};

const text = {
  PAGE_LABEL: gettext('Exclusion'),
  ARCHIVE_SUCCESS: gettext('Excluded user successfully archived'),
  ARCHIVE_FAILED: gettext('Failed to archive excluded user'),
};

const { GridComponent, actions } = GridFactory(pageConfig);
const bread = [{ label: text.PAGE_LABEL, route: appRoutes.KYC_EXCLUSION }];

class Exclustion extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.headerActions = this.headerActions.bind(this);
    this.tableActions = {
      ...(hasAccess('user.archive.*') && { archive: this.archive.bind(this) }),
    };
  }

  archive(data) {
    const api = getDjangoApi(`users/${data.id}/archive/`);
    api
      .create({})
      .then(
        () => notifier.success(text.ARCHIVE_SUCCESS),
        () => notifier.error(text.ARCHIVE_FAILED),
      )
      .finally(() => this.actions.fetchTableData());
  }

  headerActions() {
    const { exportUrl } = this.props;
    return (
      <If condition={hasAccess('kyc.exclusion.export.*')}>
        <Export url={exportUrl} />
      </If>
    );
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions),
    };

    return (
      <Page headerActions={this.headerActions} bread={bread} title={text.PAGE_LABEL}>
        <Filters {...filterProps} />
        <GridComponent
          tableModifier={modifier(this.tableActions)}
          singleActions={!!Object.keys(this.tableActions).length}
          {...this.props}
        />
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchTableData: actions.fetchTableData,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const table = pages[reducerKey].tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
    tableQuery: table.query,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(Exclustion); 
