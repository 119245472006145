
/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { bindActionCreators, compose } from 'redux';
import { notifier, Button } from 'tc-biq-design-system';

import connect from '../../../logic/connect';
import { gettext } from '../../../logic/utilities/languageUtility';
import GridFactory from '../../../components/grid';
import Page from '../../../components/Page';
import Export from '../../../components/Export';
import modifier from './modifier';
import overlayActions from '../../../components/overlay';
import { getActiveFilters } from '../../../components/grid/GridUtils';
import Filters from '../../../components/Filters';
import { CreateEditTask, ViewTask, UserPreview, BiqUserPreview } from '../../../components/common/sidepanels';
import { getDjangoApi } from '../../../logic/services/api-factory';
import TASK_STATES from '../../../logic/enums/task-state-label';
import to from '../../../logic/utilities/to';
import If from '../../../components/If';
import { hasAccess } from '../../../logic/services/acl';
import appRoutes from '../../../components/App/Router/appRoutes';
import withErrorBoundary from '../../../components/hoc/withErrorBoundary';

const pageConfig = {
  apiUrl: 'tasks',
  reducerKey: 'TASKS',
  tableKey: 'TASKS_TABLE',
};

const text = {
  PAGE_TITLE: gettext('Tasks'),
  DELETED: gettext('Task successfully deleted.'),
  COMPLETED: gettext('Task successfully completed.'),
  CREATE_TASK: gettext('Create task'),
  VIEW_ACTION: gettext('Preview task'),
  EDIT_ACTION: gettext('Edit task'),
  CLONE_ACTION: gettext('Clone task'),
  DELETE_ACTION: gettext('Delete task'),
  COMPLETE_ACTION: gettext('Mark as done'),
  DELETE_LABEL: gettext('Are you sure you want to delete task?'),
  COMPLETE_LABEL: gettext('Are you sure you want to complete task?'),
  CANCEL: gettext('No'),
  CONFIRM: gettext('Yes'),
};

const { GridComponent, actions } = GridFactory(pageConfig);

const bread = [{ label: gettext('Tasks'), route: appRoutes.TASKS }];

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.headerActions = this.headerActions.bind(this);
    this.MultiActions = task => [
      {
        action: this.actions.openSidepanel.bind(this, 'VIEW_TASK'),
        label: text.VIEW_ACTION,
        icon: 'View',
      },
      ...((hasAccess('tasks.update') || hasAccess('team.profile.tasks.update')) ? [{
        action: this.actions.openSidepanel.bind(this, 'CREATE_EDIT_TASK'),
        data: {
          type: 'edit',
        },
        label: text.EDIT_ACTION,
        icon: 'Edit',
      }] : []),
      ...((hasAccess('tasks.create') || hasAccess('team.profile.tasks.create')) ? [{
        action: this.actions.openSidepanel.bind(this, 'CREATE_EDIT_TASK'),
        data: {
          type: 'clone',
        },
        label: text.CLONE_ACTION,
        icon: 'Duplicate',
      }] : []),
      ...((hasAccess('tasks.destroy') || hasAccess('team.profile.tasks.destroy')) ? [{
        action: this.onDelete.bind(this),
        label: text.DELETE_ACTION,
        icon: 'Delete',
      }] : []),
      ...(task.state !== TASK_STATES.COMPLETED && (hasAccess('tasks.mark_as_completed.*') || hasAccess('team.profile.tasks.mark_as_completed.*')) ? [{
        action: this.onComplete.bind(this),
        label: text.COMPLETE_ACTION,
        icon: 'Checkmark',
      }] : []),
    ];

    this.tableActions = {
      previewUser: this.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
      previewBiqUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  async onDelete(data) {
    const { apiUrl } = pageConfig;
    const api = getDjangoApi(apiUrl);
    const [err] = await to(api.destroy(data.id));
    if (err) {
      notifier.error(err.data.msg);
    } else {
      notifier.success(text.DELETED);
      this.actions.fetchTableData();
    }
  }

  async onComplete(data) {
    const { apiUrl } = pageConfig;
    const api = getDjangoApi(apiUrl)
      .all('mark_as_completed');
    const [err] = await to(api.create({ ids: [data.id] }));
    if (err) {
      notifier.error(err.data.msg);
    } else {
      notifier.success(text.COMPLETED);
      this.actions.fetchTableData();
    }
  }

  headerActions() {
    const { exportUrl } = this.props;
    return (
      <Fragment>
        <If condition={hasAccess('tasks.create') || hasAccess('team.profile.tasks.create')}>
          <Button color="confirmation" onClick={() => this.actions.openSidepanel('CREATE_EDIT_TASK', { type: 'add' })}>
            {text.CREATE_TASK}
          </Button>
        </If>
        <If condition={hasAccess('tasks.export.*')}>
          <Export url={exportUrl} />
        </If>
      </Fragment>
    );
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.MultiActions, this.tableActions),
    };

    return (
      <Page bread={bread} headerActions={this.headerActions} title={text.PAGE_TITLE}>
        <Filters {...filterProps} />
        <GridComponent
          tableModifier={modifier(this.MultiActions, this.tableActions)}
          singleActions
          {...this.props}
        />
        <ViewTask onSuccess={actions.fetchTableData} />
        <CreateEditTask onSuccess={actions.fetchTableData} />
        <UserPreview onUserUpdate={actions.fetchTableData} />
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      openSidepanel: overlayActions.open,
      closeSidepanel: overlayActions.close,
      fetchTableData: actions.fetchTableData,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
    },
    dispatch,
  ),
});

export default {
  component: compose(
    connect(mapStateToProps, mapDispatchToProps),
    withErrorBoundary,
  )(Tasks),
  aclId: 'tasks.list',
  path: appRoutes.TASKS,
};
