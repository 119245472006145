import React from 'react';
import { Card } from 'tc-biq-design-system';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { object, string } from 'prop-types';
import { gettext } from '../../../../logic/utilities/languageUtility';
import WithdrawalDetail from './WithdrawalDetail';

const text = {
  HEADER_TITLE: gettext('Preferred withdrawal method'),
  method: gettext('Method'),
  brand: gettext('Brand'),
  card_number: gettext('Card number'),
  expiry: gettext('Expiry date'),
  funding: gettext('Funding'),
  cardholder_name: gettext('Cardholder name'),
  account_number: gettext('Account number'),
  account_holder_name: gettext('Account holder name'),
  bank_name: gettext('Bank name'),
  bank_country: gettext('Bank country'),
  bank_address: gettext('Bank address'),
  iban: gettext('Iban'),
  swift_code: gettext('Swift code'),
  sort_code: gettext('Sort code'),
  email: gettext('Email'),
  account_identifier: gettext('Account identifier'),
  first_name: gettext('First name'),
  last_name: gettext('Last name'),
  secondary_external_id: gettext('Card last digits'),
  primary_external_id: gettext('Bank account number'),
  organization: gettext('Bank name'),
  addr_line_3: gettext('Bank code'),
  account_holdername: gettext('Accountholder name'),
  email_alt: gettext('Email'),
  addr_line_2: gettext('Phone number'),
};

const isBank = (method, withdrawal) => method.toLowerCase() === 'bank' || withdrawal?.method === 'Bank';

const isCard = (method, withdrawal) => method.toLowerCase() === 'credit card' || withdrawal?.method === 'Credit Card';

const isMpesa = method => ['mpesa', 'm-pesa'].includes(method.toLowerCase());

const iseWallet = withdrawal => withdrawal.method_type === 'e-Wallet';

const iseWire = withdrawal => withdrawal.method_type === 'e-Wire';


const fields = {
  bank: [
    'method',
    'account_number',
    'account_holder_name',
    'bank_name',
    'bank_country',
    'bank_address',
    'iban',
    'swift_code',
    'sort_code',
  ],
  credit_card: [
    'method',
    'brand',
    'card_number',
    'expiry',
    'funding',
    'cardholder_name',
  ],
  wallet: [
    'method',
    'email',
    'first_name',
    'last_name',
  ],
  eWire: [
    'organization',
    'primary_external_id',
    'secondary_external_id',
  ],
  eWireMpesa: [
    'organization',
    'primary_external_id',
    'secondary_external_id',
    'addr_line_3',
    'account_holdername',
    'email_alt',
    'addr_line_2',
  ],
};

const prepareDetails = (cardInfo, type, withdrawal) => {
  let data = {};
  data.method = _.get(withdrawal, 'payment_gateway_account.method');
  data = { ...data, ...pick(cardInfo, fields[type] || []) };
  
  return data;
};

const getType = (method, withdrawal) => {
  if (isBank(method, withdrawal)) return 'bank';
  if (isCard(method, withdrawal)) return 'credit_card';
  if (iseWallet(withdrawal)) return 'wallet';
  if (iseWire(withdrawal) && isMpesa(withdrawal.method)) return 'eWireMpesa';
  if (iseWire(withdrawal)) return 'eWire';
  return '';
};


const renderDetails = cardInfo => map(cardInfo, (value, key) => (
  <WithdrawalDetail
    key={key}
    entity={key === 'email' && cardInfo?.method?.toLowerCase().indexOf('e-wallet') > -1 ? text.account_identifier : text[key]}
    value={value}
  />
));

const CreditCardDetails = ({ cardInfo = {}, withdrawal = {}, method = '' }) => {
  // eslint-disable-next-line
  const type = getType(method, withdrawal);
  if (!type) return null;

  const data = prepareDetails(cardInfo, type, withdrawal);
  if (isEmpty(data)) return null;
  
  return (
    <div className="m-b">
      <Card title={text.HEADER_TITLE}>
        <div className="biq-pending-withdrawal__card">
          <div className="biq-pending-withdrawal__details">
            {renderDetails(data)}
          </div>
        </div>
      </Card>
    </div>
  );
};

CreditCardDetails.propTypes = {
  cardInfo: object.isRequired,
  withdrawal: object.isRequired,
  method: string.isRequired,
};

export default CreditCardDetails;
