import React, { Component } from 'react';
import { Tab, Tabs, Button } from 'tc-biq-design-system';
import { object } from 'prop-types';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';
import Page from '../../../../../components/Page';
import NamesTable from './NamesTable';
import LinksTable from './LinksTable';
import TradingGroupsTable from './TradingGroupsTable';
import AccountTypesTable from './AccountTypesTable';
import { hasAccess } from '../../../../../logic/services/acl';
import { SIDEPANEL_ID as NAMES_SIDEPANEL_ID } from './NamesTable/NamesSidepanel';
import { SIDEPANEL_ID as LINKS_SIDEPANEL_ID } from './LinksTable/LinksSidepanel';
import { SIDEPANEL_ID as ACCOUNT_TYPES_SIDEPANEL_ID } from './AccountTypesTable/AccountTypesSidepanel';
import { SIDEPANEL_ID as TRADING_GROUPS_SIDEPANEL_ID } from './TradingGroupsTable/TradingGroupsSidepanel';
import PersonalForm from './PersonalForm';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const env = window.config.environment;

const text = {
  TRADING_PLATFORM: gettext('Trading platform'),
  NAMES: gettext('Names'),
  LINKS: gettext('Links'),
  TRADING_GROUPS: gettext('Trading Groups'),
  ACCOUNT_TYPES: gettext('Account types'),
  PERSONAL_DATA: gettext('Personal data'),
  CREATE_TRADING_PLATFORM: gettext('Create trading platform'),
  CREATE_LINK: gettext('Create platform link'),
  CREATE_ACCOUNT_TYPE: gettext('Create account type'),
  CREATE_TRADING_GROUP: gettext('Create trading group'),
};

const bread = [{
  label: text.TRADING_PLATFORM, 
  route: appRoutes.SETTINGS_TRADING_PLATFORMS,
}];

const tabs = {
  NAMES: 0,
  LINKS: 1,
  ACCOUNT_TYPES: 2,
  PERSONAL_DATA: 3,
  TRADING_GROUPS: 4,
};

const propTypes = {
  actions: object.isRequired,
};


class TradingPlatform extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: tabs.NAMES,
    };
  }

  openCreateForm = tab => () => {
    const { actions } = this.props;
    const SIDEPANEL_ID = ({
      [tabs.NAMES]: NAMES_SIDEPANEL_ID,
      [tabs.LINKS]: LINKS_SIDEPANEL_ID,
      [tabs.ACCOUNT_TYPES]: ACCOUNT_TYPES_SIDEPANEL_ID,
      [tabs.TRADING_GROUPS]: TRADING_GROUPS_SIDEPANEL_ID,
    })[tab];

    actions.openSidepanel(SIDEPANEL_ID);
  }

  headerActions = tab => () => ({
    [tabs.NAMES]: hasAccess('tpi.trading_backends.manage_display.create') && <Button onClick={this.openCreateForm(tab)}>{text.CREATE_TRADING_PLATFORM}</Button>,
    [tabs.LINKS]: hasAccess('tpi.trading_backends.manage_links.create') && <Button onClick={this.openCreateForm(tab)}>{text.CREATE_LINK}</Button>,
    [tabs.ACCOUNT_TYPES]: hasAccess('trading_account_labels.create') && <Button onClick={this.openCreateForm(tab)}>{text.CREATE_ACCOUNT_TYPE}</Button>,
    [tabs.TRADING_GROUPS]: hasAccess('tpi.trading_groups.create') && <Button onClick={this.openCreateForm(tab)}>{text.CREATE_TRADING_GROUP}</Button>,
  })[tab]

  loadTeam = () => {
    const { actions } = this.props;
    actions.loadTeam(this.id);
  }

  render() {
    const { activeTab } = this.state;
    
    return (
      <Page 
        title={text.TRADING_PLATFORM} 
        bread={bread}
        headerActions={this.headerActions(activeTab)}
      >
        <Tabs active={activeTab}>
          <Tab 
            visible={hasAccess('tpi.trading_backends.manage_display.list')}
            title={text.NAMES}
            onLoad={() => this.setState({ activeTab: tabs.NAMES })}
          >
            <NamesTable />
          </Tab>
          <Tab 
            visible={hasAccess('tpi.trading_backends.manage_links.list')}
            title={text.LINKS}
            onLoad={() => this.setState({ activeTab: tabs.LINKS })}
          >
            <LinksTable />
          </Tab>
          <Tab 
            visible={hasAccess('tpi.trading_groups.list')}
            title={text.TRADING_GROUPS}
            onLoad={() => this.setState({ activeTab: tabs.TRADING_GROUPS })}
          >
            <TradingGroupsTable />
          </Tab>
          <Tab 
            visible={hasAccess('trading_account_labels.list') && env === 'AvaTrade'}
            title={text.ACCOUNT_TYPES} 
            onLoad={() => this.setState({ activeTab: tabs.ACCOUNT_TYPES })}
          >
            <AccountTypesTable />
          </Tab>
          <Tab 
            visible={hasAccess('settings.tpi.personal_data_visibility.mt4.*') && isFeatureEnabled()('GDPR')}
            title={text.PERSONAL_DATA} 
            onLoad={() => this.setState({ activeTab: tabs.PERSONAL_DATA })}
          >
            <PersonalForm />
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

TradingPlatform.propTypes = propTypes;

export default TradingPlatform;
