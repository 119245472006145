import React, { Fragment } from 'react';
import { Tooltip, Icon, Popconfirm, Pill } from 'tc-biq-design-system';

import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import {
  ActionsCellWrapper,
  PreviewCell,
  StateCell,
  UsernameCell,
} from '../../../../../../components/gridCellRenderers';
import { isPDF, getSrc } from '../../../../../../logic/services/document';
import hideCols from '../../../../../../logic/utilities/hideCols';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { getStatus } from './states';

const env = window.config.environment;

const style = {
  icon: {
    position: 'relative',
    top: '2px',
  },
  label: {
    position: 'relative',
    left: '5px',
  },
};

const text = {
  ARE_YOU_SURE: gettext('Are you sure you want to delete document?'),
  CLEAR_DOC_STATE: gettext('Are you sure you want to clear document state?'),
  IGNORE_DOC: gettext('Are you sure you want to ignore document?'),
  CANCEL: gettext('Cancel'),
  CONFIRM: gettext('Delete'),
  PENDING: gettext('Pending'),
  PASSED: gettext('Approved'),
  REJECTED: gettext('Rejected'),
  FAILED: gettext('Failed'),
  SUSPICIOUS: gettext('Suspicious'),
  CAUTION: gettext('Caution'),
  ONFIDO_CHECK: gettext('Onfido check'),
  ONFIDO: gettext('Onfido'),
  GBG: gettext('GBG'),
  GBG_CHECK:gettext('GBG check'),
  APPROVE: gettext('Approve'),
  REJECT: gettext('Reject'),
  DELETE: gettext('Delete'),
  UNDO: gettext('Undo'),
  IGNORE: gettext('Ignore'),
  REVIEW: gettext('Review'),
  ACTION_DATE: gettext('Action date'),
  ACTOR: gettext('Actor'),
};

/* eslint-disable */
const OnfidoStatus = action => ({ data }) => {
  const tooltipText = env === 'ICMCapital' ? text.GBG : text.ONFIDO;
  if (!isFeatureEnabled()('ONFIDO')) return <div style={{ width: '140px' }} />;
  return (
    <div
      style={{
        display: 'flex',
        width: '140px',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: '1',
      }}
    >
      {!data.is_deleted && (
        <span
          className="m-t-xs tc-icon tc-icon-onfido"
          style={{ height: '16px', marginRight: '8px', marginBottom: '4px' }}
        />
      )}
      {!data.check_status && !data.is_deleted && data.usage === 'KYC' && (
        <Pill onClick={() => action(data.id)}>{env === 'ICMCapital'? text.GBG_CHECK : text.ONFIDO_CHECK}</Pill>
      )}
      {data.check_status === 1 && (
        <Tooltip title={tooltipText} placement="top">
          <span className="text-status02-600 tc-mono-strong">{text.PENDING}</span>
        </Tooltip>
      )}
      {data.check_status === 2 && (
        <Tooltip title={tooltipText} placement="top">
          <span className="text-status01-600 tc-mono-strong">{text.PASSED}</span>
        </Tooltip>
      )}
      {data.check_status === 3 && (
        <Tooltip title={tooltipText} placement="top">
          <span className="text-status04-600 tc-mono-strong">{text.REJECTED}</span>
        </Tooltip>
      )}
      {data.check_status === 4 && (
        <Tooltip title={tooltipText} placement="top">
          <span className="text-status03-600 tc-mono-strong">{text.FAILED}</span>
        </Tooltip>
      )}
      {data.check_status === 5 && (
        <Tooltip title={tooltipText} placement="top">
          <span className="text-status03-600 tc-mono-strong">{text.SUSPICIOUS}</span>
        </Tooltip>
      )}
      {data.check_status === 6 && (
        <Tooltip title={tooltipText} placement="top">
          <span className="text-status03-600 tc-mono-strong">{text.CAUTION}</span>
        </Tooltip>
      )}
    </div>
  );
};
/* eslint-enable */

// eslint-disable-next-line
const DocumentType =  (accounts, openTradingAccount) => ({ value, data }) => {
  if (!data.object_id) return <span>{value}</span>;
  const account = _.find(accounts, { id: data.object_id });
  if (account) {
    return (
      <Fragment>
        <div>{value}</div>
        <span onClick={() => openTradingAccount(data.object_id)} className="like-a-link">{`(${account.external_id}, ${account.backend.name})`}</span>
      </Fragment>
    );
  }
  return value;
};

const actionIcons = {
  review: (actionHandler, data) => getStatus(data.status).isActionAllowed('review')
    && !data.is_deleted && (
      <div
        key="REVIEW"
        onClick={() => actionHandler({ document: data })}
      >
        <Tooltip title={text.REVIEW} placement="top">
          <Icon name="View" colorName="text-status01-400" />
        </Tooltip>
      </div>
  ),
  undo: (actionHandler, data) => getStatus(data.status).isActionAllowed('undo')
    && !data.is_deleted && actionHandler && (
      <Tooltip title={text.UNDO} key="undo">
        <Popconfirm
          type="primary"
          icon="Pending"
          label={text.CLEAR_DOC_STATE}
          onConfirm={() => actionHandler(data)}
          buttonLabels={{
            cancel: text.CANCEL,
            confirm: text.UNDO,
          }}
        >
          <Icon name="Pending" />
        </Popconfirm>
      </Tooltip>
  ),
  ignore: (actionHandler, data) => getStatus(data.status).isActionAllowed('ignore')
    && !data.is_deleted && (
      <Tooltip title={text.IGNORE} key="ignore">
        <Popconfirm
          type="primary"
          icon="Close"
          label={text.IGNORE_DOC}
          onConfirm={() => actionHandler(data)}
          buttonLabels={{
            cancel: text.CANCEL,
            confirm: text.IGNORE,
          }}
        >
          <Icon name="Close" />
        </Popconfirm>
      </Tooltip>
  ),
  deleteDocument: (actionHandler, data) => getStatus(data.status).isActionAllowed('deleteDocument') && !data.is_deleted && (
    <Tooltip title={text.DELETE} key="delete">
      <Popconfirm
        type="destructive"
        icon="Delete"
        label={text.ARE_YOU_SURE}
        onConfirm={() => actionHandler(data)}
        buttonLabels={{
          cancel: text.CANCEL,
          confirm: text.CONFIRM,
        }}
      >
        <Icon name="Delete" />
      </Popconfirm>
    </Tooltip>
  ),
};

const renderName = (data) => {
  if (!data.status) return data.name;
  const { icon, color } = getStatus(data.status);

  return (
    <div>
      <span style={style.icon}>
        <Icon name={icon} colorName={`text-${color}`} />
      </span>
      <span style={style.label}>{data.name}</span>
    </div>
  );
};

// eslint-disable-next-line
const DocumentName = onPreview => ({ data }) => (
  <PreviewCell
    onPreview={onPreview}
    data={{ action: 'preview', document: data }}
    // eslint-disable-next-line react/prop-types
    href={isPDF(data.file_name) ? getSrc(data) : null}
  >
    {renderName(data)}
  </PreviewCell>
);

const StatusCell = ({ value }) => {
  const { label } = getStatus(value);
  return StateCell({ value: label });
};

export default ({ previewUser, preview, review, ignore, undo, deleteDocument, onfidoCheck }, accounts, openTradingAccount) => ({
  ...hideCols(['id', 'user', 'object_id', 'related_to']),
  status_changed_at: {
    headerName: text.ACTION_DATE,
    sortValue: 12,
  },
  verified_by: {
    headerName: text.ACTOR,
    cellRendererFramework: UsernameCell(previewUser, { displayKey: 'username', isStuff: true }),
    sortValue: 13,
  },
  name: {
    cellRendererFramework: DocumentName(preview),
  },
  check_status: {
    label: env === 'ICMCapital' ? text.GBG : text.ONFIDO,
    cellRendererFramework: OnfidoStatus(onfidoCheck),
  },
  document_type: {
    cellRendererFramework: DocumentType(accounts, openTradingAccount),
    sortValue: -2,
  },
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper(
      { deleteDocument, review, ignore, undo },
      actionIcons,
    ),
    pinned: 'right',
    width: 120,
  },
  status: { cellRendererFramework: StatusCell },
});
