/* eslint react/prop-types: 0 */
import React from 'react';
import { HyperLink, Pill, Tooltip, Icon, Popconfirm, Checkbox } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import hideCols from '../../../logic/utilities/hideCols';
import { gettext } from '../../../logic/utilities/languageUtility';
import { ActionsCellWrapper } from '../../../components/gridCellRenderers';
import appRoutes from '../../../components/App/Router/appRoutes';

const text = {
  NONE: gettext('None'),
  ACCESS_RIGHTS_UPDATE: gettext('Update access rights'),
  DELETE: gettext('Delete team'),
  MODIFY: gettext('Update team'),
  CLONE: gettext('Clone team'),
  POPCONFIRM_LABEL: gettext('Are you sure you want to delete this team?'),
  YES: gettext('Confirm'),
  NO: gettext('Cancel'),
  SALES: gettext('Sales'),
  RETENTION: gettext('Retention'),
};

const ParentCell = ({ value }) => {
  const type = value ? 'primary' : 'status04';
  const name = value ? value.name : text.NONE;
  const href = value ? generatePath(appRoutes.TEAM, { id: value.id }) : null;

  return (
    <Link to={href}>
      <Pill startPropagation type={type}>{name}</Pill>
    </Link>
  );
};


const iconStyle = {
  cursor: 'pointer',
};

const actionIcons = (onAccessRightsUpdate, onModify, onClone, onDelete) => ({
  ...(onAccessRightsUpdate && {
    onAccessRightsUpdate: (actionHandler, data) => (
      <div key="AccessRightsUpdate">
        <Link to={actionHandler(data.id)}>
          <Tooltip title={text.ACCESS_RIGHTS_UPDATE} placement="top">
            <Icon name="Pen" />
          </Tooltip>
        </Link>
      </div>
    ),
  }),
  ...(onModify && {
    onModify: (actionHandler, data) => (
      <div style={iconStyle} key="OnModify" onClick={() => actionHandler(data)}>
        <Tooltip title={text.MODIFY} placement="top">
          <Icon name="Edit" />
        </Tooltip>
      </div>
    ),
  }),
  ...(onClone && {
    onClone: (actionHandler, data) => (
      <div style={iconStyle} key="OnClone" onClick={() => actionHandler(data)}>
        <Tooltip title={text.CLONE} placement="top">
          <Icon name="Duplicate" />
        </Tooltip>
      </div>
    ),
  }),
  ...(onDelete && {
    onDelete: (actionHandler, data) => (
      <Tooltip title={text.DELETE} placement="top">
        <Popconfirm
          type="destructive"
          icon="Delete"
          onConfirm={() => actionHandler(data)}
          label={text.POPCONFIRM_LABEL}
          buttonLabels={{ cancel: text.NO, confirm: text.YES }}
        >
          <span style={iconStyle}>
            <Icon name="Delete" />
          </span>
        </Popconfirm>
      </Tooltip>
    ),
  }),
});

const modifier = ({ onAccessRightsUpdate, onModify, onClone, onDelete, onCheckRetentionSales }) => {
  const actions = {
    ...(onAccessRightsUpdate && { onAccessRightsUpdate }),
    ...(onModify && { onModify }),
    ...(onClone && { onClone }),
    ...(onDelete && { onDelete }),
  };

  const env = window.config.environment;
  return {
    ...hideCols(env !== 'AvaTrade' ? ['id', 'sales', 'retention'] : ['id']),
    name: {
      width: 150,
      cellRendererFramework: ({ value, data }) => (
        <HyperLink>
          <Link to={generatePath(appRoutes.TEAM, { id: data.id })}>{value}</Link>
        </HyperLink>
      ),
    },
    parent: {
      width: 150,
      cellRendererFramework: ParentCell,
    },
    sales: {
      width: 150,
      headerName: text.SALES,
      cellRendererFramework: ({ data, value, node }) => (
        <Checkbox checked={value} onChange={e => onCheckRetentionSales('sales', data, node, e)} />
      ),
    },
    retention: {
      width: 150,
      headerName: text.RETENTION,
      cellRendererFramework: ({ data, value, node }) => (
        <Checkbox checked={value} onChange={e => onCheckRetentionSales('retention', data, node, e)} />
      ),
    },
    actions: {
      label: '',
      key: 'actions',
      pinned: 'right',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(
        actions,
        actionIcons(onAccessRightsUpdate, onModify, onClone, onDelete),
      ),
      width: 120,
    },
  };
};

export default modifier;
