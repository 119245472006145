import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  ADD_USER_TO_BLACKLIST: 'ADD_USER_TO_BLACKLIST',
  ADD_USER_TO_BLACKLIST_FULFILLED: 'ADD_USER_TO_BLACKLIST_FULFILLED',
  ADD_USER_TO_BLACKLIST_PENDING: 'ADD_USER_TO_BLACKLIST_PENDING',
  ADD_USER_TO_BLACKLIST_REJECTED: 'ADD_USER_TO_BLACKLIST_REJECTED',
};

export const addUserToBlacklist = createAction(actionTypes.ADD_USER_TO_BLACKLIST, async (user) => {
  const blacklistApi = getDjangoApi(`users/${user.id}/add_to_blacklist`);
  
  await blacklistApi.create({});
});


const initialState = {
  submitInProgress: false,
  errors: null,
};

const pendingHandler = state => ({ ...state, submitInProgress: true });

const fulfilledHandler = state => ({
  ...state,
  submitInProgress: false,
  error: null,
});

const rejectedHandler = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  errors: payload.data,
});

export default handleActions(
  {
    [actionTypes.ADD_USER_TO_BLACKLIST_PENDING]: pendingHandler,
    [actionTypes.ADD_USER_TO_BLACKLIST_FULFILLED]: fulfilledHandler,
    [actionTypes.ADD_USER_TO_BLACKLIST_REJECTED]: rejectedHandler,
  },
  initialState,
);
