import { createAction } from 'redux-actions';

import FormActionTypesFactory from './actionTypesFactory';
import { formatRequestData } from './utils';
import fieldPropsResolver from './fieldPropsResolver';
import store from '../../../logic/store';
import { flattenNestedFields } from '../../../logic/services/query-adapter';

const overrideFields = (fields, customFields) => {
  const merged = [...fields, ...customFields];
  const hashedObject = merged.reduce((acc, field) => ({ ...acc, [field.id]: field }), {});
  return Object.keys(hashedObject).map(key => hashedObject[key]);
};

export const FormActionsFactory = (form) => {
  const {
    FORM_OPTIONS,
    FORM_RESET,
    FORM_RESET_VALUES,
    FORM_SET_ERRORS,
    FORM_SET_FIELD_VALUE,
    FORM_SET_FIELDS,
    FORM_CREATE,
    FORM_UPDATE,
    FORM_SET_ROW_DATA,
    FORM_SHOW_LOADER,
    FORM_SET_VALIDATORS,
    FORM_VALIDATE,
  } = FormActionTypesFactory(form);

  const fetchFields = createAction(FORM_OPTIONS, async (api, customFields = [], renderNested = false) => {
    const optionsResponse = await api.options();
    const { PUT, POST } = optionsResponse.data.actions;
    const fields = PUT ? PUT.fields : POST.fields;
    const flatFields = renderNested ? flattenNestedFields(fields, '.') : fields;
    return overrideFields(flatFields.map(fieldPropsResolver), customFields);
  });

  const resetFields = createAction(FORM_RESET);
  const resetValues = createAction(FORM_RESET_VALUES);
  const setFieldValue = createAction(FORM_SET_FIELD_VALUE, fieldValue => fieldValue);
  const setRowData = createAction(FORM_SET_ROW_DATA, data => data);
  const setFieldsErrors = createAction(FORM_SET_ERRORS, errors => ({ errors }));
  const setFields = createAction(FORM_SET_FIELDS, fields => fields);
  const showLoader = createAction(FORM_SHOW_LOADER, show => show);
  const setValidators = createAction(FORM_SET_VALIDATORS, validators => validators);

  const isDirty = (dirty, key) => dirty[key];

  const getRequestPayload = (customFormatter) => {
    const { values, dirty } = store.getState().forms[form];
    const dirtyValues = _.pickBy(values, (_, key) => isDirty(dirty, key));

    return customFormatter && typeof customFormatter === 'function'
      ? customFormatter(values, dirtyValues)
      : formatRequestData(values);
  };

  const create = createAction(FORM_CREATE, async (api, customFormatter, shouldResetValuesOnSuccess = true) => {
    const payload = getRequestPayload(customFormatter);
    const isUploadRequest = !!api.upload;
    const res = isUploadRequest ? await api.upload(payload) : await api.create(payload);
    // handle upload errors
    if (api.upload && !res.ok) {
      const data = await res.json();
      // eslint-disable-next-line no-throw-literal
      throw { data };
    }
    
    const { data } = res;
    
    return { response: data, shouldResetValuesOnSuccess };
  });

  const update = createAction(FORM_UPDATE, async (api, id, customFormatter, updatePart = false) => {
    const payload = getRequestPayload(customFormatter, updatePart);
    const { data } = await api[updatePart ? 'updatePart' : 'update'](id, payload);
    return { response: data };
  });

  const validator = createAction(FORM_VALIDATE, () => {
    const { values = {}, validators = {} } = store.getState().forms[form] || {};
    
    const errors = Object.keys(validators).reduce((acc, fieldKey) => {
      const fieldValidators = validators[fieldKey];
      const fieldErrors = [];
      fieldValidators.forEach((fieldValidator) => {
        if (fieldValidator.exec && !fieldValidator.exec(values[fieldKey], values)) {
          fieldErrors.push(fieldValidator.message);
        }
      });

      if (_.isEmpty(fieldErrors)) return acc;

      return {
        ...acc,
        [fieldKey]: fieldErrors,
      };
    }, {});

    return errors;
  });

  return {
    fetchFields,
    resetFields,
    resetValues,
    setFieldsErrors,
    setFieldValue,
    setRowData,
    setFields,
    create,
    update,
    showLoader,
    setValidators,
    validator,
  };
};

export default FormActionsFactory;
