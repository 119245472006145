import features from '../logic/constants/features';

if (_.includes(features, 'ZENDESK')) {
  console.log('sendesk active');
  /* eslint-disable */
  window.zEmbed || (function (e, n) {
    let t; let o; let d; let s; let i; const a = []; const 
      r = document.createElement('iframe');
    window.zEmbed = function () {
      a.push(arguments);
    }, window.zE = window.zE || window.zEmbed, r.src = 'javascript:false', r.title = '', r.role = 'presentation', (r.frameElement || r).style.cssText = 'display: none', d = document.getElementsByTagName('script'), d = d[d.length - 1], d.parentNode.insertBefore(r, d), s = r.contentWindow, i = s.document;
    try {
      o = i;
    } catch (e) {
      t = document.domain, r.src = `javascript:var d=document.open();d.domain="${t}";void(0);`, o = i;
    }
    o.open()._l = function () {
      const e = this.createElement('script');
      t && (this.domain = t), e.id = 'js-iframe-async', e.src = 'https://assets.zendesk.com/embeddable_framework/main.js', this.t = +new Date(), this.zendeskHost = 'tradecore.zendesk.com', this.zEQueue = a, this.body.appendChild(e);
    }, o.write('<body onload="document._l();">'), o.close();
  }('https://assets.zendesk.com/embeddable_framework/main.js', 'tradecore.zendesk.com'));
}
