/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Tabs, Tab, Button, notifier } from 'tc-biq-design-system';

import { bindActionCreators, compose } from 'redux';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import Page from '../../../../../components/Page';
import GridComponentFactory from '../../../../../components/grid';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import withTable, { GridTab } from '../../../../../components/grid/withTable';
import { methodsModifier, mappingsModifier } from './modifier';
import { BiqUserPreview } from '../../../../../components/common/sidepanels';
import PaymentMethodForm from './sidepanels/PaymentMethodForm';
import { hasAccess } from '../../../../../logic/services/acl';
import PaymentMappingForm from './sidepanels/PaymentMappingForm/PaymentMappingForm';
import GridActionsFactory from '../../../../../components/grid/GridActionsFactory';
import to from '../../../../../logic/utilities/to';
import appRoutes from '../../../../../components/App/Router/appRoutes';
import { getActiveFilters } from '../../../../../components/grid/GridUtils';
import Filters from '../../../../../components/Filters';
import connect from '../../../../../logic/connect';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import overlayActions from '../../../../../components/overlay';

 
const text = {
  PAGE_TITLE: gettext('Payment methods'),
  METHODS: gettext('Methods'),
  MAPPINGS: gettext('Mappings'),
  CREATE_NEW_METHOD: gettext('Create payment method'),
  SUBMIT_ERROR: gettext('Error while updating mapping'),
  SUBMIT_SUCCESS: gettext('Mapping updated successfully'),
  INTRO: gettext('Gateway = Praxis, Identifier field (DMN) = PaymentMethod'),
};

const PRAXIPAY = 'praxispay';

const bread = [{ label: text.PAGE_TITLE, route: appRoutes.SETTINGS_PAYMENT_METHODS }];

const reducerKey = 'PAYMENT_METHODS';

const apiPaymentGateways = getDjangoApi('payment_gateways');
const apiMapping = pgid => getDjangoApi(`payment_gateways/${pgid}/identifiers`);

const methodsConfig = () => ({
  reducerKey,
  apiUrl: 'payment_methods',
  tableKey: 'METHODS',
});


const mappingsConfig = id => ({
  reducerKey,
  apiUrl: `payment_gateways/${id}/identifiers`,
  tableKey: 'MAPPINGS',
});

const getTableActions = (tableConfig, id) => {
  const config = tableConfig(id);
  const api = getDjangoApi(config.apiUrl);
  return GridActionsFactory(config.tableKey, api);
};

const { 
  GridComponent: MethodsTable,
  actions: methodActions,
} = GridComponentFactory(methodsConfig());

class PaymentMethods extends Component {
  constructor(props) {
    super(props);

    const { actions } = props;

    this.methodTableActions = {
      onModify: hasAccess('payment_methods.update') && this.onModifyMethod,
      onBiqUserPreview: actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };

    this.mappingTableActions = {
      onModify: hasAccess('payment_gateways.identifiers.update') && this.onModifyMapping,
      onRemove: hasAccess('payment_gateways.identifiers.update') && this.onRemoveIdentifier,
    };

    this.state = {
      active: methodsConfig().tableKey,
    };
  }

  componentDidMount() {
    apiPaymentGateways.list({ name: PRAXIPAY })
      .then(({ data }) => {
        this.pgid = _.get(data, 'results[0].id');
        this.MappingsTable = withTable(GridTab, mappingsConfig, null, null)(this.pgid);
      });
  }

  onModifyMethod = (data) => {
    const { actions } = this.props;
    actions.openSidepanel('PAYMENT_METHOD_FORM', { type: 'edit', data });
  }

  onModifyMapping = (data) => {
    const { actions } = this.props;
    const { pgid } = this;
    actions.openSidepanel('PAYMENT_MAPPING_FORM', { pgid, data });
  }

  onRemoveIdentifier = async (method, identifier) => {
    const { pgid } = this;
    const { id, identifiers } = method;
    const remainingIdentifiers = identifiers.filter(i => i !== identifier);
    const request = apiMapping(pgid).updatePart(id, { identifiers: remainingIdentifiers });

    const [err, res] = await to(request);
    if (err) notifier.error(text.SUBMIT_ERROR);
    if (res) {
      notifier.success(text.SUBMIT_SUCCESS);
      this.onSuccessMappingRequest();
    }
  }

  onSuccessRequest = () => {
    const { dispatch } = this.props;
    dispatch(methodActions.fetchTableData());
  }

  onSuccessMappingRequest = () => {
    const { dispatch } = this.props;
    const actions = getTableActions(mappingsConfig, this.pgid);
    dispatch(actions.fetchTableData());
  }

  setActiveTab = (active) => {
    this.setState({ active });
  }

  createNewMethod = () => {
    const { actions } = this.props;

    return (
      hasAccess('payment_methods.create') && (
        <Button onClick={() => actions.openSidepanel('PAYMENT_METHOD_FORM', { type: 'add' })}>
          {text.CREATE_NEW_METHOD}
        </Button>
      )
    );
  }

  render() {
    const { active } = this.state;
    const { MappingsTable } = this;
    const { fields, table, actions } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    
    const filters = getActiveFilters(table);
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
    };


    const headerActions = active === methodsConfig().tableKey 
      ? this.createNewMethod
      : null;

    return (
      <Page active={active} headerActions={headerActions} bread={bread} title={text.PAGE_TITLE}>
        <Tabs>
          <Tab 
            visible={hasAccess('payment_methods.list')}
            title={text.METHODS} 
            onLoad={() => this.setActiveTab(methodsConfig().tableKey)}
          >
            <Filters {...filterProps} />
            <MethodsTable
              disableQuickFilter
              singleActions
              tableModifier={methodsModifier(this.methodTableActions)}
              {...this.props}
            />
          </Tab>
          <Tab 
            visible={hasAccess('payment_gateways.identifiers.list')}
            title={text.MAPPINGS} 
            onLoad={() => this.setActiveTab(mappingsConfig().tableKey)}
          >
            <p className="tc-paragraph-strong">{text.INTRO}</p>
            {MappingsTable && (
              <MappingsTable
                disableQuickFilter
                singleActions
                modifier={mappingsModifier(this.mappingTableActions)}
              />
            )}
          </Tab>
        </Tabs>
        <BiqUserPreview />
        <PaymentMethodForm onSuccessRequest={this.onSuccessRequest} />
        <PaymentMappingForm onSuccessRequest={this.onSuccessMappingRequest} />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = methodsConfig();
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchTableData: methodActions.fetchTableData,
    updateRowData: methodActions.updateRowData,
    deleteRow: methodActions.deleteRow,
    updateFilter: methodActions.updateFilter,
    changeSegment: methodActions.changeSegment,
    fetchOptions: methodActions.fetchOptions,
    openSidepanel: overlayActions.open,
  }, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(PaymentMethods); 
