export default class ErrorHandler {
  constructor({ errors, fields }) {
    this.errors = errors;
    this.fields = fields;
  }

  extractMessages() {
    const errorKeys = Object.keys(this.errors);
    const fieldKeys = this.fields.map(field => field.id);

    return errorKeys
      .filter(key => !fieldKeys.includes(key))
      .map(key => this.errors[key]);
  }

  showMessages(renderer) {
    this.extractMessages().forEach(renderer);
  }
}
