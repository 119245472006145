import React, { Component, Fragment } from 'react';
import { Select, Space, Pill, Input } from 'tc-biq-design-system';
import { object, array, bool, string } from 'prop-types';

import TogglePanel from '../../../../../../../../components/TogglePanel';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { getConvertedAmountLimits, getDefaultCurrency } from '../../../../../../withdrawal-single/Components/ExecuteWithdrawal/logic/utils';

import './CurrencyConverter.scss';

const text = {
  TITLE: gettext('Credit Currency Conversion'),
  TITLE_DEBIT: gettext('Debit Currency Conversion'),
  ORIGINAL_CURRENCY: gettext('Original currency'),
  CONVERTED_CURRENCY: gettext('Converted Currency'),
  EXCHANGE_RATE: gettext('Exchange rate'),
  CONVERTED_AMOUNT: gettext('Converted Amount'),
  CONVERTED_AMOUNT_HELP: (min, max) => gettext('Min Amount: {{min}}; Max Amount: {{max}}', { min, max }),
};

const fields = {
  IS_CONVERSION_REQUIRED: 'is_conversion_required',
  DEPOSIT_CURRENCY: 'deposit_currency',
  IS_CONVERTED_AMOUNT_CHANGED: 'is_converted_amount_changed',
  CONVERTED_AMOUNT: 'converted_amount',
  ORIGINAL_CONVERTED_AMOUNT: 'original_converted_amount',
  EXCHANGE_RATE: 'exchange_rate',
  ORIGINAL_EXCHANGE_RATE: 'original_exchange_rate',
};

const propTypes = {
  actions: object.isRequired,
  currencies: array.isRequired,
  formValues: object.isRequired,
  tradingAccounts: array.isRequired,
  user: object.isRequired,
  isIb: bool.isRequired,
  rate: string,
  isCredit: bool,
  formErrors: object,
};

const defaultProps = {
  rate: '',
  isCredit: true,
  formErrors: {},
};

class CurrencyConverter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountCurrency: null,
    };
    this.actions = props.actions;
    this.onCurrencyChange = this.onCurrencyChange.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onConvertedAmountChange = this.onConvertedAmountChange.bind(this);
  }

  componentDidMount() {
    const { user, currencies, isCredit } = this.props;
    const defaultCurrency = getDefaultCurrency({ user, currencies, isCredit });
    this.setDepositCurrency(defaultCurrency.symbol);
  }

  componentDidUpdate(prevProps) {
    this.setupCurrencies(prevProps);
    this.onAmountChange(prevProps);
  }

  onAmountChange(prevProps) {
    const prevAmount = _.get(prevProps, 'formValues.amount');
    const amount = _.get(this.props, 'formValues.amount');
    
    if (prevAmount !== amount) {
      const rate = _.get(this.props, 'formValues.original_exchange_rate');
      this.setIsConvertedAmounthChanged(false);
      this.setOriginalConvertedAmount(rate, amount);
    }
  }

  onCurrencyChange(option) {
    const depositCurrency = option.symbol;
    this.setDepositCurrency(depositCurrency);
  }

  onToggle(checked) {
    this.actions.setFieldValue({
      id: fields.IS_CONVERSION_REQUIRED,
      value: checked,
    });
  }

  onConvertedAmountChange(e) {
    const convertedAmount = e.target.value;
    const { formValues } = this.props;
    const { amount } = formValues;
    const rate = Number(convertedAmount / amount);

    this.setConvertedAmount(convertedAmount);
    this.setIsConvertedAmounthChanged(true);
    this.setExchangeRate(rate);
  }

  setupCurrencies(prevProps) {
    const prevFormValues = prevProps.formValues;
    const { accountCurrency } = this.state;
    const { 
      formValues,  
      tradingAccounts,
    } = this.props;

    const prevTradingAccountId = _.get(prevFormValues, 'trading_account.value');
    const tradingAccountId = _.get(formValues, 'trading_account.value');
    
    if ((prevTradingAccountId !== tradingAccountId || !accountCurrency) && tradingAccounts.length > 0) {
      const selectedTradingAccount = tradingAccounts.find(account => account.id === tradingAccountId);
      const currency = _.get(selectedTradingAccount, 'group.currency');
      this.setAccountCurrency(currency);
    }
  }

  setDepositCurrency(currency) {
    const {
      accountCurrency,
    } = this.state;

    this.actions.setFieldValue({
      id: fields.DEPOSIT_CURRENCY,
      value: currency,
    });

    this.setConversionRate(currency, accountCurrency);
  }

  setAccountCurrency(currency) {
    const { formValues } = this.props;
    const { deposit_currency } = formValues;

    this.setState({
      accountCurrency: currency,
    });

    this.setConversionRate(deposit_currency, currency);
  }

  setConversionRate(depositCurrency, accountCurrency) {
    const { user, isIb, isCredit } = this.props;
    
    if (depositCurrency && accountCurrency) {
      this.actions.fetchRate(depositCurrency, accountCurrency, user, isIb, isCredit).then(() => {
        const { rate, formValues } = this.props;
        const roundedRate = _.round(+rate, 5);
        this.setIsConvertedAmounthChanged(false);
        this.setOriginalExchangeRate(roundedRate);
        this.setOriginalConvertedAmount(roundedRate, formValues.amount);
      });
    }
  }

  setExchangeRate(rate) {
    this.actions.setFieldValue({
      id: fields.EXCHANGE_RATE,
      value: rate,
    });
  }

  setOriginalExchangeRate(rate) {
    this.actions.setFieldValue({
      id: fields.ORIGINAL_EXCHANGE_RATE,
      value: rate,
    });
    this.setExchangeRate(rate);
  }

  setConvertedAmount(value) {
    this.actions.setFieldValue({
      id: fields.CONVERTED_AMOUNT,
      value,
    });
  }

  setIsConvertedAmounthChanged(value) {
    this.actions.setFieldValue({
      id: fields.IS_CONVERTED_AMOUNT_CHANGED,
      value,
    });
  }

  setOriginalConvertedAmount(rate, amount) {
    if (rate && amount) {
      const convertedAmount = _.round((Number(amount * rate)), 3);

      // vm.exchange_rate = vm.originalAmount && Number((vm.converted_amount / vm.originalAmount).toFixed(5));

      this.actions.setFieldValue({
        id: fields.ORIGINAL_CONVERTED_AMOUNT,
        value: convertedAmount,
      });

      this.setConvertedAmount(convertedAmount);
    }
  }

  getConvertedAmountLimitsText = (amount) => {
    const { min, max } = getConvertedAmountLimits(amount);

    if (!min || !max) return null;
 
    return text.CONVERTED_AMOUNT_HELP(min, max);
  }

  render() {
    const { 
      currencies, 
      formValues, 
      isCredit,
      formErrors,
    } = this.props;

    const {
      converted_amount: convertedAmountError,
    } = formErrors;

    const {
      accountCurrency,
    } = this.state;


    const {
      is_conversion_required,
      deposit_currency,
      converted_amount,
      original_converted_amount,
      original_exchange_rate,
      is_converted_amount_changed,
      exchange_rate,
    } = formValues;

    const convertedAmount = is_converted_amount_changed ? converted_amount : original_converted_amount;
    const exchangeRate = is_converted_amount_changed ? exchange_rate : original_exchange_rate;
    const convertedRate = _.round(+exchangeRate, 5);
    const selectedCurrency = { symbol: deposit_currency };
    const currencyLabel = isCredit ? text.ORIGINAL_CURRENCY : text.CONVERTED_CURRENCY;
    const title = isCredit ? text.TITLE : text.TITLE_DEBIT;
    const convertedAmountLimits = this.getConvertedAmountLimitsText(original_converted_amount);
    const quoteCurrency = isCredit ? accountCurrency : deposit_currency;

    return (
      <TogglePanel 
        title={title} 
        checked={is_conversion_required}
        onToggle={this.onToggle}
      >
        {is_conversion_required && (
        <Fragment>
          <Select 
            label={currencyLabel} 
            onChange={this.onCurrencyChange}
            value={selectedCurrency}
            options={currencies}
            valueKey="symbol"
            labelKey="symbol"
          />
          <Space size={16} />
          <div>{text.EXCHANGE_RATE}: <b>{convertedRate}</b></div>
          <Space size={16} />
          <div className="biq-currency-converter__converted">
            <div className="biq-currency-converter__input">
              <Input 
                label={text.CONVERTED_AMOUNT}
                type="number"
                value={convertedAmount}
                onChange={this.onConvertedAmountChange}
                hasError={!!convertedAmountError}
                helpText={convertedAmountError}
              />
            </div>
            {quoteCurrency && (
            <div className="biq-currency-converter__currency">
              <Pill big>{quoteCurrency}</Pill>
            </div>
            )}
          </div>
          <Space size={16} />
          <div>{convertedAmountLimits}</div>
        </Fragment>
        )}
      </TogglePanel>
    );
  }
}

CurrencyConverter.propTypes = propTypes;
CurrencyConverter.defaultProps = defaultProps;

export default CurrencyConverter;
