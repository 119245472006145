import React, { PureComponent } from 'react';
import { Button, Spinner, Tabs, Tab } from 'tc-biq-design-system';
import { object, array, bool, func } from 'prop-types';
import { bindActionCreators, compose } from 'redux';

import connect from '../../../../../logic/connect';
import { actions as documentsActions } from './Model';
import overlayActions from '../../../../../components/overlay';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { hasAccess } from '../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';
import If from '../../../../../components/If';
import Page from '../../../../../components/Page';
import DocumentsHeader from './DocumentsHeader';
import DocumentsBody from './DocumentsBody';
import CreateEditDocument from './CreateEditDocument';
import withTable, { GridTab } from '../../../../../components/grid/withTable';
import reasonsModifier from './reasonsModifier';
import RejectionReasonModal, { modalModes, MODAL_ID } from '../../../../../components/common/modals/RejectReason/RejectionReasonModal';

import './Documents.scss';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const propTypes = {
  actions: object.isRequired,
  isLoading: bool.isRequired,
  documents: array.isRequired,
  docTypes: array.isRequired,
  sidepanel: object.isRequired,
  dispatch: func.isRequired,
};

const text = {
  PAGE_LABEL: gettext('Documents'),
  BUTTON_LABEL: gettext('Create document'),
  DOCUMENT_TYPES: gettext('Document types'),
  REJECTION_REASONS: gettext('Rejection reasons'),
  UPDATE_SUCCESS: gettext('Successfully updated'),
  CREATE_REJECTION_REASON: gettext('Create reject reason'),
};
export const SIDEPANEL_ID = 'CREATE_EDIT_DOCUMENT';
const tableReducerKey = 'REJECTION_REASONS';

const bread = [{ label: text.PAGE_LABEL, route: appRoutes.SETTINGS_DOCUMENTS }];

const rejectionReasonApi = getDjangoApi('document_rejection_reasons');

const tabs = {
  DOCUMENT_TYPES: {
    value: 0,
    title: text.DOCUMENT_TYPES,
  },
  REJECTION_REASONS: {
    value: 1,
    title: text.REJECTION_REASONS,
  },
};

const reasonsGridConfig = () => ({
  reducerKey: 'DOCUMENTS',
  apiUrl: 'document_rejection_reasons',
  tableKey: tableReducerKey,
});

class Documents extends PureComponent {
  constructor(props) {
    super(props);
    this.api = getDjangoApi('document_presets');
    this.isDocumentRejectReasonEnabled = isFeatureEnabled()('DOCUMENT_REJECTION_REASONS');
    this.Grid = withTable(GridTab, reasonsGridConfig, null, { withActions: true })();
    this.state = {
      activeTab: tabs.DOCUMENT_TYPES.value,
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.loadDocuments();
  }

  onSuccessRejectReason = () => {
    const { dispatch } = this.props;
    const [, gridActions] = this.Grid;
    dispatch(gridActions.fetchTableData());
  }

  setActiveTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  }

  toggleIsActive = ({ id, is_active }) => {
    const { updateRowData } = this.Grid[1];
    const { dispatch } = this.props;
    dispatch(updateRowData(id, {
      is_active: !is_active,
    }));
  }
  
  createReason = () => {
    const { actions } = this.props;
    actions.openSidepanel(MODAL_ID, { reasonId: null, mode: modalModes.CREATE });
  }

  editReason = (id) => {
    const { actions } = this.props;
    actions.openSidepanel(MODAL_ID, { reasonId: id, mode: modalModes.EDIT });
  }

  headerActions = () => {
    const { actions } = this.props;
    const { activeTab } = this.state;
    
    if (activeTab === tabs.DOCUMENT_TYPES.value && hasAccess('document_types.create')) {
      return <Button onClick={() => actions.openSidepanel(SIDEPANEL_ID)}>{text.BUTTON_LABEL}</Button>;
    } if (activeTab === tabs.REJECTION_REASONS.value 
      && hasAccess('document_rejection_reasons.create') 
      && this.isDocumentRejectReasonEnabled) {
      return (
        <Button onClick={this.createReason}>
          { text.CREATE_REJECTION_REASON }
        </Button>
      );
    }

    return null;
  };

  render() {
    const {
      isLoading,
      documents,
      docTypes,
      sidepanel,
    } = this.props;
    const { toggleIsActive } = this;
    const showDocuments = !!documents.length && !!docTypes.length;
    const [ReasonsTable] = this.Grid;

    return (
      <Page
        headerActions={this.headerActions}
        bread={bread}
        title={text.PAGE_LABEL}
      >
        <Tabs>
          <Tab 
            onLoad={() => { this.setActiveTab(tabs.DOCUMENT_TYPES.value); }}
            title={tabs.DOCUMENT_TYPES.title}
          >
            <div className="biq-settings-documents">
              <DocumentsHeader />
              {isLoading && !showDocuments && (
              <div className="biq-settings-documents__spinner">
                <Spinner size="large" />
              </div>
              )}
              {showDocuments && <DocumentsBody />}
            </div>
            <If condition={sidepanel.visible}>
              <CreateEditDocument />
            </If>
          </Tab>
          <Tab
            visible={this.isDocumentRejectReasonEnabled}
            title={tabs.REJECTION_REASONS.title}
            onLoad={() => {
              this.setActiveTab(tabs.REJECTION_REASONS.value);
            }}
          >
            <ReasonsTable 
              modifier={reasonsModifier({ toggleIsActive, editReason: this.editReason })}
              singleActions
            />
            <RejectionReasonModal
              onSuccess={this.onSuccessRejectReason}
              api={rejectionReasonApi}
            />
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

Documents.propTypes = propTypes;

const mapStateToProps = ({ pages, overlays }) => {
  const { 
    isLoading,
    documents,
    docTypes, 
  } = pages.DOCUMENTS.documentsReducer;
  
  const sidepanel = overlays[SIDEPANEL_ID];
  
  return { 
    isLoading, 
    documents, 
    docTypes, 
    sidepanel,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      loadDocuments: documentsActions.loadDocuments,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(Documents);
