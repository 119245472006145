import React from 'react';
import { object } from 'prop-types';
import { Tooltip, Popconfirm, Icon } from 'tc-biq-design-system';
import { Link } from 'react-router-dom';

import hideCols from '../../../../../logic/utilities/hideCols';
import { ToggleCell, ActionsCellWrapper, UsernameCell } from '../../../../../components/gridCellRenderers';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { millisecondsToTimeframeModel } from '../../../../../components/form/Components/Inputs/TimeFrame';

const renderTimeframe = ({ data }) => {
  const { delay, type } = millisecondsToTimeframeModel(data.timeframe);
  return <span>{delay} {type.display_name}</span>;
};

renderTimeframe.propTypes = {
  data: object.isRequired,
};

const text = {
  POPCONFIRM_LABEL: gettext('Are you sure you want to delete this rule?'),
  NO: gettext('No'),
  YES: gettext('Yes'),
  MODIFY: gettext('Edit'),
  CLONE: gettext('Clone'),
  DELETE: gettext('Delete'),
};

const actionIcons = (onModify, onDelete, onClone) => ({
  ...(onModify && {
    onModify: (actionHandler, data) => {
      const url = actionHandler(data);
      return (
        <div key="Modify">
          <Link to={url}>
            <Tooltip title={text.MODIFY} placement="top">
              <Icon name="Edit" />
            </Tooltip>
          </Link>
        </div>
      );
    },
  }),
  ...(onDelete && {
    onDelete: (actionHandler, data) => (
      <Tooltip title={text.DELETE} placement="top">
        <Popconfirm
          type="destructive"
          icon="Delete"
          onConfirm={() => actionHandler(data)}
          label={text.POPCONFIRM_LABEL}
          buttonLabels={{ cancel: text.NO, confirm: text.YES }}
        >
          <Icon name="Delete" />
        </Popconfirm>
      </Tooltip>
    ),
  }),
  ...(onClone && {
    onClone: (actionHandler, data) => {
      const url = actionHandler(data);
      return (
        <div key="Clone">
          <Link to={url}>
            <Tooltip title={text.CLONE} placement="top">
              <Icon name="Duplicate" />
            </Tooltip>
          </Link>
        </div>
      );
    },
  }),
});


export default ({ toggleEnable, onDelete, onModify, onClone, previewBiqUser }) => {
  const actions = {
    ...(onModify && { onModify }),
    ...(onDelete && { onDelete }),
    ...(onClone && { onClone }),
  };
  return { ...hideCols(['id']),
    ...(toggleEnable && {
      enabled: {
        width: 75,
        pinned: 'left',
        cellRendererFramework: ToggleCell(toggleEnable),
      },
    }),
    modified_by: {
      cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
    },
    created_by: {
      cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
    },
    timeframe: {
      cellRendererFramework: renderTimeframe,
    },
    actions: {
      label: gettext('Actions'),
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(onModify, onDelete, onClone)),
      width: 120,
      pinned: 'right',
    },
  };
};
