import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import overlayActions from '../../../../../components/overlay';
import bulkActionsActionFactory from '../../../../../components/common/sidepanels/bulk/actions';
import ClientsAssignCage from './ClientsAssignCage';


const { 
  updateData, 
  resetInput, 
  submit, 
  loadOptions, 
} = bulkActionsActionFactory('CLIENTS_ASSIGN_CAGE');


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateData,
      resetInput,
      submit,
      loadOptions,
      closeSidepanel: overlayActions.close,
      updateSidepanel: overlayActions.update,
    },
    dispatch,
  ),
});
  
const mapStateToProps = ({ overlays, pages }) => {
  const assignCage = pages.CLIENTS.bulkActions.ASSIGN_CAGE;
  
  return {
    sidepanel: overlays.ASSIGN_CAGE,
    fieldData: assignCage.fieldData,
    submitInProgress: assignCage.submitInProgress,
    submitSuccess: assignCage.submitSuccess,
    errors: assignCage.errors,
    options: assignCage.options,
  };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientsAssignCage);
