import React from 'react';
import { Checkbox } from 'tc-biq-design-system';
import { string, func, bool, oneOfType, any } from 'prop-types';

const propTypes = {
  name: string,
  value: oneOfType([bool, string]),
  onChange: func.isRequired,
  id: string.isRequired,
  label: string.isRequired,
  actionComponent: any,
};

const defaultProps = {
  value: false,
  name: null,
  actionComponent: null,
};

const CheckboxField = ({ onChange, name, value, id, label, actionComponent }) => (
  <div className="biq-form__field">
    <Checkbox name={name} checked={!!value} onChange={e => onChange({ id, value: e.target.checked })}>
      {label}
    </Checkbox>
    {actionComponent}
  </div>
);

CheckboxField.propTypes = propTypes;
CheckboxField.defaultProps = defaultProps;

export default CheckboxField;
