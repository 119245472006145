import appRoutes from '../../../../../../../../components/App/Router/appRoutes';
import isFeatureEnabled from '../../../../../../../../logic/filters/is-feature-enabled';
import { hasAccess } from '../../../../../../../../logic/services/acl';
import MailchimpIntegration from './MailchimpIntegration';

export default {
  component: MailchimpIntegration,
  aclFn: () => isFeatureEnabled()('MAILCHIMP_INTEGRATION') && hasAccess('integration.mailchimp.lists.list'),
  path: appRoutes.SETTINGS_INTEGRATIONS_MAILCHIMP,
};
