import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { refactoredInjectRules, setupFields } from '../../../../../logic/services/query-adapter';

const actionTypes = {
  GET_PENDING_DEPOSIT_RULE_FIELDS_OPTIONS: 'GET_PENDING_DEPOSIT_RULE_FIELDS_OPTIONS',
  GET_PENDING_DEPOSIT_RULE_FIELDS_OPTIONS_PENDING: 'GET_PENDING_DEPOSIT_RULE_FIELDS_OPTIONS_PENDING',
  GET_PENDING_DEPOSIT_RULE_FIELDS_OPTIONS_REJECTED: 'GET_PENDING_DEPOSIT_RULE_FIELDS_OPTIONS_REJECTED',
  GET_PENDING_DEPOSIT_RULE_FIELDS_OPTIONS_FULFILLED: 'GET_PENDING_DEPOSIT_RULE_FIELDS_OPTIONS_FULFILLED',
  GET_PENDING_DEPOSIT_RULE_INFO: 'GET_PENDING_DEPOSIT_RULE_INFO',
  GET_PENDING_DEPOSIT_RULE_INFO_PENDING: 'GET_PENDING_DEPOSIT_RULE_INFO_PENDING',
  GET_PENDING_DEPOSIT_RULE_INFO_REJECTED: 'GET_PENDING_DEPOSIT_RULE_INFO_REJECTED',
  GET_PENDING_DEPOSIT_RULE_INFO_FULFILLED: 'GET_PENDING_DEPOSIT_RULE_INFO_FULFILLED',
  SUBMIT_PENDING_DEPOSIT_RULE: 'SUBMIT_PENDING_DEPOSIT_RULE',
  SUBMIT_PENDING_DEPOSIT_RULE_PENDING: 'SUBMIT_PENDING_DEPOSIT_RULE_PENDING',
  SUBMIT_PENDING_DEPOSIT_RULE_REJECTED: 'SUBMIT_PENDING_DEPOSIT_RULE_REJECTED',
  SUBMIT_PENDING_DEPOSIT_RULE_FULFILLED: 'SUBMIT_PENDING_DEPOSIT_RULE_FULFILLED',
  SET_PENDING_DEPOSIT_RULE_NAME: 'SET_PENDING_DEPOSIT_RULE_NAME',
  SET_PENDING_DEPOSIT_RULE_CONDITION: 'SET_PENDING_DEPOSIT_RULE_CONDITION',
  RESET_PENDING_DEPOSIT_RULE_PAGE_STATE: 'RESET_PENDING_DEPOSIT_RULE_PAGE_STATE',
};

const initialState = {
  submitInProgress: false,
  fields: [],
  name: '',
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  errors: {
    name: [''],
  },
};

const pendingDepositRuleAPI = getDjangoApi('pending_deposit_rules');

const getFieldsOptionsHandler = (state, { payload }) => {
  const { fields } = payload.data.event_spec;
  const mappedFields = setupFields(fields);

  return {
    ...state,
    fields: mappedFields,
  };
};

const getPendingDepositInfoHandler = (state, { payload }) => {
  const { condition, name } = payload.data;
  const group = refactoredInjectRules(state.fields, condition);

  return {
    ...state,
    group,
    name,
  };
};

const setRuleNameHandler = (state, { payload }) => (
  {
    ...state,
    name: payload,
    errors: { ...state.errors, name: [''] },
  }
);

const setRuleConditionHandler = (state, { payload }) => (
  {
    ...state,
    group: { ...payload },
  }
);

const submitDepositRulePendingHandler = state => (
  {
    ...state,
    submitInProgress: true,
  }
);

const submitDepositRuleSuccessHandler = state => (
  {
    ...state,
    submitInProgress: false,
  }
);

const submitDepositRuleErrorHandler = (state, { payload }) => (
  {
    ...state,
    errors: { ...state.errors, ...payload.data },
    submitInProgress: false,
  }
);

const resetStateHandler = () => (
  {
    ...initialState,
  }
);

export const getFieldsOptions = createAction(
  actionTypes.GET_PENDING_DEPOSIT_RULE_FIELDS_OPTIONS,
  async () => pendingDepositRuleAPI.options(),
);

export const getPendingDepositInfo = createAction(
  actionTypes.GET_PENDING_DEPOSIT_RULE_INFO,
  async id => pendingDepositRuleAPI.retrieve(id),
);

export const setRuleName = createAction(
  actionTypes.SET_PENDING_DEPOSIT_RULE_NAME,
  event => event.target.value,
);

export const setRuleCondition = createAction(
  actionTypes.SET_PENDING_DEPOSIT_RULE_CONDITION,
  group => group,
);

export const submitDepositRule = createAction(
  actionTypes.SUBMIT_PENDING_DEPOSIT_RULE,
  async (isEdit, request, id) => (
    isEdit ? pendingDepositRuleAPI.updatePart(id, request) : pendingDepositRuleAPI.create(request)
  ),
);

export const resetState = createAction(
  actionTypes.RESET_PENDING_DEPOSIT_RULE_PAGE_STATE,
);

export const actions = {
  getFieldsOptions,
  getPendingDepositInfo,
  setRuleName,
  setRuleCondition,
  submitDepositRule,
  resetState,
};

export default handleActions(
  {
    [actionTypes.GET_PENDING_DEPOSIT_RULE_FIELDS_OPTIONS_FULFILLED]: getFieldsOptionsHandler,
    [actionTypes.GET_PENDING_DEPOSIT_RULE_INFO_FULFILLED]: getPendingDepositInfoHandler,
    [actionTypes.SET_PENDING_DEPOSIT_RULE_NAME]: setRuleNameHandler,
    [actionTypes.SET_PENDING_DEPOSIT_RULE_CONDITION]: setRuleConditionHandler,
    [actionTypes.SUBMIT_PENDING_DEPOSIT_RULE_PENDING]: submitDepositRulePendingHandler,
    [actionTypes.SUBMIT_PENDING_DEPOSIT_RULE_REJECTED]: submitDepositRuleErrorHandler,
    [actionTypes.SUBMIT_PENDING_DEPOSIT_RULE_FULFILLED]: submitDepositRuleSuccessHandler,
    [actionTypes.RESET_PENDING_DEPOSIT_RULE_PAGE_STATE]: resetStateHandler,
  },
  initialState,
);
