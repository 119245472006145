import React, { Component } from 'react';
import { Row, Col, Space, Button, notifier } from 'tc-biq-design-system';
import { object, bool, arrayOf, shape, func } from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { generatePath, withRouter } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import Page from '../../../../components/Page';
import FormFactory, { FormActionsFactory } from '../../../../components/form';
import DataTagsSidepanel, { sidepanelID } from '../DataTagsSidepanel';
import { Field } from '../formUtils';
import HtmlPreviewModal from './HtmlPreviewModal';
import TemplateGroupModal from './TemplateGroupModal';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import to from '../../../../logic/utilities/to';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import overlayActions from '../../../../components/overlay';
import { resetPlaceholdersStateAction } from '../UpdateTemplateModel';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

import './EmailTemplateUpdate.scss';
import getID from '../../../../logic/utilities/getID';

const text = {
  CREATE_EMAIL_TEMPLATE: gettext('Create email template'),
  COPY_EMAIL_TEMPLATE: gettext('Copy email template'),
  EDIT_EMAIL_TEMPLATE: gettext('Edit email template'),
  EMAIL_TEMPLATES: gettext('Email templates'),
  ADD_NEW: gettext('Add new'),
  TEXT: gettext('Text/HTML'),
  DATA_TAGS: gettext('Data tags'),
  NONE: gettext('None'),
  TEXT_HTML: gettext('Text/HTML'),
  DISCARD: gettext('Discard'),
  CREATE_TEMPLATE: gettext('Create template'),
  COPY_TEMPLATE: gettext('Copy template'),
  EDIT_TEMPLATE: gettext('Edit template'),
  PREVIEW: gettext('Preview'),
  SUBJECT: gettext('Subject'),
  LANGUAGE: gettext('Language'),
  BASE_GROUP: gettext('Base group'),
  REPLY_TO_EMAIL: gettext('Reply to email'),
  FROM_EMAIL: gettext('From email'),
  CONTENT_ERROR: gettext('This field may not be blank.'),
  CREATE_SUCCESS: gettext('Successfully created'),
  EDIT_SUCCESS: gettext('Successfully edited'),
  WHITELABEL: gettext('Whitelabel'),
  NAME: gettext('Name'),
  TYPE: gettext('Type'),
};

const emailActorNone = {
  value: null,
  display_name: text.NONE,
};

function getLabel(type, isButton) {
  if (type === 'edit') return isButton ? text.EDIT_TEMPLATE : text.EDIT_EMAIL_TEMPLATE;
  if (type === 'clone') return isButton ? text.COPY_TEMPLATE : text.COPY_EMAIL_TEMPLATE;
  return isButton ? text.CREATE_TEMPLATE : text.CREATE_EMAIL_TEMPLATE;
}

const bread = (type, tid) => [
  {
    label: text.EMAIL_TEMPLATES, route: appRoutes.EMAIL_TEMPLATES },
  { 
    label: getLabel(type),
    params: { id: tid || '' },
    route: '',
  },
];

const formID = 'EMAIL_TEMPLATE_FORM';
const marketingApi = getDjangoApi('emails/marketing');
const languagesApi = getDjangoApi('emails/marketing/languages');
const { setFieldValue, create, setFieldsErrors, update } = FormActionsFactory(formID);
const contentApi = id => getDjangoApi(`emails/marketing/${id}/content`);
const triggerEmailApi = getDjangoApi('emails/trigger_rules');
const loadWhitelabelOptions = (input = '') => getDjangoApi('autocomplete/whitelabel')
  .list({ name: input })
  .then(res => res.data.results);
const loadLanguages = () => languagesApi.list().then(res => res.data);

const applicationTypes = {
  BODY: {
    value: 0,
    display_name: 'Body',
  },
  BASE: { value: 1, display_name: 'Base' },
  CUSTOM: { value: 2, display_name: 'Custom' },
};

const fieldsEnum = {
  subject: 'subject',
  language: 'language',
  base_group: 'base_group',
  reply_to_email: 'reply_to_email',
  from_email: 'from_email',
  content: 'content',
  whitelabel: 'whitelabel',
  application: 'application',
  name: 'name',
  business_group: 'business_group',
  template_groups: 'template_groups',
};


const formConfig = {
  form: formID,
  api: marketingApi,
  customFields: [
    {
      id: fieldsEnum.subject,
      label: text.SUBJECT,
      placeholder: text.SUBJECT,
      type: 'text',
    },
    {
      id: fieldsEnum.language,
      label: text.LANGUAGE,
      type: 'select',
      async: true,
      valueKey: 'value',
      labelKey: 'display_name',
      debounceInterval: 500,
      loadOptions: loadLanguages,
    },
    {
      id: fieldsEnum.base_group,
      label: text.BASE_GROUP,
      type: 'select',
      async: true,
      valueKey: 'value',
      labelKey: 'display_name',
      debounceInterval: 500,
      loadOptions: () => marketingApi
        .options()
        .then(res => res.data.actions.POST.fields.find(f => f.key === 'template_groups').child.choices),
    },
    {
      id: fieldsEnum.reply_to_email,
      async: true,
      label: text.REPLY_TO_EMAIL,
      valueKey: 'value',
      type: 'select',
      labelKey: 'display_name',
      debounceInterval: 500,
      loadOptions: () => marketingApi
        .options()
        .then(res => [emailActorNone, ...res.data.actions.POST.fields.find(f => f.key === 'reply_to_email').choices]),
    },
    {
      id: fieldsEnum.from_email,
      async: true,
      label: text.FROM_EMAIL,
      valueKey: 'value',
      type: 'select',
      labelKey: 'display_name',
      debounceInterval: 500,
      loadOptions: () => marketingApi
        .options()
        .then(res => [emailActorNone, ...res.data.actions.POST.fields.find(f => f.key === 'from_email').choices]),
    },
    {
      id: fieldsEnum.content,
      type: 'textarea',
      label: text.TEXT_HTML,
    },
    {
      id: fieldsEnum.whitelabel,
      name: fieldsEnum.whitelabel,
      type: 'select',
      label: text.WHITELABEL,
      placeholder: text.WHITELABEL,
      debounceInterval: 500,
      loadOptions: loadWhitelabelOptions,
      async: true,
      joinValues: true,
      labelKey: 'name',
      valueKey: 'id',
      dataKeys: 'name',
    },
  ],
};

const formModifier = {
  type: { label: text.NAME },
  application: { label: text.TYPE },
};


class EmailTemplateUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openPreviewModal: false,
      openBaseModal: false,
    };
    this.FormComponent = null;
    this.languageValueMap = {};
    this.languages = [];
    this.editedLanguagesSet = new Set();
    this.type = this.getType();
    
    if (this.type !== 'create') this.id = props.match.params.id;
  }

  componentDidMount() {
    languagesApi.list().then((res) => { 
      this.languages = res.data;
    
      if (this.id) {
        this.fetchTemplate(this.id);  
      }
    }); 
  }

  componentDidUpdate(prevProps) {
    const { isLoading: oldIsLoading, values: oldValues } = prevProps;
    const { isLoading, values, submitInProgress, actions } = this.props;

    if (submitInProgress) {
      return;
    }

    if (oldIsLoading && !isLoading && !values.application && this.type !== 'edit') {
      this.setDefaultValues();
    }

    if (values.name !== oldValues.name && values.language) {
      this.languageValueMap[values.language.value].name = values.name;
    }

    if (values.subject !== oldValues.subject && values.language) {
      this.languageValueMap[values.language.value].subject = values.subject;
    }

    if (values.content !== oldValues.content && values.language) {
      this.languageValueMap[values.language.value].content = values.content;
    }

    if (values.language && !isEqual(oldValues.language, values.language)) {
      this.setFieldValues(values.language.value);
    }

    if (oldValues.application && !values.application) {
      actions.setFieldValue({
        id: fieldsEnum.application,
        value: oldValues.application,
      });
    }
  }

  onSuccess(message) {
    notifier.success(message);
    setTimeout(() => this.goToEmailTemplates(), 3000);
  }

  getType = () => {
    const pathRoutes = getID();

    if (_.includes(pathRoutes, 'add-new')) {
      return pathRoutes[pathRoutes.length - 1] === 'add-new' ? 'create' : 'clone';
    } 
      
    return 'edit';
  }

  setDefaultValues = () => {
    const { actions, fields } = this.props;
    const base_group = fields.find(field => field.id === 'base_group');
    const language = fields.find(field => field.id === 'language');
    const application = fields.find(field => field.id === 'application');

    base_group.loadOptions().then((choices) => {
      actions.setFieldValue({
        id: fieldsEnum.base_group,
        value: choices[0],
      });
    });

    language.loadOptions().then((choices) => {
      actions.setFieldValue({
        id: fieldsEnum.language,
        value: choices[0],
      });
      this.languageValueMap[choices[0].value] = {};
    });

    actions.setFieldValue({
      id: fieldsEnum.application,
      value: application.options[0],
    });

    actions.setFieldValue({
      id: fieldsEnum.from_email,
      value: emailActorNone,
    });

    actions.setFieldValue({
      id: fieldsEnum.reply_to_email,
      value: emailActorNone,
    });
  };

  setFieldValues = (lang) => {
    const { actions } = this.props;
    const emptyValue = '';

    if (!this.languageValueMap[lang]) {
      this.languageValueMap[lang] = {
        content: emptyValue,
        subject: emptyValue,
      };
    }

    const fieldKeys = Object.keys(this.languageValueMap[lang]);

    fieldKeys.forEach((key) => {
      actions.setFieldValue({
        id: key,
        value: this.languageValueMap[lang][key],
      });
    });
  };

  fetchTemplate = (id) => {
    const templatePromise = marketingApi.retrieve(id);
    const marketingOptionsPromise = marketingApi.options();
    const contentPromise = contentApi(id).retrieve();
    const laguagePromise = loadLanguages();
    const { actions } = this.props;

    Promise.all([
      templatePromise,
      contentPromise,
      marketingOptionsPromise,
      laguagePromise,
    ])
      .then(([templateRes, contentRes, marketingOptionsRes, languages]) => {
        const { fields } = marketingOptionsRes.data.actions.POST;
        const templateGroupsChoices = fields.find(f => f.key === fieldsEnum.template_groups).child.choices;
        const replyToEmailChoices = fields.find(f => f.key === fieldsEnum.reply_to_email).choices;
        const fromEmailChoices = fields.find(f => f.key === fieldsEnum.from_email).choices;
        const appKeys = Object.keys(applicationTypes);
        const appKey = appKeys.find(key => applicationTypes[key].display_name === templateRes.data.application);

        actions.setFieldValue({
          id: fieldsEnum.name,
          value: templateRes.data.name,
        });

        actions.setFieldValue({
          id: fieldsEnum.application,
          value: applicationTypes[appKey],
        });

        const replyTo = replyToEmailChoices.find(r => r.display_name === templateRes.data.reply_to_email);
        const from = fromEmailChoices.find(f => f.display_name === templateRes.data.from_email);

        actions.setFieldValue({
          id: fieldsEnum.reply_to_email,
          value: replyTo || emailActorNone,
        });

        actions.setFieldValue({
          id: fieldsEnum.from_email,
          value: from || emailActorNone,
        });

        actions.setFieldValue({
          id: fieldsEnum.whitelabel,
          value: templateRes.data.whitelabel ? templateRes.data.whitelabel : null,
        });
        
        actions.setFieldValue({
          id: fieldsEnum.business_group,
          value: templateRes.data.business_group ? templateRes.data.business_group : null,
        });

        actions.setFieldValue({
          id: fieldsEnum.base_group,
          value: templateRes.data.template_groups && templateRes.data.template_groups.length ? { 
            value: templateRes.data.template_groups[0].id,
            display_name: templateRes.data.template_groups[0].name,
          } : templateGroupsChoices[0],
        });

        this.languageValueMap = contentRes
          .data
          .results
          .reduce((acc, template) => {
            this.editedLanguagesSet.add(template.language);
            return {
              ...acc,
              [template.language]: {
                content: template.content,
                id: template.id,
                subject: template.subject,
              },
            };
          }, {});

        actions.setFieldValue({
          id: fieldsEnum.language,
          value: languages[0],
        });

        this.setFieldValues(languages[0].value);
      });
  }

  openTags = () => {
    const { actions } = this.props;
    actions.openSidepanel(sidepanelID);
  }

  openPreviewModal = () => {
    this.setState({
      openPreviewModal: true,
    });
  }

  closePreviewModal = () => {
    this.setState({
      openPreviewModal: false,
    });
  }

  openBaseModal = () => {
    this.setState({ openBaseModal: true });
  };

  closeBaseModal = () => {
    this.setState({ openBaseModal: false });
  }

  goToEmailTemplates = () => {
    const { history } = this.props;
    history.push(generatePath(appRoutes.EMAIL_TEMPLATES));
  }

  updateEmailTemplateWithBodyApplication = async () => {
    const { actions, values } = this.props;
    const templateId = this.id;
 
    if (!values.name) {
      actions.setFieldsErrors({
        name: !values.name ? [text.CONTENT_ERROR] : null,
      });
    }

    const langWithEmptySubject = Object.keys(this.languageValueMap)
      .find(key => this.languageValueMap[key].content
         && !this.languageValueMap[key].subject
         && !this.editedLanguagesSet.has(key));

    if (langWithEmptySubject) {
      this.setFieldValues(langWithEmptySubject);

      const lang = this.languages.find(l => l.value === langWithEmptySubject);

      actions.setFieldValue({
        id: fieldsEnum.language,
        value: lang,
      });

      actions.setFieldsErrors({
        [fieldsEnum.subject]: [text.CONTENT_ERROR],
      });

      return;
    }  

    const [, emailResponse] = await to(actions.update(marketingApi, templateId, () => ({
      application: values.application.value,
      from_email: values.from_email.value,
      name: values.name,
      reply_to_email: values.reply_to_email.value,
      type: 2,
    })));  

    if (emailResponse) {
      const promises = Object.keys(this.languageValueMap)
        .map((key) => {
          const langId = this.languageValueMap[key].id;
          if (this.editedLanguagesSet.has(key)) {
            if (!this.languageValueMap[key].content || !this.languageValueMap[key].subject) {
              return contentApi(templateId).destroy(langId);
            }
            return actions.update(contentApi(templateId), langId, () => ({
              content: this.languageValueMap[key].content,
              subject: this.languageValueMap[key].subject,
            }), true);
          }
          
          if (this.languageValueMap[key].content && this.languageValueMap[key].subject) {
            return actions.create(contentApi(templateId), () => ({
              content: this.languageValueMap[key].content,
              subject: this.languageValueMap[key].subject,
              language: key,
              template: templateId,
            })); 
          }
          return null;
        });
      
      const [error] = await to(Promise.all(promises)); 
      
      if (!error) {
        this.onSuccess(text.EDIT_SUCCESS);
      }
    }
  };

  createEmailTemplateWithBodyApplication = async () => {
    const { actions, values } = this.props;


    const arrayOfTemplates = Object
      .keys(this.languageValueMap)
      .map(lang => ({
        language: lang,
        name: values.name,
        content: this.languageValueMap[lang].content,
        subject: this.languageValueMap[lang].subject,
      })).filter(template => !!template.content && !!template.subject);
   
    if (!values.name) {
      actions.setFieldsErrors({
        name: !values.name ? [text.CONTENT_ERROR] : null,
      });

      return;   
    }

    const [, emailResponse] = await to(actions.create(marketingApi, () => ({
      application: values.application.value,
      from_email: values.from_email.value,
      name: values.name,
      reply_to_email: values.reply_to_email.value,
      type: 2,
    })));

    if (emailResponse) {
      const { id } = emailResponse.value.response;
      const promises = arrayOfTemplates
        .map(template => ({ ...template, template: id }))
        .map(template => actions.create(
          contentApi(id),
          () => ({ ...template }),
        ));

      const [error] = await to(Promise.all(promises));

      if (!error) {
        this.onSuccess(text.CREATE_SUCCESS);
      }
    }
  };

  updateEmailTemplateWithBaseApplication = async () => {
    const { actions, values } = this.props;
    const templateId = this.id;
 
    if (!values.name) {
      actions.setFieldsErrors({
        name: !values.name ? [text.CONTENT_ERROR] : null,
      });
    }  

    const [, emailResponse] = await to(actions.update(marketingApi, templateId, () => ({
      application: values.application.value,
      from_email: null,
      name: values.name,
      reply_to_email: null,
      whitelabel: values.whitelabel ? values.whitelabel.name : null,
      business_group: values.business_group ? values.business_group.name : null,
      template_groups: [values.base_group.value],
    })));

    if (emailResponse) {
      const promises = Object.keys(this.languageValueMap)
        .map((key) => {
          const langId = this.languageValueMap[key].id;
          if (this.editedLanguagesSet.has(key)) {
            if (!this.languageValueMap[key].content) {
              return contentApi(templateId).destroy(langId);
            }
            return actions.update(contentApi(templateId), langId, () => ({
              content: this.languageValueMap[key].content,
            }), true);
          }
          
          if (this.languageValueMap[key].content) {
            return actions.create(contentApi(templateId), () => ({
              content: this.languageValueMap[key].content,
              language: key,
              template: templateId,
            })); 
          }
          return null;
        });
      
      const [error] = await to(Promise.all(promises)); 
      
      if (!error) {
        this.onSuccess(text.EDIT_SUCCESS);
      }
    }
  }  

  createEmailTemplateWithBaseApplication = async () => {
    const { actions, values } = this.props;
    
    const arrayOfTemplates = Object
      .keys(this.languageValueMap)
      .map(lang => ({
        language: lang,
        name: values.name,
        content: this.languageValueMap[lang].content,
      })).filter(template => !!template.content); 

    if (!values.name) {
      actions.setFieldsErrors({
        name: !values.name ? [text.CONTENT_ERROR] : null,
      });

      return;   
    }

    const [, emailResponse] = await to(actions.create(marketingApi, () => ({
      application: values.application.value,
      from_email: null,
      name: values.name,
      reply_to_email: null,
      whitelabel: values.whitelabel ? values.whitelabel.name : null,
      business_group: values.business_group ? values.business_group.name : null,
      template_groups: [values.base_group.value],
    })));

    if (emailResponse) {
      const { id } = emailResponse.value.response;
      
      const promises = arrayOfTemplates
        .map(template => ({ ...template, template: id }))
        .map(template => actions.create(
          contentApi(id),
          () => ({ ...template }),
        ));

      const [error] = await to(Promise.all(promises));
      if (!error) {
        this.onSuccess(text.CREATE_SUCCESS);
      }
    }
  }

  updateEmailTemplateWithCustomApplication = async () => {
    const { actions, values } = this.props;
    const templateId = this.id;
 
    if (!values.name) {
      actions.setFieldsErrors({
        name: !values.name ? [text.CONTENT_ERROR] : null,
      });
    }  

    const [, emailResponse] = await to(actions.update(marketingApi, templateId, () => ({
      application: values.application.value,
      name: values.name,
    })));

    if (emailResponse) {
      const promises = Object.keys(this.languageValueMap)
        .map((key) => {
          const langId = this.languageValueMap[key].id;
          if (this.editedLanguagesSet.has(key)) {
            if (!this.languageValueMap[key].content) {
              return contentApi(templateId).destroy(langId);
            }
            return actions.update(contentApi(templateId), langId, () => ({
              content: this.languageValueMap[key].content,
            }), true);
          }
          
          if (this.languageValueMap[key].content) {
            return actions.create(contentApi(templateId), () => ({
              content: this.languageValueMap[key].content,
              language: key,
              template: templateId,
            })); 
          }
          return null;
        });
      
      const [error] = await to(Promise.all(promises)); 
      
      if (!error) {
        this.onSuccess(text.EDIT_SUCCESS);
      }
    }
  }  


  createEmailTemplateWithCustomApplication = async () => {
    const { actions, values } = this.props;

    const arrayOfTemplates = Object
      .keys(this.languageValueMap)
      .map(lang => ({
        language: lang,
        name: values.name,
        content: this.languageValueMap[lang].content,
      })).filter(template => !!template.content); 

    if (!values.name) {
      actions.setFieldsErrors({
        name: !values.name ? [text.CONTENT_ERROR] : null,
      });

      return;   
    }

    const [, emailResponse] = await to(actions.create(marketingApi, () => ({
      name: values.name,
    })));

    if (emailResponse) {
      const { id } = emailResponse.value.response;
      
      const promises = arrayOfTemplates
        .map(template => ({ ...template, template: id }))
        .map(template => actions.create(
          contentApi(id),
          () => ({ ...template }),
        ));

      const [error] = await to(Promise.all(promises));

      if (!error) {
        this.onSuccess(text.CREATE_SUCCESS);
      }
    }
  }

  createEmailTemplate = () => {
    const { actions, values } = this.props;
    actions.closeSidepanel(sidepanelID);
    resetPlaceholdersStateAction();

    if (values.application.value === applicationTypes.BODY.value) {
      this.createEmailTemplateWithBodyApplication();
    }

    if (values.application.value === applicationTypes.BASE.value) {
      this.createEmailTemplateWithBaseApplication();
    }

    if (values.application.value === applicationTypes.CUSTOM.value) {
      this.createEmailTemplateWithCustomApplication();
    }
  };

  updateEmailTemplate = () => {
    const { actions, values } = this.props;

    actions.closeSidepanel(sidepanelID);
    resetPlaceholdersStateAction();

    if (values.application.value === applicationTypes.BODY.value) {
      this.updateEmailTemplateWithBodyApplication();
    }
    
    if (values.application.value === applicationTypes.BASE.value) {
      this.updateEmailTemplateWithBaseApplication();
    }

    if (values.application.value === applicationTypes.CUSTOM.value) {
      this.updateEmailTemplateWithCustomApplication();
    }
  }

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }

    const { FormComponent } = this;
    const { values, submitInProgress } = this.props;
    const { openPreviewModal } = this;
    const isApplicationType = type => !!values.application && values.application.value === type;
    return (
      <FormComponent
        modifiers={formModifier}
        renderForm={fields => (
          <div className="email-template-update__card">
            <div className="email-template-update__card-header">
              {
            isApplicationType(applicationTypes.BODY.value) && (
              <React.Fragment>
                <Field field={fields.name} />
                <div className="email-template-update__card-body-info">
                  <div>
                    <Field field={fields.application} props={{ clearable: false }} />
                    <Field field={fields.language} props={{ clearable: false }} />
                  </div>
                  <div>
                    <Field field={fields.from_email} props={{ clearable: false }} />
                    <Field props={{ style: { marginRight: 0 }, clearable: false }} field={fields.reply_to_email} />
                  </div>
                </div>
                <Space size={24} />
                <Field field={fields.subject} />
              </React.Fragment>
            )
           }
              {
            isApplicationType(applicationTypes.BASE.value) && (
              <React.Fragment>
                <Field field={fields.name} />
                <div className="email-template-update__card-body-info">
                  <div>
                    <Field field={fields.application} props={{ clearable: false }} />
                    <Field field={fields.language} props={{ clearable: false }} />
                  </div>
                </div>
                <Field field={fields.whitelabel} />
                <Field field={fields.business_group} />
                <div className="email-template-update__card-base-info">
                  <Field field={fields.base_group} props={{ clearable: false }} />
                  <Button onClick={this.openBaseModal} size="small" icon="Plus">{ text.ADD_NEW }</Button>
                </div>
              </React.Fragment>
            )
           }
              {
            isApplicationType(applicationTypes.CUSTOM.value) && (
              <React.Fragment>
                <Field field={fields.name} />
                <div className="email-template-update__card-body-info">
                  <div>
                    <Field field={fields.application} props={{ clearable: false }} />
                    <Field field={fields.language} props={{ clearable: false }} />
                  </div>
                </div>
              </React.Fragment>
            )
           }
              {
            !values.application && null
           }
            </div>
            <Space size={32} />
            <div className="email-template-update__card-body">
              <Button onClick={this.openTags} color="ghost">{ text.DATA_TAGS }</Button>
              <Field field={fields.content} />
            </div>
            <div className="email-template-update__card-footer">
              <Button 
                onClick={openPreviewModal}
                icon="View"
                color="ghost"
              >{ text.PREVIEW }
              </Button>
              <div>
                <Button onClick={this.goToEmailTemplates} color="ghost">{ text.DISCARD }</Button>
                <Button disabled={submitInProgress} type="submit" onClick={this.type === 'edit' ? this.updateEmailTemplate : this.createEmailTemplate}>
                  { getLabel(this.type, true) }
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    );
  }

  render() {
    const { actions: { closeSidepanel } } = this.props;
    const { openPreviewModal, openBaseModal } = this.state;
    const { values } = this.props;

    return (
      <Page 
        title={getLabel(this.type)}
        bread={bread(this.type, this.id)}
      >
        <Row className="email-template-update__row">
          <Col lg="900px">
            { this.renderForm() }
          </Col>
        </Row>
        <DataTagsSidepanel 
          closeSidepanel={closeSidepanel}
          api={triggerEmailApi}
        />
        <HtmlPreviewModal 
          onCloseModal={this.closePreviewModal}
          visible={openPreviewModal}
          html={values.content}  
        />
        <TemplateGroupModal 
          visible={openBaseModal}
          onCloseModal={this.closeBaseModal}
        />
      </Page>
    );
  }
}


EmailTemplateUpdate.propTypes = {
  actions: object.isRequired,
  values: object.isRequired,
  isLoading: bool.isRequired,
  fields: arrayOf(object).isRequired,
  submitInProgress: bool.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  match: object.isRequired,
};

const mapStateToProps = ({ forms }) => ({
  ...forms[formID],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    setFieldValue,
    openSidepanel: overlayActions.open,
    closeSidepanel: overlayActions.close,
    resetPlaceholdersStateAction,
    setFieldsErrors,
    create,
    update,
  }, dispatch),
  dispatch,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(EmailTemplateUpdate); 
