import React from 'react';
import { string } from 'prop-types';

import './ConditionConnector.scss';

const propTypes = {
  displayValue: string.isRequired,
};

const ConditionConnector = ({ displayValue }) => (
  <div className="condition-connector">
    {displayValue}
  </div>
);

ConditionConnector.propTypes = propTypes;

export default ConditionConnector;
