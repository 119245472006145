import React from 'react';
import { string, object, arrayOf } from 'prop-types';
import { Detail } from 'tc-biq-design-system';

import BiqAccordion from '../../../../../../../components/BiqAccordion';
import AccordionHeaderTemplate from './AccordionHeaderTemplate';

const propTypes = {
  title: string.isRequired,
  state: object,
  data: arrayOf(object).isRequired,
  reviewAction: object.isRequired,
};

const defaultProps = {
  state: {},
};

const SectionAccordion = ({
  title,
  state,
  data,
  reviewAction,
}) => (
  <div className="accordion-section">
    <BiqAccordion headerTemplate={open => (
      <AccordionHeaderTemplate 
        reviewAction={reviewAction} 
        open={open} 
        title={title} 
        state={state} 
      />
    )}
    >
      <div className="biq-protrader__section-details">
        {data.map(({ entity, value }) => <Detail key={entity} reverse entity={entity} value={value} />)}
      </div>
    </BiqAccordion>
  </div>
);

SectionAccordion.propTypes = propTypes;
SectionAccordion.defaultProps = defaultProps;
export default SectionAccordion;
