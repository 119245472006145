/* eslint react/prop-types: 0 */
import React, { PureComponent } from 'react';
import { bindActionCreators, compose } from 'redux';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import overlayActions from '../../../../components/overlay';
import Page from '../../../../components/Page';
import Export from '../../../../components/Export';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import modifier from './modifier';
import IbExecuteWithdrawalSidepanel from './sidepanel/ibExecuteWitdrawal';
import IbRejectWithdrawalSidepanel from './sidepanel/ibRejectWithdrawal';
import { WithdrawalRequest, TransactionSingle } from '../../../../components/common/sidepanels';
import { getWithdrawalDetails } from '../../../../components/common/sidepanels/WithdrawalRequest';
import If from '../../../../components/If';
import UserPreview from '../../../../components/common/sidepanels/UserPreview';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';

const pageConfig = {
  apiUrl: 'ib/withdraw_requests',
  reducerKey: 'IB_PENDING_WITHDRAWALS',
  tableKey: 'IB_PENDING_WITHDRAWALS_TABLE',
};

const text = { PAGE_LABEL: gettext('IB Pending withdrawals') };
const { GridComponent, actions } = GridFactory(pageConfig);
const bread = [{ label: text.PAGE_LABEL, route: 'home.ib-system-pending-withdrawals' }];
const transactionApi = getDjangoApi('payment_transactions');

class IbPendingWithdrawals extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.headerActions = this.headerActions.bind(this);

    this.openWithdrawalRequest = this.openWithdrawalRequest.bind(this);
    this.openSingleTransaction = this.openSingleTransaction.bind(this);
    this.tableActions = {
      ...((hasAccess('ib.withdraw_requests.execute.*'))
        && { approve: this.actions.openSidepanel.bind(this, 'IB_EXECUTE_WITHDRAWAL') }),
      ...((hasAccess('ib.withdraw_requests.reject.*'))
        && { reject: this.actions.openSidepanel.bind(this, 'IB_REJECT_WITHDRAWAL') }),
    };

    this.previewUser = this.actions.openSidepanel.bind(this, 'USER_PREVIEW');
  }

  fetchTableData = () => {
    const { table } = this.props;
    this.actions.fetchTableData(table.query);
  }

  async openSingleTransaction(id) {
    const { actions } = this.props;
    const response = await transactionApi.retrieve(id);
    actions.openSidepanel('TRANSACTION_SINGLE', response.data);
  }

  async openWithdrawalRequest(data) {
    const { actions } = this.props;
    const response = await getDjangoApi(pageConfig.apiUrl).retrieve(data.id);
    let sidepanelData = response.data;
    if (sidepanelData.payment_gateway_account) {
      const { method, id } = sidepanelData.payment_gateway_account;
      const detailsResponse = await getWithdrawalDetails(method).retrieve(id);
      sidepanelData = { ...sidepanelData, preferredMethods: detailsResponse.data };
    }
    actions.openSidepanel('WITHDRAWAL_REQUEST', sidepanelData);
  }

  headerActions() {
    const { exportUrl } = this.props;
    return (
      <If condition={hasAccess('ib.withdraw_requests.export.*')}>
        <Export url={exportUrl} />
      </If>
    );
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions, this.openWithdrawalRequest, this.previewUser),
    };

    return (
      <Page headerActions={this.headerActions} bread={bread} title={text.PAGE_LABEL}>
        <Filters {...filterProps} />
        <GridComponent
          tableModifier={modifier(this.tableActions, this.openWithdrawalRequest, this.previewUser)}
          singleActions={!!Object.keys(this.tableActions).length}
          {...this.props}
        />
        <IbExecuteWithdrawalSidepanel onSuccess={this.fetchTableData} />
        <IbRejectWithdrawalSidepanel onSuccess={this.fetchTableData} />
        <WithdrawalRequest isIb openTransactionSingle={this.openSingleTransaction} />
        <TransactionSingle isIb />
        <UserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const table = pages[reducerKey].tables[tableKey];
  return {
    table,
    fields: table.fields,
    exportUrl: table.exportUrl,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      toggleSidepanel: actions.toggleSidepanel,
      addActionsColumn: actions.addActionsColumn,
      setSidepanelData: actions.setSidepanelData,
      fetchTableData: actions.fetchTableData,
      openSidepanel: overlayActions.open,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
    },
    dispatch,
  ),
});

export default compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(IbPendingWithdrawals);
