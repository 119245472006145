/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Button } from 'tc-biq-design-system';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import Page from '../../../../components/Page';
import Export from '../../../../components/Export';
import modifier from './modifier';
import overlayActions from '../../../../components/overlay';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import ImportCsv from './modals/ImportCsv';
import BlacklistForm from './sidepanels/BlacklistForm';
import If from '../../../../components/If';
import { BiqUserPreview } from '../../../../components/common/sidepanels';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const pageConfig = {
  apiUrl: 'kyc/blacklist',
  reducerKey: 'KYC_BLACKLIST',
  tableKey: 'KYC_BLACKLIST_TABLE',
};

const text = {
  PAGE_LABEL: gettext('Blacklist'),
  ADD: gettext('Add'),
  IMPORT_CSV: gettext('Import CSV'),
};

const api = getDjangoApi('kyc/blacklist');
const { GridComponent, actions } = GridFactory(pageConfig);
const bread = [{ label: text.PAGE_LABEL, route: appRoutes.KYC_BLACKLIST }];

class KycBlacklist extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.tableActions = {
      ...(hasAccess('kyc.blacklist.update') && { edit: blacklist => this.openKycSidepanel(blacklist) }),
      ...(hasAccess('kyc.blacklist.destroy') && { delete: this.onDeleteAction.bind(this) }),
    };

    this.previewBiqUser = this.actions.overlayOpen.bind(this, 'BIQ_USER_PREVIEW');
    this.headerActions = this.headerActions.bind(this);
    this.onSuccessRequest = this.onSuccessRequest.bind(this);
  }

  async onDeleteAction({ id }) {
    await api.destroy(id);
    this.onSuccessRequest();
  }

  onSuccessRequest() {
    const { tableQuery } = this.props;
    this.actions.fetchTableData(tableQuery);
  }

  async openKycSidepanel(blacklist) {
    const { data } = await api.options();
    this.actions.overlayOpen('KYC_BLACKLIST_SIDEPANEL', {
      blacklist,
      fields: data.actions.POST.fields,
    });
  }

  headerActions() {
    const { exportUrl } = this.props;
    return (
      <Fragment>
        <If condition={hasAccess('kyc.blacklist.create')}>
          <Button color="confirmation" onClick={() => this.openKycSidepanel()}>
            {text.ADD}
          </Button>
        </If>
        <If condition={hasAccess('kyc.blacklist.create')}>
          <Button color="confirmation" onClick={() => this.actions.overlayOpen('KYC_BLACKLIST_IMPORT_CSV')}>
            {text.IMPORT_CSV}
          </Button>
        </If>
        <If condition={hasAccess('kyc.blacklist.export.*')}>
          <Export url={exportUrl} />
        </If>
      </Fragment>
    );
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions, this.previewBiqUser),
    };

    return (
      <Page headerActions={this.headerActions} bread={bread} title={text.PAGE_LABEL}>
        <Filters {...filterProps} />
        <GridComponent
          singleActions={!!Object.keys(this.tableActions).length}
          tableModifier={modifier(this.tableActions, this.previewBiqUser)}
          {...this.props}
        />
        <ImportCsv onSuccess={this.onSuccessRequest} />
        <BlacklistForm onSuccess={this.onSuccessRequest} />
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const table = pages[reducerKey].tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
    tableQuery: table.query,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      addActionsColumn: actions.addActionsColumn,
      overlayOpen: overlayActions.open,
      fetchTableData: actions.fetchTableData,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
    },
    dispatch,
  ),
});

export default compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(KycBlacklist);
