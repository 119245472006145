import { bindActionCreators } from 'redux';
import Authentication from './Authentication';
import connect from '../../../logic/connect';
import { setPostLoginRoute, loadAuthSession } from '../../../logic/session/Model';

const mapStateToProps = ({ session }) => ({ session });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    setPostLoginRoute,
    loadAuthSession,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
