import { createAction, handleActions } from 'redux-actions';
import { refactoredInjectRules, setupFields } from '../../../../../../logic/services/query-adapter';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const actionTypes = {
  GET_CANCELLATION_RULE_FIELDS_OPTIONS: 'GET_CANCELLATION_RULE_FIELDS_OPTIONS',
  GET_CANCELLATION_RULE_FIELDS_OPTIONS_PENDING: 'GET_CANCELLATION_RULE_FIELDS_OPTIONS_PENDING',
  GET_CANCELLATION_RULE_FIELDS_OPTIONS_REJECTED: 'GET_CANCELLATION_RULE_FIELDS_OPTIONS_REJECTED',
  GET_CANCELLATION_RULE_FIELDS_OPTIONS_FULFILLED: 'GET_CANCELLATION_RULE_FIELDS_OPTIONS_FULFILLED',

  GET_CANCELLATION_RULE: 'GET_CANCELLATION_RULE',
  GET_CANCELLATION_RULE_PENDING: 'GET_CANCELLATION_RULE_PENDING',
  GET_CANCELLATION_RULE_REJECTED: 'GET_CANCELLATION_RULE_REJECTED',
  GET_CANCELLATION_RULE_FULFILLED: 'GET_CANCELLATION_RULE_FULFILLED',

  SUBMIT_CANCELLATION_RULE: 'SUBMIT_CANCELLATION_RULE',
  SUBMIT_CANCELLATION_RULE_PENDING: 'SUBMIT_CANCELLATION_RULE_PENDING',
  SUBMIT_CANCELLATION_RULE_REJECTED: 'SUBMIT_CANCELLATION_RULE_REJECTED',
  SUBMIT_CANCELLATION_RULE_FULFILLED: 'SUBMIT_CANCELLATION_RULE_FULFILLED',
  
  SET_CANCELLATION_RULE_CONDITION: 'SET_CANCELLATION_RULE_CONDITION',

  RESET_CANCELLATION_RULE_PAGE: 'RESET_CANCELLATION_RULE_PAGE',
};

const initialState = {
  name: '',
  errors: {
    name: [''],
  },
  fields: [],
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  submitInProgress: false,
};

const api = getDjangoApi('settings/bonus_expiry_rules');

export const getFieldsOptions = createAction(
  actionTypes.GET_CANCELLATION_RULE_FIELDS_OPTIONS,
  api.options,
);

export const getCancellationRule = createAction(
  actionTypes.GET_CANCELLATION_RULE,
  api.retrieve,
);

export const setCondition = createAction(
  actionTypes.SET_CANCELLATION_RULE_CONDITION,
  group => group,
);

export const submit = createAction(
  actionTypes.SUBMIT_CANCELLATION_RULE,
  api.create,
);

export const resetState = createAction(
  actionTypes.RESET_CANCELLATION_RULE_PAGE,
);

export const actions = {
  getFieldsOptions,
  getCancellationRule,
  setCondition,
  submit,
  resetState,
};

const getFieldsOptionsHandler = (state, { payload }) => {
  const { fields } = payload.data.bonus_expiry_conditions;
  const mappedFields = setupFields(fields);

  return {
    ...state,
    fields: mappedFields,
  };
};

const getCancellationRuleHandler = (state, { payload }) => {
  const { bonus_expiry_conditions, name } = payload.data;
  const group = refactoredInjectRules(state.fields, bonus_expiry_conditions);
  return {
    ...state,
    group,
    name,
  };
};

const setConditionHandler = (state, { payload }) => (
  {
    ...state,
    group: { ...payload },
  }
);

const submitHandler = state => (
  {
    ...state,
    submitInProgress: true,
  }
);

const submitSuccessHandler = state => (
  {
    ...state,
    submitInProgress: false,
  }
);

const resetStateHandler = () => (
  {
    ...initialState,
  }
);


export default handleActions(
  {
    [actionTypes.GET_CANCELLATION_RULE_FIELDS_OPTIONS_FULFILLED]: getFieldsOptionsHandler,
    [actionTypes.GET_CANCELLATION_RULE_FULFILLED]: getCancellationRuleHandler,
    [actionTypes.SET_CANCELLATION_RULE_CONDITION]: setConditionHandler,
    [actionTypes.SUBMIT_CANCELLATION_RULE_PENDING]: submitHandler,
    [actionTypes.SUBMIT_CANCELLATION_RULE_FULFILLED]: submitSuccessHandler,
    [actionTypes.RESET_CANCELLATION_RULE_PAGE]: resetStateHandler,
  },
  initialState,
);
