import { gettext } from './languageUtility';

const dateTimeOptions = [
  [3, gettext('minutes')],
  [5, gettext('minutes')],
  [10, gettext('minutes')],
  [15, gettext('minutes')],
  [20, gettext('minutes')],
  [30, gettext('minutes')],
  [45, gettext('minutes')],
  [1, gettext('hour')],
  [2, gettext('hour')],
  [3, gettext('hour')],
  [6, gettext('hours')],
  [12, gettext('hours')],
  [1, gettext('day')],
  [2, gettext('days')],
  [1, gettext('week')],
  [2, gettext('weeks')],
  [3, gettext('weeks')],
  [1, gettext('month')],
  [2, gettext('months')],
  [6, gettext('months')],
  [1, gettext('year')],
];

const dateOptions = [
  [7, gettext('days')],
  [14, gettext('days')],
  [1, gettext('month')],
  [2, gettext('months')],
  [6, gettext('months')],
  [1, gettext('year')],
];

function mapDateChoices(options) {
  return options.map(d => ({
    display_name: `${d[0]} ${d[1]}`,
    value: moment.duration(d[0], d[1]).asMinutes(),
  }));
}

export const dateTimeChoices = mapDateChoices(dateTimeOptions);
export const dateChoices = mapDateChoices(dateOptions);
