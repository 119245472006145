import React, { Component } from 'react';
import { Select, Checkbox, Sidepanel, Space, notifier } from 'tc-biq-design-system';
import { array, func, bool, object } from 'prop-types';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { ClientsList, SidepanelFooter } from '../../..';

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
  fetchTableData: func.isRequired,
  fieldData: array,
  submitInProgress: bool,
  removeTags: bool,
  submitSuccess: bool,
  isIb: bool,
  gridApi: object,
};

const defaultProps = {
  fieldData: null,
  submitInProgress: false,
  removeTags: false,
  submitSuccess: false,
  isIb: false,
  gridApi: null,
};

const text = {
  TITLE: gettext('Edit tags'),
  SUBMIT: gettext('Save'),
  CANCEL: gettext('Cancel'),
  APPEND_TAGS: gettext('Would you like to remove old tags on users?'),
  SUBMIT_SUCCESS: gettext('Tags updated'),
  PLACEHOLDER: gettext('Search...'),
  SELECTED_USERS: gettext('Selected users'),
  BUTTON_LABELS: {
    confirm: gettext('Edit tags'),
    cancel: gettext('Discard changes'),
  },
};

const formatRequestData = (data, fieldData, removeTags, defaultTags) => {
  const tags = fieldData.map(tag => (tag.label ? ({
    id: tag.value,
    name: tag.label,
  }) : tag));
  const selectedTags = tags ? tags.map(tag => tag.name) : [];
  const removedTags = defaultTags.filter(tag => !selectedTags.includes(tag.name)).map(tag => tag.name);
  if (removeTags) {
    return data.map(user => ({ user: user.id, tags: selectedTags }));
  }
  return data.map(user => ({
    user: user.id,
    tags: _.union(selectedTags, user.tags.filter(tag => !removedTags.includes(tag))),
  }));
};

const customFooter = (execute, close, submitInProgess) => (
  <SidepanelFooter 
    submitInProgress={submitInProgess}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

class EditTags extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.apiTags = getDjangoApi('tags');
    this.apiTagsBulk = getDjangoApi('tags/bulk_set');
    this.loadOptions = this.loadOptions.bind(this);
    this.submit = this.submit.bind(this);
    this.onClose = this.onClose.bind(this);

    this.state = {
      defaultTags: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { submitSuccess, fetchTableData } = this.props;
    if (submitSuccess) {
      notifier.success(text.SUBMIT_SUCCESS);
      fetchTableData();
      this.onClose();
    }

    const prevParams = _.get(prevProps, 'sidepanel.parameters', []);
    const params = _.get(this.props, 'sidepanel.parameters', []);

    if (!_.isEqual(prevParams, params) && !_.isEmpty(params)) this.setDefault(params);
  }

  onClose() {
    const { gridApi } = this.props;
    gridApi && gridApi.deselectAll();
    this.actions.closeSidepanel('EDIT_TAGS');
    this.actions.resetInput();
  }

  setDefault = (clients) => {
    const tags = clients.map(client => client.tags);
    const defaultTags = _.intersection(...tags).map(tag => ({ id: tag, name: tag }));
    this.setState({ defaultTags });
    this.actions.updateData(defaultTags);
  }


  loadOptions(input) {
    return new Promise((resolve) => {
      if (input) {
        return this.apiTags
          .list({
            name: input,
            limit: 10,
            offset: 0,
          })
          .then(res => resolve(res.data.results));
      }
      return this.apiTags
        .list({
          type: 'live',
          limit: 10,
          offset: 0,
        })
        .then(res => resolve(res.data.results));
    });
  }

  submit() {
    const { sidepanel, fieldData, removeTags } = this.props;
    const { defaultTags } = this.state;
    const requestData = formatRequestData(sidepanel.parameters, fieldData, removeTags, defaultTags);
    this.actions.submit(this.apiTagsBulk, requestData);
  }

  render() {
    const { sidepanel, fieldData, submitInProgress, removeTags, isIb } = this.props;
    const { visible, parameters } = sidepanel;
    return (
      <Sidepanel
        icon="Tag"
        type="info"
        title={text.TITLE}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.submit, this.onClose, submitInProgress)}
      >
        <Space size={16} />
        <Select
          placeholder={text.PLACEHOLDER}
          type="multi"
          onChange={this.actions.updateData}
          value={fieldData || ''}
          async
          debounceInterval={500}
          loadOptions={this.loadOptions}
          valueKey="id"
          labelKey="name"
          creatable
        />
        <Space size={16} />
        <Checkbox checked={removeTags} onChange={this.actions.shouldRemoveTags}>
          {text.APPEND_TAGS}
        </Checkbox>
        <Space size={16} />
        {visible && !_.isEmpty(parameters) ? (
          <h4>{`${text.SELECTED_USERS} (${parameters.length})`}</h4>
        ) : null}
        {visible && !_.isEmpty(parameters) ? (
          <ClientsList clients={parameters} hideUserState isIb={isIb} />
        ) : null}
      </Sidepanel>
    );
  }
}

EditTags.defaultProps = defaultProps;
EditTags.propTypes = propTypes;

export default EditTags;
