import session from '../../../../logic/services/session';
import { gettext } from '../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../logic/services/api-factory';

export const text = {
  PLACEHOLDER: gettext('...search'),
  GROUP_USERS: gettext('Users'),
  GROUP_TEAMS: gettext('Teams'),
  GROUP_DEFAULT: gettext('Default'),
  NONE: gettext('None'),
};

export function formatResults(response, currentSession, strings) {
  const users = response[0].data.results.map(user => ({
    label: user.username,
    id: user.id,
    is_staff: true,
    group: strings.GROUP_USERS,
  }));
  const teams = response[1].data.results.map(team => ({
    label: team.name,
    id: team.id,
    group: strings.GROUP_TEAMS,
  }));
  return [
    { id: 'none', label: strings.NONE, group: strings.GROUP_DEFAULT },
    {
      id: currentSession.user.id,
      label: currentSession.user.username,
      group: strings.GROUP_DEFAULT,
      is_staff: true,
    },
    ...users,
    ...teams,
  ];
}

export async function loadOptions(input = '') {
  const apiUsers = getDjangoApi('team');
  const apiTeams = getDjangoApi('teams');
  const userParams = {
    type: 'live',
    limit: 10,
    offset: 0,
  };
  const teamParams = {
    limit: 5,
    offset: 0,
  };
  const usersPromise = apiUsers.list({ ...userParams, username: input });
  const teamsPromise = apiTeams.list({ ...teamParams, name: input });
  const results = await Promise.all([usersPromise, teamsPromise]);
  return formatResults(results, session, text);
}


export async function userAutoComplete(input) {
  const usersAutocompleteApi = getDjangoApi('autocomplete/users');
  return usersAutocompleteApi
    .list({ username: input })
    .then(res => ([{ id: 'none', username: text.NONE }, ...res.data.results]));
}

export const setContentTypes = (options, parameters, performer) => {
  const data = {};
  const targetContentTypes = options && _.find(options.fields, { key: 'target_content_type' });
  const performerContentTypes = options && _.find(options.fields, { key: 'performer_content_type' }).choices;
  const performer_content_type = parameters.performer && parameters.performer.is_staff
    ? _.find(performerContentTypes, { display_name: 'bo user' }).value
    : _.find(performerContentTypes, { display_name: 'team' }).value;

  if (performer_content_type && performer && performer.id !== 'none') {
    data.performer_content_type = performer_content_type;
    data.performer_object_id = performer ? performer.id : null;
  }

  if (
    parameters.target && parameters.target.id !== 'none'
    && targetContentTypes.choices
    && targetContentTypes.choices.length
  ) {
    const userType = _.find(targetContentTypes.choices, { display_name: 'user' });
    if (userType) {
      data.target_content_type = userType.value;
      data.target_object_id = parameters.target.id;
    }
  }

  if (parameters.target && parameters.target.id === 'none') {
    data.target_content_type = undefined;
    data.target_object_id = undefined;
  }

  return data;
};

export const setRequestDataValues = (fields) => {
  const data = {};
  _.forEach(fields, (value, key) => {
    if (value && value.id === 'none') return;
    if (value && value.id) {
      data[key] = value.id;
    } else {
      data[key] = key === 'due_date' ? moment(value).utc() : value;
    }
  });
  return data;
};
