import React from 'react';
import { notifier } from 'tc-biq-design-system';

export const Field = ({ children, ...props }) => React.cloneElement(children, {
  ...props, 
});

const pullFieldValue = (values, field, key) => ({ 
  ...values,
  [key]: !!field && typeof field === 'object' ? field.value : field,
});

export function formatRequestData(data) {
  return _.reduce(data, pullFieldValue, {});
}

const setInitialValue = fields => (values, fieldValue, id) => {
  const field = _.find(fields, { id });
  if (!field) return values;

  switch (field.type) {
    case 'select':
    case 'search':
      return { ...values, [id]: compileSelectFieldValue(field, fieldValue) };
    default:
      return { ...values, [id]: fieldValue };
  }
};

export function initiateValues(data, fields) {
  return _.reduce(data, setInitialValue(fields), {});
}

function compileSelectFieldValue(field, initialValue) {
  const { valueKey, labelKey, dataKeys, options } = field;
  if (!field.loadOptions) return _.find(options, { [labelKey]: initialValue }) || _.find(options, { [valueKey]: initialValue });

  const dataKey = setDataKey(dataKeys, initialValue);
  return setAsyncSelectFieldValue(initialValue, valueKey, labelKey, dataKey);
}

export function setDataKey(dataKeys, initialData) {
  if (typeof dataKeys === 'string') return dataKeys;
  const initialDataKeys = initialData ? Object.keys(initialData) : [];
  return _.intersection(dataKeys, initialDataKeys)[0];
}

export function setAsyncSelectFieldValue(initialData, valueKey, labelKey, dataKey) {
  if (_.isPlainObject(initialData) && dataKey) {
    return { [labelKey]: initialData[dataKey], [valueKey]: initialData[valueKey] };
  }
  
  return { [labelKey]: dataKey, [valueKey]: initialData };
}

export const formErrorHandler = generalError => (response, badRequestCallback) => {
  const { data } = response;
  if (!data) return data;
  const { non_field_errors, messages, message } = data;
  const badRequest = _.get(response, 'status') === 400;
  const all = _.get(data, '__all__.[0]');
  if (non_field_errors) {
    notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
  } else if (messages) {
    notifier.error(messages.map(err => <span>{err.text}</span>));
  } else if (message) {
    notifier.error(message);
  } else if (all) {
    notifier.error(all);
  } else if (badRequest && badRequestCallback) {
    badRequestCallback(response);
  } else if (!badRequest && generalError) {
    notifier.error(generalError);
  }
};

export const cleanEmptyValues = (values = {}) => {
  const cleaned = {};
  Object.keys(values).forEach((key) => {
    const value = values[key];

    if (value || _.isBoolean(value)) {
      cleaned[key] = value;
    }
  });

  return cleaned;
};



export const validateForm = (validateAction) => {
  const { payload } = validateAction();
  return _.isEmpty(payload);
};


export const getFieldError = (error) => {
  if (_.isString(error)) return error;
  if (Array.isArray(error)) return error[0];
  return null;
};
