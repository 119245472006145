import React from 'react';
import { Pill } from 'tc-biq-design-system';
import { func, string, oneOfType, number, bool, object } from 'prop-types';

import { gettext } from '../../logic/utilities/languageUtility';
import { dateTimeChoices } from '../../logic/utilities/timeAgo';
import dateTimeFormatter from '../../logic/utilities/formatters/dateTimeFormatter';
import { fieldType } from './FilterTypes';
import { convertValueToDisplay, getChoices, isMultipleSelect, isRange } from './filterUtils';
import apiDataType from '../../logic/enums/api-data-type';
import queryOperators from '../../logic/enums/query-operators';

const {
  CHOICE,
  DATETIME,
  DATE,
  NESTED_RELATION,
  BOOLEAN,
  FIELD,
  INTEGER,
  DECIMAL,
  FLOAT,
  NESTED_OBJECT,
  NESTED_LIST,
} = apiDataType;

const propTypes = {
  field: fieldType.isRequired,
  onCloseIconClick: func.isRequired,
  onFilterClick: func.isRequired,
  filterKey: string.isRequired,
  operator: string.isRequired,
  operatorLabel: string.isRequired,
  filterValue: oneOfType([string, number, bool, object]).isRequired,
  hide: bool.isRequired,
};

const text = {
  YES: gettext('Yes'),
  NO: gettext('No'),
};

const formatBoolean = value => (value ? text.YES : text.NO);

const renderOperator = (operatorLabel, fieldType) => {
  const blacklist = [BOOLEAN];
  if (blacklist.includes(fieldType)) {
    return ' ';
  }
  return ` ${operatorLabel} `;
};

const getDisplayName = (value, choices) => {
  if (!choices) return value;
  // eslint-disable-next-line prefer-template
  const choice = choices.find(o => o && o.value + '' === value + '');
  if (choice && choice.display_name) {
    return choice.display_name;
  }
  return value;
};

const formatChoice = (value, fieldChoices, operator) => {
  if (isMultipleSelect(operator)) {
    return value
      .split(',')
      .map(option => getDisplayName(option, fieldChoices))
      .toString();
  }
  return getDisplayName(value, fieldChoices);
};

const formatDateTime = (value, operator) => {
  if (operator === queryOperators.MINUTES_AGO.value) {
    return getDisplayName(value, dateTimeChoices);
  }
  if (isRange(operator)) {
    const range = value.split(',');
    const from = dateTimeFormatter(range[0], false);
    const to = dateTimeFormatter(range[1], false);
    return `${from} - ${to}`;
  }
  return dateTimeFormatter(value, false);
};

function formatNumbers(value, operator) {
  if (isRange(operator)) {
    const range = value.split(',');
    const from = range[0];
    const to = range[1];
    return `${from} - ${to}`;
  }
  return value;
}

const formatFilterValueForType = (field, value, operator) => {
  const choices = getChoices(field || {});
  if (field.type === NESTED_OBJECT && choices) {
    return formatChoice(value, choices, operator);
  }

  const sanitizedValue = convertValueToDisplay(value);

  switch (field.type) {
    case CHOICE:
    case NESTED_RELATION:
    case NESTED_LIST:
    case FIELD:
      return formatChoice(sanitizedValue, choices, operator);
    case DATETIME:
    case DATE:
      return formatDateTime(sanitizedValue, operator);
    case BOOLEAN:
      return formatBoolean(value);
    case INTEGER:
    case FLOAT:
    case DECIMAL:
      return formatNumbers(sanitizedValue, operator);
    default:
      return sanitizedValue;
  }
};

const Filter = (props) => {
  const {
    onCloseIconClick,
    filterKey,
    onFilterClick,
    field,
    operator,
    filterValue,
    operatorLabel,
    hide,
  } = props;
  return (
    !hide ? (
      <Pill
        big
        icon="Close"
        onIconClick={() => onCloseIconClick(filterKey)}
        onClick={() => onFilterClick(filterKey, filterValue, field)}
      >
        <b>
          {field.label} {renderOperator(operatorLabel, field.type)}
        </b>
        {formatFilterValueForType(field, filterValue, operator)}
      </Pill>
    ) : null
  );
};
Filter.propTypes = propTypes;
export default Filter;
