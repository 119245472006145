import React, { Component, Fragment } from 'react';
import { notifier } from 'tc-biq-design-system';
import { object, func } from 'prop-types';

import withTable, { GridTab } from '../../../../../components/grid/withTable';
import modifiers from './modifiers';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import to from '../../../../../logic/utilities/to';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import EmailAlertsSidepanel, { SIDEPANEL_ID } from './EmailAlertsSidepanel';
import { hasAccess } from '../../../../../logic/services/acl';

const reducerKey = 'EMAIL_ALERT_SETTINGS';

const text = {
  SUCCESS: gettext('Email deleted'),
  ERROR: gettext('Email delete failed'),
};

const tableConfig = () => ({
  reducerKey,
  apiUrl: 'settings/bo_notifications',
  tableKey: 'EMAILS',
});

const api = getDjangoApi(tableConfig().apiUrl);

const propTypes = {
  actions: object.isRequired,
  dispatch: func.isRequired,
};

class AlertSettings extends Component {
  constructor(props) {
    super(props);

    this.emailGrid = withTable(GridTab, tableConfig, null, { withActions: true })();
    this.actions = {
      modify: hasAccess('settings.bo_notifications.update') && this.onEdit,
      remove: hasAccess('settings.bo_notifications.destroy') && this.onRemove,
    };
  }

  onRemove = async ({ id }) => {
    const [err] = await to(api.destroy(id));
    if (err) {
      notifier.error(text.ERROR);
    } else {
      notifier.success(text.SUCCESS);
      this.reloadTable();
    }
  }

  onEdit = (data) => {
    const { actions } = this.props;
    actions.openSidepanel(SIDEPANEL_ID, { data });
  }

  reloadTable = () => {
    const { dispatch } = this.props;
    const [, tableActions] = this.emailGrid;
    dispatch(tableActions.fetchTableData());
  }

  render() {
    const [EmailTable] = this.emailGrid;
    return (
      <Fragment>
        <EmailTable
          singleActions
          modifier={modifiers(this.actions)}
        />
        <EmailAlertsSidepanel onSuccess={this.reloadTable} />
      </Fragment>
    );
  }
}

AlertSettings.propTypes = propTypes;

export default AlertSettings;
