import _ from 'lodash';

const CONFIG = window.config;
const env = CONFIG.environment;

// First init feature for all clients
const features = [
  'client_communication_tabs',
  'REACT',
  'DOCUMENT_REJECTION_REASONS',
  'table-refactor',
];

if (env === 'AvaTrade' || env === 'stfu') {
  features.push(
    'ADVANCED_PROFILE_STATE_TRANSITIONS',
    'zopim',
    'MIXED_CURRENCY_INTERNAL_TRANSFER',
    'FRAUD_CHECK',
    'CARD_INFORMATION',
    'SMS',
    'AVA_PARTNERS',
    'ASSIGN_SALES',
    'ASSIGN_RETENTION',
    'ASSIGN_CAGE',
    'MANUAL_BONUS',
    'MANUAL_BONUS_REASON',
    'DEFAULT_VIEWS',
    'CURRENCY_CONVERSION',
    'FLP',
    'USER_PROFILE_WITHDRAWALS',
    'BUSINESS_GROUP',
    'PROTRADER',
    'MAILCHIMP_SUBSCRIPTION',
    'TEAMS_SALES_RETENTION',
    'TRANSLATIONS',
    'ONLINE_STATUS',
    'LOGIN_LOCKDOWN',
    'RETENTION_CUSTOMER_REPORT',
    'INSURANCE_TRANSACTIONS',
    'RECORD_DUPLICATE_USERS',
    'GDPR',
    'PASSWORD_POLICY',
    'WITHDRAWALS_PAYMENT_GATEWAY_ENABLED',
    'DOCUMENT_CLEAR_STATUS',
    'BLOCK_UNAUTHORIZED_DATA_SHARING',
    'COMMUNICATION_FLOW',
    'REJECT_PENDING_DEPOSIT',
    'MINUTES_AGO_OPERATOR',
    'ACTIVITY_STREAM',
    'INTEGRATION_AVA_PARTNERS_ENABLE_REFER_A_FRIEND',
    'EDITABLE_SIGNUP_DATA',
    'AUTOMATIC_FEE',
    'MONITORING',
    'PHONE_VISIBILITY_TOGGLE',
    'TPI_TRANSACTION_RETRY',
    'TA_NOTIFICATIONS',
    'TRANSACTION_EXTRA_DATA',
    'MASS_ASSIGNMENT',
  );
}

if (_.includes(['VCGMarkets'], env)) {
  features.push(
    'ADVANCED_PROFILE_STATE_TRANSITIONS',
    'CHANGE_TA_PASSWORD',
  );
}

if (_.includes(['TradeCore'], env)) {
  features.push(
    'LOGIN_LOCKDOWN',
  );
}

if (_.includes(['AvaFeatures'], env)) {
  features.push(
    'PHONE_VISIBILITY_TOGGLE',
  );
}

if (_.includes(['AAAFX', 'FXView'], env)) {
  features.push(
    'PHONE_CALL_ACTION',
    'TRADING_ACCOUNT_NUMBER',
    'CHANGE_TA_PASSWORD',
    'IB_WITHDRAWAL_SELECT_METHOD_GATEWAY',
    'SINGLE_LEAD_CAMPAIGN_FIELD',
    'TOTAL_EQUITY_BASE_CURRENCY',
    'CLIENT_VISIBILITY_GROUPS',
    'ADD_MEMBER_OBJECT_ACL_RULES',
    'SIGNUP_DATA',
    'DEFAULT_VIEWS',
    'WHITELABEL',
    'COMMUNICATION_PROFILES',
  );
}


if (_.includes(['AvaTrade', 'stfu', 'ICMCapital', 'GCCBrokers'], env)) {
  features.push('COMMUNICATION_LOG');
}

if (_.includes(['AvaTrade', 'stfu', 'PriceMarkets', 'Zcom'], env)) {
  features.push('MAILCHIMP_INTEGRATION');
}

if (env === 'stfu') {
  features.push('CONTACT_QT');
}

if (_.includes(['stfu', 'Zcom'], env)) {
  features.push('COMPLY_ADVANTAGE');
}

if (
  _.includes(
    [
      'ICMCapital',
      'Zcom',
      'stfu',
      'Internal',
      'TradeCore',
      'CSAustralia',
      'CoreSpreads',
      'BlackStone',
      'GCCBrokers',

    ],
    env,
  )
) {
  features.push('WHITELABEL');
}

if (_.includes(['CoreSpreads', 'BourseTrade', 'FinancialSpreads'], env)) {
  features.push('ANNUAL_KYC');
}

if (
  _.includes(
    [
      'AvaTrade',
      'ICMCapital',
      'Internal',
      'TradeCore',
      'PipIndex',
      'CoreSpreads',
      'CSAustralia',
      'TradeDirect365',
      'BourseTrade',
      'FinancialSpreads',
      'ISTMarkets',
      'Zcom',
      'BlackStone',
      'PriceMarkets',
      'EagleGlobalMarkets',
      'XSpotInternational',
      'Oxdfx',
      'Fixi',
      'GoMarkets',
      'XSpotMarkets',
      'stfu',
      'OneTradingMarkets',
      'Dzengi',
      'OrbitInvest',
      'GCCBrokers',
      'VCGMarkets',
      'PolarisMarkets',
      'Zulu',
      'AfterPrime',
      'Cumbrae',
    ],
    env,
  )
) {
  features.push('DEFAULT_VIEWS');
}

if (
  _.includes(
    ['ICMCapital', 'stfu', 'OneTradingMarkets', 'OrbitInvest', 'GCCBrokers'],
    env,
  )
) {
  features.push('MT4_BONUS');
}

if (env === 'stfu') {
  features.push('etrass-export');
}

if (_.includes(['Internal', 'TradeCore', 'stfu', 'AvaTrade'], env)) {
  features.push('CPATTERN');
}

if (
  _.includes(
    [
      'AvaTrade',
      'Bancdm',
      'Internal',
      'TradeCore',
      'Oxdfx',
      'Zcom',
      'WebWallet',
      'NetLeaders',
      'FinancialSpreads',
      'PriceMarkets',
      'XSpotInternational',
      'BourseTrade',
      'ISTMarkets',
      'CoinZero',
      'CoreSpreads',
      'XSpotMarkets',
      'GoMarkets',
      'ICMCapital',
      'Fixi',
      'BlackStone',
      'EagleGlobalMarkets',
      'CSAustralia',
      'TradeDirect365',
      'stfu',
      'Seekers',
      'BOTH',
      'OneTradingMarkets',
      'OrbitInvest',
      'Dzengi',
      'GCCBrokers',
      'VCGMarkets',
      'PolarisMarkets',
      'Zulu',
      'AfterPrime',
      'Cumbrae',
    ],
    env,
  )
) {
  features.push('SIGNUP_DATA');
}

if (
  _.includes(
    [
      'FinancialSpreads',
      'BourseTrade',
      'ISTMarkets',
      'CoreSpreads',
      'Zcom',
      'PipIndex',
      'Oxdfx',
      'AvaTrade',
      'XSpotMarkets',
      'stfu',
      'ICMCapital',
      'CSAustralia',
      'GCCBrokers',
    ],
    env,
  )
) {
  features.push('MIFID');
}

if (
  _.includes(
    [
      'Bancdm',
      'XSpotInternational',
      'XSpotMarkets',
      'Internal',
      'TradeCore',
      'ICMCapital',
      'stfu',
      'GCCBrokers',
      'VCGMarkets',
      'AAAFX',
    ],
    env,
  )
) {
  features.push('MARKETING_CAMPAIGNS');
}

if (env === 'Bancdm' || env === 'AvaTrade' || env === 'stfu') {
  features.push('NYLAS', 'TELEPHONE_CALL_LINK');
}

if (
  _.includes(
    [
      'AvaTrade',
      'Internal',
      'TradeCore',
      'ICMCapital',
      'PipIndex',
      'CoreSpreads',
      'CSAustralia',
      'TradeDirect365',
      'BourseTrade',
      'FinancialSpreads',
      'ISTMarkets',
      'Zcom',
      'BlackStone',
      'PriceMarkets',
      'EagleGlobalMarkets',
      'XSpotInternational',
      'Oxdfx',
      'Fixi',
      'GoMarkets',
      'XSpotMarkets',
      'stfu',
      'OneTradingMarkets',
      'OrbitInvest',
      'GCCBrokers',
      'Dzengi',
      'VCGMarkets',
      'PolarisMarkets',
      'Zulu',
      'AfterPrime',
      'Cumbrae',
    ],
    env,
  )
) {
  features.push('ADD_MEMBER_OBJECT_ACL_RULES');
}

if (
  _.includes(
    [
      'AvaTrade',
      'Internal',
      'TradeCore',
      'PriceMarkets',
      'GoMarkets',
      'ICMCapital',
      'PipIndex',
      'CoreSpreads',
      'CSAustralia',
      'TradeDirect365',
      'BourseTrade',
      'FinancialSpreads',
      'ISTMarkets',
      'Zcom',
      'BlackStone',
      'EagleGlobalMarkets',
      'XSpotInternational',
      'Oxdfx',
      'Fixi',
      'XSpotMarkets',
      'stfu',
      'OneTradingMarkets',
      'OrbitInvest',
      'GCCBrokers',
      'Dzengi',
      'VCGMarkets',
      'PolarisMarkets',
      'AfterPrime',
      'Zulu',
      'Cumbrae',
    ],
    env,
  )
) {
  features.push('CLIENT_VISIBILITY_GROUPS');
}

if (
  _.includes(
    [
      'WebWallet',
      'NetLeaders',
      'AvaTrade',
      'Internal',
      'TradeCore',
      'stfu',
      'BOTH',
      'Seekers',
      'Zcom',
      'ICMCapital',
      'VCGMarkets',
      'FXView',
      'AAAFX',
      'AfterPrime',
    ],
    env,
  )
) {
  features.push('ONFIDO');
}

if (
  _.includes(
    [
      'Internal',
      'TradeCore',
      'Bancdm',
      'stfu',
      'CoreSpreads',
      'BourseTrade',
      'FinancialSpreads',
      'TradeDirect365',
      'CSAustralia',
      'BlackStone',
    ],
    env,
  )
) {
  features.push('COMPLIANCE_STATUS_ENABLED');
}

if (
  _.includes(
    [
      'Internal',
      'TradeCore',
      'FinancialSpreads',
      'PipIndex',
      'CoreSpreads',
      'CSAustralia',
      'TradeDirect365',
      'BourseTrade',
      'FinancialSpreads',
      'ISTMarkets',
      'Zcom',
      'BlackStone',
      'PriceMarkets',
      'EagleGlobalMarkets',
      'XSpotInternational',
      'Oxdfx',
      'Fixi',
      'GoMarkets',
      'XSpotMarkets',
      'ICMCapital',
      'stfu',
      'OneTradingMarkets',
      'OrbitInvest',
      'GCCBrokers',
      'Dzengi',
      'VCGMarkets',
      'PolarisMarkets',
      'AfterPrime',
      'Zulu',
      'Cumbrae',
    ],
    env,
  )
) {
  features.push('TOTAL_EQUITY_BASE_CURRENCY');
}

if (
  _.includes(
    [
      'PipIndex',
      'CoreSpreads',
      'CSAustralia',
      'TradeDirect365',
      'BourseTrade',
      'FinancialSpreads',
      'ISTMarkets',
      'BlackStone',
      'EagleGlobalMarkets',
      'stfu',
    ],
    env,
  )
) {
  features.push('OLD_TABLES');
}

if (_.includes(['Oxdfx', 'stfu'], env)) {
  features.push('DISABLE_INTERNAL_TRANSFER');
}

if (
  _.includes(
    [
      'FinancialSpreads',
      'ISTMarkets',
      'CoreSpreads',
      'stfu',
      'CSAustralia',
      'TradeDirect365',
    ],
    env,
  )
) {
  features.push('PUSH_VERIFICATION');
}

if (
  _.includes(
    [
      'ICMCapital',
      'OneTradingMarkets',
      'OrbitInvest',
      'Dzengi',
      'GCCBrokers',
      'VCGMarkets',
      'PolarisMarkets',
      'Polaris',
      'Zulu',
      'AfterPrime',
      'Cumbrae',
    ],
    env,
  )
) {
  features.push('TRADING_ACCOUNT_NUMBER');
}

if (_.includes(['ICMCapital'], env)) {
  features.push(
    'CHANGE_TA_PASSWORD',
    'IB_WITHDRAWAL_SELECT_METHOD_GATEWAY',
    'SINGLE_LEAD_CAMPAIGN_FIELD',
  );
}

if (!_.includes([], env)) {
  features.push('BLACKLIST_REASONS');
}

if (_.includes(['BlackStone'], env)) {
  features.push('WHITELIST_TRADING_ACCOUNTS');
}

if (
  _.includes(
    [
      'ICMCapital',
      'Zcom',
      'FinancialSpreads',
      'TradeDirect365',
      'CoreSpreads',
      'CSAustralia',
      'CSFinancial',
      'BourseTrade',
      'BlackStone',
      'TradeCore',
      'GCCBrokers',
    ],
    env,
  )


) {
  features.push('HOTJAR');
}

if (_.includes(['CoreSpreads', 'CSAustralia'], env)) {
  features.push('CLIENT_ORIGIN_FIELD');
}

if (
  _.includes(
    [
      'CoreSpreads',
      'CSAustralia',
      'TradeDirect365',
      'BourseTrade',
      'FinancialSpreads',
      'BlackStone',
      'EagleGlobalMarkets',
      'stfu',
    ],
    env,
  )
) {
  features.push('PAGINATION_10');
}

if (
  _.includes(
    [
      'AvaTrade',
      'TradeCore',
    ],
    env,
  )
) {
  features.push('MASK_PERSONAL_DETAILS');
}

export default features;
