import React, { Component } from 'react';
import { object, func, node, shape } from 'prop-types';

import Page from '../../../../components/Page';
import { gettext } from '../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import SignupUsersTable from './SignupUsersTable';
import InfoCard from '../../campaign-single/CampaignSingle/CampaignHeader/InfoCard/InfoCard';
import { hasAccess } from '../../../../logic/services/acl';
import TrackingLinkSidepanel, { SIDEPANEL_ID } from '../../campaign-single/CampaignSingle/TrackingLinksTable/TrackingLinkSidepanel';
import GraphCard from '../../campaigns/Campaigns/CampaignStat/GraphCard';
import appRoutes from '../../../../components/App/Router/appRoutes';

const fields = {
  NAME: 'name',
  DESCRIPTION: 'description',
  REGISTER_URL: 'register_url',
  SOURCE: 'source',
  MEDIUM: 'medium',
  CALLBACK_URL: 'callback_url',
  WHITELABEL: 'whitelabel',
};

const fieldKeys = Object.values(fields);

const text = {
  TITLE: gettext('Tracking links'),
  TITLES: {
    [fields.NAME]: gettext('Name'),
    [fields.DESCRIPTION]: gettext('Description'),
    [fields.REGISTER_URL]: gettext('Register url'),
    [fields.SOURCE]: gettext('Source'),
    [fields.MEDIUM]: gettext('Medium'),
    [fields.CALLBACK_URL]: gettext('Callback url'),
    [fields.WHITELABEL]: gettext('Whitelabel'),
  },
  EDIT: gettext('Edit'),
  VISITS: gettext('Visits'),
};

const infoModifiers = {
  whitelabel: ({ whitelabel }) => whitelabel && whitelabel.name,
};

const getBread = trackingLink => ([
  { 
    label: text.TITLE, 
    route: appRoutes.CAMPAIGNS,
  },
  ...(trackingLink ? [{
    label: trackingLink.name,
    route: '',
  }] : []),
]);

const api = ({ userId, campaignId, id }) => getDjangoApi(`team/profile/${userId}/campaigns/${campaignId}/tracking_links/${id}`);

const propTypes = {
  actions: object.isRequired,
  date: object.isRequired,
  setOnDateChangeHandler: func.isRequired,
  dateFilterComponent: node.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  match: object.isRequired,
};

class TrackingLinkSingle extends Component {
  constructor(props) {
    super(props);

    this.pageParams = props.match.params;

    this.infoActions = hasAccess('team.profile.campaigns.tracking_links.update') ? [{
      label: text.EDIT,
      onClick: this.onEdit,
    }] : null;
    
    this.state = {
      trackingLink: null,
    };
  }

  componentDidMount() {
    this.loadTrackingLinks();

    const { setOnDateChangeHandler } = this.props;
    setOnDateChangeHandler(this.loadTrackingLinks);
  }

  onEdit = () => {
    const { actions } = this.props;
    const { trackingLink: data } = this.state;
    actions.openSidepanel(SIDEPANEL_ID, data ? { data } : _);
  };

  loadTrackingLinks = () => {
    const { date: { startDate, endDate } } = this.props;
    api(this.pageParams)
      .list({
        period_from: startDate, 
        period_to: endDate,
      })
      .then(({ data }) => this.setState({ trackingLink: data }));
  }

  render() {
    const { trackingLink } = this.state;
    const { dateFilterComponent } = this.props;
    const { campaignId, userId } = this.pageParams;
    return (
      <Page
        title={text.TITLE}
        bread={getBread(trackingLink || {}, this.pageParams)}
      >
        <div className="campaign-header">
          {trackingLink && (
            <InfoCard
              data={trackingLink}
              fields={fieldKeys}
              text={text}
              modifers={infoModifiers}
              infoActions={this.infoActions}
            />
          )}
          <div className="campaign-header__visits">
            {trackingLink && (
              <GraphCard
                title={text.VISITS}
                data={trackingLink.visits_per_day}
                header={dateFilterComponent}
              />
            )}
          </div>
        </div>
        <SignupUsersTable {...this.pageParams} />
        <TrackingLinkSidepanel 
          onSuccess={this.loadTrackingLinks} 
          cid={campaignId} 
          uid={userId}
        />
      </Page>
    );
  }
}

TrackingLinkSingle.propTypes = propTypes;

export default TrackingLinkSingle;
