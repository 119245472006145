import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import AddToExclusionList from './AddToExclusionList';
import userActionsTypes from '../types';

const text = {
  LABEL: gettext('Add to exclusion list'),
};

const ACTION_ICON = 'Logs';

export default {
  name: 'addToExclusionList',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionsTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    const hasStatePermission = [
      USER_STATE.FULL,
      USER_STATE.PENDING,
      USER_STATE.LIMITED,
      USER_STATE.REVIEW,
      USER_STATE.LEAD,
      USER_STATE.PENDING_EMAIL_VERIFICATION,
      USER_STATE.PENDING_SMS_VERIFICATION,
      USER_STATE.DEMO,
    ].includes(state);

    return !isIb
    && hasAccess('user.add_to_exclusion.*') 
    && hasStatePermission;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(AddToExclusionList, sidepanelOptions);
  },
};
