import React, { Component } from 'react';
import ISO6391 from 'iso-639-1';
import { Select } from 'tc-biq-design-system';
import { currentLanguage, setLanguage, availableLanguages, gettext } from '../../../../../../logic/utilities/languageUtility';

const text = {
  CHANGE_LANGUAGE: gettext('Change language'),
};
 
class SelectLanguage extends Component {
  constructor(props) {
    super(props);
    const languages = ISO6391.getLanguages(availableLanguages);
    this.state = {
      languages,
      current: languages.find(lang => lang.code === currentLanguage),
    };
  }

  onLanguageChange = (current) => {
    this.setState({
      current,
    }, () => setLanguage(current.code));
  }

  render() {
    const { languages, current } = this.state;
    return (
      <Select
        label={text.CHANGE_LANGUAGE}
        type="single"
        onChange={this.onLanguageChange}
        options={languages}
        value={current}
        labelKey="nativeName"
        valueKey="code"
        clearable={false}
      />
    );
  }
}

export default SelectLanguage;
