import React, { Component } from 'react';
import { Accordion, Table, Button, notifier } from 'tc-biq-design-system';
import { array, object, string } from 'prop-types';

import AccordionHeader from '../../../../../components/AclGrid/AclCard/AccordionHeader';
import { statuses } from '../../../../../components/AclGrid/AclCard/AccordionHeader/HeaderCheckbox/HeaderCheckbox';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import FormFactory from '../../../../../components/form';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import to from '../../../../../logic/utilities/to';
import { hasAccess } from '../../../../../logic/services/acl';
import FormPanel from '../../../../../components/form/Components/FormPanel/FormPanel';

export const FORM_ID = 'EMAIL_NOTIFICATIONS_FORM';

const text = {
  USER_TASKS: gettext('User tasks'),
  TEAM_TASKS: gettext('Team tasks'),
  MY_CLIENTS: gettext('My clients'),
  SAVE: gettext('Save'),
  ERROR_GENERAL: gettext('Something went wrong.'),
  SUCCESS: gettext('Email preferences are updated'),
};

const data = {
  userTasks: {
    title: text.USER_TASKS,
    visible: true,
    fields: [
      'task_assigned',
      'task_unassigned',
      'task_due',
      'task_completed',
    ],
  },
  teamTask: {
    title: text.TEAM_TASKS,
    visible: true,
    fields: [
      'team_task_assigned',
      'team_task_unassigned',
      'team_task_due',
      'team_task_completed',
    ],
  },
  myClients: {
    title: text.MY_CLIENTS,
    visible: true,
    fields: [
      'user_duplicate_signup',
    ],
  },
};

const modifers = Object.keys(data)
  .reduce((acc, key) => ([...acc, ...data[key].fields]), [])
  .reduce((acc, key) => ({ ...acc, [key]: { label: '' } }), {});

const api = id => getDjangoApi(`team/profile/${id}/email-preferences`);

const propTypes = {
  fields: array.isRequired,
  values: object.isRequired,
  profileID: string.isRequired,
  actions: object.isRequired,
};

class EmailNotifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formID: 1,
      accordions: Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: true }), {}),
    };
  }

  componentDidUpdate(prevProps) {
    const hasOldFields = !_.isEmpty(_.get(prevProps, 'fields'));
    const hasFields = !_.isEmpty(_.get(this.props, 'fields'));
    if (hasFields && !hasOldFields) this.setFormValues();
  }

  onSubmit = async () => {
    const { actions, profileID } = this.props;
    const [err] = await to(actions.create(api(profileID)));
    err ? this.onError() : this.onSuccess();
  }

  onSuccess() {
    notifier.success(text.SUCCESS);
    this.resetForm();
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  onChange = key => (state) => {
    const { actions } = this.props;
    const { fields } = data[key];

    fields.forEach(id => actions.setFieldValue({
      id,
      value: statuses.CHECKED === state,
    }));
  }

  onHeaderClick = key => () => {
    this.setState(({ accordions }) => ({
      accordions: { ...accordions, [key]: !accordions[key] },
    }));
  }

  setFormValues = async () => {
    const { profileID } = this.props;
    const { data: formValues } = await api(profileID).retrieve();
    Object.keys(formValues).forEach((id) => {
      const { actions } = this.props;
      actions.setFieldValue({
        id,
        value: formValues[id],
      });
    });
  }
  
  getSectionStatus = (key) => {
    const visibleSections = this.getData();
    const section = visibleSections[key];
    const { fields } = section;
    const { values } = this.props;
    const checked = fields.filter(fieldKey => values[fieldKey]);

    if (checked.length === fields.length) return statuses.CHECKED;
    if (checked.length === 0) return statuses.UNCHECKED;
    return statuses.INTERMEDIATE;
  }

  getData = () => {
    const { fields } = this.props;
    const fieldsArray = fields.map(({ id }) => id);
    const newData = {};

    Object.keys(data).forEach((key) => {
      const section = data[key];
      const { fields: sectionFields } = section;
      if (sectionFields.some(f => fieldsArray.includes(f))) {
        newData[key] = data[key];
      }
    });

    return newData;
  }

  generateCols = (fields) => {
    const { fields: fieldsConf } = this.props;
    if (_.isEmpty(fieldsConf)) return [];
    const getLabel = id => fieldsConf.find(field => field.id === id).label;
    return [
      {
        title: gettext('Event'),
        key: 'event',
        render: ({ id }) => getLabel(id),
      },
      {
        title: gettext('Email'),
        key: 'email',
        width: '80px',
        // eslint-disable-next-line react/prop-types
        render: ({ id }) => <div style={{ textAlign: 'right' }}>{fields[id]}</div>,
      },
    ];
  }

  resetForm = () => {
    this.FormComponent = null;
    this.forceUpdate();
    this.setState(({ formID }) => ({
      formID: formID + 1,
    }));
  }

  formConfig = () => {
    const { profileID } = this.props;
    return {
      form: FORM_ID,
      api: api(profileID),
    };
  }

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const Form = this.renderForm();
    const { formID, accordions } = this.state;
    const visibleSections = this.getData();

    return (
      <FormPanel 
        height="full" 
        size="small"
      >
        <FormPanel.Section>
          <div className="email-notifications">
            <Form
              key={formID}
              formId={FORM_ID}
              modifiers={modifers}
              renderForm={fields => Object.keys(visibleSections).map(key => (
                <Accordion
                  key={key}
                  visible={accordions[key]}
                  title={visibleSections[key].title}
                  headerTemplate={(
                    <AccordionHeader
                      title={visibleSections[key].title}
                      status={this.getSectionStatus(key)}
                      onChange={this.onChange(key)}
                    />
                  )}
                  onClick={this.onHeaderClick(key)}
                >
                  <Table 
                    striped 
                    cols={this.generateCols(fields)}
                    data={visibleSections[key].fields.map(id => ({ id }))} 
                  />
                </Accordion>
              ))}
            />
          </div>
        </FormPanel.Section>
        {hasAccess('team.profile.email_preferences.create') && (
          <FormPanel.Submit>
            <Button type="submit" formId={FORM_ID} onClick={this.onSubmit}>{text.SAVE}</Button>
          </FormPanel.Submit>
        )}
      </FormPanel>
    );
  }
} 

EmailNotifications.propTypes = propTypes;

export default EmailNotifications;
