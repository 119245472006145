import PaymentBreakdown from './PaymentBreakdown';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../logic/enums/user-state';
import userState from '../../../../../../logic/filters/user-state';

export default {
  Component: () => PaymentBreakdown,
  label: gettext('Payment breakdown'),
  id: 'payment-breakdown',
  visibilityFn: (hasAccess, _isFeatureEnabled, isIb, user) => {
    const userStateValue = userState(user.state, true);
    const hasPermission = [
      USER_STATE.FULL,
      USER_STATE.LIMITED,
      USER_STATE.PENDING,
      USER_STATE.REVIEW,
      USER_STATE.ARCHIVED,
    ].includes(userStateValue);

    return !isIb 
      && hasAccess('user.payment_breakdown.list') 
      && hasPermission;
  },
};
