
import { bindActionCreators } from 'redux';

import CurrencyConverter from './CurrencyConverter';
import connect from '../../../../../../../../logic/connect';
import { FormActionsFactory } from '../../../../../../../../components/form';
import { fetchRate } from '../Model';

const FORM_KEY = 'MANUAL_DEPOSIT_FORM';

const { setFieldValue } = FormActionsFactory(FORM_KEY);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setFieldValue,
      fetchRate,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ forms, pages }) => {
  const form = forms[FORM_KEY];
  const page = pages.USER_SINGLE;
  const action = page.actions.CREDIT;
  return {
    formValues: form.values,
    formErrors: form.errors,
    rate: action.rate,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyConverter);
