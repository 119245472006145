import React from 'react';
import { ActionsCellWrapper, OnContentClickCell, StatusCell, UsernameCell } from '../../../../components/gridCellRenderers';
import { currencyFormatter } from '../../../../logic/utilities/formatters';
import { actionIcons } from '../../payments/PendingWithdrawals/modifier';

const modifier = (tableSingleActions, idAction, previewUser) => ({
  id: {
    cellRendererFramework: OnContentClickCell(idAction),
    width: 80,
  },
  user: {
    cellRendererFramework: UsernameCell(previewUser, { isIb: true }),
    width: 200,
  },
  amount: {
    cellRendererFramework: 
      // eslint-disable-next-line react/prop-types
      ({ value, data }) => <span>{currencyFormatter(value, data.currency.symbol)}</span>,
    cellStyle: { 'justify-content': 'flex-end' },
  },
  status: { cellRendererFramework: StatusCell },
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper(tableSingleActions, actionIcons(tableSingleActions)),
    pinned: 'right',
    width: 120,
  },
});

export default modifier;
