import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { bool, func, object } from 'prop-types';
import { Modal, notifier } from 'tc-biq-design-system';
import { gettext } from '../../../../logic/utilities/languageUtility';

import './HtmlPreviewModal.scss';
import FormFactory, { FormActionsFactory } from '../../../../components/form';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import to from '../../../../logic/utilities/to';
import connect from '../../../../logic/connect';

const text = {
  SAVE: gettext('Save'),
  CANCEL: gettext('Cancel'),
  TITLE: gettext('Add template group'),
  NAME: gettext('Name'),
  SUCCESS: gettext('Successfully created'),
};

const templateGroupApi = getDjangoApi('emails/template_groups');
const formId = 'BASE_TEMPLATE_FORM';

const { create } = FormActionsFactory(formId);

const formConfig = {
  form: formId,
  customFields: [
    {
      id: 'name',
      label: text.NAME,
      type: 'text',
    },
  ],
};

class TemplateGroupModal extends Component {
  constructor(props) {
    super(props);

    this.FormComponent = null;
  }

  createTemplateGroup = async () => {
    const { onCloseModal, values, actions } = this.props;

    const [error] = await to(actions.create(templateGroupApi, {
      name: values.name,
    }));

    if (!error) {
      notifier.success(text.SUCCESS);
      onCloseModal();
    }
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    
    const { FormComponent } = this;
    return (
      <FormComponent renderForm={fields => (
        <div>
          { fields.name }
        </div>
      )}
      />
    );
  }

  render() {
    const { visible, onCloseModal } = this.props;
    return (
      <Modal 
        visible={visible} 
        icon="Plus"
        confirmText={text.SAVE}
        cancelText={text.CANCEL}
        title={text.TITLE}
        onCancel={onCloseModal}
        onConfirm={this.createTemplateGroup}
      >
        { this.renderForm() }
      </Modal>
    );   
  }
}

TemplateGroupModal.propTypes = {
  visible: bool,
  onCloseModal: func.isRequired,
  actions: object.isRequired,
  values: object.isRequired,
};

TemplateGroupModal.defaultProps = {
  visible: false,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    create,
  }, dispatch),
});

const mapStateToProps = ({ forms }) => ({
  ...forms[formId],
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateGroupModal);
