import React, { PureComponent } from 'react';
import { InfoBox, Input, Select, Button } from 'tc-biq-design-system';
import { object, array, string, bool } from 'prop-types';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import Page from '../../../../../components/Page';
import QueryBuilder from '../../../../../components/QueryBuilder';
import getID from '../../../../../logic/utilities/getID';
import { refactoredExtractGroup } from '../../../../../logic/services/query-adapter';
import { onRuleError } from '../utils';
import appRoutes from '../../../../../components/App/Router/appRoutes';

import './DepositMethodRule.scss';

const text = {
  TITLE: {
    edit: gettext('Edit New Deposit Rule'),
    create: gettext('Create New Deposit Rule'),
  },
  INFOBOX_HEADING: gettext('You can only set up one rule for each method.'),
  INPUT_LABEL: gettext('Name'),
  SELECT_LABEL: gettext('Deposit method'),
  DEPOSIT_SETTINGS_CRUMB: gettext('Deposit method rules'),
  CREATE_BUTTON_LABEL: gettext('Create'),
  UPDATE_BUTTON_LABEL: gettext('Update'),
  DISCARD_BUTTON_LABEL: gettext('Discard'),
  CONDITION_ERROR_MESSAGE: gettext('Condition must exist in order to submit deposit method rule'),
};

const propTypes = {
  actions: object.isRequired,
  submitInProgress: bool,
  fields: array,
  methods: array,
  selectedMethod: object,
  name: string,
  group: object,
  errors: object,
  match: object.isRequired,
  history: object.isRequired,
};

const defaultProps = {
  submitInProgress: false,
  fields: [],
  methods: [],
  selectedMethod: null,
  name: '',
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  errors: {
    name: [''],
    method: [''],
  },
};

class DepositMethodRule extends PureComponent {
  constructor(props) {
    super(props);

    this.actions = props.actions;

    this.type = this.getType();
    this.id = props.match.params.id;

    this.crumbs = [
      { label: text.DEPOSIT_SETTINGS_CRUMB, route: appRoutes.SETTINGS_DEPOSITS },
      { label: text.TITLE[this.type], route: '' },
    ];
  }

  componentDidMount() {
    const { getFieldOptions, getDepositInfo } = this.actions;
    getFieldOptions().then(() => {
      if (this.id) getDepositInfo(this.id);
    });
  }

  componentWillUnmount() {
    this.actions.resetState();
  }

  getType = () => {
    const pathRoutes = getID();

    if (_.includes(pathRoutes, 'add')) {
      return pathRoutes[pathRoutes.length - 1] === 'add' ? 'create' : 'clone';
    } 
      
    return 'edit';
  }

  redirectToTableView = () => {
    const { history } = this.props;
    history.push(appRoutes.SETTINGS_DEPOSITS);
  };

  submitMethodRule = () => {
    const { name, selectedMethod, group } = this.props;
    const { submitDeposit } = this.actions;
    const condition = refactoredExtractGroup(group) || null;

    const request = {
      name,
      condition,
      ...(selectedMethod && { method: selectedMethod.value }),
    };

    submitDeposit(this.type === 'edit', request, this.id)
      .then(this.redirectToTableView, onRuleError);
  };

  render() {
    const { fields, methods, selectedMethod, name, group, errors, submitInProgress } = this.props;
    const { setSelectedMethod, setRuleName, setRuleCondition } = this.actions;

    return (
      <Page title={text.TITLE[this.type]} bread={this.crumbs}>
        <div className="deposit-method-rule__container-div">
          <InfoBox header={text.INFOBOX_HEADING} />
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
            <Input
              label={text.INPUT_LABEL}
              style={{ width: '100%', marginRight: '14px' }}
              placeholder={text.INPUT_LABEL}
              value={name}
              onChange={setRuleName}
              helpText={errors.name[0]}
              hasError={!!errors.name[0].length}
            />
            <div style={{ width: '100%' }}>
              <Select
                label={text.SELECT_LABEL}
                placeholder={text.SELECT_LABEL}
                options={methods}
                labelKey="display_name"
                onChange={setSelectedMethod}
                value={selectedMethod}
                helpText={errors.method[0]}
                hasError={!!errors.method[0].length}
              />
            </div>
          </div>
          <QueryBuilder
            group={group}
            onStateUpdate={setRuleCondition}
            fields={fields}
            className="deposit-method-rule__query-builder"
          />
          <div className="deposit-method-rule__submit-section">
            <Button
              style={{ float: 'right' }}
              onClick={this.submitMethodRule}
              disabled={submitInProgress}
            >
              {this.type !== 'edit' ? text.CREATE_BUTTON_LABEL : text.UPDATE_BUTTON_LABEL}
            </Button>
            <Button
              style={{ float: 'right' }}
              onClick={this.redirectToTableView}
              color="ghost"
            >
              {text.DISCARD_BUTTON_LABEL}
            </Button>
          </div>
        </div>
      </Page>
    );
  }
}

DepositMethodRule.propTypes = propTypes;
DepositMethodRule.defaultProps = defaultProps;

export default DepositMethodRule;
