import React from 'react';
import { bindActionCreators } from 'redux';
import { object, func } from 'prop-types';

import connect from '../../../../../../../logic/connect';
import CreateTaskForm from '../../../../../../../components/common/CreateTaskForm/CreateTaskForm';
import { FormActionsFactory } from '../../../../../../../components/form';
import { getTableActions, tableConfig } from '../../../Sections/Tasks/table';

const FORM_KEY = 'CREATE_EDIT_TASK_FORM';

const { create } = FormActionsFactory(FORM_KEY);

const propTypes = {
  actions: object.isRequired,
  closeCombox: func,
  dispatch: func.isRequired,
  table: object.isRequired,
};

const defaultProps = {
  closeCombox: null,
};

const CreateTask = ({ actions, dispatch, table, closeCombox, ...rest }) => {
  const onSuccess = () => {
    const tableAction = getTableActions();
    dispatch(tableAction.fetchTableData(table.query));
    closeCombox && closeCombox();
  };
  
  return (
    <CreateTaskForm 
      {...rest} 
      onSuccess={onSuccess} 
      createTask={actions.create} 
      formKey={FORM_KEY} 
    />
  );
};

CreateTask.propTypes = propTypes;
CreateTask.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      create,
    },
    dispatch,
  ),
  dispatch,
});

const mapStateToProps = ({ forms, pages }) => {
  const { reducerKey, tableKey } = tableConfig();
  const form = forms[FORM_KEY];
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    submitInProgress: form.submitInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTask);
