/* eslint react/prop-types: 0 */
import React, { PureComponent } from 'react';
import { bindActionCreators, compose } from 'redux';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import Page from '../../../../components/Page';
import Export from '../../../../components/Export';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import modifier from './modifiers';
import { TransactionSingle } from '../../../../components/common/sidepanels';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import overlayActions from '../../../../components/overlay';
import If from '../../../../components/If';
import UserPreview from '../../../../components/common/sidepanels/UserPreview';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';

const pageConfig = {
  apiUrl: 'ib/payment_transactions',
  reducerKey: 'IB_PAYMENT_HISTORY',
  tableKey: 'IB_PAYMENT_HISTORY_TABLE',
};

const text = {
  PAGE_LABEL: gettext('Payment History'),
};

const SIDEPANEL_ID = 'TRANSACTION_SINGLE';
const { GridComponent, actions } = GridFactory(pageConfig);
const bread = [{ label: text.PAGE_LABEL, route: 'ib-system/payment-history' }];

class IbPaymentHistory extends PureComponent {
  constructor(props) {
    super(props);

    this.headerActions = this.headerActions.bind(this);

    this.tableActions = {
      previewUser: props.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
      openTransaction: this.openSingleTransaction.bind(this),
    };
  }

  headerActions() {
    const { exportUrl } = this.props;
    return (
      <If condition={hasAccess('ib.payment_transactions.export.*')}>
        <Export url={exportUrl} />
      </If>
    );
  }

  async openSingleTransaction(data) {
    const { actions } = this.props;
    const response = await getDjangoApi(pageConfig.apiUrl).retrieve(data.id);
    actions.openSidepanel(SIDEPANEL_ID, response.data);
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions),
    };

    return (
      <Page headerActions={this.headerActions} bread={bread} title={text.PAGE_LABEL}>
        <Filters {...filterProps} />
        <GridComponent tableModifier={modifier(this.tableActions)} {...this.props} />
        <TransactionSingle isIb />
        <UserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(IbPaymentHistory); 
