import appRoutes from '../../../../../components/App/Router/appRoutes';
import EmailEventPage from './EmailEvent';

export const EmailEvent = {
  component: EmailEventPage,
  path: appRoutes.EMAIL_EVENT,
  aclId: 'emails.trigger_rules.update',
};


export const EmailEventCreate = {
  component: EmailEventPage,
  path: appRoutes.EMAIL_EVENT_CREATE,
  aclId: 'emails.trigger_rules.create',
};
