/* eslint react/prop-types: 0 */
import React from 'react';
import { Pill, Tooltip, Popconfirm, Icon } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import hideCols from '../../../logic/utilities/hideCols';
import { ActionsCellWrapper, UsernameCell } from '../../../components/gridCellRenderers';
import { gettext } from '../../../logic/utilities/languageUtility';
import appRoutes from '../../../components/App/Router/appRoutes';

const text = {
  ARCHIVE: gettext('Archive'),
  CANCEL: gettext('Cancel'),
  ARE_YOU_SURE: gettext('Are you sure want to archive this team member?'),
  STATUS: gettext('Status'),
};

const type = {
  inactive: { label: gettext('Inactive'), pill: 'status03' },
  invited: { label: gettext('Invited'), pill: 'status02' },
  live: { label: gettext('Live'), pill: 'status01' },
};


function getTypePillData(value) {
  return type[value] || { label: value, pill: 'neutral' };
}

const actionIcons = {
  archive: (actionHandler, data) => {
    if (data.type === 'inactive') return null;
    return (
      <Tooltip title={text.ARCHIVE} key="archive">
        <Popconfirm
          type="destructive"
          icon="Close"
          label={text.ARE_YOU_SURE}
          onConfirm={() => actionHandler(data)}
          buttonLabels={{
            cancel: text.CANCEL,
            confirm: text.ARCHIVE,
          }}
        >
          <Icon name="Close" />
        </Popconfirm>
      </Tooltip>
    );
  },
};

export default ({ archive, preview }) => ({
  ...hideCols(['id', 'full_name', 'object_acl_rules']),
  username: {
    cellRendererFramework: UsernameCell(preview, { isStaff: true }),
  },
  team: {
    cellRendererFramework: ({ value, data }) => {
      const { name } = value;
      return (
        <Link to={generatePath(appRoutes.TEAM_MEMBER, { id: data.id })}>
          <Pill startPropagation type="neutral">{name}</Pill>
        </Link>
      );
    },
  },
  type: {
    headerName: text.STATUS,
    quickFilter: {},
    cellRendererFramework: ({ value }) => {
      const { label, pill } = getTypePillData(value);
      return <Pill type={pill}>{label}</Pill>;
    },
  },
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper({ archive }, actionIcons),
    pinned: 'right',
    width: 100,
    suppressSizeToFit: true,
  },
});
