import React, { PureComponent } from 'react';
import { bool, array, object, string, number, func, shape } from 'prop-types';
import { Input, Button, TimeDelay, Toggle, Select, notifier } from 'tc-biq-design-system';
import { generatePath, withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import Page from '../../../../../components/Page';
import QueryBuilder from '../../../../../components/QueryBuilder';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import getID from '../../../../../logic/utilities/getID';
import { refactoredExtractGroup } from '../../../../../logic/services/query-adapter';
import { convertToMilliseconds } from '../../../../../logic/utilities/timeDuration';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';
import FormEl from '../../../../../components/form/Components/FormEl';
import { smsEventActions } from './Model';

import './SmsEvent.scss';

const delayTypeOptions = [
  { display_name: gettext('Minutes'), value: 'minutes' },
  { display_name: gettext('Hours'), value: 'hours' },
  { display_name: gettext('Days'), value: 'days' },
];

const text = {
  TITLE: {
    create: gettext('Create new sms event'),
    clone: gettext('Clone sms event'),
    edit: gettext('Edit sms event'),
  },
  NAME_INPUT_LABEL: gettext('Name'),
  ADD_DELAY_BUTTON_LABEL: gettext('Add delay'),
  DELAY_INPUT_LABEL: gettext('Delay'),
  SMS_EVENTS_TABLE: gettext('Sms events'),
  BODY_TEMPLATE_SELECT_LABEL: gettext('Body template'),
  EVENT_TRIGGER_SELECT_LABEL: gettext('Event trigger'),
  UPDATE_BUTTON_LABEL: gettext('Update'),
  CREATE_BUTTON_LABEL: gettext('Create'),
  DISCARD_BUTTON_LABEL: gettext('Discard'),
  BODY_TEMPLATE_REQUIRED: gettext('Body template is required'),
};

const propTypes = {
  actions: object.isRequired,
  fields: array,
  group: object,
  isEnabled: bool,
  name: string,
  hasDelay: bool,
  delay: number,
  delayType: object,
  bodyTemplate: object,
  bodyTemplateChoices: array,
  eventTrigger: object,
  eventTriggerChoices: array,
  errors: object,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  match: object.isRequired,
};

const defaultProps = {
  fields: [],
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  isEnabled: true,
  name: '',
  hasDelay: false,
  delay: 0,
  delayType: { display_name: gettext('Minutes'), value: 'minutes' },
  bodyTemplate: null,
  bodyTemplateChoices: [],
  eventTrigger: null,
  eventTriggerChoices: [],
  errors: {
    title: [''],
    event: [''],
    template: [''],
  },
};

class SmsEvent extends PureComponent {
  constructor(props) {
    super(props);

    this.actions = props.actions;
    const pathRoutes = getID();

    if (_.includes(pathRoutes, 'add-new')) {
      this.type = pathRoutes[pathRoutes.length - 1] === 'add-new' ? 'create' : 'clone';
    } else {
      this.type = 'edit';
    }

    if (this.type !== 'create') this.id = props.match.params.id;

    this.crumbs = [
      { label: text.SMS_EVENTS_TABLE, route: appRoutes.SMS_EVENTS },
      { label: text.TITLE[this.type], route: '' },
    ];
  }

  componentDidMount() {
    const { getEventOptions, getEventFieldChoices, getEventInfo, setEventTrigger } = this.actions;
    Promise.all([getEventOptions(), getEventFieldChoices()]).then(() => {
      if (this.type !== 'create') {
        getEventInfo(this.id).then(() => {
          const { eventTrigger } = this.props;
          setEventTrigger(eventTrigger, false);
        });
      }
    });
  }

  componentWillUnmount() {
    this.actions.resetState();
  }

  redirectToTable = () => {
    const { history } = this.props;
    history.push(generatePath(appRoutes.SMS_EVENTS));
  };

  changeEventTrigger = (trigger) => {
    const { setEventTrigger } = this.actions;
    setEventTrigger(trigger, true);
  };

  submit = () => {
    const { submitEvent, setTemplateError } = this.actions;

    const {
      name,
      group,
      isEnabled,
      eventTrigger,
      delay,
      delayType,
      bodyTemplate,
    } = this.props;

    if (!bodyTemplate) {
      setTemplateError(text.BODY_TEMPLATE_REQUIRED);
      return;
    }

    const condition = refactoredExtractGroup(group);
    const event = eventTrigger ? eventTrigger.value : null;
    const delayInMilliseconds = convertToMilliseconds(+delay, delayType.value);
    const action_params = {
      template: bodyTemplate.value,
      user: '{user.id}',
    };

    const request = {
      action: 'send_sms_user',
      name,
      condition,
      event,
      delay: delayInMilliseconds,
      is_enabled: isEnabled,
      action_params,
    };

    // TODO: @pavle Add a success notification once we introduce global notifications wrapper
    submitEvent(this.type === 'edit', request, this.id).then(this.redirectToTable, (errors) => {
      if (!errors.data.non_field_errors) return errors.data;
      notifier.error(errors.data.non_field_errors[0]);
    });
  };

  render() {
    const {
      group,
      fields,
      name,
      isEnabled,
      eventTrigger,
      eventTriggerChoices,
      bodyTemplate,
      bodyTemplateChoices,
      delay,
      delayType,
      hasDelay,
      errors,
    } = this.props;

    const {
      setEventName,
      toggleHasDelay,
      setEventDelay,
      setEventDelayType,
      setIsEventEnabled,
      setEventBodyTemplate,
      setEventCondition,
    } = this.actions;

    return (
      <Page title={text.TITLE[this.type]} bread={this.crumbs}>
        <FormEl>
          <div className="sms-event__container-div">
            <div className="sms-event__first-row-container">
              <Input
                style={{ width: '60%', marginRight: '14px' }}
                label={text.NAME_INPUT_LABEL}
                placeholder={text.NAME_INPUT_LABEL}
                value={name}
                onChange={setEventName}
                hasError={!!errors.name[0].length}
                helpText={errors.name[0]}
              />
              {!hasDelay && (
                <Button
                  style={{ width: '20%', marginTop: '22px', marginBottom: '0', height: '100%' }}
                  onClick={toggleHasDelay}
                >
                  {text.ADD_DELAY_BUTTON_LABEL}
                </Button>
              )}
              {hasDelay && (
                <TimeDelay
                  className="sms-event__time-delay"
                  label={text.DELAY_INPUT_LABEL}
                  delay={delay}
                  delayType={delayType}
                  delayTypeOptions={delayTypeOptions}
                  toggleHasDelay={toggleHasDelay}
                  setDelay={setEventDelay}
                  setDelayType={setEventDelayType}
                />
              )}
              <div className="sms-event__toggle-container">
                <span>State</span>
                <div style={{ paddingTop: '15%' }}>
                  <Toggle checked={isEnabled} onClick={setIsEventEnabled} />
                </div>
              </div>
            </div>
            <div className="sms-event__second-row-container">
              <Select
                label={text.BODY_TEMPLATE_SELECT_LABEL}
                className="sms-event__body-template-select"
                labelKey="display_name"
                value={bodyTemplate}
                options={bodyTemplateChoices}
                onChange={setEventBodyTemplate}
                hasError={!!errors.template[0].length}
                helpText={errors.template[0]}
              />
              <Select
                label={text.EVENT_TRIGGER_SELECT_LABEL}
                className="sms-event__event-trigger-select"
                labelKey="display_name"
                value={eventTrigger}
                options={eventTriggerChoices}
                onChange={this.changeEventTrigger}
                hasError={!!errors.event[0].length}
                helpText={errors.event[0]}
              />
            </div>
            {eventTrigger && (
              <QueryBuilder
                group={group}
                fields={fields}
                onStateUpdate={setEventCondition}
                className="sms-event__query-builder"
              />
            )}
            <div className="sms-event__submit-section">
              <Button style={{ float: 'right' }} type="submit" onClick={this.submit}>
                {this.type === 'edit' ? text.UPDATE_BUTTON_LABEL : text.CREATE_BUTTON_LABEL}
              </Button>
              <Button style={{ float: 'right' }} color="ghost" onClick={this.redirectToTable}>
                {text.DISCARD_BUTTON_LABEL}
              </Button>
            </div>
          </div>
        </FormEl>
      </Page>
    );
  }
}

SmsEvent.propTypes = propTypes;
SmsEvent.defaultProps = defaultProps;

const mapStateToProps = ({ pages }) => {
  const page = pages.SMS_EVENT_SINGLE_PAGE;
  return { ...page };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...smsEventActions }, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(SmsEvent);
