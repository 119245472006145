import React, { PureComponent } from 'react';
import { notifier } from 'tc-biq-design-system';
import { array, string, object, bool } from 'prop-types';
import { bindActionCreators } from 'redux';

import MultiPresetDoc from './MultiPresetDoc';
import SinglePresetDoc from './SinglePresetDoc';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import connect from '../../../../../../logic/connect';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import overlayActions from '../../../../../../components/overlay';
import { actions } from '../Model';

const propTypes = {
  actions: object.isRequired,
  documents: array.isRequired,
  docTypes: array.isRequired,
  sidepanel: object.isRequired,
  isMultiPreset: bool.isRequired,
  searchValue: string,
  checkedValue: object,
};

const defaultProps = {
  checkedValue: null,
  searchValue: '',
};

const text = {
  UPDATE_SUCCESS: gettext('Preset successfully changed'),
  UPDATE_ERROR: gettext('Error while changing preset'),
};

const SIDEPANEL_ID = 'CREATE_EDIT_DOCUMENT';

const filteredByText = (term, doc) => {
  if (!term) return true;
  return doc.display_name.toLowerCase().includes(term.toLowerCase());
};

const filteredByCheckedValues = (selected, doc, preset) => {
  if (!selected || selected.value === 'all') return true;
  if (selected.value === 'checked') return preset.document_types.includes(doc.value);
  if (selected.value === 'unchecked') return !preset.document_types.includes(doc.value);
};

const filterDocs = (searchValue, checkedValue, preset, isMultiPreset) => (doc) => {
  if (isMultiPreset) return filteredByText(searchValue, doc);
  return filteredByText(searchValue, doc) && filteredByCheckedValues(checkedValue, doc, preset);
};

class DocumentsBody extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  updatePreset = async (docId, presetId, checked) => {
    const { documents } = this.props;
    const selectedPreset = documents.find(preset => preset.id === presetId);
    if (checked) {
      selectedPreset.document_types.push(docId);
    } else {
      const docIndex = selectedPreset.document_types.indexOf(docId);
      selectedPreset.document_types.splice(docIndex, 1);
    }

    this.actions.updatePreset(presetId, selectedPreset).then(
      () => {
        this.actions.loadDocuments();
        notifier.success(text.UPDATE_SUCCESS);
      },
      () => notifier.error(text.UPDATE_ERROR),
    );
  };

  editDocument = async (event, data) => {
    event.stopPropagation();
    let existingData = {};
    const { sidepanel } = this.props;
    if (sidepanel.visible) this.actions.closeSidepanel(SIDEPANEL_ID);
    if (data && data.docId) {
      const { docId } = data;
      const response = await getDjangoApi(`document_types/${docId}`).list();
      existingData = { ...response.data };
    }
    this.actions.openSidepanel(SIDEPANEL_ID, { ...data, existingData });
  };

  componendDidMount() {
    const { docTypes } = this.props;
    docTypes.forEach(docType => this.setState({ [docType]: false }));
  }

  render() {
    const { documents, docTypes, searchValue, checkedValue, isMultiPreset } = this.props;
    const singlePreset = documents.filter(preset => preset.name === 'default')[0];
    const filteredDocTypes = docTypes
      .filter(filterDocs(searchValue, checkedValue, singlePreset, isMultiPreset))
      .sort((element1, element2) => element1.value - element2.value);
    return isMultiPreset ? (
      <div>
        {filteredDocTypes
          .map(doc => (
            <MultiPresetDoc
              key={doc.value}
              doc={doc}
              documents={documents}
              updatePreset={this.updatePreset}
              editDocument={this.editDocument}
            />
          ))}
      </div>
    ) : (
      <div className="biq-settings-documents__singlepreset">
        {filteredDocTypes.map(doc => (
          <SinglePresetDoc
            key={doc.value}
            doc={doc}
            preset={singlePreset}
            updatePreset={this.updatePreset}
            editDocument={this.editDocument}
          />
        ))}
      </div>
    );
  }
}

DocumentsBody.propTypes = propTypes;
DocumentsBody.defaultProps = defaultProps;

const mapStateToProps = ({ pages, overlays }) => {
  const data = pages.DOCUMENTS.documentsReducer;
  const sidepanel = overlays.CREATE_EDIT_DOCUMENT;
  const { documents, docTypes, searchValue, checkedValue, isMultiPreset } = data;
  return { documents, docTypes, searchValue, checkedValue, sidepanel, isMultiPreset };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loadDocuments: actions.loadDocuments,
      updatePreset: actions.updatePreset,
      openSidepanel: overlayActions.open,
      closeSidepanel: overlayActions.close,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(DocumentsBody));
