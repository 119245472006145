import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';


const actionTypes = {
  FETCH_TRADING_BACKENDS: 'FETCH_TRADING_BACKENDS',
  FETCH_TRADING_BACKENDS_FULFILLED: 'FETCH_TRADING_BACKENDS_FULFILLED',
  FETCH_TRADING_BACKENDS_PENDING: 'FETCH_TRADING_BACKENDS_PENDING',
  FETCH_TRADING_BACKENDS_REJECTED: 'FETCH_TRADING_BACKENDS_REJECTED',
  FETCH_TRADING_ACCOUNTS: 'FETCH_TRADING_ACCOUNTS',
  FETCH_TRADING_ACCOUNTS_FULFILLED: 'FETCH_TRADING_ACCOUNTS_FULFILLED',
  FETCH_TRADING_ACCOUNTS_PENDING: 'FETCH_TRADING_ACCOUNTS_PENDING',
  FETCH_TRADING_ACCOUNTS_REJECTED: 'FETCH_TRADING_ACCOUNTS_REJECTED',
  FETCH_LEVERAGE: 'FETCH_LEVERAGE',
  FETCH_LEVERAGE_FULFILLED: 'FETCH_LEVERAGE_FULFILLED',
  FETCH_LEVERAGE_PENDING: 'FETCH_LEVERAGE_PENDING',
  FETCH_LEVERAGE_REJECTED: 'FETCH_LEVERAGE_REJECTED',
  FETCH_LABELS: 'FETCH_LABELS',
  FETCH_LABELS_FULFILLED: 'FETCH_LABELS_FULFILLED',
  FETCH_LABELS_PENDING: 'FETCH_LABELS_PENDING',
  FETCH_LABELS_REJECTED: 'FETCH_LABELS_REJECTED',
  FETCH_ALLOWED_ACCOUNTS: 'FETCH_ALLOWED_ACCOUNTS',
  FETCH_ALLOWED_ACCOUNTS_FULFILLED: 'FETCH_ALLOWED_ACCOUNTS_FULFILLED',
  FETCH_ALLOWED_ACCOUNTS_PENDING: 'FETCH_ALLOWED_ACCOUNTS_PENDING',
  FFETCH_ALLOWED_ACCOUNTS_REJECTED: 'FFETCH_ALLOWED_ACCOUNTS_REJECTED',
};

export const fetchTradingBackends = createAction(actionTypes.FETCH_TRADING_BACKENDS, async (user) => {
  let whitelabelHeaders = null;
  if (isFeatureEnabled()('WHITELABEL') && !!user.whitelabel) {
    whitelabelHeaders = { whitelabel: user.whitelabel };
  }
  const api = getDjangoApi('trading_backends');
  const result = await api.list(null, whitelabelHeaders);
  const mappedBackends = result.data.results.map(backend => ({
    ...backend,
    value: backend.backend.id,
    display_name: `${backend.backend.name} (${backend.backend.mode})`,
  }));
  return mappedBackends;
});

export const fetchTradingAccounts = createAction(actionTypes.FETCH_TRADING_ACCOUNTS, async (user) => {
  const api = getDjangoApi(`users/${user.id}/trading_accounts`);
  const result = await api.list({ limit: 1000 });
  return result.data.results;
});

export const fetchLeverages = createAction(actionTypes.FETCH_LEVERAGE, async (account) => {
  const api = getDjangoApi(`trading_backends/${account.backend.id}/leverages/`);
  const result = await api.list({ limit: 1000 });
  return result.data;
});

export const fetchLabels = createAction(actionTypes.FETCH_LABELS, async () => {
  const api = getDjangoApi('trading_account_labels');
  const result = await api.list({ limit: 1000 });
  const mappedLabels = result.data.results.map(label => ({
    ...label,
    value: label.id,
    display_name: label.name,
  }));
  return mappedLabels;
});

export const fetchAllowedAccounts = createAction(actionTypes.FETCH_ALLOWED_ACCOUNTS, async (user) => {
  const api = getDjangoApi(`users/${user.id}/allowed_trading_accounts`);
  const result = await api.list();
  return result.data.results;
});

const initialState = {
  backends: [],
  fetchBackendInProgress: false,
  fetchBackendError: null,
  accounts: [],
  fetchAccInProgress: false,
  fetchAccError: null,
  leverages: [],
  fetchLeveragesInProgress: false,
  fetchLeveragesError: null,
  labels: [],
  fetchLabelsInProgress: false,
  fetchLabelsError: null,
  allowedAccounts: [],
  fetchAllowedAccountsInProgress: false,
  fetchAllowedAccountsError: null,
};

export default handleActions(
  {
    [actionTypes.FETCH_TRADING_BACKENDS_PENDING]: state => ({ ...state, fetchBackendInProgress: true }),
    [actionTypes.FETCH_TRADING_BACKENDS_FULFILLED]: (state, { payload }) => ({ ...state, fetchBackendInProgress: false, backends: payload }),
    [actionTypes.FETCH_TRADING_BACKENDS_REJECTED]: (state, { payload }) => ({ ...state, fetchBackendInProgress: false, fetchBackendError: payload }),
    [actionTypes.FETCH_TRADING_ACCOUNTS_PENDING]: state => ({ ...state, fetchAccInProgress: true }),
    [actionTypes.FETCH_TRADING_ACCOUNTS_FULFILLED]: (state, { payload }) => ({ ...state, fetchAccInProgress: false, accounts: payload }),
    [actionTypes.FETCH_TRADING_ACCOUNTS_REJECTED]: (state, { payload }) => ({ ...state, fetchAccInProgress: false, fetchAccError: payload }),
    [actionTypes.FETCH_LEVERAGE_PENDING]: state => ({ ...state, fetchLeveragesInProgress: true }),
    [actionTypes.FETCH_LEVERAGE_FULFILLED]: (state, { payload }) => ({ ...state, fetchLeveragesInProgress: false, leverages: payload }),
    [actionTypes.FETCH_LEVERAGE_REJECTED]: (state, { payload }) => ({ ...state, fetchLeveragesInProgress: false, fetchLeveragesError: payload }),
    [actionTypes.FETCH_LABELS_PENDING]: state => ({ ...state, fetchLabelsInProgress: true }),
    [actionTypes.FETCH_LABELS_FULFILLED]: (state, { payload }) => ({ ...state, fetchLabelsInProgress: false, labels: payload }),
    [actionTypes.FETCH_LABELS_REJECTED]: (state, { payload }) => ({ ...state, fetchLabelsInProgress: false, fetchLabelsError: payload }),
    [actionTypes.FETCH_ALLOWED_ACCOUNTS_PENDING]: state => ({ ...state, fetchAllowedAccountsInProgress: true }),
    [actionTypes.FETCH_ALLOWED_ACCOUNTS_FULFILLED]: (state, { payload }) => ({ ...state, fetchAllowedAccountsInProgress: false, allowedAccounts: payload }),
    [actionTypes.FETCH_ALLOWED_ACCOUNTS_REJECTED]: (state, { payload }) => ({ ...state, fetchLabelsInProgress: false, fetchAllowedAccountsError: payload }),
  },
  initialState,
);
