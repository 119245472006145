import React, { Fragment } from 'react';
import { Button } from 'tc-biq-design-system';
import { bool, func, shape, string } from 'prop-types';

import { gettext } from '../../logic/utilities/languageUtility';
import If from '../If';

const text = {
  CANCEL: gettext('Cancel'),
  CONFIRM: gettext('Save'),
};

const labels = {
  cancel: string,
  confirm: string,
};

const defaultLabels = {
  cancel: text.CANCEL,
  confirm: text.CONFIRM,
};

const propTypes = {
  buttonLabels: shape(labels),
  execute: func.isRequired,
  close: func,
  submitInProgress: bool,
  confirmColor: string,
  cancelColor: string, 
  disableSubmit: bool,
  formId: string,
};

const defaultProps = {
  close: null,
  buttonLabels: defaultLabels,
  submitInProgress: false,
  confirmColor: 'confirmation',
  cancelColor: 'ghost',
  disableSubmit: false,
  formId: null,
};

class SidepanelFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
    };

    this.onExecute = this.onExecute.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  onClickSubmit() {
    const { isDisabled } = this.state;
    if (isDisabled) return;

    this.setDisabled(true, this.onExecute);
  }

  setDisabled(isDisabled, callback) {
    this.setState(() => ({ isDisabled }), () => callback && callback());
  }

  onExecute = async () => {
    const { execute } = this.props;
    await execute();
    this.setDisabled(false);
  }

  render() {
    const { close, submitInProgress, buttonLabels, 
      confirmColor, cancelColor, disableSubmit, formId } = this.props;
    const { isDisabled } = this.state;

    return (
      <Fragment>
        <If condition={!!buttonLabels.cancel}>
          <Button color={cancelColor} onClick={close}>
            {buttonLabels.cancel}
          </Button>
        </If>
        <If condition={!!buttonLabels.confirm}>
          <Button
            type="submit"
            formId={formId}
            disabled={submitInProgress || isDisabled || disableSubmit}
            loading={submitInProgress}
            color={confirmColor}
            onClick={this.onClickSubmit}
          >
            {buttonLabels.confirm}
          </Button>
        </If>
      </Fragment>
    );
  }
}

SidepanelFooter.propTypes = propTypes;
SidepanelFooter.defaultProps = defaultProps;

export default SidepanelFooter;
