import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import { tradingAccountDisplay } from '../../../../../../../../logic/filters/trading-account-display';

const actionTypes = {
  ACTIVATE: 'ACTIVATE',
  ACTIVATE_FULFILLED: 'ACTIVATE_FULFILLED',
  FETCH_CARDS: 'FETCH_CARDS',
  FETCH_CARDS_FULFILLED: 'FETCH_CARDS_FULFILLED',
  INPUT_CHANGE: 'INPUT_CHANGE',
  UPLOAD: 'UPLOAD',
  UPLOAD_PENDING: 'UPLOAD_PENDING',
  UPLOAD_REJECTED: 'UPLOAD_REJECTED',
  UPLOAD_FULFILLED: 'UPLOAD_FULFILLED',
  RESET_FORM: 'RESET_FORM',
};

export const activate = createAction(actionTypes.ACTIVATE, (id, isIb) => {
  const promises = [
    getDjangoApi(`${isIb ? 'ib' : 'users'}/${id}/documents`).options(),
    getDjangoApi(`${isIb ? 'ib' : 'users'}/${id}/uploaded_documents`).list({ limit: 50 }),
    ...(isIb ? [] : [getDjangoApi(`users/${id}/trading_accounts`).list()]),
  ];

  return Promise.all(promises);
});

export const fetchCards = createAction(actionTypes.FETCH_CARDS, async (id) => {
  const response = await getDjangoApi(`users/${id}/cards`).list();
  return response.data.results;
});

export const onInputChange = createAction(actionTypes.INPUT_CHANGE, (field, value) => ({
  field, 
  value,
}));

export const upload = createAction(actionTypes.UPLOAD, async (uploader, request) => {
  const response = await uploader.upload(request);

  if (!response.ok) {
    const errors = await response.json();
    // eslint-disable-next-line
    return Promise.reject({ errors });
  }

  return response;
});

export const resetForm = createAction(actionTypes.RESET_FORM);

const initialState = {
  formData: {
    name: { value: '' },
    document_type: {
      value: {},
      choices: [],
    },
    related_to: {
      value: {},
      choices: [],
    },
    object_id: {
      value: {},
      choices: [],
    },
    file: {
      value: {},
    },
    file_backside: {
      value: {},
    },
  },
  submitInProgress: false,
  errors: {},
  documentTypes: [],
  tradingAccounts: [],
  cards: [],
  optionsFetched: false,
};

const transformChoices = choices => choices.map(choice => ({
  id: choice.value,
  label: choice.display_name,
}));

const optionsSuccess = (state, payload) => {
  const relatedToField = _.find(payload, { key: 'related_to' });
  const typeField = _.find(payload, { key: 'document_type' });

  return {
    ...state,
    formData: {
      ...state.formData,
      related_to: {
        ...state.formData.related_to,
        choices: transformChoices(relatedToField.choices),
      },
      ...(typeField ? { document_type: {
        ...state.formData.document_type,
        choices: transformChoices(typeField.choices),
      } } : {}),
    },
    optionsFetched: true,
  };
};

const onActivateSuccess = (state, { payload }) => {
  const [options, documentTypes, tradingAccounts] = payload;
  return {
    ...optionsSuccess(state, options.data.actions.POST.fields),
    documentTypes: documentTypes.data.results,
    ...(tradingAccounts ? { tradingAccounts: tradingAccounts.data.results.map(account => ({
      id: account.id,
      label: tradingAccountDisplay(account),
    })) } : {}),
  };
};

const onCardsSuccess = (state, { payload }) => ({
  ...state, cards: payload,
});

const handleInputChange = (state, { payload }) => { 
  const errors = { ...state.errors, [payload.field]: [] };

  const newFormData = {
    ...state.formData,
    [payload.field]: {
      ...state.formData[payload.field],
      value: payload.value,
    },
  };

  if (payload.field === 'related_to' && _.get(payload, 'value.label') !== 'client') {
    return { 
      ...state,
      errors,
      formData: {
        ...newFormData,
        object_id: {
          ...state.formData.object_id,
          choices: state.tradingAccounts,
        },
      },
    };
  }

  return { ...state, errors, formData: newFormData };
};

const uploadPending = state => ({ ...state, submitInProgress: true });
const uploadSuccess = state => ({ ...state, submitInProgress: false });
const uploadRejected = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  errors: payload.errors,
});

export default handleActions(
  {
    [actionTypes.ACTIVATE_FULFILLED]: onActivateSuccess,
    [actionTypes.FETCH_CARDS_FULFILLED]: onCardsSuccess,
    [actionTypes.INPUT_CHANGE]: handleInputChange,
    [actionTypes.UPLOAD_PENDING]: uploadPending,
    [actionTypes.UPLOAD_FULFILLED]: uploadSuccess,
    [actionTypes.UPLOAD_REJECTED]: uploadRejected,
    [actionTypes.RESET_FORM]: () => initialState,
  },
  initialState,
);
