import React, { Component } from 'react';

import withFormSidepanel from '../../../../../../../components/FormSidepanel';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

export const SIDEPANEL_ID = 'LINKS_FORM_SIDEPANEL';
export const FORM_ID = 'LINKS_FORM';

const apiUrl = 'trading_backends/manage_links';

const text = {
  CREATE: gettext('Create platform link'),
  EDIT: gettext('Edit platform link'),
  SUCCESS: {
    create: gettext('Platform link successfully created'),
    edit: gettext('Platform link successfully updated'),
  },
};

const formModifiers = {
  backend: {
    label: gettext('Trading platform'),
  },
};

class LinksSidepanel extends Component {
  constructor(props) {
    super(props);

    this.FormSidepanel = withFormSidepanel(FORM_ID, SIDEPANEL_ID);
  }
  
  getApi = () => getDjangoApi(apiUrl)

  getRequestPayload = () => ({ codename, currency, backend, whitelabel, destination }) => ({
    ...(codename && { codename: codename.value }),
    ...(currency && { currency: currency.value }),
    ...(backend && { backend: backend.value }),
    ...(whitelabel && { whitelabel: whitelabel.name }),
    ...(destination && { destination }),
  })

  prepareRowData = () => (data) => {
    const row = { ...data };
    row.currency = _.get(row, 'currency.name');
    row.backend = _.get(row, 'backend.name');
    row.whitelabel = _.get(row, 'whitelabel.name');
    return row;
  }

  render() {
    const { FormSidepanel, prepareRowData, getRequestPayload, getFormConfig, getApi } = this;
    return (
      <FormSidepanel
        {...this.props}
        options={{
          getApi,
          text,
          prepareRowData,
          getRequestPayload,
          getFormConfig,
          formModifiers,
        }}
      />
    );
  }
}

export default LinksSidepanel;
