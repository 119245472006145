import React, { PureComponent } from 'react';
import { Accordion, Table, Checkbox } from 'tc-biq-design-system';
import { object, func, string } from 'prop-types';
import { gettext } from '../../../logic/utilities/languageUtility';
import AccordionHeader from './AccordionHeader';

import './AclCard.scss';
import { statuses } from './AccordionHeader/HeaderCheckbox/HeaderCheckbox';

const text = {
  NAME: gettext('Name'),
  LIST: gettext('List'),
  CREATE: gettext('Create'),
  RETRIEVE: gettext('Retrieve'),
  UPDATE: gettext('Update'),
  DESTROY: gettext('Destroy'),
  UPLOAD: gettext('Upload'),
  ALL: gettext('All'),
  NA: gettext('-'),
};

const propTypes = {
  data: object.isRequired,
  onChange: func.isRequired,
  onToggle: func.isRequired,
  category: string.isRequired,
  onSelect: func.isRequired,
};

const prepareTableData = (data) => {
  const hashStatus = {};
  let status = statuses.CHECKED;
  const arr = Object.keys(data.names).map((name) => {
    Object.keys(data.names[name]).forEach((action) => {
      if (action !== 'visible') hashStatus[data.names[name][action]] = true;
    });
    return {
      id: name,
      name,
      ...data.names[name],
    };
  });

  if (hashStatus.false) {
    status = hashStatus.true ? statuses.INTERMEDIATE : statuses.UNCHECKED;
  }

  return {
    tableData: arr.filter(d => d.visible),
    status,
  };
};

// eslint-disable-next-line react/destructuring-assignment
const renderCheckbox = (name, onChange) => data => (typeof data[name] === 'boolean' ? <Checkbox onChange={e => onChange(`${data.name}.${name}`, e.target.checked)} checked={data[name]} /> : text.NA);

const cols = onChange => [
  { title: text.NAME, key: 'name' },
  { title: text.LIST, width: '50px', key: 'list', render: renderCheckbox('list', onChange) },
  { title: text.CREATE, width: '50px', key: 'create', render: renderCheckbox('create', onChange) },
  { title: text.RETRIEVE, width: '50px', key: 'retrieve', render: renderCheckbox('retrieve', onChange) },
  { title: text.UPDATE, width: '50px', key: 'update', render: renderCheckbox('update', onChange) },
  { title: text.UPLOAD, width: '50px', key: 'upload', render: renderCheckbox('upload', onChange) },
  { title: text.DESTROY, width: '50px', key: 'destroy', render: renderCheckbox('destroy', onChange) },
  { title: text.ALL, width: '50px', key: '*', render: renderCheckbox('*', onChange) },
];

class AclCard extends PureComponent {
  constructor(props) {
    super(props);
    const { onChange } = props;
    this.cols = cols(onChange);
  }

  onClick = () => {
    const { category, data, onToggle } = this.props;
    onToggle(category, { ...data, visible: !data.visible });
  }

  onChange = (status) => {
    const { category, onSelect } = this.props;
    onSelect(category, status);
  }

  render() {
    const { category, data } = this.props;
    const { tableData, status } = prepareTableData(data);
    return tableData.length > 0 ? (
      <div className="acl-card">
        <Accordion 
          visible={data.visible}
          title={category}
          headerTemplate={<AccordionHeader title={category} status={status} onChange={this.onChange} />}
          onClick={this.onClick}
        >
          <Table 
            striped 
            cols={this.cols} 
            data={tableData} 
          />
        </Accordion>
      </div>
    ) : null;
  }
}

AclCard.propTypes = propTypes;

export default AclCard;
