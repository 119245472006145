import pathToRegexp from 'path-to-regexp';

export const toRelativePath = path => (_.isString(path) ? path.substring(1) : path);

export const toSingleItemUrl = (id) => {
  const url = window.location.pathname;
  const match = url.match(/\/\d+\/?$/);
  if (match) {
    return `${url.substr(0, match.index)}/${id}`;
  }
  return `${url}/${id}`.replace('//', '/').replace(`/${window.location.host}`);
};

export default (url, params) => pathToRegexp.compile(url)(params);
