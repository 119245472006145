import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import WithdrawalMethodRule from './WithdrawalMethodRule';

import connect from '../../../../../logic/connect';
import { withdrawalMethodRuleActions } from './Model';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const mapStateToProps = ({ pages }) => {
  const page = pages.WITHDRAWAL_METHOD_RULE_SINGLE;
  return { ...page };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...withdrawalMethodRuleActions }, dispatch),
});

const WithdrawalMethodRulePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(WithdrawalMethodRule);

export default {
  component: WithdrawalMethodRulePage,
  aclId: 'settings.manage_withdrawal_methods.update',
  path: appRoutes.SETTINGS_WITHDRAWAL_METHODS,
};

export const WithdrawalMethodRuleCreate = {
  component: WithdrawalMethodRulePage,
  aclId: 'settings.manage_withdrawal_methods.create',
  path: appRoutes.SETTINGS_WITHDRAWAL_METHODS_CREATE,
};

export const WithdrawalMethodRuleClone = {
  component: WithdrawalMethodRulePage,
  aclId: 'settings.manage_withdrawal_methods.create',
  path: appRoutes.SETTINGS_WITHDRAWAL_METHODS_CLONE,
};
