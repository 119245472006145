/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import {
  Modal,
  Button,
  Input,
  Select,
  Upload,
  notifier,
  Space,
} from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import connect from '../../../../../../../../logic/connect';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import Uploader from '../../../../../../../../logic/services/uploader';
import { isPDF } from '../../../../../../../../logic/services/document';
import overlayActions from '../../../../../../../../components/overlay';
import If from '../../../../../../../../components/If';
import { onInputChange, upload, resetForm, activate, fetchCards } from './Model';
import { getTableActions } from '../../table';
import '../../Documents.scss';
import FormEl from '../../../../../../../../components/form/Components/FormEl';

const FORM_ID = 'UPLOAD_DOCUMENT_FORM';

const uploadFile = {
  initial: {
    title: gettext('Click here to upload file'),
    subtitle: gettext('Drag and drop files or click to browse'),
  },
  loading: {
    title: gettext('Uploading file'),
    subtitle: gettext('This may take a second, please do not leave the page'),
  },
};

const uploadFileBackside = {
  initial: {
    title: gettext('Click here to upload file backside'),
    subtitle: gettext('Drag and drop files or click to browse'),
  },
  loading: {
    title: gettext('Uploading file'),
    subtitle: gettext('This may take a second, please do not leave the page'),
  },
};

const text = {
  TITLE: gettext('Upload document'),
  UPLOAD_SUCCESS: gettext('Document uploaded successfully'),
  CLOSE: gettext('Close'),
  UPLOAD: gettext('Upload'),
  NAME: gettext('Name'),
  DOCUMENT_TYPE: gettext('Document type'),
  RELATED_TO: gettext('Related to'),
  OBJECT_ID: gettext('Object ID'),
  PDF_FILE_TITLE: gettext('PDF file selected'),
};

class UploadDocument extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    const endpoint = `${props.isIb ? 'ib' : 'users'}/${props.id}/documents`;
    this.uploaderApi = new Uploader(endpoint);
  }

  componentDidUpdate() {
    const { uploader, id, isIb } = this.props;
    const visible = _.get(this.props, 'modal.visible');

    if (visible && !uploader.optionsFetched) {
      this.actions.activate(id, isIb);
    }
  }

  componentWillUnmount() {
    this.actions.resetForm();
  }

  onUploadSuccess = () => {
    const { onSuccess } = this.props;

    this.updateDocumentsTable();
    notifier.success(text.UPLOAD_SUCCESS);
    onSuccess();
    this.closeModal();
  }

  save = () => {
    const { uploader } = this.props;
    const { formData } = uploader;

    const request = {
      name: formData.name.value,
      document_type: formData.document_type.value && formData.document_type.value.id,
    };

    if (!_.isEmpty(formData.file.value)) {
      request.file = formData.file.value;
    }

    if (this.hasTwoSides() && !_.isEmpty(formData.file_backside.value)) {
      request.file_backside = formData.file_backside.value;
    }

    if (_.get(formData, 'related_to.value.id')) request.related_to = formData.related_to.value.id;
    if (_.get(formData, 'object_id.value.id')) request.object_id = formData.object_id.value.id;

    this.actions.upload(this.uploaderApi, request)
      .then(this.onUploadSuccess);
  }

  updateDocumentsTable = () => {
    const { dispatch, id } = this.props;
    const actions = getTableActions(id);

    dispatch(actions.fetchTableData());
  }

  closeModal = () => {
    this.actions.resetForm();
    this.actions.close('UPLOAD_DOCUMENT');
  }

  modalFooter = () => {
    const { uploader } = this.props;
    const { submitInProgress } = uploader;

    return (
      <div>
        <Button onClick={this.closeModal} color="ghost">
          {text.CLOSE}
        </Button>

        <Button type="submit" formId={FORM_ID} onClick={this.save} color="confirmation" loading={submitInProgress} disabled={submitInProgress}>
          {text.UPLOAD}
        </Button>
      </div>
    );
  }

  hasTwoSides() {
    const { uploader } = this.props;
    const { formData, documentTypes } = uploader;
    const documentType = formData.document_type.value && _.find(documentTypes, { name: formData.document_type.value.label });

    return documentType && documentType.is_two_sided;
  }

  trimRelatedToChoices() {
    const { uploader } = this.props;
    const { tradingAccounts, formData } = uploader;
    const { related_to } = formData;

    return related_to.choices.filter(({ label }) => (
      label === 'client' || (label === 'trading account' && tradingAccounts.length > 0)
    ));
  }

  showObjectId() {
    const related_to = _.get(this.props, 'uploader.formData.related_to.value.label');
    return related_to === 'trading account' || related_to === 'card';
  }

  render() {
    const { modal, uploader, isIb } = this.props;
    const { formData, errors = {} } = uploader;

    const relatedToChoices = this.trimRelatedToChoices();

    return (
      <Modal
        icon="Upload"
        title={text.TITLE}
        footerRender={this.modalFooter}
        visible={modal.visible}
        size="large"
      >
        <FormEl formId={FORM_ID}>
          <div style={{ padding: '10px' }}>
            <div className="m-b">
              <Input
                hasError={!!errors.name && errors.name.length}
                helpText={errors.name ? errors.name[0] : null}
                label={text.NAME}
                onChange={e => this.actions.onInputChange('name', e.target.value)}
                value={formData.name.value}
              />
            </div>

            {!isIb && (
              <div className="m-b">
                <Select
                  hasError={!!errors.document_type && errors.document_type.length}
                  helpText={errors.document_type ? errors.document_type[0] : null}
                  label={text.DOCUMENT_TYPE}
                  type="single"
                  onChange={value => this.actions.onInputChange('document_type', value)}
                  value={formData.document_type.value}
                  options={formData.document_type.choices}
                />
              </div>
            )}

            <div className="m-b">
              <Select
                hasError={!!errors.related_to && errors.related_to.length}
                helpText={errors.related_to ? errors.related_to[0] : null}
                label={text.RELATED_TO}
                type="single"
                onChange={value => this.actions.onInputChange('related_to', value)}
                value={formData.related_to.value}
                options={relatedToChoices}
              />
            </div>

            <If condition={this.showObjectId()}>
              <div className="m-b">
                <Select
                  hasError={!!errors.object_id && errors.object_id.length}
                  helpText={errors.object_id ? errors.object_id[0] : null}
                  label={text.OBJECT_ID}
                  type="single"
                  onChange={value => this.actions.onInputChange('object_id', value)}
                  value={formData.object_id.value}
                  options={formData.object_id.choices}
                />
              </div>
            </If>
            <Space size={20} />
            <div className={classnames({ 'biq-profile__documents__two-sides': this.hasTwoSides() })}>
              <div className="m-b">
                <If condition={_.isEmpty(formData.file.value)}>
                  <Upload
                    status="initial"
                    onDrop={file => this.actions.onInputChange('file', file[0])}
                    localization={uploadFile}
                    hasError={!!errors.file && !!errors.file.length}
                    helpText={errors.file && errors.file[0]}
                  />
                </If>
                
                {!_.isEmpty(formData.file.value) && (
                  <>
                    <If condition={!isPDF(formData.file.value.name)}>
                      <img className="img-responsive" src={URL.createObjectURL(formData.file.value)} alt={formData.file.value.name} />
                    </If>

                    <If condition={isPDF(formData.file.value.name)}>
                      <span className="black-text">
                        {`${text.PDF_FILE_TITLE} (${formData.file.value.name})`}
                      </span>
                    </If>
                  </>
                )}
              </div>

              <If condition={this.hasTwoSides()}>
                <div className="m-b">
                  <If condition={_.isEmpty(formData.file_backside.value)}>
                    <Upload
                      status="initial"
                      onDrop={file => this.actions.onInputChange('file_backside', file[0])}
                      localization={uploadFileBackside}
                      hasError={!!errors.file_backside && !!errors.file_backside.length}
                      helpText={errors.file_backside && errors.file_backside[0]}
                    />
                  </If>

                  {!_.isEmpty(formData.file_backside.value) && (
                    <img className="img-responsive" src={URL.createObjectURL(formData.file_backside.value)} alt={formData.file.value.name} />
                  )}
                </div>
              </If>
            </div>
          </div>
        </FormEl>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    close: overlayActions.close,
    onInputChange,
    upload,
    resetForm,
    activate,
    fetchCards,
  }, dispatch),
  dispatch,
});

const mapStateToProps = ({ overlays, pages }) => ({
  modal: overlays.UPLOAD_DOCUMENT,
  uploader: pages.USER_SINGLE.uploadDocumentReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocument);
