/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Button, notifier } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';

import connect from '../../../logic/connect';
import isFeatureEnabled from '../../../logic/filters/is-feature-enabled';
import { gettext } from '../../../logic/utilities/languageUtility';
import GridFactory from '../../../components/grid';
import withUnmountMethod from '../../../components/hoc/withUnmountMethod';
import If from '../../../components/If';
import Page from '../../../components/Page';
import overlayActions from '../../../components/overlay';
import { getActiveFilters } from '../../../components/grid/GridUtils';
import Filters from '../../../components/Filters';
import { BiqUserPreview } from '../../../components/common/sidepanels';
import modifier from './modifier';
import AddEditBlacklistReason from './Sidepanel';
import { hasAccess } from '../../../logic/services/acl';
import withErrorBoundary from '../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../components/App/Router/appRoutes';

const pageConfig = {
  apiUrl: 'blacklist_reason',
  reducerKey: 'BLACKLIST_REASONS',
  tableKey: 'BLACKLIST_REASONS_TABLE',
};

const { GridComponent, actions } = GridFactory(pageConfig);

const text = {
  TITLE: gettext('Blacklist reasons'),
  DELETE_SUCCESS: gettext('Blacklist reason deleted successfully'),
  DELETE_ERROR: gettext('Error while deleting reason'),
  TOGGLE_SUCCESS: gettext('Blacklist reason successfully updated'),
  TOGGLE_ERROR: gettext('Error while updating reason state'),
  CREATE_NEW: gettext('Create new'),
};

const bread = [{ label: text.TITLE, route: appRoutes.SETTINGS_BLACKLIST_REASONS }];

class BlacklistReasons extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.headerActions = this.headerActions.bind(this);

    this.tableActions = {
      ...(hasAccess('blacklist_reason.update') && { onEdit: this.onEdit.bind(this) }),
      ...(hasAccess('blacklist_reason.destroy') && { onDelete: this.onDelete.bind(this) }),
      ...(hasAccess('blacklist_reason.update') && { onToggle: this.onToggle.bind(this) }),
      previewBiqUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  onEdit(data) {
    this.actions.openSidepanel('ADD_EDIT_BLACKLIST_REASON', { type: 'edit', data });
  }

  onDelete(data) {
    this.actions.deleteRow(data)
      .then(() => {
        notifier.success(text.DELETE_SUCCESS);
      }, () => {
        notifier.error(text.DELETE_ERROR);
      });
  }

  onToggle({ id }, is_enabled) {
    this.actions.updateRowData(id, { is_enabled })
      .then(() => {
        notifier.success(text.TOGGLE_SUCCESS);
      }, () => {
        notifier.error(text.TOGGLE_ERROR);
      });
  }

  headerActions() {
    return (
      <If condition={hasAccess('blacklist_reason.create')}>
        <Button onClick={() => this.actions.openSidepanel('ADD_EDIT_BLACKLIST_REASON', { type: 'add' })}>
          {text.CREATE_NEW}
        </Button>
      </If>
    );
  }

  render() {  
    const { fields, actions, table, addEditBlacklistReason } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions),
    };

    return (
      <Page bread={bread} headerActions={this.headerActions} title={text.TITLE}>
        <Filters {...filterProps} />
        <GridComponent
          singleActions={!!Object.keys(this.tableActions).length}
          tableModifier={modifier(this.tableActions)}
          {...this.props}
        />
        <BiqUserPreview />
        <If condition={addEditBlacklistReason.visible}>
          <AddEditBlacklistReason onSuccess={this.actions.fetchTableData} />
        </If>
      </Page>
    );
  }
}

const mapStateToProps = ({ pages, overlays }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
    addEditBlacklistReason: overlays.ADD_EDIT_BLACKLIST_REASON,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    updateRowData: actions.updateRowData, 
    deleteRow: actions.deleteRow,
    fetchTableData: actions.fetchTableData,
    openSidepanel: overlayActions.open,
    updateFilter: actions.updateFilter,
    changeSegment: actions.changeSegment,
    fetchOptions: actions.fetchOptions,
  }, dispatch), 
});

const BlacklistReasonsPage = compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(BlacklistReasons);

export default {
  component: BlacklistReasonsPage,
  path: appRoutes.SETTINGS_BLACKLIST_REASONS,
  aclFn: () => hasAccess('blacklist_reason.list') && isFeatureEnabled()('BLACKLIST_REASONS'),
};
