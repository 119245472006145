import { bindActionCreators, compose } from 'redux';
import {
  withRouter,
} from 'react-router-dom';

import Login, { FORM_KEY } from './Login';
import connect from '../../../logic/connect';
import { FormActionsFactory } from '../../../components/form';
import { clearPostLoginRoute, loadAuthSession } from '../../../logic/session/Model';
import appRoutes from '../../../components/App/Router/appRoutes';
import withErrorBoundary from '../../../components/hoc/withErrorBoundary';

const formActions = FormActionsFactory(FORM_KEY);

const mapStateToProps = ({ forms, session }) => ({ ...forms[FORM_KEY], session });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...formActions, clearPostLoginRoute, loadAuthSession }, dispatch),
});

export default {
  component: compose(
    connect(mapStateToProps, mapDispatchToProps),
    withErrorBoundary,
    withRouter,
  )(Login),
  aclFn: () => true,
  path: appRoutes.LOGIN,
  anonymousAccess: true,
};
