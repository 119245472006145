import { bindActionCreators } from 'redux';

import AssignSalesManager from '../../../../../components/common/sidepanels/bulk/AssignSalesManager';
import connect from '../../../../../logic/connect';
import overlayActions from '../../../../../components/overlay';
import bulkActionsActionFactory from '../../../../../components/common/sidepanels/bulk/actions';


const { 
  updateData, 
  resetInput, 
  submit, 
  loadOptions, 
} = bulkActionsActionFactory('CLIENTS_ASSIGN_SALES_MANAGER');


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateData,
      resetInput,
      submit,
      loadOptions,
      closeSidepanel: overlayActions.close,
      updateSidepanel: overlayActions.update,
    },
    dispatch,
  ),
});
  
const mapStateToProps = ({ overlays, pages }) => {
  const assignSalesManager = pages.CLIENTS.bulkActions.ASSIGN_SALES_MANAGER;
  
  return {
    sidepanel: overlays.ASSIGN_SALES_MANAGER,
    fieldData: assignSalesManager.fieldData,
    submitInProgress: assignSalesManager.submitInProgress,
    submitSuccess: assignSalesManager.submitSuccess,
    errors: assignSalesManager.errors,
    options: assignSalesManager.options,
  };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignSalesManager);
