import { func, string } from 'prop-types';
import React from 'react';
import { HyperLink } from 'tc-biq-design-system';

const TradingAccountLink = ({ displayName, userId, id, openTradingAccountSidepanel }) => (
  <HyperLink>
    <a onClick={() => openTradingAccountSidepanel({ userId, id })}>{displayName}</a>
  </HyperLink>
);

TradingAccountLink.propTypes = {
  displayName: string.isRequired,
  id: string.isRequired,
  userId: string.isRequired,
  openTradingAccountSidepanel: func.isRequired,
};

export default TradingAccountLink;
