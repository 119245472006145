
import { handleActions } from 'redux-actions';

import { OPEN, UPDATE, CLOSE, RESET, UPDATE_EXTRA } from './overlayActionTypes';
import { overlays } from '.';

const overlayInitialState = { 
  visible: false, 
  parameters: null, 
  extra: null,
};

export const activeOverlays = overlays.reduce((allOverlays, overlay) => ({ ...allOverlays, [overlay]: overlayInitialState }), {});

export const open = (state, action) => {
  const { id, data, options, extra = null } = action.payload;
  if (state[id]) return { ...(options.closeOthers ? activeOverlays : state), [id]: { visible: true, parameters: data || null, extra } };
  return { ...state };
};

export const update = (state, action) => {
  const { id, data } = action.payload;
  const overlay = state[id];
  if (overlay) return { ...state, [id]: { ...overlay, parameters: data } };
  return { ...state };
};

export const updateExtra = (state, action) => {
  const { id, data } = action.payload;
  const overlay = state[id];
  if (overlay) return { ...state, [id]: { ...overlay, extra: { ...overlay.extra, ...data } } };
  return { ...state };
};

export const close = (state, action) => {
  const id = action.payload;
  if (state[id]) return { ...state, [id]: { visible: false, parameters: null } };
  return { ...state };
};

export const resetState = () => ({ ...activeOverlays });

const overlayReducer = handleActions({ 
  [OPEN]: open, 
  [UPDATE]: update, 
  [UPDATE_EXTRA]: updateExtra, 
  [CLOSE]: close, 
  [RESET]: resetState,
}, activeOverlays);

export const getOverlays = state => state.overlays;
export const getOverlay = (state, overlay) => getOverlays(state)[overlay];
export const getOverlayParameters = (state, overlayName) => {
  const overlay = getOverlay(state, overlayName);
  return overlay && overlay.parameters ? overlay.parameters : {};
};
export const getOverlayVisibility = (state, overlayName) => {
  const overlay = getOverlay(state, overlayName);
  return overlay && overlay.visible;
};
export const getOverlayExtra = (state, overlayName) => {
  const overlay = getOverlay(state, overlayName);
  return overlay && overlay.extra;
};

export default overlayReducer;
