import React from 'react';
import { Icon, Tooltip } from 'tc-biq-design-system';

import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { ActionsCellWrapper } from '../../../../../../../../components/gridCellRenderers';

const text = {
  EDIT: gettext('Edit'),
};

export const actionIcons = {
  edit: (actionHandler, data) => (
    <div key="Edit" onClick={() => actionHandler(data)}>
      <Tooltip title={text.EDIT} placement="top">
        <Icon colorName="text-status01-700" name="Edit" />
      </Tooltip>
    </div>
  ),
};

const modifier = actions => ({
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper(actions, actionIcons),
    pinned: 'right',
    width: 60,
  },
});

export default modifier;
