import Campaign from './Campaign';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

export default {
  Component: () => Campaign,
  label: gettext('Campaign'),
  id: 'campaign',
  visibilityFn: (hasAccess, _isFeatureEnabled, isIb) => hasAccess('user.marketing_data.list') 
    && hasAccess('team.profile.campaigns.list') 
    && !isIb,
};
