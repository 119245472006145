import React, { Component, Fragment } from 'react';
import { notifier } from 'tc-biq-design-system';
import { func, object } from 'prop-types';

import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import modifiers from './modifiers';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import to from '../../../../../../logic/utilities/to';
import AccountTypesSidepanel, { SIDEPANEL_ID } from './AccountTypesSidepanel';
import { hasAccess } from '../../../../../../logic/services/acl';

const apiUrl = 'trading_account_labels';
const api = getDjangoApi(apiUrl);

export const tableConfig = () => ({
  reducerKey: 'TRADING_PLATFORM',
  apiUrl,
  tableKey: 'ACCOUNT_TYPES_TABLE',
});

const text = {
  SUCCESS: gettext('Trading account type deleted'),
  ERROR: gettext('Trading account type delete failed'),
};

const propTypes = {
  dispatch: func.isRequired,
  actions: object.isRequired,
};

class AccountTypesTable extends Component {
  constructor(props) {
    super(props);
    this.Grid = withTable(GridTab, tableConfig, null, { withActions: true })();
    this.tableActions = {
      ...(hasAccess('trading_account_labels.destroy') && { onDelete: this.onDelete }),
      ...(hasAccess('trading_account_labels.update') && { onEdit: this.onEdit }),
    };
    this.showActions = !!Object.keys(this.tableActions).length;
  }

  onDelete = async (data) => {
    const [err] = await to(api.destroy(data.id));
    if (err) {
      notifier.error(text.ERROR);
    } else {
      notifier.success(text.SUCCESS);
      this.reloadTable();
    }
  }

  onEdit = (data) => {
    const { actions } = this.props;
    actions.openSidepanel(SIDEPANEL_ID, { data });
  }

  reloadTable = () => {
    const { dispatch } = this.props;
    const [, tableActions] = this.Grid;
    dispatch(tableActions.fetchTableData());
  }

  render() {
    const [Table] = this.Grid;
    return (
      <Fragment>
        <Table
          singleActions
          modifier={modifiers(this.tableActions)}
        />
        <AccountTypesSidepanel onSuccess={this.reloadTable} />
      </Fragment>
    );
  }
}

AccountTypesTable.propTypes = propTypes;

export default AccountTypesTable;
