import React from 'react';
import { bool, string, func } from 'prop-types';
import { Modal, Button } from 'tc-biq-design-system';
import { gettext } from '../../../../logic/utilities/languageUtility';

import './HtmlPreviewModal.scss';

const text = {
  CLOSE: gettext('Close'),
  TITLE: gettext('HTML preview'),
};


const footer = ({ onCloseModal }) => () => <Button onClick={onCloseModal}>{ text.CLOSE }</Button>;

const HtmlPreviewModal = ({ visible, html = '', onCloseModal }) => (
  <Modal 
    visible={visible} 
    icon="View"
    title={text.TITLE}
    size="large"
    className="html-preview-modal"
    footerRender={footer({ onCloseModal })}
  >
    <iframe srcDoc={html} title="preview" className="html-preview-modal__iframe" />
  </Modal>
);

HtmlPreviewModal.propTypes = {
  visible: bool,
  html: string,
  onCloseModal: func.isRequired,
};

HtmlPreviewModal.defaultProps = {
  visible: false,
  html: '',
};

export default HtmlPreviewModal;
