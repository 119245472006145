import { createAction, handleActions } from 'redux-actions';

const actionTypes = {
  FETCH_KYC: 'FETCH_KYC',
  FETCH_KYC_REJECTED: 'FETCH_KYC_REJECTED',
  FETCH_KYC_FULFILLED: 'FETCH_KYC_FULFILLED',
  REVALIDATE: 'REVALIDATE',
  REVALIDATE_PENDING: 'REVALIDATE_PENDING',
  REVALIDATE_REJECTED: 'REVALIDATE_REJECTED',
  REVALIDATE_FULFILLED: 'REVALIDATE_FULFILLED',
  CONTINUE_VALIDATION: 'CONTINUE_VALIDATION',
  MARK_AS_FAILED: 'MARK_AS_FAILED',
};

export const fetchKycData = createAction(
  actionTypes.FETCH_KYC, 
  api => Promise.all([api.options(), api.list()]),
);

export const revalidate = createAction(
  actionTypes.REVALIDATE,
  api => api.create(),
  (_api, id) => ({ id }),
);

const initialState = {
  data: [],
  checksInProgress: [],
  errors: null,
};

const fetchRejected = (state, { payload }) => ({
  ...state,
  errors: payload.data,
});

const fetchFulfilled = (state, { payload }) => { 
  const [options, response] = payload;
  const fields = _.get(options, 'data.actions.GET.fields');

  const gateways = _.find(fields, { key: 'gateway' }).choices;
  const runningStates = _.find(fields, { key: 'running_state' }).choices;
  const profileStates = _.find(fields, { key: 'profile_state' }).choices;

  const kycData = response.data.results.map((kycItem) => {
    const runningState = _.find(runningStates, { value: kycItem.running_state });
    const profileState = _.find(profileStates, { value: kycItem.profile_state });
    const gateway = _.find(gateways, { value: kycItem.gateway });

    return {
      id: kycItem.id,
      updated: kycItem.updated,
      profileState,
      runningState,
      communication: kycItem.communication,
      gateway,
    };
  });

  return {
    ...state,
    data: kycData,
  };
};

const revalidatePending = (state, { meta }) => ({
  ...state,
  checksInProgress: [...state.checksInProgress, meta.id],
});

const revalidateFinish = (state, { meta }) => ({
  ...state,
  checksInProgress: state.checksInProgress.filter(id => id !== meta.id),
});

export default handleActions(
  {
    [actionTypes.FETCH_KYC_FULFILLED]: fetchFulfilled,
    [actionTypes.FETCH_KYC_REJECTED]: fetchRejected,
    [actionTypes.REVALIDATE_PENDING]: revalidatePending,
    [actionTypes.REVALIDATE_REJECTED]: revalidateFinish,
    [actionTypes.REVALIDATE_FULFILLED]: revalidateFinish,
  },
  initialState,
);
