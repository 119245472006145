import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import Archive from './Archive';
import userActionTypes from '../types';

const text = {
  LABEL: gettext('Archive'),
};

// @TODO missing archive icon
const ACTION_ICON = 'Delete';

export default {
  name: 'archive',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    if (isIb) {
      return !user.is_archived && hasAccess('ib.archive.*');
    } 

    const hasStatePermission = [
      USER_STATE.FULL,
      USER_STATE.PENDING,
      USER_STATE.LIMITED,
      USER_STATE.REVIEW,
      USER_STATE.ALERT,
      USER_STATE.EXCLUDED,
      USER_STATE.BLACKLIST,
      USER_STATE.UNSUITABLE,
      USER_STATE.UNSUITABLE_REFER,
      USER_STATE.UNSUITABLE_DEMO,
      USER_STATE.UNSUITABLE_REFER_DEMO,
      USER_STATE.REJECTED,
      USER_STATE.INCOMPLETE,
      USER_STATE.KYC_CHECK_IN_PROGRESS,
      USER_STATE.REVIEW,
      USER_STATE.PENDING_EMAIL_VERIFICATION,
      USER_STATE.PENDING_SMS_VERIFICATION,
      USER_STATE.LEAD,
      USER_STATE.DEMO,
    ].includes(state);

    return hasAccess('user.archive.*') && hasStatePermission;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(Archive, sidepanelOptions);
  },
};
