import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  UNLOCK_LOGIN: 'UNLOCK_LOGIN',
  UNLOCK_LOGIN_FULFILLED: 'UNLOCK_LOGIN_FULFILLED',
  UNLOCK_LOGIN_PENDING: 'UNLOCK_LOGIN_PENDING',
  UNLOCK_LOGIN_REJECTED: 'UNLOCK_LOGIN_REJECTED',
};

export const unlockLogin = createAction(actionTypes.UNLOCK_LOGIN, async (user) => {
  const unlockLoginApi = getDjangoApi(`users/${user.id}/unlock_login/`);
  
  await unlockLoginApi.create();
});


const initialState = {
  submitInProgress: false,
  errors: null,
};

const pendingHandler = state => ({ ...state, submitInProgress: true });

const fulfilledHandler = state => ({
  ...state,
  submitInProgress: false,
  error: null,
});

const rejectedHandler = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  errors: payload.data,
});

export default handleActions(
  {
    [actionTypes.UNLOCK_LOGIN_PENDING]: pendingHandler,
    [actionTypes.UNLOCK_LOGIN_FULFILLED]: fulfilledHandler,
    [actionTypes.UNLOCK_LOGIN_REJECTED]: rejectedHandler,
  },
  initialState,
);
