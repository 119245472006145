import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { notifier, Sidepanel, Space } from 'tc-biq-design-system';
import { array, bool, func, object } from 'prop-types';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import FormFactory from '../../../../../../components/form';
import { SidepanelFooter } from '../../../../../../components/common';
import { getTableActions } from '../../Sections/TradingAccounts/table';
import { Field, formErrorHandler } from '../../../../../../components/form/logic/utils';
import { useDispatch, useSelector } from '../../../../../../logic/utilities/hooks';
import { fetchTradingBackends, fetchAllowedAccounts, fetchLabels } from './Model';
import { getUserDetails } from '../../../../../../logic/utilities/storageGetters';
import { isRequiredValidator } from '../../../../../../components/form/logic/validators';

const text = {
  SUCCESS_CREATE: gettext('Added new trading account for user.'),
  CHOOSE_GROUP: gettext('Choose group'),
  SERVICE: gettext('Service'),
  CURRENCY: gettext('Currency'),
  ACCOUNT_TYPE: gettext('Account type'),
  LABEL: gettext('Trading Account Type'),
  BUTTON_LABels: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const FORM_KEY = 'TRADING_ACCOUNT_FORM';

const formModifiers = {
  backend: {
    validators: [isRequiredValidator()],
  },
  currency: {
    validators: [isRequiredValidator()],
  },
  label: {
    validators: [isRequiredValidator()],
  },
  group: {
    validators: [isRequiredValidator()],
  },
};

const customFooter = (execute, close, submitInProgress, disableSubmit) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    disableSubmit={disableSubmit}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABels}
    formId={FORM_KEY}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  formFields: array,
  formErrors: object,
  formValues: object,
  submitInProgress: bool.isRequired,
  accounts: array.isRequired,
  backends: array.isRequired,
  labels: array.isRequired,
  dispatch: func.isRequired,
  trandingAccounts: array,
  allowedAccounts: array,
};

const defaultProps = {
  user: null,
  actions: null,
  formFields: [],
  formErrors: {},
  formValues: {},
  trandingAccounts: [],
  allowedAccounts: [],
};

const TradingAccountAvaForm = (props) => {
  /**
   * we can use this form as 'Edit TD form' in the future (if needed)
   * but in this case we need to improve it
   */
  const {
    sidepanelManager,
    actions,
    submitInProgress,
    formValues,
    labels,
    formErrors,
    backends,
    allowedAccounts,
    formFields,
    ...options
  } = props;

  const user = useSelector(getUserDetails);
  const dispatch = useDispatch();
  const api = useCallback(() => getDjangoApi(`users/${user.id}/trading_accounts`), [user]);

  const onSuccess = useCallback(() => {
    notifier.success(text.SUCCESS_CREATE);
    dispatch(getTableActions(user.id).fetchTableData());
    sidepanelManager.close();
  }, [sidepanelManager, user]);

  const onError = payload => formErrorHandler()(payload);

  const onClose = useCallback(() => sidepanelManager.close(), [sidepanelManager]);

  const onSubmit = useCallback(async () => {
    const { backend, currency, group, label } = formValues;
    const formatter = () => ({
      account_type: label?.id || null,
      backend: backend?.backend?.id || null,
      currency: currency?.value || null,
      group: group?.id || null,
    });
    const params = [api(), formatter];
    const [err] = await to(actions.create(...params));
    err ? onError(err) : onSuccess();
  }, [api, formValues, onError, onSuccess]);

  const onBackendChange = useCallback(backend => actions.setFieldValue({
    id: 'backend',
    value: backend,
  }), [actions]);

  const onCurrencyChange = useCallback((currency) => {
    actions.setFieldValue({
      id: 'currency',
      value: currency,
    });
  }, [actions]);

  const onLabelChange = useCallback(label => actions.setFieldValue({
    id: 'label',
    value: label,
  }), [actions]);

  const onGroupChange = useCallback(group => actions.setFieldValue({
    id: 'group',
    value: group,
  }), [actions]);

  useEffect(() => {
    dispatch(fetchTradingBackends(user));
    dispatch(fetchAllowedAccounts(user));
    dispatch(fetchLabels());
  }, [user]);

  const allowedBackends = useMemo(
    () => backends.filter(
      tb => allowedAccounts.findIndex(
        account => account.trading_backend === tb.backend.name) > -1), [backends, allowedAccounts]);
  useEffect(() => onBackendChange(allowedBackends?.[0]), [allowedBackends, onBackendChange]);

  const allowedCurrencies = useMemo(
    () => {
      const allowedCurrencySymbols = allowedAccounts
        .filter(account => account.trading_backend === formValues.backend?.backend.name)
        .map(account => account.currency);
      return formFields.find(field => field.id === 'currency')?.options.filter(cur => allowedCurrencySymbols.indexOf(cur.value) > -1);
    }, [allowedAccounts, formValues.backend, formFields],
  );
  useEffect(() => onCurrencyChange(allowedCurrencies?.[0]), [allowedCurrencies, onCurrencyChange]);

  const allowedGroups = useMemo(
    () => {
      const allowedGroupNames = allowedAccounts.filter(
        account => account.trading_backend === formValues.backend?.backend.name
          && account.currency === formValues.currency?.value).map(account => account.group);
      return formValues.backend?.groups.filter(group => allowedGroupNames.indexOf(group.name) > -1);
    }, [allowedAccounts, formValues.backend, formValues.currency],
  );
  useEffect(() => onGroupChange(allowedGroups?.[0]), [allowedGroups, onGroupChange]);

  const Form = useMemo(() => FormFactory({
    form: FORM_KEY,
    api: api(),
    includeFields: ['label', 'backend', 'currency', 'group'],
    customFields: [
      {
        id: 'trading_account',
        name: 'trading_account',
        type: 'text',
      },
      {
        id: 'group',
        name: 'group',
        type: 'select',
      },
    ],
  }), []);

  return (
    <Sidepanel
      {...options}
      formFields={formFields}
      footerRender={customFooter(
        onSubmit,
        onClose,
        submitInProgress,
      )}
    >
      <Space size={16} />
      <Form
        formId={FORM_KEY}
        modifiers={formModifiers}
        renderForm={({ backend, currency, label, group }) => (
          <Fragment>
            <Field
              clearable={false}
              label={text.SERVICE}
              options={allowedBackends}
              onChange={onBackendChange}
            >
              {backend}
            </Field>
            <Space size={12} />
            <Field
              clearable={false}
              label={text.CURRENCY}
              options={allowedCurrencies}
              onChange={onCurrencyChange}
            >
              {currency}
            </Field>
            <Space size={12} />
            <Field
              clearable
              label={text.ACCOUNT_TYPE}
              options={labels}
              placeholder={text.ACCOUNT_TYPE}
              onChange={onLabelChange}
            >
              {label}
            </Field>
            <Space size={12} />
            <Field
              clearable={false}
              label={text.CHOOSE_GROUP}
              placeholder={text.CHOOSE_GROUP}
              labelKey="name"
              valueKey="id"
              options={allowedGroups}
              onChange={onGroupChange}
            >
              {group}
            </Field>
          </Fragment>
        )}
      />
      <Space size={16} />
    </Sidepanel>
  );
};

TradingAccountAvaForm.propTypes = propTypes;
TradingAccountAvaForm.defaultProps = defaultProps;

export default TradingAccountAvaForm;
