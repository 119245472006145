import React, { Component, Fragment } from 'react';
import { array, func, object } from 'prop-types';
import { Button, Card, Col, Input, notifier, Row, Select, Space } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../logic/services/acl';
import to from '../../../../../logic/utilities/to';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import FormFactory, { FormActionsFactory } from '../../../../../components/form';
import DepositsMatchingMethod from './DepositsMatchingMethod';
import WithdrawalsMatchingMethod from './WithdrawalsMatchingMethod';
import CreditCardDetails from '../CreditCardDetails';

import { GatewayFactory } from './logic/gateway';
import { Field } from '../../../../../components/form/logic/utils';
import { Conversion } from './Conversion';

import {
  formatRequestData,
  getAcquiererOptions,
  getGatewayOption,
  getPaymentGatewayMethodUrlId,
  isCreditCardSelected,
  notifyError,
  notifySuccess,
} from './logic/utils';
import TogglePanel from '../../../../../components/TogglePanel';

const propTypes = {
  withdrawal: object.isRequired,
  actions: object.isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  fields: array.isRequired,
  onExecuteSuccess: func.isRequired,
  payoutRefundTable: array.isRequired,
};

const text = {
  EXECUTE_WITHDRAWAL: gettext('Execute withdrawal'),
  PANEL_TITLE_PREFERRED_METHOD: gettext('Preferred withdrawal method'),
  DEPOSITS_MATCHING_METHOD: gettext('Deposits matching method'),
  WITHDRAWALS_MATCHING_METHOD: gettext('Deposit refund/payout'),
  EXECUTE: gettext('Execute'),
  SUCCESS: gettext('Pending withdrawal successfully executed.'),
  ERROR: gettext('Something went wrong.'),
  GATEWAY_ID: gettext('Gateway ID'),
  WITHDRAWAL_ID: gettext('Withdrawal ID'),
  PAYMENT_GATEWAY: gettext('Payment gateway'),
  ACQUIRER: gettext('Acquirer'),
  ACQUIRER_ID: gettext('Acquirer ID'),
  WITHDRAWAL_GATEWAY_ID: gettext('Withdrawal request external ID'),
  ALL_STEPS_APPROVED_WARNING: gettext('Withdrawal cannot be executed until all steps are approved'),
  AUTO_WITHDRAWAL_FLOW_TITLE: gettext('Automatic withdrawal flow'),
  CONVERTED_AMOUNT_OUT_OF_RANGE: gettext('Entered amount is outside of the interval defined by maximum allowed exchange rate variation (+/- 3%)'),
};

const tableCardStyle = {
  content: { padding: '20px 20px 40px 20px' },
};

const FORM_WIDTH = '30%';
const TABLE_WIDTH = '70%';

const FORM_ID = 'EXECUTE_WITHDRAWAL';

const formConfig = api => ({
  form: FORM_ID,
  api,
  excludeFields: ['referral_transactions'],
});

const { create, setFieldValue, setRowData } = FormActionsFactory(FORM_ID);

const formModifiers = ({ is_automatic_withdrawal_allowed } = {}) => ({
  external_id: {
    label: text.GATEWAY_ID,
  },
  transaction_external_id: {
    label: text.GATEWAY_ID,
    placeholder: text.GATEWAY_ID,
  },
  transaction_acquirer_external_id: {
    label: text.ACQUIRER_ID,
    visible: is_automatic_withdrawal_allowed,
  },
});

class ExecuteWithdrawal extends Component {
  constructor(props) {
    super(props);

    const id = _.get(props, 'withdrawal.id');
    this.api = getDjangoApi('pending_withdrawals').one(id).all('execute');
    this.gatewayApi = getDjangoApi('payment_gateways');

    this.state = {
      isConversionRequired: false,
      submitInProgress: false,
      acquirerOptions: [],
      referralTransactions: [],
      conversionData: {
        exchangeRate: null,
        originalExchangeRate: null,
        convertedAmount: 0,
        convertCurrency: null,
        isConvertedAmountValid: true,
        showError: false,
      },
      gatewayOptions: [],
      paymentGateway: GatewayFactory(),
      executeInstructionsData: [],
      prefData: {},
      isAutomaticWithdrawal: props.withdrawal.is_automatic_withdrawal_allowed,
      gateway: {},
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    const accountId = _.get(this.props, 'withdrawal.payment_gateway_account.id');

    if (!accountId) {
      return null;
    }

    const gatewayMethod = _.get(this.props, 'withdrawal.method');
    const method = getPaymentGatewayMethodUrlId(gatewayMethod);
    const { data } = await getDjangoApi(`payment_gateway_accounts/${method}`).retrieve(accountId);

    this.setState({
      prefData: data,
    });
  }

  componentDidUpdate(prevProps) {
    const { fields, actions, withdrawal } = this.props;

    const gatewayOptions = this.getFieldOptions('gateway');
    const gateway = _.find(gatewayOptions, { display_name: withdrawal.gateway });

    this.onChangePaymentMethod(prevProps);

    if (prevProps.fields.length === 0 && fields.length > 0) {
      const methodOptions = this.getFieldOptions('method');

      const predefinedFields = [
        { id: 'amount', value: Number(withdrawal.amount) },
        { id: 'method', value: _.find(methodOptions, { display_name: withdrawal.method }) },
        { id: 'gateway', value: gateway },
      ];

      if (gateway) this.onChangeGateway(gateway);
      predefinedFields.forEach(actions.setFieldValue);
    }
  }

  onChangePaymentMethod = (prevProps) => {
    const prevMethod = _.get(prevProps, 'values.method.value');
    const method = _.get(this.props, 'values.method.value');

    if (prevMethod !== method) {
      this.updateGatewayOptions(method);
    }
  }

  onConvertedAmountChange = (amount) => {
    const { actions } = this.props;
    actions.setFieldValue({ id: 'amount', value: amount });
  }

  async onSubmit() {
    const { actions } = this.props;
    const { conversionData } = this.state;
    const { isConvertedAmountValid } = conversionData;
    if (isConvertedAmountValid) {
      this.setState({ submitInProgress: true });
      const [err, res] = await to(actions.create(this.api, this.getRequestPayload));

      err ? this.onError(err) : this.onSuccess(res.value.response);
      this.setState({ conversionData: { ...conversionData, showError: false } });
    } else {
      this.setState({ conversionData: { ...conversionData, showError: true } });
    }
    this.setState({ submitInProgress: false });
  }

  onSuccess(data) {
    const { onExecuteSuccess } = this.props;
    setTimeout(onExecuteSuccess, 3000);

    notifySuccess(data, notifier);
  }

  onError(error) {
    notifyError(error, notifier);
  }

  getRequestPayload = (payload) => {
    const { isConversionRequired, conversionData, paymentGateway, isAutomaticWithdrawal } = this.state;
    const { payoutRefundTable, withdrawal } = this.props;

    const visibilityParams = this.getVisibilityParams();
    const visibleFields = paymentGateway.getVisibleFields({ fields: payload, visibilityParams });
    const withoutExecuteInstructions = this.isVisible('auto_withdrawal_flow') && !isAutomaticWithdrawal;

    const requestData = {
      isConversionRequired,
      conversionData,
      withdrawal,
      executeInstructionsData: withoutExecuteInstructions ? [] : payoutRefundTable,
      payload: visibleFields,
    };

    return formatRequestData(requestData);
  };

  onDepositSelected = (transactions) => {
    this.setState({ referralTransactions: transactions });
  }

  onChangeGateway = (gatewayField) => {
    this.setPaymentGateway(gatewayField);
    this.updateAcquirerOptions(gatewayField.value);
    this.gatewayApi.retrieve(gatewayField.value).then(({ data }) => {
      this.setState({ gateway: data });
    });
  }

  setPaymentGateway = (gateway) => {
    const paymentGateway = GatewayFactory(gateway.display_name);
    this.setState({
      paymentGateway,
    }, () => {
      const { actions } = this.props;
      actions.setFieldValue({
        id: 'gateway',
        value: gateway,
      });
    });
  }

  setPaymentAcquirer = (acquirer) => {
    const { actions } = this.props;
    actions.setFieldValue({
      id: 'acquirer',
      value: acquirer,
    });
  }

  getFieldOptions(field) {
    const { fields } = this.props;
    const gatewayField = _.find(fields, { id: field });
    return gatewayField && gatewayField.options;
  }

  getVisibilityParams() {
    const { referralTransactions, isAutomaticWithdrawal } = this.state;
    const { withdrawal } = this.props;
    const { is_automatic_withdrawal_allowed } = withdrawal;

    return { referralTransactions, is_automatic_withdrawal_allowed, isCreditCardSelected: this.isCreditCardSelected(), isAutomaticWithdrawal };
  }

  getError = (key) => {
    const { errors } = this.props;
    const hasError = !!errors[key];
    const helpText = hasError ? errors[key][0] : null;

    return [hasError, helpText];
  }

  updateGatewayOptions = (paymentMethodId) => {
    const { fields } = this.props;

    this.setState({
      gatewayOptions: getGatewayOption({ fields, paymentMethodId }),
    });
  }

  updateAcquirerOptions = (gatewayId) => {
    const { fields } = this.props;

    this.setPaymentAcquirer(undefined);
    this.setState({
      acquirerOptions: getAcquiererOptions({ fields, gatewayId }),
    });
  }

  handleConversionDataChange = (data) => {
    this.setState(prevState => ({
      ...prevState,
      conversionData: {
        ...prevState.conversionData,
        ...data,
      },
    }));
  }

  handleConversionToggle = () => {
    this.setState(({ isConversionRequired }) => ({
      isConversionRequired: !isConversionRequired,
    }));
  }

  handleAutoWithdrawalFlowSwitch = () => {
    const { isAutomaticWithdrawal } = this.state;
    this.setState({
      isAutomaticWithdrawal: !isAutomaticWithdrawal,
    });
  }

  isCreditCardSelected() {
    return isCreditCardSelected(this.props);
  }

  isMatchingMethodSelected() {
    const { referralTransactions } = this.state;
    return referralTransactions.length;
  }

  isConversionEnabled() {
    const { gateway } = this.state;
    return gateway.debit_currency_conversion;
  }

  isVisible(field) {
    const { paymentGateway } = this.state;
    return paymentGateway.isVisible({ field, visibilityParams: this.getVisibilityParams() });
  }

  canExecute() {
    const { paymentGateway } = this.state;
    return paymentGateway.canExecute(this.getVisibilityParams());
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig(this.api));
    }

    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const Form = this.renderForm();
    const { values, withdrawal } = this.props;

    const {
      isConversionRequired,
      submitInProgress,
      conversionData,
      gatewayOptions,
      acquirerOptions,
      referralTransactions,
      prefData,
      paymentGateway,
      executeInstructionsData,
      isAutomaticWithdrawal,
    } = this.state;
    const {
      amount,
      gateway,
      method,
    } = values;

    const disabled = !this.canExecute() || submitInProgress;
    const [gatewayHasError, gatewayHelpText] = this.getError('gateway');

    return (
      <Row gutter={0}>
        <Col xs={FORM_WIDTH} sm={FORM_WIDTH} md={FORM_WIDTH} lg={FORM_WIDTH}>
          <Card title={text.EXECUTE_WITHDRAWAL} style={{ content: { padding: 10 } }}>
            <Form
              modifiers={formModifiers()}
              renderForm={formFields => (
                <Fragment>
                  <Field clearable={false}>{formFields.method}</Field>
                  {formFields.amount}
                  {this.isConversionEnabled() && (
                    <Fragment>
                      <Conversion
                        checked={isConversionRequired}
                        onToggle={this.handleConversionToggle}
                        amount={amount}
                        withdrawal={withdrawal}
                        conversionData={conversionData}
                        onConversionDataChange={this.handleConversionDataChange}
                        error={conversionData.showError && text.CONVERTED_AMOUNT_OUT_OF_RANGE}
                      />
                    </Fragment>
                  )}
                  <Fragment>
                    <Input
                      label={text.WITHDRAWAL_GATEWAY_ID}
                      disabled
                      value={withdrawal.external_id}
                      valueKey="external_id"
                    />
                    <Space size={12} />
                  </Fragment>
                  <Fragment>
                    <Select
                      label={text.PAYMENT_GATEWAY}
                      onChange={this.onChangeGateway}
                      value={gateway}
                      options={gatewayOptions}
                      hasError={gatewayHasError}
                      helpText={gatewayHelpText}
                      valueKey="value"
                      labelKey="display_name"
                    />
                    <Space size={12} />
                  </Fragment>
                  <Space size={12} />
                  {this.isVisible('acquirer') && (
                    <Field
                      label={text.ACQUIRER}
                      options={acquirerOptions}
                      onChange={this.setPaymentAcquirer}
                    >
                      {formFields.acquirer}
                    </Field>
                  )}
                  {this.isVisible('transaction_external_id') && formFields.transaction_external_id}
                  {this.isVisible('transaction_acquirer_external_id') && formFields.transaction_acquirer_external_id}
                  {this.isVisible('auto_withdrawal_flow') && (
                    <TogglePanel
                      title={text.AUTO_WITHDRAWAL_FLOW_TITLE}
                      checked={isAutomaticWithdrawal}
                      onToggle={this.handleAutoWithdrawalFlowSwitch}
                    />
                  )}
                </Fragment>
              )}
            />

            <div className="biq-pending-withdrawal__execute-button">
              {hasAccess('withdrawals.requests.execute.*') && (
                <Button disabled={disabled} loading={submitInProgress} onClick={this.onSubmit}>
                  {text.EXECUTE}
                </Button>
              )}
            </div>
          </Card>
          <div>
            <Space size={20} />
            <CreditCardDetails cardInfo={prefData} withdrawal={withdrawal} method={method && method.display_name} />
          </div>
        </Col>

        <Col xs={TABLE_WIDTH} sm={TABLE_WIDTH} md={TABLE_WIDTH} lg={TABLE_WIDTH}>
          <Card title={text.DEPOSITS_MATCHING_METHOD} style={tableCardStyle}>
            <DepositsMatchingMethod
              withdrawal={withdrawal}
              onDepositSelected={this.onDepositSelected}
              gateway={paymentGateway}
              isDisabled={this.isVisible('auto_withdrawal_flow') && !isAutomaticWithdrawal}
            />
          </Card>

          <Space size={20} />

          <Row gutter={0}>
            <Card title={text.WITHDRAWALS_MATCHING_METHOD} style={tableCardStyle}>
              <WithdrawalsMatchingMethod
                referralTransactions={referralTransactions}
                withdrawal={withdrawal}
                gateway={paymentGateway}
                table={executeInstructionsData}
              />
            </Card>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ forms, pages }) => {
  const page = pages.PENDING_WITHDRAWAL;
  const table = page.tables.WITHDRAWALS_MATCHING_METHOD;

  return {
    ...forms.EXECUTE_WITHDRAWAL,
    payoutRefundTable: table,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setFieldValue,
      create,
      setRowData,
    },
    dispatch,
  ),
  dispatch,
});

ExecuteWithdrawal.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(ExecuteWithdrawal);
