import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  ASSIGN_USER_TO_IB: 'ASSIGN_USER_TO_IB',
  ASSIGN_USER_TO_IB_FULFILLED: 'ASSIGN_USER_TO_IB_FULFILLED',
  ASSIGN_USER_TO_IB_PENDING: 'ASSIGN_USER_TO_IB_PENDING',
  ASSIGN_USER_TO_IB_REJECTED: 'ASSIGN_USER_TO_IB_REJECTED',
};

export const getAssignToAPi = (user, isIb) => {
  const assignToIbUrl = `${isIb ? 'ib' : 'users'}/${user.id}/assign_to_ib`;
  return getDjangoApi(assignToIbUrl);
};

export const assignUserToIb = createAction(actionTypes.ASSIGN_USER_TO_IB, async (user, isIb, link) => {
  const api = getAssignToAPi(user, isIb);

  await api.create({
    link: link.hash,
    user: user.id,
  });
});


const initialState = {
  submitInProgress: false,
  errors: null,
  fetchInProgress: false,
  fetchError: null,
};

export default handleActions(
  {
    [actionTypes.ASSIGN_USER_TO_IB_PENDING]: state => ({ ...state, submitInProgress: true }),
    [actionTypes.ASSIGN_USER_TO_IB_FULFILLED]: state => ({ ...state, submitInProgress: false, error: null }),
    [actionTypes.ASSIGN_USER_TO_IB_REJECTED]: (state, { payload }) => ({ ...state, submitInProgress: false, error: payload }),
  },
  initialState,
);
