import session from './session';
import { getToken } from './cookies';
import isFeatureEnabled from '../filters/is-feature-enabled';
import ACCESS_CODE from '../enums/access-codes';

const alwaysAllowed = ['403', '404'];

export default {
  hasStateAccess,
  hasAccess,
  getAccessForState,
  generateEndpointAccessData,
  areAllChecked,
  extractAclArray,
  extractPolarityAclArrays,
};

// @deprecated
export function hasStateAccess(state) {
  const aclFnResult = typeof _.get(state, 'aclFn') === 'function' && state.aclFn(hasAccess, isFeatureEnabled());
  const allowSuperusers = !!(_.get(session.user, 'is_superuser') && _.get(state, 'allowSuperusers'));
  return !!state
    && (alwaysAllowed.indexOf(state.aclId) >= 0 || allowSuperusers || hasAccess(state.aclId) || aclFnResult);
}

export function hasPageAccess(page, session) {
  const aclFnResult = typeof _.get(page, 'aclFn') === 'function' && page.aclFn();
  const allowSuperusers = !!(_.get(session.user, 'is_superuser') && _.get(page, 'allowSuperusers'));
  return !!page
    && (alwaysAllowed.indexOf(page.aclId) >= 0 || allowSuperusers || hasAccess(page.aclId) || aclFnResult);
}

// temporary hack for wildcards
function accessTests(pageId) {
  if (!pageId) return false;
  if (pageId === '*') return true;

  const tests = [pageId];
  const idParts = pageId.split('.');
  if (idParts[idParts.length - 1] !== '*') {
    idParts[idParts.length - 1] = '*';
    tests.push(idParts.join('.'));
  }

  for (let i = 0; i < tests.length; i++) {
    if (session.acl.indexOf(tests[i]) >= 0) {
      return true;
    }
  }
  return false;
}

export function hasAccess(pageId) {
  return pageId === '*' || session.acl.indexOf(pageId) >= 0 || accessTests(pageId);
}

export function getAccessForState(state) {
  if (state.anonymousAccess) {
    return ACCESS_CODE.ALLOWED;
  } if (!getToken()) {
    return ACCESS_CODE.UNAUTHORIZED;
  } if (!session.user) {
    return ACCESS_CODE.SESSION_REQUIRED;
  } if (hasPageAccess(state)) {
    return ACCESS_CODE.ALLOWED;
  } 
  return ACCESS_CODE.FORBIDDEN;
}

export function getAccessForPage(page, session, match) {
  if (page.anonymousAccess) {
    return ACCESS_CODE.ALLOWED;
  } 
  if (!getToken()) {
    return ACCESS_CODE.UNAUTHORIZED;
  } 
  if (!session.user) {
    return ACCESS_CODE.SESSION_REQUIRED;
  } 
  if (hasPageAccess(page, session, match)) {
    return ACCESS_CODE.ALLOWED;
  } 
  return ACCESS_CODE.FORBIDDEN;
}

export function generateEndpointAccessData(options, accessAclStrings, positiveAclStrings, negativeAclStrings) {
  let aclStrings = accessAclStrings;
  if (positiveAclStrings && positiveAclStrings.length) {
    aclStrings = _.uniq(aclStrings.concat(positiveAclStrings));
  }
  if (negativeAclStrings && negativeAclStrings.length) {
    aclStrings = _.uniq(aclStrings.concat(negativeAclStrings));
  }

  const ep = _.cloneDeep(session.endpoints);

  ep.forEach((point) => {
    point.actions.forEach((action) => {
      const aclDefined = aclStrings.indexOf(action.fullName) >= 0;

      if (aclDefined) {
        const hasTeamAccess = accessAclStrings.indexOf(action.fullName) >= 0;
        action.originalAccess = hasTeamAccess;
        action.access = hasTeamAccess;
        if (hasTeamAccess) {
          action.access = !(negativeAclStrings && negativeAclStrings.indexOf(action.fullName) >= 0);
        } else {
          action.access = positiveAclStrings && positiveAclStrings.indexOf(action.fullName) >= 0;
        }
      } else {
        action.originalAccess = false;
        action.access = false;
      }

      // fill description fields
      const aclOption = options && _.find(options.choices, { value: action.fullName });
      if (aclOption) {
        action.description = aclOption.display_name;
      }
    });
  });

  return ep;
}


export function areAllChecked(actions) {
  if (actions.length === 1) return actions[0].access;
  let areAllChecked = true;
  for (const index in actions) {//eslint-disable-line
    const action = actions[index];
    if (action.name !== '*' && !action.access) {
      areAllChecked = false;
      break;
    }
  }
  return areAllChecked;
}

export function extractAclArray(endpoints) {
  const aclArray = [];
  endpoints.forEach((endpoint) => {
    endpoint.actions.forEach((action) => {
      if (action.name === '*') {
        action.access = areAllChecked(endpoint.actions);
      }

      if (action.access) {
        aclArray.push(action.fullName);
      }
    });
  });
  return aclArray;
}

export function extractPolarityAclArrays(endpoints) {
  const polarity = {
    positive_acls: [],
    negative_acls: [],
  };
  endpoints.forEach((endpoint) => {
    endpoint.actions.forEach((action) => {
      if (action.name === '*') {
        action.access = areAllChecked(endpoint.actions);
      }

      if (action.access !== action.originalAccess && (action.name !== '*' || endpoint.actions.length === 1)) {
        if (action.access) {
          polarity.positive_acls.push(action.fullName);
        } else {
          polarity.negative_acls.push(action.fullName);
        }
      }
    });
  });

  polarity.positive_acls = _.uniq(polarity.positive_acls);
  polarity.negative_acls = _.uniq(polarity.negative_acls);
  return polarity;
}

export const parseAcl = (acl) => {
  const arr = acl.split('.');
  const category = arr.shift();
  return {
    category,
    action: arr.pop(),
    name: [category, ...arr].join('.'),
  };
};

export const cleanAcls = (allAcls, acls = []) => {
  const aclTest = allAcls.reduce((acc, acl) => {
    const { name } = parseAcl(acl);
    return {
      ...acc,
      [name]: acc[name] ? acc[name] + 1 : 1,
    };
  }, {});

  return acls.filter((acl) => {
    const { name, action } = parseAcl(acl);
    return action !== '*' || (action === '*' && aclTest[name] === 1);
  });
};
