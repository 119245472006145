import React from 'react';
import { Icon, Tooltip, Popconfirm } from 'tc-biq-design-system';

import { UsernameCell } from '../../../../components/gridCellRenderers';
import { gettext } from '../../../../logic/utilities/languageUtility';
import ActionsCellWrapper from '../../../../components/gridCellRenderers/SingleActionCell';

const text = {
  POP_CANCEL: gettext('Cancel'),
  POP_CONFIRM: gettext('Delete'),
  POP_LABEL: gettext('You are about to delete an unsuitable object'),
  TOOLTIP: gettext('Delete'),
};

const actionIcon = {
  deleteRow: (actionHandler, data) => (
    <Tooltip title={text.TOOLTIP} placement="top">
      <Popconfirm
        key="delete"
        type="destructive"
        placement="bottom"
        icon="Delete"
        label={text.POP_LABEL}
        onConfirm={() => actionHandler(data)}
        buttonLabels={{ cancel: text.POP_CANCEL, confirm: text.POP_CONFIRM }}
      >
        <Icon name="Delete" />
      </Popconfirm>
    </Tooltip>
  ),
};

const modifier = ({ deleteRow, previewUser }) => ({
  user: {
    cellRendererFramework: UsernameCell(previewUser),
    width: 200,
  },
  actions: {
    label: '',
    pinned: 'right',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper({ deleteRow }, actionIcon),
    width: 120,
  },
});

export default modifier;
