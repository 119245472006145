import React from 'react';

import AppRouter from './Router/AppRouter';
import MainLayout from '../MainLayout';
import RequestLoader from '../RequestLoader';

import 'tc-biq-design-system/build/index.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '../../assets/sass/style.scss';

const App = () => (
  <MainLayout>
    <RequestLoader />
    <AppRouter />
  </MainLayout>
);

export default App;
