import { combineReducers } from 'redux';

import GridReducerFactory from '../../../../../../components/grid/GridReducerFactory';
import form from './IntegrationForm/Model';

const rootReducer = combineReducers({
  form,
  tables: combineReducers({
    INTEGRATION_AVA_TABLE: GridReducerFactory('INTEGRATION_AVA_TABLE'),
    INTEGRATION_MAILCHIMP_TABLE: GridReducerFactory('INTEGRATION_MAILCHIMP_TABLE'),
  }),

});

export default rootReducer;
