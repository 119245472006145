import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  PUSH_VERIFICATION: 'PUSH_VERIFICATION',
  PUSH_VERIFICATION_FULFILLED: 'PUSH_VERIFICATION_FULFILLED',
  PUSH_VERIFICATION_PENDING: 'PUSH_VERIFICATION_PENDING',
  PUSH_VERIFICATION_REJECTED: 'PUSH_VERIFICATION_REJECTED',
};

export const pushVerification = createAction(actionTypes.PUSH_VERIFICATION, async (user) => {
  const pushVerificationApi = getDjangoApi(`users/${user.id}/kyc/push_verification`);
  
  await pushVerificationApi.create({});
});


const initialState = {
  submitInProgress: false,
  errors: null,
};

const pendingHandler = state => ({ ...state, submitInProgress: true });

const fulfilledHandler = state => ({
  ...state,
  submitInProgress: false,
  error: null,
});

const rejectedHandler = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  errors: payload.data,
});

export default handleActions(
  {
    [actionTypes.PUSH_VERIFICATION_PENDING]: pendingHandler,
    [actionTypes.PUSH_VERIFICATION_FULFILLED]: fulfilledHandler,
    [actionTypes.PUSH_VERIFICATION_REJECTED]: rejectedHandler,
  },
  initialState,
);
