import { bindActionCreators } from 'redux';
import TeamAcls from './TeamAcls';
import connect from '../../../../../logic/connect';
import { loadTeamOptions } from '../Model';

const mapStateToProps = ({ pages }) => ({ ...pages.TEAM.team });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    loadTeamOptions,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamAcls);
