import React from 'react';
import { string } from 'prop-types';

import { dateTimeFormatter } from '../../logic/utilities/formatters';

const propTypes = {
  value: string,
};

const defaultProps = {
  value: null,
};

const DateTimeCell = ({ value }) => (
  <span>
    {dateTimeFormatter(value)}
  </span>
);

DateTimeCell.propTypes = propTypes;
DateTimeCell.defaultProps = defaultProps;
export default DateTimeCell;
