import React from 'react';
import { Icon, Tooltip, Popconfirm } from 'tc-biq-design-system';

import ActionsCellWrapper from '../../../../components/gridCellRenderers/SingleActionCell';
import { UsernameCell } from '../../../../components/gridCellRenderers';
import { gettext } from '../../../../logic/utilities/languageUtility';

const text = {
  DELETE: gettext('Delete'),
  CANCEL: gettext('Cancel'),
  EDIT: gettext('Edit'),
  DELETE_LABEL: gettext('Are you sure you want to delete this item?'),
};

export const actionIcons = {
  edit: (actionHandler, data) => (
    <div key="Edit" onClick={() => actionHandler(data)}>
      <Tooltip title={text.EDIT} placement="top">
        <Icon colorName="text-status01-700" name="Edit" />
      </Tooltip>
    </div>
  ),
  delete: (actionHandler, data) => (
    <div key="Delete">
      <Tooltip title={text.DELETE} placement="top">
        <Popconfirm
          type="destructive"
          icon="Delete"
          onConfirm={() => actionHandler(data)}
          label={text.DELETE_LABEL}
          buttonLabels={{ cancel: text.CANCEL, confirm: text.DELETE }}
        >
          <Icon colorName="text-status04-700" name="Delete" />
        </Popconfirm>
      </Tooltip>
    </div>
  ),
};

const modifier = (actions, previewBiqUser) => ({
  added_by: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper(actions, actionIcons),
    pinned: 'right',
    width: 120,
  },
});

export default modifier;
