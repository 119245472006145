import React, { Component } from 'react';

import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';
import { getUrlParameter } from '../../../logic/utilities/state-param';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.token = getUrlParameter('token');
    this.Form = this.token ? ResetPasswordForm : ForgotPasswordForm;
  }

  render() {
    return <this.Form {...this.props} token={this.token} />;
  }
}

export default ResetPassword;
