import React, { Component } from 'react';
import { bool, object, func, string } from 'prop-types';
import { Select, notifier } from 'tc-biq-design-system';

import { gettext } from '../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../logic/services/api-factory';
import isFeatureEnabled from '../../logic/filters/is-feature-enabled';
import session from '../../logic/services/session';
import to from '../../logic/utilities/to';

const ENV = window.config.environment;

const translate = {
  GENERAL_ERROR: gettext('Something went wrong'),
};

const propTypes = {
  user: object.isRequired,
  onSuccess: func,
  text: object.isRequired,
  managerProperty: string,
  isIb: bool,
};

const defaultProps = {
  managerProperty: 'assigned_to',
  isIb: false,
  onSuccess: null,
};

class AssignManager extends Component {
  constructor(props) {
    super(props);
    this.apiUsers = getDjangoApi('team');
    this.apiAssign = (managerId, isIb) => getDjangoApi(`team/profile/${managerId}/${isIb ? 'ibs' : 'clients'}`);
    this.submit = (api, data, destroyId) => (destroyId ? api.destroy(destroyId) : api.create(data));
    this.assignRetentionEnabled = isFeatureEnabled()('ASSIGN_RETENTION');
    this.loadOptions = _.debounce(this.loadOptions.bind(this), 500, { leading: true });

    this.state = {
      options: [],
      selected: null,
    };
  }

  componentDidMount() {
    this.loadOptions().then(() => this.setAssignedManager());
  }

  componentDidUpdate(prevProps) {
    const { user, managerProperty } = this.props;
    const { user: prevUser } = prevProps;
    if (user && prevUser && user[managerProperty] !== prevUser[managerProperty]) {
      this.setAssignedManager && this.setAssignedManager();
    }
  }

  onSubmit = async (newManager) => {
    const { user, managerProperty, isIb } = this.props;
    const oldManager = user[managerProperty];
    const isNoneSelected = !newManager || newManager.id === 'none';

    if (isNoneSelected && !oldManager) return;

    const action = isNoneSelected ? 'unAssignSales' : 'assignSales';
    const manager = isNoneSelected ? oldManager : newManager;

    const params = this.getApiParams(action)(user, manager, isIb);

    this.setState({
      selected: newManager,
    });

    const [err] = await to(this.submit(...params));

    err ? this.onError(err) : this.onSuccess(newManager);
  }

  onSuccess = (manager) => {
    const { text, onSuccess, managerProperty } = this.props;
    onSuccess && onSuccess(manager, managerProperty);
    notifier.success(text.SUCCESS_MESSAGE);
  }

  onError = ({ data }) => {
    const { non_field_errors, messages } = data;
    if (non_field_errors) {
      notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
    } else if (messages) {
      notifier.error(data.messages.map(err => <span>{err.text}</span>));
    } else {
      notifier.error(translate.GENERAL_ERROR);
    }
  }

  getApiParams = (action) => {
    const { managerProperty } = this.props;

    return (user, manager, isIb) => {
      const apis = () => {
        if (this.assignRetentionEnabled) {
          if (managerProperty === 'retention_manager') {
            return {
              assignSales: [getDjangoApi('team/assign'), this.formatRequest(user, manager)],
              unAssignSales: [getDjangoApi('team/unassign'), this.formatRequest(user)],
            };
          }

          return {
            assignSales: [getDjangoApi('env/team/assign_sales'), this.formatRequest(user, manager)],
            unAssignSales: [getDjangoApi('env/team/unassign_sales'), this.formatRequest(user)],
          };
        }

        return {
          assignSales: [this.apiAssign(manager.id, isIb), this.formatRequest(user)],
          unAssignSales: [this.apiAssign(manager.id, isIb), null, user.assignment_relation_id],
        };
      };

      return apis()[action];
    };
  }

  setAssignedManager = () => {
    const { user, managerProperty } = this.props;
    let selected;
    if (user[managerProperty]) {
      const format = user => (user && {
        ...user[managerProperty],
        label: user[managerProperty].username,
      });
      selected = format(user);
    } else {
      const { options } = this.state;
      const [firstOption] = options;
      selected = firstOption;
    }

    this.setState({
      selected,
    });
  }

  formatRequest = (user, manager) => {
    if (ENV === 'AvaTrade') {
      return {
        user: user.id,
        ...(manager && { team_profile: manager.id }),
      };
    }
    return {
      re_assigned: true,
      user: user.id,
    };
  };

  formatOptions = (response, text) => {
    const users = response ? response.data.results.map(user => ({
      label: user.username,
      id: user.id,
      group: text.GROUP_USERS,
    })) : [];
  
    
    return [
      { id: 'none', label: text.NONE, group: text.GROUP_DEFAULT },
      { id: session.user.id, label: session.user.username, group: text.GROUP_DEFAULT },
      ...users,
    ];
  }

  loadOptions = (input = '') => {
    const { text } = this.props;
    const userParams = {
      type: 'live',
      limit: 10,
      offset: 0,
    };
    
    const usersPromise = this.apiUsers.list({ ...userParams, username: input });

    return usersPromise.then((res) => {
      this.setState({
        options: this.formatOptions(res, text),
      });
    });
  }

  render() {
    const { text } = this.props;
    const { options, selected } = this.state;

    return (
      <div title={(selected && selected.label) || ''}>
        <Select
          smallTitle
          group
          type="search"
          label={text.SALES_MANGER}
          value={selected}
          options={options}
          onInputChange={this.loadOptions}
          onChange={this.onSubmit}
          clearable={!_.isEmpty(selected)}
        />
      </div>
    );
  }
}

AssignManager.propTypes = propTypes;
AssignManager.defaultProps = defaultProps;

export default AssignManager;
