import React, { Component } from 'react';
import { Button, MultiActions, notifier } from 'tc-biq-design-system';
import { object } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { bindActionCreators } from 'redux';
import { stepTypes, getStepType } from './CommunicationFlow';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import actionTypes from '../types';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';

import CommunicationFlow from './CommunicationFlowAction';
import connect from '../../../../../../../logic/connect';
import { hasAccess } from '../../../../../../../logic/services/acl';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import { setActiveStep, initWizard, fetchCommunicationWizard } from './Model';
import { resetTaskForm } from './CreateTask';
import { resetNotesForm } from './SendNote';

import './CommunicationFlowAction.scss';

const getActions = (action, item = {}, user, onSelect) => [
  hasAccess('user.call.*') && isFeatureEnabled()('TELEPHONE_CALL_LINK') && {
    label: gettext('Call'),
    action: () => {
      getDjangoApi(`users/${user.id}/call`)
        .create({})
        .then(() => {
          notifier.success(gettext('Successfully called'));
        })
        .catch((response) => {
          const errors = _.get(response, 'data.errors');
          return errors 
          && errors.length > 0 
          && notifier.error(errors.map((error, index) => <span key={index}>{error}</span>));
        });
    },
    icon: ACTION_ICON,
    disabled: true,
    iconColorName: 'text-primary-1000',
  },
  !isEmpty(item.answered) && {
    label: gettext('Answered'),
    action: () => { onSelect(item.answered, flow.ANSWERED); action(); },
    icon: ACTION_ICON,
    iconColorName: 'text-status01-400',
  },
  !isEmpty(item.not_answered) && {
    label: gettext('Not-answered'),
    action: () => { onSelect(item.not_answered, flow.NOT_ANSWERED); action(); },
    icon: 'PhoneHangup',
    iconColorName: 'text-status04-400',
  },
].filter(item => !!item);

const text = {
  LABEL: gettext('Communication flow'),
  CHANGED_FLOW: gettext('You have changed communication flow'),
};

const ACTION_ICON = 'Phone';

export default {
  name: 'communicationFlow',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.TOP,
  handler: () => {},
  hasPermission(userState, user, isIb) {
    return hasAccess('user.communication_wizard.list') 
    && isFeatureEnabled()('COMMUNICATION_FLOW')
    && !isIb;
  },
  // eslint-disable-next-line
  content: ({ sidepanelManager, user }) => <WrappedCommunicationFlowAction sidepanelManager={sidepanelManager} user={user} />,
};

const flow = {
  ANSWERED: 'ANSWERED',
  NOT_ANSWERED: 'NOT_ANSWERED',
};

class CommunicationFlowAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFlow: null,
    };
  }

  componentDidMount() {
    const { communicationFlow, user } = this.props;
    const { wizard, fetchInProgress } = communicationFlow;
    const { actions } = this.props;
    if (!fetchInProgress && isEmpty(wizard) && user) {
      actions.fetchCommunicationWizard(user).then((res) => {
        actions.setActiveStep({
          type: stepTypes.START,
          item: res.value,
        });
      });
    }
  }

  onSelect = (item, selectedFlow) => {
    const { activeFlow } = this.state;
    const { actions, communicationFlow: { wizard, finished } } = this.props;

    if (activeFlow !== selectedFlow || finished) {
      actions.resetNotesForm();
      actions.resetTaskForm();
      actions.initWizard();
      actions.setActiveStep({ type: stepTypes.START, item: wizard });  
      
      this.initFlow(item);
      this.setState({ activeFlow: selectedFlow });

      if (activeFlow && selectedFlow) {
        notifier.warning(text.CHANGED_FLOW);
      }
    }
  };

  initFlow = (item) => {
    const { actions } = this.props;
    
    const type = getStepType(item);
    const nextStep = {
      item,
      type,
    };
            
    actions.setActiveStep(nextStep);
  };

  renderHeader = () => <Button size="extra-small" icon="Phone">{gettext('Communicaiton flow')}</Button>
  
  render() {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
    };

    const { communicationFlow, sidepanelManager, user } = this.props;
    const { wizard, fetchInProgress } = communicationFlow;
    const menuActions = getActions(() => { sidepanelManager.add(CommunicationFlow, sidepanelOptions); }, wizard, user, this.onSelect);
  
    return (
      <div className="communication-flow-action">
        { !fetchInProgress && user && !isEmpty(wizard) && (
          <MultiActions
            renderHeader={this.renderHeader}
            actions={menuActions}
          />
        ) }
      </div>
    );
  }
}

CommunicationFlowAction.propTypes = {
  sidepanelManager: object.isRequired,
  communicationFlow: object.isRequired,
  user: object.isRequired, 
  actions: object.isRequired,
};

const mapStateToProps = ({ pages }) => {
  const communicationFlow = pages.USER_SINGLE.CommunicationFlow;

  return {
    communicationFlow,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ setActiveStep, resetNotesForm, resetTaskForm, initWizard, fetchCommunicationWizard }, dispatch),
});

export { resetNotesForm, resetTaskForm };
const WrappedCommunicationFlowAction = connect(mapStateToProps, mapDispatchToProps)(CommunicationFlowAction);
