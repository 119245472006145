/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Button } from 'tc-biq-design-system';

import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import GridFactory from '../../../../../../../../components/grid';
import { getActiveFilters } from '../../../../../../../../components/grid/GridUtils';
import Page from '../../../../../../../../components/Page';
import Filters from '../../../../../../../../components/Filters';
import connect from '../../../../../../../../logic/connect';
import modifier from './modifier';
import { hasAccess } from '../../../../../../../../logic/services/acl';
import { BiqUserPreview } from '../../../../../../../../components/common/sidepanels';
import overlayActions from '../../../../../../../../components/overlay';
import withErrorBoundary from '../../../../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../../../../components/App/Router/appRoutes';

const pageConfig = {
  apiUrl: 'integration/mailchimp/lists',
  reducerKey: 'INTEGRATIONS',
  tableKey: 'INTEGRATION_MAILCHIMP_TABLE',
};

const text = {
  PAGE_LABEL: gettext('Mailchimp'),
  PARENT_PAGE_LABEL: gettext('Integrations'),
  RETURN_BUTTON: gettext('Return'),
};

const api = getDjangoApi(pageConfig.apiUrl);
const { GridComponent, actions } = GridFactory(pageConfig);
const bread = [
  { 
    label: text.PARENT_PAGE_LABEL, route: appRoutes.SETTINGS_INTEGRATIONS,
  }, { 
    label: text.PAGE_LABEL, route: appRoutes.SETTINGS_INTEGRATIONS_MAILCHIMP,
  },
];

class MailchimpIntegration extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.tableActions = {
      ...(hasAccess('integration.mailchimp.lists.destroy') && { delete: this.onDeleteAction.bind(this) }),
    };

    this.headerActions = this.headerActions.bind(this);
    this.onSuccessRequest = this.onSuccessRequest.bind(this);
    this.onReturn = this.onReturn.bind(this);
    this.previewBiqUser = this.actions.overlayOpen.bind(this, 'BIQ_USER_PREVIEW');
  }

  async onDeleteAction({ id }) {
    await api.destroy(id);
    this.onSuccessRequest();
  }

  onSuccessRequest() {
    const { tableQuery } = this.props;
    this.actions.fetchTableData(tableQuery);
  }

  onReturn() {
    const { history } = this.props;

    history.push(appRoutes.SETTINGS_INTEGRATIONS);
  }


  headerActions() {
    return (
      <Button icon="ArrowLeft" color="ghost" onClick={this.onReturn}>
        {text.RETURN_BUTTON}
      </Button>
    );
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions, this.previewBiqUser),
    };

    return (
      <Page headerActions={this.headerActions} bread={bread} title={text.PAGE_LABEL}>
        <Filters {...filterProps} />
        <GridComponent
          singleActions={!!Object.keys(this.tableActions).length}
          tableModifier={modifier(this.tableActions, this.previewBiqUser)}
          {...this.props}
        />
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const table = pages[reducerKey].tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
    tableQuery: table.query,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      addActionsColumn: actions.addActionsColumn,
      fetchTableData: actions.fetchTableData,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      overlayOpen: overlayActions.open,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(MailchimpIntegration); 
