import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../logic/services/acl';
import AssignToIb from './AssignToIb';
import userActionTypes from '../types';

const text = {
  LABEL: gettext('Assign to IB'),
  TITLE: gettext('Assign user to an Introducing Broker'),
};

const ACTION_ICON = 'User';

export default {
  name: 'assignToIb',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    if (isIb) {
      return !user.is_archived && hasAccess('ib.assign_to_ib.*');
    } 
    return hasAccess('user.assign_to_ib.*');
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.TITLE,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(AssignToIb, sidepanelOptions);
  },
};
