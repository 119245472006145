import React, { Component } from 'react';
import { Sidepanel, Space, notifier } from 'tc-biq-design-system';
import { object, bool, func } from 'prop-types';

import { SidepanelFooter } from '../../../../../../components/common';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import FormFactory from '../../../../../../components/form';
import Uploader from '../../../../../../logic/services/uploader';
import to from '../../../../../../logic/utilities/to';
import { formErrorHandler } from '../../../../../../components/form/logic/utils';


export const SIDEPANEL_ID = 'CREATE_TOC_SIDEPANEL';
export const FORM_ID = 'CREATE_TOC_FORM';

const createTosUri = 'customer_policy/tos';
const createTosApi = () => getDjangoApi(createTosUri);
const tosGroupApi = () => getDjangoApi('customer_policy/tos_groups');

const text = {
  CREATE: gettext('Add new Terms and Conditions'),
  SUCCESS: gettext('Terms and conditions document successfully created'),
  ERROR_GENERAL: gettext('Something went wrong!'),
  DOCUMENT_TYPE: gettext('Document type'),
  BUTTON_LABELS: {
    confirm: gettext('Create'),
    cancel: gettext('Cancel'),
  },
  RELATED_TO: gettext('Related to'),
};

const formModifiers = {
  group: {
    label: text.DOCUMENT_TYPE,
    creatable: true,
  },
  type: {
    label: text.RELATED_TO,
    placeholder: text.RELATED_TO,
  },
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_ID}
  />
);

const propTypes = {
  sidepanel: object.isRequired,
  submitInProgress: bool.isRequired,
  actions: object.isRequired,
  onSuccess: func.isRequired,
  values: object.isRequired,
};

class CreateTocSidepanel extends Component {
  constructor(props) {
    super(props);
    this.uploaderApi = new Uploader(createTosUri);
  }

  onSubmitGroup = async () => {
    const { values, actions } = this.props;

    const [err, res] = await to(tosGroupApi().create({ name: values.group.label }));
    err ? this.onError() : actions.setFieldValue({
      id: 'group',
      value: res.data,
    });
    return res;
  }

  onSubmit = async () => {
    const { actions, values } = this.props;
    if (this.shouldCreateGroup(values)) await this.onSubmitGroup();
    const [err] = await to(actions.create(this.uploaderApi, this.getRequestPayload));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    const { onSuccess } = this.props;
    notifier.success(text.SUCCESS);
    this.onClose();
    onSuccess && onSuccess();
  }

  onError(err) {
    formErrorHandler(text.ERROR_GENERAL)(err);
  }

  onClose = () => {
    const { actions } = this.props;
    actions.closeSidepanel(SIDEPANEL_ID);
  }

  getRequestPayload = (values) => {
    const data = {
      description: values.description,
      business_group: _.get(values.businessGroup, 'name'),
      language: _.get(values.language, 'value'),
      link: values.link,
      type: _.get(values.type, 'value'),
      group: _.get(values.group, 'id'),
      whitelabel: _.get(values.whitelabel, 'name'),
      file: values.file,
    };

    Object.keys(data).forEach((key) => {
      if (!data[key]) delete data[key];
    });

    return data;
  }

  formConfig = () => ({
    form: FORM_ID,
    api: createTosApi(),
  })

  shouldCreateGroup = values => values.group && values.group.label && !values.group.id;

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const Form = this.renderForm();
    const { sidepanel, submitInProgress } = this.props;
    return (
      <Sidepanel
        icon="Pen"
        title={text.CREATE}
        visible={sidepanel.visible}
        onCloseIconClick={() => this.onClose()}
        footerRender={customFooter(this.onSubmit, this.onClose, submitInProgress)}
      >
        <Space size={16} />
        <Form formId={FORM_ID} modifiers={formModifiers} />
        <Space size={16} />
      </Sidepanel>
    );
  }
}

CreateTocSidepanel.propTypes = propTypes;

export default CreateTocSidepanel;
