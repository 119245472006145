import React, { Component } from 'react';
import { Button, Space, notifier } from 'tc-biq-design-system';
import { object, array, shape, func } from 'prop-types';
import { generatePath } from 'react-router-dom';
import Page from '../../../../../../components/Page';
import FormPanel from '../../../../../../components/form/Components/FormPanel/FormPanel';
import QueryBuilder from '../../../../../../components/QueryBuilder';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import FormFactory from '../../../../../../components/form';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';
import CampaignConditions from './CampaignConditions';
import { refactoredExtractGroup } from '../../../../../../logic/services/query-adapter';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

import './CampaignForm.scss';

export const FORM_KEY = 'CAMPAIGN_FORM';

const api = getDjangoApi('bonus/campaigns');

const modes = {
  CREATE: 'new',
  EDIT: 'edit',
};

const text = {
  SAVE_BUTTON_LABEL: gettext('Save'),
  DISCARD_BUTTON_LABEL: gettext('Discard'),
  BONUS_SYSTEM: gettext('Bonus system'),
  CAMPAIGN_CONDITION: gettext('Campaign condition'),
  BONUS_CONDITIONS: gettext('Bonus amount conditions'),
  ADD_CONDITION: gettext('Add condition'),
  BONUS_AMOUNT_INFO: gettext('If the Fixed option is selected, the bonus amount assigned will be amount in USD converted to equivalent in trading account currency as per relevant exchange rate.'),
  INFO: gettext('Info'),
  STATE: gettext('State'),
  CAMPAIGN_CREATE: gettext('Create bonus campaign'),
  CAMPAIGN_UPDATE: gettext('Update bonus campaign'),
  DISCARD: gettext('Discard'),
  ERROR_GENERAL: gettext('Something went wrong'),
  MISSING_SCHEME: gettext('You have not defined any bonus amount scheme. Please check your input or click "Add case" button'),
};

const modifiers = {
  is_enabled: {
    type: 'toggle',
    label: null,
  },
};

const propTypes = {
  fields: array.isRequired,
  group: object.isRequired,
  actions: object.isRequired,
  form: object.isRequired,
  match: object.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

class CampaignForm extends Component {
  constructor(props) {
    super(props);

    this.id = props.match.params.id;
    this.mode = this.id ? modes.EDIT : modes.CREATE;
    this.title = this.getTitle();
    this.bread = this.getBread();
    this.fieldOptionsPromise = null;
  }

  componentDidMount() {
    const { actions: { getFieldsOptions } } = this.props;
    this.fieldOptionsPromise = getFieldsOptions();
  }


  componentDidUpdate(prevProps) {
    const hasOldFields = !_.isEmpty(_.get(prevProps, 'form.fields'));
    const hasFields = !_.isEmpty(_.get(this.props, 'form.fields'));
    if (hasFields && !hasOldFields && this.id) {
      this.fieldOptionsPromise.then(this.setFormData);
    }
  }

  componentWillUnmount() {
    const { actions: { resetState } } = this.props;
    resetState();
  }

  onSubmit = async () => {
    const def = {
      EVENT: 'new_deposit',
      ACTION: 'apply_ftd_bonus',
    };

    const { actions, form: { values } } = this.props;
    
    if (_.isEmpty(values.schema)) return notifier.error(text.MISSING_SCHEME);
    const getParams = v => ({ ...v, 
      ...(!this.id ? {
        event: def.EVENT,
        action: def.ACTION,
      } : {}),
      ...(v.reason ? { reason: v.reason.value } : {}),
    });
    const action = () => (this.id 
      ? actions.update(api, this.id, getParams, true) 
      : actions.create(api, getParams));
    
    const [err] = await to(action());
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    const { history } = this.props;
    history.push(generatePath(appRoutes.SETTINGS_BONUS));
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <p>{err}</p>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  onDiscard = () => {
    const { history } = this.props;
    history.push(generatePath(appRoutes.SETTINGS_BONUS));
  }

  onConditionsChange = (conditions) => {
    const { actions } = this.props;
    actions.setFieldValue({
      id: 'schema',
      value: conditions,
    });
  }

  onAddCondition = () => {
    const { form } = this.props;
    const { values: { schema } } = form;
    const newFields = [{ is_percentage: false }, { is_percentage: true }];
    const conditions = schema ? [...schema, ...newFields] : newFields;
    this.onConditionsChange(conditions);
  }

  onQbStateUpdate = (condition) => {
    const { actions } = this.props;
    actions.setCondition(condition);
    const extracted = refactoredExtractGroup(condition) || null;
    actions.setFieldValue({
      id: 'condition',
      value: extracted,
    });
  }

  getBread = () => [
    { label: text.BONUS_SYSTEM, route: appRoutes.SETTINGS_BONUS },
    { label: this.getTitle(),
      route: '',
    },
  ];

  getTitle = () => (this.id ? text.CAMPAIGN_UPDATE : text.CAMPAIGN_CREATE)

  getFormConfig = () => ({
    form: FORM_KEY,
    api,
    excludeFields: ['event', 'action', 'created_by', 'action_params'],
  });

  setFormData = async () => {
    const { actions, form: { fields } } = this.props;
    const { value: { data } } = await actions.getCampaign(this.id);
    const { excludeFields } = this.getFormConfig();
    const keys = fields.reduce((acc, field) => (
      !excludeFields.includes(field.id) ? [...acc, field.id] : acc), []);

    keys.forEach(id => actions.setFieldValue({
      id,
      value: data[id],
      ...(id === 'reason' ? { value: this.getReasonValue(fields, data[id]) } : {}),
    }));
  }

  getReasonValue = (fields, value) => {
    const reasonField = fields.find(({ id }) => id === 'reason');

    return reasonField.options.find(option => option.value === value);
  }

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.getFormConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const {
      fields, group,
      form } = this.props;
    const { values: { schema }, errors: { schema: errorSchema } } = form;
    const Form = this.renderForm();

    return (
      <Page
        bread={this.bread}
        title={this.title}
      >
        <Space size={20} />
        <Form
          modifiers={modifiers}
          renderForm={formFields => (
            <FormPanel size="wide">
              <FormPanel.Section>
                <div className="campaign-form__row">
                  <div className="campaign-form__row__half">
                    {formFields.name}
                  </div>
                  <div id="capaign-form-id" className="campaign-form__state">
                    <label htmlFor="capaign-form-id" className="campaign-form__state__label tc-paragraph-regular biq-input-wrapper">
                      {text.STATE}
                    </label>
                    {formFields.is_enabled}
                  </div>
                </div>
                <div className="compaign-form__row">
                  <div className="campaign-form__row__half">
                    {formFields.reason}
                  </div>
                </div>
                <div className="campaign-form__row">
                  <div className="campaign-form__row__flex">
                    {formFields.enabled_from}
                  </div>
                  <Space horizontal size={20} />
                  <div className="campaign-form__row__flex">
                    {formFields.enabled_to}
                  </div>
                </div>
                <Space size={30} />
                <FormPanel.Header title={text.CAMPAIGN_CONDITION} />
                {fields.length ? (
                  <QueryBuilder
                    className="query-builder"
                    fields={fields}
                    group={group}
                    onStateUpdate={this.onQbStateUpdate}
                  />
                ) : (
                  <FormPanel.Spinner />
                )}
                <FormPanel.Header title={text.BONUS_CONDITIONS}>
                  <div style={{ flex: 1 }} />
                  <Button color="transparent" size="small" onClick={() => notifier.info(text.BONUS_AMOUNT_INFO)} icon="Info">{text.INFO}</Button>
                  <Button onClick={this.onAddCondition} icon="Plus" size="small">{text.ADD_CONDITION}</Button>
                </FormPanel.Header>
                <CampaignConditions value={schema} errors={errorSchema} onChange={this.onConditionsChange} />
              </FormPanel.Section>
              <FormPanel.Submit>
                <Button
                  loading={form.submitInProgress}
                  onClick={this.onDiscard}
                  color="ghost"
                >
                  {text.DISCARD}
                </Button>
                <Button
                  type="submit"
                  loading={form.submitInProgress}
                  onClick={this.onSubmit}
                >
                  {text.SAVE_BUTTON_LABEL}
                </Button>
              </FormPanel.Submit>
            </FormPanel>
          )}
        />
      </Page>
    );
  }
}

CampaignForm.propTypes = propTypes;

export default CampaignForm;
