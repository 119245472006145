import React, { useCallback, useEffect, useState } from 'react';
import { object, string, array, func } from 'prop-types';
import { Icon } from 'tc-biq-design-system';
import { connect } from 'react-redux';
import classnames from 'classnames';

import FilterInput from '../FilterInput';
import { fieldType } from '../FilterTypes';
import { formatValue, genreateFilterKey, getChoices, getFilterValue, getFilterValueFromActiveFilters, setFieldValue } from '../filterUtils';

import {
  setActiveFilter,
} from '../FilterRedux';
import { fieldInputResolver, inputTypes } from '../inputTypeResolver';

import './QuickFilter.scss';

const enterInputs = [inputTypes.TEXT, inputTypes.NUMBER];

const propTypes = {
  field: fieldType,
  operator: string.isRequired,
  activeFilters: object,
  autocompleteChoices: array,
  setFilter: func.isRequired,
};

const defaultProps = {
  field: {},
  activeFilters: {},
  autocompleteChoices: [],
};

const QuickFilter = ({ field, operator, autocompleteChoices, activeFilters, setFilter }) => {
  const [value, setValue] = useState(undefined);

  // Apply change
  const onApply = useCallback((data) => {
    const filterKey = genreateFilterKey(field, operator);
    const filterValue = getFilterValue({ operator, value: data, forApi: true, field });
    setFilter({ key: filterKey, value: filterValue });
  }, [field, operator]);

  // Set value and apply changes
  const onChange = useCallback((data) => {
    setValue(data);
    const type = fieldInputResolver(field);
    if (!enterInputs.includes(type)) {
      onApply(data);
    }
  }, [onApply]);

  // Clear filter
  const onClear = useCallback(() => {
    const filterKey = genreateFilterKey(field, operator);
    setFilter({ key: filterKey });
  }, [setFilter]);

  // Parse initial value
  useEffect(() => {
    const filterValue = getFilterValueFromActiveFilters(field, operator, activeFilters);
    const choices = getChoices(field) || autocompleteChoices;
    const fieldValue = setFieldValue(choices, filterValue, operator, formatValue, field);
    setValue(fieldValue);
  }, [field, operator, activeFilters, autocompleteChoices]);

  const showClear = !_.isEmpty(value);
  return (
    <div className="biq-quick-filter">
      <div className="biq-quick-filter__input">
        <FilterInput
          field={field} 
          filterOptions={field.filterOptions || {}}
          operator={operator} 
          onChange={onChange}
          onEnter={onApply}
          value={value}
          withPortal
        />
      </div>
      <div className={classnames('biq-quick-filter__clear', { 'biq-quick-filter__clear--hide': !showClear })}>
        <Icon name="Close" size="small" onClick={onClear} />
      </div>
    </div>
  );
};

QuickFilter.propTypes = propTypes;
QuickFilter.defaultProps = defaultProps;

const mapStateToProps = ({ filters }) => ({
  activeFilters: filters.activeFilters,
  autocompleteChoices: filters.autocompleteChoices,
});

const mapDispatchToProps = {
  setFilter: setActiveFilter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuickFilter);
