import DjangoApi from 'tc-biqf-djangoapi-adapter/dist/django-api.min';
import PubSub from 'pubsub-js';

import { BO_APP_FULL, urlCombine } from './api';
import ACLService from './acl';
import { getCsrfToken } from './cookies';


export const headers = {
  Accept: 'application/json, text/plain, */*',
  'content-type': 'application/json',
};

const interceptors = [injectCsrfToken, tracker];

export { getDjangoApi, updateHttpHeaders, interceptors };

function getDjangoApi(urlPart, aclBase, networdProvider = null, networkProviderType = 'fetch') {
  const model = new DjangoApi(getFullUrl(urlPart), networdProvider, networkProviderType, interceptors);

  if (!aclBase) aclBase = urlPart.replace(/\/\d+\//g, '.').replace(/\//g, '.').replace(/\.$/, '');//eslint-disable-line

  ['create', 'destroy', 'list', 'retrieve', 'update'].forEach((verb) => {
    model[verb].hasAccess = ACLService.hasAccess(`${aclBase}.${verb}`) //eslint-disable-line
    model[verb].aclName = `${aclBase}.${verb}`;
  });

  model.updatePart.hasAccess = ACLService.hasAccess(`${aclBase}.update`)//eslint-disable-line
  model.updatePart.aclName = `${aclBase}.update`;
  model.next.hasAccess = ACLService.hasAccess(`${aclBase}.list`)//eslint-disable-line
  model.next.aclName = `${aclBase}.list`;
  model.options.hasAccess = ACLService.hasAccess(`${aclBase}.list`)//eslint-disable-line
  model.options.aclName = `${aclBase}.list`;
  model.optionsSingle.hasAccess = ACLService.hasAccess(`${aclBase}.retrieve`)//eslint-disable-line
  model.optionsSingle.aclName = `${aclBase}.retrieve`;

  return model;
}


const csrfTokenName = 'X-CSRFToken';

function injectCsrfToken() {
  const csrfToken = getCsrfToken();

  if (!csrfToken) {
    delete headers[csrfTokenName];
  } else {
    headers[csrfTokenName] = csrfToken;
  }

  return {
    request: config => Object.assign({}, config, { headers: mergeHeaders(headers, config.headers) }),
  };
}

function tracker() {
  return {
    request: (config) => {
      PubSub.publish('ADD_REQUEST');
      return config;
    },
    response: () => PubSub.publish('REMOVE_REQUEST'),
    responseError: () => PubSub.publish('REMOVE_REQUEST'),
  };
}

function updateHttpHeaders(key, value) {
  headers[key] = value;
}

function mergeHeaders(predefinedHeaders, requestRelatedHeaders = {}) {
  return Object.assign(predefinedHeaders, requestRelatedHeaders);
}

export function getFullUrl(urlPart) {
  return urlCombine(BO_APP_FULL, urlPart, '/');
}
