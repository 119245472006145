import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { object, string, array, bool } from 'prop-types';
import { Spinner, InfoBox } from 'tc-biq-design-system';

import connect from '../../../../../../logic/connect';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import If from '../../../../../../components/If';
import { activate } from './Model';
import IdentityCode from './IdentityCode';
import './MIFID.scss';

const propTypes = {
  actions: object.isRequired,
  id: string.isRequired,
  data: array.isRequired,
  fetchInProgress: bool.isRequired,
};

const text = {
  INFO_TEXT: gettext('No MIFID data for this user.'),
  NATIONALITY: gettext('Nationality'),
  IDENTITY_CODES: gettext('Nationality identity codes'),
};

class MIFID extends Component {
  componentDidMount() {
    const { id, actions } = this.props;
    actions.activate(id);
  }

  render() {
    const { fetchInProgress, data } = this.props;

    return (
      <div className="biq-profile__wrapper biq-mifid">
        <If condition={fetchInProgress}>
          <div className="loader">
            <Spinner size="large" />
          </div>
        </If>

        <If condition={!fetchInProgress && !data.length}>
          <InfoBox>{text.INFO_TEXT}</InfoBox>
        </If>

        <If condition={!fetchInProgress && !!data.length}>
          <div className="biq-mifid__header">
            <p className="text-neutral-900 tc-heading-m">{text.NATIONALITY}</p>
            <p className="text-neutral-900 tc-paragraph-regular">{data.length && data[0].nationality}</p>
          </div>

          <div className="biq-mifid__content">
            <p className="text-neutral-900 tc-heading-s">{text.IDENTITY_CODES}</p>
            <div className="m-t biq-mifid__identity-codes">
              {data.map(mifidData => <IdentityCode key={mifidData.id} {...mifidData} />)}
            </div>
          </div>
        </If>
      </div>
    );
  }
}

const mapStateToProps = ({ pages }) => pages.USER_SINGLE.MIFID;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ activate }, dispatch),
});

MIFID.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(MIFID);
