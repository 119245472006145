import React from 'react';
import { bindActionCreators } from 'redux';

import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import connect from '../../../../../../logic/connect';
import Confirmation from './Confirmation';
import { fetchUser } from '../../Model';

const ConfirmationFactory = ({
  text,
  apiAction,
  apiParams,
  reduxActionName,
}) => {
  const ConfirmationInstance = props => (
    <Confirmation 
      text={text}
      apiParams={apiParams}
      {...props} 
    />
  );

  const mapStateToProps = ({ pages }) => {
    const page = pages.USER_SINGLE;
    const action = page.actions[reduxActionName];
    return {
      submitInProgress: action.submitInProgress,
    };
  };

  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
      {
        fetchUser,
        apiAction,
      },
      dispatch,
    ),
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorBoundary(ConfirmationInstance));
};

export default ConfirmationFactory;
