/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { Button, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { generatePath, Link } from 'react-router-dom';

import connect from '../../../../../logic/connect';
import GridFactory from '../../../../../components/grid';
import text from '../text';
import { flowModifier } from '../modifier';
import { getActiveFilters } from '../../../../../components/grid/GridUtils';
import Filters from '../../../../../components/Filters';
import overlayActions from '../../../../../components/overlay';
import { BiqUserPreview } from '../../../../../components/common/sidepanels';
import If from '../../../../../components/If';
import { hasAccess } from '../../../../../logic/services/acl';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const tableKey = 'FLOW';

const pageConfig = {
  reducerKey: 'WITHDRAWALS',
  apiUrl: 'settings/withdrawal_flow',
  tableKey: 'FLOW',
};

const { GridComponent, actions } = GridFactory(pageConfig);

const CreateNewFlow = () => (
  <If condition={hasAccess('withdrawal_flow.settings.create')}>
    <Link to={generatePath(appRoutes.SETTINGS_WITDRAWAL_FLOW_CREATE)}>
      <Button startPropagation>{text.CREATE_NEW}</Button>
    </Link>
  </If>
);

class Flow extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.tableActions = {
      onModify: hasAccess('withdrawal_flow.settings.update') && this.getModifyUrl,
      onDelete: hasAccess('withdrawal_flow.settings.destroy') && this.onDelete.bind(this),
      previewBiqUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  onDelete(data) {
    this.actions.deleteRow(data)
      .then(() => {
        notifier.success(text.DELETE_SUCCESS);
      }, () => {
        notifier.error(text.DELETE_ERROR);
      });
  }

  getModifyUrl({ id }) {
    return generatePath(appRoutes.SETTINGS_WITDRAWAL_FLOW, { id });
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: flowModifier(this.tableActions),
    };
    return (
      <Fragment>
        <Filters {...filterProps} />
        <GridComponent
          tableModifier={flowModifier(this.tableActions)} 
          singleActions={!!(this.tableActions.onModify || this.tableActions.onDelete)}
          {...this.props}
        />
        <BiqUserPreview />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchTableData: actions.fetchTableData, 
    updateRowData: actions.updateRowData, 
    deleteRow: actions.deleteRow,
    updateFilter: actions.updateFilter,
    changeSegment: actions.changeSegment,
    fetchOptions: actions.fetchOptions,
    openSidepanel: overlayActions.open,
  }, dispatch), 
});

export { CreateNewFlow, actions, tableKey };
export default connect(mapStateToProps, mapDispatchToProps)(Flow);
