import React, { Component } from 'react';
import { bool, func, object, arrayOf } from 'prop-types';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';

import SidepanelFooter from '../SidepanelFooter';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../logic/connect';
import withUnmountMethod from '../../../../../../components/hoc/withUnmountMethod';
import FormFactory, { FormActionsFactory } from '../../../../../../components/form';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';

const EDIT_CORPORATE_RISK = 'EDIT_CORPORATE_RISK';
const sidepanelID = EDIT_CORPORATE_RISK;
const formID = EDIT_CORPORATE_RISK;
const api = getDjangoApi('settings/risk_rating/user_attrs');

const { update, setFieldValue, setRowData, resetFields, create } = FormActionsFactory(formID);

const text = {
  TITLE: gettext('Corporate'),
  discard: gettext('Discard'),
  save: gettext('Save changes'),
  riskLabel: gettext('Risk'),
  editSuccessMsg: gettext('Risk group edited successfully'),
  createSuccessMsg: gettext('Risk group created successfully'),
};

const formConfig = {
  form: formID,
  api,
  excludeFields: [
    'value',  
  ],
};

const propTypes = {
  visible: bool.isRequired,
  closeSidePanel: func.isRequired,
  parameters: object,
  submitInProgess: bool,
  actions: object.isRequired,
  fields: arrayOf(object),
  values: object,
  dispatch: func.isRequired,
  fetchTableData: func.isRequired,
};

const defaultProps = {
  parameters: null,
  submitInProgess: false,
  fields: [],
  values: {},
};

class EditRiskSidepanel extends Component {
  constructor(props) {
    super(props);

    this.FormComponent = null;
  }

  componentDidUpdate() {
    const { actions: { setFieldValue }, parameters, fields, values } = this.props;
    if (parameters && !isEmpty(fields) && isEmpty(values)) { 
      const parameterField = fields.find(({ id }) => id === 'parameter');
      setFieldValue({ id: 'risk_value', value: parameters.risk_value });
      setFieldValue({ id: 'parameter', value: parameterField.options[0] }); 
    }
  }
  
  onSave = async () => {
    const { 
      values: { risk_value },
      actions: { update },
      parameters: { id },
      fetchTableData,
      fields,
      dispatch } = this.props;
    
    const parameter = fields.find(({ id }) => id === 'parameter');
    const promise = update(api, id, () => ({ risk_value, parameter: parameter.options[0].value, id }));

    const [error] = await to(promise);

    if (!error) { 
      notifier.success(text.editSuccessMsg);
      this.closeSidePanel();
      dispatch(fetchTableData());
    }
  }

  onCreate = async () => {
    const { 
      values: { risk_value },
      actions: { create },
      fields,
      fetchTableData,
      dispatch } = this.props;
    
    const parameter = fields.find(({ id }) => id === 'parameter');
    const promise = create(api, () => ({ risk_value, parameter: parameter.options[0].value }));

    const [error] = await to(promise);

    if (!error) { 
      notifier.success(text.createSuccessMsg);
      this.closeSidePanel();
      dispatch(fetchTableData());
    }
  }

  closeSidePanel = () => {
    const { closeSidePanel } = this.props;
    closeSidePanel(sidepanelID);
  }

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    } 
    const { FormComponent } = this;
    return <FormComponent />;
  }
  

  render() {
    const { 
      visible,
      submitInProgess,
      parameters,
    } = this.props;

    return (
      <Sidepanel
        icon="Edit"
        title={text.TITLE}
        visible={visible}
        onCloseIconClick={this.closeSidePanel}
        footerRender={() => (
          <SidepanelFooter 
            onCancel={this.closeSidePanel}
            onSave={parameters ? this.onSave : this.onCreate}
            submitInProgess={submitInProgess}
          />
        )}
      >
        { this.renderForm() }
      </Sidepanel>
    );
  }
}

EditRiskSidepanel.propTypes = propTypes;
EditRiskSidepanel.defaultProps = defaultProps;

const mapStateToProps = ({ overlays, forms }) => ({
  sidepanel: overlays[sidepanelID],
  ...overlays[sidepanelID],
  ...forms[formID],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    update,
    setRowData,
    resetFields,
    create,
    setFieldValue,
  }, dispatch),
  dispatch,
});

export { sidepanelID, api };
export default withUnmountMethod(connect(mapStateToProps, mapDispatchToProps)(EditRiskSidepanel));
