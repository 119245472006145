import React, { Component } from 'react';
import { Button, notifier, Space } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';
import { object, node, func } from 'prop-types';

import Page from '../../../../../components/Page';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import connect from '../../../../../logic/connect';
import FormFactory, { FormActionsFactory } from '../../../../../components/form';
import FormPanel from '../../../../../components/form/Components/FormPanel/FormPanel';

import { getDjangoApi } from '../../../../../logic/services/api-factory';
import to from '../../../../../logic/utilities/to';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const policyApi = getDjangoApi('settings/password-policy');

const text = {
  PAGE_TITLE: gettext('Passwords'),
  SAVE: gettext('Save'),
  SUCCESS: gettext('Password policy successfully updated'),
  LAST: gettext('Last'),
  USED_PASSWORD: gettext('used password'),
  PASSWORD_CHANGING_RULE: gettext('Password changing rules'),
  CLIENT_IS_NOT_ALLOWED: gettext('Client is not allowed to use:'),
};

const bread = [{
  label: text.PAGE_TITLE, route: appRoutes.SETTINGS_PASSWORD_POLICY,
}];

const FORM_ID = 'SETTINGS_PASSWORDS_FORM';

const { setFieldValue, create } = FormActionsFactory(FORM_ID);

const fieldsEnum = {
  LAST_USED_PASSWORD: {
    id: 'last_used_password',
    name: 'last_used_password',
    value: 0,
  },
  CUSTOM_USED_PASSWORD: {
    id: 'custom_used_password',
    name: 'custom_used_password',
    value: 1,
  },
  ANYTIME_USED_PASSWORD: {
    id: 'anytime_used_password',
    name: 'anytime_used_password',
    value: 2,
  },
  USED_PASSWORD_COUNT: {
    id: 'used_password_count',
    name: 'used_password_count',
    defaultValue: {
      value: 2,
      display_name: '2',
    },
  },
  POLICY: {
    id: 'policy',
  },
};

function* range(start, end) {
  for (let i = start; i <= end; i += 1) {
    yield i;
  }
}

const formConfig = {
  form: FORM_ID,
  customFields: [
    { 
      id: fieldsEnum.LAST_USED_PASSWORD.id,
      type: 'radio',
      name: fieldsEnum.LAST_USED_PASSWORD.name,
      value: fieldsEnum.LAST_USED_PASSWORD.value,
    },
    { 
      id: fieldsEnum.CUSTOM_USED_PASSWORD.id,
      type: 'radio',
      name: fieldsEnum.CUSTOM_USED_PASSWORD.name,
      value: fieldsEnum.CUSTOM_USED_PASSWORD.value,
    },
    { 
      id: fieldsEnum.ANYTIME_USED_PASSWORD.id,
      type: 'radio',
      name: fieldsEnum.ANYTIME_USED_PASSWORD.name,
      value: fieldsEnum.ANYTIME_USED_PASSWORD.value,
    },
    {
      id: fieldsEnum.USED_PASSWORD_COUNT.id,
      type: 'select',
      name: fieldsEnum.USED_PASSWORD_COUNT.name,
      options: [...range(2, 10)].map(v => ({
        value: v,
        display_name: v,
      })), 
      valueKey: 'value',
      labelKey: 'display_name',
    },
  ],
};


const Field = ({ field, ...props }) => {
  const { onClick } = props;
  return (
    <span onClick={onClick}>
      { React.cloneElement(field, { ...props }) }
    </span>
  );
};

const noop = () => {};

Field.propTypes = {
  field: node.isRequired,
  onClick: func,
};

Field.defaultProps = {
  onClick: noop,
};

class PasswordPage extends Component {
  constructor(props) {
    super(props);

    this.FormComponent = null;
  }

  componentDidMount() {
    this.fetchPasswordPolicy();
  }
  
  setFieldValue = (id, value) => {
    const { actions } = this.props;
    actions.setFieldValue({
      id,
      value,
    });
  }

  resolvePolicyValue = () => {
    const { values: { policy, used_password_count } } = this.props;
    if (policy === fieldsEnum.LAST_USED_PASSWORD.value) {
      return 1;
    }
    if (policy === fieldsEnum.ANYTIME_USED_PASSWORD.value) {
      return 'Inf';
    }
    if (policy === fieldsEnum.CUSTOM_USED_PASSWORD.value) {
      return used_password_count.value;
    }
  }

  changePasswordPolicy = async () => {
    const [err] = await to(policyApi.create({
      PASSWORD_POLICY_NUMBER_OF_USER_PASSWORDS_TO_CHECK: this.resolvePolicyValue(),
    }));

    if (!err) {
      notifier.success(text.SUCCESS);
    }
  }

  fetchPasswordPolicy = () => {
    policyApi.list().then((res) => {
      const policyValue = res.data.options[0].PASSWORD_POLICY_NUMBER_OF_USER_PASSWORDS_TO_CHECK;
      const {
        ANYTIME_USED_PASSWORD,
        POLICY,
        LAST_USED_PASSWORD,
        CUSTOM_USED_PASSWORD,
        USED_PASSWORD_COUNT,
      } = fieldsEnum;
      this.setFieldValue(USED_PASSWORD_COUNT.id, USED_PASSWORD_COUNT.defaultValue);

      if (policyValue === 'Inf') {
        this.setFieldValue(POLICY.id, ANYTIME_USED_PASSWORD.value);
      } else if (policyValue === 1) {
        this.setFieldValue(POLICY.id, LAST_USED_PASSWORD.value);
      } else {
        this.setFieldValue(POLICY.id, CUSTOM_USED_PASSWORD.value);
        this.setFieldValue(USED_PASSWORD_COUNT.id, {
          value: policyValue,
          display_name: `${policyValue}`,
        });
      }
    });
  };

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig); 
    }

    const { FormComponent } = this;
    const { 
      LAST_USED_PASSWORD,
      CUSTOM_USED_PASSWORD,
      ANYTIME_USED_PASSWORD,
      POLICY,
    } = fieldsEnum;
    const { values } = this.props;
    const { setFieldValue } = this;
    const { policy } = values;

    return (
      <FormComponent renderForm={fields => (
        <div>
          <Field
            field={fields[fieldsEnum.LAST_USED_PASSWORD.id]}
            checked={policy === LAST_USED_PASSWORD.value}
            onClick={() => {
              setFieldValue(
                POLICY.id,
                LAST_USED_PASSWORD.value,
              );
            }}
          >
            { `${text.LAST} ${text.USED_PASSWORD}` }, 
          </Field>
          <Space size={20} />
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <Field
              field={fields[CUSTOM_USED_PASSWORD.id]}
              checked={policy === CUSTOM_USED_PASSWORD.value}
              onClick={() => {
                setFieldValue(
                  POLICY.id,
                  CUSTOM_USED_PASSWORD.value,
                );
              }}
            >
              { text.LAST}
            </Field>
            <Field 
              field={fields.used_password_count}
              style={{ width: '100px', marginLeft: '20px', marginRight: '10px' }}
              clearable={false}
            />
            <span>{ text.USED_PASSWORD }</span>
          </div>
          <Space size={20} />
          <Field
            field={fields[ANYTIME_USED_PASSWORD.id]}
            checked={policy === ANYTIME_USED_PASSWORD.value}
            onClick={() => {
              setFieldValue(
                POLICY.id,
                ANYTIME_USED_PASSWORD.value,
              );
            }}
          >
            {`${text.LAST} ${text.USED_PASSWORD}`}
          </Field>
        </div>
      )}
      />
    );
  }

  render() {
    return (
      <Page
        bread={bread}
        title={text.PAGE_TITLE}
      >
        <div className="settings-passwords">
          <FormPanel>
            <FormPanel.Section>
              <h3> { text.PASSWORD_CHANGING_RULE }</h3>
              <h4> { text.CLIENT_IS_NOT_ALLOWED }</h4>
              <Space size={20} />
              { this.renderForm() }
            </FormPanel.Section>
            <FormPanel.Submit>
              <Button onClick={this.changePasswordPolicy}>{ text.SAVE }</Button>
            </FormPanel.Submit>
          </FormPanel>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ forms }) => ({
  ...forms[FORM_ID],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    setFieldValue,
    create,
  }, dispatch),
});

PasswordPage.propTypes = {
  actions: object.isRequired,
  values: object,
};


PasswordPage.defaultProps = {
  values: {},
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(PasswordPage); 
