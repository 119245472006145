import { bindActionCreators } from 'redux';
import connect from '../../../../logic/connect';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import { fetchPlaceholdersAction, resetPlaceholdersStateAction } from '../UpdateTemplateModel';
import SmsDataTagaSidepanel, { sidepanelID, formID } from './DataTagsSidepanel';

const mapStateToProps = ({ pages, overlays, forms }) => ({
  ...overlays[sidepanelID],
  ...forms[formID],
  ...pages.UPDATE_NOTIFICATION_TEMPLATE,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchPlaceholdersAction,
    resetPlaceholdersStateAction,
  }, dispatch),
});

export { sidepanelID };

export default 
withUnmountMethod(connect(mapStateToProps, mapDispatchToProps)(SmsDataTagaSidepanel));
