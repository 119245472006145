import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import session from '../../../../../../../logic/services/session';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import IB_STATE from '../../../../../../../logic/enums/ib-state';
import userActionsTypes from '../types';
import Login from './LoginAsUser';

const { environment } = window.config;

const text = {
  LABEL: gettext('Login as user'),
};

// @TODO missing archive icon
const ACTION_ICON = 'User';

export default {
  name: 'login-as-user',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionsTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    if (environment === 'AvaTrade') return false;

    if (session.user && session.user.is_superuser) {
      if (isIb) {
        return !user.is_archived && state !== IB_STATE.INCOMPLETE;
      } 
      return _.includes([
        USER_STATE.FULL,
        USER_STATE.PENDING,
        USER_STATE.LIMITED,
        USER_STATE.REVIEW,
        USER_STATE.DEMO,
      ], state);
    } 
    return false;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };
    sidepanelManager.add(Login, sidepanelOptions);
  },
};
