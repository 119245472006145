import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const actionTypes = {
  FETCH_INSTRUMENT_STATS: 'FETCH_INSTRUMENT_STATS',
  FETCH_INSTRUMENT_STATS_PENDING: 'FETCH_INSTRUMENT_STATS_PENDING',
  FETCH_INSTRUMENT_STATS_REJECTED: 'FETCH_INSTRUMENT_STATS_REJECTED',
  FETCH_INSTRUMENT_STATS_FULFILLED: 'FETCH_INSTRUMENT_STATS_FULFILLED',
};

const api = user => getDjangoApi(`users/${user.id}/instruments_stats`);

export const fetchInstrumentStats = createAction(actionTypes.FETCH_INSTRUMENT_STATS, async (user) => {
  const { data } = await api(user).list();
  return data;
});

const initialState = {
  stats: [],
  fetchInProgress: false,
  errors: null,
};

const fetchPending = state => ({ ...state, fetchInProgress: true });

const fetchRejected = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  errors: payload.data,
});

const fetchFulfilled = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  stats: payload,
});

export default handleActions(
  {
    [actionTypes.FETCH_INSTRUMENT_STATS_PENDING]: fetchPending,
    [actionTypes.FETCH_INSTRUMENT_STATS_FULFILLED]: fetchFulfilled,
    [actionTypes.FETCH_INSTRUMENT_STATS_REJECTED]: fetchRejected,
  },
  initialState,
);
