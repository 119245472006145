import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { notifier, Button } from 'tc-biq-design-system';

import { gettext } from '../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../logic/services/acl';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import modifiers from './modifier';
import Page from '../../../../components/Page';
import { BiqUserPreview } from '../../../../components/common/sidepanels';
import ComplianceStatusSidepanel, { SIDEPANEL_ID } from './CreateComplianceStatusSidepanel/ComplianceStatusSidepanel';
import withTable, { GridTab } from '../../../../components/grid/withTable';
import appRoutes from '../../../../components/App/Router/appRoutes';

export const tableConfig = () => ({
  apiUrl: 'compliance_status',
  reducerKey: 'COMPLIANCE_STATUS',
  tableKey: 'COMPLIANCE_STATUS_TABLE',
});

const text = {
  PAGE_LABEL: gettext('Compliance statuses'),
  DELETE_SUCCESS: gettext('Compliance status deleted successfully'),
  DELETE_ERROR: gettext('Error while deleting compliance status'),
  CREATE_NEW: gettext('Create new'),
};

const propTypes = {
  dispatch: func.isRequired,
  table: object.isRequired,
  sidepanel: object.isRequired,
  actions: object.isRequired,
};

const bread = [{ label: text.PAGE_LABEL, route: appRoutes.SETTINGS_COMPLIANCE_STATUSES }];
const api = getDjangoApi('compliance_status/');

class ComplianceStatuses extends Component {
  constructor(props) {
    super(props);

    this.Grid = withTable(GridTab, tableConfig, null, { withActions: true })();
    this.actions = {
      remove: hasAccess('compliance_status.destroy') && this.onRemove.bind(this),
      modify: hasAccess('compliance_status.update') && this.onModify.bind(this),
      previewBiqUser: props.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  onRemove({ id }) {
    api.destroy(id)
      .then(() => {
        this.reloadTable();
        notifier.success(text.DELETE_SUCCESS);
      }).catch(() => {
        notifier.error(text.DELETE_ERROR);
      });
  }

  onModify(data) {
    const { actions, sidepanel } = this.props;
    if (sidepanel.visible) actions.closeSidepanel(SIDEPANEL_ID);
    actions.openSidepanel(SIDEPANEL_ID, { mode: 'Edit', data });
  }

  headerActions = () => {
    const { actions } = this.props;
    const actionHandler = () => actions.openSidepanel(SIDEPANEL_ID, { mode: 'Create' });
    return hasAccess('compliance_status.create') && <Button onClick={actionHandler}>{text.CREATE_NEW}</Button>;
  };

  reloadTable = () => {
    const { dispatch, table } = this.props;
    const [, tableActions] = this.Grid;
    dispatch(tableActions.fetchTableData(table.query));
  }

  render() {
    const [Table] = this.Grid;

    return (
      <Page bread={bread} title={text.PAGE_LABEL} headerActions={this.headerActions}>
        <Table
          singleActions
          modifier={modifiers(this.actions)}
        />
        <ComplianceStatusSidepanel onSuccess={this.reloadTable} />
        <BiqUserPreview />
      </Page>
    );
  }
}

ComplianceStatuses.propTypes = propTypes;

export default ComplianceStatuses;
