import React, { PureComponent } from 'react';
import { Sidepanel, Panel, Space, Toggle, Pill, notifier, Popconfirm } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { object, bool } from 'prop-types';

import connect from '../../../logic/connect';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import overlayActions from '../../overlay';
import { gettext } from '../../../logic/utilities/languageUtility';
import Extra from '../../Extra';
import UserInfo from '../../UserInfo';
import { SidepanelFooter } from '..';
import { getDjangoApi } from '../../../logic/services/api-factory';
import to from '../../../logic/utilities/to';
import { hasAccess } from '../../../logic/services/acl';
import { formErrorHandler } from '../../form/logic/utils';

export const SIDEPANEL_ID = 'TRADING_ACCOUNT';
const tradingAccountApi = getDjangoApi('trading_account_overview');
const getRecreateApi = (userId, accountId) => getDjangoApi(`users/${userId}/trading_accounts/${accountId}/resend`);
const getReadOnlyApi = (userId, accountId) => getDjangoApi(`users/${userId}/trading_accounts/${accountId}/set_read_only`);
const getAccountStatusApi = (userId, accountId) => getDjangoApi(`users/${userId}/trading_accounts/${accountId}/set_active`);

const propTypes = {
  sidepanel: object.isRequired,
  actions: object.isRequired,
  hideUserInfo: bool,
};

const defaultProps = {
  hideUserInfo: false,
};

const text = {
  TITLE: gettext('Trading account'),
  PANEL_TITLE: gettext('Account details'),
  ACCOUNT_ID: gettext('Account ID'),
  ACC_EXTERNAL_ID: gettext('Acc. external ID'),
  ACCOUNT_NAME: gettext('Account name'),
  BACKEND: gettext('Backend'),
  BUTTON_LABELS: {
    cancel: gettext('No'),
    confirm: gettext('Yes'),
  },
  PLATFORM: gettext('Platform'),
  CURRENCY: gettext('Currency'),
  DATE_CREATED: gettext('Date created'),
  LAST_UPDATED: gettext('Last updated'),
  EXPIRY_DATE: gettext('Expiry date'),
  READ_ONLY: gettext('Read only'),
  READ_ONLY_CONFIRM: gettext('Are you sure you want to change Read only mode?'),
  READ_ONLY_SUCCESS: gettext('Read only mode successfully updated'),
  READ_ONLY_ERROR: gettext('Error while updating read only mode'),
  ENABLE_BUTTON_LABEL: { confirm: gettext('Enable') },
  DISABLE_BUTTON_LABEL: { confirm: gettext('Disable') },
  RECREATE_ERROR: gettext('Error recreating trading account'),
  RECREATE_SUCCESS: gettext('Trading account recreated'),
  RECREATE_BUTTON_LABEL: { confirm: gettext('Recreate') },
  CHANGE_STATUS_SUCCESS: gettext('Trading account status successfully changed'),
  CHANGE_STATUS_ERROR: gettext('Error while changing trading account status'),
};

const refreshTradingAccountData = async (updateSidepanel, accountId) => {
  const response = await tradingAccountApi.retrieve(accountId);
  updateSidepanel(SIDEPANEL_ID, response.data);
};

const setAccountStatus = (status) => {
  switch (status) {
    case 'active':
      return 'status01';
    case 'processing':
      return 'status02';
    case 'pending approval':
      return 'primary';
    case 'error':
      return 'status04';
    default:
      return 'neutral';
  }
};

const renderFooter = (status, recreate, enable, disable) => {
  switch (status) {
    case 'active':
      return hasAccess('user.trading_accounts.set_active.*') ? (
        <SidepanelFooter
          confirmColor="ghost"
          execute={disable}
          buttonLabels={text.DISABLE_BUTTON_LABEL}
        />
      ) : null;
    case 'disabled':
      return hasAccess('user.trading_accounts.set_active.*') ? (
        <SidepanelFooter
          confirmColor="ghost"
          execute={enable}
          buttonLabels={text.ENABLE_BUTTON_LABEL}
        />
      ) : null;
    default:
      return hasAccess('user.trading_accounts.resend.*') ? (
        <SidepanelFooter
          confirmColor="primary"
          execute={recreate}
          buttonLabels={text.RECREATE_BUTTON_LABEL}
        />
      ) : null;
  }
};

const renderToggle = (readOnly, toggle) => (
  <Popconfirm
    icon="Approve"
    label={text.READ_ONLY_CONFIRM}
    onConfirm={toggle}
    buttonLabels={text.BUTTON_LABELS}
  >
    <Toggle checked={readOnly} key="toggle" />
  </Popconfirm>
);

const formatPanelBodyData = data => [
  { label: text.ACCOUNT_ID, value: data.id },
  { label: text.ACCOUNT_NAME, value: data.name },
  { label: text.ACC_EXTERNAL_ID, value: data.external_id },
  { label: text.BACKEND, value: data.backend.name },
  { label: text.PLATFORM, value: data.backend.platform_type },
  { label: text.CURRENCY, value: data.currency.symbol },
  { label: text.DATE_CREATED, value: moment.utc(data.created).format('MMM Do YYYY, [at] HH:mm') },
  {
    label: text.LAST_UPDATED,
    value: data.lastUpdated && moment.utc(data.lastUpdated).format('MMM Do YYYY, [at] HH:mm'),
  },
  {
    label: text.EXPIRY_DATE,
    value: data.expires_at && moment.utc(data.expires_at).format('MMM Do YYYY, [at] HH:mm'),
  },
  {
    label: text.READ_ONLY,
    value: toggle => renderToggle(data.read_only, toggle),
  },
];

// eslint-disable-next-line
const PanelBody = ({ tradeData, toggleReadOnly }) => {
  const bodyData = formatPanelBodyData(tradeData);
  return (
    <div className="card-panel-body">
      {bodyData
        .filter(line => !!line.value)
        .map((line, index) => (
          <div className="panel-body-line" key={line.value + index}>
            <span>{line.label}</span>
            <span>{typeof line.value === 'function' ? line.value(toggleReadOnly) : line.value}</span>
          </div>
        ))}
    </div>
  );
};

class TradingAccount extends PureComponent {
  constructor(props) {
    super(props);

    this.toggleReadOnly = this.toggleReadOnly.bind(this);
    this.toggleAccount = this.toggleAccount.bind(this);
    this.recreateAccount = this.recreateAccount.bind(this);
  }

  async toggleReadOnly(readOnly) {
    const { actions, sidepanel } = this.props;
    const { parameters } = sidepanel;
    const readOnlyApi = getReadOnlyApi(parameters.user.id, parameters.id);
    const [err] = await to(readOnlyApi.create({ read_only: !readOnly }));
    if (err) {
      formErrorHandler(text.READ_ONLY_ERROR)(err);
    } else {
      actions.updateSidepanel(SIDEPANEL_ID, { ...parameters, read_only: !readOnly });
      notifier.success(text.READ_ONLY_SUCCESS);
    }
  }

  async recreateAccount() {
    const { actions, sidepanel } = this.props;
    const { parameters } = sidepanel;
    const recreateApi = getRecreateApi(parameters.user.id, parameters.id);
    const [err, response] = await to(recreateApi.create());
    if (err || !response.data.success) {
      formErrorHandler(text.RECREATE_ERROR)(err);
    } else {
      refreshTradingAccountData(actions.updateSidepanel, parameters.id);
      notifier.success(text.RECREATE_SUCCESS);
    }
  }

  async toggleAccount() {
    const { actions, sidepanel } = this.props;
    const { parameters } = sidepanel;
    const accountStatusApi = getAccountStatusApi(parameters.user.id, parameters.id);
    const [err] = await to(accountStatusApi.create({ active: parameters.status !== 'active', propagate_status: true }));
    if (err) {
      formErrorHandler(text.CHANGE_STATUS_ERROR)(err);
    } else {
      refreshTradingAccountData(actions.updateSidepanel, parameters.id);
      notifier.success(text.CHANGE_STATUS_SUCCESS);
    }
  }

  render() {
    const { sidepanel, actions, hideUserInfo } = this.props;
    if (!sidepanel.parameters) return null;
    const data = sidepanel.parameters;

    return (
      <Sidepanel
        visible={sidepanel.visible}
        title={`${text.PANEL_TITLE} ${data.id}`}
        onCloseIconClick={() => actions.closeSidepanel(SIDEPANEL_ID)}
        footerRender={() => renderFooter(data.status, this.recreateAccount, this.toggleAccount, this.toggleAccount)}
      >
        {!hideUserInfo && <UserInfo user={data.user} />}
        <Panel
          title={text.PANEL_TITLE}
          status={() => <Pill key="panelStatus" type={setAccountStatus(data.status)}>{data.status}</Pill>}
        >
          <PanelBody tradeData={data} toggleReadOnly={() => this.toggleReadOnly(data.read_only)} />
        </Panel>
        <Space size={16} />
        <Extra rawData={data.extra} />
      </Sidepanel>
    );
  }
}

const mapStateToProps = ({ overlays }) => ({
  sidepanel: overlays.TRADING_ACCOUNT,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      updateSidepanel: overlayActions.update,
    },
    dispatch,
  ),
});

TradingAccount.propTypes = propTypes;
TradingAccount.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(TradingAccount));
