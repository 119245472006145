import React, { PureComponent } from 'react';
import { array, string, func } from 'prop-types';
import { Input } from 'tc-biq-design-system';

import VisibilityCheckboxFactory from './VisibilityCheckboxFactory';

// @TODO dynamic label
const filterOptions = filter => option => (
  option.label.toLowerCase().includes(filter.toLowerCase())
);

const propTypes = {
  options: array,
  onToggle: func.isRequired,
  modalId: string.isRequired,
};

const defaultProps = {
  options: [],
};

class OptionsBox extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { filter: '' };
  }

  handleInputChange = (filter) => {
    this.setState({ filter });
  }

  renderFields() {
    const { options, modalId, onToggle } = this.props;
    const { filter } = this.state;

    // @TODO dymamic label and key
    return options
      .filter(filterOptions(filter))
      .map(({ key, label }) => { 
        const VisibilityCheckbox = VisibilityCheckboxFactory({ modalId, key });

        return (
          <VisibilityCheckbox 
            key={key} 
            label={label} 
            onChange={onToggle} 
          />
        );
      });
  }

  render() {
    const { filter } = this.state;

    return (
      <div className="biq-modal-body__section">
        <div className="search-box">
          <Input 
            id="columns-search" 
            placeholder="Search" 
            iconPosition="left" 
            icon="Search"
            value={filter}
            onChange={e => this.handleInputChange(e.target.value)}
          />
        </div>

        <div className="columns">
          {this.renderFields()}
        </div>
      </div>
    );
  }
}

OptionsBox.propTypes = propTypes;
OptionsBox.defaultProps = defaultProps;
export default OptionsBox;
