import { array, func, oneOf } from 'prop-types';
import React, { Fragment, useMemo, useCallback, useState, useEffect } from 'react';
import { Button, Radio, Select, Sidepanel, Input, notifier } from 'tc-biq-design-system';
import { useDispatch, useSelector } from '../../../../../logic/utilities/hooks';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { getOverlayExtra, getOverlayParameters, getOverlayVisibility } from '../../../../overlay/reducer';
import { getClientsTablePagination, getClientsTableOrdering } from '../../../../../logic/utilities/storageGetters';
import overlayActions from '../../../../overlay';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { ClientsList } from '../../..';
import ProgressBar from '../../../ProgressBar/ProgressBar';

import './MassAssignment.scss';
import { getActiveFilters } from '../../../../Filters/FilterRedux';
import { getBulkActionDataStats, getSocketUrl, isBulkActionDataFinished } from '../../../../../logic/utilities/socket';

export const MASS_ASSIGN_TYPES = {
  EDIT_TAGS: 'EDIT_TAGS',
  ASSIGN_SALES_MANAGER: 'ASSIGN_SALES_MANAGER',
  EDIT_SALES_STATUS: 'EDIT_SALES_STATUS',
  ASSIGN_CAGE: 'ASSIGN_CAGE',
  ASSIGN_RETENTION_POOL_DESK: 'ASSIGN_RETENTION_POOL_DESK',
  ASSIGN_MANAGER: 'ASSIGN_MANAGER',
  ARCHIVE: 'ARCHIVE',
};

const text = {
  titles: {
    [MASS_ASSIGN_TYPES.EDIT_TAGS]: gettext('Edit tags'),
    [MASS_ASSIGN_TYPES.ASSIGN_SALES_MANAGER]: gettext('Assign sales manager'),
    [MASS_ASSIGN_TYPES.EDIT_SALES_STATUS]: gettext('Edit sales status'),
    [MASS_ASSIGN_TYPES.ASSIGN_CAGE]: gettext('Assign cage'),
    [MASS_ASSIGN_TYPES.ASSIGN_RETENTION_POOL_DESK]: gettext('Edit retention pool desk'),
    [MASS_ASSIGN_TYPES.ASSIGN_MANAGER]: gettext('Assign retention manager'),
    [MASS_ASSIGN_TYPES.ARCHIVE]: gettext('Archive'),
  },
  submit: gettext('Execute'),
  cancel: gettext('Cancel'),
  placeholder: gettext('Search'),
  allItems: gettext('All items from the filter'),
  first: gettext('First'),
  firstContinue: gettext('items from the filter'),
  selected: gettext('Selected users'),
  addTags: gettext('Add tags'),
  removeTags: gettext('Remove tags'),
  massAssignmentInProgress: gettext('Mass assignment is in progress'),
  finished: (success, failed, skipped) => (
    <>
      {gettext('Bulk action Success count: {{success}}', { success })} 
      <br />{gettext('Bulk action Failed count: {{failed}}', { failed })} 
      <br />{gettext('Bulk action Skipped count: {{skipped}}', { skipped })} 
      <br /> For more details, please check your email.
    </>
  ),
  error: gettext('An error occurred while initializing the WebSocket.'),
  [`manager_error${MASS_ASSIGN_TYPES.ASSIGN_SALES_MANAGER}`]: gettext('Selected item should be sales manager'),
  [`manager_error${MASS_ASSIGN_TYPES.ASSIGN_MANAGER}`]: gettext('Selected item should be retention manager'),
};

const MODES = {
  selectedUsers: 'selectedUsers',
  limited: 'limited',
  unlimited: 'unlimited',
};

const APIs = {
  [MASS_ASSIGN_TYPES.EDIT_TAGS]: getDjangoApi('tags'),
  [MASS_ASSIGN_TYPES.ASSIGN_SALES_MANAGER]: getDjangoApi('team'),
  [MASS_ASSIGN_TYPES.ASSIGN_CAGE]: getDjangoApi('autocomplete/cages'),
  [MASS_ASSIGN_TYPES.EDIT_SALES_STATUS]: getDjangoApi('autocomplete/sales_status'),
  [MASS_ASSIGN_TYPES.ASSIGN_RETENTION_POOL_DESK]: getDjangoApi('autocomplete/retention_pool_desks'),
  [MASS_ASSIGN_TYPES.ASSIGN_MANAGER]: getDjangoApi('team'),
};

const SUBMIT_APIs = {
  [`${MASS_ASSIGN_TYPES.EDIT_TAGS}add`]: getDjangoApi('/bulk_actions/users/add_tags'),
  [`${MASS_ASSIGN_TYPES.EDIT_TAGS}remove`]: getDjangoApi('/bulk_actions/users/remove_tags'),
  [MASS_ASSIGN_TYPES.ASSIGN_SALES_MANAGER]: getDjangoApi('/bulk_actions/users/sales_manager'),
  [MASS_ASSIGN_TYPES.ASSIGN_CAGE]: getDjangoApi('/bulk_actions/users/assign_cage'),
  [MASS_ASSIGN_TYPES.EDIT_SALES_STATUS]: getDjangoApi('/bulk_actions/users/set_sales_status/'),
  [MASS_ASSIGN_TYPES.ASSIGN_RETENTION_POOL_DESK]: getDjangoApi('/bulk_actions/users/retention_pool_desk/'),
  [MASS_ASSIGN_TYPES.ASSIGN_MANAGER]: getDjangoApi('/bulk_actions/users/retention_manager/'),
  [MASS_ASSIGN_TYPES.ARCHIVE]: getDjangoApi('/bulk_actions/users/archive/'),
  [`${MASS_ASSIGN_TYPES.ASSIGN_SALES_MANAGER}activetrader`]: getDjangoApi('/bulk_actions/active_traders/sales_manager'),
  [`${MASS_ASSIGN_TYPES.ASSIGN_MANAGER}activetrader`]: getDjangoApi('/bulk_actions/active_traders/retention_manager/'),
};

const customFooter = (
  execute,
  close,
  submitInProgress,
  users,
  mode,
  usersNum,
  withoutData = false,
) => {
  const extra = useSelector(state => getOverlayExtra(state, 'MASS_ASSIGNMENT'));
  const data = useSelector(state => getOverlayParameters(state, 'MASS_ASSIGNMENT'));
  
  const percent = useMemo(() => (extra?.processed >= 0 && extra?.total >= 0 
    ? Math.round((extra?.processed / extra?.total) * 100) : 0), [extra]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isBulkActionDataFinished(extra?.last_event) && extra?.assignmentInProgress) {
      dispatch(overlayActions.updateExtra('MASS_ASSIGNMENT', { assignmentInProgress: false }));
      notifier.success(text.finished(extra?.success, extra?.failed || 0, extra?.skipped || 0));
      close();
    }
  }, [extra, close]);

  const isSubmitDisabled = useMemo(() => submitInProgress || extra?.assignmentInProgress  
  || ((!data || (Array.isArray(data) && !data.length)) && !withoutData) 
  || (users.length === 0 && mode === MODES.selectedUsers)
  || (mode === MODES.limited && !+usersNum),
  [submitInProgress, data, users, mode, usersNum]);

  return (
    <Fragment>
      { extra?.assignmentInProgress && (
        <div>
          <ProgressBar 
            percent={percent} 
            label={`${extra?.action || text.massAssignmentInProgress} (${extra?.processed}/${extra?.limit && extra?.total ? Math.min(extra?.limit, extra?.total) : extra?.total})`} 
          />
        </div>
      )}
      { !extra?.assignmentInProgress && (
      <Fragment>
        <Button color="ghost" onClick={close}>
          {text.cancel}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          loading={submitInProgress}
          color="primary"
          onClick={execute}
        >
          {text.submit}
        </Button>
      </Fragment>
      )}
    </Fragment>
  );
};

const createFilterQueryString = (mode, selectedUsers, filters) => {
  let filterQueryString = '';
  if (mode === MODES.selectedUsers) {
    filterQueryString = `id__in=${selectedUsers.map(user => user.id).join(',')}`;
  } else {
    const ignoredKeys = ['limit', 'offset', 'ordering', '_current_timestamp', '_segment'];
    Object.entries(filters).forEach(([key, val]) => {
      if (!ignoredKeys.includes(key)) filterQueryString += `${key}=${val}&`;
    });
    filterQueryString.length > 0 ? filterQueryString = filterQueryString.substring(0, filterQueryString.length - 1) : '';
    if (!filterQueryString) filterQueryString = 'id__exact!=0';
  }
  return filterQueryString;
};

const SUFFIXES = {
  add: 'add',
  remove: 'remove',
};

const createRequestUpdateData = (type, data) => {
  const getVlaues = (by) => {
    let res;
    if (Array.isArray(data)) {
      res = data.map(item => item[by]);
    } else {
      res = data[by];
    }
    return res;
  };
  switch (type) {
    case MASS_ASSIGN_TYPES.EDIT_TAGS: return { tags: getVlaues('name') };
    case MASS_ASSIGN_TYPES.ASSIGN_SALES_MANAGER: return { manager: getVlaues('id') };
    case MASS_ASSIGN_TYPES.ASSIGN_CAGE: return { cage: getVlaues('name') };
    case MASS_ASSIGN_TYPES.EDIT_SALES_STATUS: return { sales_status: getVlaues('id') };
    case MASS_ASSIGN_TYPES.ASSIGN_RETENTION_POOL_DESK: return { retention_pool_desk: getVlaues('id') ? getVlaues('name') : null };
    case MASS_ASSIGN_TYPES.ASSIGN_MANAGER: return { manager: getVlaues('id') };
    case MASS_ASSIGN_TYPES.ARCHIVE: return { pk: null };
    default: return null;
  }
};

const MassAssignment = ({ users = [], apiSuffix = '', fetchTableData }) => {
  const [mode, setMode] = useState(MODES.selectedUsers);
  const [usersNum, setUsersNum] = useState(500);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [suffix, setSuffix] = useState(apiSuffix);
  const [errors, setErrors] = useState();
  const { type } = useSelector(state => getOverlayExtra(state, 'MASS_ASSIGNMENT') || {});
  const data = useSelector(state => getOverlayParameters(state, 'MASS_ASSIGNMENT'));
  const visible = useSelector(state => getOverlayVisibility(state, 'MASS_ASSIGNMENT'));
  const filters = useSelector(getActiveFilters);
  const ordering = useSelector(getClientsTableOrdering);
  const { realTotal } = useSelector(getClientsTablePagination);
  const extra = useSelector(state => getOverlayExtra(state, 'MASS_ASSIGNMENT'));
  const dispatch = useDispatch();
  const searchType = useMemo(() => {
    if (type === MASS_ASSIGN_TYPES.EDIT_TAGS) return 'multi';
    return 'search';
  }, [type]);

  useEffect(() => {
    if (isBulkActionDataFinished(extra?.last_event) && extra?.assignmentInProgress) {
      notifier.success(
        <>
          {gettext('Bulk action Success count: {{success}}', { success: extra?.success })} 
          <br />{gettext('Bulk action Failed count: {{failed}}', { failed: extra?.failed })} 
          <br />{gettext('Bulk action Skipped count: {{skipped}}', { skipped: extra?.skipped || 0 })} 
          <br />{gettext('For more details, please check your email.')}
        </>,
      );
    }
  }, [extra?.last_event]);

  useEffect(() => {
    if (isBulkActionDataFinished(extra?.last_event) && extra?.assignmentInProgress) {
      dispatch(overlayActions.updateExtra('MASS_ASSIGNMENT', { assignmentInProgress: false }));
    }
  }, [extra]);

  const changeSuffixHandler = useCallback(event => setSuffix(event.target.value), [setSuffix]);
  const closeHandler = useCallback((withFetchTableData = true) => {
    if (withFetchTableData && fetchTableData) {
      setMode(MODES.selectedUsers);
      fetchTableData();
    }
    dispatch(overlayActions.close('MASS_ASSIGNMENT'));
  }, [dispatch, setMode, fetchTableData]);
  const changeHandler = useCallback((...value) => {
    let selectedValues = value[0] || [];
    if (searchType === 'multi') {
      selectedValues = value[0]?.map((item) => {
        const res = item.__isNew__ ? { name: item.value } : item;
        return res;
      }) || [];
    }
    dispatch(overlayActions.update('MASS_ASSIGNMENT', selectedValues));
  }, [overlayActions, searchType]);
  const changeModeHandler = useCallback((event) => {
    setMode(event.target.value);
  }, [setMode]);
  const handleChangeUsersNumber = useCallback((event) => {
    setMode(MODES.limited);
    if (+event.target.value >= 0) {
      setUsersNum(+event.target.value);
    }
  }, [setUsersNum, setMode]);

  const establishSocket = useCallback((notifyOnError = false) => {
    const s = new WebSocket(`${getSocketUrl()}/bouser-notifications/`);
    s.onmessage = (res) => {
      const stats = getBulkActionDataStats(res);
      dispatch(overlayActions.updateExtra('MASS_ASSIGNMENT', { assignmentInProgress: true, ...stats }));
    };
    s.onerror = () => {
      if (notifyOnError) notifier.error(text.error);
    };
    s.onclose = () => {};
  }, []);

  const submitHandler = useCallback(() => {
    setSubmitInProgress(true);
    const requestData = {
      update_data: createRequestUpdateData(type, data),
      rows_limit: mode === MODES.limited ? usersNum : null,
      filter_query_string: createFilterQueryString(mode, users, filters),
      processing_order: ordering,
    };
    const submitApi = SUBMIT_APIs[`${type}${suffix}`];
    submitApi.create(requestData)
      .then(() => {
        establishSocket(false, closeHandler);
        setErrors(null);
      }).catch(({ data }) => {
        setErrors(data);
      }).finally(() => {
        setSubmitInProgress(false);
      });
  }, [setSubmitInProgress, type, data, mode, users, filters, 
    suffix, dispatch, overlayActions, ordering, setErrors, establishSocket]);

  const api = useMemo(() => APIs[type], [type]);

  useEffect(() => {
    setErrors(null);
    setSuffix(type === MASS_ASSIGN_TYPES.EDIT_TAGS ? SUFFIXES.add : apiSuffix);
  }, [type]);
  
  useEffect(() => {
    setMode(users.length ? MODES.selectedUsers : MODES.limited);
  }, [visible]);

  useEffect(() => {
    establishSocket();
  }, []);

  const getUpdateDataError = useMemo(() => {
    let errorText = null;
    
    if (errors?.update_data) {
      const update_data = errors.update_data;
      if (typeof update_data === 'object' && update_data !== null) {
        errorText = '';
        Object.entries(update_data).forEach(([key, value]) => {
          switch (key) {
            case 'manager':
              errorText = text[`manager_error${type}`] ? text[`manager_error${type}`] : value; 
              break;
            default:
              errorText += `${value}, `;
          }
        });
      } else if (typeof data === 'string') {
        errorText = data;
      }
    }
    
    return errorText;
  }, [errors, type]);

  const loadOptions = useCallback(async (input) => {
    const params = {
      limit: 10,
      offset: 0,
    };
    if (type === MASS_ASSIGN_TYPES.ASSIGN_SALES_MANAGER 
      || type === MASS_ASSIGN_TYPES.ASSIGN_MANAGER) {
      params.type = 'live';
      params.username = input || '';
    } else {
      params.name = input || '';
    }

    const res = await api?.list(params);
    const options = res.data.results.map((item) => {
      if (!item.name && item.username) item.name = item.username;
      return item; 
    });
    if (type === MASS_ASSIGN_TYPES.ASSIGN_SALES_MANAGER 
      || type === MASS_ASSIGN_TYPES.ASSIGN_RETENTION_POOL_DESK 
      || type === MASS_ASSIGN_TYPES.ASSIGN_MANAGER) {
      options.unshift({ id: null, name: gettext('None') });
    }
    return options;
  }, [api, type]);

  return (
    <Sidepanel
      icon="Pen"
      type="info"
      title={text.titles[type]}
      visible={visible}
      onCloseIconClick={() => closeHandler(false)}
      footerRender={() => customFooter(
        submitHandler, 
        closeHandler, 
        submitInProgress, 
        users, 
        mode, 
        usersNum, 
        !api,
      )}
    >
      {type === MASS_ASSIGN_TYPES.EDIT_TAGS && (
      <Radio.Group
        name="suffixes"
        onChange={changeSuffixHandler}
        value={suffix}
        horizontal
      >
        <Radio value={SUFFIXES.add}>
          {text.addTags}
        </Radio>
        <Radio value={SUFFIXES.remove}>
          {text.removeTags}
        </Radio>
      </Radio.Group>
      )}
      { !!api && (
      <Select
        key={`${type}_selector`}
        placeholder={text.placeholder}
        type={searchType}
        onChange={changeHandler}
        value={data}
        async
        debounceInterval={500}
        loadOptions={loadOptions}
        valueKey="id"
        labelKey="name"
        creatable={type === MASS_ASSIGN_TYPES.EDIT_TAGS}
        hasError={!!getUpdateDataError}
        helpText={getUpdateDataError}
      />
      )}

      <Radio.Group
        name="modes"
        onChange={changeModeHandler}
        value={mode}
      >
        <Radio value={MODES.limited}>
          <div className="inline">
            {text.first}
            <Input
              id="users-number"
              onChange={handleChangeUsersNumber}
              size="small"
              type="number"
              value={usersNum}
              hasError={errors?.rows_limit}
              helpText={errors?.rows_limit}
            />
            {text.firstContinue}
          </div>
        </Radio>
        <Radio value={MODES.unlimited}>
          {`${text.allItems} (${realTotal})`}
        </Radio>
        <Radio value={MODES.selectedUsers} disabled={users.length === 0}>
          {`${text.selected} (${users.length})`}
        </Radio>
      </Radio.Group>

      <ClientsList clients={users} hideUserState />

    </Sidepanel>
  );
};

MassAssignment.propTypes = {
  users: array,
  apiSuffix: oneOf(['activetrader']),
  fetchTableData: func.isRequired,
};
MassAssignment.defaultProps = {
  users: [],
  apiSuffix: '',
};

export default MassAssignment;
