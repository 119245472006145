import React, { Component } from 'react';
import { Accordion, Toggle, Checkbox, notifier } from 'tc-biq-design-system';
import { object, bool, func, string } from 'prop-types';
import classNames from 'classnames';

import { gettext } from '../../../../../../logic/utilities/languageUtility';
import SalesStatusesHeader from './SalesStatusesHeader';

import './SalesStatuses.scss';

const text = {
  TASK: gettext('Task'),
  NOTES: gettext('Notes'),
  SUCCESS: gettext('Substatus updated successfully'),
};

const substatusActions = {
  TASKS: 'tasks',
  NOTES: 'notes',
};

const Status = ({
  name,
  notes,
  tasks,
  is_enabled,
  onStatusToggle,
  onStatusChange,
  hasPermission,
  isAutoDialer,
}) => (
  <div className="cf-accordion__status">
    { !isAutoDialer && (
    <Toggle 
      disabled={!hasPermission.updata} 
      checked={is_enabled} 
      onChange={onStatusToggle}
    />
    )}
    <span className="cf-accordion__status__title">{name}</span>
    { !isAutoDialer && (
    <div className="cf-accordion__status__checkboxes">

      <Checkbox
        disabled={!hasPermission.update}
        checked={tasks}
        onChange={() => onStatusChange(substatusActions.TASKS)}
      >
        {text.TASK}
      </Checkbox>
      <Checkbox
        disabled={!hasPermission.update} 
        checked={notes}
        onChange={() => onStatusChange(substatusActions.NOTES)}
      >
        {text.NOTES}
      </Checkbox>
    </div>
    )}
  </div>
);

Status.propTypes = {
  name: string.isRequired,
  notes: bool.isRequired,
  tasks: bool.isRequired,
  is_enabled: bool.isRequired,
  onStatusChange: func.isRequired,
  onStatusToggle: func.isRequired,
  hasPermission: object.isRequired,
  isAutoDialer: bool.isRequired,
};

const propTypes = {
  flow: object.isRequired,
  actions: object.isRequired,
  api: object.isRequired,
  hasPermission: object.isRequired,
  active: string.isRequired,
};

class SalesStatuses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  onAccordionToggle = () => this.setState(({ visible }) => ({ visible: !visible }))

  onStatusToggle = (substatus) => {
    this.onStatusChange(substatus)('is_enabled');
  }

  onStatusChange = substatus => (substatusAction) => {
    const { actions, flow, api } = this.props;
    actions.updateSubstatus(api, flow.id, substatus.id, { [substatusAction]: !substatus[substatusAction] })
      .then(() => notifier.success(text.SUCCESS));
  }

  render() {
    const {
      flow,
      api,
      active,
      hasPermission,
    } = this.props;
    const {
      name,
      substatuses,
    } = flow;    
    const { visible } = this.state;

    const disabled = substatuses.length === 0;
    const isAutoDialer = active === 'auto-dialer';
    
    return (
      <div className={classNames('cf-accordion', { 'cf-accordion--disabled': disabled })}>
        <Accordion 
          visible={visible}
          title={name}
          headerTemplate={(
            <SalesStatusesHeader
              flow={flow}
              api={api}
              isAutoDialer={isAutoDialer}
              hasPermission={hasPermission}
            />
          )}
          onClick={disabled ? null : this.onAccordionToggle}
        >
          {substatuses.map(status => (
            <Status
              key={status.id}
              isAutoDialer={isAutoDialer}
              hasPermission={hasPermission}
              onStatusToggle={() => this.onStatusToggle(status)}
              onStatusChange={this.onStatusChange(status)}
              {...status}
            />
          ))}
        </Accordion>
      </div>
    );
  }
}

SalesStatuses.propTypes = propTypes;

export default SalesStatuses;
