/* eslint react/prop-types: 0 */
import React from 'react';
import appRoutes from '../../../../../../components/App/Router/appRoutes';
import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import { RouteCell } from '../../../../../../components/gridCellRenderers';
import hideCols from '../../../../../../logic/utilities/hideCols';


const modifier = {
  ...hideCols([
    'id',
  ]),
  username: {
    cellRendererFramework: ({ data, ...rest }) => RouteCell({ 
      href: appRoutes.CLIENT_PROFILE, 
      stateParams: { id: data.id } })({ data, ...rest }),
  },
  link: {
    cellRendererFramework: ({ value }) => <div>{value.name}</div>,
  },
};

const tableConfig = id => ({
  apiUrl: `ib/${id}/clients/`,
  reducerKey: 'USER_SINGLE',
  tableKey: 'IB_CLIENTS',
});

const IbClients = ({ id }) => {
  const Table = withTable(GridTab, tableConfig, null, { hideSegments: true })(id);

  return <Table modifier={modifier} />;
};

export default IbClients;
