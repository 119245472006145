import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { Tabs, Tab, Button } from 'tc-biq-design-system';
import { string, object, func, shape } from 'prop-types';
import { withRouter, generatePath } from 'react-router-dom';

import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../logic/services/acl';
import { BiqUserPreview, TransactionSingle, RejectWithdrawal } from '../../../../../../components/common/sidepanels';
import overlayActions from '../../../../../../components/overlay';
import connect from '../../../../../../logic/connect';
import { historyModifier, pendingModifier } from './modifier';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import WithdrawalRequest, { getWithdrawalDetails } from '../../../../../../components/common/sidepanels/WithdrawalRequest';
import { withdrawalHistoryConfig, pendingWithdrawalsConfig, getTableActions } from './table';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

const CONFIG = window.config;
const SIDEPANEL_ID = 'WITHDRAWAL_REQUEST';

const withdrawalHistoryApi = getDjangoApi('withdrawal_history');
const withdrawalPendingApi = getDjangoApi('pending_withdrawals');
const transactionApi = getDjangoApi('payment_transactions');

export const acls = {
  pending: 'user.pending_withdrawals.list',
  history: 'user.withdrawal_history.list',
};

const exportAcls = {
  [pendingWithdrawalsConfig().tableKey]: 'user.pending_withdrawals.export.*',
  [withdrawalHistoryConfig().tableKey]: 'user.withdrawal_history.export.*',
};

const text = {
  REQUESTS: gettext('REQUESTS'),
  HISTORY: gettext('HISTORY'),
  EXPORT_ALL: gettext('Export All'),
};

const propTypes = {
  id: string.isRequired,
  actions: object.isRequired,
  dispatch: func.isRequired,
  user: object.isRequired,
  tables: object.isRequired,
  history: shape({
    push: func,
  }).isRequired,
};


class Withdrawals extends Component {
  constructor(props) {
    super(props);
    
    this.Requests = withTable(GridTab, pendingWithdrawalsConfig, this.tableAction(pendingWithdrawalsConfig().tableKey, { hideSegments: true }))(props.id);
    this.History = withTable(GridTab, withdrawalHistoryConfig, this.tableAction(withdrawalHistoryConfig().tableKey), { hideSegments: true })(props.id);

    this.tableActions = {
      openHistoryWithdrawalRequest: this.openWithdrawalRequest().bind(this),
      openHistoryWithdrawalRelatedTransaction: this.openHistoryWithdrawalRelatedTransaction.bind(this),
      openPendingWithdrawalRequest: this.openWithdrawalRequest(true).bind(this),
      previewBiqUser: props.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
      ...(hasAccess('withdrawals.requests.execute.*')
        && { executePendingWithdrawal: this.executePendingWithdrawal.bind(this) }),
      ...(hasAccess('withdrawals.requests.reject.*')
        && { rejectPendingWithdrawal: props.actions.openSidepanel.bind(this, 'REJECT_WITHDRAWAL') }),
    };
  }

  tableAction = key => () => {
    const { tables } = this.props;
    const table = tables[key];
    const all = _.get(table, 'exportUrl.all');
    if (!hasAccess(exportAcls[key])) return null; 
    return <a href={all}><Button>{text.EXPORT_ALL}</Button></a>;
  }

  openWithdrawalRequest = isPending => async (data) => {
    const { actions, history } = this.props;
    if (isPending && CONFIG.environment === 'AvaTrade') {
      history.push(appRoutes.PENDING_WITHDRAWAL, { id: data.id, tab: 'withdrawal-details' });
    } else {
      const response = await (isPending ? withdrawalPendingApi : withdrawalHistoryApi).retrieve(data.id);
      let sidepanelData = response.data;
      if (sidepanelData.payment_gateway_account) {
        const { method, id } = sidepanelData.payment_gateway_account;
        const detailsResponse = await getWithdrawalDetails(method).retrieve(id);
        sidepanelData = { ...sidepanelData, preferredMethods: detailsResponse.data };
      }
      actions.openSidepanel(SIDEPANEL_ID, sidepanelData);
    }
  }

  openHistoryWithdrawalRelatedTransaction = (data) => {
    this.openSingleTransaction(data.id);
  }
 
  openSingleTransaction = async (id) => {
    const { actions } = this.props;
    const response = await transactionApi.retrieve(id);
    actions.openSidepanel('TRANSACTION_SINGLE', response.data);
  }

  refreshTable = () => {
    const { dispatch, user } = this.props;
    const actions = getTableActions(pendingWithdrawalsConfig, user.id);
    dispatch(actions.fetchTableData());
  }

  executePendingWithdrawal(data) {
    const { history } = this.props;
    history.push(generatePath(appRoutes.PENDING_WITHDRAWAL, { id: data.id, tab: 'execute-withdrawal' }));
  }

  render() {
    const { Requests, History } = this;

    return (
      <Fragment>
        <Tabs>
          <Tab visible={hasAccess(acls.pending)} title={text.REQUESTS}>
            <Requests singleActions modifier={pendingModifier(this.tableActions)} />
          </Tab>
          <Tab visible={hasAccess(acls.history)} title={text.HISTORY}>
            <History modifier={historyModifier(this.tableActions)} />
          </Tab>
        </Tabs>
        <WithdrawalRequest openTransactionSingle={this.openSingleTransaction} />
        <TransactionSingle />
        <RejectWithdrawal onSuccess={this.refreshTable} />
        <BiqUserPreview />
      </Fragment>
    );
  }
}

Withdrawals.propTypes = propTypes;

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  return {
    user: page.userDetail.user,
    tables: page.tables,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { 
      openSidepanel: overlayActions.open, 
    }, 
    dispatch,
  ),
  dispatch,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Withdrawals),
);
