/* eslint-disable react/prop-types */
import React from 'react';
import { Tooltip, Icon, Popconfirm } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import { gettext } from '../../../../logic/utilities/languageUtility';
import { UsernameCell } from '../../../../components/gridCellRenderers';
import ActionsCellWrapper from '../../../../components/gridCellRenderers/SingleActionCell';
import hideCols from '../../../../logic/utilities/hideCols';
import appRoutes from '../../../../components/App/Router/appRoutes';
import queryOperators from '../../../../logic/enums/query-operators';

const SYSTEM_USER = 'system';

const text = {
  MODIFY_ICON_TOOLTIP: gettext('Modify'),
  CANCEL: gettext('Cancel'),
  DELETE: gettext('Delete'),
  ARE_YOU_SURE: gettext('You are about to delete group'),
};

const TeamMembersCell = ({ data }) => {
  const usernames = data.team_members.map(v => v.username);
  return <span>{usernames.join(',')}</span>;
};

const actionIcons = (modify, remove) => ({
  ...(modify && {
    modify: (_, { id }) => {
      const url = generatePath(appRoutes.CLIENT_VISIBILITY_GROUP, { id });
      return (
        <div key="Modify">
          <Link to={url}>
            <Tooltip title={text.MODIFY_ICON_TOOLTIP} placement="top">
              <Icon name="Edit" />
            </Tooltip>
          </Link>
        </div>
      );
    },
  }),
  ...(remove && {
    remove: (onRemove, data) => {
      const label = `${text.ARE_YOU_SURE} ${data.name}`;
      return onRemove ? (
        <Tooltip key="remove" title={text.DELETE}>
          <Popconfirm
            type="destructive"
            icon="Delete"
            label={label}
            onConfirm={() => onRemove(data)}
            buttonLabels={{
              cancel: text.CANCEL,
              confirm: text.DELETE,
            }}
          >
            <Icon name="Delete" />
          </Popconfirm>
        </Tooltip>
      ) : <span />;
    },
  }),
});

const isNotSystemUser = data => _.get(data, 'created_by.username') !== SYSTEM_USER;

const ActionsCellRenderer = (remove, modify) => ({ data }) => (isNotSystemUser(data) ? ActionsCellWrapper({
  modify: null,
  remove,
}, actionIcons(modify, remove))({ data }) : <span />);

export default ({ modify, remove, previewBiqUser }) => ({
  ...hideCols([
    'condition',
  ]),
  name: {
    quickFilter: {
      operator: queryOperators.EXACT.value,
    },
  },
  created_by: {
    quickFilter: {
      operator: queryOperators.EXACT.value,
    },
    cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
  },
  team_members: {
    cellRendererFramework: TeamMembersCell,
  },
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellRenderer(remove, modify),
    pinned: 'right',
    width: 120,
  },
});
