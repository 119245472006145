import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';


export const settingsApi = getDjangoApi('settings/email');
const initialState = {
  data: [],
  isLoading: false,
};

const actionTypes = {
  GET_DATA: 'GET_DATA',
  GET_DATA_PENDING: 'GET_DATA_PENDING',
  GET_DATA_FULFILLED: 'GET_DATA_FULFILLED',
};

const getData = createAction(actionTypes.GET_DATA, async () => {
  const response = await settingsApi.list();
  return { data: response.data.options };
});

export default {
  getData,
};

export const reducer = handleActions({
  [actionTypes.GET_DATA_PENDING]: state => ({ ...state, isLoading: true }),
  [actionTypes.GET_DATA_FULFILLED]: (state, { payload }) => {
    const { data } = payload;
    return {
      isLoading: false, 
      data,
    };
  },
}, initialState);
