import React, { Fragment } from 'react';
import { Space } from 'tc-biq-design-system';

import BalanceForm from './BalanceForm';
import CommissionTable from './CommissionTable';

import './CommissionSettings.scss';

const CommissionSettings = () => (
  <Fragment>
    <Space size={10} />
    <BalanceForm />
    <CommissionTable />
  </Fragment>
);

export default CommissionSettings;
