import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import overlayActions from '../../../../../components/overlay';
import CampaignHeader from './CampaignHeader';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(CampaignHeader);
