import React, { Component, Fragment } from 'react';
import { Sidepanel, Panel, Space, HyperLink } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { object, func, bool } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import connect from '../../../logic/connect';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import overlayActions from '../../overlay';
import withdrawalStepStateLabel from '../../../logic/filters/withdrawal-step-state-label';
import PAYMENT_METHOD_TYPES from '../../../logic/enums/payment-method-types';
import { gettext } from '../../../logic/utilities/languageUtility';
import isFeatureEnabled from '../../../logic/filters/is-feature-enabled';
import { OnContentClickCell, StatusCell } from '../../gridCellRenderers';
import Extra from '../../Extra';
import ExtraData from '../../../pages/home/withdrawal-single/Components/ExtraData';
import UserInfo from '../../UserInfo';
import PanelTable from '../../PanelTable';
import { getDjangoApi } from '../../../logic/services/api-factory';
import If from '../../If';
import { hasAccess } from '../../../logic/services/acl';
import withUnmountMethod from '../../hoc/withUnmountMethod';
import GridFactory from '../../grid';
import WalletTransactions from '../../../pages/home/withdrawal-single/Components/WalletTransactions';
import { currencyFormatter, dateTimeFormatter } from '../../../logic/utilities/formatters';
import { getPaymentGatewayMethodUrlId } from '../../../pages/home/withdrawal-single/Components/ExecuteWithdrawal/logic/utils';
import { tradingAccountDisplay } from '../../../logic/filters/trading-account-display';
import appRoutes from '../../App/Router/appRoutes';
import GridWrapper from '../../grid/GridWrapper';
import TradingAccountLink from '../TradingAccountLink';
import { isAvaTrade } from '../../../utils/environment';

export const getWithdrawalDetails = (gatewayMethod) => {
  const method = getPaymentGatewayMethodUrlId(gatewayMethod);
  return getDjangoApi(`payment_gateway_accounts/${method}`, 'payment_gateway_accounts');
};

export const isMethodVisible = (method = '') => hasAccess(`pga.${method.toLowerCase()}.accounts.retrieve`) || isAvaTrade();

const isPayPall = data => data.method === 'PayPal';
const isEWire = data => data.method_type === PAYMENT_METHOD_TYPES.E_WIRE;

const env = window.config.environment;

const modifier = ({ openSingleTransaction } = {}) => ({
  id: {
    cellRendererFramework: OnContentClickCell(openSingleTransaction),
  },
  wallet: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => <div>{`${data.wallet.display_external_id} ${data.wallet.backend.name}`}</div>,
  },
});

const propTypes = {
  sidepanel: object.isRequired,
  actions: object.isRequired,
  openTransactionSingle: func.isRequired,
  openTradingAccountSidepanel: func,
  isIb: bool,
  dispatch: func.isRequired,
};

const defaultProps = {
  isIb: false,
  openTradingAccountSidepanel: () => null,
};

const SIDEPANEL_ID = 'WITHDRAWAL_REQUEST';

const text = {
  TITLE: gettext('Withdrawal request'),
  PANEL_TITLE_TRANSACTION: gettext('Payment transaction'),
  PANEL_TITLE_DETAILS: gettext('Withdrawal details'),
  PANEL_TITLE_PREFERRED_METHOD: gettext('Withdrawal method details'),
  RELATED_TRANSACTION: gettext('Related payment transaction'),
  EXECUTED_BY: gettext('Executed by'),
  REJECTION_REASON: gettext('Rejection reason'),
  SECONDAY_EXTERNAL_ID: gettext('Secondary external ID'),
  METHOD: gettext('Method'),
  CURRENCY: gettext('Currency'),
  GATEWAY: gettext('Gateway'),
  DATE: gettext('Date'),
  AMOUNT: gettext('Amount'),
  BRAND: gettext('Brand'),
  PHONE: gettext('Phone number'),
  CARD_NUMBER: gettext('1234'),
  EXPIRY_DATE: gettext('Expiry date'),
  FUNDING: gettext('Funding'),
  CARDHOLDER_NAME: gettext('Cardholder name'),
  ACCOUNT_NUMBER: gettext('Account number'),
  ACCOUNT_HOLDER_NAME: gettext('Account holder name'),
  BANK_NAME: gettext('Bank name'),
  BANK_CITY: gettext('Bank city'),
  BANK_COUNTRY: gettext('Bank country'),
  NAME_MATCHED: gettext('Name matched'),
  BANK_ADDRESS: gettext('Bank address'),
  LOCAL_CURRENCY: gettext('Local currency'),
  EXCHAGE_RATE: gettext('Exchange rate'),
  IBAN: gettext('IBAN'),
  SWIFT_CODE: gettext('Swift code'),
  BANK_CODE: gettext('Bank code'),
  SORT_CODE: gettext('Sort code'),
  FIRST_NAME: gettext('First name'),
  LAST_NAME: gettext('Last name'),
  EMAIL: gettext('Email'),
  WITHDRAWAL_FLOW: gettext('Withdrawal flow'),
  STEP_NAME: gettext('Step name'),
  ACTIONED_BY: gettext('Actioned by'),
  RESULT: gettext('Result'),
  DATE_TIMESTAPM: gettext('Date timestamp'),
  RELATED_PAYMENT_TRANSACTIONS: gettext('Related payment transactions'),
  EXECUTED_AMOUNT: gettext('Executed amount'),
  META: gettext('Meta'),
  ORGANIZATION: gettext('Organization'),
  PRIMARY_EXTERNAL_ID: gettext('Bank account number'),
  SECONDARY_EXTERNAL_ID: gettext('Card last digits'),
  SECONDARY_EXTERNAL_ID_PAY_PALL: gettext('Secondary external ID (payer_id)'),
  EXTERNAL_ID: gettext('Gateway ID'),
  TRADING_ACCOUNT: gettext('Trading account'),
  NA: gettext('N/A'),
};

const withdrawalDetailsFields = [
  'local_name',
  'chinese_full_name',
  'payment_provider',
  'bank_name',
  'iban_number',
  'bank_account_number',
  'bank_address',
  'local_currency',
  'exchange_rate',
  'external_id',
  'rejection_reason',
];

const renderRelatedPayment = (transactions, openTransactionSingle) => {
  if (isEmpty(transactions)) return null;

  return (
    <div className="like-a-link__group">
      {
        transactions.map((t, i) => (
          <div className="like-a-link" onClick={() => openTransactionSingle(t)}>{`${t} ${i < transactions.length - 1 ? ', ' : ''}`}</div>
        ))
      }
    </div>
  );
};

const formatPaymentTransactionPanelData = (data, openTransactionSingle, openTradingAccountSidepanel) => [
  {
    label: text.RELATED_TRANSACTION,
    value: renderRelatedPayment(data.related_payment_transaction, openTransactionSingle),
  },
  { label: text.EXECUTED_BY, 
    value: data.executed_by ? (
      <HyperLink>
        <Link to={generatePath(appRoutes.TEAM_MEMBER, { id: data.executed_by.id })}>{data.executed_by.username}</Link>
      </HyperLink>
    ) : null },
  { label: text.METHOD, value: data.method },
  { label: text.CURRENCY, value: data.currency.symbol },
  { label: text.DATE, value: dateTimeFormatter(data.date) },
  { label: text.AMOUNT, value: currencyFormatter(data.amount, data.currency.symbol) },
  { label: text.EXECUTED_AMOUNT, value: currencyFormatter(data.executed_amount, data.currency.symbol), hide: env !== 'AvaTrade' },
  { label: text.REJECTION_REASON, value: data.rejection_reason },
  { label: text.EXTERNAL_ID, value: data.external_id || text.NA, hide: env !== 'AvaTrade' },
  { label: text.TRADING_ACCOUNT, value: TradingAccountLink({ displayName: tradingAccountDisplay(data.wallet), id: get(data, 'wallet.id'), userId: get(data, 'user.id'), openTradingAccountSidepanel }) },
  { label: text.SECONDARY_EXTERNAL_ID_PAY_PALL, value: _.get(data, 'payment_gateway_account.secondary_external_id'), hide: !(isPayPall(data) && env === 'ICMCapital') },
];


const bankData = data => [
  { label: text.METHOD, value: data.method },
  { label: text.ACCOUNT_NUMBER, value: data.account_number },
  { label: text.ACCOUNT_HOLDER_NAME, value: data.account_holder_name },
  { label: text.BANK_NAME, value: data.bank_name },
  { label: text.BANK_COUNTRY, value: data.bank_country },
  { label: text.BANK_CITY, value: data.bank_city },
  { label: text.BANK_ADDRESS, value: data.bank_address },
  { label: text.IBAN, value: data.iban },
  { label: text.SWIFT_CODE, value: data.swift_code },
  { label: text.SORT_CODE, value: data.sort_code },
  { label: text.ORGANIZATION, value: _.get(data, 'payment_gateway_account.organization'), hide: !isEWire(data) },
  { label: text.PRIMARY_EXTERNAL_ID, value: _.get(data, 'payment_gateway_account.primary_external_id'), hide: !isEWire(data) },
  { label: text.SECONDARY_EXTERNAL_ID, value: _.get(data, 'payment_gateway_account.secondary_external_id'), hide: !isEWire(data) },
];

const cardData = data => [
  { label: text.BRAND, value: data.brand },
  { label: text.CARD_NUMBER, value: data.card_number },
  { label: text.EXPIRY_DATE, value: data.expiry },
  { label: text.FUNDING, value: data.funding },
  { label: text.CARDHOLDER_NAME, value: data.cardholder_name },
];

const walletData = data => [
  { label: text.EMAIL, value: data.email },
  { label: text.FIRST_NAME, value: data.first_name },
  { label: text.LAST_NAME, value: data.last_name },
];

const mpesaData = data => [
  { label: text.BANK_NAME, value: data.account_holdername },
  { label: text.ACCOUNT_NUMBER, value: data.primary_external_id },
  { label: text.BANK_CODE, value: data.addr_line_3 },
  { label: text.ACCOUNT_HOLDER_NAME, value: data.account_holdername },
  { label: text.EMAIL, value: data.email_alt },
  { label: text.PHONE, value: data.addr_line_2 },
];

const formatPreferredWithdrawalMethodPanelData = (data, withdrawal) => {
  if (data.method === 'Bank') return bankData(data);
  if (data.method === 'Credit Card') return cardData(data);
  if (withdrawal.method === 'M-PESA' || withdrawal.method === 'MPESA') return mpesaData(data);
  if (data.method === 'eWallet' || data.method === 'Skrill' || data.method === 'Neteller') return walletData(data);
  if (withdrawal?.method === 'Bank' || withdrawal?.method_type === 'Wire transfer') return bankData(data);

  return [];
};

const formatWithdrawalDetailsPanelData = (data, openTransactionSingle) => [
  {
    label: text.NAME_MATCHED,
    value: data.name_matched
      ? renderRelatedPayment(data.related_payment_transaction, openTransactionSingle)
      : null,
  },
  { label: text.BANK_NAME, value: data.bank_name },
  { label: text.IBAN, value: data.iban },
  { label: text.BANK_ADDRESS, value: data.bank_address },
  { label: text.LOCAL_CURRENCY, value: data.local_currency ? data.local_currency.name : null },
  { label: text.EXCHAGE_RATE, value: data.exchange_rate },
];

const approvingDetailsCols = [
  { title: text.STEP_NAME, key: 'step_name' },
  { 
    title: text.ACTIONED_BY, 
    key: 'approved_by', 
    /* eslint-disable react/prop-types */
    render: ({ approved_by }) => (approved_by ? (
      <HyperLink>
        <Link to={generatePath(appRoutes.TEAM_MEMBER, { id: approved_by.id })}>{approved_by.username}</Link> 
      </HyperLink>
    ) : ''),
    /* eslint-enable react/prop-types */
  },
  { 
    title: text.RESULT, 
    key: 'status',
    render: ({ status }) => (status ? withdrawalStepStateLabel()(status) : '') },
  { 
    title: text.DATE_TIMESTAPM, 
    key: 'timestamp_update',
    render: ({ timestamp_update }) => (timestamp_update ? moment(timestamp_update).format('YYYY-MM-DD HH:mm:ss') : ''),
  },
];

const PanelBody = ({ tradeData, formatter, openTransactionSingle, openTradingAccountSidepanel }) => {// eslint-disable-line
  const bodyData = formatter(tradeData, openTransactionSingle, openTradingAccountSidepanel) || [];
  return (
    <div className="card-panel-body">
      {bodyData
        .filter(line => !!line.value && !line.hide)
        .map((line, index) => (
          <div className="panel-body-line" key={line.value + index}>
            <span>{line.label}</span>
            <span>{line.value}</span>
          </div>
        ))}
    </div>
  );
};

// eslint-disable-next-line
const PanelWrapper = ({ title, status, tradeData, formatter, openTransactionSingle, children, openTradingAccountSidepanel }) => (
  <Panel title={title} status={status}>
    {children || (
    <PanelBody
      tradeData={tradeData}
      formatter={formatter}
      openTransactionSingle={openTransactionSingle}
      openTradingAccountSidepanel={openTradingAccountSidepanel}
    />
    )}
  </Panel>
);

const initialState = {
  approvingDetails: null,
  GridComponent: null,
};

const relatedPaymentesConfig = id => ({
  apiUrl: `withdrawal_history/${id}/related_transactions`,
  tableKey: 'RELATED_PAYMENTS',
  reducerKey: 'RELATED_PAYMENTS',
});

class WithdrawalRequest extends Component {
  constructor(props) {
    super(props);
    this.paymentTransactionsApi = getDjangoApi('payment_transactions');
    this.approvingDetailsApi = withdrawal => getDjangoApi(`withdrawal_history/${withdrawal.id}/approving_details/`);
    this.state = initialState;

    this.tableActions = {
      openSingleTransaction: this.openSingleTransaction.bind(this),
    };
  }

  componentDidUpdate(prevProp) {
    const prevParams = _.get(prevProp, 'sidepanel.parameters');
    const params = _.get(this.props, 'sidepanel.parameters');
    const { dispatch } = this.props;

    if (!_.isEqual(params, prevParams) && !_.isEmpty(params) && env === 'AvaTrade') {
      const { GridComponent, actions } = GridFactory(relatedPaymentesConfig(params.id));

      // eslint-disable-next-line
      this.setState({ GridComponent });
      dispatch(actions.fetchTableData());
      if (env === 'AvaTrade' && hasAccess('withdrawals.requests.retrieve')) this.getApprovingDetails(params);
    }
  }

  onClose = () => {
    const { actions } = this.props;
    this.setState(initialState);
    actions.closeSidepanel(SIDEPANEL_ID);
  }

  getApprovingDetails = async (withdrawal) => {
    const { data } = await this.approvingDetailsApi(withdrawal).list();
    this.setState({ approvingDetails: data.results });
  }

  getRawData(data) {
    return _.isEmpty(data.meta) ? data.extra : data.meta;
  }

  openSingleTransaction = async ({ id }) => {
    const { actions } = this.props;
    const response = await this.paymentTransactionsApi.retrieve(id);
    actions.openSidepanel('TRANSACTION_SINGLE', response.data);
  }

  render() {
    const { sidepanel, openTransactionSingle, isIb, openTradingAccountSidepanel } = this.props;
    if (!sidepanel.parameters) return null;
    const { GridComponent } = this.state;
    const { approvingDetails } = this.state;
    const data = { ...sidepanel.parameters };
    const { wallet_transactions } = data;
    const status = () => <StatusCell key={data.status} value={data.status} />;
    const showWithdrawalDetails = withdrawalDetailsFields.some(field => !!data[field]);
    const metaData = this.getRawData(data);
    return (
      <Sidepanel
        size="large"
        visible={sidepanel.visible}
        title={`${text.TITLE} ${data.id}`}
        onCloseIconClick={this.onClose}
        hideFooter
      >
        <UserInfo user={data.user} isIb={isIb} />
        <PanelWrapper
          title={text.PANEL_TITLE_TRANSACTION}
          status={status}
          tradeData={data}
          formatter={formatPaymentTransactionPanelData}
          openTransactionSingle={openTransactionSingle}
          openTradingAccountSidepanel={openTradingAccountSidepanel}
        />
        {approvingDetails && (
          <Fragment>
            <PanelTable
              title={text.WITHDRAWAL_FLOW} 
              cols={approvingDetailsCols} 
              data={approvingDetails} 
            />
            <Space size={16} />
          </Fragment>
        )}
        <Extra title={text.META} rawData={data.meta} />
        {metaData && <Extra rawData={metaData} />}
        {isFeatureEnabled()('TRANSACTION_EXTRA_DATA') && <ExtraData logs={data.logs} />}
        <If condition={showWithdrawalDetails}>
          <PanelWrapper
            tradeData={data}
            title={text.PANEL_TITLE_DETAILS}
            formatter={formatWithdrawalDetailsPanelData}
            openTransactionSingle={openTransactionSingle}
            openTradingAccountSidepanel={openTradingAccountSidepanel}
          />
        </If>
        <If condition={hasAccess('withdrawals.requests.retrieve') && data.preferredMethods}>
          <PanelWrapper
            tradeData={data.preferredMethods}
            title={text.PANEL_TITLE_PREFERRED_METHOD}
            formatter={preferredMethods => formatPreferredWithdrawalMethodPanelData(preferredMethods, data)}
          />
        </If>
        <If condition={env === 'AvaTrade'}>
          <PanelWrapper title={text.RELATED_PAYMENT_TRANSACTIONS}>
            { !!GridComponent && (
              <GridWrapper>
                <GridComponent 
                  disableQuickFilter
                  tableModifier={modifier(this.tableActions)} 
                  hasManageOption={false}
                /> 
              </GridWrapper>
            )}
          </PanelWrapper>
        </If>
        <Space size={16} />
        <If condition={env === 'AvaTrade' && !isEmpty(wallet_transactions)}>
          <WalletTransactions transactions={wallet_transactions} />
        </If>
      </Sidepanel>
    );
  }
}

const mapStateToProps = ({ overlays }) => ({
  sidepanel: overlays.WITHDRAWAL_REQUEST,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
  dispatch,
});

WithdrawalRequest.propTypes = propTypes;
WithdrawalRequest.defaultProps = defaultProps;

export default withUnmountMethod(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorBoundary(WithdrawalRequest)),
);
