import React from 'react';
import { Button } from 'tc-biq-design-system';
import { func, bool } from 'prop-types';
import { gettext } from '../../../../../logic/utilities/languageUtility';

const text = {
  DISCARD: gettext('Discard'),
  SAVE: gettext('Save changes'),
};

const SidepanelFooter = ({ 
  onCancel,
  onSave,
  submitInProgess }) => (
    <React.Fragment>
      <Button 
        onClick={onCancel}
        color="ghost"
      >
        { text.DISCARD }
      </Button>
      <Button 
        onClick={onSave}
        color="primary"
        disable={submitInProgess}
        loading={submitInProgess}
      > {text.SAVE } 
      </Button> 
    </React.Fragment>
);
  
SidepanelFooter.propTypes = {
  onCancel: func.isRequired,
  onSave: func.isRequired,
  submitInProgess: bool.isRequired,
};

export default SidepanelFooter;
