import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import DepositMethodRule from './DepositMethodRule';
import connect from '../../../../../logic/connect';
import { depositMethodRuleActions } from './Model';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const mapStateToProps = ({ pages }) => {
  const page = pages.DEPOSIT_METHOD_RULE_SINGLE;
  return { ...page };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...depositMethodRuleActions }, dispatch),
});

const DepositMethodRulePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(DepositMethodRule); 

export default {
  component: DepositMethodRulePage,
  aclId: 'settings.manage_deposit_methods.update',
  path: appRoutes.SETTINGS_DEPOSIT_METHODS_RULES,
};

export const DepositMethodRuleCreate = {
  component: DepositMethodRulePage,
  aclId: 'settings.manage_deposit_methods.create',
  path: appRoutes.SETTINGS_DEPOSIT_METHODS_RULES_CREATE,
};
