import React, { Component } from 'react';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { bool, object, func, array } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import FormFactory, { ErrorHandler, FormActionsFactory } from '../../../../../components/form';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import to from '../../../../../logic/utilities/to';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import overlayActions from '../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../components/common';

const propTypes = {
  onSuccess: func,
  sidepanel: object,
  fields: array.isRequired,
  actions: object.isRequired,
  recordData: object.isRequired,
  submitInProgress: bool,
};

const defaultProps = {
  onSuccess: null,
  sidepanel: null,
  submitInProgress: false,
};

const text = {
  TITLE: gettext('Edit Communication Record'),
  BUTTON_LABEL: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
  SUCCESS_TEXT: gettext('Communication record successfully updated'),
  ERROR_TEXT: gettext('Failed to edit communication record'),
};

const FORM_KEYS = channel => ({
  to_email: channel === 'messages' ? 'email' : 'phone_number',
  subject: 'subject',
  body_plain: channel === 'messages' ? 'body' : 'text',
  description: 'description',
  date: 'date',
  ...channel === 'calls' && { start_time: 'start_time' },
  ...channel === 'calls' && { end_time: 'end_time' },
  ...channel === 'calls' && { destination_phone_num: 'phone_number' },
  ...channel !== 'calls' && { communication_status: 'communication_status' },
  source: 'source',
  status: 'status',
});

const formModifiers = {
  body: { type: 'textarea' },
  description: { type: 'textarea' },
  date: { type: 'datetime' },
};

const formatPayload = (values) => {
  const payload = { ...values };
  if (payload.date) payload.date = moment(payload.date).toISOString();
  if (payload.source) payload.source = payload.source.value;
  if (payload.status) payload.status = payload.status.value;
  if (payload.communication_status) payload.communication_status = payload.communication_status.value;
  if (payload.type) payload.type = payload.type.value;
  return payload;
};

const SIDEPANEL_ID = 'EDIT_LOGS_RECORD_FORM';

const customFooter = (execute, onClose, submitInProgress) => (
  <SidepanelFooter
    execute={execute}
    close={onClose}
    submitInProgress={submitInProgress}
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABEL}
  />
);

const formConfig = api => ({
  form: SIDEPANEL_ID,
  api,
  excludeFields: [],
});

const { setFieldsErrors, update, resetFields, setFieldValue } = FormActionsFactory(SIDEPANEL_ID);

class EditLogsMessage extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.api = null;
  }

  componentDidUpdate() {
    const { recordData, sidepanel, fields } = this.props;
    if (!_.isEmpty(recordData) && sidepanel.visible && fields.length !== 0) {
      this.initiateFormValues();
    }
  }

  onClose = (submitSuccess) => {
    const { onSuccess } = this.props;
    this.actions.resetFields();
    this.actions.close(SIDEPANEL_ID);
    if (submitSuccess) onSuccess();
  };

  initiateFormValues = () => {
    const { recordData, sidepanel, fields } = this.props;
    const formKeys = FORM_KEYS(sidepanel.parameters.channel);
    Object.keys(recordData)
      .filter(key => Object.keys(formKeys).includes(key))
      .forEach((key) => {
        const formField = _.find(fields, { id: formKeys[key] });
        let value;
        if (formField && formField.type === 'select' && formField.options) {
          value = _.find(formField.options, { display_name: recordData[key] });
        } else {
          value = this.isDateField(key) ? moment(new Date(recordData[key])).format('YYYY-MM-DD') : recordData[key];
        }
        this.actions.setFieldValue({ id: formKeys[key], value });
      });
  };

  execute = async () => {
    const { fields } = this.props;
    const [err] = await to(this.actions.update(this.api, null, formatPayload));

    if (err) {
      new ErrorHandler({ errors: err.data, fields }).showMessages(notifier.error);
    } else {
      notifier.success(text.SUCCESS_TEXT);
      this.onClose(true);
      this.actions.resetFields();
    }
  };

  isDateField(key) {
    return key === 'date' || key === 'start_date' || key === 'end_date';
  }

  renderForm = () => {
    const { sidepanel } = this.props;
    if (!sidepanel.parameters) return null;
    const { userId, recordId, channel } = sidepanel.parameters;
    this.api = getDjangoApi('users')
      .one(userId)
      .all(channel)
      .one(recordId);
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig(this.api));
    }
    const { FormComponent } = this;
    return <FormComponent modifiers={formModifiers} />;
  };

  render() {
    const { sidepanel, submitInProgress } = this.props;
    return (
      <Sidepanel
        icon="Pen"
        title={text.TITLE}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.execute, this.onClose, submitInProgress)}
      >
        {this.renderForm()}
      </Sidepanel>
    );
  }
}

EditLogsMessage.propTypes = propTypes;
EditLogsMessage.defaultProps = defaultProps;

const mapStateToProps = ({ forms, overlays, pages }) => {
  const { fields, submitInProgress } = forms[SIDEPANEL_ID];
  const sidepanel = overlays[SIDEPANEL_ID];
  const { data } = pages.LOGS_MESSAGE;
  return {
    fields,
    submitInProgress,
    sidepanel,
    recordData: data,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setFieldsErrors,
      setFieldValue,
      update,
      resetFields,
      close: overlayActions.close,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(EditLogsMessage));
