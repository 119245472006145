import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import GridActionsFactory from '../../../../../../components/grid/GridActionsFactory';

const REDUCER_KEY = 'USER_SINGLE';

export const pendingWithdrawalsConfig = id => ({
  reducerKey: REDUCER_KEY,
  apiUrl: `users/${id}/pending_withdrawals`,
  tableKey: 'PENDING_WITHDRAWALS',
});

export const withdrawalHistoryConfig = id => ({
  reducerKey: REDUCER_KEY,
  apiUrl: `users/${id}/withdrawal_history`,
  tableKey: 'WITHDRAWAL_HISTORY',
});

export const getTableActions = (tableConfig, id) => {
  const config = tableConfig(id);
  const api = getDjangoApi(config.apiUrl);
  return GridActionsFactory(config.tableKey, api);
};
