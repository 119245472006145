import React, { Component, Fragment } from 'react';
import { notifier } from 'tc-biq-design-system';
import { func, object } from 'prop-types';

import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import modifers from './modifiers';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import NamesSidepanel, { SIDEPANEL_ID } from './NamesSidepanel';
import { hasAccess } from '../../../../../../logic/services/acl';

const apiUrl = 'trading_backends/manage_display';
const api = getDjangoApi(apiUrl);

export const tableConfig = () => ({
  reducerKey: 'TRADING_PLATFORM',
  apiUrl,
  tableKey: 'NAMES_TABLE',
});

const text = {
  SUCCESS: gettext('Platform name successfully deleted'),
  ERROR: gettext('Error while trying to delete platform name'),
};

const propTypes = {
  dispatch: func.isRequired,
  actions: object.isRequired,
};

class NamesTable extends Component {
  constructor(props) {
    super(props);
    this.Grid = withTable(GridTab, tableConfig, null, { withActions: true })();
    this.tableActions = {
      ...(hasAccess('tpi.trading_backends.manage_display.destroy') && { onDelete: this.onDelete }),
      ...(hasAccess('tpi.trading_backends.manage_display.update') && { onEdit: this.onEdit }),
    };
    this.showActions = !!Object.keys(this.tableActions).length;
  }

  onDelete = async (data) => {
    const [err] = await to(api.destroy(data.id));
    if (err) {
      notifier.error(text.ERROR);
    } else {
      notifier.success(text.SUCCESS);
      this.reloadTable();
    }
  }

  onEdit = (data) => {
    const { actions } = this.props;
    actions.openSidepanel(SIDEPANEL_ID, { data });
  }

  reloadTable = () => {
    const { dispatch } = this.props;
    const [, tableActions] = this.Grid;
    dispatch(tableActions.fetchTableData());
  }

  render() {
    const [Table] = this.Grid;
    return (
      <Fragment>
        <Table
          singleActions={this.showActions}
          modifier={modifers(this.tableActions)}
        />
        <NamesSidepanel onSuccess={this.reloadTable} />
      </Fragment>
    );
  }
}

NamesTable.propTypes = propTypes;

export default NamesTable;
