import React, { Component } from 'react';
import { object, string, bool, func } from 'prop-types';
import { HyperLink, Pill, Panel, Button, notifier } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import { gettext } from '../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import THIRDPARTY_STATE_LABEL from '../../../../logic/enums/thirdparty-state-label';
import { YesNoCell } from '../../../gridCellRenderers';
import PanelRow from './PanelRow';
import appRoutes from '../../../App/Router/appRoutes';

const propTypes = {
  pgAccount: object.isRequired,
  thirdPartyVerified: string.isRequired,
  thirdParty: bool.isRequired,
  activate: func.isRequired, 
};

const text = {
  THIRD_PARTY_PAYMENT: gettext('3rd party payment'),
  SET_AS_NOT_THIRD_PARTY: gettext('Set as not 3rd party'),
  SET_AS_NOT_THIRD_PARTY_ERROR: gettext('Failed to set as not 3rd party'),
  REJECT: gettext('Reject'),
  REJECT_SUCCESS: gettext('Third party payment rejected'),
  REJECT_ERROR: gettext('Failed to reject third party payment'),
  APPROVE: gettext('Approve'),
  APPROVE_SUCCESS: gettext('Third party payment approved'),
  APPROVE_ERROR: gettext('Failed to approve third party payment'),
  THIRD_PARTY: gettext('3rd party'),
  THIRD_PARTY_VERIFIED: gettext('3rd party verified'),
  USER: gettext('User'),
  BANK_ACCOUNT: gettext('Bank account'),
  BANK_ACCOUNT_HOLDER_NAME: gettext('Bank account holder name'),
  BANK_NAME: gettext('Bank name'),
  IBAN: gettext('IBAN'),
  SWIFT: gettext('SWIFT'),
  SORT_CODE: gettext('Sort code'),
  LAST_FOUR_CARD_DIGITS: gettext('Last four card digits'),
  CARD_HOLDER_NAME: gettext('Card holder name'),
  PAYMENT_GATEWAY: gettext('Payment gateway'),
  EMAIL: gettext('Email'),
  SECONDARY_EXTERNAL_ID: gettext('Secondary external ID'),
  CARD_EXPIRY_DATE: gettext('Card expiry date'),
};

const accountType = {
  BANK: 'Bank',
  BANK_ACCOUNT: 'Bank Account',
  CARD: 'Card',
  EWALLET: 'eWallet',
};

const UserLink = ({ user }) => (
  <HyperLink>
    <Link to={generatePath(appRoutes.CLIENT_PROFILE, { id: user.id })}>{user.full_name}</Link>
  </HyperLink>
);

UserLink.propTypes = { user: object.isRequired };

class ThirdPartyPayment extends Component {
  constructor(props) {
    super(props);

    this.setNotThirdPartyApi = getDjangoApi(`payment_gateway_accounts/${props.pgAccount.id}/set_not_thirdparty`);
    this.approveApi = getDjangoApi(`payment_gateway_accounts/${props.pgAccount.id}/approve`);
    this.rejectApi = getDjangoApi(`payment_gateway_accounts/${props.pgAccount.id}/reject`);
  }

  setAsNotThirdParty = () => {
    const { activate } = this.props;

    this.setNotThirdPartyApi
      .create({})
      .then(() => {
        notifier.success(text.SET_AS_NOT_THIRD_PARTY);
        activate();
      }, () => {
        notifier.error(text.SET_AS_NOT_THIRD_PARTY_ERROR);
      });
  }

  reject = () => {
    const { activate } = this.props;

    this.rejectApi
      .create({})
      .then(() => {
        notifier.success(text.REJECT_SUCCESS);
        activate();
      }, () => {
        notifier.error(text.REJECT_ERROR);
      });
  }

  approve = () => {
    const { activate } = this.props;

    this.approveApi
      .create({})
      .then(() => {
        notifier.success(text.APPROVE_SUCCESS);
        activate();
      }, () => {
        notifier.error(text.APPROVE_ERROR);
      });
  }

  showActions = pgAccount => pgAccount.is_thirdparty && pgAccount.state === THIRDPARTY_STATE_LABEL.UNDER_REVIEW;

  showData = pgAccount => pgAccount.is_thirdparty || pgAccount.state !== 'N/A';

  showBankData = pgAccount => this.showData(pgAccount) && (pgAccount.type === accountType.BANK || pgAccount.type === accountType.BANK_ACCOUNT);

  showCardData = pgAccount => this.showData(pgAccount) && pgAccount.type === accountType.CARD;

  showGatewayData = pgAccount => this.showData(pgAccount) && pgAccount.type === accountType.EWALLET;

  showApprove = () => {
    const { thirdPartyVerified } = this.props;
    return thirdPartyVerified === null;
  }

  thirdpartyVerifiedPillType = () => {
    const { thirdPartyVerified } = this.props;

    if (thirdPartyVerified) {
      switch (thirdPartyVerified) {
        case THIRDPARTY_STATE_LABEL.NOT_VERIFIED:
          return 'status01';
        case THIRDPARTY_STATE_LABEL.VERIFIED:
          return 'status01';
        case THIRDPARTY_STATE_LABEL.UNDER_REVIEW:
          return 'primary';
      }
    }
  }

  render() {
    const { thirdParty, thirdPartyVerified, pgAccount } = this.props;

    const PanelHeader = (
      <div>
        <span>{text.THIRD_PARTY_PAYMENT}</span>
        {this.showActions(pgAccount) && <Button size="small" onClick={this.setAsNotThirdParty}>{text.SET_AS_NOT_THIRD_PARTY}</Button>}
        {this.showActions(pgAccount) && <Button size="small" onClick={this.reject} color="destructive">{text.REJECT}</Button>}
        {this.showApprove(pgAccount) && <Button size="small" onClick={this.approve} color="confirmation">{text.APPROVE}</Button>}
      </div>
    );

    return (
      <Panel title={PanelHeader} style={{ marginTop: 0 }}>
        <div className="card-panel-body">
          <PanelRow label={text.THIRD_PARTY_PAYMENT} value={<YesNoCell value={thirdParty} />} />
          {this.showData(pgAccount) && <PanelRow label={text.THIRD_PARTY_VERIFIED} value={<Pill type={this.thirdpartyVerifiedPillType()}>{thirdPartyVerified}</Pill>} />}
          {this.showData(pgAccount) && <PanelRow label={text.USER} value={<UserLink user={pgAccount.user} />} />}

          {/* Bank Data */}
          {this.showBankData(pgAccount) && <PanelRow label={text.BANK_ACCOUNT} value={pgAccount.account_number} />}
          {this.showBankData(pgAccount) && <PanelRow label={text.BANK_ACCOUNT_HOLDER_NAME} value={pgAccount.account_holder_name} />}
          {this.showBankData(pgAccount) && <PanelRow label={text.BANK_NAME} value={pgAccount.bank_name} />}
          {this.showBankData(pgAccount) && <PanelRow label={text.IBAN} value={pgAccount.iban} />}
          {this.showBankData(pgAccount) && <PanelRow label={text.SWIFT} value={pgAccount.swift_code} />}
          {this.showBankData(pgAccount) && <PanelRow label={text.SORT_CODE} value={pgAccount.sort_code} />}

          {/* Card data */}

          {this.showCardData(pgAccount) && <PanelRow label={text.LAST_FOUR_CARD_DIGITS} value={pgAccount.card_last4} />}
          {this.showCardData(pgAccount) && <PanelRow label={text.CARD_HOLDER_NAME} value={pgAccount.cardholder_name} />}
          {this.showCardData(pgAccount) && <PanelRow label={text.CARD_EXPIRY_DATE} value={pgAccount.expiry} />}

          {/* Payment account gateway */}
          {this.showGatewayData(pgAccount) && <PanelRow label={text.PAYMENT_GATEWAY} value={pgAccount.payment_gateway.name} />}
          {this.showGatewayData(pgAccount) && <PanelRow label={text.EMAIL} value={pgAccount.email} />}
          {this.showGatewayData(pgAccount) && <PanelRow label={text.SECONDARY_EXTERNAL_ID} value={pgAccount.secondary_external_id} />}
        </div>
      </Panel>
    );
  }
}

ThirdPartyPayment.propTypes = propTypes;
export default ThirdPartyPayment;
