import React, { Component } from 'react';
import { Space, HyperLink, DashboardCard } from 'tc-biq-design-system';

import DashboardNumeric from '../../Numeric';
import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import { currencyFormatter } from '../../../../../../../../logic/utilities/formatters';
import withFilters from '../../hoc/withFilters';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';

const size = {
  w: 2,
  h: 5,
  minW: 2,
  minH: 5,
  maxW: 3,
  maxH: 5,
  y: 0,
  x: 8,
};

const text = {
  TITLE: gettext('Withdrawals'),
  DESCRIPTION: gettext('Executed withdrawals during selected time period'),
};

const api = getDjangoApi('dashboard/overall_withdrawals');

class Withdrawals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      withdrawals: {},
      loading: false,
    };
  }

  onQueryReady = async (query) => {
    this.setState({ loading: true });
    const { data } = await api.list(query);

    this.setState({
      withdrawals: data,
      loading: false,
    });
  }

  render() {
    const { withdrawals: { amount, currency, number }, loading } = this.state;
    const withdrawalAmount = currencyFormatter(amount, currency);
    const withdrawalNumber = number || 0;
    return (
      <DashboardCard
        title={text.TITLE}
        description={text.DESCRIPTION}
        loading={loading}
      >
        <DashboardNumeric>
          <DashboardNumeric.Wrapper>
            <Space size={5} />
            <DashboardNumeric.Price>{withdrawalAmount}</DashboardNumeric.Price>
            <Space size={12} />
            <HyperLink>
              <a href="payments/withdrawal-history">
                <DashboardNumeric.Number color="red" iconName="Withdrawal">{withdrawalNumber}</DashboardNumeric.Number>
              </a>
            </HyperLink>
          </DashboardNumeric.Wrapper>
        </DashboardNumeric>
      </DashboardCard>
    );
  }
}

export default {
  component: withFilters(Withdrawals),
  size,
};
