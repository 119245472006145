import { createAction, handleActions } from 'redux-actions';
import { toEndLocalDateTime, toStartLocalDateTime } from '../../../../../logic/utilities/formatters/dateTimeFormatter';

const defaultStartDate = moment()
  .subtract(1, 'month')
  .format('YYYY-MM-DD');
const defaultEndDate = moment().format('YYYY-MM-DD');

const generatePublishedRange = (startDate, endDate) => `${toStartLocalDateTime(startDate)},${toEndLocalDateTime(endDate)}`;

const initialState = {
  textSearchFilter: { value: '', query: {} },
  dateFilter: {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    query: { published__range: generatePublishedRange(defaultStartDate, defaultEndDate) },
  },
};

const actionTypes = {
  SET_DATE_FILTER: 'SET_DATE_FILTER',
  SET_TEXT_SEARCH_FILTER: 'SET_TEXT_SEARCH_FILTER',
  RESET_FILTERS: 'RESET_FILTERS',
};

const setDateFilter = createAction(actionTypes.SET_DATE_FILTER, value => value);
const setTextSearchFilter = createAction(actionTypes.SET_TEXT_SEARCH_FILTER, value => value);
const resetFilters = createAction(actionTypes.RESET_FILTERS);

export const reducer = handleActions(
  {
    [actionTypes.SET_TEXT_SEARCH_FILTER]: (state, { payload }) => ({
      ...state,
      textSearchFilter: {
        ...state.textSearchFilter,
        value: payload,
        query: { search: payload },
      },
    }),
    [actionTypes.SET_DATE_FILTER]: (state, { payload }) => ({
      ...state,
      dateFilter: {
        ...state.dateFilter,
        startDate: payload.startDate || defaultStartDate,
        endDate: payload.endDate || defaultEndDate,
        query: {
          published__range: generatePublishedRange(payload.startDate, payload.endDate),
        },
      },
    }),
    [actionTypes.RESET_FILTERS]: () => ({ ...initialState }),
  },
  initialState,
);

export default {
  setDateFilter,
  setTextSearchFilter,
  resetFilters,
};
