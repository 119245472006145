/* eslint react/prop-types: 0 */
import React from 'react';
import { DatePicker, DateTimePicker } from 'tc-biq-design-system';

const withTime = type => type === 'datetime';

const DatePickerField = (props) => {
  const { 
    name,
    placeholder,
    label,
    value, 
    hasError, 
    helpText, 
    id, 
    onChange, 
    type,
    actionComponent,
  } = props;

  const format = 'YYYY-MM-DD';

  const date = value || moment().format(format);

  const inputData = {
    name,
    placeholder,
    label,
    hasError,
    helpText,
  };

  const Component = withTime(type) 
    ? DateTimePicker 
    : DatePicker;

  return (
    <div className="biq-form__field">
      <Component
        {...props}
        date={date}
        inputProps={inputData}
        onChange={value => onChange({ value, id })}
      />
      {actionComponent}
    </div>
  );
};

export default DatePickerField;
