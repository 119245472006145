import React from 'react';
import { node, string } from 'prop-types';

import If from '../../../../../../components/If';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { YesNoCell } from '../../../../../../components/gridCellRenderers';

const propTypes = {
  label: node.isRequired,
  value: node,
  link: string,
};

const defaultProps = {
  value: null,
  link: '',
};

const text = {
  NONE: gettext('None'),
};

const MarketingInfo = ({ label, value, link }) => (
  <span className="biq-marketing__info">
    <span className="text-neutral-400 tc-paragraph-regular">{label}</span>

    <If condition={typeof value !== 'boolean'}>
      <If condition={!value}>
        <span className="biq-marketing__info-value">{text.NONE}</span>
      </If>

      <If condition={!link && !!value}>
        <span className="biq-marketing__info-value">{value}</span>
      </If>

      <If condition={!!link && !!value}>
        <span className="text-primary-400 tc-paragraph-strong">
          <a href={link} target="_blank" rel="noopener noreferrer">{value}</a>
        </span>
      </If>
    </If>

    {typeof value === 'boolean' && (
      <span style={{ display: 'inline' }}>
        <YesNoCell value={value} />
      </span>
    )}
  </span>
);

MarketingInfo.propTypes = propTypes;
MarketingInfo.defaultProps = defaultProps;
export default MarketingInfo;
