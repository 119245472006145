import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { array, object, string } from 'prop-types';

import connect from '../../../../../../logic/connect';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import BiqAccordion from '../../../../../../components/BiqAccordion';
import { fetchTos } from './Model';
import DocumentTable from './DocumentTable';
import './TermsAndConditions.scss';

const propTypes = {
  id: string.isRequired,
  actions: object.isRequired,
  data: array,
};

const defaultProps = {
  data: [],
};

const renderTos = group => (
  <div className="accordion-section" key={document.id}>
    <BiqAccordion title={group[0].group}>
      {group.map(document => (<DocumentTable {...document} />))}
    </BiqAccordion>
  </div>
);

class TermsAndConditions extends Component {
  constructor(props) {
    super(props);

    this.actions = props.actions;
    this.api = getDjangoApi(`users/${props.id}/tos`);
  }

  componentDidMount() {
    this.actions.fetchTos(this.api);
  }

  render() {
    const { data } = this.props;
    const grouppedData = _.map(_.groupBy(data, tos => tos.group), item => item);
    return (
      <div className="biq-profile__wrapper biq-profile__tos">
        {grouppedData.length > 0 && grouppedData.map(renderTos)}
      </div>
    );
  }
}

const mapStateToProps = ({ pages }) => pages.USER_SINGLE.termsAndConditions;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchTos }, dispatch),
});

TermsAndConditions.propTypes = propTypes;
TermsAndConditions.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
