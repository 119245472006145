import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';
import EditMifid from './EditMifid';
import userActionTypes from '../types';

const env = window.config.environment;

const text = {
  LABEL: gettext('Edit MIFID II details'),
  TITLE: user => gettext('Edit MIFID II details, user {{first_name}} {{last_name}}', user),
};

const ACTION_ICON = 'Edit';

export default {
  name: 'editMifidDetails',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    const hasStatePermission = [
      USER_STATE.FULL,
      USER_STATE.PENDING,
      USER_STATE.LIMITED,
      USER_STATE.REVIEW,
      USER_STATE.LEAD,
      USER_STATE.PENDING_EMAIL_VERIFICATION,
      USER_STATE.PENDING_SMS_VERIFICATION,
      USER_STATE.DEMO,
    ].includes(state);
    return !isIb 
      && hasAccess('user.update') 
      && hasStatePermission 
      && isFeatureEnabled()('MIFID')
      && env !== 'AvaTrade';
  },
  handler: (sidepanelManager, _dispatch, user) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.TITLE(user),
      visible: true,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(EditMifid, sidepanelOptions);
  },
};
