import { handleActions } from 'redux-actions';

import ActionTypesFactory from './GridActionTypesFactory';
import { getExportURL, getCursor } from '../../logic/services/table-data';
import { gettext } from '../../logic/utilities/languageUtility';
import { applyModifiers } from '../../logic/utilities/applyModifiers';
import { getDefaultPageSize, getPageSizes } from './GridUtils';

export const defaultSegment = { id: 'default', label: gettext('None'), fields: [], filters: {} };
const getDefaultFields = state => _.get(state, 'options.actions.GET.fields', []).map(({ key }) => key);

const initialState = {
  data: [],
  fetchDataInProgress: false,
  options: {},
  selectedSegment: defaultSegment,
  ownSegments: [],
  publicSegments: [],
  sharedSegments: [],
  fields: [],
  columnsState: {
    initial: [],
    current: [],
  },
  customFields: [],
  query: {
    ordering: '-id',
    limit: getDefaultPageSize(),
    offset: 0,
    cursor: null,
  },
  loading: false,
  activeRequests: 0,
  error: null,
  maxTotal: Number.MAX_SAFE_INTEGER,
  pagination: {
    total: 0,
    current: 1,
    pageSize: getDefaultPageSize(),
    sizeOptions: getPageSizes(),
    cursor: {
      next: null,
      previous: null,
    },
  },
  exportUrl: {
    single: null,
    all: null,
  },
};

function addRequest(currentActiveRequests) {
  const activeRequests = currentActiveRequests + 1;
  return {
    loading: activeRequests > 0,
    activeRequests,
  };
}

function removeRequest(currentActiveRequests) {
  const activeRequests = currentActiveRequests - 1;
  return {
    loading: activeRequests > 0,
    activeRequests,
  };
}

function setInitialColumnsState(allColumns, visibleColumns, hiddenColumns = []) {
  return allColumns.map(column => ({
    key: column.key,
    visible: visibleColumns ? visibleColumns.includes(column.key) : !hiddenColumns.includes(column.key),
  }));
}

function extractNestedColumns(fields) {
  const stepsIndex = fields.findIndex(field => field.type === 'column_field');
  if (stepsIndex === -1) return fields;
  const columnField = fields[stepsIndex];
  return [
    ...fields.slice(0, stepsIndex),
    ...columnField.columns.map(f => ({ ...f, key: `${columnField.key}__${f.key}` })),
    ...fields.slice(stepsIndex + 1),
  ];
}

function setCursors(data) {
  const result = { next: getCursor(data.next), previous: getCursor(data.previous) };

  return result;
}

function getCursorCurrentPage(action, state) {
  if (action.payload === 'next') {
    return state.pagination.current + 1;
  }
  if (action.payload === 'previous') {
    return state.pagination.current - 1;
  }

  return state.pagination.current;
}

const GridReducer = (table) => {
  const {
    CHANGE_PAGE,
    CHANGE_CURSOR,
    ORDERING_CHANGE,
    TABLE_DATA_FULFILLED,
    TABLE_DATA_PENDING,
    TABLE_DATA_REJECTED,
    TABLE_OPTIONS_FULFILLED,
    TABLE_OPTIONS_PENDING,
    UPDATE_PAGE_SIZE,
    UPDATE_FILTER,
    UPDATE_EXPORT_URL,
    ADD_ACTIONS_COLUMN,
    ADD_INFO_COLUMN,
    UPDATE_ROW_DATA_FULFILLED,
    UPDATE_ROW_DATA_PENDING,
    UPDATE_ROW_DATA_REJECTED,
    DELETE_ROW_FULFILLED,
    DELETE_ROW_PENDING,
    DELETE_ROW_REJECTED,
    CHANGE_SEGMENT,
    SET_COLUMNS_VISIBILITY,
    RESET_TABLE,
    CLEAR_DATA,
    UPDATE_CELL,
    UPDATE_MAX_TOTAL,
  } = ActionTypesFactory(table);

  return handleActions(
    {
      [TABLE_OPTIONS_PENDING]: state => ({
        ...state,
        ...addRequest(state.activeRequests),
      }),
      [TABLE_OPTIONS_FULFILLED]: (state, action) => {
        const { options, fields, modifier } = action.payload;
        const visibleColumns = options.selected_segment && options.selected_segment.fields;
        const extendedFields = extractNestedColumns(fields);
        const [filteredFields, sortedFields] = applyModifiers(extendedFields, modifier, visibleColumns);
        const hiddenColumns = Object.keys(modifier || {}).filter(key => modifier[key].deselect).map(key => key);
        
        return {
          ...state,
          ...removeRequest(state.activeRequests),
          options: { ...options, fromApi: true },
          fields: sortedFields,
          selectedSegment: options.selected_segment || initialState.selectedSegment, 
          ownSegments: options.own_segments || [],
          sharedSegments: [...(options.shared_segments || []), ...(options.team_segments || [])],
          publicSegments: options.public_segments || [],
          columnsState: {
            initial: setInitialColumnsState(filteredFields, visibleColumns, hiddenColumns),
            current: setInitialColumnsState(sortedFields, visibleColumns, hiddenColumns),
          },
        };
      },
      [TABLE_DATA_PENDING]: state => ({
        ...state,
        ...addRequest(state.activeRequests),
        fetchDataInProgress: true,
        error: null,
      }),
      [TABLE_DATA_FULFILLED]: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          data: Array.isArray(data) ? data : data.results,
          fetchDataInProgress: false,
          pagination: {
            ...state.pagination,
            realTotal: data.count,
            total: Math.min(data.count, state.maxTotal),
            cursor: { ...state.pagination.cursor, ...setCursors(data) },
          },
          ...removeRequest(state.activeRequests),
          error: null,
        };
      },
      [TABLE_DATA_REJECTED]: state => ({
        ...state,
        ...removeRequest(state.activeRequests),
        fetchDataInProgress: false,
        error: true,
      }),
      [ORDERING_CHANGE]: (state, action) => ({
        ...state,
        pagination: { ...state.pagination, current: 1 },
        query: {
          ...state.query,
          ordering: action.payload,
          offset: 0,
        },
      }),
      [UPDATE_PAGE_SIZE]: (state, action) => ({
        ...state,
        pagination: { ...state.pagination, pageSize: action.payload },
        query: {
          ...state.query,
          limit: action.payload,
        },
      }),
      [CHANGE_PAGE]: (state, action) => ({
        ...state,
        pagination: { ...state.pagination, current: action.payload },
        query: {
          ...state.query,
          offset: (action.payload - 1) * state.query.limit,
        },
      }),
      [CHANGE_CURSOR]: (state, action) => ({
        ...state,
        pagination: { ...state.pagination, current: getCursorCurrentPage(action, state) },
        query: {
          ...state.query,
          cursor: state.pagination.cursor[action.payload],
        },
      }),
      [UPDATE_FILTER]: (state, action) => ({
        ...state,
        pagination: { ...state.pagination, current: 1 },
        query: {
          ...initialState.query,
          ...action.payload,
        },
      }),
      [SET_COLUMNS_VISIBILITY]: (state, action) => {
        const { payload } = action;

        return {
          ...state,
          columnsState: {
            ...state.columnsState,
            current: payload,
          },
        };
      },
      [UPDATE_EXPORT_URL]: (state, action) => {
        const { url } = action.payload;

        const exportUrl = {
          single: getExportURL(url, { ...state.query, _segment: state.selectedSegment.id }),
          all: getExportURL(url, { ...state.query, _segment: state.selectedSegment.id, all: true }),
        };

        return { ...state, exportUrl };
      },
      [ADD_ACTIONS_COLUMN]: (state, { payload }) => ({
        ...state,
        customFields: payload ? [...state.customFields, payload] : [...state.customFields],
      }),
      [ADD_INFO_COLUMN]: (state, { payload }) => ({
        ...state,
        customFields: payload ? [...state.customFields, payload] : [...state.customFields],
      }),
      [UPDATE_ROW_DATA_PENDING]: state => ({
        ...state,
        ...addRequest(state.activeRequests),
      }),
      [UPDATE_ROW_DATA_FULFILLED]: (state, action) => {
        const { data } = action.payload;
        const rowIndex = _.findIndex(state.data, val => val.id === data.id);
        return {
          ...state,
          ...removeRequest(state.activeRequests),
          data: state.data.map((item, index) => {
            if (index !== rowIndex) {
              return item;
            }
            return data;
          }),
        };
      },
      [UPDATE_ROW_DATA_REJECTED]: state => ({
        ...state,
        ...removeRequest(state.activeRequests),
      }),
      [DELETE_ROW_FULFILLED]: (state, action) => {
        const deletedRows = Array.isArray(action.payload) ? action.payload : [action.payload];
        const indexes = deletedRows.map(item => item.id);
        return {
          ...state,
          ...removeRequest(state.activeRequests),
          data: _.filter(state.data, item => !_.includes(indexes, item.id)),
        };
      },
      [DELETE_ROW_PENDING]: state => ({
        ...state,
        ...addRequest(state.activeRequests),
      }),
      [DELETE_ROW_REJECTED]: state => ({
        ...state,
        ...removeRequest(state.activeRequests),
        error: true,
      }),
      [CHANGE_SEGMENT]: (state, action) => {
        const { fields } = state;
        const { payload } = action;
        const visibleFields = payload.id === 'default' ? getDefaultFields(state) : payload.fields;
        const hiddenColumns = Object.keys(payload.modifier || {}).filter(key => payload.modifier[key].deselect).map(key => key);
        const [, sortedFields] = applyModifiers(fields, payload.modifier, visibleFields);

        return {
          ...state,
          selectedSegment: action.payload,
          columnsState: {
            ...state.columnsState,
            current: setInitialColumnsState(sortedFields, visibleFields, hiddenColumns),
          },
        };
      },
      [RESET_TABLE]: () => ({ ...initialState }),
      [CLEAR_DATA]: (state, { payload: { data } }) => ({ ...state, data }),
      [UPDATE_CELL]: (state, { payload: { index, key, value } }) => ({ ...state, 
        data: state.data.map((item, i) => {
          if (index === i) {
            return {
              ...item,
              [key]: value,
            };
          } 
          return item;
        }),
      }),
      [UPDATE_MAX_TOTAL]: (state, { payload }) => ({
        ...state,
        maxTotal: payload || state.maxTotal,
      }),
    },
    initialState,
  );
};

export default GridReducer;
