import React, { PureComponent } from 'react';
import { Input, Select } from 'tc-biq-design-system';
import { string, object, array, bool } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../../logic/connect';
import { actions } from '../Model';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

const propTypes = {
  actions: object.isRequired,
  isMultiPreset: bool.isRequired,
  checkedChoices: array.isRequired,
  searchValue: string,
  checkedValue: object,
};

const defaultProps = {
  searchValue: '',
  checkedValue: null,
};

const text = {
  SEARCH: gettext('Search...'),
};

class DocumentsHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  updateSearchTerm = (value) => {
    this.actions.updateTextFilter(value);
  };

  updateSearchSelection = (value) => {
    this.actions.updateSelectFilter(value);
  };

  render() {
    const { searchValue, checkedChoices, checkedValue, isMultiPreset } = this.props;
    return (
      <div className="biq-settings-documents__header">
        <span style={{ minWidth: '300px' }}>
          <Input
            placeholder={text.SEARCH}
            iconPosition="left"
            icon="Search"
            type="text"
            name="search"
            value={searchValue}
            onChange={e => this.updateSearchTerm(e.target.value)}
          />
        </span>
        {!isMultiPreset && (
          <span style={{ minWidth: '150px' }}>
            <Select
              value={checkedValue || checkedChoices[0]}
              type="single"
              onChange={this.updateSearchSelection}
              clearable={false}
              options={checkedChoices}
            />
          </span>
        )}
      </div>
    );
  }
}

DocumentsHeader.propTypes = propTypes;
DocumentsHeader.defaultProps = defaultProps;

const mapStateToProps = ({ pages }) => {
  const { searchValue, checkedChoices, checkedValue, isMultiPreset } = pages.DOCUMENTS.documentsReducer;
  return { searchValue, checkedChoices, checkedValue, isMultiPreset };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    updateTextFilter: actions.updateTextFilter,
    updateSelectFilter: actions.updateSelectFilter,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary(DocumentsHeader));
