import { urlCombine, BO_APP_FULL } from './api';

// Helpers for react version
export const isPDF = fileName => fileName && fileName.toLowerCase().endsWith('.pdf');

export const getSrc = (document, options = {}) => {
  const { file_name_backside, id, user } = document;
  if (options.isBackside && !file_name_backside) return null;

  const endpoint = options.isBackside ? 'file_backside' : 'file';
  return urlCombine(BO_APP_FULL, 'users', user.id, 'documents', id, endpoint, '/');
};

class Document {
  constructor(document, DI) {
    const {
      name,
      check_status,
      onfido_eligible,
      file_name,
      file_name_backside,
      status,
      id,
      is_deleted,
      object_id,
      usage,
      user,
      document_type,
      uploaded_at,
      rejection_reason,
      status_changed_at,
      verified_by,
    } = document;
    
    this.label = name;
    this.onfido_status = check_status;
    this.onfido_eligible = onfido_eligible;
    this.link = file_name;
    this.link_backside = file_name_backside;
    this.status = status;
    this.id = id;
    this.is_deleted = is_deleted;
    this.object_id = object_id;
    this.usage = usage;
    this.user = user;
    this.type = document_type;
    this.uploaded_at = uploaded_at;
    this.rejection_reason = rejection_reason;
    this.action_date = status_changed_at;
    this.actor = verified_by;

    this.api = DI.ApiFactory.getDjangoApi(`documents/${this.id}`);
    this.approveApi = this.api.all('approve');
  }

  getSrc(options = {}) {
    if (options.isBackside && !this.link_backside) return null;

    const endpoint = options.isBackside ? 'file_backside' : 'file';
    return urlCombine(BO_APP_FULL, 'users', this.user.id, 'documents', this.id, endpoint, '/'); 
  }

  isPDF() {
    return this.link.toLowerCase().endsWith('.pdf');
  }

  options() {
    return this.approveApi.options();
  }

  delete() {
    return this.api.destroy();
  }

  approve() {
    return this.approveApi.create({ status: 2 });
  }

  reject(rejection_reason) {
    const request = {
      status: 3,
    };

    if (rejection_reason) request.rejection_reason = rejection_reason;

    return this.approveApi.create(request);
  }
}

export default Document;
