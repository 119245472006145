import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { object, func, bool, array } from 'prop-types';
import { Sidepanel, Space, notifier } from 'tc-biq-design-system';

import { gettext } from '../../../../../../logic/utilities/languageUtility';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import connect from '../../../../../../logic/connect';
import overlayActions from '../../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../../components/common';
import FormFactory, { FormActionsFactory } from '../../../../../../components/form';
import to from '../../../../../../logic/utilities/to';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import { submitDashboardAssign } from '../../DashboardLayout/DashboardPage/Model';
import { hasAccess } from '../../../../../../logic/services/acl';


export const SIDEPANEL_ID = 'DASHBOARD_LAYOUT_SIDEPANEL';
const FORM_KEY = 'DASHBOARD_LAYOUT_FORM';
const REDUCER_KEY = 'DASHBOARD_LAYOUT';

const { create, update, setFieldValue, showLoader } = FormActionsFactory(FORM_KEY);

const dashboardLayoutApi = getDjangoApi('settings/layout/dashboard');

const text = {
  NAME: gettext('Name'),
  CREATE_TITLE: gettext('New dashboard'),
  EDIT_TITLE: gettext('Edit dashboard'),
  SHARE_WITH_USERS: gettext('Share with BO users'),
  SHARE_WITH_TEAMS: gettext('Share with teams'),
  SUCCESS_CREATE: gettext('Dashboard layout successfully created'),
  SUCCESS_UPDATE: gettext('Dashboard layout successfully updated'),
  BUTTON_LABELS: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
};

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
  onSuccess: func,
  submitInProgress: bool,
  submitAssignInProgress: bool,
  formValues: object.isRequired,
  formFields: array.isRequired,
};

const defaultProps = {
  submitInProgress: false,
  submitAssignInProgress: false,
  onSuccess: null,
};

const customFooter = (execute, close, submitInProgress) => (
  <SidepanelFooter
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    submitInProgress={submitInProgress}
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_KEY}
  />
);

class DashboardLayoutForm extends PureComponent {
  componentDidUpdate() {
    const { formValues, formFields, sidepanel: { parameters } } = this.props;
    if (!_.isEmpty(parameters) && _.isEmpty(formValues) && !_.isEmpty(formFields)) {
      this.setFormFields();
    }
  }

  onSubmit = async () => {
    const { actions, formValues: { users, teams, layout, name }, sidepanel: { parameters } } = this.props;
    const action = () => (this.isEdit() ? actions.update(dashboardLayoutApi, parameters.id) : actions.create(dashboardLayoutApi, () => ({ layout, name })));

    const [formErr, formData] = await to(action());
    const dashboard = _.get(formData, 'action.payload.response');
    const [assignErr] = await to(actions.submitDashboardAssign(dashboard, users, teams));
    const err = formErr || assignErr;
    err ? this.onError(err) : this.onSuccess(dashboard);
  }

  onSuccess(dashboard) {
    const { onSuccess } = this.props;
    const message = this.isEdit() ? text.SUCCESS_UPDATE : text.SUCCESS_CREATE;
    notifier.success(message);
    this.onClose();
    onSuccess && onSuccess(dashboard);
  }

  onError({ data }) {
    const { non_field_errors, messages } = data || {};
    if (non_field_errors) {
      notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
    } else if (messages) {
      notifier.error(data.messages.map(err => <span>{err.text}</span>));
    } else {
      notifier.error(text.GENERAL_ERROR);
    }
  }

  onClose = () => {
    const { actions } = this.props;
    actions.closeSidepanel(SIDEPANEL_ID);
  };

  onChange = (e) => {
    const name = e.target.value;
    const { actions, sidepanel: { parameters } } = this.props;
    actions.updateSidepanel(SIDEPANEL_ID, { ...parameters, name });
  }

  setFormFields = () => {
    const { sidepanel: { parameters } } = this.props;
    const { name, available_to_users, available_to_teams, layout } = parameters;
    this.setField('name', name);
    this.setField('users', available_to_users);
    this.setField('teams', available_to_teams);
    this.setField('layout', layout);
  }

  setField = (id, value) => {
    const { actions } = this.props;
    actions.setFieldValue({
      id,
      value,
    });
  }

  // eslint-disable-next-line react/destructuring-assignment
  isEdit = () => _.get(this.props, 'sidepanel.parameters.id');

  shareField = (key, label, loadOptions, valueKey, labelKey) => {
    const { actions } = this.props;
    return {
      type: 'multiselect',
      multi: true,
      async: true,
      label,
      loadOptions,
      labelKey,
      valueKey,
      id: key,
      customOnChange: actions.setFieldValue,
    };
  }

  loadUsersOptions = value => getDjangoApi('team').list({ username: value, type: 'live', limit: 10, offset: 0 }).then(res => res.data.results);
  
  loadTeamsOptions = value => getDjangoApi('teams').list({ name: value, limit: 10, offset: 0 }).then(res => res.data.results);

  formConfig = () => ({
    form: FORM_KEY,
    customFields: [
      {
        id: 'name',
        label: text.NAME,
        type: 'text',
      },
      ...(hasAccess('layout.dashboard.assign_to_users.*') && [this.shareField('users', text.SHARE_WITH_USERS, this.loadUsersOptions, 'id', 'full_name')]),
      ...(hasAccess('layout.dashboard.assign_to_teams.*') && [this.shareField('teams', text.SHARE_WITH_TEAMS, this.loadTeamsOptions, 'id', 'name')]),
    ],
  })

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { sidepanel, submitInProgress, submitAssignInProgress } = this.props;
    const inProgress = submitInProgress || submitAssignInProgress;
    if (!sidepanel.visible) return null;
    const Form = this.renderForm();
    const title = this.isEdit() ? text.EDIT_TITLE : text.CREATE_TITLE;
    const iconName = this.isEdit() ? 'Edit' : 'Dashboard';

    return (
      <Sidepanel
        icon={iconName}
        title={title}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.onSubmit, this.onClose, inProgress)}
      >
        <Space size={16} />
        <Form 
          formId={FORM_KEY} 
          renderForm={formFields => (
            <Fragment>
              {formFields.name}
              {formFields.users}
              {formFields.teams}
            </Fragment>
          )} 
        />
      </Sidepanel>
    );
  }
}

DashboardLayoutForm.propTypes = propTypes;
DashboardLayoutForm.defaultProps = defaultProps;

const mapStateToProps = ({ overlays, forms, pages }) => {
  const sidepanel = overlays[SIDEPANEL_ID];
  const { submitInProgress, values, fields } = forms[FORM_KEY];
  const page = pages[REDUCER_KEY];
  return {
    ...page,
    submitInProgress,
    sidepanel,
    formValues: values,
    formFields: fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      updateSidepanel: overlayActions.update,
      resetSidepanel: overlayActions.reset,
      create,
      update,
      showLoader,
      setFieldValue,
      submitDashboardAssign,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(DashboardLayoutForm));
