export default {
  STRING          : 'string',
  FIELD           : 'field',
  EMAIL           : 'email',
  INTEGER         : 'integer',
  DECIMAL         : 'decimal',
  BOOLEAN         : 'boolean',
  CHOICE          : 'choice',
  LIST            : 'list',
  NESTED_OBJECT   : 'nested object',
  NESTED_LIST     : 'nested list',
  NESTED_RELATION : 'nested relation',
  GENERIC_RELATION: 'generic relation',
  COLUMN_FIELD    : 'column_field',
  DATE            : 'date',
  DATETIME        : 'datetime',
  FLOAT           : 'float',
  TIMEAGO         : 'timeago',
};
