import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { array, object, string, bool } from 'prop-types';
import { InfoBox, Spinner, notifier } from 'tc-biq-design-system';

import connect from '../../../../../../logic/connect';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import If from '../../../../../../components/If';
import { activate, fetchOneReport, revalidate, documentPropertiesToInject } from './Model';
import Report from './Report';
import './OnfidoReports.scss';

const env = window.config.environment;
const propTypes = {
  reports: array.isRequired,
  fetchInProgress: bool.isRequired,
  checksInProgress: array.isRequired,
  actions: object.isRequired,
  id: string.isRequired,
};

const text = {
  INFO_BOX_TITLE: gettext('Onfido reports'),
  INFO_BOX_TITLE_GBG: gettext('GBG reports'),
  INFO_BOX_TITLE_VCG: gettext('Sumsub reports'),
  INFO: gettext('This user does not have any reports.'),
  REVALIDATE_SUCCESS: gettext('Onfido check successfully started.'),
  REVALIDATE_SUCCESS_GBG: gettext('GBG check successfully started.'),
  REVALIDATE_SUCCESS_VCG: gettext('Sumsub check successfully started.'),
  REVALIDATE_ERROR: gettext('Error while starting onfido check.'),
  REVALIDATE_ERROR_GBG: gettext('Error while starting GBG check.'),
  REVALIDATE_ERROR_VCG: gettext('Error while starting Sumsub check.'),
};

class OnfidoReports extends Component {
  componentDidMount() {
    const { actions, id } = this.props;
    actions.activate(id);
  }

  revalidate = (id) => {
    const { actions } = this.props;

    actions.revalidate(id)
      .then(() => {
        notifier.success(this.getMessagesByEnv(env).revalidateSuccess);
        actions.fetchOneReport(id);
      }, () => {
        notifier.error(this.getMessagesByEnv(env).revalidateError);
      });
  }

  isCheckInProgress = (documentId) => {
    const { checksInProgress } = this.props;
    return checksInProgress.includes(documentId);
  }

  getMessagesByEnv = (envName) => {
    switch (envName) {
      case 'ICMCapital':
        return {
          infoBoxTitle: text.INFO_BOX_TITLE_GBG,
          revalidateSuccess: text.REVALIDATE_SUCCESS_GBG,
          revalidateError: text.REVALIDATE_ERROR_GBG,
        };
  
      case 'VCGMarkets':
      case 'FXView':
      case 'AAAFX':
      case 'AfterPrime':
        return {
          infoBoxTitle: text.INFO_BOX_TITLE_VCG,
          revalidateSuccess: text.REVALIDATE_SUCCESS_VCG,
          revalidateError: text.REVALIDATE_ERROR_VCG,
        };
  
      default:
        return {
          infoBoxTitle: text.INFO_BOX_TITLE,
          revalidateSuccess: text.REVALIDATE_SUCCESS,
          revalidateError: text.REVALIDATE_ERROR,
        };
    }
  };

  render() {
    const { reports, fetchInProgress } = this.props;
    const retrievedReports = reports.filter((report) => {
      const plainReport = _.omit(report, _.keys(documentPropertiesToInject));
      return !_.isEmpty(plainReport);
    });

    return (
      <div className="biq-profile__wrapper biq-onfido-reports">
        <If condition={fetchInProgress}>
          <div className="loader">
            <Spinner size="large" />
          </div>
        </If>

        <If condition={!fetchInProgress && !retrievedReports.length}>
          <InfoBox header={this.getMessagesByEnv(env).infoBoxTitle}>{text.INFO}</InfoBox>
        </If>
 
        <If condition={!fetchInProgress && !!retrievedReports.length}>
          {retrievedReports.map(report => (
            <Report 
              {...report} 
              revalidate={this.revalidate} 
              checkInProgress={this.isCheckInProgress(report.documentId)} 
              key={report.id} 
              report={report}
            />
          ))} 
        </If>
      </div>
    );
  }
}

const mapStateToProps = ({ pages }) => pages.USER_SINGLE.onfidoReports;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ activate, fetchOneReport, revalidate }, dispatch),
});

OnfidoReports.propTypes = propTypes;
// OnfidoReports.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(OnfidoReports);
