/* eslint react/prop-types: 0 */
import React from 'react';

import { UsernameCell } from '../../../../components/gridCellRenderers';
import hideCols from '../../../../logic/utilities/hideCols';
import ENTITY_TYPES from '../../../../logic/enums/entity';

const linkLog = openLog => ({ value, data }) => ( 
  <div style={{ cursor: 'pointer' }} key="log" onClick={() => openLog({ id: data.id })}>
    <span className="text-primary-400">{value}</span>
  </div>
);

const linkEntity = ({ previewUser, previewBiqUser }) => ({ data, colDef }) => {
  const field = data[colDef.field];
  if (!field) return <div />;

  const isStaff = field.type !== ENTITY_TYPES.USER || field.is_stuff;
  const previewAction = isStaff ? previewBiqUser : previewUser;
  const cellOptions = { displayKey: 'display', isStaff };

  return UsernameCell(previewAction, cellOptions)({ data, colDef });
};

export default actions => ({
  ...hideCols([
    'id',
    'action_object', 
    'data',
  ]),
  display: {
    width: 500,
    pinned: 'left',
    cellRendererFramework: linkLog(actions.openLog),
    quickFilter: {},
  },
  actor: { cellRendererFramework: linkEntity(actions) },
  target: { cellRendererFramework: linkEntity(actions) },
});
