import React from 'react';
import { string, object, bool } from 'prop-types';
import { Pill, Icon, Button, Popconfirm } from 'tc-biq-design-system';

import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import If from '../../../../../../../components/If';
import reviewTypes from '../reviewTypes';
import sectionStates from '../sectionStates';

const stateColors = {
  [sectionStates.NA]: 'primary',
  [sectionStates.PENDING]: 'status03',
  [sectionStates.APPROVED]: 'status01',
  [sectionStates.REJECTED]: 'status04',
};

const propTypes = {
  title: string.isRequired,
  state: object.isRequired,
  open: bool.isRequired,
  reviewAction: object.isRequired,
};

const text = {
  POPCONFIRM_LABEL: gettext('Are you sure you want to approve this section?'),
  APPROVE_SECTION: gettext('Approve section'),
  BUTTON_LABELS: { 
    cancel: gettext('No'), 
    confirm: gettext('Yes'), 
  },
  APPROVE: gettext('Approve'),
  REJECT: gettext('Reject'),
};

const AccordionHeaderTemplate = ({ title, state, open, reviewAction }) => (
  <div className="header">
    <div>
      <If condition={open}>
        <Icon name="CaretDown" size="extraSmall" />
      </If>

      <If condition={!open}>
        <Icon name="CaretRight" size="extraSmall" />
      </If>

      <span className="title">{title}</span>
      <span className="biq-protrader__status-pill">
        <Pill type={stateColors[state.value]}>{state.display_name}</Pill>
      </span>
    </div>

    <div className="biq-protrader__approval-section">
      <If condition={state.value === sectionStates.PENDING}>
        <If condition={reviewAction.type === reviewTypes.POPCONFIRM}>
          <Popconfirm
            icon="Approve"
            label={text.POPCONFIRM_LABEL}
            onConfirm={reviewAction.handler(reviewTypes.POPCONFIRM, 'approve')}
            buttonLabels={text.BUTTON_LABELS}
          >
            <Button size="small" color="confirmation">{text.APPROVE}</Button>
          </Popconfirm>

          <Popconfirm
            icon="Approve"
            label={text.POPCONFIRM_LABEL}
            onConfirm={reviewAction.handler(reviewTypes.POPCONFIRM, 'reject')}
            buttonLabels={text.BUTTON_LABELS}
          >
            <Button size="small" color="destructive">{text.REJECT}</Button>
          </Popconfirm>
        </If>

        <If condition={reviewAction.type === reviewTypes.MODAL}>
          <Button size="small" color="ghost" onClick={reviewAction.handler('modal')}>{text.APPROVE_SECTION}</Button>
        </If>
      </If>
    </div>
  </div>
);

AccordionHeaderTemplate.propTypes = propTypes;
export default AccordionHeaderTemplate;
