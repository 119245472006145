import React, { Component } from 'react';
import { Pill, Tab, Tabs } from 'tc-biq-design-system';
import { object } from 'prop-types';
import Page from '../../../../components/Page';
import { gettext } from '../../../../logic/utilities/languageUtility';
import UserHeader from '../../../../components/UserHeader';
import PersonalDetailsForm from './PersonalDetailsForm';
import MemberAcls from './MemberAcls';
import EmailNotifications from './EmailNotifications';
import { hasAccess } from '../../../../logic/services/acl';
import SettingsForm from "./Settings";
import isFeatureEnabled from "../../../../logic/filters/is-feature-enabled";

const CONFIG = window.config;
const env = CONFIG.environment;

const text = {
  PERSONAL_DETAILS: gettext('Personal details'),
  ACCESS_RIGHTS: gettext('Access rights'),
  NOTIFICATIONS: gettext('Notifications'),
  SETTINGS: gettext('Settings'),
  TITLE: gettext('Team Settings'),
};

const tabs = {
  PERSONAL_DETAILS: 0,
  ACCESS_RIGHTS: 1,
  NOTIFICATIONS: 3,
  SETTINGS: 4,
};

const propTypes = {
  actions: object.isRequired,
  member: object.isRequired,
  memberProfile: object.isRequired,
  match: object.isRequired,
};

const defaultTab = (section) => {
  switch (section) {
    case 'access-rights':
      return tabs.ACCESS_RIGHTS;
    default:
      return tabs.PERSONAL_DETAILS;
  }
};

class TeamProfile extends Component {
  constructor(props) {
    super(props);

    this.id = props.match.params.id;
    this.section = props.match.params.section;
    this.state = {
      activeTab: defaultTab(this.section),
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.loadTeamMember(this.id);
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.resetTeamMember();
  }

  hasSettingsAccess() {
    return isFeatureEnabled()('COMMUNICATION_PROFILES') && hasAccess('team.profile.communication_profiles.list');
  }

  render() {
    const { member, memberProfile } = this.props;
    const { activeTab } = this.state;

    return (
      <Page
        hideHeader
        title={text.TITLE}
        style={{}}
      >
        <UserHeader>
          <UserHeader.Avatar />
          <UserHeader.Profile>
            <UserHeader.Name>{ member.full_name }</UserHeader.Name>
            <UserHeader.States>
              {member.type && <Pill>{member.type}</Pill>}
            </UserHeader.States>
            <UserHeader.Subtitle>
              {member.username}
              <UserHeader.Link text={member.email} link={`mailto:${member.email}`} icon="Mail" />
              <UserHeader.Link text={memberProfile.phone} link={`tel:${memberProfile.phone}`} icon="Call" />
            </UserHeader.Subtitle>
          </UserHeader.Profile>
        </UserHeader>
        <Tabs active={activeTab}>
          <Tab
            visible={hasAccess('team.list')}
            title={text.PERSONAL_DETAILS}
            onLoad={() => this.setState({ activeTab: tabs.PERSONAL_DETAILS })}
          >
            <PersonalDetailsForm profileID={this.id} />
          </Tab>
          <Tab
            visible={hasAccess('team.list')}
            title={text.ACCESS_RIGHTS}
            onLoad={() => this.setState({ activeTab: tabs.ACCESS_RIGHTS })}
          >
            <MemberAcls />
          </Tab>
          <Tab
            visible={hasAccess('team.profile.email_preferences.list')}
            title={text.NOTIFICATIONS}
            onLoad={() => this.setState({ activeTab: tabs.NOTIFICATIONS })}
          >
            <EmailNotifications profileID={this.id} />
          </Tab>

          <Tab
            visible={this.hasSettingsAccess()}
            title={text.SETTINGS}
            onLoad={() => this.setState({ activeTab: tabs.SETTINGS })}
          >
            <SettingsForm profileID={this.id} />
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

TeamProfile.propTypes = propTypes;

export default TeamProfile;
