import React, { Component } from 'react';
import DatePickerRangeAll from '../../../../../../components/common/DatePickerRangeAll';

const DATE_FORMAT = 'YYYY-MM-DD';

function withSubscription(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        startDate: moment()
          .subtract(1, 'month')
          .format(DATE_FORMAT),
        endDate: moment().format(DATE_FORMAT),
      };
    }

    onDateChange = (date) => {
      this.setState(date, this.propagateChange);
    }

    setOnDateChangeHandler = (handler) => {
      this.onDateChangeChild = handler;
    }

    propagateChange = (date) => {
      if (this.onDateChangeChild) this.onDateChangeChild(date);
    }

    renderDateFilter = () => {
      if (!this.Component) {
        const { startDate, endDate } = this.state;
        this.Component = (
          <DatePickerRangeAll
            onChange={this.onDateChange}
            startDate={startDate}
            endDate={endDate}
          />
        );
      }

      return this.Component;
    }

    render() {
      const Filter = this.renderDateFilter();
      const { startDate, endDate } = this.state;
      return (
        <WrappedComponent
          date={{ startDate, endDate }}
          dateFilterComponent={Filter}
          setOnDateChangeHandler={this.setOnDateChangeHandler}
          {...this.props}
        />
      );
    }
  };
}

export default withSubscription;
