/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import Page from '../../../../components/Page';
import Export from '../../../../components/Export';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import { TransactionSingle, UserPreview, BiqUserPreview } from '../../../../components/common/sidepanels';
import overlayActions from '../../../../components/overlay';
import modifier from './modifier';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import { hasAccess } from '../../../../logic/services/acl';
import appRoutes from '../../../../components/App/Router/appRoutes';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import { toSingleItemUrl } from '../../../../logic/utilities/toPath';

const pageConfig = {
  apiUrl: 'payment_transactions',
  reducerKey: 'PAYMENT_TRANSACTIONS',
  tableKey: 'PAYMENT_TRANSACTIONS_TABLE',
};

const text = {
  PAGE_TITLE: gettext('Payment transactions'),
};

const SIDEPANEL_ID = 'TRANSACTION_SINGLE';
const { GridComponent, actions } = GridFactory(pageConfig);
const bread = [{ label: text.PAGE_TITLE, route: appRoutes.PAYMENT_TRANSACTIONS }];

class Payments extends Component {
  constructor(props) {
    super(props);
    
    this.headerActions = this.headerActions.bind(this);

    this.tableActions = {
      openTransaction: this.openSingleTransaction.bind(this),
      previewUser: props.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
      previewBiqUser: props.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };

    this.id = props.match.params.id;
  }

  componentDidMount() {
    if (this.id) {
      this.openSingleTransaction({ id: this.id });
    }
  }

  headerActions() {
    const { exportUrl } = this.props;
    return hasAccess('payment_transactions.export.*') && <Export url={exportUrl} />;
  }

  async openSingleTransaction(data) {
    const { actions } = this.props;
    const response = await getDjangoApi(pageConfig.apiUrl).retrieve(data.id);
    actions.openSidepanel(SIDEPANEL_ID, response.data);
    window.history.replaceState(null, '', toSingleItemUrl(data.id));
  }

  render() {  
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, publicSegments, sharedSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, publicSegments, sharedSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions),
    };

    return (
      <Page 
        bread={bread} 
        headerActions={this.headerActions} 
        title={text.PAGE_TITLE}
      >
        <Filters {...filterProps} />
        <GridComponent tableModifier={modifier(this.tableActions)} {...this.props} />
        <TransactionSingle />
        <UserPreview />
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return { 
    exportUrl: table.exportUrl,
    table,
    fields: table.fields, 
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { 
      changePageSize: actions.changePageSize,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      openSidepanel: overlayActions.open, 
    }, 
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(Payments); 
