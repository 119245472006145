import React from 'react';
import { HyperLink } from 'tc-biq-design-system';
import { bool, number, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';

import PreviewCell from './PreviewCell';
import UserCell from './UserCell';
import If from '../If';
import { hasAccess } from '../../logic/services/acl';
import appRoutes from '../App/Router/appRoutes';

import './UsernameCell.scss';

const defaultOptions = { 
  isIb: false,
  isStaff: false,
  displayKey: 'username',
  withOnlineStatus: false,
};

const SYSTEM_USER = 'system';

const ClientCellTemplate = ({ isIb, id, label }) => {
  const route = isIb ? appRoutes.IB_PROFILE : appRoutes.CLIENT_PROFILE;
  return (
    <HyperLink style={{ width: '80%', overflow: 'hidden', textOverflow: 'ellipsis', verticalAlign: 'middle' }}>
      <Link to={generatePath(route, { id })}>{label}</Link>
    </HyperLink>
  );
};

ClientCellTemplate.propTypes = {
  isIb: bool.isRequired,
  id: number.isRequired,
  label: string.isRequired,
};

const TeamMemberCellTemplate = ({ id, label }) => (
  <HyperLink>
    <Link to={generatePath(appRoutes.TEAM_MEMBER, { id })}>
      <UserCell fullName={label} />
    </Link>
  </HyperLink>
);

TeamMemberCellTemplate.propTypes = {
  id: number.isRequired,
  label: string.isRequired,
};

// eslint-disable-next-line
const UsernameCell = (onPreview, options = {}) => ({ data, colDef }) => {
  const config = { ...defaultOptions, ...options };
  // eslint-disable-next-line
  const userObject = typeof data[colDef.field] === 'string' ? data : data[colDef.field];

  if (!userObject || !userObject[config.displayKey] || !userObject.id) return <div />;

  const label = userObject[config.displayKey];

  if (!hasAccess('user.retrieve') || label === SYSTEM_USER) return <div>{label}</div>;
  const isOnline = !_.isEmpty(userObject.online_status);
  return (
    <PreviewCell disableEllipsis onPreview={onPreview} data={{ isIb: config.isIb, id: userObject.id }}>
      <If condition={config.withOnlineStatus}>
        <div className={`username-cell__status ${isOnline && 'username-cell__status--active'}`} />
      </If>
      <If condition={config.isStaff}>
        <TeamMemberCellTemplate id={userObject.id} label={label} />
      </If>

      <If condition={!config.isStaff}>
        <ClientCellTemplate isIb={config.isIb} id={userObject.id} label={label} />
      </If>
    </PreviewCell>
  );
};

export default UsernameCell;
