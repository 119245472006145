import React, { Component } from 'react';
import { Space, DashboardCard, DashboardNumeric } from 'tc-biq-design-system';

import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import { currencyFormatter } from '../../../../../../../../logic/utilities/formatters';
import withFilters from '../../hoc/withFilters';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';

const size = {
  w: 2,
  h: 5,
  minW: 2,
  minH: 5,
  maxW: 3,
  maxH: 5,
  y: 0,
  x: 4,
};

const text = {
  TITLE: gettext('FTD'),
  DESCRIPTION: gettext('First-time deposits placed during selected time period'),
};

const api = getDjangoApi('dashboard/overall_deposits');

class FTD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ftdDeposits: {},
      loading: false,
    };
  }

  onQueryReady = async (query) => {
    this.setState({ loading: true });
    const { data } = await api.list([...query, { is_ftd: true }]);
  
    this.setState({
      ftdDeposits: data,
      loading: false,
    });
  }

  render() {
    const { ftdDeposits: { amount, currency, number }, loading } = this.state;
    const ftdAmount = currencyFormatter(amount, currency);
    const ftdNumber = number || 0;
    return (
      <DashboardCard
        title={text.TITLE}
        description={text.DESCRIPTION}
        loading={loading}
      >
        <DashboardNumeric>
          <DashboardNumeric.Wrapper>
            <Space size={5} />
            <DashboardNumeric.Price>{ftdAmount}</DashboardNumeric.Price>
            <Space size={12} />
            <DashboardNumeric.Number iconName="Deposit">{ftdNumber}</DashboardNumeric.Number>
          </DashboardNumeric.Wrapper>
        </DashboardNumeric>
      </DashboardCard>
    );
  }
}

export default {
  component: withFilters(FTD),
  size,
};
