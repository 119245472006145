import appRoutes from '../../../../components/App/Router/appRoutes';
import { hasAccess } from '../../../../logic/services/acl';
import KYCExport from './KYCExport';

const { environment } = window.config;

export default {
  component: KYCExport,
  aclFn: () => hasAccess('reports.regulation.*') && environment !== 'AvaTrade',
  path: appRoutes.KYC_EXPORT,
};
