/* jshint ignore:start */
import { RouteCell, UsernameCell } from '../../../../components/gridCellRenderers';
import { gettext } from '../../../../logic/utilities/languageUtility';
import dateTimeFilter from '../../../../logic/utilities/formatters/dateTimeFormatter';
import hideCols from '../../../../logic/utilities/hideCols';
import paymentsModifier from '../Payments/modifier';
import appRoutes from '../../../../components/App/Router/appRoutes';

const LinkToAccount = () => ({ value, data, colDef }) => {
  const stateParams = { userId: data.user.id, accountId: data.wallet_object_id };
  const Component = RouteCell({ href: appRoutes.TRADING_ACCOUNT, stateParams, withId: false });
  return Component({ value, data, colDef });
};

const LinkToTransaction = () => ({ value, data, colDef }) => {
  const stateParams = { id: data.source_object_id };
  const Component = RouteCell({ href: appRoutes.PAYMENT_TRANSACTION, stateParams });
  return Component({ value, data, colDef });
};

export default ({ previewUser, previewBiqUser }) => ({
  ...paymentsModifier({ previewUser, previewBiqUser }),
  ...hideCols([
    'source_content_type',
    'source_object_id',
    'wallet_object_id',
    'wallet_content_type',
  ]),
  id: {
    width: 120,
    valueGetter: ({ data }) => data.id,
  },
  uploaded_by: { 
    cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
  },
  upload_date: {
    cellRendererFramework: ({ data }) => dateTimeFilter(data.upload_date),
  },
  sales_manager: { 
    cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
  },
  retention_manager: { 
    cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
  },
  wallet_repr: {
    cellRendererFramework: LinkToAccount(),
    headerName: gettext('Wallet'),
    width: 250,
  },
  source_repr: {
    cellRendererFramework: LinkToTransaction(),
    headerName: gettext('Source'),
    width: 250,
  },
  value: {
    quickFilter: {},
  },
});
