import React from 'react';
import { Spinner } from 'tc-biq-design-system';

import './Loader.scss';

const Loader = () => (
  <div className="biq-loader">
    <Spinner size="large" />
  </div>
);

export default Loader;
