/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { Button, notifier } from 'tc-biq-design-system';
import { func } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import GridFactory from '../../../../../components/grid';
import overlayActions from '../../../../../components/overlay';
import If from '../../../../../components/If';
import text from '../text';
import { reasonsModifier } from '../modifier';
import { getActiveFilters } from '../../../../../components/grid/GridUtils';
import Filters from '../../../../../components/Filters';
import { BiqUserPreview } from '../../../../../components/common/sidepanels';
import { hasAccess } from '../../../../../logic/services/acl';
import RejectionReasonModal, { MODAL_ID, modalModes } from '../../../../../components/common/modals/RejectReason/RejectionReasonModal';
import { getDjangoApi } from '../../../../../logic/services/api-factory';

const propTypes = {
  openSidepanel: func.isRequired,
};

const tableKey = 'REJECT_REASONS';
// const SIDEPANEL_KEY = 'ADD_EDIT_REASON';

const pageConfig = {
  reducerKey: 'WITHDRAWALS',
  apiUrl: 'settings/withdrawal_rejection_reasons',
  tableKey,
};

const rejectReasonApi = getDjangoApi(pageConfig.apiUrl);

const { GridComponent, actions } = GridFactory(pageConfig);

const CreateNewReason = ({ openSidepanel }) => (
  <If condition={hasAccess('withdrawal_rejection_reasons.settings.create')}>
    <Button onClick={() => openSidepanel(MODAL_ID, { mode: modalModes.CREATE })}>
      {text.CREATE_NEW}
    </Button>
  </If>
);

class Reasons extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.tableActions = {
      onModify: hasAccess('withdrawal_rejection_reasons.settings.update') && this.onModify.bind(this),
      onToggle: hasAccess('withdrawal_rejection_reasons.settings.update') && this.onToggle.bind(this),
      previewBiqUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  onToggle(row, is_active) {
    const { id } = row;

    this.actions.updateRowData(id, { is_active })
      .then(() => {
        notifier.success(text.STATE_UPDATE_SUCCESS);
      }, () => {
        notifier.error(text.STATE_UPDATE_ERROR);
      });
  }

  onModify(data) {
    this.actions.openSidepanel(MODAL_ID, { reasonId: data.id, mode: modalModes.EDIT });
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: reasonsModifier(this.tableActions),
    };
    return (
      <Fragment>
        <Filters {...filterProps} />
        <GridComponent
          tableModifier={reasonsModifier(this.tableActions)}
          singleActions={!!this.tableActions.onModify}
          {...this.props}
        />

        <BiqUserPreview />
        <RejectionReasonModal
          api={rejectReasonApi}
          onSuccess={this.actions.fetchTableData}
        />
      </Fragment>
    );
  }
}

CreateNewReason.propTypes = propTypes;

const mapStateToProps = ({ pages }) => {
  const { reducerKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    fetchTableData: actions.fetchTableData,
    updateRowData: actions.updateRowData,
    openSidepanel: overlayActions.open,
    updateFilter: actions.updateFilter,
    changeSegment: actions.changeSegment,
    fetchOptions: actions.fetchOptions,
  }, dispatch),
});

export { CreateNewReason, actions, tableKey };
export default connect(mapStateToProps, mapDispatchToProps)(Reasons);
