/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { Sidepanel, notifier } from 'tc-biq-design-system';

import FormFactory from '../../../../../../../../components/form';
import { SidepanelFooter } from '../../../../../../../../components/common';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { Field } from '../../../../../../notification-templates/formUtils';
import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import to from '../../../../../../../../logic/utilities/to';

export const SIDEPANEL_ID = 'COMMISSION_SIDEPANEL';
export const FORM_ID = 'COMMISSION_SIDEPANEL_FORM';

const text = {
  TITLE_ADD: gettext('Add commission'),
  TITLE_EDIT: gettext('Edit commission'),
  ADD_BUTTON_LABEL: {
    confirm: gettext('Add commission'),
    cancel: gettext('Cancel'),
  },
  EDIT_BUTTON_LABEL: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
  PROVIDER: gettext('Commission provider'),
  TYPE: gettext('Commission type'),
  LEVEL0: gettext('Commission value'),
  LEVEL1: gettext('Sub IB commission Level 1'),
  LEVEL2: gettext('Sub IB commission Level 2'),
  LEVEL3: gettext('Sub IB commission Level 3'),
  SUCCESS: gettext('Commission saved'),
  ERROR_GENERAL: gettext('Something went wrong'),
};

const providerField = {
  id: 'provider',
  type: 'select',
  options: [],
  valueKey: 'value',
  labelKey: 'display_name',
  group: true,
  label: text.PROVIDER,
  clearable: false,
};

const typeField = {
  id: 'type',
  type: 'select',
  options: [],
  valueKey: 'value',
  labelKey: 'display_name',
  label: text.TYPE,
  clearable: false,
};

const formConfig = {
  form: FORM_ID,
  customFields: [
    providerField,
    typeField,
    {
      id: 'level0',
      type: 'number',
    }, {
      id: 'level1',
      type: 'number',
    }, {
      id: 'level2',
      type: 'number',
    }, {
      id: 'level3',
      type: 'number',
    },
  ],
};

const api = id => getDjangoApi(`ib/${id}/subib_commission`);

export const getLevel = depth => `level${depth}`;

const customFooter = (isEdit, onClose, onConfirm, submitInProgress) => (
  <SidepanelFooter
    execute={onConfirm}
    close={onClose}
    submitInProgress={submitInProgress}
    confirmColor="primary"
    buttonLabels={isEdit ? text.EDIT_BUTTON_LABEL : text.ADD_BUTTON_LABEL}
  />
);

class CommissionForm extends Component {
  componentWillUnmount() {
    const { actions } = this.props;
    actions.closeOverlay(SIDEPANEL_ID);
  }

  onSubmit = () => {
    const { isEdit } = this.props;
    isEdit ? this.onEdit() : this.onCreate();
  }

  onCreate = async () => {
    const { user } = this.props;
    const payload = this.getPayload();

    const [err] = await to(api(user.id).create(payload));
    err ? this.onError(err) : this.onSuccess();
  }

  onEdit = async () => {
    const { user, commission } = this.props;
    const payload = this.getPayload();
    const promisses = payload.map((data) => {
      const { pk } = commission[getLevel(data.depth)];
      return api(user.id).update(pk, data);
    });

    const [err] = await to(Promise.all(promisses));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess = () => {
    notifier.success(text.SUCCESS);
    this.closeSidepanel();
  }

  onError = (payload) => {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  setValues = () => {
    const { actions, commission: editCommission } = this.props;
    const commission = editCommission || this.getDefault();
    Object.keys(commission).forEach((key) => {
      switch (key) {
        case 'id':
          break;
        case 'level0':
        case 'level1':
        case 'level2':
        case 'level3':
          actions.setFieldValue({
            id: key,
            value: commission[key].value,
          });
          break;
        default:
          actions.setFieldValue({
            id: key,
            value: commission[key],
          });
      }
    });
  }

  getPayload = () => {
    const { values } = this.props;
    const { provider } = values;
    const [content_type, object_id] = provider.value.split('.');
    return [0, 1, 2, 3].map(depth => ({
      depth,
      content_type,
      object_id,
      source: values.type.value,
      value: values[getLevel(depth)],
    }));
  }

  getDefault = () => {
    const { options } = this.props;

    return {
      provider: options.providers.find(({ disabled }) => !disabled),
      type: options.types[0],
      level0: { value: 0 },
      level1: { value: 0 },
      level2: { value: 0 },
      level3: { value: 0 },
    };
  }

  getPercentage = () => {
    const SSV = 'ssv';
    const { values } = this.props;
    return _.get(values, 'type.value') === SSV ? ' (%)' : '';
  }

  closeSidepanel = () => {
    const { actions } = this.props;
    actions.closeOverlay(SIDEPANEL_ID);
    const { onSuccess } = this.props;
    onSuccess && onSuccess();
  }
  
  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { visible, isEdit, submitInProgress, options } = this.props;
    const TITLE = isEdit ? text.TITLE_EDIT : text.TITLE_ADD;
    const percetage = this.getPercentage();
    const Form = this.renderForm();
    const { types, providers } = options || {};
    return (
      <Sidepanel
        title={TITLE}
        onCloseIconClick={this.closeSidepanel}
        visible={visible}
        footerRender={() => customFooter(isEdit, this.closeSidepanel, this.onSubmit, submitInProgress)}
      >
        <Form
          onFormReady={this.setValues}
          renderForm={formFields => (
            <Fragment>
              <Field props={{ options: providers || [] }} field={formFields.provider} />
              <Field props={{ options: types || [] }} field={formFields.type} />
              <Field props={{ label: `${text.LEVEL0}${percetage}` }} field={formFields.level0} />
              <Field props={{ label: `${text.LEVEL1}${percetage}` }} field={formFields.level1} />
              <Field props={{ label: `${text.LEVEL2}${percetage}` }} field={formFields.level2} />
              <Field props={{ label: `${text.LEVEL3}${percetage}` }} field={formFields.level3} />
            </Fragment>
          )}
        />
      </Sidepanel>
    );
  }
}

export default CommissionForm;
