import Visibility from 'visibilityjs';

const POLL_TIME = 60000;

const jobs = {};

const runIfVisible = (fnToRun) => {
  if (Visibility.state() === 'visible') {
    fnToRun();
  }
};

const isJobPresent = job => !!jobs[job];

const startPoll = (job, fnToRun, interval = POLL_TIME) => {
  if (isJobPresent(job)) return;
  jobs[job] = setInterval(() => runIfVisible(fnToRun), interval);
};

const stopPoll = (job) => {
  if (!isJobPresent(job)) return;
  clearInterval(jobs[job]);
  delete jobs[job];
};

export { startPoll, stopPoll };
