import React from 'react';

import { Form, mapDispatchToProps, mapStateToProps } from '../Components/Form';
import connect from '../../../logic/connect';
import withErrorBoundary from '../../hoc/withErrorBoundary';

const FormFactory = ({ 
  api, 
  excludeFields,
  includeFields, 
  form, 
  customFields, 
  renderForm,
  disableResetOnUnmount,
}) => {
  const FormInstance = props => (
    <Form 
      formId={form} 
      api={api} 
      excludeFields={excludeFields} 
      includeFields={includeFields} 
      renderForm={renderForm}
      customFields={customFields}
      disableResetOnUnmount={disableResetOnUnmount}
      {...props} 
    />
  );

  return connect(
    mapStateToProps(form),
    mapDispatchToProps(form),
  )(withErrorBoundary(FormInstance));
};

export default FormFactory;
