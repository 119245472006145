import React from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, Icon } from 'tc-biq-design-system';

export const bulkRender = onChange => data => (
  // eslint-disable-next-line react/destructuring-assignment
  <Checkbox checked={data.checked} onChange={e => onChange(e.target.checked, data)} />
);


export const iconRender = (name, onClick) => data => (
  <Icon onClick={() => onClick(data)} name={name} />
);

export const routeRender = (iconName, path) => (
  <Link to={path}>
    <Icon name={iconName} />
  </Link>
);
