import React, { Component } from 'react';
import { Sidepanel, Space, notifier, Select } from 'tc-biq-design-system';
import { func, bool, object, array, oneOfType } from 'prop-types';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { ClientsList, SidepanelFooter } from '../../../../../components/common';
import to from '../../../../../logic/utilities/to';

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
  fetchTableData: func.isRequired,
  submitInProgress: bool,
  fieldData: oneOfType([array, object]),
  isIb: bool,
};

const defaultProps = {
  submitInProgress: false,
  fieldData: null,
  isIb: false,
};

const text = {
  TITLE: gettext('Assign cage'),
  SUBMIT_SUCCESS: gettext('Cage assigned'),
  SELECTED_USERS: gettext('Selected users'),
  BUTTON_LABELS: {
    confirm: gettext('Assign'),
    cancel: gettext('Discard changes'),
  },
};

const customFooter = (execute, close, submitInProgess) => (
  <SidepanelFooter 
    submitInProgress={submitInProgess}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

class ClientsAssignCage extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.apiCageBulk = getDjangoApi('users/bulk_cage_assign');
    this.apiCage = getDjangoApi('autocomplete/cages');

    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    this.loadOptions();
  }

  onClose = () => {
    this.actions.closeSidepanel('ASSIGN_CAGE');
    this.actions.resetInput();
  }

  onSuccess = () => {
    const { fetchTableData } = this.props;
    notifier.success(text.SUBMIT_SUCCESS);
    fetchTableData();
    this.onClose();
  }

  onError = (payload) => {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  onSubmit = async () => {
    const { sidepanel: { parameters }, actions } = this.props;
    const requestData = this.formatRequestData(parameters);
    
    const [err] = await to(actions.submit(this.apiCageBulk, requestData));
    err ? this.onError(err) : this.onSuccess();
  }

  formatRequestData = (data) => {
    const { fieldData } = this.props;
    const users = data.map(user => user.id);
    return {
      users,
      cage: fieldData.name,
    };
  }

  loadOptions = async () => {
    const { data } = await this.apiCage.list();

    this.setState({
      options: data.results,
    });
  }

  render() {
    const { sidepanel, submitInProgress, isIb, fieldData } = this.props;
    const { options } = this.state;
    const { visible, parameters } = sidepanel;

    return (
      <Sidepanel
        icon="Edit"
        type="info"
        title={text.TITLE}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.onSubmit, this.onClose, submitInProgress)}
      >
        <Space size={16} />
        <Select
          placeholder={text.PLACEHOLDER}
          onInputChange={this.onInputChange}
          onChange={this.actions.updateData}
          value={fieldData}
          clearable={!_.isEmpty(fieldData)}
          options={options}
          valueKey="id"
          labelKey="name"
        />
        {visible && !_.isEmpty(parameters) ? (
          <h4>{`${text.SELECTED_USERS} (${parameters.length})`}</h4>
        ) : null}
        {visible && !_.isEmpty(parameters) ? (
          <ClientsList clients={parameters} hideUserState isIb={isIb} />
        ) : null}
      </Sidepanel>
    );
  }
}

ClientsAssignCage.defaultProps = defaultProps;
ClientsAssignCage.propTypes = propTypes;

export default ClientsAssignCage;
