/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Button, notifier } from 'tc-biq-design-system';
import { Link } from 'react-router-dom';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import overlayActions from '../../../../components/overlay';
import Page from '../../../../components/Page';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import DeleteClientVisibilityGroupSidepanel from './Sidepanel/deleteClientVisibilityGroup';
import modifiers from './modifiers';
import If from '../../../../components/If';
import { BiqUserPreview } from '../../../../components/common/sidepanels';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const text = {
  PAGE_TITLE: gettext('Client visibility groups'),
  BIQ_BREADCRUMB: gettext('BrokerIQ'),
  CREATE_NEW_GROUP_BUTTON_LABEL: gettext('Create new'),
  BULK_DELETE_LABEL: gettext('Delete'),
  SUCCESS: gettext('Group deleted successfully'),
};

const pageConfig = {
  apiUrl: 'settings/object_acl_group',
  reducerKey: 'CLIENT_VISIBILITY_GROUPS',
  tableKey: 'CLIENT_VISIBILITY_GROUPS_TABLE',
};

const { GridComponent, actions } = GridFactory(pageConfig);
const bread = [{ label: text.PAGE_TITLE, route: '' }];

const headerActions = () => (
  <If condition={hasAccess('object_acl_group.settings.create')}>
    <Link to={appRoutes.CLIENT_VISIBILITY_GROUP_CREATE}>
      <Button startPropagation>{text.CREATE_NEW_GROUP_BUTTON_LABEL}</Button>
    </Link>
  </If>
);

class ClientVisibilityGroups extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.bulkActions = [
      ...(hasAccess('object_acl_group.settings.destroy') && [{
        icon: 'Delete',
        label: text.BULK_DELETE_LABEL,
        onClick: this.actions.openSidepanel.bind(this, 'DELETE_CLIENT_VISIBILITY_GROUP'),
      }]),
    ];

    this.tableActions = {
      modify: hasAccess('object_acl_group.settings.update'),
      remove: hasAccess('object_acl_group.settings.destroy') && this.onRemove.bind(this),
      previewBiqUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  onRemove = (data) => {
    this.actions.deleteRow(data).then(() => {
      notifier.success(text.SUCCESS);
    }, (error) => {
      notifier.error(error.data.detail ? error.data.detail : text.ERROR);
    });
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifiers(this.tableActions),
    };

    return (
      <Page headerActions={headerActions} bread={bread} title={text.PAGE_TITLE}>
        <Filters {...filterProps} />
        <GridComponent
          sizeColumnsToFit
          checkboxSelection={!!this.bulkActions.length}
          singleActions={this.tableActions.modify}
          bulkActions={this.bulkActions}
          tableModifier={modifiers(this.tableActions)}
          onRowSelected={data => this.actions.updateSidepanel('DELETE_CLIENT_VISIBILITY_GROUP', data)}
          {...this.props}
        />
        <DeleteClientVisibilityGroupSidepanel tableActions={this.actions} />
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      updateRowData: actions.updateRowData,
      addActionsColumn: actions.addActionsColumn,
      fetchTableData: actions.fetchTableData,
      deleteRow: actions.deleteRow,
      openSidepanel: overlayActions.open,
      updateSidepanel: overlayActions.update,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
    },
    dispatch,
  ),
});

export default compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(ClientVisibilityGroups);
