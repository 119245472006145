import React, { useCallback, Fragment } from 'react';
import { Modal, Button } from 'tc-biq-design-system';

import { string, func, bool, object } from 'prop-types';
import { bindActionCreators } from 'redux';
import { text } from './helper';
import { getOverlayParameters, getOverlayVisibility } from '../../../overlay/reducer';
import overlayActions from '../../../overlay';
import connect from '../../../../logic/connect';

const CONFIRM_ACTION_MODAL_ID = 'CONFIRM_ACTION_MODAL';

const ConfirmActionModal = (props) => {
  const { title, message, onConfirm, visible, actions, parameters } = props;

  const handleClose = useCallback(() => {
    actions.close(CONFIRM_ACTION_MODAL_ID);
  }, [actions]);

  const handleConfirm = useCallback(() => {
    const result = onConfirm();
    result && result.then ? result.then(handleClose) : handleClose();
  }, [onConfirm, handleClose]);

  const footerRender = useCallback(() => (
    <Fragment>
      <Button onClick={handleClose} color="ghost">
        <span>{ text.CANCEL }</span>
      </Button>
      <Button onClick={handleConfirm} color="confirmation">
        <span>{ text.CONFIRM }</span>
      </Button>
    </Fragment>
  ), [onConfirm]);
  
  return (
    <Modal
      footerRender={footerRender}
      className="confirm-action-modal"
      visible={visible}
      title={title || parameters.title || text.DEFAULT_TITLE}
    >
      { message || parameters.message || text.DEFAULT_MESSAGE }
    </Modal>
  );
};

ConfirmActionModal.propTypes = {
  title: string,
  message: string,
  onConfirm: func.isRequired,
  visible: bool,
  actions: object,
  parameters: object.isRequired,
};

ConfirmActionModal.defaultProps = {
  title: '',
  message: '',
  visible: false,
  actions: {},
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      close: overlayActions.close,
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  parameters: getOverlayParameters(state, CONFIRM_ACTION_MODAL_ID),
  visible: getOverlayVisibility(state, CONFIRM_ACTION_MODAL_ID),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmActionModal);
