import React, { Component } from 'react';
import { Space, HyperLink, DashboardCard } from 'tc-biq-design-system';
import { string } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import DashboardNumeric from '../../Numeric';
import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import { currencyFormatter } from '../../../../../../../../logic/utilities/formatters';
import withFilters from '../../hoc/withFilters';
import appRoutes from '../../../../../../../../components/App/Router/appRoutes';

const size = {
  w: 2,
  h: 5,
  minW: 2,
  minH: 5,
  maxW: 3,
  maxH: 5,
  y: 0,
  x: 10,
};

const api = getDjangoApi('dashboard/pending_withdrawals');

const propTypes = {
  title: string.isRequired,
  description: string.isRequired,
};

class PendingWithdrawals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingWithdrawals: {},
      loading: false,
    };
  }

  onQueryReady = async (query) => {
    this.setState({ loading: true });
    const { data } = await api.list(query);

    this.setState({
      pendingWithdrawals: data,
      loading: false,
    });
  }

  render() {
    const { title, description } = this.props;
    const { pendingWithdrawals: { amount, currency, number }, loading } = this.state;
    const withdrawalAmount = currencyFormatter(amount, currency);
    const withdrawalNumber = number || 0;
    return (
      <DashboardCard
        title={title}
        description={description}
        loading={loading}
      >
        <DashboardNumeric>
          <DashboardNumeric.Wrapper>
            <Space size={5} />
            <DashboardNumeric.Price>{withdrawalAmount}</DashboardNumeric.Price>
            <Space size={12} />
            <HyperLink>
              <Link to={generatePath(appRoutes.PENDING_WITHDRAWALS)}>
                <DashboardNumeric.Number color="red" iconName="Withdrawal">{withdrawalNumber}</DashboardNumeric.Number>
              </Link>
            </HyperLink>
          </DashboardNumeric.Wrapper>
        </DashboardNumeric>
      </DashboardCard>
    );
  }
}

PendingWithdrawals.propTypes = propTypes;

export default ({ removeDateFilters } = {}) => ({
  component: withFilters(PendingWithdrawals, { removeDateFilters }),
  size,
});
