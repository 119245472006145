/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Button, notifier } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';

import connect from '../../../../logic/connect';
import isFeatureEnabled from '../../../../logic/filters/is-feature-enabled';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import If from '../../../../components/If';
import Page from '../../../../components/Page';
import overlayActions from '../../../../components/overlay';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import AddEditExchangeRate from './Sidepanels';
import { SIDEPANEL_ID as EXCHANGE_RATE_SIDEPANEL_ID } from './Sidepanels/AddEditExchangeRate';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';
import { exchangeRatesModifier } from './modifier';

const pageConfig = {
  apiUrl: 'settings/rates',
  reducerKey: 'EXCHANGE_RATES',
  tableKey: 'EXCHANGE_RATES_TABLE',
};

const { GridComponent, actions } = GridFactory(pageConfig);

const text = {
  TITLE: gettext('Exchange rates'),
  DELETE_SUCCESS: gettext('Exchange rate deleted successfully'),
  DELETE_ERROR: gettext('Error while deleting exchange rate'),
  TOGGLE_SUCCESS: gettext('Exchange rate successfully updated'),
  TOGGLE_ERROR: gettext('Error while updating exchange rate'),
  CREATE_NEW: gettext('Create new'),
};

const bread = [{ label: text.TITLE, route: appRoutes.SETTINGS_EXCHANGE_RATES }];

class ExchangeRates extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.headerActions = this.headerActions.bind(this);

    this.tableActions = {
      ...(hasAccess('settings.rates.destroy') && { onDelete: this.onDelete.bind(this) }),
    };
  }

  onEdit(data) {
    this.actions.openSidepanel(EXCHANGE_RATE_SIDEPANEL_ID, { type: 'edit', data });
  }

  onDelete(data) {
    this.actions.deleteRow(data)
      .then(() => {
        notifier.success(text.DELETE_SUCCESS);
      }, () => {
        notifier.error(text.DELETE_ERROR);
      });
  }

  headerActions() {
    return (
      <If condition={hasAccess('settings.rates.create')}>
        <Button onClick={() => this.actions.openSidepanel(EXCHANGE_RATE_SIDEPANEL_ID, { type: 'add' })}>
          {text.CREATE_NEW}
        </Button>
      </If>
    );
  }

  render() {
    const { fields, actions, table, addEditExchangeRate } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: exchangeRatesModifier(this.tableActions),
    };

    return (
      <Page bread={bread} headerActions={this.headerActions} title={text.TITLE}>
        <Filters {...filterProps} />
        <GridComponent
          singleActions={!!Object.keys(this.tableActions).length}
          tableModifier={exchangeRatesModifier(this.tableActions)}
          {...this.props}
        />
        <If condition={addEditExchangeRate.visible}>
          <AddEditExchangeRate onSuccess={this.actions.fetchTableData} />
        </If>
      </Page>
    );
  }
}

const mapStateToProps = ({ pages, overlays }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];

  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
    addEditExchangeRate: overlays.ADD_EDIT_EXCHANGE_RATE,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    updateRowData: actions.updateRowData,
    deleteRow: actions.deleteRow,
    fetchTableData: actions.fetchTableData,
    openSidepanel: overlayActions.open,
    updateFilter: actions.updateFilter,
    changeSegment: actions.changeSegment,
    fetchOptions: actions.fetchOptions,
  }, dispatch),
});

const ExchangeRatesPage = compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(ExchangeRates);

export default {
  component: ExchangeRatesPage,
  path: appRoutes.SETTINGS_EXCHANGE_RATES,
  aclFn: () => hasAccess('settings.rates.list') && !isFeatureEnabled()('COMMUNICATION_FLOW'),
};
