import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionTypes from '../types';
import TradingAccountForm from '../../TradingAccountForm';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const env = window.config.environment;

const text = {
  LABEL: gettext('New trading account'),
};

const ACTION_ICON = 'Account';

export default {
  name: 'newTradingAccount',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.TRADING_ACCOUNTS,
  hasPermission: async (state, _user, isIb) => {
    if (isIb) return false;
    const hasStatePermission = [
      USER_STATE.FULL,
      USER_STATE.LIMITED,
      USER_STATE.REVIEW,
      USER_STATE.DEMO,
    ].includes(state);
    let result = hasAccess('user.trading_accounts.create') && hasStatePermission;
    if (env === 'AvaTrade') {
      const api = getDjangoApi(`users/${_user.id}/allowed_trading_accounts`);
      const res = await api.retrieve();
      result = result && res.data?.results?.length > 0;
    }
    return result;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
      isNew: true,
      key: 'new-trading-account',
    };

    sidepanelManager.add(TradingAccountForm, sidepanelOptions);
  },
};
