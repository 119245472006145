import React, { Component } from 'react';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { object, func, bool } from 'prop-types';

import connect from '../../../../../../../logic/connect';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';
import overlayActions from '../../../../../../../components/overlay';
import to from '../../../../../../../logic/utilities/to';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { SidepanelFooter } from '../../../../../../../components/common';

const SIDEPANEL_ID = 'PAYMENT_MAPPING_FORM';
const { create, update, resetFields, setRowData } = FormActionsFactory(SIDEPANEL_ID);

const text = {
  CREATE_TITLE: gettext('Add new mapping'),
  CREATE_BUTTON_LABEL: {
    confirm: gettext('Add'),
    cancel: gettext('Discard'),
  },
  SUBMIT_SUCCESS: gettext('Mapping added successfully'),
  SUBMIT_ERROR: gettext('Error while saving mapping'),
  METHOD: 'Method',
  IDENTIFIER: 'Identifier',
};

const customFooter = (execute, close, submitInProgress) => (
  <SidepanelFooter
    execute={execute} 
    close={close} 
    submitInProgress={submitInProgress} 
    confirmColor="primary"
    buttonLabels={text.CREATE_BUTTON_LABEL}
    formId={SIDEPANEL_ID}
  />
);

const formModifiers = () => ({
  name: {
    disabled: true,
  },
});

const propTypes = {
  sidepanel: object.isRequired,
  actions: object.isRequired,
  onSuccessRequest: func,
  submitInProgress: bool,
};

const defaultProps = {
  onSuccessRequest: null,
  submitInProgress: false,
};

class PaymentMappingForm extends Component {
  onSuccess = () => {
    const { onSuccessRequest } = this.props;
    this.close();
    onSuccessRequest && onSuccessRequest();
    notifier.success(text.SUBMIT_SUCCESS);
  }

  getRequestPayload = ({ identifier }) => {
    const { sidepanel: { parameters } } = this.props;
    const { identifiers } = parameters.data;
    return {
      identifiers: [...identifiers, identifier],
    };
  };

  api = () => {
    const { sidepanel: { parameters } } = this.props;
    const pgid = parameters && parameters.pgid;
    return getDjangoApi(`payment_gateways/${pgid}/identifiers`);
  }

  formConfig = () => ({
    form: SIDEPANEL_ID,
    customFields: [
      {
        id: 'method',
        name: 'method',
        label: text.METHOD,
        type: 'text',
        disabled: true,
      }, {
        id: 'identifier',
        name: 'identifier',
        label: text.IDENTIFIER,
        type: 'text',
      },
    ],
  });

  submit = async () => {
    const { actions, sidepanel } = this.props;
    const { parameters } = sidepanel;
    
    const request = actions.update(this.api(), parameters.data.id, this.getRequestPayload);

    const [err, res] = await to(request);
    if (err) this.showError(err);
    if (res) this.onSuccess();
  }

  showError = ({ data }) => {
    if (data && data.non_field_errors) {
      notifier.error(data.non_field_errors[0]);
    } else {
      notifier.error(text.SUBMIT_ERROR);
    }
  }

  close = () => {
    const { actions } = this.props;
    actions.closeOverlay(SIDEPANEL_ID);
    this.FormComponent = null;
    actions.resetFields();
  }

  renderForm = () => {
    if (!this.FormComponent) {
      const { sidepanel, actions } = this.props;
      const { parameters } = sidepanel;
      
      if (parameters) {
        const data = { ...parameters.data };
        actions.setRowData(data);
        this.FormComponent = FormFactory(this.formConfig());
      }
    }

    const { FormComponent } = this;
    return FormComponent && <FormComponent formId={SIDEPANEL_ID} modifiers={formModifiers()} />;
  }

  render() {
    const { sidepanel, submitInProgress } = this.props;
    const { visible } = sidepanel;

    return (
      <Sidepanel
        visible={visible}
        title={text.CREATE_TITLE}
        icon="Payments"
        onCloseIconClick={() => this.close()}
        footerRender={() => customFooter(this.submit, this.close, submitInProgress)}
      >
        {this.renderForm()}
      </Sidepanel>
    );
  }
}

PaymentMappingForm.propTypes = propTypes;
PaymentMappingForm.defaultProps = defaultProps;

const mapStateToProps = ({ overlays, forms }) => {
  const { fields, submitInProgress } = forms[SIDEPANEL_ID];

  return {
    sidepanel: overlays[SIDEPANEL_ID],
    form: fields,
    submitInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeOverlay: overlayActions.close,
      setRowData,
      create,
      update,
      resetFields,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentMappingForm);
