import appRoutes from '../../../../components/App/Router/appRoutes';
import { RouteCell, UsernameCell } from '../../../../components/gridCellRenderers';
import queryOperators from '../../../../logic/enums/query-operators';
import deselectCols from '../../../../logic/utilities/deselectCols';

const LinkToAccount = () => ({ value, data, colDef }) => {
  const stateParams = { userId: data.user.id, accountId: data.trading_account_id };
  const Component = RouteCell({ href: appRoutes.TRADING_ACCOUNT, stateParams, withId: false });
  return Component({ value, data, colDef });
};

export default onPreview => ({
  ...deselectCols(['trading_account_id']),
  id: {
    sortValue: -99,
  },
  user: {
    sortValue: -98,
    cellRendererFramework: UsernameCell(onPreview),
  },
  reason: {
    quickFilter: {
      operator: queryOperators.EXACT.value,
    },
  },
  wallet: {
    cellRendererFramework: LinkToAccount(),
  },
});
