import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import IB_STATE from '../../../../../../../logic/enums/ib-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionTypes from '../types';
import IbChangeBackends from './IbChangeBackends';

const env = window.config.environment;

const text = {
  LABEL: gettext('Ib change backends'),
};

const ACTION_ICON = 'Edit';

export default {
  name: 'ibChangeBackends',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    if (isIb) {
      return !user.is_archived 
      && state !== IB_STATE.PENDING 
      && state !== IB_STATE.INCOMPLETE 
      && (hasAccess('ib.trading_backends.*') || hasAccess('ib.trading_backends.groups.*'))
      && env !== 'AvaTrade';
    } 
    return false;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
      editBackends: true,
      key: Date.now(), // force render each time user click on action
    };

    sidepanelManager.add(IbChangeBackends, sidepanelOptions);
  },
};
