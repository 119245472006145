import React from 'react';
import { DatePicker } from 'tc-biq-design-system';
import { string, bool } from 'prop-types';

const propTypes = {
  value: string,
  withPortal: bool,
};

const defaultProps = {
  value: null,
  withPortal: false,
};

const DateInput = ({ value, withPortal, ...rest }) => (
  <DatePicker
    {...rest}
    portalId={withPortal ? 'app' : null}
    date={value}
    showYearDropdown
    showMonthDropdown
  />
);

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

export default DateInput;
