import React from 'react';
import { string, bool, any } from 'prop-types';
import { Col, Icon, Space } from 'tc-biq-design-system';

const propTypes = {
  icon: string.isRequired,
  label: string.isRequired,
  half: bool,
  children: any.isRequired,
};

const defaultProps = {
  half: false,
};

const TaskItem = ({ icon, label, half, children }) => (
  <Col gutter={0} sm={half ? '50%' : '100%'}>
    <div className="task-item">
      <div className="task-item-label">
        <Icon colorName="text-neutral-500" name={icon} size="small" />
        <Space size={4} horizontal />
        <span>{label}</span>
      </div>
      <div className="task-item-content">{children}</div>
    </div>
  </Col>
);

TaskItem.propTypes = propTypes;
TaskItem.defaultProps = defaultProps;

export default TaskItem;
