import React from 'react';
import { Icon } from 'tc-biq-design-system';
import { string, func } from 'prop-types';
import HeaderCheckbox from './HeaderCheckbox/HeaderCheckbox';

const propTypes = {
  title: string.isRequired,
  status: string.isRequired,
  onChange: func.isRequired,
};

const AccordionHeader = ({ title, status, onChange }) => (
  <div className="header">
    <div className="accordion-arrow"><Icon name="CaretRight" size="extraSmall" /></div>
    <div className="title">{title}</div>
    <div className="acl-card__checkbox">
      <HeaderCheckbox status={status} onChange={onChange} />
    </div>
  </div>
);

AccordionHeader.propTypes = propTypes;

export default AccordionHeader;
