import React, { PureComponent } from 'react';
import { Row, Col, Block } from 'tc-biq-design-system';
import { array } from 'prop-types';

import Page from '../../../../../../components/Page';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import IntegrationForm from './IntegrationForm';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

const text = {
  TITLE: gettext('Integration Settings'),
};

const bread = [{ label: gettext('Integrations'), route: appRoutes.SETTINGS_INTEGRATIONS }];

const createBlockButtons = ({ name, onConfigure }) => [
  ...(onConfigure ? [{
    buttonType: 'button',
    text: 'Configure',
    color: 'ghost',
    onClick: () => onConfigure(name),
  }] : []),
];

const createBlockActions = ({ onEdit, name }) => [
  ...(onEdit ? [{
    colorName: 'text-primary-900',
    name: 'Edit',
    onClick: () => onEdit(name),
  }] : []),
];

const propTypes = {
  integrations: array,
};

const defaultProps = {
  integrations: [],
};

class Integrations extends PureComponent {
  render() {
    const { integrations, ...rest } = this.props;
    return (
      <Page bread={bread} title={text.TITLE} style={{}}>
        <Row gutter={11}>
          {integrations.map(({ name, text, onConfigure, onEdit, logo }) => (
            <Col key={name} sm="1/3">
              <Block logo={logo} title={text} footerActions={createBlockActions({ onEdit, name })} footerButtons={createBlockButtons({ name, onConfigure })} />
            </Col>
          ))}
        </Row>
        <IntegrationForm {...rest} />
      </Page>
    );
  }
}

Integrations.propTypes = propTypes;
Integrations.defaultProps = defaultProps;

export default Integrations;
