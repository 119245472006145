import React from 'react';
import { Toggle, Icon } from 'tc-biq-design-system';
import { object, func } from 'prop-types';

import { hasAccess } from '../../../../../../logic/services/acl';

const propTypes = {
  doc: object.isRequired,
  preset: object.isRequired,
  updatePreset: func.isRequired,
  editDocument: func.isRequired,
};

const SinglePresetDoc = ({ doc, preset, updatePreset, editDocument }) => (
  <div className="biq-settings-documents__singlepreset__doc">
    <div>
      <Toggle
        checked={preset.document_types.includes(doc.value)}
        onClick={e => updatePreset(doc.value, preset.id, e)}
        disabled={!hasAccess('document_presets.create')}
      />
      <span className="title">{doc.display_name}</span>
    </div>
    {hasAccess('document_types.update') && hasAccess('document_types.create') && (
      <span
        className="edit-doc"
        onClick={e => editDocument(e, { type: 'edit', docId: doc.value })}
      >
        <Icon colorName="text-primary-900" name="Edit" />
      </span>
    )}
  </div>
);

SinglePresetDoc.propTypes = propTypes;

export default SinglePresetDoc;
