import { bindActionCreators } from 'redux';

import Selector from './Selector';
import overlayActions from '../../../overlay';
import connect from '../../../../logic/connect';

export default function selectorFactory(MODAL_ID) {
  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
      close: overlayActions.close,
      update: overlayActions.update,
    }, dispatch),
  });

  const mapStateToProps = ({ overlays }) => ({ modal: overlays[MODAL_ID], modalId: MODAL_ID });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Selector);
}
