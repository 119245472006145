/* eslint-disable react/prop-types */
import React from 'react';
import { TradingBackendsCell, UsernameCell } from '../../../components/gridCellRenderers';
import queryOperators from '../../../logic/enums/query-operators';
import { countryFormatter, currencyFormatter } from '../../../logic/utilities/formatters';

export default ({ previewUser, previewBiqUser }) => ({
  id: {
    width: 80,
  },
  username: {
    cellRendererFramework: UsernameCell(previewUser),
    width: 200,
  },
  first_name: {
    width: 150,
  },
  last_name: {
    width: 150,
  },
  trading_backends: {
    cellRendererFramework: TradingBackendsCell,
    quickFilter: {
      operator: queryOperators.IN.value,
    },
  },
  country: {
    // eslint-disable-next-line react/prop-types
    cellRendererFramework: ({ data }) => <span>{countryFormatter(data.addr_country)}</span>,
  },
  total_deposit: {
    // eslint-disable-next-line max-len
    cellRendererFramework: ({ data }) => <span>{currencyFormatter(data.total_deposit, data.currency.symbol)}</span>,
  },
  assigned_to: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
});
