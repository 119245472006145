import React from 'react';
import { object, shape, oneOfType, string, node, array } from 'prop-types';
import { Space, DashboardCard } from 'tc-biq-design-system';

import { gettext } from '../../../../../../logic/utilities/languageUtility';

const trans = {
  INFORMATION: gettext('Information'),
};

const propTypes = {
  data: object.isRequired,
  fields: array.isRequired,
  text: object.isRequired,
  modifers: object,
  infoActions: array,
  top: shape({
    label: string,
    value: oneOfType([node, string]),
  }),
};

const defaultProps = {
  modifers: {},
  infoActions: null,
  top: null,
};

const InfoCard = ({ data, fields, text, modifers, top, infoActions }) => (
  <div className="campaign-header__info">
    <DashboardCard
      title={trans.INFORMATION}
      description={trans.INFORMATION}
      actions={infoActions}
    >
      <Space size={16} />
      {fields.map(key => (
        <div key={key} className="campaign-header__detail">
          <span className="campaign-header__detail__label tc-micro-regular">{text.TITLES[key]}</span>
          <p className="tc-paragraph-regular">{modifers[key] ? modifers[key](data) : data[key]}</p>
        </div>
      ))}
      {top && (
        <div className="campaign-header__detail campaign-header__detail--top-right">
          <span className="campaign-header__detail__label tc-micro-regular">{top.label}</span>
          <p className="tc-paragraph-regular">{top.value}</p>
        </div>
      )}
      <Space size={16} />
    </DashboardCard>
  </div>
);

InfoCard.propTypes = propTypes;
InfoCard.defaultProps = defaultProps;

export default InfoCard;
