/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Sidepanel, Space, notifier } from 'tc-biq-design-system';
import { object, array } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import overlayActions from '../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../components/common';

const propTypes = { tableActions: object.isRequired };

const sidepanelBodyPropTypes = {
  data: array,
};

const sidepanelBodyDefaultProps = {
  data: [],
};

const text = {
  BUTTON_LABELS: {
    confirm: gettext('Delete'),
    cancel: gettext('Cancel'),
  },
  SUCCESS: gettext('Event(s) deleted successfully'),
  ERROR: gettext('Failed deleting event(s)'),
  SIDEPANEL_BODY_SINGLE: gettext('Are you sure you want to delete this rule?'),
  SIDEPANEL_BODY_MULTIPLE: gettext('Are you sure you want to delete these rules?'),
  SIDEPANEL_TITLE_SINGLE: gettext('Delete email event'),
  SIDEPANEL_TITLE_MULTIPLE: gettext('Delete email events'),
};

const SIDEPANEL_ID = 'DELETE_EMAIL_EVENT';

const customFooter = (execute, close, submitInProgress) => (
  <SidepanelFooter
    execute={execute}
    close={close}
    submitInProgress={submitInProgress}
    confirmColor="destructive"
    buttonLabels={text.BUTTON_LABELS}
  />
);

const SidepanelBody = ({ data }) => (
  <div>
    <Space size={16} />
    {data.length < 2 ? text.SIDEPANEL_BODY_SINGLE : text.SIDEPANEL_BODY_MULTIPLE}
    <Space size={16} />
    <ul>
      {data.map(item => (
        <li key={item.id}>{item.name}</li>
      ))}
    </ul>
  </div>
);

class DeleteEmailEventSidepanel extends Component {
  constructor(props) {
    super(props);
    this.actions = { ...props.actions, ...props.tableActions };
    this.execute = this.execute.bind(this);
    this.onClose = this.onClose.bind(this);
    this.api = getDjangoApi('emails/trigger_rules');
    this.state = { submitInProgress: false };
  }

  componentWillUnmount() {
    this.actions.close(SIDEPANEL_ID);
  }

  onClose(submitSuccess) {
    this.actions.close(SIDEPANEL_ID);
    if (submitSuccess) this.actions.fetchTableData();
  }

  execute() {
    const { data } = this.props;
    if (!data.length) return;
    // TODO: replace this API when https://tradecrowd.atlassian.net/browse/BIQS-3204 is resolved
    const deleteAPI = getDjangoApi(`emails/trigger_rules/${data[0].id}`);
    this.setState({ submitInProgress: true });
    this.actions.deleteRow(data, deleteAPI).then(() => {
      this.onClose(true);
      notifier.success(text.SUCCESS);
    }, (error) => {
      const errorMessage = error.data.detail ? error.data.detail : text.ERROR;
      notifier.error(errorMessage);
    });
  }

  render() {
    const { submitInProgress } = this.state;
    const { data } = this.props;
    const title = data.length > 1 ? text.SIDEPANEL_TITLE_MULTIPLE : text.SIDEPANEL_TITLE_SINGLE;

    return (
      <Sidepanel
        type="error"
        icon="Close"
        title={title}
        visible={data}
        onCloseIconClick={() => this.actions.close(SIDEPANEL_ID)}
        footerRender={() => customFooter(this.execute, this.onClose, submitInProgress)}
      >
        <SidepanelBody data={data} />
      </Sidepanel>
    );
  }
}

DeleteEmailEventSidepanel.propTypes = propTypes;
SidepanelBody.propTypes = sidepanelBodyPropTypes;
SidepanelBody.defaultProps = sidepanelBodyDefaultProps;

const mapStateToProps = ({ overlays }) => ({ data: overlays[SIDEPANEL_ID].parameters });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ close: overlayActions.close }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(DeleteEmailEventSidepanel));
