import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { Space } from 'tc-biq-design-system'; 
import { object } from 'prop-types';

import connect from '../../../../../../../logic/connect';
import { tradingAccountDisplay } from '../../../../../../../logic/filters/trading-account-display';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import { fetchRate } from '../Credit/Model';
import { fetchTradingAccounts } from '../../TradingAccountForm/Model';
import { Field } from '../../../../../../../components/form/logic/utils';

const FORM_KEY = 'EXTERNAL_TRANSFER_FORM';
const { setFields, setFieldValue } = FormActionsFactory(FORM_KEY);

const text = {
  TITLE: gettext('External transfer'),
  SOURCE_CLIENT: gettext('Source client'),
  SOURCE_ACCOUNT: gettext('Source trading account'),
  SOURCE_AMOUNT: gettext('Source amount'),
  TARGET_CLIENT: gettext('Target client'),
  TARGET_ACCOUNT: gettext('Target trading account'),
  TARGET_AMOUNT: gettext('Target amount'),
  SUCCESS: gettext('Amount is transferred.'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const propTypes = {
  user: object,
  formValues: object,
  actions: object,
};

const defaultProps = {
  user: null,
  formValues: null,
  actions: null,
};


class ExternalTransferForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sourceAccountOptions: [],
      targetAccountOptions: [],
    };
  }

  componentDidMount() {
    this.setFields();
  }

  componentDidUpdate(prevProps) {
    this.onSourceAmountChange(prevProps);
    this.onSourceClientChange(prevProps);
    this.onTargetClientChange(prevProps);
    this.onTargetAccountChange(prevProps);
  }

  onSourceAmountChange = (prevProps) => {
    const { actions } = this.props;
    const amount = _.get(this.props, 'formValues.amount');
    const prevAmount = _.get(prevProps, 'formValues.amount');
    
    if (amount !== prevAmount) {
      const currencySource = _.get(this.props, 'formValues.account_from.currency.symbol');
      const currencyTarget = _.get(this.props, 'formValues.account_to.currency.symbol');

      if (currencySource && currencyTarget) {
        actions.fetchRate(currencySource, currencyTarget).then(({ value }) => {
          this.setField('exchange_rate', Number(value).toFixed(2));
          this.setField('target_amount', amount * value);
        });
      }
    }
  }

  onSourceClientChange = (prevProps) => {
    const { actions } = this.props;
    const sourceClient = _.get(this.props, 'formValues.source_client');
    const prevSourceClient = _.get(prevProps, 'formValues.source_client');
    
    if (sourceClient !== prevSourceClient && sourceClient) {
      const accountUserId = _.get(this.props, 'formValues.account_from.user.id');

      if (sourceClient.id !== accountUserId) {
        let newAccount = null;
        actions.fetchTradingAccounts(sourceClient).then(({ value }) => {
          if (!_.isEmpty(value)) {
            const mappedAccounts = this.transformTradingAccounts(value);
            newAccount = mappedAccounts && mappedAccounts[0];
            this.setState({
              sourceAccountOptions: mappedAccounts,
            });
          }

          this.setField('account_from', newAccount);
        });
      }
    }
  }

  onTargetAccountChange = (prevProps) => {
    const targetAccount = _.get(this.props, 'formValues.account_to');
    const targetAccountId = targetAccount && targetAccount.id;
    const prevTargetAccountId = _.get(prevProps, 'formValues.account_to.id');

    if (targetAccountId !== prevTargetAccountId && targetAccount) {
      const accountUser = targetAccount.user;
      this.setField('target_client', { ...accountUser, display_name: accountUser.username });
    }
  }

  onTargetClientChange = (prevProps) => {
    const { actions } = this.props;
    const targetClient = _.get(this.props, 'formValues.target_client');
    const prevTargetClient = _.get(prevProps, 'formValues.target_client');
    
    if (targetClient !== prevTargetClient && targetClient) {
      const accountUserId = _.get(this.props, 'formValues.account_to.user.id');

      if (targetClient.id !== accountUserId) {
        let newAccount = null;
        actions.fetchTradingAccounts(targetClient).then(({ value }) => {
          if (!_.isEmpty(value)) {
            const mappedAccounts = this.transformTargetTradingAccounts(value);
            newAccount = mappedAccounts && mappedAccounts[0];
            this.setState({
              targetAccountOptions: mappedAccounts,
            });
          }

          this.setField('account_to', newAccount);
        });
      }
    }
  }

  setField = (id, value) => {
    const { actions } = this.props;
    actions.setFieldValue({ id, value });
  }

  setFields = () => {
    const { actions, user } = this.props;

    const generateClientSelect = (id, label, user) => ({
      id,
      name: id,
      label,
      required: false,
      clearable: false,
      type: 'search',
      async: true,
      loadOptions: this.loadClientOptions(id, user),
      debounceInterval: 500,
      valueKey: 'id',
      labelKey: 'display_name',
    });

    const generateAmount = (id, label) => ({
      id,
      label,
      type: 'number',
    });

    const generateTradingAccount = (id, label) => ({
      id,
      name: id,
      label,
      required: false,
      clearable: false,
      type: 'search',
      options: [],
      debounceInterval: 500,
      valueKey: 'id',
      labelKey: 'display_name',
    });

    actions.setFields([
      generateClientSelect('source_client', text.SOURCE_CLIENT, user),
      generateTradingAccount('account_from', text.SOURCE_ACCOUNT),
      generateAmount('amount', text.SOURCE_AMOUNT),
      generateClientSelect('target_client', text.TARGET_CLIENT, null),
      generateTradingAccount('account_to', text.TARGET_ACCOUNT),
      generateAmount('target_amount', text.TARGET_AMOUNT),
    ]);
  }

  getFormConfig = () => ({
    form: FORM_KEY,
  })

  loadClientOptions = (id, defaultUser) => (input) => {
    const { actions } = this.props;
    const usersApi = getDjangoApi('autocomplete/client-users');
    return usersApi
      .list({ username: input })
      .then((res) => {
        let { results } = res.data;
        const isDefault = defaultUser && _.isEmpty(input);

        if (isDefault) {
          results = [defaultUser, ...results];
        }

        const mappedResponse = this.transformClients(results);

        if (isDefault) {
          results = [defaultUser, ...results];
          actions.setFieldValue({
            id,
            value: mappedResponse[0],
          });
        }

        return mappedResponse;
      });
  }

  loadAccountOptions = (user, transformAccount) => () => {
    const taApi = getDjangoApi('autocomplete/trading-accounts');

    if (_.isEmpty(user)) return Promise.resolve([]);
    
    return taApi
      .list({
        user: user.id,
      })
      .then((res) => {
        const { results } = res.data;

        const mappedResponse = transformAccount(results);

        return mappedResponse;
      });
  }

  formatAccountLabel = (account) => {
    const hideTypeCurrency = false;
    const withBalance = true;
    return { 
      ...account, 
      display_name: tradingAccountDisplay(account, hideTypeCurrency, withBalance), 
    };
  };

  keepLiveAccounts = account => account.backend && account.backend.mode === 'Live';

  keepCurrentUsersAccounts = (account) => {
    const { formValues } = this.props;
    const targetClient = formValues.target_client;
    if (!targetClient) return true;
    return account.user.id === targetClient.id;
  }

  transformTradingAccounts = accounts => accounts
    .filter(this.keepLiveAccounts)
    .map(this.formatAccountLabel);

  transformTargetTradingAccounts = accounts => accounts
    .filter(this.keepLiveAccounts)
    .filter(this.keepCurrentUsersAccounts)
    .map(this.formatAccountLabel);

  transformClients = users => users.map(user => ({
    ...user,
    display_name: user.username,
  }));

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.getFormConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const Form = this.renderForm();
    const { sourceAccountOptions, targetAccountOptions } = this.state;
    return (
      <Fragment>
        <Space size={16} />
        <Form 
          formId={FORM_KEY}
          renderForm={formFields => (
            <Fragment>
              { formFields.source_client }
              <Field options={sourceAccountOptions}>{ formFields.account_from }</Field>
              { formFields.amount }
              { formFields.target_client }
              <Field options={targetAccountOptions}>{ formFields.account_to }</Field>
              { formFields.target_amount }
            </Fragment>
          )}
        />
        <Space size={16} />
      </Fragment>
    );
  }
}

ExternalTransferForm.propTypes = propTypes;
ExternalTransferForm.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setFields,
      setFieldValue,
      fetchRate,
      fetchTradingAccounts,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ forms }) => {
  const form = forms[FORM_KEY];
  return {
    formValues: form.values,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalTransferForm);
