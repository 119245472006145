import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './widgets';

import App from './components/App/App';
import appStore from './logic/store';

const app = document.getElementById('app');

ReactDOM.render(
  <Provider store={appStore}>
    <BrowserRouter basename={process.env.BASEPATH}>
      <App />
    </BrowserRouter>
  </Provider>, app,
);
