/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import {
  Modal,
  Upload,
  HyperLink,
  Input,
  notifier,
  InfoBox,
  Button,
} from 'tc-biq-design-system';
import { func, object } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import Uploader from '../../../../../logic/services/uploader';
import overlayActions from '../../../../../components/overlay';

const EXAMPLE_URL = 'http://pubdocs.tradecore.com.s3.amazonaws.com/Support/sample_mass_upload.csv';
const propTypes = {
  fetchTableData: func.isRequired,
  modal: object.isRequired,
};

const uploadCopy = {
  initial: {
    title: gettext('Click here or drop csv file to upload'),
    subtitle: gettext('Drag and drop files or click to browse'),
  },
  loading: {
    title: gettext('Uploading file'),
    subtitle: gettext('This may take a second, please do not leave the page'),
  },
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const text = {
  UPLOAD_SUCCESS: gettext('Payments successfully uploaded'),
  CANCEL: gettext('Cancel'),
  UPLOADING: gettext('Uploading'),
  UPLOAD: gettext('Upload'),
  INFOBOX_LABEL: gettext('Error'),
  MODAL_TITLE: gettext('Upload bulk payments'),
  LIMIT_INFO: gettext('The limit is 1500 rows'),
  REQUIRED_FIELDS: gettext('Required fields: trading account number, backend, type, source, reason, amount'),
  OPTIONAL_FIELDS: gettext('Optional fields: comment'),
  DOWNLOAD_EXAMPLE: gettext('Click here to download example CSV.'),
  PLACEHOLDER: gettext('Upload name'),
  ROW: gettext('Row'),
};

const initialState = {
  uploaderStatus: 'initial',
  errors: {
    
  },
  form: {
    name: '',
    file: null,
  },
};

class UploadBulkPayments extends Component {
  constructor(props) {
    super(props);
    this.uploader = new Uploader('payment/mass_uploads');
    this.state = initialState;
    this.onDrop = this.onDrop.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.save = this.save.bind(this);
    this.modalFooter = this.modalFooter.bind(this);
  }

  onDrop(files) {
    const { form } = this.state;
    this.setState({
      form: { ...form, file: files[0] },
    });
  }

  async onError(response) {
    const errors = await response.json();
    const { form } = this.state;
    this.setState({
      errors,
      form: { ...form, file: null },
      uploaderStatus: 'initial',
    });
  }

  onSuccess() {
    const { actions } = this.props;
    const { fetchTableData } = this.props;
    fetchTableData();
    actions.close('UPLOAD_BULK_PAYMENTS');
    notifier.success(text.UPLOAD_SUCCESS);
  }

  async save() {
    this.setState({ uploaderStatus: 'loading', errors: [] });
    const { form } = this.state;
    const response = await this.uploader.upload(form);
    response.ok ? this.onSuccess() : this.onError(response);
  }

  modalCloseButton(uploading) {
    if (uploading) return null;
    const { actions } = this.props;
    return (
      <Button onClick={() => actions.close('UPLOAD_BULK_PAYMENTS')} color="ghost">
        {text.CANCEL}
      </Button>
    );
  }

  modalFooter() {
    const { uploaderStatus } = this.state;
    const uploading = uploaderStatus === 'loading';
    return (
      <div>
        {this.modalCloseButton(uploading)}
        <Button
          icon="Upload"
          color="confirmation"
          onClick={this.save}
          loading={uploading}
          disabled={uploading}
        >
          { uploading ? text.UPLOADING : text.UPLOAD }
        </Button>
      </div>
    );
  }

  handleInputChange(event) {
    const { target } = event;
    const { form } = this.state;
    this.setState({ form: { ...form, [target.name]: target.value } });
  }

  fileErrors() {
    const { errors } = this.state;
    if (!errors.file_errors) return null;
    const response = [];
    _.forIn(errors.file_errors, (rowErrors, key) => {
      response.push(
        <div key={key} style={{ marginTop: '10px' }}>
          <p className="tc-paragraph-regular">
            {text.ROW} {key}
          </p>
          {this.renderRowErrors(rowErrors)}
        </div>,
      );
    });

    return (
      <InfoBox header={text.INFOBOX_LABEL} type="error">
        {response}
      </InfoBox>
    );
  }

  renderRowErrors(rowErrors) {
    const result = [];
    _.forIn(rowErrors, (value, key) => {
      result.push(
        <div key={key}>
          {key}: {value.map(reason => reason)}
        </div>,
      );
    });
    return result;
  }

  renderUploader() {
    const { form, uploaderStatus } = this.state;
    const { file } = form;
    if (file && file.name) return <p className="tc-paragraph-regular">{file.name}</p>;
    return (
      <Upload
        status={uploaderStatus}
        onDrop={this.onDrop}
        localization={uploadCopy}
      />
    );
  }

  render() {
    const { form, errors } = this.state;
    const { modal } = this.props;

    return (
      <Modal 
        icon="Upload" 
        title={text.MODAL_TITLE} 
        footerRender={this.modalFooter}
        visible={modal.visible}
      >
        <div style={styles.container}>
          <p className="tc-paragraph-regular">
            {text.LIMIT_INFO}
            <br />
            {text.REQUIRED_FIELDS}
            <br />
            {text.OPTIONAL_FIELDS}
          </p>

          <HyperLink>
            <a href={EXAMPLE_URL} target="_blank" rel="noopener noreferrer">
              {text.DOWNLOAD_EXAMPLE}
            </a>
          </HyperLink>

          <Input
            name="name"
            value={form.name}
            hasError={!!errors.name}
            helpText={errors.name ? errors.name[0] : null}
            style={{ width: '100%', margin: '10px 0' }}
            placeholder={text.PLACEHOLDER}
            icon="Edit"
            onChange={this.handleInputChange}
            iconPosition="left"
          />
          {this.renderUploader()}
          <br />
          {this.fileErrors()}
        </div>
      </Modal>
    );
  }
}

UploadBulkPayments.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ close: overlayActions.close }, dispatch),
});

const mapStateToProps = ({ overlays }) => ({ modal: overlays.UPLOAD_BULK_PAYMENTS });

export default connect(mapStateToProps, mapDispatchToProps)(UploadBulkPayments);
