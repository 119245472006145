import React, { Component } from 'react';
import { Tabs, Tab, Banner, Button } from 'tc-biq-design-system';
import { string, object } from 'prop-types';

import connect from '../../../../../../logic/connect';
import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import { hasAccess } from '../../../../../../logic/services/acl';
import If from '../../../../../../components/If';
import { messagesModifier, smsModifier, callModifier, chatModifier } from './modifier';

const messagesConfig = id => ({
  reducerKey: 'USER_SINGLE',
  apiUrl: `users/${id}/messages`,
  tableKey: 'COMMUNICATION_MESSAGES',
});

const callsConfig = id => ({
  reducerKey: 'USER_SINGLE',
  apiUrl: `users/${id}/calls`,
  tableKey: 'COMMUNICATION_CALLS',
});

const smsConfig = id => ({
  reducerKey: 'USER_SINGLE',
  apiUrl: `users/${id}/sms`,
  tableKey: 'COMMUNICATION_SMS',
});

const chatConfig = id => ({
  reducerKey: 'USER_SINGLE',
  apiUrl: `users/${id}/chat`,
  tableKey: 'COMMUNICATION_CHAT',
});

const text = {
  MESSAGES: gettext('MESSAGES'),
  CALLS: gettext('CALLS'),
  SMS: gettext('SMS'),
  CHAT: gettext('CHAT'),
  EXPORT_ALL: gettext('Export all'),
  BANNER: gettext('We are unable to sync your messages, please re-authorize email syncing'),
};

export const acls = {
  messages: 'user.messages.list',
  calls: 'user.calls.list',
  sms: 'user.sms.list',
  chat: 'user.chat.list',
};

export const exportAcls = {
  [messagesConfig().tableKey]: 'user.messages.export.*',
  [callsConfig().tableKey]: 'user.calls.export.*',
  [smsConfig().tableKey]: 'user.sms.export.*',
  [chatConfig().tableKey]: 'user.chat.export.*',
};

const propTypes = {
  id: string.isRequired,
  tables: object.isRequired,
};

class Communication extends Component {
  constructor(props) {
    super(props);

    this.Messages = withTable(GridTab, messagesConfig, this.tableActions(messagesConfig().tableKey))(props.id);
    this.Calls = withTable(GridTab, callsConfig, this.tableActions(callsConfig().tableKey))(props.id);
    this.Sms = withTable(GridTab, smsConfig, this.tableActions(smsConfig().tableKey))(props.id);
    this.Chat = withTable(GridTab, chatConfig, this.tableActions(chatConfig().tableKey))(props.id);


    this.nylasEnabled = isFeatureEnabled()('NYLAS');
    this.nylasApi = getDjangoApi('integration/nylas');

    this.state = {
      nylasConnected: true,
    };
  }

  componentDidMount() {
    if (this.nylasEnabled) {
      this.nylasApi.list()
        .then(({ data }) => this.setState({ nylasConnected: data.is_connected }));
    }
  }

  tableActions = key => () => {
    const { tables } = this.props;
    const table = tables[key];
    const all = _.get(table, 'exportUrl.all');
    if (!hasAccess(exportAcls[key])) return null;
    return <a href={all}><Button>{text.EXPORT_ALL}</Button></a>;
  }


  render() {
    const { Messages, Calls, Sms, Chat, nylasEnabled } = this;
    const { nylasConnected } = this.state;

    return (
      <Tabs>
        <Tab visible={hasAccess(acls.messages)} title={text.MESSAGES}>
          <If condition={nylasEnabled && nylasConnected}>
            <Banner type="error" close={false}>{text.BANNER}</Banner>
          </If>

          <Messages modifier={messagesModifier} />
        </Tab>

        <Tab visible={hasAccess(acls.calls)} title={text.CALLS}>
          <Calls modifier={callModifier} />
        </Tab>

        <Tab visible={hasAccess(acls.sms)} title={text.SMS}>
          <Sms modifier={smsModifier} />
        </Tab>

        <Tab visible={hasAccess(acls.chat)} title={text.CHAT}>
          <Chat modifier={chatModifier} />
        </Tab>
      </Tabs>
    );
  }
}

Communication.propTypes = propTypes;

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  return {
    tables: page.tables,
  };
};

export default connect(mapStateToProps, null)(Communication);
