import React, { Component } from 'react';
import { object, bool, func } from 'prop-types';
import { Space, Sidepanel, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';
import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import to from '../../../../../../../logic/utilities/to';
import { getTableActions, bonusConfig } from '../../../Sections/Transactions/table';
import { formErrorHandler, validateForm } from '../../../../../../../components/form/logic/utils';
import { isGreaterThenValidator, isRequiredValidator } from '../../../../../../../components/form/logic/validators';

const FORM_KEY = 'MANUAL_BONUS_FORM';
const { create, resetFields, validator } = FormActionsFactory(FORM_KEY);

const text = {
  TITLE: gettext('Manual bonus'),
  SUCCESS: gettext('Manual bonus successful.'),
  GENERAL_ERROR: gettext('Something went wrong!'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const formModifiers = {
  wallet: {
    validators: [isRequiredValidator()],
  },
  amount: {
    validators: [
      isRequiredValidator(),
      isGreaterThenValidator(),
    ],
  },
  reason: {
    validators: [isRequiredValidator()],
  },
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  submitInProgress: bool.isRequired,
  isIb: bool,
  dispatch: func.isRequired,
};

const defaultProps = {
  user: null,
  actions: null,
  isIb: false,
};

class Bonus extends Component {
  constructor(props) {
    super(props);

    this.api = user => getDjangoApi(`users/${user.id}/bonuses/`);
    this.actions = props.actions;
    this.onClose = this.onClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  async onSubmit() {
    const { user } = this.props;
    const isValid = validateForm(this.actions.validator);
    if (!isValid) return;
    const [err] = await to(this.actions.create(this.api(user), this.getRequestPayload));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    this.updateBonusTable();
    this.onClose();
    notifier.success(text.SUCCESS);
  }

  onError(err) {
    formErrorHandler(text.GENERAL_ERROR)(err);
  }

  onClose() {
    const { sidepanelManager } = this.props;
    sidepanelManager.close();
  }

  getRequestPayload(values) {
    return _.reduce(values, (payload, field, key) => ({
      ...payload,
      [key]: typeof field === 'object' && !_.isEmpty(field) ? field.value : field,
    }), {});
  }

  getFormConfig() {
    const { user } = this.props;
  
    return {
      form: FORM_KEY,
      api: this.api(user),
      excludeFields: isFeatureEnabled()('MANUAL_BONUS_REASON') ? [] : ['reason'],
    };
  }

  updateBonusTable = () => {
    const { dispatch, user } = this.props;
    const actions = getTableActions(bonusConfig, user.id);
    dispatch(actions.fetchTableData());
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.getFormConfig());
    }
    const { FormComponent } = this;
    return <FormComponent modifiers={formModifiers} />;
  }

  render() {
    const { sidepanelManager, user, isIb, actions, submitInProgress, ...options } = this.props;
    return (
      <Sidepanel
        {...options}
        footerRender={customFooter(this.onSubmit, this.onClose, submitInProgress)}
      >
        <Space size={16} />
        {this.renderForm()}
        <Space size={16} />
      </Sidepanel>
    );
  }
}
  

Bonus.propTypes = propTypes;
Bonus.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      create,
      resetFields,
      validator,
    },
    dispatch,
  ),
  dispatch,
});

const mapStateToProps = ({ forms }) => {
  const form = forms[FORM_KEY];
  return { ...form };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bonus);
