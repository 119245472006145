import React from 'react';
import { Icon, Tooltip, Popconfirm } from 'tc-biq-design-system';
import { Link } from 'react-router-dom';

import { ToggleCell, ActionsCellWrapper, UsernameCell } from '../../../../components/gridCellRenderers';
import hideCols from '../../../../logic/utilities/hideCols';
import displayFromChoices from '../../../../logic/utilities/formatters/displayFromChoices';
import { gettext } from '../../../../logic/utilities/languageUtility';

const text = {
  MODIFY: gettext('Modify'),
  DELETE: gettext('Delete'),
  POPCONFIRM_LABEL: gettext('Are you sure you want to delete this deposit rule?'),
  NO: gettext('No'),
  YES: gettext('Yes'),
  STATE: gettext('State'),
  ACTIONS: gettext('Actions'),
};

const actionIcons = (modify, onDelete) => ({
  ...(modify && {
    modify: (actionHandler, data) => {
      const url = actionHandler(data);
      return (
        <div key="Modify">
          <Link to={url}>
            <Tooltip title={text.MODIFY} placement="top">
              <Icon name="Edit" />
            </Tooltip>
          </Link>
        </div>
      );
    },
  }),
  ...(onDelete && {
    onDelete: (actionHandler, data) => (
      <Tooltip title={text.DELETE} placement="top">
        <Popconfirm
          type="destructive"
          icon="Delete"
          onConfirm={() => actionHandler(data)}
          label={text.POPCONFIRM_LABEL}
          buttonLabels={{ cancel: text.NO, confirm: text.YES }}
        >
          <Icon name="Delete" />
        </Popconfirm>
      </Tooltip>
    ),
  }),
});

const modifier = ({ toggleEnabled, modify, onDelete, previewBiqUser }) => {
  const actions = {
    ...(modify && { modify }),
    ...(onDelete && { onDelete }),
  };

  return {
    ...hideCols(['id', 'condition']),
    ...(toggleEnabled && {
      is_enabled: {
        width: 100,
        headerName: text.STATE,
        pinned: 'left',
        cellRendererFramework: ToggleCell(toggleEnabled),
      },
    }),
    created_by: {
      cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
    },
    updated_by: {
      cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
    },
    method: {
      valueGetter: displayFromChoices('method'),
    },
    type: {
      valueGetter: displayFromChoices('type'),
    },
    actions: {
      label: text.ACTIONS,
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(modify, onDelete)),
      width: 120,
    },
  };
};

export default modifier;
