import React, { Component } from 'react';
import { Input, Select, Button, notifier } from 'tc-biq-design-system';
import { gettext } from '../../../../../logic/utilities/languageUtility';

import './TemplatePreview.scss';
import SelectEvent from './SelectEvent/SelectEvent';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { urlCombine, BO_APP_FULL } from '../../../../../logic/services/api';
import FormEl from '../../../../../components/form/Components/FormEl';

const api = getDjangoApi('emails/bo_notifications');

const text = {
  SEND_EMAIL: gettext('Send text email'),
  SEARCH: gettext('Search'),
  LANGUAGES: gettext('Languages'),
  EMAIL_ADDR: gettext('Email address...'),
  EMAIL_SUCCESS: gettext('Test email was sent'),
  SEND_EMAIL_INFO: gettext('Please enter email and select event and language.'),
};

class TemplatePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',

      email: '',
      emailSubmitInProgress: false,

      selectedLanguage: null,
      languages: [],

      selectedEvent: null,
      events: [],
    };
  }

  componentDidMount() {
    api.options()
      .then((res) => {
        const fields = _.get(res, 'data.actions.POST.fields') || [];
        const events = fields.find(({ key }) => key === 'event').choices;
        const languages = fields.find(({ key }) => key === 'language').choices;
        this.setState({ 
          events,
          selectedEvent: events[0],
          languages, 
          selectedLanguage: languages[0],
        });
      });
  }

  onLanguageChange = selectedLanguage => this.setState({ selectedLanguage })

  onSearch = e => this.setState({ searchTerm: e.target.value })

  onEmailSend = () => {
    const { selectedEvent, selectedLanguage, email } = this.state;

    if (!selectedEvent || !selectedLanguage || !email) {
      notifier.info(gettext(text.SEND_EMAIL_INFO));
      return;
    }
    this.setState({ emailSubmitInProgress: true });
    api.create({
      event: selectedEvent.value,
      language: selectedLanguage.value,
      email,
    })
      .then(() => notifier.success(text.EMAIL_SUCCESS))
      .finally(() => this.setState({ emailSubmitInProgress: false }));
  }

  onEmailChange = e => this.setState({ email: e.target.value })

  onEventChange = selectedEvent => this.setState({ selectedEvent })

  getSrc = () => {
    const { selectedLanguage, selectedEvent } = this.state;

    if (selectedEvent && selectedLanguage) {
      return urlCombine(BO_APP_FULL,
        `emails/transactional/${selectedEvent.value}/preview/?language=${selectedLanguage.value}`);
    }

    return '';
  }

  render() {
    const { selectedLanguage, languages, email, emailSubmitInProgress, searchTerm, events, selectedEvent } = this.state;
    const src = this.getSrc();
    const filteredEvents = events.filter(event => event.display_name.toLowerCase().includes(searchTerm.toLowerCase()));
    return (
      <div className="template-preview">
        <div className="template-preview__header">
          <Input
            className="template-preview__search"
            placeholder={text.SEARCH}
            icon="Search"
            iconPosition="left"
            onChange={this.onSearch}
            value={searchTerm}
          />
          <Select
            clearable={false}
            className="template-preview__language"
            placeholder={text.LANGUAGES}
            value={selectedLanguage}
            options={languages}
            onChange={this.onLanguageChange}
            labelKey="display_name"
            valueKey="value"
          />
          <FormEl className="template-preview__email">
            <Input
              className="template-preview__email__input"
              placeholder={text.EMAIL_ADDR}
              icon="Search"
              iconPosition="left"
              value={email}
              onChange={this.onEmailChange}
            />
            <Button type="submit" loading={emailSubmitInProgress} onClick={this.onEmailSend}>
              {text.SEND_EMAIL}
            </Button>
          </FormEl>
        </div>
        <div className="template-preview__body">
          <div className="template-preview__events">
            <SelectEvent 
              options={filteredEvents}
              value={selectedEvent}
              onChange={this.onEventChange}
            />
          </div>
          <div className="template-preview__preview">
            <iframe 
              title="email preview"
              frameBorder="0"
              className="template-preview__iframe"
              src={src}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TemplatePreview;
