import React, { Component, Fragment } from 'react';
import { Sidepanel, notifier, Select, Space } from 'tc-biq-design-system';
import { bool, object, func, array, oneOfType } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import FormFactory, { ErrorHandler, FormActionsFactory } from '../../../../../components/form';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import to from '../../../../../logic/utilities/to';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import overlayActions from '../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../components/common';

const propTypes = {
  onSuccess: func.isRequired,
  sidepanel: object.isRequired,
  fields: array.isRequired,
  errors: oneOfType([array, object]).isRequired,
  values: object.isRequired,
  actions: object.isRequired,
  submitInProgress: bool.isRequired,
};

const text = {
  TITLE: gettext('Approve withdrawal'),
  BUTTON_LABEL: {
    confirm: gettext('Approve withdrawal'),
  },
  SUCCESS_TEXT: gettext('Withdrawal executed successfully'),
  ERROR_TEXT: gettext('Execute withdrawal error'),
  PAYMENT_GATEWAY: gettext('Gateway'),
  ACQUIRER: gettext('Acquirer'),
};

const SIDEPANEL_ID = 'EXECUTE_WITHDRAWAL';

const customFooter = (execute, submitInProgress) => (
  <SidepanelFooter 
    execute={execute} 
    submitInProgress={submitInProgress} 
    confirmColor="confirmation"
    buttonLabels={text.BUTTON_LABEL}
  />
);

const { setFieldsErrors, create, resetFields, setRowData, setFieldValue } = FormActionsFactory(SIDEPANEL_ID);

class ExecuteWithdrawalSidepanel extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.execute = this.execute.bind(this);
    this.onClose = this.onClose.bind(this);
    this.api = id => getDjangoApi('pending_withdrawals')
      .one(id)
      .all('execute');

    this.state = {
      gatewayOptions: null,
      isAcquirerVisible: false,
      acquirerOptions: [],
    };
  }

  componentDidUpdate(prevProps) {
    this.setFormvalues(prevProps);
    this.onChangeMethod(prevProps);
  }

  onChangeMethod(prevProps) {
    const prevMethod = _.get(prevProps, 'values.method.value');
    const method = _.get(this.props, 'values.method.value');

    if (prevMethod !== method) {
      this.updateGatewayOptions(method);
    }
  }

  onClose(submitSuccess) {
    const { onSuccess } = this.props;
    this.FormComponent = null;
    this.setState({ 
      isAcquirerVisible: false,
    });
    this.actions.resetFields();
    this.actions.closeSidepanel('EXECUTE_WITHDRAWAL');
    if (submitSuccess === true) onSuccess();
  }

  onChangeGateway = (gateway) => {
    const gatewayName = _.get(gateway, 'display_name');
    const gatewayValue = _.get(gateway, 'value');
    this.setState({ 
      isAcquirerVisible: gatewayName && gatewayName.startsWith('bank'),
    }, () => {
      this.setPaymentGateway(gateway);
      this.updateAcquirerOptions(gatewayValue);
    });
  }

  getError = (key) => {
    const { errors } = this.props;
    const hasError = !!errors[key];
    const helpText = hasError ? errors[key][0] : null;

    return [hasError, helpText];
  }

  setFormvalues = (prevProps) => {
    const prevId = _.get(prevProps, 'sidepanel.parameters.id');
    const id = _.get(this.props, 'sidepanel.parameters.id');

    if (prevId !== id && id) {
      const { sidepanel: { parameters } } = this.props;
      const params = Object.keys(parameters).reduce((acc, key) => {
        const value = parameters[key];
        if (value) return { ...acc, [key]: value };
        return acc;
      }, {});
      this.actions.setRowData(params);
    }
  }

  setPaymentGateway = (gateway) => {
    const { actions } = this.props;
    actions.setFieldValue({
      id: 'gateway',
      value: gateway,
    });
  }

  setPaymentAcquirer = (acquirer) => {
    this.actions.setFieldValue({
      id: 'acquirer',
      value: acquirer,
    });
  }

  updateGatewayOptions = (paymentMethodId) => {
    const { fields } = this.props;
    if (_.isEmpty(fields)) return;
    const selected = fields.find(field => field.id === 'gateway');
    const { gateways_per_method, options } = selected;
    const optionIds = gateways_per_method[paymentMethodId];
    const gatewayOptions = optionIds ? options.filter(option => optionIds.includes(option.value)) : [];
    this.setPaymentGateway(null);
    this.setState({
      gatewayOptions,
    });
  }

  updateAcquirerOptions = (gatewayId) => {
    const { fields } = this.props;
    const selected = fields.find(field => field.id === 'acquirer');
    if (!selected) return;
    const { acquirers_per_gateway, options } = selected;
    const optionIds = acquirers_per_gateway[gatewayId];
    const acquirerOptions = optionIds ? options.filter(option => optionIds.includes(option.value)) : [];
    this.setPaymentAcquirer(undefined);
    this.setState({
      acquirerOptions,
    });
  }

  formConfig = api => ({
    form: SIDEPANEL_ID,
    api,
    excludeFields: ['referral_transactions', 'withdrawal_extra_id'],
  });

  async execute() {
    const { fields, sidepanel: { parameters } } = this.props;
    const [err] = await to(this.actions.create(this.api(parameters.id)));
    if (err) {
      new ErrorHandler({ errors: err.data, fields }).showMessages(notifier.error);
    } else {
      notifier.success(text.SUCCESS_TEXT);
      this.actions.resetFields();
      this.onClose(true);
    }
  }

  renderForm = () => {
    const id = _.get(this.props, 'sidepanel.parameters.id');
    if ((this.id !== id || !this.FormComponent) && id) {
      this.id = id;
      const api = this.api(id);
      this.FormComponent = FormFactory(this.formConfig(api));
    }

    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { gatewayOptions, isAcquirerVisible, acquirerOptions } = this.state;
    const { sidepanel, submitInProgress, values } = this.props;
    const { gateway, acquirer } = values;
    const [gatewayHasError, gatewayHelpText] = this.getError('gateway');
    const [acquirerHasError, acquirerHelpText] = this.getError('acquirer');
    const Form = this.renderForm();
    return (
      <Sidepanel
        icon="Checkmark"
        type="success"
        title={text.TITLE}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.execute, submitInProgress)}
      >
        {Form && (
          <Form renderForm={formFields => (
            <Fragment>
              {formFields.method}
              {formFields.amount}
              {formFields.external_id}
              <Fragment>
                <Select 
                  label={text.PAYMENT_GATEWAY} 
                  onChange={this.onChangeGateway}
                  value={gateway}
                  options={gatewayOptions}
                  hasError={gatewayHasError}
                  helpText={gatewayHelpText}
                  valueKey="value"
                  labelKey="display_name"
                />
                <Space size={12} />
              </Fragment>
              {isAcquirerVisible && (
                <Fragment>
                  <Select 
                    label={text.ACQUIRER} 
                    onChange={this.setPaymentAcquirer}
                    value={acquirer}
                    options={acquirerOptions}
                    hasError={acquirerHasError}
                    helpText={acquirerHelpText}
                    valueKey="value"
                    labelKey="display_name"
                  />
                  <Space size={12} />
                </Fragment>
              )}
            </Fragment>
          )} 
          />
        )}
      </Sidepanel>
    );
  }
}

ExecuteWithdrawalSidepanel.propTypes = propTypes;

const mapStateToProps = ({ forms, overlays }) => {
  const { fields, submitInProgress, values, errors } = forms[SIDEPANEL_ID];

  return {
    fields,
    values,
    errors,
    submitInProgress,
    sidepanel: overlays.EXECUTE_WITHDRAWAL,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setFieldsErrors,
      create,
      resetFields,
      setRowData,
      closeSidepanel: overlayActions.close,
      setFieldValue,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(ExecuteWithdrawalSidepanel));
