import React from 'react';
import { Select } from 'tc-biq-design-system';
import { array, func, any } from 'prop-types';

import { gettext } from '../../logic/utilities/languageUtility';

const text = {
  PLACEHOLDER: gettext('Field'),
};

const propTypes = {
  fields: array,
  onChange: func,
  selectedField: any,
};

const defaultProps = {
  fields: [],
  onChange: null,
  selectedField: '',
};

const SelectField = ({ fields, onChange, selectedField }) => (
  <Select
    placeholder={text.PLACEHOLDER}
    clearable={false}
    type="search"
    valueKey="key"
    sortOrder="asc"
    value={selectedField}
    options={fields}
    onChange={onChange}
  />
);

SelectField.propTypes = propTypes;
SelectField.defaultProps = defaultProps;

export default SelectField;
