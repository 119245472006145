import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const actionTypes = {
  FETCH_PAYMENT_BREAKDOWN: 'FETCH_PAYMENT_BREAKDOWN',
  FETCH_PAYMENT_BREAKDOWN_PENDING: 'FETCH_PAYMENT_BREAKDOWN_PENDING',
  FETCH_PAYMENT_BREAKDOWN_REJECTED: 'FETCH_PAYMENT_BREAKDOWN_REJECTED',
  FETCH_PAYMENT_BREAKDOWN_FULFILLED: 'FETCH_PAYMENT_BREAKDOWN_FULFILLED',
};

const api = user => getDjangoApi(`users/${user.id}/payment_breakdown`);

export const fetchPaymentBreakdown = createAction(
  actionTypes.FETCH_PAYMENT_BREAKDOWN,
  async (user) => {
    const { data } = await api(user).list();
    return data.results;
  },
);

const initialState = {
  payments: [],
  paymentsInProgress: false,
  paymentsErrors: null,
};


const paymentsPending = state => ({
  ...state,
  paymentsInProgress: true,
});

const paymentsFulfield = (state, { payload }) => ({
  ...state,
  payments: payload,
  paymentsInProgress: false,
});

const paymentsRejected = (state, { payload }) => ({
  ...state,
  paymentsInProgress: false,
  paymentsErrors: payload.data,
});

export default handleActions(
  {
    [actionTypes.FETCH_PAYMENT_BREAKDOWN_PENDING]: paymentsPending,
    [actionTypes.FETCH_PAYMENT_BREAKDOWN_FULFILLED]: paymentsFulfield,
    [actionTypes.FETCH_PAYMENT_BREAKDOWN_REJECTED]: paymentsRejected,
  },
  initialState,
);
