import React, { Component, Fragment } from 'react';
import { object, array } from 'prop-types';
  
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { nestedFieldsGenerator } from '../../../../../../../../components/form/Components/Form';
import TogglePanel from '../../../../../../../../components/TogglePanel';
import { fields, DEFAULT_FIELDS, FIELDS_ORDER } from './fields';

const text = {
  TITLE: gettext('Add bank details'),
};

const FORM_KEY = 'MANUAL_DEPOSIT_FORM';

const PARENT_KEY = 'bank_account_data';

const propTypes = {
  actions: object.isRequired,
  formValues: object.isRequired,
  formFields: array.isRequired,
};

class BankData extends Component {
  constructor(props) {
    super(props);

    this.actions = props.actions;
    this.onToggle = this.onToggle.bind(this);
    this.renderActiveFields = this.renderActiveFields.bind(this);

    this.state = {
      activeFields: [],
    };
  }

  componentDidMount() {
    this.generateFormFields();
  }

  componentDidUpdate(prevProps) {
    this.onChangeLocation(prevProps);
  }

  onChangeLocation(prevProps) {
    const prevLocation = _.get(prevProps, 'formValues.bank_account_data_location.value');
    const location = _.get(this.props, 'formValues.bank_account_data_location.value');

    if (location !== prevLocation) {
      const locationRule = this.locationRules[location];
      if (locationRule) {
        this.setState({
          activeFields: locationRule.fields,
        });
      }
    }
  }

  onToggle(checked) {
    this.actions.setFieldValue({
      id: fields.IS_BANK_DETAILS_REQUIRED,
      value: checked,
    });
  }

  generateFormFields() {
    const { formFields } = this.props;
    const bankData = formFields.find(field => field.id === PARENT_KEY);
    
    this.fields = nestedFieldsGenerator(FORM_KEY, formFields, PARENT_KEY);
    this.locationRules = bankData.children.find(field => field.id === 'location').dyn_dependent_fields;
  }

  renderActiveFields() {
    const { activeFields } = this.state;
    const key = field => `${PARENT_KEY}_${field}`;
    const fieldMapper = (keyMapper = field => field) => (acc, field) => ({ ...acc, [keyMapper(field)]: this.fields[keyMapper(field)] });

    if (_.isEmpty(activeFields)) return [];
  
    const formFields = DEFAULT_FIELDS.reduce(fieldMapper(), {});
    const locationFields = activeFields.reduce(fieldMapper(key), {});
    const allFields = { ...formFields, ...locationFields };
    
    const sortedFields = FIELDS_ORDER.filter(field => !!allFields[field]).map(field => allFields[field]);
    
    return sortedFields;
  }

  render() {
    const { formValues } = this.props;
    const { is_bank_acc_details_required } = formValues; 
    
    return (
      <TogglePanel
        title={text.TITLE}
        checked={is_bank_acc_details_required}
        onToggle={this.onToggle}
      >
        {is_bank_acc_details_required && this.fields 
          && (
            <Fragment>
              {this.fields.bank_account_data_location}
              {this.renderActiveFields()}
            </Fragment>
          )}
      </TogglePanel>
    );
  }
}

BankData.propTypes = propTypes;

export default BankData;
