import React from 'react';
import { Tooltip, Icon, Popconfirm } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import hideCols from '../../../../logic/utilities/hideCols';
import { gettext } from '../../../../logic/utilities/languageUtility';
import { ToggleCell, ActionsCellWrapper, UsernameCell } from '../../../../components/gridCellRenderers';
import appRoutes from '../../../../components/App/Router/appRoutes';

const text = {
  MODIFY_ICON_TOOLTIP: gettext('Modify'),
  DUPLICATE_ICON_TOOLTIP: gettext('Copy'),
  DELETE_ICON_TOOLTIP: gettext('Delete'),
  NAME_COLUMN_LABEL: gettext('Trigger name'),
  IS_ENABLED_COLUMN_LABEL: gettext('State'),
  CANCEL: gettext('Cancel'),
  ARE_YOU_SURE: gettext('You are about to delete sms event'),

};

const actionIcons = (modify, copy, remove) => ({
  ...(modify && {
    modify: (_, { id }) => {
      const url = generatePath(appRoutes.SMS_EVENT, { id });
      return (
        <div key="Modify">
          <Link to={url}>
            <Tooltip title={text.MODIFY_ICON_TOOLTIP} placement="top">
              <Icon name="Edit" />
            </Tooltip>
          </Link>
        </div>
      );
    },
  }),
  ...(copy && {
    copy: (_, { id }) => {
      const url = generatePath(appRoutes.SMS_EVENT_CREATE, { id });
      return (
        <div key="Copy">
          <Link to={url}>
            <Tooltip title={text.DUPLICATE_ICON_TOOLTIP} placement="top">
              <Icon name="Duplicate" />
            </Tooltip>
          </Link>
        </div>
      );
    },
  }),
  ...(remove && {
    remove: (onRemove, data) => {
      const label = `${text.ARE_YOU_SURE} ${data.name}`;
      return (
        <Tooltip key="remove" title={text.DELETE_ICON_TOOLTIP}>
          <Popconfirm
            type="destructive"
            icon="Delete"
            label={label}
            onConfirm={() => onRemove(data)}
            buttonLabels={{
              cancel: text.CANCEL,
              confirm: text.DELETE_ICON_TOOLTIP,
            }}
          >
            <Icon name="Delete" />
          </Popconfirm>
        </Tooltip>
      );
    },
  }),
});

export default ({ toggle, modify, copy, remove, previewUser }) => {
  const actions = {
    ...(modify && { modify: null }),
    ...(copy && { copy: null }),
    ...(remove && { remove }),
  };

  return ({
    ...hideCols(['id', 'action', 'action_params', 'code_num', 'condition']),
    created_by: {
      cellRendererFramework: UsernameCell(previewUser, { isStaff: true }),
    },
    name: {
      headerName: text.NAME_COLUMN_LABEL,
    },
    ...(toggle && {
      is_enabled: {
        position: 0,
        headerName: text.IS_ENABLED_COLUMN_LABEL,
        width: 100,
        pinned: 'left',
        enableFiltering: false,
        cellRendererFramework: ToggleCell(toggle),
      },
    }),
    actions: {
      label: '',
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(modify, copy, remove)),
      pinned: 'right',
      width: 120,
    },
  });
};
