import React from 'react';
import { Avatar as BiqAvatar, Icon, HyperLink } from 'tc-biq-design-system';
import classnames from 'classnames';
import { any, bool, string } from 'prop-types';

import './UserHeader.scss';

const propTypes = {
  children: any,
};

const defaultProps = {
  children: null,
};

const Avatar = () => (
  <div className="biq-user-header__avatar">
    <BiqAvatar size="large" />
  </div>
);

const Profile = ({ children }) => (
  <div className="biq-user-header__profile">{ children }</div>
);

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

const Name = ({ children }) => (
  <h4 className="biq-user-header__name">{children}</h4>
);

Name.propTypes = propTypes;
Name.defaultProps = defaultProps;

const States = ({ children }) => (
  <div className="biq-user-header__states">{children}</div>
);

States.propTypes = propTypes;
States.defaultProps = defaultProps;

const Subtitle = ({ children }) => (
  <span className="biq-user-header__subtitle">{children}</span>
);

Subtitle.propTypes = propTypes;
Subtitle.defaultProps = defaultProps;

const Actions = ({ children, noMargin }) => (
  <div className={classnames('biq-user-header__user-actions', { 'biq-user-header__user-actions--no-margin': noMargin })}>{children}</div>
);

Actions.propTypes = {
  ...propTypes,
  noMargin: bool,
};

Actions.defaultProps = {
  ...defaultProps,
  noMargin: false,
};

const Link = ({ link, text, icon }) => (text ? (
  <div className="biq-user-header__link">
    <Icon colorName="text-primary-400" name={icon} size="small" />
    <HyperLink>
      <a href={link}>{text}</a>
    </HyperLink>
  </div>
) : null);

Link.propTypes = {
  link: string,
  text: string,
  icon: string,
};

Link.defaultProps = {
  link: '',
  text: '',
  icon: '',
};

const UserHeader = ({ children }) => (
  <div className="biq-user-header">{children}</div>
);

UserHeader.propTypes = {
  children: any,
};

UserHeader.defaultProps = defaultProps;

UserHeader.Avatar = Avatar;
UserHeader.Profile = Profile;
UserHeader.Name = Name;
UserHeader.States = States;
UserHeader.Subtitle = Subtitle;
UserHeader.Actions = Actions;
UserHeader.Link = Link;

export default UserHeader;
