
import { bindActionCreators } from 'redux';

import CardData from './CardData';
import connect from '../../../../../../../../logic/connect';
import { FormActionsFactory } from '../../../../../../../../components/form';

const FORM_KEY = 'MANUAL_DEPOSIT_FORM';

const { setFieldValue } = FormActionsFactory(FORM_KEY);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setFieldValue,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ forms }) => {
  const form = forms[FORM_KEY];
  return {
    formValues: form.values,
    formFields: form.fields,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardData);
