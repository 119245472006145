import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../logic/services/api-factory';
import isFeatureEnabled from '../../../../logic/filters/is-feature-enabled';
import { hasAccess } from '../../../../logic/services/acl';

const actionTypes = {
  FETCH_USER: 'FETCH_USER',
  FETCH_USER_PENDING: 'FETCH_USER_PENDING',
  FETCH_USER_REJECTED: 'FETCH_USER_REJECTED',
  FETCH_USER_FULFILLED: 'FETCH_USER_FULFILLED',
  FETCH_USER_FIELDS: 'FETCH_USER_FIELDS',
  FETCH_USER_FIELDS_PENDING: 'FETCH_USER_FIELDS_PENDING',
  FETCH_USER_FIELDS_REJECTED: 'FETCH_USER_FIELDS_REJECTED',
  FETCH_USER_FIELDS_FULFILLED: 'FETCH_USER_FIELDS_FULFILLED',
  UPDATE_USER: 'UPDATE_USER',
  RESET_USER: 'RESET_USER',
  FETCH_LOSS_LIMIT_REACHED: 'FETCH_LOSS_LIMIT_REACHED',
};

const usersApi = () => getDjangoApi('users');
const ibUsersApi = () => getDjangoApi('ib');
const equityApi = user => getDjangoApi(`users/${user.id}/total_equity`);
const ibStatisticApi = user => getDjangoApi(`ib/${user.id}/statistics`);
const userRegistrationApi = user => getDjangoApi(`users/${user.id}/reg_info`);
const ibRegistrationApi = user => getDjangoApi(`ib/${user.id}/reg_info`);
const userApi = user => getDjangoApi(`users/${user.id}`);
const ibApi = user => getDjangoApi(`ib/${user.id}`);
// const tradingAccountsApi = user => getDjangoApi(`users/${user.id}/trading_accounts`);
const lossLimitReachedApi = () => getDjangoApi('env/user_loss_limit');

export const fetchUser = createAction(actionTypes.FETCH_USER, async (user, isIb) => {
  const totalEquityEnabled = isFeatureEnabled()('TOTAL_EQUITY_BASE_CURRENCY') && hasAccess('user.total_equity.list');
  // const canListTradingAccounts = hasAccess('user.trading_accounts.list') && hasAccess('trading_accounts.list') && !isIb;
  const statisticsApi = isIb ? ibStatisticApi(user) : (totalEquityEnabled && equityApi(user));
  const api = isIb ? ibUsersApi() : usersApi();
  const requestrationApi = isIb ? ibRegistrationApi(user) : hasAccess('user.reg_info.*') && userRegistrationApi(user);
  const [userData, statisticData, registrationData, tradingAccountsData] = await Promise.all([ 
    api.retrieve(user.id), 
    statisticsApi ? statisticsApi.list() : Promise.resolve(false),
    requestrationApi ? requestrationApi.list() : Promise.resolve(false),
    // canListTradingAccounts ? tradingAccountsApi(user).list() : Promise.resolve(false),
  ].map(p => p.catch(() => false)));

  if (!statisticData && !registrationData) return userData;

  return { data: { ...statisticData.data, ...userData.data, ...registrationData.data, userTradingAccounts: _.get(tradingAccountsData, 'data.results') } };
});

export const fetchUserFields = createAction(actionTypes.FETCH_USER_FIELDS, async (user, isIb) => {
  const api = isIb ? ibApi(user) : userApi(user);
  const { data } = await api.options();
  const { actions, flp } = data;
  const getFields = _.get(actions, 'GET.fields', []);
  const putFields = _.get(actions, 'PUT.fields', []);
  
  const hashedPutFields = putFields.reduce((acc, field) => ({ ...acc, [field.key]: field }), {});
  const fields = getFields.map(field => (hashedPutFields[field.key] 
    ? { ...hashedPutFields[field.key], writable: true }
    : { ...field }));
  return {
    fields,
    flp,
  };
});

export const updateUser = createAction(actionTypes.UPDATE_USER, data => data);
export const resetUser = createAction(actionTypes.RESET_USER);

export const fetchLossLimitReached = createAction(actionTypes.FETCH_LOSS_LIMIT_REACHED, async (user) => {
  const api = lossLimitReachedApi();
  const { data } = await api.retrieve(user.id);

  return data;
});

const initialState = {
  user: null,
  submitInProgress: false,
  errors: null,
  fields: [],
  flp: [],
  fieldsInProgress: false,
  fieldsErrors: null,
};

const fetchPendingHandler = state => ({ ...state, submitInProgress: true });

const fetchFulfiledHandler = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  user: payload.data,
});

const fetchRejectedHandler = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  errors: payload.data,
});

const fetchFieldsFulfiledHandler = (state, { payload }) => ({
  ...state,
  fields: payload.fields,
  flp: payload.flp,
  fieldsInProgress: false,
});

const fetchFieldsRejectedHandler = (state, { payload }) => ({
  ...state,
  fieldsInProgress: false,
  fieldsErrors: payload.data,
});

const updateUserHandler = (state, { payload }) => ({ ...state, user: { ...state.user, ...payload } });

export default handleActions(
  {
    [actionTypes.FETCH_USER_PENDING]: fetchPendingHandler,
    [actionTypes.FETCH_USER_FULFILLED]: fetchFulfiledHandler,
    [actionTypes.FETCH_USER_REJECTED]: fetchRejectedHandler,
    [actionTypes.FETCH_USER_FIELDS_PENDING]: state => ({ ...state, fieldsInProgress: true }),
    [actionTypes.FETCH_USER_FIELDS_FULFILLED]: fetchFieldsFulfiledHandler,
    [actionTypes.FETCH_USER_FIELDS_REJECTED]: fetchFieldsRejectedHandler,
    [actionTypes.UPDATE_USER]: updateUserHandler,
    [actionTypes.RESET_USER]: () => ({ ...initialState }),
  },
  initialState,
);
