import { createAction } from 'redux-actions';

import store from '../../logic/store';
import { OPEN, UPDATE, CLOSE, RESET, UPDATE_EXTRA } from './overlayActionTypes';

const defaultOptions = {
  closeOthers: true,
};

const open = createAction(OPEN, (id, data, options = {}, extra = {}) => ({ id, data, options: { ...defaultOptions, ...options }, extra }));
const close = createAction(CLOSE);
const resetState = createAction(RESET);

const update = createAction(UPDATE, (id, updateData, extra) => {
  if (typeof updateData !== 'function') return { id, data: updateData, extra };
  const { parameters } = store.getState().overlays[id];
  return { id, data: updateData(parameters), extra };
});
const updateExtra = createAction(UPDATE_EXTRA, (id, data) => ({ id, data }));

const overlayActions = {
  open,
  update,
  close,
  resetState,
  updateExtra,
};

export default overlayActions;
