import React, { Component } from 'react';
import { object, number, string, func } from 'prop-types';
import { Select, Input, Space, Pill } from 'tc-biq-design-system';

import { getDjangoApi } from '../../logic/services/api-factory';
import { gettext } from '../../logic/utilities/languageUtility';
import {
  getConvertedAmountLimits,
  getDefaultCurrency,
  isConvertedAmountValid,
} from '../../pages/home/withdrawal-single/Components/ExecuteWithdrawal/logic/utils';

import './CurrencyExchange.scss';

const propTypes = {
  amount: number,
  convertedAmount: number,
  exchangeRate: number,
  originalCurrency: string.isRequired,
  convertCurrency: object,
  onConversionDataChange: func.isRequired,
  userId: number,
  error: string,
};

const defaultProps = {
  amount: 0,
  exchangeRate: undefined,
  convertedAmount: undefined,
  convertCurrency: null,
  userId: 0,
  error: null,
};

const text = {
  CONVERT_CURRENCY: gettext('Convert currency'),
  EXCHANGE_RATE: gettext('Exchange rate'),
  CONVERTED_AMOUNT: gettext('Converted amount'),
  CONVERTED_AMOUNT_HELP: (min, max) => gettext('Min Amount: {{min}}; Max Amount: {{max}}', { min, max }),
};

class CurrencyExchange extends Component {
  constructor(props) {
    super(props);

    this.api = getDjangoApi('currencies');
    this.userApi = getDjangoApi('users');

    this.state = {
      currencies: [],
      originalExchangeRate: 0,
    };
  }

  async componentDidMount() {
    const { userId } = this.props;
    const [{ data: currencies }, { data: user }] = await Promise.all([
      this.api.list(),
      this.userApi.retrieve(userId),
    ]);

    this.setState({ currencies }, () => {
      const defaultCurrency = getDefaultCurrency({
        currencies,
        user,
        isCredit: false,
      });
      
      this.getExchangeRate(defaultCurrency);
    });
  }

  componentDidUpdate({ amount }) {
    const { onConversionDataChange, exchangeRate } = this.props;

    if (exchangeRate && amount !== _.get(this, 'props.amount')) {
      const { originalExchangeRate } = this.state;
      const convertedAmountLimits = getConvertedAmountLimits(this.convert(originalExchangeRate));
      const convertedAmount = this.convert(exchangeRate);
      onConversionDataChange({
        exchangeRate,
        convertedAmountLimits,
        convertedAmount,
        isConvertedAmountValid: isConvertedAmountValid(convertedAmount, convertedAmountLimits),
      });
    }
  }

  onConvertedAmountChange = ({ target }) => {
    const { originalExchangeRate } = this.state;
    const { onConversionDataChange, amount } = this.props;
    const convertedAmount = +target.value;
    const exchangeRate = _.round(convertedAmount / amount, 5);
    const convertedAmountLimits = getConvertedAmountLimits(this.convert(originalExchangeRate));
    onConversionDataChange({
      convertedAmount,
      exchangeRate,
      convertedAmountLimits,
      isConvertedAmountValid: isConvertedAmountValid(convertedAmount, convertedAmountLimits),
    });
  }

  getExchangeRate = (convertCurrency) => {
    const { originalCurrency, onConversionDataChange } = this.props;

    this.api.all(`${originalCurrency}/exchange_rate/${convertCurrency.symbol}`)
      .list()
      .then((response) => {
        const exchangeRate = +response.data.rate;
        const originalExchangeRate = exchangeRate;
        const convertedAmount = this.convert(exchangeRate);
        this.setState({
          originalExchangeRate,
        }, () => {
          const convertedAmountLimits = getConvertedAmountLimits(this.convert(originalExchangeRate));
          onConversionDataChange({
            exchangeRate,
            originalExchangeRate,
            convertedAmount,
            convertCurrency,
            convertedAmountLimits,
            isConvertedAmountValid: isConvertedAmountValid(convertedAmount, convertedAmountLimits),
          });
        });
      });
  }

  getConvertedAmountLimitsText = () => {
    const { originalExchangeRate } = this.state;
    const originalConvertedAmouth = this.convert(originalExchangeRate);
    const { min, max } = getConvertedAmountLimits(originalConvertedAmouth);

    if (!min || !max) return null;
    return text.CONVERTED_AMOUNT_HELP(min, max);
  }

  convert = (exchangeRate) => {
    const { amount } = this.props;
    return amount * exchangeRate;
  }

  render() {
    const { 
      originalCurrency, 
      convertCurrency, 
      exchangeRate,
      convertedAmount,
      error,
    } = this.props;

    const { currencies } = this.state;
    const convertedAmountLimits = this.getConvertedAmountLimitsText();
    
    return (
      <div>
        <Select 
          label={text.CONVERT_CURRENCY} 
          onChange={this.getExchangeRate}
          value={convertCurrency}
          options={currencies}
          valueKey="symbol"
          labelKey="symbol"
        />
        <Space size={16} />
        <div>{text.EXCHANGE_RATE}: <b>{exchangeRate}</b></div>
        <Space size={16} />
        <div className="biq-currency-converter__converted">
          <div className="biq-currency-converter__input">
            <Input 
              label={text.CONVERTED_AMOUNT}
              type="number"
              value={convertedAmount}
              onChange={this.onConvertedAmountChange}
            />
          </div>
          {originalCurrency && (
          <div className="biq-currency-converter__currency">
            {convertCurrency && <Pill big>{convertCurrency.symbol}</Pill>}
          </div>
          )}
        </div>
        <Space size={16} />
        <div>{convertedAmountLimits}</div>
        {error && <div className="text-help has-error">{error}</div>}
      </div>
    );
  }
}

CurrencyExchange.propTypes = propTypes;
CurrencyExchange.defaultProps = defaultProps;
export default CurrencyExchange;
