import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../logic/services/api-factory';

const riskLabelsApi = getDjangoApi('settings/risk_labels/');
const riskGroupApi = getDjangoApi('settings/risk_groups');

const initialState = {
  riskLabels: [],
  fetchRiskLabelsInProgress: false,
  updateRiskLabelsInProgress: false,
  fetchRiskGroupOptionsIn: false,
  riskGroupOptions: [],
};

const actionTypes = {
  FETCH_RISK_LABELS: 'FETCH_RISK_LABELS',
  FETCH_RISK_LABELS_FULFILLED: 'FETCH_RISK_LABELS_FULFILLED',
  FETCH_RISK_LABELS_PENDING: 'FETCH_RISK_LABELS_PENDING',
  FETCH_RISK_LABELS_REJECTED: 'FETCH_RISK_LABELS_REJECTED',

  UPDATE_RISK_LABELS: 'UPDATE_RISK_LABELS',
  UPDATE_RISK_LABELS_FULFILLED: 'UPDATE_RISK_LABELS_FULFILLED',
  UPDATE_RISK_LABELS_PENDING: 'UPDATE_RISK_LABELS_PENDING',
  UPDATE_RISK_LABELS_REJECTED: 'UPDATE_RISK_LABELS_REJECTED',

  FETCH_RISK_GROUP_OPTIONS: 'FETCH_RISK_GROUP_OPTIONS',
  FETCH_RISK_GROUP_OPTIONS_FULFILLED: 'FETCH_RISK_GROUP_OPTIONS_FULFILLED',
  FETCH_RISK_GROUP_OPTIONS_PENDING: 'FETCH_RISK_GROUP_OPTIONS_PENDING',
  FETCH_RISK_GROUP_OPTIONS_REJECTED: 'FETCH_RISK_GROUP_OPTIONS_REJECTED',

};

export const fetchRiskGroupOptions = createAction(actionTypes.FETCH_RISK_GROUP_OPTIONS,
  async () => {
    const response = await riskGroupApi.list();
    return response;
  });

export const updateRiskLabels = createAction(actionTypes.UPDATE_RISK_LABELS,
  async (riskLabels) => {
    const promises = riskLabels
      .map(({ id, risk_value_min, risk_value_max, label }) => riskLabelsApi.update(id, {
        risk_value_max,
        risk_value_min,
        label,
      }));
    await Promise.all(promises);
    return riskLabels;
  });

export const fetchRiskLabels = createAction(actionTypes.FETCH_RISK_LABELS,
  async () => {
    const { data } = await riskLabelsApi.list();
    return data;
  });

export default handleActions(
  {
    [actionTypes.FETCH_RISK_LABELS_PENDING]: state => ({ ...state, isLoadingRiskLabels: true }),
    [actionTypes.FETCH_RISK_LABELS_FULFILLED]: 
      (state, { payload: { results } }) => (
        { ...state,
          fetchRiskLabelsInProgress: false,
          riskLabels: results.sort((rl1, rl2) => rl1.id - rl2.id) }
      ),
    [actionTypes.FETCH_RISK_LABELS_REJECTED]:
      state => ({ ...state,
        riskLabels: initialState.riskLabels,
        fetchRiskLabelsInProgress: false }),
    [actionTypes.UPDATE_RISK_LABELS_PENDING]: 
      state => ({ ...state, updateRiskLabelsInProgress: true }),
    [actionTypes.UPDATE_RISK_LABELS_FULFILLED]: (state, { payload }) => ({
      ...state,
      updateRiskLabelsInProgress: false,
      riskLabels: payload,
    }),
    [actionTypes.UPDATE_RISK_LABELS_REJECTED]: state => ({
      ...state,
      updateRiskLabelsInProgress: false,  
    }),
    [actionTypes.FETCH_RISK_GROUP_OPTIONS_PENDING]:
     state => ({ ...state, fetchRiskGroupOptionsInProgres: true }),
    [actionTypes.FETCH_RISK_GROUP_OPTIONS_FULFILLED]:
       (state, { payload: { data } }) => ({
         ...state,
         riskGroupOptions: data,
         fetchRiskGroupOptionsInProgres: true }),
  },
  initialState,
);
