import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { object, bool, array } from 'prop-types';
import { notifier, Space, Sidepanel } from 'tc-biq-design-system';

import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import to from '../../../../../../../logic/utilities/to';
import { fetchTradingAccounts } from './Model';


const text = {
  SUCCESS: account => gettext('Changed password for Trading account {{account}}', { account }),
  PASSWORD: gettext('Password'),
  CONFIRM_PASSWORD: gettext('Confirm password'),
  SELECT: gettext('Change Trading Account Password'),
  SUPPORT: user => gettext('{{first_name}} {{last_name}} trading account platform does not support password change.', user),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const FORM_KEY = 'CHANGE_TA_PASSWORD_FORM';

const formConfig = {
  form: FORM_KEY,
};

const fields = [
  {
    type: 'password',
    id: 'password',
    name: 'password',
    label: text.PASSWORD,
  }, {
    type: 'password',
    id: 'password2',
    name: 'password2',
    label: text.CONFIRM_PASSWORD,
  },
];

const { create, resetFields, setFields, showLoader } = FormActionsFactory(FORM_KEY);

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_KEY}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  submitInProgress: bool.isRequired,
  fetchInProgress: bool.isRequired,
  fields: array.isRequired,
  accounts: array,
  formValues: object,
};

const defaultProps = {
  user: null,
  actions: null,
  accounts: [],
  formValues: {},
};

class ChangeTaPassword extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      support: true,
    };
    this.actions = props.actions;
    this.api = getDjangoApi('users');
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    this.actions.showFormLoader(true);
    this.actions.fetchTradingAccounts(user).then(() => {
      const { accounts } = this.props;
      if (_.isEmpty(accounts)) {
        this.setState({
          support: false,
        });
      }
      this.actions.showFormLoader(false);
    });
  }

  componentDidUpdate() {
    // eslint-disable-next-line react/destructuring-assignment
    const currentFields = this.props.fields;
    if (_.isEmpty(currentFields)) {
      this.setFormFields();
    }
  }

  async onSubmit() {
    const { user, formValues } = this.props;
    const { trading_accounts } = formValues;
    const apiRequest = () => this.api
      .one(user.id)
      .all('trading_accounts')
      .one(trading_accounts.id)
      .all('change_password');
    const [err] = await to(this.actions.create(apiRequest()));
    err ? this.onError(err) : this.onSuccess(formValues);
  }

  onSuccess(formValues) {
    notifier.success(text.SUCCESS(formValues.trading_accounts.display_name));
    this.onClose();
  }

  onError({ data }) {
    const { non_field_errors } = data;
    if (non_field_errors) {
      notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
    }
  }

  onClose() {
    const { sidepanelManager } = this.props;
    sidepanelManager.close();
  }

  setFormFields() {
    const { accounts } = this.props;

    if (_.isEmpty(accounts)) return;
    
    const mappedAccounts = accounts
      .filter(account => (account.is_password_editable))
      .map(({ id, external_id, backend }) => ({
        id,
        display_name: `${external_id}, ${backend.name}`,
      }));

    const taField = {
      type: 'select',
      id: 'trading_accounts',
      name: 'trading_accounts',
      label: text.SELECT,
      options: mappedAccounts,
      joinValues: true,
      valueKey: 'value',
      labelKey: 'display_name',
    };

    this.actions.setFields([taField, ...fields]);
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    const { FormComponent } = this;
    return <FormComponent formId={FORM_KEY} />;
  }

  render() {
    const {
      sidepanelManager,
      user,
      actions,
      submitInProgress,
      fetchInProgress,
      ...options
    } = this.props;

    const { support } = this.state;

    return (
      <Sidepanel
        {...options}
        footerRender={customFooter(this.onSubmit, this.onClose, submitInProgress)}
      >
        <Space size={16} />
        {support ? this.renderForm() : <div>{text.SUPPORT(user)}</div>}
        <Space size={16} />
      </Sidepanel>
    );
  }
}

ChangeTaPassword.propTypes = propTypes;
ChangeTaPassword.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      resetFields,
      setFields,
      create,
      fetchTradingAccounts,
      showFormLoader: showLoader,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages, forms }) => {
  const page = pages.USER_SINGLE;
  const action = page.actions.CHANGE_TA_PASSWORD;
  const form = forms[FORM_KEY];
  return {
    submitInProgress: form.submitInProgress,
    fields: form.fields,
    formValues: form.values,
    fetchInProgress: action.fetchInProgress,
    accounts: action.accounts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTaPassword);
