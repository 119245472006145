import React, { Component, Fragment } from 'react';
import { object, array, bool } from 'prop-types';
import { Button, Space } from 'tc-biq-design-system';
import { generatePath } from 'react-router-dom';

import Page from '../../../../../../components/Page';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import FormFactory from '../../../../../../components/form';
import FormPanel from '../../../../../../components/form/Components/FormPanel/FormPanel';
import to from '../../../../../../logic/utilities/to';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import Substatuses from './Substatuses';
import appRoutes from '../../../../../../components/App/Router/appRoutes';
import { formErrorHandler } from '../../../../../../components/form/logic/utils';

import './SalesStatusForm.scss';

export const FORM_ID = 'SALES_STATUS_FLOW_FORM';

const cfApi = getDjangoApi('communication_flow');
const adApi = getDjangoApi('autodialer_settings');

const modes = {
  CREATE: 'add',
  EDIT: 'edit',
  CLONE: 'clone',
};
 
const text = {
  TITLE: gettext('Communication flow'),
  FLOW_TITLE: gettext('Title'),
  DISCARD: gettext('Discard'),
  ERROR_GENERAL: gettext('Something went wrong'),
  SUBMIT: {
    [modes.CREATE]: gettext('Create communication flow'),
    [modes.EDIT]: gettext('Update communication flow'),
    [modes.CLONE]: gettext('Clone communication flow'),
  },
};

const bread = [{ label: text.TITLE, route: appRoutes.SETTINGS_SALES_STATUSES_AND_SUBSTATUSES }];

const propTypes = {
  fields: array.isRequired,
  values: object.isRequired,
  actions: object.isRequired,
  submitInProgress: bool.isRequired,
  match: object.isRequired,
  history: object.isRequired,
};

const modifiers = (mode, isAutoDialer) => ({
  name: {
    label: text.FLOW_TITLE,
    disabled: (mode === modes.EDIT) && !isAutoDialer,
  },
  flow: field => ({
    ...field,
    disabled: mode === modes.EDIT,
    horizontal: true,
    type: 'radio-group',
    transformValue: val => Number(val),
    options: field.options.map(({ display_name, value }) => ({ value, text: display_name })),
  }),
  team: {
    disabled: mode === modes.EDIT,
  },
  whitelabel: {
    disabled: mode === modes.EDIT,
  },
  business_group: {
    disabled: mode === modes.EDIT,
  },
});

class SalesStatusForm extends Component {
  constructor(props) {
    super(props);
    const { tab, mode, id } = props.match.params;
    this.tab = tab;
    this.mode = mode;
    this.id = id;
    this.isAutoDialer = this.tab === 'auto-dialer';
    this.api = this.isAutoDialer ? adApi : cfApi;
  }

  componentDidUpdate() {
    const { fields, values } = this.props;
    if (this.id && !_.isEmpty(fields) && _.isEmpty(values)) this.setFormData();  
  }

  onChangeSubstatuses = (value) => {
    const { actions } = this.props;

    actions.setFieldValue({
      id: 'substatuses',
      value,
    });
  }

  onDiscard = () => {
    this.goToCfPage();
  }

  onSubmit = async () => {
    const { actions } = this.props;
    
    const promise = this.mode === modes.EDIT 
      ? actions.update(this.api, this.id, this.getRequestPayload, true)
      : actions.create(this.api, this.getRequestPayload);
    const [err] = await to(promise);
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess = () => {
    this.goToCfPage();
  }

  onError(payload) {
    formErrorHandler(text.ERROR_GENERAL)(payload);
  }

  getRequestPayload = (values) => {
    let modifier;
    if (this.isAutoDialer) {
      modifier = key => ({
        name: () => values[key] && values[key],
        substatuses: () => values[key].map(({ name }) => ({ name })).filter(({ name }) => !!name),
      })[key];
    } else {
      modifier = key => ({
        business_group: () => values[key] && values[key].value,
        flow: () => values[key],
        name: () => values[key] && values[key],
        substatuses: () => values[key].map(({ name, notes, tasks }) => ({ name, notes, tasks })).filter(({ name }) => !!name),
        team: () => values[key] && values[key].id,
        whitelabel: () => values[key] && values[key].value,
      })[key];
    }

    return Object.keys(values).reduce((acc, key) => ({ ...acc, 
      [key]: modifier(key)() }), {});
  }

  setFormData = async () => {
    const { data } = await this.api.retrieve(this.id);
    const { actions } = this.props;

    const { fields } = this.props;
    const { excludeFields } = this.formConfig();
    const { options: flowOptions } = fields.find(field => field.id === 'flow') || {};

    const keys = fields.reduce((acc, field) => (!excludeFields.includes(field.id) ? [...acc, field.id] : acc), []);

    const mapper = (id, option) => {
      const map = {
        whitelabel: () => option && ({
          display_name: option.name,
          value: option.id,
        }),
        business_group: () => option && ({
          display_name: option.name,
          value: option.id,
        }),
        flow: () => (flowOptions.find(o => o.display_name === option) || {}).value,
      };

      return map[id] ? map[id]() : option;
    };


    keys.forEach(id => actions.setFieldValue({
      id,
      value: this.isAutoDialer ? data[id] : mapper(id, data[id]),
    }));
  }

  goToCfPage = () => {
    const { history } = this.props;
    history.push(generatePath(appRoutes.SETTINGS_SALES_STATUSES_AND_SUBSTATUSES, {
      tab: this.tab,
    }));
  }

  formConfig = () => ({
    form: FORM_ID,
    api: this.api,
    excludeFields: [
      'is_enabled',
      'allow_manager_self_assign',
      ...(this.isAutoDialer ? ['team', 'whitelabel', 'business_group', 'flow', 'type'] : []),
    ],
  })

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { values, submitInProgress } = this.props;
    const { substatuses } = values || {}; 
    const Form = this.renderForm();

    return (
      <Page title={text.TITLE} bread={bread}>
        <div className="cf-form">
          <Space size={20} />
          <FormPanel wide>
            <FormPanel.Section>
              <Form 
                modifiers={modifiers(this.mode, this.isAutoDialer)}
                renderForm={formFields => (
                  <Fragment>
                    <div className="cf-form__wrapper">
                      <div className="cf-form__title">{formFields.name}</div>
                      <div className="cf-form__flow">{formFields.flow}</div>
                    </div>
                    <div className="cf-form__wrapper">
                      <div className="cf-form__select">{formFields.business_group}</div>
                      <div className="cf-form__select">{formFields.whitelabel}</div>
                      <div className="cf-form__select">{formFields.team}</div> 
                    </div>
                  </Fragment>
                )}
              />
              <Substatuses isAutoDialer={this.isAutoDialer} value={substatuses} onChange={this.onChangeSubstatuses} />
            </FormPanel.Section>
            <FormPanel.Submit>
              <Button loading={submitInProgress} onClick={this.onDiscard} color="ghost">{text.DISCARD}</Button>
              <Button loading={submitInProgress} onClick={this.onSubmit}>{text.SUBMIT[this.mode]}</Button>
            </FormPanel.Submit>
          </FormPanel>
        </div>
      </Page>
    );
  }
}

SalesStatusForm.propTypes = propTypes;

export default SalesStatusForm;
