import React, { useCallback, useEffect, useMemo } from 'react';
import { Input, Select, Toggle } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { array, bool, object, string } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { gettext } from '../../../../../../logic/utilities/languageUtility';
import Page from '../../../../../../components/Page';
import connect from '../../../../../../logic/connect';
import QueryBuilder from '../../../../../../components/QueryBuilder';
import getID from '../../../../../../logic/utilities/getID';
import { depositExpiryPeriodActions } from './Model';
import QueryBuilderSubmitSection from '../../../../../../components/QueryBuilder/QueryBuilderSubmitSection';
import { refactoredExtractGroup } from '../../../../../../logic/services/query-adapter';
import { onRuleError } from '../../utils';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

const TYPE_MODES = {
  create: 'create',
  edit: 'edit',
};

const text = {
  TITLE: {
    [TYPE_MODES.create]: gettext('Create New Deposit expiry Period'),
    [TYPE_MODES.clone]: gettext('Create New Deposit expiry Period'),
    [TYPE_MODES.edit]: gettext('Edit Deposit expiry Period'),
  },
  NAME_INPUT_LABEL: gettext('Name*'),
  EXPIRY_PERIOD_INPUT_LABEL: gettext('Expiry period'),
  SELECT_EXPIRY_TYPE_LABEL: gettext('Expiry period type'),
  SELECT_METHOD_LABEL: gettext('Deposit method*'),
  SELECT_ACQUIRER_LABEL: gettext('Acquirer*'),
  SELECT_APPLIES_TO_LABEL: gettext('Applies to*'),
  DEPOSIT_SETTINGS_CRUMB: gettext('Deposit expiry period'),
  TOGGLE_STATE_LABEL: gettext('State'),
};

const propTypes = {
  submitInProgress: bool,
  actions: object.isRequired,
  fields: array,
  methods: array,
  selectedMethod: object,
  name: string,
  group: object,
  errors: object,
  selectedAcquirer: object,
  acquirers: array,
  selectedExpiryType: object,
  expiryPeriod: object,
  expiryTypes: array,
  selectedAppliesTo: object,
  appliesTo: array,
  expiryEnabled: bool,
  match: object.isRequired,
  history: object.isRequired,
};

const defaultProps = {
  submitInProgress: false,
  fields: [],
  methods: [],
  expiryTypes: [],
  acquirers: [],
  appliesTo: [],
  selectedMethod: null,
  name: '',
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  errors: {
    name: [''],
    method: [''],
  },
  selectedAcquirer: null,
  selectedExpiryType: null,
  expiryPeriod: null,
  selectedAppliesTo: null,
  expiryEnabled: false,
};

const EditDepositExpiry = (props) => {
  const {
    name, actions, errors, methods, selectedMethod, group, fields, selectedAcquirer, acquirers,
    selectedExpiryType, expiryPeriod, expiryTypes, selectedAppliesTo, appliesTo, expiryEnabled,
    match, history, submitInProgress } = props;

  const { setSelectedMethod, setExpiryName, setExpiryCondition, getFieldOptions, getDepositInfo,
    setSelectedAcquirer, setExpiryType, setExpiryPeriod, setAppliesTo, setExpiryEnable, submitDeposit, resetState } = actions;

  const { id } = match.params;

  const typeMode = useMemo(() => {
    const pathRoutes = getID();
    if (_.includes(pathRoutes, 'add')) return TYPE_MODES.create;
    if (_.includes(pathRoutes, 'clone')) return TYPE_MODES.create;
    if (_.includes(pathRoutes, 'edit')) return TYPE_MODES.edit;
  }, [_]);

  const redirectToTableView = () => history.push(appRoutes.SETTINGS_DEPOSITS);
  const title = text.TITLE[typeMode];
  const crumbs = useMemo(() => [
    { label: text.DEPOSIT_SETTINGS_CRUMB, route: appRoutes.SETTINGS_DEPOSITS },
    { label: title, route: '' },
  ], [typeMode]);
  

  useEffect(() => {
    getFieldOptions().then(() => {
      if (id) getDepositInfo(id);
    });
    return resetState;
  }, [id, resetState]);

  const submitExpiryPeriod = useCallback(() => {
    const payload = {
      name,
      acquirer: _.get(selectedAcquirer, 'value'),
      applies_to: _.get(selectedAppliesTo, 'value'),
      expiry_conditions: refactoredExtractGroup(group) || null,
      expiry_period: expiryPeriod,
      expiry_period_type: _.get(selectedExpiryType, 'value'),
      method: _.get(selectedMethod, 'value'),
      is_enabled: expiryEnabled,
    };
    submitDeposit(typeMode === TYPE_MODES.edit, payload, id).then(redirectToTableView, onRuleError);
  }, [name, selectedAcquirer, selectedExpiryType, selectedAppliesTo, group, selectedMethod, expiryPeriod, expiryEnabled, id, submitDeposit, typeMode]);

  return (
    <Page title={title} bread={crumbs}>
      <div className="deposit-expiry-period__container-div">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', margin: '10px 0' }}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label style={{ marginRight: '5px', position: 'relative', top: '-3px' }}>{text.TOGGLE_STATE_LABEL}</label>
          <Toggle checked={expiryEnabled} onChange={setExpiryEnable} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0' }}>
          <Input
            label={text.NAME_INPUT_LABEL}
            style={{ width: '100%', marginRight: '14px' }}
            placeholder={text.NAME_INPUT_LABEL}
            value={name}
            onChange={setExpiryName}
            helpText={errors.name[0]}
            hasError={!!errors.name[0].length}
          />
          <div style={{ width: '100%', marginRight: '14px' }}>
            <Select
              label={text.SELECT_ACQUIRER_LABEL}
              placeholder={text.SELECT_ACQUIRER_LABEL}
              options={acquirers}
              labelKey="display_name"
              onChange={setSelectedAcquirer}
              value={selectedAcquirer}
              helpText={errors.acquirer[0]}
              hasError={!!errors.acquirer[0].length}
            />
          </div>
          <div style={{ width: '100%' }}>
            <Select
              label={text.SELECT_METHOD_LABEL}
              placeholder={text.SELECT_METHOD_LABEL}
              options={methods}
              labelKey="display_name"
              onChange={setSelectedMethod}
              value={selectedMethod}
              helpText={errors.method[0]}
              hasError={!!errors.method[0].length}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0' }}>
          <Input
            label={text.EXPIRY_PERIOD_INPUT_LABEL}
            style={{ width: '100%', marginRight: '14px' }}
            placeholder={text.EXPIRY_PERIOD_INPUT_LABEL}
            value={expiryPeriod}
            type="number"
            onChange={setExpiryPeriod}
            helpText={errors.expiryPeriod[0]}
            hasError={!!errors.expiryPeriod[0].length}
          />
          <div style={{ width: '100%', marginRight: '14px' }}>
            <Select
              label={text.SELECT_EXPIRY_TYPE_LABEL}
              placeholder={text.SELECT_EXPIRY_TYPE_LABEL}
              options={expiryTypes}
              labelKey="display_name"
              onChange={setExpiryType}
              value={selectedExpiryType}
              helpText={errors.expiryType[0]}
              hasError={!!errors.expiryType[0].length}
            />
          </div>
          <div style={{ width: '100%' }}>
            <Select
              label={text.SELECT_APPLIES_TO_LABEL}
              placeholder={text.SELECT_APPLIES_TO_LABEL}
              options={appliesTo}
              labelKey="display_name"
              onChange={setAppliesTo}
              value={selectedAppliesTo}
              helpText={errors.appliesTo[0]}
              hasError={!!errors.appliesTo[0].length}
            />
          </div>
        </div>
        <QueryBuilder
          group={group}
          onStateUpdate={setExpiryCondition}
          fields={fields}
          className="deposit-expiry-period__query-builder"
        />
        <QueryBuilderSubmitSection
          onSubmit={submitExpiryPeriod}
          onDiscard={redirectToTableView}
          type={typeMode}
          submitInProgress={submitInProgress}
        />
      </div>
    </Page>
  );
};

const mapStateToProps = ({ pages }) => {
  const page = pages.DEPOSIT_EXPIRY_PERIOD_SINGLE;
  return { ...page };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...depositExpiryPeriodActions }, dispatch),
});

EditDepositExpiry.propTypes = propTypes;
EditDepositExpiry.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditDepositExpiry));
