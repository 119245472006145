import React, { Component, Fragment } from 'react';
import { func } from 'prop-types';

import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import { hasAccess } from '../../../../../../logic/services/acl';
import modifiers from './modifiers';
import TradingGroupSidepanel from './TradingGroupsSidepanel';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';
import { formErrorHandler } from '../../../../../../components/form/logic/utils';

const apiUrl = 'trading_groups';

const api = getDjangoApi(apiUrl);

export const tableConfig = () => ({
  reducerKey: 'TRADING_PLATFORM',
  apiUrl,
  tableKey: 'TRADING_GROUPS_TABLE',
});

const propTypes = {
  dispatch: func.isRequired,
};

class TradingGroupsTable extends Component {
  constructor(props) {
    super(props);
    this.Grid = withTable(GridTab, tableConfig, null, { withActions: true })();
    this.tableActions = {
      ...(hasAccess('tpi.trading_groups.update') && { onActiveToggle: this.onActiveToggle }),
    };
  }

  onActiveToggle = async ({ id, active }) => {
    const [err] = await to(api.updatePart(id, { active: !active }));
    err ? this.onError(err) : this.reloadTable();
  }

  onError = (payload) => {
    formErrorHandler()(payload);
  }

  reloadTable = () => {
    const { dispatch } = this.props;
    const [, tableActions] = this.Grid;
    dispatch(tableActions.fetchTableData());
  }

  render() {
    const [Table] = this.Grid;
    return (
      <Fragment>
        <Table
          singleActions
          modifier={modifiers(this.tableActions)}
        />
        <TradingGroupSidepanel onSuccess={this.reloadTable} />
      </Fragment>
    );
  }
}

TradingGroupsTable.propTypes = propTypes;

export default TradingGroupsTable;
