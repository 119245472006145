import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';

import session from '../../../../../logic/services/session';
import connect from '../../../../../logic/connect';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { thousandSeparator } from '../../../../../logic/utilities/formatters/index';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';
import UserHeader from './UserHeader';
import { updateUser } from '../Model';

const text = {
  TOTAL_EQUITY: gettext('Total equity'),
  TOTAL_DEPOSITS: gettext('Total deposits'),
  WITHDRAWALS: gettext('Withdrawals'),
  TOTAL_WITHDRAWALS: gettext('Total withdrawals'),
  WHITELABEL: gettext('Whitelabel'),
  TOTAL_EARNED: gettext('Total earned'),
  PAID_TO_DATE: gettext('Total paid to date'),
  CACHE_BALANCE: gettext('Balance'),
  CLIENTS: gettext('Clients'),
  WITHDRAWABLE_AMOUNT: gettext('Withdrawable amount'),
  SUM_PENDING_WITHDRAWALS: gettext('Sum of pending withdrawals requests'),
};

const propTypes = {
  isIb: bool,
  user: object,
  actions: object.isRequired,
};

const defaultProps = {
  user: null,
  isIb: false,
};

class UserHeaderController extends Component {
  constructor(props) {
    super(props);

    this.isIb = props.isIb;
    this.actions = props.actions;
    this.totalEquityEnabled = isFeatureEnabled()('TOTAL_EQUITY_BASE_CURRENCY');

    this.onTagsUpdate = this.onTagsUpdate.bind(this);
  }

  onTagsUpdate(tags) {
    const { updateUser } = this.actions;
    
    updateUser({ tags });
  }

  getUser(user) {
    if (!user) return null;

    const finance = this.getFinancialDetails(user);
    const location = this.getUserLocation(user);

    return {
      ...user,
      location,
      finance,
    };
  }

  getUserLocation(user) {
    if (!user) return '';

    const countryKey = this.isIb ? 'country' : 'addr_country';

    const city = user.addr_city;
    const country = get(user[countryKey], 'country_name');

    return `${city}, ${country}`;
  }

  getFinancialDetails(user) {
    const { baseCurrency } = session;

    const currency = this.isIb ? _.get(user, 'currency.symbol') : _.get(baseCurrency, 'symbol');

    if (!user) return [];

    const ibFinance = this.isIb
      ? [
        {
          entity: text.CLIENTS,
          value: user.clients,
        },
        {
          entity: text.TOTAL_DEPOSITS,
          value: `${currency} ${thousandSeparator(user.total_deposit)}`,
        },
        {
          entity: text.TOTAL_EARNED,
          value: `${currency} ${thousandSeparator(user.total_earned)}`,
        },
        {
          entity: text.PAID_TO_DATE,
          value: `${currency} ${thousandSeparator(user.total_withdrawn)}`,
        },
        { entity: text.CACHE_BALANCE, 
          value: `${currency} ${thousandSeparator(user.balance)}`,
        },
        {
          entity: text.WITHDRAWABLE_AMOUNT,
          value: `${currency} ${thousandSeparator(user.withdrawable_amount)}`,
        },
        {
          entity: text.SUM_PENDING_WITHDRAWALS,
          value: `${currency} ${thousandSeparator(user.pending_withdrawals_amount)}`,
        },
        {
          entity: text.TOTAL_WITHDRAWALS,
          value: `${currency} ${thousandSeparator(user.total_withdrawal)}`,
        },
      ]
      : [];

    const clientFinance = !this.isIb
      ? [
        {
          entity: text.TOTAL_DEPOSITS,
          value: `${currency} ${thousandSeparator(user.total_deposit_base_currency)}`,
        },
        ...(this.totalEquityEnabled && [{
          entity: text.TOTAL_EQUITY,
          value: `${currency} ${thousandSeparator(user.total_equity_base_currency)}`,
        }]),
        {
          entity: text.WITHDRAWALS,
          value: `${currency} ${thousandSeparator(user.total_withdrawal_base_currency)}`,
        },
      ]
      : [];

    return [...ibFinance, ...clientFinance];
  }

  render() {
    const { user, ...rest } = this.props;
    const mappedUser = this.getUser(user);
    
    return <UserHeader {...rest} user={mappedUser} onTagsUpdate={this.onTagsUpdate} />;
  }
}

UserHeaderController.propTypes = propTypes;
UserHeaderController.defaultProps = defaultProps;

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  return {
    user: page.userDetail.user,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateUser,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserHeaderController);
