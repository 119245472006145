import React, { Component } from 'react';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { bool, object, array } from 'prop-types';
import { get } from 'lodash';

import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { SidepanelFooter } from '../../../../../../../components/common';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import FormFactory from '../../../../../../../components/form';

const text = {
  TITLE: gettext('Save'),
  SUBMIT_SUCCESS: gettext('Integration saved'),
  BUTTON_LABELS: {
    confirm: gettext('Save'),
    cancel: gettext('Cancel'),
  },
};

const propTypes = {
  submitInProgress: bool,
  submitSuccess: bool,
  fetchInProgress: bool,
  fetchErrors: object,
  integration: object,
  actions: object.isRequired,
  sidepanel: object.isRequired,
  formFields: array.isRequired,
  formValues: object.isRequired,
};

const defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  fetchInProgress: false,
  fetchErrors: null,
  integration: null,
};

const SIDEPANEL_ID = 'INTEGRATION_FORM';

const customFooter = (close, execute, submitInProgess, disableUpdate) => (
  <SidepanelFooter
    submitInProgress={submitInProgess}
    disableSubmit={disableUpdate}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={SIDEPANEL_ID}
  />
);


const formConfig = {
  form: SIDEPANEL_ID,
};


class IntegrationForm extends Component {
  constructor(props) {
    super(props);

    this.actions = props.actions;
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { submitSuccess, submitInProgress, fetchInProgress } = this.props;

    if (!submitInProgress && !fetchInProgress) {
      this.resetForm(prevProps);

      this.fetchIntegration();

      this.setFormFields();
    }

    if (submitSuccess) {
      notifier.success(text.SUBMIT_SUCCESS);
      this.onClose();
    }
  }

  onSubmit() {
    const { submitIntegration } = this.actions;
    const { formValues, sidepanel: { parameters } } = this.props;
    const apiIntegration = getDjangoApi(parameters.serviceUrl);
    
    submitIntegration(apiIntegration, formValues);
  }

  onClose() {
    this.actions.closeSidepanel(SIDEPANEL_ID);
    this.resetSidepanel();
  }

  setFormFields() {
    const { formFields, integration } = this.props;
    const { setFields, setRowData } = this.actions;
    
    if (_.isEmpty(formFields) && !_.isEmpty(integration)) {
      const parsedFields = integration.options.map(item => ({
        type: 'text',
        id: item.key,
        name: item.key,
        placeholder: null,
        label: item.description || item.slug,
      }));
      const parsedData = integration.options.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});

      setRowData(parsedData);
      setFields(parsedFields);
    }
  }

  resetForm(prevProps) {
    const { formFields, integration } = this.props;
    const previousName = get(prevProps, 'sidepanel.parameters.name');    
    const currentName = get(this.props, 'sidepanel.parameters.name');

    if (previousName !== currentName && !_.isEmpty(formFields) && !_.isEmpty(integration)) {
      this.resetSidepanel();
    }
  }

  fetchIntegration() {
    const { sidepanel: { parameters }, integration, fetchErrors } = this.props;
    const { fetchIntegration, setIntegration } = this.actions;

    if (_.isEmpty(integration) && !fetchErrors && !_.isEmpty(parameters)) {
      if (parameters.fields) {
        setIntegration({ options: parameters.fields });
      } else {
        const apiFetchIntegration = getDjangoApi(parameters.serviceUrl);
        fetchIntegration(apiFetchIntegration);
      }
    }
  }

  resetSidepanel() {
    this.actions.resetIntegration();
    this.actions.resetFields();
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    const { FormComponent } = this;
    return <FormComponent formId={SIDEPANEL_ID} />;
  }

  render() {
    const { sidepanel, submitInProgress } = this.props;
    const disableUpdate = get(this.props, 'sidepanel.parameters.disableUpdate');
    const integrationTitle = get(this.props, 'sidepanel.parameters.text');
    
    return (
      <Sidepanel
        icon="Integrations"
        type="info"
        title={`${text.TITLE} ${integrationTitle}`}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.onClose, this.onSubmit, submitInProgress, disableUpdate)}
      >
        { this.renderForm() }
      </Sidepanel>
    );
  }
}

IntegrationForm.defaultProps = defaultProps;
IntegrationForm.propTypes = propTypes;

export default IntegrationForm;
