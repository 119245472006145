import { bindActionCreators } from 'redux';

import IntegrationForm from './IntegrationForm';
import connect from '../../../../../../../logic/connect';
import overlayActions from '../../../../../../../components/overlay';
import { fetchIntegration, submitIntegration, resetIntegration, setIntegration } from './Model';
import { FormActionsFactory } from '../../../../../../../components/form';

const SIDEPANEL_ID = 'INTEGRATION_FORM';

const { setFields, setRowData, resetFields } = FormActionsFactory(SIDEPANEL_ID);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      updateSidepanel: overlayActions.update,
      fetchIntegration,
      submitIntegration,
      resetIntegration,
      setIntegration,
      setFields,
      setRowData,
      resetFields,
    },
    dispatch,
  ),
});
  
const mapStateToProps = ({ overlays, pages, forms }) => {
  const integrations = pages.INTEGRATIONS.form;
  const integrationForm = forms[SIDEPANEL_ID];
  
  return {
    sidepanel: overlays[SIDEPANEL_ID],
    submitInProgress: integrations.submitInProgress,
    submitSuccess: integrations.submitSuccess,
    fetchInProgress: integrations.fetchInProgress,
    fetchErrors: integrations.fetchErrors,
    integration: integrations.integration,
    formFields: integrationForm.fields,
    formValues: integrationForm.values,
  };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationForm);
