import { bindActionCreators } from 'redux';

import CreateTocSidepanel, { SIDEPANEL_ID, FORM_ID } from './CreateTocSidepanel';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import connect from '../../../../../../logic/connect';
import { FormActionsFactory } from '../../../../../../components/form';
import overlayActions from '../../../../../../components/overlay';

const { errorFields, create, update, resetFields, setRowData, setFieldValue } = FormActionsFactory(FORM_ID);

export const mapStateToProps = ({ overlays, forms }) => {
  const sidepanel = overlays[SIDEPANEL_ID];
  const { fields, submitInProgress, values } = forms[FORM_ID];
  return { sidepanel, fields, values, submitInProgress };
};

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      errorFields,
      create,
      resetFields,
      setRowData,
      setFieldValue,
      update,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(CreateTocSidepanel));

export { SIDEPANEL_ID };
