import React from 'react';
import { Route } from 'react-router-dom';
import { shape, string, func, node, oneOfType } from 'prop-types';

import Authentication from '../../Authentication';


const propTypes = {
  page: shape({
    component: oneOfType([func, node]).isRequired,
    path: string.isRequired,
    aclFn: func,
    aclId: string,
  }).isRequired,
};

const AppRoute = ({ page, ...props }) => (
  <Route {...props}>
    <Authentication page={page}>
      <page.component />
    </Authentication>
  </Route>
);

AppRoute.propTypes = propTypes;

export default AppRoute;
