import { bindActionCreators } from 'redux';

import EditTags from '../../../../../components/common/sidepanels/bulk/EditTags';
import connect from '../../../../../logic/connect';
import overlayActions from '../../../../../components/overlay';
import bulkActionsActionFactory from '../../../../../components/common/sidepanels/bulk/actions';

const { 
  updateData, 
  resetInput, 
  shouldRemoveTags, 
  submit, 
} = bulkActionsActionFactory('CLIENTS_EDIT_TAGS');

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateData,
      resetInput,
      shouldRemoveTags,
      submit,
      closeSidepanel: overlayActions.close,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ overlays, pages }) => {
  const editTags = pages.CLIENTS.bulkActions.EDIT_TAGS;
  return {
    sidepanel: overlays.EDIT_TAGS,
    fieldData: editTags.fieldData,
    submitInProgress: editTags.submitInProgress,
    removeTags: editTags.removeTags,
    submitSuccess: editTags.submitSuccess,
  };
};

export default connect(
  mapStateToProps, 
  mapDispatchToProps,
)(EditTags);
