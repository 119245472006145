import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import connect from '../../../../../../logic/connect';
import WithdrawalFlowStep from './WithdrawalFlowStep';
import { withdrawalFlowStepActions } from './Model';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

const mapStateToProps = ({ pages }) => {
  const page = pages.WITHDRAWAL_FLOW_STEP;
  return { ...page };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...withdrawalFlowStepActions }, dispatch),
});

const WithdrawalFlowStepPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(WithdrawalFlowStep); 

export default {
  component: WithdrawalFlowStepPage,
  aclId: 'withdrawal_flow.settings.update',
  path: appRoutes.SETTINGS_WITDRAWAL_FLOW,
};

export const WithdrawalFlowStepCreate = {
  component: WithdrawalFlowStepPage,
  aclId: 'withdrawal_flow.settings.create',
  path: appRoutes.SETTINGS_WITDRAWAL_FLOW_CREATE,
};
