import React from 'react';
import { bindActionCreators } from 'redux';
import { object, bool, string } from 'prop-types';
import { Space, Sidepanel } from 'tc-biq-design-system';

import LoginAsUser from '../../../../../../../components/LoginAsUser';
import connect from '../../../../../../../logic/connect';
import { fetchLoginToken } from './Model';

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  isIb: bool,
  token: string,
  domain: string,
};

const defaultProps = {
  user: null,
  actions: null,
  isIb: false,
  token: '',
  domain: null,
};

const Login = ({ sidepanelManager, user, isIb, actions, token, domain, ...options }) => {
  if (!token) {
    actions.fetchLoginToken(user.id, isIb);
    return null;
  }

  return (
    <Sidepanel {...options} footerRender={() => null}>
      <Space size={16} />
      <LoginAsUser token={token} domain={domain} isIb={isIb}>
        <span style={{ color: '#006BE6' }}>Click here to login as user.</span>
      </LoginAsUser>
    </Sidepanel>
  );
};

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { fetchLoginToken },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const { token, domain } = page.actions.LOGIN_AS_USER;
  return { token, domain };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
