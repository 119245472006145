import { object } from 'prop-types';
import StateCell from './StateCell';

const propTypes = {
  data: object.isRequired,
};

const StepCell = key => ({ data }) => {
  const columnData = data.steps.find(element => element.key === key);
  return columnData && columnData.value ? StateCell({ value: columnData.value }) : null;
};

StepCell.propTypes = propTypes;

export default StepCell;
