import { dateTimeFormatter } from '../../../../../../logic/utilities/formatters';

const modifier = {
  payment_gateway: {
    cellRendererFramework: ({ data: { payment_gateway } }) => payment_gateway && payment_gateway.name,
  },
};

export const bankAndWalletModifier = {
  ...modifier,
  last_used: {
    cellRendererFramework: ({ value }) => dateTimeFormatter(value),
  },
};

export default modifier;
