import React, { Component } from 'react';
import { Sidepanel, notifier, Input } from 'tc-biq-design-system';
import { object, func, string } from 'prop-types';
import { bindActionCreators } from 'redux';
import { handleActions, createAction } from 'redux-actions';

import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import overlayActions from '../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../components/common';

export const SIDEPANEL_ID = 'ADD_EDIT_GATEWAY';

const propTypes = {
  state: object.isRequired,
  onSuccess: func.isRequired,
  sidepanel: object.isRequired,
  actions: object.isRequired,
  api: string.isRequired,
};

const actionTypes = {
  UPDATE_GATEWAY_VALUE: 'UPDATE_GATEWAY_VALUE',
  RESET_STATE: 'RESET_STATE',
  SUBMIT: 'SUBMIT',
  SUBMIT_PENDING: 'SUBMIT_PENDING',
  SUBMIT_REJECTED: 'SUBMIT_REJECTED',
  SUBMIT_FULFILLED: 'SUBMIT_FULFILLED',
};

const initialState = {
  gateway: '',
  submitInProgress: false,
  errors: {},
};

const saveGateway = (gateway, action, api) => {
  const request = { name: gateway.value };
  return action === 'add' ? api.create(request) : api.update(gateway.id, request);
};

const updateGateway = createAction(actionTypes.UPDATE_GATEWAY_VALUE, value => value);
const resetState = createAction(actionTypes.RESET_STATE);
const submit = createAction(actionTypes.SUBMIT, saveGateway);

const onError = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  errors: payload.data,
});

const onSuccess = state => ({ ...state, submitInProgress: false, errors: {} });

export const reducer = handleActions(
  {
    [actionTypes.UPDATE_GATEWAY_VALUE]: (state, { payload }) => ({ ...state, gateway: payload }),
    [actionTypes.RESET_STATE]: () => ({ ...initialState }),
    [actionTypes.SUBMIT_PENDING]: state => ({ ...state, submitInProgress: true }),
    [actionTypes.SUBMIT_REJECTED]: onError,
    [actionTypes.SUBMIT_FULFILLED]: onSuccess,
  },
  initialState,
);

const text = {
  CREATE_GATEWAY: gettext('Create gateway'),
  UPDATE_GATEWAY: gettext('Update gateway'),
  INPUT_LABEL: gettext('Gateway name'),
  CREATE_BUTTON_LABELS: {
    confirm: gettext('Create the gateway'),
    cancel: gettext('Discard'),
  },
  EDIT_BUTTON_LABELS: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
  SUCCESS: gettext('Gateway successfully saved'),
  ERROR: gettext('Error while saving gateway'),
};

const customFooter = (execute, close, submitInProgress, type) => (
  <SidepanelFooter
    execute={execute}
    close={close}
    submitInProgress={submitInProgress}
    confirmColor="primary"
    buttonLabels={type === 'edit' ? text.EDIT_BUTTON_LABELS : text.CREATE_BUTTON_LABELS}
  />
);

class AddEditGateway extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.submit = this.submit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.updateGateway = this.updateGateway.bind(this);
    this.api = getDjangoApi(props.api);
  }

  componentDidUpdate() {
    const { sidepanel, state } = this.props;
    const { parameters } = sidepanel;

    if (sidepanel.visible && !state.gateway && parameters.type === 'edit') {
      this.actions.updateGateway(parameters.data.name);
    }
  }

  componentWillUnmount() {
    this.onClose();
  }

  onClose(submitSuccess) {
    const { onSuccess } = this.props;
    this.actions.resetState();
    this.actions.closeSidepanel(SIDEPANEL_ID);
    if (submitSuccess) onSuccess();
  }

  updateGateway(event) {
    const { target } = event;
    this.actions.updateGateway(target.value);
  }

  submit() {
    const { state, sidepanel } = this.props;
    const { data, type } = sidepanel.parameters;

    const request = { value: state.gateway };
    if (type === 'edit') request.id = data.id;

    this.actions.submit(request, type, this.api).then(
      () => {
        notifier.success(text.SUCCESS);
        this.onClose(true);
      },
      () => {
        notifier.error(text.ERROR);
      },
    );
  }

  render() {
    const { sidepanel, state } = this.props;
    const { errors, submitInProgress, gateway } = state;
    const title = sidepanel.visible && sidepanel.parameters.type === 'add' ? text.CREATE_GATEWAY : text.UPDATE_GATEWAY;
    return (
      <Sidepanel
        icon="Pen"
        title={title}
        visible={sidepanel.visible}
        onCloseIconClick={() => this.onClose()}
        footerRender={() => customFooter(this.submit, this.onClose, submitInProgress, sidepanel.parameters.type)}
      >
        <Input
          hasError={!!errors.name}
          helpText={errors.name ? errors.name[0] : null}
          label={text.INPUT_LABEL}
          placeholder={text.INPUT_LABEL}
          value={gateway}
          onChange={this.updateGateway}
        />
      </Sidepanel>
    );
  }
}

AddEditGateway.propTypes = propTypes;

export const mapStateToProps = ({ overlays, pages }) => ({
  state: pages.PAYMENT_TRANSACTION_TYPES.actions[SIDEPANEL_ID],
  sidepanel: overlays[SIDEPANEL_ID],
});

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      updateGateway,
      submit,
      resetState,
    },
    dispatch,
  ),
});

export default AddEditGateway;
