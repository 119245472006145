/* eslint-disable default-case */
import React, { Component, Fragment } from 'react';
import { generatePath, withRouter } from 'react-router-dom';
import { Button, Select } from 'tc-biq-design-system';

import { getDjangoApi } from '../../logic/services/api-factory';
import { gettext } from '../../logic/utilities/languageUtility';
import USER_TYPE from '../../logic/enums/user-type';
import appRoutes from '../App/Router/appRoutes';
import { toRelativePath } from '../../logic/utilities/toPath';
import { isAvaTrade } from '../../utils/environment';

class SearchAll extends Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
    this.containerRef = React.createRef();
    
    this.state = {
      input: '',
      results: [],
    };
  }

  onSearchResponse = (response) => {
    let results = [];
    const users = response.data.auth_user;
    const payments = response.data.tc_payments_paymenttransaction;
    const accounts = response.data.tpi_tradingaccount;

    if (users && users.length) {
      users.forEach(({ full_name, username, email, whitelabel, id, type }) => {
        results.push({
          group: gettext('Users'),
          display_name: `${full_name} (${username} - ${email}) ${whitelabel ? `- ${whitelabel}` : ''}`,
          id,
          user_type: type,
        });
      });
    }

    if (accounts && accounts.length) {
      accounts.forEach((item) => {
        results.push({
          group: gettext('Trading Accounts'),
          display_name: `${item.external_id}, ${item.currency.symbol}, ${item.backend.name}`,
          id: item.id,
          userId: item.user.id,
        });
      });
    }

    if (payments && payments.length) {
      payments.forEach(({ id, type, gateway }) => {
        results.push({
          group: gettext('Payments'),
          display_name: `${id} - ${type}, ${gateway}`,
          id,
        });
      });
    }

    results = results.length ? results : [{ display_name: 'no results.', id: -1 }];
    
    if (!isAvaTrade()) return results;
    
    this.setState({ results });
    this.selectRef.current.openMenu();
    this.containerRef.current.getElementsByTagName('input')[0].focus();
  }

  onChangeInput = (input) => {
    this.setState({ input });
  }

  onSearchSelected = (selected) => {
    if (_.get(selected, 'display_name.length') && selected.display_name !== 'no results') {
      switch (selected.group) {
        case 'Payments':
          window.location = toRelativePath(
            generatePath(appRoutes.PAYMENT_TRANSACTION, { id: selected.id }));
          break;
        case 'Trading Accounts':
          window.location = toRelativePath(generatePath(appRoutes.TRADING_ACCOUNT, {
            accountId: selected.id,
            userId: selected.userId,
          }));
          break;
        case 'Users':
          switch (selected.user_type) {
            case USER_TYPE.CLIENT:
              window.location = toRelativePath(generatePath(appRoutes.CLIENT_PROFILE, { id: selected.id }));
              break;
            case USER_TYPE.IB:
              window.location = toRelativePath(generatePath(appRoutes.IB_PROFILE, { id: selected.id }));
              break;
            case USER_TYPE.BO:
              window.location = toRelativePath(generatePath(appRoutes.TEAM_MEMBER, { id: selected.id }));
              break;
          }
          break;
      }
    }
  }

  refreshSearchResultsInput = (subUrl) => {
    if (!subUrl) {
      return null;
    } if (subUrl.length < 2) {
      return null;
    }
    return getDjangoApi(`search-es/${encodeURIComponent(subUrl)}`)
      .list()
      .then(this.onSearchResponse);
  }

  refreshSearchResultsBtn = () => {
    const { input } = this.state;
    if (!input) {
      return null;
    } if (input.length < 2) {
      return null;
    }
    return getDjangoApi(`search-es/${encodeURIComponent(input)}`)
      .list()
      .then(this.onSearchResponse);
  }

  render() {
    const { results, input } = this.state;
    return (
      <div ref={this.containerRef} style={{ width: '340px', display: 'flex' }}>
        {
          !isAvaTrade() && (
          <Select
            name="app-header"
            placeholder={gettext('Search anything...')}
            type="search"
            group
            async
            persistSearch
            debounceInterval={500}
            loadOptions={this.refreshSearchResultsInput}
            labelKey="display_name"
            valueKey="id"
            onChange={this.onSearchSelected}
          />
          )
        }
        {
          isAvaTrade() && (
          <Fragment>
            <Select
              ref={this.selectRef}
              name="app-header"
              placeholder={gettext('Search anything...')}
              type="search"
              group
              persistSearch
              options={results}
              labelKey="display_name"
              valueKey="id"
              onInputChange={this.onChangeInput}
              onChange={this.onSearchSelected}
            />
            <Button
              style={{ position: 'relative', top: '-3px' }}
              disabled={!input || input.length < 1}
              onClick={() => this.refreshSearchResultsBtn()}
              color="confirmation"
            >
              Search
            </Button>
          </Fragment>
          )
        }
      </div>
    );
  }
}


export default withRouter(SearchAll);
