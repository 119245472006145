import { handleActions, createAction } from 'redux-actions';
import { getDjangoApi } from '../../../logic/services/api-factory';

const getTemplateApi = id => getDjangoApi(`sms/templates/${id}/content`);

const initialState = {
  placeholders: [],
  loadingTagsInProgress: false,
  timestamp: undefined,
  lastCalledUrl: null,
};

const actionTypes = {
  FETCH_PLACEHOLDERS: 'FETCH_PLACEHOLDERS',
  FETCH_PLACEHOLDERS_PENDING: 'FETCH_PLACEHOLDERS_PENDING',
  FETCH_PLACEHOLDERS_FULFILLED: 'FETCH_PLACEHOLDERS_FULFILLED',
  FETCH_PLACEHOLDERS_REJECTED: 'FETCH_PLACEHOLDERS_REJECTED',
  FETCH_TEMPLATE: 'FETCH_TEMPLATE',
  RESET_PLACEHOLDERS_STATE: 'RESET_PLACEHOLDERS_STATE',
};


const isValidAction = (state, eventUrl) => state.lastCalledUrl === eventUrl;

const getPlaceholdersListFromRowData = ({ fields }) => fields.reduce((acc, { children, key }) => {
  const placeholders = (children || { fields: [{ key: '' }] })
    .fields
    .map(field => `${key}${field.key ? '.' : ''}${field.key}`);
 
  return [...acc, ...placeholders];
}, []);

const fetchPlaceholdersAction = eventUrl => ({
  type: actionTypes.FETCH_PLACEHOLDERS,
  payload: {
    promise: getDjangoApi(eventUrl).retrieve().then(res => ({
      res,
      eventUrl,
    })),
    data: { eventUrl },
  },
});

const resetPlaceholdersStateAction = createAction(actionTypes.RESET_PLACEHOLDERS_STATE);

const fetchTemplateAction = createAction(actionTypes.FETCH_TEMPLATE, async (id) => {
  const res = await getTemplateApi(id).retrieve();

  return res;
});

export default handleActions({
  [actionTypes.FETCH_PLACEHOLDERS_FULFILLED]: (state, { payload }) => {
    const { eventUrl, res } = payload;
    
    if (isValidAction(state, eventUrl)) {
      return {
        ...state,
        loadingTagsInProgress: false,
        placeholders: getPlaceholdersListFromRowData(res.data),
      };
    }

    return {
      ...state,
    };
  },
  [actionTypes.FETCH_PLACEHOLDERS_PENDING]: (state, { payload }) => ({
    ...state,
    loadingTagsInProgress: true,
    placeholders: [],
    lastCalledUrl: payload.eventUrl,
  }),
  
  [actionTypes.FETCH_PLACEHOLDERS_REJECTED]: state => ({
    ...state,
    loadingTagsInProgress: false,
    placeholders: [],
  }),
  
  [actionTypes.RESET_PLACEHOLDERS_STATE]: state => ({
    ...state,
    loadingTagsInProgress: false,
    placeholders: [],
    lastCalledUrl: null,
  }),
}, initialState);

export {
  fetchPlaceholdersAction,
  resetPlaceholdersStateAction,
  fetchTemplateAction,
};
