import React, { Component } from 'react';
import { Pill, Row, Col, Spinner } from 'tc-biq-design-system';
import { object, array, bool } from 'prop-types';

import TreeItem from './TreeItem';
import TreeWrapper from './TreeWrapper';

const propTypes = {
  actions: object.isRequired,
  teams: array.isRequired,
  isLoading: bool.isRequired,
};

class TeamsStructure extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      expanded: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { actions } = this.props;
    actions.loadTeams();
  }

  onClick = (id) => {
    const { expanded } = this.state;
    const isExpanded = expanded.includes(id);
    const newArr = isExpanded ? expanded.filter(item => item !== id) : [...expanded, id];
    this.setState({
      expanded: newArr,
    });
  }

  renderTeam = (teams, level = 0) => {
    const { expanded } = this.state;
    return (
      <TreeWrapper reset={!level}>
        { teams.map(({ children, id, name }) => (
          <TreeWrapper.Item key={id} collapsed={!expanded.includes(id)}>
            <TreeItem 
              onClick={children.length ? () => this.onClick(id) : null}
              type={!level ? 'root' : `level-${level}`} 
              title={name}
              id={id}
            >
              {children.length ? <Pill iconPosition="left" icon="Teams">{` ${children.length}`}</Pill> : null}
            </TreeItem>
            {children.length ? this.renderTeam(children, level + 1) : null}
          </TreeWrapper.Item>
        )) }
      </TreeWrapper>
    );
  }

  render() {
    const { teams, isLoading } = this.props;

    return (
      <Row>
        <Col sm="1/7" />
        <Col sm="5/7">
          {isLoading ? <div style={{ textAlign: 'center' }}><Spinner /></div> : this.renderTeam(teams)}
        </Col>
      </Row>
    );
  }
}

TeamsStructure.propTypes = propTypes;

export default TeamsStructure;
