import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { object, bool, array } from 'prop-types';
import { Space, Sidepanel, Select, notifier } from 'tc-biq-design-system';

import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import { assignUserToIb, getAssignToAPi } from './Model';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import FormEl from '../../../../../../../components/form/Components/FormEl';

const FORM_ID = 'ASSIGN_TO_IB_FORM';

const text = {
  FORM: {
    BROKER_FIELD: gettext('Select an Introducing Broker'),
    LINK_FIELD: gettext('Select link'),
  },
  SUCCESS: context => gettext(
    'You have successfully assigned {{user}} to {{broker}}?',
    context,
  ),
  ERROR: gettext('Error while assigning user.'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_ID}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  submitInProgress: bool.isRequired,
  user: object,
  actions: object,
  isIb: bool,
  links: array,
};

const defaultProps = {
  user: null,
  actions: null,
  isIb: false,
  links: [],
};

class AssignToIb extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brokers: [],
      selected: null,
      linkSelected: null,
    };

    this.actions = props.actions;
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onChangeBroker = this.onChangeBroker.bind(this);
    this.onChangeLink = this.onChangeLink.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    if (user.invited_by) {
      this.setDefault(user);
    } else {
      this.onLoadOptions(null);
    }
  }

  onSubmit() {
    const { linkSelected, selected } = this.state;
    const { user, isIb, sidepanelManager } = this.props;

    this.actions.assignUserToIb(user, isIb, linkSelected).then(() => {
      this.actions.fetchUser(user, isIb);
      notifier.success(text.SUCCESS({
        broker: selected.username,
        user: user.username,
      }));
      sidepanelManager.close();
    }).catch(() => {
      notifier.error(text.ERROR);
    });
  }

  onClose() {
    const { sidepanelManager } = this.props;
    sidepanelManager.close();
  }

  onLoadOptions = async (term) => {
    const { user, isIb } = this.props;
    const { data: { results } } = await getAssignToAPi(user, isIb).list({ username: term });

    this.setState({
      brokers: results,
    });
  }

  onChangeBroker(option) {
    const { brokers } = this.state;
    const newOption = option || brokers[0];
    this.setState({
      selected: newOption,
      linkSelected: _.get(newOption, 'links[0]'),
    });
  }

  onChangeLink(option) {
    this.setState({
      linkSelected: option,
    });
  }

  setDefault = async (user) => {
    const api = getDjangoApi(`ib/${user.invited_by.id}/clients/`);
    const { data: { results: [link] } } = await api.retrieve(null, { id: user.id });
    const selected = {
      ...user.invited_by,
      links: [link.link],
    };
    this.setState({
      selected,
      brokers: [selected],
      linkSelected: selected.links[0],
    });
  }

  render() {
    const { submitInProgress, ...options } = this.props;
    const { brokers } = this.state;
    const { selected, linkSelected } = this.state;
    const linkOptions = selected ? selected.links : [];

    return (
      <Sidepanel 
        {...options} 
        footerRender={customFooter(this.onSubmit, this.onClose, submitInProgress)}
      >
        <Space size={16} />
        <FormEl formId={FORM_ID}>
          <Select 
            label={text.FORM.BROKER_FIELD}
            type="single"
            value={selected}
            onInputChange={this.onLoadOptions}
            onChange={this.onChangeBroker}
            options={brokers} 
            labelKey="username"
            valueKey="username"
          />
          <Space size={16} />
          <Select 
            label={text.FORM.LINK_FIELD}
            type="single"
            value={linkSelected}
            options={linkOptions} 
            onChange={this.onChangeLink}
            labelKey="name"
            valueKey="username"
          />
        </FormEl>
      </Sidepanel>
    );
  }
}

AssignToIb.propTypes = propTypes;
AssignToIb.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      assignUserToIb,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const action = page.actions.ASSIGN_TO_IB;
  return {
    submitInProgress: action.submitInProgress,
    brokers: action.brokers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignToIb);
