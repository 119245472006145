import { actionIcons } from '../../../../components/grid/GridUtils';
import { ActionsCellWrapper, UsernameCell } from '../../../../components/gridCellRenderers';
import { dateTimeFormatter } from '../../../../logic/utilities/formatters';

export default ({ remove, modify, previewBiqUser }) => {
  const actions = {
    ...(modify && { modify }),
    ...(remove && { remove }),
  };
  return ({
    id: {
      valueGetter: ({ data }) => data.id,
      width: 80,
    },
    name: {
      header: 'Name',
      width: 200,
    },
    creator: { 
      cellRendererFramework: UsernameCell(previewBiqUser),
      width: 200,
    },
    created: { cellRendererFramework: ({ data }) => dateTimeFormatter(data.created, true) },
    modified: { cellRendererFramework: ({ data }) => dateTimeFormatter(data.modified, true) },
    user_count: {
      valueGetter: ({ data }) => data.user_count,
      quickFilter: {},
    },
    actions: {
      label: '',
      field: 'actions',
      key: 'actions',
      pinned: 'right',
      width: actions.modify && actions.remove ? 100 : 50,
      hiddenSortIcon: true,
      suppressSizeToFit: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(modify, remove)),
    },
  });
};
