import hideCols from '../../../../../logic/utilities/hideCols';
import { RouteCell } from '../../../../../components/gridCellRenderers';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const UserLink = () => ({ value, data, colDef }) => {
  const stateParams = { id: data.id };
  const Component = RouteCell({ href: appRoutes.CLIENT_PROFILE, stateParams });
  return Component({ value, data, colDef });
};

export default () => ({
  ...hideCols(['id']),
  username: {
    cellRendererFramework: UserLink(),
  },
});
