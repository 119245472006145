/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';

import session from '../../../../../../logic/services/session';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import { hasAccess } from '../../../../../../logic/services/acl';
import connect from '../../../../../../logic/connect';
import withUnmountMethod from '../../../../../../components/hoc/withUnmountMethod';
import overlayActions from '../../../../../../components/overlay';
import Integrations from './Integrations';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

const CONFIG = window.config;

const SIDEPANEL_ID = 'INTEGRATION_FORM';

const text = {
  TITLE: gettext('Integration Settings'),
  GBG: gettext('GBG'),
  MAILCHIMP: gettext('Mailchimp'),
  AVA_PARTNER: gettext('AvaPartner'),
  CELLXPERT: gettext('Cellxpert'),
  SMS: gettext('SMS'),
  SAFECHARGE: gettext('Safecharge'),
  ACTIVITY_EXPORT: gettext('Activity Export'),
  SENDGRID: gettext('Sendgrid'),
  COPERATO: gettext('COPERATO'),
};

const mailchimpFields = [{
  key: 'INTEGRATION_MAILCHIMP_API_KEY',
  description: gettext('Mailchimp API'),
}];

const cellxpertFields = [{
  key: 'cellxpert.token',
  description: gettext('Cellxpert API Key'),
}, {
  key: 'cellxpert.whitelist',
  description: gettext('Cellxpert IP whitelisting'),
}];

const allIntegrations = [
  {
    name: 'gbg',
    text: text.GBG,
    serviceUrl: 'settings/gbg',
    logo: 'assets/images/integrations/gbg.png',
    show: () => session.user.is_superuser,
  },
  {
    name: 'mailchimp',
    text: text.MAILCHIMP,
    serviceUrl: 'settings/integration-mailchimp',
    fields: mailchimpFields,
    editRoute: appRoutes.SETTINGS_INTEGRATIONS_MAILCHIMP,
    logo: 'assets/images/integrations/mailchimp.png',
    show: () => isFeatureEnabled()('MAILCHIMP_INTEGRATION') && hasAccess('integration.mailchimp.lists.list'),
  },
  {
    name: 'avapartner',
    text: text.AVA_PARTNER,
    editRoute: appRoutes.SETTINGS_INTEGRATIONS_AVA,
    logo: 'assets/images/integrations/avaPartner.png',
    show: () => isFeatureEnabled()('AVA_PARTNERS') && hasAccess('ava_partners.tags.list'),
  },
  {
    name: 'cellexpert',
    text: text.CELLXPERT,
    fields: cellxpertFields,
    disableUpdate: !hasAccess('settings.integration.cellxpert.update'),
    logo: 'assets/images/integrations/cellxpert.png',
    show: () => isFeatureEnabled()('CELLXPERT') && session.user.is_superuser,
  },
  {
    name: 'sms',
    text: text.SMS,
    show: () => isFeatureEnabled()('SMS') && hasAccess('settings.sms.list'),
  },
  {
    name: 'safecharge',
    text: text.SAFECHARGE,
    serviceUrl: 'settings/safecharge',
    logo: 'assets/images/integrations/safecharge.png',
    show: () => hasAccess('settings.safecharge.list'),
  },
  {
    name: 'activity_export',
    text: text.ACTIVITY_EXPORT,
    serviceUrl: 'settings/_activity-export',
    show: () => hasAccess('settings._activity.export.list'),
  },
  {
    name: 'sendgrid',
    text: text.SENDGRID,
    serviceUrl: 'settings/sendgrid',
    logo: 'assets/images/integrations/sendgrid.png',
    show: () => session.user.is_superuser,
  },
  {
    name: 'coperato',
    text: text.COPERATO,
    show: () => isFeatureEnabled()('COPERATO') && session.user.is_superuser,
  },
];

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
  history: object.isRequired,
};

class IntegrationController extends Component {
  constructor(props) {
    super(props);

    this.actions = props.actions;
  }

  onConfigureClick(name) {
    const { sidepanel } = this.props;
    const { updateSidepanel, openSidepanel } = this.actions;
    const integration = allIntegrations.find(item => item.name === name);

    if (sidepanel.visible) {
      updateSidepanel(SIDEPANEL_ID, { ...integration });
    } else {
      openSidepanel(SIDEPANEL_ID, { ...integration });
    }
  }

  onEdit(name) {
    const integration = allIntegrations.find(item => item.name === name);
    const { history } = this.props; 

    history.push(integration.editRoute);
  }

  filterIntegrations() {
    return allIntegrations
      .filter(item => item.show())
      .map(item => ({ 
        ...item, 
        ...((item.serviceUrl || item.fields) && { onConfigure: name => this.onConfigureClick(name) }),
        ...((item.editRoute) && { onEdit: name => this.onEdit(name) }),
      }));
  }

  render() {
    const integrations = this.filterIntegrations();
    
    return (
      <Integrations
        {...this.props}
        onSidepanelClose={this.onSidepanelClose}
        onSidepanelSubmit={this.onFormSubmit}
        integrations={integrations}
      />
    );
  }
}

IntegrationController.propTypes = propTypes;

const mapStateToProps = ({ overlays }) => ({
  sidepanel: overlays[SIDEPANEL_ID],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
      updateSidepanel: overlayActions.update,
      closeSidepanel: overlayActions.close,
      resetSidepanel: overlayActions.resetState,
    },
    dispatch,
  ),
});

const IntegrationsPage = compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(IntegrationController);

export default {
  component: IntegrationsPage,
  path: appRoutes.SETTINGS_INTEGRATIONS,
  aclFn: () => hasAccess('settings._activity.export.list')
      || hasAccess('settings.safecharge.list')
      || (isFeatureEnabled()('AVA_PARTNERS') && hasAccess('ava_partners.tags.list'))
      || (CONFIG.environment === 'AvaTrade'
        && (hasAccess('settings.sms.list') || hasAccess('integration.mailchimp.lists.list'))
      ),
};
