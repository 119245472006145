import deposits from './Deposits';
import nonFtd from './NonFTD';
import FTD from './FTD';
import pendingDeposits from './PendingDeposits';
import pendingDepositsTotal from './PendingDepositsTotal';
import withdrawals from './Widthdrawals';
import pendingwithdrawals from './PendingWidthdrawals';
import pendingwithdrawalsTotal from './PendingWidthdrawalsTotal';
import activeClients from './ActiveClients';
import assignedClientsPerSalesStatus from './AssignedClientsPerSalesStatus';
import assignedClientsPerState from './AssignedClientsPerState';
import tasks from './Tasks';
import activeTraders from './ActiveTraders';

const widgets = [
  deposits,
  activeTraders,
  nonFtd,
  FTD,
  pendingDeposits,
  pendingDepositsTotal,
  withdrawals,
  pendingwithdrawals,
  pendingwithdrawalsTotal,
  activeClients,
  assignedClientsPerSalesStatus,
  assignedClientsPerState,
  tasks,
];

export default widgets;
