
import SubIBs from './SubIBs';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

export default {
  Component: () => SubIBs,
  label: gettext('Sub IB\'s'),
  id: 'sub-ibs',
  visibilityFn: (hasAccess, _isFeatureEnabled, isIb) => hasAccess('ib.sub_ibs.*') && isIb,
};
