const getStateParam = (stateUrl, param) => {
  const { pathname } = window.location;
  const pathRoutes = pathname.substring(1).split('/');
  const stateRoutes = stateUrl.split('/').filter(r => !!r);
  
  /* 
  This is done in case where stateUrl and pathname doesn't match 
  and they don't have same number of elements 
  (e.x. when feature branch is opened in browser 
   we need to strip 'BO2-preview/feature-ticket-id/' part 
   so this function can match parameters properly)
  and they don't have same number of elements  
  */
  const numberOfUnusedElements = pathRoutes.indexOf(stateRoutes[0]);
  const paramPosition = stateRoutes.indexOf(`:${param}`);

  return pathRoutes.slice(numberOfUnusedElements)[paramPosition];
};

export const getUrlParameter = (name) => {
  const parsedName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${parsedName}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default getStateParam;
