import React, { Component } from 'react';
import { Tabs, Tab, Button } from 'tc-biq-design-system';
import { object, string } from 'prop-types';

import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../logic/services/acl';
import modifier, { bankAndWalletModifier } from './modifier';
import connect from '../../../../../../logic/connect';

export const acls = {
  cards: 'pga.card.accounts.list',
  bankAccount: 'pga.bank.accounts.list',
  ewallet: 'pga.ewallet.accounts.list',
};

const cardsConfig = id => ({
  reducerKey: 'USER_SINGLE',
  apiUrl: `users/${id}/payment_details/cards`,
  tableKey: 'PAYMENT_DETAILS_CARDS',
  aclKey: acls.cards,
});

const bankAccountsConfig = id => ({
  reducerKey: 'USER_SINGLE',
  apiUrl: `users/${id}/payment_details/bank_accounts`,
  tableKey: 'PAYMENT_DETAILS_BANK_ACCOUNTS',
  aclKey: acls.bankAccount,
});

const eWalletsConfig = id => ({
  reducerKey: 'USER_SINGLE',
  apiUrl: `users/${id}/payment_details/ewallet`,
  tableKey: 'PAYMENT_DETAILS_EWALLET',
  aclKey: acls.ewallet,
});

const text = {
  CARDS: gettext('CARDS'),
  BANK_DETAILS: gettext('BANK DETAILS'),
  EWALLET: gettext('EWALLET'),
  EXPORT_ALL: gettext('Export all'),
};

const propTypes = {
  id: string.isRequired,
  tables: object.isRequired,
};

class PaymentDetails extends Component {
  constructor(props) {
    super(props);

    this.Cards = withTable(GridTab, cardsConfig, this.tableActions(cardsConfig()), { hideSegments: true })(props.id);
    this.BankDetails = withTable(GridTab, bankAccountsConfig, this.tableActions(bankAccountsConfig()), { hideSegments: true })(props.id);
    this.EWallet = withTable(GridTab, eWalletsConfig, this.tableActions(eWalletsConfig()), { hideSegments: true })(props.id);
  }

  tableActions = ({ tableKey, aclKey }) => () => {
    const { tables } = this.props;
    const table = tables[tableKey];
    const all = _.get(table, 'exportUrl.all');
    return hasAccess(aclKey) && <a href={all}><Button>{text.EXPORT_ALL}</Button></a>;
  }

  render() {
    const { Cards, BankDetails, EWallet } = this;

    return (
      <Tabs>
        <Tab visible={hasAccess(acls.cards)} title={text.CARDS}>
          <Cards modifier={modifier} />
        </Tab>

        <Tab visible={hasAccess(acls.bankAccount)} title={text.BANK_DETAILS}>
          <BankDetails modifier={bankAndWalletModifier} />
        </Tab>

        <Tab visible={hasAccess(acls.ewallet)} title={text.EWALLET}>
          <EWallet modifier={bankAndWalletModifier} />
        </Tab>
      </Tabs>
    );
  }
}

PaymentDetails.propTypes = propTypes;

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  return {
    tables: page.tables,
  };
};

export default connect(mapStateToProps, null)(PaymentDetails);
