import moment from 'moment';
import { gettext } from './languageUtility';

export const time = {
  SECONDS: 'seconds',
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days',
};


const durationTypeChoices = {
  minutes: { display_name: gettext('Minutes'), value: time.MINUTES },
  hours: { display_name: gettext('Hours'), value: time.HOURS },
  days: { display_name: gettext('Days'), value: time.DAYS },
};

export function convertToMilliseconds(duration, durationType) {
  return moment.duration(duration, durationType).asMilliseconds();
}

export function convertFromMilliseconds(time) {
  const timeAsMinutes = moment.duration(time).asMinutes();

  if (timeAsMinutes % 1440 === 0) {
    return {
      duration: timeAsMinutes / 1440,
      durationType: durationTypeChoices.days,
    };
  }

  if (timeAsMinutes % 60 === 0) {
    return {
      duration: timeAsMinutes / 60,
      durationType: durationTypeChoices.hours,
    };
  }

  return {
    duration: timeAsMinutes,
    durationType: durationTypeChoices.minutes,
  };
}
