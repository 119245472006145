import { ActionsCellWrapper } from '../../../../../../components/gridCellRenderers';
import { actionIcons } from '../../../../../../components/grid/GridUtils';


export default ({ modify }) => ({
  country_name: {
    width: 100,
  },
  iso_code: {
    width: 100,
  },
  actions: {
    label: '',
    field: 'actions',
    key: 'actions',
    pinned: 'right',
    width: 50,
    hiddenSortIcon: true,
    suppressSizeToFit: true,
    cellRendererFramework: ActionsCellWrapper({ modify: modify || null }, actionIcons(modify)),
  },
});
