import React from 'react';
import { func, node } from 'prop-types';
import { Icon } from 'tc-biq-design-system';

const propTypes = {
  onClick: func.isRequired,
  children: node.isRequired,
};

const UploadButton = ({ onClick, children }) => (
  <div className="upload" onClick={onClick}>
    <span className="icon">
      <Icon name="Upload" colorName="text-primary500" />
    </span>
    {children}
  </div>
);

UploadButton.propTypes = propTypes;
export default UploadButton;
