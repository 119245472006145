import React from 'react';
import { Pill } from 'tc-biq-design-system';
import { string } from 'prop-types';

import { gettext } from '../../logic/utilities/languageUtility';

const propTypes = {
  value: string,
};

const defaultProps = {
  value: '',
};

const StatusCell = ({ value }) => {
  const status = value.split(' ')[1];
  let type = 'status03';
  let icon = 'Pending';

  if (status === gettext('accepted')) {
    type = 'status01';
    icon = 'Checkmark';
  } else if (status === gettext('rejected')) {
    type = 'status04';
    icon = 'Close';
  }

  return (
    <div style={{ float: 'right' }}>
      <Pill
        type={type}
        icon={icon}
        iconPosition="left"
      >
        {value}
      </Pill>
    </div>
  );
};

StatusCell.propTypes = propTypes;
StatusCell.defaultProps = defaultProps;

export default StatusCell;
