import React, { Component } from 'react';
import { array, func, object, shape } from 'prop-types';
import { Button, Col, Row, Space } from 'tc-biq-design-system';
import { generatePath } from 'react-router';


import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import appRoutes from '../../../../../../components/App/Router/appRoutes';
import FormFactory from '../../../../../../components/form';
import Page from '../../../../../../components/Page';
import FormPanel from '../../../../../../components/form/Components/FormPanel/FormPanel';
import QueryBuilder from '../../../../../../components/QueryBuilder/QueryBuilder';
import { refactoredExtractGroup } from '../../../../../../logic/services/query-adapter';
import to from '../../../../../../logic/utilities/to';
import { formErrorHandler, validateForm } from '../../../../../../components/form/logic/utils';
import { isPositiveValidator, isRequiredValidator } from '../../../../../../components/form/logic/validators';

export const FORM_KEY = 'CANCELLATION_RULES_FORM';

const section = 'CANCELLATION_RULES';

const api = getDjangoApi('settings/bonus_expiry_rules');

const convertToHours = (days, hours) => Number(hours || 0) + Number(days || 0) * 24;

const convertFromHours = (hours) => {
  const nHours = Number(hours || 0);
  const oHours = (nHours % 24);
  const oDays = Math.round(nHours / 24);
  return [oDays, oHours];
};

const modes = {
  CREATE: 'new',
  EDIT: 'edit',
};

const text = {
  SAVE_BUTTON_LABEL: gettext('Save'),
  DISCARD_BUTTON_LABEL: gettext('Discard'),
  BONUS_SYSTEM: gettext('Bonus system'),
  STATE: gettext('State'),
  CANCELLATION_RULE_CREATE: gettext('Create expiry rule'),
  CANCELLATION_RULE_UPDATE: gettext('Update expiry rule'),
  DISCARD: gettext('Cancel'),
  ERROR_GENERAL: gettext('Something went wrong'),
  MISSING_SCHEME: gettext('You have not defined any bonus amount scheme. Please check your input or click "Add case" button'),
  EXP_DAYS: gettext('Days'),
  EXP_HOURS: gettext('Hours'),
  RULE_CONDITION: gettext('Rule condition'),
};

const modifiers = {
  name: {
    validators: [isRequiredValidator()],
  },
  description: {
    validators: [isRequiredValidator()],
  },
  enabled: {
    type: 'toggle',
    label: null,
  },
  expiration_period: {
    type: 'number',
    placeholder: text.EXP_HOURS,
    label: gettext('Expiration period (hours)'),
    validators: [isPositiveValidator()],
  },
  exp_days: {
    validators: [isPositiveValidator()],
  },
};

const customFields = {
  exp_days: {
    type: 'number',
    id: 'exp_days',
    name: 'exp_days',
    placeholder: text.EXP_DAYS,
    label: gettext('Expiration period (days)'),
  },
};

const formConfig = {
  form: FORM_KEY,
  api,
  customFields: [customFields.exp_days],
};

const propTypes = {
  fields: array.isRequired,
  group: object.isRequired,
  actions: object.isRequired,
  form: object.isRequired,
  match: object.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

class CancellationRulesForm extends Component {
  constructor(props) {
    super(props);
    
    this.id = props.match.params.id;
    this.mode = this.id !== 'new' ? modes.EDIT : modes.CREATE;
    this.title = this.getTitle();
    this.bread = this.getBread();
    this.fieldOptionsPromise = null;
    this.formConfig = {
      form: FORM_KEY,
      api,
      excludeFields: ['event', 'action', 'created_by', 'action_params'],
      customFields: [customFields.exp_days],
    };
  }

  componentDidMount() {
    const { actions: { getFieldsOptions } } = this.props;
    this.fieldOptionsPromise = getFieldsOptions();
  }


  componentDidUpdate(prevProps) {
    const hasOldFields = !_.isEmpty(_.get(prevProps, 'form.fields'));
    const hasFields = !_.isEmpty(_.get(this.props, 'form.fields'));
    if (hasFields && !hasOldFields) {
      this.fieldOptionsPromise.then(this.mode === modes.EDIT ? this.setFormData : null);
    }
  }

  onQbStateUpdate = (condition) => {
    const { actions } = this.props;
    actions.setCondition(condition);
    const extracted = refactoredExtractGroup(condition) || null;
    actions.setFieldValue({
      id: 'bonus_expiry_conditions',
      value: extracted,
    });
  }

  onRedirect = () => {
    const { history } = this.props;
    history.push(generatePath(appRoutes.SETTINGS_BONUS, { section }));
  }

  onSubmit = async () => {
    const { actions } = this.props;

    const isValid = validateForm(actions.validator);
    if (!isValid) return;

    const getParams = (v) => {
      const expiration_period = convertToHours(v.exp_days, v.expiration_period);
      if (!expiration_period) {
        delete v.expiration_period;
      } else {
        return {
          ...v,
          expiration_period,
        };
      }
      return v;
    };
    const action = () => (this.mode === modes.EDIT 
      ? actions.update(api, this.id, getParams, true)
      : actions.create(api, getParams));
    
    const [err] = await to(action());
    return err ? this.onError(err) : this.onRedirect();
  }


  onError(payload) {
    formErrorHandler(text.ERROR_GENERAL)(payload);
  }

  setDefaultData = () => {
    const { actions } = this.props;
    actions.setRowData({
      enabled: true,
      expiration_period: 0,
      exp_days: 0,
    });
  }

  setFormData = async () => {
    const { actions, form: { fields } } = this.props;
    const { value: { data } } = await actions.getCancellationRule(this.id);
    const { excludeFields } = this.formConfig;
    const keys = fields.reduce((acc, field) => (
      !excludeFields.includes(field.id) ? [...acc, field.id] : acc), []);

    const [days, hours] = convertFromHours(data.expiration_period);

    keys.forEach(id => actions.setFieldValue({
      id,
      value: data[id],
      ...(id === 'exp_days' ? { value: days || null } : { }),
      ...(id === 'expiration_period' ? { value: hours || null } : { }),
    }));
  }

  getBread = () => [
    { label: text.BONUS_SYSTEM, route: appRoutes.SETTINGS_BONUS },
    { label: this.getTitle(),
      route: '',
    },
  ];

  getTitle = () => (this.mode === modes.EDIT 
    ? text.CANCELLATION_RULE_UPDATE 
    : text.CANCELLATION_RULE_CREATE)

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
      this.setDefaultData();
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { fields, group, form } = this.props;
    const Form = this.renderForm();
    return (
      <Page
        bread={this.bread}
        title={this.title}
      >
        <Space size={20} />
        <Form 
          modifiers={modifiers}
          renderForm={formFields => (
            <FormPanel size="wide">
              <FormPanel.Section>
                <Row>
                  <Col sm="1/2">
                    {formFields.name}
                  </Col>
                  <Col sm="1/2">
                    <div id="cancellation-enabled" className="biq-pull-right">
                      <label htmlFor="cancellation-enabled" className="tc-paragraph-regular biq-input-wrapper">
                        {text.STATE}
                      </label>
                      {formFields.enabled}
                    </div>
                  </Col>
                  <Col sm="1/2">{formFields.exp_days}</Col>
                  <Col sm="1/2">{formFields.expiration_period}</Col>
                  <Col>{formFields.description}</Col>
                  <Col>
                    <FormPanel.Header title={text.RULE_CONDITION} />
                    {fields.length ? (
                      <QueryBuilder
                        className="query-builder"
                        fields={fields}
                        group={group}
                        onStateUpdate={this.onQbStateUpdate}
                      />
                    ) : (
                      <FormPanel.Spinner />
                    )}
                  </Col>
                </Row>
              </FormPanel.Section>
              <FormPanel.Submit>
                <Button
                  loading={form.submitInProgress}
                  onClick={this.onRedirect}
                  color="ghost"
                >
                  {text.DISCARD}
                </Button>
                <Button
                  type="submit"
                  loading={form.submitInProgress}
                  onClick={this.onSubmit}
                >
                  {text.SAVE_BUTTON_LABEL}
                </Button>
              </FormPanel.Submit>
            </FormPanel>
          )}
        />
      </Page>
    );
  }
}

CancellationRulesForm.propTypes = propTypes;

export default CancellationRulesForm;
