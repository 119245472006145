/* eslint react/prop-types: 0 */
import React from 'react';

import { dateTimeFormatter } from '../../../../../../logic/utilities/formatters';
import withTable, { GridTab } from '../../../../../../components/grid/withTable';

const modifier = {
  last_used: {
    cellRendererFramework: ({ value }) => dateTimeFormatter(value),
  },
  payment_gateway: {
    cellRendererFramework: ({ value }) => value.name,
  },
};

const tableConfig = id => ({
  apiUrl: `users/${id}/payment_details/bank_accounts/`,
  reducerKey: 'USER_SINGLE',
  tableKey: 'BANK_ACCOUNTS_TABLE',
});

const BankAccounts = ({ id }) => {
  const Table = withTable(GridTab, tableConfig)(id);
  return <Table modifier={modifier} />;
};

export default BankAccounts;
