import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const api = child => getDjangoApi(`emails/marketing/${child}`);

const actionTypes = {
  GET_EMAIL_TEMPLATES: 'GET_EMAIL_TEMPLATES',
  GET_EMAIL_TEMPLATES_FULFILLED: 'GET_EMAIL_TEMPLATES_FULFILLED',
  GET_EMAIL_TEMPLATES_PENDING: 'GET_EMAIL_TEMPLATES_PENDING',
  GET_EMAIL_TEMPLATES_REJECTED: 'GET_EMAIL_TEMPLATES_REJECTED',
  GET_LANGUAGES: 'GET_LANGUAGES',
  GET_LANGUAGES_FULFILLED: 'GET_LANGUAGES_FULFILLED',
  GET_LANGUAGES_PENDING: 'GET_LANGUAGES_PENDING',
  GET_LANGUAGES_REJECTED: 'GET_LANGUAGES_REJECTED',
  GET_TEMPLATE: 'GET_TEMPLATE',
  GET_TEMPLATE_FULFILLED: 'GET_TEMPLATE_FULFILLED',
  GET_TEMPLATE_PENDING: 'GET_TEMPLATE_PENDING',
  GET_TEMPLATE_REJECTED: 'GET_TEMPLATE_REJECTED',
};

export const getEmailTemplates = createAction(actionTypes.GET_EMAIL_TEMPLATES, async () => {
  const emailApi = getDjangoApi('events/actions/send_email_user');
  
  const { data } = await emailApi.list();
  const templates = data.fields.find(field => field.key === 'template').choices;
  return templates;
});

export const getTemplateContent = createAction(actionTypes.GET_TEMPLATE, async (templateId) => {
  const templateApi = api(`${templateId}/content`);
  const { data } = await templateApi.list(templateId);
  const { results } = data;
  return results;
});

export const getLanguages = createAction(actionTypes.GET_LANGUAGES, async () => {
  const languageApi = api('languages');
  const { data } = await languageApi.list();
  return data;
});


const initialState = {
  templates: [],
  templatesInProgress: false,
  templatesError: null,
  template: [],
  templateInProgress: false,
  templateError: null,
  languages: [],
  languagesInProgress: false,
  langugaesError: null,
};

const templatesFulfilledHandler = (state, { payload }) => ({
  ...state,
  templates: payload,
  templatesInProgress: false,
  templatesError: null,
});

const templatesRejectedHandler = (state, { payload }) => ({
  ...state,
  templatesInProgress: false,
  templatesError: payload.data,
});

const templateFulfilledHandler = (state, { payload }) => ({
  ...state,
  template: payload,
  templateInProgress: false,
  templateError: null,
});

const templateRejectedHandler = (state, { payload }) => ({
  ...state,
  templateInProgress: false,
  templateError: payload.data,
});

const languagesFulfilledHandler = (state, { payload }) => ({
  ...state,
  languages: payload,
  languagesInProgress: false,
  lenguagesError: null,
});

const languagesRejectedHandler = (state, { payload }) => ({
  ...state,
  languagesInProgress: false,
  languagesError: payload.data,
});

export default handleActions(
  {
    [actionTypes.GET_EMAIL_TEMPLATES_PENDING]: state => ({ ...state, templatesInProgress: true }),
    [actionTypes.GET_EMAIL_TEMPLATES_FULFILLED]: templatesFulfilledHandler,
    [actionTypes.GET_EMAIL_TEMPLATES_REJECTED]: templatesRejectedHandler,
    [actionTypes.GET_TEMPLATE_PENDING]: state => ({ ...state, templateInProgress: true }),
    [actionTypes.GET_TEMPLATE_FULFILLED]: templateFulfilledHandler,
    [actionTypes.GET_TEMPLATE_REJECTED]: templateRejectedHandler,
    [actionTypes.GET_LANGUAGES_PENDING]: state => ({ ...state, languagesInProgress: true }),
    [actionTypes.GET_LANGUAGES_FULFILLED]: languagesFulfilledHandler,
    [actionTypes.GET_LANGUAGES_REJECTED]: languagesRejectedHandler,
  },
  initialState,
);
