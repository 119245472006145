import React, { Component } from 'react';
import { string, object, bool } from 'prop-types';
import { bindActionCreators } from 'redux';
import { InfoBox, Spinner, Table, notifier } from 'tc-biq-design-system';

import connect from '../../../../../../logic/connect';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import dateTimeFormatter from '../../../../../../logic/utilities/formatters/dateTimeFormatter';
import documentTypes from '../../../../../../logic/enums/document-types';
import If from '../../../../../../components/If';
import { YesNoCell } from '../../../../../../components/gridCellRenderers';
import overlayActions from '../../../../../../components/overlay';
import BiqAccordion from '../../../../../../components/BiqAccordion';
import DocumentPreview from '../../../../../../components/common/modals/DocumentPreview';
import { activate, resetData } from './Model';
import Header from './Components/Header';
import SectionAccordion from './Components/SectionAccordion';
import reviewTypes from './reviewTypes';
import sectionStates from './sectionStates';
import './Protrader.scss';

const propTypes = {
  id: string.isRequired,
  data: object.isRequired,
  fetchInProgress: bool.isRequired,
  actions: object.isRequired,
};

const DOCUMENT_USAGES = {
  PORTFOLIO: 'Professional trader portfolio',
  TRADING: 'Professional trader trading activity',
  QUESTIONS: 'Professional trader financial sector',
};

const text = {
  MODAL_TITLE: gettext('Section review'),
  DOCUMENT_PREVIEW: gettext('Document preview'),
  INFO: gettext('There is no professional data for this user.'),
  APPROVE_SUCCESS: gettext('Professional trader status successfully approved.'),
  APPROVE_ERROR: gettext('Error while approving professional trader status.'),
  REJECT_SUCCESS: gettext('Professional trader status successfully rejected.'),
  REJECT_ERROR: gettext('Error while rejecting professional trader status.'),
  QUESTIONS: gettext('Questions'),
  PORTFOLIO_CHECK: gettext('Portfolio check'),
  TRADING_ACTIVITY: gettext('Trading activity'),
  DOCUMENTS: gettext('Documents'),
  ON_SUCCESS: gettext('Section updated successfully'),
  ON_ERROR: gettext('Error while updating section'),
  NA: gettext('N/A'),
  SECTOR: gettext('Sector'),
  ROLE: gettext('Role'),
  COMPANY_NAME: gettext('Company name'),
  PORTFOLIO_VALUE: gettext('Sufficient portfolio value'),
  DATE_CHANGED: gettext('Date changed'),
  NUMBER_OF_TRADES: gettext('Number of trades'),
  TRADING_ACTIVITY_CHECK: gettext('Trading activity check'),
  DOCUMENT_NAME: gettext('Document name'),
  DATE_UPLOADED: gettext('Date uploaded'),
  TYPE: gettext('Type'),
};

class Protrader extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.api = getDjangoApi(`users/${props.id}/professional_trader`);
  }

  componentDidMount() {
    const { id } = this.props;
    this.actions.activate(this.api, id);
  }

  componentWillUnmount() {
    this.actions.resetData();
  }

  onSuccess() {
    const { id } = this.props;
    notifier.success(text.ON_SUCCESS);
    this.actions.activate(this.api, id);
  }

  onError() {
    notifier.error(text.ON_ERROR);
  }

  getDocument = (section) => {
    const documents = _.get(this, 'props.data.documents');
    return documents.filter(({ usage }) => usage === section);
  }

  updateStatus = action => () => {
    const { id } = this.props;
    const isApprove = action === 'approve';
    const endpoint = isApprove ? 'manual_approve' : 'manual_reject';
    const successMessage = isApprove ? text.APPROVE_SUCCESS : text.REJECT_SUCCESS;
    const errorMessage = isApprove ? text.APPROVE_ERROR : text.REJECT_ERROR;

    this.api.all(endpoint)
      .create()
      .then(() => {
        notifier.success(successMessage);
        this.actions.activate(this.api, id);
      }, () => {
        notifier.error(errorMessage);
      });
  }

  previewDocument = (document) => {
    this.actions.openModal('PREVIEW_DOCUMENT', {
      document, 
      action: 'preview',
      content: { title: text.DOCUMENT_PREVIEW },
    });
  }

  reviewDocuments = (section, api, state) => {
    const documents = _.get(this, 'props.data.documents');

    let action = 'review';
    if (state.value === sectionStates.REJECTED) action = 'approve';
    if (state.value === sectionStates.APPROVED) action = 'reject';
    this.actions.openModal('PREVIEW_DOCUMENT', {
      document: documents.filter(({ usage }) => usage === section),
      action,
      onApprove: () => api.all('approve').create(),
      onReject: () => api.all('reject').create(),
      content: { title: text.MODAL_TITLE, success: text.ON_SUCCESS, error: text.ON_ERROR },
    });
  }

  previewDocuments = (section) => {
    const document = this.getDocument(section);
    this.actions.openModal('PREVIEW_DOCUMENT', {
      document,
      action: 'preview',
      content: { title: text.DOCUMENT_PREVIEW, icon: 'View' },
    });
  }

  questionsHandler = (type, action) => () => {
    const state = _.get(this, 'props.data.financialSectorState');
    const api = this.api.all('section_finsec');
    if (type === reviewTypes.POPCONFIRM) api.all(action).create().then(this.onSuccess, this.onError);
    if (type === reviewTypes.MODAL) this.reviewDocuments(DOCUMENT_USAGES.QUESTIONS, api, state);
  }

  portfolioHandler = (type, action) => () => {
    const state = _.get(this, 'props.data.portfolioState');
    const api = this.api.all('section_portfolio');
    if (type === reviewTypes.POPCONFIRM) api.all(action).create().then(this.onSuccess, this.onError);
    if (type === reviewTypes.MODAL) this.reviewDocuments(DOCUMENT_USAGES.PORTFOLIO, api, state);
  }

  activityHandler = (type, action) => () => {
    const state = _.get(this, 'props.data.activityState');
    const api = this.api.all('section_tradeact');
    if (type === reviewTypes.POPCONFIRM) api.all(action).create().then(this.onSuccess, this.onError);
    if (type === reviewTypes.MODAL) this.reviewDocuments(DOCUMENT_USAGES.TRADING, api, state);
  }

  hasDocuments(usage) {
    const { data } = this.props;
    if (!data || _.isEmpty(data)) return false;

    const { documentUsages, documents } = data;
    const sectionUsage = _.find(documentUsages, { value: usage });
    if (!sectionUsage) return false;

    const documentsCount = documents.filter(({ usage }) => usage === sectionUsage.display_name).length;
    return documentsCount > 0;
  }

  render() {
    const { data, fetchInProgress, id } = this.props;

    const {
      state,
      financialSectorState,
      portfolioState,
      activityState,
      approval_time,
      rejection_time,
      cancellation_time,
      manually_reviewed_by,
      finsec_sector,
      finsec_role,
      finsec_company_name,
      portfolio_check,
      portfolio_updated,
      tradeact_check,
      tradeact_value,
      documents,
    } = data;

    const headerProps = {
      state,
      approval_time,
      rejection_time,
      cancellation_time,
      manually_reviewed_by,
    };

    const questionsProps = {
      title: text.QUESTIONS,
      state: financialSectorState,
      data: [
        { entity: text.SECTOR, value: finsec_sector || text.NA }, 
        { entity: text.ROLE, value: finsec_role || text.NA },
        { entity: text.COMPANY_NAME, value: finsec_company_name || text.NA },
      ],
      reviewAction: {
        handler: this.questionsHandler,
        type: this.hasDocuments(documentTypes.FINANCIAL_SECTOR) ? reviewTypes.MODAL : reviewTypes.POPCONFIRM,
      },
    };

    const portfolioProps = {
      title: text.PORTFOLIO_CHECK,
      state: portfolioState,
      data: [
        { entity: text.PORTFOLIO_VALUE, value: YesNoCell({ value: portfolio_check }) || text.NA }, 
        { entity: text.DATE_CHANGED, value: dateTimeFormatter(portfolio_updated, false) || text.NA },
      ],
      reviewAction: {
        handler: this.portfolioHandler,
        type: this.hasDocuments(documentTypes.PORTFOLIO) ? reviewTypes.MODAL : reviewTypes.POPCONFIRM,
      },
    };

    const activityProps = {
      title: text.TRADING_ACTIVITY,
      state: activityState,
      data: [
        { entity: text.NUMBER_OF_TRADES, value: tradeact_value || text.NA }, 
        { entity: text.TRADING_ACTIVITY_CHECK, value: tradeact_check || text.NA },
      ],
      reviewAction: {
        handler: this.activityHandler,
        type: this.hasDocuments(documentTypes.TRADING_ACTIVITY) ? reviewTypes.MODAL : reviewTypes.POPCONFIRM,
      },
    };

    const documentsProps = () => ({
      cols: [
        { 
          title: text.DOCUMENT_NAME, 
          key: 'name', 
          render: data => (
            <span 
              className="text-primary-400" 
              style={{ cursor: 'pointer' }} 
              onClick={() => this.previewDocument(data)}
            >
              {data.name}
            </span>
          ),
        },
        { title: text.DATE_UPLOADED, key: 'uploaded_at', render: ({ uploaded_at }) => dateTimeFormatter(uploaded_at, false) },
        { title: text.TYPE, key: 'document_type' },
      ],
      data: documents,
    });

    return (
      <div className="biq-profile__wrapper biq-protrader">
        <If condition={fetchInProgress}>
          <div className="loader">
            <Spinner size="large" />
          </div>
        </If>

        <If condition={!fetchInProgress && _.isEmpty(data)}>
          <div>
            <InfoBox>
              <span className="text-neutral-900">{text.INFO}</span>
            </InfoBox>
          </div>
        </If>

        <If condition={!fetchInProgress && !_.isEmpty(data)}>
          <Header 
            onApprove={this.updateStatus('approve')} 
            onReject={this.updateStatus('reject')} 
            {...headerProps} 
          />

          <SectionAccordion {...questionsProps} />
          <SectionAccordion {...portfolioProps} />
          <SectionAccordion {...activityProps} />

          <div className="accordion-section">
            <BiqAccordion title="Documents">
              <div className="biq-protrader__documents">
                <Table {...documentsProps(this.previewDocuments)} />
              </div>
            </BiqAccordion>
          </div>
        </If>

        <DocumentPreview onSuccess={() => this.actions.activate(this.api, id)} />
      </div>
    );
  }
}

const mapStateToProps = ({ pages }) => pages.USER_SINGLE.protrader;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    activate,
    resetData,
    openModal: overlayActions.open,
  }, dispatch),
});

Protrader.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(Protrader);
