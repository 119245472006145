import React, { Component } from 'react';
import { object, node, func, shape } from 'prop-types';
import { Tabs, Tab, Space } from 'tc-biq-design-system';

import { gettext } from '../../../../logic/utilities/languageUtility';
import TrackingLinksTable from './TrackingLinksTable';
import SignupUsersTable from './SignupUsersTable';
import Page from '../../../../components/Page';
import CampaignHeader from './CampaignHeader';
import appRoutes from '../../../../components/App/Router/appRoutes';

const text = {
  TITLE: gettext('Campaigns'),
  TRACKING_LINKS: gettext('Tracking links'),
  SIGNUP_USERS: gettext('Signup users'),
};

const bread = campaign => ([
  { 
    label: text.TITLE, 
    route: appRoutes.CAMPAIGNS,
  },
  ...(campaign ? [{
    label: campaign.name,
    route: '',
  }] : []),
]);

const propTypes = {
  actions: object.isRequired,
  campaign: object,
  date: object.isRequired,
  dateFilterComponent: node.isRequired,
  setOnDateChangeHandler: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  match: object.isRequired,
};

const defaultProps = {
  campaign: null,
};

class CampaignSingle extends Component {
  constructor(props) {
    super(props);

    this.cid = Number(props.match.params.id);
  }

  componentDidMount() {
    this.loadCampaign();
    const { setOnDateChangeHandler } = this.props;
    setOnDateChangeHandler(this.loadCampaign);
  }

  getTableParams = () => {
    const { campaign } = this.props;
    return campaign ? { cid: this.cid, uid: campaign.user.id } : null;
  }

  loadCampaign = () => {
    const { actions, date: { startDate, endDate } } = this.props;
    actions.fetchCampaign(this.cid, {
      period_from: startDate, 
      period_to: endDate,
    });
  }

  render() {
    const { campaign, dateFilterComponent } = this.props;
    const tableParams = this.getTableParams();

    return (
      <Page 
        title={text.TITLE}
        bread={bread(campaign)}
      >
        <Space size={16} />
        {campaign && (
        <CampaignHeader 
          dateFilterComponent={dateFilterComponent} 
          campaign={campaign}
          loadCampaign={this.loadCampaign}
        />
        )}
        <Tabs>
          <Tab
            visible
            title={text.SIGNUP_USERS}
          >
            {tableParams ? <SignupUsersTable {...tableParams} /> : null}
          </Tab>
          <Tab 
            visible
            title={text.TRACKING_LINKS}
          >
            {tableParams ? <TrackingLinksTable {...tableParams} /> : null}
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

CampaignSingle.propTypes = propTypes;
CampaignSingle.defaultProps = defaultProps;

export default CampaignSingle;
