import React from 'react';
import { string, node, oneOf, func, number, shape } from 'prop-types';
import classNames from 'classnames';

import { generatePath } from 'react-router-dom';
import appRoutes from '../../../../../components/App/Router/appRoutes';

import './TreeItem.scss';

const types = {
  ROOT: 'root',
  LEVEL_1: 'level-1',
  LEVEL_2: 'level-2',
  LEVEL_3: 'level-3',
};

const TreeItem = ({ title, id, type, children, onClick, history }) => {
  const onNavigate = (e) => {
    e.stopPropagation();
    history.push(generatePath(appRoutes.TEAM, { id }));
  };

  return (
    <div onClick={onClick} className={classNames('biq-tree-item', `biq-tree-item--${type}`, { 'biq-tree-item--clickable': !!onClick })}>
      <div className="biq-tree-item__title tc-heading-s" onClick={onNavigate}>
        {title}
      </div>
      {children && (
        <div className="biq-tree-item__content">
          {children}
        </div>
      )}
    </div>
  );
};

TreeItem.propTypes = {
  title: string.isRequired,
  id: number.isRequired,
  children: node,
  onClick: func,
  type: oneOf([types.ROOT, types.LEVEL_1, types.LEVEL_2, types.LEVEL_3]),
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

TreeItem.defaultProps = {
  children: null,
  onClick: null,
  type: types.ROOT,
};

export default TreeItem;
