import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  MARK_PENDING: 'MARK_PENDING',
  MARK_PENDING_FULFILLED: 'MARK_PENDING_FULFILLED',
  MARK_PENDING_PENDING: 'MARK_PENDING_PENDING',
  MARK_PENDING_REJECTED: 'MARK_PENDING_REJECTED',
};

export const markPendingUser = createAction(actionTypes.MARK_PENDING, async (user, state) => {
  const markPendingApi = getDjangoApi(`users/${user.id}/mark_pending`);
  
  await markPendingApi.create({ state });
});


const initialState = {
  submitInProgress: false,
  errors: null,
};

const pendingHandler = state => ({ ...state, submitInProgress: true });

const fulfilledHandler = state => ({
  ...state,
  submitInProgress: false,
  error: null,
});

const rejectedHandler = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  errors: payload.data,
});

export default handleActions(
  {
    [actionTypes.MARK_PENDING_PENDING]: pendingHandler,
    [actionTypes.MARK_PENDING_FULFILLED]: fulfilledHandler,
    [actionTypes.MARK_PENDING_REJECTED]: rejectedHandler,
  },
  initialState,
);
