import React from 'react';
import { Select } from 'tc-biq-design-system';
import { bool, string } from 'prop-types';

import loadOptions from '../../../logic/utilities/loadOptions';

const propTypes = {
  listUrl: string,
  queryKey: string,
  fieldId: string,
  withPortal: bool,
};

const defaultProps = {
  listUrl: '',
  queryKey: '',
  fieldId: 'id',
  withPortal: false,
};

const MultipleSelect = ({ listUrl, queryKey, fieldId, withPortal, ...props }) => (
  <Select
    {...props}
    type="multi"
    debounceInterval={500}
    loadOptions={loadOptions(listUrl, queryKey, _, fieldId)}
    menuPortalTarget={withPortal ? document.body : null}
    async={!!listUrl}
  />
);

MultipleSelect.defaultProps = defaultProps;
MultipleSelect.propTypes = propTypes;

export default MultipleSelect;
