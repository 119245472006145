import React, { Fragment } from 'react';
import { HyperLink } from 'tc-biq-design-system';
import { array } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import _ from 'lodash';

const propTypes = {
  value: array,
};

const defaultProps = {
  value: [],
};

// eslint-disable-next-line
const MultiRouteCell = ({ href, displayName }) => ({ value }) => {
  if (!_.isArray(value) || _.isEmpty(value)) return <span />;
  return value.map((item, index) => (
    <Fragment key={item.id}>
      <HyperLink>
        <Link to={generatePath(href, { id: item.id })}>{item[displayName]}</Link>
        {index !== value.length - 1 ? <span>,</span> : null}
      </HyperLink>
    </Fragment>
  ));
};

MultiRouteCell.propTypes = propTypes;
MultiRouteCell.defaultProps = defaultProps;

export default MultiRouteCell;
