import { OnContentClickCell, UsernameCell } from '../../../../components/gridCellRenderers';
import { currencyFormatter } from '../../../../logic/utilities/formatters';

export default ({ previewUser, openTransaction }) => ({
  id: {
    cellRendererFramework: OnContentClickCell(openTransaction),
    width: 80,
  },
  user: {
    cellRendererFramework: UsernameCell(previewUser, { isIb: true }),
    width: 200,
  },
  value: {
    valueGetter: ({ data }) => currencyFormatter(data.value, data.currency.symbol),
  },
});
