import React, { Component } from 'react';
import { Button, Tabs, Tab } from 'tc-biq-design-system';
import { object } from 'prop-types';

import isFeatureEnabled from '../../../../logic/filters/is-feature-enabled';
import Page from '../../../../components/Page';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GbgdTab, { SIDEPANEL_ID as GBG_SIDEPANEL_ID } from './GbgTab';
import OnfidoTab, { SIDEPANEL_ID as ONFIDO_SIDEPANEL_ID } from './OnfidoTab';
import { hasAccess } from '../../../../logic/services/acl';
import appRoutes from '../../../../components/App/Router/appRoutes';

const tabs = {
  GBG: 'gbg',
  ONFIDO: 'onfido',
};

const text = {
  TITLE: gettext('User state mapping'),
  CREATE: {
    [tabs.GBG]: gettext('Create GBG mapping'),
    [tabs.ONFIDO]: gettext('Create Onfido mapping'),
  },
  TAB_TITLE: {
    [tabs.GBG]: gettext('GBG'),
    [tabs.ONFIDO]: gettext('Onfido'),
  },
};

const bread = [{ label: text.TITLE, route: appRoutes.KYC_USER_STATE_MAPPING }];

const propTypes = {
  actions: object.isRequired,
};

class UserStateMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: tabs.GBG,
    };
  }

  setActiveTab = (active) => {
    this.setState({ active });
  }

  headerActions = () => {
    const { active } = this.state;
    const { actions } = this.props;
    return ({
      [tabs.GBG]: hasAccess('kyc.user_state_mapping.create') && (
        <Button onClick={() => actions.openSidepanel(GBG_SIDEPANEL_ID)}>
          {text.CREATE[tabs.GBG]}
        </Button>
      ),
      [tabs.ONFIDO]: hasAccess('kyc.user_state_mapping.create') && (
        <Button onClick={() => actions.openSidepanel(ONFIDO_SIDEPANEL_ID)}>
          {text.CREATE[tabs.ONFIDO]}
        </Button>
      ),
    })[active];
  }

  render() {
    return (
      <Page 
        title={text.TITLE} 
        bread={bread} 
        headerActions={this.headerActions}
      >
        <Tabs>
          <Tab
            visible 
            title={text.TAB_TITLE[tabs.GBG]} 
            onLoad={() => this.setActiveTab(tabs.GBG)}
          >
            <GbgdTab />
          </Tab>
          <Tab
            visible={isFeatureEnabled()('ONFIDO')} 
            title={text.TAB_TITLE[tabs.ONFIDO]} 
            onLoad={() => this.setActiveTab(tabs.ONFIDO)}
          >
            <OnfidoTab />
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

UserStateMapping.propTypes = propTypes;

export default UserStateMapping;
