import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { Modal, notifier, Upload } from 'tc-biq-design-system';
import { object, func } from 'prop-types';

import overlayActions from '../../../../../components/overlay';
import { ADD_LEAD_BULK_MODAL_ID } from '../../AddLeads/AddLeads';
import connect from '../../../../../logic/connect';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import Uploader from '../../../../../logic/services/uploader';
import { leadsApi } from '../AddLeadSingle';

import './AddLeadBulk.scss';

const propTypes = {
  actions: object.isRequired,
  modal: object.isRequired,
  fetchTableData: func.isRequired,
};

const text = {
  TITLE: gettext('Upload new leads'),
  SAVE: gettext('Upload'),
  SUCCESS: gettext('Your file is being processed - once completed you will be notified via e-mail'),
  ERROR: gettext('Failed to upload leads'),
  UPLOAD_TEXT: {
    initial: {
      title: gettext('*The uploaded file must be .csv format'),
      subtitle: gettext('The limit is 1500 rows'),
    },
  },
  OBLIGATORY_FIELDS: gettext('Obligatory fields:'),
  OPTIONAL_FIELDS: gettext('Optional fields:'),
  WRONG_FILE_TYPE: gettext('The uploaded file must be .csv format'),
};

const excludeKeys = ['cxd', 'aff_id', 'sate'];
const avaOnlyKeys = ['language', 'cage', 'tag_id'];

class AddLeadSingle extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.uploadStatus = 'initial';
    this.uploaderApi = new Uploader('lead_upload/');
    this.state = {
      fieldKeys: [],
    };
  }

  componentDidMount() {
    this.getFieldKeys();
  }

  onClose = (success) => {
    const { fetchTableData } = this.props;
    if (success) fetchTableData();
    this.actions.closeModal(ADD_LEAD_BULK_MODAL_ID);
  };

  getFieldKeys = async () => {
    const response = await leadsApi.options();
    let filteredKeys = response.data.actions.POST.fields.map(field => field.key).filter(key => !excludeKeys.includes(key));
    if (window.config.environment !== 'AvaTrade') filteredKeys = filteredKeys.filter(key => !avaOnlyKeys.includes(key));
    this.setState({ fieldKeys: filteredKeys });
  };

  submit = ([file]) => {
    if (!file.name.endsWith('.csv')) {
      return notifier.error(text.WRONG_FILE_TYPE);
    }

    return this.uploaderApi.upload({ lead_upload: file }).then(
      () => {
        this.onClose(true);
        notifier.info(text.SUCCESS);
      },
      () => {
        notifier.error(text.ERROR);
      },
    );
  };

  render() {
    const { modal } = this.props;
    const { fieldKeys } = this.state;
    const obligatoryKeys = ['first_name', 'last_name', 'email'];
    const optionalKeys = fieldKeys.filter(key => !obligatoryKeys.includes(key));
    return (
      <Modal
        type="success"
        icon="Upload"
        title={text.TITLE}
        visible={modal.visible}
        onConfirm={this.submit}
        onCancel={this.onClose}
        onCloseIconClick={this.onClose}
        confirmText={text.SAVE}
        hideFooter
        closable
      >
        <div className="biq-add-lead-bulk">
          <div className="biq-add-lead-bulk__field-info">
            <div className="section">
              <strong>{text.OBLIGATORY_FIELDS}</strong>
              <ul>
                {obligatoryKeys.map(key => <li key={key}>{key}</li>)}
              </ul>
            </div>
            <div className="section">
              <strong>{text.OPTIONAL_FIELDS}</strong>
              <ul className="optional-fields">
                {optionalKeys.map(key => <li key={key}>{key}</li>)}
              </ul>
            </div>
          </div>
          <Upload status="initial" onDrop={this.submit} localization={text.UPLOAD_TEXT} />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ overlays }) => {
  const modal = overlays[ADD_LEAD_BULK_MODAL_ID];
  return { modal };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeModal: overlayActions.close,
    },
    dispatch,
  ),
});

AddLeadSingle.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(AddLeadSingle));
