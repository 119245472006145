import React, { Component } from 'react';

import { Button, notifier, Select, Space, DashboardGridLayout } from 'tc-biq-design-system';
import { array, object, string } from 'prop-types';

import to from '../../../../../../logic/utilities/to';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import Page from '../../../../../../components/Page';
import SelectSource from './SelectSource';
import SelectorFactory from '../../../../../../components/common/modals/Selector';
import ActionBar from './ActionBar';
import widgets from './widgets';
import DashboardLayoutForm, { SIDEPANEL_ID } from '../../DashboardLayouts/sidepanels/DashboardLayoutForm';
import { getValues, mapValues, parseLayout } from './utils/dashboard';
import defaultDashboard from './data/defaultDashboard.json';
import DatePickerRangeAll from '../../../../../../components/common/DatePickerRangeAll';
import { hasAccess } from '../../../../../../logic/services/acl';

import './DashboardPage.scss';

const hashedWidgets = widgets.reduce((acc, widget) => ({ ...acc, [widget.key]: widget }), {});

const MODAL_ID = 'SELECT_DASHBOARD_WIDGET';
const SelectorModal = SelectorFactory(MODAL_ID);

const text = {
  TITLE: gettext('Dashboard'),
  ADD_REPORT: gettext('Add report'),
  GENERAL_ERROR: gettext('Something went wrong'),
  SUCCESS: gettext('Dashboard layout saved successfully'),
  DASHBOARDS: gettext('Select dashboards'),
  PERMISSIONS: gettext('You do not have permission to see this'),
};

const selectorText = {
  TITLE: gettext('Manage widgets'),
  SELECTED: gettext('Selected widgets'),
};

const propTypes = {
  bread: array.isRequired,
  actions: object.isRequired,
  startDate: string.isRequired,
  endDate: string.isRequired,
  dashboards: array,
  dashboard: object,
  match: object.isRequired,
};

const defaultProps = {
  dashboards: [],
  dashboard: {},
};

class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: getValues(widgets),
      layoutChanged: false,
    };

    this.defaultDashboard = {};
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.resetDashboard();
  }

  onSelectDashboards = (dashboard) => {
    this.setDashboard({ dashboard, updateState: true });
  }

  onLayoutChange = (layout) => {
    const { actions, dashboard } = this.props;
    let layoutChanged = false;
    if (this.shouldUpdate(layout)) {
      layoutChanged = true;

      actions.setDashboard({ ...dashboard, layout });
    }

    this.setState({
      layoutChanged,
    });
  }

  onApply = (newValues) => {
    const { dashboard, actions } = this.props;

    const newLayout = this.addWidgets(parseLayout(newValues, dashboard.layout, hashedWidgets));
    actions.setDashboard({ ...dashboard, layout: newLayout });

    this.setState({
      values: newValues,
    });
  }

  onAddWidget = () => {
    const { actions } = this.props;
    const { values } = this.state;

    actions.openModal(MODAL_ID, {
      options: widgets,
      values,
    });
  }

  onDateChange = (date) => {
    const { actions } = this.props;
    actions.setDate(date);
  }

  onUndo = () => {
    const { dashboard: { id } } = this.props;

    if (!id) {
      this.setDashboard({ dashboard: this.defaultDashboard, updateState: true });
    } else {
      this.fetchDashboard(id);
    }
  }

  onSaveLayout = async () => {
    const { actions, dashboard: { id, layout } } = this.props;
    const updatePart = true;

    const [err] = await to(actions.updateDashboard(id, { layout }, updatePart));
    err ? this.onError(err) : this.onSuccess();
  }

  onSaveAsLayout = () => {
    const { actions, dashboard: { layout, name } } = this.props;
    actions.openSidepanel(SIDEPANEL_ID, { layout, name });
  }

  onSuccess = () => {
    notifier.success(text.SUCCESS);
    this.setState({
      layoutChanged: false,
    });
  }

  onCreateSuccess = (dashboard) => {
    const { actions } = this.props;
    actions.fetchDashboards().then(() => {
      this.setState({
        layoutChanged: false,
      }, () => this.setDashboard({ dashboard, updateState: true }));
    });
  }

  onError({ data }) {
    if (data) {
      const { non_field_errors, messages } = data;
      if (non_field_errors) {
        notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
      } else if (messages) {
        notifier.error(data.messages.map(err => <span>{err.text}</span>));
      } else {
        notifier.error(text.GENERAL_ERROR);
      }
    } else {
      notifier.error(text.GENERAL_ERROR);
    }
  }

  setDashboard = ({ dashboard, updateState }) => {
    const { actions } = this.props;
    const values = getValues(widgets);

    if (updateState) actions.setDashboard(dashboard);
    this.defaultDashboard = { ...dashboard };
    const newValues = mapValues(values, dashboard.layout);
    this.setState({
      values: newValues,
    });
  }

  isDefaultDashboard = () => {
    const { dashboard } = this.props;
    if (!dashboard) return false;

    return dashboard.id === defaultDashboard.id;
  }

  shouldUpdate = (newLayout) => {
    const keys = ['i', 'x', 'y', 'h', 'w'];
    const oldLayout = this.defaultDashboard.layout || [];
    return oldLayout.length !== newLayout.length
      || oldLayout.some((dl, index) => {
        const l = newLayout[index];

        return keys.some(key => l[key] !== dl[key]);
      });
  }

  addWidgets = ({ cleanLayout, newWidgets }) => {
    if (_.isEmpty(cleanLayout)) {
      return newWidgets.map(widget => ({ i: widget.key, ...widget.size }));
    }

    newWidgets.forEach((widget) => {
      cleanLayout = [...cleanLayout, { i: widget.key, ...widget.size }];
    });

    return cleanLayout;
  }

  headerActions = () => {
    const { startDate, endDate } = this.props;
    return (
      <DatePickerRangeAll
        onChange={this.onDateChange}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  topBar = () => {
    const { layoutChanged } = this.state;
    const canUpdate = !this.isDefaultDashboard() && hasAccess('layout.dashboard.update');
    const canCreate = hasAccess('layout.dashboard.create');
    return (
      <ActionBar
        visible={layoutChanged}
        onSave={canUpdate ? this.onSaveLayout : null}
        onSaveAs={canCreate ? this.onSaveAsLayout : null}
        onUndo={this.onUndo}
      />
    );
  }

  fetchData = () => {
    const { match } = this.props;
    const layoutId = match.params.id;
    if (layoutId) {
      this.fetchDashboard(layoutId);
    } else {
      this.fetchDashboards();
    }
  }

  fetchDashboard = (id) => {
    const { actions } = this.props;

    actions.fetchDashboard(id).then(() => {
      const { dashboard } = this.props;
      this.setDashboard({ dashboard });
    });
  }

  fetchDashboards = () => {
    const { actions } = this.props;

    return actions.fetchDashboards().then(() => {
      const { dashboards } = this.props;
      const dashboard = dashboards[0];
      this.setDashboard({ dashboard, updateState: true });
    });
  }

  render() {
    const { bread, dashboards, dashboard } = this.props;
    const { values } = this.state;
    const visibleValue = values.some(v => v.visible);
    
    return (
      <Page
        bread={bread}
        title={text.TITLE}
        headerActions={this.headerActions}
        topBar={this.topBar}
      >
        <Space size={20} />
        <div className="dashboard-page">
          <div className="dashboard-page__grid">
            <div className="dashboard-page__top">
              {dashboards.length > 0 && (
                <div className="dashboard-page__top__select">
                  <Select
                    type="search"
                    options={dashboards}
                    value={dashboard}
                    onChange={this.onSelectDashboards}
                    placeholder={text.DASHBOARDS}
                    valueKey="id"
                    labelKey="name"
                    clearable={false}
                  />
                </div>
              )}
              <div className="dashboard-page__top__item">
                <SelectSource />
              </div>
            </div>
            <Space size={20} />

            {visibleValue && (
              <DashboardGridLayout
                onLayoutChange={this.onLayoutChange}
                layout={dashboard.layout}
                isDraggable={hasAccess('layout.dashboard.update') || hasAccess('layout.dashboard.update')}
                cols={12}
                rowHeight={30}
              >
                {
                  values.filter(value => value.visible).map(({ key }) => {
                    const Widget = hashedWidgets[key];
                    const WidgetComponent = Widget.component;
                    return (
                      <div key={key} style={!Widget.isVisible() ? { display: 'none' } : {}}>
                        <WidgetComponent title={Widget.label} description={Widget.description} />
                      </div>
                    );
                  })
}
              </DashboardGridLayout>
            )}
          </div>
          <Space size={20} />
          {hasAccess('layout.dashboard.update') && (
            <div className="dashboard-page__add-report">
              <Button color="ghost" onClick={this.onAddWidget}>
                {text.ADD_REPORT}
              </Button>
            </div>
          )}
          <Space size={20} />
        </div>
        <SelectorModal
          translations={selectorText}
          onApply={this.onApply}
        />
        <DashboardLayoutForm onSuccess={this.onCreateSuccess} />
      </Page>
    );
  }
}

DashboardPage.propTypes = propTypes;
DashboardPage.defaultProps = defaultProps;

export default DashboardPage;
