import React, { Component } from 'react';
import { Button, Input, Checkbox, Icon } from 'tc-biq-design-system';
import { arrayOf, string, shape, bool, func } from 'prop-types';

import { gettext } from '../../../../../../../logic/utilities/languageUtility';

import './Substatuses.scss';

const text = {
  TITLE: gettext('Substatues'),
  REMOVE_ALL: gettext('Remove all'),
  ADD: gettext('Add substatus'),
  OPTION_TITLE: gettext('Title'),
  TASKS: gettext('Tasks'),
  NOTES: gettext('Notes'),
};

const renderSubstatuses = (value, onRemove, onChangeName, onChangeNotes, onChangeTasks, isAutoDialer) => (
  <div className="cf-substatuses__options">
    {value.map(({ name, notes, tasks }, index) => (
      <div key={index} className="cf-substatuses__option">
        <Input 
          className="cf-substatuses__option__title"
          placeholder={text.OPTION_TITLE}
          onChange={e => onChangeName(index, e.target.value)}
          value={name}
        />
        { !isAutoDialer && (
        <Checkbox
          className="cf-substatuses__option__checkbox"
          checked={tasks}
          onChange={() => onChangeTasks(index)}
        >
          {text.TASKS}
        </Checkbox>
        )}
        {
          !isAutoDialer
        && (
        <Checkbox
          checked={notes}
          onChange={() => onChangeNotes(index)}
        >
          {text.NOTES}
        </Checkbox>
        )
        }
        <Icon
          className="cf-substatuses__option__icon"
          onClick={() => onRemove(index)}
          colorName="text-neutral-900"
          name="Close"
        /> 
      </div>
    ))}
  </div>
);

const propTypes = {
  onChange: func,
  isAutoDialer: bool.isRequired,
  value: arrayOf(shape({
    name: string,
    tasks: bool,
    notes: bool,
  })),
};

const defaultProps = {
  value: [],
  onChange: () => {},
};

class Substatuses extends Component {
  onAdd = () => {
    const { value, onChange } = this.props;
    onChange([...value, {}]);
  }

  onRemoveAll = () => {
    const { onChange } = this.props;
    onChange([]);
  }

  onRemove = (index) => {
    const { value, onChange } = this.props;
    const newValue = value.reduce((acc, substatus, i) => (i === index ? acc : [...acc, substatus]), []);
    onChange(newValue);
  }

  onChangeName = (index, name) => {
    const { value, onChange } = this.props;
    const newValue = value.map((substatus, i) => (i === index ? { ...substatus, name } : substatus));
    onChange(newValue);
  }

  onChangeNotes = index => this.onToggleField('notes')(index)

  onChangeTasks = index => this.onToggleField('tasks')(index)

  onToggleField = key => (index) => {
    const { value, onChange } = this.props;
    const newValue = value.map((substatus, i) => (i === index ? { ...substatus, [key]: !substatus[key] } : substatus));
    onChange(newValue);
  };

  render() {
    const { value, isAutoDialer } = this.props;
    return (
      <div className="cf-substatuses">
        <div className="cf-substatuses__header">
          <span className="cf-substatuses__title tc-heading-s">{text.TITLE}</span>
          <div className="cf-substatuses__actions">
            <Button 
              color="ghost"
              size="small"
              icon="Delete"
              onClick={this.onRemoveAll}
            >
              {text.REMOVE_ALL}
            </Button>
            <Button 
              size="small"
              icon="Plus"
              onClick={this.onAdd}
            >
              {text.ADD}
            </Button>
          </div>
        </div>
        {renderSubstatuses(
          value,
          this.onRemove,
          this.onChangeName,
          this.onChangeNotes,
          this.onChangeTasks,
          isAutoDialer,
        )}
      </div>
    );
  }
}

Substatuses.propTypes = propTypes;
Substatuses.defaultProps = defaultProps;

export default Substatuses;
