/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Sidepanel, notifier, InfoBox, Space } from 'tc-biq-design-system';
import { bool, object, func, array } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import FormFactory, { ErrorHandler, FormActionsFactory } from '../../../../../components/form';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import to from '../../../../../logic/utilities/to';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import overlayActions from '../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../components/common';

const propTypes = {
  onSuccess: func,
  sidepanel: object,
  fields: array.isRequired,
  actions: object.isRequired,
  submitInProgess: bool,
};

const defaultProps = {
  onSuccess: null,
  sidepanel: null,
  submitInProgess: false,
};

const text = {
  TITLE: gettext('Reject Withdrawal'),
  BUTTON_LABEL: {
    confirm: gettext('Reject withdrawal'),
  },
  SUCCESS_TEXT: gettext('Withdrawal rejected successfully'),
  ERROR_TEXT: gettext('Reject withdrawal error'),
  INFO: gettext(
    'Please note that this action is irreversible. Once you perform it it cannot be undone.',
  ),
  NOTE: gettext('Note'),
};

const SIDEPANEL_ID = 'IB_REJECT_WITHDRAWAL';

const customFooter = (execute, submitInProgress) => (
  <SidepanelFooter 
    execute={execute} 
    submitInProgress={submitInProgress} 
    confirmColor="destructive"
    buttonLabels={text.BUTTON_LABEL}
    formId={SIDEPANEL_ID}
  />
);

const formConfig = api => ({
  form: SIDEPANEL_ID,
  api,
  excludeFields: [],
});

const { errorFields, create, resetFields, setRowData } = FormActionsFactory(SIDEPANEL_ID);

class IbRejectWithdrawalSidepanel extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.execute = this.execute.bind(this);
    this.onClose = this.onClose.bind(this);
    this.api = null;
  }

  componentDidUpdate() {
    const { sidepanel } = this.props;
    this.actions.setRowData(sidepanel.parameters);
  }

  onClose(submitSuccess) {
    const { onSuccess } = this.props;
    this.actions.resetFields();
    this.actions.close(SIDEPANEL_ID);
    if (submitSuccess) onSuccess();
  }

  async execute() {
    const { fields } = this.props;
    const [err] = await to(this.actions.create(this.api));

    if (err) {
      new ErrorHandler({ errors: err.data, fields }).showMessages(notifier.error);
    } else {
      notifier.success(text.SUCCESS_TEXT);
      this.onClose(true);
      this.actions.resetFields;
    }
  }

  renderForm() {
    const { sidepanel } = this.props;
    if (!sidepanel.parameters) return null;
    this.api = getDjangoApi('ib')
      .all('withdraw_requests')
      .one(sidepanel.parameters.id)
      .all('reject');
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig(this.api));
    }
    const { FormComponent } = this;
    return <FormComponent formId={SIDEPANEL_ID} />;
  }

  render() {
    const { sidepanel, submitInProgess } = this.props;

    return (
      <Sidepanel
        type="error"
        icon="Close"
        title={text.TITLE}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.execute, submitInProgess)}
      >
        {this.renderForm()}
        <Space size={16} />
        <InfoBox type="error" header={text.NOTE}>{text.INFO}</InfoBox>
      </Sidepanel>
    );
  }
}

IbRejectWithdrawalSidepanel.propTypes = propTypes;
IbRejectWithdrawalSidepanel.defaultProps = defaultProps;

const mapStateToProps = ({ forms, overlays }) => {
  const { fields, submitInProgess } = forms[SIDEPANEL_ID];
  return {
    fields,
    submitInProgess,
    sidepanel: overlays.IB_REJECT_WITHDRAWAL,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      errorFields,
      create,
      resetFields,
      setRowData,
      close: overlayActions.close,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(IbRejectWithdrawalSidepanel));
