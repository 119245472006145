import React, { Component } from 'react';
import { Tabs, Tab, Button, Select, notifier } from 'tc-biq-design-system';
import { object, func } from 'prop-types';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import Page from '../../../../../components/Page';
import withTable, { GridTab } from '../../../../../components/grid/withTable';
import { gatewaysModifier, banksModifier } from './modifier';
import { BiqUserPreview } from '../../../../../components/common/sidepanels';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { hasAccess } from '../../../../../logic/services/acl';
import to from '../../../../../logic/utilities/to';
import withConfirmSidepanel from './sidepanels/Confirmation';
import AddMethod from './sidepanels/AddMethod';
import BankForm from './sidepanels/BankForm';
import appRoutes from '../../../../../components/App/Router/appRoutes';

 
const text = {
  PAGE_TITLE: gettext('Payment gateways'),
  GATEWAYS: gettext('Gateways'),
  ACQUIRING_BANKS: gettext('Acquiring banks'),
  CREATE_NEW_BANK: gettext('Add new bank'),
  SUBMIT_ERROR: gettext('Error while updating bank'),
  GATEWAY_UPDATE_SUCCESS: gettext('Gateway successfully updated'),
  GATEWAY_UPDATE_ERROR: gettext('Error while updating gateway'),
  BANK_UPDATE_SUCCESS: gettext('Bank successfully updated'),
  BANK_UPDATE_ERROR: gettext('Error while updating bank'),
  BANK_DELETE_SUCCESS: gettext('Bank successfully deleted'),
  BANK_DELETE_ERROR: gettext('Error while deleting bank'),
  REMOVE_METHOD_SUCCESS: gettext('Link between payment method and gateway successfully removed'),
  REMOVE_METHOD_ERROR: gettext('Error while trying to remove link between payment method and gateway'),
  confrimPortalText: {
    TITLE: gettext('Disable gateway for client portal'),
    QUESTION: gettext(`
      Are you sure that you want to make the Gateway unavailable? 
      This action will cause that the Gateway will not be available 
      for payments initiated through Client portal.
    `),
  },
  confrimLinkText: {
    TITLE: gettext('Remove link'),
    QUESTION: gettext('Are you sure you want to remove link between payment method and gateway?'),
  },
  confrimDeleteBankText: {
    TITLE: gettext('Delete bank'),
    QUESTION: gettext('Are you sure you want to delete acquiring bank?'),
  },
};

const sidepanels = {
  CONFIRM_PORTAL_SIDEPANEL: 'CONFIRM_PORTAL_SIDEPANEL',
  CONFIRM_REMOVE_LINK: 'CONFIRM_REMOVE_LINK_SIDEPANEL',
  CONFIRM_DELETE_BANK: 'CONFIRM_DELETE_BANK_SIDEPANEL',
  ADD_METHOD_FORM: 'ADD_METHOD_FORM',
  GATEWAY_BANK_FORM: 'GATEWAY_BANK_FORM',
};

const ConfirmPortal = withConfirmSidepanel(sidepanels.CONFIRM_PORTAL_SIDEPANEL, 
  { text: text.confrimPortalText });
const ConfirmLink = withConfirmSidepanel(sidepanels.CONFIRM_REMOVE_LINK, 
  { text: text.confrimLinkText });
const ConfirmDeleteBank = withConfirmSidepanel(sidepanels.CONFIRM_DELETE_BANK, 
  { text: text.confrimDeleteBankText });

const bread = [{ label: text.PAGE_TITLE, route: appRoutes.SETTINGS_PAYMENT_GATEWAYS }];

const reducerKey = 'PAYMENT_GATEWAYS';

const apiPaymentGateways = getDjangoApi('payment_gateways');
const apiMethods = getDjangoApi('autocomplete/payment_methods');

const gatewaysConfig = () => ({
  reducerKey,
  apiUrl: 'payment_gateways',
  tableKey: 'GATEWAYS',
});


const banksConfig = id => ({
  reducerKey,
  apiUrl: `payment_gateways/${id}/acquirers`,
  tableKey: 'BANKS',
});


const propTypes = {
  actions: object.isRequired,
  dispatch: func.isRequired,
};

class PaymentGateways extends Component {
  constructor(props) {
    super(props);

    const { actions } = props;

    this.isWhitelabelEnabled = isFeatureEnabled()('WHITELABEL');

    this.GatewaysGrid = withTable(GridTab, gatewaysConfig, null, { withActions: true })();

    const commonActions = {
      onBiqUserPreview: actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };

    this.gatewaysTableActions = {
      ...commonActions,
      onBOToggle: hasAccess('payment_gateways.update') && this.onBOToggle,
      onPortalToggle: hasAccess('payment_gateways.update') && this.onPortalToggle,
      onDebitCurrencyConversionToggle: hasAccess('payment_gateways.update') && this.onDebitCurrencyConversionToggle,
      onMethodClear: hasAccess('payment_gateways.methods.*') && this.onMethodClear,
      onMethodAdd: hasAccess('payment_gateways.methods.*') && this.onMethodAdd,
    };

    this.banksTableActions = {
      ...commonActions,
      onModify: hasAccess('payment_gateways.acquirers.update') && this.onModifyBank,
      onRemove: hasAccess('payment_gateways.acquirers.destroy') && this.onRemoveBank,
      onBankToggle: hasAccess('payment_gateways.acquirers.update') && this.onBankToggle,
    };

    this.state = {
      active: gatewaysConfig().tableKey,
      gateways: [],
      selectedGateway: null,
    };
  }

  onLoadBanks = () => {
    this.setActiveTab(banksConfig().tableKey);
    this.fetchBankGateways();
  }

  onCreateBank = () => {
    const { selectedGateway } = this.state;
    const { actions } = this.props;
    actions.openSidepanel(sidepanels.GATEWAY_BANK_FORM, { gateway: selectedGateway });
  }

  onModifyBank = (bank) => {
    const { selectedGateway } = this.state;
    const { actions } = this.props;
    actions.openSidepanel(sidepanels.GATEWAY_BANK_FORM, { bank, gateway: selectedGateway });
  }

  onRemoveBank = async (bank) => {
    const { actions } = this.props;
    actions.openSidepanel(sidepanels.CONFIRM_DELETE_BANK, { bank });
  }

  onBankToggle = async (bank, checked) => {
    const { selectedGateway } = this.state;
    const [err] = await to(apiPaymentGateways
      .one(selectedGateway.id)
      .all('acquirers')
      .updatePart(bank.id, { is_active: checked }));

    if (err) {
      notifier.error(gettext(err.data.message));
    } else {
      notifier.success(text.BANK_UPDATE_SUCCESS);
      this.fetchBanksTable();
    }
  }

  onChangeGateway = selectedGateway => this.setState({ selectedGateway })

  onBOToggle = async (gateway, checked) => {
    this.gatewayToggleSubmit('is_active_for_bo')({ gateway, checked });
  }

  onPortalToggle = (gateway, checked) => {
    const { actions } = this.props;
    if (!checked) {
      actions.openSidepanel(sidepanels.CONFIRM_PORTAL_SIDEPANEL, { gateway, checked });
    } else {
      this.gatewayToggleSubmit('is_active_for_clients')({ gateway, checked });
    }
  }

  onDebitCurrencyConversionToggle = (gateway, checked) => {
    this.gatewayToggleSubmit('debit_currency_conversion')({ gateway, checked });
  }

  onMethodAdd = (gateway) => {
    const { actions } = this.props;
    actions.openSidepanel(sidepanels.ADD_METHOD_FORM, { gateway });
  }

  onMethodClear = (gateway, name) => {
    const { actions } = this.props;
    actions.openSidepanel(sidepanels.CONFIRM_REMOVE_LINK, { gateway, name });
  }

  setActiveTab = (active) => {
    this.setState({ active });
  }

  submitClearMethod = async ({ gateway, name }) => {
    const [methodsErr, methodsRes] = await to(apiMethods.list({ name }));
    if (methodsErr) {
      notifier.error(text.REMOVE_METHOD_ERROR);
    } else {  
      const { data } = methodsRes;
      const payment_method = _.get(data, 'results[0].id');
      const [gatewayErr] = await to(apiPaymentGateways
        .one(gateway.id)
        .all('methods')
        .create({ payment_method, action: 'unlink' }));
      if (!gatewayErr) {
        notifier.success(text.REMOVE_METHOD_SUCCESS);
        this.fetchGatewaysTable();
      } else {
        notifier.error(text.REMOVE_METHOD_ERROR);
      }
    } 
  }

  submitDeleteBank = async ({ bank }) => {
    const { selectedGateway } = this.state;
    const [err] = await to(apiPaymentGateways
      .one(selectedGateway.id)
      .all('acquirers')
      .destroy(bank.id));

    if (err) {
      notifier.error(text.BANK_DELETE_ERROR);
    } else {
      notifier.success(text.BANK_DELETE_SUCCESS);
      this.fetchBanksTable();
    }
  }

  gatewayToggleSubmit = key => async ({ gateway: { id }, checked }) => {
    const [err] = await to(apiPaymentGateways.updatePart(id, { [key]: checked }));
    if (err) {
      notifier.error(text.GATEWAY_UPDATE_ERROR);
    } else {
      notifier.success(text.GATEWAY_UPDATE_SUCCESS);
      this.fetchGatewaysTable();
    }
  }

  fetchBankGateways = () => {
    apiPaymentGateways.list({ name: 'bank' }).then((res) => {
      const { data: { results } } = res;
      const gateways = this.isWhitelabelEnabled ? results.filter(({ whitelabel }) => whitelabel !== 'All') : results;

      this.setState({
        gateways,
        selectedGateway: gateways && gateways[0],
      });
    });
  }

  fetchGatewaysTable = () => {
    const { dispatch } = this.props;
    const [, gatewaysTableActions] = this.GatewaysGrid;
    dispatch(gatewaysTableActions.fetchTableData());
  }

  fetchBanksTable = () => {
    const { dispatch } = this.props;
    const [, banksTableActions] = this.BanksGrid;
    dispatch(banksTableActions.fetchTableData());
  }

  createNewBank = onClick => () => (
    hasAccess('payment_gateways.acquirers.create') && (
      <Button onClick={onClick}>
        {text.CREATE_NEW_BANK}
      </Button>
    )
  )

  renderBanksTable = () => {
    const { selectedGateway } = this.state;

    if (selectedGateway && selectedGateway.id !== this.selectedGatewayId) {
      this.selectedGatewayId = selectedGateway.id;
      this.BanksGrid = withTable(GridTab, banksConfig, null, { withActions: true })(selectedGateway.id);
    }

    const [BanksTable] = this.BanksGrid || [];
    return BanksTable;
  }

  render() {
    const { active, gateways, selectedGateway } = this.state;
    const [GatewaysTable] = this.GatewaysGrid;
    const BanksTable = this.renderBanksTable();

    const headerActions = active === gatewaysConfig().tableKey 
      ? null
      : hasAccess('payment_gateways.acquirers.create') && this.createNewBank(this.onCreateBank);

    return (
      <Page active={active} headerActions={headerActions} bread={bread} title={text.PAGE_TITLE}>
        <Tabs>
          <Tab 
            visible={hasAccess('payment_gateways.list')}
            title={text.GATEWAYS} 
            onLoad={() => this.setActiveTab(gatewaysConfig().tableKey)}
          >
            <GatewaysTable
              singleActions
              modifier={gatewaysModifier(this.gatewaysTableActions)}
            />
          </Tab>
          <Tab 
            visible={hasAccess('payment_gateways.acquirers.list')}
            title={text.ACQUIRING_BANKS} 
            onLoad={this.onLoadBanks}
          >
            <Select
              clearable={false}
              placeholder={text.SELECT_WHITELABEL}
              onChange={this.onChangeGateway}
              options={gateways}
              value={selectedGateway}
              valueKey="id"
              labelKey="whitelabel"
            />
            {BanksTable && (
              <BanksTable 
                singleActions 
                modifier={banksModifier(this.banksTableActions)}
              />
            )}
          </Tab>
        </Tabs>
        <BiqUserPreview />
        <ConfirmPortal onSubmit={this.gatewayToggleSubmit('is_active_for_clients')} />
        <ConfirmLink onSubmit={this.submitClearMethod} />
        <ConfirmDeleteBank onSubmit={this.submitDeleteBank} />
        <AddMethod onSuccess={this.fetchGatewaysTable} />
        <BankForm onSuccess={this.fetchBanksTable} />
      </Page>
    );
  }
}

PaymentGateways.propTypes = propTypes;

export default PaymentGateways;
