import React from 'react';
import { DatePickerRange } from 'tc-biq-design-system';


import { gettext } from '../../../logic/utilities/languageUtility';

const DatePickerRangeAll = props => (
  <DatePickerRange 
    predefinedRange
    predefinedRangeAdditions={{
      ranges: {
        all: {
          startDate: moment(0),
          endDate: moment(),
        },
      },
      localization: {
        all: gettext('Display all'),
      },
    }}
    {...props}
  />
);

export default DatePickerRangeAll;
