import { createAction, handleActions } from 'redux-actions';

const actionTypes = {
  FETCH_DETAILS: 'FETCH_DETAILS',
  FETCH_DETAILS_PENDING: 'FETCH_DETAILS_PENDING',
  FETCH_DETAILS_REJECTED: 'FETCH_DETAILS_REJECTED',
  FETCH_DETAILS_FULFILLED: 'FETCH_DETAILS_FULFILLED',
};

export const fetchSignupDetails = createAction(actionTypes.FETCH_DETAILS, async (api) => {
  const { data } = await api.list();
  return data.results;
});

const initialState = {
  details: [],
  fetchInProgress: false,
  errors: null,
};

const fetchPending = state => ({ ...state, fetchInProgress: true });

const fetchRejected = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  errors: payload.data,
});

const fetchFulfilled = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  details: payload,
});

export default handleActions(
  {
    [actionTypes.FETCH_DETAILS_PENDING]: fetchPending,
    [actionTypes.FETCH_DETAILS_FULFILLED]: fetchFulfilled,
    [actionTypes.FETCH_DETAILS_REJECTED]: fetchRejected,
  },
  initialState,
);
