import { compose } from 'redux'; 

import connect from '../../../../logic/connect';
import CountriesTable, { reducerKey, tableConfig } from './CountriesTable';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const mapStateToProps = ({ pages }) => ({
  table: pages[reducerKey].tables[tableConfig().tableKey],
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const CountriesPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(CountriesTable); 

export default {
  component: CountriesPage,
  aclId: 'countries.list',
  path: appRoutes.SETTINGS_COUNTRIES,
};

