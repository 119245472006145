import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { Modal, Col, Row, notifier } from 'tc-biq-design-system';
import { object, func } from 'prop-types';

import overlayActions from '../../../../components/overlay';
import { ADD_LEAD_MODAL_ID } from '../AddLeads/AddLeads';
import connect from '../../../../logic/connect';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import { gettext } from '../../../../logic/utilities/languageUtility';
import FormFactory, { FormActionsFactory } from '../../../../components/form';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import isFeatureEnabled from '../../../../logic/filters/is-feature-enabled';
import { formErrorHandler, formatRequestData, Field } from '../../../../components/form/logic/utils';

const propTypes = {
  actions: object.isRequired,
  modal: object.isRequired,
  fetchTableData: func.isRequired,
  values: object.isRequired,
};

const { environment } = window.config;
const FORM_ID = 'ADD_LEAD_SINGLE_FORM';
export const leadsApi = getDjangoApi('leads');
const clientsApi = id => getDjangoApi(`team/profile/${id}/clients`);

const text = {
  TITLE: gettext('Add new lead'),
  SAVE: gettext('Add'),
  SUCCESS: gettext('Lead is successfully added'),
  ERROR: gettext('Failed to add lead'),
  FIRST_NAME: gettext('First name'),
  LAST_NAME: gettext('Last name'),
  EMAIL: gettext('Email'),
  TELEPHONE: gettext('Telephone'),
  COUNTRY: gettext('Country'),
  CITY: gettext('City'),
  ADDR_LINE_1: gettext('Address line 1'),
  ADDR_LINE_2: gettext('Address line 2'),
  ZIP: gettext('Zip'),
  CURRENCY: gettext('Currency'),
  TAGS: gettext('Tags'),
  WHITELABEL: environment === 'ICMCapital' ? gettext('Company') : gettext('Whitelabel'),
  LANGUAGE: gettext('Language'),
  TAG_ID: gettext('Tag ID'),
  ASSIGN_TO: gettext('Assign to'),
  CAMPAIGN: gettext('Campaign'),
};

const { create } = FormActionsFactory(FORM_ID);

const formRenderer = formFields => (
  <Row>
    <Col md="50%">
      {formFields.first_name}
      {formFields.email}
      {formFields.country_iso_code}
      {formFields.addr_street}
      {formFields.addr_zip}
      {formFields.tags}
      {formFields.language}
      {environment === 'AvaTrade' && formFields.cage}
      {isFeatureEnabled()('SINGLE_LEAD_CAMPAIGN_FIELD') && formFields.campaign}
    </Col>
    <Col md="50%">
      {formFields.last_name}
      {formFields.telephone}
      <Field label={text.CITY} placeholder={text.CITY}>{formFields.addr_city}</Field>
      {formFields.addr_line_2}
      {formFields.currency}
      {environment === 'AvaTrade' || isFeatureEnabled()('WHITELABEL') ? formFields.whitelabel : null}
      {formFields.tag_id}
      {environment === 'ICMCapital' && formFields.assign_to}
    </Col>
  </Row>
);

const formModifier = {
  first_name: { label: text.FIRST_NAME },
  last_name: { label: text.LAST_NAME },
  email: { label: text.EMAIL },
  country_iso_code: { label: text.COUNTRY },
  addr_street: { label: text.ADDR_LINE_1 },
  addr_line_2: { label: text.ADDR_LINE_2 },
  addr_zip: { label: text.ZIP },
  tags: { label: text.TAGS },
  currency: { label: text.CURRENCY },
  language: { label: text.LANGUAGE },
  whitelabel: { label: text.WHITELABEL, placeholder: text.WHITELABEL },
  assign_to: { label: text.ASSIGN_TO },
};

class AddLeadSingle extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  onClose = (success) => {
    const { fetchTableData } = this.props;
    if (success) fetchTableData();
    this.actions.closeModal(ADD_LEAD_MODAL_ID);
  }

  onSubmit = () => {
    const { values } = this.props;
    this.actions.create(leadsApi, this.formatRequest).then((response) => {
      this.onClose(true);

      const teamMemberId = values.assign_to && values.assign_to.id;
      const teamMemberFullName = values.assign_to && values.assign_to.full_name;
      const leadId = response.value.response.id;
      const isICM = environment === 'ICMCapital';

      if (teamMemberId && leadId && isICM) {
        const request = {
          team_profile: teamMemberId,
          user: leadId,
        };

        clientsApi(teamMemberId).create(request).then(() => {
          notifier.success(gettext(`User assigned to team member ${teamMemberFullName}`));
        });
      }

      notifier.success(text.SUCCESS);
    }, formErrorHandler(text.ERROR));
  }

  getFormConfig = () => ({
    form: FORM_ID,
    api: leadsApi,
    customFields: [
      {
        id: 'campaign',
        name: 'campaign',
        type: 'select',
        label: text.CAMPAIGN,
        placeholder: text.CAMPAIGN,
        loadOptions: this.loadOptions,
        debounceInterval: 500,
        async: true,
        joinValues: true,
        labelKey: 'name',
        valueKey: 'id',
      },
      {
        id: 'assign_to',
        name: 'assign_to',
        type: 'select',
        label: text.ASSIGN_TO,
        placeholder: text.ASSIGN_TO,
        loadOptions: this.loadOptionsUsers,
        debounceInterval: 500,
        async: true,
        joinValues: true,
        labelKey: 'username',
        valueKey: 'id',
      },
      {
        id: 'tag_id',
        name: 'tag_id',
        type: 'text',
        label: text.TAG_ID,
        placeholder: text.TAG_ID,
      },
    ],
  })

  formatRequest = (values) => {
    const data = formatRequestData(values);
    if (values.campaign) data.campaign = values.campaign.name;
    return data;
  }

  loadOptions = (input = '') => getDjangoApi('autocomplete/campaigns')
    .list({ name: input }).then(({ data }) => data.results);

  loadOptionsUsers = (input = '') => getDjangoApi('autocomplete/bo-users')
    .list({ username: input }).then(({ data }) => data.results);

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.getFormConfig());
    }
    const { FormComponent } = this;
    return <FormComponent modifiers={formModifier} renderForm={formRenderer} />;
  }

  render() {
    const { modal } = this.props;
    return (
      <Modal
        type="success"
        icon="Plus" 
        title={text.TITLE}
        visible={modal.visible}
        onConfirm={this.onSubmit}
        onCancel={this.onClose}
        size="large"
        onCloseIconClick={this.onClose}
        confirmText={text.SAVE}
        formId={FORM_ID}
        closable
      >
        {this.renderForm()}
      </Modal>
    );
  }
}

const mapStateToProps = ({ overlays, forms }) => {
  const modal = overlays[ADD_LEAD_MODAL_ID];
  return { modal, ...forms.ADD_LEAD_SINGLE_FORM };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    closeModal: overlayActions.close,
    create,
  }, dispatch),
});

AddLeadSingle.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary(AddLeadSingle));
