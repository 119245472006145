import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import ResetPasswordForm, { FORM_KEY } from './ResetPasswordForm';
import connect from '../../../../logic/connect';
import { FormActionsFactory } from '../../../../components/form';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';

const formActions = FormActionsFactory(FORM_KEY);

const mapStateToProps = ({ forms }) => ({ ...forms[FORM_KEY] });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...formActions }, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(ResetPasswordForm); 
