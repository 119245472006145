import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';
import { generateBackendId, getBackendId } from './utils';

const actionTypes = {
  FETCH_BACKENDS: 'FETCH_BACKENDS',
  FETCH_BACKENDS_FULFILLED: 'FETCH_BACKENDS_FULFILLED',
  FETCH_BACKENDS_PENDING: 'FETCH_BACKENDS_PENDING',
  FETCH_BACKENDS_REJECTED: 'FETCH_BACKENDS_REJECTED',
  SUBMIT_IB_APPROVAL: 'SUBMIT_IB_APPROVAL',
  SUBMIT_IB_APPROVAL_FULFILLED: 'SUBMIT_IB_APPROVAL_FULFILLED',
  SUBMIT_IB_APPROVAL_PENDING: 'SUBMIT_IB_APPROVAL_PENDING',
  SUBMIT_IB_APPROVAL_REJECTED: 'SUBMIT_IB_APPROVAL_REJECTED',
  SUBMIT_BACKEND_CHANGE: 'SUBMIT_BACKEND_CHANGE',
  SUBMIT_BACKEND_CHANGE_FULFILLED: 'SUBMIT_BACKEND_CHANGE_FULFILLED',
  SUBMIT_BACKEND_CHANGE_PENDING: 'SUBMIT_BACKEND_CHANGE_PENDING',
  SUBMIT_BACKEND_CHANGE_REJECTED: 'SUBMIT_BACKEND_CHANGE_REJECTED',
};

const types = {
  CTRADER: 'ctrader',
  CLOUDTRADE: 'cloudtrade',
  DXTRADE: 'dx_trade',
};

// We done't have an id that can be compared with selected groups
// so we have to use combination of group name and currency
const hybridId = group => group.name + group.currency;

const setSelectedGroup = editedGroupHybridIds => (group) => {
  const editedGroup = !_.isEmpty(editedGroupHybridIds)
    && editedGroupHybridIds.find(editedGroup => editedGroup.id === hybridId(group));

  return {
    ...group,
    selected: !_.isEmpty(editedGroupHybridIds)
      ? editedGroupHybridIds.some(editedGroup => editedGroup.id === hybridId(group))
      : group.is_fallback,
    ibId: !!editedGroup && editedGroup.ibId,
  };
};

const generateCurrencies = (currencies, groups, type, editedGroupHybridIds) => {
  const useGroups = !(type === types.CTRADER || type === types.CLOUDTRADE || type === types.DXTRADE);
  const hasGroup = currency => !currency.groups || (currency.groups && currency.groups.length > 0);

  return currencies
    .map(currency => ({
      name: currency,
      ...(useGroups && {
        groups: groups
          .filter(group => group.currency === currency)
          .map(setSelectedGroup(editedGroupHybridIds)),
      }),
    }))
    .filter(hasGroup);
};

const generateGroups = (groups, type, editedGroupHybridIds) => {
  if (type !== types.CTRADER) return null;

  return groups.map(setSelectedGroup(editedGroupHybridIds));
};

/**
 * Fetch backend action
 */
export const fetchBackends = createAction(
  actionTypes.FETCH_BACKENDS,
  async (user, edit = false) => {
    const headers = isFeatureEnabled()('WHITELABEL') && user.whitelabel 
      ? { whitelabel: user.whitelabel } 
      : {};

    // Fetch selected values only in edit mode
    const [defaultBackends, options, editedBackends] = await Promise.all([
      getDjangoApi('trading_backends').list({ limit: 100 }, headers),
      getDjangoApi(`ib/${user.id}/approve`).options(),
      edit && getDjangoApi(`ib/${user.id}/trading_backends`).list({ limit: 100 }),
    ]);

    // Use hased selected backends for faster selection
    const hashedBackends = editedBackends
      && editedBackends.data.results.reduce((acc, item) => ({ ...acc, [generateBackendId(item)]: item }), {});

    const approveOptions = options.data.actions.POST.fields;
    const approveOptionsTpiData = _.find(approveOptions, { key: 'tpi_data' }).child.children.fields;
    const approveOptionsAvailableCurrencies = _.find(approveOptionsTpiData, { key: 'currencies' });
    const availableCurrencies = approveOptionsAvailableCurrencies.choices.map(({ value }) => value);

    // Prepare backend data
    return defaultBackends.data.results.reduce((backends, item) => {
      const { backend, groups, whitelabel } = item;
      if (backend.hidden) return backends;
      const editedBackend = hashedBackends && hashedBackends[generateBackendId(item)];
      const editedGroupHybridIds = (editedBackend
          && editedBackend.groups.reduce(
            (acc, group) => [...acc, { id: hybridId(group), ibId: group.id }],
            [],
          ))
        || [];
      const generatedGroups = generateGroups(groups, backend.type, editedGroupHybridIds);
      const generatedBackend = {
        id: generateBackendId(item),
        name: backend.name,
        type: backend.type,
        selected: edit ? !!editedBackend : true,
        currencies: generateCurrencies(availableCurrencies, groups, backend.type, editedGroupHybridIds),
        ibId: editedBackend ? editedBackend.id : null,
        ...(whitelabel ? {
          group: whitelabel,
          whitelabel,
        } : {}),
        ...(!_.isEmpty(generatedGroups) && { groups: generatedGroups }),
      };
      
      return [...backends, generatedBackend];
    }, []);
  },
);

const formatAddBackendRequest = (backend) => {
  // This is ctrader case where we have top level groups
  let groups = backend.groups && backend.groups.filter(group => group.selected);
  let currencies = [];
  if (groups) {
    // select only first because if cannot have more than two fallbacks.
    groups = groups && [groups[0]];
    currencies = backend.currencies.map(currency => currency.name);
  } else {
    // if we dont have top level groups we should use groups from currencies
    // eslint-disable-next-line no-lonely-if
    if (backend.type.toLowerCase() === types.CLOUDTRADE
    || backend.type.toLowerCase() === types.DXTRADE) {
      groups = [];
      currencies = backend.currencies.map(currency => currency.name);
    } else {
      groups = backend.currencies
        && backend.currencies.reduce((acc, currency) => {
          const [selected] = currency.groups.filter(group => group.selected);
          if (selected) return [...acc, selected];
          return acc;
        }, []);
      currencies = groups.filter(group => group.selected).map(group => group.currency);
    }
  }

  // set default fallback true to every selected group
  groups = groups && groups.map(group => ({ ...group, is_fallback: true }));

  if (types.CLOUDTRADE === backend.type || types.DXTRADE === backend.type) groups = [];

  if (types.CTRADER === backend.type) {
    groups = groups.map(({ name }) => ({
      is_fallback: true,
      name,
      currency: null,
    }));
  }

  return {
    backend: getBackendId(backend.id),
    groups,
    currencies,
    whitelabel: backend.whitelabel,
  };
};

const getAllGroups = (backend) => {
  let { groups } = backend;
  if (!groups) {
    groups = backend.currencies
      && backend.currencies.flatMap(currency => currency.groups);
  }

  return groups && groups.map(group => ({ ...group, is_fallback: true }));
};

export const submitApproval = createAction(
  actionTypes.SUBMIT_IB_APPROVAL,
  async (user, backends) => {
    const api = getDjangoApi(`ib/${user.id}/approve`);
    const filterBackends = backend => backend.selected;
    const filterValidTpis = tpi => tpi.currencies.length > 0;

    const tpi_data = backends.filter(filterBackends)
      .map(formatAddBackendRequest)
      .filter(filterValidTpis);
    
    await api.create({ tpi_data });
  },
);

/**
 * Submit backend change action
 */
export const submitChange = createAction(
  actionTypes.SUBMIT_BACKEND_CHANGE,
  async (user, backends) => {
    const promises = [];
    
    backends.forEach((backend) => {
      const backendGroupsApi = getDjangoApi(`ib/${user.id}/trading_backends/${backend.ibId}/groups`);
      const backendsApi = getDjangoApi(`ib/${user.id}/trading_backends`);

      if (!backend.selected && backend.ibId) {
        // BACKEND IS REMOVED
        promises.push(backendsApi.destroy(backend.ibId));
      } else if (backend.selected && !backend.ibId) {
        // BACKEND IS ADDED
        const request = formatAddBackendRequest(backend);
        promises.push(backendsApi.create(request));// check if we should use tpi_data
      } else if (backend.selected && backend.ibId) {
        // BACKEND UPDATED

        // update currencies
        const { currencies } = formatAddBackendRequest(backend);
        const groups = getAllGroups(backend);
        
        promises.push(backendsApi.updatePart(backend.ibId, { currencies }));

        // NOTE a default backend should be set first before removing a default backend, thats why adding groups
        // need to succeed as a promise before deleting starts
        const addedGroups = [];
        const removedGroups = [];
        
        groups.forEach((group) => {
          if (group.selected && !group.ibId) {
            addedGroups.push(group);
          } else if (!group.selected && group.ibId) {
            removedGroups.push(group);
          }
        });

        const addedGroupsPromises = [];
        addedGroups.forEach((group) => {
          addedGroupsPromises.push(backendGroupsApi.create(group));
        });

        const addingGroupsRequest = Promise.all(addedGroupsPromises).then((result) => {
          removedGroups.forEach((group) => {
            promises.push(backendGroupsApi.destroy(group.ibId));
          });
          return result;
        });

        promises.push(addingGroupsRequest);
      }
    });

    await Promise.all(promises);
  },
);

const initialState = {
  backends: [],
  fetchInProgress: false,
  fetchErrors: null,
  approvalInProgress: false,
  approvalErrors: null,
  changeInProgress: false,
  changeErrors: null,
};

const fetchFulfilledHandler = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  error: null,
  backends: payload,
});

const fetchRejectedHandler = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  errors: payload.data,
});

export default handleActions(
  {
    [actionTypes.FETCH_BACKENDS_PENDING]: state => ({ ...state, fetchInProgress: true }),
    [actionTypes.FETCH_BACKENDS_FULFILLED]: fetchFulfilledHandler,
    [actionTypes.FETCH_BACKENDS_REJECTED]: fetchRejectedHandler,
    [actionTypes.SUBMIT_IB_APPROVAL_PENDING]: state => ({ ...state, approvalInProgress: true }),
    [actionTypes.SUBMIT_IB_APPROVAL_FULFILLED]: state => ({ ...state, approvalInProgress: false }),
    [actionTypes.SUBMIT_IB_APPROVAL_REJECTED]: (state, { payload }) => ({
      ...state,
      approvalInProgress: false,
      approvalErrors: payload,
    }),
    [actionTypes.SUBMIT_BACKEND_CHANGE_PENDING]: state => ({ ...state, changeInProgress: true }),
    [actionTypes.SUBMIT_BACKEND_CHANGE_FULFILLED]: state => ({ ...state, changeInProgress: false }),
    [actionTypes.SUBMIT_BACKEND_CHANGE_REJECTED]: (state, { payload }) => ({
      ...state,
      changeInProgress: false,
      changeErrors: payload,
    }),
  },
  initialState,
);
