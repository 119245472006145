/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Tabs, Tab } from 'tc-biq-design-system';

import connect from '../../../../logic/connect';
import Page from '../../../../components/Page';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import overlayActions from '../../../../components/overlay';
import text from './text';
import Flow, { CreateNewFlow, actions as flowActions, tableKey as flowKey } from './Tabs/Flow';
import Methods, { CreateNewMethod, actions as methodsActions, tableKey as methodsKey } from './Tabs/Methods';
import Reasons, { CreateNewReason, actions as reasonsActions, tableKey as reasonsKey } from './Tabs/Reasons';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const bread = [{ label: text.PAGE_TITLE, route: appRoutes.SETTINGS_WITHDRAWALS }];

class Withdrawals extends Component {
  constructor(props) {
    super(props);

    this.getHeaderActions = this.getHeaderActions.bind(this);
    this.state = { active: flowKey };
  }

  getHeaderActions() {
    const { active } = this.state;
    const { props } = this;
    const { openSidepanel } = props.pageActions;

    if (active === flowKey) return CreateNewFlow;
    if (active === methodsKey) return CreateNewMethod;

    return () => <CreateNewReason openSidepanel={openSidepanel} />;
  }

  setActiveTab(active) {
    this.setState({ active });
  }

  render() {
    return (
      <Page bread={bread} headerActions={this.getHeaderActions()} title={text.PAGE_TITLE}>
        <Tabs>
          <Tab visible={hasAccess('withdrawal_flow.settings.list')} title={text.FLOW} onLoad={() => this.setActiveTab(flowKey)}>
            <Flow {...this.props} />
          </Tab>

          <Tab visible={hasAccess('settings.manage_withdrawal_methods.list')} title={text.METHODS} onLoad={() => this.setActiveTab(methodsKey)}>
            <Methods {...this.props} />
          </Tab>

          <Tab visible={hasAccess('withdrawal_rejection_reasons.settings.list')} title={text.REASONS} onLoad={() => this.setActiveTab(reasonsKey)}>
            <Reasons {...this.props} />
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  flowActions: bindActionCreators({ deleteRow: flowActions.deleteRow }, dispatch),
  methodsActions: bindActionCreators({
    updateRowData: methodsActions.updateRowData,
    deleteRow: methodsActions.deleteRow,
  }, dispatch),
  reasonsActions: bindActionCreators({ updateRowData: reasonsActions.updateRowData }, dispatch),
  pageActions: bindActionCreators({ openSidepanel: overlayActions.open }, dispatch),
});

const WithdrawalsPage = compose(
  withUnmountMethod,
  connect(null, mapDispatchToProps),
  withErrorBoundary,
)(Withdrawals);

export default {
  component: WithdrawalsPage,
  aclFn: () => hasAccess('withdrawal_flow.settings.list') || hasAccess('settings.manage_withdrawal_methods.list'),
  path: appRoutes.SETTINGS_WITHDRAWALS,
};
