const CONFIG = window.config;

console.log('google analytic active');
if (window.top.document.getElementById('googleAnalytics') === null) {
  const a = window.top.document.getElementsByTagName('head')[0]; const 
    b = window.top.document.createElement('script');
  b.type = 'text/javascript';
  b.id = 'googleAnalytics';
  const code = '(function (i, s, o, g, r, a, m) {\n'
    + '        i[\'GoogleAnalyticsObject\'] = r; i[r] = i[r] || function () {\n'
    + '            (i[r].q = i[r].q || []).push(arguments)\n'
    + '        }, i[r].l = 1 * new Date(); a = s.createElement(o),\n'
    + '        m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)\n'
    + '    })(window, document, \'script\', \'//www.google-analytics.com/analytics.js\', \'ga\');';
  b.appendChild(document.createTextNode(code));
  a.appendChild(b);
}

if (window.ga && CONFIG.ga_tracker) {
  window.ga('create', CONFIG.ga_tracker, 'auto');
  window.ga('set', 'dimension4', 'BO3');
}
