import hideCols from '../../../../../../logic/utilities/hideCols';
import { ActionsCellWrapper } from '../../../../../../components/gridCellRenderers';
import { actionIcons } from '../../../../../../components/grid/GridUtils';

export default ({ modify, remove }) => {
  const actions = {
    ...(modify && { modify }),
    ...(remove && { remove }),
  };
  
  return {
    ...hideCols(['id']),
    min_value: {
      width: 100,
    },
    max_value: {
      width: 100,
    },
    actions: {
      label: '',
      field: 'actions',
      key: 'actions',
      pinned: 'right',
      hiddenSortIcon: true,
      width: actions.modify && actions.remove ? 100 : 50,
      suppressSizeToFit: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(modify, remove)),
    },
  };
};
