import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { bool, number, object } from 'prop-types';

import ActivityBody from './ActivityBody';
import AcitivityStreamFilters from './ActivityStreamFilters';
import activityStreamActions from './Model/activityActions';
import connect from '../../../../logic/connect';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import { AddComment } from './sidepanels';
import { TransactionSingle, WithdrawalRequest,
  TradingAccount, ViewTask, CreateEditTask, UserPreview, BiqUserPreview } from '../../../../components/common/sidepanels';
import DocumentPreview from '../../../../components/common/modals/DocumentPreview';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import overlayActions from '../../../../components/overlay';
import { types as viewTaskType } from '../../../../components/common/sidepanels/ViewTask/ViewTask';

import './ActivityStream.scss';

const propTypes = {
  firstLevelQuery: object.isRequired,
  actions: object.isRequired,
  userId: number,
  boUserId: number,
  query: object,
  style: object,
  withManuallyLog: bool,
};

const defaultProps = {
  userId: null,
  boUserId: null,
  query: null,
  style: null,
  withManuallyLog: false,
};

const { loadActivity, setFirstLevelQuery, resetOffset, updateNewActivityCount, resetActivityStream } = activityStreamActions;
const HOST = window.config.api.host;
const getFirstLevelQuery = (userId, boUserId, query) => {
  let firstLevelQuery = {};
  if (userId) firstLevelQuery = { user_id: userId, ...query };
  if (boUserId) firstLevelQuery = { bo_user_id: boUserId, ...query };
  return firstLevelQuery;
};

class ActivityStream extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.ws = new WebSocket(`${HOST.replace('https', 'wss')}/ws/activity-notifications/`);
    // WebSocket for notifications BIT-14
    this.ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.activity) {
        this.ws.send(JSON.stringify({ users: data.users }));
      }
      this.actions.updateNewActivityCount(data);
    };
  }

  componentDidMount() {
    const { userId, boUserId, query } = this.props;
    this.actions.setFirstLevelQuery(getFirstLevelQuery(userId, boUserId, query));
  }

  componentDidUpdate(prevProps) {
    const { firstLevelQuery } = this.props;
    if (!_.isMatch(prevProps.firstLevelQuery, firstLevelQuery) || _.isEmpty(firstLevelQuery)) {
      this.actions.loadActivity();
    } 
  }

  componentWillUnmount() {
    this.ws.close();
    this.actions.resetActivityStream();
  }

  openSingleTransaction = async (id) => {
    const response = await getDjangoApi('payment_transactions').retrieve(id);
    this.actions.openSidepanel('TRANSACTION_SINGLE', response.data);
  }

  render() {
    const { userId, boUserId, query, withManuallyLog, style } = this.props;
    return (
      <div className="activity-stream">
        <div className="activity-stream__wrapper">
          <AcitivityStreamFilters
            firstLevelQuery={getFirstLevelQuery(userId, boUserId, query)}
            withManuallyLog={withManuallyLog}
          />
          <ActivityBody userId={userId} boUserId={boUserId} style={style} />
          <AddComment />
          <TransactionSingle />
          <WithdrawalRequest openTransactionSingle={this.openSingleTransaction} />
          <TradingAccount />
          <UserPreview />
          <BiqUserPreview />
          <DocumentPreview onSuccess={() => null} />
          <ViewTask type={viewTaskType.EDIT} onSuccess={this.actions.loadActivity} />
          <CreateEditTask onSuccess={this.actions.loadActivity} />
        </div>
      </div>
    );
  }
}

ActivityStream.propTypes = propTypes;
ActivityStream.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loadActivity,
      updateNewActivityCount,
      setFirstLevelQuery,
      resetOffset,
      resetActivityStream,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const { firstLevelQuery } = pages.ACTIVITY_STREAM.activities;
  return { firstLevelQuery };
};

export default withUnmountMethod(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(ActivityStream)));
