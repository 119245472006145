import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { object, string, array } from 'prop-types';
import { notifier } from 'tc-biq-design-system';

import connect from '../../../../../../logic/connect';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import { fetchKycData, revalidate } from './Model';
import KycAccordion from './KycAccordion';
import { hasAccess } from '../../../../../../logic/services/acl';
import { fetchUser } from '../../Model';

import './KYC.scss';

const propTypes = {
  actions: object.isRequired,
  id: string.isRequired,
  data: array,
  checksInProgress: array,
  user: object.isRequired,
};

const defaultProps = {
  data: [],
  checksInProgress: [],
};

const text = {
  ERROR: gettext('Error while revalidating KYC step'),
  MARK_AS_FAILED_ERROR: gettext('Error while marking as failed'),
  CONTINUE_VALIDATION_ERROR: gettext('Error while continuing validation'),
};

class KYC extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    
    this.api = getDjangoApi(`users/${props.id}/kyc`);

    this.revalidate = this.revalidate.bind(this);
    this.kycContinueValidation = this.kycContinueValidation.bind(this);
    this.kycMarkAsFailed = this.kycMarkAsFailed.bind(this);
  }

  componentDidMount() {
    this.actions.fetchKycData(this.api);
  }

  refreshUser = () => {
    const { user } = this.props;
    const isIb = false;
    this.actions.fetchUser(user, isIb);
  }
  
  revalidate(kycId) {
    const { id } = this.props;
    const api = getDjangoApi(`users/${id}/kyc/${kycId}/re_validate`);

    this.actions.revalidate(api, kycId)
      .then(({ value }) => {
        notifier.success(value.data.msg);
        this.actions.fetchKycData(this.api);
        this.refreshUser();
      }, () => notifier.error(text.ERROR));
  }

  kycContinueValidation(keyId) {
    const { id } = this.props;
    const api = getDjangoApi(`users/${id}/kyc/${keyId}/continue_validation`);

    api.create()
      .then(({ data }) => {
        notifier.success(data.msg);
        this.refreshUser();
        this.actions.fetchKycData(this.api);
      }, () => notifier.error(text.CONTINUE_VALIDATION_ERROR));
  }

  kycMarkAsFailed(keyId) {
    const { id } = this.props;
    const api = getDjangoApi(`users/${id}/kyc/${keyId}/mark_failed`);

    api.create()
      .then(({ data }) => {
        notifier.success(data.msg);
        this.refreshUser();
        this.actions.fetchKycData(this.api);
      }, () => notifier.error(text.MARK_AS_FAILED_ERROR));
  }

  render() {
    const { data, checksInProgress } = this.props;
    if (!data.length) return null;

    return (
      <div className="biq-kyc">
        {data.map(kyc => (
          <KycAccordion 
            validationInProgress={checksInProgress.includes(kyc.id)} 
            revalidate={hasAccess('user.kyc.re_validate') ? this.revalidate : null} 
            kycContinueValidation={hasAccess('user.kyc.continue_validation.*') ? this.kycContinueValidation : null}
            kycMarkAsFailed={hasAccess('user.kyc.mark_failed.*') ? this.kycMarkAsFailed : null}
            kycCheck={kyc} 
            key={kyc.id}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const { user } = page.userDetail;
  return {
    ...pages.USER_SINGLE.KYC,
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchKycData, revalidate, fetchUser }, dispatch),
});

KYC.propTypes = propTypes;
KYC.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(KYC);
