import React from 'react';
import { Tooltip, Icon, Popconfirm } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import hideCols from '../../../../../logic/utilities/hideCols';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { ToggleCell, ActionsCellWrapper, RouteCell, UsernameCell } from '../../../../../components/gridCellRenderers';
import appRoutes from '../../../../../components/App/Router/appRoutes';
import queryOperators from '../../../../../logic/enums/query-operators';


const text = {
  EDIT: gettext('Edit'),
  CLONE: gettext('Clone'),
  DELETE: gettext('Delete'),
  CANCEL: gettext('Cancel'),
  ARE_YOU_SURE: gettext('Are you sure you want to delete this item?'),
};

const name = href => ({ value, data, colDef }) => {
  const Component = RouteCell({
    href,
    stateParams: { id: data.id },
  });
  return Component({ value, data, colDef });
};

const campaignActionIcons = (editCampaign, onRemoveCampaign) => ({
  ...(editCampaign && {
    editCampaign: (_, { id }) => {
      const url = generatePath(appRoutes.SETTINGS_BONUS_CAMPAIGN, { id });
      return (
        <Tooltip title={text.EDIT} key="edit">
          <Link to={url}>
            <Icon name="Edit" />
          </Link>
        </Tooltip>
      );
    },
  }),
  ...(onRemoveCampaign && {
    onRemoveCampaign: (actionHandler, data) => (
      <Tooltip title={text.DELETE} key="remove">
        <Popconfirm
          type="destructive"
          icon="Delete"
          label={text.ARE_YOU_SURE}
          onConfirm={() => actionHandler(data)}
          buttonLabels={{
            cancel: text.CANCEL,
            confirm: text.DELETE,
          }}
        >
          <Icon name="Delete" />
        </Popconfirm>
      </Tooltip>
    ),
  }),
});

const reasonActionIcons = onEditReason => ({
  ...(onEditReason && {
    onEditReason: (actionHandler, data) => (
      <Tooltip title={text.EDIT} key="edit">
        <Icon onClick={() => actionHandler(data)} name="Edit" />
      </Tooltip>
    ),
  }),
});

const cancellableRulesActionIcons = (editRule, onRemoveRule) => ({
  ...(editRule && {
    editRule: (_, { id }) => {
      const url = generatePath(appRoutes.SETTINGS_BONUS_CANCELLATION_RULES, { id });
      return (
        <Tooltip title={text.EDIT} key="edit">
          <Link to={url}>
            <Icon name="Edit" />
          </Link>
        </Tooltip>
      );
    },
  }),
  ...(onRemoveRule && {
    onRemoveRule: (actionHandler, data) => (
      <Tooltip title={text.DELETE} key="remove">
        <Popconfirm
          type="destructive"
          icon="Delete"
          label={text.ARE_YOU_SURE}
          onConfirm={() => actionHandler(data)}
          buttonLabels={{
            cancel: text.CANCEL,
            confirm: text.DELETE,
          }}
        >
          <Icon name="Delete" />
        </Popconfirm>
      </Tooltip>
    ),
  }),
});

const modifier = previewBiqUser => ({
  ...hideCols(['event', 'action']),
  id: {
    width: 100,
  },
  created_by: {
    cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
    quickFilter: {
      operator: queryOperators.EXACT.value,
    },
  },
  modified_by: {
    cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
    quickFilter: {
      operator: queryOperators.EXACT.value,
    },
  },
});

const campaignModifier = ({ onToggleCampaigns, previewBiqUser, editCampaign, onRemoveCampaign }) => {  
  const actions = {
    ...(editCampaign && { editCampaign }),
    ...(onRemoveCampaign && { onRemoveCampaign }),
  };
  
  return {
    ...modifier(previewBiqUser),
    name: {
      cellRendererFramework: name(appRoutes.SETTINGS_BONUS_CAMPAIGN),
    },
    ...(onToggleCampaigns && {
      is_enabled: {
        width: 120,
        pinned: 'left',
        cellRendererFramework: ToggleCell(onToggleCampaigns),
      },
    }),
    created_by:{
      width: 250,
    },
    name: {
      width: 250,
    },
    reason: {
      // eslint-disable-next-line react/prop-types
      cellRendererFramework: ({ colDef, value }) => {
        const choices = _.get(colDef, 'definition.choices', []);
        const reason = (choices.find(option => option.value === value) || {}).display_name;
        return <span>{reason}</span>;
      },
      filterOptions: {
        operators: [queryOperators.EXACT],
      },
    },
    actions: {
      label: '',
      key: 'actions',
      hiddenSortIcon: true,
      pinned: 'right',
      cellRendererFramework: ActionsCellWrapper(actions, campaignActionIcons(editCampaign, onRemoveCampaign)),
      width: 120,
    },
  };
};

const reasonsModifier = ({ previewBiqUser, onToggleReasons, onEditReason }) => {
  const actions = {
    ...(onEditReason && { onEditReason }),
  };
  return {
    ...modifier(previewBiqUser),
    ...(onToggleReasons && {
      is_active: {
        width: 120,
        pinned: 'left',
        cellRendererFramework: ToggleCell(onToggleReasons),
      },
    }),
    actions: {
      label: '',
      key: 'actions',
      hiddenSortIcon: true,
      pinned: 'right',
      cellRendererFramework: ActionsCellWrapper(actions, reasonActionIcons(onEditReason)),
      width: 120,
    },
  };
};

const cancellationRulesModifier = ({ previewBiqUser, onToggleRule, editRule, onRemoveRule }) => {
  const actions = {
    ...(editRule && { editRule }),
    ...(onRemoveRule && { onRemoveRule }),
  };
  return {
    created_by: {
      cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
    },
    modified_by: {
      cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
    },
    ...(onToggleRule && {
      enabled: {
        width: 120,
        pinned: 'left',
        cellRendererFramework: ToggleCell(onToggleRule),
      },
    }),
    expiration_period: {
      quickFilter: {
        operator: queryOperators.EXACT.value,
      },
      headerName: gettext('Expiration period'),
    },
    actions: {
      label: '',
      key: 'actions',
      hiddenSortIcon: true,
      pinned: 'right',
      cellRendererFramework: ActionsCellWrapper(
        actions, 
        cancellableRulesActionIcons(editRule, onRemoveRule),
      ),
      width: 120,
    },
  };
};

export { campaignModifier, reasonsModifier, cancellationRulesModifier };
