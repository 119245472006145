import React from 'react';
import { Header, Breadcrumb } from 'tc-biq-design-system';
import {
  string,
  object,
  arrayOf,
  shape,
  node,
  func,
} from 'prop-types';
import { generatePath, Link, withRouter } from 'react-router-dom';

import withErrorBoundary from './hoc/withErrorBoundary';

const propTypes = {
  pageTitle: string.isRequired,
  breads: arrayOf(shape({ label: string.isRequired, route: string.isRequired })).isRequired,
  actions: node,
  style: object,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const defaultProps = {
  actions: null,
  style: {},
};

const renderBreads = (breads, history) => (
  <Breadcrumb key="pageBreadcrumb">
    {breads.map(item => (
      <Breadcrumb.Item key={item.route}>
        <Link to={generatePath(item.route || history.location.pathname)}>
          {item.label}
        </Link>
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

const PageHeader = ({ pageTitle, breads, actions, style, history }) => (
  <div style={style}>
    <Header title={pageTitle} breadcrumbs={renderBreads(breads, history)} actions={actions} />
  </div>
);

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default withRouter(withErrorBoundary(PageHeader));
