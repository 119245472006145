/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { func } from 'prop-types';
import { bindActionCreators } from 'redux';
import {
  Sidepanel,
  Row,
  Col,
  Panel,
  Icon,
  Button,
  Detail,
  Pill,
  Space,
  Spinner,
} from 'tc-biq-design-system';
import { get } from 'lodash';
import { generatePath, Link } from 'react-router-dom';

import { getDjangoApi } from '../../../../logic/services/api-factory';
import { gettext } from '../../../../logic/utilities/languageUtility';
import { thousandSeparator } from '../../../../logic/utilities/formatters/index';
import session from '../../../../logic/services/session';
import isFeatureEnabled from '../../../../logic/filters/is-feature-enabled';
import connect from '../../../../logic/connect';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import overlayActions from '../../../overlay';
import If from '../../../If';
import LoginAsUser from '../../../LoginAsUser';
// eslint-disable-next-line
import UserTags from './UserTags';
// eslint-disable-next-line
import HeaderTitle from './HeaderTitle';
import RetentionManager from './RetentionManager';
import SalesManager from './SalesManager';
import { hasAccess } from '../../../../logic/services/acl';
import appRoutes from '../../../App/Router/appRoutes';

export const SIDEPANEL_ID = 'USER_PREVIEW';

const text = {
  ASSIGN_SUCCESS: gettext('User assigned successfully'),
  SIGNIN: gettext('Sign in as user'),
  VIEW_PROFILE: gettext('View profile'),
  TOTAL_EQUITY: gettext('Total equity'),
  DEPOSITS: gettext('Deposits'),
  WITHDRAWALS: gettext('Withdrawals'),
  BASIC_INFO: gettext('Basic info'),
  ASSIGNED_TO: gettext('Assigned to'),
  WHITELABEL: gettext('Whitelabel'),
  LANGUAGE: gettext('Language'),
  ADDRESS: gettext('Address'),
  CITY_ZIP: gettext('City & ZIP'),
  COUNTRY: gettext('Country'),
  TELEPHONE: gettext('Phone No.'),
  CLIENTS: gettext('Clients'),
  TOTAL_EARNED: gettext('Total earned'),
  PAID_TO_DATE: gettext('Total paid to date'),
  BALANCE: gettext('Balance'),
};

const propTypes = {
  onUserUpdate: func,
};

const defaultProps = {
  onUserUpdate: null,
};

const renderFinanceDetails = data => data.map(({ entity, value }, index) => (
  <div key={index}>
    <Col>
      <Detail entity={entity} value={value} />
    </Col>
  </div>
));

const BasicInfoRow = ({ prop, value }) => (
  <p className="biq-user-preview__row">
    <span className="grey-text">{prop}</span>
    <span className="black-text">{value}</span>
  </p>
);

const Loader = (
  <div style={{ width: '100px', margin: '30px auto' }}>
    <Spinner size="large" />
  </div>
);

class UserPreview extends Component {
  constructor(props) {
    super(props);
    const currentParams = get(props, 'sidepanel.parameters.data');

    this.actions = props.actions;
    this.isIb = currentParams && currentParams.isIb;
    this.totalEquityEnabled = isFeatureEnabled()('TOTAL_EQUITY_BASE_CURRENCY') && hasAccess('user.total_equity.list');
    this.whitelabelEnabled = isFeatureEnabled()('WHITELABEL');

    this.modalFooter = this.modalFooter.bind(this);
    this.onAssign = this.onAssign.bind(this);
    this.onTagsUpdate = this.onTagsUpdate.bind(this);
  }

  componentDidUpdate(prevProps) {
    const previousParams = get(prevProps, 'sidepanel.parameters.data');
    const previousUser = get(prevProps, 'sidepanel.parameters.user');
    const currentParams = get(this.props, 'sidepanel.parameters.data');
    const currentUser = get(this.props, 'sidepanel.parameters.user');

    const userHasChanged = !previousParams || (currentParams && previousParams.id !== currentParams.id);
    const userReopened = !!previousParams && !currentUser && currentParams && !!previousUser && currentParams.id === previousParams.id;

    if (userHasChanged && currentParams) {
      this.isIb = currentParams.isIb;
      this.fetchUser(currentParams.id);
      this.updateLoginUrl(currentParams.id);
      if (this.totalEquityEnabled && !this.isIb) this.updateTotalEquity(currentParams.id);
      if (this.isIb) this.updateFinanceData(currentParams.id);
    } else if (userReopened) {
      this.actions.update(SIDEPANEL_ID, prevProps.sidepanel.parameters);
    }
  }

  onTagsUpdate(tags) {
    const { onUserUpdate } = this.props;

    this.actions.update(SIDEPANEL_ID, parameters => ({
      ...parameters,
      user: { ...parameters.user, tags },
    }));

    if (onUserUpdate) onUserUpdate();
  }

  onAssign(manager, managerProperty) {
    const { onUserUpdate } = this.props;

    this.actions.update(SIDEPANEL_ID, parameters => ({
      ...parameters,
      user: {
        ...parameters.user,
        [managerProperty]: manager,
      },
    }));

    if (onUserUpdate) onUserUpdate();
  }

  async updateFinanceData(id) {
    const { data } = await getDjangoApi(`ib/${id}/statistics`).list();
    this.actions.update(SIDEPANEL_ID, parameters => ({
      ...parameters,
      ibFinancialDetails: data,
    }));
  }

  async fetchUser(id) {
    const { data } = await getDjangoApi(`${this.isIb ? 'ib' : 'users'}/${id}`).list();

    this.actions.update(SIDEPANEL_ID, parameters => ({
      ...parameters, user: data,
    }));
  }

  async updateTotalEquity(id) {
    const { data } = await getDjangoApi(`users/${id}/total_equity`).list();

    this.actions.update(SIDEPANEL_ID, parameters => ({
      ...parameters,
      totalEquity: data.total_equity_base_currency,
    }));
  }

  modalFooter() {
    const parameters = get(this.props, 'sidepanel.parameters');
    if (!parameters || !parameters.loginToken || !parameters.data) return null;

    const { loginToken, data } = parameters;
    const profileUrl = generatePath(this.isIb ? appRoutes.IB_PROFILE : appRoutes.CLIENT_PROFILE, { id: data.id });

    return (
      <div>
        <If condition={session.user && session.user.is_superuser}>
          <LoginAsUser token={loginToken} isIb={this.isIb}>
            <Button onClick={null} color="ghost">
              {text.SIGNIN}
            </Button>
          </LoginAsUser>
        </If>

        <Link to={profileUrl}>
          <Button color="primary" onClick={null}>
            {text.VIEW_PROFILE}
          </Button>
        </Link>
      </div>
    );
  }

  async updateLoginUrl(id) {
    const { data } = await getDjangoApi(`${this.isIb ? 'ib' : 'users'}/${id}/login_as_url`).list();

    this.actions.update(SIDEPANEL_ID, parameters => ({
      ...parameters,
      loginToken: data.token,
    }));
  }

  render() {
    const { sidepanel } = this.props;
    const parameters = sidepanel.parameters || {};

    const { totalEquity, ibFinancialDetails, user } = parameters;

    const telephoneKey = this.isIb ? 'phone' : 'telephone';
    const addressKey = this.isIb ? 'address' : 'addr_street';
    const countryKey = this.isIb ? 'country' : 'addr_country';

    const { baseCurrency } = session;

    const totalEquityDetail = this.totalEquityEnabled && !this.isIb ? [
      { entity: text.TOTAL_EQUITY, value: `${baseCurrency.symbol} ${thousandSeparator(totalEquity) || '0.00'}` },
    ] : [];

    const ibFinance = ibFinancialDetails ? [
      { entity: text.CLIENTS, value: ibFinancialDetails.clients },
      { entity: text.TOTAL_EARNED, value: `${baseCurrency.symbol} ${thousandSeparator(ibFinancialDetails.total_earned)}` },
      { entity: text.PAID_TO_DATE, value: `${baseCurrency.symbol} ${thousandSeparator(ibFinancialDetails.total_withdrawn)}` },
      { entity: text.BALANCE, value: `${baseCurrency.symbol} ${thousandSeparator(ibFinancialDetails.balance)}` },
      { entity: text.DEPOSITS, value: `${baseCurrency.symbol} ${thousandSeparator(ibFinancialDetails.total_deposit)}` },
      { entity: text.WITHDRAWALS, value: `${baseCurrency.symbol} ${thousandSeparator(ibFinancialDetails.total_withdrawal)}` },
    ] : [];

    const clientFinance = user ? [
      ...totalEquityDetail,
      { entity: text.DEPOSITS, value: `${baseCurrency.symbol} ${thousandSeparator(user.total_deposit_base_currency)}` },
      { entity: text.WITHDRAWALS, value: `${baseCurrency.symbol} ${thousandSeparator(user.total_withdrawal_base_currency)}` },
    ] : [];

    const Title = user ? <HeaderTitle firstName={user.first_name} lastName={user.last_name} id={user.id} state={user.state} /> : '';

    const PanelTitle = (
      <div>
        <Icon name="Client" size="large" colorName="text-primary-500" />
        <span className="biq-panel-title black-text">{text.BASIC_INFO}</span>
      </div>
    );

    return (
      <Sidepanel
        type="success"
        title={Title}
        visible={sidepanel.visible}
        onCloseIconClick={() => this.actions.close(SIDEPANEL_ID)}
        footerRender={this.modalFooter}
      >
        {
          parameters && user ? (
            <div>
              <Row gutter={0}>{renderFinanceDetails(this.isIb ? ibFinance : clientFinance)}</Row>

              <Row gutter={0}>
                <Col>
                  {user && (
                    <div style={{ width: '60%' }}>
                      <SalesManager isIb={this.isIb} user={user} onAssign={this.onAssign} />
                      <Space size={16} />
                      {isFeatureEnabled()('ASSIGN_RETENTION') && (
                        <RetentionManager isIb={this.isIb} user={user} onAssign={this.onAssign} />
                      )}
                    </div>
                  )}
                </Col>
              </Row>

              <If condition={this.whitelabelEnabled}>
                <Row gutter={0}>
                  <Col>
                    <p className="black-text">{text.WHITELABEL}</p>
                    <Pill type="neutral">{user.whitelabel}</Pill>
                  </Col>
                </Row>
              </If>

              <Row gutter={0}>
                <Col><UserTags onUpdate={this.onTagsUpdate} user={user} /></Col>
              </Row>

              <Row gutter={0}>
                <Col>
                  <Panel style={{ margin: '0' }} title={PanelTitle}>
                    <div style={{ padding: '16px' }}>
                      <BasicInfoRow prop={text.LANGUAGE} value={user.language} />
                      <BasicInfoRow prop={text.ADDRESS} value={user[addressKey]} />
                      <BasicInfoRow prop={text.CITY_ZIP} value={`${user.addr_city}, ${user.addr_zip}`} />
                      <BasicInfoRow prop={text.COUNTRY} value={get(user[countryKey], 'country_name')} />
                      <BasicInfoRow prop={text.TELEPHONE} value={user[telephoneKey]} />
                    </div>
                  </Panel>
                </Col>
              </Row>
            </div>
          ) : Loader
        }
      </Sidepanel>
    );
  }
}

UserPreview.propTypes = propTypes;
UserPreview.defaultProps = defaultProps;

const mapStateToProps = ({ overlays }) => ({ sidepanel: overlays[SIDEPANEL_ID] });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    close: overlayActions.close,
    update: overlayActions.update,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(UserPreview));
