import React from 'react';
import { Input } from 'tc-biq-design-system';
import { func, object, string } from 'prop-types';
import { GatewayFactory } from './logic/gateway';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import {
  isPayoutExpired,
  isPayoutFullyUtilized,
  isRefundExpired,
  isRefundFullyUtilized,
} from './logic/utils';


const text = {
  TOTAL: gettext('Total'),
};

class EditableCell extends React.Component {
  handleChange = (e) => {
    const { onChange, name, data } = this.props;
    const { value } = e.target;

    const updatedRow = { ...data, [name]: value };
    onChange(updatedRow);
  }

  isDisabled(data) {
    const { name } = this.props;
    const params = {
      isRefundFullyUtilized: isRefundFullyUtilized(data),
      isPayoutFullyUtilized: isPayoutFullyUtilized(data),
      isPayoutExpired: isPayoutExpired(data),
      isRefundExpired: isRefundExpired(data),
    };

    return GatewayFactory(data.gateway).isDisabled(name, params);
  }

  render() {
    const { name, type, data } = this.props;

    return (
      <Input
        size="small"
        key={`${data.id}-input`}
        name={name}
        value={data[name]}
        type={type}
        disabled={this.isDisabled(data)}
        onChange={this.handleChange}
      />
    );
  }
}

EditableCell.propTypes = {
  data: object.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  type: string.isRequired,
};

export default ({ onChange, is_automatic_withdrawal_allowed, payoutSum, refundSum }) => ({
  id: {
    label: 'Id',
    sortValue: 1,
    width: '200px',
    render: data => data.id,
    pinnedRowCellRenderer: () => <strong> {`${text.TOTAL}: ${payoutSum + refundSum}` } </strong>,
  },
  refund_acquirer_external_id: {
    label: 'Refund acquirer id',
    sortValue: 2,
    hide: is_automatic_withdrawal_allowed,
    render: data => (
      <EditableCell
        name="refund_acquirer_external_id"
        data={data}
        type="text"
        onChange={onChange}
      />
    ),
  },
  refund_amount: {
    label: 'Refundable amount',
    sortValue: 3,
    editable: true,
    render: data => (
      <EditableCell
        name="refund_amount"
        type="number"
        data={data}
        onChange={onChange}
      />
    ),
  },
  payout_acquirer_external_id: {
    label: 'Payout acquirer id',
    hide: is_automatic_withdrawal_allowed,
    sortValue: 4,
    render: data => (
      <EditableCell
        name="payout_acquirer_external_id"
        type="text"
        data={data}
        onChange={onChange}
      />
    ),
  },
  payout_amount: {
    label: 'Payoutable amount',
    sortValue: 5,
    render: data => (
      <EditableCell
        name="payout_amount"
        type="number"
        data={data}
        onChange={onChange}
      />
    ),
  },
  left_for_refund: {
    render: data => data.left_for_refund,
    label: 'Left for  refund',
    sortValue: 6,
  },
  left_for_payout: {
    render: data => data.left_for_payout,
    label: 'Left for payout',
    sortValue: 7,
  },
  max_refund: {
    render: data => data.max_refund,
    label: 'Max refund',
    sortValue: 8,
  },
  max_payout: {
    render: data => data.max_payout,
    label: 'Max payout',
    sortValue: 9,
  },
  previous_refunds: {
    render: data => data.previous_refunds,
    label: 'Previous refunds',
    sortValue: 10,
  },
  previous_payouts: {
    render: data => data.previous_payouts,
    label: 'Previous payouts',
    sortValue: 11,
  },
});
