/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../logic/connect';
import Filters from '../../../../../../components/Filters';
import { TradingAccount, UserPreview } from '../../../../../../components/common/sidepanels';
import { getActiveFilters } from '../../../../../../components/grid/GridUtils';
import overlayActions from '../../../../../../components/overlay';
import DocumentPreview from '../../../../../../components/common/modals/DocumentPreview';
import UploadDocument from './Overlays/UploadDocument';
import modifier from './modifier';
import UploadButton from './UploadButton';
import states from './states';

import './Documents.scss';
const env = window.config.environment;
const text = {
  PREVIEW_TITLE: gettext('Preview document'),
  REVIEW_TITLE: gettext('Review document'),
  APPROVE_TITLE: gettext('Approve document'),
  APPROVE_TEXT: gettext('Are you sure you want to approve this document?'),
  APPROVE_SUCCESS: gettext('Document approved successfully'),
  APPROVE_ERROR: gettext('Error while approving document'),
  REJECT_TITLE: gettext('Reject document'),
  REJECT_TEXT: gettext('Are you sure you want to reject this document?'),
  REJECT_SUCCESS: gettext('Document rejected successfully'),
  REJECT_ERROR: gettext('Error while rejecting document'),
  DELETE_SUCCESS: gettext('Document deleted successfully'),
  DELETE_ERROR: gettext('Error while deleting document'),
  IGNORE_SUCCESS: gettext('Document ignored successfully'),
  IGNORE_ERROR: gettext('Error while ignoring document'),
  CLEAR_SUCCESS: gettext("Document's state cleared successfully"),
  CLEAR_ERROR: gettext('Error while clearing document state'),
  UPLOAD_DOCUMENT: gettext('Upload document'),
  ONFIDO_SUCCESS: gettext('Onfido check successfully started'),
  GBG_SUCCESS:gettext('GBG check successfully started'),
  ONFIDO_ERROR: gettext('Error while starting Onfido check'),
  GBG_ERROR: gettext('Error while starting GBG check.'),
};

class Documents extends Component {
  constructor(props) {
    super(props);
    this.actions = { ...props.actions, ...props.sectionActions };
    this.isOnfidoEnabled = isFeatureEnabled()('ONFIDO');
    this.isDocumentClearStatusEnabled = isFeatureEnabled()('DOCUMENT_CLEAR_STATUS');

    this.tableActions = {
      previewUser: props.sectionActions.openOverlay.bind(this, 'USER_PREVIEW'),
      preview: this.preview,
      review: this.reviewDoc,
      undo: this.isDocumentClearStatusEnabled && this.undo,
      ignore: this.ignore,
      deleteDocument: this.deleteDocument.bind(this),
      onfidoCheck: this.onfidoCheck,
    };
  }

  async componentDidMount() {
    const { table, isIb, userTradingAccounts } = this.props;
    const tableModifier = modifier(this.tableActions, userTradingAccounts, this.openTradingAccountSidepanel);
    const { value } = await this.actions.fetchOptions(tableModifier);
    const fields = _.get(value, 'options.actions.GET.fields');
    const usageChoices = _.find(fields, { key: 'usage' }).choices;
    const kycValue = _.find(usageChoices, { display_name: 'KYC' }).value;
    this.actions.fetchTableData({ ...table.query, ...(!isIb ? { usage__exact: kycValue } : {}) });
  }

  onfidoCheck = (id) => {
    const api = getDjangoApi(`documents/${id}/start_check`);

    api.create({ force_recheck: false }).then(
      () => {
        notifier.success(env==='ICMCapital' ? text.GBG_SUCCESS : text.ONFIDO_SUCCESS);
        this.actions.fetchTableData();
      },
      () => {
        notifier.error(env === 'ICMCapital' ? text.GBG_ERROR : text.ONFIDO_ERROR);
      },
    );
  };

  getApprovalApi = id => getDjangoApi(`documents/${id}`).all('approve');

  getClearApi = id => getDjangoApi(`documents/${id}`).all('clear_status');

  preview = ({ data }) => {
    const content = { title: text.PREVIEW_TITLE, icon: 'View' };
    this.actions.openOverlay('PREVIEW_DOCUMENT', { ...data, content });
  };

  openTradingAccountSidepanel = async (accountId) => {
    const { id } = this.props;
    const response = await getDjangoApi(`users/${id}/trading_accounts/${accountId}`).list();
    this.actions.openOverlay('TRADING_ACCOUNT', response.data);
  }

  deleteDocument = ({ id }) => {
    getDjangoApi(`documents/${id}`)
      .destroy()
      .then(
        () => {
          notifier.success(text.DELETE_SUCCESS);
          this.actions.fetchTableData();
        },
        () => {
          notifier.error(text.DELETE_ERROR);
        },
      );
  };

  undo = ({ id }) => {
    this.getClearApi(id)
      .create()
      .then(
        () => {
          notifier.success(text.CLEAR_SUCCESS);
          this.actions.fetchTableData();
        },
        () => {
          notifier.error(text.CLEAR_ERROR);
        },
      );
  }

  ignore = ({ id }) => {
    this.getApprovalApi(id)
      .create({ status: states.IGNORED })
      .then(
        () => {
          notifier.success(text.IGNORE_SUCCESS);
          this.actions.fetchTableData();
        },
        () => {
          notifier.error(text.IGNORE_ERROR);
        },
      );
  }

  reviewDoc = (parameters) => {
    const content = {
      icon: 'View',
      title: text.REVIEW_TITLE,
    };

    const { sectionActions } = this.props;

    sectionActions.openOverlay('PREVIEW_DOCUMENT', {
      ...parameters,
      review: true,
      onReject: ({ id, rejection_reason }) => this.getApprovalApi(id).create({ status: states.REJECTED, rejection_reason }),
      onApprove: ({ id }) => this.getApprovalApi(id).create({ status: states.APPROVED }),
      onPreApprove: ({ id }) => this.getApprovalApi(id).create({ status: states.PRE_APPROVED }),
      content,
    });
  }

  uploadButton = () => (
    <UploadButton onClick={() => this.actions.openOverlay('UPLOAD_DOCUMENT')}>
      {text.UPLOAD_DOCUMENT}
    </UploadButton>
  )

  render() {
    const { GridComponent, fields, actions, table, id, userTradingAccounts, isIb, hideSegments } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      hideSegments,
      tableModifier: modifier(this.tableActions, userTradingAccounts, this.openTradingAccountSidepanel),
    };

  
    return (
      <div className="biq-profile__documents">
        <Filters {...filterProps} />
        
        <GridComponent
          preventInternalOptions
          {...this.props}
          GridActions={this.uploadButton}
          tableModifier={modifier(this.tableActions, userTradingAccounts, this.openTradingAccountSidepanel)}
          singleActions
        />

        <TradingAccount />
        <DocumentPreview onSuccess={this.actions.fetchTableData} />
        <UploadDocument id={id} onSuccess={this.actions.fetchTableData} isIb={isIb} />
        <UserPreview />
      </div>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { userTradingAccounts } = pages.USER_SINGLE.userDetail.user;
  return { ...pages.USER_SINGLE.tables.USER_DOCUMENTS, userTradingAccounts };
};

const mapDispatchToProps = dispatch => ({
  sectionActions: bindActionCreators(
    {
      openOverlay: overlayActions.open,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Documents);
