import moment from 'moment';
import { currentLanguage } from '../languageUtility';

export const toLocalDateTime = (dateTimeString, period = 'endOf') => dateTimeString 
  && moment(dateTimeString)[period]('day')
    .utc()
    .format('YYYY-MM-DD HH:mm:ss');

export const toStartLocalDateTime = dateTimeString => toLocalDateTime(dateTimeString, 'startOf');
export const toEndLocalDateTime = dateTimeString => toLocalDateTime(dateTimeString, 'endOf');
 
export default (dateTimeString, withTime = true, withTimeAgo = false) => {
  if (!dateTimeString) return null;
  const time = new Date(dateTimeString).toLocaleTimeString(currentLanguage);
  const date = new Date(dateTimeString).toLocaleDateString(currentLanguage);
  if (withTime && !withTimeAgo) {
    return `${date} ${time}`;
  }
  if (!withTime && withTimeAgo) {
    return `${date} ${moment(dateTimeString).fromNow()}`;
  }
  if (withTime && withTimeAgo) {
    return `${date} ${time} ${moment(dateTimeString).fromNow()}`;
  }
  return date;
};
