/* eslint-disable react/prop-types */
import React from 'react';
import appRoutes from '../../../../../components/App/Router/appRoutes';
import { InfoCell, OnContentClickCell, RouteCell } from '../../../../../components/gridCellRenderers';
import hideCols from '../../../../../logic/utilities/hideCols';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { isRefundAndPayoutExpired } from './logic/utils';

// eslint-disable-next-line no-nested-ternary
const ifBoolToText = info => (info === true ? gettext('Yes') : info === false ? gettext('No') : info);

const renderInfoItem = (label, info) => <div style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden' }}>{gettext(label)} - {ifBoolToText(info) || 'N/A'}</div>;

const getInfoContentEl = (cell) => {
  const { data } = cell;
  const { payout_info = {}, refund_info = {} } = data;
  const { gateway_allows_payout, max_payout, payout_expired, payout_fully_utilized, previous_payouts } = payout_info;
  const { gateway_allows_refund, max_refund, previous_refunds, refund_expired, refund_fully_utilized } = refund_info;

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1, textAlign: 'left', marginRight: '5px' }}>
        {renderInfoItem('Max payout', max_payout)}
        {renderInfoItem('Previous payouts', previous_payouts)}
        {renderInfoItem('Payout fully utilized', payout_fully_utilized)}
        {renderInfoItem('Gateway allows payout', gateway_allows_payout)}
        {renderInfoItem('Payout expired', payout_expired)}
      </div>
      <div style={{ flex: 1, textAlign: 'left' }}>
        {renderInfoItem('Max refund', max_refund)}
        {renderInfoItem('Previous refunds ', previous_refunds)}
        {renderInfoItem('Refund fully utilized', refund_fully_utilized)}
        {renderInfoItem('Gateway allows refund', gateway_allows_refund)}
        {renderInfoItem('Refund expired', refund_expired)}
      </div>
    </div>
  );
};

export default ({ openTransaction }) => ({
  ...hideCols(['refund_expired', 'payout_expired', 'refund_info', 'payout_info']),
  id: {
    cellRendererFramework: OnContentClickCell(openTransaction),
    sortable: false,
  },
  user: {
    cellRendererFramework: RouteCell({ href: appRoutes.CLIENT_PROFILE }),
    sortable: false,
  },
  value: {
    cellRendererFramework: ({ data, value }) => (!isRefundAndPayoutExpired(data) 
      ? <span>{value}</span> 
      : null),
    sortable: false,
  },
  currency: {
    sortable: false,
  },
  gateway: {
    sortable: false,
  },
  acquirer: {
    sortable: false,
  },
  date: {
    sortable: false,
  },
  info: {
    label: '',
    field: 'info',
    key: 'info',
    pinned: 'left',
    width: 40,
    sortable: false,
    cellRendererFramework: cell => InfoCell({ children: getInfoContentEl(cell) }),
  },
});
