import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import { loadTeamOptions, loadTeam } from '../../../team/Team/Model';
import MemberAcls from './MemberAcls';
import { updateTeamMember } from '../Model';

const mapStateToProps = ({ pages }) => ({ ...pages.TEAM.team, ...pages.TEAM_PROFILE.profile });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    updateTeamMember, 
    loadTeamOptions,
    loadTeam,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberAcls);
