import React from 'react';
import { Icon, Popconfirm, Tooltip } from 'tc-biq-design-system';
import { gettext } from '../../../../logic/utilities/languageUtility';
import { ActionsCellWrapper } from '../../../../components/gridCellRenderers';
import hideCols from '../../../../logic/utilities/hideCols';

const text = {
  EDIT: gettext('Edit'),
  DELETE: gettext('Delete'),
  POPCONFIRM_LABEL: gettext('Are you sure you want to delete exchange rate?'),
  NO: gettext('No'),
  YES: gettext('Yes'),
  ACTIONS: gettext('Actions'),
};

const deleteIcon = (actionHandler, data) => (
  <Tooltip title={text.DELETE} placement="top" key={data.id}>
    <Popconfirm
      type="destructive"
      icon="Delete"
      onConfirm={() => actionHandler(data)}
      label={text.POPCONFIRM_LABEL}
      buttonLabels={{ cancel: text.NO, confirm: text.YES }}
    >
      <Icon name="Delete" />
    </Popconfirm>
  </Tooltip>
);

const exchangeRatesModifier = ({ onDelete }) => (
  {
    ...hideCols(['id']),
    actions: {
      pinned: 'right',
      label: 'Actions',
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper({ onDelete }, { onDelete: deleteIcon }),
      width: 80,
    },
  });

export { exchangeRatesModifier };
