import React from 'react';
import classnames from 'classnames';
import { string, shape, arrayOf, func } from 'prop-types';

import './SelectEvent.scss';

const optionShape = {
  display_name: string,
  value: string,
};

const propTypes = {
  value: shape(optionShape),
  options: arrayOf(shape(optionShape)),
  onChange: func,
};

const defaultProps = {
  value: null,
  options: [],
  onChange: () => {},
};

const SelectEvent = ({ value, onChange, options }) => (
  <div className="select-event">
    {options.map(option => (
      <div 
        key={option.value} 
        onClick={() => onChange(option)}
        className={classnames(
          'tc-paragraph-strong',
          'select-event__event', 
          { 
            'select-event__event--active': value && option.value === value.value,
          })}
      >
        {option.display_name}
      </div>
    ))}
  </div>
);

SelectEvent.propTypes = propTypes;
SelectEvent.defaultProps = defaultProps;

export default SelectEvent;
