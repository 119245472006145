import React from 'react';
import { HyperLink } from 'tc-biq-design-system';
import { string, object } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

const propTypes = {
  value: string.isRequired,
  data: object.isRequired,
  colDef: object.isRequired,
};

// eslint-disable-next-line
const RouteCell = ({ href, stateParams }) => ({ value, data, colDef }) => {
  const { definition } = colDef; // eslint-disable-line
  const { type } = definition; // eslint-disable-line
  const model = type === 'nested relation' ? data[colDef.field] : null; // eslint-disable-line
  const id = model ? model.id : value;
  // This fix problem with tagged string returned from BE
  // <method-wrapper '__str__' of NoneType object at 0x8f5320>
  if (!id || /<[a-z][\s\S]*>/i.test(id)) return <div />;
  const url = generatePath(href, { id, ...stateParams });
  return (
    <HyperLink>
      <Link to={url}>{value}</Link>
    </HyperLink>
  );
};

RouteCell.propTypes = propTypes;

export default RouteCell;
