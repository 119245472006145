import deposits from './Desposits';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../../logic/services/acl';

export default {
  ...deposits,
  key: 'deposits',
  label: gettext('Deposits'),
  isVisible: () => hasAccess('dashboard.net_deposits.*') && hasAccess('dashboard.overall_deposits.*'),
};
