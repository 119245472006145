import React from 'react';
import { ListItem, Avatar, Space, Pill, HyperLink } from 'tc-biq-design-system';
import { array, bool, func } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import userStateFilter from '../../logic/filters/user-state';
import USER_STATE from '../../logic/enums/user-state';
import appRoutes from '../App/Router/appRoutes';

const propTypes = {
  clients: array,
  hideUserState: bool,
  action: func,
  isIb: bool,
};

const defaultProps = {
  clients: null,
  hideUserState: false,
  action: null,
  isIb: false,
};

const getInitials = (fullName) => {
  const splitedName = fullName.split(' ');
  const formattedName = splitedName.length === 1 ? splitedName.slice(0, 2)[0] : `${splitedName[0].charAt(0)}${splitedName[1].charAt(1)}`;
  return formattedName.toUpperCase();
};

const getPillType = (state) => {
  switch (state) {
    case USER_STATE.FULL:
    case USER_STATE.APPROVED_IB:
      return 'status01';
    case USER_STATE.UNSUITABLE_DEMO:
      return 'status03';
    case USER_STATE.DEMO:
      return 'status02';
    case USER_STATE.REJECTED:
    case USER_STATE.REJECTED_IB:
      return 'status04';
    default:
      return 'primary';
  }
};

const renderClients = (clients, hideUserState, action, isIb) => clients.map((client) => {
  const displayName = client.user ? client.user.full_name : client.username;

  return (
    <HyperLink key={client.id} style={{ display: 'block' }}>
      <Link to={generatePath(isIb ? appRoutes.IB_PROFILE : appRoutes.CLIENT_PROFILE, { id: client.id })}>
        <ListItem>
          <Avatar initials={getInitials(displayName)} />
          <Space horizontal size={8} />
          <span>{displayName}</span>
          <Space horizontal size={8} />
          {hideUserState ? null : (
            <Pill type={getPillType(client.user.state)}>{userStateFilter(client.user.state)}</Pill>
          )}
          {action ? action(client) : null}
        </ListItem>
      </Link>
    </HyperLink>
  );
});

const ClientsList = ({ clients, hideUserState, action, isIb }) => {
  if (!clients) return null;
  return renderClients(clients, hideUserState, action, isIb);
};

ClientsList.propTypes = propTypes;
ClientsList.defaultProps = defaultProps;

export default ClientsList;
