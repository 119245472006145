/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import Page from '../../../../components/Page';
import Export from '../../../../components/Export';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import If from '../../../../components/If';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const pageConfig = {
  apiUrl: 'instruments',
  reducerKey: 'INSTRUMENTS',
  tableKey: 'INSTRUMENTS_TABLE',
};

const text = {
  TITLE: gettext('Instruments'),
};

const { GridComponent, actions } = GridFactory(pageConfig);

const breads = [{ label: text.TITLE, route: appRoutes.TRADE_INSTRUMENTS }];

class Instruments extends Component {
  constructor(props) {
    super(props);

    this.headerActions = this.headerActions.bind(this);
  }

  headerActions() {
    const { exportUrl } = this.props;
    return (
      <If condition={hasAccess('trade.instruments.export.*')}>
        <Export url={exportUrl} />
      </If>
    );
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
    };
    return (
      <Page headerActions={this.headerActions} bread={breads} title={text.TITLE}>
        <Filters {...filterProps} />
        <GridComponent {...this.props} tableModifier={{}} />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(Instruments); 
