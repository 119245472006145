import PendingWithdrawals from './PendingWithdrawals';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../../logic/services/acl';

export default {
  ...PendingWithdrawals(),
  key: 'pending-withdrawals',
  label: gettext('Pending withdrawals'),
  description: gettext('Pending withdrawals originated in the selected time period'),
  isVisible: () => hasAccess('dashboard.pending_withdrawals.*'),
};
