import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionTypes from '../types';
import SendSMS from './SendSMS';

const text = {
  LABEL: gettext('Send SMS'),
};

const ACTION_ICON = 'SingleChat';

export default {
  name: 'sendSms',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.TOP,
  hasPermission(_state, user, isIb) {
    if (isIb) {
      return !user.is_archived && hasAccess('ib.messages.create');
    } 
    return hasAccess('user.sms.create') && hasAccess('user.sms.send_sms');
  },
  handler: (_sidepanelManager, _dispatch, user, _isIb, comboxManager) => {
    const comboxOptions = {
      title: text.LABEL,
      id: 'send-sms-combox',
      user,
    };

    comboxManager.add(SendSMS, comboxOptions);
  },
};
