import {
  YesNoCell,
  OnContentClickCell,
  UsernameCell,
  BackendModeCell,
  TagsCell,
} from '../../../components/gridCellRenderers';

const modifier = ({ previewUser, openTradingAccount }) => ({
  id: {
    cellRendererFramework: OnContentClickCell(openTradingAccount),
    width: 120,
  },
  user: {
    cellRendererFramework: UsernameCell(previewUser),
    width: 200,
  },
  is_third_party: {
    cellRendererFramework: YesNoCell,
    quickFilter: {},
  },
  mode: {
    cellRendererFramework: BackendModeCell,
  },
  tags: {
    cellRendererFramework: TagsCell,
  },
  cash_balance: {
    quickFilter: {},
    filterOptions: {
      disableFilter: true,
    },
  },
});

export default modifier;
