import React, { Component } from 'react';
import { Pill, Select } from 'tc-biq-design-system';
import { object, array } from 'prop-types';

import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

import './SelectSource.scss';
import { hasAccess } from '../../../../../../../logic/services/acl';

const text = {
  USERS: gettext('BO Users'),
  TEAMS: gettext('Teams'),
  REMOVE_ALL: gettext('Remove all'),
};

const SelectedSource = ({ users, teams, onClose }) => {
  const usersArr = users ? Object.keys(users).map(key => ({ ...users[key] })) : [];
  const teamsArr = teams ? Object.keys(teams).map(key => ({ ...teams[key] })) : [];
  const sources = [...usersArr, ...teamsArr];
  return !_.isEmpty(sources) && sources.map(source => (
    <div key={source.id} className="select-source__selected-item">
      <Pill
        key={source.id}
        onIconClick={() => onClose(source)}
        icon="Close"
        big
      >
        { source.name ? source.name : source.full_name }
      </Pill>
    </div>
  ));
};

const propTypes = {
  actions: object.isRequired,
  selectedUsers: array.isRequired,
  userOptions: array.isRequired,
  selectedTeams: array.isRequired,
  teamOptions: array.isRequired,
};

class SelectSource extends Component {
  constructor(props) {
    super(props);

    this.loadUserOptions = _.debounce(this.loadOptions(true), 500);
    this.loadTeamOptions = _.debounce(this.loadOptions(), 500);
  }

  async componentDidMount() {
    this.loadUserOptions();
    this.loadTeamOptions();
  }

  onSourceChange = (source) => {
    if (source) {
      const isUser = !!source.username;
      const { options, selected, setSelected, setOptions } = this.getSourceModel(isUser);
      const sources = [...selected, source];
      const filtered = this.filterSource(options, sources);
      
      setSelected(sources);
      setOptions(filtered);
    }
  }

  onRemoveAll = () => {
    const { actions, userOptions, teamOptions } = this.props;
    const enableItems = item => ({ ...item, disabled: false });
    actions.setSelectedUsers([]);
    actions.setSelectedTeams([]);
    actions.setUserOptions(userOptions.map(enableItems));
    actions.setTeamOptions(teamOptions.map(enableItems));
  }

  onRemoveSource = (source) => {
    const isUser = !!source.username;
    const { selected, options, setSelected, setOptions } = this.getSourceModel(isUser);

    const newSelected = selected.filter(item => item.id !== source.id);
    const newOptions = options.map(item => ({ ...item, ...(item.id === source.id && { disabled: false }) }));
    
    setSelected(newSelected);
    setOptions(newOptions);
  }

  getSourceModel = (isUser) => {
    const { 
      actions: { 
        setUserOptions, 
        setTeamOptions,
        setSelectedUsers,
        setSelectedTeams, 
      }, 
      userOptions, 
      teamOptions,
      selectedUsers,
      selectedTeams,
    } = this.props;
    const setOptions = isUser ? setUserOptions : setTeamOptions;
    const setSelected = isUser ? setSelectedUsers : setSelectedTeams;
    const options = isUser ? userOptions : teamOptions;
    const selected = isUser ? selectedUsers : selectedTeams;
    const inputName = isUser ? 'userInput' : 'teamInput';
    const userApi = value => getDjangoApi('team').list({
      ...(value && { username: value }),
      type: 'live',
      limit: 10,
      offset: 0,
    }).then(res => ({
      ...res,
      data: {
        ...res.data,
        results: res.data.results.map(user => ({
          ...user,
          full_name: user.username,
        })),
      },
    }));
    const teamApi = value => getDjangoApi('teams').list({ ...(value && { name: value }), limit: 10, offset: 0 });
    const api = isUser ? userApi : teamApi;

    return { selected, options, setSelected, setOptions, inputName, api };
  }

  loadOptions = isUser => async (value = '') => {
    const { selected, setOptions, inputName, api } = this.getSourceModel(isUser);
    if (value === this[inputName]) return selected;
    this[inputName] = value;
    const res = await api(value);

    const { data: { results } } = res;
      
    const options = this.filterSource(results, selected);
    setOptions(options);
  }

  filterSource = (current, selected) => {
    const hashedSelectedItems = selected.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
    return _.isEmpty(hashedSelectedItems) ? current : current.map(item => ({ ...item, disabled: hashedSelectedItems[item.id] }));
  }
  
  render() {
    const { selectedUsers, selectedTeams, userOptions, teamOptions } = this.props;
    return (
      <div className="select-source">
        <div className="select-source__form">
          {hasAccess('team.list') && (
            <div className="select-source__users">
              <Select
                type="search"
                name="users"
                options={userOptions}
                placeholder={text.USERS}
                valueKey="id"
                labelKey="full_name"
                onChange={this.onSourceChange}
                onInputChange={this.loadUserOptions}
              />
            </div>
          )}
          {hasAccess('teams.list') && (
            <div className="select-source__teams">
              <Select
                type="search"
                name="teams"
                options={teamOptions}
                placeholder={text.TEAMS}
                valueKey="id"
                labelKey="name"
                onChange={this.onSourceChange}
                onInputChange={this.loadTeamOptions}
              />
            </div>
          )}
        </div>
        <div className="select-source__selected">
          <SelectedSource 
            teams={selectedTeams} 
            users={selectedUsers} 
            onClose={this.onRemoveSource}
          />
          {(!_.isEmpty(selectedUsers) || !_.isEmpty(selectedTeams)) 
            && <a onClick={this.onRemoveAll} className="select-source__remove">{text.REMOVE_ALL}</a>}
        </div>
                
      </div>
    );
  }
}

SelectSource.propTypes = propTypes;

export default SelectSource;
