import React from 'react';
import { string, func, object, oneOfType, bool } from 'prop-types';
import { Upload, Space } from 'tc-biq-design-system';
import classnames from 'classnames';

import './Upload.scss';
import { gettext } from '../../../../../logic/utilities/languageUtility';

const text = {
  SELECTED: gettext('Selected file:'),
};

const propTypes = {
  id: string.isRequired,
  onChange: func.isRequired,
  value: oneOfType([string, object]).isRequired,
  label: string.isRequired,
  hasError: bool.isRequired,
  helpText: string,
};

const defaultProps = {
  helpText: null,
};

const UploadField = (props) => {
  const { onChange, id, value, label, hasError, helpText } = props;
  return (
    <div className={classnames({ 'upload-field--has-error': hasError })}>
      <label htmlFor="upload" className="upload-field__label tc-paragraph-regular">{label}</label>
      <Upload id="upload" onDrop={file => onChange({ value: file[0], id })} />
      {value && (
        <div className="upload-field__selected">
          <span className="tc-paragraph-strong upload-field__name">{text.SELECTED}</span>
          <span className="tc-paragraph-regular upload-field__name">{value.name}</span>
        </div>
      )}
      {hasError && <p className="tc-micro-regular upload-field__help">{helpText}</p>}
      <Space size={12} />
    </div>
  );
};

UploadField.propTypes = propTypes;
UploadField.defaultProps = defaultProps;
export default UploadField;
