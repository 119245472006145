import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { object, func, oneOf } from 'prop-types';

import TaskComponent from './TaskComponent';
import { gettext } from '../../../../logic/utilities/languageUtility';
import overlayActions from '../../../overlay/index';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import connect from '../../../../logic/connect';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import to from '../../../../logic/utilities/to';
import { SidepanelFooter } from '../../index';
import withUnmountMethod from '../../../hoc/withUnmountMethod';
import taskStateLabels from '../../../../logic/enums/task-state-label';

export const types = {
  DEFAULT: 'default',
  EDIT: 'edit',
};

export const SIDEPANEL_ID = 'VIEW_TASK';
export const EDIT_SIDEPANEL_ID = 'CREATE_EDIT_TASK';
const apiUrl = 'tasks';

const propTypes = {
  sidepanel: object.isRequired,
  actions: object.isRequired,
  parameters: object,
  sidepanelManager: object,
  onSuccess: func,
  type: oneOf(Object.values(types)),
};

const defaultProps = {
  sidepanelManager: null,
  type: types.DEFAULT,
  onSuccess: null,
  parameters: {},
};

const text = {
  DELETED: gettext('Task successfully deleted.'),
  COMPLETED: gettext('Task successfully completed.'),
  TITLE: gettext('Preview task'),
  BUTTON_LABELS: (task, sidepanelType) => ({
    ...(sidepanelType === types.DEFAULT ? { cancel: gettext('Delete task') } : {}),
    ...(sidepanelType === types.EDIT ? { cancel: gettext('Edit') } : {}),
    ...(task.state === taskStateLabels.OPEN ? { confirm: gettext('Mark as done') } : {}),
  }),
};

const customFooter = (completeTask, deleteTask, clickEdit, task, type) => {
  const props = {
    [types.DEFAULT]: {
      close: () => deleteTask(task),
      cancelColor: 'destructive',
    },
    [types.EDIT]: {
      close: () => clickEdit(task),
    },
  };

  return (
    <SidepanelFooter
      execute={() => completeTask(task)}
      confirmColor="confirmation"
      buttonLabels={text.BUTTON_LABELS(task, type)}
      // eslint-disable-next-line react/destructuring-assignment
      {...props[type]}
    />
  );
};

class ViewTask extends Component {
  constructor(props) {
    super(props);
    this.onComplete = this.onComplete.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  async onDelete(data) {
    const { onSuccess } = this.props;
    const api = getDjangoApi(apiUrl);
    const [err] = await to(api.destroy(data.id));
    if (err) {
      notifier.error(err.data.msg);
    } else {
      notifier.success(text.DELETED);
      if (onSuccess) onSuccess();
      this.onClose();
    }
  }

  async onComplete(data) {
    const { onSuccess } = this.props;
    const api = getDjangoApi(apiUrl)
      .all('mark_as_completed');
    const [err] = await to(api.create({
      ids: [data.id],
    }));
    if (err) {
      notifier.error(err.data.msg);
    } else {
      notifier.success(text.COMPLETED);
      if (onSuccess) onSuccess();
      this.onClose();
    }
  }

  onClose = () => {
    const { actions, sidepanelManager } = this.props;
    sidepanelManager ? sidepanelManager.close() : actions.closeSidepanel(SIDEPANEL_ID);
  }

  onEditClick = (data) => {
    this.onClose();
    const { actions } = this.props;
    actions.openSidepanel(EDIT_SIDEPANEL_ID, { ...data, type: 'edit' });
  }

  getParams = () => {
    const { sidepanel, parameters } = this.props;
    return !_.isEmpty(parameters) ? parameters : sidepanel.parameters;
  }

  getType = () => {
    const { type } = this.props; 
    const task = this.getParams();
    if (_.isEmpty(task)) return types.DEFAULT;
    return task.state !== taskStateLabels.OPEN ? types.DEFAULT : type;
  }

  render() {
    const { sidepanel, ...options } = this.props;
    const task = this.getParams();
    const type = this.getType();
    if (!task) return null;
    return (
      <Sidepanel
        icon="CalendarCreate"
        type="success"
        title={task.name}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.onComplete, this.onDelete, this.onEditClick, task, type)}
        style={{ zIndex: '10000' }}
        {...options}
      >
        <TaskComponent task={task} />
      </Sidepanel>
    );
  }
}

export const mapStateToProps = ({ overlays }) => ({
  sidepanel: overlays.VIEW_TASK,
});

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});
ViewTask.propTypes = propTypes;
ViewTask.defaultProps = defaultProps;

export default withUnmountMethod(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorBoundary(ViewTask)),
);
