/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';

import GridFactory from '../../../../../components/grid';
import connect from '../../../../../logic/connect';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import { getActiveFilters } from '../../../../../components/grid/GridUtils';
import Filters from '../../../../../components/Filters';
import { sharedModifier } from '../modifier';

const pageConfig = {
  apiUrl: 'reports/sales_overview/team',
  reducerKey: 'SALES_OVERVIEW',
  tableKey: 'ALL_TEAMS_TABLE',
};

const { actions, GridComponent } = GridFactory(pageConfig);

// eslint-disable-next-line
class AllTeams extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  componentWillUnmount() {
    this.actions.fetchTableData();
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: sharedModifier,
    };
    return (
      <Fragment>
        <Filters {...filterProps} />
        <GridComponent tableModifier={sharedModifier} {...this.props} />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { 
      fetchTableData: actions.fetchTableData,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(AllTeams));
