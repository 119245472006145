/* eslint-disable react/prop-types */
import React from 'react';
import { Pill, HyperLink, Tooltip } from 'tc-biq-design-system';
import { object } from 'prop-types';

import './PillsCell.scss';
import { gettext } from '../../logic/utilities/languageUtility';

const text = {
  SEE_MORE: gettext('see all'),
};

const PillsTooltip = ({ clerableField, onClear, data, field }) => {
  const PillsMethods = <div className="pills-cell__tooltip">{PillsCell(clerableField, onClear)({ data, colDef: { field } })}</div>;
  return (
    
    <Tooltip
      content={PillsMethods}
      placement="bottom"
      title=""
      tooltipOptions={{
        interactive: true,
      }}
    >
      <HyperLink>
        <span>... </span><a>{text.SEE_MORE}</a>
      </HyperLink>
    </Tooltip>
  );
};

const propTypes = {
  data: object.isRequired,
  colDef: object.isRequired,
};

const PillsCell = (
  clerableField,
  onClear,
  maxLength = Number.MAX_SAFE_INTEGER,
) => ({ data, colDef: { field } }) => {
  const items = data[field];
  const isClearable = data[clerableField];
  if (!items) return null;
  const AdditionaPills = items.length > maxLength ? (
    <PillsTooltip 
      clerableField={clerableField}
      onClear={onClear}
      data={data}
      field={field}
    />
  ) : null;
  return (
    <div>
      {items.length > 0 && items
        .slice(0, maxLength)
        .map(item => (
          <Pill 
            key={item}
            icon={isClearable && 'Close'}
            onIconClick={() => onClear(data, item)}
          >
            {item}
          </Pill>
          
        ))}
      {AdditionaPills && AdditionaPills} 
    </div>
  );
};

PillsCell.propTypes = propTypes;

export default PillsCell;
