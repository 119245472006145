import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import GridActionsFactory from '../../../../../../components/grid/GridActionsFactory';

export const TABLE_KEY = 'CLIENT_TRADING_ACCOUNTS_TABLE';

export const tableConfig = id => ({
  apiUrl: `users/${id}/trading_accounts`,
  reducerKey: 'USER_SINGLE',
  tableKey: TABLE_KEY,
});

export const getTableActions = (id) => {
  const config = tableConfig(id);
  const api = getDjangoApi(config.apiUrl);
  return GridActionsFactory(config.tableKey, api);
};
