import React, { Component, Fragment } from 'react';
import { object, func } from 'prop-types';
import { Select, Space } from 'tc-biq-design-system';

import Page from '../../../../components/Page';
import { gettext } from '../../../../logic/utilities/languageUtility';
import withTable, { GridTab } from '../../../../components/grid/withTable';
import modifiers from './modifiers';
import { session } from '../../../../logic/services/session';
import { hasAccess } from '../../../../logic/services/acl';
import appRoutes from '../../../../components/App/Router/appRoutes';
import Export from '../../../../components/Export';

const text = {
  TITLE: gettext('Countries settings'),
  EXPORT: gettext('Export current page'),
  EXPORT_ALL: gettext('Export all'),
  SELECT_WHITELABEL: gettext('Select whitelabel'),
};

const bread = [{ label: text.TITLE, route: appRoutes.SETTINGS_COUNTRIES }];

export const reducerKey = 'COUNTRIES_SETTINGS';

export const tableConfig = whitelabel => ({
  reducerKey,
  apiUrl: whitelabel ? `settings/countries/by-whitelabel/${whitelabel}` : 'settings/countries/',
  tableKey: 'COUNTRIES',
});

const propTypes = {
  dispatch: func.isRequired,
  table: object.isRequired,
};

class CountriesTable extends Component {
  constructor(props) {
    super(props);

    this.isWhitelableDropdownVisible = session.whitelabel_segregation
      && session.whitelabel_segregation.includes('COUNTRY_RISK_TYPE');

    const whitelabelOptions = session.whitelabels || [];
    this.state = {
      selectedWhitelabel: whitelabelOptions[0] || null,
      whitelabelOptions,
    };
  }

  onChangeWhitelabel = selectedWhitelabel => this.setState({ selectedWhitelabel })

  getRowNodeId = data => data.iso_code

  reloadTable = () => {
    const { dispatch, table } = this.props;
    const [, tableActions] = this.Grid;
    dispatch(tableActions.fetchTableData(table.query));
  }

  headerActions = () => {
    const { table } = this.props;
    const exportUrl = _.get(table, 'exportUrl');
    if (!hasAccess('countries.export.*')) return null;
    return <Export url={exportUrl} />;
  }

  renderTable = () => {
    const { selectedWhitelabel } = this.state;
    
    if (selectedWhitelabel !== this.renderedWhitelabel) {
      this.renderedWhitelabel = selectedWhitelabel;
      this.Grid = withTable(GridTab, 
        tableConfig, null, { 
          withActions: true,
          gridConfig: { getRowNodeId: data => data.iso_code },
        })(selectedWhitelabel && selectedWhitelabel.name);
    }

    const [Table] = this.Grid || [];
    return Table;
  }

  render() {
    const { selectedWhitelabel, whitelabelOptions } = this.state;
    const Table = this.renderTable();
     
    return (
      <Page 
        title={text.TITLE} 
        bread={bread} 
        headerActions={this.headerActions}
      >
        {this.isWhitelableDropdownVisible && (
        <Fragment>
          <Space size={10} />
          <Select
            clearable={false}
            placeholder={text.SELECT_WHITELABEL}
            onChange={this.onChangeWhitelabel}
            options={whitelabelOptions}
            value={selectedWhitelabel}
            name="whitelabel"
            valueKey="id"
            labelKey="name"
          />
        </Fragment>
        )}
        {Table && (
          <Table 
            modifier={modifiers(this.reloadTable)}
          />
        )}
      </Page>
    );
  }
}

CountriesTable.propTypes = propTypes;

export default CountriesTable;
