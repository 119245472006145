import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import connect from '../../../../../logic/connect';
import overlayActions from '../../../../../components/overlay';
import { FormActionsFactory } from '../../../../../components/form';
import { 
  setLayout, fetchDefaultLayout, fetchProfileLayout, assignToTeams, 
  assignToUsers, submitAssign, resetLayout, 
} from './Model';
import ClientProfileLayoutComp, { FORM_KEY } from './ClientProfileLayout';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const { create, update, setFieldValue, showLoader } = FormActionsFactory(FORM_KEY);

const reducerId = 'CLIENT_PROFILE_LAYOUT';

const mapStateToProps = ({ pages, forms }) => {
  const { submitInProgress } = forms[FORM_KEY];
  return { 
    ...pages[reducerId],
    submitInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    setLayout,
    fetchDefaultLayout,
    fetchProfileLayout,
    create,
    update,
    setFieldValue,
    showLoader,
    assignToTeams,
    assignToUsers,
    submitAssign,
    resetLayout,
    openSidepanel: overlayActions.open,
  }, dispatch),
});

const ClientProfileLayoutPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(ClientProfileLayoutComp); 

export const ClientProfileLayout = {
  component: ClientProfileLayoutPage,
  aclId: 'layout.profile.update',
  path: appRoutes.SETTINGS_CUSTOMIZATION_PROFILE,
};

export const ClientProfileLayoutCreate = {
  component: ClientProfileLayoutPage,
  aclId: 'layout.profile.create',
  path: appRoutes.SETTINGS_CUSTOMIZATION_PROFILE_CREATE,
};
