import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import connect from '../../../../logic/connect';
import CampaignSingle from './CampaignSingle';
import withDateFilter from '../../campaigns/Campaigns/CampaignStat/DateFilter';
import { actions } from './Model';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const mapStateToProps = ({ pages }) => ({
  ...pages.CAMPAIGNS.campaign,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

const CampaignPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withDateFilter,
  withErrorBoundary,
  withRouter,
)(CampaignSingle); 

export default {
  component: CampaignPage,
  aclId: 'team.profile.campaigns.retrieve',
  path: appRoutes.CAMPAIGN,
};
