import React, { PureComponent } from 'react';
import { array, object, any, func, string } from 'prop-types';
import { Select, Icon } from 'tc-biq-design-system';
import If from '../../If';
import FilterInput from '../../Filters/FilterInput';
import { operatorsForType } from '../../../logic/filters/operators-for-type';
import { getDjangoApi } from '../../../logic/services/api-factory';
import { isMultipleSelect } from '../../Filters/filterUtils';
import apiDataType from '../../../logic/enums/api-data-type';

import './Condition.scss';

const { NESTED_RELATION, FIELD, CHOICE, NESTED_LIST, BOOLEAN } = apiDataType;

const handleNestedList = values => values.map(v => ({ value: v, display_name: v }));

const handleChoices = (value, operator, field) => {
  const choices = field.child ? field.child.choices : field.choices;

  if (!isMultipleSelect(operator)) return _.find(choices, (o => o.value === value)) || value;

  return value.map(singleValue => choices.find(o => o.value === singleValue));
};

const handleText = (value, operator) => {
  if (isMultipleSelect(operator)) {
    return (value || []).map((singleValue) => {
      if (_.isString(singleValue)) {
        return {
          label: singleValue,
          value: singleValue,
        };
      }

      return singleValue;
    });
  }

  return value;
};

const getApi = (url) => {
  const uri = url.replace('/backoffice/api/', '');
  return getDjangoApi(uri);
};

const isObject = (field) => {
  switch (field.type) {
    case apiDataType.STRING:
    case apiDataType.INTEGER:
    case apiDataType.FLOAT:
    case apiDataType.DATE:
    case apiDataType.DATETIME:
    case apiDataType.DECIMAL:
    case apiDataType.EMAIL:
      return false;
    default:
      return true;
  }
};

const convertValue = (operator, data, field) => {
  let value = '';

  if ((operator.value === 'in' || operator.value === '!in') 
    && !_.isEmpty(data)) {
    value = [data];
  }

  if ((operator.value !== 'in' && operator.value !== '!in') 
    && !_.isEmpty(data) && _.isArray(data) 
    && _.isPlainObject(data[0])) {
    if (!isObject(field)) {
      value = _.get(data, '[0].value', '');
    } else {
      [value] = data;
    }
  }

  return value;
};

const handleAsync = async (value, operator, field) => {
  if (value === '') return null;
  const isMultipleSelectFilter = isMultipleSelect(operator);
  let filterValue = value;

  if (isMultipleSelectFilter && _.isArray(value)) {
    filterValue = value.join(',');
  }
  
  
  const { data: { results } } = await getApi(field.list_url).list({ [`${field.key_field}__in`]: filterValue });

  const values = results.map(item => ({
    display_name: item[field.display_field],
    value: item[field.key_field],
  }));
  
  const [firstValue] = values || [];

  return isMultipleSelectFilter ? values : firstValue;
};

const getFieldValue = (field, value, operator) => {
  if (_.isNull(value)
    || _.isUndefined(value)
    || _.isPlainObject(value)
    || (_.isArray(value) && _.isEmpty(value))
    || (_.isArray(value) && _.isPlainObject(value[0]))
  ) return value;

  switch (field.type) {
    case FIELD:
    case CHOICE:
      return handleChoices(value, operator, field);
    case NESTED_RELATION:
      return field.list_url ? handleAsync(value, operator, field) : handleChoices(value, operator, field);
    case NESTED_LIST:
      return handleNestedList(value);
    case BOOLEAN:
      return !!value;
    default:
      return handleText(value, operator);
  }
};

const propTypes = {
  fields: array.isRequired,
  fieldToQuery: object,
  operator: object,
  filterData: any,
  updateState: func.isRequired,
  identifier: string.isRequired,
  removeRule: func.isRequired,
};

const defaultProps = {
  fieldToQuery: {},
  operator: {},
  filterData: null,
};

class Condition extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  componentDidMount() {
    this.setFieldValue();
  }

  async componentDidUpdate(prevProps) {
    const { filterData: newFilterData } = this.props;
    const { filterData: oldFilterData } = prevProps;
    if (!_.isEqual(newFilterData, oldFilterData)) {
      await this.setFieldValue();
    }
  }

  onQueryFieldChange = (field) => {
    const { updateState, identifier } = this.props;
    const propertiesToUpdate = {
      field,
      operator: {},
      value: null,
    };
    updateState(propertiesToUpdate, identifier);
  };

  onOperatorChange = (operator) => {
    const { updateState, identifier, filterData, fieldToQuery } = this.props;
    const value = convertValue(operator, filterData, fieldToQuery);

    const propertiesToUpdate = {
      operator,
      value,
    };
    updateState(propertiesToUpdate, identifier);
  };

  onFilterDataChange = (filterData) => {
    const { updateState, identifier } = this.props;
    updateState({ value: filterData }, identifier);
  };

  setFieldValue = async () => {
    const { fieldToQuery, operator, filterData } = this.props;
    const value = await getFieldValue(fieldToQuery, filterData, operator.value);
    this.setState({ value });
    return value;
  }

  getOperatorsForFieldType(field) {
    if (field && field.type) {
      return operatorsForType(field.type);
    }
    return [];
  }

  removeCondition = () => {
    const { removeRule, identifier } = this.props;
    removeRule(identifier);
  };

  render() {
    const { fields, fieldToQuery, operator } = this.props;
    const { value } = this.state;
    const operators = this.getOperatorsForFieldType(fieldToQuery);
    
    return (
      <li className="condition__parent">
        <div className="condition__input">
          <Select
            onChange={this.onQueryFieldChange}
            value={fieldToQuery}
            options={fields}
            valueKey="key"
            clearable={false}
            arrowRenderer={() => <Icon name="CaretDown" size="small" />}
          />
        </div>
        <If condition={!!fieldToQuery.key}>
          <div className="condition__input--operator">
            <Select
              onChange={this.onOperatorChange}
              value={operator}
              options={operators}
              valueKey="value"
              clearable={false}
              arrowRenderer={() => <Icon name="CaretDown" size="small" />}
            />
          </div>
        </If>
        <If condition={!!operator.value && operator.value !== 'isnull' && operator.value !== '!isnull'}>
          <div className={operator.value === 'range' ? 'condition__input--range' : 'condition__input'}>
            <FilterInput
              onChange={this.onFilterDataChange}
              value={value}
              operator={operator.value}
              field={fieldToQuery}
            />
          </div>
        </If>
        <div className="condition__remove" onClick={this.removeCondition}>
          <Icon name="Close" size="regular" />
        </div>
      </li>
    );
  }
}

Condition.propTypes = propTypes;
Condition.defaultProps = defaultProps;

export default Condition;
