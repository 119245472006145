import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import IB_STATE from '../../../../../../../logic/enums/ib-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionTypes from '../types';
import Credit from '../Credit/Credit';

const text = {
  LABEL: gettext('Debit'),
};

// @TODO missing archive icon
const ACTION_ICON = 'Withdrawal';

export default {
  name: 'manualWithdrawal',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.TRANSACTIONS,
  hasPermission(state, user, isIb) {
    if (isIb) {
      return !user.is_archived && hasAccess('ib.withdraw.create') && state !== IB_STATE.INCOMPLETE;
    } 
    const hasStatePermission = [
      USER_STATE.FULL,
      USER_STATE.LIMITED,
      USER_STATE.REVIEW,
    ].includes(state);

    return hasAccess('user.withdraw.create') && hasStatePermission;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
      isCredit: false,
      key: 'debit',
    };

    sidepanelManager.add(Credit, sidepanelOptions);
  },
};
