/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { notifier } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';

import connect from '../../../../logic/connect';

import { gettext } from '../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import GridFactory from '../../../../components/grid';
import Page from '../../../../components/Page';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import overlayActions from '../../../../components/overlay';
import { BiqUserPreview } from '../../../../components/common/sidepanels';
import modifiers from './modifiers';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const pageConfig = {
  apiUrl: 'settings/segments',
  reducerKey: 'SEGMENTS',
  tableKey: 'SEGMENTS_TABLE',
};

const text = {
  PAGE_LABEL: gettext('Segments'),
  DELETE_SUCCESS: gettext('View deleted successfully'),
};

const { GridComponent, actions } = GridFactory(pageConfig);
const bread = [{ label: text.PAGE_LABEL, route: appRoutes.SETTINGS_SEGMENTS }];
const api = getDjangoApi('segments');

class Segments extends Component {
  constructor(props) {
    super(props);
    this.tableActions = {
      remove: hasAccess('segments.destroy') && this.onRemove.bind(this),
      previewBiqUser: props.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }


  async onRemove({ id }) {
    const { actions } = this.props;
    await api.destroy(id);
    notifier.success(text.DELETE_SUCCESS);
    actions.fetchTableData();
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifiers(this.tableActions),
    };

    return (
      <Page bread={bread} title={text.PAGE_LABEL}>
        <Filters {...filterProps} />
        <GridComponent
          singleActions={!!this.tableActions.remove}
          tableModifier={modifiers(this.tableActions)}
          {...this.props}
        />
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchTableData: actions.fetchTableData,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    fields: table.fields,
  };
};

const SegmentsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(Segments); 

export default {
  component: SegmentsPage,
  aclId: 'segments.settings.list',
  path: appRoutes.SETTINGS_SEGMENTS,
};
