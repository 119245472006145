import React from 'react';
import { Icon, Popconfirm } from 'tc-biq-design-system';
import { func, bool } from 'prop-types';

import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import './ActionBar.scss';

const text = {
  UNSAVED_CHANGES: gettext('You have some unsaved changes. To save them,'),
  CLICK_HERE: gettext('click here'),
  DO_YOU_WANT_TO_SAVE: gettext('Do you want to save this dashboard layout?'),
  SAVE_NEW: gettext('New'),
  SAVE_CURRENT: gettext('Current'),
  SAVE: gettext('Save'),
  UNDO: gettext('Undo'),
};

const propTypes = {
  onSave: func,
  onSaveAs: func,
  onUndo: func,
  visible: bool,
};

const defaultProps = {
  onSave: null,
  onSaveAs: null,
  onUndo: null,
  visible: false,
};

const ActionBar = ({ onUndo, onSave, onSaveAs, visible }) => visible && (
  <div className="biq-action-bar">
    <Icon colorName="text-neutral-50" name="Info" />
    <span className="biq-action-bar__text text-neutral-50 tc-paragraph-strong">{text.UNSAVED_CHANGES}</span>
    <Popconfirm
      icon="Dashboard"
      type="primary"
      label={text.DO_YOU_WANT_TO_SAVE}
      buttonActions={[
        {
          type: 'cancel',
          label: text.UNDO,
          onClick: onUndo,
          size: 'regular',
        }, {
          type: 'dropdown',
          label: text.SAVE,
          actions: [
            ...(onSaveAs ? [{
              label: text.SAVE_NEW,
              onClick: onSaveAs,
            }] : []), 
            ...(onSave ? [{
              label: text.SAVE_CURRENT,
              onClick: onSave,
            }] : []),
          ],
        },
      ]}
    >
      <span className="biq-action-bar__link text-neutral-50 tc-paragraph-strong">{text.CLICK_HERE}</span>
    </Popconfirm>
  </div>
);

ActionBar.propTypes = propTypes;
ActionBar.defaultProps = defaultProps;

export default ActionBar;
