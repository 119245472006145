import { bindActionCreators } from 'redux';

import CommissionForm, { SIDEPANEL_ID, FORM_ID } from './CommissionForm';
import connect from '../../../../../../../../logic/connect';
import overlayActions from '../../../../../../../../components/overlay';
import { FormActionsFactory } from '../../../../../../../../components/form';

const { setFieldValue } = FormActionsFactory(FORM_ID);

const mapStateToProps = ({ overlays, forms, pages }) => {
  const sidepanel = overlays[SIDEPANEL_ID];
  const { commission, options } = sidepanel.parameters || {};
  const form = forms[FORM_ID];
  const page = pages.USER_SINGLE;

  return {
    visible: sidepanel.visible,
    isEdit: !!commission,
    commission,
    options,
    errors: form.errors,
    submitInProgress: form.submitInProgress,
    fields: form.fields,
    values: form.values,
    user: page.userDetail.user,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeOverlay: overlayActions.close,
      setFieldValue,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommissionForm);
