import React, { Component } from 'react';

import withFormSidepanel from '../../../../../../components/FormSidepanel';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import { formatRequestData } from '../../../../../../components/form/logic/utils';

export const SIDEPANEL_ID = 'EMAIL_ALERTS_FORM_SIDEPANEL';
export const FORM_ID = 'EMAIL_ALERTS_FORM';

const apiUrl = 'settings/bo_notifications';

const text = {
  CREATE: gettext('Add New Email'),
  EDIT: gettext('Update Email'),
  SUCCESS: {
    create: gettext('Email successfully added'),
    edit: gettext('Email successfully updated'),
  },
};

class EmailAlertsSidepanel extends Component {
  constructor(props) {
    super(props);

    this.FormSidepanel = withFormSidepanel(FORM_ID, SIDEPANEL_ID);
    this.whiteLabelEnabled = isFeatureEnabled()('WHITELABEL');
    this.options = null;
  }

  getOptions = async () => {
    const optionsResponse = await this.getApi().options();
    const fields =  _.get(optionsResponse, 'data.actions.POST.fields');
    const enabled_events = _.find(fields, { key: 'enabled_events' }).choices;
    const disabled_events = _.find(fields, { key: 'disabled_events' }).choices;
    this.options = {
      enabled_events,
      disabled_events,
    };
  }

  componentDidMount(){
    this.getOptions();
  }

  getRequestPayload = () => (values) => {
    const parsed = formatRequestData(values);

    const disabled_events = values.disabled_events?.map(({ value }) => value);
    const enabled_events = values.enabled_events?.map(({ value }) => value);
    if (this.whiteLabelEnabled) {
      return {
        ...parsed,
        whitelabel: values.whitelabel && values.whitelabel.name,
        enabled_events,
        disabled_events,
      };
    }
    
    return parsed;
  }

  prepareRowData = () => (data) => {
    const row = { ...data };
    if (row.enabled_events.length) {
      const events = [];
      for (let event of row.enabled_events) {
        const eventData = _.find(this.options.enabled_events, { value: event });
        events.push(eventData);
      }
      row.enabled_events = events;
    }
    
    if (row.disabled_events?.length) {
      const events = [];
      for (let event of row.disabled_events) {
        const eventData = _.find(this.options.disabled_events, { value: event });
        events.push(eventData);
      }
      row.disabled_events = events;
    }

    if (this.whiteLabelEnabled) {
      row.whitelabel = _.get(row, 'whitelabel.name');
    }
    
    return row;
  }

  getFormConfig = () => ({
    excludeFields: this.whiteLabelEnabled ? [] : ['whitelabel'],
  });

  getApi = () => getDjangoApi(apiUrl)

  render() {
    const { FormSidepanel, getFormConfig, getApi, getRequestPayload, prepareRowData } = this;
    return (
      <FormSidepanel
        {...this.props}
        options={{
          getApi,
          text,
          getFormConfig,
          getRequestPayload,
          prepareRowData,
        }}
      />
    );
  }
}

export default EmailAlertsSidepanel;
