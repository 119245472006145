import React from 'react';
import RiskCell from '../RiskCell';

export default onSuccess => ({
  country_name: {
    width: 50,
  },
  iso_code: {
    width: 50,
  },
  risk_type: {
    cellRendererFramework: ({ value, data, column }) => {
      const options = _.get(column, 'colDef.definition.choices');
      if (!_.isEmpty(options)) {
        const selected = options.find(o => o.display_name === value);
        return (
          <RiskCell 
            key={data.iso_code}
            data={data} 
            name={data.iso_code}
            options={options}
            value={selected}
            onSuccess={onSuccess}
          />
        );
      }

      return value;
    },
  },
  whitelabel: {
    quickFilter: {},
    width: 100,
  },
});
