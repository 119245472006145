import { bindActionCreators } from 'redux';

import connect from '../../../../../../logic/connect';
import { actions } from '../Model';
import CommunicationFlow from '../CommunicationFlow/CommunicationFlow';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import { hasAccess } from '../../../../../../logic/services/acl';

const api = getDjangoApi('autodialer_settings');

const hasPermission = () => ({
  update: hasAccess('autodialer_settings.update'),
  create: hasAccess('autodialer_settings.create'),
  list: hasAccess('autodialer_settings.list'),
});

const mapStateToDispatch = ({ pages }) => ({
  ...pages.COMMUNICATION_FLOW,
  api,
  hasPermission,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...actions,
  }, dispatch),
});

export default connect(mapStateToDispatch, mapDispatchToProps)(CommunicationFlow);
