import React, { Component } from 'react';
import { Sidepanel, Panel, Icon } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { object, string, bool, node } from 'prop-types';

import connect from '../../../../../../logic/connect';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import overlayActions from '../../../../../../components/overlay';
import { YesNoCell } from '../../../../../../components/gridCellRenderers';
import BiqAccordion from '../../../../../../components/BiqAccordion';
import Entity from '../../../../../../components/Entity';
import { dateTimeFormatter } from '../../../../../../logic/utilities/formatters';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { activate } from './Model';
import './AuditSingle.scss';

const propTypes = {
  sidepanel: object.isRequired,
  log: object.isRequired,
  actions: object.isRequired,
};

const text = {
  CHANGES: gettext('Changes'),
  AUDIT_LOG: gettext('Audit log'),
  DETAILS: gettext('Details'),
  ACTION_OBJECT: gettext('Action object'),
  ACTOR: gettext('Actor'),
  TARGET: gettext('Target'),
  VERB: gettext('Verb'),
};

const AccordionHeaderTemplate = ({ title, isOpen }) => (
  <div className="biq-audit-single__accordion-header-template">
    <span className="biq-audit-single__icon">
      {isOpen && <Icon name="CaretDown" colorName="text-neutral-900" />}
      {!isOpen && <Icon name="CaretRight" colorName="text-neutral-900" />}
    </span>
    <span className="tc-paragraph-strong text-neutral-900">{title}</span>
  </div>
);

AccordionHeaderTemplate.propTypes = {
  title: string.isRequired,
  isOpen: bool.isRequired,
};

const LogDetail = ({ label, value }) => (
  <div className="biq-audit-single__detail">
    <span className="text-neutral-400">{label}</span>
    <span className="text-neutral-900">{value}</span>
  </div>
);

LogDetail.propTypes = {
  label: string.isRequired,
  value: node,
};

LogDetail.defaultProps = {
  value: null,
};

const isDate = (value) => { 
  const intValue = parseInt(value);
  if (isNaN(intValue) || value === intValue.toString()) return false; // eslint-disable-line
  return !!Date.parse(value);
};

const displayValue = (value) => {
  if (isDate(value)) return dateTimeFormatter(value);
  if (typeof value === 'boolean') return <YesNoCell value={value} />;
  if (_.isObject(value)) return JSON.stringify(value);
  return value;
};

class AuditSingle extends Component {
  componentDidUpdate(prevProps) {
    const { actions, sidepanel } = this.props;
    const id = _.get(sidepanel, 'parameters.id');
    const prevId = _.get(prevProps, 'sidepanel.parameters.id');
    if (id && id !== prevId) actions.activate(id);
  }

  render() {
    const { sidepanel, actions, log } = this.props;
    const { data } = log;
    const changes = _.get(data, 'data.changes');

    const {
      action_object,
      actor,
      target,
      verb,
    } = data;

    const PanelTitle = (
      <span>
        <span className="biq-audit-single__icon">
          <Icon name="Pending" colorName="text-primary-400" />
        </span>
        <span className="text-neutral-900">{text.CHANGES}</span>
      </span>
    );

    return (
      <Sidepanel
        visible={sidepanel.visible}
        type="info"
        icon="View"
        title={text.AUDIT_LOG}
        onCloseIconClick={() => actions.closeSidepanel('AUDIT_SINGLE')}
        hideFooter
      >
        <div className="biq-audit-single">
          <Panel title={<span className="text-neutral-900">{text.DETAILS}</span>}>
            <LogDetail label={text.ACTION_OBJECT} value={action_object && <Entity data={action_object} />} />
            <LogDetail label={text.ACTOR} value={actor && <Entity data={actor} />} />
            <LogDetail label={text.TARGET} value={target && <Entity data={target} />} />
            <LogDetail label={text.VERB} value={verb} />
          </Panel>

          {changes && changes.length ? (
            <Panel title={PanelTitle}>
              {changes.map((change, index) => (
                <div className="biq-audit-single__accordion" key={index}>
                  <BiqAccordion headerTemplate={isOpen => <AccordionHeaderTemplate isOpen={isOpen} title={change[0]} />}>
                    <div className="biq-audit-single__accordion-body">
                      <span className="text-neutral-400">{displayValue(change[2])}</span> 
                      <span>&nbsp;&#8594;&nbsp;</span>
                      <span className="text-neutral-900">{displayValue(change[1])}</span>
                    </div>
                  </BiqAccordion>
                </div>
              ))}
            </Panel>
          ) : null}
        </div>
      </Sidepanel>
    );
  }
}

AuditSingle.propTypes = propTypes;

const mapStateToProps = ({ overlays, pages }) => ({
  sidepanel: overlays.AUDIT_SINGLE,
  log: pages.AUDIT_LOGS.auditSingle,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { 
      activate,
      closeSidepanel: overlayActions.close,
    }, 
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary(AuditSingle));
