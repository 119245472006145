import React from 'react';
import { Tooltip, Icon } from 'tc-biq-design-system';

import { ToggleCell, UsernameCell, ActionsCellWrapper } from '../../../../../components/gridCellRenderers';
import PillsCell from '../../../../../components/gridCellRenderers/PillsCell';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import hideCols from '../../../../../logic/utilities/hideCols';

const LIMIT_DISPLAYED_METHODS = 3;

const text = {
  ADD: gettext('Add method'),
  MODIFY_BANK: gettext('Modify bank'),
  REMOVE_BANK: gettext('Remove bank'),
};

const createIcon = (actionHandler, data) => data.is_methods_manageable && (
  <div key="create" onClick={() => actionHandler(data)}>
    <Tooltip title={text.ADD} placement="top">
      <Icon name="Plus" />
    </Tooltip>
  </div>
);

const actionIcon = (text, icon) => (actionHandler, data) => (
  <div key={icon} onClick={() => actionHandler(data)}>
    <Tooltip title={text} placement="top">
      <Icon name={icon} />
    </Tooltip>
  </div>
);


const gatewayAction = onCreate => ({
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    pinned: 'right',
    cellRendererFramework: onCreate && ActionsCellWrapper({ onCreate }, { onCreate: createIcon }),
    width: 120,
  },
});

const bankAction = ({ onRemove, onModify }) => ({
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    pinned: 'right',
    cellRendererFramework: (onModify || onRemove) 
      && ActionsCellWrapper({ onModify, onRemove }, { 
        onModify: actionIcon(text.MODIFY_BANK, 'Edit'),
        onRemove: actionIcon(text.REMOVE_BANK, 'Delete'),
      }),
    width: 120,
  },
});


const gatewaysModifier = ({ 
  onBOToggle,
  onPortalToggle,
  onMethodClear,
  onMethodAdd,
  onBiqUserPreview,
  onDebitCurrencyConversionToggle,
}) => ({
  ...hideCols(['is_methods_manageable']),
  is_active_for_bo: {
    cellRendererFramework: ToggleCell(onBOToggle),
  },
  is_active_for_clients: {
    cellRendererFramework: ToggleCell(onPortalToggle),
  },
  debit_currency_conversion: {
    cellRendererFramework: ToggleCell(onDebitCurrencyConversionToggle),
  },
  updated_by: {
    cellRendererFramework: UsernameCell(onBiqUserPreview, { isStaff: true }),
  },
  methods: {
    width: 380,
    cellRendererFramework: PillsCell('is_methods_manageable', onMethodClear, LIMIT_DISPLAYED_METHODS),
    quickFilter: {},
  },
  acquirers: {
    cellRendererFramework: PillsCell(),
    quickFilter: {},
  },
  ...gatewayAction(onMethodAdd),
});

const banksModifier = ({ onBankToggle, onBiqUserPreview, onModify, onRemove }) => ({
  ...hideCols(['id']),
  is_active: {
    cellRendererFramework: ToggleCell(onBankToggle),
  },
  updated_by: {
    cellRendererFramework: UsernameCell(onBiqUserPreview, { isStaff: true }),
  },
  ...bankAction({ onModify, onRemove }),
});

export {
  gatewaysModifier,
  banksModifier,
};
