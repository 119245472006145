import { handleActions } from 'redux-actions';

import actionTypes from './activityActionTypes';

const initialState = {
  isLoading: false,
  data: [],
  firstLevelQuery: {},
  count: 0,
  hasMore: true,
  error: null,
  limit: 20,
  offset: 0,
  newActivityCount: 0,
  highlightNewCount: 0,
  activityData: {
    users: [],
    bo_users: [],
  },
};

export default handleActions(
  {
    [actionTypes.LOAD_ACTIVITY_PENDING]: state => ({
      ...state,
      isLoading: true,
      offset: 0,
    }),
    [actionTypes.LOAD_ACTIVITY_FULFILLED]: (state, { payload }) => {
      const { data, highlightNew } = payload;
      return {
        ...state,
        isLoading: false,
        data: data.results,
        count: data.count,
        hasMore: data.count > state.offset,
        offset: state.limit,
        newActivityCount: 0,
        highlightNewCount: highlightNew ? state.newActivityCount : 0,
      };
    },
    [actionTypes.LOAD_MORE_PENDING]: state => ({
      ...state,
      isLoading: true,
    }),
    [actionTypes.LOAD_MORE_FULFILLED]: (state, { payload }) => {
      const { results, count } = payload.data;
      const hasMore = count > state.offset + state.limit;
      const offset = hasMore ? state.offset + state.limit : state.offset;
      return {
        ...state,
        isLoading: false,
        data: [...state.data, ...results],
        count: results.count,
        hasMore,
        offset,
      };
    },
    [actionTypes.UPDATE_NEW_ACTIVITY_COUNT]: (state, { payload }) => {
      const { activity, users, bo_users, success } = payload.data;
      if (activity) {
        return {
          ...state,
          activityData: {
            users,
            bo_users,
          },
        };
      }
      if (success) {
        return {
          ...state,
          newActivityCount: state.newActivityCount + 1,
        };
      }
      return { ...state };
    },
    [actionTypes.RESET_NEW_ACTIVITY_COUNT]: state => ({ ...state, newActivityCount: 0 }),
    [actionTypes.SET_FIRST_LEVEL_QUERY]: (state, { payload }) => ({
      ...state,
      firstLevelQuery: payload || {},
    }),
    [actionTypes.RESET_OFFSET]: state => ({ ...state, offset: 0 }),
    [actionTypes.RESET_ACTIVITY_STREAM]: () => ({ ...initialState }),
  },
  initialState,
);
