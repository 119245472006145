import React, { Component } from 'react';
import { Accordion, Icon, Pill, Table, InfoBox } from 'tc-biq-design-system';
import { object, array } from 'prop-types';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import currencyFormatter from '../../../../../../logic/utilities/formatters/currency';

const text = {
  TRADEING_ACCOUNT: gettext('Trading account'),
  PAYMENT_METHOD: gettext('Payment method'),
  TOTAL_DEPOSIT: gettext('Total deposit'),
  TOTAL_WITHDRAWALS: gettext('Total withdrawals'),
  NET_DEPOSITS: gettext('Net deposits'),
  NO_PAYMENT_HISTORY: gettext('No payment history.'),
};

// eslint-disable-next-line react/prop-types
const HeaderTemplate = ({ toggleVisibility, visible, tradingAccount }) => (
  <div className="biq-payment-breakdown__accordion__header" onClick={toggleVisibility}>
    <div className="biq-payment-breakdown__accordion__caret-icon">
      {visible ? (
        <Icon name="CaretDown" />
      ) : (
        <Icon name="CaretRight" />
      )}
    </div>
    <div className="biq-payment-breakdown__accordion__title">
      <span className="biq-payment-breakdown__accordion__label tc-mono-regular">{text.TRADEING_ACCOUNT}</span>
      <span className="biq-payment-breakdown__accordion__number tc-paragraph-strong">{tradingAccount.external_id}</span>
    </div>
    <span className="biq-payment-breakdown__accordion__platform">{tradingAccount.backend.platform_type}</span>
    <span className="biq-payment-breakdown__accordion__currency">{tradingAccount.currency.symbol}</span>
    <div className="biq-payment-breakdown__accordion__state-pill">
      <Pill type="status01">{tradingAccount.backend.mode}</Pill>
    </div>
  </div>
);

HeaderTemplate.propTypes = {
  tradingAccount: object.isRequired,
};

const propTypes = {
  tradingAccount: object.isRequired,
  data: array.isRequired,
};

const cols = [
  { title: text.PAYMENT_METHOD, key: 'method' },
  { title: text.TOTAL_DEPOSIT, key: 'deposit' },
  { title: text.TOTAL_WITHDRAWALS, key: 'withdraw' },
  { title: text.NET_DEPOSITS, key: 'net' },
];

class PaymentBreakdownAccordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  toggleVisibility = () => {
    this.setState(({ visible }) => ({
      visible: !visible,
    }));
  }

  formatData = (data, currency) => data.map(payment => ({
    ...payment,
    deposit: currencyFormatter(payment.deposit, currency),
    net: currencyFormatter(payment.net, currency),
    withdraw: currencyFormatter(payment.withdraw, currency),
  }));


  render() {
    const { visible } = this.state;
    const { toggleVisibility } = this;
    const { tradingAccount, data } = this.props;
    const headerParams = { visible, toggleVisibility, tradingAccount, data };
    const formatedData = this.formatData(data, tradingAccount.currency.symbol);

    return (
      <Accordion headerTemplate={<HeaderTemplate {...headerParams} />} visible={visible}>
        {data.length > 0 ? (
          <Table
            striped
            cols={cols}
            data={formatedData}
          />
        ) : (
          <InfoBox>{text.NO_PAYMENT_HISTORY}</InfoBox>
        )}
      </Accordion>
    );
  }
}

PaymentBreakdownAccordion.propTypes = propTypes;

export default PaymentBreakdownAccordion;
