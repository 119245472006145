import React from 'react';
import { string, number } from 'prop-types';
import { Avatar } from 'tc-biq-design-system';

import { StateCell } from '../../../gridCellRenderers';

const propTypes = {
  firstName: string.isRequired,
  lastName: string.isRequired,
  id: number.isRequired,
  state: string.isRequired,
};

const HeaderTitle = ({ firstName, lastName, id, state }) => (
  <div className="title-container">
    <div>
      <Avatar initials={`${firstName[0]}${lastName[0]}`} size="regular" />
    </div>
    <div className="title-text m-l">
      <span>{firstName} {lastName}</span>
      <div className="title-user-state"><StateCell value={state} /></div>
      <br />
      <span className="title-help-text text-grey">{id}</span>
    </div>
  </div>
);

HeaderTitle.propTypes = propTypes;
export default HeaderTitle;
