import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { refactoredInjectRules, setupFields } from '../../../../../logic/services/query-adapter';

const actionTypes = {
  GET_WITHDRAWAL_METHOD_OPTIONS: 'GET_WITHDRAWAL_METHOD_OPTIONS',
  GET_WITHDRAWAL_METHOD_OPTIONS_PENDING: 'GET_WITHDRAWAL_METHOD_OPTIONS_PENDING',
  GET_WITHDRAWAL_METHOD_OPTIONS_FULFILLED: 'GET_WITHDRAWAL_METHOD_OPTIONS_FULFILLED',
  SET_WITHDRAWAL_RULE_SELECTED_METHOD: 'SET_WITHDRAWAL_RULE_SELECTED_METHOD',
  SET_WITHDRAWAL_RULE_NAME: 'SET_WITHDRAWAL_RULE_NAME',
  SET_WITHDRAWAL_RULE_CONDITION: 'SET_WITHDRAWAL_RULE_CONDITION',
  SUBMIT_WITHDRAWAL_METHOD_RULE: 'SUBMIT_WITHDRAWAL_METHOD_RULE',
  SUBMIT_WITHDRAWAL_METHOD_RULE_PENDING: 'SUBMIT_WITHDRAWAL_METHOD_RULE_PENDING',
  SUBMIT_WITHDRAWAL_METHOD_RULE_REJECTED: 'SUBMIT_WITHDRAWAL_METHOD_RULE_REJECTED',
  SUBMIT_WITHDRAWAL_METHOD_RULE_FULFILLED: 'SUBMIT_WITHDRAWAL_METHOD_RULE_FULFILLED',
  GET_WITHDRAWAL_METHOD_RULE_INFO: 'GET_WITHDRAWAL_METHOD_RULE_INFO',
  GET_WITHDRAWAL_METHOD_RULE_INFO_FULFILLED: 'GET_WITHDRAWAL_METHOD_RULE_INFO_FULFILLED',
  RESET_WITHDRAWAL_METHOD_RULE_PAGE_STATE: 'RESET_WITHDRAWAL_METHOD_RULE_PAGE_STATE',
};

const withdrawalMethodRulesAPI = getDjangoApi('settings/withdrawal_methods');

const initialState = {
  submitInProgress: false,
  fields: [],
  methods: [],
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  errors: {
    name: [''],
    method: [''],
    condition: [''],
  },
  selectedMethod: null,
  name: '',
};

export const getFieldOptions = createAction(
  actionTypes.GET_WITHDRAWAL_METHOD_OPTIONS,
  async () => withdrawalMethodRulesAPI.options(),
);

export const submitWithdrawal = createAction(
  actionTypes.SUBMIT_WITHDRAWAL_METHOD_RULE,
  async (isEdit, request, id) => (
    isEdit
      ? withdrawalMethodRulesAPI.updatePart(id, request)
      : withdrawalMethodRulesAPI.create(request)
  ),
);

export const setSelectedMethod = createAction(
  actionTypes.SET_WITHDRAWAL_RULE_SELECTED_METHOD,
  method => method,
);

export const setRuleName = createAction(
  actionTypes.SET_WITHDRAWAL_RULE_NAME,
  event => event.target.value,
);

export const setRuleCondition = createAction(
  actionTypes.SET_WITHDRAWAL_RULE_CONDITION,
  group => group,
);

export const getWithdrawalInfo = createAction(
  actionTypes.GET_WITHDRAWAL_METHOD_RULE_INFO,
  async id => withdrawalMethodRulesAPI.retrieve(id),
);

export const resetState = createAction(
  actionTypes.RESET_WITHDRAWAL_METHOD_RULE_PAGE_STATE,
);

const getWithdrawalOptionsHandler = (state, { payload }) => {
  const { data } = payload;
  const { fields } = data.spec;
  const methods = _.find(data.actions.POST.fields, { key: 'method' }).choices;
  const mappedFields = setupFields(fields);

  return {
    ...state,
    fields: [...mappedFields],
    methods: [...methods],
  };
};

const setSelectedMethodHandler = (state, { payload }) => (
  {
    ...state,
    selectedMethod: { ...payload },
    errors: { ...state.errors, method: [''] },
  }
);

const setRuleNameHandler = (state, { payload }) => (
  {
    ...state,
    name: payload,
    errors: { ...state.errors, name: [''] },
  }
);

const setRuleConditionHandler = (state, { payload }) => (
  {
    ...state,
    group: { ...payload },
  }
);

const withdrawalSubmitHandler = state => (
  {
    ...state,
    submitInProgress: false,
  }
);

const withdrawalSubmitErrorHandler = (state, { payload }) => (
  {
    ...state,
    errors: { ...state.errors, ...payload.data },
    submitInProgress: false,
  }
);

const getWithdrawalInfoHandler = (state, { payload }) => {
  const { condition, method, name } = payload.data;
  const group = refactoredInjectRules(state.fields, condition);
  const selectedMethod = _.find(state.methods, { value: method });

  return {
    ...state,
    name,
    group,
    selectedMethod,
  };
};

const resetStateHandler = () => (
  {
    ...initialState,
  }
);

export const withdrawalMethodRuleActions = {
  getFieldOptions,
  setSelectedMethod,
  setRuleName,
  setRuleCondition,
  getWithdrawalInfo,
  submitWithdrawal,
  resetState,
};

export default handleActions(
  {
    [actionTypes.GET_WITHDRAWAL_METHOD_OPTIONS_PENDING]: state => (
      { ...state, optionsInProgress: true }
    ),
    [actionTypes.GET_WITHDRAWAL_METHOD_OPTIONS_FULFILLED]: getWithdrawalOptionsHandler,
    [actionTypes.SET_WITHDRAWAL_RULE_SELECTED_METHOD]: setSelectedMethodHandler,
    [actionTypes.SET_WITHDRAWAL_RULE_NAME]: setRuleNameHandler,
    [actionTypes.SET_WITHDRAWAL_RULE_CONDITION]: setRuleConditionHandler,
    [actionTypes.SUBMIT_WITHDRAWAL_METHOD_RULE_PENDING]: state => (
      { ...state, submitInProgress: true }
    ),
    [actionTypes.SUBMIT_WITHDRAWAL_METHOD_RULE_FULFILLED]: withdrawalSubmitHandler,
    [actionTypes.SUBMIT_WITHDRAWAL_METHOD_RULE_REJECTED]: withdrawalSubmitErrorHandler,
    [actionTypes.GET_WITHDRAWAL_METHOD_RULE_INFO_FULFILLED]: getWithdrawalInfoHandler,
    [actionTypes.RESET_WITHDRAWAL_METHOD_RULE_PAGE_STATE]: resetStateHandler,
  },
  initialState,
);
