import JSCookie from 'js-cookie';

const CONFIG = window.config;

export default { setCookies, clearCookies, getToken };

export function getToken() {
  return JSCookie.get('bo_token');
}

export function getCsrfToken() {
  return JSCookie.get('csrftoken');
}

export function setCookies(token, expires) {
  const cookieParams = expires ? { expires } : {};

  JSCookie.set('bo_token', token, cookieParams);
}

export function clearCookies() {
  JSCookie.remove('bo_token');
  JSCookie.remove('bo_token', { path: CONFIG.frontendRoot });
}
