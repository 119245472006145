import React from 'react';
import { Spinner } from 'tc-biq-design-system';

const FormSpinner = (
  <div className="biq-form__spinner">
    <Spinner size="large" />
  </div>
);

export default FormSpinner;
