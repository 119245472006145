import { ActionsCellWrapper, RouteCell } from '../../../../../components/gridCellRenderers';
import { actionIcons } from '../../../../../components/grid/GridUtils';
import hideCols from '../../../../../logic/utilities/hideCols';
import CurrencyCell from '../../../../../components/gridCellRenderers/CurrencyCell';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const LinkToTrackingLink = (userId, campaignId) => ({ value, data, colDef }) => {
  const stateParams = { id: data.id, userId, campaignId };
  const Component = RouteCell({ href: appRoutes.TRACKING_LINK, stateParams });
  return Component({ value, data, colDef });
};

export default ({ modify }, { uid, cid }) => {
  const actions = {
    ...(modify && { modify }),
  };
  return {
    ...hideCols(['id']),
    name: {
      cellRendererFramework: LinkToTrackingLink(uid, cid),
    },
    currency: {
      cellRendererFramework: CurrencyCell,
    },
    actions: {
      label: '',
      field: 'actions',
      key: 'actions',
      pinned: 'right',
      width: actions.modify ? 100 : 50,
      hiddenSortIcon: true,
      suppressSizeToFit: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(modify)),
    },
  };
};
