import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { object } from 'prop-types';

import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import SelectRetentionPoolDesk from '../SelectRetentionPoolDesk';

const RETENTION_PROPERTY = 'retention_pool_desk';

const propTypes = {
  user: object,
  actions: object.isRequired,
};

const defaultProps = {
  user: null,
};

const text = {
  RETENTION_POOL_DESK: gettext('Retention Pool Desk'),
  SUCCESS_MESSAGE: gettext('Retention pool desk successfully updated'),
  NONE: gettext('None'),
};

class RetentionPoolDesk extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  onSuccess = () => {
    const { user } = this.props;
    this.actions.fetchUser(user);
  }

  render() {
    return (
      <SelectRetentionPoolDesk
        {...this.props}
        text={text}
        retentionProperty={RETENTION_PROPERTY}
        onSuccess={this.onSuccess}
      />
    );
  }
}

RetentionPoolDesk.propTypes = propTypes;
RetentionPoolDesk.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const { user } = page.userDetail;
  return {
    user,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetentionPoolDesk);
