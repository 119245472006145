/* eslint react/prop-types: 0 */
import React, { PureComponent } from 'react';
import { Tabs, Tab } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';


import isFeatureEnabled from '../../../logic/filters/is-feature-enabled';
import connect from '../../../logic/connect';
import activityActions from './ActivityStream/Model/activityActions';
import withUnmountMethod from '../../../components/hoc/withUnmountMethod';
import withErrorBoundary from '../../../components/hoc/withErrorBoundary';
import { gettext } from '../../../logic/utilities/languageUtility';
import Page from '../../../components/Page';
import './ActivityStreamPage.scss';
import session from '../../../logic/services/session';
import ActivityStream from './ActivityStream/ActivityStream';
import { hasAccess } from '../../../logic/services/acl';
import appRoutes from '../../../components/App/Router/appRoutes';

const text = {
  TITLE: gettext('Activity stream'),
  ALL_ACTIVITY: gettext('All activity'),
  MY_ACTIVITY: gettext('My activity'),
};

const bread = [{ label: text.TITLE, route: appRoutes.ACTIVITY_STREAM }];
class ActivityStreamPage extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  loadActivity = (firstLevelQuery) => {
    this.actions.setFirstLevelQuery(firstLevelQuery);
  };

  render() {
    return (
      <Page
        headerActions={this.headerActions}
        bread={bread}
        title={text.TITLE}
        style={{ margin: '0px' }}
      >
        <div className="biq-activity-stream">
          <Tabs styleTabsList={{ marginBottom: 0 }}>
            <Tab title={text.ALL_ACTIVITY} onLoad={() => this.actions.setFirstLevelQuery()}>
              <ActivityStream />
            </Tab>
            <Tab
              title={text.MY_ACTIVITY}
              onLoad={() => this.actions.setFirstLevelQuery({ bo_user_id: session.user.id })}
            >
              <ActivityStream boUserId={session.user.id} />
            </Tab>
          </Tabs>
        </div>
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loadActivity: activityActions.loadActivity,
      setFirstLevelQuery: activityActions.setFirstLevelQuery,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const {
    filters: {
      dateTextFilters: { dateFilter },
    },
  } = pages.ACTIVITY_STREAM;
  return { dateFilter };
};

const ActivityStreamComp = compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(ActivityStreamPage);

export default {
  component: ActivityStreamComp,
  aclFn: () => isFeatureEnabled()('ACTIVITY_STREAM') && hasAccess('activity_stream.list'),
  path: appRoutes.ACTIVITY_STREAM,
};
