import { createAction, handleActions } from 'redux-actions';
import { refactoredInjectRules, setupFields } from '../../../../../../logic/services/query-adapter';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const actionTypes = {
  GET_CAMPAIGN_FIELDS_OPTIONS: 'GET_CAMPAIGN_FIELDS_OPTIONS',
  GET_CAMPAIGN_FIELDS_OPTIONS_PENDING: 'GET_CAMPAIGN_FIELDS_OPTIONS_PENDING',
  GET_CAMPAIGN_FIELDS_OPTIONS_REJECTED: 'GET_CAMPAIGN_FIELDS_OPTIONS_REJECTED',
  GET_CAMPAIGN_FIELDS_OPTIONS_FULFILLED: 'GET_CAMPAIGN_FIELDS_OPTIONS_FULFILLED',

  GET_CAMPAIGN: 'GET_CAMPAIGN',
  GET_CAMPAIGN_PENDING: 'GET_CAMPAIGN_PENDING',
  GET_CAMPAIGN_REJECTED: 'GET_CAMPAIGN_REJECTED',
  GET_CAMPAIGN_FULFILLED: 'GET_CAMPAIGN_FULFILLED',

  SUBMIT_CAMPAIGN: 'SUBMIT_CAMPAIGN',
  SUBMIT_CAMPAIGN_PENDING: 'SUBMIT_CAMPAIGN_PENDING',
  SUBMIT_CAMPAIGN_REJECTED: 'SUBMIT_CAMPAIGN_REJECTED',
  SUBMIT_CAMPAIGN_FULFILLED: 'SUBMIT_CAMPAIGN_FULFILLED',
  
  SET_CAMPAIGN_CONDITION: 'SET_CAMPAIGN_CONDITION',

  RESET_CAMPAIGN_PAGE: 'RESET_CAMPAIGN_PAGE',
};

const initialState = {
  name: '',
  errors: {
    name: [''],
  },
  users: [],
  fields: [],
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  submitInProgress: false,
};

const api = getDjangoApi('bonus/campaigns');

export const getFieldsOptions = createAction(
  actionTypes.GET_CAMPAIGN_FIELDS_OPTIONS,
  api.options,
);

export const getCampaign = createAction(
  actionTypes.GET_CAMPAIGN,
  api.retrieve,
);

export const setCondition = createAction(
  actionTypes.SET_CAMPAIGN_CONDITION,
  group => group,
);

export const submit = createAction(
  actionTypes.SUBMIT_CAMPAIGN,
  api.create,
);

export const resetState = createAction(
  actionTypes.RESET_CAMPAIGN_PAGE,
);

export const actions = {
  getFieldsOptions,
  getCampaign,
  setCondition,
  submit,
  resetState,
};

const getFieldsOptionsHandler = (state, { payload }) => {
  const { fields } = payload.data.event_specs.new_deposit;
  const mappedFields = setupFields(fields);

  return {
    ...state,
    fields: mappedFields,
  };
};

const getCampaignHandler = (state, { payload }) => {
  const { condition, name } = payload.data;
  const group = refactoredInjectRules(state.fields, condition);
  return {
    ...state,
    group,
    name,
  };
};

const setConditionHandler = (state, { payload }) => (
  {
    ...state,
    group: { ...payload },
  }
);

const submitHandler = state => (
  {
    ...state,
    submitInProgress: true,
  }
);

const submitSuccessHandler = state => (
  {
    ...state,
    submitInProgress: false,
  }
);

const resetStateHandler = () => (
  {
    ...initialState,
  }
);


export default handleActions(
  {
    [actionTypes.GET_CAMPAIGN_FIELDS_OPTIONS_FULFILLED]: getFieldsOptionsHandler,
    [actionTypes.GET_CAMPAIGN_FULFILLED]: getCampaignHandler,
    [actionTypes.SET_CAMPAIGN_CONDITION]: setConditionHandler,
    [actionTypes.SUBMIT_CAMPAIGN_PENDING]: submitHandler,
    [actionTypes.SUBMIT_CAMPAIGN_FULFILLED]: submitSuccessHandler,
    [actionTypes.RESET_CAMPAIGN_PAGE]: resetStateHandler,
  },
  initialState,
);
