import { hasAccess } from '../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';

// Section components
import Activity from './Activity';
import TradingAccounts from './TradingAccounts';
import Positions from './Positions';
import SignupDetails from './SignupDetails';
import Transactions from './Transactions';
import BankAccounts from './BankAccounts';
import Tasks from './Tasks';
import PossibleDuplicates from './PossibleDuplicates';
import Documents from './Documents';
import Withdrawals from './Withdrawals';
import Communication from './Communication';
import PaymentDetails from './PaymentDetails';
import OnfidoReports from './OnfidoReports';
import TermsAndConditions from './TermsAndConditions';
import KYC from './KYC';
import TradingStats from './TradingStats';
import Protrader from './Protrader';
import Marketing from './Marketing';
import Campaign from './Campaign';
import MIFID from './MIFID';
import InstrumentsStats from './InstrumentStats';
import PaymentBreakdown from './PaymentBreakdown';
import Overview from './Overview';
import Monitoring from './Monitoring';
import SalesSpeech from './SalesSpeech';
import Notes from './Notes';

// IB Section components
import TrackingLinks from './TrackingLinks';
import IbClients from './IbClients';
import IbSubClients from './IbSubClients';
import SubIBs from './SubIBs';
import IbPayments from './IbPayments';
import IbCommunication from './IbCommunication';
import IbMarketing from './IbMarketing';
import IbDocuments from './IbDocuments';
import CommissionSettings from './CommissionSettings';

const sections = [
  SalesSpeech,
  TradingAccounts,
  Overview, 
  Activity,
  Tasks,
  SignupDetails,
  Notes,
  PossibleDuplicates,
  Transactions,
  Positions,
  Documents,
  IbDocuments,
  BankAccounts,
  Withdrawals,
  Communication,
  PaymentDetails,
  OnfidoReports,
  TrackingLinks,
  IbClients,
  IbSubClients,
  SubIBs,
  IbPayments,
  IbCommunication,
  IbMarketing,
  CommissionSettings,
  TermsAndConditions,
  KYC,
  TradingStats,
  Protrader,
  Marketing,
  Campaign,
  MIFID,
  InstrumentsStats, 
  PaymentBreakdown,
  Monitoring,
];

const getAvailableSections = (isIb, user) => sections.filter((section) => {
  if (section.visibilityFn && typeof section.visibilityFn === 'function') { 
    return section.visibilityFn(hasAccess, isFeatureEnabled, isIb, user);
  }

  return true;
});

export default getAvailableSections;
