import { find, get } from 'lodash';

const displayFromChoices = key => ({ data, colDef }) => {
  const choices = get(colDef, 'definition.choices');
  if (!choices || !choices.length) return null;

  const value = data[key];
  const choice = find(choices, { value });
  return choice ? choice.display_name : value;
};

export default displayFromChoices;
