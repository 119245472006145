import React from 'react';
import { Tooltip, Icon, Popconfirm } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import { gettext } from '../../../../logic/utilities/languageUtility';
import { ActionsCellWrapper, UsernameCell } from '../../../../components/gridCellRenderers';
import appRoutes from '../../../../components/App/Router/appRoutes';

const text = {
  MODIFY_ICON_TOOLTIP: gettext('Modify'),
  DUPLICATE_ICON_TOOLTIP: gettext('Copy'),
  APPLICATION_COLUMN_LABEL: gettext('Template type'),
  ARE_YOU_SURE: gettext('Are you sure you want to delete template?'),
  CANCEL: gettext('Cancel'),
  DELETE: gettext('Confirm'),
  PREVIEW_EMAIL_TEMPLATE: ('Preview email template'),
};

const templateGroupsCell = ({ value }) => {
  const groups = value.map(group => group.name);
  return <span>{groups.join(',')}</span>;
};

const actionIcons = (modify, copy, remove, previewEmailTempalte) => ({
  ...(previewEmailTempalte && {
    previewEmailTempalte: (onPreviewEmailTemplate, data) => (
      <div key="PreviewEmailTemplate" style={{ cursor: 'pointer' }} onClick={() => onPreviewEmailTemplate(data)}>
        <Tooltip title={text.PREVIEW_EMAIL_TEMPLATE} placement="top">
          <Icon name="View" />
        </Tooltip>
      </div>
    ),
  }),
  ...(modify && {
    modify: (_, { id }) => {
      const url = generatePath(appRoutes.EMAIL_TEMPLATE, { id });
      return (
        <div key="Modify">
          <Link to={url}>
            <Tooltip title={text.MODIFY_ICON_TOOLTIP} placement="top">
              <Icon name="Edit" />
            </Tooltip>
          </Link>
        </div>
      );
    },
  }),
  ...(copy && {
    copy: (_, { id }) => {
      const url = generatePath(appRoutes.EMAIL_TEMPLATE_CREATE, { id });
      return (
        <div key="Copy">
          <Link to={url}>
            <Tooltip title={text.DUPLICATE_ICON_TOOLTIP} placement="top">
              <Icon name="Duplicate" />
            </Tooltip>
          </Link>
        </div>
      );
    },
  }),
  ...(remove && {
    remove: (onRemove, data) => {
      const label = `${text.ARE_YOU_SURE} ${data.name}`;
      return (
        <Tooltip key="remove" title={text.ARE_YOU_SURE}>
          <Popconfirm
            type="destructive"
            icon="Delete"
            label={label}
            onConfirm={() => onRemove(data)}
            buttonLabels={{
              cancel: text.CANCEL,
              confirm: text.DELETE,
            }}
          >
            <Icon name="Delete" />
          </Popconfirm>
        </Tooltip>
      );
    },
  }),
});

export default ({ modify, copy, remove, previewEmailTempalte, previewUser }) => {
  const actions = {
    ...(previewEmailTempalte && { previewEmailTempalte }),
    ...(modify && { modify: null }),
    ...(copy && { copy: null }),
    ...(remove && { remove }),
  };

  return {
    uploaded_by: {
      cellRendererFramework: UsernameCell(previewUser, { isStaff: true }),
    },
    modified_by: {
      cellRendererFramework: UsernameCell(previewUser, { isStaff: true }),
    },
    template_groups: {
      cellRendererFramework: templateGroupsCell,
    },
    application: {
      headerName: text.APPLICATION_COLUMN_LABEL,
    },
    actions: {
      label: '',
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(previewEmailTempalte, modify, copy, remove)),
      pinned: 'right',
      width: 120,
    },
  };
};
