import React, { Component, Fragment } from 'react';
import { func } from 'prop-types';
import { notifier } from 'tc-biq-design-system';

import connect from '../../../../../../logic/connect';
import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import modifiers from './modifiers';
import EditCorporateRiskSidepanel, { sidepanelID, api } from './EditCorporateRiskSidepanel';
import to from '../../../../../../logic/utilities/to';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../logic/services/acl';

const tableKey = 'CORPORATE_TABLE';
const reducerKey = 'RISK_RATING_SYSTEM'; 

const text = {
  DELETE_MSG_SUCCESS: gettext('Risk group deleted successfully'),
};

const countryRisksConfig = () => ({
  reducerKey,
  apiUrl: 'settings/risk_rating/user_attrs',
  tableKey,
});

const propTypes = {
  openSidePanel: func.isRequired,
  closeSidePanel: func.isRequired,
  dispatch: func.isRequired,
};

class Corporate extends Component {
  constructor(props) {
    super(props);
    
    this.tableData = withTable(GridTab, countryRisksConfig, null, { withActions: true })();
    const { openSidePanel } = this.props;
    this.actions = {
      modify: hasAccess('settings.risk_rating.user_attrs.update') && ((data) => { openSidePanel(sidepanelID, data); }),
      remove: hasAccess('settings.risk_rating.user_attrs.destroy') && this.onRemove,
    };
  }

  onRemove = async ({ id }) => {
    const { closeSidePanel, dispatch } = this.props;
    const promise = api.destroy(id);

    const [error] = await to(promise);

    if (!error) {
      notifier.success(text.DELETE_MSG_SUCCESS);
      closeSidePanel(sidepanelID);
      dispatch(this.tableData[1].fetchTableData());
    }
  }

  render() {
    const [CountryRiskTable, actions] = this.tableData;
    const { closeSidePanel, openSidePanel } = this.props;

    return (
      <Fragment>
        <CountryRiskTable
          singleActions={!!(this.actions.modify || this.actions.remove)}
          modifier={modifiers(this.actions)}
        />
        <EditCorporateRiskSidepanel 
          openSidePanel={openSidePanel}
          closeSidePanel={closeSidePanel}
          fetchTableData={actions.fetchTableData}
        />
      </Fragment>  
    );
  } 
}

Corporate.propTypes = propTypes;

export default connect(null, dispatch => ({ dispatch }))(Corporate);
