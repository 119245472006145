/* eslint-disable react/prop-types */
import React from 'react';
import {
  StateCell,
  OnContentClickCell,
  UsernameCell,
  TeamCell,
} from '../../../../components/gridCellRenderers';
import { currencyFormatter } from '../../../../logic/utilities/formatters';
import { gettext } from '../../../../logic/utilities/languageUtility';
import YesNoCell from '../../../../components/gridCellRenderers/YesNoCell';
import { strToBool } from '../../../../utils/strToBool';

const WalletCell = ({ value }) => {
  if (!value) return null;
  const { backend } = value;
  if (!backend) return null;
  const id = backend.type === 'cloudtrade' ? value.external_user_id : value.external_id;
  return <div>{id}, {backend.name}</div>;
};

export default ({ previewUser, previewBiqUser, openTransaction }) => ({
  id: {
    cellRendererFramework: OnContentClickCell(openTransaction),
    sortValue: -3,
    width: 80,
  },
  thirdparty_id: {
    sortValue: -2,
  },
  user: {
    cellRendererFramework: UsernameCell(previewUser),
    width: 200,
  },
  wallet: {
    cellRendererFramework: WalletCell,
    width: 250,
  },
  first_name: {
    width: 150,
  },
  last_name: {
    width: 150,
  },
  payment_state: {
    cellRendererFramework: StateCell,
    valueGetter: ({ data }) => (data.payment_state ? data.payment_state : gettext('N/A')),
  },
  tpi_state: {
    cellRendererFramework: StateCell,
  },
  team: {
    cellRendererFramework: TeamCell,
  },
  security_check: {
    cellRendererFramework: StateCell,
    valueGetter: ({ data }) => (data.security_check ? data.security_check : gettext('N/A')),
  },
  value: {
    valueGetter: ({ data }) => currencyFormatter(data.value, data.currency.symbol),
  },
  invited_by: {
    cellRendererFramework: UsernameCell(previewUser, { isIb: true }),
  },
  assigned_to: {
    cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
  },
  challenge: {
    cellRendererFramework: YesNoCell,
    valueGetter: ({ data }) => (data.challenge ? strToBool(data.challenge) : null),
  },
  card_exp: {
    valueGetter: ({ data }) => (data.card_exp ? data.card_exp : gettext('N/A')),
  },
  fee: {
    quickFilter: {},
  },
});
