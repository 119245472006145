import { bindActionCreators } from 'redux';

import CommunicationFlow from './CommunicationFlow';
import connect from '../../../../../../logic/connect';
import { actions } from '../Model';
import { hasAccess } from '../../../../../../logic/services/acl';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const api = getDjangoApi('communication_flow');
const hasPermission = () => ({
  update: hasAccess('communication_flow.update'),
  create: hasAccess('communication_flow.create'),
  list: hasAccess('communication_flow.list'),
});

const mapStateToDispatch = ({ pages }) => ({
  ...pages.COMMUNICATION_FLOW,
  hasPermission,
  api,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...actions,
  }, dispatch),
});

export default connect(mapStateToDispatch, mapDispatchToProps)(CommunicationFlow);
