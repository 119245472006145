import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import Join, { FORM_KEY } from './Join';
import connect from '../../../logic/connect';
import { FormActionsFactory } from '../../../components/form';
import withErrorBoundary from '../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../components/App/Router/appRoutes';
import { loadAuthSession } from '../../../logic/session/Model';

const formActions = FormActionsFactory(FORM_KEY);

const mapStateToProps = ({ forms }) => ({ ...forms[FORM_KEY] });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...formActions, loadAuthSession }, dispatch),
});

const JoinPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(Join);

export default {
  component: JoinPage,
  anonymousAccess: true,
  aclFn: () => true,
  path: appRoutes.JOIN,
};
