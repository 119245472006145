import React, { Fragment, useEffect } from 'react';
import { object, arrayOf, shape, string, func, node, bool } from 'prop-types';
import { Notification, Spinner } from 'tc-biq-design-system';

import { gettext } from '../../logic/utilities/languageUtility';
import PageHeader from '../PageHeader';
import appRoutes from '../App/Router/appRoutes';

import './Page.scss';

const BREAD = {
  label: string.isRequired,
  route: string.isRequired,
};

const defaultPageStyle = {
  margin: '0 20px',
};

const propTypes = {
  bread: arrayOf(shape(BREAD)),
  headerActions: func,
  children: node,
  style: object,
  hideHeader: bool,
  title: string,
  topBar: func,
  loading: bool,
};

const defaultProps = {
  bread: [],
  headerActions: null,
  children: null,
  style: defaultPageStyle,
  hideHeader: false,
  topBar: null,
  loading: false,
  title: '',
};

const text = {
  HOME_LABEL: gettext('BrokerIQ'),
};

const getHeaderProps = (props) => {
  const { headerActions, bread, title } = props;

  return {
    pageTitle: title,
    breads: [{ label: text.HOME_LABEL, route: appRoutes.DASHBOARD }, ...bread],
    actions: headerActions ? headerActions() : null,
  };
};

const Page = ({ children, headerActions, bread, style, hideHeader, title, topBar, loading }) => {
  useEffect(() => {
    document.title = title || gettext('Backoffice 3.0');
  }, [title]);
  
  return (
    <div className="biq-page">
      {loading ? (
        <div className="biq-page__loader"><Spinner size="large" /></div>
      ) : (
        <Fragment>
          {topBar && topBar()}
          {!hideHeader && <PageHeader {...getHeaderProps({ headerActions, bread, title })} />}
          {children && <div className="biq-body" style={style}>{children}</div>}
          <Notification />
        </Fragment>
      )}
    </div>
  );
};

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
