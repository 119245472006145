/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import { withRouter } from 'react-router-dom';
import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import Page from '../../../../components/Page';
import GridFactory from '../../../../components/grid';
import Export from '../../../../components/Export';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import overlayActions from '../../../../components/overlay';
import modifier from './modifier';
import { WithdrawalRequest, TransactionSingle, UserPreview, BiqUserPreview, TradingAccount } from '../../../../components/common/sidepanels';
import { SIDEPANEL_ID as TRADING_ACCOUNT_SIDEPANEL_ID } from '../../../../components/common/sidepanels/TradingAccount';
import { getWithdrawalDetails, isMethodVisible } from '../../../../components/common/sidepanels/WithdrawalRequest';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';
import { toSingleItemUrl } from '../../../../logic/utilities/toPath';

const pageConfig = {
  apiUrl: 'withdrawal_history',
  reducerKey: 'WITHDRAWAL_HISTORY',
  tableKey: 'WITHDRAWAL_HISTORY_TABLE',
};

const text = {
  TITLE: gettext('Withdrawal history'),
};

const SIDEPANEL_ID = 'WITHDRAWAL_REQUEST';
const bread = [{ label: text.TITLE, route: 'home.payments-withdrawal-history' }];
const { GridComponent, actions } = GridFactory(pageConfig);
const transactionApi = getDjangoApi('payment_transactions');
class WithdrawalHistory extends Component {
  constructor(props) {
    super(props);

    this.headerActions = this.headerActions.bind(this);
    this.openSingleTransaction = this.openSingleTransaction.bind(this);
    this.openTradingAccountSidepanel = this.openTradingAccountSidepanel.bind(this);

    this.tableActions = {
      openWithdrawalRequest: this.openWithdrawalRequest.bind(this),
      openHistoryWithdrawalRelatedTransaction: this.openHistoryWithdrawalRelatedTransaction.bind(this),
      previewUser: props.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
      previewBiqUser: props.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
    const id = props.match?.params.id;
    if (id) this.openWithdrawalRequest({ id });
  }

  async openWithdrawalRequest(data) {
    const { actions } = this.props;
    const response = await getDjangoApi(pageConfig.apiUrl).retrieve(data.id);
    let sidepanelData = response.data;
    if (sidepanelData.payment_gateway_account) {
      const { id } = sidepanelData.payment_gateway_account;
      const { method } = sidepanelData;
      if (isMethodVisible(method)) {
        const detailsResponse = await getWithdrawalDetails(method).retrieve(id);
        sidepanelData = { ...sidepanelData, preferredMethods: detailsResponse.data };
      }
    }
    actions.openSidepanel(SIDEPANEL_ID, sidepanelData);
    window.history.replaceState(null, '', toSingleItemUrl(data.id));
  }

  openHistoryWithdrawalRelatedTransaction(data) {
    this.openSingleTransaction(data.id);
  }

  async openSingleTransaction(id) {
    const { actions } = this.props;
    const response = await transactionApi.retrieve(id);
    actions.openSidepanel('TRANSACTION_SINGLE', response.data);
  }

  async openTradingAccountSidepanel({ id, userId }) {
    const { actions } = this.props;
    const response = await getDjangoApi(`users/${userId}/trading_accounts/${id}`).list();
    actions.openSidepanel(TRADING_ACCOUNT_SIDEPANEL_ID, response.data);
  }

  headerActions() {
    const { exportUrl } = this.props;
    return hasAccess('withdrawals.history.export.*') && <Export url={exportUrl} />;
  }

  render() {  
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions, table.data),
    };

    return (
      <Page headerActions={this.headerActions} bread={bread} title={text.TITLE}>
        <Filters {...filterProps} />
        <GridComponent tableModifier={modifier(this.tableActions, table.data)} {...this.props} />
        <WithdrawalRequest openTransactionSingle={this.openSingleTransaction} openTradingAccountSidepanel={this.openTradingAccountSidepanel} />
        <TradingAccount />
        <TransactionSingle />
        <UserPreview />
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { 
      changePageSize: actions.changePageSize,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      openSidepanel: overlayActions.open, 
    }, 
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(WithdrawalHistory);
