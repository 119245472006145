import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Sidepanel, notifier } from 'tc-biq-design-system'; 
import { object, bool } from 'prop-types';

import connect from '../../../../../../../logic/connect';
import to from '../../../../../../../logic/utilities/to';
import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { fetchUser } from '../../../Model';
import ExternalTransferForm from './ExternalTransferForm';
import { FormActionsFactory } from '../../../../../../../components/form';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const FORM_KEY = 'EXTERNAL_TRANSFER_FORM';
const { create } = FormActionsFactory(FORM_KEY);

const text = {
  TITLE: gettext('External transfer'),
  SOURCE_CLIENT: gettext('Source client'),
  SUCCESS: gettext('Amount is transferred.'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  submitInProgress: bool.isRequired,
  isIb: bool,
};

const defaultProps = {
  user: null,
  actions: null,
  isIb: false,
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_KEY}
  />
);

class ExternalTransfer extends Component {
  onSubmit = async () => {
    const api = getDjangoApi('external_transfer');
    const { actions } = this.props;
    const [err] = await to(actions.create(api, this.getRequestPayload));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess = () => {
    this.onClose();
    notifier.success(text.SUCCESS);
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }


  onClose = () => {
    const { sidepanelManager } = this.props;
    sidepanelManager.close();
  }

  getRequestPayload = (values) => {
    const { 
      amount, 
      target_amount, 
      exchange_rate, 
      account_from, 
      account_to,
    } = _.reduce(values, (payload, field, key) => ({
      ...payload,
      [key]: typeof field === 'object' && !_.isEmpty(field) ? field.id : field,
    }), {});

    const override_exchange_rate = Number(target_amount / amount).toFixed(2);
    const request = {
      account_from,
      account_to,
      amount,
    };
     
    if (override_exchange_rate !== exchange_rate) {
      return { ...request, override_exchange_rate };
    }

    return request;
  }


  render() {
    const { sidepanelManager, user, isIb, actions, submitInProgress, ...options } = this.props;
    return (
      <Sidepanel {...options} footerRender={customFooter(this.onSubmit, this.onClose, submitInProgress)}>
        <ExternalTransferForm 
          user={user} 
          sidepanelManager={sidepanelManager} 
        />
      </Sidepanel>
    );
  }
}

ExternalTransfer.propTypes = propTypes;
ExternalTransfer.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      create,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ forms }) => {
  const form = forms[FORM_KEY];
  return {
    submitInProgress: form.submitInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalTransfer);
