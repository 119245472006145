import React from 'react';
import { Button, Dropdown } from 'tc-biq-design-system';
import { func } from 'prop-types';

import { gettext } from '../../logic/utilities/languageUtility';
import { makeSegmentList } from './filterUtils';
import { segmentsType } from './FilterTypes';

import './ViewDropdown.css';

const propTypes = {
  segments: segmentsType.isRequired,
  onSegmentClick: func.isRequired,
};

const text = {
  SAVED_VIEWS: gettext('Saved views'),
};

const customDropdownHeader = selectedSegmentName => toggleHandler => (
  <Button onClick={toggleHandler} color="ghost" icon="Funnel" key="filter-header">
    {selectedSegmentName || text.SAVED_VIEWS}
  </Button>
);

const ViewDropdown = ({ segments, onSegmentClick }) => {
  const segmentsDropdownItems = makeSegmentList(segments, onSegmentClick);
  const { selectedSegment } = segments;
  let label = '';
  if (selectedSegment.id === 'default') {
    label = text.SAVED_VIEWS;
  } else {
    label = selectedSegment.name;
  }
  return (
    <span className="view-dropdown">
      <Dropdown list={segmentsDropdownItems} customHeader={customDropdownHeader(label)} />
    </span>
  );
};

ViewDropdown.propTypes = propTypes;

export default ViewDropdown;
