import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { notifier } from 'tc-biq-design-system';
import { func } from 'prop-types';
import { generatePath } from 'react-router-dom';

import withTable, { GridTab } from '../../../../../components/grid/withTable';
import modifiers from './modifiers';
import { hasAccess } from '../../../../../logic/services/acl';
import connect from '../../../../../logic/connect';
import to from '../../../../../logic/utilities/to';
import overlayActions from '../../../../../components/overlay';
import { millisecondsToTimeframeModel } from '../../../../../components/form/Components/Inputs/TimeFrame';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import appRoutes from '../../../../../components/App/Router/appRoutes';

import './index.scss';

const text = {
  TOGGLE_ENABLE_MESSAGE: 'Withdrawal amounts rule enabled',
  TOGGLE_DISABLE_MESSAGE: 'Withdrawal amounts rule disabled',
};

const gridConfig = () => ({
  apiUrl: 'deposit_state_rules',
  reducerKey: 'DEPOSITS',
  tableKey: 'STATES_RULES',
});

class StateRules extends Component {
  constructor({ dispatch }) {
    super();
    this.Grid = withTable(GridTab, gridConfig, null, { withActions: true })();

    const [, tableActions] = this.Grid;
    this.actions = {
      ...bindActionCreators({
        openSidepanel: overlayActions.open,
        ...tableActions,
      }, dispatch),
    };
    this.tableActions = {
      toggleEnable: hasAccess('deposit_state_rules.update') && this.toggleEnable,
      onModify: hasAccess('deposit_state_rules.update') && this.onModify,
      previewBiqUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
    this.state = {
      defaultTimeframe: null,
    };
    const defaultTimeframeApi = getDjangoApi('deposit_state_rules/default_timeframe_milliseconds');
    defaultTimeframeApi.retrieve()
      .then(({ data }) => this.setState({ defaultTimeframe: data.default_timeframe_milliseconds }));
  }

  onDelete = (data) => {
    this.actions.deleteRow(data);
  }

  onModify = data => generatePath(appRoutes.SETTINGS_STATE_RULES, { id: data.id })

  onClone = data => generatePath(appRoutes.SETTINGS_STATE_RULES_CLONE, { id: data.id })

  toggleEnable = async ({ id }, enabled) => {
    const [err] = await to(this.actions.updateRowData(id, { enabled }));
    if (err) return notifier.error(err.data.msg);
    notifier.success(enabled ? text.TOGGLE_ENABLE_MESSAGE : text.TOGGLE_DISABLE_MESSAGE);
  }

  render() {
    const { defaultTimeframe } = this.state;
    const [GridComponent] = this.Grid;
    const { delay, type } = millisecondsToTimeframeModel(defaultTimeframe);

    return (
      <Fragment>
        <small><strong>Pending Payment state and TPI state will automatically be updated after {delay} {type.display_name} to Failed and N/A respectively. Below created rules are exception cases per specific payment methods.</strong></small>
        <GridComponent singleActions={!!Object.keys(this.tableActions).length} modifier={modifiers(this.tableActions)} />
      </Fragment>
    );
  }
}


StateRules.propTypes = {
  dispatch: func.isRequired,
};
const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(StateRules);
