import { arrayOf, func, node, oneOfType } from 'prop-types';
import React, { Component } from 'react';

const propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  onLoadMore: func.isRequired,
};

class LoadMore extends Component {
  constructor(props) {
    super(props);
    this.loadMoreRef = React.createRef();
    this.onScroll = _.throttle(this.onScroll, 1000);
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
  }
  
  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }
  
  onScroll = () => {
    if (this.isBottom()) {
      const { onLoadMore } = this.props;
      onLoadMore();
    }
  };

  isBottom() {
    return this.loadMoreRef.current.getBoundingClientRect().bottom <= window.innerHeight;
  }

  render() {
    const { children } = this.props;
    return <div ref={this.loadMoreRef}>{children}</div>;
  }
}

LoadMore.propTypes = propTypes;

export default LoadMore;
