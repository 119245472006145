import React from 'react';
import { object, string } from 'prop-types';
import { Pill } from 'tc-biq-design-system';
import { gettext } from '../../logic/utilities/languageUtility';

export const statePillTypeMap = {
  [gettext('Accepted')]: 'brand',
  [gettext('Cancelled')]: 'status04',
  [gettext('Failed')]: 'status04',
  [gettext('Closed')]: 'status04',
  [gettext('Success')]: 'status01',
  [gettext('Open')]: 'status01',
  [gettext('Auto Accepted')]: 'status01',
  [gettext('Manually Accepted')]: 'status01',
  [gettext('Review')]: 'status02',
  [gettext('N/A')]: 'neutral',
  [gettext('Buy')]: 'status01',
  [gettext('Sell')]: 'status04',
  [gettext('Pending')]: 'status03',
  [gettext('Approved')]: 'status01',
  [gettext('Rejected')]: 'status04',
  [gettext('Deleted')]: 'status04',
  [gettext('Clear')]: 'status01',
  [gettext('Consider')]: 'status04',
  [gettext('Full')]: 'status01',
  [gettext('Demo')]: 'primary',
  [gettext('Limited')]: 'status03',
  [gettext('Blacklist')]: 'status04',
  [gettext('Archived')]: 'status04',
  [gettext('Lead')]: 'brand',
  [gettext('Rejected')]: 'status04',
  [gettext('Incomplete')]: 'neutral',
  [gettext('KYC checks in progress')]: 'status02',
  [gettext('Exclusion')]: 'status04',
  [gettext('Unsuitable')]: 'status04',
  [gettext('Pre-approved')]: 'status03',
  [gettext('Yes')]: 'status01',
  [gettext('No')]: 'status04',
};

const propTypes = {
  value: string,
  mapper: object,
};

const defaultProps = {
  value: gettext('N/A'),
  mapper: null,
};

const StateCell = ({ value, mapper }) => (
  <Pill type={(mapper || statePillTypeMap)[value]}>
    {value}
  </Pill>
);

StateCell.propTypes = propTypes;
StateCell.defaultProps = defaultProps;
export default StateCell;
