import React, { Component } from 'react';
import { Modal,
  Button,
  Icon,
  Space,
  notifier,
} from 'tc-biq-design-system';
import {
  array,
  func,
  number,
  node,
  object,
} from 'prop-types';
import { bindActionCreators } from 'redux';

import FormFactory, { FormActionsFactory } from '../../../form';
import { gettext } from '../../../../logic/utilities/languageUtility';
import connect from '../../../../logic/connect';
import to from '../../../../logic/utilities/to';

import './RejectionReasonModal.scss';
import overlayActions from '../../../overlay';

export const modalModes = {
  EDIT: 'edit',
  CREATE: 'create',
};

const text = {
  SAVE: gettext('Save'),
  CANCEL: gettext('Cancel'),
  NAME_TITLE: gettext('Name'),
  ADD_DESCRIPTION: gettext('Add description'),
  CREATE_SUCCESS: gettext('Successfully created rejection reason'),
  EDIT_SUCCESS: gettext('Successfully edited rejection reason'),
  ERROR_MSG: gettext('This field is required'),
  CREATE_REASON_TITLE: gettext('Create rejection reason'),
  EDIT_REASON_TITLE: gettext('Edit rejection reason'),
};

const FORM_ID = 'REJECT_REASONS_FORM';
export const MODAL_ID = 'REJECT_REASONS_FORM_MODAL';

const { setFieldValue, setFieldsErrors, create, update } = FormActionsFactory(FORM_ID);

const formConfig = (onChangeLanguage, onChangeDescription) => ({
  form: FORM_ID,
  customFields: [
    {
      id: 'languages',
      type: 'select',
      labelKey: 'display_name',
      valueKey: 'value',
      customOnChange: onChangeLanguage,
    },
    {
      id: 'description',
      type: 'text',
      customOnChange: onChangeDescription,
    },
    {
      id: 'name',
      type: 'text',
      label: text.NAME_TITLE,
    },
  ],
});

const formFieldValueId = 'reasons';
const defaultDescriptionKey = 'description';
const nameId = 'name';
const activeID = 'is_active';

const Field = ({ field, ...otherProps }) => React.cloneElement(field, {
  ...otherProps,
});

const ReasonField = ({ 
  languagesField,
  descriptionField,
  index,
  error,
  reason,
  languages,
  removeDescription,
}) => {
  const isDefaultDescription = reason.value === defaultDescriptionKey;

  return (
    <div className="reason-field">
      <Field
        options={languages}
        helpText={error.langError}
        hasError={!!error.langError}
        field={languagesField}
        value={{ value: reason.value, display_name: reason.display_name }}
        disabled={isDefaultDescription}
        index={index}
        styles={{ menuPortal: ({ zIndex, ...provided }) => ({ ...provided, zIndex: 10000 }) }}
        menuPortalTarget={document.body}
        clearable={false}
      />
      <Field 
        field={descriptionField}
        index={index}
        hasError={!!error.descError}
        helpText={error.descError}
        value={reason.descriptionValue}
      />
      { !isDefaultDescription && (
      <Icon
        name="Delete"
        className="reason-field__delete"
        onClick={() => {
          removeDescription(index);
        }}
        style={{ cursor: 'pointer' }}
      />
      )}
    </div>
  );
};

ReasonField.propTypes = {
  languagesField: node.isRequired,
  descriptionField: node.isRequired,
  index: number.isRequired,
  languages: array,
  error: object.isRequired,
  reason: object.isRequired,
  removeDescription: func.isRequired,
};

ReasonField.defaultProps = {
  languages: [],
};

class RejectionReasonModal extends Component {
  constructor(props) {
    super(props);
    this.Form = null;
    this.state = {
      originalDescriptions: {},
      languages: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { fields: oldFields } = prevProps;
    const { fields } = this.props;
    const mode = this.getMode();
    
    if (mode === modalModes.CREATE && fields.length && !oldFields.length) {
      this.loadLanguages().then(this.loadValuesOnCreate);
    } 

    if (mode === modalModes.EDIT && fields.length && !oldFields.length) {
      this.loadLanguages().then(this.loadValuesOnEdit);
    }
  }

  onChangeLanguage = ({ value }, index) => {
    const { values: { reasons } } = this.props;

    this.setFieldValue({
      id: formFieldValueId,
      value: reasons.map((reason, i) => {
        if (i === index) {
          return {
            ...reason,
            ...value,
          };
        } 
        return reason;
      }),
    });
  };

  onChangeDescription = ({ value }, index) => {
    const { values: { reasons } } = this.props;
    this.setFieldValue({
      id: formFieldValueId,
      value: reasons.map((reason, i) => {
        if (i === index) {
          return {
            ...reason,
            descriptionValue: value,
          };
        } 
        return reason;
      },
      ), 
    });
  };
  
  setFieldValue = ({ id, value }) => {
    const { actions } = this.props;
    actions.setFieldValue({
      id,
      value,
    });
  }

  getModalParams = () => {
    const { modal } = this.props;
    return modal.parameters || {};
  }

  getMode = () => {
    const { mode } = this.getModalParams();
    return mode;
  }

  loadLanguages = async () => {
    const { api } = this.props;
    const { data } = await api.options();

    const languages = data.actions.POST.fields.map(({ key, label }) => ({
      value: key,
      display_name: key === 'description' ? `${label} [EN]` : label,
    }));

    this.setState({ languages });

    return languages;
  }

  loadValuesOnCreate = () => {
    const { languages } = this.state;
    const description = languages.find(({ value }) => value === defaultDescriptionKey);
    if (description) {
      this.setFieldValue({ id: formFieldValueId, value: [{ ...description, descriptionValue: '' }] });
    }
    this.setFieldValue({ id: nameId, value: '' });
  };

  addDescription = () => {
    const { values: { reasons } } = this.props;
    const newDescription = {
      value: null,
      display_name: null,
      descriptionValue: '',
    };

    this.setFieldValue({
      id: formFieldValueId,
      value: [...reasons, newDescription],
    });
  }

  removeDescription = (index) => {
    const { values: { reasons } } = this.props;

    this.setFieldValue({
      id: formFieldValueId,
      value: reasons.filter((_, i) => index !== i),
    });
  };

  loadValuesOnEdit = () => {
    const { languages } = this.state;
    const { api } = this.props;
    const { reasonId } = this.getModalParams();
    
    const langMap = languages.reduce((acc, reason) => ({
      ...acc,
      [reason.value]: reason.display_name,
    }), {});

    api.retrieve(reasonId).then(({ data }) => {
      this.setFieldValue({ id: nameId, value: data.name });
      const descriptionKeys = Object.keys(data).filter(key => key.startsWith('description'));
      this.setState({
        originalDescriptions: descriptionKeys,
      });
      const reasons = descriptionKeys.map(key => ({
        value: key,
        display_name: langMap[key],
        descriptionValue: data[key],
      }));
      this.setFieldValue({
        id: formFieldValueId,
        value: reasons,
      });
    });
  };

  
  cancel = () => {
    const { actions } = this.props;
    actions.closeModal(MODAL_ID);
  }
  
  footerRender = () => (
    <React.Fragment>
      <Button onClick={this.createReason} type="submit" formId={FORM_ID}>
        { text.SAVE }
      </Button>
      <Button onClick={this.cancel}>
        { text.CANCEL }
      </Button>
    </React.Fragment>
  );

  validateForm = (values) => {
    if (!values.reasons) return [];
    const errors = values.reasons.map((r) => {
      if (!r.value && r.descriptionValue) {
        return {
          langError: text.ERROR_MSG,
        };
      } if (r.value && !r.descriptionValue) {
        return {
          descError: text.ERROR_MSG,
        };
      } 
      return null;
    });
    return errors;
  }

  createReason = async () => {
    const { values, onSuccess, actions, api } = this.props;
    const { reasonId, mode } = this.getModalParams();

    if (!values.name) {
      actions.setFieldsErrors({
        name: [text.ERROR_MSG],
      });
      return;
    }

    const errors = this.validateForm(values);
    
    if (errors.filter(e => !!e).length) {
      this.setFieldValue({ id: 'errors', value: errors });
      return;
    }

    const getRemovedDescriptions = () => {
      const { originalDescriptions } = this.state;
      const payloadDescriptions = values.reasons.map(({ value }) => value);
      const removedDescriptions = originalDescriptions.filter(desc => !payloadDescriptions.includes(desc));
      return removedDescriptions.reduce((acc, desc) => ({
        ...acc,
        [desc]: null,
      }), {});
    };

    const removedDescriptions = mode === modalModes.EDIT ? getRemovedDescriptions() : {}; 

    const payload = {
      name: values.name,
      ...(values.reasons || []).reduce((acc, r) => ({
        ...acc,
        [r.value]: r.descriptionValue,
      }), {}),
      ...removedDescriptions,
    };

    const promise = mode === modalModes.CREATE
      ? actions.create(api, () => payload)
      : actions.update(api, reasonId, () => payload, true); 

    const [err] = await to(promise);

    if (!err) {
      this.cancel();
      onSuccess();
      notifier.success(text.CREATE_SUCCESS);
    }
  };
  
  renderForm = () => {
    if (!this.Form) {
      this.Form = FormFactory(formConfig(this.onChangeLanguage, this.onChangeDescription));
    }

    const { Form } = this;

    return Form;
  }

  render() {
    const params = this.getModalParams();
    if (!params) return null;
    const {
      values,
      values: { errors },
      modal,
    } = this.props;
    const { languages } = this.state;
  
    const title = modalModes.CREATE === params.mode ? text.CREATE_REASON_TITLE : text.EDIT_REASON_TITLE;
    const Form = this.renderForm();

    const langValues = (values.reasons || []).map(l => l.value);
    const validLanguages = languages.filter(l => !langValues.includes(l.value)
         && l.value !== nameId 
         && l.value !== defaultDescriptionKey 
         && l.value !== activeID);

    return (
      <Modal 
        footerRender={this.footerRender}
        className="rejection-reason-modal"
        visible={modal.visible}
        title={title}
      >
        <Form
          formId={FORM_ID}
          renderForm={formFields => (
            <div>
              { formFields.name }
              { (values.reasons || []).map((reason, index) => (
                <ReasonField 
                  descriptionField={formFields.description}
                  languagesField={formFields.languages}
                  index={index}
                  languages={validLanguages}
                  reason={reason}
                  error={(errors && errors[index]) || {}}
                  removeDescription={this.removeDescription}
                  onChangeDescription={this.onChangeDescription}
                  onChangeLanguage={this.onChangeLanguage}
                />
              ))}
              <Space size={16} />
              { !!validLanguages.length && (
              <Button 
                onClick={this.addDescription} 
                color="primary"
                size="small"
                style={{ marginLeft: '0' }}
                icon="Plus"
                iconPosition="left"
              >
                { text.ADD_DESCRIPTION }
              </Button>
              )}
            </div>
          )}
        />
      </Modal>
    );
  }
} 

RejectionReasonModal.propTypes = {
  onSuccess: func.isRequired,
  actions: object.isRequired,
  values: object,
  fields: array,
  modal: object,
  api: object.isRequired,
};

RejectionReasonModal.defaultProps = {
  values: {},
  fields: [],
  modal: {},
};

const mapStateToProps = ({ forms, overlays }) => ({
  ...forms[FORM_ID],
  modal: overlays[MODAL_ID],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    setFieldsErrors,
    create,
    update,
    setFieldValue,
    closeModal: overlayActions.close,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectionReasonModal);
