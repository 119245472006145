import React, { Component } from 'react';
import { array, bool, func, object } from 'prop-types';
import { InfoBox, notifier, Sidepanel, Space } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import connect from '../../../../logic/connect';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import FormFactory, { FormActionsFactory } from '../../../../components/form';
import overlayActions from '../../../../components/overlay';
import { SidepanelFooter } from '../../../../components/common';
import { gettext } from '../../../../logic/utilities/languageUtility';
import PaymentUtilityService from '../../../../logic/services/payment-utility';
import to from '../../../../logic/utilities/to';
import If from '../../../../components/If';
import { Field, formErrorHandler } from '../../../../components/form/logic/utils';

const propTypes = {
  onSuccess: func,
  actions: object.isRequired,
  sidepanel: object.isRequired,
  fields: array.isRequired,
  submitInProgress: bool,
  values: object,
};

const defaultProps = {
  onSuccess: null,
  submitInProgress: false,
  values: {},
};

const api = getDjangoApi('payment_reasons_settings');
export const SIDEPANEL_ID = 'ADD_EDIT_TRANSACTION_REASON';

const text = {
  TITLE: gettext('Create payment transaction reason'),
  TITLE_CREATE: gettext('Create reason'),
  TITLE_CLONE: gettext('Clone reason'),
  TITLE_EDIT: gettext('Edit reason'),
  CREATE_BUTTON_LABELS: {
    confirm: gettext('Create the reason'),
    cancel: gettext('Discard'),
  },
  CLONE_BUTTON_LABELS: {
    confirm: gettext('Clone the reason'),
    cancel: gettext('Discard'),
  },
  EDIT_BUTTON_LABELS: {
    confirm: gettext('Edit the reason'),
    cancel: gettext('Discard'),
  },
  INFO: gettext('Info'),
  INFO_REASON: gettext('You will not be able to remove Reason once you add it. You will only be able to make it inactive.'),
  SUCCESS_TEXT: gettext('Transaction reason successfully saved'),
  CATEGORY: gettext('Category'),
  ERROR: gettext('Something went wrong'),
};


const getButtonLabels = (type) => {
  switch (type) {
    case 'clone':
      return text.CLONE_BUTTON_LABELS;
    case 'edit':
      return text.EDIT_BUTTON_LABELS;
    default:
      return text.CREATE_BUTTON_LABELS;
  }
};

const customFooter = (execute, close, submitInProgress, type) => (
  <SidepanelFooter
    execute={execute}
    close={close}
    submitInProgress={submitInProgress}
    confirmColor="primary"
    buttonLabels={getButtonLabels(type)}
    formId={SIDEPANEL_ID}
  />
);

class AddEditTransactionReason extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.state = {
      categoryOptions: [],
    };

    this.onClose = this.onClose.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { sidepanel, values, fields, actions } = this.props;
    const { parameters } = sidepanel;

    if (this.isTypeChanged(prevProps, values)) {
      this.resetCategoryValue(prevProps, values);
      this.getCategoryOptions(values, fields, actions);
    }

    if (this.setRowData(sidepanel, parameters)) {
      this.actions.setRowData(sidepanel.parameters.data);
    }
  }

  componentWillUnmount() {
    this.onClose();
  }

  onClose(success) {
    const { onSuccess } = this.props;

    this.actions.resetFields();
    this.actions.closeSidepanel(SIDEPANEL_ID);
    if (success) onSuccess();
  }

  getCategoryOptions(values, fields) {
    const categoryValues = PaymentUtilityService()
      .getPaymentCategoriesForTransactionType(_.get(values, 'type.value'));
    const category = fields.find(field => field.id === 'category') || {};
    const categoryOptions = _.filter(category.options, category => _.includes(categoryValues, category.value));

    this.setState({
      categoryOptions,
    });
  }

  setRowData(sidepanel) {
    if (sidepanel.visible && (this.isClone() || this.isEdit())) return true;

    return false;
  }

  getTitle(sidepanel) {
    if (sidepanel.visible && this.isClone()) {
      return text.TITLE_CLONE;
    }

    if (sidepanel.visible && this.isEdit()) {
      return text.TITLE_EDIT;
    } 

    return text.TITLE_CREATE;
  }

  getType() {
    const { sidepanel } = this.props;

    return sidepanel.parameters && sidepanel.parameters.type;
  }

  isTypeChanged(prevProps, values) {
    return _.get(prevProps, 'values.type.value') !== _.get(values, 'type.value');
  }

  isEdit() {
    return this.getType() === 'edit';
  }

  isClone() {
    return this.getType() === 'clone';
  }

  isCreate() {
    return this.getType() === 'add';
  }

  async submit() {
    const { sidepanel } = this.props;
    const { parameters } = sidepanel;
    const { data } = parameters;
    const request = this.isEdit() ? this.actions.update(api, data.id) : this.actions.create(api);
    const [err] = await to(request);

    if (err) {
      formErrorHandler(text.ERROR)(err);
    } else {
      notifier.success(text.SUCCESS_TEXT);
      this.onClose(true);
    }
  }

  resetCategoryValue(prevProps) {
    const { actions } = this.props;
    const shouldReset = _.get(prevProps, 'values.type');
    if (shouldReset) {
      actions.setFieldValue({
        id: 'category',
        value: null,
      });
    }
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory({ form: SIDEPANEL_ID, api });
    }

    const { FormComponent } = this;
    const { categoryOptions } = this.state;

    return (
      <FormComponent renderForm={formField => (
        <React.Fragment>
          { formField.name }
          { formField.type }
          <Field options={categoryOptions}>
            {formField.category}
          </Field>
          <Space size={16} />
          <If condition={!this.isEdit()}>
            <InfoBox header={text.INFO}>
              {text.INFO_REASON}
            </InfoBox>
          </If>
        </React.Fragment>
      )}
      />
    );
  }

  render() {
    const { sidepanel, submitInProgress } = this.props;

    return (
      <Sidepanel
        title={this.getTitle(sidepanel)}
        visible={sidepanel.visible}
        onCloseIconClick={() => this.onClose()}
        footerRender={() => customFooter(this.submit, this.onClose, submitInProgress, this.getType())}
      >
        { this.renderForm() }
      </Sidepanel>
    );
  }
}

AddEditTransactionReason.propTypes = propTypes;
AddEditTransactionReason.defaultProps = defaultProps;

const mapStateToProps = ({ overlays, forms }) => ({
  ...forms[SIDEPANEL_ID],
  sidepanel: overlays[SIDEPANEL_ID],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    ...FormActionsFactory(SIDEPANEL_ID), 
    closeSidepanel: overlayActions.close, 
  }, dispatch),
});

export default connect(
  mapStateToProps, 
  mapDispatchToProps,
)(withErrorBoundary(AddEditTransactionReason));
