import React from 'react';
import { bool } from 'prop-types';

import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import { hasAccess } from '../../../../../../logic/services/acl';
import SalesStatus from './SalesStatus';
import SalesSubStatus from './SalesSubStatus';
import SalesManager from './SalesManager';
import RetentionManager from './RetentionManager';
import RetentionPoolDesk from './RetentionPoolDesk';
import ComplianceStatus from './ComplianceStatus';

import './UserAssignments.scss';

const propTypes = {
  isIb: bool,
};

const defaultProps = {
  isIb: false,
};

const UserAssignments = ({ isIb }) => (
  <div className="biq-user-assign">
    {hasAccess('compliance_status.retrieve') && isFeatureEnabled()('COMPLIANCE_STATUS_ENABLED') && (
      <div className="biq-user-assign__item">
        <ComplianceStatus />
      </div>
    )}
    {hasAccess('sales_status.retrieve') && (
      <div className="biq-user-assign__item">
        <SalesStatus />
      </div>
    )}
    {isFeatureEnabled()('COMMUNICATION_FLOW') 
      && hasAccess('sales_status.retrieve') && (
      <div className="biq-user-assign__item">
        <SalesSubStatus />
      </div>
    )}
    <div className="biq-user-assign__item">
      <SalesManager isIb={isIb} />
    </div>
    {isFeatureEnabled()('ASSIGN_RETENTION') && (
      <div className="biq-user-assign__item">
        <RetentionManager isIb={isIb} />
      </div>
    )}
    {hasAccess('user.assign_rpd.create') && (
      <div className="biq-user-assign__item">
        <RetentionPoolDesk />
      </div>
    )}
  </div>
);

UserAssignments.propTypes = propTypes;
UserAssignments.defaultProps = defaultProps;

export default UserAssignments;
