import React, { Component } from 'react';
import { Select, Sidepanel, Space, notifier } from 'tc-biq-design-system';
import { oneOfType, array, object, func, bool, string } from 'prop-types';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { ClientsList, SidepanelFooter } from '../../..';

const propTypes = {
  sidepanel: object.isRequired,
  fetchTableData: func.isRequired,
  apiAssignSalesManager: object.isRequired,
  apiUnassignSalesManger: object.isRequired,
  submitSuccess: bool,
  fieldData: oneOfType([array, object]),
  submitInProgress: bool,
  errors: object,
  options: array,
  actions: object,
  isIb: bool,
  overlayName: string.isRequired,
  gridApi: object,
  updateSuccessText: string,
  titleText: string,
};

const defaultProps = {
  submitSuccess: false,
  fieldData: null,
  submitInProgress: false,
  errors: null,
  options: null,
  actions: null,
  isIb: false,
  gridApi: null,
  updateSuccessText: null,
  titleText: null,
};

const text = {
  TITLE: gettext('Assign Sales Manager'),
  SUBMIT: gettext('Save'),
  CANCEL: gettext('Cancel'),
  PLACEHOLDER: gettext('Search...'),
  UPDATE_SUCCESS: gettext('Assign sales manager successful'),
  GROUP_USERS: gettext('Users'),
  GROUP_TEAMS: gettext('Teams'),
  GROUP_DEFAULT: gettext('Default'),
  NONE: gettext('None'),
  SELECTED_USERS: gettext('Selected users'),
  BUTTON_LABELS: {
    confirm: gettext('Assign'),
    cancel: gettext('Discard changes'),
  },
};

const customFooter = (execute, close, submitInProgess) => (
  <SidepanelFooter 
    submitInProgress={submitInProgess}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

class AssignSalesManager extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.apiUsers = getDjangoApi('team');
    this.apiAssignSalesManager = props.apiAssignSalesManager;
    this.apiUnassignSalesManger = props.apiUnassignSalesManger;
    this.loadOptions = _.debounce(this.loadOptions.bind(this), 250, { leading: true });
    this.onInputChange = this.onInputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.loadOptions();
  }

  componentDidUpdate() {
    const { fetchTableData, submitSuccess, updateSuccessText } = this.props;
    if (submitSuccess) {
      notifier.success(updateSuccessText || text.UPDATE_SUCCESS);
      fetchTableData();
      this.onClose();
    }
  }

  onInputChange(input) {
    this.loadOptions(input);
  }

  onClose() {
    const { gridApi, overlayName } = this.props;
    gridApi && gridApi.deselectAll();
    this.actions.closeSidepanel(overlayName);
    this.actions.resetInput();
  }

  loadOptions(input = '') {
    const userParams = {
      type: 'live',
      limit: 10,
      offset: 0,
    };
    
    const usersPromise = this.apiUsers.list({ ...userParams, username: input });
    
    this.actions.loadOptions(usersPromise, false, text);
  }

  submit() {
    const { sidepanel, fieldData } = this.props;
    const users = sidepanel.parameters.map(user => user.id);
    const api = fieldData && fieldData.id === 'none'
      ? this.apiUnassignSalesManger
      : this.apiAssignSalesManager;
    const requestData = fieldData && fieldData.id === 'none'
      ? { users }
      : { team_profile: fieldData.id, users };

    this.actions.submit(api, requestData);
  }

  render() {
    const { sidepanel, fieldData, submitInProgress, options, errors, isIb, titleText } = this.props;
    const helpText = errors ? errors.team_profile : '';
    const { visible, parameters } = sidepanel;
    return (
      <Sidepanel
        icon="User"
        type="info"
        title={titleText || text.TITLE}
        visible={visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.submit, this.onClose, submitInProgress)}
      >
        <Space size={16} />
        <Select
          placeholder={text.PLACEHOLDER}
          type="search"
          onInputChange={this.onInputChange}
          onChange={this.actions.updateData}
          value={fieldData}
          hasError={errors || false}
          helpText={helpText}
          group
          options={options || []}
        />
        <Space size={16} />
        {visible && !_.isEmpty(parameters) ? (
          <h4>{`${text.SELECTED_USERS} (${parameters.length})`}</h4>
        ) : null}
        {visible && !_.isEmpty(parameters) ? (
          <ClientsList clients={parameters} hideUserState isIb={isIb} />
        ) : null}
      </Sidepanel>
    );
  }
}

AssignSalesManager.defaultProps = defaultProps;
AssignSalesManager.propTypes = propTypes;

export default AssignSalesManager;
