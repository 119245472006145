import React, { Component } from 'react';
import { Sidepanel, Space, notifier } from 'tc-biq-design-system';
import { object, bool, func, string, shape } from 'prop-types';

import { gettext } from '../../logic/utilities/languageUtility';
import { formErrorHandler } from '../form/logic/utils';
import to from '../../logic/utilities/to';
import { SidepanelFooter } from '../common';
import FormFactory from '../form';

const env = window.config.environment;

const text = {
  ERROR_GENERAL: gettext('Something went wrong!'),
  BUTTON_LABELS: {
    create: {
      confirm: gettext('Create'),
      cancel: gettext('Cancel'),
    },
    edit: {
      confirm: gettext('Save'),
      cancel: gettext('Cancel'),
    },
  },
};

const propTypes = {
  sidepanel: object.isRequired,
  submitInProgress: bool.isRequired,
  actions: object.isRequired,
  onSuccess: func.isRequired,
  onFormReady: func,
  options: shape({
    getApi: func.isRequired,
    text: object.isRequired,
    prepareRowData: func,
    getRequestPayload: func,
    getFormConfig: func,
    onlyCustomFields: bool,
  }).isRequired,
  FORM_ID: string.isRequired,
  SIDEPANEL_ID: string.isRequired,
  isEdit: bool,
  renderForm: func,
};

const defaultProps = {
  onFormReady: () => null,
  isEdit: false,
  renderForm: null,
};

class FormSidepanel extends Component {
  constructor(props) {
    super(props);

    this.text = { ...text, ...props.options.text };
  }

  componentDidUpdate(prevProps) {
    const { sidepanel } = this.props;
    const wasHidden = !_.get(prevProps, 'sidepanel.visible');
    if (wasHidden && sidepanel.visible) this.setFormData();
  }

  onSubmit = async () => {
    const { actions, options } = this.props;
    const getRequestPayload = options.getRequestPayload && options.getRequestPayload(this.getHelpers());
    const action = () => (this.isEdit() 
      ? actions.update(this.getApi(), this.getId(), getRequestPayload, true) 
      : actions.create(this.getApi(), getRequestPayload));
    
    const [err] = await to(action());
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    const { onSuccess } = this.props;
    notifier.success(this.text.SUCCESS[this.isEdit() ? 'edit' : 'create']);
    this.onClose();
    onSuccess && onSuccess();
  }

  onError(payload) {
    formErrorHandler(this.text.ERROR_GENERAL)(payload);
  }

  onClose = () => {
    const { actions, SIDEPANEL_ID } = this.props;
    actions.closeSidepanel(SIDEPANEL_ID);
    this.resetForm();
  }

  setFormData = () => {
    const id = this.getId();
    const { sidepanel: { parameters }, actions, options } = this.props;
    const { data } = parameters || {};
    if (id && id !== this.id) {
      this.id = id;
      const row = options.prepareRowData ? options.prepareRowData(this.getHelpers())(data) : { ...data };
      actions.setRowData(row);
    }
  }

  getApi = () => {
    const { options: { getApi } } = this.props;
    return getApi(this.getHelpers());
  }

  getId = () => _.get(this.props, 'sidepanel.parameters.data.id');
  
  getHelpers = () => ({
    isEdit: this.isEdit.bind(this),
    getId: this.getId.bind(this),
    isAva: this.isAva.bind(this),
  })

  // eslint-disable-next-line react/destructuring-assignment
  isEdit = () => this.props.isEdit || !!this.getId();

  isAva = () => env === 'AvaTrade';

  customFooter = (execute, close, submitInProgress, FORM_ID) => () => (
    <SidepanelFooter
      submitInProgress={submitInProgress}
      execute={execute}
      close={close}
      cancelColor="ghost"
      confirmColor="primary"
      buttonLabels={this.text.BUTTON_LABELS[this.isEdit() ? 'edit' : 'create']}
      formId={FORM_ID}
    />
  );

  formConfig = () => {
    const { FORM_ID, options: { getFormConfig = () => {}, onlyCustomFields } } = this.props;
    return {
      form: FORM_ID,
      ...(onlyCustomFields ? {} : { api: this.getApi() }),
      ...getFormConfig(this.getHelpers()),
    };
  }

  resetForm = () => {
    delete this.id;
    delete this.FormComponent;
  }

  renderForm = () => {
    const { sidepanel } = this.props;
    if (!this.FormComponent && sidepanel.visible) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const Form = this.renderForm();
    const { sidepanel, submitInProgress, options, onFormReady, renderForm, FORM_ID } = this.props;
    const { formModifiers } = options;
    const title = this.isEdit() ? this.text.EDIT : this.text.CREATE;
    return (
      <Sidepanel
        icon="Pen"
        title={title}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={this.customFooter(this.onSubmit, this.onClose, submitInProgress, FORM_ID)}
      >
        <Space size={16} />
        {Form && (
          <Form 
            formId={FORM_ID}
            modifiers={formModifiers}
            onFormReady={onFormReady}
            renderForm={renderForm}
          />
        )}
        <Space size={16} />
      </Sidepanel>
    );
  }
}

FormSidepanel.propTypes = propTypes;
FormSidepanel.defaultProps = defaultProps;

export default FormSidepanel;
