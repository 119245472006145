import { createAction } from 'redux-actions';

import { SUBMIT, RESET_STATE } from './actionTypes';

const submit = createAction(SUBMIT, async (api, data) => {
  const responseData = await api.create(data);
  const response = responseData.data;
  return { response };
});

const reset = createAction(RESET_STATE);

export default {
  submit,
  reset,
};
