/* eslint-disable */
import React, { Component } from 'react';
import { array, shape, string, func } from 'prop-types';
import ConditionGroup from './ConditionGroup';
import {
  getUpdatedTreeState,
  insertQueryRule,
  removeQueryRule,
} from '../../logic/services/query-adapter';

const propTypes = {
  fields: array,
  group: shape({
    condition: string,
    rules: array,
    identifier: string,
  }).isRequired,
  className: string,
  onStateUpdate: func.isRequired,
};

const defaultProps = {
  fields: [],
  className: null,
};

export default class QueryBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: {},
      fields: [],
    }
  }

  static getDerivedStateFromProps(props) {
    const { group, fields } = props;
    return { group, fields };
  }

  addRule = (type, identifier) => {
    const { group } = this.state;
    const treeWithAddedRule = insertQueryRule(group, type, identifier);
    this.setState({ group: treeWithAddedRule }, () => this.onUpdate(this.state.group));
  };

  removeRule = identifier => {
    const { group } = this.state;
    const treeWithoutRemovedRule = removeQueryRule(group, identifier);
    this.setState({ group: treeWithoutRemovedRule }, () => this.onUpdate(treeWithoutRemovedRule));
  };

  updateComponentState = (updatedProperties, identifier) => {
    const { group } = this.state;
    const updatedRulesTree = getUpdatedTreeState(group, updatedProperties, identifier);
    this.setState({ group: updatedRulesTree }, () => this.onUpdate(updatedRulesTree));
  };

  onUpdate = (group) => {
    const { onStateUpdate } = this.props;
    onStateUpdate(group);
  }

  renderRules = (fields, group, initial) => (
    <ConditionGroup
      rules={group.rules}
      condition={group.condition}
      fields={fields}
      identifier={group.identifier}
      renderRules={this.renderRules}
      addRule={this.addRule}
      updateState={this.updateComponentState}
      removeRule={this.removeRule}
      initial={initial}
    />
  );

  render() {
    const { group, fields } = this.state;
    const { className } = this.props;

    return (
      <div className={className}>
        {this.renderRules(fields, group, true)}
      </div>
    )
  }
}

QueryBuilder.propTypes = propTypes;
QueryBuilder.defaultProps = defaultProps;
