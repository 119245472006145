import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import GridActionsFactory from '../../../../../../components/grid/GridActionsFactory';

const reducerKey = 'USER_SINGLE';
export const paymentConfig = (id, isIb) => ({
  reducerKey,
  apiUrl: `${isIb ? 'ib' : 'users'}/${id}/payment_transactions`,
  tableKey: `${isIb ? 'IB_' : ''}PAYMENT_TRANSACTIONS`,
});

export const internalConfig = (id, isIb) => ({
  reducerKey,
  apiUrl: `${isIb ? 'ib' : 'users'}/${id}/internal_transactions`,
  tableKey: `${isIb ? 'IB_' : ''}INTERNAL_TRANSACTIONS`,
});

export const insuranceConfig = (id, isIb) => ({
  reducerKey,
  apiUrl: `${isIb ? 'ib' : 'users'}/${id}/internal_transactions`,
  tableKey: `${isIb ? 'IB_' : ''}INSURANCE_TRANSACTIONS`,
});

export const bonusConfig = (id, isIb) => ({
  reducerKey,
  apiUrl: `${isIb ? 'ib' : 'users'}/${id}/bonuses`,
  tableKey: 'BONUS_TRANSACTIONS',
});


export const getTableActions = (tableConfig, id, isIb) => {
  const config = tableConfig(id, isIb);
  const api = getDjangoApi(config.apiUrl);
  return GridActionsFactory(config.tableKey, api);
};
