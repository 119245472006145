import { createAction, handleActions } from 'redux-actions';

const initialState = {
  data: {},
  isLoading: false,
  submitInProgress: false,
};

const actionsTypes = {
  LOAD_DATA: 'LOAD_DATA_LOGS',
  LOAD_DATA_PENDING: 'LOAD_DATA_LOGS_PENDING',
  LOAD_DATA_FULFILLED: 'LOAD_DATA_LOGS_FULFILLED',
  DELETE_RECORD: 'DELETE_RECORD',
  DELETE_RECORD_PENDING: 'DELETE_RECORD_PENDING',
  DELETE_RECORD_FULFILLED: 'DELETE_RECORD_FULFILLED',
};

const loadData = createAction(actionsTypes.LOAD_DATA, async (api) => {
  const response = await api.list();
  return response.data;
});

const deleteRecord = createAction(actionsTypes.DELETE_RECORD, async (api) => {
  const response = await api.destroy();
  return response;
});

export default {
  loadData,
  deleteRecord,
};

export const reducer = handleActions({
  [actionsTypes.LOAD_DATA_PENDING]: state => ({ ...state, isLoading: true }),
  [actionsTypes.LOAD_DATA_FULFILLED]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    data: payload,
  }),
  [actionsTypes.DELETE_RECORD_PENDING]: state => ({ ...state, submitInProgress: true }),
  [actionsTypes.DELETE_RECORD_FULFILLED]: state => ({ ...state, submitInProgress: false }),
}, initialState);
