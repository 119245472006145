import { combineReducers } from 'redux';

import activities from './ActivityStream/Model/activityReducer';
import { reducer as dateTextFilters } from './ActivityStream/ActivityStreamFilters/Model';
import { reducer as typeFilter } from './ActivityStream/ActivityStreamFilters/ActivityTypeFilter/Model';
import { reducer as addCommentReducer } from './ActivityStream/sidepanels/AddComment/Model';

const rootReducer = combineReducers({
  activities,
  filters: combineReducers({
    typeFilter,
    dateTextFilters, 
  }),
  addComment: addCommentReducer,
});

export default rootReducer;
