import { createAction, handleActions } from 'redux-actions';
import { loadSession, updateAuthorizationHeader } from '../services/authentication';

const initialState = {
  user: null,
  acl: [],
  endpoints: [],
  team: null,
  baseCurrency: null,
  postLoginRoute: null,
};


const actionTypes = {
  SET_SEESION: 'SET_SEESION',
  DELETE_SESSION: 'DELETE_SESSION',
  SET_POST_LOGIN_ROUTE: 'SET_POST_LOGIN_ROUTE',
  CLEAR_POST_LOGIN_ROUTE: 'CLEAR_POST_LOGIN_ROUTE',
  LOAD_SESSION: 'LOAD_SESSION',
  LOAD_SESSION_FULFILLED: 'LOAD_SESSION_FULFILLED',
};

const setSession = createAction(actionTypes.SET_SEESION, session => session);
const deleteSession = createAction(actionTypes.DELETE_SESSION);
const setPostLoginRoute = createAction(actionTypes.SET_POST_LOGIN_ROUTE, 
  postLoginRoute => postLoginRoute);
const clearPostLoginRoute = createAction(actionTypes.CLEAR_POST_LOGIN_ROUTE);
const loadAuthSession = createAction(actionTypes.LOAD_SESSION, () => {
  updateAuthorizationHeader();
  return loadSession();
});

export {
  setSession,
  deleteSession,
  setPostLoginRoute,
  clearPostLoginRoute,
  loadAuthSession,
};

export default handleActions({
  [actionTypes.SET_SEESION]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [actionTypes.DELETE_SESSION]: () => ({ ...initialState }),
  [actionTypes.SET_POST_LOGIN_ROUTE]: (state, { payload }) => ({
    ...state,
    postLoginRoute: payload,
  }),
  [actionTypes.LOAD_SESSION_FULFILLED]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [actionTypes.CLEAR_POST_LOGIN_STATE]: state => ({
    ...state,
    postLoginRoute: initialState.postLoginRoute,
  }),
}, initialState);
