import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  FETCH_CURRENCIES: 'FETCH_CURRENCIES',
  FETCH_CURRENCIES_FULFILLED: 'FETCH_CURRENCIES_FULFILLED',
  FETCH_CURRENCIES_PENDING: 'FETCH_CURRENCIES_PENDING',
  FETCH_CURRENCIES_REJECTED: 'FETCH_CURRENCIES_REJECTED',

  FETCH_PAYMENT_METHODS: 'FETCH_PAYMENT_METHODS',
  FETCH_PAYMENT_METHODS_FULFILLED: 'FETCH_PAYMENT_METHODS_FULFILLED',
  FETCH_PAYMENT_METHODS_PENDING: 'FETCH_PAYMENT_METHODS_PENDING',
  FETCH_PAYMENT_METHODS_REJECTED: 'FETCH_PAYMENT_METHODS_REJECTED',

  FETCH_RATE: 'FETCH_RATE',
  FETCH_RATE_FULFILLED: 'FETCH_RATE_FULFILLED',
  FETCH_RATE_PENDING: 'FETCH_RATE_PENDING',
  FETCH_RATE_REJECTED: 'FETCH_RATE_REJECTED',
};

export const fetchCurrencies = createAction(actionTypes.FETCH_CURRENCIES, async () => {
  const result = await getDjangoApi('currencies').list();
  return result.data;
});

export const fetchPaymentMethods = createAction(actionTypes.FETCH_PAYMENT_METHODS, async () => {
  const result = await getDjangoApi('payment_methods').list({ offset: 0, limit: 100 });
  return result.data;
});

export const fetchRate = createAction(actionTypes.FETCH_RATE, async (depositCurrency, accountCurrency, user, isIb, isCredit) => {
  const req = {};
  if (user) {
    const countryIsoCode = isIb ? user.country.iso_code : user.addr_country.iso_code;
    req.country = countryIsoCode;
  }

  const baseCurrency = isCredit ? depositCurrency : accountCurrency;
  const quoteCurrency = isCredit ? accountCurrency : depositCurrency;

  const response = await getDjangoApi(`currencies/${baseCurrency}/exchange_rate/${quoteCurrency}`).list(req);
  return response.data.rate;
});


const initialState = {
  currencies: [],
  fetchInProgress: false,
  errors: null,
  rate: null,
  fetchRateInProgress: false,
  rateErrors: null,
  paymentMethods: {},
  paymentsErrors: null,
};

const pendingHandler = state => ({ ...state, fetchInProgress: true });

const fulfilledHandler = (state, { payload }) => ({
  ...state,
  currencies: payload,
  fetchInProgress: false,
  error: null,
});

const rejectedHandler = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  errors: payload.data,
});

const rateFulfilledHandling = (state, { payload }) => ({
  ...state,
  rate: payload,
  fetchRateInProgress: false,
  rateErrors: null,
});

const rateRejectedHandler = (state, { payload }) => ({
  ...state,
  fetchRateInProgress: false,
  rateErrors: payload.data,
});

export default handleActions(
  {
    [actionTypes.FETCH_CURRENCIES_PENDING]: pendingHandler,
    [actionTypes.FETCH_CURRENCIES_FULFILLED]: fulfilledHandler,
    [actionTypes.FETCH_CURRENCIES_REJECTED]: rejectedHandler,
    [actionTypes.FETCH_PAYMENT_METHODS_FULFILLED]: (state, { payload: { results } }) => ({ ...state, paymentMethods: results.reduce((acc, method) => ({ ...acc, [method.id]: method }), {}) }),
    [actionTypes.FETCH_PAYMENT_METHODS_REJECTED]: (state, { payload }) => ({ ...state, paymentsErrors: payload }),
    [actionTypes.FETCH_RATE_PENDING]: state => ({ ...state, fetchRateInProgress: true }),
    [actionTypes.FETCH_RATE_FULFILLED]: rateFulfilledHandling,
    [actionTypes.FETCH_RATE_REJECTED]: rateRejectedHandler,
  },
  initialState,
);
