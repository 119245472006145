import React, { Component, Fragment } from 'react';
import { array, bool, object, string } from 'prop-types';
import { Spinner, Button } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import connect from '../../../../../../logic/connect';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import If from '../../../../../../components/If';
import BiqAccordion from '../../../../../../components/BiqAccordion';
import { fetchSignupDetails } from './Model';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';


import './SignupDetails.scss';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import EditSignupDetails, { SIDEPANEL_ID } from './EditSignupDetails';
import overlayActions from '../../../../../../components/overlay';

const text = {
  TRUE: gettext('True'),
  FALSE: gettext('False'),
  NA: gettext('N/A'),
  EDIT_SIGNUP_DATA: gettext('Edit signup data'),
  ADDITIONAL_INFO: gettext('Additional Info'),
  INITIAL_TRADING_PLATFORM: gettext('Initial Trading Platform'),
  DOWNLOAD_PDF: gettext('Download PDF'),
};

const propTypes = {
  id: string.isRequired,
  actions: object.isRequired,
  user: object.isRequired,
  details: array,
  fetchInProgress: bool,
};

const defaultProps = {
  details: [],
  fetchInProgress: false,
};

const getRawData = ({ details, user }) => ({ data: details[0] && details[0].data, details, user });

const addFirstVisitData = ({ user, details, data }) => ({
  user,
  details,
  data: {
    ...data,
    'First Visit': {
      'IP address': _.get(user, 'first_visit.ip_address', text.NA),
      City: _.get(user, 'first_visit.geo_data.city', text.NA),
      Country: _.get(user, 'first_visit.geo_data.country_name', text.NA),
    },
  },
});

const addAdditionalInfoData = ({ user, details, data }) => ({
  user,
  details,
  data: {
    ...data,
    [text.ADDITIONAL_INFO]: {
      ...(data[text.ADDITIONAL_INFO] || {}),
      [text.INITIAL_TRADING_PLATFORM]: _.get(user, 'initial_trading_platforms', []).join(','),
    },
  },
});

const renameKey = (oldName, newName) => ({ user, details, data }) => {
  _.set(data, newName, data[oldName]);
  // eslint-disable-next-line no-param-reassign
  delete data[oldName];

  return {
    user, details, data,
  };
};

const removeKeysIfEmpty = (...keys) => ({ user, details, data }) => {
  keys.forEach((field) => {
    if (_.has(data, field) && _.isEmpty(data[field])) {
      // eslint-disable-next-line no-param-reassign
      delete data[field];
    }
  });

  return { user, details, data };
};

const getProp = prop => data => data[prop];

const formatData = _.flow([
  getRawData,
  addFirstVisitData,
  renameKey('Unknown', text.ADDITIONAL_INFO),
  addAdditionalInfoData,
  removeKeysIfEmpty(text.ADDITIONAL_INFO),
  getProp('data'),
]);


const renderValue = (key, value) => {
  if (value === null || value === undefined) return text.NA;

  if (typeof value === 'boolean') return value ? text.TRUE : text.FALSE;

  if (key === 'Trading platform' && Array.isArray(value)) {
    return value.map(item => item.backend).join(', ');
  } 

  return String(value);
};

const renderDetailsSection = data => _.map(data, (value, key) => (
  <div className="content" key={key}>
    <p className="field">{key}</p>
    <p className="answer">{renderValue(key, value)}</p>
  </div>
));

const renderSignupData = data => _.map(data, (value, key) => (
  <div className="accordion-section" key={key}>
    <BiqAccordion title={key}>
      {renderDetailsSection(value)}
    </BiqAccordion>
  </div>
));

class SignupDetails extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.api = getDjangoApi(`users/${props.id}/signup_data`);
  }

  componentDidMount() {
    this.fetchSignupDetails();
  }

  onEdit = () => {
    this.actions.openSidepanel(SIDEPANEL_ID);
  }

  fetchSignupDetails = () => this.actions.fetchSignupDetails(this.api);

  render() {
    const { details, fetchInProgress, user } = this.props;
    const data = formatData({ details, user });

    return (
      <Fragment>
        {isFeatureEnabled()('EDITABLE_SIGNUP_DATA') && (
          <div className="section__actions">
            <Button onClick={this.onEdit}>{text.EDIT_SIGNUP_DATA}</Button>
          </div>
        )}
        <div className="biq-profile__wrapper signup-details">
          <If condition={fetchInProgress}>
            <div className="loader">
              <Spinner size="large" />
            </div>
          </If>

          <If condition={!fetchInProgress}>
            { renderSignupData(data) }
          </If>

          <If condition={user.application}>
            <div className="section__actions" style={{ marginTop: '15px' }}>
              <a href={user.application} target="_blank" rel="noreferrer">         
                <Button>{text.DOWNLOAD_PDF}</Button>
              </a>
            </div>
          </If>
        </div>
        <EditSignupDetails uid={user.id} onSuccess={this.fetchSignupDetails} />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  return ({ ...page.signupDetails, user: page.userDetail.user });
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    fetchSignupDetails,
    openSidepanel: overlayActions.open,
  }, dispatch),
});

SignupDetails.propTypes = propTypes;
SignupDetails.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(SignupDetails);
