import FTD from './FTD';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../../logic/services/acl';

export default {
  ...FTD,
  key: 'ftd',
  label: gettext('FTD'),
  isVisible: () => hasAccess('dashboard.overall_deposits.*'),
};
