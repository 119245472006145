export default table => (
  {
    TABLE_OPTIONS: `${table}_OPTIONS`,
    TABLE_OPTIONS_PENDING: `${table}_OPTIONS_PENDING`,
    TABLE_OPTIONS_FULFILLED: `${table}_OPTIONS_FULFILLED`,
    TABLE_DATA: `${table}_DATA`,
    TABLE_DATA_PENDING: `${table}_DATA_PENDING`,
    TABLE_DATA_FULFILLED: `${table}_DATA_FULFILLED`,
    TABLE_DATA_REJECTED: `${table}_DATA_REJECTED`,
    ORDERING_CHANGE: `${table}_ORDERING_CHANGE`,
    UPDATE_PAGE_SIZE: `${table}_UPDATE_PAGE_SIZE`,
    CHANGE_PAGE: `${table}_CHANGE_PAGE`,
    CHANGE_CURSOR: `${table}_CHANGE_CURSOR`,
    UPDATE_FILTER: `${table}_UPDATE_FILTER`,
    UPDATE_EXPORT_URL: `${table}_UPDATE_EXPORT_URL`,
    ADD_ACTIONS_COLUMN: `${table}_ADD_ACTIONS_COLUMN`,
    ADD_INFO_COLUMN: `${table}_ADD_INFO_COLUMN`,
    UPDATE_ROW_DATA: `${table}_UPDATE_ROW_DATA`,
    UPDATE_ROW_DATA_PENDING: `${table}_UPDATE_ROW_DATA_PENDING`,
    UPDATE_ROW_DATA_FULFILLED: `${table}_UPDATE_ROW_DATA_FULFILLED`,
    UPDATE_ROW_DATA_REJECTED: `${table}_UPDATE_ROW_DATA_REJECTED`,
    DELETE_ROW: `${table}_DELETE_ROW`,
    DELETE_ROW_PENDING: `${table}_DELETE_ROW_PENDING`,
    DELETE_ROW_FULFILLED: `${table}_DELETE_ROW_FULFILLED`,
    DELETE_ROW_REJECTED: `${table}_DELETE_ROW_REJECTED`,
    CHANGE_SEGMENT: `${table}_CHANGE_SEGMENT`,
    SET_COLUMNS_VISIBILITY: `${table}_SET_COLUMNS_VISIBILITY`,
    RESET_TABLE: `${table}_RESET`,
    CLEAR_DATA: `${table}_CLEAR_DATA`,
    UPDATE_CELL: `${table}_UPDATE_CELL`,
    UPDATE_MAX_TOTAL: `${table}_UPDATE_MAX_TOTAL`,
  }
);
