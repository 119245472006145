import React, { Component, Fragment } from 'react';
import { object, array } from 'prop-types';
  
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { nestedFieldsGenerator } from '../../../../../../../../components/form/Components/Form';
import TogglePanel from '../../../../../../../../components/TogglePanel';

const text = {
  TITLE: gettext('Add card details'),
  CARD_NUMBER: gettext('Last four card digits'),
  EXPIRY: gettext('Expiry date (MM/YYYY)'),
};

const FORM_KEY = 'MANUAL_DEPOSIT_FORM';

const fields = {
  IS_CARD_DETAILS_REQUIRED: 'is_card_details_required',
};

const propTypes = {
  actions: object.isRequired,
  formValues: object.isRequired,
  formFields: array.isRequired,
};

const formModifiers = {
  card_data_card_number: {
    label: text.CARD_NUMBER,
  },
  card_data_expiry: {
    label: text.EXPIRY,
    type: 'text',
  },
};

class CardData extends Component {
  constructor(props) {
    super(props);

    this.actions = props.actions;
    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
    this.generateFields(); 
  }

  onToggle(checked) {
    this.actions.setFieldValue({
      id: fields.IS_CARD_DETAILS_REQUIRED,
      value: checked,
    });
  }

  generateFields() {
    const { formFields } = this.props;
    this.fields = nestedFieldsGenerator(FORM_KEY, formFields, 'card_data', formModifiers);
  }

  render() {
    const { formValues } = this.props;

    const { is_card_details_required } = formValues;

    return (
      <TogglePanel
        title={text.TITLE}
        checked={is_card_details_required}
        onToggle={this.onToggle}
      >
        {is_card_details_required && this.fields && (
          <Fragment>
            {this.fields.card_data_card_number}
            {this.fields.card_data_funding}
            {this.fields.card_data_cardholder_name}
            {this.fields.card_data_expiry}
            {this.fields.card_data_brand}
          </Fragment>
        )}
      </TogglePanel>
    );
  }
}

CardData.propTypes = propTypes;

export default CardData;
