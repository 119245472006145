import React, { useEffect, useState, Fragment } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { node, object } from 'prop-types';

import { getAccessForPage } from '../../../logic/services/acl';
import accessCodes from '../../../logic/enums/access-codes';
import appRoutes from '../Router/appRoutes';
import { logout } from '../../../logic/services/authentication';
import { gettext } from '../../../logic/utilities/languageUtility';
import Loader from '../../Loader';

const propTypes = {
  children: node.isRequired,
  session: object.isRequired,
  page: object.isRequired,
  actions: object.isRequired,
};

const text = {
  LOGIN_ERROR: gettext('You have been logged out due to inactivity.'),
};

const Authentication = ({ children, session, page, actions }) => {
  const [loadingSession, setLoadingSession] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  useEffect(() => {
    const accessCode = getAccessForPage(page, session, match);
    switch (accessCode) {
      case accessCodes.ALLOWED:
        setAllowed(true);
        break;
      case accessCodes.FORBIDDEN:
        history.replace(appRoutes.FORBIDDEN);
        break;
      case accessCodes.SESSION_REQUIRED:
        setLoadingSession(true);
        actions.loadAuthSession()
          .then(() => {
            setLoadingSession(false);
          })
          .catch(() => {
            logout();
            actions.setPostLoginRoute(location.pathname);
            history.push({
              pathname: appRoutes.LOGIN,
              search: `error=${text.LOGIN_ERROR}`,
            });
            setLoadingSession(false);
          });
        break;
      case accessCodes.UNAUTHORIZED:
      default:
        actions.setPostLoginRoute(location.pathname);
        history.push(appRoutes.LOGIN);
    }
  }, [location.pathname, session]);
  
  return (
    <Fragment>
      {allowed && children}
      {!allowed && loadingSession && <Loader />}
    </Fragment>
  );
};

Authentication.propTypes = propTypes;

export default Authentication;
