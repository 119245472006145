import React from 'react';
import { Input } from 'tc-biq-design-system';
import { func } from 'prop-types';
import useEnterPress from '../hooks/useEnterPress';

const propTypes = {
  onChange: func.isRequired,
  onEnter: func,
};

const defaultProps = {
  onEnter: () => {},
};

const TextInput = (props) => {
  const { onChange, onEnter } = props;
  const inputProps = _.omit(props, 'withPortal', 'onEnter', 'filterOptions');

  const onKeyPress = useEnterPress(onEnter);

  return <Input {...inputProps} onChange={e => onChange(e.target.value)} onKeyPress={onKeyPress} />;
};

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;

export default TextInput;
