/* eslint-disable react/prop-types */
import React from 'react';

import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import hideCols from '../../../../../../logic/utilities/hideCols';
import { RouteCell } from '../../../../../../components/gridCellRenderers';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

const modifier = {
  ...hideCols([
    'id',
  ]),
  username: {
    cellRendererFramework: ({ data, ...rest }) => RouteCell({ 
      href: appRoutes.IB_PROFILE, 
      stateParams: { id: data.id } })({ data, ...rest }),
  },
  link: {
    cellRendererFramework: ({ value }) => <div>{value.name}</div>,
  },
};

const tableConfig = id => ({
  apiUrl: `ib/${id}/sub_ibs/`,
  reducerKey: 'USER_SINGLE',
  tableKey: 'IB_SUB_IBS',
});


const SubIbs = ({ id }) => {
  const Table = withTable(GridTab, tableConfig, null, { hideSegments: true })(id);

  return <Table modifier={modifier} />;
};

export default SubIbs;
