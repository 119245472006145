import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import actionTypes from '../types';
import InternalTransfer from './InternalTransfer';

const text = {
  LABEL: gettext('Internal transfer'),
};

const ACTION_ICON = 'Star';

export default {
  name: 'internal-transaction',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.TRANSACTIONS,
  hasPermission(state, _user, isIb) {
    if (isIb) return false;
    const hasStatePermission = [
      USER_STATE.FULL,
      USER_STATE.LIMITED,
      USER_STATE.REVIEW,
    ].indexOf(state) >= 0;
    return hasAccess('user.internal_transfer.create') && hasAccess('user.trading_accounts.list') && hasStatePermission;
  },
  handler: (sidepanelManager, _dispatch, user) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(InternalTransfer, sidepanelOptions);
  },
};
