import React from 'react';
import { string, bool, node } from 'prop-types';

import { BASE } from '../../logic/services/api';

const propTypes = {
  token: string.isRequired,
  domain: string,
  isIb: bool,
  children: node.isRequired,
};

const defaultProps = {
  isIb: false,
  domain: null,
};

const getPortalUrl = (token, domain, isIb) => `${!domain ? BASE : `//${domain}`}/auth/widget?token=${token}${isIb ? '&type=ib' : ''}`;

const LoginAsUser = ({ token, domain, isIb, children }) => (
  <a target="_blank" rel="noopener noreferrer" href={getPortalUrl(token, domain, isIb)}>
    { children }
  </a>
);

LoginAsUser.propTypes = propTypes;
LoginAsUser.defaultProps = defaultProps;
export default LoginAsUser;
