import React from 'react';
import { string, object } from 'prop-types';
import { Detail } from 'tc-biq-design-system';

import dateTimeFormatter from '../../../../../../logic/utilities/formatters/dateTimeFormatter';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

const propTypes = {
  group: string.isRequired,
  related_to: string.isRequired,
  created: string.isRequired,
  link: string,
  date_accepted: string,
  description: string,
  trading_account: object,
};

const defaultProps = {
  date_accepted: '',
  link: '',
  trading_account: null,
  description: '',
};

const text = {
  DOCUMENT_VERSION: gettext('Document'),
  RELATED_TO: gettext('Related to'),
  DATE_OF_AGREEMENT: gettext('Date of agreement'),
  DESCRIPTION: gettext('Description'),
  TRADING_ACCOUNT: gettext('Trading account'),
  NA: gettext('N/A'),
};

const isRelatedToTradingAccount = relatedTo => relatedTo === gettext('Trading Account');

const DocumentTable = ({ 
  group, 
  link, 
  related_to, 
  created, 
  date_accepted, 
  description, 
  trading_account,
}) => (
  <div className="document-table">
    <Detail reverse entity={text.DOCUMENT_VERSION} value={`${group}`} link={link} />
    <Detail reverse entity={text.RELATED_TO} value={related_to} />
    <Detail reverse entity={text.DATE_OF_AGREEMENT} value={date_accepted ? dateTimeFormatter(date_accepted, false) : text.NA} />
    <Detail reverse entity={text.DESCRIPTION} value={description || text.NA} />
    {isRelatedToTradingAccount(related_to) && <Detail reverse Detail={text.TRADING_ACCOUNT} value={trading_account || text.NA} />}
  </div>
);

DocumentTable.propTypes = propTypes;
DocumentTable.defaultProps = defaultProps;
export default DocumentTable;
