import { hasAccess } from '../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';
import Documents from './Documents';
import appRoutes from '../../../../../components/App/Router/appRoutes';

export default {
  component: Documents,
  aclFn: () => hasAccess('document_types.list')
        || (hasAccess('document_rejection_reasons.list') && isFeatureEnabled()('DOCUMENT_REJECTION_REASONS')),
  path: appRoutes.SETTINGS_DOCUMENTS,
};
