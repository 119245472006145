import React from 'react';
import { InfoBox } from 'tc-biq-design-system';

import { gettext } from '../../../logic/utilities/languageUtility';

const text = {
  NOT_FOUND: gettext('Page not found.'),
};

const NotFound = () => (
  <div className="biq-page__notification">
    <InfoBox type="warning">
      {text.NOT_FOUND}
    </InfoBox>
  </div>
);

export default NotFound;
