import React from 'react';
import { object, bool } from 'prop-types';
import { Modal } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import overlayActions from '../../../../../components/overlay';
import Extra from '../../../../../components/Extra';

const propTypes = {
  parameters: object,
  visible: bool.isRequired,
  actions: object.isRequired,
};

const defaultProps = {
  parameters: {},
};

const text = {
  TITLE: gettext('Communication preview'),
  REQUEST: gettext('Request'),
  RESPONSE: gettext('Response'),
};

const CommunicationModal = ({ parameters, visible, actions }) => (
  <Modal
    title={text.TITLE}
    visible={visible}
    closable
    hideFooter
    onCloseIconClick={() => actions.closeModal('WALLET_TRANSACTION_COMMUNICATION')}
  >
    {_.get(parameters, 'communication.length')
      && <Extra rawData={parameters.communication[0].request} title={text.REQUEST} accordionVisible />}
    {_.get(parameters, 'communication.length')
      && <Extra rawData={parameters.communication[0].response} title={text.RESPONSE} accordionVisible />}
  </Modal>
);

const mapStateToProps = ({ overlays }) => overlays.WALLET_TRANSACTION_COMMUNICATION;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { closeModal: overlayActions.close },
    dispatch,
  ),
});

CommunicationModal.propTypes = propTypes;
CommunicationModal.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(CommunicationModal);
