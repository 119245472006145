import { getDjangoApi } from '../../../logic/services/api-factory';

const env = window.config.environment;

export const managerType = {
  SALES: 'sales_manager',
  RETENTION: 'retention_manager',
};

export const getAssignSalesApi = (action) => {
  switch (action) {
    case managerType.SALES: {
      if (env === 'AvaTrade') {
        return {
          assignSales: getDjangoApi('env/team/assign_sales/bulk'),
          unAssignSales: getDjangoApi('env/team/unassign_sales/bulk'),
        };
      }
      
      return {
        assignSales: getDjangoApi('team/assign/bulk'),
        unAssignSales: getDjangoApi('team/assign/bulk'),
      };
    }
    case managerType.RETENTION: {
      return {
        assignSales: getDjangoApi('team/assign/bulk'),
        unAssignSales: getDjangoApi('team/assign/bulk'),
      };
    }
    default:
      return {};
  }
};

export const getAssignSalesManagerApi = () => getAssignSalesApi(managerType.SALES);
export const getAssignRetentionManagerApi = () => getAssignSalesApi(managerType.RETENTION);
