import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { gettext } from '../../../../../logic/utilities/languageUtility';

const api = getDjangoApi('document_presets');

const initialState = {
  documents: [],
  isLoading: false,
  docTypes: [],
  searchValue: '',
  checkedValue: null,
  isMultiPreset: false,
  checkedChoices: [
    { id: 1, label: gettext('All items'), value: 'all' },
    { id: 2, label: gettext('Checked items'), value: 'checked' },
    { id: 3, label: gettext('Unchecked items'), value: 'unchecked' },
  ],
};

const actionTypes = {
  LOAD_DOCUMENTS: 'LOAD_DOCUMENTS',
  LOAD_DOCUMENTS_FULFILLED: 'LOAD_DOCUMENTS_FULFILLED',
  LOAD_DOCUMENTS_PENDING: 'LOAD_DOCUMENTS_PENDING',
  LOAD_DOCUMENTS_REJECTED: 'LOAD_DOCUMENTS_REJECTED',
  UPDATE_PRESET: 'UPDATE_PRESET',
  UPDATE_TEXT_FILTER: 'UPDATE_TEXT_FILTER',
  UPDATE_SELECT_FILTER: 'UPDATE_SELECT_FILTER',
};

const loadDocuments = createAction(actionTypes.LOAD_DOCUMENTS, async () => {
  const response = await api.list({ ordering: '-id' });
  const docTypes = await api.options();
  return {
    documents: response.data.results,
    docTypes: docTypes.data.actions.GET.fields[2].choices,
  };
});

const updatePreset = createAction(actionTypes.UPDATE_PRESET, async (id, data) => {
  const response = await api.update(id, data);
  return { data: response.data };
});

const updateTextFilter = createAction(actionTypes.UPDATE_TEXT_FILTER);

const updateSelectFilter = createAction(actionTypes.UPDATE_SELECT_FILTER, 
  selected => ({ selected }));


export const actions = {
  loadDocuments,
  updatePreset,
  updateTextFilter,
  updateSelectFilter,
};

export default handleActions(
  {
    [actionTypes.LOAD_DOCUMENTS_PENDING]: state => ({ ...state, isLoading: true }),
    [actionTypes.LOAD_DOCUMENTS_FULFILLED]: (state, { payload }) => {
      const { documents, docTypes } = payload;
      const isMultiPreset = documents.filter(preset => preset.name).length > 1;
      return {
        ...state,
        isLoading: false,
        documents,
        docTypes,
        isMultiPreset,
      };
    },
    [actionTypes.UPDATE_TEXT_FILTER]: (state, { payload }) => ({
      ...state,
      searchValue: payload,
    }),
    [actionTypes.UPDATE_SEARCH_VALUE]: (state, { payload }) => ({ ...state, searchValue: payload }),
    [actionTypes.UPDATE_SELECT_FILTER]: (state, { payload }) => {
      const { selected } = payload;
      return {
        ...state,
        checkedValue: selected,
      };
    },
  },
  initialState,
);
