import { StateCell, OnContentClickCell, RouteCell } from '../../../../../../components/gridCellRenderers';
import { currencyFormatter, countryFormatter } from '../../../../../../logic/utilities/formatters';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import hideCols from '../../../../../../logic/utilities/hideCols';
import { tradingAccountDisplay } from '../../../../../../logic/filters/trading-account-display';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

const env = window.config.environment;

export default ({ openTransaction }) => ({
  ...hideCols(env !== 'AvaTrade' ? ['user', 'thirdparty_success'] : ['user']),
  id: {
    cellRendererFramework: OnContentClickCell(openTransaction),
    width: 80,
    sortValue: -3,
  },
  thirdparty_id: {
    sortValue: -2,
  },
  first_name: {
    width: 150,
  },
  last_name: {
    width: 150,
  },
  country: {
    cellRendererFramework: ({ data }) => countryFormatter(data.country),
  },
  wallet: {
    valueGetter: ({ data }) => tradingAccountDisplay(data.wallet, true),
  },
  retention_manager: { cellRendererFramework: RouteCell({ href: appRoutes.TEAM_MEMBER }) },
  sales_manager: { cellRendererFramework: RouteCell({ href: appRoutes.TEAM_MEMBER }) },
  retention_team: { cellRendererFramework: RouteCell({ href: appRoutes.TEAM }) },
  sales_team: { cellRendererFramework: RouteCell({ href: appRoutes.TEAM }) },
  payment_state: {
    cellRendererFramework: StateCell,
    valueGetter: ({ data }) => (data.payment_state ? data.payment_state : gettext('N/A')),
  },
  tpi_state: {
    cellRendererFramework: StateCell,
  },
  security_check: env !== 'AvaTrade' ? { hide: true } : {
    cellRendererFramework: StateCell,
    valueGetter: ({ data }) => (data.security_check ? data.security_check : gettext('N/A')),
  },
  value: {
    valueGetter: ({ data }) => currencyFormatter(data.value, data.currency.symbol),
  },
});
