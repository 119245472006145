import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  APPROVE_USER: 'APPROVE_USER',
  APPROVE_USER_FULFILLED: 'APPROVE_USER_FULFILLED',
  APPROVE_USER_PENDING: 'APPROVE_USER_PENDING',
  APPROVE_USER_REJECTED: 'APPROVE_USER_REJECTED',
};

export const approveUser = createAction(actionTypes.APPROVE_USER, async (user, state) => {
  const exclusionApi = getDjangoApi(`users/${user.id}/approve`);
  
  await exclusionApi.create({ state });
});


const initialState = {
  submitInProgress: false,
  errors: null,
};

const pendingHandler = state => ({ ...state, submitInProgress: true });

const fulfilledHandler = state => ({
  ...state,
  submitInProgress: false,
  error: null,
});

const rejectedHandler = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  errors: payload.data,
});

export default handleActions(
  {
    [actionTypes.APPROVE_USER_PENDING]: pendingHandler,
    [actionTypes.APPROVE_USER_FULFILLED]: fulfilledHandler,
    [actionTypes.APPROVE_USER_REJECTED]: rejectedHandler,
  },
  initialState,
);
