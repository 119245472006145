import { bindActionCreators } from 'redux';

import Confirmation from './Confirmation';
import overlayActions from '../../../../../../../components/overlay';
import connect from '../../../../../../../logic/connect';

const withConfirmSidepanel = (reducerKey, options) => {
  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
      {
        closeSidepanel: overlayActions.close,
      },
      dispatch,
    ),
  });
  
  const mapStateToProps = ({ overlays }) => ({
    sidepanel: overlays[reducerKey],
    reducerKey,
    ...options,
  });
  
  return connect(mapStateToProps, mapDispatchToProps)(Confirmation);
};

export default withConfirmSidepanel;
