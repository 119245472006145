import React, { Component, Fragment } from 'react';
import { Tab, Tabs, Button, notifier } from 'tc-biq-design-system';
import { object, bool } from 'prop-types';

import Page from '../../../../components/Page';
import TeamTable from './TeamTable';
import TeamAcls from './TeamAcls';
import { gettext } from '../../../../logic/utilities/languageUtility';
import to from '../../../../logic/utilities/to';
import isFeatureEnabled from '../../../../logic/filters/is-feature-enabled';
import TeamFlp from './TeamFlp';
import appRoutes from '../../../../components/App/Router/appRoutes';

const text = {
  TEAMS: gettext('Teams'),
  TEAM_MEMBERS: gettext('Team members'),
  ACCESS_RIGTHS: gettext('Access rights'),
  FIELD_PERMISSIONS: gettext('Field permissions'),
  DISCARD: gettext('Discard'),
  SAVE_CHANGES: gettext('Save changes'),
  GENERAL_ERROR: gettext('Something went wrong'),
  SUCCESS_MESSAGE: gettext('Access rights saved'),
};

const bread = (title = '') => [{ label: text.TEAMS, route: appRoutes.TEAMS }, { label: title, route: '' }];

const tabs = {
  TEAM_MEMBERS: 0,
  ACLS: 1,
  FIELD_PERMISSIONS: 2,
};

const propTypes = {
  team: object,
  actions: object.isRequired,
  isUpdateLoading: bool.isRequired,
  match: object.isRequired,
};

const defaultProps = {
  team: null,
};

class Team extends Component {
  constructor(props) {
    super(props);

    this.id = props.match.params.id;
    this.section = props.match.params.section;
    this.state = {
      acls: [],
      activeTab: this.section === 'access-rights' ? tabs.ACLS : tabs.TEAM_MEMBERS,
      aclReset: 1,
    };
  }

  componentDidMount() {
    this.loadTeam();
  }

  onAclSave = async () => {
    const { acls } = this.state;
    const { actions } = this.props;
    const [err] = await to(actions.updateTeam(this.id, { view_acls: acls }));

    err ? this.onError(err) : this.onSuccess();
  }

  onAclChange = acls => this.setState({ acls })

  onAclDiscard = () => {
    this.setState(({ aclReset }) => ({
      acls: [],
      aclReset: aclReset + 1,
    }));
  }

  onSuccess = () => {
    this.setState({
      acls: [],
    });
    notifier.success(text.SUCCESS_MESSAGE);
  }

  onError = ({ data }) => {
    const { non_field_errors, messages } = data;
    if (non_field_errors) {
      notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
    } else if (messages) {
      notifier.error(data.messages.map(err => <span>{err.text}</span>));
    } else {
      notifier.error(text.GENERAL_ERROR);
    }
  }

  headerActions = () => {
    const { activeTab, acls } = this.state;
    const { isUpdateLoading } = this.props;
    return activeTab === tabs.ACLS && acls.length > 0 && (
      <Fragment>
        <Button onClick={this.onAclDiscard} color="ghost">{text.DISCARD}</Button>
        <Button loading={isUpdateLoading} onClick={this.onAclSave}>{text.SAVE_CHANGES}</Button>
      </Fragment>
    );
  }

  loadTeam = () => {
    const { actions } = this.props;
    actions.loadTeam(this.id);
  }

  render() {
    const { team } = this.props;
    const { aclReset, activeTab } = this.state;
    
    return (
      <Page 
        title={team.name} 
        bread={bread(team.name)}
        headerActions={this.headerActions}
      >
        <Tabs active={activeTab}>
          <Tab 
            visible
            title={text.TEAM_MEMBERS}
            onLoad={() => this.setState({ activeTab: tabs.TEAM_MEMBERS })}
          >
            <TeamTable teamId={this.id} />
          </Tab>
          <Tab 
            visible
            title={text.ACCESS_RIGTHS}
            onLoad={() => this.setState({ activeTab: tabs.ACLS })}
          >
            <TeamAcls key={aclReset} onAclChange={this.onAclChange} />
          </Tab>
          <Tab 
            visible={isFeatureEnabled()('FLP')}
            title={text.FIELD_PERMISSIONS} 
            onLoad={() => this.setState({ activeTab: tabs.FIELD_PERMISSIONS })}
          >
            <TeamFlp teamId={this.id} />
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;
