import Tasks from './Tasks';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../../logic/services/acl';

export default {
  ...Tasks,
  key: 'tasks',
  label: gettext('Tasks'),
  isVisible: () => hasAccess('tasks.list'),
};
