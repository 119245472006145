import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import overlayActions from '../../../../components/overlay';
import connect from '../../../../logic/connect';
import TrackingLinkSingle from './TrackingLinkSingle';
import withDateFilter from '../../campaigns/Campaigns/CampaignStat/DateFilter';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

const TrackingLinkSinglePage = compose(
  connect(null, mapDispatchToProps),
  withDateFilter,
  withErrorBoundary,
  withRouter,
)(TrackingLinkSingle);

export default {
  component: TrackingLinkSinglePage,
  aclId: 'team.profile.campaigns.retrieve',
  path: appRoutes.TRACKING_LINK,
};
