
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionsTypes from '../types';
import Unarchive from './Unarchive';

const text = {
  LABEL: gettext('Unarchive'),
};

// @TODO missing archive icon
const ACTION_ICON = 'Delete';

export default {
  name: 'unarchive',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionsTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    if (isIb) {
      return user.is_archived && hasAccess('ib.unarchive.*');
    } 
    return state === USER_STATE.ARCHIVED && hasAccess('user.unarchive.*');
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(Unarchive, sidepanelOptions);
  },
};
