import React, { PureComponent, Fragment } from 'react';
import { notifier, ComboxFooter, Select, Space } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { func, object, bool } from 'prop-types';
import moment from 'moment';

import fieldPropsResolver from '../../../../../../../components/form/logic/fieldPropsResolver';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import to from '../../../../../../../logic/utilities/to';
import { formatRequestData } from '../../../../../../../components/form/logic/utils';

const FORM_KEY = 'CREATE_LOG_FORM';

const { create, setFields, showLoader, setFieldValue } = FormActionsFactory(FORM_KEY);

const text = {
  TEXT: gettext('Text'),
  CREATE: gettext('Create'),
  TYPE: gettext('Type'),
  CALL_TYPE: gettext('Call type'),
  MESSAGE: gettext('Message'),
  CALL: gettext('Call'),
  SMS: gettext('SMS'),
  CHAT: gettext('Chat'),
  RECEPIENTS_EMAIL: gettext('Recepient\'s email'),
  TYPE_BODY: gettext('Type body text here'),
  TYPE_DESC: gettext('Type description text here'),
  TYPE_TEXT: gettext('Type text here'),
  SUCCESS: gettext('Communication record successfully added'),
  ERROR_GENERAL: gettext('Something went wrong!'),
};

const channelTypes = {
  MESSAGES: 'messages',
  CHAT: 'chat',
  SMS: 'sms',
  CALLS: 'calls',
};

const propTypes = {
  closeCombox: func.isRequired,
  submitInProgress: bool.isRequired,
  user: object.isRequired,
  isIb: bool.isRequired,
  actions: object.isRequired,
};

const logTypes = [
  {
    display_name: text.MESSAGE,
    value: channelTypes.MESSAGES,
  }, {
    display_name: text.CALL,
    value: channelTypes.CALLS,
  }, {
    display_name: text.SMS,
    value: channelTypes.SMS,
  }, {
    display_name: text.CHAT,
    value: channelTypes.CHAT,
  },
];

const fieldModifiers = {
  text: {
    type: 'textarea',
    placeholder: text.TYPE_TEXT,
  },
  type: {
    placeholder: text.CALL_TYPE,
    label: text.CALL_TYPE,
  },
  email: {
    label: text.RECEPIENTS_EMAIL,
  },
  description: {
    type: 'textarea',
    placeholder: text.TYPE_DESC,
  },
  body: {
    type: 'textarea',
    placeholder: text.TYPE_BODY,
  },
  status: {
    clearable: false,
  },
};

const dateFields = ['date', 'start_time', 'end_time'];

class CreateLog extends PureComponent {
  constructor(props) {
    super(props);
    this.api = (user, logType) => getDjangoApi(`users/${user.id}/${logType.value}`);
    this.state = {
      logType: logTypes[0],
    };
  }

  componentDidMount() {
    this.loadForm(logTypes[0]);
  }

  onChangeType = (logType) => {
    this.setState({ logType }, () => this.loadForm(logType));
  }

  onSubmit = async () => {
    const { user, actions } = this.props;
    const { logType } = this.state;
    const formatRequest = (data) => {
      const request = formatRequestData(data);
      return _.reduce(request, (acc, field, key) => {
        if (dateFields.includes(key)) {
          return { ...acc, [key]: moment(field).format() };
        }
        return { ...acc, [key]: field };
      }, {});
    };
    const [err] = await to(actions.create(this.api(user, logType), formatRequest));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    const { closeCombox } = this.props;
    notifier.success(text.SUCCESS);
    closeCombox();
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  loadForm = async (logType) => {
    const { actions, user } = this.props;
    actions.showLoader(true);
    const { data } = await this.api(user, logType).options();
    const { fields } = data.actions.POST;
    const resolvedFields = fields.map(field => fieldPropsResolver(field));
    actions.setFields(resolvedFields);
    this.loadDefaultFields(resolvedFields);
    actions.showLoader(false);
  }

  loadDefaultFields(fields) {
    const { logType } = this.state;
    const { user, isIb, actions } = this.props;
    const telephoneKey = isIb ? 'phone' : 'telephone';
    const phoneNumber = user[telephoneKey];
    const dateValue = moment().format('YYYY-MM-DD HH:mm');
    const callType = fields.find(field => field.name === 'type');
    const status = fields.find(field => field.name === 'status');
    const updateField = (field, id, value) => {
      if (field.id === id) {
        actions.setFieldValue({
          id,
          value,
        });
      }
    };
    
    fields.forEach((field) => {
      if (logType.value === channelTypes.CALLS) updateField(field, 'type', callType.options[0]);
      if (status) updateField(field, 'status', status.options[0]);
      updateField(field, 'email', user.email);
      updateField(field, 'phone_number', phoneNumber);
      if (dateFields.includes(field.id)) {
        updateField(field, field.id, dateValue);
      }
    });
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory({ form: FORM_KEY });
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { submitInProgress } = this.props;
    const { logType } = this.state;
    const Form = this.renderForm();
    return (
      <Fragment>
        <Form 
          formId={FORM_KEY}
          rerenderFields
          modifiers={fieldModifiers}
          renderForm={formFields => (
            <Fragment>
              <Fragment>
                <Select 
                  label={text.TYPE} 
                  onChange={this.onChangeType}
                  value={logType}
                  options={logTypes}
                  clearable={false}
                  valueKey="value"
                  labelKey="display_name"
                />
                <Space size={12} />
              </Fragment>
              {formFields.status}
              {formFields.date}
              {formFields.type}
              {formFields.start_time}
              {formFields.end_time}
              {formFields.phone_number}
              {formFields.text}
              {formFields.subject}
              {formFields.email}
              {formFields.body}
              {formFields.communication_status}
              {formFields.description}
            </Fragment>
          )}
        />
        <ComboxFooter 
          onConfirm={this.onSubmit}
          isLoading={submitInProgress}
          confirmText={text.CREATE} 
          formId={FORM_KEY}
        />
      </Fragment>
    );
  }
}

CreateLog.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      create,
      setFields,
      setFieldValue,
      showLoader,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ forms }) => {
  const form = forms[FORM_KEY];
  return {
    submitInProgress: form.submitInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLog);
