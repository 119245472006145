import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { InfoBox } from 'tc-biq-design-system';
import { object, number } from 'prop-types';

import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import connect from '../../../../../../logic/connect';
import activityActions from '../../Model/activityActions';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

const propTypes = {
  actions: object.isRequired,
  newActivityCount: number.isRequired,
  activityData: object.isRequired,
  userId: number,
  boUserId: number,
  countMax: number,
};

const defaultProps = {
  userId: null,
  boUserId: null,
  countMax: 10,
};

const text = { NEW_ACTIVITIES: gettext('new activites'), NEW_ACTIVITY: gettext('new activity') };
const displayPerUserOLP = (data, userId, boUserId) => {
  if (userId) return data.users.includes(userId);
  if (boUserId) return data.bo_users.includes(boUserId);
  return true;
};

class NewNotifications extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  render() {
    const { activityData, newActivityCount, userId, boUserId, countMax } = this.props;
    const displayNewActivities = newActivityCount > 0 && displayPerUserOLP(activityData, userId, boUserId);
    const countMaxText = `${countMax}+`;
    return (
      <div>
        {displayNewActivities ? (
          <div className="activity-body__notifications">
            <InfoBox onClick={() => this.actions.loadActivity(true)}>
              <span className="activity-body__notifications__content-text text-primary-500 tc-paragraph-strong">{`${
                newActivityCount > countMax ? countMaxText : newActivityCount
              } ${newActivityCount > 1 ? text.NEW_ACTIVITIES : text.NEW_ACTIVITY}`}
              </span>
            </InfoBox>
          </div>
        ) : null}
      </div>
    );
  }
}

NewNotifications.propTypes = propTypes;
NewNotifications.defaultProps = defaultProps;

const mapStateToProps = ({ pages }) => {
  const {
    activities: { newActivityCount, activityData },
  } = pages.ACTIVITY_STREAM;
  return { newActivityCount, activityData };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loadActivity: activityActions.loadActivity,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(NewNotifications));
