/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { 
  Sidepanel, 
  Avatar, 
  Panel, 
  Icon,
  Button,
  Pill,
  Space,
  notifier,
} from 'tc-biq-design-system';
import { get } from 'lodash';
import { generatePath, Link } from 'react-router-dom';

import { getDjangoApi } from '../../../../logic/services/api-factory';
import { gettext } from '../../../../logic/utilities/languageUtility';
import to from '../../../../logic/utilities/to';
import connect from '../../../../logic/connect';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import overlayActions from '../../../overlay';
import { ClientsList, TaskRenderer } from '../..';
import If from '../../../If';
import appRoutes from '../../../App/Router/appRoutes';

import './BiqUserPreview.scss';

export const SIDEPANEL_ID = 'BIQ_USER_PREVIEW';

const text = {
  DISABLE_ACCOUNT: gettext('Disable account'),
  VIEW_PROFILE: gettext('View profile'),
  BASIC_INFO: gettext('Basic info'),
  USERNAME: gettext('Username'),
  EMAIL: gettext('Email'),
  TELEPHONE: gettext('Telephone'),
  ASSIGNED_CLIENTS: gettext('Assigned clients'),
  ASSIGNED_TASKS: gettext('Assigned tasks'),
  CLIENTS_HELP_TEXT: gettext('This BO user does not have assigned clients'),
  TASKS_HELP_TEXT: gettext('This BO user does not have assigned tasks'),
  COMPLETED: gettext('Task successfully completed'),
};

const UserTitle = ({ user }) => {
  const { first_name, last_name, id, username } = user;
  const hasFullName = first_name && last_name;

  const title = hasFullName ? `${first_name} ${last_name}` : username;
  const initials = hasFullName ? `${first_name[0]}${last_name[0]}}` : null;

  return (
    <div className="title-container">
      <div>
        <Avatar size="regular" initials={initials} />
      </div>
      <div className="title-text m-l">
        <span>{title}</span>
        <div className="title-user-state">
          <Pill>{user.team.name}</Pill>
        </div>
        <br />
        <span className="title-help-text text-grey">{id}</span>
      </div>
    </div>
  );
};

const PanelTitle = ({ icon, text }) => (
  <div>
    <Icon name={icon} size="large" colorName="text-primary-500" />
    <span className="biq-panel-title black-text">{text}</span>
  </div>
);

const BasicInfoRow = ({ prop, value }) => (
  <p className="biq-user-preview__row">
    <span className="grey-text">{prop}</span>
    <span className="black-text">{value}</span>
  </p>
);

const renderTasks = (tasks, completeTask, viewTask) => (
  <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
    { 
      tasks.map(task => <TaskRenderer key={task.id} task={task} viewTask={viewTask} completeTask={completeTask} hidePerformer />)
    }
  </div>
);

const HelpText = ({ children }) => <div style={{ padding: '16px' }} className="black-text">{children}</div>;

class UserPreview extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.clientsQuery = {
      limit: 100,
      offset: 0,
    };

    this.tasksApi = getDjangoApi('tasks');

    this.completeTask = this.completeTask.bind(this);
    this.viewTask = this.viewTask.bind(this);
    this.modalFooter = this.modalFooter.bind(this);
  }

  componentDidUpdate(prevProps) {
    const previousParams = get(prevProps, 'sidepanel.parameters.data');
    const previousUser = get(prevProps, 'sidepanel.parameters.user');
    const currentParams = get(this.props, 'sidepanel.parameters.data');
    const currentUser = get(this.props, 'sidepanel.parameters.user');

    const userHasChanged = !previousParams || (currentParams && previousParams.id !== currentParams.id);
    const userReopened = !!previousParams && !currentUser && currentParams && !!previousUser && currentParams.id === previousParams.id;

    if (userHasChanged && currentParams) {
      this.fetchUser(currentParams.id);
      this.fetchAssignedClients(currentParams.id);
      this.fetchTasks(currentParams.id);
    } else if (userReopened) {
      this.actions.update(SIDEPANEL_ID, prevProps.sidepanel.parameters);
    }
  }

  async fetchUser(id) {
    const profile = await getDjangoApi(`team/profile/${id}`).list();
    const member = await getDjangoApi(`team/${id}`).list();
    
    this.actions.update(SIDEPANEL_ID, parameters => ({
      ...parameters, 
      user: {
        ...profile.data,
        ...member.data,
      },
    }));
  }

  async fetchAssignedClients(id) {
    const { data } = await getDjangoApi(`team/profile/${id}/clients`).list(this.clientsQuery);
    
    this.actions.update(SIDEPANEL_ID, parameters => ({
      ...parameters,
      assignedClients: data.results,
    }));
  }

  async fetchTasks(id) {
    const query = { 
      limit: 10, 
      offset: 0, 
      performer__auth_bo_user__id__exact: id,
    };

    const { data } = await this.tasksApi.list(query);
    
    this.actions.update(SIDEPANEL_ID, parameters => ({
      ...parameters,
      tasks: data.results,
    }));
  }

  viewTask(task) {
    this.actions.open('VIEW_TASK', task);
  }

  async completeTask({ id }) {
    const api = this.tasksApi.one(id).all('mark_as_completed');
    const [err] = await to(api.create());
    if (err) {
      notifier.error(err.data.msg);
    } else {
      notifier.success(text.COMPLETED);
      const userId = get(this.props, 'sidepanel.parameters.data.id');
      this.fetchTasks(userId);
    }
  }

  modalFooter() {
    const { archive } = this.props;
    const { user } = get(this.props, 'sidepanel.parameters');

    return (
      <div>
        <If condition={user.type !== 'inactive'}>
          <Button onClick={() => archive({ id: user.id })} color="ghost">{text.DISABLE_ACCOUNT}</Button>
        </If>
        <Link to={generatePath(appRoutes.TEAM_MEMBER, { id: user.id })}>
          <Button color="primary" onClick={null}>{text.VIEW_PROFILE}</Button>
        </Link>
      </div>
    );
  }

  render() {
    const { sidepanel } = this.props;
    const { parameters } = sidepanel;
    if (!parameters || !parameters.user) return null;

    const { user, assignedClients, tasks } = parameters;

    const { username, email, phone } = user; 
    const Title = <UserTitle user={user} />;

    return (
      <Sidepanel
        type="primary"
        title={Title}
        visible={sidepanel.visible}
        onCloseIconClick={() => this.actions.close(SIDEPANEL_ID)}
        footerRender={this.modalFooter}
      >
        <Space size={10} />

        <Panel style={{ margin: '0' }} title={<PanelTitle icon="User" text={text.BASIC_INFO} />}>
          <div style={{ padding: '16px' }}>
            <BasicInfoRow prop={text.USERNAME} value={username} />
            <BasicInfoRow prop={text.EMAIL} value={email} />
            <BasicInfoRow prop={text.TELEPHONE} value={phone} />
          </div>
        </Panel>

        <Panel title={<PanelTitle icon="Client" text={text.ASSIGNED_CLIENTS} />} style={{ padding: 0 }}>
          { assignedClients && assignedClients.length
            ? <div style={{ maxHeight: '200px', overflowY: 'scroll' }}><ClientsList clients={assignedClients} /></div>
            : <HelpText>{text.CLIENTS_HELP_TEXT}</HelpText>}
        </Panel>

        <Panel title={<PanelTitle icon="CalendarCreate" text={text.ASSIGNED_TASKS} />} style={{ padding: 0 }}>
          { tasks && tasks.length
            ? renderTasks(tasks, this.completeTask, this.viewTask) : <HelpText>{text.TASKS_HELP_TEXT}</HelpText>}
        </Panel>
      </Sidepanel>
    );
  }
}

const mapStateToProps = ({ overlays }) => ({ sidepanel: overlays[SIDEPANEL_ID] });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    open: overlayActions.open,
    close: overlayActions.close,
    update: overlayActions.update,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(UserPreview));
