import WITHDRAWAL_STEP_STATE from '../enums/withdrawal-step-state';

function withdrawalStepStateLabel() {
  return function (state) {
    switch (state) {
      case WITHDRAWAL_STEP_STATE.N_A:
        return 'N/A';
      case WITHDRAWAL_STEP_STATE.PENDING:
        return 'Pending';
      case WITHDRAWAL_STEP_STATE.APPROVED:
        return 'Approved';
      case WITHDRAWAL_STEP_STATE.REJECTED:
        return 'Rejected';
    }
  };
}

export default withdrawalStepStateLabel;
