import React from 'react';

import { SidepanelProvider, ComboxProvider } from 'tc-biq-design-system';
import ClientProfile from './ClientProfile';
import appRoutes from '../../../../components/App/Router/appRoutes';

const ClientProfileController = props => (
  <ComboxProvider>
    <SidepanelProvider>
      <ClientProfile {...props} />
    </SidepanelProvider>
  </ComboxProvider>
);

export default {
  component: ClientProfileController,
  path: appRoutes.CLIENT_PROFILE,
  aclId: 'user.retrieve',
};
