/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';

import FormFactory, { FormActionsFactory } from '../../../../../components/form';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import generalActions from './Model';
import connect from '../../../../../logic/connect';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';

const autocompleteBackendsApi = getDjangoApi('/backoffice/api/autocomplete/trading-backends/');
export const FORM_ID = 'SETTINGS_GENERAL_FORM';

const { setFields, setFieldValue } = FormActionsFactory(FORM_ID);

const formConfig = {
  form: FORM_ID,
};

const loadOptions = value => autocompleteBackendsApi.list({ name: value }).then(res => res.data.results);

const fieldPropsResolver = (data, customOnChange) => data.map((field) => {
  const { slug, description, key } = field;
  switch (slug) {
    case 'notification_emails':
    case 'gbgroup_profile_ids':
      return {
        type: 'textarea',
        id: key,
        label: description,
      };
    case 'min_transaction_amount_bo_ignore':
      return {
        label: description,
        type: 'checkbox',
        id: key,
      };
    case 'internal_transfer_disabled_for_backends':
      return {
        type: 'multiselect',
        multi: true,
        async: true,
        label: description,
        loadOptions,
        labelKey: 'name',
        valueKey: 'id',
        debounceInterval: 500,
        id: key,
        customOnChange,
      };
    case 'launch_date':
      return {
        label: description,
        type: 'date',
        id: key,
        inputProps: { label: description },
      };
    default:
      return {
        type: 'text',
        label: description,
        id: key,
      };
  }
});

class GeneralForm extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  componentDidMount() {
    this.actions.getData();
  }

  componentDidUpdate(prevProps) {
    const { data, fields } = this.props;
    if ((fields.length === 0 && data.length !== 0) || !_.isMatch(prevProps.data, data)) {
      this.actions.setFields(fieldPropsResolver(data, this.actions.setFieldValue));
      data.forEach((item) => {
        const { value, key } = item;
        if (value) {
          this.actions.setFieldValue({ id: key, value });
        }
      });
    }
  }

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    const { FormComponent } = this;
    return (
      <FormComponent
        renderForm={formFields => (
          <Fragment>
            <div className="biq-settings-general__wrapper__section">{formFields.BRAND_NAME}</div>
            <div className="biq-settings-general__wrapper__section">
              {formFields.MIN_TRANSACTION_AMOUNT}
              {formFields.MIN_TRANSACTION_AMOUNT_BO_IGNORE}
              {formFields.MIN_TRANSACTION_AMOUNT_INTERNAL}
              {formFields.MIN_DEPOSIT_AMOUNT}
              {isFeatureEnabled()('DISABLE_INTERNAL_TRANSFER')
                  && formFields.INTERNAL_TRANSFER_DISABLED_FOR_BACKENDS}
            </div>
            <div className="biq-settings-general__wrapper__section">
              {formFields.CLEARBIT_KEY}
              {formFields.HOTJAR_CODE}
              {formFields.INTERCOMIO_TOKEN}
            </div>
            <div className="biq-settings-general__wrapper__section">{formFields.LAUNCH_DATE}</div>
          </Fragment>
        )}
      />
    );
  };

  render() {
    return this.renderForm();
  }
}

const mapStateToProps = ({ pages, forms }) => {
  const { data } = pages.SETTINGS_GENERAL;
  const { fields } = forms[FORM_ID];
  return { data, fields };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getData: generalActions.getData,
      setFields,
      setFieldValue,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(GeneralForm));
