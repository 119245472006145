import { bindActionCreators, compose } from 'redux';

import overlayActions from '../../../../../components/overlay';
import connect from '../../../../../logic/connect';
import PaymentGateways from './PaymentGateways';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import { hasAccess } from '../../../../../logic/services/acl';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
  dispatch,
});

const PaymentGatewaysPage = compose(
  connect(null, mapDispatchToProps),
  withErrorBoundary,
)(PaymentGateways);

export default {
  component: PaymentGatewaysPage,
  aclFn: () => hasAccess('payment_gateways.list') || hasAccess('payment_gateways.acquirers.list'),
  path: appRoutes.SETTINGS_PAYMENT_GATEWAYS,
};
