import queryString from 'query-string';

import { urlCombine } from './api';

const CONFIG = window.config;

export const REDIRECT_URI = urlCombine(location.origin + CONFIG.frontendRoot, 'auth'); // eslint-disable-line
export const isEnabled = CONFIG.auth0_enabled === 'True';
const clientId = CONFIG.auth0_config?.auth0_client_id;

export function auth0RedirectTo(postLoginRoute) {
  const uriParams = queryString.stringify({
    client_id: clientId,
    protocol: 'oauth2',
    redirect_uri: `${REDIRECT_URI}?post_login_route=${encodeURIComponent(postLoginRoute || '')}`,
    prompt: 'consent',
    response_type: 'code',
    connection: CONFIG.auth0_config.auth0_connection,
    scope: 'openid profile email',
  });

  window.location.href = `https://${CONFIG.auth0_config.auth0_domain}/authorize/?${uriParams}`;
}

export function auth0Logout(redirectParams = {}) {
  const uriParams = queryString.stringify({
    client_id: clientId,
    returnTo: urlCombine(location.origin + CONFIG.frontendRoot, `login?${encodeURIComponent(redirectParams)}`),// eslint-disable-line
  });

  window.location.href = `https://${CONFIG.auth0_config.auth0_domain}/v2/logout/?${uriParams}`;
}
