import React from 'react';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import DashboardPage from './DashboardPage';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const text = {
  CUSTOMIZATION_PAGE_TITLE: gettext('Customization'),
  DASHBOARDS_PAGE_TITLE: gettext('Dashboard settings'),
  PAGE_TITLE: gettext('Client profile layout'),
};

const routes = {
  CUSTOMIZATION: appRoutes.SETTINGS_CUSTOMIZATION,
  DASHBOARDS: appRoutes.SETTINGS_CUSTOMIZATION_DASHBOARDS,
  NEW_DASHBOARD: appRoutes.SETTINGS_CUSTOMIZATION_DASHBOARD_CREATE,
};

const bread = [
  { label: text.CUSTOMIZATION_PAGE_TITLE, route: routes.CUSTOMIZATION },
  { label: text.DASHBOARDS_PAGE_TITLE, route: routes.DASHBOARDS },
  { label: text.PAGE_TITLE, route: routes.NEW_DASHBOARD },
];

const DashboardLayout = () => (
  <DashboardPage
    bread={bread}
  />
);

export default withErrorBoundary(DashboardLayout);
