// eslint-disable-next-line no-unused-vars
import React, { useMemo } from 'react';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import FormFactory from '../../../../../../../components/form';
import appRoutes from '../../../../../../../components/App/Router/appRoutes';

export const state = {
  CREATE: 'create',
  EDIT: 'edit',
};

export const text = {
  TITLE: {
    [state.CREATE]: gettext('Create state rule'),
    [state.EDIT]: gettext('Edit state rule'),
  },
  DEPOSITS_SETTINGS: gettext('Deposits settings'),
  SUCCESS_CREATE: gettext('Successfully created'),
  SUCCESS_EDIT: gettext('Successfully edited'),
  SUCCESS_CLONE: gettext('Successfully cloned'),
  ERROR: gettext('error occurred during initialization'),
  CREATE: gettext('Create'),
  DISCARD: gettext('Discard'),
  REQUIRED: gettext('This field is required!'),
  EDIT: gettext('Edit'),
};

export const bread = type => [
  {
    label: text.DEPOSITS_SETTINGS,
    route: appRoutes.SETTINGS_DEPOSITS,
  }, {
    label: text.TITLE[type],
    route: '',
  },
];

const getRequestFieldValue = (key, field) => {
  switch (key) {
    case 'business_group':
      return field.name;
    case 'country':
      return field.iso_code;
    case 'payout_percents':
      return field ? +field : undefined;
    case 'refund_percents':
      return field ? +field : undefined;
    default:
      return !!field && typeof field === 'object' ? field.id : field;
  }
};

export const requestFieldFormatter = formState => _.reduce(formState, (values, field, key) => ({
  ...values,
  [key]: getRequestFieldValue(key, field),
}), {});


export const useStateRuleForm = (formConfig) => {
  const FormComponent = useMemo(() => FormFactory(formConfig), [formConfig]);
  return FormComponent;
};
