import React from 'react';
import { Tooltip, Icon, Popconfirm } from 'tc-biq-design-system';

import { ActionsCellWrapper, UsernameCell } from '../../../../components/gridCellRenderers';
import hideCols from '../../../../logic/utilities/hideCols';
import { gettext } from '../../../../logic/utilities/languageUtility';

const text = {
  EDIT: gettext('Edit'),
  DELETE: gettext('Delete'),
  POPCONFIRM_LABEL: gettext('Are you sure you want to delete communication status?'),
  NO: gettext('No'),
  YES: gettext('Yes'),
  ACTIONS: gettext('Actions'),
};

const actionIcons = (onEdit, onDelete) => ({
  ...(onEdit && {
    onEdit: (actionHandler, data) => (
      <div key="Modify" onClick={() => actionHandler(data)}>
        <Tooltip title={text.EDIT} placement="top">
          <Icon name="Edit" />
        </Tooltip>
      </div>
    ),
  }),
  ...(onDelete && {
    onDelete: (actionHandler, data) => (
      <Tooltip title={text.DELETE} key="remove" placement="top">
        <Popconfirm
          type="destructive"
          icon="Delete"
          onConfirm={() => actionHandler(data)}
          label={text.POPCONFIRM_LABEL}
          buttonLabels={{
            cancel: text.NO,
            confirm: text.YES,
          }}
        >
          <Icon name="Delete" />
        </Popconfirm>
      </Tooltip>
    ),
  }),
});

const modifier = ({ onEdit, onDelete, previewBiqUser }) => {
  const actions = {
    ...(onEdit && { onEdit }),
    ...(onDelete && { onDelete }),
  };

  return {
    ...hideCols(['records_count']),
    creator: {
      cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
    },
    actions: {
      label: text.ACTIONS,
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(onEdit, onDelete)),
      width: 120,
    },
  };
};

export default modifier;
