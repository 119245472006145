import React, { PureComponent } from 'react';
import { Input } from 'tc-biq-design-system';
import { object } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import withUnmountMethod from '../../../../../components/hoc/withUnmountMethod';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import textDateFilterActions from './Model';
import acitivityActions from '../Model/activityActions';

import ActivityTypeFilter from './ActivityTypeFilter';
import './ActivityStreamFilters.scss';
import DatePickerRangeAll from '../../../../../components/common/DatePickerRangeAll';

const propTypes = {
  textSearchFilter: object.isRequired,
  dateFilter: object.isRequired,
  actions: object.isRequired,
};

const { setTextSearchFilter, setDateFilter, resetFilters } = textDateFilterActions;
class ActivityStreamFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  componentDidUpdate(prevProps) {
    const { dateFilter, textSearchFilter } = this.props;
    if (
      !_.isEqual(dateFilter, prevProps.dateFilter)
      || !_.isEqual(textSearchFilter, prevProps.textSearchFilter)
    ) {
      this.actions.loadActivity();
    }
  }

  componentWillUnmount() {
    this.actions.resetFilters();
  }

  onDateChange = (dateValues) => {
    this.actions.setDateFilter(dateValues);
  };

  onTextSearch = (value) => {
    this.actions.setTextSearchFilter(value);
  };

  render() {
    const { textSearchFilter, dateFilter } = this.props;
    const { startDate, endDate } = dateFilter;

    return (
      <div className="activity-stream-filters">
        <div className="activity-stream-filters__filter-item">
          <ActivityTypeFilter />
        </div>
        <div className="activity-stream-filters__filter-item">
          <DatePickerRangeAll
            onChange={this.onDateChange}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        <div className="activity-stream-filters__filter-item__search">
          <Input
            placeholder="Search within selected criteria"
            icon="Search"
            iconPosition="left"
            onChange={e => this.onTextSearch(e.target.value)}
            value={textSearchFilter.value}
          />
        </div>
      </div>
    );
  }
}

ActivityStreamFilters.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setTextSearchFilter,
      setDateFilter,
      resetFilters,
      loadActivity: acitivityActions.loadActivity,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const { filters } = pages.ACTIVITY_STREAM;
  const {
    dateTextFilters: { textSearchFilter, dateFilter },
  } = filters;
  return { textSearchFilter, dateFilter };
};

export default withUnmountMethod(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorBoundary(ActivityStreamFilters)),
);
