import React, { Component } from 'react';
import { array, object, string } from 'prop-types';
import { Radio, Button, notifier } from 'tc-biq-design-system';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import { gettext } from '../../../../logic/utilities/languageUtility';
import to from '../../../../logic/utilities/to';


import './RiskCell.scss';

const api = getDjangoApi('settings/countries');

const text = {
  SAVE: gettext('Save changes'),
  DISCARD: gettext('Discard changes'),
  SUCCESS: gettext('Country risk changed'),
};

const propTypes = {
  options: array,
  value: object,
  name: string.isRequired,
  data: object.isRequired,
};

const defaultProps = {
  options: [],
  value: null,
};

class RiskCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      saved: null,
      submitInProgress: false,
    };
  }

  onSubmit = async () => {
    const { data } = this.props;
    const { selected } = this.state;
    
    this.setState({ submitInProgress: true });

    const [err] = await to(api.updatePart(data.iso_code, {
      risk_type: selected.value,
      ...(data.whitelabel ? { whitelabel: data.whitelabel } : {}),
    }));

    this.setState({ 
      submitInProgress: false,
      saved: { ...selected },
      selected: null,
    });

    if (!err) {
      notifier.success(text.SUCCESS);
    }
  }

  onChange = (e) => {
    const defaultValue = this.getDefault();
    const { value } = e.target;
    const { options } = this.props;
    const selected = options.find(o => o.value === Number(value));

    this.setState({ selected: selected.value === defaultValue.value ? null : selected });
  }

  onDiscard = () => this.setState({ selected: null })

  getDefault = () => {
    const { saved } = this.state;
    const { value } = this.props;

    return saved || value;
  }

  render() {
    const { selected, submitInProgress } = this.state;
    const { options, name } = this.props;
    const defaultValue = this.getDefault();
    const value = selected || defaultValue;
    
    return (
      <div className="risk-cell">  
        <Radio.Group
          horizontal
          name={name}
          value={value.value}
          onChange={this.onChange}
        >
          {options.map(option => (
            <Radio key={option.value} value={option.value}>
              {option.display_name}
            </Radio>  
          ))}
        </Radio.Group>
        {selected && (
          <div className="risk-cell__buttons">
            <Button 
              loading={submitInProgress} 
              size="small" 
              onClick={this.onSubmit} 
              color="confirmation" 
              icon="Checkmark"
            >
              {text.SAVE}
            </Button>
            <Button loading={submitInProgress} size="small" onClick={this.onDiscard} color="ghost">{text.DISCARD}</Button>
          </div>
        )}
      </div>
    );
  }
}

RiskCell.propTypes = propTypes;
RiskCell.defaultProps = defaultProps;

export default RiskCell;
