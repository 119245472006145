import React, { Component } from 'react';
import { string, func, object, node, bool } from 'prop-types';
import { Icon } from 'tc-biq-design-system';

import If from '../If';

const propTypes = {
  data: object.isRequired,
  onPreview: func.isRequired,
  children: node.isRequired,
  href: string,
  disableEllipsis: bool,
};

const defaultProps = {
  href: '',
  disableEllipsis: false,
};

const styles = {
  previewContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  cellText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  previewIcon: {
    cursor: 'pointer',
    position: 'relative',
    top: '3px',
  },
  fullWidth: { width: '100%' },
};

class PreviewCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseEntered: false,
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter() {
    this.setState(() => ({ mouseEntered: true }));
  }

  handleMouseLeave() {
    this.setState(() => ({ mouseEntered: false }));
  }

  render() {
    const { mouseEntered } = this.state;
    const { onPreview, data, children, href, disableEllipsis } = this.props;

    return (
      <div style={styles.previewContainer} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        <span style={disableEllipsis ? styles.fullWidth : styles.cellText}>
          { children }
        </span>
        <If condition={mouseEntered}>
          { href ? (
            <a href={href} target="_blank" rel="noopener noreferrer">
              <Icon name="View" colorName="text-primary-400" />
            </a>
          ) : (
            <span style={styles.previewIcon} onClick={() => onPreview({ data })}>
              <Icon name="View" colorName="text-primary-400" />
            </span>
          )}
        </If>
      </div>
    );
  }
}

PreviewCell.propTypes = propTypes;
PreviewCell.defaultProps = defaultProps;
export default PreviewCell;
