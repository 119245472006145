import React, { Component } from 'react';
import { 
  Sidepanel, 
  Block,
  Table, 
  Icon,
  Space,
  Spinner,
  notifier,
} from 'tc-biq-design-system';
import {
  bool, 
  func,
  arrayOf,
  object,
  string, 
} from 'prop-types';
import get from 'lodash/get';

import './DataTagsSidepanel.scss';
import FormFactory from '../../../../components/form';
import { Field } from '../../../../components/form/logic/utils';
import { gettext } from '../../../../logic/utilities/languageUtility';

const sidepanelID = 'SMS_TEMPLATE_DATA_TAGS';
const formID = 'DATA_TAGS_FORM';

const text = {
  CLIPBOARD_MESSAGE: gettext('Successfully copied to clipboard'),
  TITLE: gettext('Data tags'),
  PLACEHOLDER: gettext('Search available placeholders'),
  WARNING_TITLE: gettext('Warning'),
  WARNING_DESCRIPTION: gettext('Using data tags might result in sending multiple SMS messages'),
};

const formConfig = api => ({
  form: formID, 
  customFields: [
    {
      id: 'triggerRule',
      labelKey: 'display_name',
      valueKey: 'value',
      placeholder: text.PLACEHOLDER,
      type: 'select',
      async: true,
      debounceInterval: 500,
      loadOptions: () => api
        .options()
        .then(({ data: { event_specs } }) => Object.keys(event_specs).map(eventSpecKey => ({ 
          display_name: eventSpecKey,
          value: eventSpecKey,
          eventUrl: event_specs[eventSpecKey],
        }))), 
    },
  ],
});

const Warning = () => (
  <Block 
    fallbackIcon="Warning"
    fallbackLogo="icon"
    title={text.WARNING_TITLE}
    description={text.WARNING_DESCRIPTION}
  />
);

export const copyToClipboard = ({ name }) => {
  const data = new window.Blob([name], { type: 'text/plain' });
  const item = new window.ClipboardItem({ 'text/plain': data });

  window.navigator.clipboard.write([item]);
  notifier.success(text.CLIPBOARD_MESSAGE);
};

class SmsDataTagsSidepanel extends Component {
  constructor(props) {
    super(props);

    this.FormComponent = null;
  }

  componentDidUpdate(prevProps) {
    const triggerRulePath = 'values.triggerRule';
    const oldTriggerRule = get(prevProps, triggerRulePath);
    const newTriggerRule = get(this.props, triggerRulePath);
    const { actions } = this.props;

    if (newTriggerRule && !oldTriggerRule) {
      this.fetchPlaceholders(newTriggerRule.eventUrl);
      return;
    }

    if (!newTriggerRule && oldTriggerRule) {
      actions.resetPlaceholdersStateAction();
      return;
    }
    
    if (oldTriggerRule && newTriggerRule && oldTriggerRule.value !== newTriggerRule.value) {
      this.fetchPlaceholders(newTriggerRule.eventUrl);
    }
  }
  
  onCloseSidepanel = () => {
    const { closeSidepanel, actions } = this.props;
    actions.resetPlaceholdersStateAction();
    closeSidepanel(sidepanelID);
  }

  fetchPlaceholders = (eventUrl) => {
    const { actions } = this.props;

    actions.fetchPlaceholdersAction(eventUrl);
  }

  renderForm = () => {
    if (!this.FormComponent) {
      const { api } = this.props;
      this.FormComponent = FormFactory(formConfig(api));
    }

    const { FormComponent } = this;
    const { placeholders, api, loadingTagsInProgress } = this.props;
    const showWarning = api.url.indexOf('sms') >= 0;

    return (
      <FormComponent renderForm={fields => (
        <React.Fragment>
          { showWarning && <Warning /> }
          { showWarning && <Space size={16} /> }
          <Field>
            { fields.triggerRule }
          </Field>
          <Warning />
          <Space size={16} />
          {
            loadingTagsInProgress && <Spinner />
          }
          { 
            !!placeholders.length
              && (
              <Table 
                cols={
                  [
                    { 
                      key: 'name',
                      width: '80%', 
                      title: '', 
                    },
                    { key: 'select',
                      width: '20%', 
                      title: '',
                      render: () => <Icon name="Duplicate" size="small" />,
                    },
                  ]
                }
                data={placeholders.map((p, i) => ({
                  id: `${p}-${i}`,
                  key: `${p}-${i}`,
                  name: `{{ ${p} }}`,
                  title: '',
                }))}
                onRowClick={copyToClipboard}
              />
              )
          }
        </React.Fragment>
      )}
      />
    );
  }

  render() {
    const { visible } = this.props;

    return (
      <Sidepanel 
        title={text.TITLE}
        icon="Tag"
        className="sms-tags-sidepanel"
        visible={visible}
        onCloseIconClick={this.onCloseSidepanel}
        footerRender={() => null}
      >
        {
          this.renderForm()
        }
      </Sidepanel>
    );
  }
}

SmsDataTagsSidepanel.propTypes = {
  visible: bool,
  closeSidepanel: func.isRequired,
  placeholders: arrayOf(string).isRequired,
  actions: object.isRequired,
  loadingTagsInProgress: bool.isRequired,
  api: object.isRequired,
};

SmsDataTagsSidepanel.defaultProps = {
  visible: false,
};

export { formID, sidepanelID };
export default SmsDataTagsSidepanel;
