import React from 'react';
import FieldPermissions from '../../../../../components/FieldPermissions';
import { hasAccess } from '../../../../../logic/services/acl';

// eslint-disable-next-line react/prop-types
const TeamFlp = ({ teamId }) => (
  <FieldPermissions 
    team={{ id: teamId }} 
    updateFLPEnabled={hasAccess('team.settings.flp.user_details.update')}
  />
);

export default TeamFlp;
