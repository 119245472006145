import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { func, object } from 'prop-types';

import withFormSidepanel from '../../../../components/FormSidepanel';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import { FormActionsFactory } from '../../../../components/form';
import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';

export const SIDEPANEL_ID = 'WHITELIST_FORM_SIDEPANEL';
export const FORM_ID = 'WHITELIST_LINKS_FORM';

const { setFieldValue } = FormActionsFactory(FORM_ID);
const accountsKey = 'INTEGRATION_BLACKSTONE_WHITE_LISTED_TRADING_ACCOUNTS';

const text = {
  CREATE: gettext('Whitelist trading accounts'),
  EDIT: null,
  SUCCESS: {
    create: gettext('Whitelist trading accounts saved'),
    edit: null,
  },
  WHITELABEL: gettext('Whitelabel'),
  BUTTON_LABELS: {
    create: {
      confirm: gettext('Save'),
      cancel: gettext('Cancel'),
    },
  },
};

const propTypes = {
  onSuccess: func.isRequired,
  actions: object.isRequired,
};

const BACKEND_MODE = 'Live';

const loadOptions = (input = '') => getDjangoApi('autocomplete/trading-accounts')
  .list({ backend__mode: BACKEND_MODE, external_id__contains: input })
  .then(res => res.data.results);


class WhitelistForm extends Component {
  constructor(props) {
    super(props);

    this.FormSidepanel = withFormSidepanel(FORM_ID, SIDEPANEL_ID);

    this.formModifiers = {
      whitelists: {
        customOnChange: this.customOnChange,
        defaultOptions: false,
        loadOptions,
      },
    };
  }

  onFormReady = () => {
    this.setDefaultOptions();
  }

  getApi = () => getDjangoApi('integration/iblackstone/whitelist')

  getFormConfig = () => ({
    customFields: this.getCustomFields(this.customOnChange),
  })

  getRequestPayload = () => ({ whitelists }) => {
    if (!whitelists) return {};
    const externalIds = whitelists.map(({ external_id }) => external_id).join(',');
    return { 
      [accountsKey]: externalIds,
    };
  }

  getCustomFields = () => [{
    id: 'whitelists',
    name: 'whitelists',
    type: 'multiselect',
    label: text.WHITELABEL,
    placeholder: text.WHITELABEL,
    async: true,
    joinValues: true,
    labelKey: 'external_id',
    valueKey: 'external_id',
    dataKeys: 'external_id',
  }]

  setDefaultOptions = () => {
    this.getApi().retrieve().then(({ data }) => {
      if (!data) return [];
      const { actions } = this.props;
      const keys = data[accountsKey] || '';

      const accounts = _.isEmpty(keys) ? [] : keys.split(',').map(key => ({ external_id: key }));

      actions.setFieldValue({ id: 'whitelists', value: accounts });
      return accounts;
    });
  }

  customOnChange = (value) => {
    const { actions } = this.props;
    actions.setFieldValue(value);
  }

  render() {
    const { FormSidepanel, getApi, getFormConfig, getRequestPayload } = this;
    return (
      <FormSidepanel
        {...this.props}
        onFormReady={this.onFormReady}
        options={{
          text,
          getFormConfig,
          getApi,
          getRequestPayload,
          onlyCustomFields: true,
          formModifiers: this.formModifiers,
        }}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setFieldValue,
    },
    dispatch,
  ),
});

WhitelistForm.propTypes = propTypes;

export default connect(null, mapDispatchToProps)(WhitelistForm);
