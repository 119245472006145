import { arrayOf, func, node, oneOfType, string } from 'prop-types';
import React from 'react';

const propTypes = {
  formId: string,
  children: oneOfType([
    arrayOf(node),
    node,
  ]),
  onSubmit: func,
  className: string,
};

const deafultProps = {
  formId: null,
  children: null,
  onSubmit: () => null,
  className: null,
};

const FormEl = ({ formId, children, onSubmit, className }) => {
  const submitHandler = (e) => {
    e.preventDefault();

    if (onSubmit) onSubmit(e);
  };

  return (
    <form id={formId} onSubmit={submitHandler} className={className}>
      {children}
    </form>
  );
};

FormEl.propTypes = propTypes;
FormEl.defaultProps = deafultProps;

export default FormEl;
