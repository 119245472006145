import React from 'react';
import { object, bool, number, func, string } from 'prop-types';
import { notifier, Radio, Icon, Button, Accordion, Table } from 'tc-biq-design-system';

import { gettext } from '../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../logic/services/api-factory';
import FLP_VALUE from '../../logic/enums/flp-value';

import './FieldPermissions.scss';

const labels = {
  editBtn: gettext('Edit'),
  header: gettext('Client details'),
  saveBtn: gettext('Save'),
  field: gettext('Field'),
  viewAndEdit: gettext('View and edit'),
  view: gettext('View'),
  hide: gettext('Hide'),
  successMessage: gettext('Field permissions saved'),
  errorMessage: gettext('Failed to save field permissions'),
};

const propTypes = {
  team: object.isRequired,
  DI: object,
  updateFLPEnabled: bool,
};

const defaultProps = {
  DI: null,
  updateFLPEnabled: true,
};

class FieldPermissions extends React.Component {
  constructor(props) {
    super(props);

    const { team } = props;
    this.flpApi = getDjangoApi(`team/${team.id}/settings/flp/user_details`);

    this.state = {
      fields: [],
      isEdit: false,
      accordionOpen: true,
      inProgress: false,
    };

    this.activate();
  }

  onRadioClick = (item, value) => () => {
    const { fields } = this.state;
    fields.slice();
    const editedItem = _.find(fields, { key: item.key });
    if (editedItem) {
      editedItem.model = value;
      this.setState({ fields });
    }
  }

  onEdit = () => {
    this.setState({ isEdit: true, accordionOpen: true });
  }

  onSave = () => {
    const { team: { id } } = this.props;
    const request = {
      team: id,
    };

    this.setState({ inProgress: true });
    const { fields } = this.state;
    fields.forEach((field) => { request[field.key] = field.model; });
    this.flpApi.update(this.flpApi.list.data.id, request)
      .then(() => {
        this.notify().success(labels.successMessage);
      }, () => {
        this.notify().error(labels.errorMessage);
      })
      .finally(() => {
        this.setState({
          isEdit: false,
          inProgress: false,
        });
        this.activate();
      });
  }

  setupModel = () => {
    if (this.flpApi.options.data && this.flpApi.list.data) {
      const fieldsRaw = _.get(this.flpApi.options.data, 'actions.GET.fields');

      if (fieldsRaw && fieldsRaw.length) {
        const fields = [];
        fieldsRaw.forEach((field) => {
          if (!_.includes(['id', 'team', 'created_at', 'updated_at', 'created_by'], field.key)) {
            fields.push({ ...field, model: this.flpApi.list.data[field.key], id: field.key });
          }
        });

        this.setState({ fields });
      }
    }
  }

  notify = () => {
    const { DI } = this.props;
    return DI ? DI.Notify : notifier;
  }

  activate = () => {
    this.flpApi.options.data = null;
    this.flpApi.list.data = null;
    this.flpApi.options().then(this.setupModel);
    this.flpApi.list().then(this.setupModel);
  }

  toggleAccordion = () => {
    this.setState(state => ({ accordionOpen: !state.accordionOpen }));
  }

  render() {
    const { accordionOpen, isEdit, inProgress, fields } = this.state;
    const { updateFLPEnabled } = this.props;
    return (
      <div className="field-permissions">
        <Accordion
          title=""
          visible={accordionOpen}
          onClick={this.toggleAccordion}
          headerTemplate={(
            <AccordionHeader
              title={labels.header}
              buttonProps={{
                visible: updateFLPEnabled,
                onClick: isEdit ? this.onSave : this.onEdit,
                disabled: inProgress,
                label: isEdit ? labels.saveBtn : labels.editBtn,
              }}
            />
          )}
        >
          <Table
            striped
            cols={[
              { 
                title: labels.field, 
                key: 'field', 
                render: data => data.label,
              },
              ...([
                {
                  title: labels.viewAndEdit, 
                  key: 'viewAndEdit',
                  type: FLP_VALUE.CAN_WRITE,
                }, {
                  title: labels.view, 
                  key: 'view',
                  type: FLP_VALUE.CAN_READ,
                }, {
                  title: labels.hide,
                  key: 'hide',
                  type: FLP_VALUE.HIDDEN,
                },
              ].map(field => ({
                ...field,
                render: data => _.find(data.choices, { value: field.type }) && ( 
                  isEdit ? <TableRadio onChange={this.onRadioClick} item={data} type={field.type} disabled={inProgress} /> : <TableCheckmark item={data} type={field.type} /> 
                ),
              }))),
            ]}
            data={fields}
          />
        </Accordion>
      </div>
    );
  }
}


const TableRadio = ({ item, type, onChange, disabled }) => (
  <Radio
    name={item.key}
    type="radio"
    value={type}
    disabled={disabled}
    checked={item.model === type}
    onChange={onChange(item, type)}
  />
);

TableRadio.propTypes = {
  item: object.isRequired,
  type: number.isRequired,
  onChange: func.isRequired,
  disabled: bool.isRequired,
};

// eslint-disable-next-line react/prop-types
const TableCheckmark = ({ item, type }) => item.model === type && <div className="field-permissions__checkmark"><Icon colorName="text-primary-500" name="Checkmark" /></div>;

TableCheckmark.propTypes = {
  item: object.isRequired,
  type: string.isRequired,
};

const AccordionHeader = ({ title, buttonProps }) => (
  <div className="header">
    <div className="accordion-arrow"><Icon name="CaretRight" size="extraSmall" /></div>
    <div className="title">{title}</div>
    <div className="field-permissions__button">
      {buttonProps.visible && <Button {...buttonProps} size="small">{buttonProps.label}</Button>}
    </div>
  </div>
);

AccordionHeader.propTypes = {
  title: string.isRequired,
  buttonProps: object.isRequired,
};

FieldPermissions.propTypes = propTypes;
FieldPermissions.defaultProps = defaultProps;

export default FieldPermissions;
