import appRoutes from '../../../../../../../../components/App/Router/appRoutes';
import { hasAccess } from '../../../../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../../../../logic/filters/is-feature-enabled';
import AvaIntegration from './AvaIntegration';

export default {
  component: AvaIntegration,
  aclFn: () => isFeatureEnabled()('AVA_PARTNERS') && hasAccess('ava_partners.tags.list'),
  path: appRoutes.SETTINGS_INTEGRATIONS_AVA,
};
