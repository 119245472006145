import React from 'react';
import { MultiActions, withSidepanelManager, withComboxManager } from 'tc-biq-design-system';

import connect from '../../../../../../logic/connect';
import { withRouter } from '../../../../../../components/hoc/withRouter';
import ActionItem from './ActionItem';
import UserActions, { userActionTypes } from '../../UserActions';

import './HeaderActions.scss';

const mapActionsAttributes = actions => actions.map(({ icon, label, name, handler }) => ({
  icon,
  label,
  data: name,
  action: handler,
  iconColorName: 'text-primary-1000',
}));

const renderDropdownActions = (actions) => {
  const key = actions.reduce((acc, action) => acc + action.name, '');
  return (
    <div className="biq-user-actions__dropdown">
      <MultiActions key={key} actions={mapActionsAttributes(actions)} />
    </div>
  );
};

const renderTopActions = actions => actions.map(action => (
  <div key={action.name} className="biq-user-actions__action">
    <ActionItem
      icon={action.icon}
      label={action.label}
      onClick={action.handler}
      content={action.content}
    />
  </div>
));

const HeaderActions = props => (
  <div className="biq-user-actions">
    <UserActions type={userActionTypes.TOP} DI={props}>
      {renderTopActions}
    </UserActions>

    <UserActions type={userActionTypes.DROPDOWN} DI={props}>
      {renderDropdownActions}
    </UserActions>
  </div>
);

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  return {
    user: page.userDetail.user,
  };
};

export default withRouter(
  withComboxManager(
    withSidepanelManager(
      connect(mapStateToProps)(HeaderActions),
    ),
  ),
);
