/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { Sidepanel, Button, Space, notifier } from 'tc-biq-design-system';
import { object, array } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import overlayActions from '../../../../../components/overlay';

const propTypes = {
  sidepanel: object,
  tableActions: object.isRequired,
};

const defaultProps = { sidepanel: null };

const sidepanelBodyPropTypes = {
  data: array,
};

const sidepanelBodyDefaultProps = {
  data: [],
};

const text = {
  DELETE: gettext('Delete'),
  CANCEL: gettext('Cancel'),
  SUCCESS: gettext('Group(s) deleted successfully'),
  ERROR: gettext('Failed deleting group(s)'),
  SIDEPANEL_BODY_SINGLE: gettext('Are you sure you want to delete this group?'),
  SIDEPANEL_BODY_MULTIPLE: gettext('Are you sure you want to delete these groups?'),
  SIDEPANEL_TITLE_SINGLE: gettext('Delete client visibility group'),
  SIDEPANEL_TITLE_MULTIPLE: gettext('Delete client visibility groups'),
};

const SIDEPANEL_ID = 'DELETE_CLIENT_VISIBILITY_GROUP';

const customFooter = (execute, close, submitInProgress) => (
  <Fragment>
    <Button color="ghost" onClick={close}>
      {text.CANCEL}
    </Button>
    <Button
      disabled={submitInProgress}
      loading={submitInProgress}
      color="destructive"
      onClick={execute}
    >
      {text.DELETE}
    </Button>
  </Fragment>
);

const SidepanelBody = ({ data }) => (
  <div>
    <Space size={16} />
    {data.length < 2 ? text.SIDEPANEL_BODY_SINGLE : text.SIDEPANEL_BODY_MULTIPLE}
    <Space size={16} />
    <ul>
      {data.map(item => (
        <li key={item.id}>{item.id}, {item.name}</li>
      ))}
    </ul>
  </div>
);

class DeleteClientVisibilityGroupSidepanel extends Component {
  constructor(props) {
    super(props);
    this.actions = { ...props.actions, ...props.tableActions };
    this.execute = this.execute.bind(this);
    this.onClose = this.onClose.bind(this);
    this.api = getDjangoApi('settings/object_acl_group');

    this.state = { submitInProgress: false };
  }

  onClose(submitSuccess) {
    if (submitSuccess) this.actions.fetchTableData();
    this.actions.close(SIDEPANEL_ID);
  }

  execute() {
    const { sidepanel } = this.props;
    const { parameters: data } = sidepanel;
    // TODO: replace this API when https://tradecrowd.atlassian.net/browse/BIQS-3204 is resolved
    const deleteAPI = getDjangoApi(`settings/object_acl_group/${data[0].id}`);
    this.setState({ submitInProgress: true });

    this.actions.deleteRow(deleteAPI, data).then(() => {
      this.setState({ submitInProgress: false });
      notifier.success(text.SUCCESS);
      this.onClose(true);
    }, (error) => {
      this.setState({ submitInProgress: false });
      notifier.error(error.data.detail ? error.data.detail : text.ERROR);
    });
  }

  render() {
    const { sidepanel } = this.props;
    const { parameters: data, visible } = sidepanel;
    const title = data && data.length > 1 ? text.SIDEPANEL_TITLE_MULTIPLE : text.SIDEPANEL_TITLE_SINGLE;
    const { submitInProgress } = this.state;
    return (
      <Sidepanel
        title={title}
        visible={visible}
        onCloseIconClick={() => this.actions.close(SIDEPANEL_ID)}
        footerRender={() => customFooter(this.execute, this.onClose, submitInProgress)}
      >
        <SidepanelBody data={data} />
      </Sidepanel>
    );
  }
}

DeleteClientVisibilityGroupSidepanel.propTypes = propTypes;
DeleteClientVisibilityGroupSidepanel.defaultProps = defaultProps;
SidepanelBody.propTypes = sidepanelBodyPropTypes;
SidepanelBody.defaultProps = sidepanelBodyDefaultProps;

const mapStateToProps = ({ overlays }) => ({ sidepanel: overlays[SIDEPANEL_ID] });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ close: overlayActions.close }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(DeleteClientVisibilityGroupSidepanel));
