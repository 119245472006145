import { bindActionCreators, compose } from 'redux';

import TermsAndConditions, { tocConfig } from './TermsAndConditions';
import connect from '../../../../../logic/connect';
import overlayActions from '../../../../../components/overlay';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const toc = tocConfig();

const mapStateToProps = ({ pages }) => {
  const page = pages[toc.reducerKey];
  const table = page.tables[toc.tableKey];
  return {
    tableOptions: table.options,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
  dispatch,
});

const TosPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(TermsAndConditions); 

export default {
  component: TosPage,
  aclId: 'customer_policy.tos.list',
  path: appRoutes.SETTINGS_TOS,
};
