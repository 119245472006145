import React, { PureComponent } from 'react';
import { object, func } from 'prop-types';
import { notifier, Button } from 'tc-biq-design-system';

import Page from '../../../../../components/Page';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import withTable, { GridTab } from '../../../../../components/grid/withTable';
import modifiers from './modifiers';
import { BiqUserPreview } from '../../../../../components/common/sidepanels';
import to from '../../../../../logic/utilities/to';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import GridActionsFactory from '../../../../../components/grid/GridActionsFactory';
import CreateTocSidepanel, { SIDEPANEL_ID as CREATE_TOC_SIDEPANEL_ID } from './CreateTocSidepanel';
import { hasAccess } from '../../../../../logic/services/acl';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const text = {
  TITLE: gettext('Terms & Conditions'),
  CREATE_NEW: gettext('Create new'),
  SUCCESS: gettext('State has been successfully updated.'),
  ERROR_GENERAL: gettext('Something went wrong!'),
};

const bread = [{ label: text.TITLE, route: appRoutes.SETTINGS_TOS }];

export const tocConfig = () => ({
  reducerKey: 'TERMS_AND_CONDITIONS',
  apiUrl: 'customer_policy/tos',
  tableKey: 'TERMS_AND_CONDITIONS_TABLE',
});

export const getTableActions = () => {
  const config = tocConfig();
  const api = getDjangoApi(config.apiUrl);
  return GridActionsFactory(config.tableKey, api);
};

const headerActions = onCreateToc => () => (
  hasAccess('customer_policy.tos.create') && <Button onClick={onCreateToc}>{text.CREATE_NEW}</Button>
);

const propTypes = {
  tableOptions: object.isRequired,
  actions: object.isRequired,
  dispatch: func.isRequired,
};

const tosApi = id => getDjangoApi(`customer_policy/tos/${id}/set_active`);

class TermsAndConditions extends PureComponent {
  constructor(props) {
    super(props);

    const { openSidepanel } = props.actions;
    
    this.TocTable = withTable(GridTab, tocConfig)();
    this.tableActions = {
      onToggle: this.onActiveToggle,
      onBiqUserPreview: openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  onCreateToc = () => {
    const { actions } = this.props;
    actions.openSidepanel(CREATE_TOC_SIDEPANEL_ID);
  }

  onActiveToggle = async (toc, is_active) => {
    const [err] = await to(tosApi(toc.id).create({
      is_active,
    }));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    notifier.success(text.SUCCESS);
    this.updateTable();
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  getLanguage = (code) => {
    const { tableOptions } = this.props;
    const { fields } = tableOptions.actions.POST;
    const languages = _.find(fields, { key: 'language' });
  
    if (languages) {
      return _.find(languages.choices, { value: code }).display_name;
    }

    return code;
  }

  updateTable = () => {
    const { dispatch } = this.props;
    const actions = getTableActions();
    dispatch(actions.fetchTableData());
  }

  render() {
    const { TocTable } = this;
    return (
      <Page
        bread={bread}
        title={text.TITLE}
        headerActions={headerActions(this.onCreateToc)}
      >
        <TocTable modifier={modifiers(this.tableActions, this.getLanguage)} />
        <BiqUserPreview />
        <CreateTocSidepanel onSuccess={this.updateTable} />
      </Page>
    );
  }
}

TermsAndConditions.propTypes = propTypes;

export default TermsAndConditions;
