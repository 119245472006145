import React, { Component } from 'react';
import { object, oneOfType, array, bool, func, string } from 'prop-types';
import { notifier, Select } from 'tc-biq-design-system';

import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../logic/services/acl';


const text = {
  SALES_STATUS: gettext('Sales status'),
  SUCCESS_MESSAGE: gettext('Sales status successfully updated'),
  PERMISSION_MESSAGE: gettext('You don\'t have permission to delete status.'),
};

const propTypes = {
  actions: object.isRequired,
  fieldData: oneOfType([array, object]),
  errors: array,
  user: object.isRequired,
  submitSuccess: bool.isRequired,
  apiSales: object.isRequired,
  apiSalesSubmit: func.isRequired,
  loadOptionsRequest: func,
  submitRequest: func,
  options: array,
  trans: object,
  salesKey: string,
  hasDestroyAccess: bool,
  hasUpdateAccess: bool,
};

const defaultProps = {
  errors: null,
  fieldData: null,
  loadOptionsRequest: null,
  submitRequest: null,
  options: null,
  trans: {},
  salesKey: 'sales_status',
  hasDestroyAccess: null,
  hasUpdateAccess: null,
};

class SelectStatus extends Component {
  constructor(props) {
    super(props);

    this.actions = props.actions;
    this.text = this.getText();
    this.loadOptions = this.loadOptions.bind(this);
    this.onChange = this.onChange.bind(this);
    this.hasDestroyAccess = props.hasDestroyAccess !== null ? props.hasDestroyAccess : hasAccess('sales_status.destroy');
    this.hasUpdateAccess = props.hasUpdateAccess !== null ? props.hasUpdateAccess : hasAccess('sales_status.update');
  }

  componentDidMount() {
    this.loadStatus();
  }
  
  componentDidUpdate(prevProps) {
    const { salesKey, user } = this.props;

    if (prevProps.user[salesKey] !== user[salesKey]) {
      this.loadStatus();
    }

    this.updateUser();
  }

  componentWillUnmount() {
    this.actions.resetInput();
  }

  onChange(status) {
    this.actions.updateData(status);
    this.submit(status);
  }

  getText = () => {
    const { trans } = this.props;
    return { ...text, ...trans };
  }

  loadStatus() {
    const { user, salesKey } = this.props;
    this.loadOptions(user[salesKey])
      .then((res) => {
        const selectedStatusByExactMatch = _.find(res, status => status.name === user[salesKey]);
        this.actions.updateData(selectedStatusByExactMatch || res[0]);
      });
  }

  submit(status) {
    const { user, apiSalesSubmit, submitRequest } = this.props;
    const apiSubmit = apiSalesSubmit(user);
    const requestData = submitRequest ? submitRequest(status) : {
      sales_status: status && status.id,
      sales_substatus: null,
    };
    const hassPremissions = this.hasDestroyAccess || !!status;
    if (hassPremissions) {
      this.actions.submit(apiSubmit, requestData);
    } else {
      notifier.error(this.text.PERMISSION_MESSAGE);
    }
  }

  updateUser() {
    const { submitSuccess, user, fieldData, salesKey } = this.props;

    if (submitSuccess) {
      this.actions.updateUser({ ...user, [salesKey]: fieldData && fieldData.name });
      this.actions.updateSubStatusData && this.actions.updateSubStatusData(null);
      this.actions.resetSubmit();
      notifier.success(this.text.SUCCESS_MESSAGE);
    }
  }

  loadOptions(input = '') {
    const { apiSales, loadOptionsRequest } = this.props;
    const defaultRequest = { is_enabled: true, name: input };
    const request = loadOptionsRequest ? { ...defaultRequest, ...loadOptionsRequest(input) } : defaultRequest;
    return new Promise(resolve => apiSales
      .list(request)
      .then(res => resolve(res.data.results)),
    );
  }

  render() {
    const { fieldData, errors, options, salesKey } = this.props;
    const hasError = errors !== null;
    const helpText = hasError ? errors[0][salesKey][0] : '';
    const hasOptions = !_.isEmpty(options);
    return (
      <div title={(fieldData && fieldData.name) || ''}>
        <Select
          smallTitle
          label={this.text.SALES_STATUS}
          type="search"
          onChange={this.onChange}
          value={fieldData}
          hasError={hasError}
          helpText={helpText}
          async={!hasOptions}
          options={options}
          debounceInterval={500}
          loadOptions={this.loadOptions}
          valueKey="id"
          labelKey="name"
          disabled={!this.hasUpdateAccess}
        />
      </div>
    );
  }
}

SelectStatus.propTypes = propTypes;
SelectStatus.defaultProps = defaultProps;

export default SelectStatus;
