import isFeatureEnabled from '../filters/is-feature-enabled';
import TRANSACTION_TYPE from '../enums/transaction-type';
import PAYMENT_CATEGORY from '../enums/payment-category';
import PAYMENT_METHODS from '../enums/payment-methods';

function PaymentUtilityService() {
  return {
    getPaymentCategoriesForTransactionType,
    isMethodVisible,
    isGatewayVisible,
    isReasonVisible,
    isBankInfoVisible,
    isBonusVisible,
    isEmailVisible,
    isWithdrawReasonVisible,
  };
}

function getPaymentCategoriesForTransactionType(type) {
  if (type === TRANSACTION_TYPE.CREDIT) {
    return [
      PAYMENT_CATEGORY.DEPOSIT,
      PAYMENT_CATEGORY.CANCEL_WITHDRAWAL,
      PAYMENT_CATEGORY.BONUS,
      PAYMENT_CATEGORY.ADJUSTMENT,
      PAYMENT_CATEGORY.TRANSFER,
    ];
  } if (type === TRANSACTION_TYPE.DEBIT) {
    return [
      PAYMENT_CATEGORY.WITHDRAWAL,
      PAYMENT_CATEGORY.CANCELLED_BONUS,
      PAYMENT_CATEGORY.WITHDRAWAL_ADJUSTMENT,
      PAYMENT_CATEGORY.CANCELLED_DEPOSIT,
      PAYMENT_CATEGORY.TRANSFER,
    ];
  } 
  return Object.values(PAYMENT_CATEGORY);
}

function isMethodVisible(type, category) {
  if (type === TRANSACTION_TYPE.CREDIT) {
    return category === PAYMENT_CATEGORY.DEPOSIT || category === PAYMENT_CATEGORY.CANCEL_WITHDRAWAL;
  } if (type === TRANSACTION_TYPE.DEBIT) {
    return category === PAYMENT_CATEGORY.WITHDRAWAL || category === PAYMENT_CATEGORY.CANCELLED_DEPOSIT;
  } 
  return false;
}

function isGatewayVisible(type, category) {
  if (type === TRANSACTION_TYPE.CREDIT) {
    return category === PAYMENT_CATEGORY.DEPOSIT || category === PAYMENT_CATEGORY.CANCEL_WITHDRAWAL;
  } if (type === TRANSACTION_TYPE.DEBIT) {
    return (category === PAYMENT_CATEGORY.WITHDRAWAL || category === PAYMENT_CATEGORY.CANCELLED_DEPOSIT) && isFeatureEnabled()('WITHDRAWALS_PAYMENT_GATEWAY_ENABLED');
  } 
  return false;
}

function isReasonVisible(type, category) {
  if (type === TRANSACTION_TYPE.CREDIT) {
    return category === PAYMENT_CATEGORY.BONUS || category === PAYMENT_CATEGORY.ADJUSTMENT;
  } if (type === TRANSACTION_TYPE.DEBIT) {
    return category === PAYMENT_CATEGORY.CANCELLED_BONUS
           || category === PAYMENT_CATEGORY.WITHDRAWAL_ADJUSTMENT
           || category === PAYMENT_CATEGORY.CANCELLED_DEPOSIT;
  } 
  return false;
}

const methodArrayForVisibility = [
  PAYMENT_METHODS.BANK_TRANSFER,
  PAYMENT_METHODS.BANK_TRANSFER_WIDR,
  PAYMENT_METHODS.BANK_TRANSFER_WIBC,
];

function isBankInfoVisible(method) {
  return _.includes(methodArrayForVisibility, method);
}

function isBonusVisible(method) {
  return _.includes(methodArrayForVisibility, method);
}

function isEmailVisible(paymentMethod) {
  return paymentMethod === PAYMENT_METHODS.EMONEY;
}

function isWithdrawReasonVisible(type, category) {
  return type === TRANSACTION_TYPE.DEBIT && category === PAYMENT_CATEGORY.WITHDRAWAL;
}

export default PaymentUtilityService;
