import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import ApproveTo from './ApproveTo';
import userStateFilter from '../../../../../../../logic/filters/user-state';
import userActionsTypes from '../types';

const text = {
  LABEL: state => gettext('Approve to {{state}}', { state: userStateFilter(state) }),
};

const ACTION_ICON = 'Approve';

export default toState => ({
  name: 'approveTo',
  label: text.LABEL(toState),
  icon: ACTION_ICON,
  type: userActionsTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    if (isIb) return false;
    let hasStatePermission = [];
    if (toState !== USER_STATE.LIMITED) {
      hasStatePermission = [
        USER_STATE.PENDING,
        USER_STATE.LIMITED,
        USER_STATE.REVIEW,
      ].includes(state);
    } else {
      hasStatePermission = [
        USER_STATE.PENDING,
      ].includes(state);
    }
    return hasAccess('user.approve.create') 
      && hasStatePermission;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL(toState),
      visible: true,
      isIb,
      user,
      sidepanelManager,
      toState,
    };

    sidepanelManager.add(ApproveTo, sidepanelOptions);
  },
});
