import { withRouter } from 'react-router-dom';

import SalesStatusesAndSubstatuses from './SalesStatusesAndSubstatuses';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import { hasAccess } from '../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const SalesStatusesAndSubstatusesPage = withErrorBoundary(withRouter(SalesStatusesAndSubstatuses));

export default {
  component: SalesStatusesAndSubstatusesPage,
  aclFn: () => hasAccess('communication_flow.list') && isFeatureEnabled()('COMMUNICATION_FLOW'),
  path: appRoutes.SETTINGS_SALES_STATUSES_AND_SUBSTATUSES,
};
