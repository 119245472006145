import React, { Component } from 'react';
import { Space, Button, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { object, array } from 'prop-types';
import { withRouter } from 'react-router-dom';

import Page from '../../../../../../../../components/Page';
import FormPanel from '../../../../../../../../components/form/Components/FormPanel';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import FormFactory, { FormActionsFactory } from '../../../../../../../../components/form';
import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import connect from '../../../../../../../../logic/connect';
import to from '../../../../../../../../logic/utilities/to';
import appRoutes from '../../../../../../../../components/App/Router/appRoutes';
import getID from '../../../../../../../../logic/utilities/getID';

const text = {
  TITLE: {
    create: gettext('Create New Withdrawable Amount Rule'),
    edit: gettext('Edit New Withdrawable Amount Rule'),
    clone: gettext('Create New Withdrawable Amount Rule'),
  },
  DEPOSITS_SETTINGS: gettext('Deposits settings'),
  SUCCESS_CREATE: gettext('Successfully created'),
  SUCCESS_EDIT: gettext('Successfully edited'),
  SUCCESS_CLONE: gettext('Successfully cloned'),
  ERROR: gettext('error occurred during initialization'),
  CREATE: gettext('Create'),
  DISCARD: gettext('Discard'),
  REQUIRED: gettext('This field is required!'),
  EDIT: gettext('Edit'),
};

const { create, setFieldsErrors, update, setFieldValue } = FormActionsFactory('WITHDRAWABLE_AMOUNT_RULES');

const bread = type => [
  {
    label: text.DEPOSITS_SETTINGS,
    route: appRoutes.SETTINGS_DEPOSITS,
  }, {
    label: text.TITLE[type],
    route: '',
  },
];

const api = getDjangoApi('settings/withdrawalable_amount_rules');

const formConfig = {
  form: 'WITHDRAWABLE_AMOUNT_RULES',
  excludeFields: [],
  api,
};

const propTypes = {
  actions: object.isRequired,
  fields: array.isRequired,
  values: object.isRequired,
  match: object.isRequired,
  history: object.isRequired,
};

class UpdateWithdrawableAmount extends Component {
  constructor(props) {
    super(props);
    this.type = this.getType();
    this.id = props.match.params.id;
    this.state = {
      submitInProgress: false,
    };
  }

  componentDidUpdate(oldProps) {
    const { fields: oldFields } = oldProps;
    const { fields } = this.props;

    if (oldFields.length !== fields.length) {
      this.prefillData(this.id);
    }
  }

  getRequestFieldValue(key, field) {
    switch (key) {
      case 'business_group':
        return field.name;
      case 'country':
        return field.iso_code;
      case 'payout_percents':
        return field ? +field : undefined;
      case 'refund_percents':
        return field ? +field : undefined;
      default:
        return !!field && typeof field === 'object' ? field.id : field;
    }
  }

  getType = () => {
    const pathRoutes = getID();

    if (_.includes(pathRoutes, 'add')) {
      return pathRoutes[pathRoutes.length - 1] === 'add' ? 'create' : 'clone';
    } 
      
    return 'edit';
  }

  createRule = async () => {
    const { values, actions } = this.props;
    const { acquirer,
      name,
      payout_percents,
      refund_percents,
      method,
      country,
      business_group } = values;

    const payload = {
      ...(acquirer && { acquirer: acquirer.id }),
      ...(business_group && { business_group: business_group.name }),
      ...(country && { country: country.iso_code }),
      ...(method && { method: method.id }),
      name,
      payout_percents: payout_percents ? +payout_percents : undefined,
      refund_percents: refund_percents ? +refund_percents : undefined,
    };
    actions.setFieldsErrors({});
    this.setState({ submitInProgress: true });
    const [err, res] = await to(actions.create(api, () => payload));
    this.setState({ submitInProgress: false });

    if (res) {
      notifier.success(text.SUCCESS_CREATE);
      this.goToSettingsPage();
      return;
    }

    if (err && err.data.non_field_errors) {
      notifier.error(err.data.non_field_errors[0]);
      return;
    }

    const invalidPayout = typeof payout_percents !== 'string';
    const invalidRefund = typeof refund_percents !== 'string';

    actions.setFieldsErrors({
      payout_percents: invalidPayout ? [text.REQUIRED] : [],
      refund_percents: invalidRefund ? [text.REQUIRED] : [],
      ...(err ? err.data : {}),
    });
  };

  prefillData = async () => {
    const { actions } = this.props;

    if (!this.id) {
      return;
    }

    try {
      const res = await api.retrieve(this.id);
      const { data } = res;
      Object.keys(data).forEach((key) => {
        if (data[key]) actions.setFieldValue({ id: key, value: data[key] });
      });
    } catch (e) {
      notifier.error(text.ERROR);
    }
  }

  requestFieldFormatter = formState => _.reduce(formState, (values, field, key) => ({
    ...values,
    [key]: this.getRequestFieldValue(key, field),
  }), {})

  editRule = async () => {
    const { values, actions } = this.props;

    const {
      payout_percents,
      refund_percents } = values;

    this.setState({ submitInProgress: true });

    const [err, res] = await to(actions.update(api, this.id, this.requestFieldFormatter, true));

    this.setState({ submitInProgress: false });

    if (res) {
      notifier.success(text.SUCCESS_EDIT);
      this.goToSettingsPage();
      return;
    }

    if (err && err.data.non_field_errors) {
      notifier.error(err.data.non_field_errors[0]);
      return;
    }

    const invalidPayout = typeof payout_percents !== 'string';
    const invalidRefund = typeof refund_percents !== 'string'; 

    actions.setFieldsErrors({
      payout_percents: invalidPayout ? [text.REQUIRED] : null,
      refund_percents: invalidRefund ? [text.REQUIRED] : null,
      ...(err ? err.data : {}),
    });
  }

  goToSettingsPage = () => {
    const { history } = this.props;
    history.push(appRoutes.SETTINGS_DEPOSITS);
  }

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    const { FormComponent } = this;
      
    return (
      <FormComponent
        renderForm={fields => (
          <React.Fragment>
            { fields.name }
            { fields.method}
            { fields.acquirer}
            { fields.business_group}
            { fields.country}
            { fields.refund_percents}
            { fields.payout_percents}
          </React.Fragment>
        )}
      />
    );
  }

  render() {
    const { submitInProgress } = this.state;
    const isEdit = this.type === 'edit';
    return (
      <Page title={text.TITLE[this.type]} bread={bread(this.type)}>
        <Space size={24} />
        <FormPanel>
          <FormPanel.Section>
            { this.renderForm() }
          </FormPanel.Section>
          <FormPanel.Submit>
            <Button onClick={this.goToSettingsPage}>{ text.DISCARD }</Button>
            <Button loading={submitInProgress} disable={submitInProgress} onClick={isEdit ? this.editRule : this.createRule}>{ isEdit ? text.EDIT : text.CREATE }</Button>
          </FormPanel.Submit>
        </FormPanel>
      </Page>
    );
  }
}

const mapStateToProps = ({ forms }) => ({
  ...forms.WITHDRAWABLE_AMOUNT_RULES,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    create,
    setFieldsErrors,
    setFieldValue,
    update,
  }, dispatch),
});

UpdateWithdrawableAmount.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateWithdrawableAmount));
