import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionTypes from '../types';
import SendNote from './SendNote';

const text = {
  LABEL: gettext('Add Note'),
};

const ACTION_ICON = 'Notes';

export default {
  name: 'addNote',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.TOP,
  hasPermission(_state, _user, isIb) {
    return (!isIb && hasAccess('user.notes.create')) 
      || (isIb && hasAccess('ib.notes.create'));
  },
  handler: (_sidepanelManager, _dispatch, user, _isIb, comboxManager) => {
    const comboxOptions = {
      title: text.LABEL,
      id: 'send-note-combox',
      user,
    };

    comboxManager.add(SendNote, comboxOptions);
  },
};
