import React, { Component } from 'react';
import { CardList, notifier, withSidepanelManager } from 'tc-biq-design-system';
import { array, bool, object, string } from 'prop-types';

import { bindActionCreators } from 'redux';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../logic/enums/user-state';
import IB_USER_STATE from '../../../../../../logic/enums/ib-state';
import userStateFilter from '../../../../../../logic/filters/user-state';
import IbUserStateFilter from '../../../../../../logic/filters/ib-state';
import getDisplayModifier from './modifiers';
import EditDetailsForm from './EditDetailsForm';
import { hasAccess } from '../../../../../../logic/services/acl';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import connect from '../../../../../../logic/connect';
import { fetchLossLimitReached, updateUser } from '../../Model';
import to from '../../../../../../logic/utilities/to';


const propTypes = {
  title: string.isRequired,
  sidepanelManager: object.isRequired,
  user: object.isRequired,
  isIb: bool.isRequired,
  actions: object.isRequired,
  fields: array.isRequired,
  flp: array,
  labels: object,
};

const defaultProps = {
  labels: {},
  flp: [],
};

const text = {
  EDIT_USER: gettext('Edit user'),
  LOSS_LIMIT_REACHED_REFRESHED: gettext('Successfully refreshed.'),
  CALL_INITIALIZED: gettext('Call successfully initiated. Please wait.'),
  CALL_FAILED: gettext('Failed to initiate call'),
};

class UserInfoCard extends Component {
  constructor(props) {
    super(props);
    this.isFlpEnabled = isFeatureEnabled()('FLP');
    this.state = {
      phoneData: {},
      isPhoneVisible: false,
    };

    this.listItemActions = {
      onLossLimitReachedRefresh: hasAccess('user_loss_limit.retrieve') && this.onLossLimitReachedRefresh.bind(this),
      toggleVisibility: async () => {
        const { data: phoneData } = await this.getPhoneNumbers();
        this.setState(prevState => ({ isPhoneVisible: !prevState.isPhoneVisible, phoneData }));
      },
      onPhoneCall: hasAccess('user.call.*') && this.onPhoneCall.bind(this),
    };
  }

  onClick = () => {
    const { sidepanelManager, user, title, isIb } = this.props;
    const fields = this.getEditableFields();
    const sidepanelOptions = {
      user,
      isIb,
      fields,
      sidepanelManager,
      visible: true,
      icon: 'Edit',
      type: 'info',
      title: text.EDIT_USER,
      key: title,
    };
    sidepanelManager.add(EditDetailsForm, sidepanelOptions);
  }

  getPhoneNumbers = async () => {
    const { user } = this.props;
    const phoneNumberApi = getDjangoApi(`users/${user.id}/phone_numbers`);
    return phoneNumberApi.list();
  };

  onLossLimitReachedRefresh = async () => {
    const { user, actions } = this.props;

    const [err, data] = await to(actions.fetchLossLimitReached({ id: user.id }));
    if (err) {
      notifier.error(err);
    } else {
      notifier.success(text.LOSS_LIMIT_REACHED_REFRESHED);
      actions.updateUser(data.value);
    }
  };

  onPhoneCall = async (phone) => {
    const { user } = this.props;
    const api = getDjangoApi(`users/${user.id}/call`);
    api.create({ phone })
      .then(() => {
        notifier.success(text.CALL_INITIALIZED);
      }, () => {
        notifier.error(text.CALL_FAILED);
      });
  }

  getEditableFields = () => {
    const { flp } = this.props;
    const visibleFields = this.getVisibleFields();
    return visibleFields.filter(key => (this.isFlpEnabled ? flp.includes(key) : true));
  }

  getVisibleFields = () => {
    const { fields, user, isIb } = this.props;
    return fields.filter(key => getDisplayModifier(key).isVisible(user, isIb));
  }

  displayItems = () => {
    const { user, labels } = this.props;
    const { phoneData, isPhoneVisible } = this.state;

    const visibleFields = this.getVisibleFields();
    return visibleFields.map((key) => {
      const modifier = getDisplayModifier(key);

      const userData = this.transformPhoneNumber(phoneData, user, key);
      const value = _.get(userData, key);

      return {
        name: modifier.displayName(key, labels[key]),
        value: modifier.displayValue(value, { ...this.listItemActions, isPhoneVisible, field: key }),
      };
    });
  }

  hasPermisions = () => {
    const { user, isIb } = this.props;
    const params = [user.state, true];
    const state = isIb ? IbUserStateFilter()(...params) : userStateFilter(...params);

    const hasStatePermission = _.includes([
      USER_STATE.FULL,
      USER_STATE.PENDING,
      USER_STATE.LIMITED,
      USER_STATE.REVIEW,
      USER_STATE.LEAD,
      USER_STATE.PENDING_EMAIL_VERIFICATION,
      USER_STATE.PENDING_SMS_VERIFICATION,
      USER_STATE.DEMO,
    ], state);

    return (!isIb && hasAccess('user.update') && hasStatePermission)
    || (isIb && !user.is_archived && hasAccess('ib.update') && state !== IB_USER_STATE.INCOMPLETE);
  }

  transformPhoneNumber(phoneData, user, key) {
    if (_.has(phoneData, key)) {
      user[key] = phoneData[key];
    }
    return user;
  }

  render() {
    const { title } = this.props;
    const items = this.displayItems();
    const icon = this.hasPermisions() ? 'Edit' : null;
    return (
      <CardList
        title={title}
        items={items}
        onClick={this.onClick}
        iconName={icon}
      />
    );
  }
}

UserInfoCard.propTypes = propTypes;
UserInfoCard.defaultProps = defaultProps;

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  return {
    flp: page.userDetail.flp,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchLossLimitReached,
    updateUser,
  }, dispatch),
});

export default withSidepanelManager(
  connect(mapStateToProps, mapDispatchToProps)(UserInfoCard),
);
