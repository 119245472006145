/* eslint import/no-cycle: 0 */

export const overlays = [
  'DELETE_EMAIL_EVENT',
  'EXECUTE_WITHDRAWAL',
  'REJECT_WITHDRAWAL',
  'REJECT_REASONS_FORM_MODAL',
  'IB_EXECUTE_WITHDRAWAL',
  'IB_REJECT_WITHDRAWAL',
  'UPLOAD_BULK_PAYMENTS',
  'EDIT_SALES_STATUSES',
  'EDIT_COMPLIANCE_STATUSES',
  'ASSIGN_SALES_MANAGER',
  'ASSIGN_CAGE',
  'ASSIGN_RETENTION_MANAGER',
  'EDIT_TAGS',
  'ARCHIVE_CLIENTS',
  'DELETE_SMS_EVENT',
  'DELETE_SMS_TEMPLATE',
  'DELETE_EMAIL_TEMPLATE',
  'KYC_BLACKLIST_IMPORT_CSV',
  'KYC_BLACKLIST_SIDEPANEL',
  'DELETE_CLIENT_VISIBILITY_GROUP',
  'ADD_EDIT_SALES_STATUS',
  'ADD_EDIT_EXCHANGE_RATE',
  'ADD_EDIT_BLACKLIST_REASON',
  'DELETE_SMS_TEMPLATE',
  'ADD_EDIT_REASON',
  'ADD_EDIT_BONUS_REASON',
  'ADD_EDIT_COMMUNICATION_STATUS',
  'ADD_EDIT_GATEWAY',
  'ADD_EDIT_TRANSACTION_REASON',
  'ADD_NEW_MEMBER',
  'VIEW_TASK',
  'CREATE_EDIT_TASK',
  'FILTER_VIEW_FORM',
  'ADD_EDIT_TEAM',
  'USER_PREVIEW',
  'BIQ_USER_PREVIEW',
  'TRADE_SINGLE',
  'TRADE_POSITION',
  'TRADING_ACCOUNT',
  'WITHDRAWAL_REQUEST',
  'TRANSACTION_SINGLE',
  'MANAGE_GRID_COLUMNS',
  'SELECT_DASHBOARD_WIDGET',
  'PREVIEW_DOCUMENT',
  'UPLOAD_DOCUMENT',
  'INTEGRATION_FORM',
  'AVA_INTEGRATION_FORM',
  'AUDIT_SINGLE',
  'ADD_COMMENT',
  'SETTINGS_EMAILS_SIDEPANEL',
  'EDIT_LAYOUT_SECTION_NAME_SIDEPANEL',
  'DUPLICATE_PROFILE_LAYOUT_SIDEPANEL',
  'DASHBOARD_LAYOUT_SIDEPANEL',
  'CREATE_EDIT_DOCUMENT',
  'CREATE_TOC_SIDEPANEL',
  'EDIT_LOGS_RECORD_FORM',
  'ADD_LEAD_MODAL',
  'ADD_LEAD_BULK_MODAL',
  'WALLET_TRANSACTION_COMMUNICATION',
  'PAYMENT_METHOD_FORM',
  'PAYMENT_MAPPING_FORM',
  'CONFIRM_PORTAL_SIDEPANEL',
  'CONFIRM_REMOVE_LINK_SIDEPANEL',
  'CONFIRM_DELETE_BANK_SIDEPANEL',
  'ADD_METHOD_FORM',
  'GATEWAY_BANK_FORM',
  'MOVE_TO_TEAM_SIDEPANEL',
  'DELETE_TEAM_MEMBER_SIDEPANEL',
  'SMS_TEMPLATE_DATA_TAGS',
  'NAMES_FORM_SIDEPANEL',
  'LINKS_FORM_SIDEPANEL',
  'TRADING_ACCOUNT_TYPE_FORM_SIDEPANEL',
  'EDIT_COUNTRY_RISK',
  'EDIT_TOTAL_DEPOSIT',
  'EDIT_TOTAL_RISK_DEPOSIT',
  'EDIT_CORPORATE_RISK',
  'EDIT_ONFIDO_RISK',
  'EDIT_RISK_GROUP_PER_GATEWAY',
  'EMAIL_ALERTS_FORM_SIDEPANEL',
  'USER_STATE_GBG_FORM_SIDEPANEL',
  'USER_STATE_ONFIDO_FORM_SIDEPANEL',
  'CAMPAIGN_FORM_SIDEPANEL',
  'TRACKING_LINKS_FORM_SIDEPANEL',
  'COMMISSION_SIDEPANEL',
  'CAMPAIGN_FORM_SIDEPANEL',
  'TRACKING_LINKS_FORM_SIDEPANEL',
  'COMMUNICATION_FLOW_SIDEPANEL',
  'COMPLIENCE_STATUS_SIDEPANEL',
  'PREVIEW_EMAIL_TEMPLATE',
  'EDIT_SIGNUP_DATA_FORM_SIDEPANEL',
  'WHITELIST_FORM_SIDEPANEL',
  'TRADING_GROUPS_FORM_SIDEPANEL',
  'CONFIRM_ACTION_MODAL',
  'MASS_ASSIGNMENT',
];

export { default } from './actions';
