import React, { Component, Fragment } from 'react';
import { notifier, ComboxFooter, Space } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { func, object, bool, array } from 'prop-types';

import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import to from '../../../../../../../logic/utilities/to';
import { getLanguages, getEmailTemplates, getTemplateContent } from './Model';
import ComboxWrapper from '../../../../../../../components/common/ComboxWrapper';


const FORM_KEY = 'SEND_EMAIL_FORM';

const { create, setFields, setFieldValue, showLoader } = FormActionsFactory(FORM_KEY);

const text = {
  TEXT: gettext('Text'),
  SEND: gettext('Send'),
  SUBJECT: gettext('Subject'),
  BODY: gettext('Body'),
  SUCCESS: gettext('Marketing email sent.'),
  ERROR_GENERAL: gettext('Something went wrong!'),
};

const propTypes = {
  closeCombox: func.isRequired,
  submitInProgress: bool.isRequired,
  user: object.isRequired,
  actions: object.isRequired,
  languages: array.isRequired,
  templates: array.isRequired,
  template: array.isRequired,
  formValues: object.isRequired,
};

class SendEmail extends Component {
  constructor(props) {
    super(props);
    this.api = user => getDjangoApi(`users/${user.id}/marketing_emails`);
  }

  componentDidMount() {
    this.fetchOptions();
  }

  componentDidUpdate(prevProps) {
    this.fetchTemplateContent(prevProps);
  }

  onSubmit = async () => {
    const { user, actions } = this.props;
    const [err] = await to(actions.create(this.api(user), this.getRequestPayload));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    const { closeCombox } = this.props;
    notifier.success(text.SUCCESS);
    closeCombox();
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  getRequestPayload = ({ template, language }) => ({
    template: template.value,
    language: language.value,
  });

  getContent = () => {
    const { formValues, template } = this.props;
    const languageId = _.get(formValues, 'language.value');
    const templateContent = template.find(content => content.language === languageId);
   
    return templateContent || {};
  }

  setField = (id, value) => {
    const { actions } = this.props;
    actions.setFieldValue({
      id,
      value,      
    });
  }

  fetchTemplateContent = (prevProps) => {
    const { formValues, actions } = this.props;
    const prevTemplateId = _.get(prevProps, 'formValues.template.value');
    const templateId = _.get(formValues, 'template.value');

    if (prevTemplateId !== templateId && templateId) {
      actions.getTemplateContent(templateId);
    }
  }

  fetchOptions = () => {
    const { actions } = this.props;
    const selectField = (name, options) => ({
      type: 'select',
      id: name,
      name,
      label: text.SELECT,
      options,
      joinValues: true,
      clearable: false,
      openingDirection: 'down',
      valueKey: 'value',
      labelKey: 'display_name',
    });

    actions.showLoader(true);
    Promise.all([
      actions.getEmailTemplates(),
      actions.getLanguages(),
    ]).then(() => {
      const { languages, templates } = this.props;

      actions.setFields([
        selectField('template', templates),
        selectField('language', languages),
      ]);

      this.setField('template', templates[0]);
      this.setField('language', languages[0]);

      actions.showLoader(false);
    });
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory({
        form: FORM_KEY,
      });
    }
    const { FormComponent } = this;
    return <FormComponent formId={FORM_KEY} />;
  }

  render() {
    const { submitInProgress } = this.props;
    const { content, subject } = this.getContent();
    return (
      <ComboxWrapper>
        {this.renderForm()}
        {!!subject && (
          <Fragment>
            <span className="text-neutral-900 tc-paragraph-regular">{text.SUBJECT}</span>
            <div className="text-neutral-900 tc-paragraph-regular">{subject}</div>
            <Space size={12} />
          </Fragment>
        )}
        {!!content && (
          <Fragment>
            <span className="text-neutral-900 tc-paragraph-regular">{text.BODY}</span>
            <iframe 
              title="template" 
              srcDoc={content} 
              height="500px;" 
              width="100%" 
              frameBorder="0" 
            />
          </Fragment>
        )}
        <ComboxFooter 
          onConfirm={this.onSubmit}
          isLoading={submitInProgress}
          confirmText={text.SEND} 
          formId={FORM_KEY}
        />
      </ComboxWrapper>
    );
  }
}

SendEmail.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      create,
      setFields,
      setFieldValue,
      showLoader,
      getEmailTemplates,
      getLanguages,
      getTemplateContent,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages, forms }) => {
  const form = forms[FORM_KEY];
  const { templates, languages, template } = pages.USER_SINGLE.actions.SEND_EMAIL;
  return {
    submitInProgress: form.submitInProgress,
    formValues: form.values,
    templates,
    languages,
    template,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendEmail);
