import React, { Component } from 'react';
import { Checkbox } from 'tc-biq-design-system';
import { string, func } from 'prop-types';
import classnames from 'classnames';

import './HeaderCheckbox.scss';

export const statuses = {
  CHECKED: 'checked',
  UNCHECKED: 'unchecked',
  INTERMEDIATE: 'intermediate',
};

const propTypes = {
  status: string.isRequired,
  onChange: func.isRequired,
};

class HeaderCheckbox extends Component {
  onChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { onChange, status } = this.props;
    const newStatus = status === statuses.CHECKED ? statuses.UNCHECKED : statuses.CHECKED;
    onChange(newStatus);
  }

  render() {
    const { status } = this.props;
    const checked = status === statuses.CHECKED;
    return (
      <div onClick={this.onChange} className={classnames('header-checkbox', { 'header-checkbox--intermediate': status === statuses.INTERMEDIATE })}>
        <Checkbox checked={checked} />
      </div>
    );
  }
}

HeaderCheckbox.propTypes = propTypes;

export default HeaderCheckbox;
