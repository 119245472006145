import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const actionTypes = {
  ACTIVATE: 'ACTIVATE_MIFID',
  ACTIVATE_PENDING: 'ACTIVATE_MIFID_PENDING',
  ACTIVATE_FULFILLED: 'ACTIVATE_MIFID_FULFILLED',
};

export const activate = createAction(
  actionTypes.ACTIVATE, 
  id => getDjangoApi(`users/${id}/nic`).list(),
);

const initialState = {
  data: [],
  fetchInProgress: false,
  errors: null,
};

const fetchFulfilled = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  data: payload.data.results,
});

export default handleActions(
  {
    [actionTypes.ACTIVATE_PENDING]: state => ({ ...state, fetchInProgress: true }),
    [actionTypes.ACTIVATE_REJECTED]: state => ({ ...state, fetchInProgress: false }),
    [actionTypes.ACTIVATE_FULFILLED]: fetchFulfilled,
  },
  initialState,
);
