import React from 'react';
import { object, bool, func } from 'prop-types';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import AssignManager from '../../../../AssignManager';

const ENV = window.config.environment;

const MANAGER_PROPERTY = ENV === 'AvaTrade' ? 'sales_manager' : 'assigned_to';

const propTypes = {
  user: object.isRequired,
  isIb: bool,
  onAssign: func.isRequired,
};

const defaultProps = {
  isIb: false,
};

const text = {
  SALES_MANGER: gettext('Sales Manager'),
  GROUP_USERS: gettext('Users'),
  GROUP_TEAMS: gettext('Teams'),
  GROUP_DEFAULT: gettext('Default'),
  NONE: gettext('None'),
  SUCCESS_MESSAGE: gettext('Sales manager successfully updated'),
};

const SalesManagerController = ({ onAssign, ...rest }) => (
  <AssignManager
    {...rest} 
    text={text} 
    managerProperty={MANAGER_PROPERTY} 
    onSuccess={onAssign} 
  />
);

SalesManagerController.propTypes = propTypes;
SalesManagerController.defaultProps = defaultProps;

export default SalesManagerController;
