/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import connect from '../../../../logic/connect';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import FormFactory, { FormActionsFactory } from '../../../../components/form';
import overlayActions from '../../../../components/overlay';
import { gettext } from '../../../../logic/utilities/languageUtility';
import to from '../../../../logic/utilities/to';
import { SidepanelFooter } from '../../../../components/common';
import { formErrorHandler } from '../../../../components/form/logic/utils';

const SIDEPANEL_ID = 'ADD_EDIT_TEAM';
const api = getDjangoApi('teams');
const { create, update, resetFields, setRowData } = FormActionsFactory(SIDEPANEL_ID);

const types = {
  ADD: 'add',
  EDIT: 'edit',
  CLONE: 'clone',
};

const text = {
  CREATE_TITLE: gettext('Create a team'),
  EDIT_TITLE: gettext('Update Marketing team'),
  CLONE_TITLE: gettext('Clone Marketing team'),
  EDIT_BUTTON_LABEL: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
  CREATE_BUTTON_LABEL: {
    confirm: gettext('Create a team'),
    cancel: gettext('Discard'),
  },
  CLONE_BUTTON_LABEL: {
    confirm: gettext('Create a team'),
    cancel: gettext('Discard'),
  },
  SUBMIT_SUCCESS: gettext('Team saved successfully'),
  SUBMIT_ERROR: gettext('Error while saving team'),
  COPY_PERMISSION_LABEL: gettext('Copy permission from'),
  COPY_PERMISSION_PLACEHOLDER: gettext('Select team...'),
};

const customFooter = (execute, close, submitInProgress, type) => (
  <SidepanelFooter 
    execute={execute} 
    close={close} 
    submitInProgress={submitInProgress} 
    confirmColor="primary"
    buttonLabels={getTitlesOrLabels(type)}
    formId={SIDEPANEL_ID}
  />
);

const getTitlesOrLabels = (type, getTitle) => {
  switch (type) {
    case types.ADD:
      return getTitle ? text.CREATE_TITLE : text.CREATE_BUTTON_LABEL;
    case types.CLONE: 
      return getTitle ? text.CLONE_TITLE : text.CLONE_BUTTON_LABEL;
    default:
      return getTitle ? text.EDIT_TITLE : text.EDIT_BUTTON_LABEL;
  }
};

const formModifier = {
  acls_from: {
    label: text.COPY_PERMISSION_LABEL,
    placeholder: text.COPY_PERMISSION_PLACEHOLDER,
  },
};

const formConfig = {
  form: SIDEPANEL_ID,
  api,
  excludeFields: ['sales', 'retention', 'view_acls', 'members'],
};

class AddEditTeam extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.close = this.close.bind(this);
    this.getRequestPayload = this.getRequestPayload.bind(this);
  }

  componentDidUpdate() {
    const { sidepanel, actions } = this.props;
    const { parameters, visible } = sidepanel;

    if (visible && (parameters.type === types.EDIT || parameters.type === types.CLONE)) {
      actions.setRowData(parameters.data);
    }
  }

  componentWillUnmount() {
    this.close();
  }

  onSuccess() {
    const { onSuccessRequest } = this.props;
    onSuccessRequest();
    this.close();
    notifier.success(text.SUBMIT_SUCCESS);
  }

  getRequestPayload = data => (values) => {
    const { sidepanel } = this.props;
    const { parameters } = sidepanel;
    let view_acls = [];

    const request = _.reduce(values, (payload, field, key) => ({
      ...payload, [key]: typeof field === 'object' ? field.value : field,
    }), {});
    
    if (parameters.type === types.CLONE) {
      const { view_acls: acls } = data;
      view_acls = acls;
    }
    
    return {
      ...request,
      members: [],
      view_acls,
      id: parameters.type === types.EDIT ? parameters.data.id : null,
    };
  }

  async submit() {
    const { actions, sidepanel } = this.props;
    const { parameters } = sidepanel;
    const isEdit = parameters.type === types.EDIT;
    const { data } = parameters.type === types.CLONE ? await api.retrieve(parameters.data.id) : {};
    
    const request = isEdit 
      ? actions.update(api, parameters.data.id, this.getRequestPayload()) 
      : actions.create(api, this.getRequestPayload(data));

    const [err, res] = await to(request);
    if (err) this.showError(err);
    if (res) this.onSuccess();
  }

  showError(err) {
    formErrorHandler(text.SUBMIT_ERROR)(err);
  }

  close() {
    const { actions } = this.props;
    actions.closeOverlay(SIDEPANEL_ID);
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { sidepanel, submitInProgress } = this.props;
    const { parameters, visible } = sidepanel;
    const TITLE = visible && getTitlesOrLabels(parameters.type, true);

    const Form = this.renderForm();

    return (
      <Sidepanel
        visible={visible}
        title={TITLE}
        icon="Teams"
        onCloseIconClick={() => this.close()}
        footerRender={() => customFooter(this.submit, this.close, submitInProgress, parameters.type)}
      >
        <Form formId={SIDEPANEL_ID} modifiers={formModifier} />
      </Sidepanel>
    );
  }
}

const mapStateToProps = ({ overlays, forms }) => {
  const { fields, submitInProgress } = forms[SIDEPANEL_ID];

  return {
    sidepanel: overlays[SIDEPANEL_ID],
    form: fields,
    submitInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeOverlay: overlayActions.close,
      setRowData,
      create,
      update,
      resetFields,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEditTeam);
