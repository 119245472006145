import React, { Component } from 'react';
import { Button } from 'tc-biq-design-system';
import { func, object } from 'prop-types';

import connect from '../../logic/connect';
import { gettext } from '../../logic/utilities/languageUtility';
import FilterDropdown from './FilterDropdown';
import { setVisibleFilter, resetDropdownStore } from './FilterRedux';
import { fieldsType } from './FilterTypes';

const DROPDOWN_ID = 'add-new-filter-dropdown';
const text = {
  ADD_FILTER: gettext('Add filter'),
  LABEL: gettext('Add new filter'),
};

const propTypes = {
  resetDropdownStore: func.isRequired,
  setVisibleFilter: func.isRequired,
  fields: fieldsType.isRequired,
  selectedField: object,
};

const defaultProps = {
  selectedField: {},
};

class AddFilterButton extends Component {
  constructor(props) {
    super(props);
    this.openDropdown = this.openDropdown.bind(this);
  }

  openDropdown() {
    const { resetDropdownStore, setVisibleFilter } = this.props;
    resetDropdownStore();
    setVisibleFilter(DROPDOWN_ID);
  }

  render() {
    const { fields, selectedField } = this.props;
    return (
      <span id={DROPDOWN_ID}>
        <Button icon="Plus" color="transparent" onClick={this.openDropdown}>
          {text.ADD_FILTER}
        </Button>
        <FilterDropdown
          fields={fields}
          isNewFilter
          filterKey={DROPDOWN_ID}
          field={selectedField}
          label={text.LABEL}
        />
      </span>
    );
  }
}

AddFilterButton.propTypes = propTypes;
AddFilterButton.defaultProps = defaultProps;

const mapDispatchToProps = {
  setVisibleFilter,
  resetDropdownStore,
};

export default connect(
  null,
  mapDispatchToProps,
)(AddFilterButton);
