import React, { Component } from 'react';
import { object, bool } from 'prop-types';
import { Button, Space, notifier } from 'tc-biq-design-system';

import { LoginLogo } from '../../../../components/Nav/logos';
import FormPanel from '../../../../components/form/Components/FormPanel/FormPanel';
import { gettext } from '../../../../logic/utilities/languageUtility';
import FormFactory from '../../../../components/form';
import Page from '../../../../components/Page';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import to from '../../../../logic/utilities/to';
import appRoutes from '../../../../components/App/Router/appRoutes';

export const FORM_KEY = 'FORGOT_PASSWORD_FORM';

const api = getDjangoApi('forgot-password/request');

const text = {
  EMAIL: {
    LABEL: gettext('Email'),
    PLACEHOLDER: gettext('Email address of your account'),
  },
  SUBMIT: gettext('Reset password'),
  LOGIN: gettext('Go back to login'),
  TITLE: gettext('Reset password'),
  SUCCESS: gettext('Request is sent to your email address. Please check your inbox.'),
  ERROR_GENERAL: gettext('Something went wrong'),
};

const username = {
  type: 'text',
  id: 'username',
  name: 'username',
  placeholder: text.EMAIL.PLACEHOLDER,
  label: text.EMAIL.LABEL,
};

const propTypes = {
  submitInProgress: bool.isRequired,
  errors: object.isRequired,
  actions: object.isRequired,
  history: object.isRequired,
};

class ForgotPasswordForm extends Component {
  onSubmit = async () => {
    const { actions } = this.props;
    const [err] = await to(actions.create(api));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    notifier.success(text.SUCCESS);
  }

  onError(payload) {
    const { errors } = this.props;
    if (!_.isEmpty(errors) && !errors.non_field_errors) return;
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) {
      notifier.error(nonFieldErrors.map(err => <span>{err}<br /></span>));
    } else {
      notifier.error(text.ERROR_GENERAL);
    }
  }

  onGoBackToLoginClick = () => {
    const { history } = this.props;
    history.push(appRoutes.LOGIN);
  }

  formConfig = () => ({
    form: FORM_KEY,
    customFields: [
      username,
    ],
  })

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const Form = this.renderForm();
    const { submitInProgress } = this.props;
    return (
      <Page style={{}} hideHeader>
        <div className="auth-page">
          <Form renderForm={formFields => (
            <FormPanel size="small">
              <FormPanel.Section hideBorder>
                <p className="tc-heading-s auth-page__welcome">{text.TITLE}</p>
                <div className="auth-page__logo">
                  <LoginLogo />
                </div>
                <Space size={30} />
                {formFields.username}
                <Space size={20} />
                <Button loading={submitInProgress} onClick={this.onSubmit}>{text.SUBMIT}</Button>
                <Space size={20} />
                <Button 
                  loading={submitInProgress} 
                  onClick={this.onGoBackToLoginClick} 
                  color="transparent"
                >
                  {text.LOGIN}
                </Button>
              </FormPanel.Section>
            </FormPanel>
          )} 
          />
        </div>
      </Page>
    );
  }
}

ForgotPasswordForm.propTypes = propTypes;

export default ForgotPasswordForm;
