import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  ARCHIVE_USER: 'ARCHIVE_USER',
  ARCHIVE_USER_FULFILLED: 'ARCHIVE_USER_FULFILLED',
  ARCHIVE_USER_PENDING: 'ARCHIVE_USER_PENDING',
  ARCHIVE_USER_REJECTED: 'ARCHIVE_USER_REJECTED',
};

export const archiveUser = createAction(actionTypes.ARCHIVE_USER, async (user, isIb) => {
  const archiveApi = getDjangoApi(`${isIb ? 'ib' : 'users'}/${user.id}/archive`);
  
  await archiveApi.create({});
});


const initialState = {
  submitInProgress: false,
  errors: null,
};

const pendingHandler = state => ({ ...state, submitInProgress: true });

const fulfilledHandler = state => ({
  ...state,
  submitInProgress: false,
  error: null,
});

const rejectedHandler = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  errors: payload.data,
});

export default handleActions(
  {
    [actionTypes.ARCHIVE_USER_PENDING]: pendingHandler,
    [actionTypes.ARCHIVE_USER_FULFILLED]: fulfilledHandler,
    [actionTypes.ARCHIVE_USER_REJECTED]: rejectedHandler,
  },
  initialState,
);
