
import { bindActionCreators, compose } from 'redux';

import UserStateMapping from './UserStateMapping';
import connect from '../../../../logic/connect';
import overlayActions from '../../../../components/overlay';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import { hasAccess } from '../../../../logic/services/acl';
import appRoutes from '../../../../components/App/Router/appRoutes';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

const UserStateMappingPage = compose(
  connect(null, mapDispatchToProps),
  withErrorBoundary,
)(UserStateMapping); 

export default {
  component: UserStateMappingPage,
  aclFn: () => (
    hasAccess('kyc.gbg_to_user_state.list')
      || hasAccess('kyc.user_state_mapping.list')
  ),
  path: appRoutes.KYC_USER_STATE_MAPPING,
};
