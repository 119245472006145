import React, { PureComponent, Fragment } from 'react';
import { notifier, ComboxFooter } from 'tc-biq-design-system';
import { func, object, bool, string } from 'prop-types';
import { gettext } from '../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../logic/services/api-factory';
import to from '../../../logic/utilities/to';
import FormFactory from '../../form';

const text = {
  TEXT: gettext('Text'),
  SEND: gettext('Add'),
  LABEL: gettext('Notes'),
  SUCCESS: gettext('Note added'),
  ERROR_GENERAL: gettext('Something went wrong!'),
};

const propTypes = {
  submitInProgress: bool.isRequired,
  user: object.isRequired,
  formKey: string.isRequired,
  hideFooter: bool,
  showLabel: bool,
  getSubmit: func,
  onSuccess: func,
  createNote: func.isRequired,
  shouldResetValuesOnSuccess: bool,
  disableResetOnUnmount: bool,
};


const defaultProps = {
  hideFooter: false,
  showLabel: false,
  getSubmit: null,
  onSuccess: null,
  shouldResetValuesOnSuccess: true,
  disableResetOnUnmount: false,
};

class SendNoteForm extends PureComponent {
  constructor(props) {
    super(props);
    this.api = user => getDjangoApi(`users/${user.id}/notes`);
  }

  componentDidMount() {
    const { getSubmit } = this.props;
    if (getSubmit) {
      getSubmit(this.onSubmit);
    }
  }

  onSubmit = async () => {
    const { user, createNote, shouldResetValuesOnSuccess } = this.props;
    const [err] = await to(createNote(this.api(user), this.getRequestPayload, shouldResetValuesOnSuccess));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    const { onSuccess } = this.props;
    notifier.success(text.SUCCESS);
    onSuccess && onSuccess();
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  getRequestPayload = ({ content }) => ({ content })

  getFormConfig() {
    const { formKey, showLabel, disableResetOnUnmount } = this.props;
    return {
      form: formKey,
      disableResetOnUnmount,
      customFields: [
        {
          id: 'content',
          name: 'content',
          type: 'textarea',
          highlight: true,
          placeholder: text.TEXT,
          ...(showLabel && { label: text.LABEL }),
        },
      ],
    };
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.getFormConfig());
    }
    const { FormComponent } = this;
    return <FormComponent />;
  }

  render() {
    const { submitInProgress, hideFooter } = this.props;
    return (
      <Fragment>
        {this.renderForm()}
        {!hideFooter && (
          <ComboxFooter 
            onConfirm={this.onSubmit}
            isLoading={submitInProgress}
            confirmText={text.SEND} 
          />
        )}
      </Fragment>
    );
  }
}

SendNoteForm.propTypes = propTypes;
SendNoteForm.defaultProps = defaultProps;

export default SendNoteForm;
