import { combineReducers } from 'redux';
import GridReducerFactory from '../components/grid/GridReducerFactory';
import overlayReducer from '../components/overlay/reducer';
import FormReducerFactory from '../components/form/logic/reducerFactory';
import sessionReducer from './session/Model';
import bulkActionsReducerFactory from '../components/common/sidepanels/bulk/reducers';
import { reducer as addEditSalesStatus } from '../pages/home/sales-statuses/Sidepanel/AddEditStatus';
import { reducer as addEditExchangeRate } from '../pages/home/settings/exchange-rates/Sidepanels/AddEditExchangeRate';
import { reducer as addEditBlacklistReason } from '../pages/home/blacklist-reasons/Sidepanel/AddEditBlacklistReason';
import { reducer as addEditRejectReason } from '../pages/home/settings/withdrawals/Sidepanels/AddEditReason';
import {
  reducer as addEditCommunicationStatus,
} from '../pages/home/settings/communication-statuses/Sidepanel/AddEditCommunicationStatus';
import {
  reducer as addEditGateway,
} from '../pages/home/payment-transaction-types/Sidepanels/AddEditGateway/AddEditGateway';
import archiveClientsReducer from '../pages/home/clients/sidepanels/ClientsArchive/reducer';
import { filterReducers } from '../components/Filters/FilterRedux';
import activityStream from '../pages/home/activity-stream/reducers';
import userSingleReducers from '../pages/home/user-single/react/reducers';
import integrationsReducer from '../pages/home/settings/integrations/react/Integrations/reducers';
import withdrawalMethodRuleReducer
  from '../pages/home/settings/deposit-withdrawal-method-rule/WithdrawalMethodRule/Model';
import emailEventReducer from '../pages/home/notification-events/events-add-edit/EmailEvent/Model';
import smsEventReducer from '../pages/home/notification-events/events-add-edit/SmsEvent/Model';
import withdrawalFlowStepReducer from '../pages/home/settings/withdrawals/step-add-edit/WithdrawalFlowStep/Model';
import depositMethodRuleReducer from '../pages/home/settings/deposits/DepositMethodRule/Model';
import depositExpiryPeriodReducer from '../pages/home/settings/deposits/EditDepositExpiry/react/Model';
import teamClientVisibilityGroupsReducer from '../pages/home/client-visibility-groups/modify/TeamVisibilityGroup/Model';
import pendingDepositRuleReducer from '../pages/home/settings/deposits/PendingDepositRule/Model';
import auditSingleReducer from '../pages/home/logs/Audit/overlays/AuditSingle/Model';
import settingsEmailsReducer from '../pages/home/settings/email/Emails/reducer';
import { reducer as settingsGeneralReducer } from '../pages/home/settings/general/General/Model';
import documentsReducer from '../pages/home/settings/documents/Documents/Model';
import pendingWithdrawalReducer from '../pages/home/withdrawal-single/Model';
import withdrawalFlowReducer from '../pages/home/withdrawal-single/Components/WithdrawalFlow/Model';
import { reducer as logsMessageReducer } from '../pages/home/logs-message/LogsMessage/Model';
import clientProfileLayoutreducer from '../pages/home/settings/customization/ClientProfileLayout/Model';
import teamReducer from '../pages/home/team/Team/Model';
import teamMemberReducer from '../pages/home/team-profile/TeamProfile/Model';
import dashboardPageReducer from '../pages/home/settings/customization/DashboardLayout/DashboardPage/Model';
import teamsStructure from '../pages/home/teams/TeamsStructure/Model';
import RiskRatingReducer from '../pages/home/settings/risk-rating/react/Model';
import campaignReducer from '../pages/home/campaign-single/CampaignSingle/Model';
import communicationFlowReducer
  from '../pages/home/settings/sales-statuses-and-substatuses/SalesStatusesAndSubstatuses/Model';
import campaignFormReducer from '../pages/home/settings/bonus/react/CampaignForm/Model';
import cancellationRuleFormReducer from '../pages/home/settings/bonus/react/CancellationRules/Model';
import updateTemplateReducer from '../pages/home/notification-templates/UpdateTemplateModel';
import refundPayoutTable from '../pages/home/withdrawal-single/Components/ExecuteWithdrawal/logic/Model';
import kycExportReducer from '../pages/home/kyc/KYCExport/Model';

const reducers = {
  // App-wide reducers
  session: sessionReducer,
  // Page-specific reducers
  pages: combineReducers({
    ACTIVE_TRADERS: combineReducers({
      tables: combineReducers({
        ACTIVE_TRADERS_TABLE: GridReducerFactory('ACTIVE_TRADERS_TABLE'),
      }),
    }),
    ACTIVITY_STREAM: activityStream,
    ALL_POSITIONS: combineReducers({
      tables: combineReducers({
        ALL_POSITIONS_TABLE: GridReducerFactory('ALL_POSITIONS_TABLE'),
      }),
    }),
    AUDIT_LOGS: combineReducers({
      tables: combineReducers({
        AUDIT_LOGS_TABLE: GridReducerFactory('AUDIT_LOGS_TABLE'),
      }),
      auditSingle: auditSingleReducer,
    }),
    AUTOMATIC_FEES: combineReducers({
      tables: combineReducers({
        AUTOMATIC_FEES: GridReducerFactory('AUTOMATIC_FEES'),
      }),
    }),
    BLACKLIST_REASONS: combineReducers({
      actions: combineReducers({
        ADD_EDIT_BLACKLIST_REASON: addEditBlacklistReason,
      }),
      tables: combineReducers({
        BLACKLIST_REASONS_TABLE: GridReducerFactory('BLACKLIST_REASONS_TABLE'),
      }),
    }),
    BONUS: combineReducers({
      tables: combineReducers({
        CAMPAIGNS: GridReducerFactory('CAMPAIGNS'),
        REASONS: GridReducerFactory('REASONS'),
        CANCELLATION_RULES: GridReducerFactory('CANCELLATION_RULES'),
      }),
      campaign: campaignFormReducer,
      cancellationRule: cancellationRuleFormReducer,
    }),
    CAMPAIGNS: combineReducers({
      tables: combineReducers({
        CAMPAIGNS_TABLE: GridReducerFactory('CAMPAIGNS_TABLE'),
        TRACKING_LINKS_TABLE: GridReducerFactory('TRACKING_LINKS_TABLE'),
        SIGNUP_USERS_TABLE: GridReducerFactory('SIGNUP_USERS_TABLE'),
      }),
      campaign: campaignReducer,
    }),
    CLIENT_PROFILE_LAYOUT: clientProfileLayoutreducer,
    CLIENT_VISIBILITY_GROUPS: combineReducers({
      tables: combineReducers({
        CLIENT_VISIBILITY_GROUPS_TABLE: GridReducerFactory('CLIENT_VISIBILITY_GROUPS_TABLE'),
      }),
    }),
    CLIENTS: combineReducers({
      tables: combineReducers({
        CLIENTS_TABLE: GridReducerFactory('CLIENTS_TABLE'),
      }),
      bulkActions: combineReducers({
        EDIT_TAGS: bulkActionsReducerFactory('CLIENTS_EDIT_TAGS'),
        EDIT_SALES_STATUSES: bulkActionsReducerFactory('CLIENTS_EDIT_SALES_STATUSES'),
        EDIT_COMPLIANCE_STATUSES: bulkActionsReducerFactory('CLIENTS_EDIT_COMPLIANCE_STATUSES'),
        ASSIGN_SALES_MANAGER: bulkActionsReducerFactory('CLIENTS_ASSIGN_SALES_MANAGER'),
        ASSIGN_CAGE: bulkActionsReducerFactory('CLIENTS_ASSIGN_CAGE'),
        ASSIGN_RETENTION_MANAGER: bulkActionsReducerFactory('CLIENTS_ASSIGN_RETENTION_MANAGER'),
        ARCHIVE_CLIENTS: archiveClientsReducer,
      }),
    }),
    CLOSED_POSITIONS: combineReducers({
      tables: combineReducers({
        CLOSED_POSITIONS_TABLE: GridReducerFactory('CLOSED_POSITIONS_TABLE'),
      }),
    }),
    COMMUNICATION_FLOW: communicationFlowReducer,
    COMMUNICATION_HISTORY: combineReducers({
      tables: combineReducers({
        COMMUNICATION_HISTORY_TABLE: GridReducerFactory('COMMUNICATION_HISTORY_TABLE'),
      }),
    }),
    COMMUNICATION_STATUSES: combineReducers({
      actions: combineReducers({
        ADD_EDIT_COMMUNICATION_STATUS: addEditCommunicationStatus,
      }),
      tables: combineReducers({
        COMMUNICATION_STATUSES_TABLE: GridReducerFactory('COMMUNICATION_STATUSES_TABLE'),
      }),
    }),
    COMPLIANCE_STATUS: combineReducers({
      tables: combineReducers({
        COMPLIANCE_STATUS_TABLE: GridReducerFactory('COMPLIANCE_STATUS_TABLE'),
      }),
    }),
    COUNTRIES_SETTINGS: combineReducers({
      tables: combineReducers({
        COUNTRIES: GridReducerFactory('COUNTRIES'),
      }),
    }),
    CUSTOMIZATION: combineReducers({
      tables: combineReducers({
        CLIENT_PROFILE_LAYOUTS_TABLE: GridReducerFactory('CLIENT_PROFILE_LAYOUTS_TABLE'),
        DASHBOARD_SETTINGS_TABLE: GridReducerFactory('DASHBOARD_SETTINGS_TABLE'),
      }),
    }),
    DASHBOARD_LAYOUT: dashboardPageReducer,
    DEPOSIT_METHOD_RULE_SINGLE: depositMethodRuleReducer,
    DEPOSIT_EXPIRY_PERIOD_SINGLE: depositExpiryPeriodReducer,
    DEPOSITS: combineReducers({
      tables: combineReducers({
        PENDING_DEPOSIT_RULES: GridReducerFactory('PENDING_DEPOSIT_RULES'),
        DEPOSIT_METHOD_RULES: GridReducerFactory('DEPOSIT_METHOD_RULES'),
        WITHDRAWABLE_AMOUNTS_RULES: GridReducerFactory('WITHDRAWABLE_AMOUNTS_RULES'),
        DEPOSIT_EXPIRY_PERIOD: GridReducerFactory('DEPOSIT_EXPIRY_PERIOD'),
        STATES_RULES: GridReducerFactory('STATES_RULES'),
      }),
    }),
    DOCUMENT_CHECK_LOG: combineReducers({
      tables: combineReducers({
        DOCUMENT_CHECK_LOG_TABLE: GridReducerFactory('DOCUMENT_CHECK_LOG_TABLE'),
      }),
    }),
    DOCUMENTS: combineReducers({
      documentsReducer,
      tables: combineReducers({
        REJECTION_REASONS: GridReducerFactory('REJECTION_REASONS'),
      }),
    }),
    EMAIL_ALERT_SETTINGS: combineReducers({
      tables: combineReducers({
        EMAILS: GridReducerFactory('EMAILS'),
      }),
    }),
    EMAIL_EVENT_SINGLE_PAGE: emailEventReducer,
    EMAIL_EVENTS: combineReducers({
      tables: combineReducers({
        EMAIL_EVENTS_TABLE: GridReducerFactory('EMAIL_EVENTS_TABLE'),
      }),
    }),
    EMAIL_TEMPLATES: combineReducers({
      tables: combineReducers({
        EMAIL_TEMPLATES_TABLE: GridReducerFactory('EMAIL_TEMPLATES_TABLE'),
      }),
    }),
    EXCHANGE_RATES: combineReducers({
      actions: combineReducers({
        ADD_EDIT_EXCHANGE_RATE: addEditExchangeRate,
      }),
      tables: combineReducers({
        EXCHANGE_RATES_TABLE: GridReducerFactory('EXCHANGE_RATES_TABLE'),
      }),
    }),
    IB_LIST: combineReducers({
      tables: combineReducers({
        IB_LIST_TABLE: GridReducerFactory('IB_LIST_TABLE'),
      }),
      bulkActions: combineReducers({
        EDIT_TAGS: bulkActionsReducerFactory('IB_EDIT_TAGS'),
        EDIT_SALES_STATUSES: bulkActionsReducerFactory('IB_EDIT_SALES_STATUSES'),
        EDIT_COMPLIANCE_STATUSES: bulkActionsReducerFactory('IB_EDIT_COMPLIANCE_STATUSES'),
        ASSIGN_SALES_MANAGER: bulkActionsReducerFactory('IB_ASSIGN_SALES_MANAGER'),
      }),
    }),
    IB_PAYMENT_HISTORY: combineReducers({
      tables: combineReducers({
        IB_PAYMENT_HISTORY_TABLE: GridReducerFactory('IB_PAYMENT_HISTORY_TABLE'),
      }),
    }),
    IB_PENDING_WITHDRAWALS: combineReducers({
      tables: combineReducers({
        IB_PENDING_WITHDRAWALS_TABLE: GridReducerFactory('IB_PENDING_WITHDRAWALS_TABLE'),
      }),
    }),
    PAYMENT_BONUSES: combineReducers({
      tables: combineReducers({
        PAYMENT_BONUSES_TABLE: GridReducerFactory('PAYMENT_BONUSES_TABLE'),
      }),
    }),
    INSTRUMENTS: combineReducers({
      tables: combineReducers({
        INSTRUMENTS_TABLE: GridReducerFactory('INSTRUMENTS_TABLE'),
      }),
    }),
    INTEGRATIONS: integrationsReducer,
    INTERNAL_TRANSACTIONS: combineReducers({
      tables: combineReducers({
        INTERNAL_TRANSACTIONS_TABLE: GridReducerFactory('INTERNAL_TRANSACTIONS_TABLE'),
      }),
    }),
    KYC_BLACKLIST: combineReducers({
      tables: combineReducers({
        KYC_BLACKLIST_TABLE: GridReducerFactory('KYC_BLACKLIST_TABLE'),
      }),
    }),
    KYC_CHECK_LOG: combineReducers({
      tables: combineReducers({
        KYC_CHECK_LOG_TABLE: GridReducerFactory('KYC_CHECK_LOG_TABLE'),
      }),
    }),
    KYC_EXPORT: kycExportReducer,
    KYC_EXCLUSION: combineReducers({
      tables: combineReducers({
        KYC_EXCLUSION_TABLE: GridReducerFactory('KYC_EXCLUSION_TABLE'),
      }),
    }),
    KYC_HISTORY: combineReducers({
      tables: combineReducers({
        KYC_HISTORY_TABLE: GridReducerFactory('KYC_HISTORY_TABLE'),
      }),
    }),
    KYC_UNSUITABLE: combineReducers({
      tables: combineReducers({
        KYC_UNSUITABLE_TABLE: GridReducerFactory('KYC_UNSUITABLE_TABLE'),
      }),
    }),
    LOGS_MESSAGE: logsMessageReducer,
    OPEN_POSITIONS: combineReducers({
      tables: combineReducers({
        OPEN_POSITIONS_TABLE: GridReducerFactory('OPEN_POSITIONS_TABLE'),
      }),
    }),
    PAYMENT_GATEWAYS: combineReducers({
      tables: combineReducers({
        GATEWAYS: GridReducerFactory('GATEWAYS'),
        BANKS: GridReducerFactory('BANKS'),
      }),
    }),
    PAYMENT_METHODS: combineReducers({
      tables: combineReducers({
        METHODS: GridReducerFactory('METHODS'),
        MAPPINGS: GridReducerFactory('MAPPINGS'),
      }),
    }),
    PAYMENT_TRANSACTION_TYPES: combineReducers({
      actions: combineReducers({
        ADD_EDIT_GATEWAY: addEditGateway,
      }),
      tables: combineReducers({
        GATEWAYS: GridReducerFactory('GATEWAYS'),
        REASONS: GridReducerFactory('REASONS'),
      }),
    }),
    PAYMENT_TRANSACTIONS: combineReducers({
      tables: combineReducers({
        PAYMENT_TRANSACTIONS_TABLE: GridReducerFactory('PAYMENT_TRANSACTIONS_TABLE'),
      }),
    }),
    PENDING_DEPOSIT_RULE: pendingDepositRuleReducer,
    PENDING_WITHDRAWAL: combineReducers({
      withdrawal: pendingWithdrawalReducer,
      withdrawalFlow: withdrawalFlowReducer,
      tables: combineReducers({
        DEPOSITS_MATCHING_METHOD: GridReducerFactory('DEPOSITS_MATCHING_METHOD'),
        WITHDRAWALS_MATCHING_METHOD: refundPayoutTable,
      }),
    }),
    PENDING_WITHDRAWALS: combineReducers({
      tables: combineReducers({
        PENDING_WITHDRAWALS_TABLE: GridReducerFactory('PENDING_WITHDRAWALS_TABLE'),
      }),
    }),
    RELATED_PAYMENTS: combineReducers({
      tables: combineReducers({
        RELATED_PAYMENTS: GridReducerFactory('RELATED_PAYMENTS'),
        RELATED_PAYMENTS_DETAILS: GridReducerFactory('RELATED_PAYMENTS_DETAILS'),
      }),
    }),
    RISK_RATING_SYSTEM: combineReducers({
      general: RiskRatingReducer,
      tables: combineReducers({
        RISK_RATING_SYSTEM_TABLE: GridReducerFactory('RISK_RATING_SYSTEM_TABLE'),
        TOTAL_DEPOSITS_TABLE: GridReducerFactory('TOTAL_DEPOSITS_TABLE'),
        RISK_GROUPS_PER_GATEWAY_TABLE: GridReducerFactory('RISK_GROUPS_PER_GATEWAY_TABLE'),
        ONFIDO_TABLE: GridReducerFactory('ONFIDO_TABLE'),
        CORPORATE_TABLE: GridReducerFactory('CORPORATE_TABLE'),
      }),
    }),
    SALES_OVERVIEW: combineReducers({
      tables: combineReducers({
        ALL_TEAM_MEMBERS_TABLE: GridReducerFactory('ALL_TEAM_MEMBERS_TABLE'),
        ALL_TEAMS_TABLE: GridReducerFactory('ALL_TEAMS_TABLE'),
      }),
    }),
    SALES_STATUSES: combineReducers({
      actions: combineReducers({
        ADD_EDIT_SALES_STATUS: addEditSalesStatus,
      }),
      tables: combineReducers({
        SALES_STATUSES_TABLE: GridReducerFactory('SALES_STATUSES_TABLE'),
      }),
    }),
    SEGMENTS: combineReducers({
      tables: combineReducers({
        SEGMENTS_TABLE: GridReducerFactory('SEGMENTS_TABLE'),
      }),
    }),
    SETTINGS_EMAILS: settingsEmailsReducer,
    SETTINGS_GENERAL: settingsGeneralReducer,
    SMS_EVENT_SINGLE_PAGE: smsEventReducer,
    SMS_EVENTS: combineReducers({
      tables: combineReducers({
        SMS_EVENTS_TABLE: GridReducerFactory('SMS_EVENTS_TABLE'),
      }),
    }),
    SMS_TEMPLATES: combineReducers({
      tables: combineReducers({
        SMS_TEMPLATES_TABLE: GridReducerFactory('SMS_TEMPLATES_TABLE'),
      }),
    }),
    TASKS: combineReducers({
      tables: combineReducers({
        TASKS_TABLE: GridReducerFactory('TASKS_TABLE'),
      }),
    }),
    TEAM: combineReducers({
      tables: combineReducers({
        TEAM_TABLE: GridReducerFactory('TEAM_TABLE'),
      }),
      team: teamReducer,
    }),
    TEAM_CLIENT_VISIBILITY_GROUPS: teamClientVisibilityGroupsReducer,
    TEAM_PROFILE: combineReducers({
      profile: teamMemberReducer,
    }),
    TEAMS: combineReducers({
      tables: combineReducers({
        TEAMS_TABLE: GridReducerFactory('TEAMS_TABLE'),
      }),
      teamsStructure,
    }),
    TERMS_AND_CONDITIONS: combineReducers({
      tables: combineReducers({
        TERMS_AND_CONDITIONS_TABLE: GridReducerFactory('TERMS_AND_CONDITIONS_TABLE'),
      }),
    }),
    TRADES: combineReducers({
      tables: combineReducers({
        TRADES_TABLE: GridReducerFactory('TRADES_TABLE'),
      }),
    }),
    TRADING_ACCOUNTS: combineReducers({
      tables: combineReducers({
        TRADING_ACCOUNTS_TABLE: GridReducerFactory('TRADING_ACCOUNTS_TABLE'),
      }),
    }),
    TRADING_PLATFORM: combineReducers({
      tables: combineReducers({
        NAMES_TABLE: GridReducerFactory('NAMES_TABLE'),
        LINKS_TABLE: GridReducerFactory('LINKS_TABLE'),
        ACCOUNT_TYPES_TABLE: GridReducerFactory('ACCOUNT_TYPES_TABLE'),
        TRADING_GROUPS_TABLE: GridReducerFactory('TRADING_GROUPS_TABLE'),
      }),
    }),
    UPDATE_NOTIFICATION_TEMPLATE: updateTemplateReducer,
    USER_SINGLE: userSingleReducers,
    USER_STATE_MAPPING: combineReducers({
      tables: combineReducers({
        GBG: GridReducerFactory('GBG'),
        ONFIDO: GridReducerFactory('ONFIDO'),
      }),
    }),
    WITHDRAWAL_FLOW_STEP: withdrawalFlowStepReducer,
    WITHDRAWAL_HISTORY: combineReducers({
      tables: combineReducers({
        WITHDRAWAL_HISTORY_TABLE: GridReducerFactory('WITHDRAWAL_HISTORY_TABLE'),
      }),
    }),
    WITHDRAWAL_METHOD_RULE_SINGLE: withdrawalMethodRuleReducer,
    WITHDRAWALS: combineReducers({
      actions: combineReducers({
        ADD_EDIT_REJECT_REASON: addEditRejectReason,
      }),
      tables: combineReducers({
        FLOW: GridReducerFactory('FLOW'),
        METHODS: GridReducerFactory('METHODS'),
        REJECT_REASONS: GridReducerFactory('REJECT_REASONS'),
      }),
    }),
  }),
  forms: combineReducers({
    ADD_EDIT_BONUS_REASON: FormReducerFactory('ADD_EDIT_BONUS_REASON'),
    ADD_EDIT_TEAM: FormReducerFactory('ADD_EDIT_TEAM'),
    ADD_EDIT_TRANSACTION_REASON: FormReducerFactory('ADD_EDIT_TRANSACTION_REASON'),
    ADD_EDIT_EXCHANGE_RATE_FORM: FormReducerFactory('ADD_EDIT_EXCHANGE_RATE_FORM'),
    ADD_LEAD_SINGLE_FORM: FormReducerFactory('ADD_LEAD_SINGLE_FORM'),
    ADD_METHOD_FORM: FormReducerFactory('ADD_METHOD_FORM'),
    ADD_NEW_MEMBER: FormReducerFactory('ADD_NEW_MEMBER'),
    AUTOMATIC_FEES: FormReducerFactory('AUTOMATIC_FEES'),
    AVA_INTEGRATION_FORM: FormReducerFactory('AVA_INTEGRATION_FORM'),
    BASE_TEMPLATE_FORM: FormReducerFactory('BASE_TEMPLATE_FORM'),
    BO_PERSONAL_DETAILS_FORM: FormReducerFactory('BO_PERSONAL_DETAILS_FORM'),
    BO_SETTINGS_FORM: FormReducerFactory('BO_SETTINGS_FORM'),
    CAMPAIGN_FORM: FormReducerFactory('CAMPAIGN_FORM'),
    CANCELLATION_RULES_FORM: FormReducerFactory('CANCELLATION_RULES_FORM'),
    CHANGE_PASSWORD_FORM: FormReducerFactory('CHANGE_PASSWORD_FORM'),
    CHANGE_TA_PASSWORD_FORM: FormReducerFactory('CHANGE_TA_PASSWORD_FORM'),
    CLIENT_PROFILE_LAYOUT_FORM: FormReducerFactory('CLIENT_PROFILE_LAYOUT_FORM'),
    COMMISSION_SIDEPANEL_FORM: FormReducerFactory('COMMISSION_SIDEPANEL_FORM'),
    COMMUNICATION_FLOW_FORM: FormReducerFactory('COMMUNICATION_FLOW_FORM'),
    COMPLIENCE_STATUS_SIDEPANEL: FormReducerFactory('COMPLIENCE_STATUS_SIDEPANEL'),
    CREATE_EDIT_DOCUMENT: FormReducerFactory('CREATE_EDIT_DOCUMENT'),
    CREATE_EDIT_TASK_FORM: FormReducerFactory('CREATE_EDIT_TASK_FORM'),
    CREATE_LOG_FORM: FormReducerFactory('CREATE_LOG_FORM'),
    CREATE_TASK_COMMUNICATION_FLOW_FORM: FormReducerFactory('CREATE_TASK_COMMUNICATION_FLOW_FORM'),
    CREATE_TOC_FORM: FormReducerFactory('CREATE_TOC_FORM'),
    DASHBOARD_FILTER_FORM: FormReducerFactory('DASHBOARD_FILTER_FORM'),
    DASHBOARD_LAYOUT_FORM: FormReducerFactory('DASHBOARD_LAYOUT_FORM'),
    DATA_TAGS_FORM: FormReducerFactory('DATA_TAGS_FORM'),
    DELETE_EMAIL_EVENT: FormReducerFactory('DELETE_EMAIL_EVENT'),
    DOCUMENT_REJECTION_REASON_FORM: FormReducerFactory('DOCUMENT_REJECTION_REASON_FORM'),
    EDIT_CORPORATE_RISK: FormReducerFactory('EDIT_CORPORATE_RISK'),
    EDIT_COUNTRY_RISK: FormReducerFactory('EDIT_COUNTRY_RISK'),
    EDIT_LOGS_RECORD_FORM: FormReducerFactory('EDIT_LOGS_RECORD_FORM'),
    EDIT_MIFID_FORM: FormReducerFactory('EDIT_MIFID_FORM'),
    EDIT_ONFIDO_RISK: FormReducerFactory('EDIT_ONFIDO_RISK'),
    EDIT_RISK_GROUP_PER_GATEWAY: FormReducerFactory('EDIT_RISK_GROUP_PER_GATEWAY'),
    EDIT_SIGNUP_DATA_FORM: FormReducerFactory('EDIT_SIGNUP_DATA_FORM'),
    EDIT_TOTAL_RISK_DEPOSIT: FormReducerFactory('EDIT_TOTAL_RISK_DEPOSIT'),
    EDIT_TRADING_ACCOUNT_FORM: FormReducerFactory('EDIT_TRADING_ACCOUNT_FORM'),
    EDIT_USER_FORM: FormReducerFactory('EDIT_USER_FORM'),
    EMAIL_ALERTS_FORM: FormReducerFactory('EMAIL_ALERTS_FORM'),
    EMAIL_NOTIFICATIONS_FORM: FormReducerFactory('EMAIL_NOTIFICATIONS_FORM'),
    EMAIL_TEMPLATE_FORM: FormReducerFactory('EMAIL_TEMPLATE_FORM'),
    EXECUTE_WITHDRAWAL: FormReducerFactory('EXECUTE_WITHDRAWAL'),
    EXTERNAL_TRANSFER_FORM: FormReducerFactory('EXTERNAL_TRANSFER_FORM'),
    FORGOT_PASSWORD_FORM: FormReducerFactory('FORGOT_PASSWORD_FORM'),
    GATEWAY_BANK_FORM: FormReducerFactory('GATEWAY_BANK_FORM'),
    IB_EXECUTE_WITHDRAWAL: FormReducerFactory('IB_EXECUTE_WITHDRAWAL'),
    IB_REJECT_WITHDRAWAL: FormReducerFactory('IB_REJECT_WITHDRAWAL'),
    INTEGRATION_FORM: FormReducerFactory('INTEGRATION_FORM'),
    INTERNAL_TRANSFER_FORM: FormReducerFactory('INTERNAL_TRANSFER_FORM'),
    JOIN_FORM: FormReducerFactory('JOIN_FORM'),
    KYC_BLACKLIST_SIDEPANEL: FormReducerFactory('KYC_BLACKLIST_SIDEPANEL'),
    LINKS_FORM: FormReducerFactory('LINKS_FORM'),
    LOG_CALL: FormReducerFactory('LOG_CALL'),
    LOG_EMAIL: FormReducerFactory('LOG_EMAIL'),
    LOG_SMS: FormReducerFactory('LOG_SMS'),
    LOGIN_FORM: FormReducerFactory('LOGIN_FORM'),
    MANUAL_BONUS_FORM: FormReducerFactory('MANUAL_BONUS_FORM'),
    MANUAL_DEPOSIT_FORM: FormReducerFactory('MANUAL_DEPOSIT_FORM'),
    MINIMAL_WITHDRAWAL_BALANCE_FORM: FormReducerFactory('MINIMAL_WITHDRAWAL_BALANCE_FORM'),
    MT4_BONUS_FORM: FormReducerFactory('MT4_BONUS_FORM'),
    NAMES_FORM: FormReducerFactory('NAMES_FORM'),
    PAYMENT_MAPPING_FORM: FormReducerFactory('PAYMENT_MAPPING_FORM'),
    PAYMENT_METHOD_FORM: FormReducerFactory('PAYMENT_METHOD_FORM'),
    PERSONAL_DATA_FORM: FormReducerFactory('PERSONAL_DATA_FORM'),
    REJECT_CLIENT_FORM: FormReducerFactory('REJECT_CLIENT_FORM'),
    REJECT_REASONS_FORM: FormReducerFactory('REJECT_REASONS_FORM'),
    REJECT_WITHDRAWAL: FormReducerFactory('REJECT_WITHDRAWAL'),
    RESET_PASSWORD_FORM: FormReducerFactory('RESET_PASSWORD_FORM'),
    SALES_STATUS_FLOW_FORM: FormReducerFactory('SALES_STATUS_FLOW_FORM'),
    SEND_EMAIL_FORM: FormReducerFactory('SEND_EMAIL_FORM'),
    SEND_NOTE_COMMUNICATION_FLOW_FORM: FormReducerFactory('SEND_NOTE_COMMUNICATION_FLOW_FORM'),
    SEND_NOTE_FORM: FormReducerFactory('SEND_NOTE_FORM'),
    SEND_SMS_FORM: FormReducerFactory('SEND_SMS_FORM'),
    SETTINGS_EMAILS_FORM: FormReducerFactory('SETTINGS_EMAILS_FORM'),
    SETTINGS_EMAILS_SIDEPANEL: FormReducerFactory('SETTINGS_EMAILS_SIDEPANEL'),
    SETTINGS_GENERAL_FORM: FormReducerFactory('SETTINGS_GENERAL_FORM'),
    SETTINGS_PASSWORDS_FORM: FormReducerFactory('SETTINGS_PASSWORDS_FORM'),
    SMS_TEMPLATE_DATA_TAGS: FormReducerFactory('SMS_TEMPLATE_DATA_TAGS'),
    SMS_TEMPLATE_FORM: FormReducerFactory('SMS_TEMPLATE_FORM'),
    TRACKING_LINKS_FORM: FormReducerFactory('TRACKING_LINKS_FORM'),
    TRADING_ACCOUNT_FORM: FormReducerFactory('TRADING_ACCOUNT_FORM'),
    TRADING_ACCOUNT_TYPE_FORM: FormReducerFactory('TRADING_ACCOUNT_TYPE_FORM'),
    USER_STATE_GBG_FORM: FormReducerFactory('USER_STATE_GBG_FORM'),
    USER_STATE_ONFIDO_FORM: FormReducerFactory('USER_STATE_ONFIDO_FORM'),
    WHITELIST_LINKS_FORM: FormReducerFactory('WHITELIST_LINKS_FORM'),
    WITHDRAWABLE_AMOUNT_RULES: FormReducerFactory('WITHDRAWABLE_AMOUNT_RULES'),
    STATE_RULES: FormReducerFactory('STATE_RULES'),
    TRADING_GROUPS_FORM: FormReducerFactory('TRADING_GROUPS_FORM'),
  }),
  // Sidepanels and Modals reducer
  overlays: overlayReducer,
  filters: filterReducers,
};

export default combineReducers(reducers);
