/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';

import connect from '../../../../../../logic/connect';
import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import { UsernameCell } from '../../../../../../components/gridCellRenderers';
import { UserPreview } from '../../../../../../components/common/sidepanels';
import overlayActions from '../../../../../../components/overlay';

const tableConfig = id => ({
  apiUrl: `users/${id}/duplicate_users`,
  reducerKey: 'USER_SINGLE',
  tableKey: 'POSSIBLE_DUPLICATES',
});

const modifier = previewUser => ({
  username: {
    cellRendererFramework: UsernameCell(previewUser),
  },
});

class PossibleDuplicates extends Component {
  constructor(props) {
    super(props);

    this.Table = withTable(GridTab, tableConfig, null, { hideSegments: true })(props.id);
    this.previewUser = props.actions.openSidepanel.bind(this, 'USER_PREVIEW');
  }

  render() {
    const { Table } = this;

    return (
      <>
        <Table modifier={modifier(this.previewUser)} />
        <UserPreview />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    openSidepanel: overlayActions.open,
  }, dispatch),
});

export default connect(null, mapDispatchToProps)(PossibleDuplicates);
