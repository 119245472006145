import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { object, bool } from 'prop-types';
import { notifier, Space, Sidepanel } from 'tc-biq-design-system';

import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';

const FORM_KEY = 'REJECT_CLIENT_FORM';

const { create } = FormActionsFactory(FORM_KEY);


const text = {
  REJECT_REASON: gettext('Please enter reject reason'),
  SUCCESS: gettext('User successfully rejected.'),
  ERROR: gettext('Error rejecting user.'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  submitInProgress: bool.isRequired,
  isIb: bool,
};

const defaultProps = {
  user: null,
  actions: null,
  isIb: false,
};

class Reject extends Component {
  constructor(props) {
    super(props);

    this.actions = props.actions;

    this.api = (user, isIb) => getDjangoApi(`${isIb ? 'ib' : 'users'}/${user.id}/reject`);
    this.onClose = this.onClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onClose() {
    const { sidepanelManager } = this.props;
    sidepanelManager.close();
  }

  onSubmit() {
    const { user, isIb, sidepanelManager } = this.props;
    this.actions.create(this.api(user, isIb)).then(() => {
      this.actions.fetchUser(user, isIb);
      notifier.success(text.SUCCESS);
      sidepanelManager.close();
    }).catch((error) => {
      const nonFieldErrors = _.get(error, 'data.non_field_errors');
      if (nonFieldErrors) notifier.error(`${text.ERROR} ${nonFieldErrors[0]}`);
    });
  }

  getFormConfig() {
    const { user, isIb } = this.props;
  
    return {
      form: FORM_KEY,
      api: this.api(user, isIb),
    };
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.getFormConfig());
    }
    const { FormComponent } = this;
    return <FormComponent />;
  }

  render() {
    const { submitInProgress, ...options } = this.props;

    return (
      <Sidepanel {...options} footerRender={customFooter(this.onSubmit, this.onClose, submitInProgress)}>
        <Space size={16} />
        { this.renderForm() }
      </Sidepanel>
    );
  }
}

Reject.propTypes = propTypes;
Reject.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      create,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ forms }) => {
  const form = forms[FORM_KEY];
  return {
    submitInProgress: form.submitInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reject);
