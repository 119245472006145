import { bindActionCreators, compose } from 'redux';

import overlayActions from '../../../../../components/overlay';
import connect from '../../../../../logic/connect';
import PaymentMethods from './PaymentMethods';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import { hasAccess } from '../../../../../logic/services/acl';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
  dispatch,
});

const PaymentMethodsPage = compose(
  connect(null, mapDispatchToProps),
  withErrorBoundary,
)(PaymentMethods); 

export default {
  component: PaymentMethodsPage,
  aclFn: () => hasAccess('payment_methods.list') || hasAccess('payment_gateways.identifiers.list'),
  path: appRoutes.SETTINGS_PAYMENT_METHODS,
};
