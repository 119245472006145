/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/require-render-return */
import React, { Component } from 'react';
import { Footer } from 'tc-biq-design-system';
import classnames from 'classnames';
import { object, node } from 'prop-types';

import Nav from '../Nav';
import AppHeader from '../AppHeader';

const propTypes = {
  history: object.isRequired,
  session: object.isRequired,
  children: node.isRequired,
};

class MainLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarCollapsed: false,
    };
  }

  onRouteChange = (route) => {
    const { history } = this.props;
    history.push(route);
  }

  onSidebarToggle = sidebarCollapsed => this.setState({ sidebarCollapsed })

  render() {
    const { session: { user }, children } = this.props;
    const { sidebarCollapsed } = this.state;
    const showSidebar = !!user;
    
    return (
      <div className="react-app">
        {showSidebar && (
          <aside className="react-app__aside">
            <Nav onToggleCollapsed={this.onSidebarToggle} onRouteChange={this.onRouteChange} />
          </aside>
        )}

        <section className={classnames('react-app__main', { 'react-app__main--wide': sidebarCollapsed && showSidebar, 'react-app__main--narrow': !sidebarCollapsed && showSidebar })}>
          {user && <AppHeader />}
          <div className="react-app__content">{children}</div>
          <Footer />
        </section>
      </div>
    );
  }
}

MainLayout.propTypes = propTypes;

export default MainLayout;
