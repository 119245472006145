import React, { Component, Fragment } from 'react';
import { func, object } from 'prop-types';
import { notifier } from 'tc-biq-design-system';

import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import modifiers from './modifiers';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';
import LinksSidepanel, { SIDEPANEL_ID } from './LinksSidepanel';
import { hasAccess } from '../../../../../../logic/services/acl';

const text = {
  SUCCESS: gettext('Platform link successfully deleted'),
  ERROR: gettext('Error while trying to delete platform link'),
};

const apiUrl = 'trading_backends/manage_links';
const api = getDjangoApi(apiUrl);

export const tableConfig = () => ({
  reducerKey: 'TRADING_PLATFORM',
  apiUrl,
  tableKey: 'LINKS_TABLE',
});

const propTypes = {
  dispatch: func.isRequired,
  actions: object.isRequired,
};

class LinksTable extends Component {
  constructor(props) {
    super(props);
    this.Grid = withTable(GridTab, tableConfig, null, { withActions: true })();
    this.tableActions = {
      ...(hasAccess('tpi.trading_backends.manage_links.destroy') && { onDelete: this.onDelete }),
      ...(hasAccess('tpi.trading_backends.manage_links.update') && { onEdit: this.onEdit }),
    };
    this.showActions = !!Object.keys(this.tableActions).length;
  }

  onDelete = async (data) => {
    const [err] = await to(api.destroy(data.id));
    if (err) {
      notifier.error(text.ERROR);
    } else {
      notifier.success(text.SUCCESS);
      this.reloadTable();
    }
  }

  onEdit = (data) => {
    const { actions } = this.props;
    actions.openSidepanel(SIDEPANEL_ID, { data });
  }

  reloadTable = () => {
    const { dispatch } = this.props;
    const [, tableActions] = this.Grid;
    dispatch(tableActions.fetchTableData());
  }

  render() {
    const [Table] = this.Grid;
    return (
      <Fragment>
        <Table 
          singleActions={this.showActions}
          modifier={modifiers(this.tableActions)} 
        />
        <LinksSidepanel onSuccess={this.reloadTable} />
      </Fragment>
    );
  }
}

LinksTable.propTypes = propTypes;

export default LinksTable;
