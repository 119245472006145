import React, { Fragment, PureComponent } from 'react';
import { CardList, Icon, Toggle } from 'tc-biq-design-system';
import { array, string, func, number, bool } from 'prop-types';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import './LayoutCard.scss';
 
const propTypes = {
  sortIndex: number.isRequired,
  fields: array,
  title: string,
  isActive: bool,
  onEdit: func,
  onToggleSection: func,
  onToggleItem: func,
  onListSortEnd: func,
};

const defaultProps = {
  fields: [],
  title: null,
  isActive: false,
  onEdit: null,
  onToggleSection: null,
  onToggleItem: null,
  onListSortEnd: null,
};

const SortableContainer = sortableContainer(({ children }) => (
  <ul className="biq-card-list__items">{ children }</ul>
));

const SortableHandle = sortableHandle(() => (
  <Icon 
    name="Sort" 
    size="small"
    colorName="text-neutral-500"
  />
));

const SortableItem = sortableElement(({ name, label, isActive, onToggleItem }) => (
  <li key={`list-item2-${name}`} className="biq-card-list__item">
    <SortableHandle />
    <span 
      title={label} 
      className="layout-card__name tc-micro-regular"
    >
      {label}
    </span>
    <Toggle 
      onChange={onToggleItem}
      checked={isActive} 
    />
  </li>
));

// eslint-disable-next-line react/prop-types
const renderHeader = ({ 
  title, 
  isActive, 
  sortIndex,
  onEdit, 
  onToggleSection, 
}) => () => (
  <Fragment>
    <div className="layout-card__header">
      <SortableHandle />
      <span className="layout-card__title tc-micro-strong">{title}</span>
      <Icon 
        name="Edit" 
        size="small" 
        onClick={() => onEdit(sortIndex)}
        colorName="text-neutral-500"
      />
    </div>
    <Toggle 
      onChange={() => onToggleSection(sortIndex)} 
      checked={isActive} 
    />
  </Fragment>
);

const renderList = ({ 
  fields, 
  onToggleItem, 
  onListSortEnd, 
  sortIndex, 
}) => () => (
  <SortableContainer 
    key={sortIndex}
    onSortEnd={data => onListSortEnd(data, sortIndex)}  
    axis="y" 
    lockAxis="y"
    useDragHandle
  >
    { fields.map((field, index) => (
      <SortableItem 
        key={`list-item-${field.name}`} 
        index={index} 
        {...field}
        onToggleItem={() => onToggleItem(index, sortIndex)} 
      />
    ))}
  </SortableContainer>
);

class LayoutCard extends PureComponent {
  render() {
    const { 
      fields, 
      title, 
      isActive, 
      onEdit, 
      onToggleSection, 
      onToggleItem, 
      onListSortEnd, 
      sortIndex, 
    } = this.props;
    return (
      <div className="layout-card">
        <CardList 
          title={title}
          fields={fields}
          renderHeader={renderHeader({ title, isActive, sortIndex, onEdit, onToggleSection })}
          renderList={renderList({ fields, onToggleItem, onListSortEnd, sortIndex })}
        />
      </div>
    );
  }
}
LayoutCard.propTypes = propTypes;
LayoutCard.defaultProps = defaultProps;

export default LayoutCard;
