import React from 'react';
import { Pill } from 'tc-biq-design-system';

import concat from 'lodash/concat';

import hideCols from '../../../../../../logic/utilities/hideCols';
import currencyFormater from '../../../../../../logic/utilities/formatters/currency';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { OnContentClickCell, BackendModeCell } from '../../../../../../components/gridCellRenderers';
import { PLATFORM_TYPES } from '../../../../../../const/platformTypes';

const { environment } = window.config;

const text = {
  TOTAL_EQUITY: gettext('Total Equity'),
  CASH_BALANCE: gettext('Cash Balance'),
  TOTAL_CREDIT: gettext('Total Credit'),
  WITHDRAWABLE_AMOUNTH: gettext('Withdrawable Amount'),
  MODE: gettext('Mode'),
  TRADING_ACCOUNT_TYPE: gettext('Trading Account Type'),
  BACKEND: gettext('Backend'),
  TYPE: gettext('Type'),
  READ_ONLY: gettext('Read only mode'),
  ENABLED: gettext('Enabled'),
  DISABLED: gettext('Disabled'),
};

/* eslint-disable */
const totalDepositField = ({ data: { currency, total_deposits } }) =>
  (
    <span>{currencyFormater(total_deposits, currency.symbol)}</span>
  );

const totalWithdrawalField = ({ data: { currency, total_withdrawals } }) =>
  (
    <div>
      <span>{currencyFormater(total_withdrawals, currency.symbol)}</span>
    </div>
  )

const BalancePreview = ({ data: { balance: {
  TotalEquity,
  CashBalance,
  TotalCredit,
  WithdrawableAmount,
  Currency,
} } }) => (
  <div>
    <span>{text.TOTAL_EQUITY}: <Pill small>{TotalEquity === 'n/a' ? TotalEquity : currencyFormater(TotalEquity, Currency)}</Pill> </span>
    <span>{text.CASH_BALANCE}: <Pill small>{CashBalance === 'n/a' ? CashBalance : currencyFormater(CashBalance, Currency)}</Pill> </span>
    <span>{text.TOTAL_CREDIT}: <Pill small>{TotalCredit === 'n/a' ? TotalCredit : currencyFormater(TotalCredit, Currency)}</Pill> </span>
    <span>{text.WITHDRAWABLE_AMOUNTH}: <Pill small>{WithdrawableAmount === 'n/a' ? WithdrawableAmount : currencyFormater(WithdrawableAmount, Currency)}</Pill></span>
  </div>
);

const readOnlyMap = {
  true: {
    type: 'status01',
    label: text.ENABLED
  },
  false: {
    type: 'status04',
    label: text.DISABLED
  }
}

const ReadOnlyMode = ({ data }) => {
  const { backend = {}, extra = {} } = data;
  let enabled = data.read_only;

  if (backend.platform_type === PLATFORM_TYPES.avaOptions && extra.external_request) {
    enabled = Boolean(extra.external_request.disableSelfTrading);
  }

  const {type, label} = readOnlyMap[enabled];
  return (
    <Pill type={ type }>{ label }</Pill>
  )
}

const BackendCell = ({ data: { backend }}) => backend && <div>{backend.name} <BackendModeCell value={backend.mode} /></div>
/* eslint-enable */

let additionalHiddenCols = concat([], ['name', 'description', 'external_user_id', 'is_editable', 'username', 'login_url', 'leverage', 'is_third_party', 'is_password_editable', 'account_type']);

if (environment !== 'AvaTrade') additionalHiddenCols = concat(additionalHiddenCols, 'created', 'expires_at', 'label');

export default ({ openTradingAccount }) => ({
  id: {
    cellRendererFramework: OnContentClickCell(openTradingAccount),
    width: 120,
  },
  backend: {
    sortValue: -2,
    cellRendererFramework: BackendCell,
  },
  external_id: {
    sortValue: -3,
  },
  leverage: {
    cellRendererFramework: ({ data }) => _.get(data, 'extra.external_response.Leverage')
      || _.get(data, 'extra.external_request.Leverage')
      || '',
  },
  label: {
    displayName: text.TRADING_ACCOUNT_TYPE,
  },
  total_withdrawals: {
    cellRendererFramework: totalWithdrawalField,
  },
  total_deposits: {
    cellRendererFramework: totalDepositField,
  },
  balance: {
    width: 650,
    cellRendererFramework: BalancePreview,
  },
  read_only: {
    displayName: text.READ_ONLY,
    cellRendererFramework: ReadOnlyMode,
  },
  ...hideCols(concat(['meta', 'mt4_options', 'extra', 'user', 'external_user_id'], additionalHiddenCols)),
});
