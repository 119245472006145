import IB_STATE from './../enums/ib-state';

function ibState() {
  return function (state, reverse) {
    if (!reverse) {
      switch (state) {
        case IB_STATE.APPROVED:
          return 'Approved';
        case IB_STATE.PENDING:
          return 'Pending';
        case IB_STATE.INCOMPLETE:
          return 'Incomplete';
        case IB_STATE.REJECTED:
          return 'Rejected';
      }
    }

    if (reverse) {
      switch (state) {
        case 'Approved':
          return IB_STATE.APPROVED;
        case 'Pending':
          return IB_STATE.PENDING;
        case 'Incomplete':
          return IB_STATE.INCOMPLETE;
        case 'Rejected':
          return IB_STATE.REJECTED;
      }
    }
  };
}

export default ibState;
