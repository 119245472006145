import hideCols from '../../../../../../logic/utilities/hideCols';
import { MessageCell } from '../../../../../../components/gridCellRenderers';

const messagesModifier = {
  ...hideCols(['id']),
  message_id: {
    pinned: 'left',
    width: 260,
    cellRendererFramework: MessageCell('email'),
  },
};

const smsModifier = {
  id: { cellRendererFramework: MessageCell('sms') },
};

const callModifier = {
  id: { cellRendererFramework: MessageCell('calls') },
};

const chatModifier = {
  id: { cellRendererFramework: MessageCell('chat') },
};

export {
  messagesModifier,
  smsModifier,
  callModifier,
  chatModifier,
};
