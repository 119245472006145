import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  FETCH_TRADING_ACCOUNTS: 'FETCH_TRADING_ACCOUNTS',
  FETCH_TRADING_ACCOUNTS_FULFILLED: 'FETCH_TRADING_ACCOUNTS_FULFILLED',
  FETCH_TRADING_ACCOUNTS_PENDING: 'FETCH_TRADING_ACCOUNTS_PENDING',
  FETCH_TRADING_ACCOUNTS_REJECTED: 'FETCH_TRADING_ACCOUNTS_REJECTED',
};

export const fetchTradingAccounts = createAction(actionTypes.FETCH_TRADING_ACCOUNTS, async (user) => {
  const api = getDjangoApi(`users/${user.id}/trading_accounts/`);
  const result = await api.list({ limit: 1000 });
  return result.data.results;
});


const initialState = {
  accounts: [],
  fetchInProgress: false,
  fetchError: null,
};

export default handleActions(
  {
    [actionTypes.FETCH_TRADING_ACCOUNTS_PENDING]: state => ({ ...state, fetchInProgress: true }),
    [actionTypes.FETCH_TRADING_ACCOUNTS_FULFILLED]: (state, { payload }) => ({ ...state, fetchInProgress: false, accounts: payload }),
    [actionTypes.FETCH_TRADING_ACCOUNTS_REJECTED]: (state, { payload }) => ({ ...state, fetchInProgress: false, fetchError: payload }),
  },
  initialState,
);
