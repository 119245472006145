import React from 'react';
import { string, func, any } from 'prop-types';
import { TextArea } from 'tc-biq-design-system';

const propTypes = {
  id: string.isRequired,
  onChange: func.isRequired,
  actionComponent: any,
};

const defaultProps = {
  actionComponent: null,
};

const TextAreaField = (props) => {
  const { onChange, id, actionComponent } = props;
  return (
    <div className="biq-form__field">
      <TextArea 
        {...props} 
        onChange={e => onChange({ value: e.target.value, id })} 
      />
      {actionComponent}
    </div>
  );
};

TextAreaField.propTypes = propTypes;
TextAreaField.defaultProps = defaultProps;
export default TextAreaField;
