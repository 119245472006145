import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const getMimiroApi = id => getDjangoApi(`mimiro/monitor/${id}`);
const getStatusesApi = id => getDjangoApi(`mimiro/statuses/${id}`);
const getStatusApi = id => getDjangoApi(`mimiro/status/${id}`);
const getMimiroCertificateApi = id => getDjangoApi(`mimiro/certificate/${id}`);

const initialState = {
  mimiroInfo: null,
  isLoadingMimiroInfo: false,
  isLoadingCertificate: false,
  isLoadingStatuses: false,
  isLoadingSubStatusesOptions: false,
  certificateUrl: null,
  statuses: [],
  subStatusesOptions: [],
};

const actionsTypes = {
  FETCH_MIMIRO_INFO: 'FETCH_MIMIRO_INFO',
  FETCH_MIMIRO_INFO_PENDING: 'FETCH_MIMIRO_INFO_PENDING',
  FETCH_MIMIRO_INFO_FULFILLED: 'FETCH_MIMIRO_INFO_FULFILLED',
  FETCH_MIMIRO_INFO_REJECTED: 'FETCH_MIMIRO_INFO_REJECTED',
  FETCH_CERTIFICATE_URL: 'FETCH_CERTIFICATE_URL',
  FETCH_CERTIFICATE_URL_PENDING: 'FETCH_CERTIFICATE_URL_PENDING',
  FETCH_CERTIFICATE_URL_FULFILLED: 'FETCH_CERTIFICATE_URL_FULFILLED',
  FETCH_CERTIFICATE_URL_REJECETED: 'FETCH_CERTIFICATE_URL_REJECETED',
  PATCH_MONITORING_STATUS: 'PATCH_MONITORING_STATUS',
  PATCH_MONITORING_STATUS_PENDING: 'PATCH_MONITORING_STATUS_PENDING',
  PATCH_MONITORING_STATUS_FULFILLED: 'PATCH_MONITORING_STATUS_FULFILLED',
  PATCH_MONITORING_STATUS_REJECTED: 'PATCH_MONITORING_STATUS_REJECTED',
  FETCH_MIMIRO_STATUSES: 'FETCH_MIMIRO_STATUSES',
  FETCH_MIMIRO_STATUSES_PENDING: 'FETCH_MIMIRO_STATUSES_PENDING',
  FETCH_MIMIRO_STATUSES_FULFILLED: 'FETCH_MIMIRO_STATUSES_FULFILLED',
  FETCH_MIMIRO_STATUSES_REJECTED: 'FETCH_MIMIRO_STATUSES_REJECTED',
  FETCH_MIMIRO_SUBSTATUSES_OPTIONS: 'FETCH_MIMIRO_SUBSTATUSES_OPTIONS',
  FETCH_MIMIRO_SUBSTATUSES_OPTIONS_PENDING: 'FETCH_MIMIRO_SUBSTATUSES_OPTIONS_PENDING',
  FETCH_MIMIRO_SUBSTATUSES_OPTIONS_FULFILLED: 'FETCH_MIMIRO_SUBSTATUSES_OPTIONS_FULFILLED',
  FETCH_MIMIRO_SUBSTATUSES_OPTIONS_REJECTED: 'FETCH_MIMIRO_SUBSTATUSES_OPTIONS_REJECTED',
};

const fetchMimiroInfo = createAction(actionsTypes.FETCH_MIMIRO_INFO, 
  id => getMimiroApi(id).retrieve());

const fetchStatuses = createAction(actionsTypes.FETCH_MIMIRO_STATUSES, 
  id => getStatusesApi(id).retrieve());

const fetchSubstatusesOptions = createAction(actionsTypes.FETCH_MIMIRO_SUBSTATUSES_OPTIONS, 
  async (id) => {
    const { data } = await getStatusApi(id).options();
    const substatuses = _.get(data, 'actions.PUT.fields', []).find(({ key }) => key === 'substatuses');
    return _.get(substatuses, 'child.choices');
  });

const fetchCertificateUrl = createAction(actionsTypes.FETCH_CERTIFICATE_URL,
  id => getMimiroCertificateApi(id).retrieve());

const patchMonitoringStatus = createAction(actionsTypes.PATCH_MONITORING_STATUS, 
  async (id, statusId, { status, substatuses }) => {
    await getStatusApi(id).updatePart(null, {
      search: statusId,
      status,
      substatuses,
    });

    return {
      search: statusId,
      status,
      substatuses,
    };
  });

export default handleActions({
  [actionsTypes.FETCH_MIMIRO_INFO_PENDING]: 
    state => ({ ...state, isLoadingMimiroInfo: true, mimiroInfo: null }),
  [actionsTypes.FETCH_MIMIRO_INFO_REJECTED]: 
    state => ({ ...state, isLoadingMimiroInfo: false, mimiroInfo: { monitoring_status: -1 } }),
  [actionsTypes.FETCH_MIMIRO_INFO_FULFILLED]: 
   (state, { payload: { data } }) => ({ ...state, mimiroInfo: data, isLoadingMimiroInfo: false }),
  [actionsTypes.FETCH_CERTIFICATE_URL_PENDING]: 
    state => ({ ...state, certificateUrl: null, isLoadingCertificate: true }),
  [actionsTypes.FETCH_CERTIFICATE_URL_REJECTED]: 
    state => ({ ...state, isLoadingCertificate: false, certificateUrl: null }),
  [actionsTypes.FETCH_CERTIFICATE_URL_FULFILLED]:
    (state, { payload: { data } }) => ({ 
      ...state,
      isLoadingCertificate: false,
      certificateUrl: data.certificate_url, 
    }),
  [actionsTypes.PATCH_MONITORING_STATUS_FULFILLED]: (state, { payload: { index, status } }) => ({
    ...state,
    statuses: state.statuses.map((oldStatus, i) => {
      if (index === i) {
        return status;
      } 
      return oldStatus;
    }),
  }),
  [actionsTypes.FETCH_MIMIRO_STATUSES_FULFILLED]: (state, { payload: { data } }) => ({ 
    ...state,
    statuses: data.statuses,
    isLoadingStatuses: false,
  }),
  [actionsTypes.FETCH_MIMIRO_STATUSES_REJECTED]: state => ({
    ...state,
    isLoadingStatuses: false,
  }),
  [actionsTypes.FETCH_MIMIRO_SUBSTATUSES_OPTIONS_PENDING]: state => ({ 
    ...state,
    isLoadingSubStatusesOptions: true,
  }),
  [actionsTypes.FETCH_MIMIRO_SUBSTATUSES_OPTIONS_FULFILLED]: (state, { payload }) => ({ 
    ...state,
    subStatusesOptions: payload,
    isLoadingSubStatusesOptions: false,
  }),
  [actionsTypes.FETCH_MIMIRO_SUBSTATUSES_OPTIONS_REJECTED]: state => ({
    ...state,
    isLoadingSubStatusesOptions: false,
  }),
}, initialState);

export {
  fetchMimiroInfo,
  fetchCertificateUrl,
  patchMonitoringStatus,
  fetchStatuses,
  fetchSubstatusesOptions,
};
