import React from 'react';
import { Button } from 'tc-biq-design-system';
import { any, func } from 'prop-types';

import { gettext } from '../../../logic/utilities/languageUtility';


const propTypes = {
  value: any,
  onChange: func.isRequired,
};

const defaultProps = {
  value: false,
};

const text = {
  YES: gettext('Yes'),
  NO: gettext('No'),
};

const BooleanInput = (props) => {
  const { value, onChange } = props;
  const content = value ? text.YES : text.NO;
  const color = value ? 'confirmation' : 'destructive';

  return (
    <Button size="full-width" style={{ margin: '0' }} color={color} onClick={() => onChange(!value)}>
      {content}
    </Button>
  );
};

BooleanInput.propTypes = propTypes;
BooleanInput.defaultProps = defaultProps;

export default BooleanInput;
