import React, { PureComponent } from 'react';
import { array, object, bool, number } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import overlayActions from '../../../../../components/overlay';
import withUnmountMethod from '../../../../../components/hoc/withUnmountMethod';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import activityActions from '../Model/activityActions';
import './ActivityBody.scss';
import { DateGroup, Loading, NoData, NewNotifications } from './components';
import { gettext } from '../../../../../logic/utilities/languageUtility';

const propTypes = {
  data: array.isRequired,
  isLoading: bool.isRequired,
  actions: object.isRequired,
  hasMore: bool.isRequired,
  offset: number.isRequired,
  userId: number,
  boUserId: number,
  style: object,
};

const defaultProps = {
  userId: null,
  boUserId: null,
  style: null,
};

const text = {
  TODAY: gettext('Today'),
  YESTERDAY: gettext('Yesterday'),
};

export const formatDateGroupLabel = (dateString) => {
  const activityDate = moment(dateString, 'DD MM YYYY');
  const currentYear = moment().format('YYYY');
  const currentWeek = moment().format('w');
  const lastWeek = moment()
    .subtract(1, 'week')
    .format('w');
  const today = moment().format('DD MM YYYY');
  const yesterday = moment()
    .subtract(1, 'days')
    .format('DD MM YYYY');
  const dateYear = activityDate.format('YYYY');

  if (dateYear === currentYear) {
    if (activityDate.format('DD MM YYYY') === today) return text.TODAY;
    if (activityDate.format('DD MM YYYY') === yesterday) return text.YESTERDAY;
    if (activityDate.format('w') === currentWeek) return activityDate.format('dddd');
    if (activityDate.format('w') === lastWeek) return 'Last week';
    if (activityDate.format('MM') === moment().format('MM')) return activityDate.format('MMMM Do');
    if (activityDate.format('MM') < moment().format('MM')) return activityDate.format('YYYY MMMM Do');
  }
  return activityDate.format('YYYY MMMM Do');
};

const formatByDate = activities => _.groupBy(activities, activity => formatDateGroupLabel(moment(activity.published).format('DD MM YYYY')),
);

class ActivityBody extends PureComponent {
  constructor(props) {
    super(props);
    this.activityBodyRef = React.createRef();
    this.actions = props.actions;
  }

  componentDidMount() {
    // Infinite scroll
    const activityBodyNode = this.activityBodyRef.current;
    activityBodyNode.scrollTop = 0;
    activityBodyNode.onscroll = (event) => {
      const { isLoading, hasMore } = this.props;
      const { scrollTop } = event.target;
      if (isLoading || !hasMore) {
        return;
      }
      if (
        scrollTop
        >= activityBodyNode.scrollHeight - (activityBodyNode.offsetHeight + 50)
      ) {
        this.actions.loadMore();
      }
    };
  }

  componentDidUpdate() {
    const { offset } = this.props;
    if (offset === 0) this.activityBodyRef.current.scrollTop = 0;
  }

  render() {
    const { isLoading, data, userId, boUserId, style } = this.props;
    const forrmattedData = formatByDate(data);
    const dateGroups = Object.keys(forrmattedData);
    const noData = dateGroups.length === 0;
    return (
      <div className="activity-body" style={style} ref={this.activityBodyRef}>
        <NewNotifications userId={userId} boUserId={boUserId} />
        {!noData && dateGroups.map((dateGroup, index) => (
          <DateGroup
            key={`${dateGroup}-${index}`}
            activities={forrmattedData[dateGroup]}
            index={index}
            dateGroup={dateGroup}
          />
        ))}
        <NoData isVisible={noData && !isLoading} />
        <Loading isVisible={isLoading} />
      </div>
    );
  }
}

ActivityBody.propTypes = propTypes;
ActivityBody.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
      loadActivity: activityActions.loadActivity,
      loadMore: activityActions.loadMore,
      updateNewActivityCount: activityActions.updateNewActivityCount,
      resetNewActivityCoutn: activityActions.resetNewActivityCount,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const {
    activities: { data, isLoading, offset, hasMore, count },
  } = pages.ACTIVITY_STREAM;
  return { data, isLoading, offset, hasMore, count };
};

export default withUnmountMethod(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorBoundary(ActivityBody)),
);
