import GridActionsFactory from '../../../../../../components/grid/GridActionsFactory';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';

export const tableConfig = id => ({
  apiUrl: `users/${id}/notes/`,
  reducerKey: 'USER_SINGLE',
  tableKey: 'USER_NOTES_TABLE',
});

export const getTableActions = (id) => {
  const config = tableConfig(id);
  const api = getDjangoApi(config.apiUrl);
  return GridActionsFactory(config.tableKey, api);
};
