import React, { PureComponent } from 'react';
import { Input, Select, Button, Icon } from 'tc-biq-design-system';
import { object, string, array, shape, func } from 'prop-types';
import QueryBuilder from '../../../../../components/QueryBuilder';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import session from '../../../../../logic/services/session';
import { refactoredExtractGroup } from '../../../../../logic/services/query-adapter';
import Page from '../../../../../components/Page';
import getID from '../../../../../logic/utilities/getID';
import appRoutes from '../../../../../components/App/Router/appRoutes';

import './TeamVisibilityGroup.scss';
import FormEl from '../../../../../components/form/Components/FormEl';

const propTypes = {
  actions: object.isRequired,
  group: object,
  fields: array,
  name: string,
  errors: object,
  users: array,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  match: object.isRequired,
};

const defaultProps = {
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  fields: [],
  name: '',
  errors: {
    name: [''],
  },
  users: [],
};

const text = {
  TITLE: {
    create: gettext('Create new client visibility group'),
    edit: gettext('Edit group'),
  },
  SELECT_PLACEHOLDER: gettext('Select BO user(s)'),
  SELECT_LABEL: gettext('BO users'),
  INPUT_LABEL: gettext('Name'),
  CREATE_BUTTON_LABEL: gettext('Create'),
  EDIT_BUTTON_LABEL: gettext('Edit'),
  DISCARD_BUTTON_LABEL: gettext('Discard'),
  CLIENT_VISIBILITY_CRUMB: gettext('Client visibility groups'),
};

class TeamVisibilityGroup extends PureComponent {
  constructor(props) {
    super(props);

    this.actions = props.actions;
    this.type = this.getType();
    if (this.type !== 'create') this.id = props.match.params.id;

    if (+this.id === 1) this.redirectToTableView();

    this.crumbs = [
      { label: text.CLIENT_VISIBILITY_CRUMB, route: appRoutes.CLIENT_VISIBILITY_GROUPS },
      { label: text.TITLE[this.type], route: '' },
    ];
  }

  componentDidMount() {
    const { getFieldsOptions, getVisibilityGroupInfo } = this.actions;
    getFieldsOptions().then(() => {
      if (this.type === 'edit') getVisibilityGroupInfo(this.id);
    });
  }

  componentWillUnmount() {
    this.actions.resetState();
  }

  getType = () => {
    const pathRoutes = getID();

    if (_.includes(pathRoutes, 'add-new')) {
      return pathRoutes[pathRoutes.length - 1] === 'add-new' ? 'create' : 'clone';
    } 
      
    return 'edit';
  }

  getAutocompleteResults = (input) => {
    if (!input) return [];

    return getDjangoApi('autocomplete/team_members')
      .list({ username: input })
      .then(response => response.data.results);
  };

  redirectToTableView = () => {
    const { history } = this.props;
    history.push(appRoutes.CLIENT_VISIBILITY_GROUPS);
  };

  submitGroup = () => {
    const { group, name, users } = this.props;
    const { submitVisibilityGroup } = this.actions;
    const condition = refactoredExtractGroup(group) || null;
    const created_by = session.user.username;
    const team_members = users.map(user => user.id);

    const request = {
      condition,
      created_by,
      name,
      team_members,
    };

    submitVisibilityGroup(this.type === 'edit', request, this.id).then(this.redirectToTableView);
  };

  render() {
    const { fields, group, name, users, errors } = this.props;
    const { setGroupCondition, setGroupName, setGroupUsers } = this.actions;

    return (
      <Page title={text.TITLE[this.type]} bread={this.crumbs}>
        <FormEl>
          <div className="container-div">
            <Input
              className="name-input"
              label={text.INPUT_LABEL}
              placeholder={text.INPUT_LABEL}
              value={name}
              onChange={setGroupName}
              helpText={errors.name[0]}
              hasError={!!errors.name[0].length}
            />
            <Select
              className="users-select"
              async
              multi
              valueKey="id"
              labelKey="username"
              label={text.SELECT_LABEL}
              placeholder={text.SELECT_PLACEHOLDER}
              value={users}
              onChange={setGroupUsers}
              debounceInterval={500}
              loadOptions={this.getAutocompleteResults}
              arrowRenderer={() => <Icon name="CaretDown" size="small" />}
            />
            {!!fields.length && (
              <QueryBuilder
                className="query-builder"
                fields={fields}
                group={group}
                onStateUpdate={setGroupCondition}
              />
            )}
            <div className="submit-section">
              <Button
                style={{ float: 'right' }}
                onClick={this.submitGroup}
                type="submit"
              >
                {this.type === 'edit' ? text.EDIT_BUTTON_LABEL : text.CREATE_BUTTON_LABEL}
              </Button>
              <Button
                style={{ float: 'right' }}
                onClick={this.redirectToTableView}
                color="ghost"
              >
                {text.DISCARD_BUTTON_LABEL}
              </Button>
            </div>
          </div>
        </FormEl>
      </Page>
    );
  }
}

TeamVisibilityGroup.propTypes = propTypes;
TeamVisibilityGroup.defaultProps = defaultProps;

export default TeamVisibilityGroup;
