import React, { Component } from 'react';
import { Button, notifier } from 'tc-biq-design-system';
import { object, bool } from 'prop-types';

import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import FormFactory from '../../../../../../../components/form';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import to from '../../../../../../../logic/utilities/to';
import FormPanel from '../../../../../../../components/form/Components/FormPanel/FormPanel';


const text = {
  MINIMAL: gettext('Minimal allowed balance for withdraw'),
  ENABLE_COMMISSION: gettext('Enable Sub IB Commission'),
  SAVE: gettext('Save'),
  SUCCESS: gettext('Saved commission payment settings'),
};

export const FORM_ID = 'MINIMAL_WITHDRAWAL_BALANCE_FORM';

const formModifiers = {
  min_withdrawal_amount: {
    label: null,
  },
};

const api = id => getDjangoApi(`ib/${id}/payment_settings`);

const propTypes = {
  user: object.isRequired,
  submitInProgress: bool.isRequired,
  actions: object.isRequired,
};

class BalanceForm extends Component {
  onSubmit = async () => {
    const { actions, user } = this.props;
    const [err, data] = await to(actions.create(api(user.id)));
    err ? this.onError(err) : this.onSuccess(data); 
  }

  onError = (payload) => {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  onSuccess = (data) => {
    const { value: { response } } = data;
    this.setValues(response);
    notifier.success(text.SUCCESS);
  }

  setValues = (data) => {
    const { actions } = this.props;
    Object.keys(data)
      .map(key => actions.setFieldValue({
        id: key,
        value: data[key],
      }));
  }

  formConfig = () => {
    const { user } = this.props;
    return {
      form: FORM_ID,
      api: api(user.id),
    };
  }

  fetchInitialPaymentSettings = () => {
    const { user } = this.props;
    return api(user.id)
      .retrieve()
      .then(({ data }) => {
        this.setValues(data);
      });
  }

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const Form = this.renderForm();
    const { submitInProgress } = this.props;

    return (
      <Form 
        modifiers={formModifiers} 
        onFormReady={this.fetchInitialPaymentSettings}
        renderForm={formFields => (
          <FormPanel
            height="none"
            size="full"
          >
            <FormPanel.Section>
              <div className="allowed-balance">
                <div className="allowed-balance__label">
                  {text.MINIMAL}
                </div>
                <div className="allowed-balance__amount">
                  {formFields.min_withdrawal_amount}
                </div>
                <div className="allowed-balance__checkbox">
                  {formFields.subib_commission}
                </div>
                <div className="allowed-balance__button">
                  <Button 
                    onClick={this.onSubmit}
                    loading={submitInProgress}
                  >
                    {text.SAVE}
                  </Button>
                </div>
              </div>
            </FormPanel.Section>
          </FormPanel>
        )} 
      />
    );
  }
}


BalanceForm.propTypes = propTypes;

export default BalanceForm;
