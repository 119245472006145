import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import Team from './Team';
import connect from '../../../../logic/connect';
import { loadTeam, updateTeam } from './Model';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const mapStateToProps = ({ pages }) => ({
  ...pages.TEAM.team,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    loadTeam,
    updateTeam,
  }, dispatch),
});

const TeamPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(Team); 


export default {
  component: TeamPage,
  aclId: 'teams.retrieve',
  path: appRoutes.TEAM,
};

