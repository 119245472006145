import React, { PureComponent } from 'react';
import { object } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../logic/connect';
import overlayActions from '../../overlay';

const withUnmountMethod = (WrappedComponent) => {
  const propTypes = {
    actions: object.isRequired,
  };

  class Unmount extends PureComponent {
    componentWillUnmount() {
      const { props } = this;
      props.actions.resetSidepanels();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  Unmount.propTypes = propTypes;

  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
      { resetSidepanels: overlayActions.resetState },
      dispatch,
    ),
  });

  return connect(null, mapDispatchToProps)(Unmount);
};

export default withUnmountMethod;
