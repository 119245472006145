import React, { useState, useCallback } from 'react';
import { Space, Button, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';

import Page from '../../../../../../../components/Page';
import FormPanel from '../../../../../../../components/form/Components/FormPanel';
import { FormActionsFactory } from '../../../../../../../components/form';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import connect from '../../../../../../../logic/connect';
import to from '../../../../../../../logic/utilities/to';
import { requestFieldFormatter, text, bread, useStateRuleForm, state } from './helper';
import appRoutes from '../../../../../../../components/App/Router/appRoutes';

const { create, setFieldsErrors, update, setFieldValue } = FormActionsFactory('STATE_RULES');

const api = getDjangoApi('deposit_state_rules');

const formConfig = {
  form: 'STATE_RULES',
  excludeFields: [],
  api,
};

const formModifiers = {
  timeframe: {
    type: 'timeframe',
  },
};

const propTypes = {
  actions: object.isRequired,
  values: object.isRequired,
  match: object.isRequired,
  history: object.isRequired,
};

const UpdateStateRule = (props) => {
  const { values, actions, match, history } = props;
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const { id } = match.params;
  const type = id ? state.EDIT : state.CREATE;
  const isEdit = type === state.EDIT;

  const handleRequestResult = (res, err, message) => {
    setSubmitInProgress(false);
    if (res) {
      notifier.success(message);
      goToSettingsPage();
      return;
    }
    if (err && err.data.non_field_errors) {
      notifier.error(err.data.non_field_errors[0]);
      return;
    }
    actions.setFieldsErrors({ ...(err ? err.data : {}) });
  };

  const editRule = useCallback(async () => {
    setSubmitInProgress(true);
    const [err, res] = await to(actions.update(api, id, requestFieldFormatter, true));
    setSubmitInProgress(false);
    handleRequestResult(res, err, text.SUCCESS_EDIT);
  }, [id]);

  const createRule = useCallback(async () => {
    actions.setFieldsErrors({});
    setSubmitInProgress(true);
    const [err, res] = await to(actions.create(api, requestFieldFormatter));
    handleRequestResult(res, err, text.SUCCESS_CREATE);
  }, [props, values, values.name, values.method, values.timeframe, actions, setSubmitInProgress, handleRequestResult]);

  const goToSettingsPage = useCallback(() => history.push(appRoutes.SETTINGS_DEPOSITS), [history]);

  const handleSubmit = useCallback(() => {
    isEdit ? editRule() : createRule();
  }, [isEdit]);

  const StateRuleForm = useStateRuleForm(formConfig);

  const preFillForm = useCallback(() => {
    if (isEdit && id) {
      setSubmitInProgress(true);
      api.retrieve(id)
        .then((res) => {
          const { data } = res;
          Object.keys(data).forEach((key) => {
            if (data[key]) actions.setFieldValue({ id: key, value: data[key] });
          });
        })
        .catch(() => {
          notifier.error(text.ERROR);
        })
        .finally(() => setSubmitInProgress(false));
    } else {
      const defaultTimeframeApi = getDjangoApi('deposit_state_rules/default_timeframe_milliseconds');
      defaultTimeframeApi.retrieve()
        .then(({ data }) => actions.setFieldValue({ id: 'timeframe', value: data.default_timeframe_milliseconds }));
    }
  }, [id, isEdit, actions, setSubmitInProgress]);

  const renderForm = useCallback(fields => (
    <React.Fragment>
      { fields.name }
      { !isEdit && fields.method }
      { fields.timeframe }
    </React.Fragment>
  ), [isEdit]);

  return (
    <Page title={text.TITLE[type]} bread={bread(type)}>
      <Space size={24} />
      <FormPanel>
        <FormPanel.Section>
          <StateRuleForm renderForm={renderForm} modifiers={formModifiers} onFormReady={preFillForm} />
        </FormPanel.Section>
        <FormPanel.Submit>
          <Button onClick={goToSettingsPage}>{ text.DISCARD }</Button>
          <Button loading={submitInProgress} disable={submitInProgress} onClick={handleSubmit}>{ isEdit ? text.EDIT : text.CREATE }</Button>
        </FormPanel.Submit>
      </FormPanel>
    </Page>
  );
};

const mapStateToProps = ({ forms }) => ({
  ...forms.STATE_RULES,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    create,
    setFieldsErrors,
    setFieldValue,
    update,
  }, dispatch),
});

UpdateStateRule.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateStateRule));
