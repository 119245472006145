import React, { Fragment, useCallback, useMemo } from 'react';
import { string, func, number, oneOfType, bool } from 'prop-types';
import { TimeDelay, Space } from 'tc-biq-design-system';

export const DELAY_TYPE_OPTIONS = {
  minutes: { display_name: 'Minutes', value: 'minutes' },
  hours: { display_name: 'Hours', value: 'hours' },
  days: { display_name: 'Days', value: 'days' },
  months: { display_name: 'Months', value: 'months' },
  years: { display_name: 'Years', value: 'years' },
};

const DEFAULT_OPTIONS = {
  delay: 0,
  delayType: DELAY_TYPE_OPTIONS.minutes,
  delayTypeOptions: Object.values(DELAY_TYPE_OPTIONS),
};

export const timeframeModelToMilliseconds = (timeframeModel) => {
  const { delay, type } = timeframeModel;
  if (!delay && delay !== 0) return null;
  switch (type.value) {
    case DELAY_TYPE_OPTIONS.minutes.value: return delay * 60 * 1000;
    case DELAY_TYPE_OPTIONS.hours.value: return delay * 60 * 60 * 1000;
    case DELAY_TYPE_OPTIONS.days.value: return delay * 60 * 60 * 24 * 1000;
    case DELAY_TYPE_OPTIONS.months.value: return delay * 60 * 60 * 24 * 30 * 1000;
    case DELAY_TYPE_OPTIONS.years.value: return delay * 60 * 60 * 24 * 365 * 1000;
    default: return null;
  }
};

export const millisecondsToTimeframeModel = (milliseconds) => {
  const result = {
    delay: 0,
    type: DELAY_TYPE_OPTIONS.minutes,
  };
  const durationAsMinutes = moment.duration(milliseconds).asMinutes();
  if (durationAsMinutes) {
    if (durationAsMinutes % (60 * 24 * 365) === 0) {
      result.delay = durationAsMinutes / (60 * 24 * 365);
      result.type = DELAY_TYPE_OPTIONS.years;
    } else if (durationAsMinutes % (60 * 24 * 30) === 0) {
      result.delay = durationAsMinutes / (60 * 24 * 30);
      result.type = DELAY_TYPE_OPTIONS.months;
    } else if (durationAsMinutes % (60 * 24) === 0) {
      result.delay = durationAsMinutes / (60 * 24);
      result.type = DELAY_TYPE_OPTIONS.days;
    } else if (durationAsMinutes % 60 === 0) {
      result.delay = durationAsMinutes / 60;
      result.type = DELAY_TYPE_OPTIONS.hours;
    } else {
      result.delay = durationAsMinutes;
      result.type = DELAY_TYPE_OPTIONS.minutes;
    }
  }
  return result;
};


const propTypes = {
  id: string.isRequired,
  onChange: func.isRequired,
  customOnChange: func,
  index: number,
  value: oneOfType(number, string),
  label: string.isRequired,
  helpText: string,
  hasError: bool,
};

const defaultProps = {
  value: 0,
  customOnChange: null,
  index: null,
  helpText: null,
  hasError: false,
};

const TimeFrame = (props) => {
  const { onChange, value, id, label, customOnChange, index, hasError, helpText } = props;
  const delayModel = useMemo(() => millisecondsToTimeframeModel(value), [value]);

  const handleDelayChanges = useCallback((delay) => {
    const value = timeframeModelToMilliseconds({ ...delayModel, delay });
    (customOnChange || onChange)({ value, id }, index);
  }, [onChange, customOnChange, delayModel]);

  const handleDelayTypeChanges = useCallback((type) => {
    const value = timeframeModelToMilliseconds({ ...delayModel, type });
    (customOnChange || onChange)({ value, id }, index);
  }, [onChange, customOnChange, delayModel]);

  return (
    <Fragment>
      <TimeDelay
        label={label}
        delay={delayModel.delay}
        delayType={delayModel.type}
        delayTypeOptions={DEFAULT_OPTIONS.delayTypeOptions}
        setDelay={handleDelayChanges}
        setDelayType={handleDelayTypeChanges}
        hasError={hasError}
        helpText={helpText}
      />
      <Space size={12} />
    </Fragment>
  );
};

TimeFrame.propTypes = propTypes;
TimeFrame.defaultProps = defaultProps;

export default TimeFrame;
