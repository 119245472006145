
import { headers, getFullUrl } from './api-factory';

export default class Uploader {
  constructor(url) {
    this.url = getFullUrl(url);
    this.headers = _.omit(headers, 'content-type');
    this.controller = new AbortController();
  }

  upload(formData = {}) {
    const body = new FormData();
    _.forIn(formData, (value, key) => {
      body.append(key, value);
    });

    const options = {
      method: 'POST',
      body,
      signal: this.controller.signal,
      headers: _.omit(headers, 'content-type'),
    };

    return fetch(this.url, options);
  }

  abort() {
    this.controller.abort();
  }
}
