import React from 'react';
import { string, func, bool, oneOfType, any } from 'prop-types';
import { Toggle } from 'tc-biq-design-system';

const propTypes = {
  id: string.isRequired,
  onChange: func.isRequired,
  reverse: bool,
  value: oneOfType([bool, string]),
  label: string,
  actionComponent: any,
};

const defaultProps = {
  reverse: false,
  value: false,
  label: null,
  actionComponent: null,
};

const TOGGLE_FIELD_STYLE = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const ToggleField = (props) => {
  const { onChange, id, reverse, label, value, actionComponent } = props;
  return (
    <div className="biq-form__field">
      <div style={TOGGLE_FIELD_STYLE} className="biq-toggle-wrapper">
        {!reverse && label}
        <Toggle 
          {...props} 
          checked={!!value}
          onChange={value => onChange({ value, id })} 
        />
        {!!reverse && label}
      </div>
      {actionComponent}
    </div>
  );
};

ToggleField.defaultProps = defaultProps;
ToggleField.propTypes = propTypes;
export default ToggleField;
