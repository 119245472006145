import React from 'react';
import { bindActionCreators } from 'redux';
import { func, object } from 'prop-types';

import SendNoteForm from '../../../../../../../components/common/SendNoteForm/SendNoteForm';
import { FormActionsFactory } from '../../../../../../../components/form';
import connect from '../../../../../../../logic/connect';
import { getTableActions, tableConfig } from '../../../Sections/Notes/table';

const FORM_KEY = 'SEND_NOTE_FORM';

const { create } = FormActionsFactory(FORM_KEY);

const propTypes = {
  actions: object.isRequired,
  closeCombox: func,
  dispatch: func.isRequired,
  table: object.isRequired,
};

const defaultProps = {
  closeCombox: null,
};

const SendNote = ({ actions, closeCombox, table, dispatch, ...rest }) => {
  const onSuccess = () => {
    closeCombox && closeCombox();
    const tableAction = getTableActions(rest.user.id);
    dispatch(tableAction.fetchTableData(table.query));
  };
  
  return (
    <SendNoteForm 
      {...rest} 
      onSuccess={onSuccess} 
      createNote={actions.create} 
      formKey={FORM_KEY} 
    />
  );
};

SendNote.propTypes = propTypes;
SendNote.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      create,
    },
    dispatch,
  ),
  dispatch,
});

const mapStateToProps = ({ forms, pages }) => {
  const { reducerKey, tableKey } = tableConfig();
  const form = forms[FORM_KEY];
  const page = pages[reducerKey];
  const table = page.tables[tableKey];

  return {
    table,
    submitInProgress: form.submitInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendNote);
