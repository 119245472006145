import React, { PureComponent } from 'react';
import { InfoBox, Input, Select, Button } from 'tc-biq-design-system';
import { object, array, string, bool } from 'prop-types';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import Page from '../../../../../components/Page';
import QueryBuilder from '../../../../../components/QueryBuilder';
import getID from '../../../../../logic/utilities/getID';
import { refactoredExtractGroup } from '../../../../../logic/services/query-adapter';
import appRoutes from '../../../../../components/App/Router/appRoutes';

import './WithdrawalMethodRule.scss';
import FormEl from '../../../../../components/form/Components/FormEl';

const text = {
  TITLE: {
    create: gettext('Create New Withdrawal Step'),
    edit: gettext('Edit Withdrawal Step'),
    clone: gettext('Create New Withdrawal Step'),
  },
  INFOBOX_HEADING: gettext('You can only set up one rule for each method.'),
  INPUT_LABEL: gettext('Name'),
  SELECT_LABEL: gettext('Withdrawal method'),
  WITHDRAWALS_SETTINGS_CRUMB: gettext('Withdrawal method rules'),
  CREATE_BUTTON_LABEL: gettext('Create'),
  UPDATE_BUTTON_LABEL: gettext('Update'),
  DISCARD_BUTTON_LABEL: gettext('Discard'),
};

const propTypes = {
  actions: object.isRequired,
  submitInProgress: bool,
  fields: array,
  methods: array,
  selectedMethod: object,
  name: string,
  group: object,
  errors: object,
  match: object.isRequired,
  history: object.isRequired,
};

const defaultProps = {
  submitInProgress: false,
  fields: [],
  methods: [],
  selectedMethod: null,
  name: '',
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  errors: {
    name: [''],
    method: [''],
    condition: [''],
  },
};

class WithdrawalMethodRule extends PureComponent {
  constructor(props) {
    super(props);

    this.actions = props.actions;

    const pathRoutes = getID();

    if (_.includes(pathRoutes, 'add')) this.type = 'create';
    if (_.includes(pathRoutes, 'edit')) {
      this.type = 'edit';
      this.id = props.match.params.id;
    }
    if (_.includes(pathRoutes, 'clone')) {
      this.type = 'clone';
      this.id = props.match.params.id;
    }

    this.title = text.TITLE[this.type];

    this.crumbs = [
      { label: text.WITHDRAWALS_SETTINGS_CRUMB, route: appRoutes.SETTINGS_WITHDRAWALS },
      { label: this.title, route: '' },
    ];
  }

  componentDidMount() {
    const { getFieldOptions, getWithdrawalInfo } = this.actions;
    getFieldOptions().then(() => {
      if (this.id) getWithdrawalInfo(this.id);
    });
  }

  componentWillUnmount() {
    this.actions.resetState();
  }

  redirectToTableView = () => {
    const { history } = this.props;
    history.push(appRoutes.SETTINGS_WITHDRAWALS);
  };

  submitMethodRule = () => {
    const { name, selectedMethod, group } = this.props;
    const { submitWithdrawal } = this.actions;
    const condition = refactoredExtractGroup(group) || null;

    const request = {
      name,
      condition,
      ...(selectedMethod && { method: selectedMethod.value }),
    };

    submitWithdrawal(this.type === 'edit', request, this.id)
      .then(this.redirectToTableView);
  };

  render() {
    const { fields, methods, selectedMethod, name, group, errors, submitInProgress } = this.props;
    const { setSelectedMethod, setRuleName, setRuleCondition } = this.actions;

    return (
      <Page title={this.title} bread={this.crumbs}>
        <div className="withdrawal-method-rule__container-div">
          <FormEl>
            <InfoBox header={text.INFOBOX_HEADING} />
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
              <Input
                label={text.INPUT_LABEL}
                style={{ width: '100%', marginRight: '14px' }}
                placeholder={text.INPUT_LABEL}
                value={name}
                onChange={setRuleName}
                helpText={errors.name[0]}
                hasError={!!errors.name[0].length}
              />
              <div style={{ width: '100%' }}>
                <Select
                  label={text.SELECT_LABEL}
                  placeholder={text.SELECT_LABEL}
                  options={methods}
                  labelKey="display_name"
                  onChange={setSelectedMethod}
                  value={selectedMethod}
                  helpText={errors.method[0]}
                  hasError={!!errors.method[0].length}
                />
              </div>
            </div>
            <QueryBuilder
              group={group}
              onStateUpdate={setRuleCondition}
              fields={fields}
              className="withdrawal-method-rule__query-builder"
            />
            <div className="withdrawal-method-rule__submit-section">
              <Button
                type="submit"
                style={{ float: 'right' }}
                onClick={this.submitMethodRule}
                disabled={submitInProgress}
              >
                {this.type !== 'edit' ? text.CREATE_BUTTON_LABEL : text.UPDATE_BUTTON_LABEL}
              </Button>
              <Button
                style={{ float: 'right' }}
                onClick={this.redirectToTableView}
                color="ghost"
              >
                {text.DISCARD_BUTTON_LABEL}
              </Button>
            </div>
          </FormEl>
        </div>
      </Page>
    );
  }
}

WithdrawalMethodRule.propTypes = propTypes;
WithdrawalMethodRule.defaultProps = defaultProps;

export default WithdrawalMethodRule;
