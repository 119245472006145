import React, { PureComponent } from 'react';
import { object, string, array, bool, number, shape, func } from 'prop-types';
import { Input, Toggle, Select, Button, TimeDelay, notifier } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';
import { generatePath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Page from '../../../../../components/Page';
import QueryBuilder from '../../../../../components/QueryBuilder';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import getID from '../../../../../logic/utilities/getID';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { refactoredExtractGroup } from '../../../../../logic/services/query-adapter';
import { convertToMilliseconds, time } from '../../../../../logic/utilities/timeDuration';
import appRoutes from '../../../../../components/App/Router/appRoutes';
import { emailEventActions } from './Model';

import './EmailEvent.scss';
import FormEl from '../../../../../components/form/Components/FormEl';

const FORM_ID = 'EMAIl_EVENT_FORM';

const delayTypeOptions = [
  { display_name: gettext('Minutes'), value: time.MINUTES },
  { display_name: gettext('Hours'), value: time.HOURS },
  { display_name: gettext('Days'), value: time.DAYS },
];

const text = {
  TITLE_PARENT: gettext('Email events'),
  TITLE: {
    create: gettext('Create new email event'),
    clone: gettext('Clone email event'),
    edit: gettext('Edit email event'),
  },
  NAME_INPUT_LABEL: gettext('Name'),
  UPDATE_BUTTON_LABEL: gettext('Update'),
  CREATE_BUTTON_LABEL: gettext('Create'),
  DISCARD_BUTTON_LABEL: gettext('Discard'),
  DELAY_INPUT_LABEL: gettext('Delay'),
  BODY_TEMPLATE_SELECT_LABEL: gettext('Body template'),
  BASE_GROUP_SELECT_LABEL: gettext('Base group'),
  EVENT_TRIGGER_SELECT_LABEL: gettext('Event trigger'),
  SNIPPET_SELECT_LABEL: gettext('Snippet'),
  ADD_DELAY_BUTTON_LABEL: gettext('Add delay'),
  DELAY_TYPE_PLACEHOLDER: gettext('Choose time '),
};

const propTypes = {
  actions: object.isRequired,
  group: object,
  fields: array,
  name: string,
  baseGroup: object,
  baseGroupChoices: array,
  bodyTemplate: object,
  bodyTemplateChoices: array,
  eventTrigger: object,
  eventTriggerChoices: array,
  isEnabled: bool,
  hasDelay: bool,
  delay: number,
  delayType: object,
  errors: object,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  match: object.isRequired,
};

const defaultProps = {
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  fields: [],
  name: '',
  baseGroup: null,
  baseGroupChoices: [],
  bodyTemplate: null,
  bodyTemplateChoices: [],
  eventTrigger: null,
  eventTriggerChoices: [],
  isEnabled: true,
  hasDelay: false,
  delay: 0,
  delayType: delayTypeOptions[0],
  errors: {
    name: [''],
    event: [''],
  },
};

class EmailEvent extends PureComponent {
  constructor(props) {
    super(props);

    this.actions = props.actions;
    const pathRoutes = getID();

    if (_.includes(pathRoutes, 'add-new')) {
      this.type = pathRoutes[pathRoutes.length - 1] === 'add-new' ? 'create' : 'clone';
    } else {
      this.type = 'edit';
    }

    if (this.type !== 'create') this.id = props.match.params.id;

    this.crumbs = [
      { label: text.TITLE_PARENT, route: appRoutes.EMAIL_EVENTS },
      { label: text.TITLE[this.type] },
    ];
  }

  componentDidMount() {
    const { getEventOptions, getOtherFieldsOptions, getEventInfo, setEventTrigger } = this.actions;
    const eventOptionsPromise = getEventOptions();
    const otherFieldOptionsPromise = getOtherFieldsOptions();
    Promise.all([eventOptionsPromise, otherFieldOptionsPromise]).then(() => {
      if (this.type !== 'create') {
        getEventInfo(this.id).then(() => {
          const { eventTrigger } = this.props;
          setEventTrigger(eventTrigger, false);
        });
      }
    });
  }

  componentWillUnmount() {
    this.actions.resetState();
  }

  displayError = (field) => {
    const { errors } = this.props;
    const error = _.get(errors[field], '[0]'); 
    return error ? {
      hasError: !!error,
      helpText: error,
    } : {};
  };

  submit = () => {
    const {
      name,
      group,
      isEnabled,
      baseGroup,
      eventTrigger,
      delay,
      delayType,
      bodyTemplate,
    } = this.props;

    const { submitEvent } = this.actions;
    const condition = refactoredExtractGroup(group);
    const event = eventTrigger ? eventTrigger.value : null;
    const delayInMilliseconds = convertToMilliseconds(+delay, delayType.value);
    const base_group = _.get(baseGroup, 'value');
    const template = _.get(bodyTemplate, 'value');

    const action_params = {
      ...(base_group ? { base_group } : {}),
      ...(template ? { template } : {}),
      // https://tradecrowd.atlassian.net/browse/BROKERIQ-137 as per this ticket action_params.user
      // should apparently always be {user.id}. I'll try to remove it from the payload to backend
      // since it looks redundant here if it's hardcoded like this
      user: '{user.id}',
    };

    const request = {
      action: 'send_email_user',
      name,
      condition,
      event,
      delay: delayInMilliseconds,
      is_enabled: isEnabled,
      action_params,
    };

    submitEvent(this.type === 'edit', request, this.id).then(this.redirectToTable, this.onError);
  };

  onError = (errors) => {
    const nonFieldErrors = _.get(errors, 'data.non_field_errors');
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(errors, 'data');
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  redirectToTable = () => {
    const { history } = this.props;
    history.push(generatePath(appRoutes.EMAIL_EVENTS));
  };

  changeEventTrigger = (trigger) => {
    const { setEventTrigger } = this.actions;
    setEventTrigger(trigger, true);
  };

  render() {
    const {
      group,
      fields,
      name,
      eventTrigger,
      eventTriggerChoices,
      isEnabled,
      baseGroup,
      baseGroupChoices,
      bodyTemplate,
      bodyTemplateChoices,
      delay,
      delayType,
      hasDelay,
    } = this.props;

    const {
      setEventCondition,
      setEventName,
      setIsEventEnabled,
      setEventBaseGroup,
      setEventBodyTemplate,
      toggleHasDelay,
      setEventDelay,
      setEventDelayType,
    } = this.actions;
    
    return (
      <Page title={text.TITLE[this.type]} bread={this.crumbs}>
        <FormEl id={FORM_ID}>
          <div className="email-event__container-div">
            <div className="email-event__first-row-container">
              <Input
                style={{ width: '60%', marginRight: '14px' }}
                label={text.NAME_INPUT_LABEL}
                placeholder={text.NAME_INPUT_LABEL}
                value={name}
                onChange={setEventName}
                {...this.displayError('name')}
              />
              {!hasDelay && (
                <Button
                  style={{ width: '20%', marginTop: '22px', marginBottom: '0', height: '100%' }}
                  onClick={toggleHasDelay}
                >
                  {text.ADD_DELAY_BUTTON_LABEL}
                </Button>
              )}
              {hasDelay && (
                <TimeDelay
                  className="email-event__time-delay"
                  label={text.DELAY_INPUT_LABEL}
                  delay={delay}
                  delayType={delayType}
                  delayTypeOptions={delayTypeOptions}
                  toggleHasDelay={toggleHasDelay}
                  setDelay={setEventDelay}
                  setDelayType={setEventDelayType}
                />
              )}
              <div className="email-event__toggle-container">
                <span>State</span>
                <div style={{ paddingTop: '15%' }}>
                  <Toggle checked={isEnabled} onClick={setIsEventEnabled} />
                </div>
              </div>
            </div>
            <div className="email-event__second-row-container">
              <Select
                label={text.BASE_GROUP_SELECT_LABEL}
                className="email-event__base-group-select"
                value={baseGroup}
                options={baseGroupChoices}
                onChange={setEventBaseGroup}
                labelKey="display_name"
              />
              <Select
                label={text.BODY_TEMPLATE_SELECT_LABEL}
                className="email-event__body-template-select"
                labelKey="display_name"
                value={bodyTemplate}
                options={bodyTemplateChoices}
                onChange={setEventBodyTemplate}
                {...this.displayError('template')}
              />
            </div>
            <div className="email-event__third-row-container">
              <Select
                label={text.EVENT_TRIGGER_SELECT_LABEL}
                className="email-event__event-trigger-select"
                labelKey="display_name"
                value={eventTrigger}
                options={eventTriggerChoices}
                onChange={this.changeEventTrigger}
                {...this.displayError('event')}
              />
            </div>
            {eventTrigger && (
              <QueryBuilder
                group={group}
                fields={fields}
                onStateUpdate={setEventCondition}
                className="email-event__query-builder"
              />
            )}
            <div className="email-event__submit-section">
              <Button style={{ float: 'right' }} type="submit" form={FORM_ID} onClick={this.submit}>
                {this.type === 'edit' ? text.UPDATE_BUTTON_LABEL : text.CREATE_BUTTON_LABEL}
              </Button>
              <Button style={{ float: 'right' }} color="ghost" onClick={this.redirectToTable}>
                {text.DISCARD_BUTTON_LABEL}
              </Button>
            </div>
          </div>
        </FormEl>
      </Page>
    );
  }
}

EmailEvent.propTypes = propTypes;
EmailEvent.defaultProps = defaultProps;

const mapStateToProps = ({ pages }) => {
  const page = pages.EMAIL_EVENT_SINGLE_PAGE;
  return { ...page };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...emailEventActions }, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(EmailEvent); 
