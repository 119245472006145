import React from 'react';
import { Detail } from 'tc-biq-design-system';
import { number, string } from 'prop-types';

import { gettext } from '../../../../../../logic/utilities/languageUtility';

const text = {
  CODE: gettext('Code'),
  NAME: gettext('Name'),
  VALUE: gettext('Value'),
  NA: gettext('N/A'),
};

const propTypes = {
  code: string,
  label: string,
  priority: number,
  value: string,
};

const defaultProps = {
  code: '',
  label: '',
  priority: undefined,
  value: '',
};

const IdentityCode = ({ code, label, priority, value }) => (
  <div className="biq-mifid__identity-code">
    <span className="biq-mifid__identity-priority tc-paragraph-regular">{priority || text.NA}</span>
    <Detail reverse entity={text.CODE} value={code || text.NA} />
    <Detail reverse entity={text.NAME} value={label || text.NA} />
    <Detail reverse entity={text.VALUE} value={value || text.NA} />
  </div>
);

IdentityCode.propTypes = propTypes;
IdentityCode.defaultProps = defaultProps;
export default IdentityCode;
