import { createAction, handleActions } from 'redux-actions';

const actionTypes = {
  FETCH_OPTIONS: 'FETCH_OPTIONS',
  FETCH_OPTIONS_PENDING: 'FETCH_OPTIONS_PENDING',
  FETCH_OPTIONS_FULFILLED: 'FETCH_OPTIONS_FULFILLED',
  INPUT_CHANGE: 'INPUT_CHANGE',
  SUBMIT: 'SUBMIT',
  SUBMIT_PENDING: 'SUBMIT_PENDING',
  SUBMIT_REJECTED: 'SUBMIT_REJECTED',
  SUBMIT_FULFILLED: 'SUBMIT_FULFILLED',
};

const transformChoices = choices => choices.map(({ display_name, value }) => ({
  id: value,
  label: display_name,
}));

export const fetchOptions = createAction(actionTypes.FETCH_OPTIONS, async (api) => {
  const { data } = await api.options();
  const fields = _.get(data, 'actions.POST.fields');
  const fromOptions = _.find(fields, { key: 'account_to' }).choices;
  const toOptions = _.find(fields, { key: 'account_from' }).choices;

  return { fromOptions: transformChoices(fromOptions), toOptions: transformChoices(toOptions) };
});

export const onInputChange = createAction(actionTypes.INPUT_CHANGE, (field, value) => ({
  field, 
  value,
}));

export const submit = createAction(actionTypes.SUBMIT, async (api, data) => {
  await api.create(data);
});

const initialState = {
  formData: {
    amount: '',
    account_from: {},
    account_to: {},
  },
  accounts: {
    fromOptions: [],
    toOptions: [],
  },
  submitInProgress: false,
  errors: {},
};

const onOptionsSuccess = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  accounts: payload,
});

const handleInputChange = (state, { payload }) => ({
  ...state,
  formData: {
    ...state.formData,
    [payload.field]: payload.value,
  },
});

const onReject = (state, { payload }) => ({ ...state, errors: payload.data, submitInProgress: false });

export default handleActions(
  {
    [actionTypes.FETCH_OPTIONS_PENDING]: state => ({ ...state, submitInProgress: true }),
    [actionTypes.FETCH_OPTIONS_FULFILLED]: onOptionsSuccess,
    [actionTypes.INPUT_CHANGE]: handleInputChange,
    [actionTypes.SUBMIT_PENDING]: state => ({ ...state, submitInProgress: true }),
    [actionTypes.SUBMIT_FULFILLED]: state => ({ ...state, submitInProgress: false }),
    [actionTypes.SUBMIT_REJECTED]: onReject,
  },
  initialState,
);
