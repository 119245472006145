import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import appRoutes from '../../../../../../components/App/Router/appRoutes';
import { FormActionsFactory } from '../../../../../../components/form';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import CancellationRulesForm, { FORM_KEY } from './CancellationRulesForm';
import { actions } from './Model';

const formActions = FormActionsFactory(FORM_KEY);

const mapStateToProps = ({ pages, forms }) => {
  const { cancellationRule } = pages.BONUS;
  return {
    ...cancellationRule,
    form: { ...forms[FORM_KEY] },
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions, ...formActions }, dispatch),
});

const CampaignPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(CancellationRulesForm); 

export const BonusCancellationRules = {
  component: CampaignPage,
  path: appRoutes.SETTINGS_BONUS_CANCELLATION_RULES,
  aclId: 'settings.bonus_expiry_rules.update',
};


export const BonusCancellationRulesCreate = {
  component: CampaignPage,
  path: appRoutes.SETTINGS_BONUS_CANCELLATION_RULES_CREATE,
  aclId: 'settings.bonus_expiry_rules.create',
};
