import React, { Component, Fragment } from 'react';
import { Chart, Space, DashboardCard } from 'tc-biq-design-system';

import { clientsChartFormatter } from '../../utils/dashboard';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import withFilters from '../../hoc/withFilters';

const size = { 
  x: 0, 
  y: 10, 
  w: 8, 
  h: 7,
  minW: 6, 
  maxW: 12, 
  minH: 7, 
  maxH: 7, 
};

const text = {
  TITLE: gettext('Signups'),
  DESCRIPTION: gettext('Number of signups per day'),
  FULL_USERS_LABEL: gettext('Full users'),
  ALL_USERS_LABEL: gettext('All users'),
  TOTAL_LABEL: gettext('Total'),
};

const api = getDjangoApi('dashboard/users');

class ActiveClients extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      users: [],
    };
  }

  onQueryReady = async (query) => {
    const { data } = await api.list(query);
    
    this.setState({
      users: clientsChartFormatter(data),
    });
  }

  render() {
    const { users } = this.state;
    
    return (
      <DashboardCard
        title={text.TITLE}
        description={text.DESCRIPTION}
      >
        <Fragment>
          <Space size={20} />
          <Chart
            xAxisKey="day"
            height={220}
            interval="preserveStart"
            bars={[
              { key: 'all_users', color: 'primary-600', label: text.ALL_USERS_LABEL },
              { key: 'full_users', color: 'primary-200', label: text.FULL_USERS_LABEL },
              { key: 'total', label: text.TOTAL_LABEL },
            ]}
            data={users}
            valueRenderer={(key, value) => `${key}: ${value}`}
            tickFormatter={value => `${value}`}
            stacked
          />
        </Fragment>
      </DashboardCard>
    );
  }
}

export default {
  component: withFilters(ActiveClients),
  size,
};
