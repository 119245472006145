import React from 'react';
import { bindActionCreators } from 'redux';

import SelectStatus from '../SelectStatus';
import bulkActionsActionFactory from '../../../../../../../components/common/sidepanels/bulk/actions';
import connect from '../../../../../../../logic/connect';
import { updateUser } from '../../../Model';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../logic/services/acl';

const text = {
  SALES_STATUS: gettext('Annual KYC Status'),
  SUCCESS_MESSAGE: gettext('Compliance status successfully updated'),
  PERMISSION_MESSAGE: gettext('You don\'t have permission to delete status.'),
};

const apiSalesStatuses = getDjangoApi('autocomplete/compliance_status');
const apiSalesStatusSubmit = user => getDjangoApi(`users/${user.id}/compliance_status`);

const submitRequest = status => ({
  compliance_status: status && status.name,
});

const { 
  updateData, 
  resetSubmit,
  resetInput,
  submit,
} = bulkActionsActionFactory('CLIENT_EDIT_COMPLIANCE_STATUSES');

const ComplianceStatus = props => (
  <SelectStatus 
    {...props} 
    trans={text}
    salesKey="compliance_status" 
    apiSales={apiSalesStatuses} 
    apiSalesSubmit={apiSalesStatusSubmit}
    submitRequest={submitRequest}
    hasDestroyAccess={hasAccess('compliance_status.destroy')}
    hasUpdateAccess={hasAccess('compliance_status.update')}
  />
);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateData,
      resetSubmit,
      resetInput,
      submit,
      updateUser,
    },
    dispatch,
  ),
});
  
const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const editComplianceStatuses = page.actions.EDIT_COMPLIANCE_STATUSES;
  const { user } = page.userDetail;
  
  return {
    fieldData: editComplianceStatuses.fieldData,
    errors: editComplianceStatuses.errors,
    submitSuccess: editComplianceStatuses.submitSuccess,
    user,
  };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComplianceStatus);
