/* jshint ignore:start */
import { StateCell, OnContentClickCell } from '../../../../components/gridCellRenderers';
import { commonModifiers } from '../common-modifiers'; 

export default actions => ({
  ...commonModifiers(actions.onUserPreview),
  id: {
    cellRendererFramework: OnContentClickCell(actions.previewInSidepanel),
    width: 80,
  },
  position_state: {
    cellRendererFramework: StateCell,
    width: 210,
  },
  rate: {
    width: 200,
  },
});
