import { ActionsCellWrapper } from '../../../../../components/gridCellRenderers';
import hideCols from '../../../../../logic/utilities/hideCols';
import { actionIcons } from '../../../../../components/grid/GridUtils';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';

export default ({ modify, remove }) => {
  const actions = {
    ...(modify && { modify }),
    ...(remove && { remove }),
  };

  const whiteLabelEnabled = isFeatureEnabled()('WHITELABEL');
  return {
    ...hideCols(whiteLabelEnabled ? ['id'] : ['id', 'whitelabel']),
    actions: {
      label: '',
      field: 'actions',
      key: 'actions',
      pinned: 'right',
      width: actions.modify && actions.remove ? 100 : 50,
      hiddenSortIcon: true,
      suppressSizeToFit: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(modify, remove)),
    },
  };
};
