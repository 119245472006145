/* jshint ignore:start */
import { StateCell, OnContentClickCell, UsernameCell } from '../../../components/gridCellRenderers';

export const commonModifiers = onUserPreview => ({
  user: {
    width: 220,
    cellRendererFramework: UsernameCell(onUserPreview),
  },
  side: {
    width: 100,
    cellRendererFramework: StateCell,
  },
});

export const positionModifiers = ({ previewInSidepanel, onUserPreview }) => ({
  ...commonModifiers(onUserPreview),
  id: {
    cellRendererFramework: OnContentClickCell(previewInSidepanel),
    width: 120,
  },
  state: {
    cellRendererFramework: StateCell,
    width: 210,
  },
  open_rate: { width: 200 },
});
