/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { Button, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { generatePath, Link } from 'react-router-dom';

import connect from '../../../../../logic/connect';
import GridFactory from '../../../../../components/grid';
import text from '../text';
import { methodsModifier } from '../modifier';
import { getActiveFilters } from '../../../../../components/grid/GridUtils';
import Filters from '../../../../../components/Filters';
import overlayActions from '../../../../../components/overlay';
import { BiqUserPreview } from '../../../../../components/common/sidepanels';
import If from '../../../../../components/If';
import { hasAccess } from '../../../../../logic/services/acl';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const tableKey = 'METHODS';

const pageConfig = {
  reducerKey: 'WITHDRAWALS',
  apiUrl: 'settings/withdrawal_methods',
  tableKey,
};

const { GridComponent, actions } = GridFactory(pageConfig);

const CreateNewMethod = () => (
  <If condition={hasAccess('settings.manage_withdrawal_methods.create')}>
    <Link to={appRoutes.SETTINGS_WITHDRAWAL_METHODS_CREATE}>
      <Button startPropagation>{text.CREATE_NEW}</Button>
    </Link>
  </If>
);

class Methods extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.tableActions = {
      onDelete: hasAccess('settings.manage_withdrawal_methods.destroy') && this.onDelete.bind(this),
      onModify: hasAccess('settings.manage_withdrawal_methods.update') && this.getModifyUrl,
      onClone: hasAccess('settings.manage_withdrawal_methods.create') && this.getCloneUrl,
      onToggle: hasAccess('settings.manage_withdrawal_methods.update') && this.onToggle.bind(this),
      previewBiqUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  onToggle(row, is_enabled) {
    const { id } = row;

    this.actions.updateRowData(id, { is_enabled })
      .then(() => {
        notifier.success(text.STATE_UPDATE_SUCCESS);
      }, () => {
        notifier.error(text.STATE_UPDATE_ERROR);
      });
  }

  onDelete(data) {
    this.actions.deleteRow(data)
      .then(() => {
        notifier.success(text.DELETE_SUCCESS);
      }, () => {
        notifier.error(text.DELETE_ERROR);
      });
  }

  getModifyUrl({ id }) {
    return generatePath(appRoutes.SETTINGS_WITHDRAWAL_METHODS, { id });
  }
  
  getCloneUrl({ id }) {
    return generatePath(appRoutes.SETTINGS_WITHDRAWAL_METHODS_CLONE, { id });
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, publicSegments, sharedSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, publicSegments, sharedSegments };
    const { onDelete, onModify, onClone } = this.tableActions;
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: methodsModifier(this.tableActions),
    };

    return (
      <Fragment>
        <Filters {...filterProps} />
        <GridComponent
          tableModifier={methodsModifier(this.tableActions)} 
          singleActions={!!(onDelete || onModify || onClone)}
          {...this.props} 
        />
        <BiqUserPreview />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchTableData: actions.fetchTableData, 
    updateRowData: actions.updateRowData, 
    deleteRow: actions.deleteRow,
    updateFilter: actions.updateFilter,
    changeSegment: actions.changeSegment,
    fetchOptions: actions.fetchOptions,
    openSidepanel: overlayActions.open,
  }, dispatch), 
});

export { CreateNewMethod, actions, tableKey };

export default connect(mapStateToProps, mapDispatchToProps)(Methods);
