import React from 'react';
import { Button } from 'tc-biq-design-system';
import { string, func, node } from 'prop-types';

import './ActionItem.scss';

const propTypes = {
  icon: string.isRequired,
  onClick: func.isRequired,
  label: string,
  content: node,
};

const defaultProps = {
  label: '',
  content: null,
};

const ActionItem = ({ icon, label, onClick, content }) => {
  if (content) {
    return content;
  }

  return (
    <div title={label} className="biq-action-item" onClick={onClick}>
      <Button size="extra-small" icon={icon} onClick={onClick}>{label}</Button>
    </div>
  );
};

ActionItem.propTypes = propTypes;
ActionItem.defaultProps = defaultProps;

export default ActionItem;
