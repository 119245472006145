import { arrayOf, object, shape, string, number, array, bool, oneOfType } from 'prop-types';

export const fieldType = shape({
  choices: arrayOf(object),
  key: string.isRequired,
  label: string.isRequired,
  type: string.isRequired,
});

export const segmentType = shape({
  id: oneOfType([number, string]).isRequired,
  available_to_teams: array,
  available_to_users: array,
  created_at: string,
  updated_at: string,
  creator: object,
  fields: arrayOf(string),
  filters: object,
  is_public: bool,
  name: string,
  view: string,
  view_class: string,
});

export const fieldsType = arrayOf(fieldType);

export const segmentsType = shape({
  ownSegments: arrayOf.isRequired,
  sharedSegments: arrayOf.isRequired,
  publicSegments: array.isRequired,
  selectedSegment: segmentType.isRequired,
});

export const filterValueType = oneOfType([string, number, bool]);
