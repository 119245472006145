import { bindActionCreators, compose } from 'redux';

import connect from '../../../../logic/connect';
import Campaigns, { reducerKey, tableConfig } from './Campaigns';
import overlayActions from '../../../../components/overlay';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';
import { hasAccess } from '../../../../logic/services/acl';
import isFeatureEnabled from '../../../../logic/filters/is-feature-enabled';

const mapStateToProps = ({ pages }) => ({
  table: pages[reducerKey].tables[tableConfig().tableKey],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
  dispatch,
});

const CampaignsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(Campaigns); 

export default {
  component: CampaignsPage,
  aclFn: () => hasAccess('team.profile.campaigns.list') && isFeatureEnabled()('MARKETING_CAMPAIGNS'),
  path: appRoutes.CAMPAIGNS,
};
