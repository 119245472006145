import appRoutes from '../../../../../components/App/Router/appRoutes';
import { hasAccess } from '../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';
import PasswordPage from './PasswordPage';

export default {
  component: PasswordPage,
  aclFn: () => isFeatureEnabled()('PASSWORD_POLICY') && hasAccess('settings.password.policy.list'),
  path: appRoutes.SETTINGS_PASSWORD_POLICY,
};
