import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Button, notifier, Icon, Pill, Toggle, Space, Radio } from 'tc-biq-design-system';
import { object, func, bool } from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import moment from 'moment';

import connect from '../../../../../logic/connect';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import Page from '../../../../../components/Page';
import withTable, { GridTab } from '../../../../../components/grid/withTable';
import modifier from './modifier';
import { hasAccess } from '../../../../../logic/services/acl';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import to from '../../../../../logic/utilities/to';
import FormFactory, { FormActionsFactory } from '../../../../../components/form';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import QueryBuilder from '../../../../../components/QueryBuilder';
import { setupFields, decompileFromApi, compileToApi } from '../../../../../logic/services/query-adapter';
import { Field } from '../../../../../components/form/logic/utils';

import './AutomaticFees.scss';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const apiUrl = 'settings/automatic_fees';

const text = {
  LABEL: gettext('Automatic fees'),
  ACTION_TEXT: gettext('Create automatic fee'),
  DELETE_MSG_SUCCESS: gettext('Successfully deleted automatic fee'),
  CREATE_MSG_SUCCESS: gettext('Successfully created automatic fee'),
  EDIT_MSG_SUCCESS: gettext('Successfully edited automatic fee'),
  ACTIVE: gettext('Active'),
  FEE_CONDITIONS_HEADER: gettext('Fee conditions'),
  TIMEFRAME_HEADER: gettext('Timeframe'),
  TIMEFRAME_SUBHEADER: gettext('Time since an action on Trading account after which the automatic fee will be triggered.'),
  RECURRENCE_HEADER: gettext('Recurrence period'),
  RECURRENCE_SUBHEADER: gettext('Frequency of charging the automatic fee'),
  FEE_AMOUNT_HEADER: gettext('Fee amount'),
  FEE_AMOUNT_SUBHEADER: gettext('Fee will be charged in currency of the Trading Account'),
  CANCEL: gettext('Cancel'),
  SAVE: gettext('Save'),
  AND: gettext('AND'),
  TIME_AGO: gettext('Time ago'),
  ADD_CONDITION: gettext('Add condition'),
  TIMEFRAME_FIELD_ERROR: gettext('At least one time condition should be defined'),
};

const bread = [{ label: text.LABEL, route: appRoutes.AUTOMATIC_FEES }];

const AUTOMATIC_FEES = 'AUTOMATIC_FEES';
const formKey = AUTOMATIC_FEES;

const reducerKey = AUTOMATIC_FEES;
const tableConfig = () => ({
  reducerKey,
  apiUrl,
  tableKey: AUTOMATIC_FEES,
});

const api = getDjangoApi(apiUrl);

const timeOptions = [
  [3, gettext('minutes')],
  [5, gettext('minutes')],
  [10, gettext('minutes')],
  [15, gettext('minutes')],
  [20, gettext('minutes')],
  [30, gettext('minutes')],
  [45, gettext('minutes')],    
  [1, gettext('hour')],
  [2, gettext('hour')],
  [3, gettext('hour')],
  [6, gettext('hours')],
  [12, gettext('hours')],
  [1, gettext('day')],
  [2, gettext('days')],
  [1, gettext('week')],
  [2, gettext('weeks')],
  [3, gettext('weeks')],
  [1, gettext('month')],
  [2, gettext('months')],
  [6, gettext('months')],
  [1, gettext('year')],
].map(d => ({
  display_name: `${d[0]} ${d[1]}`,
  value: moment.duration(d[0], d[1]).asSeconds(),
}));

const actionOptions = [
  {
    value: 'time_since_last_deposit',
    display_name: gettext('Last successful deposit'),
  },
  {
    value: 'time_since_last_trade',
    display_name: gettext('Last closed trade'),
  },
];

const formConfig = {
  form: formKey,
  api,
  customFields: [
    {
      id: 'timeago_first',
      type: 'select',
      valueKey: 'value',
      labelKey: 'display_name',
    },
    {
      id: 'timeago_second',
      type: 'select',
      valueKey: 'value',
      labelKey: 'display_name',
    },
    {
      id: 'action_first',
      type: 'select',
      valueKey: 'value',
      labelKey: 'display_name',
    },
    {
      id: 'action_second',
      type: 'select',
      valueKey: 'value',
      labelKey: 'display_name',
    },
  ],
};

function convertTimeframeToSeconds(timeString) {
  const timeValues = timeString.split(' ');
  if (timeValues.length === 2) {
    const hmsArray = timeValues[1].split(':');
    const timeInSeconds = (+timeValues[0]) * 24 * 60 * 60 + (+hmsArray[0] * 60 * 60) + (+hmsArray[1] * 60) + (+hmsArray[2]);
    return timeInSeconds;
  }

  if (timeValues.length === 1) {
    const hmsArray = timeValues[0].split(':');
    const timeInSeconds = (+hmsArray[0] * 60 * 60) + (+hmsArray[1] * 60) + (+hmsArray[2]);
    return timeInSeconds;
  }
}

const { setFieldValue, create, update, setFieldsErrors } = FormActionsFactory(AUTOMATIC_FEES);

const initialQBvalue = { condition: 'AND', rules: [], name: '', created_by: null };

const recurrencePeriod = {
  DAILY: {
    value: 0,
    label: gettext('Daily'),
    name: 'daily',
  },
  WEEKLY: {
    value: 1,
    label: gettext('Weekly'),
    name: 'weekly',
  },
  MONTHLY: {
    value: 2,
    label: gettext('Monthly'),
    name: 'monthly',
  },
};

const feeAmountTypes = {
  FIXED: {
    label: gettext('Fixed'),
    value: 0,
    name: 'fixed',
  },
  PERCENTAGE: {
    label: gettext('Percentage'),
    value: 1,
    name: 'percentage',
  },
};

const loadAdjustementReasons = () => api.options().then(res => res.data.actions.POST.fields.find(f => f.key === 'adjustment_reason').choices.map(choice => ({
  display_name: choice.value,
  value: choice.value,
})));

const formModifiers = {};

class AutomaticFees extends Component {
  constructor(props) {
    super(props);
    
    this.state = { 
      isEditMode: false,
      feeId: null,
      isCreateMode: false,
      feeConditionsFields: [],
      showSecondTimeAgo: false,
    };
    this.Form = null;
    this.tableData = withTable(GridTab, tableConfig, null, { withActions: true })();
    this.actions = {
      toggleIsEnabled: this.toggleIsEnabled,
      ...(hasAccess('settings.automatic_fees.update') && { modify: this.modify }),
      ...(hasAccess('settings.automatic_fees.destroy') && { remove: this.remove }),
    };
  }

  componentDidMount() {
    this.fetchQueryBuilderSpec();
  }

  componentDidUpdate(prevProps) {
    const { isLoading } = this.props;
    const { isLoading: prevIsLoading } = prevProps;
    const { isCreateMode, isEditMode, feeId } = this.state;

    if (prevIsLoading && !isLoading && isCreateMode) {
      this.loadInitialData();            
    }

    if (prevIsLoading && !isLoading && isEditMode) {
      this.loadFee(feeId);
    }
  }

  onChangeField = (id, value) => {
    const { actions } = this.props;
    actions.setFieldValue({
      id,
      value,
    });
  };

  onCancel = () => {
    this.setState({
      isEditMode: false,
      isCreateMode: false,
      feeId: false,
    });
    this.deleteTimeago();
  }

  loadInitialData = () => {
    const { actions } = this.props;
    const { feeConditionsFields } = this.state;

    actions.setFieldValue({
      id: 'is_enabled',
      value: true,
    });
    actions.setFieldValue({
      id: 'recurrence_period',
      value: recurrencePeriod.DAILY.value,
    });
    actions.setFieldValue({
      id: 'fee_type',
      value: feeAmountTypes.FIXED.value,
    });
    actions.setFieldValue({
      id: 'fee_conditions',
      value: decompileFromApi(feeConditionsFields, initialQBvalue),
    });
  }

  prefillForm = (fee, adjustmentReasons) => {
    const { actions } = this.props;
    const { feeConditionsFields } = this.state;
    const adjustmentReason = adjustmentReasons.find(({ value }) => value === fee.adjustment_reason);

    const timeframeKeys = Object.keys(fee)
      .filter(key => key === 'time_since_last_deposit' || key === 'time_since_last_trade')
      .filter(key => !!fee[key]);

    timeframeKeys.forEach((timeframeKey, index) => {
      actions.setFieldValue({
        id: index === 0 ? 'action_first' : 'action_second',
        value: actionOptions.find(option => option.value === timeframeKey),
      });
      actions.setFieldValue({
        id: index === 0 ? 'timeago_first' : 'timeago_second',
        value: timeOptions.find(option => option.value === convertTimeframeToSeconds(fee[timeframeKey])),
      });
    });

    Object.keys(fee).forEach((key) => {
      if (key === 'fee_amount' || key === 'fee_limit') {
        actions.setFieldValue({
          id: key,
          value: +fee[key],
        });
      } else if (key === 'fee_conditions') {
        actions.setFieldValue({
          id: key,
          value: decompileFromApi(feeConditionsFields, fee[key] || initialQBvalue),
        });
      } else if (key === 'adjustment_reason') {
        actions.setFieldValue({
          id: key,
          value: adjustmentReason,
        });
      } else if (key !== 'time_since_last_deposit' && key !== 'time_since_last_trade') {
        actions.setFieldValue({
          id: key,
          value: fee[key],
        });
      } 
    });

    if (timeframeKeys.length === 2) {
      this.setState({
        showSecondTimeAgo: true,
      });
    }
  };  

  loadFee = (feeId) => {
    const promises = [api.retrieve(feeId), loadAdjustementReasons()];
    
    Promise.all(promises).then(([feeDataResponse, adjustmentReasonResponse]) => {
      this.prefillForm(feeDataResponse.data, adjustmentReasonResponse);  
    });
  };

  fetchQueryBuilderSpec = async () => {
    const [, res] = await to(api.options());

    const feeConditionsFields = setupFields(
      res.data.fee_conditions_spec.fields,
    );
    
    this.setState({
      feeConditionsFields,
    });
  };

  openCreateForm = () => {
    this.setState({
      isCreateMode: true,
      isEditMode: false,
    });
  };

  modify = ({ id }) => {
    this.setState({
      isCreateMode: false,
      isEditMode: true,
      feeId: id,
    });
  }

  save = async () => {
    const { values, actions } = this.props;
    const { isCreateMode, feeId } = this.state;
    const firstAction = get(values, 'action_first', null);
    const secondAction = get(values, 'action_second', null);
    const firstTimeago = get(values, 'timeago_first', null);
    const secondTimeago = get(values, 'timeago_second', null);
    const isValidFirstAction = firstAction && firstTimeago;
    const isValidSecondAction = secondAction && secondTimeago;
    const adjustmentReason = get(values, 'adjustment_reason.value'); 

    if (isValidFirstAction) {
      values[firstAction.value] = firstTimeago.value;
    }

    if (isValidSecondAction) {
      values[secondAction.value] = secondTimeago.value;
    }

    const payload = omit(values, [
      'action_first',
      'action_second',
      'timeago_first',
      'timeago_second',
      'timeframe',
    ]);

    if (isCreateMode) {
      const [err] = await to(actions.create(api, () => ({
        ...payload,
        adjustment_reason: adjustmentReason,
        fee_conditions: compileToApi(values.fee_conditions),
      })));

      if (!err) {
        notifier.success(text.CREATE_MSG_SUCCESS);
        this.onCancel();
      }
    } else {
      const [err] = await to(actions.update(api, feeId, () => ({
        ...payload,
        adjustment_reason: adjustmentReason,
        fee_conditions: compileToApi(values.fee_conditions),
        time_since_last_deposit: payload.time_since_last_deposit ? payload.time_since_last_deposit : null,
        time_since_last_trade: payload.time_since_last_trade ? payload.time_since_last_trade : null,
      }), true));

      if (!err) {
        notifier.success(text.EDIT_MSG_SUCCESS);
        this.onCancel();
      }
    }
  };

  remove = async ({ id }) => {
    const [err] = await to(api.destroy(id));
    const { dispatch } = this.props;
    const [, tableActions] = this.tableData;
    if (!err) {
      dispatch(tableActions.fetchTableData());
      notifier.success(text.DELETE_MSG_SUCCESS);
    }
  }

  toggleIsEnabled = (row) => {
    const { updateRowData } = this.tableData[1];
    const { dispatch } = this.props; 
    dispatch(updateRowData(row.id, {
      is_enabled: !row.is_enabled,
    }));
  };
  
  deleteTimeago = () => {
    this.setState({
      showSecondTimeAgo: false,
    });
    const { actions } = this.props;
    actions.setFieldValue({
      id: 'action_second',
      value: null,
    });
    actions.setFieldValue({
      id: 'timeago_second',
      value: null,
    });
  };

  renderForm = () => {
    if (!this.Form) {
      this.Form = FormFactory(formConfig);
    }
    const { Form } = this;
    return Form;
  }

  renderHeaderAction = () => { 
    const { isEditMode, isCreateMode } = this.state;

    if (!hasAccess('settings.automatic_fees.create')) {
      return null;
    }

    if (isCreateMode || isEditMode) return null;
  
    return (<Button onClick={this.openCreateForm}>{ text.ACTION_TEXT }</Button>);
  }

  render() {
    const { 
      isEditMode,
      isCreateMode, 
      feeConditionsFields,
      showSecondTimeAgo,
    } = this.state;

    const [Table] = this.tableData;
    const Form = this.renderForm();
    const shouldRenderForm = isEditMode || isCreateMode;
    const { values, errors } = this.props;
    const actionValues = [get(values, 'action_first.value', null), get(values, 'action_second.value', null)];  
    const availableActionOptions = actionOptions.map((action) => {
      if (actionValues.includes(action.value)) {
        return {
          ...action,
          isDisabled: true,
        };
      } 
      return action;
    });

    return (  
      <Page
        headerActions={this.renderHeaderAction}
        bread={bread}
        style={{ margin: '0 20px', minHeight: 'calc(100vh - 180px)' }}
        title={text.LABEL}
      >
        {!shouldRenderForm && (
        <Table 
          modifier={modifier(this.actions)}
          singleActions={!!(this.actions.modify || this.actions.remove)}
        />
        )}
        {
          shouldRenderForm && (
          <Form 
            modifiers={formModifiers}
            renderForm={
              (formFields) => {
                const { 
                  name,
                  description,
                  adjustment_reason,
                  fee_amount,
                  maximum_fee_limit,
                } = formFields;

                return (
                  <React.Fragment>
                    <Space size={24} />
                    <div className="automatic-fees__form">
                      <div className="automatic-fees__form-group">
                        <div className="automatic-fees__form-info-name">
                          <Field>{ name }</Field>
                          <div style={errors.name ? { marginBottom: '20px' } : {}}>
                            <label htmlFor="isEnabled">{ text.ACTIVE } </label>
                            <Toggle
                              checked={values.is_enabled}
                              label="Active"
                              id="isEnabled"
                              onChange={(value) => {
                                this.onChangeField('is_enabled', value);
                              }}
                            />
                          </div>
                        </div>
                        <Space size={12} />
                        <Field>{description}</Field>
                        <Field clearable={false} isSearchable style={{ maxWidth: '30%' }}>{ adjustment_reason }</Field>
                        <Space size={24} />      
                      </div>
                      <div className="automatic-fees__form-group">
                        <Space size={12} />
                        <h4>{ text.FEE_CONDITIONS_HEADER }</h4>
                        <Space size={12} />
                        <QueryBuilder 
                          group={values.fee_conditions || initialQBvalue}
                          fields={feeConditionsFields}
                          onStateUpdate={(group) => {
                            this.onChangeField({
                              id: 'fee_conditions',
                              value: group,
                            });
                          }}
                        />
                        <div className="help-text">{
                          errors.fee_conditions ? errors.fee_conditions[0] : null
                        }
                        </div>
                        <Space size={24} />
                      </div>
                      <div className="automatic-fees__form-group">
                        <Space size={12} />
                        <h4>{ text.TIMEFRAME_HEADER }</h4>
                        <h5>{ text.TIMEFRAME_SUBHEADER }</h5>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Field style={{ width: '40%' }} clearable={false} options={availableActionOptions}>{ formFields.action_first }</Field>
                          <Pill big>{ text.TIME_AGO } </Pill>
                          <Field style={{ width: '30%' }} clearable={false} options={timeOptions}>{ formFields.timeago_first }</Field>
                        </div>
                        <Space size={10} />
                        { !showSecondTimeAgo ? <Button onClick={() => { this.setState({ showSecondTimeAgo: true }); }} icon="Plus" iconPosition="left" size="small">{ text.ADD_CONDITION }</Button> : <Pill>AND</Pill> }
                        <Space size={10} />
                        { showSecondTimeAgo && (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          { !!formFields.action_second && <Field style={{ width: '40%' }} clearable={false} options={availableActionOptions}>{formFields.action_second}</Field> }
                          <Pill big>{ text.TIME_AGO } </Pill>
                          { !!formFields.timeago_second && <Field style={{ width: '30%' }} clearable={false} options={timeOptions}>{formFields.timeago_second}</Field> }
                          <Icon name="Delete" onClick={this.deleteTimeago} />
                        </div>
                        )}
                        <div className="help-text">{ errors.non_field_errors ? errors.non_field_errors[0] : null }</div>
                        <Space size={16} />
                      </div>
                      <div className="automatic-fees__form-group">
                        <Space size={12} />
                        <h4>{ text.RECURRENCE_HEADER }</h4>
                        <h5>{ text.RECURRENCE_SUBHEADER }</h5>
                        <Space size={16} />
                        <Radio.Group
                          horizontal
                          name="recurrence_period"
                          value={values.recurrence_period}
                          onChange={(e) => {
                            this.onChangeField('recurrence_period', +e.target.value);
                          }}
                        >
                          {
                     Object.keys(recurrencePeriod).map(key => (
                       <Radio 
                         name={recurrencePeriod[key].name}
                         value={recurrencePeriod[key].value}
                       >
                         { 
                           recurrencePeriod[key].label
                         }
                       </Radio>
                     ))
                      } 
                        </Radio.Group>
                        <Space size={24} />
                      </div>
                      <div className="automatic-fees__form-group">
                        <Space size={12} />
                        <h4>{ text.FEE_AMOUNT_HEADER }</h4>
                        <h5>{ text.FEE_AMOUNT_SUBHEADER }</h5>
                        <Space size={16} />
                        <div className="automatic-fees__fee-amount">
                          <Radio.Group
                            name="fee_type"
                            value={+values.fee_type}
                            onChange={(e) => {
                              this.onChangeField('fee_type', +e.target.value);
                            }}
                          >
                            {
                              Object.keys(feeAmountTypes).map(key => (
                                <Radio
                                  value={feeAmountTypes[key].value}
                                  name={feeAmountTypes[key].name}
                                >
                                  { feeAmountTypes[key].label }
                                </Radio>
                              ))
                             }
                          </Radio.Group>
                          {
                          fee_amount
                        }
                          {
                          values.fee_type === feeAmountTypes.PERCENTAGE.value && maximum_fee_limit
                        }
                        </div>
                        <Space size={24} />
                      </div>
                      <Space size={24} />
                      <div className="automatic-fees__form-footer">
                        <Button onClick={this.onCancel} color="ghost">{ text.CANCEL }</Button>
                        <Button onClick={this.save}>{ text.SAVE } </Button>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
          }
          />
          )
        }
      </Page>
    );
  }
}

const propTypes = {
  dispatch: func.isRequired,
  values: object.isRequired,
  actions: object.isRequired,
  isLoading: bool.isRequired,
  errors: object,
};

AutomaticFees.propTypes = propTypes;
AutomaticFees.defaultProps = {
  errors: {},
};

const mapStateToProps = ({ pages, forms }) => ({
  ...pages[AUTOMATIC_FEES],
  ...forms[AUTOMATIC_FEES],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    setFieldValue,
    create,
    update,
    setFieldsErrors,
  }, dispatch),
  dispatch,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(AutomaticFees);
