import { bindActionCreators } from 'redux';

import PersonalForm, { FORM_ID } from './PersonalForm';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import connect from '../../../../../../logic/connect';
import { FormActionsFactory } from '../../../../../../components/form';
import overlayActions from '../../../../../../components/overlay';

const { errorFields, create, update, resetFields, setRowData, setFieldValue } = FormActionsFactory(FORM_ID);

const mapStateToProps = ({ forms }) => {
  const { fields, submitInProgress, values } = forms[FORM_ID];
  return { fields, values, submitInProgress };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      errorFields,
      create,
      resetFields,
      setRowData,
      setFieldValue,
      update,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(PersonalForm));
