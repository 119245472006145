import { gettext } from '../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../logic/services/api-factory';

export const sidepanelName = 'EDIT_SALES_STATUSES';
export const text = {
  TITLE: gettext('Edit Sales Statuses'),
};
export const autocompleteApi = getDjangoApi('autocomplete/sales_status');
export const bulkApi = getDjangoApi('sales_status/bulk_set');
export const getPayload = fieldData => user => ({
  user: user.id,
  sales_status: fieldData ? fieldData.id : null,
});
export const getError = (errors) => {
  const hasError = errors !== null;
  const helpText = hasError ? errors[0].sales_status[0] : '';

  return {
    hasError,
    helpText,
  };
};
