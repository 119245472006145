import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../logic/services/api-factory';


const teamApi = getDjangoApi('team');
const teamProfileApi = getDjangoApi('team/profile');

const initialState = {
  member: {},
  memberProfile: {},
  memberOptions: {},
  memberInProgress: false,
};


const actionTypes = {
  FETCH_TEAM_MEMBER: 'FETCH_TEAM_MEMBER',
  FETCH_TEAM_MEMBER_PENDING: 'FETCH_TEAM_MEMBER_PENDING',
  FETCH_TEAM_MEMBER_FULFILLED: 'FETCH_TEAM_MEMBER_FULFILLED',
  FETCH_TEAM_MEMBER_FAILED: 'FETCH_TEAM_MEMBER_FAILED',
  RESET_TEAM_MEMBER: 'RESET_TEAM_MEMBER',
  UPDATE_TEAM_MEMBER: 'UPDATE_TEAM_MEMBER',
};

const loadTeamMember = createAction(actionTypes.FETCH_TEAM_MEMBER, async (id) => {
  const [{ data: member }, { data: memberProfile }, { data: memberOptions }] = await Promise.all([
    teamApi.retrieve(id),
    teamProfileApi.retrieve(id),
    teamApi.one(id).options(),
  ]);

  return {
    member,
    memberProfile,
    memberOptions,
  };
});

const updateTeamMember = createAction(actionTypes.UPDATE_TEAM_MEMBER, data => data);
const resetTeamMember = createAction(actionTypes.RESET_TEAM_MEMBER, () => initialState);

export {
  loadTeamMember,
  updateTeamMember,
  resetTeamMember,
};

export default handleActions({
  [actionTypes.FETCH_TEAM_MEMBER_PENDING]: state => ({ ...state, memberInProgress: true }),
  [actionTypes.FETCH_TEAM_MEMBER_FULFILLED]: (state, { payload }) => ({
    ...state, 
    ...payload,
    memberInProgress: false,
  }),
  [actionTypes.FETCH_TEAM_MEMBER_FAILED]: state => ({ ...state, member: false }),
  [actionTypes.UPDATE_TEAM_MEMBER]: (state, { payload }) => ({
    ...state,
    member: {
      ...state.member,
      ...payload,
    },
  }),
  [actionTypes.RESET_TEAM_MEMBER]: () => (initialState),
}, initialState);
