import React, { PureComponent } from 'react';
import { bool, func, object } from 'prop-types';
import { Sidepanel, Button, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import SidepanelFooter from '../SidepanelFooter';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../logic/connect';
import withUnmountMethod from '../../../../../../components/hoc/withUnmountMethod';
import FormFactory, { FormActionsFactory } from '../../../../../../components/form';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';

const EDIT_COUNTRY_RISK = 'EDIT_COUNTRY_RISK';
const sidepanelID = EDIT_COUNTRY_RISK;
const formID = EDIT_COUNTRY_RISK;
const api = getDjangoApi('settings/country_risks');

const { update, setRowData, resetFields } = FormActionsFactory(formID);

const text = {
  DISCARD: gettext('Discard'),
  SAVE: gettext('Save changes'),
  RISK_LABEL: gettext('Risk'),
  EDIT_SUCCESS_MSG: gettext('Risk group edited successfully'),
};

const riskField = {
  id: 'risk',
  name: 'risk',
  label: text.RISK_LABEL,
  required: true,
  type: 'number', 
  valueKey: 'id',
};

const formConfig = {
  form: formID,
  customFields: [riskField],
};

const propTypes = {
  visible: bool.isRequired,
  closeSidePanel: func.isRequired,
  parameters: object,
  submitInProgess: bool,
  actions: object.isRequired,
  values: object.isRequired,
  fetchTableData: func.isRequired,
  dispatch: func.isRequired,
};

const defaultProps = {
  parameters: null,
  submitInProgess: false,
};

const footerRender = ({ onCancel, onSave, submitInProgess }) => (
  <React.Fragment>
    <Button 
      onClick={onCancel}
      color="ghost"
    >
      { text.DISCARD }
    </Button>
    <Button 
      onClick={onSave}
      color="primary"
      disable={submitInProgess}
      loading={submitInProgess}
    > {text.SAVE } 
    </Button> 
  </React.Fragment>
);

footerRender.propTypes = {
  onCancel: func.isRequired,
  onSave: func.isRequired,
  submitInProgess: bool.isRequired,
};


class EditRiskSidepanel extends PureComponent {
  constructor(props) {
    super(props);

    this.FormComponent = null;
  }

  componentDidUpdate(prevProps) {
    const { actions: { setRowData }, parameters } = this.props;
    if (parameters && !prevProps.parameters) { setRowData(parameters); }
  }
  
  onSave = async () => {
    const { 
      values: { risk },
      actions: { update },
      parameters: { iso_code },
      fetchTableData,
      dispatch } = this.props;
    const promise = update(api, iso_code, () => ({ risk }));

    const [error] = await to(promise);

    if (!error) {
      this.closeSidePanel();
      notifier.success(text.EDIT_SUCCESS_MSG);
      dispatch(fetchTableData());
    }
  }

  closeSidePanel = () => {
    const { closeSidePanel } = this.props;
    closeSidePanel(sidepanelID);
  }

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    } 
    const { FormComponent } = this;
    return <FormComponent />;
  }
  

  render() {
    const { 
      visible,
      parameters,
      submitInProgess,
    } = this.props;

    const {
      country_name,
    } = parameters || {};

    if (!parameters) return null;

    return (
      (
        <Sidepanel
          icon="Edit"
          title={`${country_name}`}
          visible={visible}
          onCloseIconClick={this.closeSidePanel}
          footerRender={() => (
            <SidepanelFooter 
              onCancel={this.closeSidePanel}
              onSave={this.onSave}
              submitInProgess={submitInProgess}
            />
          )}
        >
          { this.renderForm() }
        </Sidepanel>
      )
    );
  }
}

EditRiskSidepanel.propTypes = propTypes;
EditRiskSidepanel.defaultProps = defaultProps;

const mapStateToProps = ({ overlays, forms }) => ({
  sidepanel: overlays[sidepanelID],
  ...overlays[sidepanelID],
  ...forms[formID],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    update,
    setRowData,
    resetFields,
  }, dispatch),
  dispatch,
});

export { sidepanelID, api };
export default withUnmountMethod(connect(mapStateToProps, mapDispatchToProps)(EditRiskSidepanel));
