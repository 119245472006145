import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { object, func } from 'prop-types';
import { Sidepanel, Space, notifier } from 'tc-biq-design-system';

import { gettext } from '../../../../../../logic/utilities/languageUtility';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import connect from '../../../../../../logic/connect';
import overlayActions from '../../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../../components/common';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';

export const SIDEPANEL_ID = 'DELETE_TEAM_MEMBER_SIDEPANEL';

const text = {
  TITLE: gettext('Remove team member'),
  QUESTION: gettext('Are you sure want to remove this team member?'),
  SUCCESS: gettext('Team member deleted successfully'),
  ERROR: gettext('Failed to delete team member'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const teamApi = getDjangoApi('team');

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
  onSuccess: func.isRequired,
};

const customFooter = (execute, close) => (
  <SidepanelFooter
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

class DeleteMember extends PureComponent {
  onSubmit = async () => {
    const { sidepanel: { parameters: { member } } } = this.props;
    const [err] = await to(teamApi.destroy(member.id));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    const { onSuccess } = this.props;
    notifier.success(text.SUCCESS);
    onSuccess && onSuccess();
    this.onClose();
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    notifier.error(text.ERROR);
  }

  onClose = () => {
    const { actions } = this.props;
    actions.closeSidepanel(SIDEPANEL_ID);
  };

  render() {
    const { sidepanel } = this.props;
    return (
      <Sidepanel
        icon="Delete"
        title={text.TITLE}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.onSubmit, this.onClose)}
      >
        <Space size={16} />
        <div>{text.QUESTION}</div>
      </Sidepanel>
    );
  }
}

DeleteMember.propTypes = propTypes;

const mapStateToProps = ({ overlays, pages }) => {
  const sidepanel = overlays[SIDEPANEL_ID];
  return { 
    sidepanel, 
    ...pages.TEAM.team,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      updateSidepanel: overlayActions.update,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(DeleteMember));
