import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../logic/services/api-factory';

const api = id => getDjangoApi(`team/campaigns/${id}`);

const initialState = {
  campaign: null,
  fetchCampaignInProgress: false,
};

const actionTypes = {
  FETCH_CAMPAIGN: 'FETCH_CAMPAIGN',
  FETCH_CAMPAIGN_PENDING: 'FETCH_CAMPAIGN_PENDING',
  FETCH_CAMPAIGN_FULFILLED: 'FETCH_CAMPAIGN_FULFILLED',
  FETCH_CAMPAIGN_FAILED: 'FETCH_CAMPAIGN_FAILED',
};


const fetchCampaign = createAction(actionTypes.FETCH_CAMPAIGN, async (id, options) => {
  const { data } = await api(id).list(options);
  return data;
});

export const actions = {
  fetchCampaign,
};

export default handleActions({
  [actionTypes.FETCH_CAMPAIGN_PENDING]: state => ({ ...state, fetchCampaignInProgress: true }),
  [actionTypes.FETCH_CAMPAIGN_FULFILLED]: (state, { payload }) => ({ ...state, fetchCampaignInProgress: false, campaign: payload }),
  [actionTypes.FETCH_CAMPAIGN]: state => ({ ...state, fetchCampaignInProgress: false }),
}, initialState);
