import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionTypes from '../types';
import ExternalTransfer from './ExternalTransfers';

const text = {
  LABEL: gettext('External transfer'),
};

const ACTION_ICON = 'InternalTransaction';

export default {
  name: 'externalTransfer',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.TRANSACTIONS,
  hasPermission(state, user, isIb) {
    if (isIb) return false;
    const hasStatePermission = [
      USER_STATE.FULL,
      USER_STATE.LIMITED,
      USER_STATE.REVIEW,
    ].indexOf(state) >= 0;
    return hasAccess('external_transfer.create') && hasStatePermission;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(ExternalTransfer, sidepanelOptions);
  },
};
