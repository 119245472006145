import React from 'react';
import hideCols from '../../../../../logic/utilities/hideCols';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';

export default () => ({
  whitelabel: {
    // eslint-disable-next-line react/prop-types
    cellRendererFramework: ({ value, colDef }) => {
      const choices = _.get(colDef, 'definition.choices');
      const displayValue = choices.find(choice => choice.value === value);

      return <span>{displayValue && displayValue.display_name}</span>;
    },
  },
  ...hideCols(['id', 'gateway', 'watchlist'].concat(isFeatureEnabled()('WHITELABEL') ? '' : 'whitelabel')),
});
