import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { object, array, bool } from 'prop-types';
import { Table, InfoBox, Spinner, Row, Space } from 'tc-biq-design-system';

import connect from '../../../../../../logic/connect';
import currencyConverter from '../../../../../../logic/utilities/formatters/currency';
import { fetchInstrumentStats } from './Model';

const text = {
  INSTRUMENT: 'Instrument',
  LONG_POSITION: 'Long Positions',
  LONG_PROFIT: 'Long Profit',
  SHORT_POSITION: 'Short Position',
  SHORT_PROFIT: 'Short Profit',
  TOTAL_POSITION: 'Total Position',
  TOTAL_PROFIT: 'Total Profit',
  TOTAL_WON: 'Total Won',
  TOTAL_LOST: 'Total Lost',
  AVG_DURATION: 'Avg Duration',
  AVG_WIN: 'Avg Win',
  AVG_LOSS: 'Avg Loss',
  NO_INSTRUMENT: 'No instrument data available',
};

const instrumentRender = ({ instrument: { symbol } }) => symbol;

const withPercentRender = (mainKey, percentKey) => (record) => {
  const main = record[mainKey];
  const percent = record[percentKey];
  return `${main} (${percent}%)`;
};

const currencyRender = (mainKey, user) => (record) => {
  const main = record[mainKey];

  return currencyConverter(main, user.currency.symbol);
};

const durationRender = mainKey => (record) => {
  const main = record[mainKey];

  return moment.duration(main, 'seconds').humanize();
};

const cols = user => [
  {
    title: text.INSTRUMENT,
    key: 'instrument',
    render: instrumentRender,
  }, {
    title: text.LONG_POSITION,
    key: 'positions_long',
  }, {
    title: text.LONG_PROFIT,
    key: 'pnl_long',
    render: currencyRender('pnl_long', user),
  }, {
    title: text.SHORT_POSITION,
    key: 'positions_short',
  }, {
    title: text.SHORT_PROFIT,
    key: 'pnl_short',
    render: currencyRender('pnl_short', user),
  }, {
    title: text.TOTAL_POSITION,
    key: 'positions',
  }, {
    title: text.TOTAL_PROFIT,
    key: 'pnl',
    render: currencyRender('pnl', user),
  }, {
    title: text.TOTAL_WON,
    key: 'positions_win',
    render: withPercentRender('positions_win', 'positions_win_percent'),
  }, {
    title: text.TOTAL_LOST,
    key: 'positions_loss',
    render: withPercentRender('positions_loss', 'positions_loss_percent'),
  }, {
    title: text.AVG_DURATION,
    key: 'avg_duration',
    render: durationRender('avg_duration'),
  }, {
    title: text.AVG_WIN,
    key: 'avg_pnl_win',
    render: currencyRender('avg_pnl_win', user),
  }, {
    title: text.AVG_LOSS,
    key: 'avg_pnl_loss',
    render: currencyRender('avg_pnl_loss', user),
  },
];

const propTypes = {
  actions: object.isRequired,
  user: object.isRequired,
  stats: array,
  fetchInProgress: bool,
};

const defaultProps = {
  stats: [],
  fetchInProgress: false,
};

class InstrumentStats extends Component {
  componentDidMount() {
    const { actions, user } = this.props;
    actions.fetchInstrumentStats(user);
  }

  render() {
    const { stats, user, fetchInProgress } = this.props;
    const colsConfig = cols(user);
    const notEmptyData = stats.length > 0;
    const showSpinner = fetchInProgress;
    const showInfo = !notEmptyData && !showSpinner;
    const showTable = notEmptyData && !showSpinner;
    return (
      <Fragment>
        <Space size={12} />
        {showSpinner && (
          <Row style={{ justifyContent: 'center' }}>
            <Space size={30} />
            <Spinner />
          </Row>
        )}
        {showInfo && (
          <InfoBox>{text.NO_INSTRUMENT}</InfoBox>
        )}
        {showTable && (
          <Table
            striped
            cols={colsConfig} 
            data={stats} 
          />
        )}
      </Fragment>
    );
  }
}

InstrumentStats.propTypes = propTypes;
InstrumentStats.defaultProps = defaultProps;

const mapStateToProps = ({ pages }) => {
  const userPage = pages.USER_SINGLE;
  const page = userPage.InstrumentStats;
  return {
    stats: page.stats,
    fetchInProgress: page.fetchInProgress,
    user: userPage.userDetail.user,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchInstrumentStats }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentStats);
