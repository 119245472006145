import Withdrawals from './Withdrawals';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../../logic/services/acl';

export default {
  ...Withdrawals,
  key: 'withdrawals',
  label: gettext('Withdrawals'),
  isVisible: () => hasAccess('dashboard.overall_withdrawals.*'),
};
