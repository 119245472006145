import React from 'react';
import { Tooltip, Icon } from 'tc-biq-design-system';

import { tradingAccountDisplay } from '../../../../../../logic/filters/trading-account-display';
import { ActionsCellWrapper, StepCell, UsernameCell, StatusCell, OnContentClickCell } from '../../../../../../components/gridCellRenderers';
import { currencyFormatter } from '../../../../../../logic/utilities/formatters';
import hideCols from '../../../../../../logic/utilities/hideCols';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

// eslint-disable-next-line react/prop-types
const AccountPreview = ({ data: { wallet } }) => (
  <span>{tradingAccountDisplay(wallet)}</span>
);

const modifier = ({ previewBiqUser }) => ({
  ...hideCols(['user', 'first_name', 'last_name']),
  wallet: {
    width: 330,
    cellRendererFramework: AccountPreview,
  },
  status: {
    cellRendererFramework: StatusCell,
    width: 280,
    cellStyle: { 'justify-content': 'flex-end' },
  },
  dealers_review: { cellRendererFramework: StepCell('dealers_review') },
  bo_review: { cellRendererFramework: StepCell('bo_review') },
  retention_review: { cellRendererFramework: StepCell('retention_review') },
  managment_review: { cellRendererFramework: StepCell('managment_review') },
  compliance_review: { cellRendererFramework: StepCell('compliance_review') },
  new_test_review_12: { cellRendererFramework: StepCell('new_test_review_12') },
  method: { width: 250 },
  amount: { cellRendererFramework: ({ value, data }) => currencyFormatter(value, data.currency.symbol) },
  executed_amount: { cellRendererFramework: ({ value, data }) => currencyFormatter(value, data.currency.symbol) },
  assigned_to: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  modified_by: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  created_by: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  executed_by: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  authorized_by: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
});


const historyModifier = ({ previewBiqUser, openHistoryWithdrawalRequest, openHistoryWithdrawalRelatedTransaction }) => ({
  ...modifier({ previewBiqUser }),
  id: {
    cellRendererFramework: OnContentClickCell(openHistoryWithdrawalRequest),
    width: 120,
  },
  related_payment_transaction: {
    cellRendererFramework: OnContentClickCell(openHistoryWithdrawalRelatedTransaction),
  },
});

const iconsTemplates = {
  executePendingWithdrawal: (actionHandler, data) => (
    <div key="Approve" onClick={() => actionHandler(data)}>
      <Tooltip title={gettext('Approve')} placement="top">
        <Icon colorName="text-status01-700" name="Approve" />
      </Tooltip>
    </div>
  ),
  rejectPendingWithdrawal: (actionHandler, data) => (
    <div key="Reject" onClick={() => actionHandler(data)}>
      <Tooltip title={gettext('Reject')} placement="top">
        <Icon colorName="text-status04-700" name="Reject" />
      </Tooltip>
    </div>
  ),
};

export const actionIcons = actions => ({
  ...(actions.executePendingWithdrawal && { executePendingWithdrawal: iconsTemplates.executePendingWithdrawal }),
  ...(actions.rejectPendingWithdrawal && { rejectPendingWithdrawal: iconsTemplates.rejectPendingWithdrawal }),
});

const pendingModifier = ({ previewBiqUser, openPendingWithdrawalRequest, executePendingWithdrawal, rejectPendingWithdrawal }) => ({
  ...modifier({ previewBiqUser }),
  id: {
    cellRendererFramework: OnContentClickCell(openPendingWithdrawalRequest),
    width: 120,
  },
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper(
      { executePendingWithdrawal, rejectPendingWithdrawal }, 
      actionIcons({ executePendingWithdrawal, rejectPendingWithdrawal }),
    ),
    pinned: 'right',
    width: 120,
  },
});

export {
  historyModifier,
  pendingModifier,
};
