import { array } from 'prop-types';
import React from 'react';

import './style.css';

const MassAssignmentPanel = ({ actions }) => (
  <div className="mass-assignment-panel">
    {actions.map(action => <div className="mass-assignment-panel__btn" onClick={action.onClick}>{action.label}</div>)}
  </div>
);

MassAssignmentPanel.propTypes = {
  actions: array,
};
MassAssignmentPanel.defaultProps = {
  actions: [],
};

export default MassAssignmentPanel;
