const ENTITY_TYPES = {
  USER_DOCUMENT: 'accounts.userdocument',
  USER: 'tc_auth.user',
  BO_USER: 'team.bouser',
  BONUS: 'bonuses.bonus',
  NOTE: 'notes.note',
  MESSAGE: 'notify.message',
  CHAT: 'notify.chat',
  SMS: 'notify.sms',
  CALL: 'notify.call',
  PAYMENT_GATEWAY: 'payment.paymentgateway',
  PAYMENT_TRANSACTION: 'payment.paymenttransaction',
  PAYMENT_WITHDRAW_REQUEST: 'payment.withdrawrequest',
  TRADING_BACKEND: 'tpi.tradingbackend',
  TRADING_ACCOUNT: 'tpi.tradingaccount',
  KYC: 'validation_gateway.kyc',
  VALIDATION_GATEWAY: 'validation_gateway.validationgateway',
  CALLS: 'communication.call',
};

export default ENTITY_TYPES;
