import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { refactoredInjectRules, setupFields } from '../../../../../logic/services/query-adapter';

const actionTypes = {
  GET_DEPOSIT_METHOD_OPTIONS: 'GET_DEPOSIT_METHOD_OPTIONS',
  GET_DEPOSIT_METHOD_OPTIONS_PENDING: 'GET_DEPOSIT_METHOD_OPTIONS_PENDING',
  GET_DEPOSIT_METHOD_OPTIONS_FULFILLED: 'GET_DEPOSIT_METHOD_OPTIONS_FULFILLED',
  SET_DEPOSIT_RULE_SELECTED_METHOD: 'SET_DEPOSIT_RULE_SELECTED_METHOD',
  SET_DEPOSIT_RULE_NAME: 'SET_DEPOSIT_RULE_NAME',
  SET_DEPOSIT_RULE_CONDITION: 'SET_DEPOSIT_RULE_CONDITION',
  SUBMIT_DEPOSIT_METHOD_RULE: 'SUBMIT_DEPOSIT_METHOD_RULE',
  SUBMIT_DEPOSIT_METHOD_RULE_PENDING: 'SUBMIT_DEPOSIT_METHOD_RULE_PENDING',
  SUBMIT_DEPOSIT_METHOD_RULE_REJECTED: 'SUBMIT_DEPOSIT_METHOD_RULE_REJECTED',
  SUBMIT_DEPOSIT_METHOD_RULE_FULFILLED: 'SUBMIT_DEPOSIT_METHOD_RULE_FULFILLED',
  GET_DEPOSIT_METHOD_RULE_INFO: 'GET_DEPOSIT_METHOD_RULE_INFO',
  GET_DEPOSIT_METHOD_RULE_INFO_PENDING: 'GET_DEPOSIT_METHOD_RULE_INFO_PENDING',
  GET_DEPOSIT_METHOD_RULE_INFO_REJECTED: 'GET_DEPOSIT_METHOD_RULE_INFO_REJECTED',
  GET_DEPOSIT_METHOD_RULE_INFO_FULFILLED: 'GET_DEPOSIT_METHOD_RULE_INFO_FULFILLED',
  RESET_DEPOSIT_METHOD_RULE_PAGE_STATE: 'RESET_DEPOSIT_METHOD_RULE_PAGE_STATE',
};

const depositMethodRulesAPI = getDjangoApi('settings/deposit_methods');

const initialState = {
  submitInProgress: false,
  fields: [],
  methods: [],
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  errors: {
    name: [''],
    method: [''],
  },
  selectedMethod: null,
  name: '',
};

export const getFieldOptions = createAction(
  actionTypes.GET_DEPOSIT_METHOD_OPTIONS,
  async () => depositMethodRulesAPI.options(),
);

export const submitDeposit = createAction(
  actionTypes.SUBMIT_DEPOSIT_METHOD_RULE,
  async (isEdit, request, id) => (
    isEdit ? depositMethodRulesAPI.updatePart(id, request) : depositMethodRulesAPI.create(request)
  ),
);

export const setSelectedMethod = createAction(
  actionTypes.SET_DEPOSIT_RULE_SELECTED_METHOD,
  method => method,
);

export const setRuleName = createAction(
  actionTypes.SET_DEPOSIT_RULE_NAME,
  event => event.target.value,
);

export const setRuleCondition = createAction(
  actionTypes.SET_DEPOSIT_RULE_CONDITION,
  group => group,
);

export const getDepositInfo = createAction(
  actionTypes.GET_DEPOSIT_METHOD_RULE_INFO,
  async id => depositMethodRulesAPI.retrieve(id),
);

export const resetState = createAction(
  actionTypes.RESET_DEPOSIT_METHOD_RULE_PAGE_STATE,
);

const getDepositOptionsHandler = (state, { payload }) => {
  const { data } = payload;
  const { fields } = data.spec;
  const methods = _.find(data.actions.POST.fields, { key: 'method' }).choices;
  const mappedFields = setupFields(fields);

  return {
    ...state,
    fields: [...mappedFields],
    methods: [...methods],
  };
};

const setSelectedMethodHandler = (state, { payload }) => (
  {
    ...state,
    selectedMethod: { ...payload },
    errors: { ...state.errors, method: [''] },
  }
);

const setRuleNameHandler = (state, { payload }) => (
  {
    ...state,
    name: payload,
    errors: { ...state.errors, name: [''] },
  }
);

const setRuleConditionHandler = (state, { payload }) => (
  {
    ...state,
    group: { ...payload },
  }
);

const depositSubmitHandler = state => (
  {
    ...state,
    submitInProgress: false,
  }
);

const depositSubmitErrorHandler = (state, { payload }) => (
  {
    ...state,
    errors: { ...state.errors, ...payload.data },
    submitInProgress: false,
  }
);

const getDepositInfoHandler = (state, { payload }) => {
  const { condition, method, name } = payload.data;
  const group = refactoredInjectRules(state.fields, condition);
  const selectedMethod = _.find(state.methods, { value: method });

  return {
    ...state,
    name,
    group,
    selectedMethod,
  };
};

const resetStateHandler = () => (
  {
    ...initialState,
  }
);

export const depositMethodRuleActions = {
  getFieldOptions,
  setSelectedMethod,
  setRuleName,
  setRuleCondition,
  getDepositInfo,
  submitDeposit,
  resetState,
};

export default handleActions(
  {
    [actionTypes.GET_DEPOSIT_METHOD_OPTIONS_PENDING]: state => (
      { ...state, optionsInProgress: true }
    ),
    [actionTypes.GET_DEPOSIT_METHOD_OPTIONS_FULFILLED]: getDepositOptionsHandler,
    [actionTypes.SET_DEPOSIT_RULE_SELECTED_METHOD]: setSelectedMethodHandler,
    [actionTypes.SET_DEPOSIT_RULE_NAME]: setRuleNameHandler,
    [actionTypes.SET_DEPOSIT_RULE_CONDITION]: setRuleConditionHandler,
    [actionTypes.SUBMIT_DEPOSIT_METHOD_RULE_PENDING]: state => (
      { ...state, submitInProgress: true }
    ),
    [actionTypes.SUBMIT_DEPOSIT_METHOD_RULE_FULFILLED]: depositSubmitHandler,
    [actionTypes.SUBMIT_DEPOSIT_METHOD_RULE_REJECTED]: depositSubmitErrorHandler,
    [actionTypes.GET_DEPOSIT_METHOD_RULE_INFO_FULFILLED]: getDepositInfoHandler,
    [actionTypes.RESET_DEPOSIT_METHOD_RULE_PAGE_STATE]: resetStateHandler,
  },
  initialState,
);
