import appRoutes from '../App/Router/appRoutes';
import RouteCell from './RouteCell';

const DEFAULT_CHANNEL = 'email';

const MessageCell = chn => ({ value, data, colDef }) => {
  const href = appRoutes.LOGS_MESSAGE;
  let channel = chn || data.channel || DEFAULT_CHANNEL;
  if (data.message_type === 'Zopim') channel = 'chats';
  const Component = RouteCell({ href, stateParams: { id: data.id, channel } });
  return Component({ value, data, colDef });
};

export default MessageCell;
