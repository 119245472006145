import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { object, func, array } from 'prop-types';
import { Sidepanel, HyperLink, ListItem, Space, Select, notifier } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import { gettext } from '../../../../../../logic/utilities/languageUtility';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import connect from '../../../../../../logic/connect';
import overlayActions from '../../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../../components/common';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

export const SIDEPANEL_ID = 'MOVE_TO_TEAM_SIDEPANEL';

const text = {
  TITLE: gettext('Move team members'),
  SELECT_TEAM: gettext('Select team'),
  SUCCESS: gettext('Team members have been moved successfully'),
  ERROR: gettext('Failed to move team members'),
  BUTTON_LABELS: {
    confirm: gettext('Submit'),
    cancel: gettext('Cancel'),
  },
};

const teamsApi = getDjangoApi('teams');

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
  onSuccess: func.isRequired,
  members: array.isRequired,
};

const customFooter = (execute, close) => (
  <SidepanelFooter
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

const MemberList = ({ members }) => members.map(member => (
  <HyperLink key={member.id} style={{ display: 'block' }}>
    <Link to={generatePath(appRoutes.TEAM_MEMBER, { id: member.id })}>
      <ListItem>
        <Space horizontal size={8} />
        <span>{member.full_name}</span>
        <Space horizontal size={8} />
      </ListItem>
    </Link>
  </HyperLink>
));

class MovetoTeam extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      teams: [],
      selectedTeam: null,
    };
  }

  UNSAFE_componentWillMount() {
    teamsApi.list({ limit: 100 }).then(({ data }) => {
      this.setState({
        teams: data.results,
        selectedTeam: data.results[0],
      });
    });
  }

  onSubmit = async () => {
    const { selectedTeam } = this.state;
    const { members } = this.props;
    const selectedMembers = members.filter(({ checked }) => checked); 
    const [err] = await to(teamsApi.updatePart(selectedTeam.id, {
      members: selectedMembers.map(({ id }) => id),
    }));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    const { onSuccess } = this.props;
    notifier.success(text.SUCCESS);
    onSuccess && onSuccess();
    this.onClose();
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    notifier.error(text.ERROR);
  }

  onClose = () => {
    const { actions } = this.props;
    actions.closeSidepanel(SIDEPANEL_ID);
  };

  onChange = selectedTeam => this.setState({
    selectedTeam,
  })

  render() {
    const { sidepanel, members } = this.props;
    const { teams, selectedTeam } = this.state;
    const selectedMembers = members.filter(({ checked }) => checked);
    return (
      <Sidepanel
        icon="Edit"
        title={text.TITLE}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.onSubmit, this.onClose)}
      >
        <Select
          label={text.SELECT_TEAM}
          value={selectedTeam}
          options={teams}
          onChange={this.onChange}
          clearable={false}
          valueKey="id"
          labelKey="name"
        />
        <Space size={20} />
        <MemberList members={selectedMembers} />
      </Sidepanel>
    );
  }
}

MovetoTeam.propTypes = propTypes;

const mapStateToProps = ({ overlays, pages }) => {
  const sidepanel = overlays[SIDEPANEL_ID];
  return { 
    sidepanel, 
    ...pages.TEAM.team,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      updateSidepanel: overlayActions.update,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(MovetoTeam));
