/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Tabs, Tab, Button, notifier } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import connect from '../../../../../logic/connect';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../../components/grid';
import withUnmountMethod from '../../../../../components/hoc/withUnmountMethod';
import overlayActions from '../../../../../components/overlay';
import Filters from '../../../../../components/Filters';
import If from '../../../../../components/If';
import Page from '../../../../../components/Page';
import { BiqUserPreview } from '../../../../../components/common/sidepanels';
import { campaignModifier, cancellationRulesModifier, reasonsModifier } from './modifier';
import AddEditBonusReason, { SIDEPANEL_ID as REASON_SIDEPANEL_ID } from './Sidepanels/AddEditBonusReason';
import { hasAccess } from '../../../../../logic/services/acl';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';
import { getActiveFilters } from '../../../../../components/grid/GridUtils';
import { resetFilterStore } from '../../../../../components/Filters/FilterRedux';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';
import withTable, { GridTab } from '../../../../../components/grid/withTable';

const reducerKey = 'BONUS';

const campaignsConfig = {
  reducerKey,
  apiUrl: 'bonus/campaigns',
  tableKey: 'CAMPAIGNS',
};

const reasonsConfig = {
  reducerKey,
  apiUrl: 'bonus/reasons',
  tableKey: 'REASONS',
};

const cancellationRulesConfig = () => ({
  reducerKey,
  apiUrl: 'settings/bonus_expiry_rules',
  tableKey: 'CANCELLATION_RULES',
});

const tabs = [
  campaignsConfig.tableKey,
  reasonsConfig.tableKey,
  cancellationRulesConfig().tableKey,
];

const { 
  GridComponent: CampaignTable, 
  actions: campaignsActions, 
} = GridFactory(campaignsConfig);

const { 
  GridComponent: ReasonsTable, 
  actions: reasonsActions, 
} = GridFactory(reasonsConfig);

const text = {
  PAGE_TITLE: gettext('Bonus system'),
  CAMPAIGNS: gettext('Campaigns'),
  REASONS: gettext('Reasons'),
  CREATE_NEW_CAMPAIGN: gettext('Create new campaign'),
  CREATE_NEW_REASON: gettext('Create new reason'),
  CREATE_NEW_CANCELLATION_RULE: gettext('Create new expiry rule'),
  STATE_UPDATE_SUCCESS: gettext('State updated successfully'),
  STATE_UPDATE_ERROR: gettext('Error while updating state'),
  CAMPAIGN_DELETE_SUCCESS: gettext('Campaign deleted successfully'),
  REASON_DELETE_SUCCESS: gettext('Reason deleted successfully'),
  RULE_DELETE_SUCCESS: gettext('Expiry rule deleted successfully'),
  IS_REASON_REQUIRED: gettext('Is Reason Required'),
  REASON_REQUIRED_SUCCESS: gettext('Reason required state successfully updated'),
  GENERAL_ERROR: gettext('Something went wrong'),
  CANCELLATION_RULES: gettext('Expiry rules'),
};

const bread = [{ label: text.PAGE_TITLE, route: 'home.settings-bonus' }];

class Bonus extends Component {
  constructor(props) {
    super(props);

    this.tableActions = {
      editCampaign: hasAccess('bonuses.campaigns.update'),
      onEditReason: hasAccess('bonuses.reasons.update') && this.onEditReason.bind(this),
      onRemoveCampaign: hasAccess('bonuses.campaigns.destroy') && this.onRemove.bind(this),
      onToggleCampaigns: hasAccess('bonuses.campaigns.update') && this.toggleEnabled.bind(this),
      onToggleReasons: hasAccess('bonuses.reasons.update') && this.toggleEnabled.bind(this),
      editRule: hasAccess('settings.bonus_expiry_rules.update'),
      onToggleRule: hasAccess('settings.bonus_expiry_rules.update') && this.toggleEnabled.bind(this),
      onRemoveRule: hasAccess('settings.bonus_expiry_rules.destroy') && this.onRemove.bind(this),
      previewBiqUser: props.overlay.open.bind(this, 'BIQ_USER_PREVIEW'),
    };

    this.state = {
      active: props.match.params.section || campaignsConfig.tableKey,
    };

    this.CancellationRulesGrid = withTable(
      GridTab, 
      cancellationRulesConfig,
      null,
      { withActions: true },
    )();

    const { dispatch } = props;
    const [, cancellationRulesActions] = this.CancellationRulesGrid;
    this.cancellationRulesActions = bindActionCreators({
      ...cancellationRulesActions,
    }, dispatch);
  }

  onRemove = async (data) => {
    const { active } = this.state;
    const { 
      reasonsActions, 
      reasonsTable, 
      campaignsActions, 
      campaignsTable, 
      cancellationRulesTable,
    } = this.props;

    const { cancellationRulesActions } = this;

    const deleteRow = async (config, data, successText, actions, table) => {
      const api = getDjangoApi(config.apiUrl);
      await api.destroy(data.id);
      notifier.success(successText);
      this.onSuccessRequest(actions, table)();
    };

    if (active === campaignsConfig.tableKey) {
      deleteRow(
        campaignsConfig, 
        data, 
        text.CAMPAIGN_DELETE_SUCCESS, 
        campaignsActions, 
        campaignsTable,
      );
    } else if (active === reasonsConfig.tableKey) {
      deleteRow(
        reasonsConfig, 
        data, 
        text.REASON_DELETE_SUCCESS, 
        reasonsActions, 
        reasonsTable,
      );
    } else {
      deleteRow(
        cancellationRulesConfig(), 
        data, 
        text.RULE_DELETE_SUCCESS, 
        cancellationRulesActions, 
        cancellationRulesTable,
      );
    }
  }

  onSuccessRequest = (actions, table) => () => actions.fetchTableData(table.query)

  onEditReason = (data) => {
    const { overlay } = this.props;
    overlay.open(REASON_SIDEPANEL_ID, { type: 'edit', data });
  }

  onError({ data }) {
    const { non_field_errors, messages } = data;
    if (non_field_errors) {
      notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
    } else if (messages) {
      notifier.error(data.messages.map(err => <span>{err.text}</span>));
    } else {
      notifier.error(text.GENERAL_ERROR);
    }
  }

  setActiveTab = (active) => {
    const { actions } = this.props;
    actions.resetFilterStore();
    this.setState({ active });
  }

  createFilter = () => {
    const { 
      campaignsTable, 
      reasonsTable, 
      campaignsActions, 
      reasonsActions,
    } = this.props;
    const { active } = this.state;
    const isCampaignActive = active === campaignsConfig.tableKey;

    const activeTable = isCampaignActive ? campaignsTable : reasonsTable;
    const activeActions = isCampaignActive ? campaignsActions : reasonsActions;
    
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = activeTable;
    const filters = getActiveFilters(activeTable);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    return {
      view: activeTable.options.view,
      segments,
      fields: activeTable.fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: activeActions.updateFilter,
      onChangeSegment: activeActions.changeSegment,
      refreshOptions: activeActions.fetchOptions,
      tableModifier: campaignModifier(this.tableActions),
    };
  }

  createNewCampaign = () => (
    <If condition={hasAccess('bonuses.campaigns.create')}>
      <Link to={generatePath(appRoutes.SETTINGS_BONUS_CAMPAIGN_CREATE)}>
        <Button startPropagation>{text.CREATE_NEW_CAMPAIGN}</Button>
      </Link>
    </If>
  )

  createNewReason = () => {
    const { overlay } = this.props;

    return (
      <If condition={hasAccess('bonuses.reasons.create')}>
        <Button onClick={() => overlay.open(REASON_SIDEPANEL_ID, { type: 'add' })}>
          {text.CREATE_NEW_REASON}
        </Button>
      </If>
    );
  }

  createNewRule = () => (
    <If condition={hasAccess('settings.bonus_expiry_rules.create')}>
      <Link to={generatePath(appRoutes.SETTINGS_BONUS_CANCELLATION_RULES_CREATE)}>
        <Button startPropagation>{text.CREATE_NEW_CANCELLATION_RULE}</Button>
      </Link>
    </If>
  )

  getHeaderActions = () => {
    const { active } = this.state;

    return ({
      [campaignsConfig.tableKey]: this.createNewCampaign,
      [reasonsConfig.tableKey]: this.createNewReason,
      [cancellationRulesConfig().tableKey]: this.createNewRule,
    })[active];
  }

  getTabIndex = () => {
    const { active } = this.state;

    return tabs.indexOf(active);
  }

  toggleEnabled = (row, is_active) => {
    const { campaignsActions, reasonsActions } = this.props;
    const { cancellationRulesActions } = this;
    const { id } = row;
    const { active } = this.state;

    const promise = ({
      [campaignsConfig.tableKey]: () => campaignsActions.updateRowData(
        id, { is_enabled: is_active }),
      [reasonsConfig.tableKey]: () => reasonsActions.updateRowData(id, { is_active }),
      [cancellationRulesConfig().tableKey]: () => cancellationRulesActions.updateRowData(
        id, { enabled: is_active }),
    })[active];

    promise().then(() => {
      notifier.success(text.STATE_UPDATE_SUCCESS);
    }, () => {
      notifier.error(text.STATE_UPDATE_ERROR);
    });
  }

  render() {
    const {
      reasonsActions,
      reasonsTable,
    } = this.props;
    const headerActions = this.getHeaderActions();
    const filterProps = this.createFilter();
    const [CancellationRulesTable] = this.CancellationRulesGrid;
    const tabIndex = this.getTabIndex();

    return (
      <Page bread={bread} headerActions={headerActions} title={text.PAGE_TITLE}>
        <Tabs active={tabIndex}>
          <Tab title={text.CAMPAIGNS} onLoad={() => this.setActiveTab(campaignsConfig.tableKey)}>
            <Filters {...filterProps} />
            <CampaignTable
              singleActions
              tableModifier={campaignModifier(this.tableActions)} 
              {...this.props}
            />
          </Tab>

          <Tab
            title={text.REASONS} 
            visible={isFeatureEnabled('MANUAL_BONUS_REASON') && hasAccess('bonuses.reasons.list')}
            onLoad={() => this.setActiveTab(reasonsConfig.tableKey)}
          >
            <Filters {...filterProps} />
            <ReasonsTable
              singleActions
              tableModifier={reasonsModifier(this.tableActions)} 
              {...this.props} 
            />
            <AddEditBonusReason
              onSuccess={this.onSuccessRequest(reasonsActions, reasonsTable)}
            />
          </Tab>
          <Tab
            title={text.CANCELLATION_RULES}
            visible={hasAccess('settings.bonus_expiry_rules.list')}
            onLoad={() => this.setActiveTab(cancellationRulesConfig().tableKey)}
          >
            <CancellationRulesTable
              singleActions
              modifier={cancellationRulesModifier(this.tableActions)} 
            />
          </Tab>
        </Tabs>
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ overlays, pages }) => ({ 
  campaignsTable: pages[campaignsConfig.reducerKey].tables[campaignsConfig.tableKey],
  reasonsTable: pages[reasonsConfig.reducerKey].tables[reasonsConfig.tableKey],
  cancellationRulesTable: pages[reasonsConfig.reducerKey].tables[cancellationRulesConfig().tableKey],
  reasonSidepanel: overlays[REASON_SIDEPANEL_ID],
});

const mapDispatchToProps = dispatch => ({
  campaignsActions: bindActionCreators({
    ...campaignsActions,
  }, dispatch),
  reasonsActions: bindActionCreators({
    ...reasonsActions,
  }, dispatch),
  overlay: bindActionCreators({
    open: overlayActions.open,
  }, dispatch),
  actions: bindActionCreators({
    resetFilterStore,
  }, dispatch),
  dispatch,
});

const BonusPage = compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(Bonus);

export default {
  component: BonusPage,
  aclId: 'bonuses.campaigns.list',
  path: appRoutes.SETTINGS_BONUS,
};
