import React from 'react';
import { HyperLink } from 'tc-biq-design-system';

import hideCols from '../../../../../logic/utilities/hideCols';
import { ToggleCell, UsernameCell } from '../../../../../components/gridCellRenderers';
import { gettext } from '../../../../../logic/utilities/languageUtility';

// eslint-disable-next-line react/prop-types
const LinkCell = ({ link, text }) => (
  <HyperLink>
    <a href={link}>{text || link}</a>
  </HyperLink>
);

const text = {
  DOCUMENT_TYPE: gettext('Document type'),
  RELATED_TO: gettext('Related to'),
};

export default ({ onToggle, onBiqUserPreview }, getLanguage) => ({
  ...hideCols(['id']),
  is_active: {
    pinned: 'left',
    width: 120,
    cellRendererFramework: ToggleCell(onToggle),
  },
  group: {
    headerName: text.DOCUMENT_TYPE,
  },
  language: {
    // eslint-disable-next-line react/prop-types
    cellRendererFramework: ({ data: { language } }) => <span>{getLanguage(language)}</span>,
  },
  created_by: { 
    cellRendererFramework: UsernameCell(onBiqUserPreview, { isStaff: true }),
  },
  link: {
    // eslint-disable-next-line react/prop-types
    cellRendererFramework: ({ data: { link } }) => <LinkCell link={link} />,
  },
  type: {
    headerName: text.RELATED_TO,
  },
  file: {
    quickFilter: {},
    // eslint-disable-next-line react/prop-types
    cellRendererFramework: ({ data: { file } }) => <LinkCell link={file} />,
  },
});
