import React from 'react';
import { bindActionCreators } from 'redux';
import { object, bool } from 'prop-types';
import { Sidepanel, notifier } from 'tc-biq-design-system';

import { SidepanelFooter } from '../../../../../../../components/common';
import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import { FormActionsFactory } from '../../../../../../../components/form';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import EditMifidContent from './EditMifidContent';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import to from '../../../../../../../logic/utilities/to';

const FORM_KEY = 'EDIT_MIFID_FORM';

const { update } = FormActionsFactory(FORM_KEY);

const text = {
  SUCCESS: gettext('User edited.'),
  ERROR: gettext('Error editing user.'),
  ERROR_GENERAL: gettext('Something went wrong!'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_KEY}
  />
);

const propTypes = {
  submitInProgress: bool,
  sidepanelManager: object,
  user: object,
  actions: object,
};

const defaultProps = {
  submitInProgress: false,
  sidepanelManager: null,
  user: null,
  actions: {},
};

const EditMifid = ({
  submitInProgress, 
  sidepanelManager,
  user,
  actions,
  ...options
}) => {
  const onClose = () => {
    sidepanelManager.close();
  };

  const onSubmit = async () => {
    const api = getDjangoApi('users');
    const updatePart = true;
    
    const getPayload = (values) => {
      const { priorities, nationality } = values;
      const nationalityValue = nationality.value;
      const nic_fields = priorities.map(field => ({ ...field, nationality: nationalityValue }));

      return {
        nic_fields,
        nationality: nationalityValue,
      };
    };

    const onError = (payload) => {
      const errorData = _.get(payload, 'data'); 
      if (!errorData) notifier.error(text.ERROR_GENERAL);
      if (errorData) {
        const nonFieldErrors = _.get(payload, 'data.non_field_errors[0]');
        const messages = _.get(payload, 'data.messages[0].text') || nonFieldErrors;
        messages && notifier.error(messages);
      } else {
        notifier.error(text.ERROR_GENERAL);
      }
    };
  
    const onSuccess = () => {
      notifier.success(text.SUCCESS);
      sidepanelManager.close();
    };
    
    const [err] = await to(actions.update(api, user.id, getPayload, updatePart));
    err ? onError(err) : onSuccess();
  };

  return (
    <Sidepanel {...options} footerRender={customFooter(onSubmit, onClose, submitInProgress)}>
      <EditMifidContent 
        user={user} 
        sidepanelManager={sidepanelManager} 
      />
    </Sidepanel>
  );
};

EditMifid.propTypes = propTypes;
EditMifid.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      update,
      fetchUser,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ forms }) => {
  const form = forms[FORM_KEY];

  return {
    formValues: form.values,
    submitInProgress: form.submitInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMifid);
