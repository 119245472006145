import React, { Component, Fragment } from 'react';
import { notifier } from 'tc-biq-design-system';
import { object, func, string } from 'prop-types';

import withTable, { GridTab } from '../../../../../components/grid/withTable';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import to from '../../../../../logic/utilities/to';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import MappingSidepanel from './MappingSidepanel';
import defaultModifiers from './modifiers';
import { hasAccess } from '../../../../../logic/services/acl';

export const reducerKey = 'USER_STATE_MAPPING';

const text = {
  SUCCESS: gettext('Mapping deleted'),
  ERROR: gettext('Mapping delete failed'),
};

const tableConfig = tableKey => () => ({
  reducerKey,
  apiUrl: 'kyc/user_state_mapping',
  tableKey,
});

const api = getDjangoApi(tableConfig()().apiUrl);

const propTypes = {
  actions: object.isRequired,
  dispatch: func.isRequired,
  tableKey: string.isRequired,
  filterOptions: object.isRequired,
  tableModifiers: func.isRequired,
  sidepanelId: string.isRequired,
  formId: string.isRequired,
  getFormConfig: func.isRequired,
  getRequestPayload: func.isRequired,
  formModifiers: object.isRequired,
  table: object.isRequired,
};

class MappingTable extends Component {
  constructor(props) {
    super(props);
    const { tableKey, filterOptions, tableModifiers } = props;
    this.Grid = withTable(GridTab, tableConfig(tableKey), null, { 
      withActions: true,
      filterOptions,
    })();
    this.actions = {
      modify: hasAccess('kyc.user_state_mapping.update') && this.onEdit,
      remove: hasAccess('kyc.user_state_mapping.destroy') && this.onRemove,
    };
    this.modifier = { ...defaultModifiers(this.actions), ...tableModifiers() };
  }

  onRemove = async ({ id }) => {
    const [err] = await to(api.destroy(id));
    if (err) {
      notifier.error(text.ERROR);
    } else {
      notifier.success(text.SUCCESS);
      this.reloadTable();
    }
  }

  onEdit = (data) => {
    const { actions, sidepanelId } = this.props;
    actions.openSidepanel(sidepanelId, { data });
  }

  reloadTable = () => {
    const { dispatch, table } = this.props;
    const [, tableActions] = this.Grid;
    dispatch(tableActions.fetchTableData(table.query));
  }

  render() {
    const [Table] = this.Grid;
    const { formId, sidepanelId, getFormConfig, getRequestPayload, formModifiers } = this.props;
    return (
      <Fragment>
        <Table
          singleActions
          modifier={this.modifier}
        />
        <MappingSidepanel
          sidepanelId={sidepanelId}
          formId={formId}
          onSuccess={this.reloadTable}
          getFormConfig={getFormConfig}
          formModifiers={formModifiers}
          getRequestPayload={getRequestPayload}
        />
      </Fragment>
    );
  }
}

MappingTable.propTypes = propTypes;

export default MappingTable;
