import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { object } from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router';

import appRoutes from '../../components/App/Router/appRoutes';
import { getDjangoApi } from '../../logic/services/api-factory';
import { loginAuth0 } from '../../logic/services/authentication';
import { getUrlParameter } from '../../logic/utilities/state-param';
import { loadAuthSession, clearPostLoginRoute } from '../../logic/session/Model';

const api = getDjangoApi('auth0_login', '*');

const propTypes = {
  actions: object.isRequired,
  history: object.isRequired,
};

const Auth = ({ actions, history }) => {
  useEffect(() => {
    const code = getUrlParameter('code');
    const postLoginRoute = getUrlParameter('post_login_route');
    if (code) {
      loginAuth0(code, api).then(() => {
        actions.loadAuthSession().then(() => {
          if (postLoginRoute) {
            actions.clearPostLoginRoute();
            history.push(postLoginRoute);
          } else {
            history.push(appRoutes.DASHBOARD);
          }
        });
      });
    }
  }, []);

  return <span />;
};

Auth.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    loadAuthSession,
    clearPostLoginRoute,
  }, dispatch),
});

export default {
  component: compose(
    connect(null, mapDispatchToProps),
    withRouter,
  )(Auth),
  anonymousAccess: true,
  path: appRoutes.AUTH,
};
