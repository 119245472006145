import { StateCell, UsernameCell } from '../../../components/gridCellRenderers';
import hideCols from '../../../logic/utilities/hideCols';

export const commonModifiers = {
  ...hideCols([
    'id',
  ]),
};

export const KYCHistoryModifiers = ({ previewUser, previewBiqUser }) => ({
  ...commonModifiers,
  username: {
    pinned: 'left',
    cellRendererFramework: UsernameCell(previewUser),
  },
  verified_by: {
    width: 200,
    cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
  },
});

export const CheckLogModifiers = previewUser => ({
  ...commonModifiers,
  total_result: { cellRendererFramework: StateCell },
  user: {
    pinned: 'left',
    cellRendererFramework: UsernameCell(previewUser),
  },
});

export const KYCCheckLogModifiers = ({ previewUser }) => ({
  ...CheckLogModifiers(previewUser),
  address: { cellRendererFramework: StateCell },
  date_of_birth: { cellRendererFramework: StateCell },
  legal_and_regulatory_warnings: { cellRendererFramework: StateCell },
  politically_exposed_person: { cellRendererFramework: StateCell },
  mortality: { cellRendererFramework: StateCell },
  sanction: { cellRendererFramework: StateCell },
});

export const DocumentCheckLogModifiers = ({ previewUser }) => ({
  ...CheckLogModifiers(previewUser),
  status: { cellRendererFramework: StateCell },
  data_comparison: { cellRendererFramework: StateCell },
  data_consistency: { cellRendererFramework: StateCell },
  data_validation: { cellRendererFramework: StateCell },
  visual_authenticity: { cellRendererFramework: StateCell },
  police_record: { cellRendererFramework: StateCell },
  image_integrity: { cellRendererFramework: StateCell },
});
