import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../logic/services/api-factory';

export const teamApi = getDjangoApi('teams');
const initialState = {
  team: {},
  members: [],
  isLoading: false,
  options: {},
  optionsIsLoading: false,
  isUpdateLoading: false,
};


const actionTypes = {
  FETCH_TEAM: 'FETCH_TEAM',
  FETCH_TEAM_PENDING: 'FETCH_TEAM_PENDING',
  FETCH_TEAM_FULFILLED: 'FETCH_TEAM_FULFILLED',
  UPDATE_TEAM: 'UPDATE_TEAM',
  UPDATE_TEAM_PENDING: 'UPDATE_TEAM_PENDING',
  UPDATE_TEAM_FULFILLED: 'UPDATE_TEAM_FULFILLED',
  UPDATE_TEAM_FAILED: 'UPDATE_TEAM_FAILED',
  FETCH_TEAM_OPTIONS: 'FETCH_TEAM_OPTIONS',
  FETCH_TEAM_OPTIONS_PENDING: 'FETCH_TEAM_OPTIONS_PENDING',
  FETCH_TEAM_OPTIONS_FULFILLED: 'FETCH_TEAM_OPTIONS_FULFILLED',
  SET_MEMBERS: 'SET_MEMBERS',
};

const loadTeam = createAction(actionTypes.FETCH_TEAM, async (id) => {
  const { data } = await teamApi.retrieve(id);
  return { data };
});

const updateTeam = createAction(actionTypes.UPDATE_TEAM, 
  async (id, data) => teamApi.updatePart(id, data));

const loadTeamOptions = createAction(actionTypes.FETCH_TEAM_OPTIONS, async (id) => {
  const { data } = await teamApi.options(id);
  return { data };
});

const setMembers = createAction(actionTypes.SET_MEMBERS, members => members);

export {
  loadTeam,
  loadTeamOptions,
  setMembers,
  updateTeam,
};

export default handleActions({
  [actionTypes.FETCH_TEAM_PENDING]: state => ({ ...state, isLoading: true }),
  [actionTypes.FETCH_TEAM_FULFILLED]: (state, { payload: { data } }) => ({
    ...state,
    isLoading: false, 
    team: data,
    members: data.members,
  }),
  [actionTypes.UPDATE_TEAM_PENDING]: state => ({ ...state, isUpdateLoading: true }),
  [actionTypes.UPDATE_TEAM_FULFILLED]: state => ({ ...state, isUpdateLoading: false }),
  [actionTypes.UPDATE_TEAM_FAILED]: state => ({ ...state, isUpdateLoading: false }),
  [actionTypes.FETCH_TEAM_FULFILLED]: (state, { payload: { data } }) => ({
    ...state,
    isLoading: false, 
    team: data,
    members: data.members,
  }),
  [actionTypes.SET_MEMBERS]: (state, { payload }) => ({
    ...state,
    members: payload,
  }),
  [actionTypes.FETCH_TEAM_OPTIONS_PENDING]: state => ({ ...state, optionsIsLoading: true }),
  [actionTypes.FETCH_TEAM_OPTIONS_FULFILLED]: (state, { payload: { data } }) => ({
    ...state,
    optionsIsLoading: false, 
    options: data,
  }),
}, initialState);
