/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import { withRouter } from 'react-router-dom';
import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import Page from '../../../../components/Page';
import UserPreview from '../../../../components/common/sidepanels/UserPreview';
import Export from '../../../../components/Export';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import modifiers from './modifiers';
import TradeSingle from './sidepanels/TradeSingle';
import overlayActions from '../../../../components/overlay';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import If from '../../../../components/If';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';
import { toSingleItemUrl } from '../../../../logic/utilities/toPath';

const pageConfig = {
  apiUrl: 'trades',
  reducerKey: 'TRADES',
  tableKey: 'TRADES_TABLE',
};

const text = {
  TITLE: gettext('All Trades'),
};

const tradesApi = getDjangoApi('trades');
const SIDEPANEL_ID = 'TRADE_SINGLE';
const { GridComponent, actions } = GridFactory(pageConfig);
const bread = [{ label: text.TITLE, route: appRoutes.ALL_TRADES }];

class Trades extends Component {
  constructor(props) {
    super(props);

    this.headerActions = this.headerActions.bind(this);

    this.tableActions = {
      previewInSidepanel: this.openTradeSingle.bind(this),
      onUserPreview: props.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
    };

    const id = props.match.params.id;
    if (id) this.openTradeSingle({ id });
  }

  async openTradeSingle(data) {
    const { actions } = this.props;
    const response = await tradesApi.retrieve(data.id);
    actions.openSidepanel(SIDEPANEL_ID, response.data);
    window.history.replaceState(null, '', toSingleItemUrl(data.id));
  }

  headerActions() {
    const { exportUrl } = this.props;
    return (
      <If condition={hasAccess('trade.export.*')}>
        <Export url={exportUrl} />
      </If>
    );
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifiers(this.tableActions),
    };

    return (
      <Page headerActions={this.headerActions} bread={bread} title={text.TITLE}>
        <Filters {...filterProps} />
        <GridComponent tableModifier={modifiers(this.tableActions)} {...this.props} />
        <TradeSingle />
        <UserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(Trades); 
