import React from 'react';
import { Pill } from 'tc-biq-design-system';
import { object } from 'prop-types';
import { statePillTypeMap } from './StateCell';

const propTypes = {
  data: object.isRequired,
};

const DocsApprovedCell = ({ data }) => {
  if (!data.docs_approved) return null;
  return (
    <div>
      {[].concat(data.docs_approved).map(status => (
        <Pill key={status} type={statePillTypeMap[status]}>{status}</Pill>
      ))}
    </div>
  );
};

DocsApprovedCell.propTypes = propTypes;

export default DocsApprovedCell;
