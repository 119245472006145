import { arrayOf, node, oneOfType, string } from 'prop-types';
import React from 'react';

const GridWrapper = ({ children, height }) => <div style={{ height }} className="biq-grid__wrapper">{children}</div>;

GridWrapper.propTypes = {
  children: oneOfType([
    node,
    arrayOf(node),
  ]).isRequired,
  height: string,
};

GridWrapper.defaultProps = {
  height: '350px',
};

export default GridWrapper;
