import fieldPrimitiveType from '../filters/field-primitive-type';
import QUERY_OPERATORS from '../enums/query-operators';
import API_DATA_TYPE from '../enums/api-data-type';
import FILTER_TYPE from '../enums/filter-type';

/* eslint-disable */

import { getChoices, parseFilterKey } from '../../components/Filters/filterUtils';

/**
 * QueryAdapterService
 *
 */

export default {
  isSimpleField,
  setupFields,
  decompileFromApi,
  compileToApi,
  changeFilterForColumn,
  newQueryBuilderObject,
  queryFilterToObject,
}

export function isFilterValid(filter) {
  return filter && filter.hasOwnProperty('condition') && filter.hasOwnProperty('rules');
}

/**
 * @param fields
 * @param filter (optional)
 * @returns QueryBuilder instance {Object}
 *  with public members:
 *    fields {Object}, filter {Object}
 *  with public methods
 *    toApi {function, parameters: none}
 * @example
 * index.js:
 * $scope.queryBuilderObject = QueryAdapterService.newQueryBuilderObject(rawFields, rawQuery);
 * $scope.save = function(){
 *   var request = $scope.queryBuilderObject.toApi();
 *   SomeApi.save(request)
 * }
 *
 * template.html:
 * <div tc-query-builder fields="queryBuilderObject.fields" group="queryBuilderObject.filter" read-only="false"></div>
 */
export function newQueryBuilderObject(fields, filter) {
  const _fields = setupFields(fields || {});


  const _filter = decompileFromApi(_fields, isFilterValid(filter) ? filter : {
    condition: 'AND',
    rules: [],
  });

  return {
    fields: _fields,
    filter: _filter,
    toApi,
  };

  function toApi() {
    return compileToApi(_filter);
  }
}

function _extractOperatorFromKey(queryKey) {
  const { operator } = parseFilterKey(queryKey);
  return operator;
}

/* 
  @Deprecated
  TODO
  This whole function needs to be rafactored is too complicated and hard to understand
  or i'm just stupid as fuck.
  @pavle Simplify this function because it's actually not doing that much, just needs a bunch of testing edge cases
*/
export function queryFilterToObject(queryKey, value) {
  const numberTest = new RegExp(/^\d+$/);
  if (numberTest.test(value)) value = Number(value);
  const queryKeyOperator = _extractOperatorFromKey(queryKey);
  // operator and key setup
  let operator = null;
  let key = null;
  let operatorLabel = null;
  for (const operatorKey in QUERY_OPERATORS) { // eslint-disable-line
    // First check in this if seems redundant since it's iterating over the object, not sure why that's there
    if (QUERY_OPERATORS.hasOwnProperty(operatorKey) && queryKeyOperator === QUERY_OPERATORS[operatorKey].value) { // eslint-disable-line
      operator = QUERY_OPERATORS[operatorKey].value;
      operatorLabel = QUERY_OPERATORS[operatorKey].label;
      // This returns the part before __operator, which should be the field key
      key = queryKey.substring(0, queryKey.length - QUERY_OPERATORS[operatorKey].value.length - 2);
      break;
    }
  }
  if (!operator || !key) {
    key = queryKey;
    operator = QUERY_OPERATORS.EXACT.value;
  }


  // value setup, maps arguments to be comma separated if the input is a list
  let val = value;
  if (operator === QUERY_OPERATORS.IN.value || operator === QUERY_OPERATORS.NOT_IN.value) {
    val = value.split(',').map((v) => {
      if (!_.isNaN(+v)) {
        return _.toNumber(v);
      }
      return v;
    });
  }

  // same as above, just for range operator
  if (operator === QUERY_OPERATORS.RANGE.value) {
    val = value.split(',').map((v) => {
      if (!_.isNaN(+v)) {
        return _.toNumber(v);
      }
      if (moment(v).isValid()) {
        return moment(v).toDate();
      }
      return v;
    });
  }
    
  /* TODO:
    Temp hack. Problem was that sometimes this function return wrong key
    example:
      Returns: sales_team__id
      Expected: sales_team
    @pavle Test this and see what's happening here and why extract operator for key isn't fixing this
  */
  if (key && key.indexOf('__') !== -1) {
    key = key.substring(0, key.indexOf('__'))
  }

  return {
    operator,
    operatorLabel,
    key,
    value: val,
  };
}

export function isSimpleField(field) {
  return field
    && (_.get(field, 'choices.length') || field.type !== API_DATA_TYPE.NESTED_OBJECT)
    && field.type !== API_DATA_TYPE.GENERIC_RELATION
    && field.type !== API_DATA_TYPE.COLUMN_FIELD
    && field.type !== API_DATA_TYPE.LIST;
}

export function setupFields(fields) {
  return flattenNestedFields(fields, '.')
    .filter(f => !f.nestedColumn) // removes column_field from displaying for filtering until BO side starts working
    .map(_setFieldsFilter(null));
}

function _setFieldsFilter(parent) {
  return function (field) {
    if (field) {
      if (field.type === API_DATA_TYPE.FLOAT) {
        field.type = API_DATA_TYPE.DECIMAL;
      }

      field.keyPath = (parent && `${parent.keyPath}.` || '') + field.key;
      field.keyPathLabel = (parent && `${parent.keyPathLabel}»` || '') + field.label;
      
      if (field.choices && field.choices.length &&
        field.type !== API_DATA_TYPE.CHOICE && field.type !== API_DATA_TYPE.LIST) {
        field.type = API_DATA_TYPE.CHOICE;
      } else if (field.children && field.children.fields && field.children.fields.length) {
        field.children.fields = field.children.fields.map(_setFieldsFilter(field));
      }
    }
    return field;
  };
}

export function _fetchField(metadata, keyPath) {
  let field = _.find(metadata, { keyPath });
  if (field && isSimpleField(field)) {
    return field;
  }

  const keyPathArray = keyPath.split('.');
  field = _.find(metadata, { key: keyPathArray.shift() });
  if (keyPathArray.length && field && field.children && field.children.fields) {
    return _fetchField(field.children.fields, keyPathArray.join('.'));
  }
  return field;
}

// In react _injectRules has been replaced with _refactoredInjectRules which doesn't return
// group as a separate nested object, remove this once angular version is dead
export function decompileFromApi(metadata, predefined) {
  const decompiled = refactoredInjectRules(metadata, predefined);
  if (decompiled && decompiled.group) {
    return decompiled.group;
  }
  return decompiled;
}

// Angular compatible function for transforming fields and groups. React query builder will
// be simplified but since changes are NOT backwards compatible I'll separate it into it's own
// function to begin with
function _injectRules(metadata, predefined) {
  if (predefined.hasOwnProperty('condition') && predefined.hasOwnProperty('rules')) {
    // its a group
    const group = {
      condition: predefined.condition,
      rules: predefined.rules.map(rule => _injectRules(metadata, rule)).filter(rule => !!rule),
    };
    return {
      group,
    };
  }
  // it's a rule
  const rule = {
    field: _fetchField(metadata, predefined.field),
    operator: predefined.operator,
    value: predefined.value,
  };
  return rule;
}

// Function for transforming fields and groups to a structure that's easier to navigate and
// manipulate conditions object for react query builder primarily. Once angular codebase is
// removed this function should be renamed back to _injectRules
export function refactoredInjectRules(metadata, predefined) {
  if (!predefined || !Object.keys(predefined).length) {
    return _getRuleToInject('group');
  }

  if (predefined.hasOwnProperty('condition') && predefined.hasOwnProperty('rules')) {
    return {
      identifier: _.uniqueId('group'),
      condition: predefined.condition,
      rules: predefined.rules.map(rule => refactoredInjectRules(metadata, rule)),
    };
  }

  return {
    field: _fetchField(metadata, predefined.field),
    identifier: _.uniqueId('condition'),
    operator: _.find(QUERY_OPERATORS, { value: predefined.operator }),
    value: predefined.value,
  }
}

function _getRuleToInject(type) {
  if (type === 'group') {
    return { condition: 'AND', rules: [], identifier: _.uniqueId('group') }
  }
  return { field: {}, operator: {}, value: '', identifier: _.uniqueId('condition') }
}

export function insertQueryRule(conditionTree, type, identifier) {
  let targetObject = Object.assign({}, conditionTree);

  function _findTargetObject(rules) {
    rules.forEach((rule) => {
      if (rule.identifier === identifier) {
        const ruleToInsert = _getRuleToInject(type);
        rule.rules.push(ruleToInsert);
        return;
      }

      if (rule.hasOwnProperty('rules')) _findTargetObject(rule.rules);
    })
  }

  if (targetObject.identifier === identifier) {
    const ruleToInsert = _getRuleToInject(type);
    targetObject.rules.push(ruleToInsert);
  } else {
    _findTargetObject(targetObject.rules);
  }

  return targetObject;
}

export function removeQueryRule(conditionTree, identifier) {
  let targetObject = Object.assign({}, conditionTree);

  function _findObjectToRemove(rules) {
    rules.forEach((rule, index) => {
      if (rule.identifier === identifier) {
        rules.splice(index, 1);
      } else if (rule.hasOwnProperty('rules')) {
        _findObjectToRemove(rule.rules);
      }
    });
  }

  if (targetObject.identifier === identifier) {
    targetObject.rules = [];
  } else {
    _findObjectToRemove(targetObject.rules);
  }

  return targetObject;
}

function _updateProperties(object, updatedProperties) {
  for (let property in updatedProperties) {
    object[property] = updatedProperties[property];
  }
}

export function getUpdatedTreeState(conditionTree, updatedProperties, identifier) {
  let targetObject = Object.assign({}, conditionTree);

  function _findTargetObject(rules) {
    rules.forEach((rule) => {
      if (rule.identifier === identifier) {
        _updateProperties(rule, updatedProperties);
        return;
      }

      if (rule.hasOwnProperty('rules')) _findTargetObject(rule.rules);
    })
  }

  if (targetObject.identifier === identifier) {
    _updateProperties(targetObject, updatedProperties);
  } else {
    _findTargetObject(targetObject.rules);
  }

  return targetObject;
}

function _refactoredExtractCondition(rule) {
  // Check the old extractRule function and the if conditions at the beginning and why or if they
  // are needed at all.
  if (!rule
    || !rule.field
    || !rule.operator
    || !((rule.value || rule.value === 0) || (rule.field.type === API_DATA_TYPE.BOOLEAN && rule.value === false))) {
    return;
  }

  const value = normalizeRuleValue(rule);

  return {
    value,
    field: rule.field.keyPath,
    operator: rule.operator.value,
    type: fieldPrimitiveType()(rule.value, rule.field.type, rule.field.choices),
  };
}

export function refactoredExtractGroup(conditionTree) {
  if (conditionTree.hasOwnProperty('rules') && conditionTree.rules.length) {
    const rules = conditionTree.rules.map(rule => {
      if (rule.hasOwnProperty('rules') && rule.rules.length) {
        return refactoredExtractGroup(rule);
      }
      return _refactoredExtractCondition(rule);
    }).filter(rule => rule);

    if (rules && rules.length) {
      return {
        condition: conditionTree.condition,
        rules,
      };
    }
  }
}

const getKeyField = (choices, field) => (choices || field.key_field || (field.child && field.child.key_field) ? 'value' : 'display_name');

const normalizeRuleValue = (rule) => {
  if(!rule) null;
  let value = rule.value;
  if (_.isObject(value)) {
    const choices = getChoices(rule.field);
    let key = getKeyField(choices, rule.field);

    if (_.isArray(value)) {
      key = rule.field.type === API_DATA_TYPE.STRING ? 'value' : key;
      value = value.map(v => _.isObject(v) ? v[key] : v);
    } else {
      value = value[key];
    }
  }

  return value;
}

// Remove after angular version is dead, it's been replaced with refactoredExtractGroup
export function compileToApi(group) {
  return _extractGroup(group);
}

// To be removed once angular version is dead, also rename the function refactoredExtractGroup to
// extractGroup and make it public since I killed one level of nesting that was unnecessary
function _extractGroup(group) {
  if (group && group.rules) {
    const rules = group.rules.map((item) => {
      if (item.hasOwnProperty('group')) {
        return _extractGroup(item.group);
      }
      return _extractRule(item);
    });

    if (rules && rules.length) {
      return {
        condition: group.condition,
        rules,
      };
    }
  }
  return null;
}

// Angular version of field extraction, remove once we go fully to react
function _extractRule(rule) {
  if (rule
    && rule.field
    && rule.operator
    && ((rule.value || rule.value === 0) || (rule.field.type === API_DATA_TYPE.BOOLEAN && rule.value === false))) {
    return {
      field: rule.field.keyPath,
      operator: rule.operator.value,
      value: rule.value,
      type: fieldPrimitiveType()(rule.value, rule.field.type, rule.field.choices),
    };
  }
  return null;
}

export const isNestedField = (field) => {
  if (!field) return null;

  return field.children 
    && (field.type === API_DATA_TYPE.NESTED_OBJECT 
    && _.isEmpty(field.list_url)
    && _.isEmpty(field.choices)
  ) 
  || field.type === API_DATA_TYPE.GENERIC_RELATION;
}

export function flattenNestedFields(filterFields, nestedSeparator = '__') {
  const flatFields = [];

  const parseParents = (parents, { key, label }) => parents.reduce((acc, parent) => ({
    label: `${parent.label} / ${acc.label}`,
    key: `${parent.key}${nestedSeparator}${acc.key}`,
  }), { label, key });

  const flattener = (fields, parents = []) => {
    fields.forEach((field) => {
      if (isNestedField(field)) {
        const { children: { fields: childrenFields } } = field;
        flattener(childrenFields, [field, ...parents]);
      } else {
        const overrides = parseParents(parents, field);
        const finalField = { ...field, ...overrides };
        flatFields.push({
          ...finalField,
          key: finalField.key_field ? `${finalField.key}${nestedSeparator}${finalField.key_field}` : finalField.key,
        });
      }
    });
  };

  flattener(filterFields);

  return flatFields;
}

/* UI GRID */
export function changeFilterForColumn(column, filterQuery) {
  const filterKey = column.filterKey || column.key;

  if (column.filter) {
    const type = column.filter.type;


    const term = column.filter.term;
    switch (type) {
      case FILTER_TYPE.TEXTBOX_TEXT:
        _setQueryPair(filterQuery, `${filterKey}__${QUERY_OPERATORS.ICONTAINS.value}`, term);
        break;
      case FILTER_TYPE.TEXTBOX_NUMBER:
      case FILTER_TYPE.DROPDOWN_SEARCHABLE:
      case FILTER_TYPE.DROPDOWN_REQUEST:
        _setQueryPair(filterQuery, `${filterKey}__${QUERY_OPERATORS.EXACT.value}`, term);
        break;
      case FILTER_TYPE.DATEPICKER:
      case FILTER_TYPE.DATEPICKER_TIME:
        if (!_.isEmpty(term)) {
          // TODO@gva: timezone handling, but BE doesn't have datetime enabled yet
          // valueFrom = moment(term[0]).startOf('day').utc().format();
          // valueTo   = moment(term[1]).endOf('day').utc().format();
          const { value, queryKey } = _setQueryKeyAndValue(term, filterKey);
          _setQueryPair(filterQuery, queryKey, value);
        } else {
          const keys = _.keys(filterQuery);
          _.forEach(keys, (key) => {
            if (key === `${filterKey}__${QUERY_OPERATORS.GTE.value}` || key === `${filterKey}__${QUERY_OPERATORS.LTE.value}` || key === `${filterKey}__${QUERY_OPERATORS.RANGE.value}`) {
              delete filterQuery[key];
            }
          });
        }
        break;
      default:
        _setQueryPair(filterQuery, filterKey, term);
    }
  }
}

function _setQueryPair(filterQuery, key, value) {
  if (value && value !== 'null') {
    filterQuery[key] = value;
  } else if (filterQuery[key] !== undefined || value === 'null') {
    delete filterQuery[key];
  }
}

function _setQueryKeyAndValue(term, filterKey) {
  if (term[0] && term[1]) {
    return {
      value: `${moment(term[0]).format('YYYY-MM-DD')},${moment(term[1]).format('YYYY-MM-DD')}`,
      queryKey: `${filterKey}__${QUERY_OPERATORS.RANGE.value}`,
    };
  }
  return {
    value: term[0] ? `${moment(term[0]).format('YYYY-MM-DD')}` : `${moment(term[1]).format('YYYY-MM-DD')}`,
    queryKey: term[0] ? `${filterKey}__${QUERY_OPERATORS.GTE.value}` : `${filterKey}__${QUERY_OPERATORS.LTE.value}`,
  };
}
