import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { object, bool } from 'prop-types';
import { notifier, Space, Sidepanel } from 'tc-biq-design-system';

import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import { unarchiveUser } from './Model';
import { generatePath, Link } from 'react-router-dom';
import appRoutes from '../../../../../../../components/App/Router/appRoutes';

const text = {
  TITLE: gettext('Unrchive user'),
  QUESTION: gettext('Are you sure you want to unarchive this user?'),
  SUCCESS: gettext('User successfully unarhived.'),
  ERROR: gettext('Error archiving user.'),
  DOUBLICATE_ERROR: gettext('Unable to unarchive this user as email is not unique. Please review user(s) with same email:'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  submitInProgress: bool.isRequired,
  isIb: bool,
};

const defaultProps = {
  user: null,
  actions: null,
  isIb: false,
};

const Unarchive = ({ sidepanelManager, user, isIb, actions, submitInProgress, ...options }) => {
  const onClose = () => {
    sidepanelManager.close();
  };

  const onSubmit = () => {
    actions.unarchiveUser(user, isIb).then(() => {
      actions.fetchUser(user, isIb);
      notifier.success(text.SUCCESS);
      sidepanelManager.close();
    }).catch(({ data }) => {
      const { messages } = data;
      if (messages) {
        messages.forEach((msg) => {
          switch (msg?.text) {
            case 'username or email already taken':
              notifier.error(
                <Fragment>
                  {text.DOUBLICATE_ERROR}
                  {msg.extra?.duplicate_users?.map(user => <div><a href={generatePath(`${window.config.api.host}${window.config.frontendRoot}${appRoutes.CLIENT_PROFILE}`, { id: user.id })}>- user {user.username}</a></div>)}
                </Fragment>);
              break;
            default:
              notifier.error(msg);
              break;
          }
        });
      } else {
        notifier.error(text.ERROR);
      }
    });
  };

  return (
    <Sidepanel {...options} footerRender={customFooter(onSubmit, onClose, submitInProgress)}>
      <Space size={16} />
      <div>{text.QUESTION}</div>
    </Sidepanel>
  );
};

Unarchive.propTypes = propTypes;
Unarchive.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      unarchiveUser,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const action = page.actions.UNARCHIVE_USER;
  return {
    submitInProgress: action.submitInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Unarchive);
