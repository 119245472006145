import { handleActions } from 'redux-actions';

import FormActionTypesFactory from './actionTypesFactory';
import { initiateValues } from './utils';

export const initialState = {
  fields: [],
  isLoading: false,
  errors: {},
  data: {},
  values: {},
  dirty: {},
  validators: {},
  submitInProgress: false,
};

export const FormReducerFactory = (form) => {
  const {
    FORM_OPTIONS,
    FORM_OPTIONS_PENDING,
    FORM_OPTIONS_FULFILLED,
    FORM_RESET,
    FORM_RESET_VALUES,
    FORM_SET_ERRORS,
    FORM_SET_VALIDATORS,
    FORM_VALIDATE,
    FORM_SET_FIELD_VALUE,
    FORM_SET_ROW_DATA,
    FORM_SET_FIELDS,
    FORM_CREATE_FULFILLED,
    FORM_CREATE_PENDING,
    FORM_CREATE_REJECTED,
    FORM_UPDATE_FULFILLED,
    FORM_UPDATE_PENDING,
    FORM_UPDATE_REJECTED,
    FORM_SHOW_LOADER,
  } = FormActionTypesFactory(form);

  return handleActions(
    {
      [FORM_OPTIONS]: state => ({
        ...state,
        isLoading: true,
      }),
      [FORM_OPTIONS_PENDING]: state => ({
        ...state,
        isLoading: true,
      }),
      [FORM_OPTIONS_FULFILLED]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        fields: payload,
        values: initiateValues(state.data, payload),
      }),
      [FORM_RESET]: () => ({
        ...initialState,
      }),
      [FORM_RESET_VALUES]: state => ({
        ...state,
        values: {},
      }),
      [FORM_SET_ERRORS]: (state, { payload }) => ({
        ...state,
        errors: payload.errors,
      }),
      [FORM_SET_VALIDATORS]: (state, { payload }) => ({
        ...state,
        validators: payload,
      }),
      [FORM_VALIDATE]: (state, { payload }) => ({
        ...state,
        errors: payload,
      }),
      [FORM_SET_FIELD_VALUE]: (state, { payload }) => {
        const { id, value, markAsDirty } = payload;
        return { 
          ...state, 
          values: { 
            ...state.values, 
            [id]: value,
          },
          ...(markAsDirty && { dirty: {
            ...state.dirty,
            [id]: true,
          } }),
        };
      },
      [FORM_SET_ROW_DATA]: (state, { payload }) => ({
        ...state,
        data: payload,
      }),
      [FORM_SET_FIELDS]: (state, { payload }) => ({
        ...state,
        fields: payload,
        values: initiateValues(state.data, payload),
      }), 
      [FORM_CREATE_PENDING]: state => ({
        ...state,
        submitInProgress: true,
      }),
      [FORM_CREATE_FULFILLED]: (state, { payload }) => {
        const { shouldResetValuesOnSuccess } = payload;
        return {
          ...state,
          values: shouldResetValuesOnSuccess ? {} : state.values,
          data: {},
          submitInProgress: false,
        };
      },
      [FORM_CREATE_REJECTED]: (state, { payload }) => {
        const { data } = payload;
        return {
          ...state,
          submitInProgress: false,
          errors: { ...data },
        };
      },
      [FORM_UPDATE_PENDING]: state => ({
        ...state,
        submitInProgress: true,
      }),
      [FORM_UPDATE_FULFILLED]: () => ({
        ...initialState,
        submitInProgress: false,
      }),
      [FORM_UPDATE_REJECTED]: (state, { payload }) => {
        const { data } = payload;
        return {
          ...state,
          submitInProgress: false,
          errors: { ...data },
        };
      },
      [FORM_SHOW_LOADER]: (state, { payload }) => ({ ...state, isLoading: payload }),
    },
    initialState,
  );
};

export default FormReducerFactory;
