/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { notifier } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import GridFactory from '../../../../components/grid';
import Page from '../../../../components/Page';
import Export from '../../../../components/Export';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import overlayActions from '../../../../components/overlay';
import modifier from './modifiers';
import If from '../../../../components/If';
import UserPreview from '../../../../components/common/sidepanels/UserPreview';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const pageConfig = {
  apiUrl: 'kyc/unsuitable',
  reducerKey: 'KYC_UNSUITABLE',
  tableKey: 'KYC_UNSUITABLE_TABLE',
};

const text = {
  PAGE_LABEL: gettext('Unsuitable'),
  DELETE_SUCCESS: gettext('Unsuitable entry successfully deleted'),
  DELETE_FAILED: gettext('Failed to delete unsuitable entry'),
};

const { GridComponent, actions } = GridFactory(pageConfig);
const bread = [{ label: text.PAGE_LABEL, route: appRoutes.KYC_UNSIUTABLE }];

class Unsuitable extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.headerActions = this.headerActions.bind(this);
    this.deleteApi = getDjangoApi(pageConfig.apiUrl);
    this.tableActions = {
      ...(hasAccess('kyc.unsuitable.destroy') && { deleteRow: this.deleteRow.bind(this, this.deleteApi) }),
      previewUser: this.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
    };
  }

  deleteRow(api, data) {
    api
      .destroy(data.id)
      .then(
        () => notifier.success(text.DELETE_SUCCESS),
        () => notifier.error(text.DELETE_FAILED),
      )
      .finally(() => this.actions.fetchTableData());
  }

  headerActions() {
    const { exportUrl } = this.props;
    return (
      <If condition={hasAccess('kyc.unsuitable.export.*')}>
        <Export url={exportUrl} />
      </If>
    );
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions),
    };

    return (
      <Page headerActions={this.headerActions} bread={bread} title={text.PAGE_LABEL}>
        <Filters {...filterProps} />
        <GridComponent
          tableModifier={modifier(this.tableActions)}
          singleActions={!!Object.keys(this.tableActions).length}
          {...this.props}
        />
        <UserPreview />
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchTableData: actions.fetchTableData,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const table = pages[reducerKey].tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
    tableQuery: table.query,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(Unsuitable);
