import React from 'react';
import { Tooltip, Popconfirm, Icon } from 'tc-biq-design-system';
import appRoutes from '../../../../components/App/Router/appRoutes';

import { ActionsCellWrapper, MultiRouteCell, UsernameCell } from '../../../../components/gridCellRenderers';
import queryOperators from '../../../../logic/enums/query-operators';
import { gettext } from '../../../../logic/utilities/languageUtility';

const text = {
  DELETE: gettext('Delete'),
  CANCEL: gettext('Cancel'),
  ARE_YOU_SURE: gettext('You are about to delete view'),
};

const actionIcons = {
  remove: (actionHandler, data) => {
    const label = `${text.ARE_YOU_SURE} ${data.name}`;
    return (
      <Tooltip title={text.DELETE} key="remove">
        <Popconfirm
          type="destructive"
          icon="Delete"
          label={label}
          onConfirm={() => actionHandler(data)}
          buttonLabels={{
            cancel: text.CANCEL,
            confirm: text.DELETE,
          }}
        >
          <Icon name="Delete" />
        </Popconfirm>
      </Tooltip>
    );
  },
};

const modifers = ({ remove, previewBiqUser }) => ({
  created_by: {
    cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
  },
  shared_with_users: {
    quickFilter: {
      operator: queryOperators.IN.value,
    },
    width: 300,
    cellRendererFramework: MultiRouteCell({ href: appRoutes.TEAM_MEMBER, displayName: 'username' }),
  },
  shared_with_teams: {
    quickFilter: {
      operator: queryOperators.IN.value,
    },
    width: 300,
    cellRendererFramework: MultiRouteCell({ href: appRoutes.TEAM, displayName: 'name' }),
  },
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper({ remove }, actionIcons),
    pinned: 'right',
    width: 120,
  },
  report: {
    quickFilter: {},
  },
});

export default modifers;
