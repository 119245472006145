/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { bool, object, shape, string } from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { generatePath, withRouter } from 'react-router-dom';
import { InfoBox } from 'tc-biq-design-system';

import connect from '../../../../logic/connect';
import { TransactionSingle } from '../../../../components/common/sidepanels';
import UserHeader from './UserHeader';
import { fetchUser, resetUser } from './Model';
import Navigation from './Navigation';
import getAvailableSections from './Sections';
import BasicInfo from './BasicInfo';
import Page from '../../../../components/Page';
import { resetNotesForm, resetTaskForm } from './UserActions/actions/CommunicationFlow';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import { gettext } from '../../../../logic/utilities/languageUtility';
import appRoutes from '../../../../components/App/Router/appRoutes';

import './ClientProfile.scss';

const propTypes = {
  actions: object.isRequired,
  match: shape({
    params: shape({
      id: string,
    }),
  }).isRequired,
  userFetchInProgress: bool,
};

const defaultProps = {
  userFetchInProgress: false,
};

const text = {
  NOT_FOUND: id => gettext('User not found ({{id}})', { id }),
  TITLE: gettext('User details'),
};

class ClientProfile extends Component {
  constructor(props) {
    super(props);
    this.sectionRef = React.createRef();
    this.isIb = props.ib;
    this.actions = props.actions;
    this.components = {};
  }

  componentDidMount() {
    this.getUser();
  }

  componentWillUnmount() {
    this.actions.resetUser();
    this.actions.resetTaskForm();
    this.actions.resetNotesForm();
  }

  componentDidUpdate(previousProps) {
    const { match: { params: { id } } } = previousProps;
    if (id !== this.getUserId()) this.getUser();
  }

  getUserId = () => {
    const { match: { params: { id } } } = this.props;
    return id;
  }

  getUser = () => {
    this.actions.fetchUser({ id: this.getUserId() }, this.isIb).then(() => {
      const { user } = this.props;
      this.sections = getAvailableSections(this.isIb, user);
      const sectionId = this.getSectionId();
      this.onSectionChange(sectionId, true);
    });
  }

  onSectionChange = (section, skipAnimation = false) => {
    const { history } = this.props;
    const route = this.isIb ? appRoutes.IB_PROFILE : appRoutes.CLIENT_PROFILE;
    const path = generatePath(route, { id: this.getUserId(), section });
    history.push(path);

    if (!skipAnimation) {
      const { offsetTop } = this.sectionRef.current;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  }

  getSectionId = () => {
    const { match: { params: { section: sectionId } } } = this.props;
    if (!sectionId && this.sections) return this.sections[0].id;
    return sectionId; 
  }

  getSectionComponent() {
    if (_.isEmpty(this.sections)) return null;
    const sectionId = this.getSectionId();

    const { Component } = _.find(this.sections, { id: sectionId }) || this.sections[0];

    if (!this.components[sectionId]) {
      this.components[sectionId] = Component(this.getUserId());
    }
    return this.components[sectionId];
  }

  changeActiveTab = (tabId) => {
    this.onSectionChange(tabId);
  }

  render() {
    const SectionComponent = this.getSectionComponent();
    const sectionId = this.getSectionId();
    const { communicationFlow, userFetchInProgress, user } = this.props;
    return (
      <Page hideHeader loading={!user && userFetchInProgress} style={{}} title={text.TITLE}>
        {user ? (
          <Fragment>
            <UserHeader
              communicationFlow={communicationFlow}
              isIb={this.isIb}
              changeActiveTab={this.changeActiveTab}
            />
            <BasicInfo isIb={this.isIb} userId={this.getUserId()} />
            <div className="biq-profile">
              <div className="nav">
                <Navigation sections={this.sections} onSectionChange={this.onSectionChange} active={sectionId} />
              </div>

              <div className="section" ref={this.sectionRef}>
                {SectionComponent && <SectionComponent id={this.getUserId()} key={this.getUserId()} />}
              </div>
            </div>

            <TransactionSingle />
          </Fragment>
        ) : null} 
        
        {!user && !userFetchInProgress ? (
          <div className="biq-page__notification">
            <InfoBox type="warning">{text.NOT_FOUND(this.getUserId())}</InfoBox>
          </div>
        ) : null}
      </Page>
    );
  }
}

ClientProfile.propTypes = propTypes;
ClientProfile.defaultProps = defaultProps;

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const { user, submitInProgress } = page.userDetail;
  return {
    user,
    userFetchInProgress: submitInProgress,
    communicationFlow: page.CommunicationFlow,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      resetUser,
      resetNotesForm,
      resetTaskForm,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(ClientProfile);
