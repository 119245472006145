import React, { Fragment } from 'react';
import { Space } from 'tc-biq-design-system';
import { func, array } from 'prop-types';

import NumberInput from './NumberInput';

const propTypes = {
  value: array,
  onChange: func.isRequired,
  onEnter: func,
};

const defaultProps = {
  value: [],
  onEnter: () => {},
};

const onNumberRangeChange = (newValue, range, index, cb) => {
  const newRangeValue = range ? [...range] : [];
  newRangeValue[index] = newValue;
  cb(newRangeValue);
};

const NumberRange = (props) => {
  const { value, onChange, onEnter } = props;
  const range = value || [];
  return (
    <Fragment>
      <NumberInput
        {...props}
        onChange={newValue => onNumberRangeChange(newValue, value, 0, onChange)}
        onEnter={() => onEnter(value)}
        value={range[0]}
      />
      <Space size={8} />
      <NumberInput
        {...props}
        onChange={newValue => onNumberRangeChange(newValue, value, 1, onChange)}
        onEnter={() => onEnter(value)}
        value={range[1]}
      />
    </Fragment>
  );
};

NumberRange.propTypes = propTypes;
NumberRange.defaultProps = defaultProps;

export default NumberRange;
