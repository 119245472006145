import React from 'react';
import { Avatar } from 'tc-biq-design-system';
import { string } from 'prop-types';

const propTypes = {
  fullName: string.isRequired,
  avatar: string,
};

const defaultProps = {
  avatar: '',
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    paddingLeft: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};


const UserCell = ({ fullName, avatar }) => (
  <div style={styles.container}>
    <Avatar src={avatar} />
    <span style={styles.name}>{fullName}</span>
  </div>
);

UserCell.propTypes = propTypes;
UserCell.defaultProps = defaultProps;
export default UserCell;
