import { createAction, handleActions } from 'redux-actions';

const initialState = {
  data: {
    default_system: [],
    per_language: [],
  },
  isLoading: false,
  count: 0,
  query: {
    default: {},
    per_language: {},
  },
};

const actionTypes = {
  LOAD_DATA: 'LOAD_DATA_EMAILS',
  LOAD_DATA_PENDING: 'LOAD_DATA_EMAILS_PENDING',
  LOAD_DATA_FULFILLED: 'LOAD_DATA_EMAILS_FULFILLED',
  DELETE_EMAIL: 'DELETE_EMAIL',
  DELETE_EMAIL_PENDING: 'DELETE_EMAIL_PENDING',
  DELETE_EMAIL_FULFILLED: 'DELETE_EMAIL_FULFILLED',
};

const loadData = createAction(actionTypes.LOAD_DATA, async (api, query, active) => {
  const _current_timestamp = moment.utc().toISOString();
  const { data, error } = await api.list({ ordering: '-id', _current_timestamp, ...query });
  return { data, error, query, active };
});

const deleteEmail = createAction(actionTypes.DELETE_EMAIL, async (api, id) => {
  const response = await api.destroy(id);
  return response;
});

export default {
  loadData,
  deleteEmail,
};

export const reducer = handleActions(
  {
    [actionTypes.LOAD_DATA_PENDING]: state => ({ ...state, isLoading: true }),
    [actionTypes.LOAD_DATA_FULFILLED]: (state, { payload }) => {
      const { data: { results, count }, query, active } = payload;
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [active]: results,
        },
        count,
        query: {
          ...state.data,
          [active]: query,
        },
      };
    },
  },
  initialState,
);
