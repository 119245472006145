import PRIMITIVE_TYPE from '../enums/primitive-type';
import API_DATA_TYPE from '../enums/api-data-type';

function fieldPrimitiveType() {
  return function (value, apiType, choices) {
    if (value === null) {
      return resultForNull(apiType, choices);
    } if (_.isInteger(value) || (_.isArray(value) && _.every(value, _.isInteger))) {
      return resultForInteger(apiType);
    } if (_.isNumber(value) || (_.isArray(value) && _.every(value, _.isNumber))) {
      return resultForDecimal(apiType);
    } if (_.isBoolean(value) && apiType === API_DATA_TYPE.BOOLEAN) {
      return PRIMITIVE_TYPE.BOOLEAN;
    } if (moment.isDate(value) || (_.isArray(value) && _.every(value, moment.isDate))) {
      return resultForDate(apiType);
    }
    // every other case
    return PRIMITIVE_TYPE.STRING;
  };
}

function resultForNull(apiType, choices) {
  switch (apiType) {
    case API_DATA_TYPE.STRING:
    case API_DATA_TYPE.FIELD:
    case API_DATA_TYPE.EMAIL:
      return PRIMITIVE_TYPE.STRING;
    case API_DATA_TYPE.INTEGER:
      return PRIMITIVE_TYPE.INTEGER;
    case API_DATA_TYPE.DECIMAL:
      return PRIMITIVE_TYPE.DOUBLE;
    case API_DATA_TYPE.BOOLEAN:
      return PRIMITIVE_TYPE.BOOLEAN;
    case API_DATA_TYPE.CHOICE:
      if (choices && choices.length >= 1) {
        if (typeForChoices(choices) === 'number') {
          return PRIMITIVE_TYPE.INTEGER;
        }
      }
      return PRIMITIVE_TYPE.STRING;
    case API_DATA_TYPE.DATE:
      return PRIMITIVE_TYPE.DATE;
    case API_DATA_TYPE.DATETIME:
      return PRIMITIVE_TYPE.DATETIME;
    default:
      return PRIMITIVE_TYPE.STRING;
  }
}

function resultForInteger(apiType) {
  switch (apiType) {
    case API_DATA_TYPE.STRING:
    case API_DATA_TYPE.FIELD:
    case API_DATA_TYPE.EMAIL:
      return PRIMITIVE_TYPE.STRING;
    case API_DATA_TYPE.INTEGER:
      return PRIMITIVE_TYPE.INTEGER;
    case API_DATA_TYPE.DECIMAL:
      return PRIMITIVE_TYPE.DOUBLE;
    case API_DATA_TYPE.BOOLEAN:
      return PRIMITIVE_TYPE.STRING;
    case API_DATA_TYPE.CHOICE:
      return PRIMITIVE_TYPE.INTEGER;
    case API_DATA_TYPE.DATE:
      return PRIMITIVE_TYPE.STRING;
    case API_DATA_TYPE.DATETIME:
      return PRIMITIVE_TYPE.STRING;
    default:
      return PRIMITIVE_TYPE.STRING;
  }
}

function resultForDecimal(apiType) {
  switch (apiType) {
    case API_DATA_TYPE.STRING:
    case API_DATA_TYPE.FIELD:
    case API_DATA_TYPE.EMAIL:
      return PRIMITIVE_TYPE.STRING;
    case API_DATA_TYPE.INTEGER:
      return PRIMITIVE_TYPE.STRING;
    case API_DATA_TYPE.DECIMAL:
    case API_DATA_TYPE.FLOAT:
      return PRIMITIVE_TYPE.DOUBLE;
    case API_DATA_TYPE.BOOLEAN:
      return PRIMITIVE_TYPE.STRING;
    case API_DATA_TYPE.CHOICE:
      return PRIMITIVE_TYPE.STRING;
    case API_DATA_TYPE.DATE:
      return PRIMITIVE_TYPE.STRING;
    case API_DATA_TYPE.DATETIME:
      return PRIMITIVE_TYPE.STRING;
    default:
      return PRIMITIVE_TYPE.STRING;
  }
}

function resultForDate(apiType) {
  if (apiType === API_DATA_TYPE.DATE) {
    return PRIMITIVE_TYPE.DATE;
  } if (apiType === API_DATA_TYPE.DATETIME) {
    return PRIMITIVE_TYPE.DATETIME;
  } 
  return PRIMITIVE_TYPE.STRING;
}

function typeForChoices(choices) {
  // return "number";
  if (choices[0].value === null) {
    return typeof choices[1].value;
  } 
  return typeof choices[0].value;
}


export default fieldPrimitiveType;
