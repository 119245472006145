import React, { Component } from 'react';
import { object, bool, func } from 'prop-types';
import { Space, Sidepanel, notifier, Input, Select } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import { getTableActions, internalConfig } from '../../../Sections/Transactions/table';
import { fetchOptions, onInputChange, submit } from './Model';
import to from '../../../../../../../logic/utilities/to';
import FormEl from '../../../../../../../components/form/Components/FormEl';
import { formErrorHandler } from '../../../../../../../components/form/logic/utils';

const FORM_ID = 'INTERNAL_TRANSFER_FORM';

const text = {
  TITLE: gettext('Internal Transfer'),
  ACCOUNT_FROM: gettext('Account from'),
  ACCOUNT_TO: gettext('Account to'),
  AMOUNT: gettext('Amount'),
  SUCCESS: gettext('Internal transfer successful'),
  GENERAL_ERROR: gettext('Something went wrong!'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_ID}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  formValues: object,
  submitInProgress: bool.isRequired,
  accounts: object,
  formData: object,
  errors: object,
  dispatch: func.isRequired,
};

const defaultProps = {
  user: null,
  actions: null,
  formValues: {},
  accounts: {},
  formData: {},
  errors: {},
};

class InternalTransfer extends Component {
  constructor(props) {
    super(props);

    const { user } = props;
    this.api = getDjangoApi(`users/${user.id}/internal_transfer/`);
    this.actions = props.actions;
    this.onClose = this.onClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  componentDidMount() {
    this.actions.fetchOptions(this.api);
  }

  async onSubmit() {
    const { formData } = this.props;
    
    const request = {
      account_from: formData.account_from.id,
      account_to: formData.account_to.id,
      amount: formData.amount,
    };

    const [err] = await to(this.actions.submit(this.api, request));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    this.updateInternalTable();
    this.onClose();
    notifier.success(text.SUCCESS);
  }

  onError(err) {
    formErrorHandler(text.GENERAL_ERROR)(err);
  }

  onClose() {
    const { sidepanelManager } = this.props;
    sidepanelManager.close();
  }

  updateInternalTable = () => {
    const { dispatch, user } = this.props;
    const actions = getTableActions(internalConfig, user.id);
    dispatch(actions.fetchTableData());
  }

  render() {
    const { submitInProgress, accounts, errors, formData, ...options } = this.props;

    const accountFromChoices = formData.account_to && formData.account_to.id 
      ? accounts.fromOptions.filter(account => account.id !== formData.account_to.id)
      : accounts.fromOptions;

    const accountToChoices = formData.account_from && formData.account_from.id 
      ? accounts.toOptions.filter(account => account.id !== formData.account_from.id)
      : accounts.toOptions;

    return (
      <Sidepanel {...options} footerRender={customFooter(this.onSubmit, this.onClose, submitInProgress)}>
        <Space size={16} />

        <div style={{ padding: '10px' }}>
          <FormEl formId={FORM_ID}>
            <div className="m-b">
              <Select
                hasError={!!errors.account_from}
                helpText={errors.account_from ? errors.account_from[0] : null}
                label={text.ACCOUNT_FROM}
                type="single"
                onChange={value => this.actions.onInputChange('account_from', value)}
                clearable={!_.isEmpty(formData.account_from)}
                value={formData.account_from}
                options={accountFromChoices}
              />
            </div>

            <div className="m-b">
              <Select
                hasError={!!errors.account_to}
                helpText={errors.account_to ? errors.account_to[0] : null}
                label={text.ACCOUNT_TO}
                type="single"
                clearable={!_.isEmpty(formData.account_to)}
                onChange={value => this.actions.onInputChange('account_to', value)}
                value={formData.account_to}
                options={accountToChoices}
              />
            </div>

            <div className="m-b">
              <Input
                hasError={!!errors.amount}
                helpText={errors.amount ? errors.amount[0] : null}
                label={text.AMOUNT}
                onChange={e => this.actions.onInputChange('amount', e.target.value)}
                value={formData.amount}
              />
            </div>
          </FormEl>
        </div>

        <Space size={16} />
      </Sidepanel>
    );
  }
}
  

InternalTransfer.propTypes = propTypes;
InternalTransfer.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchOptions, onInputChange, submit }, dispatch),
  dispatch,
});

const mapStateToProps = ({ pages }) => {
  const action = pages.USER_SINGLE.actions.INTERNAL_TRANSFER;
  const { accounts, submitInProgress, errors, formData } = action;
  return { submitInProgress, accounts, errors, formData };
};

export default connect(mapStateToProps, mapDispatchToProps)(InternalTransfer);
