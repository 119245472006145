import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import connect from '../../../../../logic/connect';
import PendingDepositRule from './PendingDepositRule';
import { actions } from './Model';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const mapStateToProps = ({ pages }) => {
  const page = pages.PENDING_DEPOSIT_RULE;
  return { ...page };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

const PendingDepositRulePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(PendingDepositRule); 

export default {
  component: PendingDepositRulePage,
  aclId: 'pending_deposit_rules.update',
  path: appRoutes.SETTINGS_PENDING_DEPOSIT,
};

export const PendingDepositRuleCreate = {
  component: PendingDepositRulePage,
  aclId: 'pending_deposit_rules.create',
  path: appRoutes.SETTINGS_PENDING_DEPOSIT_CREATE,
};
