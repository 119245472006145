import React from 'react';
import { Icon, Tooltip, Popconfirm } from 'tc-biq-design-system';

import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { ActionsCellWrapper, UsernameCell } from '../../../../../../../../components/gridCellRenderers';

const text = {
  DELETE: gettext('Delete'),
  DELETE_LABEL: gettext('Are you sure you want to delete this item?'),
  CANCEL: gettext('Cancel'),
};

export const actionIcons = {
  delete: (actionHandler, data) => (
    <div key="Delete">
      <Tooltip title={text.DELETE} placement="top">
        <Popconfirm
          type="destructive"
          icon="Delete"
          onConfirm={() => actionHandler(data)}
          label={text.DELETE_LABEL}
          buttonLabels={{ cancel: text.CANCEL, confirm: text.DELETE }}
        >
          <Icon colorName="text-status04-700" name="Delete" />
        </Popconfirm>
      </Tooltip>
    </div>
  ),
};

const modifier = (actions, previewBiqUser) => ({
  added_by: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper(actions, actionIcons),
    pinned: 'right',
    width: 80,
  },
});

export default modifier;
