import React, { Component, Fragment } from 'react';
import { Button, notifier } from 'tc-biq-design-system';
import { object, bool, string } from 'prop-types';

import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import to from '../../../../../logic/utilities/to';
import FormFactory from '../../../../../components/form';
import FormPanel from '../../../../../components/form/Components/FormPanel/FormPanel';
import session from '../../../../../logic/services/session';
import { hasAccess } from '../../../../../logic/services/acl';
import { formatRequestData, Field } from '../../../../../components/form/logic/utils';
import SelectLanguage from './SelectLanguage';

export const FORM_ID = 'BO_PERSONAL_DETAILS_FORM';
const api = id => getDjangoApi(`team/${id}`);
const apiProfile = id => getDjangoApi(`team/profile/${id}`);

const text = {
  UPDATE: gettext('Update personal details'),
  CANCEL: gettext('Discharg changes'),
  ERROR_GENERAL: gettext('Something went wrong!'),
  SUCCESS: gettext('Personal details successfully saved'),
  FIRST_NAME: gettext('First name'),
  LAST_NAME: gettext('Last name'),
  USER_NAME: gettext('Username'),
  EMAIL: gettext('Email'),
  PHONE: gettext('Phone'),
  TEAM: gettext('Team'),
};

const teamName = 'team';

const propTypes = {
  actions: object.isRequired,
  submitInProgress: bool.isRequired,
  profileID: string.isRequired,
  member: object.isRequired,
  memberProfile: object.isRequired,
  memberOptions: object.isRequired,
};

class PersonalDetailsForm extends Component {
  constructor(props) {
    super(props);
    
    this.isLoggedIn = this.isLoggedInUser();
    this.state = {
      user: null,
      teamOptions: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { member } = this.props;
    const { user } = this.state;
    if (!_.isEmpty(member) && _.isEmpty(user)) {
      this.init();
      return;
    }

    if (_.get(prevProps, 'member.id') !== member.id) {
      this.init();
    }
  }

  // eslint-disable-next-line react/sort-comp
  init = async () => {
    const { member, memberProfile, memberOptions } = this.props;
    const fields = _.get(memberOptions, 'actions.PUT.fields');
    let teamOptions;
    if (fields) {
      teamOptions = fields.find(field => field.key === 'team').choices;
    }

    this.setState({
      teamOptions,
      user: { ...member, ...memberProfile },
    }, this.setFormData);
  }

  onSubmit = async () => {
    const { actions, profileID } = this.props;
    const updatePart = true;
    const [err] = await to(actions.update(this.isTeamDisabled() ? apiProfile(profileID) : api(profileID), null, this.getRequestPayload, updatePart));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    notifier.success(text.SUCCESS);
    this.resetForm();
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  getRequestPayload = (values) => {
    const payload = formatRequestData(values);
    
    return Object.keys(payload)
      .filter(key => (this.isTeamDisabled() ? key !== teamName : key === teamName))
      .reduce((acc, key) => ({ ...acc, [key]: payload[key] }), {});
  }

  setFormData = () => {
    const { actions } = this.props;
    const { user } = this.state;
    const fields = (this.formConfig()).customFields;

    fields.forEach(({ id }) => {
      const tempVal = user[id];
      const value = id === teamName ? { display_name: tempVal.name, value: tempVal.id } : tempVal;
      actions.setFieldValue({
        id,
        value,
      });
    });
  }

  formConfig = () => ({
    form: FORM_ID,
    customFields: [
      {
        id: 'team',
        name: 'team',
        type: 'select',
        label: text.TEAM,
        placeholder: text.TEAM,
        optios: [],
        value: null,
        joinValues: true,
        labelKey: 'display_name',
        valueKey: 'value',
        clearable: false,
      },
      {
        id: 'first_name',
        name: 'first_name',
        type: 'text',
        placeholder: text.FIRST_NAME,
        label: text.FIRST_NAME,
      },
      {
        id: 'last_name',
        name: 'last_name',
        type: 'text',
        placeholder: text.LAST_NAME,
        label: text.LAST_NAME,
      },
      {
        id: 'username',
        name: 'username',
        type: 'text',
        disabled: true,
        placeholder: text.USER_NAME,
        label: text.USER_NAME,
      },
      {
        id: 'email',
        name: 'email',
        type: 'text',
        placeholder: text.EMAIL,
        label: text.EMAIL,
      },
      {
        id: 'phone',
        name: 'phone',
        type: 'text',
        placeholder: text.PHONE,
        label: text.PHONE,
      },
    ],
  })

  resetForm = () => {
    const { actions, profileID } = this.props;
    this.FormComponent = null;
    this.forceUpdate();
    actions.loadTeamMember(profileID).then(this.init);
  }

  isLoggedInUser() {
    const { profileID } = this.props;
    return session.user && Number(session.user.id) === Number(profileID);
  }

  isTeamDisabled = () => this.isLoggedInUser() || !hasAccess('team.update')

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { submitInProgress } = this.props;
    const { teamOptions } = this.state;
    const Form = this.renderForm();
    return (
      <FormPanel>
        {isFeatureEnabled()('TRANSLATIONS') && (
          <FormPanel.Section>
            <SelectLanguage />
          </FormPanel.Section>
        )}
        <FormPanel.Section>
          <Form 
            formId={FORM_ID} 
            renderForm={fields => (
              <Fragment>       
                <Field disabled={!this.isLoggedIn}>{fields.first_name}</Field>
                <Field disabled={!this.isLoggedIn}>{fields.last_name}</Field>
                <Field disabled>{fields.username}</Field>
                <Field disabled={!this.isLoggedIn}>{fields.email}</Field>
                <Field disabled={!this.isLoggedIn}>{fields.phone}</Field>
                <Field options={teamOptions} disabled={this.isTeamDisabled() || _.isEmpty(teamOptions)}>{fields.team}</Field>
              </Fragment>
            )}
          />
        </FormPanel.Section>
        <FormPanel.Submit>
          {(hasAccess('team.update') || this.isLoggedIn) && ( 
            <Button 
              disabled={!hasAccess('team.update') || _.isEmpty(teamOptions)}
              loading={submitInProgress} 
              onClick={this.onSubmit}
              type="submit"
              formId={FORM_ID}
            >
              {text.UPDATE}
            </Button>
          )}
        </FormPanel.Submit>
      </FormPanel>
    );
  }
}

PersonalDetailsForm.propTypes = propTypes;

export default PersonalDetailsForm;
