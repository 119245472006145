import { bindActionCreators, compose } from 'redux';

import TradingPlatform from './TradingPlatform';
import overlayActions from '../../../../../components/overlay';
import connect from '../../../../../logic/connect';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import { hasAccess } from '../../../../../logic/services/acl';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ), 
  dispatch,
});

const TradingPlatformPage = compose(
  connect(null, mapDispatchToProps),
  withErrorBoundary,
)(TradingPlatform); 

export default {
  component: TradingPlatformPage,
  aclFn: () => (
    hasAccess('tpi.trading_backends.manage_display.list')
    || hasAccess('tpi.trading_backends.manage_links.list')
    || hasAccess('trading_account_labels.list')
    || (hasAccess('settings.tpi.personal_data_visibility.mt4.*') && isFeatureEnabled()('GDPR'))
  ),
  path: appRoutes.SETTINGS_TRADING_PLATFORMS,
};
