import { bindActionCreators } from 'redux';

import connect from '../../../../../../logic/connect';
import SalesStatuses from './SalesStatuses';
import { actions } from '../Model';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...actions,
  }, dispatch),
});

export default connect(null, mapDispatchToProps)(SalesStatuses);
