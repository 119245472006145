import React from 'react';

import { Dropdown, Avatar, Icon } from 'tc-biq-design-system';
import { gettext } from '../../logic/utilities/languageUtility';
import session from '../../logic/services/session';
import { logout } from '../../logic/services/authentication';
import toPath from '../../logic/utilities/toPath';
import isFeatureEnabled from '../../logic/filters/is-feature-enabled';
import { mapBO3ToBO2URL } from './redirectHelper';

const style = {
  cursor: 'pointer',
};

const UserDropdown = () => {
  const logoutUser = () => {
    window.location = toPath('login?error');
    logout();
  };
  const goToProfile = () => {
    window.location = toPath('settings/team/:id', { id: session.user.id });
  };

  const goToOldDesign = () => {
    const currentLocation = window.location.href;
    const bo2Location = mapBO3ToBO2URL(currentLocation);
    window.location = bo2Location;
  };

  const initials = session.user.username.toString();

  const customHeader = toggleHandler => (
    <div key="userdropdown-header" className="custom-header" onClick={toggleHandler}>
      <div>
        <Avatar initials={initials} src="" size="regular" />
      </div>
      <p>{_.get(session, 'user.username')}</p>
      <Icon colorName="" size="small" name="CaretDown" />
    </div>
  );

  const customList = [
    {
      item: onSelect => (
        <li onClick={onSelect} className="custom-item">
          <Icon colorName="text-primary-500" size="regular" name="User" />
          <p>{gettext('My Profile')}</p>
        </li>
      ),
      onClick: goToProfile,
    },
    {
      item: onSelect => (
        <li onClick={onSelect} className="custom-item">
          <Icon colorName="text-primary-500" size="regular" name="ArrowDown" />
          <p>{gettext('Go back to old view')}</p>
        </li>
      ),
      onClick: goToOldDesign,
    },
    {
      item: onSelect => (
        <li onClick={onSelect} className="custom-item">
          <Icon colorName="text-primary-500" size="regular" name="Logout" />
          <p>{gettext('Logout')}</p>
        </li>
      ),
      onClick: logoutUser,
    },
  ];

  if (isFeatureEnabled()('DISABLE_SWITCH_TO_NEW_DESIGN')) {
    customList.splice(1, 1);
  }

  return ( 
    <div style={style}>
      <Dropdown openLeft customList={customList} customHeader={customHeader} />
    </div> 
  );
};

export default UserDropdown;
