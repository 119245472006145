import React, { PureComponent } from 'react';
import { Input, Select, Button, notifier } from 'tc-biq-design-system';
import { object, array, string, bool, number, oneOfType } from 'prop-types';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import Page from '../../../../../../components/Page';
import QueryBuilder from '../../../../../../components/QueryBuilder';
import getID from '../../../../../../logic/utilities/getID';
import { refactoredExtractGroup } from '../../../../../../logic/services/query-adapter';

import './WithdrawalFlowStep.scss';
import appRoutes from '../../../../../../components/App/Router/appRoutes';
import FormEl from '../../../../../../components/form/Components/FormEl';

const text = {
  TITLE: {
    create: gettext('Create Withdrawal Step'),
    edit: gettext('Edit Withdrawal Step'),
  },
  NAME_INPUT_LABEL: gettext('Name'),
  PRIORITY_INPUT_LABEL: gettext('Priority'),
  PRIORITY_INPUT_PLACEHOLDER: gettext('Set a number'),
  SELECT_LABEL: gettext('Teams'),
  SELECT_PLACEHOLDER: gettext('Select team(s)'),
  WITHDRAWALS_SETTINGS_CRUMB: gettext('Withdrawal flow'),
  CREATE_BUTTON_LABEL: gettext('Create'),
  UPDATE_BUTTON_LABEL: gettext('Update'),
  DISCARD_BUTTON_LABEL: gettext('Discard'),
  CONDITION_ERROR_MESSAGE: gettext('Condition must exist in order to submit withdrawal flow step'),
};

const propTypes = {
  actions: object.isRequired,
  submitInProgress: bool,
  fields: array,
  name: string,
  group: object,
  errors: object,
  teams: array,
  selectedTeams: array,
  priority: oneOfType([number, string]),
  match: object.isRequired,
  history: object.isRequired,
};

const defaultProps = {
  submitInProgress: false,
  priority: '',
  fields: [],
  teams: [],
  selectedTeams: [],
  name: '',
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  errors: {
    name: [''],
    priority: [''],
    teams: [''],
  },
};

class WithdrawalFlowStep extends PureComponent {
  constructor(props) {
    super(props);

    this.actions = props.actions;

    const pathRoutes = getID();
    if (_.includes(pathRoutes, 'create')) {
      this.type = 'create';
    } else {
      this.type = 'edit';
      this.id = props.match.params.id;
    }

    this.crumbs = [
      { label: text.WITHDRAWALS_SETTINGS_CRUMB, route: appRoutes.SETTINGS_WITHDRAWALS },
      { label: text.TITLE[this.type], route: '' },
    ];
  }

  componentDidMount() {
    const { getFieldOptions, getFlowStepInfo } = this.actions;
    getFieldOptions().then(() => {
      if (this.type === 'edit') getFlowStepInfo(this.id);
    });
  }

  redirectToTableView = () => {
    const { history } = this.props;
    history.push(appRoutes.SETTINGS_WITHDRAWALS);
  };

  submit = () => {
    const { name, group, selectedTeams, priority } = this.props;
    const { submitFlowStep } = this.actions;
    const condition = refactoredExtractGroup(group) || null;
    const teams = selectedTeams.map(team => team.value);

    const request = {
      name,
      condition,
      priority,
      teams,
    };

    submitFlowStep(this.type === 'edit', request, this.id)
      .then(this.redirectToTableView, (errors) => {
        const errorMessages = errors.data;
        if (errorMessages && errorMessages.non_field_errors) {
          notifier.error(errorMessages.non_field_errors[0]);
        }
        if (errorMessages && errorMessages.condition) notifier.error(text.CONDITION_ERROR_MESSAGE);
      });
  };

  render() {
    const {
      fields,
      name,
      group,
      errors,
      submitInProgress,
      teams,
      selectedTeams,
      priority,
    } = this.props;
    const { setStepName, setStepPriority, setStepCondition, setStepTeams } = this.actions;

    return (
      <Page title={text.TITLE[this.type]} bread={this.crumbs}>
        <div className="withdrawal-flow-step__container-div">
          <FormEl>
            <div className="withdrawal-flow-step__name-priority-section">
              <Input
                label={text.NAME_INPUT_LABEL}
                className="withdrawal-flow-step__name-input"
                placeholder={text.NAME_INPUT_LABEL}
                value={name}
                onChange={setStepName}
                helpText={errors.name[0]}
                hasError={!!errors.name[0].length}
              />
              <Input
                label={text.PRIORITY_INPUT_LABEL}
                className="withdrawal-flow-step__priority-input"
                placeholder={text.PRIORITY_INPUT_PLACEHOLDER}
                type="number"
                value={priority}
                onChange={setStepPriority}
                helpText={errors.priority[0]}
                hasError={!!errors.priority[0].length}
              />
            </div>
            <div className="withdrawal-flow-step__select-teams">
              <Select
                multi
                clearable
                label={text.SELECT_LABEL}
                placeholder={text.SELECT_PLACEHOLDER}
                options={teams}
                labelKey="display_name"
                onChange={setStepTeams}
                value={selectedTeams}
                helpText={errors.teams[0]}
                hasError={!!errors.teams[0].length}
              />
            </div>
            <QueryBuilder
              group={group}
              onStateUpdate={setStepCondition}
              fields={fields}
              className="withdrawal-flow-step__query-builder"
            />
            <div className="withdrawal-flow-step__submit-section">
              <Button
                type="submit"
                style={{ float: 'right' }}
                onClick={this.submit}
                disabled={submitInProgress}
              >
                {this.type !== 'edit' ? text.CREATE_BUTTON_LABEL : text.UPDATE_BUTTON_LABEL}
              </Button>
              <Button
                style={{ float: 'right' }}
                onClick={this.redirectToTableView}
                color="ghost"
              >
                {text.DISCARD_BUTTON_LABEL}
              </Button>
            </div>
          </FormEl>
        </div>
      </Page>
    );
  }
}

WithdrawalFlowStep.propTypes = propTypes;
WithdrawalFlowStep.defaultProps = defaultProps;

export default WithdrawalFlowStep;
