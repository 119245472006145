export default {
  LOAD_ACTIVITY: 'LOAD_ACTIVITY',
  LOAD_ACTIVITY_PENDING: 'LOAD_ACTIVITY_PENDING',
  LOAD_ACTIVITY_FULFILLED: 'LOAD_ACTIVITY_FULFILLED',
  LOAD_ACTIVITY_REJECTED: 'LOAD_ACTIVITY_REJECTED',
  LOAD_MORE: 'LOAD_MORE',
  LOAD_MORE_PENDING: 'LOAD_MORE_PENDING',
  LOAD_MORE_FULFILLED: 'LOAD_MORE_FULFILLED',
  LOAD_MORE_REJECTED: 'LOAD_MORE_REJECTED',
  UPDATE_NEW_ACTIVITY_COUNT: 'UPDATE_NEW_ACTIVITY_COUNT',
  RESET_NEW_ACTIVITY_COUNT: 'RESET_NEW_ACTIVITY_COUNT',
  SET_FIRST_LEVEL_QUERY: 'SET_FIRST_LEVEL_QUERY',
  RESET_OFFSET: 'RESET_OFFSET',
  RESET_ACTIVITY_STREAM: 'RESET_ACTIVITY_STREAM',
};
