import React, { Component } from 'react';
import { Button, Icon, notifier, Table } from 'tc-biq-design-system';
import { bool, func, object, string } from 'prop-types';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import FormFactory from '../../../../../components/form';
import FormPanel from '../../../../../components/form/Components/FormPanel/FormPanel';
import session from '../../../../../logic/services/session';
import { hasAccess } from '../../../../../logic/services/acl';

export const FORM_ID = 'BO_SETTINGS_FORM';
const api = id => getDjangoApi(`team/profile/${id}/communication-profiles/`);

const text = {
  SAVE: gettext('Save'),
  CANCEL: gettext('Cancel'),
  EDIT: gettext('Edit'),
  ERROR_GENERAL: gettext('Something went wrong!'),
  SUCCESS: gettext('External ID successfully created'),
  EDIT_SUCCESS: gettext('External ID successfully updated'),
  TEAM: gettext('Team'),
};

const propTypes = {
  actions: object.isRequired,
  submitInProgress: bool.isRequired,
  profileID: string.isRequired,
  member: object.isRequired,
  memberProfile: object.isRequired,
  memberOptions: object.isRequired,
};

// eslint-disable-next-line react/prop-types
const EditIcon = ({ onEdit }) => <div className="field-permissions__checkmark" onClick={item => onEdit(item)}><Icon colorName="text-primary-500" name="Edit" /></div>;

EditIcon.propTypes = {
  item: object.isRequired,
  onEdit: func.isRequired,
};

class SettingsForm extends Component {
  constructor(props) {
    super(props);

    this.isLoggedIn = this.isLoggedInUser();
    this.state = {
      user: null,
      showForm: false,
      communicationProfiles: [],
      selectedId: null,
    };

    this.getCommunicationProfiles();
  }

  componentDidUpdate(prevProps) {
    const { member } = this.props;
    const { user } = this.state;

    if (!_.isEmpty(member) && _.isEmpty(user)) {
      this.init();
      return;
    }

    if (_.get(prevProps, 'member.id') !== member.id) {
      this.init();
    }
  }

  // eslint-disable-next-line react/sort-comp
  init = async () => {
    const { member, memberProfile } = this.props;

    this.setState({
      user: { ...member, ...memberProfile },
    });
  }

  getCommunicationProfiles = () => {
    const { profileID } = this.props;

    api(profileID).retrieve().then((data) => {
      const communicationProfiles = data?.data?.results;
      this.setState({ communicationProfiles: communicationProfiles ?? [] });
    }).catch(err => console.log(err));
  }

  onSubmit = async () => {
    const { profileID, values } = this.props;
    const { selectedId } = this.state;

    const request = {
      provider: values?.provider?.value,
      external_id: values?.external_id,
    };

    if (selectedId) {
      api(profileID).update(selectedId, request)
        .then((response) => {
          if (response?.data) {
            this.getCommunicationProfiles();
            this.setState({ showForm: false });
            notifier.success(text.EDIT_SUCCESS);
          }
        })
        .catch(err => this.onError(err));

    } else {
      api(profileID).create(request)
        .then((response) => {
          if (response?.data) {
            this.getCommunicationProfiles();
            this.setState({ showForm: false });
            notifier.success(text.SUCCESS);
          }
        })
        .catch(err => this.onError(err));
    }
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors');
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data');
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  isLoggedInUser() {
    const { profileID } = this.props;
    return session.user && Number(session.user.id) === Number(profileID);
  }

  canCreate() {
    return hasAccess('team.profile.communication_profiles.create');
  }

  canUpdate() {
    return hasAccess('team.profile.communication_profiles.update');
  }

  generateCols = (communicationProfiles) => {
    if (_.isEmpty(communicationProfiles)) return [];
    const getProfile = id => communicationProfiles.find(profile => profile.id === id);
    const getLabel = id => getProfile(id).provider;
    const getExternalId = id => getProfile(id).external_id;

    const columns = [
      {
        title: 'Provider',
        key: 'provider',
        render: ({ id }) => getLabel(id),
      },
      {
        title: 'External ID',
        key: 'external_id',
        // eslint-disable-next-line react/prop-types
        render: ({ id }) => <div style={{ textAlign: 'left' }}>{getExternalId(id)}</div>,
      },
    ]

    if(this.canUpdate()) {
      columns.push(
        {
          title: 'Action',
          key: 'action',
          width: '100px',
          render: ({ id }) => <EditIcon item={getProfile(id)} onEdit={() => this.setUpdateData(getProfile(id))}></EditIcon>,
        },
      )
    }

    return columns;
  }

  setUpdateData(data) {
    const { actions } = this.props;
    actions.setRowData({ ...data });
    this.setState({ selectedId: data.id, showForm: true });
  }

  renderForm = () => {
    const { profileID } = this.props;

    if (!this.FormComponent) {
      this.FormComponent = FormFactory({ form: FORM_ID, api: api(profileID), excludeFields: ['bo_user'] });
    }
    const { FormComponent } = this;
    return <FormComponent formId={FORM_ID} />;
  }

  render() {
    const { submitInProgress } = this.props;
    const { showForm, communicationProfiles, selectedId } = this.state;

    return (
      <FormPanel>
          <FormPanel.Section>
            <div className="flex justify-between align-center mb-20">
              <h3 className="m-0">Communication profiles</h3>

              {
                !showForm && this.canCreate() && (
                  <Button
                    type="button"
                    onClick={() => this.setState({ showForm: true })}
                  >
                    Add new extension id
                  </Button>
                )
              }
            </div>

            <Table
              striped
              cols={this.generateCols(communicationProfiles)}
              data={communicationProfiles?.map(profile => ({ id: profile.id }))}
            />

            {showForm && (
              <div className="mt-20">
                {this.renderForm()}
              </div>
            )}
          </FormPanel.Section>


        {showForm && (
          <FormPanel.Submit>
            {(hasAccess('team.update') || this.isLoggedIn) && (
              <div>
                <Button
                  disabled={!hasAccess('team.update')}
                  loading={submitInProgress}
                  onClick={this.onSubmit}
                  type="submit"
                  formId={FORM_ID}
                >
                  {text.SAVE}
                </Button>

                <Button
                  onClick={() => this.setState({ showForm: false })}
                  type="button"
                  color="transparent"
                >
                  {text.CANCEL}
                </Button>
              </div>
            )}
          </FormPanel.Submit>
        )}
      </FormPanel>
    );
  }
}

SettingsForm.propTypes = propTypes;

export default SettingsForm;
