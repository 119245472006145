import React, { Component } from 'react';
import { isEmpty, isString, get } from 'lodash';
import { Accordion, Code } from 'tc-biq-design-system';
import { string, object, bool } from 'prop-types';

import { gettext } from '../logic/utilities/languageUtility';
import If from './If';

const propTypes = {
  title: string,
  rawData: object.isRequired,
  accordionVisible: bool,
};

const defaultProps = {
  title: gettext('Extra'),
  accordionVisible: false,
};

class Extra extends Component {
  constructor(props) {
    super(props);
    this.toggleExtra = this.toggleExtra.bind(this);
    this.notEmpty = this.notEmpty.bind(this);
    this.state = {
      accordionVisible: false || props.accordionVisible,
    };
  }

  getContent(data) {
    if (isString(data) && data.startsWith('<?xml')) {
      return data;
    }

    const rawData = _.cloneDeep(data);
    let content = {};
    const xml = get(rawData, 'external_request.xml');
    if (xml) {
      rawData.external_request.xml = '#XML_PLACEHOLDER';
    }
    if (isString(rawData)) {
      content = JSON.stringify(JSON.parse(rawData), null, 4);
    } else {
      content = JSON.stringify(rawData, null, 4);
    }
    if (xml) {
      content = content.replace('#XML_PLACEHOLDER', xml);
    }
    return content;
  }

  notEmpty(content) {
    return isString(content) ? (content !== '' || content !== '{}') : !isEmpty(content);
  }

  toggleExtra() {
    const { accordionVisible } = this.state;
    this.setState({ accordionVisible: !accordionVisible });
  }

  render() {
    const { rawData, title } = this.props;
    if (!rawData) return null;
    const content = this.getContent(rawData);
    const { accordionVisible } = this.state;
    return (
      <If condition={this.notEmpty(content)}>
        <Accordion title={title} visible={accordionVisible} onClick={this.toggleExtra}>
          <Code>
            <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
              {content}
            </div>
          </Code>
        </Accordion>
      </If>
    );
  }
}

Extra.propTypes = propTypes;
Extra.defaultProps = defaultProps;
export default Extra;
