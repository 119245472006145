import React, { Component, Fragment } from 'react';
import { Button, Spinner } from 'tc-biq-design-system';
import { object, func, number } from 'prop-types';

import withTable, { GridTab } from '../../../../../components/grid/withTable';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import modifiers from './modifiers';
import { hasAccess } from '../../../../../logic/services/acl';

const text = {
  ADD_TRACKING: gettext('Add tracking link'),
  EXPORT: gettext('Export current page'),
  EXPORT_ALL: gettext('Export all'),
};

export const reducerKey = 'CAMPAIGNS';

export const tableConfig = ({ uid, cid }) => ({
  reducerKey,
  apiUrl: `team/profile/${uid}/campaigns/${cid}/signup_users`,
  tableKey: 'SIGNUP_USERS_TABLE',
});

const propTypes = {
  dispatch: func.isRequired,
  table: object.isRequired,
  cid: number.isRequired,
  uid: number.isRequired,
};

class SignupUsersTable extends Component {
  gridActions = () => <Button>{text.ADD_TRACKING}</Button>

  gridActions = () => {
    const { table } = this.props;
    const all = _.get(table, 'exportUrl.all');
    const single = _.get(table, 'exportUrl.single');
    return hasAccess('team.profile.campaigns.tracking_links.signup_users.export') && (
      <Fragment>
        <a href={single}>
          <Button icon="Download" color="ghost">{text.EXPORT}</Button>
        </a>
        <a href={all}>
          <Button>{text.EXPORT_ALL}</Button>
        </a>
      </Fragment>
    );
  }

  reloadTable = () => {
    const { dispatch, table } = this.props;
    const [, tableActions] = this.Grid;
    dispatch(tableActions.fetchTableData(table.query));
  }

  generateGrid = () => {
    const { cid, uid } = this.props;
    if (!this.Grid && cid && uid) {
      this.Grid = withTable(GridTab, tableConfig, this.gridActions, { withActions: true })({ cid, uid });
    }
    
    return this.Grid;
  }

  render() {
    const Grid = this.generateGrid();
    const [Table] = Grid || [];
    return Table ? <Table height="50vh" modifier={modifiers()} /> : <Spinner />;
  }
}

SignupUsersTable.propTypes = propTypes;

export default SignupUsersTable;
