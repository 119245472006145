/* eslint react/prop-types: 0 */
/* eslint react/jsx-no-target-blank: 0 */
import React, { Component, Fragment } from 'react';
import { object, func } from 'prop-types';
import {
  Modal,
  Button,
  HyperLink,
  notifier,
  Tooltip,
  Space,
  ImageRotator,
} from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
 
import { isPDF, getSrc } from '../../../../logic/services/document';
import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import overlayActions from '../../../overlay';
import If from '../../../If';
import FormFactory from '../../../form';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import states from '../../../../pages/home/user-single/react/Sections/Documents/states';
import to from '../../../../logic/utilities/to';

const env = window.config.environment;

const text = {
  CLOSE: gettext('Close'),
  IMG_TEXT: gettext('Document preview'),
  APPROVE: gettext('Approve'),
  PRE_APPROVE: gettext('Pre-approve'),
  REJECT: gettext('Reject'),
  REJECT_SUCCESS: gettext('Document rejected successfully'),
  REJECT_ERROR: gettext('Error while rejecting document'),
  APPROVE_SUCCESS: gettext('Document approved successfully'),
  APPROVE_ERROR: gettext('Error while approving document'),
  PRE_APPROVE_SUCCESS: gettext('Document is successfully pre-approved'),
  PRE_APPROVE_ERROR: gettext('Error while Pre-approving document'),
  REJECT_TEXT: gettext('Are you sure you want to reject this document?'),
  PDF_HELP_TEXT: gettext('This is a pdf document, click here to view the file.'),
  REJECT_LABEL: gettext('Reject reason'),
  REJECT_PLACEHOLDER: gettext('Choose rejection reason'),
  PREVIEW_DOCUMENT: gettext('Preview document before approve/reject it'),
};

const propTypes = {
  actions: object.isRequired,
  modal: object.isRequired,
  onSuccess: func.isRequired,
};

const renderDocuments = (documents, onPreviewPdf) => documents.map((document) => {
  const fileSrc = getSrc(document);
  const fileBacksideSrc = getSrc(document, { isBackside: true });

  const { file_name, file_name_backside, id } = document;
  const isPdfFile = isPDF(file_name);
  const isBacksidePdfFile = isPDF(file_name_backside);

  return (
    <Fragment key={id}>
      <If condition={!isPdfFile}>
        {fileSrc && (
          <a href={fileSrc} target="_blank">
            <ImageRotator src={fileSrc} />
          </a>
        )}

        <If condition={!!fileBacksideSrc && !isBacksidePdfFile}>
          {fileBacksideSrc && (
            <a href={fileBacksideSrc} target="_blank">
              <ImageRotator src={fileBacksideSrc} />
            </a>
          )}
        </If>
        <If condition={!!fileBacksideSrc && isBacksidePdfFile}>
          <HyperLink>
            <a onClick={() => { onPreviewPdf('previewBackside'); }} href={fileBacksideSrc} target="_blank" rel="noopener noreferrer">{text.PDF_HELP_TEXT}</a>
          </HyperLink>
        </If>
      </If>

      <If condition={!!isPdfFile}>
        <If condition={fileSrc}>
          <HyperLink>
            <a onClick={() => { onPreviewPdf('previewFrontside'); }} href={fileSrc} target="_blank" rel="noopener noreferrer">{text.PDF_HELP_TEXT}</a>
          </HyperLink>
        </If>
        <If condition={fileBacksideSrc && isBacksidePdfFile}>
          <HyperLink>
            <a onClick={() => { onPreviewPdf('previewBackside'); }} href={fileBacksideSrc} target="_blank" rel="noopener noreferrer">{text.PDF_HELP_TEXT}</a>
          </HyperLink>
        </If>
        <If condition={fileBacksideSrc && !isBacksidePdfFile}>
          {fileBacksideSrc && (
            <a href={fileBacksideSrc} target="_blank">
              <ImageRotator src={fileBacksideSrc} />
            </a>
          )}
        </If>
      </If>
    </Fragment>
  );
});

const getDocsApi = id => getDjangoApi(`documents/${id}/approve`);
const formID = 'DOCUMENT_REJECTION_REASON_FORM';

const formConfig = id => ({
  form: formID,
  customFields: [
    {
      id: 'rejection_reason',
      name: 'rejection_reason',
      label: text.REJECT_LABEL,
      required: true,
      type: 'select',
      placeholder: text.REJECT_PLACEHOLDER,
      async: true,
      debounceInterval: 500,
      loadOptions: () => getDocsApi(id).options().then((res) => {
        const { fields } = res.data.actions.POST;
        const rejection_reason = fields.filter(({ key }) => key === 'rejection_reason')[0];
        return rejection_reason.choices;
      }),
      valueKey: 'value',
      labelKey: 'display_name',
    },
  ],
});

class DocumentPreview extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.ReasonsForm = null;
    this.state = {
      preApprove: {
        submitInProgress: false,
      },
      approve: {
        submitInProgress: false,
      },
      reject: {
        submitInProgress: false,
      },
      hasClickedOnRejectionButton: false,
      previewFrontside: false,
      previewBackside: false,
    };
  }

  onPreviewPdf = (state) => {
    this.setState({
      [state]: true,
    });
  }

  onSubmitSuccess = () => {
    const { onSuccess } = this.props;
    this.closeModal();
    onSuccess();
  }

  closeModal = () => {
    this.setState({
      hasClickedOnRejectionButton: false,
      previewBackside: false,
      previewFrontside: false,
    });
    this.actions.close('PREVIEW_DOCUMENT');
  }

  modalFooter = () => {
    const { approve, preApprove, reject, previewFrontside, previewBackside } = this.state;
    const parameters = _.get(this.props, 'modal.parameters');

    const isPdfFrontSide = isPDF(parameters.document.file_name || '');
    const isPdfBackSide = isPDF(parameters.document.file_name_backside || '');
    const disablePdfFiles = (isPdfBackSide && !previewBackside) || (isPdfFrontSide && !previewFrontside);

    const approveTitle = disablePdfFiles ? text.PREVIEW_DOCUMENT : text.APPROVE;
    const rejectTitle = disablePdfFiles ? text.PREVIEW_DOCUMENT : text.REJECT;
    const preApproveTitle = disablePdfFiles ? text.PREVIEW_DOCUMENT : text.PRE_APPROVE;
    return (
      <div>
        <Button onClick={this.closeModal} color="ghost">
          {text.CLOSE}
        </Button>

        <If condition={parameters && parameters.review}>
          <If condition={parameters.document.status !== states.PRE_APPROVED && env === 'AvaTrade'}>
            <Button
              disabled={preApprove.submitInProgress}
              loading={preApprove.submitInProgress}
              onClick={() => !disablePdfFiles && this.preApproveHandler()}
              color="confirmation"
            >
              <Tooltip key={preApproveTitle} title={preApproveTitle} placement="top">
                {text.PRE_APPROVE}
              </Tooltip>
            </Button>
          </If>
          <Button disabled={reject.submitInProgress} loading={reject.submitInProgress} onClick={() => !disablePdfFiles && this.rejectHandler()} color="destructive">
            <Tooltip key={rejectTitle} title={rejectTitle} placement="top">
              {text.REJECT}
            </Tooltip>
          </Button>
          <Button
            disabled={approve.submitInProgress}
            loading={approve.submitInProgress}
            onClick={() => !disablePdfFiles && this.approveHandler()}
            color="confirmation"
          >
            <Tooltip key={approveTitle} title={approveTitle} placement="top">
              {text.APPROVE}
            </Tooltip>
          </Button>
        </If>
      </div>
    );
  }

  rejectHandler = async () => {
    const { onReject, document } = _.get(this.props, 'modal.parameters', {});

    const { hasClickedOnRejectionButton } = this.state;
    const { values: { rejection_reason } } = this.props;

    if (hasClickedOnRejectionButton) {
      this.setState({ reject: { submitInProgress: true } });
      const [err] = await to(onReject({ id: document.id, rejection_reason: rejection_reason && rejection_reason.value }));
        
      this.setState({ reject: { submitInProgress: false } });
      if (err) {
        notifier.error(text.REJECT_ERROR);
      } else {
        notifier.success(text.REJECT_SUCCESS);
        this.onSubmitSuccess();
      }
    } else {
      this.setState({ hasClickedOnRejectionButton: true });
    }
  }

  preApproveHandler = async () => {
    this.setState({ preApprove: { submitInProgress: true } });
    const { onPreApprove, document } = _.get(this.props, 'modal.parameters', {});
    const [err] = await to(onPreApprove({ id: document.id }));
    this.setState({ preApprove: { submitInProgress: false } });

    if (err) {
      notifier.error(text.PRE_APPROVE_ERROR);
    } else {
      notifier.success(text.PRE_APPROVE_SUCCESS);
      this.onSubmitSuccess();
    }
  }

  approveHandler = async () => {
    this.setState({ approve: { submitInProgress: true } });
    const { onApprove, document } = _.get(this.props, 'modal.parameters', {});
    
    const [err] = await to(onApprove({ id: document.id }));
    this.setState({ approve: { submitInProgress: false } });
    if (err) {
      notifier.error(text.APPROVE_ERROR);
    } else {
      notifier.success(text.APPROVE_SUCCESS);
      this.onSubmitSuccess();
    }
  }

  renderRejectionReasonForm = () => {
    if (!this.ReasonsForm) {
      const { modal: { parameters } } = this.props;

      this.ReasonsForm = FormFactory(formConfig(parameters.document.id));
    }
    const { hasClickedOnRejectionButton } = this.state;

    const { ReasonsForm } = this;

    if (hasClickedOnRejectionButton) {
      return <ReasonsForm />; 
    } 
    return null;
  }

  render() {
    const { modal } = this.props;
    const { parameters } = modal;
    if (!parameters || !parameters.document) return null;
    const { hasClickedOnRejectionButton } = this.state;
    
    const { document, content } = parameters;
    const documents = Array.isArray(document) ? document : [document];
    return (
      <Modal 
        icon={content.icon || 'Upload'} 
        title={content && content.title ? content.title : text.IMG_TEXT} 
        footerRender={this.modalFooter}
        visible={modal.visible}
        onCloseIconClick={this.closeModal}
        closable
      >
        <div style={{ padding: '10px', height: hasClickedOnRejectionButton && '500px' }}>
          {content && content.text && <h4 className="black-text">{content.text}</h4>}
          {renderDocuments(documents, this.onPreviewPdf)}
          <Space size={20} />
          { this.renderRejectionReasonForm() }
        </div>
      </Modal>
    );
  }
}

DocumentPreview.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ close: overlayActions.close }, dispatch),
});

const mapStateToProps = ({ overlays, forms }) => ({ 
  modal: overlays.PREVIEW_DOCUMENT, 
  ...forms[formID],
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPreview);
