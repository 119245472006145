import React from 'react';
import { any, bool, func, string } from 'prop-types';

import { Button, HyperLink, Icon } from 'tc-biq-design-system';

import { dateTimeFormatter, hiddenMask } from '../../../../../../logic/utilities/formatters';
import text from './translate';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import UserInfoCardItemValue from './UserInfoCardItemValue';
import { hasAccess } from '../../../../../../logic/services/acl';

const env = window.config.environment;

const isEmpty = value => _.isNil(value) || value === '';

const displayValue = value => (isEmpty(value) ? text.NA : `${value}`);
const actionComponent = () => null;

const defaultModifier = {
  isVisible: () => true,
  displayName: (id, label) => {
    const name = _.capitalize(id.replace(/_/g, ' '));
    return label || name;
  },
  displayValue,
  actionComponent,
};

const displayDateValue = {
  displayValue: value => (value && dateTimeFormatter(value)) || text.NA,
};

const displayCountryValue = {
  displayValue: value => (value && value.country_name) || text.NA,
};

const displayCurrencyValue = {
  displayValue: value => (value && value.symbol) || text.NA,
};

const displayBoolValue = {
  displayValue: value => (value ? text.YES : text.NO),
};

const displayUsername = {
  displayValue: value => value && value.username,
};

export const shouldToggleVisibility = () => isFeatureEnabled()('PHONE_VISIBILITY_TOGGLE')
  && hasAccess('user.call');

export const shouldHaveCallAction = () => isFeatureEnabled()('PHONE_CALL_ACTION')
  && hasAccess('user.call');

export const MaskedPhone = ({ isPhoneVisible, value, toggleVisibility, onPhoneCall, isEdit, field }) => {
  const phone = isPhoneVisible ? value : hiddenMask(value);
  const buttonText = isPhoneVisible ? text.HIDE : text.VIEW;
  const showButton = field === 'telephone' && phone;

  const displayPhone = () => (onPhoneCall ? <span className="like-a-link" onClick={() => onPhoneCall(field)}>{phone}</span> : phone);

  const displayValue = () => <span>{shouldToggleVisibility() ? displayPhone() : value}</span>;

  if (shouldHaveCallAction()) {
    return (
      <div>
        {!isEdit && phone}
        {showButton && (
          <Button size="extra-small" onClick={() => onPhoneCall(field)}>
            Call
          </Button>
        )}
      </div>
    );
  }

  return (
    <div>
      {!isEdit && displayValue()}
      {shouldToggleVisibility() && showButton && (
        <Button size="extra-small" onClick={toggleVisibility}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

MaskedPhone.propTypes = {
  isPhoneVisible: bool,
  value: any,
  isEdit: bool,
  field: string.isRequired,
  toggleVisibility: func,
  onPhoneCall: func,
};

MaskedPhone.defaultProps = {
  isPhoneVisible: false,
  isEdit: false,
  value: null,
  toggleVisibility: () => null,
  onPhoneCall: null,
};

const EditMaskedPhone = props => <MaskedPhone isEdit {...props} />;

const phoneDisplay = (value, { toggleVisibility, isPhoneVisible, field, onPhoneCall }) => (
  <MaskedPhone
    isPhoneVisible={isPhoneVisible}
    value={value}
    toggleVisibility={toggleVisibility}
    field={field}
    onPhoneCall={onPhoneCall}
  />
);

const phoneEdit = (value, { toggleVisibility, isPhoneVisible, field } = {}) => (
  <EditMaskedPhone
    isPhoneVisible={isPhoneVisible}
    value={value}
    toggleVisibility={toggleVisibility}
    field={field}
  />
);


const modifiers = {
  birth_country: {
    displayName: () => text.BIRTH_COUNTRY,
    isVisible: (user) => {
      const country = _.get(user, 'addr_country.iso_code');
      return country === 'IT';
    },
  },
  assigned_to: {
    displayValue: data => _.get(data, 'username', text.NA),
  },
  manager_team: {
    displayValue: data => _.get(data, 'name', text.NA),
  },
  nationality: {
    displayName: () => text.NATIONALITY,
  },
  date_of_birth: {
    displayName: () => text.DATE_OF_BIRTH,
  },
  username: {
    displayName: () => text.USERNAME,
  },
  language: {
    displayName: () => text.LANGUAGE,
  },
  title: {
    displayName: () => text.TITLE,
  },
  date_joined: {
    ...displayDateValue,
    displayName: () => text.DATE_JOINED,
  },
  joint: {
    ...displayBoolValue,
    displaName: () => text.DATE_JOINED,
    isVisible: () => env === 'AvaTrade',
  },
  last_seen: {
    ...displayDateValue,
    displayName: () => text.LAST_LOGIN_TIME,
  },
  online_last_seen: {
    ...displayDateValue,
    displayName: () => text.LAST_ONLINE_TIME,
  },
  last_interaction_date: {
    ...displayDateValue,
    displayName: () => text.LAST_INTERACTION_DATE,
    isVisible: () => env === 'AvaTrade',
  },
  loss_limit_reached: {
    displayName: () => text.LOSS_LIMIT_REACHED,
    displayValue: (value, { onLossLimitReachedRefresh }) => (
      <UserInfoCardItemValue value={displayBoolValue.displayValue(value)} actionTooltip="Refresh" action={onLossLimitReachedRefresh && <Icon onClick={onLossLimitReachedRefresh} colorName="text-primary-500" size="small" name="Pending" />} />
    ),
    isVisible: data => env === 'AvaTrade' && data.loss_limit_reached !== null,
  },
  approved_loss_limit: {
    isVisible: () => env === 'AvaTrade',
  },
  approved_loss_limit_currency: {
    isVisible: () => env === 'AvaTrade',
  },
  approve_date: {
    ...displayDateValue,
  },
  currency: {
    ...displayCurrencyValue,
    displayName: () => text.TRADING_CURRENCY,
    isVisible: (user, isIb) => env !== 'AvaTrade'
      && !isIb
      && user.whitelabel !== 'TradeNationAU'
      && user.whitelabel !== 'TradeNationUK'
      && user.whitelabel !== 'TNBahamas'
      && user.whitelabel !== 'TNSouthAfrica',
  },
  initial_trading_platforms: {
    displayValue: value => (value && value.join(',')) || text.NA,
    displayName: () => text.INITIAL_TRADING_PLATFORM,
  },
  trading_backends: {
    displayValue: value => (_.map(value, 'name').join(',')) || text.NA,
    displayName: () => text.TRADING_BACKENDS,
  },
  verified_channel: {
    displayName: () => text.IDENTITY,
  },
  verified_by: {
    ...displayUsername,
  },
  invited_by: {
    ...displayUsername,
  },
  kyc_failure: {
    displayValue: value => (value ? value.name : text.NA),
  },
  nic_fields: {
    isVisible: () => false,
  },
  address: {
    displayName: () => text.ADDRESS,
  },
  addr_line_2: {
    displayName: () => text.ADDRESS_2,
  },
  addr_line_3: {
    displayName: () => text.ADDRESS_3,
  },
  addr_street: {
    displayName: () => text.STREET,
  },
  addr_building: {
    displayName: () => text.BUILDING,
  },
  addr_flat: {
    displayName: () => text.FLAT,
  },
  addr_zip: {
    displayName: () => text.POSTCODE,
    isVisible: (user) => {
      const country = _.get(user, 'addr_country.iso_code');
      return country !== 'IE' || (env === 'EagleGlobalMarkets' && country !== 'NG');
    },
  },
  addr_city: {
    displayName: () => text.CITY,
  },
  addr_state: {
    displayName: () => text.STATE,
  },
  country: {
    ...displayCountryValue,
    displayName: () => text.COUNTRY,
  },
  addr_country: {
    ...displayCountryValue,
    displayName: () => text.COUNTRY,
  },
  email: {
    displayName: () => text.EMAIL,
    displayValue: value => (env === 'AvaTrade' ? <HyperLink><a className="biq-link" href={`mailto:${value}`}>{value}</a></HyperLink> : value),
  },
  phone: {
    displayName: () => text.PHONE,
    displayValue: phoneDisplay,
    actionComponent: phoneEdit,
  },
  other_phone: {
    displayName: () => text.OTHER_PHONE,
    isVisible: () => env === 'AvaTrade',
    displayValue: phoneDisplay,
    actionComponent: phoneEdit,
  },
  other_phone2: {
    displayName: () => text.OTHER_PHONE2,
    isVisible: () => env === 'AvaTrade',
    displayValue: phoneDisplay,
    actionComponent: phoneEdit,
  },
  telephone: {
    displayName: () => text.PHONE,
    displayValue: phoneDisplay,
    actionComponent: phoneEdit,
  },
  website: {
    displayName: () => text.WEBSITE,
  },
  skype: {
    displayName: () => text.SKYPE,
  },
  mothers_maiden_name: {
    displayName: () => text.MOTHERS_MAIDEN_NAME,
    isVisible: (user) => {
      const country = _.get(user, 'addr_country.iso_code');
      return country === 'ES';
    },
  },
  nif_code: {
    displayName: () => text.NIF_CODE,
    isVisible: (user) => {
      const country = _.get(user, 'addr_country.iso_code');
      return country === 'ES';
    },
  },
  au_state: {
    displayName: () => text.AU_STATE,
    isVisible: (user) => {
      const country = _.get(user, 'addr_country.iso_code');
      return country === 'AU';
    },
  },
  cpr: {
    displayName: () => text.CPR,
    isVisible: (user) => {
      const country = _.get(user, 'addr_country.iso_code');
      return country === 'DK';
    },
  },
  it_province: {
    displayName: () => text.IT_PROVINCE,
    isVisible: (user) => {
      const country = _.get(user, 'addr_country.iso_code');
      return country === 'IT';
    },
  },
  tax_code: {
    displayName: () => text.TAX_CODE,
  },
  za_province: {
    displayName: () => text.ZA_PROVINCE,
    isVisible: (user) => {
      const country = _.get(user, 'addr_country.iso_code');
      return country === 'ZA';
    },
  },
  id_card: {
    displayName: () => text.ID_CARD,
    isVisible: (user) => {
      const country = _.get(user, 'addr_country.iso_code');
      return country === 'ZA';
    },
  },
  gender: {
    displayName: () => text.GENDER,
    isVisible: (user, isIb) => {
      if (env === 'XSpotInternational' || env === 'XSpotMarkets' || (env === 'ICMCapital' && isIb)) {
        return true;
      } if (user.addr_country) {
        if (env === 'Zcom') {
          return _.includes(['DK', 'FR', 'DE', 'LU', 'NO', 'ES', 'SE', 'GB'], user.addr_country.iso_code);
        }
        return _.includes(['IT', 'ZA'], user.addr_country.iso_code);
      }

      return false;
    },
  },
  cpf: {
    displayName: () => text.CPF,
    isVisible: (user) => {
      const country = _.get(user, 'addr_country.iso_code');
      return country === 'BR';
    },
  },
  company: {
    displayName: () => text.COMPANY,
    isVisible: (user, isIb) => !isIb,
  },
  corporate: {
    ...displayBoolValue,
  },
  sms_notifications: {
    ...displayBoolValue,
  },
  is_editable: {
    ...displayBoolValue,
  },
  referrer: {
    displayName: () => text.REFERRER,
    displayValue: value => <HyperLink><a href={value} title={value}>{value}</a></HyperLink>,
  },
  ava_partners_tag_description: {
    displayName: () => text.TAG_DESCRIPTION,
    isVisible: () => env === 'AvaTrade',
  },
  joint_name_pronounce: {
    displayName: () => text.JOINT_NAME_PRONOUNCE,
    isVisible: () => env === 'AvaTrade',
  },
  risk_rating: {
    displayName: () => text.RISK_RATING,
    isVisible: () => env === 'AvaTrade',
  },
  trading_score: {
    displayName: () => text.TRADING_SCORE,
    isVisible: () => env === 'AvaTrade',
  },
  professional_trader_status: {
    displayName: () => text.PRO_TRADER_STATUS,
    isVisible: () => env === 'AvaTrade',
  },
  locked_login: {
    ...displayBoolValue,
    displayName: () => text.LOCKED_LOGIN,
    isVisible: () => env === 'AvaTrade',
  },
  middle_initial: {
    displayName: () => text.MIDDLE_NAME,
    isVisible: () => env === 'XSpotMarkets' || env === 'ICMCapital',
  },
  was_lead: {
    ...displayBoolValue,
  },
  first_landing_page: {
    displayValue: value => (value ? <HyperLink><a href={value} title={value}>{value}</a></HyperLink> : text.NA),
  },
  registration_complete_date: {
    ...displayDateValue,
  },
  date_docs_uploaded: {
    ...displayDateValue,
  },
  first_traded: {
    ...displayDateValue,
  },
  last_note_added: {
    ...displayDateValue,
  },
  last_action_time: {
    ...displayDateValue,
  },
  'company.company_name': {
    displayName: () => text.COMPANY_NAME,
    isVisible: (_, isIb) => isIb,
  },
  'company.website': {
    displayName: () => text.COMPANY_WEBSITE,
    isVisible: (_, isIb) => isIb,
  },
  'company.country': {
    displayName: () => text.COMPANY_COUNTRY,
    isVisible: (_, isIb) => isIb,
    ...displayCountryValue,
  },
  'company.addr_city': {
    displayName: () => text.COMPANY_ADDR_CITY,
    isVisible: (_, isIb) => isIb,
  },
  'company.address': {
    displayName: () => text.COMPANY_ADDR,
    isVisible: (_, isIb) => isIb,
  },
  'company.addr_line_2': {
    displayName: () => text.COMPANY_ADDR_2,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'company.target_region': {
    displayName: () => text.COMPANY_TARGET_REGION,
    isVisible: (_, isIb) => isIb,
  },
  'company.tax_id': {
    displayName: () => text.COMPANY_TARGET_TAX_ID,
    isVisible: (_, isIb) => isIb,
  },
  'signup_data.marketing_relationship': {
    displayName: () => text.MARKETING_AGENT_REL,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.client_list_generated': {
    displayName: () => text.GENERATE_CLIENT,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.clients_list_origin': {
    displayName: () => text.CLIENT_LEADS_ORIGIN,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.broker_relation_other': {
    displayName: () => text.BROKER_REL_OTHER,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.broker_names': {
    displayName: () => text.BROKER_NAMES,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.broker_number_of_clients': {
    displayName: () => text.BROKER_NUM_CLIENTS,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.broker_clients_monthly_average': {
    displayName: () => text.BROKER_AVERAGE_CLIENTS,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.spot_fx': {
    displayName: () => text.SPOT_FX,
    displayValue: value => (isEmpty(value) ? text.NA : `${value}%`),
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.commodity_futures': {
    displayName: () => text.COMMODITY_FEATURES,
    displayValue: value => (isEmpty(value) ? text.NA : `${value}%`),
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.financial_futures': {
    displayName: () => text.FIN_FEATURES,
    displayValue: value => (isEmpty(value) ? text.NA : `${value}%`),
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.off_exchange_index': {
    displayName: () => text.OFF_EXCHANGE,
    displayValue: value => (isEmpty(value) ? text.NA : `${value}%`),
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.otc_equity_cfds': {
    displayName: () => text.OTC,
    displayValue: value => (isEmpty(value) ? text.NA : `${value}%`),
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.accounts_month_one': {
    displayName: () => text.ACC_MONTH_ONE,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.accounts_month_two': {
    displayName: () => text.ACC_MONTH_TWO,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.accounts_month_three': {
    displayName: () => text.ACC_MONTH_THREE,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.deposits_month_one': {
    displayName: () => text.DEP_MONTH_ONE,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.deposits_month_two': {
    displayName: () => text.DEP_MONTH_TWO,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.deposits_month_three': {
    displayName: () => text.DEP_MONTH_THREE,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.marketing_agent_role': {
    displayName: () => text.MARKETING_AGENT_ROLE,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.unpaid_deficit_balance': {
    displayName: () => text.UNPAID_DEFICIT_BALANCE,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'signup_data.under_investigation': {
    displayName: () => text.UNDER_INVESTIGATION,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  clickid: {
    displayName: () => text.CLICK_ID,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'first_visit.ip_address': {
    displayName: () => text.FIRST_IP,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'first_visit.geo_data.city': {
    displayName: () => text.FIRST_CITY,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  'first_visit.geo_data.country_name': {
    displayName: () => text.FIRST_COUNTRY,
    isVisible: (_, isIb, value) => isIb && !isEmpty(value),
  },
  media_source: {
    displayName: () => gettext('Media source'),
    isVisible: (_, isIb, value) => isIb && !isEmpty(value) && env === 'TradeDirect365',
  },
  campaign: {
    displayValue: value => (isEmpty(value) ? text.NA : value.name),
  },
};

export default fieldId => ({ ...defaultModifier, ...(modifiers[fieldId] || {}) });
