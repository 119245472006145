import React from 'react';
import { Checkbox } from 'tc-biq-design-system';
import { number, array, func } from 'prop-types';

import { hasAccess } from '../../../../../../logic/services/acl';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';

const propTypes = {
  docId: number.isRequired,
  presets: array.isRequired,
  updatePreset: func.isRequired,
};

const Presets = ({ presets, docId, updatePreset }) => (
  <div key={docId} className="biq-settings-documents__multipreset__content">
    {presets.map((preset, index) => (preset.name ? (
      <div
        key={`${preset.display_name}-${index}`}
        className="biq-settings-documents__multipreset__content__item"
      >
        <Checkbox
          checked={preset.document_types.includes(docId)}
          name={preset.name}
          onChange={e => updatePreset(docId, preset.id, e.target.checked)}
          disabled={!hasAccess('document_presets.update')}
        >
          {preset.name}
        </Checkbox>
      </div>
    ) : null),
    )}
  </div>
);

Presets.propTypes = propTypes;

export default withErrorBoundary(Presets);
