import { gettext } from '../utilities/languageUtility';

export default {
  EXACT: { value: 'exact', label: gettext('is') },
  CONTAINS: { value: 'contains', label: gettext('contains') },
  ICONTAINS: { value: 'icontains', label: gettext('contains') },
  STARTSWITH: { value: 'startswith', label: gettext('starts with') },
  ISTARTSWITH: { value: 'istartswith', label: gettext('starts with') },
  ENDSWITH: { value: 'endswith', label: gettext('ends with') },
  IENDSWITH: { value: 'iendswith', label: gettext('ends with') },
  GT: { value: 'gt', label: gettext('greater than') },
  GTE: { value: 'gte', label: gettext('greater than or equal to') },
  LT: { value: 'lt', label: gettext('less than') },
  LTE: { value: 'lte', label: gettext('less than or equal to') },
  RANGE: { value: 'range', label: gettext('between') },
  ISNULL: { value: 'isnull', label: gettext('is unknown') },
  IN: { value: 'in', label: gettext('is one of') },
  MINUTES_AGO: { value: 'minutes_ago', label: gettext('time ago') },
  NOT_EXACT: { value: '!exact', label: gettext('is not') },
  NOT_CONTAINS: { value: '!contains', label: gettext("doesn't contain") },
  NOT_ICONTAINS: { value: '!icontains', label: gettext("doesn't contain") },
  NOT_STARTSWITH: { value: '!startswith', label: gettext("doesn't start with") },
  NOT_ISTARTSWITH: { value: '!istartswith', label: gettext("doesn't start with") },
  NOT_ENDSWITH: { value: '!endswith', label: gettext("doesn't end with") },
  NOT_IENDSWITH: { value: '!iendswith', label: gettext("doesn't end with") },
  NOT_RANGE: { value: '!range', label: gettext('all except between') },
  NOT_ISNULL: { value: '!isnull', label: gettext('has any value') },
  NOT_IN: { value: '!in', label: gettext('is not one of') },
  IS_EMPTY: { value: 'empty', label: gettext('is empty') },
  IS_NOT_EMPTY: { value: 'empty!', label: gettext('is not empty') }
};
