import { gettext } from '../../languageUtility';

const countryFormatter = (country) => {
  if (country) {
    const { country_name, iso_code, risk_type } = country;
    return `${country_name} [${iso_code}](${risk_type})`;  
  }

  return gettext('None');
};

export default countryFormatter; 
