/* eslint react/prop-types: 0 */
import React from 'react';

const OnContentClickCell = action => ({ data, value }) => {
  if (Array.isArray(value)) {
    return (
      <div className="like-a-link__group">
        {
        value.map((v, i) => <div style={{ marginLeft: '5px' }} className="like-a-link" onClick={() => action({ ...data, id: v })}>{ `${v} ${i === (value.length - 1) ? '' : ','}` }</div>)
        }
      </div>
    );
  }

  return (<div className="like-a-link" onClick={() => action({ ...data, id: value })}>{value}</div>);
};

export default OnContentClickCell;
