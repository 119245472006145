import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../logic/services/api-factory';
import { getPaymentGatewayMethodUrlId } from './Components/ExecuteWithdrawal/logic/utils';

const actionTypes = {
  ACTIVATE: 'ACTIVATE_SINGLE_WITHDRAWAL',
  ACTIVATE_PENDING: 'ACTIVATE_SINGLE_WITHDRAWAL_PENDING',
  ACTIVATE_REJECTED: 'ACTIVATE_SINGLE_WITHDRAWAL_REJECTED',
  ACTIVATE_FULFILLED: 'ACTIVATE_SINGLE_WITHDRAWAL_FULFILLED',
  RESET: 'RESET',
};

const cardApi = method => getDjangoApi(`payment_gateway_accounts/${method}`);

export const activate = createAction(
  actionTypes.ACTIVATE, 
  async (api) => { 
    let cardInfo = null;

    const { data: withdrawal } = await api.list();

    const method = getPaymentGatewayMethodUrlId(withdrawal.method);

    if (withdrawal.payment_gateway_account) {
      cardInfo = await cardApi(method).retrieve(withdrawal.payment_gateway_account.id);
    }
  
    return { 
      withdrawal, 
      cardInfo: cardInfo ? cardInfo.data : {},
    };
  },
);

export const reset = createAction(actionTypes.RESET);

const initialState = {
  withdrawal: {},
  relatedPaymentTransaction: {},
  fetchInProgress: false,
  errors: null,
  cardInfo: {},
};

const fetchPending = state => ({ ...state, fetchInProgress: true });

const fetchRejected = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  errors: payload.data,
});

const fetchFulfilled = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  withdrawal: payload.withdrawal,
  relatedPaymentTransaction: {},
  cardInfo: payload.cardInfo,
});

export default handleActions(
  {
    [actionTypes.ACTIVATE_PENDING]: fetchPending,
    [actionTypes.ACTIVATE_FULFILLED]: fetchFulfilled,
    [actionTypes.ACTIVATE_REJECTED]: fetchRejected,
    [actionTypes.RESET]: () => initialState,
  },
  initialState,
);
