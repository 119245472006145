
const NestedCell = (columnKey, fieldKey, cellRenderer) => ({ data }) => {
  const columnData = data[columnKey].find(element => element.key === fieldKey);
  return columnData && columnData.value ? cellRenderer({ value: columnData.value }) : null;
};

const renderNestedColumnsModifiers = (key, data, cellRenderer) => {
  let fieldModifiers = {};
  if (!data || data.length === 0) return fieldModifiers;
  data[0][key].forEach((field) => {
    fieldModifiers = { ...fieldModifiers, [`${key}__${field.key}`]: { cellRendererFramework: NestedCell(key, field.key, cellRenderer) } };
  });
  return fieldModifiers;
};

export default renderNestedColumnsModifiers;
