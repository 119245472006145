import { bindActionCreators } from 'redux';

import connect from '../../../../../../../logic/connect';
import CommissionTable from './CommissionTable';
import overlayActions from '../../../../../../../components/overlay';

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  return {
    user: page.userDetail.user,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommissionTable);
