import React from 'react';
import { SidepanelProvider, ComboxProvider } from 'tc-biq-design-system';

import appRoutes from '../../../../components/App/Router/appRoutes';
import ClientProfile from './ClientProfile';

const ClientProfileController = props => (
  <ComboxProvider>
    <SidepanelProvider>
      <ClientProfile {...props} ib />
    </SidepanelProvider>
  </ComboxProvider>
);

export default {
  component: ClientProfileController,
  path: appRoutes.IB_PROFILE,
  aclId: 'ib.retrieve',
};
