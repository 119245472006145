import { bindActionCreators } from 'redux';

import connect from '../../../../../../logic/connect';
import TradingGroupsTable from './TradingGroupsTable';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    dispatch,
  ), 
  dispatch,
});

export default connect(null, mapDispatchToProps)(TradingGroupsTable);
