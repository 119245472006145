import React, { PureComponent } from 'react';
import { object, bool, func, array } from 'prop-types';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import FormFactory, { ErrorHandler, FormActionsFactory } from '../../../../../components/form';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import connect from '../../../../../logic/connect';
import withUnmountMethod from '../../../../../components/hoc/withUnmountMethod';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { SidepanelFooter } from '../../../../../components/common';
import overlayActions from '../../../../../components/overlay';
import to from '../../../../../logic/utilities/to';

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
  submitInProgress: bool.isRequired,
  onSuccess: func.isRequired,
  fields: array.isRequired,
};

export const SIDEPANEL_ID = 'COMPLIENCE_STATUS_SIDEPANEL';
const { errorFields, create, update, resetFields, setRowData } = FormActionsFactory(SIDEPANEL_ID);

const text = {
  TITLE: gettext('Create Compliance Status'),
  EDIT_TITLE: gettext('Edit Compliance Status'),
  BUTTON_LABELS: {
    confirm: gettext('Create'),
    cancel: gettext('Discard'),
  },
  EDIT_BUTTON_LABELS: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
  ERROR: gettext('Error'),
  UPDATE_SUCCESS: gettext('Compliance status successfully saved'),
  UPDATE_ERROR: gettext('Error while saving compliance status'),
  CREATE_SUCCESS: gettext('Compliance status successfully created'),
  CREATE_ERROR: gettext('Error while saving compliance status'),
};

const api = getDjangoApi('compliance_status/');

const formConfig = () => ({
  form: SIDEPANEL_ID,
  api,
  excludeFields: [],
});

const customFooter = (execute, close, submitInProgess, isEdit) => (
  <SidepanelFooter
    submitInProgress={submitInProgess}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={isEdit ? text.EDIT_BUTTON_LABELS : text.BUTTON_LABELS}
  />
);

class ComplianceStatusSidepanel extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  onClose = () => {
    this.actions.closeSidepanel(SIDEPANEL_ID);
  };

  onSubmit = async () => {
    let compId = null;
    const { fields, onSuccess, sidepanel } = this.props;
    const { parameters } = sidepanel;
    const isEdit = parameters && parameters.mode === 'Edit';
    if (parameters && isEdit) compId = parameters.data.id;
    const SUCCESS_MESSAGE = isEdit ? text.UPDATE_SUCCESS : text.CREATE_SUCCESS;
    const ERROR_MESSAGE = isEdit ? text.UPDATE_ERROR : text.CREATE_ERROR;

    const [err] = await (isEdit
      ? to(this.actions.update(api, compId))
      : to(this.actions.create(api)));

    if (err) {
      if (err.data.non_field_errors) {
        notifier.error(err.data.non_field_errors[0]);
        return;
      }
      new ErrorHandler({ errors: err.data, fields }).showMessages(ERROR_MESSAGE);
    } else {
      notifier.success(SUCCESS_MESSAGE);
      this.onClose(true);
      onSuccess();
    }
  };

  renderForm = () => {
    const { sidepanel } = this.props;
    const { parameters } = sidepanel;
    if (parameters && parameters.data) this.actions.setRowData(parameters.data);
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig());
    }
    const { FormComponent } = this;
    if (parameters && parameters.data) this.actions.setRowData(parameters.data);
    return <FormComponent />;
  };


  render() {
    const { sidepanel, submitInProgress } = this.props;
    const { parameters } = sidepanel;
    const isEdit = parameters && parameters.mode === 'Edit';
    return (
      <Sidepanel
        icon="Pen"
        title={isEdit ? text.EDIT_TITLE : text.TITLE}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.onSubmit, this.onClose, submitInProgress, isEdit)}
      >
        {this.renderForm()}
      </Sidepanel>
    );
  }
}

ComplianceStatusSidepanel.propTypes = propTypes;

const mapStateToProps = ({ overlays, forms }) => {
  const sidepanel = overlays[SIDEPANEL_ID];
  const { submitInProgress, fields } = forms[SIDEPANEL_ID];
  return { sidepanel, submitInProgress, fields };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      errorFields,
      create,
      resetFields,
      setRowData,
      update,
    },
    dispatch,
  ),
});

export default withUnmountMethod(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(ComplianceStatusSidepanel)));
