import React from 'react';

import AssignedClients from '../../AssignedClients';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import { hasAccess } from '../../../../../../../../logic/services/acl';

const text = {
  TITLE: gettext('Assigned clients per sales status'),
};

const api = getDjangoApi('dashboard/users_per_sales_status');

const AssignedClientsComponent = AssignedClients.component;
const AssignedClientsPerSalesStatus = () => <AssignedClientsComponent text={text} api={api} />;

const size = {
  ...AssignedClients.size,
  x: 4,
  y: 5,
};

export default {
  component: AssignedClientsPerSalesStatus,
  size,
  key: 'clients-per-sales-status',
  label: text.TITLE,
  isVisible: () => hasAccess('dashboard.users_per_sales_status.*'),
};
