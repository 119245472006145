import React, { Component } from 'react';
import { object, bool, string } from 'prop-types';
import { Button, Space, notifier } from 'tc-biq-design-system';

import { LoginLogo } from '../../../../components/Nav/logos';
import FormPanel from '../../../../components/form/Components/FormPanel/FormPanel';
import { gettext } from '../../../../logic/utilities/languageUtility';
import FormFactory from '../../../../components/form';
import Page from '../../../../components/Page';
import to from '../../../../logic/utilities/to';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import appRoutes from '../../../../components/App/Router/appRoutes';
import { formErrorHandler } from '../../../../components/form/logic/utils';

export const FORM_KEY = 'RESET_PASSWORD_FORM';

const api = token => getDjangoApi(`forgot-password/reset/${token}`);

const text = {
  PASSWORD: gettext('Password'),
  CONFIRM_PASSOWRD: gettext('Confirm password'),
  SUBMIT: gettext('Restore password'),
  TITLE: gettext('Reset password'),
  SUCCESS: gettext('Your password has been changed'),
  ERROR_GENERAL: gettext('Something went wrong'),
};

const password = {
  type: 'password',
  id: 'password1',
  name: 'password1',
  placeholder: text.PASSWORD,
  label: text.PASSWORD,
};

const confirmPassword = {
  type: 'password',
  id: 'password2',
  name: 'password2',
  placeholder: text.CONFIRM_PASSOWRD,
  label: text.CONFIRM_PASSOWRD,
};


const propTypes = {
  actions: object.isRequired,
  submitInProgress: bool.isRequired,
  token: string.isRequired,
  history: object.isRequired,
};

class ResetPasswordForm extends Component {
  onSubmit = async () => {
    const { actions, token } = this.props;
    const [err] = await to(actions.create(api(token)));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    notifier.success(text.SUCCESS, {
      onClose: () => this.goToLoginPage(),
    });
  }

  onError(payload) {
    formErrorHandler(text.ERROR_GENERAL)(payload);
  }

  goToLoginPage = () => {
    const { history } = this.props;
    history.push(appRoutes.LOGIN);
  }

  formConfig = () => ({
    form: FORM_KEY,
    customFields: [
      password,
      confirmPassword,
    ],
  })

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const Form = this.renderForm();
    const { submitInProgress } = this.props;
    return (
      <Page style={{}} hideHeader>
        <div className="auth-page">
          <Form renderForm={formFields => (
            <FormPanel size="small">
              <FormPanel.Section hideBorder>
                <p className="tc-heading-s auth-page__welcome">{text.TITLE}</p>
                <div className="auth-page__logo">
                  <LoginLogo />
                </div>
                <Space size={30} />
                {formFields.password1}
                <Space size={20} />
                {formFields.password2}
                <Space size={20} />
                <Button loading={submitInProgress} onClick={this.onSubmit}>{text.SUBMIT}</Button>
              </FormPanel.Section>
            </FormPanel>
          )} 
          />
        </div>
      </Page>
    );
  }
}

ResetPasswordForm.propTypes = propTypes;

export default ResetPasswordForm;
