import React, { Component } from 'react';

import withFormSidepanel from '../../../../../../components/FormSidepanel';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';

export const SIDEPANEL_ID = 'TRADING_GROUPS_FORM_SIDEPANEL';
export const FORM_ID = 'TRADING_GROUPS_FORM';

const apiUrl = 'trading_groups';

const text = {
  CREATE: gettext('Create trading group'),
  EDIT: gettext('Edit trading group'),
  SUCCESS: {
    create: gettext('Trading group successfully created'),
    edit: gettext('Trading group successfully updated'),
  },
};

class TradingGroupSidepanel extends Component {
  constructor(props) {
    super(props);

    this.FormSidepanel = withFormSidepanel(FORM_ID, SIDEPANEL_ID);
  }

  getRequestPayload = () => ({ name, currency, backend, whitelabel, active }) => ({
    ...(name && { name }),
    ...(currency && { currency: currency.value }),
    ...(backend && { backend: backend.value }),
    ...(whitelabel && { whitelabel: whitelabel.name }),
    active: !!active,
  })

  getApi = () => getDjangoApi(apiUrl)

  render() {
    const { FormSidepanel, getApi, getRequestPayload, prepareRowData } = this;
    return (
      <FormSidepanel
        {...this.props}
        options={{
          getApi,
          getRequestPayload,
          prepareRowData,
          text,
        }}
      />
    );
  }
}

export default TradingGroupSidepanel;
