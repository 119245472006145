import React from 'react';
import { any, bool } from 'prop-types';
import classNames from 'classnames';

import './TreeWrapper.scss';

const propTypes = {
  children: any.isRequired,
};

const TreeWrapperItem = ({ children, collapsed }) => (
  <div className={classNames('biq-tree-wrapper__item', { 'biq-tree-wrapper__item--collapsed': collapsed })}>
    {children}
  </div>
);

const TreeWrapper = ({ children, reset }) => (
  <div className={classNames('biq-tree-wrapper', { 'biq-tree-wrapper--reset': reset })}>
    <div className="biq-tree-wrapper__content">
      {children}
    </div>
  </div>
);

TreeWrapperItem.propTypes = { 
  ...propTypes,
  collapsed: bool,
};

TreeWrapperItem.defaultProps = { 
  ...propTypes,
  collapsed: true,
};

TreeWrapper.propTypes = { 
  ...propTypes,
  reset: bool,
};

TreeWrapper.defaultProps = {
  reset: false,
};

TreeWrapper.Item = TreeWrapperItem;

export default TreeWrapper;
