/* 
  Helper to format loadOptions callback in Async Select component
*/

import { getDjangoApi } from '../services/api-factory';

const loadOptions = (url, displayKey, limit = 10, valueKey = 'id', query = {}) => async (value) => {
  if (url && displayKey) {
    const response = await getDjangoApi(url).list({ limit, offset: 0, [displayKey]: value, ...query });
    return response.data.results.map(result => ({
      display_name: result[displayKey],
      value: result[valueKey],
    }));
  }
};

export default loadOptions;
