import { createAction } from 'redux-actions';
import actionTypeFactory from './actionTypeFactory';
import session from '../../../../../logic/services/session';

const bulkActionsActionFactory = (action) => {
  const { 
    UPDATE_DATA, 
    RESET_FIELDS, 
    SUBMIT, 
    RESET_SUBMIT,
    LOAD_OPTIONS, 
    SHOULD_REMOVE_TAGS, 
  } = actionTypeFactory(action);

  const updateData = createAction(UPDATE_DATA, tags => tags);
  const resetInput = createAction(RESET_FIELDS);
  const shouldRemoveTags = createAction(SHOULD_REMOVE_TAGS);
  const loadOptions = createAction(LOAD_OPTIONS, async (usersPromise, teamsPromise, text) => {
    const response = await Promise.all([usersPromise, teamsPromise]);
    return formatResults(response, text);
  });
  const submit = createAction(SUBMIT, async (api, data, destroyId) => {
    const responseData = await (destroyId ? api.destroy(destroyId) : api.create(data));
    const response = responseData.data;
    return { response };
  });
  const resetSubmit = createAction(RESET_SUBMIT);

  return {
    updateData,
    resetInput,
    resetSubmit,
    submit,
    shouldRemoveTags,
    loadOptions,
  };
};

function formatResults(response, text) {
  const [usersResults, teamsResults] = response;
  
  const users = usersResults ? usersResults.data.results.map(user => ({
    label: user.username,
    id: user.id,
    group: text.GROUP_USERS,
  })) : [];
  
  const teams = teamsResults ? teamsResults.data.results.map(team => ({
    label: team.name,
    id: team.id,
    group: text.GROUP_TEAMS,
  })) : [];
  
  return [
    { id: 'none', label: text.NONE, group: text.GROUP_DEFAULT },
    { id: session.user.id, label: session.user.username, group: text.GROUP_DEFAULT },
    ...users,
    ...teams,
  ];
}

export default bulkActionsActionFactory;
