/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import Page from '../../../../components/Page';
import UserPreview from '../../../../components/common/sidepanels/UserPreview';
import Export from '../../../../components/Export';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import TradePosition from '../TradePositionSidepanel';
import overlayActions from '../../../../components/overlay';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import { positionModifiers } from '../common-modifiers';
import If from '../../../../components/If';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import TradeSingle from '../Trades/sidepanels/TradeSingle';
import appRoutes from '../../../../components/App/Router/appRoutes';
import { toSingleItemUrl } from '../../../../logic/utilities/toPath';

const pageConfig = {
  apiUrl: 'trades/positions/closed',
  reducerKey: 'CLOSED_POSITIONS',
  tableKey: 'CLOSED_POSITIONS_TABLE',
};

const text = {
  TITLE: gettext('Closed positions'),
  USER_TITLE: gettext('Closed positions - Completed user trades'),
};

const { GridComponent, actions } = GridFactory(pageConfig);
const SIDEPANEL_ID = 'TRADE_POSITION';
const tradePositionApi = getDjangoApi('trades/positions');
class ClosedPositions extends Component {
  constructor(props) {
    super(props);

    this.headerActions = this.headerActions.bind(this);
    this.openPositionSidepanel = this.openPositionSidepanel.bind(this);

    this.tableActions = {
      previewInSidepanel: this.openPositionSidepanel.bind(this),
      onUserPreview: props.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
    };

    this.bread = [{ label: text.TITLE, route: appRoutes.CLOSED_POSITIONS }];
    this.title = text.TITLE;

    this.uid = props.match.params.id;
  
    if (this.uid) this.openPositionSidepanel({ id: this.uid });
  }

  headerActions() {
    const { exportUrl } = this.props;
    return (
      <If condition={hasAccess('trade.positions_closed.export.*')}>
        <Export url={exportUrl} />
      </If>
    );
  }

  async openPositionSidepanel(data) {
    const { actions } = this.props;
    const response = await tradePositionApi.retrieve(data.id);
    actions.openSidepanel(SIDEPANEL_ID, response.data);
    window.history.replaceState(null, '', toSingleItemUrl(data.id));
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: positionModifiers(this.tableActions),
    };

    return (
      <Page 
        headerActions={this.headerActions} 
        bread={this.bread}
        title={this.title}
      >
        <Filters {...filterProps} />
        <GridComponent tableModifier={positionModifiers(this.tableActions)} {...this.props} />
        <TradePosition sidepanelId={SIDEPANEL_ID} />
        <TradeSingle />
        <UserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { 
      changePageSize: actions.changePageSize,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      openSidepanel: overlayActions.open, 
    }, 
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(ClosedPositions); 
