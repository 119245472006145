import React from 'react';
import { string } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import { Tooltip, Popconfirm, Icon, HyperLink } from 'tc-biq-design-system';

import hideCols from '../../../../../../logic/utilities/hideCols';
import { ToggleCell, ActionsCellWrapper } from '../../../../../../components/gridCellRenderers';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import displayFromChoices from '../../../../../../logic/utilities/formatters/displayFromChoices';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

const renderPercents = ({ value }) => <div>{`${value}%`}</div>;

renderPercents.propTypes = {
  value: string.isRequired,
};

const text = {
  POPCONFIRM_LABEL: gettext('Are you sure you want to delete this deposit expiry period?'),
  NO: gettext('No'),
  YES: gettext('Yes'),
  MODIFY: gettext('Edit'),
  CLONE: gettext('Clone'),
  DELETE: gettext('Delete'),
};

const actionIcons = (onModify, onDelete, onClone) => ({
  ...(onModify && {
    onModify: (actionHandler, data) => {
      const url = actionHandler(data);
      return (
        <div key="Modify">
          <Link to={url}>
            <Tooltip title={text.MODIFY} placement="top">
              <Icon name="Edit" />
            </Tooltip>
          </Link>
        </div>
      );
    },
  }),
  ...(onDelete && {
    onDelete: (actionHandler, data) => (
      <Tooltip title={text.DELETE} placement="top">
        <Popconfirm
          type="destructive"
          icon="Delete"
          onConfirm={() => actionHandler(data)}
          label={text.POPCONFIRM_LABEL}
          buttonLabels={{ cancel: text.NO, confirm: text.YES }}
        >
          <Icon name="Delete" />
        </Popconfirm>
      </Tooltip>
    ),
  }),
  ...(onClone && {
    onClone: (actionHandler, data) => {
      const url = actionHandler(data);
      return (
        <div key="Clone">
          <Link to={url}>
            <Tooltip title={text.CLONE} placement="top">
              <Icon name="Duplicate" />
            </Tooltip>
          </Link>
        </div>
      );
    },
  }),
});


export default ({ toggleEnable, onDelete, onModify, onClone }) => {
  const actions = {
    ...(onModify && { onModify }),
    ...(onDelete && { onDelete }),
    ...(onClone && { onClone }),
  };
  return { ...hideCols(['id', 'expiry_conditions', 'created', 'created_by']),
    ...(toggleEnable && {
      is_enabled: {
        width: 100,
        pinned: 'left',
        cellRendererFramework: ToggleCell(toggleEnable),
      },
    }),
    method: {
      valueGetter: displayFromChoices('method'),
    },
    acquirer: {
      valueGetter: displayFromChoices('acquirer'),
    },
    expiry_period_type: {
      valueGetter: displayFromChoices('expiry_period_type'),
    },
    applies_to: {
      valueGetter: displayFromChoices('applies_to'),
    },
    modified_by: {
      displayName: gettext('Updated by'),
      // eslint-disable-next-line
      cellRendererFramework: ({ data }) => {
        const url = generatePath(appRoutes.CLIENT_PROFILE, { id: data.modified_by.id }); // eslint-disable-line
        return <HyperLink><Link to={url}>{data.modified_by.username}</Link></HyperLink>; // eslint-disable-line
      },
    },
    modified: {
      displayName: gettext('Updated'),
    },
    actions: {
      label: gettext('Actions'),
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(onModify, onDelete, onClone)),
      width: 120,
      pinned: 'right',
    },
  };
};
