import { handleActions, createAction } from 'redux-actions';
import { isNil, omit } from 'lodash';
import { parseFilterKey } from './filterUtils';

const RESET = 'resetFilter';
const RESET_DROPDOWN = 'resetDropdown';
const CHANGE_VISIBLE_FILTER = 'changeVisibleFilter';
const CHANGE_SELECTED_FIELD = 'changeSelectedField';
const CHANGE_FILTER = 'changeFilterForCurrentSelectedField';
const CHANGE_OPERATOR = 'changeOperator';
const CHANGE_VALUE = 'changeValue';
const CHANGE_ACTIVE_FILTER = 'changeActiveFilter';
const CHANGE_ACTIVE_FILTERS = 'changeAllActiveFilters';
const DELETE_ACTIVE_FILTER = 'deleteActiveFilter';
const SAVE_AUTOCOMPLETE_CHOICE = 'saveAutocompleteChoice';

// Actions
export const setVisibleFilter = createAction(CHANGE_VISIBLE_FILTER);
export const setActiveField = createAction(CHANGE_SELECTED_FIELD);
export const resetFilterStore = createAction(RESET);
export const resetDropdownStore = createAction(RESET_DROPDOWN);
export const setFilter = createAction(CHANGE_FILTER);
export const setOperator = createAction(CHANGE_OPERATOR);
export const setValue = createAction(CHANGE_VALUE);
export const setActiveFilter = createAction(CHANGE_ACTIVE_FILTER);
export const setActiveFilters = createAction(CHANGE_ACTIVE_FILTERS);
export const removeActiveFilter = createAction(DELETE_ACTIVE_FILTER);
export const setAutocompleteChoice = createAction(SAVE_AUTOCOMPLETE_CHOICE);

// Reducers
export const initialState = {
  visibleFilter: null,
  selectedField: {
    key: '',
    label: '',
    type: '',
  },
  filter: {
    operator: null,
    value: '',
  },
  activeFilters: {},
  autocompleteChoices: [],
};

const changeVisibleFilter = (state, action) => ({
  ...state,
  visibleFilter: action.payload,
});

const changeSelectedField = (state, action) => ({
  ...state,
  selectedField: action.payload,
});

const changeFilter = (state, action) => ({
  ...state,
  filter: action.payload,
});

const changeOperator = (state, action) => ({
  ...state,
  filter: {
    ...state.filter,
    operator: action.payload,
  },
});

const changeValue = (state, { payload }) => ({
  ...state,
  filter: {
    ...state.filter,
    value: payload,
  },
});

const saveAutoCompleteChoice = (state, { payload }) => {
  const { value, isMultipleSelect } = payload;
  const choices = isMultipleSelect 
    ? [...state.autocompleteChoices, ...value] 
    : [...state.autocompleteChoices, value];
  return {
    ...state,
    autocompleteChoices: _.uniqBy(choices, 'display_name'),
  };
};

const changeActiveFilter = (state, { payload }) => {
  const activeFilters = { ...state.activeFilters };
  const newActivefilters = updateActiveFilters(payload, activeFilters);
  return {
    ...state,
    activeFilters: newActivefilters,
  };
};

const updateActiveFilters = ({ key, value }, activeFilters) => {
  const newActivefilters = {};
  const { key: parsedSelectedKey } = parseFilterKey(key);
  let filterValue;
  
  // Get new value
  if (isNil(value)) {
    filterValue = null;
  } else if (!isNil(value.value)) {
    filterValue = value.value;
  } else {
    filterValue = value;
  }

  const isUpdate = Object.keys(activeFilters).some((filter) => {
    const { key: parsedKey } = parseFilterKey(filter);
    
    return parsedKey === parsedSelectedKey;
  });
  
  // Update filter
  Object.keys(activeFilters).forEach((filter) => {
    const { key: parsedKey } = parseFilterKey(filter);

    if (parsedKey === parsedSelectedKey) {
      newActivefilters[key] = filterValue;
    } else {
      newActivefilters[filter] = activeFilters[filter];
    }
  });

  // If doesn't already exist create new
  if (!isUpdate) {
    newActivefilters[key] = filterValue;
  }

  // Remove filter if value is nil
  if (_.isNil(filterValue)) {
    delete newActivefilters[key];
  }

  return newActivefilters;
};

const changeActiveFilters = (state, { payload }) => ({
  ...state,
  activeFilters: payload || {},
});

const deleteActiveFilter = (state, { payload }) => changeActiveFilter(state, { payload: { key: payload.key, value: null } });

const reset = () => initialState;

const resetDropdown = store => ({
  ...store,
  ...omit(initialState, ['activeFilters', 'autocompleteChoices']),
});

export const filterReducers = handleActions(
  {
    [CHANGE_VISIBLE_FILTER]: changeVisibleFilter,
    [CHANGE_SELECTED_FIELD]: changeSelectedField,
    [CHANGE_FILTER]: changeFilter,
    [CHANGE_OPERATOR]: changeOperator,
    [CHANGE_VALUE]: changeValue,
    [CHANGE_ACTIVE_FILTER]: changeActiveFilter,
    [CHANGE_ACTIVE_FILTERS]: changeActiveFilters,
    [DELETE_ACTIVE_FILTER]: deleteActiveFilter,
    [RESET]: reset,
    [RESET_DROPDOWN]: resetDropdown,
    [SAVE_AUTOCOMPLETE_CHOICE]: saveAutoCompleteChoice,
  },
  initialState,
);

export const getFilters = state => state.filters;
export const getActiveFilters = state => getFilters(state).activeFilters;
export const getAutocompleteChoices = state => getFilters(state).autocompleteChoices;
export const getFilter = state => getFilters(state).filter;
export const getSelectedField = state => getFilters(state).selectedField;
export const getVisibleFilter = state => getFilters(state).visibleFilter;
