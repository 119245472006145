import React from 'react';
import { string, object, func } from 'prop-types';
import { Pill, Button, Popconfirm } from 'tc-biq-design-system';

import protraderStates from '../../../../../../../logic/enums/protrader-states';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import dateTimeFormatter from '../../../../../../../logic/utilities/formatters/dateTimeFormatter';
import If from '../../../../../../../components/If';

const propTypes = {
  state: object,
  approval_time: string,
  rejection_time: string,
  cancellation_time: string,
  manually_reviewed_by: object,
  onApprove: func.isRequired,
  onReject: func.isRequired,
};

const defaultProps = {
  state: null,
  approval_time: '',
  rejection_time: '',
  cancellation_time: '',
  manually_reviewed_by: {},
};

const text = {
  STATUS: gettext('Professional trader status'),
  APPROVAL_DATE: gettext('Approval date'),
  REJECTION_DATE: gettext('Rejection date'),
  CANCELLATION_DATE: gettext('Cancellation date'),
  REVIEWED_BY: gettext('Reviewed by'),
  MANUAL_APPROVE: gettext('Manual approve'),
  MANUAL_REJECT: gettext('Manual Reject'),
  APPROVE_LABEL: gettext('Are you sure you want to approve professional trader status?'),
  REJECT_LABEL: gettext('Are you sure you want to reject professional trader status?'),
  BUTTON_LABELS: {
    cancel: gettext('No'), 
    confirm: gettext('Yes'), 
  },
};

const pillType = {
  [protraderStates.PENDING]: 'status03',
  [protraderStates.APPROVED]: 'status01',
  [protraderStates.REJECTED]: 'status04',
  [protraderStates.MANUALLY_APPROVED]: 'status01',
  [protraderStates.MANUALLY_REJECTED]: 'status04',
};

const showApproveButton = state => state === protraderStates.PENDING
  || state === protraderStates.REJECTED
  || state === protraderStates.MANUALLY_REJECTED;

const showRejectButton = state => state === protraderStates.PENDING
  || state === protraderStates.APPROVED
  || state === protraderStates.MANUALLY_APPROVED;

// eslint-disable-next-line
const StatusInfo = ({ label, value }) => (
  <span className="m-r">
    <span className="text-neutral-600 tc-micro-regular m-r-xs">{label}:</span>
    <span className="text-neutral-900 tc-micro-regular">{value}</span>
  </span>
);

const Header = ({
  state,
  approval_time,
  rejection_time,
  cancellation_time,
  manually_reviewed_by,
  onApprove,
  onReject,
}) => {
  const { display_name, value } = state;

  return (
    <div className="biq-protrader__header">
      <div>
        <p className="biq-protrader__header-status text-neutral-900 tc-heading-m">
          {text.STATUS}
        </p>
        <Pill className="biq-protrader__status-pill" type={pillType[value]}>
          {display_name}
        </Pill>
      </div>

      <div className="biq-protrader__status-info">
        <div>
          <If condition={value === protraderStates.APPROVED || value === protraderStates.MANUALLY_APPROVED}>
            <StatusInfo 
              label={text.APPROVAL_DATE} 
              value={dateTimeFormatter(approval_time, false)} 
            />
          </If>

          <If condition={value === protraderStates.REJECTED || value === protraderStates.MANUALLY_REJECTED}>
            <StatusInfo 
              label={text.REJECTION_DATE} 
              value={dateTimeFormatter(rejection_time, false)} 
            />
          </If>

          <If condition={value === protraderStates.CANCELLED}>
            <StatusInfo 
              label={text.CANCELLATION_DATE} 
              value={dateTimeFormatter(cancellation_time, false)} 
            />
          </If>

          {manually_reviewed_by ? (
            <StatusInfo 
              label={text.REVIEWED_BY} 
              value={manually_reviewed_by.username} 
            />
          ) : null}

          <If condition={state !== protraderStates.CANCELLED}>
            <If condition={showApproveButton(value)}>
              <Popconfirm
                icon="Approve"
                label={text.APPROVE_LABEL}
                onConfirm={onApprove}
                buttonLabels={text.BUTTON_LABELS}
              >
                <Button color="confirmation" size="small">{text.MANUAL_APPROVE}</Button>
              </Popconfirm>
            </If>

            <If condition={showRejectButton(value)}>
              <Popconfirm
                icon="Reject"
                label={text.REJECT_LABEL}
                onConfirm={onReject}
                buttonLabels={text.BUTTON_LABELS}
              >
                <Button color="destructive" size="small">{text.MANUAL_REJECT}</Button>
              </Popconfirm>
            </If>
          </If>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
export default Header;
