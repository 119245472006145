import React from 'react';

import { Icon, Tooltip } from 'tc-biq-design-system';
import { object } from 'prop-types';

import './InfoCell.scss';

const propTypes = {
  children: object.isRequired,
};

const InfoCell = (props) => {
  const { children } = props;

  return (
    <div className="info-cell-wrapper">
      <Tooltip
        content={children}
        placement="right"
        className="info-cell-tooltip"
        tooltipOptions={{ trigger: 'click' }}
      >
        <Icon colorName="text-primary-500" bgColorName="" name="Info" />
      </Tooltip>
    </div>
  );
};

InfoCell.propTypes = propTypes;

export default InfoCell;
