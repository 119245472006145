import { bindActionCreators } from 'redux';

import TradingAccountForm from './TradingAccountForm';
import { fetchLabels, fetchLeverages, fetchTradingAccounts, fetchTradingBackends } from './Model';
import { FormActionsFactory } from '../../../../../../components/form';
import { fetchUser } from '../../Model';
import connect from '../../../../../../logic/connect';
import TradingAccountAvaForm from './TradingAccountAvaForm';

const FORM_KEY = 'TRADING_ACCOUNT_FORM';

const env = window.config.environment;

const { update, create, setFieldValue,
  setFieldsErrors, showLoader, resetValues } = FormActionsFactory(FORM_KEY);


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      fetchTradingAccounts,
      fetchTradingBackends,
      fetchLeverages,
      fetchLabels,
      showLoader,
      update,
      create,
      setFieldValue,
      setFieldsErrors,
      resetValues,
    },
    dispatch,
  ),
  dispatch,
});

const mapStateToProps = ({ forms, pages }) => {
  const form = forms[FORM_KEY];
  const page = pages.USER_SINGLE;
  const action = page.actions.TRADING_ACCOUNT;
  const trandingAccounts = _.get(pages, 'USER_SINGLE.tables.CLIENT_TRADING_ACCOUNTS_TABLE.data');
  return {
    submitInProgress: form.submitInProgress,
    formFields: form.fields,
    formValues: form.values,
    formErrors: form.errors,
    accounts: action.accounts,
    backends: action.backends,
    leverages: action.leverages,
    allowedAccounts: action.allowedAccounts,
    labels: action.labels,
    trandingAccounts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(env === 'AvaTrade' ? TradingAccountAvaForm : TradingAccountForm);
