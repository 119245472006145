import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';
import Bonus from './Bonus';
import userActionTypes from '../types';

const text = {
  LABEL: gettext('Manual bonus'),
};

const ACTION_ICON = 'Star';

export default {
  name: 'manualBonus',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.TRANSACTIONS,
  hasPermission(state, user, isIb) {
    return !isIb 
      && hasAccess('user.bonuses.create') 
      && isFeatureEnabled()('MANUAL_BONUS')
      && state >= USER_STATE.PENDING;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(Bonus, sidepanelOptions);
  },
};
