export const keepIfNotHidden = (modifier = {}) => column => !_.get(modifier[column.key], 'hide');
export const hideDeselected = (modifier, visibleFields) => column => ({ 
  ...column,
  visible: _.isEmpty(visibleFields) ? !_.get(modifier[column.key], 'deselect') : visibleFields.includes(column.key),
});

const mapLabel = modifier => (item) => {
  // eslint-disable-next-line no-param-reassign
  item.label = _.get(modifier, [item.key, 'headerName'], item.label);
  return item;
};

export const getSortValue = (field, modifier = {}, visibleFields = []) => {
  if (!_.isEmpty(visibleFields)) {
    const indexOf = visibleFields.indexOf(field.key);
    return indexOf - visibleFields.length;
  }

  return (modifier[field.key] ? (modifier[field.key].sortValue || -1) : -1);
};

export const applyModifiers = (items, modifier = {}, visibleFields = []) => {
  const filtered = items
    .filter(keepIfNotHidden(modifier))
    .map(hideDeselected(modifier, visibleFields))
    .map(mapLabel(modifier));
  const sorted = filtered.sort((c1, c2) => (getSortValue(c1, modifier, visibleFields) - getSortValue(c2, modifier, visibleFields)));
  return [filtered, sorted];
};
