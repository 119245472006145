import activeTraders from './ActiveTraders';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../../logic/services/acl';

export default {
  ...activeTraders,
  key: 'active-traders',
  label: gettext('Active Traders'),
  isVisible: () => hasAccess('dashboard.active_traders.*'),
};
