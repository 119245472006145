import userStates from '../enums/user-state';
import runningStates from '../enums/kyc-running-state';
import profileStates from '../enums/kyc-state';
import gatewayLabels from '../enums/kyc-gateway-label';

function KycValidationService() {
  return {
    hasStepPassed,
    hasStepFailed,
    isStepInProgress,
    getStepState,
  };
}

function hasStepPassed(runningState, profileState, gatewayLabel) {
  return _runningStateCheck(runningState) && _profileStateCheck(profileState, gatewayLabel);
}

function _runningStateCheck(runningState) {
  return _.includes([runningStates.FINISHED, runningStates.MANUALLY_VALIDATED], runningState);
}

function _profileStateCheck(profileState, stepLabel = '') {
  if (stepLabel.toUpperCase() === gatewayLabels.GBG) {
    return profileState === userStates.FULL;
  } 
  return _.includes([userStates.LIMITED, userStates.PENDING, userStates.FULL], profileState);
}

function isStepInProgress(runningState, profileState) {
  return (_runningStateCheck(runningState) && profileState === userStates.KYC_CHECK_IN_PROGRESS)
    || _.includes([runningStates.INITIALIZED, runningStates.EXECUTING, runningStates.PENDING_RESPONSE], runningState);
}

function hasStepFailed(runningState, profileState, gatewayLabel) {
  return !hasStepPassed(runningState, profileState, gatewayLabel) && !isStepInProgress(runningState, profileState);
}

function getStepState(runningState, profileState, gatewayLabel) {
  if (hasStepPassed(runningState, profileState, gatewayLabel)) {
    return profileStates.PASS;
  } if (isStepInProgress(runningState, profileState, gatewayLabel)) {
    return profileStates.PENDING;
  }
  return profileStates.FAIL;
}

export default KycValidationService;
