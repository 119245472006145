/**
 * SessionService
 * Service for keeping the session data
 */
export const session = { reset };

reset();

export function reset() {
  session.user = null;
  session.acl = [];
  session.endpoints = [];
  session.team = null;
  session.baseCurrency = null;
  session.whitelabel_segregation = [];
  session.whitelabels = [];
}

export default session;
