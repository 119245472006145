import React, { Component, Fragment } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Tabs, Tab } from 'tc-biq-design-system';
import { object, string } from 'prop-types';

import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { OnContentClickCell } from '../../../../../../components/gridCellRenderers';
import hideCols from '../../../../../../logic/utilities/hideCols';
import { hasAccess } from '../../../../../../logic/services/acl';
import TradePosition from '../../../../trade/TradePositionSidepanel';
import { withRouter } from '../../../../../../components/hoc/withRouter';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import connect from '../../../../../../logic/connect';
import overlayActions from '../../../../../../components/overlay';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import TradeSingle from '../../../../trade/Trades/sidepanels/TradeSingle';


const openPositionsConfig = id => ({
  reducerKey: 'USER_SINGLE',
  apiUrl: `users/${id}/open_positions`,
  tableKey: 'OPEN_POSITIONS',
});

const closedPositionsConfig = id => ({
  reducerKey: 'USER_SINGLE',
  apiUrl: `users/${id}/closed_positions`,
  tableKey: 'CLOSED_POSITIONS',
});

export const acls = {
  open: 'user.open_positions.list',
  closed: 'user.closed_positions.list',
};

const text = {
  OPEN_POSITIONS: gettext('OPEN POSITIONS'),
  CLOSED_POSITIONS: gettext('CLOSED POSITIONS'),
};

const propTypes = {
  id: string.isRequired,
  actions: object.isRequired,
};

const modifier = ({ onPreviewPosition }) => ({
  ...hideCols(['user']),
  id: {
    cellRendererFramework: OnContentClickCell(onPreviewPosition),
  },
});

const SIDEPANEL_ID = 'TRADE_POSITION';
const tradePositionApi = getDjangoApi('trades/positions');

class Positions extends Component {
  constructor(props) {
    super(props);

    this.OpenPositions = withTable(GridTab, openPositionsConfig, null, { hideSegments: true })(props.id);
    this.ClosedPositions = withTable(GridTab, closedPositionsConfig, null, { hideSegments: true })(props.id);

    this.tableActions = {
      onPreviewPosition: this.openPositionSidepanel.bind(this),
    };
  }

  async openPositionSidepanel(data) {
    const { actions } = this.props;
    const response = await tradePositionApi.retrieve(data.id);
    actions.openSidepanel(SIDEPANEL_ID, response.data);
  }

  render() {
    const { OpenPositions, ClosedPositions } = this;

    return (
      <Fragment>
        <Tabs>
          <Tab visible={hasAccess(acls.open)} title={text.OPEN_POSITIONS}>
            <OpenPositions modifier={modifier(this.tableActions)} />
          </Tab>

          <Tab visible={hasAccess(acls.closed)} title={text.CLOSED_POSITIONS}>
            <ClosedPositions modifier={modifier(this.tableActions)} />
          </Tab>
        </Tabs>
        <TradePosition sidepanelId={SIDEPANEL_ID} />
        <TradeSingle />
      </Fragment>
    );
  }
}

Positions.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withErrorBoundary,
)(Positions);
