/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { Sidepanel, Panel, Space, HyperLink, Tooltip } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { object, string } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import connect from '../../../../logic/connect';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import PanelTable from '../../../../components/PanelTable';
import overlayActions from '../../../../components/overlay';
import { gettext } from '../../../../logic/utilities/languageUtility';
import { OnContentClickCell, StateCell } from '../../../../components/gridCellRenderers';
import Extra from '../../../../components/Extra';
import UserInfo from '../../../../components/UserInfo';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import appRoutes from '../../../../components/App/Router/appRoutes';

const propTypes = {
  sidepanel: object.isRequired,
  actions: object.isRequired,
  sidepanelId: string.isRequired,
};

const tradesApi = getDjangoApi('trades');
const TRADE_SIDEPANEL_ID = 'TRADE_SINGLE';

const text = {
  TITLE: gettext('Position'),
  PANEL_TITLE: gettext('Position details'),
  INSTRUMENT: gettext('Instrument'),
  VOLUME: gettext('Volume'),
  OPEN_PRICE: gettext('Open price'),
  CLOSE_PRICE: gettext('Close price'),
  STOP_LOSS: gettext('Stop loss'),
  TAKE_PROFIT: gettext('Take profit'),
  PNL: gettext('P/L'),
  RATE: gettext('Rate'),
  SIDE: gettext('Side'),
  SSV: gettext('SSV'),
  OPEN_TIME: gettext('Open time'),
  CLOSE_TIME: gettext('Close time'),
  TRADE_ID: gettext('ID'),
  USER: gettext('User'),
  PRICE: gettext('Price'),
  POSITION_STATE: gettext('Position state'),
  CURRENCY: gettext('Currency'),
  DATE: gettext('Date'),
  TRADES_PANEL_TITLE: trade => gettext('Trades for position {{id}}', trade),
};

function formatDate(date) {
  const fullDate = moment(date).format('MMM Do YYYY, [at] HH:mm');
  return <Tooltip title={fullDate}>{moment(date).format('YYYY-MM-DD')}</Tooltip>;
}

const panelBodyDataFormatter = data => [
  { label: text.INSTRUMENT, value: data.instrument.symbol },
  { label: text.VOLUME, value: data.amount },
  { label: text.OPEN_PRICE, value: data.open_rate },
  { label: text.CLOSE_PRICE, value: data.close_rate, closedPosition: true },
  { label: text.SIDE, value: data.side },
  { label: text.STOP_LOSS, value: data.stop_loss },
  { label: text.TAKE_PROFIT, value: data.take_profit },
  { label: text.SSV, value: data.ssv },
  { label: text.PNL, value: `${data.currency.symbol} ${data.pnl}` },
  { label: `${text.PNL} ${data.currency.symbol}`, value: `${data.currency.symbol} ${data.pnl}` },
  { label: text.OPEN_TIME, value: moment.utc(data.open_date).format('MMM Do YYYY, [at] HH:mm') },
  { label: text.CLOSE_TIME, value: moment.utc(data.close_date).format('MMM Do YYYY, [at] HH:mm') },
];

const PanelBody = ({ tradeData, panelBodyDataFormatter }) => {// eslint-disable-line
  const bodyData = panelBodyDataFormatter(tradeData);
  return (
    <div className="card-panel-body">
      {bodyData
        .filter((line) => {
          if (tradeData.state === 'Open') return !(line.label === text.CLOSE_TIME || line.label === text.CLOSE_PRICE);
          return true;
        })
        .map((line, index) => (
          <div className="panel-body-line" key={line.value + index}>
            <span>{line.label}</span>
            <span>{line.value}</span>
          </div>
        ))}
    </div>
  );
};

PanelBody.propTypes = {
  tradeData: object.isRequired,
};

const tradesCols = ({ openTrade }) => [
  {
    title: text.TRADE_ID,
    key: 'id',
    // eslint-disable-next-line react/prop-types
    render: ({ id }) => OnContentClickCell(openTrade)({ data: { id }, value: id }),
  },
  {
    title: text.USER,
    key: 'user',
    render: ({ user }) => (user ? (
      <HyperLink>
        <Link to={generatePath(appRoutes.CLIENT_PROFILE, { id: user.id })}>{user.username}</Link>
      </HyperLink>
    ) : ''),
  },
  {
    title: text.INSTRUMENT,
    key: 'instrument',
    render: ({ instrument }) => (instrument ? instrument.symbol : ''),
  },
  {
    title: text.VOLUME,
    key: 'amount',
  },
  {
    title: text.SIDE,
    key: 'side',
  },
  {
    title: text.PRICE,
    key: 'rate',
  },
  {
    title: text.SSV,
    key: 'ssv',
  },
  {
    title: text.POSITION_STATE,
    key: 'position_state',
  },
  {
    title: text.CURRENCY,
    key: 'currency',
    render: ({ currency }) => (currency.symbol ? currency.symbol : ''),
  },
  {
    title: text.DATE,
    key: 'time',
    render: ({ time }) => (time ? formatDate(time) : ''),
  },
];

const initalState = {
  trades: null,
};

class TradePosition extends Component {
  constructor(props) {
    super(props);
    this.tradesApi = trade => getDjangoApi(`trades/positions/${trade.id}/trades/`);
    this.state = initalState;

    this.tableActions = {
      openTrade: this.openTrade,
    };
  }

  componentDidUpdate(prevProps) {
    const prevParams = _.get(prevProps, 'sidepanel.parameters');
    const params = _.get(this.props, 'sidepanel.parameters');
    if (params && prevParams !== params) {
      this.getTradePositions(params);
    }
  }

  getTradePositions = async (params) => {
    if (!params.id) return;
    const { data } = await this.tradesApi(params).retrieve(params.related_payment_transaction);
    this.setState({ trades: data.results });
  }

  openTrade = async ({ id }) => {
    const { actions, sidepanelId } = this.props;
    actions.closeSidepanel(sidepanelId);
    const response = await tradesApi.retrieve(id);
    actions.openSidepanel(TRADE_SIDEPANEL_ID, response.data);
  }

  render() {
    const { sidepanel, sidepanelId, actions } = this.props;
    const { trades } = this.state;
    if (!sidepanel.parameters) return null;
    const data = sidepanel.parameters;

    return (
      <Fragment>
        <Sidepanel
          size="large"
          visible={sidepanel.visible}
          title={`${text.TITLE} ${data.id}`}
          onCloseIconClick={() => actions.closeSidepanel(sidepanelId)}
          hideFooter
        >
          <UserInfo user={data.user} />
          <Panel
            title={text.PANEL_TITLE}
            status={() => <StateCell key={data.state} value={data.state} />}
          >
            <PanelBody tradeData={data} panelBodyDataFormatter={panelBodyDataFormatter} />
          </Panel>
          {trades && (
            <Fragment>
              <PanelTable
                title={text.TRADES_PANEL_TITLE(data)}
                cols={tradesCols(this.tableActions)}
                data={trades}
                xScroll
                noWrap
              />
            </Fragment>
          )}
          <Space size={16} />
          <Extra rawData={data.extra} />
        </Sidepanel>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ overlays }) => ({
  sidepanel: overlays.TRADE_POSITION,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

TradePosition.propTypes = propTypes;

export default withUnmountMethod(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorBoundary(TradePosition)),
);
