import React, { useMemo } from 'react';
import { Sidebar } from 'tc-biq-design-system';
import { func, bool, object } from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { gettext } from '../../logic/utilities/languageUtility';
import { LogoFull, LogoSmall } from './logos';
import NavigationService from '../../logic/services/nav';
import appRoutes from '../App/Router/appRoutes';

import './Nav.scss';

const propTypes = {
  collapsed: bool,
  onToggleCollapsed: func,
  session: object.isRequired,
};

const defaultProps = {
  collapsed: false,
  onToggleCollapsed: undefined,
};


const Nav = ({ collapsed, onToggleCollapsed, session }) => {
  const location = useLocation();
  const items = useMemo(() => NavigationService.getNavigationItems(session), [session.user]);
  const initialActive = useMemo(() => {
    let active = { item: appRoutes.DASHBOARD };
    items.forEach((item) => {
      if (location.pathname === item.route) {
        active = { item: item.label };
      }
      if (item.subItems && item.subItems.length) {
        item.subItems.forEach((subItem) => {
          if (location.pathname === subItem.route) {
            active = { item: item.label, subItem: subItem.label };
          }
        });
      }
    });

    return active;
  }, []);

  return (
    <div className="biq-nav">
      <Sidebar
        onToggleCollapsed={onToggleCollapsed}
        items={items}
        initialActive={initialActive}
        LogoFull={LogoFull}
        LogoSmall={LogoSmall}
        collapsed={collapsed}
        collapseSidebarText={gettext('Collapse Sidebar')}
      >
        {(NavItem, route, key) => (route ? (
          <Link to={route} key={key}><NavItem /></Link>
        ) : <NavItem key={key} />)}
      </Sidebar>
    </div>
  );
};

Nav.defaultProps = defaultProps;
Nav.propTypes = propTypes;

export default Nav;
