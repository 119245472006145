import { createAction, handleActions } from 'redux-actions';


const filterQueries = {
  call: 'communication_call',
  sms: 'notify_message:sms',
  email: 'notify_message:email',
  notes: 'notes_note',
  tasks: 'bo_tasks_task',
};

const initialState = {
  value: {
    call: false,
    sms: false,
    email: false,
    notes: false,
    tasks: false,
  },
  query: {
    object_type__in: '',
  },
};

const actionTypes = {
  SET_TYPE_FILTER: 'SET_ACTIVITY_STREAM_TYPE_FILTER',
  SET_TYPE_FILTER_ALL: 'SET_ACTIVITY_STREAM_TYPE_FILTER_ALL',
  RESET_TYPE_FILTER_ALL: 'RESET_ACTIVITY_STREAM_TYPE_FILTER',
};

const setTypeFilter = createAction(actionTypes.SET_TYPE_FILTER, (type, value) => ({ type, value }));
const setTypeFilterAll = createAction(actionTypes.SET_TYPE_FILTER_ALL);
const resetTypeFilter = createAction(actionTypes.RESET_TYPE_FILTER_ALL);


export const reducer = handleActions(
  {
    [actionTypes.SET_TYPE_FILTER]: (state, { payload }) => {
      const { type, value } = payload;
      return {
        value: {
          ...state.value,
          [type]: value,
        },
        query: { object_type__in: formatTypeQueries(state.value, { [type]: value }) },
      };
    },
    [actionTypes.SET_TYPE_FILTER_ALL]: (state) => {
      const updatedValues = selectAll(state.value);
      return {
        value: updatedValues,
        query: { object_type__in: formatTypeQueries(updatedValues) },
      };
    },
    [actionTypes.RESET_TYPE_FILTER_ALL]: () => ({ ...initialState }),
  },
  initialState,
);

export default {
  setTypeFilter,
  setTypeFilterAll,
  resetTypeFilter,
};

function selectAll(values) {
  const updateValues = {};
  Object.keys(values).forEach(key => updateValues[key] = true);
  return updateValues;
}

function formatTypeQueries(values, selectedValues) {
  const updatedQueries = selectedValues ? { ...values, ...selectedValues } : { ...values };
  return Object.keys(updatedQueries)
    .filter(key => updatedQueries[key])
    .map(key => filterQueries[key])
    .join(',');
}
