import React, { Component } from 'react';
import { compose } from 'redux';
import { string } from 'prop-types';

import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import { tableConfig } from './table';
import hideCols from '../../../../../../logic/utilities/hideCols';
import userTopActionFactory from '../../../../../../components/UserTopAction';

const modifer = {
  ...hideCols(['id', 'author_avatar', 'topic']),
  content: {
    // eslint-disable-next-line react/prop-types
    cellRendererFramework: ({ data }) => <div title={data.content}>{data.content}</div>,
    width: 350,
  },
  created: {
    width: 50,
  },
  author: {
    width: 50,
  },
};

const GridActions = userTopActionFactory('addNote');

const propTypes = {
  id: string.isRequired,
};

class Notes extends Component {
  constructor(props) {
    super(props);
    const { id } = props;

    this.Grid = withTable(GridTab, tableConfig, GridActions, { 
      withActions: true,
      hideSegments: true,
      hideFilters: true,
    })(id);
  }

  render() {
    const [Table] = this.Grid;
    return (
      <Table autoHeight singleActions disableQuickFilter modifier={modifer} />
    );
  }
}

Notes.propTypes = propTypes;

export default compose(
  withErrorBoundary,
)(Notes);
