/* eslint react/prop-types: 0 */
import React from 'react';
import { Col, Row, Block } from 'tc-biq-design-system';
import { Link } from 'react-router-dom';

import Page from '../../../../../components/Page';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../logic/services/acl';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const text = {
  PAGE_TITLE: gettext('Customization'),
  CLIENT_PROFILE_LAYOUTS: gettext('Client profile layouts'),
  DASHBOARDS: gettext('Dashboards'),
};

const bread = [{ label: text.PAGE_TITLE, route: appRoutes.SETTINGS_CUSTOMIZATION }];

const Customization = () => (
  <Page bread={bread} title={text.PAGE_TITLE}>
    <Row>
      <Col sm="1/3">
        {hasAccess('layout.dashboard.list') && (
          <Link to={appRoutes.SETTINGS_CUSTOMIZATION_DASHBOARDS}>
            <Block
              fallbackLogo="icon"
              fallbackIcon="Dashboard"
              title={text.DASHBOARDS}
            />
          </Link>
        )}
      </Col>
      <Col sm="1/3">
        {hasAccess('layout.profile.list') && (
          <Link to={appRoutes.SETTINGS_CUSTOMIZATION_PROFILES}>
            <Block
              fallbackLogo="icon"
              fallbackIcon="User"
              title={text.CLIENT_PROFILE_LAYOUTS}
            />
          </Link>
        )}
      </Col>
    </Row>
  </Page>
);

export default withErrorBoundary(Customization);
