import { bindActionCreators } from 'redux';

import SalesStatusesHeader from './SalesStatusesHeader';
import { actions } from '../../Model';
import connect from '../../../../../../../logic/connect';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...actions,
  }, dispatch),
});

export default connect(null, mapDispatchToProps)(SalesStatusesHeader);
