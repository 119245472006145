import { gettext } from '../../../../logic/utilities/languageUtility';

export const text = {
  CONFIRM: gettext('Confirm'),
  CANCEL: gettext('Cancel'),
  DEFAULT_TITLE: gettext('Confirm action'),
  DEFAULT_MESSAGE: gettext('Please, press Confirm button if you agree'),
};


