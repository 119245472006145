import React, { PureComponent } from 'react';
import { Tooltip, Popconfirm, Icon } from 'tc-biq-design-system';
import { func } from 'prop-types';

import { gettext } from '../../logic/utilities/languageUtility';

const propTypes = {
  onRemoveClick: func.isRequired,
};

const text = {
  REMOVE_SAVED_VIEW: gettext('Delete saved view'),
  REMOVE_QUESTION: gettext('Are you sure you want to delete selected view?'),
  REMOVE: gettext('Remove'),
  CANCEL: gettext('Cancel'),
};

class RemoveSavedFilters extends PureComponent {
  render() {
    const { onRemoveClick } = this.props;
    return (
      <Tooltip key="remove" title={text.REMOVE_SAVED_VIEW}>
        <Popconfirm
          type="destructive"
          icon="Delete"
          label={text.REMOVE_QUESTION}
          onConfirm={onRemoveClick}
          buttonLabels={{
            cancel: text.CANCEL,
            confirm: text.REMOVE,
          }}
        >
          <Icon name="Delete" />
        </Popconfirm>
      </Tooltip>
    );
  }
}

RemoveSavedFilters.propTypes = propTypes;

export default RemoveSavedFilters;
