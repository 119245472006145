import React, { Component } from 'react';
import { Button, Tabs, Tab } from 'tc-biq-design-system';
import { object } from 'prop-types';

import Page from '../../../../components/Page';
import { hasAccess } from '../../../../logic/services/acl';
import { gettext } from '../../../../logic/utilities/languageUtility';
import TemplatePreview from './TemplatePreview';
import AlertSettings from './AlertSettings';
import { SIDEPANEL_ID } from './AlertSettings/EmailAlertsSidepanel';
import appRoutes from '../../../../components/App/Router/appRoutes';

const tabs = {
  TEMPLATE_PREVIEW: 'template-preview',
  ALERT_SETTINGS: 'alert-settings',
};

const text = {
  TITLE: gettext('Email alert settings'),
  CREATE_EMAIL: gettext('Create email'),
  TAB_TITLE: {
    [tabs.TEMPLATE_PREVIEW]: gettext('Template Preview'),
    [tabs.ALERT_SETTINGS]: gettext('Alert settings'),
  },
};

const bread = [{ label: text.TITLE, route: appRoutes.SETTINGS_EMAIL_ALERTS }];

const propTypes = {
  actions: object.isRequired,
};

class EmailAlerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: tabs.TEMPLATE_PREVIEW,
    };
  }

  setActiveTab = (active) => {
    this.setState({ active });
  }

  headerActions = () => {
    const { active } = this.state;
    const { actions } = this.props;
    return ({
      [tabs.ALERT_SETTINGS]: hasAccess('settings.bo_notifications.create') && (
        <Button onClick={() => actions.openSidepanel(SIDEPANEL_ID)}>
          {text.CREATE_EMAIL}
        </Button>
      ),
    })[active];
  }

  render() {
    return (
      <Page 
        title={text.TITLE} 
        bread={bread} 
        headerActions={this.headerActions}
      >
        <Tabs>
          <Tab
            visible 
            title={text.TAB_TITLE[tabs.TEMPLATE_PREVIEW]} 
            onLoad={() => this.setActiveTab(tabs.TEMPLATE_PREVIEW)}
          >
            <TemplatePreview />
          </Tab>
          <Tab
            visible={hasAccess('settings.bo_notifications.list')} 
            title={text.TAB_TITLE[tabs.ALERT_SETTINGS]} 
            onLoad={() => this.setActiveTab(tabs.ALERT_SETTINGS)}
          >
            <AlertSettings />
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

EmailAlerts.propTypes = propTypes;

export default EmailAlerts;
