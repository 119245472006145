/* eslint react/prop-types: 0 */
import React, { Fragment, Component } from 'react';
import { Dropdown, withSidepanelManager, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import UserActions, { userActionTypes } from '../../UserActions';
import { withRouter } from '../../../../../../components/hoc/withRouter';
import connect from '../../../../../../logic/connect';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { TradingAccount } from '../../../../../../components/common/sidepanels';
import { tableConfig, TABLE_KEY } from './table';
import modifier from './modifier';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';

import overlayActions from '../../../../../../components/overlay';

const SIDEPANEL_ID = 'TRADING_ACCOUNT';

const mapActionAttributes = actions => actions.map(({ label, icon, handler }) => ({
  label, icon, onClick: handler,
}));

const text = {
  ACTIONS: gettext('Actions'),
  RETRIEVING_TA: gettext('Retrieving trading account Collateral data is in progress.'),
};

const Actions = props => (
  <UserActions type={userActionTypes.TRADING_ACCOUNTS} DI={{ ...props }}>
    {actions => !_.isEmpty(actions) && <Dropdown openLeft title={text.ACTIONS} list={mapActionAttributes(actions)} />}
  </UserActions>
);

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const table = page.tables[TABLE_KEY];
  return {
    user: page.userDetail.user,
    table,
  };
};

const GridActions = withRouter(
  withSidepanelManager(
    connect(mapStateToProps)(Actions),
  ),
);
class TradingAccounts extends Component {
  constructor(props) {
    super(props);

    this.showNotifications = isFeatureEnabled()('TA_NOTIFICATIONS');
    this.notificationDisplayed = false;
    this.generateGrid();
  }

  componentDidMount() {
    if (this.showNotifications) {
      notifier.warning(text.RETRIEVING_TA);
    }
  }

  generateGrid = () => {
    this.Grid = withTable(GridTab, tableConfig, 
      GridActions, { hideSegments: false, hideFilters: true })(this.props.id);
  }

  componentDidUpdate(prevProps) {
    const prevData = _.get(prevProps, 'table.data');
    const data = _.get(this.props, 'table.data');
    const prevUserId = _.get(prevProps, 'id');
    const userId = _.get(this.props, 'id');

    if (!_.isEmpty(data) && data !== prevData) {
      this.displayNotifications(data);
    }

    if (prevUserId !== userId) this.generateGrid();
  }

  openTradingAccountSidepanel = async ({ id, user }) => {
    const { actions } = this.props;
    const response = await getDjangoApi(`users/${user.id}/trading_accounts/${id}`).list();
    actions.openSidepanel(SIDEPANEL_ID, response.data);
  }

  displayNotifications = (tradingAccounts = []) => {
    if (!this.showNotifications || this.notificationDisplayed) return;
    this.notificationDisplayed = true;
    const msg = tradingAccounts.map((ta) => {
      const { balance = {} } = ta;
      const isBalanceFailed = !Object.values(balance).some(val => val !== 'n/a');
      if (isBalanceFailed && ta.status === 'active') {
        return (
          <li key={ta.id}>{gettext('{{externalId}}, {{name}}', {
            externalId: ta.external_id || '', 
            name: ta.backend.name,
          })}
          </li>
        );
      }
        
      return null;
    }).filter(v => v);

    if (msg && msg.length > 0) {
      notifier.success(
        <div>
          <span>{gettext('Collateral data could not be retrieved due to timeout on trading backend for account(s):')}</span>
          {msg}
        </div>,
        { pauseOnHover: true },
      );
    }
  }

  render() {
    const { Grid } = this;
    return (
      <Fragment>
        <Grid
          disableQuickFilter
          modifier={modifier({ openTradingAccount: this.openTradingAccountSidepanel })}
        />
        <TradingAccount 
          hideUserInfo
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TradingAccounts));
