import { combineReducers } from 'redux';

import GridReducerFactory from '../../../../components/grid/GridReducerFactory';
import userDetail from './Model';
import signupDetails from './Sections/SignupDetails/Model';
import onfidoReports from './Sections/OnfidoReports/Model';
import termsAndConditions from './Sections/TermsAndConditions/Model';
import protrader from './Sections/Protrader/Model';
import uploadDocumentReducer from './Sections/Documents/Overlays/UploadDocument/Model';
import KYC from './Sections/KYC/Model';
import tradingStats from './Sections/TradingStats/Model';
import paymentBreakdown from './Sections/PaymentBreakdown/Model';
import marketing from './Sections/Marketing/Model';
import campaign from './Sections/Campaign/Model';
import MIFID from './Sections/MIFID/Model';
import InstrumentStats from './Sections/InstrumentStats/Model';
import CommunicationFlow from './UserActions/actions/CommunicationFlow/Model';
import monitoring from './Sections/Monitoring/Model';
import bulkActionsReducerFactory from '../../../../components/common/sidepanels/bulk/reducers';
import ADD_USER_TO_EXCLUSION from './UserActions/actions/AddToExclusionList/Model';
import ADD_USER_TO_BLACKLIST from './UserActions/actions/AddToBlacklist/Model';
import MASK_PERSONAL_DETAILS from './UserActions/actions/MaskPersonalDetails/Model';
import ARCHIVE_USER from './UserActions/actions/Archive/Model';
import UNARCHIVE_USER from './UserActions/actions/Unarchive/Model';
import APPROVE_USER from './UserActions/actions/ApproveTo/Model';
import ASSIGN_TO_IB from './UserActions/actions/AssignToIb/Model';
import INTERNAL_TRANSFER from './UserActions/actions/InternalTransfer/Model';
import CHANGE_TA_PASSWORD from './UserActions/actions/ChangeTaPassword/Model';
import LOGIN_AS_USER from './UserActions/actions/LoginAsUser/Model';
import CREDIT from './UserActions/actions/Credit/Model';
import TRADING_BACKENDS from './UserActions/actions/IbChangeBackends/Model';
import MOVE_TO_PENDING from './UserActions/actions/MoveToPending/Model';
import SEND_EMAIL from './UserActions/actions/SendEmail/Model';
import PUSH_VERIFICATION from './UserActions/actions/PushVerification/Model';
import UNLOCK_LOGIN from './UserActions/actions/UnlockLogin/Model';
import EDIT_MIFID from './UserActions/actions/EditMifid/Model';
import TRADING_ACCOUNT from './UserActions/TradingAccountForm/Model';

const rootReducer = combineReducers({
  userDetail,
  signupDetails,
  onfidoReports,
  termsAndConditions,
  protrader,
  uploadDocumentReducer,
  KYC,
  tradingStats,
  paymentBreakdown,
  marketing,
  campaign,
  MIFID,
  InstrumentStats,
  CommunicationFlow,
  monitoring,
  actions: combineReducers({
    EDIT_SALES_STATUSES: bulkActionsReducerFactory('CLIENT_EDIT_SALES_STATUSES'),
    EDIT_COMPLIANCE_STATUSES: bulkActionsReducerFactory('CLIENT_EDIT_COMPLIANCE_STATUSES'),
    EDIT_SALES_SUBSTATUSES: bulkActionsReducerFactory('CLIENT_EDIT_SALES_SUBSTATUSES'),
    ASSIGN_SALES_MANAGER: bulkActionsReducerFactory('CLIENT_ASSIGN_SALES_MANAGER'),
    ASSIGN_RETENTION_MANAGER: bulkActionsReducerFactory('CLIENT_ASSIGN_RETENTION_MANAGER'),
    ADD_USER_TO_EXCLUSION,
    ADD_USER_TO_BLACKLIST,
    ARCHIVE_USER,
    UNARCHIVE_USER,
    APPROVE_USER,
    TRADING_BACKENDS,
    ASSIGN_TO_IB,
    INTERNAL_TRANSFER,
    CREDIT,
    LOGIN_AS_USER,
    CHANGE_TA_PASSWORD,
    MOVE_TO_PENDING,
    SEND_EMAIL,
    PUSH_VERIFICATION,
    UNLOCK_LOGIN,
    EDIT_MIFID,
    TRADING_ACCOUNT,
    MASK_PERSONAL_DETAILS,
  }),
  tables: combineReducers({
    CLIENT_TRADING_ACCOUNTS_TABLE: GridReducerFactory('CLIENT_TRADING_ACCOUNTS_TABLE'),
    PAYMENT_TRANSACTIONS: GridReducerFactory('PAYMENT_TRANSACTIONS'),
    INTERNAL_TRANSACTIONS: GridReducerFactory('INTERNAL_TRANSACTIONS'),
    INSURANCE_TRANSACTIONS: GridReducerFactory('INSURANCE_TRANSACTIONS'),
    INSURANCE_TRANSACTIONS_IB: GridReducerFactory('INSURANCE_TRANSACTIONS_IB'),
    BONUS_TRANSACTIONS: GridReducerFactory('BONUS_TRANSACTIONS'),
    OPEN_POSITIONS: GridReducerFactory('OPEN_POSITIONS'),
    CLOSED_POSITIONS: GridReducerFactory('CLOSED_POSITIONS'),
    BANK_ACCOUNTS_TABLE: GridReducerFactory('BANK_ACCOUNTS_TABLE'),
    USER_TASKS_TABLE: GridReducerFactory('USER_TASKS_TABLE'),
    POSSIBLE_DUPLICATES: GridReducerFactory('POSSIBLE_DUPLICATES'),
    INSTRUMENT_STATS: GridReducerFactory('INSTRUMENT_STATS'),
    USER_DOCUMENTS: GridReducerFactory('USER_DOCUMENTS'),
    PENDING_WITHDRAWALS: GridReducerFactory('PENDING_WITHDRAWALS'),
    WITHDRAWAL_HISTORY: GridReducerFactory('WITHDRAWAL_HISTORY'),
    COMMUNICATION_MESSAGES: GridReducerFactory('COMMUNICATION_MESSAGES'),
    COMMUNICATION_CALLS: GridReducerFactory('COMMUNICATION_CALLS'),
    COMMUNICATION_SMS: GridReducerFactory('COMMUNICATION_SMS'),
    COMMUNICATION_CHAT: GridReducerFactory('COMMUNICATION_CHAT'),
    PAYMENT_DETAILS_CARDS: GridReducerFactory('PAYMENT_DETAILS_CARDS'),
    PAYMENT_DETAILS_BANK_ACCOUNTS: GridReducerFactory('PAYMENT_DETAILS_BANK_ACCOUNTS'),
    PAYMENT_DETAILS_EWALLET: GridReducerFactory('PAYMENT_DETAILS_EWALLET'),
    IB_TRACKING_LINKS: GridReducerFactory('IB_TRACKING_LINKS'),
    IB_CLIENTS: GridReducerFactory('IB_CLIENTS'),
    IB_SUB_CLIENTS: GridReducerFactory('IB_SUB_CLIENTS'),
    IB_SUB_IBS: GridReducerFactory('IB_SUB_IBS'),
    IB_PAYMENT_TRANSACTIONS: GridReducerFactory('IB_PAYMENT_TRANSACTIONS'),
    IB_INTERNAL_TRANSACTIONS: GridReducerFactory('IB_INTERNAL_TRANSACTIONS'),
    IB_COMMUNICATION: GridReducerFactory('IB_COMMUNICATION'),
    USER_NOTES_TABLE: GridReducerFactory('USER_NOTES_TABLE'),
  }),
});

export default rootReducer;
