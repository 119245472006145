import React, { Component } from 'react';
import { array, func, string, any } from 'prop-types';

import { Button, Input, Tabs, Tab, Space, Checkbox } from 'tc-biq-design-system';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';

import './CampaignConditions.scss';
 
const text = {
  REMOVE_CONDITION: gettext('Remove condition'),
  FROM_AMOUNT: gettext('From amount'),
  TO_AMOUNT: gettext('To amount'),
  PRICE_RANGE: gettext('Price range'),
  PERCENTAGE: gettext('Percentage'),
  PERCENTAGE_PLACEHOLDER: gettext('Amount percentage %'),
  FIXED_AMOUNT: gettext('Fixed amount'),
  BONUS_LIMIT: gettext('Bonus limit'),
  BONUS_LIMIT_PLACEHOLDER: gettext('Bonus limit amount'),
  BONUS_AMOUNT: gettext('Bonus amount'),
  IS_MULTIPLE: gettext('Multiple'),
  TOTAL_LIMIT: gettext('Total limit'),
  CONDITION_TITLE: i => gettext('Condition {{i}}', { i }),
};

const fields = {
  PERCENTAGE: 'value',
  BONUS_LIMIT: 'max_percentage_value',
  BONUS_AMOUNT: 'value',
  FROM: 'min_amount',
  TO: 'max_amount',
  IS_PERCENTAGE: 'is_percentage',
  IS_MULTIPLE: 'is_multiple',
  MAX_TOTAL_VALUE: 'max_total_value',
};

const universalPropTypes = {
  onChange: func.isRequired,
  value: any.isRequired,
  error: string.isRequired,
};

const From = ({ onChange, value, error }) => (
  <Input
    type="number"
    placeholder={text.FROM_AMOUNT}
    onChange={e => onChange(fields.FROM)(e.target.value)}
    value={value}
    helpText={error}
    hasError={!!error}
  />
);

From.propTypes = universalPropTypes;

const To = ({ onChange, value, error }) => (
  <Input
    type="number"
    placeholder={text.TO_AMOUNT}
    onChange={e => onChange(fields.TO)(e.target.value)}
    value={value}
    helpText={error}
    hasError={!!error}
  />
);

To.propTypes = universalPropTypes;

const Percentage = ({ onChange, value, error }) => (
  <Input
    type="number"
    label={text.PERCENTAGE}
    placeholder={text.PERCENTAGE_PLACEHOLDER}
    onChange={e => onChange(fields.PERCENTAGE)(e.target.value)}
    value={value}
    helpText={error}
    hasError={!!error}
  />
);

Percentage.propTypes = universalPropTypes;

const BonusLimit = ({ onChange, value, error }) => (
  <Input
    type="number"
    label={text.BONUS_LIMIT}
    placeholder={text.BONUS_LIMIT_PLACEHOLDER}
    onChange={e => onChange(fields.BONUS_LIMIT)(e.target.value)}
    value={value}
    helpText={error}
    hasError={!!error}
  />
);

BonusLimit.propTypes = universalPropTypes;

const BonusAmount = ({ onChange, value, error }) => (
  <Input
    type="number"
    label={text.BONUS_AMOUNT}
    placeholder={text.BONUS_AMOUNT}
    onChange={e => onChange(fields.BONUS_AMOUNT)(e.target.value)}
    value={value}
    helpText={error}
    hasError={!!error}
  />
);
BonusAmount.propTypes = universalPropTypes;

const IsMultiple = ({ onChange, value, error }) => (
  <Checkbox
    name={text.IS_MULTIPLE}
    onChange={e => onChange(fields.IS_MULTIPLE)(e.target.checked)}
    checked={value}
    helpText={error}
    hasError={!!error}
  >{text.IS_MULTIPLE}</Checkbox>
);

IsMultiple.propTypes = universalPropTypes;

const TotalLimit = ({ onChange, value, error }) => (
  <Input
    type="number"
    label={text.TOTAL_LIMIT}
    placeholder={text.TOTAL_LIMIT}
    onChange={e => onChange(fields.MAX_TOTAL_VALUE)(e.target.value)}
    value={value}
    helpText={error}
    hasError={!!error}
  />
);
TotalLimit.propTypes = universalPropTypes;

const propTypes = {
  value: array,
  errors: array,
  onChange: func.isRequired,
};

const defaultProps = {
  value: null,
  errors: null,
};

class CampaignConditions extends Component {
  onChange = i => field => (val) => {
    const { value, onChange } = this.props;
    const newValue = value.map((condition, index) => ({ ...condition, ...(i === index ? { [field]: val } : {}) }));
    onChange(newValue);
  }

  onRemoveCondition = i => () => {
    const { value, onChange } = this.props;
    const newValue = value.reduce((acc, condition, index) => ([...acc, ...(index !== i ? [condition] : [])]), []);
    onChange(newValue);
  }

  getError = (index, name) => {
    const { errors } = this.props;
    return errors 
      && errors[index] 
      && errors[index][name] 
      && errors[index][name][0];
  }

  render() {
    const { value } = this.props;

    return (
      <div className="campaign-conditions">
        {(value || []).map((condition, index) => (
          <div key={index} className="campaign-conditions__box">
            <div className="campaign-conditions__row">
              <span className="campaign-conditions__title tc-heading-s">{text.CONDITION_TITLE(index + 1)}</span>
              <Button onClick={this.onRemoveCondition(index)} size="small" icon="Delete" color="ghost">{text.REMOVE_CONDITION}</Button>
            </div>
            <Space size={10} />
            <label htmlFor="campaign-conditions-form">{text.PRICE_RANGE}</label>
            <div id="campaign-conditions-form" className="campaign-conditions__row">
              <From error={this.getError(index, 'min_amount')} value={condition.min_amount} onChange={this.onChange(index)} />
              <div className="campaign-conditions__separator" />
              <To error={this.getError(index, 'max_amount')} value={condition.max_amount} onChange={this.onChange(index)} />
              <div className="campaign-conditions__separator" />
              <IsMultiple error={this.getError(index, 'is_multiple')} value={condition.is_multiple} onChange={this.onChange(index)}/>
            </div>
            <Space size={10} />
            <Tabs active={0}>
              <Tab
                visible={condition.is_percentage}
                title={text.PERCENTAGE}
              >
                <Space size={10} />
                <div className="campaign-conditions__row">
                  <Percentage error={this.getError(index, 'value')} value={condition.value} onChange={this.onChange(index)} />
                  <Space size={20} />
                  <BonusLimit error={this.getError(index, 'max_percentage_value')} value={condition.max_percentage_value} onChange={this.onChange(index)} />
                  <Space size={20} />
                  <TotalLimit error={this.getError(index, 'max_total_value')} value={condition.max_total_value} onChange={this.onChange(index)}/>
                </div>
              </Tab>
              <Tab
                visible={!condition.is_percentage}
                title={text.FIXED_AMOUNT}
              >
                <Space size={10} />
                <div className="campaign-conditions__row">
                  <BonusAmount error={this.getError(index, 'value')} value={condition.value} onChange={this.onChange(index)} />
                  <Space size={20} />
                  <TotalLimit error={this.getError(index, 'max_total_value')} value={condition.max_total_value} onChange={this.onChange(index)}/>
                </div>
              </Tab>
            </Tabs>
          </div>
        ))}
      </div>
    );
  }
}

CampaignConditions.propTypes = propTypes;
CampaignConditions.defaultProps = defaultProps;

export default CampaignConditions;
