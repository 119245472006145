import React from 'react';
import { Tooltip, HyperLink } from 'tc-biq-design-system';
import { any, string, shape } from 'prop-types';

import './Info.scss';

// eslint-disable-next-line react/prop-types
const InfoContent = ({ title, desc, link: { text, url } }) => (
  <div className="biq-info__content">
    <span className="tc-paragraph-regular">{ title }</span>
    <br />
    <span className="tc-paragraph-strong">{ desc }</span>
    <br />
    <HyperLink>
      <a className="biq-info__link" href={url}>{text}</a>
    </HyperLink>
  </div>
);

const linkShape = {
  text: string,
  url: string,
};

const propTypes = {
  children: any.isRequired,
  title: string.isRequired, 
  desc: string.isRequired, 
  link: shape(linkShape).isRequired,
};

const Info = ({ children, ...rest }) => (
  <Tooltip 
    content={<InfoContent {...rest} />}
    tooltipOptions={{ interactive: true }}
    placement="bottom"
  >
    {children}
  </Tooltip>
);

Info.propTypes = propTypes;

export default Info;
