import React, { Component, Fragment } from 'react';
import { Table, Space, Button, Tooltip, Spinner, HyperLink } from 'tc-biq-design-system';
import { object, string, array, bool } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import * as modifiers from './modifiers';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import MoveToTeam, { SIDEPANEL_ID } from './sidepanels/MoveToTeam';
import DeleteMember, { SIDEPANEL_ID as DELETE_SIDEPANEL_ID } from './sidepanels/DeleteMember';
import appRoutes from '../../../../../components/App/Router/appRoutes';

import './TeamTable.scss';

const text = {
  MOVE_MEMBERS: gettext('Move team members'),
  BULK_INFO: gettext('Select more than one team member to enable bulk editing option.'),
  MEMBER: gettext('Member'),
  USERNAME: gettext('Username'),
  EMAIL: gettext('Email'),
  ACCESS_RIGHTS: gettext('Edit access rights'),
  CLIENT_VISIBILITY: gettext('Edit client visibility'),
  DELETE: gettext('Delete'),
  NO_ROWS: gettext('No rows to show'),
};

const propTypes = {
  actions: object.isRequired,
  teamId: string.isRequired,
  members: array.isRequired,
  isLoading: bool.isRequired,
};

class TeamTable extends Component {
  UNSAFE_componentWillMount() {
    this.loadTeam();
  }

  onChange = (checked, member) => {
    const { members, actions } = this.props;
    const newMembers = members.map((m) => {
      if (m.id === member.id) return { ...m, checked };
      return { ...m };
    });

    actions.setMembers(newMembers);
  }

  onClick = () => {
    const { actions } = this.props;
    actions.openSidepanel(SIDEPANEL_ID);
  }

  onDelete = (member) => {
    const { actions } = this.props;
    actions.openSidepanel(DELETE_SIDEPANEL_ID, { member });
  }

  loadTeam = () => {
    const { actions, teamId } = this.props;
    actions.loadTeam(teamId);
  }

  renderActions = data => (
    <div className="team-table__actions">
      <div className="team-table__action">
        <Tooltip title={text.ACCESS_RIGHTS}>
          {modifiers.routeRender('Edit', generatePath(appRoutes.TEAM_MEMBER, {
            id: data.id,
            section: 'access-rights',
          }))}
        </Tooltip>
      </div>
      <div className="team-table__action">
        <Tooltip title={text.CLIENT_VISIBILITY}>
          {modifiers.routeRender('Edit', generatePath(appRoutes.TEAM_MEMBER, {
            id: data.id,
            section: 'client-visibility',
          }))}
        </Tooltip>
      </div>
      <div className="team-table__action">
        <Tooltip title={text.DELETE}>
          {modifiers.iconRender('Delete', this.onDelete)(data)}
        </Tooltip>
      </div>
    </div>
  )

  render() {
    const { members, isLoading } = this.props;
    const isSelected = members.some(member => member.checked);
    const displayTable = !isLoading && !!members.length;
    const displayNoRow = !isLoading && !members.length;
    const displayBulk = !isLoading && displayTable && isSelected;
    const displayBulkInfo = !isLoading && displayTable && !isSelected;
    const displayLoading = isLoading;
    return (
      <div className="team-table">
        <Space size={12} />
        {displayBulk && (
          <Button onClick={this.onClick} icon="Edit" color="transparent">{text.MOVE_MEMBERS}</Button>
        )}
        {displayBulkInfo && (
          <Fragment>
            <Space size={10} />
            <p className="tc-paragraph-regular">{text.BULK_INFO}</p>
          </Fragment>
        )}
        {displayTable && (
          <Table
            cols={[
              { title: '', key: 'bulk', render: modifiers.bulkRender(this.onChange) },
              {
                title: text.MEMBER,
                key: 'full_name',
                render: data => (
                  <HyperLink>
                    <Link
                      to={generatePath(appRoutes.TEAM_MEMBER, { id: data.id })}
                    >{data.full_name}
                    </Link>
                  </HyperLink>
                ),
              },
              { title: text.USERNAME, key: 'username' },
              { title: text.EMAIL, key: 'email' },
              { title: '', width: '100px', key: 'actions', render: this.renderActions },
            ]} 
            data={members || []}
          />
        )}
        {displayNoRow && (
          <div className="tc-paragraph-regular team-table__center">{text.NO_ROWS}</div>
        )}
        {displayLoading && (
          <div className="team-table__loader">
            <Spinner />
          </div>
        )}
        <MoveToTeam onSuccess={this.loadTeam} />
        <DeleteMember onSuccess={this.loadTeam} />
      </div>
    );
  }
}

TeamTable.propTypes = propTypes;

export default TeamTable;
