import _ from 'lodash';
import { gettext } from '../../../logic/utilities/languageUtility';
import { getConvertedAmountLimits } from '../../../pages/home/withdrawal-single/Components/ExecuteWithdrawal/logic/utils';

const text = {
  IS_REQUIRED: gettext('This field is required'),
  IS_GREATER_THEN: comparator => gettext('This field shold be more than {{comparator}}', { comparator }),
  CONVERTED_RATE: percentage => gettext('Entered amount is outside of the interval defined by maximum allowed exchange rate variation (+/-{{percentage}}%)', { percentage }),
  IS_POSITIVE: gettext('This field should be a positive number'),
};

const isGreatherThen = (value, comparator) => {
  if (value === undefined || value === null) return false;
        
  const regex = /^\d*(\.\d+)?$/;

  if (!(String(value)).match(regex)) return false;

  const floatValue = Number.parseFloat(value);

  return floatValue > comparator;
};

const isPositive = value => isGreatherThen(value, -1);

const isRequired = value => !_.isEmpty(value) && !_.isNil(value);

const isConvertedAmountCorrect = (value, { original_converted_amount } = {}, percentage) => {
  if (_.isNil(value)) return true;
  if (_.isNil(original_converted_amount)) return true;
  const amount = Number.parseFloat(value);
  const { min, max } = getConvertedAmountLimits(original_converted_amount, percentage);
  
  return amount >= min && amount <= max;
};


const isRequiredValidator = () => ({
  message: text.IS_REQUIRED,
  exec: value => isRequired(value),
});

const isGreaterThenValidator = (comparator = 0) => ({
  message: text.IS_GREATER_THEN(comparator),
  exec: value => isGreatherThen(value, comparator),
});

const isPositiveValidator = () => ({
  message: text.IS_POSITIVE,
  exec: value => isPositive(value),
});

const convertedRateValidator = (percentage = 3) => ({
  message: text.CONVERTED_RATE(percentage),
  exec: (value, values) => isConvertedAmountCorrect(value, values, percentage),
});


export {
  isRequiredValidator,
  isGreaterThenValidator,
  convertedRateValidator,
  isPositiveValidator,
};
