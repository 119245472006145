import { UsernameCell, TeamCell } from '../../../../components/gridCellRenderers';

export const sharedModifier = {
  team: { cellRendererFramework: TeamCell },
};

export default ({ previewBiqUser }) => ({
  ...sharedModifier,
  team_member: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
});
