import { bindActionCreators } from 'redux';

import BalanceForm, { FORM_ID } from './BalanceForm';
import { FormActionsFactory } from '../../../../../../../components/form';
import connect from '../../../../../../../logic/connect';

const { create, setFieldValue } = FormActionsFactory(FORM_ID);

const mapStateToProps = ({ pages, forms }) => {
  const page = pages.USER_SINGLE;
  const { submitInProgress } = forms[FORM_ID];
  return {
    user: page.userDetail.user,
    submitInProgress,
  };
};

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      create,
      setFieldValue,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BalanceForm);
