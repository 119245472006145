import { bindActionCreators } from 'redux';

import EditStatuses from '../../../../../../components/common/sidepanels/bulk/EditStatuses';
import connect from '../../../../../../logic/connect';
import overlayActions from '../../../../../../components/overlay';
import bulkActionsActionFactory from '../../../../../../components/common/sidepanels/bulk/actions';
import { sidepanelName, text, autocompleteApi, bulkApi, getPayload, getError } from '../../../../clients/sidepanels/ClientsEditComplianceStatuses/complianceStatusProps';

const { 
  updateData, 
  resetInput, 
  submit, 
} = bulkActionsActionFactory('IB_EDIT_COMPLIANCE_STATUSES');

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateData,
      resetInput,
      submit,
      closeSidepanel: overlayActions.close,
    },
    dispatch,
  ),
});
  
const mapStateToProps = ({ overlays, pages }) => {
  const editSalesStatuses = pages.IB_LIST.bulkActions.EDIT_COMPLIANCE_STATUSES;
  
  return {
    sidepanelName,
    text,
    autocompleteApi,
    bulkApi,
    getPayload,
    getError,
    sidepanel: overlays.EDIT_COMPLIANCE_STATUSES,
    fieldData: editSalesStatuses.fieldData,
    submitInProgress: editSalesStatuses.submitInProgress,
    submitSuccess: editSalesStatuses.submitSuccess,
    errors: editSalesStatuses.errors,
  };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditStatuses);
