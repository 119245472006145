import React from 'react';
import { bindActionCreators } from 'redux';
import { object } from 'prop-types';

import CreateTaskForm from '../../../../../../../../components/common/CreateTaskForm/CreateTaskForm';
import { FormActionsFactory } from '../../../../../../../../components/form';
import connect from '../../../../../../../../logic/connect';


const FORM_KEY = 'CREATE_TASK_COMMUNICATION_FLOW_FORM';

const { create, resetFields } = FormActionsFactory(FORM_KEY);

const propTypes = {
  actions: object.isRequired,
};

const CreateTask = ({ actions, ...rest }) => <CreateTaskForm disableResetOnUnmount shouldResetValuesOnSuccess={false} {...rest} createTask={actions.create} formKey={FORM_KEY} />;

CreateTask.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      create,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages, forms }) => {
  const form = forms[FORM_KEY];
  const page = pages.USER_SINGLE;
  return {
    submitInProgress: form.submitInProgress,
    user: page.userDetail.user,
  };
};

export { resetFields as resetTaskForm };
export default connect(mapStateToProps, mapDispatchToProps)(CreateTask);
