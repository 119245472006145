import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';
import ChangeUserState from './ChangeUserState';
import userActionTypes from '../types';

const text = {
  TITLE: gettext('Update user state'),
  SET_AS_LIMITED: gettext('Set as limited'),
  REMOVE_FROM_BLACKLIST: gettext('Remove from Blacklist'),
  REMOVE_FROM_EXCLUSION_LIST: gettext('Remove from Exclusion list'),
};

// @TODO missing archive icon
const ACTION_ICON = 'Pen';

export default {
  name: 'change-user-state',
  label: getActionLabel,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, _user, isIb) {
    return isFeatureEnabled()('ADVANCED_PROFILE_STATE_TRANSITIONS')
      && !isIb
      && ((state === USER_STATE.FULL && hasAccess('user.set_as_limited.create'))
        || (state === USER_STATE.BLACKLIST && hasAccess('user.remove_from_blacklist.create'))
        || (state === USER_STATE.EXCLUDED && hasAccess('user.remove_from_exclusion.create')));
  },
  handler: (sidepanelManager, _dispatch, user) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.TITLE,
      visible: true,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(ChangeUserState, sidepanelOptions);
  },
};

function getActionLabel(state) {
  switch (state) {
    case USER_STATE.FULL:
      return gettext(text.SET_AS_LIMITED);
    case USER_STATE.BLACKLIST:
      return gettext(text.REMOVE_FROM_BLACKLIST);
    case USER_STATE.EXCLUDED:
      return gettext(text.REMOVE_FROM_EXCLUSION_LIST);
    default:
      return null;
  }
}
