import { bindActionCreators } from 'redux';
import connect from '../../../../../logic/connect';
import TrackingLinksTable, { reducerKey, tableConfig } from './TrackingLinksTable';
import overlayActions from '../../../../../components/overlay';

const mapStateToProps = ({ pages }) => ({
  table: pages[reducerKey].tables[tableConfig({}).tableKey],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),  
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackingLinksTable);
