/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { Tooltip, Icon, Pill } from 'tc-biq-design-system';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import { ActionsCellWrapper, UsernameCell } from '../../../../../components/gridCellRenderers';
import hideCols from '../../../../../logic/utilities/hideCols';

const text = {
  EDIT: gettext('Edit'),
  CREDIT: gettext('Credit'),
  DEBIT: gettext('Debit'),
};

const modifyIcon = (actionHandler, data) => (
  <div key="Modify" onClick={() => actionHandler(data)}>
    <Tooltip title={text.EDIT} placement="top">
      <Icon name="Edit" />
    </Tooltip>
  </div>
);

const modifyAction = onModify => ({
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    pinned: 'right',
    cellRendererFramework: onModify && ActionsCellWrapper({ onModify }, { onModify: modifyIcon }),
    width: 120,
  },
});

const methodsModifier = ({ onModify, onBiqUserPreview }) => ({
  direction: {
    cellRendererFramework: ({ data: { direction } }) => (
      <Fragment>
        {direction.credit && <Pill>{text.CREDIT}</Pill>}
        {direction.debit && <Pill>{text.DEBIT}</Pill>}
      </Fragment>
    ),
  },
  gateways: {
    cellRendererFramework: ({ data: { gateways } }) => gateways.map(gateway => <Pill key={gateway}>{gateway}</Pill>),
  },
  updated_by: { 
    cellRendererFramework: UsernameCell(onBiqUserPreview, { isStaff: true }),
  },
  ...modifyAction(onModify),
});

const mappingsModifier = ({ onModify, onRemove }) => ({
  ...hideCols(['id']),
  identifiers: {
    cellRendererFramework: ({ data }) => data.identifiers.map(identifier => (
      <Pill 
        icon="Close" 
        key={identifier}
        onIconClick={() => onRemove(data, identifier)}
      >
        {identifier}
      </Pill>
    )),
  },
  ...modifyAction(onModify),
});

export { 
  methodsModifier, 
  mappingsModifier, 
};
