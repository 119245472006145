import React, { PureComponent } from 'react';
import { Modal, Button, Space } from 'tc-biq-design-system';
import { func, object, string } from 'prop-types';

import { gettext } from '../../../../logic/utilities/languageUtility';
import OptionsBox from './OptionsBox';
import SortableBox from './SortableBox';
import { updatePosition } from '../../../grid/GridUtils';

import './Selector.scss';

// @TODO move outside
const defaultText = {
  TITLE: gettext('Manage'),
  CANCEL: gettext('Cancel'),
  APPLY: gettext('Apply'),
  SELECTED: gettext('Selected'),
};

const propTypes = {
  onApply: func.isRequired,
  actions: object.isRequired,
  modal: object.isRequired,
  modalId: string.isRequired,
  translations: object,
  iconName: string,
};

const defaultProps = {
  translations: {},
  iconName: 'Settings',
};

const keepIfVisible = i => i.visible;
const toItemDefinition = options => item => _.find(options, { key: item.key });
const getVisibleValues = values => values.filter(keepIfVisible);

const toggleValue = key => parameters => ({
  ...parameters,
  values: parameters.values.map((item) => {
    if (key !== item.key) return item;
    return { ...item, visible: !item.visible };
  }),
});

class Selector extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.text = { ...defaultText, ...props.translations };
  }

  onSortChange = ({ oldIndex, newIndex }) => {
    const { modalId } = this.props;
    this.actions.update(modalId, (parameters) => {
      const values = updatePosition(parameters.values, oldIndex, newIndex);

      return {
        ...parameters,
        values,
      };
    });
  }

  applyToAll = ({ visible }) => {
    const { modalId } = this.props;
    this.actions.update(modalId, parameters => ({
      ...parameters,
      values: parameters.values.map(value => ({ ...value, visible })),
    }));
  }

  modalFooter = () => (
    <div>
      <Button onClick={() => this.close()} color="ghost">
        {this.text.CANCEL}
      </Button>
      <Button color="primary" onClick={this.apply}>
        {this.text.APPLY}
      </Button>
    </div>
  )

  apply = () => {
    const { onApply, modal } = this.props;
    const { values } = modal.parameters;
    onApply(values);
    this.close();
  }

  close = () => {
    const { modalId } = this.props;
    this.actions.close(modalId);
  }

  toggleVisibility = (key) => {
    const { modalId } = this.props;
    this.actions.update(modalId, toggleValue(key));
  }

  render() {
    const { modal, modalId, iconName } = this.props;
    const { visible, parameters } = modal;
    if (_.isEmpty(parameters)) return null;

    const visibleValues = getVisibleValues(parameters.values).map(toItemDefinition(parameters.options));
    const allVisible = visibleValues.length === parameters.values.length;
    const allHidden = visibleValues.length === 0;

    return (
      <Modal
        size="large"
        icon={iconName} 
        title={this.text.TITLE} 
        footerRender={this.modalFooter}
        visible={visible}
      >
        <div className="biq-modal-body">
          <OptionsBox 
            options={parameters.options} 
            modalId={modalId}
            onToggle={this.toggleVisibility} 
          />

          <div className="biq-modal-body__section" style={{ position: 'relative', bottom: '10px' }}>
            <Space size={10} />
            <span style={{ position: 'relative', top: '1px', fontSize: '13px' }}>{ this.text.SELECTED } { visibleValues.length }</span>

            <div className="action-buttons">
              <Button color="transparent" size="small" onClick={() => this.applyToAll({ visible: true })} disabled={allVisible}>
                Add all
              </Button> 
              <Button color="transparent" size="small" onClick={() => this.applyToAll({ visible: false })} disabled={allHidden}>
                Remove all
              </Button> 
            </div>

            <SortableBox 
              values={visibleValues} 
              toggle={this.toggleVisibility} 
              onSortChange={this.onSortChange} 
            />
          </div>
        </div>
      </Modal>
    );
  }
}

Selector.propTypes = propTypes;
Selector.defaultProps = defaultProps;

export default Selector;
