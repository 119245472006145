import React, { Component } from 'react';
import { object, func, bool } from 'prop-types';
import { Spinner } from 'tc-biq-design-system';

import AclGrid from '../../../../../components/AclGrid';

import './TeamAcls.scss';

const propTypes = {
  team: object,
  options: object,
  actions: object.isRequired,
  onAclChange: func.isRequired,
  optionsIsLoading: bool.isRequired,
};

const defaultProps = {
  team: null,
  options: null,
};

class TeamAcls extends Component {
  UNSAFE_componentWillMount() {
    const { actions } = this.props;
    actions.loadTeamOptions();
  }

  getAclOptions = () => {
    const { options } = this.props;
    const fields = _.get(options, 'actions.POST.fields');
    if (fields) {
      return fields.find(field => field.key === 'view_acls').choices.map(acl => acl.value);
    }

    return [];
  }

  render() {
    const { team, onAclChange, optionsIsLoading } = this.props;
    const aclOptions = this.getAclOptions();
    const showLoader = optionsIsLoading;
    const showAcls = team.view_acls && aclOptions.length > 0 && !optionsIsLoading;

    return (
      <div className="team-acls">
        {showAcls && <AclGrid values={team.view_acls} options={aclOptions} onChange={onAclChange} />}
        {showLoader && <div className="team-acls__loader"><Spinner /></div>}
      </div>
    );
  }
}

TeamAcls.propTypes = propTypes;
TeamAcls.defaultProps = defaultProps;

export default TeamAcls;
