export default {
  REJECTED_IB: 1010,
  INCOMPLETE_IB: 1000,
  PENDING_IB: 500,
  FULL: 300,
  LIMITED: 200,
  PENDING: 150, // changed from 140
  REVIEW: 110,
  ALERT: 100,
  KYC_CHECK_IN_PROGRESS: 99, // changed from 150
  UNSUITABLE_REFER_DEMO: 62,
  UNSUITABLE_DEMO: 61,
  DEMO: 60,
  PENDING_SMS_VERIFICATION: 57,
  PENDING_EMAIL_VERIFICATION: 55,
  INCOMPLETE: 50,
  EXCLUDED: 40,
  BLACKLIST: 35,
  UNSUITABLE_REFER: 31,
  UNSUITABLE: 30,
  REJECTED: 20,
  LEAD: 10,
  APPROVED_IB: 1,
  ARCHIVED: 0,
};
