import React, { Component } from 'react';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { object, func, bool } from 'prop-types';

import connect from '../../../../../../../logic/connect';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';
import overlayActions from '../../../../../../../components/overlay';
import to from '../../../../../../../logic/utilities/to';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { SidepanelFooter } from '../../../../../../../components/common';
import { formErrorHandler } from '../../../../../../../components/form/logic/utils';

const SIDEPANEL_ID = 'PAYMENT_METHOD_FORM';
const api = getDjangoApi('payment_methods');
const { create, update, resetFields, setRowData } = FormActionsFactory(SIDEPANEL_ID);

const types = {
  ADD: 'add',
  EDIT: 'edit',
};

const text = {
  CREATE_TITLE: gettext('Create payment method'),
  EDIT_TITLE: gettext('Update payment method'),
  EDIT_BUTTON_LABEL: {
    confirm: gettext('Save'),
    cancel: gettext('Discard'),
  },
  CREATE_BUTTON_LABEL: {
    confirm: gettext('Create'),
    cancel: gettext('Discard'),
  },

  SUBMIT_SUCCESS: gettext('Payment method saved successfully'),
  SUBMIT_ERROR: gettext('Error while saving payment method'),
  ERROR: gettext('Something went wrong'),
};

const customFooter = (execute, close, submitInProgress, type) => (
  <SidepanelFooter
    execute={execute} 
    close={close} 
    submitInProgress={submitInProgress} 
    confirmColor="primary"
    buttonLabels={getTitlesOrLabels(type)}
    formId={SIDEPANEL_ID}
  />
);

const getTitlesOrLabels = (type, getTitle) => {
  switch (type) {
    case types.ADD:
      return getTitle ? text.CREATE_TITLE : text.CREATE_BUTTON_LABEL;
    default:
      return getTitle ? text.EDIT_TITLE : text.EDIT_BUTTON_LABEL;
  }
};

const formConfig = {
  form: SIDEPANEL_ID,
  api,
};

const formModifiers = isEdit => ({
  name: {
    disabled: isEdit,
  },
  type: {
    disabled: isEdit,
  },
});

const propTypes = {
  sidepanel: object.isRequired,
  actions: object.isRequired,
  onSuccessRequest: func,
  submitInProgress: bool,
};

const defaultProps = {
  onSuccessRequest: null,
  submitInProgress: false,
};

class PaymentMethodForm extends Component {
  componentDidUpdate() {
    const { sidepanel, actions } = this.props;
    const { parameters, visible } = sidepanel;
    if (visible && (parameters.type === types.EDIT)) {
      const data = { ...parameters.data, ...parameters.data.direction };
      actions.setRowData(data);
    }
  }

  onSuccess = () => {
    const { onSuccessRequest } = this.props;
    this.close();
    onSuccessRequest && onSuccessRequest();
    notifier.success(text.SUBMIT_SUCCESS);
  }

  getRequestPayload = (values) => {
    const { sidepanel } = this.props;
    const { parameters } = sidepanel;

    const { credit, debit, is_visible_in_bo, ...rest } = _.reduce(values, (payload, field, key) => ({
      ...payload, [key]: typeof field === 'object' ? field.value : field,
    }), {});

    return {
      ...rest,
      credit: !!credit,
      debit: !!debit,
      direction: {
        credit: !!credit,
        debit: !!debit,
      },
      is_visible_in_bo: !!is_visible_in_bo,
      id: parameters.type === types.EDIT ? parameters.data.id : null,
    };
  }

  submit = async () => {
    const { actions, sidepanel } = this.props;
    const { parameters } = sidepanel;
    const isEdit = parameters.type === types.EDIT;
    const request = isEdit 
      ? actions.update(api, parameters.data.id, this.getRequestPayload) 
      : actions.create(api, this.getRequestPayload);

    const [err, res] = await to(request);
    if (err) this.showError(err);
    if (res) this.onSuccess();
  }

  showError = (err) => {
    formErrorHandler()(err);
  }

  close = () => {
    const { actions } = this.props;
    actions.closeOverlay(SIDEPANEL_ID);
  }

  renderForm = () => {
    const { sidepanel } = this.props;
    const { parameters } = sidepanel;
    const isEdit = parameters && parameters.type === types.EDIT;
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    const { FormComponent } = this;
    return <FormComponent formId={SIDEPANEL_ID} modifiers={formModifiers(isEdit)} />;
  }

  render() {
    const { sidepanel, submitInProgress } = this.props;
    const { parameters, visible } = sidepanel;
    const TITLE = visible && getTitlesOrLabels(parameters.type, true);

    return (
      <Sidepanel
        visible={visible}
        title={TITLE}
        icon="Payments"
        onCloseIconClick={() => this.close()}
        footerRender={() => customFooter(this.submit, this.close, submitInProgress, parameters.type)}
      >
        {this.renderForm()}
      </Sidepanel>
    );
  }
}

PaymentMethodForm.propTypes = propTypes;
PaymentMethodForm.defaultProps = defaultProps;

const mapStateToProps = ({ overlays, forms }) => {
  const { fields, submitInProgress } = forms[SIDEPANEL_ID];

  return {
    sidepanel: overlays[SIDEPANEL_ID],
    form: fields,
    submitInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeOverlay: overlayActions.close,
      setRowData,
      create,
      update,
      resetFields,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentMethodForm);
