/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Button, notifier } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import overlayActions from '../../../../components/overlay';
import Page from '../../../../components/Page';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import DeleteSmsTemplateSidepanel from './Sidepanel/deleteSmsTemplate';
import modifiers from './modifiers';
import If from '../../../../components/If';
import { BiqUserPreview } from '../../../../components/common/sidepanels';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';
import { formErrorHandler } from '../../../../components/form/logic/utils';

const text = {
  PAGE_TITLE: gettext('Sms templates'),
  BIQ_BREADCRUMB: gettext('BrokerIQ'),
  ADD_SMS_TEMPLATE_BUTTON_LABEL: gettext('Add new sms template'),
  BULK_DELETE_LABEL: gettext('Delete'),
  DELETE_SUCCESS: gettext('Sms template deleted successfully'),
  DELETE_ERROR: gettext('Something went wrong'),
};

const pageConfig = {
  apiUrl: 'sms/templates',
  reducerKey: 'SMS_TEMPLATES',
  tableKey: 'SMS_TEMPLATES_TABLE',
};

const { GridComponent, actions } = GridFactory(pageConfig);
const bread = [{ label: text.PAGE_TITLE, route: appRoutes.SMS_TEMPLATES }];

const headerActions = () => (
  <If condition={hasAccess('sms.templates.create') || hasAccess('sms.templates.content.create')}>
    <Link to={generatePath(appRoutes.SMS_TEMPLATES_CREATE)}>
      <Button startPropagation>{text.ADD_SMS_TEMPLATE_BUTTON_LABEL}</Button>
    </Link>
  </If>
);

class SmsTemplates extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.bulkActions = [
      ...(hasAccess('sms.templates.destroy') && [{
        icon: 'Delete',
        label: text.BULK_DELETE_LABEL,
        onClick: this.actions.openSidepanel.bind(this, 'DELETE_SMS_TEMPLATE'),
      }]),
    ];

    this.tableActions = {
      modify: hasAccess('sms.templates.update'),
      copy: hasAccess('sms.templates.create'),
      ...(hasAccess('sms.templates.destroy') && { onDelete: this.onDelete.bind(this) }),
      previewUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  onDelete(data) {
    this.actions.deleteRow(data)
      .then(() => {
        notifier.success(text.DELETE_SUCCESS);
      }, (e) => {
        formErrorHandler(text.DELETE_ERROR)(e);
      });
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifiers(this.tableActions),
    };

    return (
      <Page headerActions={headerActions} bread={bread} title={text.PAGE_TITLE}>
        <Filters {...filterProps} />
        <GridComponent
          checkboxSelection={!!this.bulkActions.length}
          singleActions={this.tableActions.modify || this.tableActions.copy}
          bulkActions={this.bulkActions}
          tableModifier={modifiers(this.tableActions)}
          onRowSelected={data => this.actions.updateSidepanel('DELETE_SMS_TEMPLATE', data)}
          {...this.props}
        />
        <DeleteSmsTemplateSidepanel tableActions={this.actions} />
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      updateRowData: actions.updateRowData,
      addActionsColumn: actions.addActionsColumn,
      fetchTableData: actions.fetchTableData,
      deleteRow: actions.deleteRow,
      openSidepanel: overlayActions.open,
      updateSidepanel: overlayActions.update,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
    },
    dispatch,
  ),
});

export default compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(SmsTemplates);
