import React from 'react';
import { bool } from 'prop-types';
import { Spinner } from 'tc-biq-design-system';

import { gettext } from '../../../../../../logic/utilities/languageUtility';

const propTypes = {
  isVisible: bool.isRequired,
};

const text = { LOADING: gettext('Loading...') };

const Loading = ({ isVisible }) => {
  if (!isVisible) return null;
  return (
    <div className="center-content">
      <Spinner size="large" />
      <span>{text.LOADING}</span>
    </div>
  );
};

Loading.propTypes = propTypes;

export default Loading;
