import React, { Component } from 'react';
import { Button, Checkbox, Dropdown } from 'tc-biq-design-system';
import { object } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../../logic/connect';
import typeFilterActions from './Model';
import withUnmountMethod from '../../../../../../components/hoc/withUnmountMethod';
import activityActions from '../../Model/activityActions';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import './ActivityTypeFilter.scss';

const propTypes = {
  actions: object.isRequired,
  typeFilter: object.isRequired,
};

const text = {
  BUTTON_LABEL: gettext('All activity'),
  ACITIVITIES_SELECTED: gettext('activities selected'),
  SELECT_ALL: gettext('Select all'),
  APPLY_FILTER: gettext('Apply filter'),
};

const customHeader = toggleHandler => (
  <Button
    key="filter-allFEa"
    icon="CaretDown"
    iconPosition="right"
    color="ghost"
    onClick={toggleHandler}
  >
    {text.BUTTON_LABEL}
  </Button>
);

const getCheckedFilters = types => Object.keys(types).filter(type => types[type]);

const customList = (applyFilter, selected, selectAll, toggleCheckbox) => [
  {
    onClick: () => {}, // I have to keep it here because of the wierd implementation on design system side
    item: close => (
      <div className="activity-type-filter" key="activity-type-filter">
        <div className="activity-type-filter__body">
          <div className="activity-type-filter__body__count">
            <span>{`${getCheckedFilters(selected).length} ${text.ACITIVITIES_SELECTED}`}</span>
            <span className="like-a-link" onClick={selectAll}>
              {text.SELECT_ALL}
            </span>
          </div>
          {!_.isEmpty(selected)
            && Object.keys(selected).map((type, index) => {
              const name = type.replace('_', ' ');
              return (
                <div key={`${type}-${index}`} className="activity-type-filter__body__checkbox">
                  <Checkbox name={type} checked={selected[type]} onChange={toggleCheckbox}>
                    {`${name[0].toUpperCase()}${name.slice(1)}`}
                  </Checkbox>
                </div>
              );
            })}
        </div>
        <div className="activity-type-filter__footer">
          <Button
            onClick={() => {
              applyFilter();
              close();
            }}
            size="full-width"
          >
            {text.APPLY_FILTER}
          </Button>
        </div>
      </div>
    ),
  },
];

class ActivityTypeFilter extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  componentWillUnmount() {
    this.actions.resetTypeFilter();
  }

  toggleCheckbox = (event) => {
    const { name, checked } = event.target;
    this.actions.setTypeFilter(name, checked);
  };

  selectAll = () => {
    this.actions.setTypeFilterAll();
  };

  applyFilter = () => {
    this.actions.loadActivity();
  }

  render() {
    const { typeFilter } = this.props;
    return (
      <Dropdown
        customHeader={customHeader}
        customList={customList(
          this.applyFilter,
          typeFilter.value,
          this.selectAll,
          this.toggleCheckbox,
        )}
      />
    );
  }
}

ActivityTypeFilter.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setTypeFilter: typeFilterActions.setTypeFilter,
      setTypeFilterAll: typeFilterActions.setTypeFilterAll,
      resetTypeFilter: typeFilterActions.resetTypeFilter,
      loadActivity: activityActions.loadActivity,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const { filters } = pages.ACTIVITY_STREAM;
  return {
    typeFilter: filters.typeFilter,
  };
};

export default withUnmountMethod(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ActivityTypeFilter),
);
