import React, { Component } from 'react';
import { Space, DashboardCard } from 'tc-biq-design-system';
import { string } from 'prop-types';

import DashboardNumeric from '../../Numeric';
import withFilters from '../../hoc/withFilters';
import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import { currencyFormatter } from '../../../../../../../../logic/utilities/formatters';

const size = {
  w: 2,
  h: 5,
  minW: 2,
  minH: 5,
  maxW: 3,
  maxH: 5,
  y: 0,
  x: 6,
};

const api = getDjangoApi('dashboard/pending_deposits');

const propTypes = {
  title: string.isRequired,
  description: string.isRequired,
};

class PendingDeposits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingDeposits: {},
      loading: false,
    };
  }

  onQueryReady = async (query) => {
    this.setState({ loading: true });
    const { data } = await api.list(query);
  
    this.setState({
      pendingDeposits: data,
      loading: false,
    });
  }

  render() {
    const { title, description } = this.props;
    const { pendingDeposits: { amount, currency, number }, loading } = this.state;
    const depositAmount = currencyFormatter(amount, currency);
    const depositNumber = number || 0;
    return (
      <DashboardCard
        title={title}
        description={description}
        loading={loading}
      >
        <DashboardNumeric>
          <DashboardNumeric.Wrapper>
            <Space size={5} />
            <DashboardNumeric.Price>{depositAmount}</DashboardNumeric.Price>
            <Space size={12} />
            <DashboardNumeric.Number iconName="Deposit">{depositNumber}</DashboardNumeric.Number>
          </DashboardNumeric.Wrapper>
        </DashboardNumeric>
      </DashboardCard>
    );
  }
}

PendingDeposits.propTypes = propTypes;

export default ({ removeDateFilters } = {}) => ({
  component: withFilters(PendingDeposits, { removeDateFilters }),
  size,
});
