/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Button } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import overlayActions from '../../../../components/overlay';
import Page from '../../../../components/Page';
import Export from '../../../../components/Export';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import modifier from './modifiers';
import UploadBulkPayments from './modals/UploadBulkPayments';
import If from '../../../../components/If';
import { UserPreview, BiqUserPreview } from '../../../../components/common/sidepanels';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const pageConfig = {
  apiUrl: 'internal-transactions',
  reducerKey: 'INTERNAL_TRANSACTIONS',
  tableKey: 'INTERNAL_TRANSACTIONS_TABLE',
};

const text = {
  TITLE: gettext('Internal Transactions'),
};

const bread = [{ label: text.TITLE, route: appRoutes.INTERNAL_TRANSACTIONS }];

const { GridComponent, actions } = GridFactory(pageConfig);

class InternalTransactions extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.headerActions = this.headerActions.bind(this);
    this.fetchTableData = this.fetchTableData.bind(this);

    this.tableActions = {
      previewUser: this.actions.openOverlay.bind(this, 'USER_PREVIEW'),
      previewBiqUser: this.actions.openOverlay.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  headerActions() {
    const { exportUrl, actions } = this.props;
    return (
      <span>
        <If condition={hasAccess('payment.mass_uploads.create')}>
          <Button color="confirmation" onClick={() => actions.openOverlay('UPLOAD_BULK_PAYMENTS')}>
            {gettext('Upload bulk payments')}
          </Button>
        </If>
        {hasAccess('payment_transactions.internal.export.*') && <Export url={exportUrl} />}
      </span>
    );
  }

  fetchTableData() {
    const { table } = this.props;
    this.actions.fetchTableData(table.query);
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions),
    };

    return (
      <Page title={text.TITLE} headerActions={this.headerActions} bread={bread}>
        <Filters {...filterProps} />
        <GridComponent tableModifier={modifier(this.tableActions)} {...this.props} />
        <UploadBulkPayments fetchTableData={this.fetchTableData} />
        <UserPreview />
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchTableData: actions.fetchTableData,
      openOverlay: overlayActions.open,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: page.tables[tableKey].exportUrl,
    table,
    fields: table.fields,
  };
};

export default compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(InternalTransactions);
