import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import { refactoredInjectRules, setupFields } from '../../../../../../logic/services/query-adapter';

const actionTypes = {
  GET_WITHDRAWAL_FLOW_STEP_FIELDS_OPTIONS: 'GET_WITHDRAWAL_FLOW_STEP_FIELDS_OPTIONS',
  GET_WITHDRAWAL_FLOW_STEP_FIELDS_OPTIONS_PENDING: 'GET_WITHDRAWAL_FLOW_STEP_FIELDS_OPTIONS_PENDING',
  GET_WITHDRAWAL_FLOW_STEP_FIELDS_OPTIONS_FULFILLED: 'GET_WITHDRAWAL_FLOW_STEP_FIELDS_OPTIONS_FULFILLED',
  SUBMIT_WITHDRAWAL_FLOW_STEP: 'SUBMIT_WITHDRAWAL_FLOW_STEP',
  SUBMIT_WITHDRAWAL_FLOW_STEP_PENDING: 'SUBMIT_WITHDRAWAL_FLOW_STEP_PENDING',
  SUBMIT_WITHDRAWAL_FLOW_STEP_REJECTED: 'SUBMIT_WITHDRAWAL_FLOW_STEP_REJECTED',
  SUBMIT_WITHDRAWAL_FLOW_STEP_FULFILLED: 'SUBMIT_WITHDRAWAL_FLOW_STEP_FULFILLED',
  GET_WITHDRAWAL_FLOW_STEP_INFO: 'GET_WITHDRAWAL_FLOW_STEP_INFO',
  GET_WITHDRAWAL_FLOW_STEP_INFO_FULFILLED: 'GET_WITHDRAWAL_FLOW_STEP_INFO_FULFILLED',
  SET_WITHDRAWAL_FLOW_STEP_NAME: 'SET_WITHDRAWAL_FLOW_STEP_NAME',
  SET_WITHDRAWAL_FLOW_STEP_PRIORITY: 'SET_WITHDRAWAL_FLOW_STEP_PRIORITY',
  SET_WITHDRAWAL_FLOW_STEP_CONDITION: 'SET_WITHDRAWAL_FLOW_STEP_CONDITION',
  SET_WITHDRAWAL_FLOW_STEP_TEAMS: 'SET_WITHDRAWAL_FLOW_STEP_TEAMS',
};

const withdrawalFlowStepAPI = getDjangoApi('settings/withdrawal_flow');

const initialState = {
  submitInProgress: false,
  fields: [],
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  errors: {
    name: [''],
    priority: [''],
    teams: [''],
  },
  name: '',
  priority: '',
  teams: [],
  selectedTeams: [],
};

const getWithdrawalOptionsHandler = (state, { payload }) => {
  const { data } = payload;
  const { fields } = data.event_spec;
  const teams = _.find(data.actions.POST.fields, { key: 'teams' }).choices;
  const mappedFields = setupFields(fields);

  return {
    ...state,
    fields: [...mappedFields],
    teams: [...teams],
  };
};

const setStepNameHandler = (state, { payload }) => (
  {
    ...state,
    name: payload,
    errors: { ...state.errors, name: [''] },
  }
);

const setStepPriorityHandler = (state, { payload }) => (
  {
    ...state,
    priority: payload,
    errors: { ...state.errors, priority: [''] },
  }
);

const setStepConditionHandler = (state, { payload }) => (
  {
    ...state,
    group: { ...payload },
  }
);

const setStepTeamsHandler = (state, { payload }) => (
  {
    ...state,
    selectedTeams: [...payload],
    errors: { ...state.errors, teams: [''] },
  }
);

const flowStepSubmitHandler = state => (
  {
    ...state,
    submitInProgress: false,
  }
);

const flowStepSubmitErrorHandler = (state, { payload }) => (
  {
    ...state,
    errors: { ...state.errors, ...payload.data },
    submitInProgress: false,
  }
);

const getFlowStepInfoHandler = (state, { payload }) => {
  const { condition, teams, priority, name } = payload.data;
  const group = refactoredInjectRules(state.fields, condition);
  const selectedTeams = teams.map(team => _.find(state.teams, { value: team }));

  return {
    ...state,
    name,
    group,
    selectedTeams,
    priority,
  };
};

export const getFieldOptions = createAction(
  actionTypes.GET_WITHDRAWAL_FLOW_STEP_FIELDS_OPTIONS,
  async () => withdrawalFlowStepAPI.options(),
);

export const submitFlowStep = createAction(
  actionTypes.SUBMIT_WITHDRAWAL_FLOW_STEP,
  async (isEdit, request, id) => (
    isEdit
      ? withdrawalFlowStepAPI.updatePart(id, request)
      : withdrawalFlowStepAPI.create(request)
  ),
);

export const setStepName = createAction(
  actionTypes.SET_WITHDRAWAL_FLOW_STEP_NAME,
  event => event.target.value,
);

export const setStepPriority = createAction(
  actionTypes.SET_WITHDRAWAL_FLOW_STEP_PRIORITY,
  event => event.target.value,
);

export const setStepCondition = createAction(
  actionTypes.SET_WITHDRAWAL_FLOW_STEP_CONDITION,
  group => group,
);

export const getFlowStepInfo = createAction(
  actionTypes.GET_WITHDRAWAL_FLOW_STEP_INFO,
  async id => withdrawalFlowStepAPI.retrieve(id),
);

export const setStepTeams = createAction(
  actionTypes.SET_WITHDRAWAL_FLOW_STEP_TEAMS,
  value => value,
);

export const withdrawalFlowStepActions = {
  getFieldOptions,
  setStepName,
  setStepPriority,
  setStepCondition,
  setStepTeams,
  getFlowStepInfo,
  submitFlowStep,
};

export default handleActions(
  {
    [actionTypes.GET_WITHDRAWAL_FLOW_STEP_FIELDS_OPTIONS_PENDING]: state => (
      { ...state, optionsInProgress: true }
    ),
    [actionTypes.GET_WITHDRAWAL_FLOW_STEP_FIELDS_OPTIONS_FULFILLED]: getWithdrawalOptionsHandler,
    [actionTypes.SUBMIT_WITHDRAWAL_FLOW_STEP_PENDING]: state => (
      { ...state, submitInProgress: true }
    ),
    [actionTypes.SUBMIT_WITHDRAWAL_FLOW_STEP_FULFILLED]: flowStepSubmitHandler,
    [actionTypes.SUBMIT_WITHDRAWAL_FLOW_STEP_REJECTED]: flowStepSubmitErrorHandler,
    [actionTypes.GET_WITHDRAWAL_FLOW_STEP_INFO_FULFILLED]: getFlowStepInfoHandler,
    [actionTypes.SET_WITHDRAWAL_FLOW_STEP_TEAMS]: setStepTeamsHandler,
    [actionTypes.SET_WITHDRAWAL_FLOW_STEP_NAME]: setStepNameHandler,
    [actionTypes.SET_WITHDRAWAL_FLOW_STEP_CONDITION]: setStepConditionHandler,
    [actionTypes.SET_WITHDRAWAL_FLOW_STEP_PRIORITY]: setStepPriorityHandler,
  },
  initialState,
);
