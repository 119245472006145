import React from 'react';
import { Toggle, Pill } from 'tc-biq-design-system';
import { bool, number, func } from 'prop-types';

import hideCols from '../../../../../logic/utilities/hideCols';
import { UsernameCell, ActionsCellWrapper } from '../../../../../components/gridCellRenderers';
import { hasAccess } from '../../../../../logic/services/acl';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { actionIcons } from '../../../../../components/grid/GridUtils';

const text = {
  YES: gettext('YES'),
  NO: gettext('NO'),  
};

const isEnabledRenderer = ({ is_enabled, id, toggleIsEnabled }) => { 
  if (hasAccess('settings.automatic_fees.update')) {
    return <Toggle checked={is_enabled} onChange={() => { toggleIsEnabled({ is_enabled, id }); }} />;
  } 
  return (<Pill type={is_enabled ? 'status01' : 'status04'}> { is_enabled ? text.YES : text.NO } </Pill>
  );
};

isEnabledRenderer.propTypes = {
  is_enabled: bool,
  toggleIsEnabled: func.isRequired,
  id: number.isRequired, 
};

isEnabledRenderer.defaultProps = {
  is_enabled: false,
};

const noop = () => {};

export default ({ toggleIsEnabled, modify, remove }) => {
  const actions = {
    ...(modify && { modify }),
    ...(remove && { remove }),
  };
  
  return { ...hideCols(['id']),
    is_enabled: {
      cellRendererFramework: ({ data }) => isEnabledRenderer({
        is_enabled: data.is_enabled,
        id: data.id,
        toggleIsEnabled,
      }),
    },
    created_by: {
      cellRendererFramework: UsernameCell(noop, { isStaff: true }),
    },
    modified_by: {
      cellRendererFramework: UsernameCell(noop, { isStaff: true }),
    },
    actions: {
      label: '',
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(modify, remove)),
      pinned: 'right',
      width: (modify && remove) ? 100 : 50,
    },
  };
};
