import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { Panel, Pill, Code, Button, Popconfirm, notifier } from 'tc-biq-design-system';

import { getDjangoApi } from '../../../../logic/services/api-factory';
import { gettext } from '../../../../logic/utilities/languageUtility';
import { dateTimeFormatter } from '../../../../logic/utilities/formatters';
import BiqAccordion from '../../../BiqAccordion';
import PanelRow from './PanelRow';

const text = {
  SECURITY_CHECK: gettext('Security check'),
  GATEWAY: gettext('Gateway'),
  DECISION: gettext('Decision'),
  SCORE: gettext('Score'),
  CREATED_DATE: gettext('Created date'),
  RESPONSE: gettext('Response'),
  REJECT: gettext('Reject'),
  REJECT_POPCONFIRM: gettext('Are you sure you want to manually reject this transaction?'),
  REJECT_SUCCESS: gettext('Transaction successfully rejected'),
  REJECT_ERROR: gettext('Error while rejecting transaction'),
  ACCEPT: gettext('Accept'),
  APPROVE_POPCONFIRM: gettext('Are you sure you want to manually approve this transaction?'),
  APPROVE_SUCCESS: gettext('Transaction successfully accepted'),
  APPROVE_ERROR: gettext('Error while accepting transaction'),
  CANCEL: gettext('Cancel'),
};

const propTypes = {
  transaction: object.isRequired,
  activate: func.isRequired,
};

class SecurityCheck extends Component {
  showActions = () => {
    const { transaction } = this.props;
    const { security_check, thirdparty_success } = transaction;
    return security_check === 'Review' && !thirdparty_success;
  }

  reject = () => {
    const { activate, transaction } = this.props;

    getDjangoApi(`payment_transactions/${transaction.id}/manual_reject`)
      .create({})
      .then(() => {
        notifier.success(text.REJECT_SUCCESS);
        activate();
      }, ({ data }) => notifier.error(data.msg || text.REJECT_ERROR));
  }

  accept = () => {
    const { activate, transaction } = this.props;

    getDjangoApi(`payment_transactions/${transaction.id}/manual_approve_thirdparty`)
      .create({})
      .then(() => {
        notifier.success(text.APPROVE_SUCCESS);
        activate();
      }, ({ data }) => notifier.error(data.msg || text.APPROVE_ERROR));
  }

  render() {
    const { transaction } = this.props; // eslint-disable-line
    const { fraud_check = {} } = transaction;

    const {
      gateway,
      decision,
      score,
      created_date,
      response,
    } = fraud_check;

    const PanelHeader = (
      <div>
        <span>{text.SECURITY_CHECK}</span>
        {this.showActions() && (
          <Popconfirm 
            type="destructive" 
            icon="Reject" 
            label={text.REJECT_POPCONFIRM} 
            onConfirm={this.reject}
            buttonLabels={{ cancel: text.CANCEL, confirm: text.REJECT }}
          >
            <Button size="small" color="destructive">{text.REJECT}</Button>
          </Popconfirm>
        )}
        {this.showActions() && (
          <Popconfirm 
            type="confirmation" 
            icon="Approve" 
            label={text.APPROVE_POPCONFIRM} 
            onConfirm={this.accept}
            buttonLabels={{ cancel: text.CANCEL, confirm: text.ACCEPT }}
          >
            <Button size="small" color="confirmation">{text.ACCEPT}</Button>
          </Popconfirm>
        )}
      </div>
    );

    return (
      <Panel title={PanelHeader}>
        <div className="card-panel-body">
          <PanelRow label={text.GATEWAY} value={gateway.name} />
          <PanelRow label={text.DECISION} value={<Pill type="primary">{decision}</Pill>} />
          <PanelRow label={text.SCORE} value={score} />
          <PanelRow label={text.CREATED_DATE} value={dateTimeFormatter(created_date)} />

          <BiqAccordion title={text.RESPONSE}>
            <Code>{response}</Code>
          </BiqAccordion>
        </div>
      </Panel>
    );
  }
}

SecurityCheck.propTypes = propTypes;
export default SecurityCheck;
