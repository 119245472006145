import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { object, array, bool, string } from 'prop-types';
import { Button, Icon, Spinner, notifier, Sidepanel, InfoBox } from 'tc-biq-design-system';
import classNames from 'classnames';

import { 
  fetchCommunicationWizard, 
  submitCommunicationWizard,
  finishWizard,
  resetWizard,
  setActiveStep, 
  initWizard,
  setNextSteps, 
  goStepBack, 
} from './Model';
import SelectStatus from './SelectStatus';
import CreateTask from './CreateTask';
import SendNote from './SendNote';

import './CommunicationFlow.scss';

import { fetchUser } from '../../../Model';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import to from '../../../../../../../logic/utilities/to';

const TASK_FORM_KEY = 'CREATE_TASK_COMMUNICATION_FLOW_FORM';
const NOTE_FORM_KEY = 'SEND_NOTE_COMMUNICATION_FLOW_FORM';
export const SIDEPANEL_ID = 'COMMUNICATION_FLOW_SIDEPANEL';

export const stepTypes = {
  STATUS: 'status',
  SUBSTATUS: 'substatus',
  START: 'start',
  TASK: 'task',
  NOTE: 'note',
  UNDEFINED: 'undefined',
};

const text = {
  COMMUNICATION_FLOW: gettext('Communication flow'),
  ANSWERED: gettext('Answered'),
  NOT_ANSWERED: gettext('Not answered'),
  BACK: gettext('Back'),
  NEXT: gettext('Next step'),
  SUBMIT: gettext('Complete flow'),
  START_NEW: gettext('Start new flow'),
  NOTES: gettext('Notes'),
  FINISHED: gettext('Complete'),
  DONT_HAVE_STATUSES: gettext('You don\'t have any statuses configured for this flow.'),
  ERROR_GENERAL: gettext('Something went wrong!'),
  STEPS: {
    [stepTypes.STATUS]: gettext('Status'),
    [stepTypes.SUBSTATUS]: gettext('Sub Status'),
    [stepTypes.TASK]: gettext('Task'),
    [stepTypes.NOTE]: gettext('Notes'),
  },
};

// eslint-disable-next-line react/prop-types
const StepIndicator = ({ done, active, number, label }) => (
  
  <div className={classNames('biq-communication-flow__step', { 
    'biq-communication-flow__step--done': done, 
    'biq-communication-flow__step--active': active, 
  })}
  >
    <div className="biq-communication-flow__step__ring">
      <div className="biq-communication-flow__step__circle">
        { done ? <Icon colorName="text-neutral-50" name="Checkmark" /> : number }
      </div>
    </div>
    <span className="biq-communication-flow__step__label tc-paragraph-strong">{label}</span>
  </div>
);

// eslint-disable-next-line react/prop-types
const StepsIndicator = ({ activeStep, nextSteps, previousSteps, finished }) => {
  const previous = previousSteps.map(step => ({
    done: true,
    type: step.type,
  }));
  const active = { active: true, type: activeStep.type };
  const next = nextSteps.map(step => ({
    type: step.type,
  }));
  const steps = [...previous, active, ...next].splice(1).map((step, index) => ({
    ...step,
    ...(finished && { done: true, active: false }),
    number: step.type === stepTypes.UNDEFINED ? '...' : index + 1,
    label: text.STEPS[step.type],
  }));

  
  return (
    <div className="biq-communication-flow__steps">
      {steps.length > 1 && <div className="biq-communication-flow__steps__line" />}
      {steps.map((step, index) => (
        <StepIndicator key={index} {...step} />
      ))}
    </div>
  );
};

StepsIndicator.propTypes = {
  previousSteps: array.isRequired,
  activeStep: object.isRequired,
  nextSteps: array.isRequired,
};

// eslint-disable-next-line react/prop-types
const StartWizard = ({ item, onSelect }) => (
  <Fragment>
    {!_.isEmpty(item.answered) && (
      <Button 
        icon="Call"
        onClick={() => onSelect(item.answered)}
      >
        {text.ANSWERED}
      </Button>
    )}
    {!_.isEmpty(item.not_answered) && (
      <Button 
        icon="Call" 
        color="destructive"
        onClick={() => onSelect(item.not_answered)}
      >
        {text.NOT_ANSWERED}
      </Button>
    )}  
    {_.isEmpty(item.answered) && _.isEmpty(item.not_answered) && (
      <div className="biq-communication-flow__info">
        <InfoBox>{text.DONT_HAVE_STATUSES}</InfoBox>
      </div>
    )}
  </Fragment>
);

StartWizard.propTypes = {
  item: object.isRequired,
};

// eslint-disable-next-line react/prop-types
const SelectStatusStep = ({ item, ...rest }) => (<SelectStatus statuses={item} {...rest} />);

// eslint-disable-next-line react/prop-types
const SelectSubStatusStep = ({ item, ...rest }) => (<SelectStatus statuses={item.substatuses} {...rest} />);

// eslint-disable-next-line react/prop-types
const Navigation = ({ nextSteps, previousSteps = [], activeStep, notes, onBack, onNext, onComplete, isLoading, finished }) => {
  const disableBack = previousSteps && previousSteps.length > 0 && previousSteps[previousSteps.length - 1].type === stepTypes.START;
  const showNext = nextSteps.length > 0;
  const disableNext = !activeStep.selected && activeStep.type !== stepTypes.TASK;
  const showComplete = !showNext;
  const disableComplete = (activeStep.type === stepTypes.NOTE && _.isEmpty(notes));
  return (
    <div className="biq-communication-flow__navigation">
      { !finished && (
      <Fragment>
        { !disableBack && <Button color="ghost" onClick={onBack}>{text.BACK}</Button> }
        {showNext && <Button loading={isLoading} disabled={disableNext} onClick={onNext}>{text.NEXT}</Button>}
        {showComplete && <Button loading={isLoading} disabled={disableComplete} onClick={onComplete}>{text.SUBMIT}</Button>}
      </Fragment>
      )}
    </div>
  );
};

Navigation.propTypes = {
  nextSteps: array.isRequired,
  activeStep: object.isRequired,
  previousSteps: array,
};

Navigation.defaultProps = {
  previousSteps: [],
};

const Step = (props) => {
  const { type, user } = props;
  
  switch (type) {
    case stepTypes.START:
      return <StartWizard {...props} />;
    case stepTypes.STATUS: 
      return <SelectStatusStep {...props} />;
    case stepTypes.SUBSTATUS: 
      return <SelectSubStatusStep {...props} />;
    case stepTypes.TASK:
      return <CreateTask user={user} hideFooter {...props} />;
    default:
      return null;
  }
};

Step.propTypes = {
  type: string.isRequired,
  user: object.isRequired,
};

const propTypes = {
  actions: object.isRequired,
  user: object.isRequired,
  wizard: object,
  activeStep: object,
  selectedStep: object,
  nextSteps: array,
  previousSteps: array,
  submitTaskInProgress: bool,
  submitNoteInProgress: bool,
  fetchInProgress: bool,
  submitInProgress: bool,
  notes: string,
  finished: bool,
  sidepanelOptions: object.isRequired,
};

const defaultProps = {
  wizard: {},
  activeStep: {},
  selectedStep: {},
  nextSteps: [],
  previousSteps: [],
  submitTaskInProgress: false,
  submitNoteInProgress: false,
  fetchInProgress: false,
  submitInProgress: false,
  notes: '',
  finished: false,
};

export const getStepType = (item) => {
  if (Array.isArray(item)) {
    return stepTypes.STATUS;
  }

  if (item.substatuses) return stepTypes.SUBSTATUS;
  if (item.answered) return stepTypes.START;
  return null;
};

class CommunicationFlow extends Component {
  componentDidMount() {
    this.updateNextSteps();
  }

  componentDidUpdate(prevProps) {
    const prevActiveStep = prevProps.activeStep;
    const { activeStep } = this.props;

    if (prevActiveStep !== activeStep) {
      this.updateNextSteps();
    }
  }

  onTaskSuccess = () => {
    const { actions, activeStep: { item }, nextSteps, notes } = this.props;
    
    if (_.isEmpty(nextSteps)) {
      if (_.isEmpty(notes)) {
        this.onEnd();
      } else {
        this.submitNote();
      }
      this.onEnd();
    } else {
      const selectedType = nextSteps[0].type;

      actions.setActiveStep({
        type: selectedType,
        item,
      });
    }
  }

  onNoteSuccess = () => {
    this.onEnd();
  }

  onEnd = async () => {
    const { previousSteps, activeStep, actions, user } = this.props;

    const payload = [...previousSteps, activeStep].reduce((acc, { selected, type }) => {
      if (selected) {
        return { ...acc, [`sales_${type}`]: selected.id };
      }

      return acc;
    }, {});

    const [err] = await to(actions.submitCommunicationWizard(user, payload).then(() => actions.finishWizard()));

    if (err) {
      this.onWizardError(err);
    } else {
      actions.fetchUser({ id: user.id });
    }
  }

  onWizardError = (payload) => {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  onSelect = (item) => {
    const { actions, activeStep } = this.props;
    
    if (activeStep.type === stepTypes.START) {
      const type = getStepType(item);
      const nextStep = {
        item,
        type,
      };
      actions.setActiveStep(nextStep);
    } else {
      actions.setActiveStep({ ...activeStep, selected: item }, true);
    }
  }

  onNext = () => {
    const { actions, activeStep: { selected, type }, nextSteps } = this.props;

    switch (type) {
      case stepTypes.STATUS: {
        const selectedType = getStepType(selected);
        actions.setActiveStep({
          type: selectedType,
          item: selected,
        });
        break;
      }
        
      case stepTypes.SUBSTATUS: {
        const selectedType = nextSteps[0].type;
        actions.setActiveStep({
          type: selectedType,
          item: selected,
        });
        break;
      }
        
      case stepTypes.TASK: {
        this.submitTask();
        break;
      }
      default:
        break;
    }
  }

  onBack = () => {
    const { actions } = this.props;
    actions.goStepBack();
  }

  onComplete = () => {
    const { activeStep: { type }, notes } = this.props;
    if (type === stepTypes.TASK) {
      this.submitTask();
    } else if (type === stepTypes.NOTE || !_.isEmpty(notes)) {
      this.submitNote();
    } else {
      this.onEnd();
    }
  }

  onRestart = () => {
    const { actions } = this.props;
    actions.resetWizard();
  }

  getTaskSubmit = (submit) => {
    this.submitTask = submit;
  }

  getNoteSubmit = (submit) => {
    this.submitNote = submit;
  }

  getStepType = (item) => {
    if (Array.isArray(item)) {
      return stepTypes.STATUS;
    }

    if (item.substatuses) return stepTypes.SUBSTATUS;
    if (item.answered) return stepTypes.START;
    return null;
  };


  updateNextSteps = () => {
    const { actions, activeStep } = this.props;
    const { type, selected, item } = activeStep;
    const nextStepsConf = {
      [stepTypes.STATUS]: [
        {
          type: stepTypes.SUBSTATUS,
        }, {
          type: stepTypes.UNDEFINED,
        },
      ],
      [stepTypes.SUBSTATUS]: [
        ...(!selected && item.substatuses && item.substatuses.length > 0 ? [{ type: stepTypes.UNDEFINED }] : []),
        ...(selected && selected.tasks ? [{ type: stepTypes.TASK }] : []),
        ...(selected && selected.notes ? [{ type: stepTypes.NOTE }] : []),
      ],
      [stepTypes.TASK]: [
        ...(item && item.notes ? [{ type: stepTypes.NOTE }] : []),
      ],
      [stepTypes.NOTE]: [],
    };
    
    const nextSteps = nextStepsConf[type];
    if (nextSteps) actions.setNextSteps(nextSteps);
  }

  render() {
    const { 
      activeStep, 
      user, 
      notes, 
      finished,
      submitTaskInProgress, 
      submitNoteInProgress,
      previousSteps,
      submitInProgress,
      fetchInProgress,
      sidepanelOptions,
      actions,
    } = this.props;

    const isLoading = submitTaskInProgress || submitNoteInProgress || submitInProgress;
    return (
      <Sidepanel
        onCloseIconClick={() => {
          if (finished) {
            actions.initWizard();
          }
        }}
        footerRender={() => activeStep && activeStep.type !== stepTypes.START && (
          <Navigation 
            {...this.props} 
            isLoading={isLoading} 
            finished={finished}
            notes={notes}
            previousSteps={previousSteps}
            onNext={this.onNext}
            onBack={this.onBack}
            onRestart={this.onRestart}
            onComplete={this.onComplete}
          />
        )}
        {...sidepanelOptions}
        className="communication-flow-sidepanel"
      >
        <div className={classNames('biq-communication-flow', `biq-communication-flow--${activeStep && activeStep.type}-step`)}>
          <div className="biq-communication-flow__wrapper">
            {fetchInProgress && (
            <div className="biq-communication-flow__step-container">
              <Spinner />
            </div>
            )}
            {!fetchInProgress && activeStep && (
            <Fragment>
              { activeStep.type !== stepTypes.START && <StepsIndicator {...this.props} />}
              { finished ? (
                <div className="biq-communication-flow__step-container">
                  <div className="biq-communication-flow__finished">
                    <Icon 
                      size="large" 
                      name="Checkmark" 
                      colorName="text-neutral-900" 
                    />
                    <span className="text-neutral-900 tc-heading-s">{text.FINISHED}</span>
                  </div>
                </div>
              ) : (
                <div className="biq-communication-flow__container">
                  <div className="biq-communication-flow__step-container">
                    <Step 
                      {...activeStep} 
                      onSuccess={this.onTaskSuccess}
                      onSelect={this.onSelect} 
                      getSubmit={submitTask => this.getTaskSubmit(submitTask)} 
                    />
                  </div>
                  { activeStep.type !== stepTypes.START && (
                    <div className="biq-communication-flow__notes">
                      <SendNote 
                        hideFooter 
                        onSuccess={this.onNoteSuccess}
                        user={user} 
                        getSubmit={submitNote => this.getNoteSubmit(submitNote)} 
                      />
                    </div>
                  )}
                </div>
              ) }
            </Fragment>
            )}
          </div>
        </div>
      </Sidepanel>
    );
  }
}

CommunicationFlow.propTypes = propTypes;
CommunicationFlow.defaultProps = defaultProps;

const mapStateToProps = ({ pages, forms }) => {
  const userPage = pages.USER_SINGLE;
  const page = userPage.CommunicationFlow;
  const taskForm = forms[TASK_FORM_KEY];
  const noteForm = forms[NOTE_FORM_KEY];
  return {
    ...page,
    submitTaskInProgress: taskForm.submitInProgress,
    submitNoteInProgress: noteForm.submitInProgress,
    notes: noteForm.values.content,
    user: userPage.userDetail.user,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    fetchCommunicationWizard, 
    submitCommunicationWizard,
    finishWizard,
    resetWizard,
    setActiveStep,
    initWizard,
    setNextSteps,
    goStepBack,
    fetchUser,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationFlow);
