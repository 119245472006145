import React, { Component } from 'react';
import { array, object, shape, string } from 'prop-types';
import { Card, Table, HyperLink, Icon, Popconfirm, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { generatePath, Link } from 'react-router-dom';

import connect from '../../../../../logic/connect';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { dateTimeFormatter } from '../../../../../logic/utilities/formatters';
import withdrawalStepStateLabel from '../../../../../logic/filters/withdrawal-step-state-label';
import WITHDRAWAL_STEP_STATE from '../../../../../logic/enums/withdrawal-step-state';
import { StateCell } from '../../../../../components/gridCellRenderers';
import { fetchWithdrawalFlow } from './Model';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const propTypes = {
  steps: array.isRequired,
  actions: object.isRequired,
  api: object.isRequired,
};

const actionedByPropTypes = {
  approved_by: object.isRequired,
};

const text = {
  WITHDRAWAL_FLOW: gettext('Withdrawal flow'),
  STEP_NAME: gettext('Step name'),
  ACTIONED_BY: gettext('Actioned by'),
  RESULT: gettext('Result'),
  DATE: gettext('Date stamp'),
  ACTIONS: gettext('Actions'),
  APPROVE_QUESTION: gettext('Are you sure you want to approve withdrawal step?'),
  REJECT_QUESTION: gettext('Are you sure you want to reject withdrawal step?'),
  CANCEL: gettext('Cancel'),
  APPROVE: gettext('Approve'),
  REJECT: gettext('Reject'),
  APPROVE_SUCCESS: gettext('Withdrawal step successfully approved'),
  APPROVE_ERROR: gettext('Error while approving withdrawal step'),
  REJECT_SUCCESS: gettext('Withdrawal step successfully rejected'),
  REJECT_ERROR: gettext('Error while rejecting withdrawal step'),
};

const cardStyle = {
  content: { padding: 0 },
};

const StepNameRenderer = ({ step_name }) => <span className="text-neutral-900">{step_name}</span>; // eslint-disable-line
const StatusRenderer = ({ status }) => <StateCell value={withdrawalStepStateLabel()(status)} />; // eslint-disable-line
const DateRenderer = ({ timestamp_update }) => <span className="text-neutral-900">{dateTimeFormatter(timestamp_update)}</span>; // eslint-disable-line

const ActionedByRenderer = ({ approved_by }) => approved_by && (
  <HyperLink>
    <Link to={generatePath(appRoutes.TEAM_MEMBER, { id: approved_by.id })}>
      {approved_by.username}
    </Link>
  </HyperLink>
);

ActionedByRenderer.propTypes = {
  approved_by: shape({
    id: string,
    username: string,
  }).isRequired,
};

const isPending = status => status === WITHDRAWAL_STEP_STATE.PENDING;

// eslint-disable-next-line react/destructuring-assignment
const ActionsRenderer = ({ approve, reject }) => step => isPending(step.status) && step.can_be_approved && (
  <div>
    <Popconfirm 
      icon="Approve" 
      label={text.APPROVE_QUESTION} 
      onConfirm={() => approve(step.id)} 
      buttonLabels={{ cancel: text.CANCEL, confirm: text.APPROVE }}
    >
      <span className="m-l-xs">
        <Icon name="Approve" colorName="text-status01-400" />
      </span>
    </Popconfirm>
    
    <Popconfirm 
      type="destructive" 
      icon="Reject" 
      label={text.REJECT_QUESTION} 
      onConfirm={() => reject(step.id)} 
      buttonLabels={{ cancel: text.CANCEL, confirm: text.REJECT }}
    >
      <span className="m-l">
        <Icon name="Reject" colorName="text-status04-400" />
      </span>
    </Popconfirm>
  </div>
);

const anyStepCanBeApproved = steps => !!_.find(steps, { can_be_approved: true });
const isAnyStepRejected = steps => !!_.find(steps, { status: WITHDRAWAL_STEP_STATE.REJECTED });

class WithdrawalFlow extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.api = props.api.all('approve_details');
  }

  componentDidMount() {
    this.actions.fetchWithdrawalFlow(this.api);
  }

  getTableCols = () => {
    const { steps } = this.props;

    return [
      { title: text.STEP_NAME, key: 'step_name', render: StepNameRenderer },
      { title: text.ACTIONED_BY, key: 'approved_by', render: ActionedByRenderer },
      { title: text.RESULT, key: 'status', render: StatusRenderer },
      { title: text.DATE, key: 'timestamp_update', render: DateRenderer },
      ...(anyStepCanBeApproved(steps) && !isAnyStepRejected(steps) ? [{
        title: text.ACTIONS, key: 'actions', render: ActionsRenderer({ approve: this.approve, reject: this.reject }),
      }] : []),
    ];
  }

  approve = (step) => {
    this.api.one(step).all('approve').create({})
      .then(() => {
        this.actions.fetchWithdrawalFlow(this.api);
        notifier.success(text.APPROVE_SUCCESS);
      }, ({ data }) => {
        notifier.error(data.message || text.APPROVE_ERROR);
      });
  }

  reject = (step) => {
    this.api.one(step).all('reject').create({})
      .then(() => {
        this.actions.fetchWithdrawalFlow(this.api);
        notifier.success(text.REJECT_SUCCESS);
      }, ({ data }) => {
        notifier.error(data.message || text.REJECT_ERROR);
      });
  }

  render() {
    const { steps } = this.props;
    if (!steps.length) return null;

    return (
      <Card title={text.WITHDRAWAL_FLOW} style={cardStyle}>
        <Table cols={this.getTableCols(steps)} data={steps} />
      </Card>
    );
  }
}

const mapStateToProps = ({ pages }) => pages.PENDING_WITHDRAWAL.withdrawalFlow;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { 
      fetchWithdrawalFlow,
    },
    dispatch,
  ),
});


WithdrawalFlow.propTypes = propTypes;
ActionedByRenderer.propTypes = actionedByPropTypes;
export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalFlow);
