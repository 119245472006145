import React, { Component } from 'react';
import { Dropdown, Button } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { object } from 'prop-types';

import overlayActions from '../../../../components/overlay';
import connect from '../../../../logic/connect';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import { gettext } from '../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../logic/services/acl';

const propTypes = {
  actions: object.isRequired,
};

export const ADD_LEAD_MODAL_ID = 'ADD_LEAD_MODAL';
export const ADD_LEAD_BULK_MODAL_ID = 'ADD_LEAD_BULK_MODAL';
const text = {
  ADD_LEADS: gettext('Add leads'),
  SINGLE: gettext('Single lead'),
  BULK: gettext('Bulk import'),
};

const customList = openSidepanel => [
  ...(hasAccess('leads.create.*') ? [{
    item: () => (
      <li
        key={ADD_LEAD_MODAL_ID}
        className="dropdown-list-item"
        onClick={() => openSidepanel(ADD_LEAD_MODAL_ID)}
      >
        {text.SINGLE}
      </li>
    ),
  }] : []),
  ...(hasAccess('leads.import.upload') ? [{
    item: () => (
      <li
        key={ADD_LEAD_BULK_MODAL_ID}
        className="dropdown-list-item"
        onClick={() => openSidepanel(ADD_LEAD_BULK_MODAL_ID)}
      >
        {text.BULK}
      </li>
    ),
  }] : []),
];

const headerButton = toggleAction => (
  <Button
    key="add-leads"
    icon="CaretDown"
    iconPosition="right"
    color="confirmation"
    onClick={toggleAction}
  >
    {text.ADD_LEADS}
  </Button>
);

class AddLeads extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  render() {
    return (
      <Dropdown
        openLeft
        customHeader={headerButton}
        customList={customList(this.actions.openSidepanel)}
      />
    );
  }
}

AddLeads.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default connect(
  null,
  mapDispatchToProps,
)(withErrorBoundary(AddLeads));
