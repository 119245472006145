import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import IB_STATE from '../../../../../../../logic/enums/ib-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionTypes from '../types';
import Reject from './Reject';

const text = {
  LABEL: gettext('Reject'),
  TITLE: user => gettext('Reject user {{first_name}} {{last_name}}', user),
};

const ACTION_ICON = 'Reject';

export default {
  name: 'reject',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    if (isIb) {
      return !user.is_archived && hasAccess('ib.reject.*') && state === IB_STATE.PENDING;
    } 
    const hasStatePermission = [
      USER_STATE.PENDING,
      USER_STATE.REVIEW,
    ].includes(state);
    return hasAccess('user.reject.*') && hasStatePermission;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.TITLE(user),
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(Reject, sidepanelOptions);
  },
};
