import { bindActionCreators } from 'redux';

import LinksTable from './LinksTable';
import connect from '../../../../../../logic/connect';
import overlayActions from '../../../../../../components/overlay';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ), 
  dispatch,
});

export default connect(null, mapDispatchToProps)(LinksTable);
