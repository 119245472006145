import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { notifier } from 'tc-biq-design-system';
import { generatePath } from 'react-router-dom';
import { func } from 'prop-types';
import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import modifiers from './modifiers';
import { hasAccess } from '../../../../../../logic/services/acl';
import connect from '../../../../../../logic/connect';
import to from '../../../../../../logic/utilities/to';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

const text = {
  TOGGLE_ENABLE_MESSAGE: 'Withdrawal amounts rule enabled',
  TOGGLE_DISABLE_MESSAGE: 'Withdrawal amounts rule disabled',
};

const gridConfig = () => ({
  apiUrl: 'settings/withdrawalable_amount_rules',
  reducerKey: 'DEPOSITS',
  tableKey: 'WITHDRAWABLE_AMOUNTS_RULES',
});

class WithdrawableAmounts extends Component {
  constructor({ dispatch }) {
    super();
    this.Grid = withTable(GridTab, gridConfig, null, { withActions: true })();

    const [, tableActions] = this.Grid;
    this.actions = {
      ...bindActionCreators({
        ...tableActions,
      }, dispatch),
    };
    this.tableActions = {
      toggleEnable: hasAccess('settings.manage_withdrawalable_amount_rules.update') && this.toggleEnable,
      onDelete: hasAccess('settings.manage_withdrawalable_amount_rules.destroy') && this.onDelete,
      onModify: hasAccess('settings.manage_withdrawalable_amount_rules.update') && this.onModify,
      onClone: hasAccess('settings.manage_withdrawalable_amount_rules.create') && this.onClone,
    };
  }

  onDelete = (data) => {
    this.actions.deleteRow(data);
  }

  onModify = data => generatePath(appRoutes.SETTINGS_WITHDRAWABLE_AMOUNTS_EDIT, { id: data.id })

  onClone = data => generatePath(appRoutes.SETTINGS_WITHDRAWABLE_AMOUNTS_CREATE, { id: data.id })

  toggleEnable = async ({ id }, is_enabled) => {
    const [err] = await to(this.actions.updateRowData(id, { is_enabled }));
    if (err) return notifier.error(err.data.msg);
    notifier.success(is_enabled ? text.TOGGLE_ENABLE_MESSAGE : text.TOGGLE_DISABLE_MESSAGE);
  }

  render() {
    const [GridComponent] = this.Grid;

    return (
      <GridComponent singleActions={!!Object.keys(this.tableActions).length} modifier={modifiers(this.tableActions)} />
    );
  }
}


WithdrawableAmounts.propTypes = {
  dispatch: func.isRequired,
};
const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(WithdrawableAmounts);
