import currencyFormater from '../utilities/formatters/currency'

export function tradingAccountDisplay(account, hideTypeCurrency, withBalance) {
  if (account) {
    const currency = getCurrency(account, hideTypeCurrency);
    let balance = '';
    if(withBalance) {
      balance = account.balance ? account.balance.WithdrawableAmount : "";
    }
    
    return `${getId(account)}, ${currency && currency + ', '}${getBackend(account, hideTypeCurrency)}${balance && ', ' + currencyFormater(balance, currency)}`;
  } 
  return '';
}

export function getId(account = {}) {
  let id = account.display_external_id || account.external_id;
  if (account.backend && account.backend.type && account.backend.type === 'cloudtrade') {
    if (account.meta && account.meta.Alphacode) id = account.meta.Alphacode;
  }

  return id || '';
}

export function getCurrency(account, hideTypeCurrency) {
  return (account && account.currency && !hideTypeCurrency) ? (account.currency.symbol || '') : ''
}

export function getBackend(account, hideTypeCurrency) {
  let backend = '';
  if (account && account.backend && account.backend.name) {
    backend = account.backend.name;
    if (!hideTypeCurrency) {
      backend += ` - ${account.backend.mode}`;
    }
  }
  return backend;
}
