import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { object } from 'prop-types';

import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import AssignManager from '../../../../../../../components/AssignManager';

const MANAGER_PROPERTY = 'retention_manager';

const propTypes = {
  user: object,
  actions: object.isRequired,
};

const defaultProps = {
  user: null,
};

const text = {
  SALES_MANGER: gettext('Retention Manager'),
  GROUP_USERS: gettext('Users'),
  GROUP_TEAMS: gettext('Teams'),
  GROUP_DEFAULT: gettext('Default'),
  NONE: gettext('None'),
  SUCCESS_MESSAGE: gettext('Retention manager successfully updated'),
};

class RetentionManagerController extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  onSuccess = () => {
    const { user } = this.props;
    this.actions.fetchUser(user);
  }

  render() {
    return (
      <AssignManager 
        {...this.props} 
        text={text} 
        managerProperty={MANAGER_PROPERTY} 
        onSuccess={this.onSuccess} 
      />
    );
  }
}

RetentionManagerController.propTypes = propTypes;
RetentionManagerController.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const { user } = page.userDetail;
  return {
    user,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetentionManagerController);
