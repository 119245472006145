import React, { Component } from 'react';
import { object, node, func } from 'prop-types';
import { Button, Select } from 'tc-biq-design-system';
import moment from 'moment';

import PRIMITIVE_TYPE from '../../../../../logic/enums/primitive-type';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import session from '../../../../../logic/services/session';
import currencyFormater from '../../../../../logic/utilities/formatters/currency';
import GraphCard from './GraphCard';

import './CampaignStat.scss';

const DATE_FORMAT = 'YYYY-MM-DD';

const filters = {
  SIGNUPS: 'signups',
  VISITS: 'visits',
  CONVERSION: 'conversion',
  DEPOSITS: 'deposits',
  FTDS: 'ftds',
  REVENUE: 'revenue',
  PROFIT: 'profit',
  COST: 'cost',
};

const filterKeys = Object.values(filters);

const text = {
  ALL: gettext('All campaigns'),
  SEARCH: gettext('Search for a campaign'),
  FILTER_TITLES: {
    [filters.SIGNUPS]: gettext('Signups'),
    [filters.VISITS]: gettext('Visits'),
    [filters.CONVERSION]: gettext('Conversion'),
    [filters.DEPOSITS]: gettext('Deposits'),
    [filters.FTDS]: gettext('Ftds'),
    [filters.REVENUE]: gettext('Revenue'),
    [filters.PROFIT]: gettext('Profit'),
    [filters.COST]: gettext('Costs'),
  },
};

const apiCampaign = () => getDjangoApi('team/campaigns');

const apiSingleCampaign = campaign => getDjangoApi(`team/profile/${campaign.user.id}/campaigns/${campaign.id}`);

const propTypes = {
  date: object.isRequired,
  dateFilterComponent: node.isRequired,
  setOnDateChangeHandler: func.isRequired,
};

class CampaignStat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCampaign: null,
      filter: null,
    };
  }

  componentDidMount() {
    this.loadFilterData();
    const { setOnDateChangeHandler } = this.props;
    setOnDateChangeHandler(this.loadFilterData);
  }

  onAllSearch = () => {
    this.setState({ selectedCampaign: null }, this.loadFilterData);
  }

  onCampaignSearch = (camapign) => {
    this.setState({ selectedCampaign: camapign }, this.loadFilterData);
  }

  getFilters = (...args) => {
    const { filter } = this.state;
    if (!filter) return [];
    return args.filter(arg => filter[arg] !== undefined).map((name) => {
      const value = filter[name] || 0;
      const type = this.getType(name);
      const unit = this.getUnit(type);
      // eslint-disable-next-line no-nested-ternary
      const format = type === PRIMITIVE_TYPE.DOUBLE 
        ? currencyFormater(value, unit) 
        : `${value}${unit}`; 
      return {
        key: name,
        value: format || '-', 
        perDay: filter[`${name}_per_day`],
        title: text.FILTER_TITLES[name],
      };
    });
  }

  getType = (statKey) => {
    switch (statKey) {
      case 'revenue':
      case 'cost':
      case 'profit':
        return PRIMITIVE_TYPE.DOUBLE;
      case 'conversion':
        return 'percent';
      case 'deposits':
      case 'ftds':
      case 'leads':
      case 'signups':
      case 'visits':
      default:
        return PRIMITIVE_TYPE.INTEGER;
    }
  }

  getUnit = (type) => {
    switch (type) {
      case PRIMITIVE_TYPE.DOUBLE:
        return session.baseCurrency.symbol;
      case 'percent':
        return '%';
      case PRIMITIVE_TYPE.INTEGER:
      default:
        return '';
    }
  }

  /**
   * End date is excluded so we need to add one day
   */
  modifyEndDate = endDate => moment(endDate, DATE_FORMAT).add(1, 'd').format(DATE_FORMAT)

  loadFilterData = () => {
    const { selectedCampaign } = this.state;
    const { date: { startDate, endDate } } = this.props;
    const api = selectedCampaign ? apiSingleCampaign(selectedCampaign) : apiCampaign().one('all');
    api.list({
      period_from: startDate, 
      period_to: this.modifyEndDate(endDate),
    }).then(({ data }) => {
      this.setState({ filter: data });
    });
  }

  loadOptions(input = '') {
    return new Promise(resolve => apiCampaign()
      .list({ name: input })
      .then(res => resolve(res.data.results)),
    );
  }

  render() {
    const { selectedCampaign } = this.state;
    const { dateFilterComponent } = this.props;
    const filterData = this.getFilters(...filterKeys) || [];
    return (
      <div className="campaign-stat">
        <div className="campaign-stat__filter">  
          <div className="campaign-stat__search">
            <Button onClick={this.onAllSearch}>{text.ALL}</Button>
            <Select
              async
              clearable={false}
              placeholder={text.SEARCH}
              type="search"
              onChange={this.onCampaignSearch}
              value={selectedCampaign}
              debounceInterval={500}
              loadOptions={this.loadOptions}
              valueKey="id"
              labelKey="name"
            />
          </div>
          { dateFilterComponent }
        </div>
        <div className="campaign-stat__cards">
          {filterData.map(filter => (
            <div key={filter.key} className="campaign-stat__card">
              <GraphCard 
                title={filter.title}
                data={filter.perDay}
                value={filter.value}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

CampaignStat.propTypes = propTypes;

export default CampaignStat;
