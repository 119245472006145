/*
  This litle utility function help us to handle async await errors
  So we don't need to use try/catch block

  Example:
  [err, user] = await to(api.get(1));
  if(err) // handle error
*/
export default function to(promise) {
  return promise.then(data => [null, data]).catch(err => [err]);
}
