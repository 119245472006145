import React from 'react';
import { string } from 'prop-types';

import IconCell from './IconCell';

const currencies = {
  BTC: 'BTC',
  EUR: 'EUR',
  GBP: 'GBP',
  USD: 'USD',
  JPY: 'JPY',
};

const getMapping = (currency) => {
  switch (currency) {
    case currencies.USD: {
      return {
        colorName: 'text-status01-600',
        bgColorName: 'bck-status01-100',
        name: currency,
      };
    }
    case currencies.EUR: {
      return {
        colorName: 'text-primary-600',
        bgColorName: 'bck-primary-100',
        name: currency,
      };
    }
    case currencies.GBP: {
      return {
        colorName: 'text-status02-600',
        bgColorName: 'bck-status02-100',
        name: currency,
      };
    }
    case currencies.JPY: {
      return {
        colorName: 'text-status03-600',
        bgColorName: 'bck-status03-100',
        name: currency,
      };
    }
    default: {
      return {};
    }
  }
};

const propTypes = {
  value: string,
};

const defaultProps = {
  value: '',
};

const CurrencyCell = ({ value }) => <IconCell displayName={value} {...getMapping(value)} />;

CurrencyCell.propTypes = propTypes;
CurrencyCell.defaultProps = defaultProps;

export default CurrencyCell;
