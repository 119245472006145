export default {
  CREDIT_CARD  : 1,
  BANK_TRANSFER: 350,
  EMONEY: 450,
  WEBMONEY: 317,
  BANK_TRANSFER_WIDR: 21,
  BANK_TRANSFER_WIBC: 22,
  CASH_BONUS: 1000,
  VG_TRANSFER: 2000,
};
