/* eslint react/prop-types: 0 */
import React, { PureComponent } from 'react';
import { Button, notifier } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';

import Page from '../../../../../components/Page';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import GeneralForm, { FORM_ID } from './GeneralForm';
import generalSettingsActions, { settingsApi } from './Model';
import connect from '../../../../../logic/connect';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import { FormActionsFactory } from '../../../../../components/form';
import appRoutes from '../../../../../components/App/Router/appRoutes';

import './General.scss';

const text = {
  PAGE_TITLE: gettext('General'),
  SAVE: gettext('Save'),
  SUCCESS: gettext('General settings successfully updated'),
};
const { create } = FormActionsFactory(FORM_ID);
const bread = [{ label: text.PAGE_TITLE, route: appRoutes.SETTINGS_GENERAL }];

const customFormatter = data => (values) => {
  const payload = {};
  const valuesKeys = Object.keys(values);
  valuesKeys
    .filter((key) => {
      const initialData = data.find(element => element.key === key);
      return initialData[key] !== values[key];
    })
    .forEach((key) => {
      payload[key] = values[key];
    });

  if (payload.INTERNAL_TRANSFER_DISABLED_FOR_BACKENDS) {
    const formattedData = payload.INTERNAL_TRANSFER_DISABLED_FOR_BACKENDS.map(
      item => item.name,
    ).join(',');
    if (formattedData === data.INTERNAL_TRANSFER_DISABLED_FOR_BACKENDS) delete payload.INTERNAL_TRANSFER_DISABLED_FOR_BACKENDS;
  }
  return payload;
};
class General extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  submit = () => {
    const { data } = this.props;
    this.actions.create(settingsApi, customFormatter(data)).then(
      () => {
        this.actions.getData();
        notifier.success(text.SUCCESS);
      });
  };

  render() {
    const { submitInProgress } = this.props;
    return (
      <Page bread={bread} title={text.PAGE_TITLE}>
        <div className="biq-settings-general">
          <div className="biq-settings-general__wrapper">

            <GeneralForm />
            <div className="submit">
              <Button
                loading={submitInProgress}
                disabled={submitInProgress}
                onClick={this.submit}
                type="submit"
                formId={FORM_ID}
              >
                {text.SAVE}
              </Button>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      create,
      getData: generalSettingsActions.getData,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages, forms }) => {
  const { data } = pages.SETTINGS_GENERAL;
  const { submitInProgress } = forms[FORM_ID];
  return { data, submitInProgress };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(General);
