import { createStore, compose, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import reducers from './reducers';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [promiseMiddleware()];

if (process.env.NODE_ENV === 'development' || window.CONFIG?.indexOf('alpha.avatrade.com') > -1) {
  const { logger } = require('redux-logger');
  middlewares.push(logger);
}

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middlewares)),
);

export const getStore = () => store;
export default store;
