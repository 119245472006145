import React, { Component } from 'react';
import { object, bool, func } from 'prop-types';
import { Space, Sidepanel, notifier } from 'tc-biq-design-system';

import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import FormFactory from '../../../../../../../components/form';
import to from '../../../../../../../logic/utilities/to';

export const SIDEPANEL_ID = 'ADD_METHOD_FORM';

const text = {
  TITLE: gettext('Add new mapping'),
  METHOD: gettext('Method'),
  GATEWAY: gettext('Gateway'),
  SUCCESS_MESSAGE: gettext('Gateway and method linked successfully'),
  ERROR_MESSAGE: gettext('Error while linking gateway and method'),
  button: {
    confirm: gettext('Save'),
    cancel: gettext('Cancel'),
  },
};

const api = getDjangoApi('payment_gateways');

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.button}
  />
);

const propTypes = {
  actions: object,
  sidepanel: object.isRequired,
  submitInProgress: bool,
  onSuccess: func,
};

const defaultProps = {
  actions: null,
  submitInProgress: false,
  onSuccess: null,
};

class Confirmation extends Component {
  onSubmit = async () => {
    const { sidepanel, actions } = this.props;
    const { parameters } = sidepanel;
    const { gateway } = parameters;
    const getPayload = ({ method }) => ({
      action: 'link',
      payment_method: method.id,
    });

    const request = api
      .one(gateway.id)
      .all('methods');

    const [err, res] = await to(actions.create(request, getPayload));
    if (err) notifier.error(text.ERROR_MESSAGE);
    if (res) this.onSuccess();
  }

  onSuccess = () => {
    const { onSuccess } = this.props;
    if (onSuccess) onSuccess();
    notifier.success(text.SUCCESS_MESSAGE);
    this.onClose();
  }

  onClose = () => {
    const { actions } = this.props;
    actions.closeSidepanel(SIDEPANEL_ID);
    this.FormComponent = null;
  }

  loadOptions = (input = '') => getDjangoApi('autocomplete/payment_methods')
    .list({ name: input })
    .then((res) => {
      const { sidepanel } = this.props;
      const { parameters: { gateway } } = sidepanel;
      const filterMethods = method => !gateway.methods.includes(method.name);
      return res.data.results.filter(filterMethods);
    })

  formConfig = () => ({
    form: SIDEPANEL_ID,
    customFields: [
      {
        id: 'name',
        name: 'name',
        label: text.GATEWAY,
        type: 'text',
        disabled: true,
      },
      {
        id: 'method',
        name: 'method',
        type: 'select',
        label: text.METHOD,
        placeholder: text.METHOD,
        loadOptions: this.loadOptions,
        debounceInterval: 500,
        async: true,
        joinValues: true,
        labelKey: 'name',
        valueKey: 'id',
      },
    ],
  });

  renderForm = () => {
    if (!this.FormComponent) {
      const { sidepanel, actions } = this.props;
      const { parameters } = sidepanel;
      
      if (parameters) {
        const { gateway } = parameters;
        actions.setRowData(gateway);
        this.FormComponent = FormFactory(this.formConfig());
      }
    }

    const { FormComponent } = this;
    return FormComponent && <FormComponent />;
  }

  render() {
    const { sidepanel, submitInProgress } = this.props;
    const { visible } = sidepanel || {};
  
    return (
      <Sidepanel 
        title={text.TITLE}
        footerRender={customFooter(this.onSubmit, this.onClose, submitInProgress)}
        visible={visible}
        onCloseIconClick={this.onClose}
      >
        <Space size={16} />
        { this.renderForm() }
      </Sidepanel>
    );
  }
}

Confirmation.propTypes = propTypes;
Confirmation.defaultProps = defaultProps;

export default Confirmation;
