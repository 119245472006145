import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { object, bool } from 'prop-types';
import { Pill, InfoBox, Row, Space, Spinner } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import connect from '../../../../../../logic/connect';
import { fetchCampaign } from './Model';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

import './Campaign.scss';

const text = {
  ACTIVE: gettext('Active'),
  NO_CAMPAIGN: gettext('No campaign available'),
};

const propTypes = {
  actions: object.isRequired,
  user: object.isRequired,
  campaign: object.isRequired,
  campaignInProgress: bool.isRequired,
};

class Campaign extends Component {
  componentDidMount() {
    const { actions, user } = this.props;
    actions.fetchCampaign(user);
  }
  
  render() {
    const { campaign, campaignInProgress } = this.props;
    const isEmptyData = _.isEmpty(campaign);
    const showSpinner = campaignInProgress;
    const showInfo = isEmptyData && !showSpinner;
    const showCampaign = !isEmptyData && !showSpinner;
    
    return (
      <Fragment>
        <Space size={10} />
        {showSpinner && (
          <Row style={{ justifyContent: 'center' }}>
            <Space size={30} />
            <Spinner />
          </Row>
        )}
        {showInfo && (
          <InfoBox>{text.NO_CAMPAIGN}</InfoBox>
        )}
        {showCampaign && (
          <Link to={generatePath(appRoutes.CAMPAIGN, { id: campaign.id })} className="biq-campaign">
            <div className="biq-campaign__text">
              <span className="text-primary-500 tc-heading-s">{campaign.name}</span>
              {campaign.description && (
                <p className="biq-campaign__description text-neutral-900 tc-micro-regular">{campaign.description}</p>
              )}
            </div>
            {campaign.active && (
              <div className="biq-campaign__pill">
                <Pill type="status01">{text.ACTIVE}</Pill>
              </div>
            )}
          </Link>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { USER_SINGLE } = pages;
  const { campaign, userDetail: { user } } = USER_SINGLE;

  return {
    ...campaign,
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    fetchCampaign,
  }, dispatch),
});

Campaign.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
