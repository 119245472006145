import React from 'react';
import { array, func } from 'prop-types';
import { Space, Icon } from 'tc-biq-design-system';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

const ValueCard = sortableElement(({ value, toggle }) => (
  <div className="sort-box" key={value.key} style={{ zIndex: '10000' }}>
    <Icon name="Burger" size="small" />
    <span className="sort-box-label">{value.label}</span>
    <span onClick={() => toggle(value.key)} className="sort-box-close">
      <Icon name="Close" size="small" />
    </span>
  </div>
));

const ColumnsList = sortableContainer(({ children }) => <div>{children}</div>);

const propTypes = {
  values: array,
  onSortChange: func.isRequired,
  toggle: func.isRequired,
};

const defaultProps = {
  values: [],
};

const SortableBox = ({ values, toggle, onSortChange }) => (
  <div className="columns" id="sortable">
    <Space size={5} />
    <ColumnsList getContainer={() => document.getElementById('sortable')} onSortEnd={onSortChange}>
      {values.map((value, index) => (
        <ValueCard value={value} key={`item-${value.key}`} index={index} toggle={toggle} />
      ))}
    </ColumnsList>
  </div>
);

SortableBox.propTypes = propTypes;
SortableBox.defaultProps = defaultProps;
export default SortableBox;
