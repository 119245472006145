import React from 'react';
import { arrayOf, shape, string, number, func, any } from 'prop-types';
import { Radio } from 'tc-biq-design-system';

const option = shape({
  value: number.isRequired,
  text: string.isRequired,
});

const propTypes = {
  options: arrayOf(option),
  id: string.isRequired,
  onChange: func.isRequired,
  transformValue: func,
  actionComponent: any,
};

const defaultProps = {
  options: [],
  transformValue: val => val,
  actionComponent: null,
};

const renderButtons = options => options.map(({ value, text }) => (
  <Radio key={value} value={value}>{text}</Radio>
));

const RadioGroupField = (props) => {
  const { options, onChange, id, transformValue, actionComponent } = props;
  return (
    <div className="biq-form__field">
      <Radio.Group {...props} name={id} onChange={e => onChange({ value: transformValue(e.target.value), id })}>
        {renderButtons(options)}
      </Radio.Group>
      {actionComponent}
    </div>
  );
};

RadioGroupField.propTypes = propTypes;
RadioGroupField.defaultProps = defaultProps;
export default RadioGroupField;
