import isEmpty from 'lodash/isEmpty';
import COUNTRY_CURRENCY_MAP from '../../../../../../logic/enums/country-currency-map';

export const extractError = (err = {}) => {
  const result = {};
  const error = _.get(err, 'data', {});
  if (error.execute_instructions) {
    const validError = error.execute_instructions.find(execute_instruction => !isEmpty(execute_instruction));
    if (typeof validError === 'string') {
      result.error = (validError);
    } if (validError.non_field_errors) {
      result.error = (validError.non_field_errors[0]);
    }
  } else if (error.message || error.external_id) {
    const msg = error.message ? error.message : `External id: ${error.external_id}`;
    result.error = msg;
  } else if (error.converted_amount) {
    result.error = (error.converted_amount[0]);
  } else if (!error.amount && !error.gateway) {
    result.error = error;
  }

  if (error.warning) {
    result.warning = (error.warning);
  }

  return result;
};

export const extractSuccessMessage = (data) => {
  const result = {};

  if (data.status && data.status === 'Withdrawal rejected') {
    result.error = data.status;
  } else if (_.get(data, 'status') === 'Withdrawal processing' || _.get(data, 'status') === 'Withdrawal partially accepted' || _.get(data, 'status') === 'Withdrawal incomplete') {
    result.warning = data.status;
  } else {
    result.success = data.status;
  }

  if (data.warning) {
    result.extraWarning = data.warning;
  }

  return result;
};

export const isCreditCardSelected = (props) => {
  const selectedMethod = _.get(props, 'values.method.display_name');
  return selectedMethod === 'Credit Card';
};

export const formatExecuteInstructionsData = (data, { is_automatic_withdrawal_allowed }) => data.map(row => ({
  id: row.id,
  refund_amount: Number(row.refund_amount) || 0,
  payout_amount: Number(row.payout_amount) || 0,
  ...!is_automatic_withdrawal_allowed && { payout_acquirer_external_id: row.payout_acquirer_external_id || undefined },
  ...!is_automatic_withdrawal_allowed && { refund_acquirer_external_id: row.refund_acquirer_external_id || undefined },
}));

export const formatRequestData = ({ conversionData, isConversionRequired, withdrawal, executeInstructionsData, payload }) => {
  const { amount, gateway, method, transaction_external_id, transaction_acquirer_external_id, external_id, acquirer } = payload;

  return {
    amount,
    ...(gateway && { gateway: gateway.value }),
    ...(method && { method: method.value }),
    ...(acquirer && { acquirer: acquirer.value }),
    ...(transaction_external_id && { transaction_external_id }),
    ...(transaction_acquirer_external_id && { transaction_acquirer_external_id }),
    ...(external_id && { external_id }),
    ...(isConversionRequired && conversionData ? {
      converted_amount: conversionData.convertedAmount,
      exchange_rate: conversionData.exchangeRate,
      original_exchange_rate: conversionData.originalExchangeRate,
      withdrawal_currency: _.get(conversionData, 'convertCurrency.symbol'),
    } : {}),
    execute_instructions: formatExecuteInstructionsData(executeInstructionsData, withdrawal),
  };
};

export const getAcquiererOptions = ({ fields, gatewayId }) => {
  const selected = fields.find(field => field.id === 'acquirer');
  if (!selected) return;
  const { acquirers_per_gateway, options } = selected;
  const optionIds = acquirers_per_gateway[gatewayId];
  return optionIds ? options.filter(option => optionIds.includes(option.value)) : [];
};

export const getGatewayOption = ({ fields, paymentMethodId }) => {
  const selected = fields.find(field => field.id === 'gateway');
  if (!selected) return;

  const { gateways_per_method, options } = selected;
  const optionIds = gateways_per_method[paymentMethodId];
  return optionIds ? options.filter(option => optionIds.includes(option.value)) : [];
};

export const notifySuccess = (data, notifier) => {
  const message = extractSuccessMessage(data);
  if (message.error) notifier.error(message.error);
  if (message.warning) notifier.warning(message.warning);
  if (message.extraWarning) notifier.warning(message.extraWarning);
  if (message.success) notifier.success(message.success);
};

export const notifyError = (error, notifier) => {
  const message = extractError(error);

  if (message.error) notifier.error(message.error);
  if (message.warning) notifier.warning(message.warning);
};

export const getPaymentGatewayMethodUrlId = (gatewayMethod) => {
  switch (gatewayMethod) {
    case 'Bank':
      return 'bank_accounts';
    case 'Credit Card':
      return 'cards';
    case 'M-PESA':
    case 'MPESA':
      return '';
    default:
      return 'ewallets';
  }
};

export const isRefundFullyUtilized = cellData => _.get(cellData, 'refund_info').refund_fully_utilized;

export const isPayoutFullyUtilized = cellData => _.get(cellData, 'payout_info').payout_fully_utilized;

export const isPayoutExpired = cellData => _.get(cellData, 'payout_info').payout_expired;

export const isRefundExpired = cellData => _.get(cellData, 'refund_info').refund_expired;

export const isRefundOrPayoutLeft = cellData => !isRefundFullyUtilized(cellData) || !isPayoutFullyUtilized(cellData);

export const isRefundOrPayoutExpired = cellData => isPayoutExpired(cellData) || isRefundExpired(cellData);

export const isRefundAndPayoutExpired = cellData => isPayoutExpired(cellData) && isRefundExpired(cellData);

const enforceNumber = (row) => {
  row.refund_amount = Number(row.refund_amount) || 0;
  row.initial_refund_amount = Number(row.initial_refund_amount) || 0;
  row.payout_amount = Number(row.payout_amount) || 0;
  row.initial_payout_amount = Number(row.initial_payout_amount) || 0;

  return row;
};

export const transformPayoutData = (row) => {
  const result = enforceNumber(row);
  if (isRefundFullyUtilized(result)) return result;

  return { ...result, payout_amount: 0 };
};

export const handlePayoutValue = (nextRow, prevRowValue) => {
  if (+prevRowValue.payout_amount === 0 && +nextRow.payout_amount === 0 && isRefundFullyUtilized(nextRow)) {
    nextRow.payout_amount = nextRow.initial_payout_amount;
  }
  return nextRow;
};

export const getConvertedAmountLimits = (amount, percentage = 3) => {
  const amountNumber = +amount;
  if (!_.isNumber(amountNumber)) return {};

  const ratio = percentage / 100;
  const maxRatio = 1 + ratio;
  const minRatio = 1 - ratio;

  const max = (Math.floor(amountNumber * maxRatio * 100) / 100);
  const min = (Math.ceil(amountNumber * minRatio * 100) / 100);

  return { min, max };
};

export const isConvertedAmountValid = (convertedAmount, convertedAmountLimits) => {
  const { min, max } = convertedAmountLimits;
  return (convertedAmount >= min || !min) && (convertedAmount <= max || !max);
};

export const getDefaultCurrency = ({ user, currencies, isCredit }) => {
  const defaultCurrency = _.get(currencies, '[0]');
  const countryCode = user.addr_country 
    ? user.addr_country.iso_code 
    : user.country.iso_code;
  
  const countryCurrencyCode = COUNTRY_CURRENCY_MAP[countryCode];
  const countryCurrency = countryCurrencyCode && currencies.find(choice => choice.symbol === countryCurrencyCode);

  return !isCredit && countryCurrency 
    ? countryCurrency 
    : defaultCurrency;
};
