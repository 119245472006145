import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import ForgotPasswordForm, { FORM_KEY } from './ForgotPasswordForm';
import connect from '../../../../logic/connect';
import { FormActionsFactory } from '../../../../components/form';

const formActions = FormActionsFactory(FORM_KEY);

const mapStateToProps = ({ forms }) => ({ ...forms[FORM_KEY] });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...formActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPasswordForm));
