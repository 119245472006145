/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import { getDjangoApi } from '../../../../logic/services/api-factory';
import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import overlayActions from '../../../../components/overlay';
import Page from '../../../../components/Page';
import Export from '../../../../components/Export';
import modifier from './modifiers';
import IbAssignSalesManager from './sidepanels/IbAssignSalesManager';
import IbEditTags from './sidepanels/IbEditTags';
import IbEditSalesStatuses from './sidepanels/IbEditSalesStatuses';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import { hasAccess } from '../../../../logic/services/acl';
import If from '../../../../components/If';
import { UserPreview, BiqUserPreview } from '../../../../components/common/sidepanels';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import isFeatureEnabled from '../../../../logic/filters/is-feature-enabled';
import IbEditComplianceStatuses from './sidepanels/IbEditComplianceStatuses';
import appRoutes from '../../../../components/App/Router/appRoutes';


const pageConfig = {
  apiUrl: 'ib',
  reducerKey: 'IB_LIST',
  tableKey: 'IB_LIST_TABLE',
};

const { GridComponent, actions } = GridFactory(pageConfig);
const env = window.config.environment;
const text = {
  PAGE_LABEL: env === 'ICMCapital' ? gettext('Marketing agent list') : gettext('IB list'),
  EDIT_TAGS_LABEL: gettext('Edit tags'),
  EDIT_SALES_STATUTS_LABEL: gettext('Edit sales status'),
  EDIT_COMPLIANCE_STATUTS_LABEL: gettext('Edit compliance status'),
  ASSIGN_SALES_MANAGERS_LABEL: gettext('Assign Sales Manager'),
};
const bread = [{ label: text.PAGE_LABEL, route: appRoutes.IB_LIST }];

const bulkActions = onClick => [
  {
    icon: 'Tag',
    label: text.EDIT_TAGS_LABEL,
    onClick: data => onClick(data, 'EDIT_TAGS'),
  },
  ...((hasAccess('sales_status.bulk_set.create') || hasAccess('sales_status.bulk_set.update')) ? [{
    icon: 'Edit',
    label: text.EDIT_SALES_STATUTS_LABEL,
    onClick: data => onClick(data, 'EDIT_SALES_STATUSES'),
  }] : []),
  ...((hasAccess('compliance_status.bulk_set.create') || hasAccess('compliance_status.bulk_set.update')) 
    && isFeatureEnabled()('COMPLIANCE_STATUS_ENABLED') ? [{
      icon: 'Edit',
      label: text.EDIT_COMPLIANCE_STATUTS_LABEL,
      onClick: data => onClick(data, 'EDIT_COMPLIANCE_STATUSES'),
    }] : []),
  ...((hasAccess('team.ibs_assign.create') || hasAccess('team.ibs_unassign.create')) ? [{
    icon: 'Client',
    label: text.ASSIGN_SALES_MANAGERS_LABEL,
    onClick: data => onClick(data, 'ASSIGN_SALES_MANAGER'),
  }] : []),
];

class IbList extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.apiAssignSalesManager = getDjangoApi('team/ib_assign/bulk');
    this.apiUnassignSalesManger = getDjangoApi('team/ib_unassign/bulk');
    this.headerActions = this.headerActions.bind(this);
    this.bulkAction = this.bulkAction.bind(this);
    this.fetchTableData = this.fetchTableData.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);

    this.tableActions = {
      previewUser: this.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
      previewBiqUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  onRowSelected(data) {
    const { actionSidepanels } = this.props;

    _.forEach(actionSidepanels, (sidepanel, id) => {
      if (sidepanel.visible) this.actions.updateSidepanel(id, data);
    });
  } 

  getGridApi = (api) => {
    this.gridApi = api;
  };

  fetchTableData() {
    const { table } = this.props;
    this.actions.fetchTableData(table.query);
  }
  
  bulkAction(data, actionType) {
    this.actions.openSidepanel(actionType, data);
  }

  headerActions() {
    const { exportUrl } = this.props;
    return (
      <If condition={hasAccess('ib.export.*')}>
        <Export url={exportUrl} />
      </If>
    );
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions),
    };

    return (
      <Page headerActions={this.headerActions} bread={bread} title={text.PAGE_LABEL}>
        <Filters {...filterProps} />
        <GridComponent
          getGridApi={this.getGridApi}
          tableModifier={modifier(this.tableActions)}
          checkboxSelection={!!bulkActions(this.bulkAction).length}
          bulkActions={bulkActions(this.bulkAction)}
          onRowSelected={this.onRowSelected}
          {...this.props}
        />
        <IbEditTags fetchTableData={this.fetchTableData} isIb />
        <IbEditSalesStatuses fetchTableData={this.fetchTableData} isIb />
        <IbEditComplianceStatuses fetchTableData={this.fetchTableData} isIb />
        <IbAssignSalesManager
          isIb
          apiAssignSalesManager={this.apiAssignSalesManager}
          apiUnassignSalesManger={this.apiUnassignSalesManger}
          fetchTableData={this.fetchTableData}
          overlayName="ASSIGN_SALES_MANAGER"
          gridApi={this.gridApi} 
        />
        <UserPreview onUserUpdate={this.fetchTableData} />
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    fetchTableData: actions.fetchTableData,
    openSidepanel: overlayActions.open,
    updateSidepanel: overlayActions.update,
    updateFilter: actions.updateFilter,
    changeSegment: actions.changeSegment,
    fetchOptions: actions.fetchOptions,
  }, dispatch),
});

const mapStateToProps = ({ pages, overlays }) => {
  const { reducerKey, tableKey } = pageConfig;
  const table = pages[reducerKey].tables[tableKey];
  return { 
    table, 
    exportUrl: table.exportUrl,
    actionSidepanels: _.pick(overlays, ['EDIT_TAGS', 'EDIT_SALES_STATUSES', 'EDIT_COMPLIANCE_STATUSES', 'ASSIGN_SALES_MANAGER']),
    fields: table.fields,
  };
};

export default compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(IbList);
