import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { object, func, bool, arrayOf } from 'prop-types';

import connect from '../../../../logic/connect';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import { hasAccess } from '../../../../logic/services/acl';
import session from '../../../../logic/services/session';
import { gettext } from '../../../../logic/utilities/languageUtility';
import overlayActions from '../../../overlay/index';
import FormFactory, { FormActionsFactory } from '../../../form/index';
import to from '../../../../logic/utilities/to';
import {
  loadOptions,
  setRequestDataValues,
  setContentTypes,
  userAutoComplete,
} from './CreateEditTaskHelper';
import { SidepanelFooter } from '../../index';
import withUnmountMethod from '../../../hoc/withUnmountMethod';

const tasksApi = getDjangoApi('tasks');
const teamApi = getDjangoApi('team');
const FORM_ID = 'CREATE_EDIT_TASK_FORM';
export const SIDEPANEL_ID = 'CREATE_EDIT_TASK';
const { setRowData, resetFields, create, update } = FormActionsFactory(FORM_ID);

const types = {
  ADD: 'add',
  EDIT: 'edit',
  CLONE: 'clone',
};

const text = {
  EDIT_TITLE: gettext('Edit task'),
  CLONE_TITLE: gettext('Create a task'),
  CREATE_TITLE: gettext('Create a task'),
  EDIT_BUTTON_LABEL: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
  CREATE_BUTTON_LABEL: {
    confirm: gettext('Create a task'),
  },
  CLONE_BUTTON_LABEL: {
    confirm: gettext('Create a task'),
    cancel: gettext('Discard'),
  },
  PLACEHOLDER: gettext('...search'),
  LABEL: gettext('Performer'),
  TARGET: gettext('Target'),
  SUCCESS: gettext('Task updated successfully'),
  ERROR_CREATE: gettext('Error while creating task'),
  ERROR_UPDATE: gettext('Error while editing task'),
};

const getTitlesOrLabels = (type, isGetTitle) => {
  switch (type) {
    case types.ADD:
      return isGetTitle ? text.CREATE_TITLE : text.CREATE_BUTTON_LABEL;
    case types.CLONE:
      return isGetTitle ? text.CLONE_TITLE : text.CLONE_BUTTON_LABEL;
    default:
      return isGetTitle ? text.EDIT_TITLE : text.EDIT_BUTTON_LABEL;
  }
};

const customFooter = (execute, close, submitInProgress, type) => (
  <SidepanelFooter
    execute={execute}
    close={close}
    submitInProgress={submitInProgress}
    confirmColor="primary"
    formId={FORM_ID}
    buttonLabels={getTitlesOrLabels(type)}
  />
);

const assignToField = {
  id: 'performer',
  name: 'performer',
  label: text.LABEL,
  required: false,
  type: 'search',
  placeholder: text.PLACEHOLDER,
  async: true,
  debounceInterval: 500,
  loadOptions,
  group: true,
  valueKey: 'id',
  labelKey: 'label',
  dataKeys: ['username', 'name'],
};
const targetField = {
  id: 'target',
  name: 'target',
  label: text.TARGET,
  required: true,
  type: 'select',
  placeholder: text.PLACEHOLDER,
  async: true,
  debounceInterval: 500,
  loadOptions: userAutoComplete,
  valueKey: 'id',
  labelKey: 'username',
  dataKeys: ['username', 'full_name'],
};

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
  onSuccess: func,
  submitInProgress: bool,
  parameters: object,
  sidepanelManager: object,
  fields: arrayOf(object).isRequired,
};

const defaultProps = {
  onSuccess: null,
  submitInProgress: false,
  parameters: null,
  sidepanelManager: null,
};

class CreateEditTask extends Component {
  constructor(props) {
    super(props);
    this.saveTask = this.saveTask.bind(this);
    this.onClose = this.onClose.bind(this);
    this.renderForm = this.renderForm.bind(this);

    this.hasAllTasksAccess = hasAccess('tasks.list');
    this.api = () => (this.hasAllTasksAccess ? tasksApi : getDjangoApi(`team/profile/${session.user.id}/tasks`));
  }

  componentDidUpdate() {
    const { actions } = this.props;
    const params = this.getParams();
    actions.setRowData(params);
  }

  onClose(success) {
    const { actions, onSuccess, sidepanelManager } = this.props;
    actions.resetFields();
    sidepanelManager ? sidepanelManager.close() : actions.closeSidepanel(SIDEPANEL_ID);
    if (success) onSuccess();
  }

  getParams = () => {
    const { sidepanel, parameters } = this.props;
    return !_.isEmpty(parameters) ? parameters : sidepanel.parameters;
  }

  formConfig = () => ({
    form: FORM_ID,
    api: this.api(),
    excludeFields: [
      'performer_content_type',
      'performer_object_id',
      'all_day',
      'target_content_type',
      'target_object_id',
      'reminder',
    ],
    customFields: [assignToField, targetField],
  });

  async saveTask() {
    const { actions } = this.props;
    const parameters = this.getParams();
    const { id, type } = parameters;

    const res = await this.api().options();
    const options = res.data.actions.POST;
    let requestData = values => ({
      ...setRequestDataValues(values),
      ...setContentTypes(
        options,
        {
          target: values.target,
          performer: values.performer,
        },
        values.performer,
      ),
    });

    if (type !== types.ADD) {
      const res = this.hasAllTasksAccess ? await tasksApi.options() : await teamApi.optionsSingle(session.user.id);
      const options = res.data.actions.POST || res.data.actions.PUT;

      requestData = values => ({
        ...setRequestDataValues(values),
        ...setContentTypes(options, { ...parameters, target: values.target }, values.performer),
      });
    }

    const promise = type === types.EDIT
      ? actions.update(this.api(), id, requestData)
      : actions.create(this.api(), requestData);

    const [err] = await to(promise);

    if (err) {
      notifier.error(type === types.EDIT ? text.ERROR_UPDATE : text.ERROR_CREATE);
    } else {
      notifier.success(text.SUCCESS);
      this.onClose(true);
    }
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return (
      <Fragment>
        <FormComponent />
      </Fragment>
    );
  }

  render() {
    const { sidepanel, actions, submitInProgress, ...options } = this.props;
    const parameters = this.getParams();
    if (!parameters) return null;
    const { type } = parameters;
    return (
      <Sidepanel
        icon="CalendarCreate"
        type="success"
        title={getTitlesOrLabels(type, true)}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.saveTask, this.onClose, submitInProgress, type)}
        {...options}
      >
        {this.renderForm()}
      </Sidepanel>
    );
  }
}

export const mapStateToProps = ({ overlays, forms }) => {
  const { fields, errors, submitInProgress } = forms[FORM_ID];
  return {
    fields,
    errors,
    submitInProgress,
    sidepanel: overlays.CREATE_EDIT_TASK,
  };
};

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      setRowData,
      resetFields,
      create,
      update,
    },
    dispatch,
  ),
});

CreateEditTask.defaultProps = defaultProps;
CreateEditTask.propTypes = propTypes;

export default withUnmountMethod(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorBoundary(CreateEditTask)),
);
