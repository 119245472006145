import React from 'react';
import * as PropTypes from 'prop-types';

import { Button } from 'tc-biq-design-system';
import { gettext } from '../../../../logic/utilities/languageUtility';


const text = {
  ACCEPT: gettext('ACCEPT'),
  REJECT: gettext('REJECT'),
};

const ApproveAndReject = ({ disabled, loading, onApprove, onReject, size }) => (
  <span>
    <Button
      onClick={onReject}
      disabled={disabled}
      size={size}
      color="destructive"
    >{text.REJECT}
    </Button>
    <Button
      loading={loading}
      disabled={disabled}
      onClick={onApprove}
      size={size}
      color="confirmation"
    >{text.ACCEPT}
    </Button>
  </span>
);


ApproveAndReject.propTypes = {
  onReject: PropTypes.func.isRequired,
  disabled: PropTypes.any.isRequired,
  size: PropTypes.string.isRequired,
  loading: PropTypes.any.isRequired,
  onApprove: PropTypes.func.isRequired,
};

export default ApproveAndReject;
