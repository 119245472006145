import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { object, bool, array } from 'prop-types';
import { notifier, Space, Sidepanel } from 'tc-biq-design-system';

import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import to from '../../../../../../../logic/utilities/to';
import { formErrorHandler } from '../../../../../../../components/form/logic/utils';


const text = {
  ERROR_GENERAL: gettext('Something went wrong!'),
  SUCCESS: gettext('Password changed for user.'),
  PASSWORD: gettext('Password'),
  CONFIRM_PASSWORD: gettext('Confirm password'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const FORM_KEY = 'CHANGE_PASSWORD_FORM';

const formConfig = {
  form: FORM_KEY,
};

const fields = [
  {
    type: 'password',
    id: 'password',
    name: 'password',
    label: text.PASSWORD,
  }, {
    type: 'password',
    id: 'password2',
    name: 'password2',
    label: text.CONFIRM_PASSWORD,
  },
];

const { create, resetFields, setFields } = FormActionsFactory(FORM_KEY);

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_KEY}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  submitInProgress: bool.isRequired,
  fields: array.isRequired,
  isIb: bool.isRequired,
};

const defaultProps = {
  user: null,
  actions: null,
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.actions = props.actions;
    this.api = user => getDjangoApi(`${user.isIb ? 'ib' : 'users'}/${user.id}/change_password`);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.actions.setFields(fields);
  }

  componentDidUpdate() {
    // eslint-disable-next-line react/destructuring-assignment
    const currentFields = this.props.fields;
    if (_.isEmpty(currentFields)) {
      this.actions.setFields(fields);
    }
  }

  async onSubmit() {
    const { user, isIb } = this.props;
    const [err] = await to(this.actions.create(this.api({ ...user, isIb })));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    this.onClose();
    notifier.success(text.SUCCESS);
  }

  onError(payload) {
    formErrorHandler(text.ERROR_GENERAL)(payload);
  }

  onClose() {
    const { sidepanelManager } = this.props;
    sidepanelManager.close();
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    const { FormComponent } = this;
    return <FormComponent formId={FORM_KEY} />;
  }

  render() {
    const {
      sidepanelManager,
      user,
      actions,
      submitInProgress,
      ...options
    } = this.props;
    return (
      <Sidepanel
        {...options}
        footerRender={customFooter(this.onSubmit, this.onClose, submitInProgress)}
      >
        <Space size={16} />
        {this.renderForm()}
        <Space size={16} />
      </Sidepanel>
    );
  }
}

ChangePassword.propTypes = propTypes;
ChangePassword.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      resetFields,
      setFields,
      create,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ forms }) => {
  const form = forms[FORM_KEY];
  return {
    submitInProgress: form.submitInProgress,
    fields: form.fields,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
