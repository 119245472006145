import React, { Component } from 'react';
import { object, array } from 'prop-types';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import { Table } from 'tc-biq-design-system';

import './index.scss';

import { bindActionCreators } from 'redux';
import connect from '../../../../../logic/connect';
import modifier from './refundPayoutModifier';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { setRefundPayoutTable, editRefundPayoutRow, clearRefundPayoutTable } from './logic/Model';

const propTypes = {
  withdrawal: object.isRequired,
  referralTransactions: array.isRequired,
  data: array.isRequired,
  actions: object.isRequired,
};

const REDUCER_KEY = 'PENDING_WITHDRAWAL';
const TABLE_KEY = 'WITHDRAWALS_MATCHING_METHOD';

class WithdrawalsMatchingMethod extends Component {
  constructor({ withdrawal }) {
    super();
    const { id } = withdrawal;
    this.updateTable = debounce(this.updateTable, 500);

    this.api = getDjangoApi(`pending_withdrawals/${id}/referral_transaction_data`);
  }

  componentDidUpdate(prevProps) {
    const prevValues = _.get(prevProps, 'form.values');
    const oldReferralTransactions = _.get(prevProps, 'referralTransactions');
    const values = _.get(this, 'props.form.values');
    const { referralTransactions } = this.props;
    const hasChangeGatewayOrMethod = (prevValues.gateway !== values.gateway && values.gateway) || (prevValues.method !== values.method && values.method);

    this.fetchTableData(referralTransactions, oldReferralTransactions, hasChangeGatewayOrMethod, values);
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.clearRefundPayoutTable();
  }

  updateTable = async (initialData) => {
    const { referralTransactions, actions } = this.props;
    actions.clearRefundPayoutTable();
    actions.setRefundPayoutTable(this.enrichData({ referralTransactions, data: initialData }));
  }

  enrichData = ({ referralTransactions = [], data = [] } = {}) => referralTransactions
    .map((transaction) => {
      const initialData = find(data, (d => d.id === transaction.id)) || {};
      
      return ({ ...transaction, ...initialData, initial_refund_amount: initialData.refund_amount, initial_payout_amount: initialData.payout_amount });
    });

  async fetchTableData(referralTransactions, oldReferralTransactions, hasChangeGatewayOrMethod, values) {
    if (!isEqual(referralTransactions, oldReferralTransactions) || hasChangeGatewayOrMethod) {
      const { withdrawal } = this.props;
      const methodId = values.method && values.method.value;

      const query = {
        user: withdrawal.user.id,
        method: methodId,
        ids: map(referralTransactions, 'id'),
        ...(values.gateway && { gateway: values.gateway.value }),
      };

      const { data } = await this.api.list(query);
      this.updateTable(data);
    }
  }

  calculateTotals(data) {
    return data.reduce((sums, item) => ({
      payoutSum: sums.payoutSum + Number(+item.payout_amount),
      refundSum: sums.refundSum + Number(+item.refund_amount),
    }), {
      payoutSum: 0,
      refundSum: 0,
    });
  }

  render() {
    const { withdrawal, data, actions } = this.props;
    const { is_automatic_withdrawal_allowed } = withdrawal;

    const { payoutSum, refundSum } = this.calculateTotals(data);

    const tableModifier = modifier({
      payoutSum,
      refundSum,
      is_automatic_withdrawal_allowed,
      onChange: actions.editRefundPayoutRow,
    });

    const cols = _.map(tableModifier, (value, key) => ({
      ...value,
      title: value.label,
      key,
    })).filter(col => !col.hide);
    return (
      <div className="withdrawal-matching-method">
        <Table
          xScroll
          noWrap
          striped
          cols={cols}
          data={data}
          modifier={tableModifier}
          pinnedBottomRowData={[{ payout_amount: <strong>Total payoutable: {payoutSum}</strong>, refund_amount: <strong>Total refundable: {refundSum}</strong> }]}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ pages, forms }) => {
  const page = pages[REDUCER_KEY];
  const data = page.tables[TABLE_KEY];
  return {
    data,
    dmmTable: page.tables.DEPOSITS_MATCHING_METHOD,
    form: forms.EXECUTE_WITHDRAWAL,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    setRefundPayoutTable,
    editRefundPayoutRow,
    clearRefundPayoutTable,
  }, dispatch),
});

WithdrawalsMatchingMethod.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalsMatchingMethod);
