import nonFTD from './NonFTD';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../../logic/services/acl';

export default {
  ...nonFTD,
  key: 'non-ftd',
  label: gettext('Non-FTD'),
  isVisible: () => hasAccess('dashboard.overall_deposits.*'),
};
