import moment from 'moment';
import React, { Component } from 'react';
import { Space, Pill, Input, Button, notifier, DashboardCard } from 'tc-biq-design-system';
import { object, node, func } from 'prop-types';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import GraphCard from '../../../campaigns/Campaigns/CampaignStat/GraphCard';
import CampaignSidepanel, { SIDEPANEL_ID } from '../../../campaigns/Campaigns/CampaignSidepanel';
import { hasAccess } from '../../../../../logic/services/acl';

import './CampaignHeader.scss';
import FormEl from '../../../../../components/form/Components/FormEl';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

const fields = {
  NAME: 'name',
  DESCRIPTION: 'description',
  USER: 'user',
  WHITELABEL: 'whitelabel',
};

const fieldKeys = Object.values(fields);


const text = {
  INFORMATION: gettext('Information'),
  YES: gettext('Yes'),
  NO: gettext('No'),
  ACTIVE: gettext('Active'),
  TITLES: {
    [fields.NAME]: gettext('Name'),
    [fields.DESCRIPTION]: gettext('Description'),
    [fields.USER]: gettext('User'),
    [fields.WHITELABEL]: gettext('Whitelabel'),
  },
  NOTES: gettext('Notes'),
  TYPE: gettext('Type notes...'),
  ADD: gettext('Add'),
  EDIT: gettext('Edit'),
  NOTE_SUCCESS: gettext('Create new note'),
  NOTES_ERROR: gettext('Failed to create a new note'),
  VISITS: gettext('Visits'),
};

const propTypes = {
  campaign: object.isRequired,
  dateFilterComponent: node.isRequired,
  actions: object.isRequired,
  loadCampaign: func.isRequired,
};

const api = ({ user, id }) => getDjangoApi(`team/profile/${user.id}/campaigns/${id}`);
const apiNotes = campaign => api(campaign).one('notes');

class CampaignHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: [],
      noteInProgress: false,
      note: '',
    };

    this.infoActions = hasAccess('team.profile.campaigns.update') ? [{
      label: text.EDIT,
      onClick: this.onEdit,
    }] : null;
  }

  componentDidMount() {
    this.loadNotes();
  }

  onEdit = () => {
    const { actions, campaign: data } = this.props;
    actions.openSidepanel(SIDEPANEL_ID, data ? { data } : _);
  };

  onNoteChange = e => this.setState({ note: e.target.value })

  onNoteSubmit = () => {
    const { campaign } = this.props;
    const { note } = this.state;
    const submit = () => {
      apiNotes(campaign)
        .create({ content: note })
        .then(() => {
          notifier.success(text.NOTE_SUCCESS);
          this.setState({ note: '' });
          this.loadNotes();
        }).catch(() => notifier.error(text.NOTES_ERROR))
        .finally(() => this.setState({ noteInProgress: false }));
    };
    this.setState({ noteInProgress: true }, submit);
  }

  loadNotes = () => {
    const { campaign } = this.props;
    hasAccess('team.profile.campaigns.notes.list') 
      && apiNotes(campaign).list().then(({ data }) => {
        this.setState({ notes: data.results });
      });
  }

  render() {
    const { campaign, dateFilterComponent, loadCampaign } = this.props;
    const { notes, note, noteInProgress } = this.state;
    return (
      <div className="campaign-header">
        <div className="campaign-header__info">
          <DashboardCard
            title={text.INFORMATION}
            description={text.INFORMATION}
            actions={this.infoActions}
          >
            <Space size={16} />
            {fieldKeys.map(key => (
              <div key={key} className="campaign-header__detail">
                <span className="campaign-header__detail__label tc-micro-regular">{text.TITLES[key]}</span>
                <p className="tc-paragraph-regular">{key === fields.USER ? campaign.user.username : campaign[key]}</p>
              </div>
            ))}
            <div className="campaign-header__detail campaign-header__detail--top-right">
              <span className="campaign-header__detail__label tc-micro-regular">{text.ACTIVE}</span>
              <p className="tc-paragraph-regular"><Pill type="status01">{campaign.active ? text.YES : text.NO}</Pill></p>
            </div>
            <Space size={16} />
          </DashboardCard>
        </div>
        <div className="campaign-header__notes">
          <DashboardCard
            title={text.NOTES}
            description={text.NOTES}
          >
            <FormEl>
              <div className="campaign-header__notes__body">
                {notes.map(({ id, author, created, content }) => (
                  <div key={id} className="campaign-header__note">
                    <div className="tc-paragraph-strong text-neutral-900">{author}</div>
                    <div className="tc-paragraph-regular text-neutral-600">{moment(created).format(DATE_FORMAT)}</div>
                    <Space size={3} />
                    <p className="campaign-header__note__text tc-paragraph-regular">{content}</p>
                  </div>
                ))}
              </div>
              {hasAccess('team.profile.campaigns.notes.create') && (
                <div className="campaign-header__notes__form">
                  <Input
                    placeholder={text.TYPE}
                    onChange={this.onNoteChange}
                    value={note}
                  />
                  <Button loading={noteInProgress} type="submit" onClick={this.onNoteSubmit}>{text.ADD}</Button>
                </div>
              )}
            </FormEl>
          </DashboardCard>
        </div>
        <div className="campaign-header__visits">
          <GraphCard
            title={text.VISITS}
            data={campaign.visits_per_day}
            header={dateFilterComponent}
          />
        </div>
        <CampaignSidepanel onSuccess={loadCampaign} />
      </div>
    );
  }
}

CampaignHeader.propTypes = propTypes;

export default CampaignHeader;
