import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import connect from '../../../../../../logic/connect';
import CampaignForm, { FORM_KEY } from './CampaignForm';
import { actions } from './Model';
import { FormActionsFactory } from '../../../../../../components/form';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

const formActions = FormActionsFactory(FORM_KEY);

const mapStateToProps = ({ pages, forms }) => {
  const { campaign } = pages.BONUS;
  return { 
    ...campaign,
    form: { ...forms[FORM_KEY] },
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions, ...formActions }, dispatch),
});

const CampaignPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(CampaignForm); 

export const BonusCampaign = {
  component: CampaignPage,
  path: appRoutes.SETTINGS_BONUS_CAMPAIGN,
  aclId: 'bonuses.campaigns.update',
};


export const BonusCampaignCreate = {
  component: CampaignPage,
  path: appRoutes.SETTINGS_BONUS_CAMPAIGN_CREATE,
  aclId: 'bonuses.campaigns.create',
};
