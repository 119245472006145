import React, { Component, Fragment } from 'react';
import { object, bool } from 'prop-types';
import { Button, Space, InfoBox } from 'tc-biq-design-system';

import { LoginLogo } from '../../../components/Nav/logos';
import FormFactory from '../../../components/form';
import FormPanel from '../../../components/form/Components/FormPanel/FormPanel';
import Page from '../../../components/Page';
import { login } from '../../../logic/services/authentication';
import { getDjangoApi } from '../../../logic/services/api-factory';
import { gettext } from '../../../logic/utilities/languageUtility';
import to from '../../../logic/utilities/to';
import appRoutes from '../../../components/App/Router/appRoutes';
import { formErrorHandler } from '../../../components/form/logic/utils';

export const FORM_KEY = 'JOIN_FORM';

const text = {
  TITLE: gettext('Join'),
  FIRST_NAME: gettext('First name'),
  LAST_NAME: gettext('Last name'),
  USERNAME: {
    LABEL: gettext('Username'),
    PLACEHOLDER: gettext('Email or username'),
  },
  PASSWORD: gettext('Password'),
  CONFIRM_PASSOWRD: gettext('Confirm password'),
  JOIN: gettext('Create account'),
  WELCOME: gettext('Welcome to BackOffice'),
  ERROR_GENERAL: gettext('Something went wrong'),
  ACCESS_DENIED: gettext('You don\'t have permissions to access this page.'),
  WARNING: gettext('Warning'),
};

const firstName = {
  type: 'text',
  id: 'first_name',
  name: 'last_name',
  placeholder: text.FIRST_NAME,
  label: text.FIRST_NAME,
};

const lastName = {
  type: 'text',
  id: 'last_name',
  name: 'last_name',
  placeholder: text.LAST_NAME,
  label: text.LAST_NAME,
};

const username = {
  type: 'text',
  id: 'username',
  name: 'username',
  placeholder: text.USERNAME.PLACEHOLDER,
  label: text.USERNAME.LABEL,
};

const password = {
  type: 'password',
  id: 'password',
  name: 'password',
  placeholder: text.PASSWORD,
  label: text.PASSWORD,
};

const confirmPassword = {
  type: 'password',
  id: 'password_confirm',
  name: 'password_confirm',
  placeholder: text.CONFIRM_PASSOWRD,
  label: text.CONFIRM_PASSOWRD,
};

const api = getDjangoApi('team/join');

const propTypes = {
  submitInProgress: bool.isRequired,
  actions: object.isRequired,
  values: object.isRequired,
  match: object.isRequired,
  history: object.isRequired,
};

class Join extends Component {
  constructor(props) {
    super(props);

    this.code = props.match.params.code;
    this.state = {
      isValidCode: false,
      checkInProgress: false,
    };
  }

  async componentDidMount() {
    this.setState({ checkInProgress: true });
    const [err] = await to(api.retrieve(this.code));
    this.setState({
      isValidCode: !err,
      checkInProgress: false,
    });
  }

  onSubmit = async () => {
    const { actions, values } = this.props;
    const data = { ...values };
    const [err] = await to(actions.update(api, this.code));
    err ? this.onError(err) : this.onSuccess(data);
  }

  onSuccess({ username, password }) {
    const { actions, history } = this.props;
    login(username, password).then(() => {
      actions.loadAuthSession().then(() => {
        history.push(appRoutes.DASHBOARD);
      });
    }).catch(this.onError);
  }

  onError(payload) {
    formErrorHandler(text.ERROR_GENERAL)(payload);
  }

  formConfig = () => ({
    form: FORM_KEY,
    customFields: [
      firstName,
      lastName,
      username,
      password,
      confirmPassword,
    ],
  })

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const Form = this.renderForm();
    const { submitInProgress } = this.props;
    const { isValidCode, checkInProgress } = this.state;
    const showLoader = checkInProgress;
    const showForm = !checkInProgress && isValidCode;
    const showWarning = !checkInProgress && !isValidCode;

    return (
      <Page style={{}} title={text.TITLE} hideHeader>
        <div className="auth-page">
          <Form renderForm={formFields => (
            <FormPanel 
              height="full" 
              size="small"
            >
              <FormPanel.Section hideBorder>
                <p className="tc-heading-s auth-page__welcome">{text.WELCOME}</p>
                <div className="auth-page__logo">
                  <LoginLogo />
                </div>
                <Space size={30} />
                {showLoader && (
                  <FormPanel.Spinner />
                )}
                {showWarning && (
                  <InfoBox header={text.WARNING} type="warning">
                    {text.ACCESS_DENIED}
                  </InfoBox>
                )}
                {showForm && (
                  <Fragment>
                    {formFields.first_name}
                    <Space size={20} />
                    {formFields.last_name}
                    <Space size={20} />
                    {formFields.username}
                    <Space size={20} />
                    {formFields.password}
                    <Space size={20} />
                    {formFields.password_confirm}
                    <Space size={30} />
                    <Button loading={submitInProgress} onClick={this.onSubmit}>{text.JOIN}</Button>
                  </Fragment>
                )}
              </FormPanel.Section>
            </FormPanel>
          )} 
          />
        </div>
      </Page>
    );
  }
}

Join.propTypes = propTypes;

export default Join;
