import React from 'react';
import { Panel, Table } from 'tc-biq-design-system';
import { string, array } from 'prop-types';

const propTypes = {
  title: string.isRequired,
  cols: array.isRequired,
  data: array.isRequired,
};

const PanelTable = ({ title, cols, data, ...rest }) => (
  <Panel title={title}>
    <Table
      striped
      cols={cols}
      data={data}
      {...rest}
    />
  </Panel>
);

PanelTable.propTypes = propTypes;

export default PanelTable;
