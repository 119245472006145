import React, { Component, Fragment } from 'react';
import { func } from 'prop-types';
import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import modifiers from './modifiers';
import EditRiskSidepanel, { sidepanelID } from './EditRiskSidepanel';
import { hasAccess } from '../../../../../../logic/services/acl';

const countryRisksConfig = () => ({
  reducerKey: 'RISK_RATING_SYSTEM',
  apiUrl: 'settings/country_risks',
  tableKey: 'RISK_RATING_SYSTEM_TABLE',
});

const propTypes = {
  openSidePanel: func.isRequired,
  closeSidePanel: func.isRequired,
};

class CountryRisks extends Component {
  constructor(props) {
    super(props);
    
    this.tableData = withTable(GridTab, countryRisksConfig, null, { withActions: true })();
    const { openSidePanel } = this.props;
    this.actions = {
      modify: hasAccess('country_risks.settings.list') && ((data) => { openSidePanel(sidepanelID, data); }),
    };
  }

  componentWillUnmount() {
    const {
      closeSidePanel,
    } = this.props;
    closeSidePanel(sidepanelID);
  }

  render() {
    const [CountryRiskTable, actions] = this.tableData;
    const { closeSidePanel } = this.props;

    return (
      <Fragment>
        <CountryRiskTable
          singleActions={!!this.actions.modify}
          gridConfig={{ getRowNodeId: data => data.iso_code }}
          modifier={modifiers(this.actions)}
        />
        <EditRiskSidepanel
          fetchTableData={actions.fetchTableData}
          closeSidePanel={closeSidePanel}
        />
      </Fragment>
    );
  } 
}

CountryRisks.propTypes = propTypes;

export default CountryRisks;
