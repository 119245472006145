/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Button, notifier } from 'tc-biq-design-system';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import overlayActions from '../../../../components/overlay';
import Page from '../../../../components/Page';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import DeleteEmailTemplateSidepanel from './Sidepanel/deleteEmailTemplate';
import PreviewEmailTempalte from './Sidepanel/previewEmailTemplate';
import modifiers from './modifiers';
import If from '../../../../components/If';
import { BiqUserPreview } from '../../../../components/common/sidepanels';
import { hasAccess } from '../../../../logic/services/acl';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const text = {
  PAGE_TITLE: gettext('Email templates'),
  BIQ_BREADCRUMB: gettext('BrokerIQ'),
  ADD_EMAIL_TEMPLATE_BUTTON_LABEL: gettext('Add new email template'),
  BULK_DELETE_LABEL: gettext('Delete'),
  DELETE_SUCCESS: gettext('Template removed'),
  DELETE_ERROR: gettext('Removing template failed'),
  ERROR_PREVIEW: gettext('Something got wrong.'),
};

const pageConfig = {
  apiUrl: 'emails/marketing',
  reducerKey: 'EMAIL_TEMPLATES',
  tableKey: 'EMAIL_TEMPLATES_TABLE',
};

const { GridComponent, actions } = GridFactory(pageConfig);
const bread = [{ label: text.PAGE_TITLE, route: appRoutes.EMAIL_TEMPLATES }];

const headerActions = () => (
  <If condition={hasAccess('emails.templates.create') && hasAccess('emails.templates.content.create')}>
    <Link to={generatePath(appRoutes.EMAIL_TEMPLATE_CREATE)}>
      <Button startPropagation>{text.ADD_EMAIL_TEMPLATE_BUTTON_LABEL}</Button>
    </Link>
  </If>
);


class EmailTemplates extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.bulkActions = [
      ...(hasAccess('emails.templates.destroy') && [{
        icon: 'Delete',
        label: text.BULK_DELETE_LABEL,
        onClick: this.actions.openSidepanel.bind(this, 'DELETE_EMAIL_TEMPLATE'),
      }]),
    ];

    this.tableActions = {
      modify: hasAccess('emails.templates.update'),
      copy: hasAccess('emails.templates.create'),
      remove: hasAccess('emails.templates.destroy') && this.onRemove.bind(this),
      previewEmailTempalte: ('emails.templates.retrieve') && this.onPreviewEmailTemplate.bind(this),
      previewUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.fetchTableData();
  }

  onRemove(data) {
    const api = getDjangoApi('emails/marketing');
    this.actions.deleteRow(data, api).then(() => {
      notifier.success(text.DELETE_SUCCESS);
    }, (error) => {
      notifier.error(error.data.detail ? error.data.detail : text.DELETE_ERROR);
    });
  }

  onPreviewEmailTemplate(data) {
    const api = getDjangoApi(`emails/marketing/${data.id}/content`);
    api.retrieve().then(({ data: { results } }) => {
      this.actions.openSidepanel('PREVIEW_EMAIL_TEMPLATE', { languages: results });
    }).catch((error) => {
      notifier.error(error.data ? error.data : text.ERROR_PREVIEW);
    });
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifiers(this.tableActions),
    };

    return (
      <Page headerActions={headerActions} bread={bread} title={text.PAGE_TITLE}>
        <Filters {...filterProps} />
        <GridComponent
          checkboxSelection={!!this.bulkActions.length}
          singleActions={this.tableActions.modify || this.tableActions.copy || this.tableActions.remove || this.tableActions.previewEmailTempalte}
          bulkActions={this.bulkActions}
          tableModifier={modifiers(this.tableActions)}
          onRowSelected={data => this.actions.updateSidepanel('DELETE_EMAIL_TEMPLATE', data)}
          {...this.props}
        />
        <DeleteEmailTemplateSidepanel tableActions={this.actions} />
        <PreviewEmailTempalte />
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      updateRowData: actions.updateRowData,
      addActionsColumn: actions.addActionsColumn,
      fetchTableData: actions.fetchTableData,
      deleteRow: actions.deleteRow,
      openSidepanel: overlayActions.open,
      updateSidepanel: overlayActions.update,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
    },
    dispatch,
  ),
});

export default compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(EmailTemplates);
