import React, { useMemo } from 'react';
import { func, number, string } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../logic/connect';
import FormActionsFactory from '../logic/actionsFactory';
import { 
  TextField, 
  TextAreaField, 
  SelectField,
  RadioField,
  RadioGroupField,
  DatePickerField, 
  CheckboxField,
  ToggleField,
  MultiSelectField,
  UploadField,
  TimeFrame,
} from './Inputs';
import { getFieldError } from '../logic/utils';

const propTypes = {
  onChange: func.isRequired,
  customOnChange: func,
  index: number,
  type: string.isRequired,
};

const defaultProps = {
  customOnChange: null,
  index: 0,
};
const Field = (props) => {
  const { type, onChange, customOnChange, index } = props;

  const onChangeHandlers = useMemo(() => ({
    ...onChange && {
      onChange: (value) => {
        value.markAsDirty = true;
        onChange(value, index);
      },
    },
    ...customOnChange && {
      customOnChange: (value) => {
        value.markAsDirty = true;
        customOnChange(value, index);
      },
    },
  }), [onChange, customOnChange, index]);

  switch (type) {
    case 'text':
    case 'password':
    case 'number':
      return <TextField {...props} {...onChangeHandlers} />;
    case 'textarea':
      return <TextAreaField {...props} {...onChangeHandlers} />;
    case 'select':
    case 'search':
      return <SelectField {...props} {...onChangeHandlers} />;
    case 'multiselect':
      return <MultiSelectField {...props} {...onChangeHandlers} />;
    case 'radio':
      return <RadioField {...props} {...onChangeHandlers} />;
    case 'radio-group':
      return <RadioGroupField {...props} {...onChangeHandlers} />;
    case 'checkbox':
      return <CheckboxField {...props} {...onChangeHandlers} />;
    case 'toggle':
      return <ToggleField {...props} {...onChangeHandlers} />;
    case 'date':
    case 'datetime':
      return <DatePickerField {...props} {...onChangeHandlers} />;
    case 'file':
      return <UploadField {...props} {...onChangeHandlers} />;
    case 'timeframe':
      return <TimeFrame {...props} />;
    default:
      return null;
  }
};

Field.propTypes = propTypes;
Field.defaultProps = defaultProps;

const mapStateToProps = (formId, fieldKey) => ({ forms }, ownProps) => {
  const form = forms[formId];
  const hasError = !!form.errors[fieldKey];
  const helpText = hasError ? getFieldError(form.errors[fieldKey]) : null;
  const tempVal = form.values[fieldKey];
  const value = !tempVal && tempVal !== 0 ? '' : tempVal;

  return { value: ownProps.value ? ownProps.value : value, hasError: hasError || ownProps.hasError, helpText: helpText || ownProps.helpText };
}; 

const mapDispatchToProps = ({ onChange, customOnChange }) => dispatch => ({ customOnChange, ...bindActionCreators({ onChange }, dispatch) });

const renderField = ({ formId, fieldKey, customOnChange }) => {
  const { setFieldValue } = FormActionsFactory(formId);

  return connect(
    mapStateToProps(formId, fieldKey), 
    mapDispatchToProps({ onChange: setFieldValue, customOnChange }),
  )(Field);
};

export default renderField;
