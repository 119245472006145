import React from 'react';
import { Card, Code, HyperLink } from 'tc-biq-design-system';
import { object, func } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import { gettext } from '../../../../logic/utilities/languageUtility';
import { dateTimeFormatter, currencyFormatter } from '../../../../logic/utilities/formatters';
import WithdrawalDetail from './WithdrawalDetail';
import { YesNoCell } from '../../../../components/gridCellRenderers';
import appRoutes from '../../../../components/App/Router/appRoutes';
import TradingAccountLink from '../../../../components/common/TradingAccountLink';
import { tradingAccountDisplay } from '../../../../logic/filters/trading-account-display';

const propTypes = {
  withdrawal: object.isRequired,
  relatedPaymentTransaction: object.isRequired,
  openSingleTransaction: func.isRequired,
  openTradingAccountSidepanel: func.isRequired,
};

const { environment } = window.config;
const isAvaTrade = environment === 'AvaTrade';

const text = {
  TRADING_ACCOUNT: gettext('Trading account'),
  PAYMENT_TRANSACTION: gettext('Payment transaction'),
  WITHDRAWAL_ID: gettext('Withdrawal ID'),
  USER: gettext('User'),
  EXECUTED_BY: gettext('Executed by'),
  STATUS: gettext('Status'),
  METHOD: gettext('Method'),
  DATE: gettext('Date'),
  GATEWAY: gettext('Gateway'),
  VALUE: gettext('Value'),
  WITHDRAWAL_EXTRA_ID: gettext('Withdrawal extra ID'),
  RELATED_TRANSACTION: gettext('Related transaction'),
  REJECTION_REASON: gettext('Rejection reason'),
  CURRENCY: gettext('Currency'),
  NA: gettext('N/A'),
  GATEWAY_ID: gettext('Gateway ID'),
  ACTIVE_BONUS: gettext('Active bonus'),
  TRANSFER: gettext('Transfer'),
};

const getUserRoute = user => generatePath(user.is_staff 
  ? appRoutes.TEAM_MEMBER : appRoutes.CLIENT_PROFILE, { id: user.id });

const displayUser = user => user && (
  <HyperLink>
    <Link to={getUserRoute(user)} href={getUserRoute(user)}>{user.username}</Link>
  </HyperLink>
);

const displayRelatedTransaction = (transactions, openSingleTransaction) => (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {
      transactions.map((t, i) => (
        <div onClick={() => { openSingleTransaction({ id: t }); }}> {`${t} ${i < transactions.length - 1 ? ', ' : ''}`} </div>
      ))
    }
  </div>
);

const PaymentTransaction = ({ 
  withdrawal, relatedPaymentTransaction, 
  openSingleTransaction, openTradingAccountSidepanel,
}) => {
  const {
    id,
    user,
    status,
    method,
    date,
    meta,
    executed_by,
    currency,
    amount,
    related_payment_transaction,
    rejection_reason,
    external_id,
    is_active_bonus,
    has_transfer,
    wallet,
  } = withdrawal;

  const {
    withdrawal_extra_id,
    gateway,
  } = relatedPaymentTransaction;

  return (
    <Card title={text.PAYMENT_TRANSACTION}>
      <div className="biq-pending-withdrawal__card">
        <div className="biq-pending-withdrawal__payment-transaction-details">
          <WithdrawalDetail entity={text.WITHDRAWAL_ID} value={id} />
          <WithdrawalDetail entity={text.USER} value={displayUser(user)} />
          <WithdrawalDetail entity={text.STATUS} value={status} />
          <WithdrawalDetail entity={text.METHOD} value={method} />
          <WithdrawalDetail entity={text.DATE} value={dateTimeFormatter(date)} />
          <WithdrawalDetail entity={text.GATEWAY} value={gateway || text.NA} />
          <WithdrawalDetail entity={text.EXECUTED_BY} value={displayUser(executed_by) || text.NA} />
          <WithdrawalDetail entity={text.VALUE} value={currency ? currencyFormatter(amount, currency.symbol) : amount} />
          {is_active_bonus !== undefined && isAvaTrade && <WithdrawalDetail entity={text.ACTIVE_BONUS} value={<YesNoCell value={is_active_bonus} />} />}
          {has_transfer !== undefined && isAvaTrade && <WithdrawalDetail entity={text.TRANSFER} value={<YesNoCell value={has_transfer} />} />}
          
          {external_id && <WithdrawalDetail entity={text.GATEWAY_ID} value={external_id} />}
          {currency && <WithdrawalDetail entity={text.CURRENCY} value={currency.symbol} />}
          {withdrawal_extra_id && isAvaTrade && <WithdrawalDetail entity={text.WITHDRAWAL_EXTRA_ID} value={withdrawal_extra_id} />}
          {related_payment_transaction && Array.isArray(related_payment_transaction) && (
            <WithdrawalDetail 
              entity={text.RELATED_TRANSACTION} 
              value={displayRelatedTransaction(related_payment_transaction, openSingleTransaction)}
            />
          )}
          <WithdrawalDetail 
            entity={text.TRADING_ACCOUNT} 
            value={TradingAccountLink({ 
              displayName: tradingAccountDisplay(wallet), 
              id: _.get(withdrawal, 'wallet.id'), 
              userId: _.get(withdrawal, 'user.id'), 
              openTradingAccountSidepanel,
            })} 
          />
          {rejection_reason && <WithdrawalDetail entity={text.REJECTION_REASON} value={rejection_reason} />}
        </div>

        <div className="biq-pending-withdrawal__payment-transaction-meta">
          <span className="tc-paragraph-regular text-neutral-500" style={{ fontSize: '12px' }}>Meta</span>
          <Code data={meta} />
        </div>
      </div>
    </Card>
  );
};

PaymentTransaction.propTypes = propTypes;
export default PaymentTransaction;
