import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  FETCH_NIC_VALUES: 'FETCH_NIC_VALUES',
  FETCH_NIC_VALUES_FULFILLED: 'FETCH_NIC_VALUES_FULFILLED',
  FETCH_NIC_VALUES_PENDING: 'FETCH_NIC_VALUES_PENDING',
  FETCH_NIC_VALUES_REJECTED: 'FETCH_NIC_VALUES_REJECTED',
  FETCH_NIC_FIELDS: 'FETCH_NIC_FIELDS',
  FETCH_NIC_FIELDS_FULFILLED: 'FETCH_NIC_FIELDS_FULFILLED',
  FETCH_NIC_FIELDS_PENDING: 'FETCH_NIC_FIELDS_PENDING',
  FETCH_NIC_FIELDS_REJECTED: 'FETCH_NIC_FIELDS_REJECTED',
  RESET_EDIT_MIFID: 'RESET_EDIT_MIFID',
};

export const fetchNicValues = createAction(actionTypes.FETCH_NIC_VALUES, async (user) => {
  const api = getDjangoApi(`users/${user.id}/nic`);
  const { data } = await api.list();
  const { results } = data;
  return results;
});

export const fetchNicFields = createAction(actionTypes.FETCH_NIC_FIELDS, async (user) => {
  const api = getDjangoApi(`users/${user.id}`);
  const { data } = await api.options();
  const { actions, nic_fields } = data;
  const nicFieldOptions = actions.PUT.fields.find(field => field.key === 'nic_fields');
  const nationalityOptions = _.get(nicFieldOptions, 'child.children.fields').find(children => children.key === 'nationality').choices;
  return {
    nationalityOptions,
    nicFields: nic_fields,
  };
});

export const resetMifid = createAction(actionTypes.RESET_EDIT_MIFID, () => ({ ...initialState }));


const initialState = {
  nicValues: [],
  fetchNicValuesInProgress: false,
  nicValuesErrors: null,
  nationalityOptions: [],
  nicFields: [],
  fetchNicFieldsInProgress: false,
  nicFieldsErrors: null,
};

const fulfilledNicValuesHandler = (state, { payload }) => ({
  ...state,
  nicValues: payload,
  fetchNicValuesInProgress: false,
  error: null,
});

const rejectedNicValuesHandler = (state, { payload }) => ({
  ...state,
  fetchNicValuesInProgress: false,
  nicValuesErrors: payload.data,
});

const fulfilledNicFieldsHandler = (state, { payload: { nationalityOptions, nicFields } }) => ({
  ...state,
  nationalityOptions,
  nicFields,
  fetchNicFieldsInProgress: false,
  nicFieldsErrors: null,
});

const rejectedNicFieldsHandler = (state, { payload }) => ({
  ...state,
  fetchNicValuesInProgress: false,
  errors: payload.data,
});

export default handleActions(
  {
    [actionTypes.FETCH_NIC_VALUES_PENDING]: state => ({ ...state, fetchNicValuesInProgress: true }),
    [actionTypes.FETCH_NIC_VALUES_FULFILLED]: fulfilledNicValuesHandler,
    [actionTypes.FETCH_NIC_VALUES_REJECTED]: rejectedNicValuesHandler,
    [actionTypes.FETCH_NIC_FIELDS_PENDING]: state => ({ ...state, fetchNicFieldsInProgress: true }),
    [actionTypes.FETCH_NIC_FIELDS_FULFILLED]: fulfilledNicFieldsHandler,
    [actionTypes.FETCH_NIC_FIELDS_REJECTED]: rejectedNicFieldsHandler,
  },
  initialState,
);
