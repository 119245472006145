import React from 'react';
import { object, func } from 'prop-types';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import AssignManager from '../../../../AssignManager';

const MANAGER_PROPERTY = 'retention_manager';

const propTypes = {
  user: object.isRequired,
  onAssign: func.isRequired,
};

const text = {
  SALES_MANGER: gettext('Retention Manager'),
  GROUP_USERS: gettext('Users'),
  GROUP_TEAMS: gettext('Teams'),
  GROUP_DEFAULT: gettext('Default'),
  NONE: gettext('None'),
  SUCCESS_MESSAGE: gettext('Retention manager successfully updated'),
};

const RetentionManagerController = ({ onAssign, ...rest }) => (
  <AssignManager
    {...rest} 
    text={text} 
    managerProperty={MANAGER_PROPERTY} 
    onSuccess={onAssign} 
  />
);

RetentionManagerController.propTypes = propTypes;

export default RetentionManagerController;
