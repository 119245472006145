import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const actionTypes = {
  FETCH_CAMPAIGN: 'FETCH_CAMPAIGN_MARKETING',
  FETCH_CAMPAIGN_PENDING: 'FETCH_CAMPAIGN_MARKETING_PENDING',
  FETCH_CAMPAIGN_FULFILLED: 'FETCH_CAMPAIGN_MARKETING_FULFILLED',
  FETCH_CAMPAIGN_REJECTED: 'FETCH_CAMPAIGN_REJECTED',
};

const marketingApi = user => getDjangoApi(`users/${user.id}/marketing_data`);
const campaignApi = campaign => getDjangoApi(`team/campaigns/${campaign.id}/`);

export const fetchCampaign = createAction(
  actionTypes.FETCH_CAMPAIGN, async (user) => { 
    const marketingResponse = await marketingApi(user).retrieve();
    const campaign = _.get(marketingResponse, 'data.invited_by_link.campaign');
    if (!campaign) return {};
    const campaignResponse = await campaignApi(campaign).retrieve();
    return campaignResponse.data;
  },
);

const initialState = {
  campaign: {},
  campaignInProgress: false,
  campaignErrors: null,
};

const fetchFulfilled = (state, { payload }) => ({
  ...state,
  campaignInProgress: false,
  campaign: payload,
});

export default handleActions(
  {
    [actionTypes.FETCH_CAMPAIGN_PENDING]: state => ({ ...state, campaignInProgress: true }),
    [actionTypes.FETCH_CAMPAIGN_REJECTED]: (state, { payload }) => ({ ...state, campaignInProgress: false, campaignErrors: payload.data }),
    [actionTypes.FETCH_CAMPAIGN_FULFILLED]: fetchFulfilled,
  },
  initialState,
);
