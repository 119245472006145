import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import overlayActions from '../../../../../../components/overlay';
import connect from '../../../../../../logic/connect';
import DashboardPage from './DashboardPage';
import { setDate, updateDashboard, fetchDashboards, fetchDashboard, resetDashboard, setDashboard } from './Model';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';

const mapStateToProps = ({ pages }) => {
  const { startDate, endDate, dashboard, dashboards } = pages.DASHBOARD_LAYOUT;
  return {
    startDate,
    endDate,
    dashboard,
    dashboards,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    openModal: overlayActions.open,
    openSidepanel: overlayActions.open,
    setDate,
    updateDashboard,
    fetchDashboards,
    fetchDashboard,
    setDashboard,
    resetDashboard,
  }, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(DashboardPage); 
