export default form => ({
  FORM_OPTIONS: `${form}_FORM_OPTIONS`,
  FORM_OPTIONS_PENDING: `${form}_FORM_OPTIONS_PENDING`,
  FORM_OPTIONS_FULFILLED: `${form}_FORM_OPTIONS_FULFILLED`,
  FORM_RESET: `${form}_FORM_RESET`,
  FORM_RESET_VALUES: `${form}_FORM_RESET_VALUES`,
  FORM_SET_ERRORS: `${form}_FORM_SET_ERRORS`,
  FORM_SET_VALIDATORS: `${form}_FORM_SET_VALIDATORS`,
  FORM_VALIDATE: `${form}_FORM_VALIDATE`,
  FORM_SET_FIELD_VALUE: `${form}_FORM_SET_FIELD_VALUE`,
  FORM_SET_ROW_DATA: `${form}_FORM_SET_ROW_DATA`,
  FORM_SET_FIELDS: `${form}_FORM_SET_FIELDS`,
  FORM_CREATE: `${form}_FORM_CREATE`,
  FORM_CREATE_PENDING: `${form}_FORM_CREATE_PENDING`,
  FORM_CREATE_FULFILLED: `${form}_FORM_CREATE_FULFILLED`,
  FORM_CREATE_REJECTED: `${form}_FORM_CREATE_REJECTED`,
  FORM_UPDATE: `${form}_FORM_UPDATE`,
  FORM_UPDATE_PENDING: `${form}_FORM_UPDATE_PENDING`,
  FORM_UPDATE_FULFILLED: `${form}_FORM_UPDATE_FULFILLED`,
  FORM_UPDATE_REJECTED: `${form}_FORM_UPDATE_REJECTED`,
  FORM_SHOW_LOADER: `${form}_FORM_SHOW_LOADER`,
});
