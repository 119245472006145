import { bindActionCreators } from 'redux';

import overlayActions from '../../../../../../../components/overlay';
import AddMethod, { SIDEPANEL_ID } from './AddMethod';
import connect from '../../../../../../../logic/connect';
import { FormActionsFactory } from '../../../../../../../components/form';

const { create, update, resetFields, setRowData } = FormActionsFactory(SIDEPANEL_ID);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      create, 
      update, 
      resetFields, 
      setRowData,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ overlays, forms }) => {
  const { submitInProgress } = forms[SIDEPANEL_ID];
  return {
    sidepanel: overlays[SIDEPANEL_ID],
    submitInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMethod);
