
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import overlayActions from '../../../../../components/overlay';
import MappingTable, { reducerKey } from './MappingTable';

export default (tableKey) => {
  const mapStateToProps = ({ pages }) => ({
    table: pages[reducerKey].tables[tableKey],
  });
  
  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
      {
        openSidepanel: overlayActions.open,
      },
      dispatch,
    ), 
    dispatch,
  });
  
  return connect(mapStateToProps, mapDispatchToProps)(MappingTable);
};
