import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  MASK_PERSONAL_DETAILS: 'MASK_PERSONAL_DETAILS',
  MASK_PERSONAL_DETAILS_FULFILLED: 'MASK_PERSONAL_DETAILS_FULFILLED',
  MASK_PERSONAL_DETAILS_PENDING: 'MASK_PERSONAL_DETAILS_PENDING',
  MASK_PERSONAL_DETAILS_REJECTED: 'MASK_PERSONAL_DETAILS_REJECTED',
};

export const maskPersonalDetails = createAction(actionTypes.MASK_PERSONAL_DETAILS, async (user) => {
  const blacklistApi = getDjangoApi(`users/${user.id}/mask_personal_data`);
  
  await blacklistApi.create({});
});


const initialState = {
  submitInProgress: false,
  errors: null,
};

const pendingHandler = state => ({ ...state, submitInProgress: true });

const fulfilledHandler = state => ({
  ...state,
  submitInProgress: false,
  error: null,
});

const rejectedHandler = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  errors: payload.data,
});

export default handleActions(
  {
    [actionTypes.MASK_PERSONAL_DETAILS_PENDING]: pendingHandler,
    [actionTypes.MASK_PERSONAL_DETAILS_FULFILLED]: fulfilledHandler,
    [actionTypes.MASK_PERSONAL_DETAILS_REJECTED]: rejectedHandler,
  },
  initialState,
);
