import withTable, { GridTab } from '../../../../../../components/grid/withTable';

const tableConfig = id => ({
  apiUrl: `ib/${id}/tracking_links/`,
  reducerKey: 'USER_SINGLE',
  tableKey: 'IB_TRACKING_LINKS',
});

export default withTable(GridTab, tableConfig, null, {
  hideSegments: true,
  gridConfig: {
    getRowNodeId: data => data.name,
  },
});
