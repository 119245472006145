import appRoutes from '../../../../components/App/Router/appRoutes';
import EmailTemplateUpdate from './EmailTemplateUpdate';

export { default } from './EmailTemplateUpdate';

export const EmailTemplate = {
  component: EmailTemplateUpdate,
  path: appRoutes.EMAIL_TEMPLATE,
  aclId: 'emails.templates.update',
};


export const EmailTemplateCreate = {
  component: EmailTemplateUpdate,
  path: appRoutes.EMAIL_TEMPLATE_CREATE,
  aclId: 'emails.templates.create',
};
