import React, { Component, Fragment } from 'react';
import { func } from 'prop-types';
import { notifier } from 'tc-biq-design-system';
import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import modifiers from './modifiers';
import EditOnfidoRiskSidepanel, { sidepanelID, api } from './EditOnfidoRiskSidepanel';
import to from '../../../../../../logic/utilities/to';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../logic/services/acl';

const countryRisksConfig = () => ({
  reducerKey: 'RISK_RATING_SYSTEM',
  apiUrl: 'settings/risk_rating/kyc_result',
  tableKey: 'ONFIDO_TABLE',
});

const text = {
  deleteSuccessMsg: gettext('Risk rating deleted successfully'),
};

const propTypes = {
  openSidePanel: func.isRequired,
  closeSidePanel: func.isRequired,
  dispatch: func.isRequired,
};

class Onfido extends Component {
  constructor(props) {
    super(props);

    this.tableData = withTable(GridTab, countryRisksConfig, null, { withActions: true })();
    const { openSidePanel } = this.props;
    this.actions = {
      modify: hasAccess('settings.risk_rating.kyc_result.update') && ((data) => { openSidePanel(sidepanelID, data); }),
      remove: hasAccess('settings.risk_rating.kyc_result.destroy') && this.onRemove,
    };
  }

  onRemove = async ({ id }) => {
    const { dispatch } = this.props;
    const [err] = await to(api.destroy(id));

    if (!err) {
      notifier.success(text.deleteSuccessMsg);
      dispatch(this.tableData[1].fetchTableData());
    }
  }

  render() {
    const [CountryRiskTable, actions] = this.tableData;
    const { closeSidePanel } = this.props;

    return (
      <Fragment>
        <CountryRiskTable
          singleActions={!!(this.actions.modify || this.actions.remove)}
          modifier={modifiers(this.actions)}
        />
        <EditOnfidoRiskSidepanel 
          fetchTableData={actions.fetchTableData}
          closeSidePanel={closeSidePanel}
        />
      </Fragment>
    );
  } 
}

Onfido.propTypes = propTypes;

export default Onfido;
