import { createAction } from 'redux-actions';

import { getDjangoApi } from '../../../../../logic/services/api-factory';
import store from '../../../../../logic/store';
import actionTypes from './activityActionTypes';

const ENV = window.config.environment;

const activityApi = getDjangoApi('activity_stream');

const resetTypeFilter = (typeFilter) => {
  const values = _.get(typeFilter, 'value', {});
  const allSelected = Object.keys(values).every(key => values[key]);
  if (!allSelected) return typeFilter;

  return {
    ...typeFilter,
    query: null,
  };
};

const getFilterQueries = () => {
  const { filters, activities } = store.getState().pages.ACTIVITY_STREAM;
  const { dateTextFilters: { dateFilter, textSearchFilter }, typeFilter } = filters;
  const { firstLevelQuery } = activities;
  const modifiedTypeFilter = resetTypeFilter(typeFilter);
  return {
    ...firstLevelQuery,
    ...dateFilter.query,
    ...modifiedTypeFilter.query,
    ...textSearchFilter.query,
  };
};

const loadActivity = createAction(actionTypes.LOAD_ACTIVITY, async (highlightNew) => {
  const response = await activityApi.list({ limit: 20, offset: 0, ...getFilterQueries() });
  return { data: response.data, highlightNew };
});
const loadMore = createAction(actionTypes.LOAD_MORE, async () => {
  const { limit, offset } = store.getState().pages.ACTIVITY_STREAM.activities;
  const filterQuery = {
    limit,
    offset,
    ...getFilterQueries(),
  };
  const response = await activityApi.list(filterQuery);
  return { data: response.data };
});
const updateNewActivityCount = createAction(actionTypes.UPDATE_NEW_ACTIVITY_COUNT, data => ({
  data,
}));
const resetNewActivityCount = createAction(actionTypes.RESET_NEW_ACTIVITY_COUNT);
const setFirstLevelQuery = createAction(actionTypes.SET_FIRST_LEVEL_QUERY);
const resetOffset = createAction(actionTypes.RESET_OFFSET);
const resetActivityStream = createAction(actionTypes.RESET_ACTIVITY_STREAM);

export default {
  loadActivity,
  loadMore,
  updateNewActivityCount,
  resetNewActivityCount,
  setFirstLevelQuery,
  resetOffset,
  resetActivityStream,
};
