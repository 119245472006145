import React, { Component } from 'react'; 
import isEmpty from 'lodash/isEmpty';
import { Button } from 'tc-biq-design-system';
import classNames from 'classnames';
import { string, func, number, object, bool } from 'prop-types';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import fieldsEnum from './fieldsEnum';

import './SalesSpeech.scss';

import { gettext } from '../../../../../../logic/utilities/languageUtility';
import BiqAccordion from '../../../../../../components/BiqAccordion';

const getCallTipsApi = id => getDjangoApi(`users/${id}/call_tips`);

const text = {
  ABOUT: gettext('About the Client'),
  FIRST_INTERESTED_IN: gettext('First interested in'),
  LAST_INTERESTED_IN: gettext('Last interested in'),
  ACTIVITY: gettext('Activity'),
  EXPAND_ALL: gettext('Expand all'),
  REFRESH: gettext('Refresh'),
};

const accordions = [{
  key: 'about',
  title: text.ABOUT,
},
{
  key: 'first_interested_in',
  title: text.FIRST_INTERESTED_IN,
},
{
  key: 'last_interested_in',
  title: text.LAST_INTERESTED_IN,
},
{
  key: 'activity',
  title: text.ACTIVITY,
}];

const Field = ({ label, render }) => {
  if (!render) {
    return null;
  }
  
  return (
    <div className="sales-speech-section__field">
      <div className="sales-speech-section__label">{ label }</div>
      <div className="sales-speech-section__value">{ render() }</div>
    </div>
  );  
};

Field.propTypes = {
  label: string.isRequired,
  render: func.isRequired,
};

const renderDefault = value => value || '-';

const SalesSpeechAccordion = ({ fetchCallTipsData, isLoading, visible, title, onClick, data, name, showRefreshButton }) => (
  <div className="accordion-section" key={name}>
    <BiqAccordion 
      title={!showRefreshButton ? title : <div>{ title } <Button onClick={fetchCallTipsData} loading={isLoading} disabled={isLoading} color="transparent" style={{ marginLeft: '20px' }}> { text.REFRESH } </Button> </div>} 
      isVisible={visible} 
      onClick={() => { onClick(name); }}
    >
      <div className={classNames('sales-speech-section__body', {
        'sales-speech-section__body--visible': visible,
      })}
      >
        {
           Object.keys(data).map((key) => {
             const { render, label } = fieldsEnum[key] || {};
             return (
               <Field 
                 label={showRefreshButton ? key : label}
                 render={showRefreshButton ? () => renderDefault(data[key]) : (render ? () => render(data[key]) : null)} // eslint-disable-line 
               />
             ); 
           })
         } 
      </div>
    </BiqAccordion>
  </div>
);

SalesSpeechAccordion.propTypes = {
  visible: bool.isRequired,
  title: string.isRequired,
  onClick: func.isRequired,
  data: object.isRequired,
  name: string.isRequired,
  showRefreshButton: string.isRequired,
  isLoading: bool.isRequired,
  fetchCallTipsData: func.isRequired,
};

class SalesSpeech extends Component {
  constructor(props) {
    super(props);

    this.state = {
      callTipsData: {},
      isLoading: false,
      visibility: accordions.reduce((acc, accordion) => ({
        ...acc,
        [accordion.key]: true,
      }), {}),
    };
  }
  

  componentDidMount() {
    this.fetchCallTipsData();
  }
  
  onClickAccordion = (key) => {
    const { visibility } = this.state;
    this.setState({
      visibility: {
        ...visibility,
        [key]: !visibility[key],
      },
    });
  }
  
  fetchCallTipsData = () => {
    const { id } = this.props;
    this.setState({ isLoading: true });
    getCallTipsApi(id)
      .list()
      .then((res) => {
        this.setState({ callTipsData: res.data, isLoading: false });
      });
  }

  updateAccordions = (visible) => {
    const { visibility } = this.state; 
    this.setState({
      visibility: Object.keys(visibility).reduce((acc, key) => ({
        ...acc,
        [key]: visible,
      }), {}),
    });
  };

  render() {
    const { visibility, callTipsData, isLoading } = this.state;
    const couldExpandAll = Object.keys(visibility).reduce((acc, key) => acc && visibility[key], true);

    return (
      <React.Fragment>
        <div className="biq-profile__wrapper sales-speech-section">
          {
           !isEmpty(callTipsData) && (
           <Button onClick={() => this.updateAccordions(!couldExpandAll)} style={{ alignSelf: 'flex-end' }} color="transparent" iconPosition="right" icon={!couldExpandAll ? 'CaretDown' : 'CaretUp'}>
             { !couldExpandAll ? gettext('Expand all') : gettext('Collapse all') }
           </Button>
           )
          }
          { !isEmpty(callTipsData) 
          && accordions.map(accordion => (
            <SalesSpeechAccordion
              showRefreshButton={accordion.key === 'last_interested_in'}
              key={accordion.key}
              visible={visibility[accordion.key]}
              title={accordion.title}
              onClick={this.onClickAccordion}
              name={accordion.key}
              data={callTipsData[accordion.key] || {}}
              isLoading={isLoading}
              fetchCallTipsData={this.fetchCallTipsData}
            />
          ))}
        </div>
      </React.Fragment>
    );
  }
}

SalesSpeech.propTypes = {
  id: number.isRequired,
};

export default SalesSpeech;
