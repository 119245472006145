import connect from '../../../../../logic/connect';
import SignupUsersTable, { reducerKey, tableConfig } from './SignupUsersTable';

const mapStateToProps = ({ pages }) => ({
  table: pages[reducerKey].tables[tableConfig({}).tableKey],
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupUsersTable);
