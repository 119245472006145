/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Sidepanel, notifier } from 'tc-biq-design-system';

import connect from '../../../../../logic/connect';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import to from '../../../../../logic/utilities/to';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import overlayActions from '../../../../../components/overlay';
import FormFactory, { FormActionsFactory } from '../../../../../components/form';
import { SidepanelFooter } from '../../../../../components/common';
import { formErrorHandler } from '../../../../../components/form/logic/utils';

const SIDEPANEL_ID = 'KYC_BLACKLIST_SIDEPANEL';
const api = getDjangoApi('kyc/blacklist');
const { create, update, setRowData } = FormActionsFactory(SIDEPANEL_ID);

const text = {
  TITLE_ADD: gettext('Add user to blacklist'),
  TITLE_EDIT: gettext('Edit blacklisted User'),
  ADD_BUTTON_LABEL: {
    confirm: gettext('Add to blacklist'),
  },
  EDIT_BUTTON_LABEL: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
  ERROR_ADD: gettext('Error while creating reason'),
  ERROR_EDIT: gettext('Edit Blacklist reason failed'),
  SUCCESS_ADD: gettext('Blacklist reason added successfully'),
  SUCCESS_EDIT: gettext('Blacklist reason edited successfully'),
};

const formConfig = {
  form: SIDEPANEL_ID,
  api,
  excludeFields: ['added_by'],
};

const customFooter = (isEdit, onClose, onConfirm, submitInProgress) => (
  <SidepanelFooter
    execute={onConfirm}
    close={onClose}
    submitInProgress={submitInProgress}
    confirmColor="primary"
    buttonLabels={isEdit ? text.EDIT_BUTTON_LABEL : text.ADD_BUTTON_LABEL}
    formId={SIDEPANEL_ID}
  />
);

class BlacklistSidepanel extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.closeSidepanel = this.closeSidepanel.bind(this);
  }

  componentDidUpdate() {
    const { blacklist, actions } = this.props;
    if (blacklist) actions.setRowData(blacklist);
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.closeOverlay(SIDEPANEL_ID);
  }

  closeSidepanel(refreshTable) {
    const { actions } = this.props;
    actions.closeOverlay(SIDEPANEL_ID);
    if (refreshTable) {
      const { onSuccess } = this.props;
      onSuccess();
    }
  }

  async submit() {
    const { actions, isEdit, blacklist } = this.props;
    const ERROR = isEdit ? text.ERROR_EDIT : text.ERROR_ADD;
    const SUCCESS = isEdit ? text.SUCCESS_EDIT : text.SUCCESS_ADD;

    const request = blacklist && blacklist.id 
      ? actions.update(api, blacklist.id) 
      : actions.create(api);

    const [err] = await to(request);
    if (err) {
      formErrorHandler(ERROR)(err);
    } else {
      this.closeSidepanel(true);
      notifier.success(SUCCESS);
    }
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    const { FormComponent } = this;
    return <FormComponent formId={SIDEPANEL_ID} />;
  }

  render() {
    const { visible, isEdit, submitInProgress } = this.props;
    const TITLE = isEdit ? text.TITLE_EDIT : text.TITLE_ADD;
    return (
      <Sidepanel
        type="error"
        icon="User"
        title={TITLE}
        onCloseIconClick={() => this.closeSidepanel()}
        visible={visible}
        footerRender={() => customFooter(isEdit, this.closeSidepanel, this.submit, submitInProgress)}
      >
        {this.renderForm()}
      </Sidepanel>
    );
  }
}

const mapStateToProps = ({ overlays, forms }) => {
  const sidepanel = overlays[SIDEPANEL_ID];
  const { fields, blacklist } = sidepanel.parameters || {};
  const form = forms[SIDEPANEL_ID];

  return {
    visible: sidepanel.visible,
    isEdit: !!blacklist,
    fields,
    blacklist,
    errors: form.errors,
    submitInProgress: form.submitInProgress,
    form: form.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeOverlay: overlayActions.close,
      create,
      update,
      setRowData,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlacklistSidepanel);
