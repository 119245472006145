import { createAction, handleActions } from 'redux-actions';

const actionTypes = {
  FETCH_TOS: 'FETCH_TOS',
  FETCH_TOS_FULFILLED: 'FETCH_TOS_FULFILLED',
};

export const fetchTos = createAction(actionTypes.FETCH_TOS, async (api) => {
  const { data } = await api.list();
  return data.results;
});

const initialState = {
  data: [],
};

const fetchFulfilled = (_state, { payload }) => ({
  data: payload,
});

export default handleActions(
  {
    [actionTypes.FETCH_TOS_FULFILLED]: fetchFulfilled,
  },
  initialState,
);
