export const fields = {
  IS_BANK_DETAILS_REQUIRED: 'is_bank_acc_details_required',
  BANK_NAME: 'bank_account_data_bank_name',
  BANK_CITY: 'bank_account_data_bank_city',
  ACCOUNT_NUMBER: 'bank_account_data_bank_account_number',
  ACCOUNT_HOLDER: 'bank_account_data_account_holdername',
  SWIFT_CODE: 'bank_account_data_swift_code',
  ROUTING_CODE: 'bank_account_data_routing_code',
  IBAN: 'bank_account_data_iban',
  SORT_CODE: 'bank_account_data_sort_code',
  ROUTING_NUMBER: 'bank_account_data_routing_number',
  IFSC_CODE: 'bank_account_data_ifsc_code',
};

export const DEFAULT_FIELDS = [
  fields.BANK_NAME,
  fields.BANK_CITY,
  fields.ACCOUNT_NUMBER,
  fields.ACCOUNT_HOLDER,
];

export const FIELDS_ORDER = [
  fields.BANK_NAME,
  fields.BANK_CITY,
  fields.ACCOUNT_NUMBER,
  fields.ACCOUNT_HOLDER,
  fields.SWIFT_CODE,
  fields.ROUTING_CODE,
  fields.IBAN,
  fields.SORT_CODE,
  fields.ROUTING_NUMBER,
  fields.IFSC_CODE,
];
