import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import { notifier, Select } from 'tc-biq-design-system';

import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import to from '../../../../../../../logic/utilities/to';


const translate = {
  GENERAL_ERROR: gettext('Something went wrong'),
};

const propTypes = {
  user: object.isRequired,
  onSuccess: func,
  text: object.isRequired,
  retentionProperty: string,
};

const defaultProps = {
  retentionProperty: 'retention_pool_desk',
  onSuccess: null,
};

class SelectRetentionPool extends Component {
  constructor(props) {
    super(props);
    this.api = user => getDjangoApi(`users/${user.id}/assign_rpd`);
    this.submit = (api, data) => api.create(data);
    this.loadOptions = _.debounce(this.loadOptions, 500, { leading: true });

    this.state = {
      options: [],
      selected: null,
    };
  }

  componentDidMount = () => {
    const { options } = this.state;
    if (_.isEmpty(options)) this.loadOptions().then(() => this.setRetentionPoolDesk());
  };

  componentDidUpdate = (prevProps) => {
    const { user, retentionProperty } = this.props;
    const { user: prevUser } = prevProps;
    if (user && prevUser && user[retentionProperty] !== prevUser[retentionProperty]) {
      this.setRetentionPoolDesk && this.setRetentionPoolDesk();
    }
  }

  onSubmit = async (newValue) => {
    const { retentionProperty, user } = this.props;

    this.setState({
      selected: newValue,
    });

    const [err] = await to(this.submit(this.api(user), { [retentionProperty]: newValue ? newValue.id : null }));

    err ? this.onError(err) : this.onSuccess(newValue);
  }

  onSuccess = (manager) => {
    const { text, onSuccess, retentionProperty } = this.props;
    onSuccess && onSuccess(manager, retentionProperty);
    notifier.success(text.SUCCESS_MESSAGE);
  }

  onError = ({ data }) => {
    const { non_field_errors, messages } = data;
    if (non_field_errors) {
      notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
    } else if (messages) {
      notifier.error(data.messages.map(err => <span>{err.text}</span>));
    } else {
      notifier.error(translate.GENERAL_ERROR);
    }
  }

  setRetentionPoolDesk = () => {
    const { user, retentionProperty, text } = this.props;
    const { options } = this.state;

    const selected = options.find(option => option.id === user[retentionProperty]) || { id: 'none', label: text.NONE };

    this.setState({
      selected,
    });
  }

  getOptions = (res) => {
    const { retentionProperty } = this.props;

    const fields = _.get(res, 'data.actions.POST.fields');
    const retentionPoolDeskOptions = fields.find(
      element => element.key === retentionProperty);
    return retentionPoolDeskOptions.choices.map(this.formatOption);
  };


  loadOptions = () => {
    const { user } = this.props;
    return this.api(user).options().then((res) => {
      const options = this.getOptions(res);

      this.setState({
        options,
      });
    });
  }

  formatOption = (lang = {}) => ({ label: lang.display_name, id: lang.value });

  render() {
    const { text } = this.props;
    const { options, selected } = this.state;

    return (
      <div title={(selected && selected.label) || ''}>
        <Select
          smallTitle
          group
          type="search"
          label={text.RETENTION_POOL_DESK}
          value={selected}
          options={options}
          onInputChange={this.loadOptions}
          onChange={this.onSubmit}
          clearable={!_.isEmpty(selected)}
        />
      </div>
    );
  }
}

SelectRetentionPool.propTypes = propTypes;
SelectRetentionPool.defaultProps = defaultProps;

export default SelectRetentionPool;
