
import React from 'react';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import withTable from '../../../../../../components/grid/withTable';
import Documents from '../Documents/Documents';
import { tableConfig } from './table';

const IbDocuments = props => <Documents isIb {...props} />;

export default {
  Component: withTable(IbDocuments, tableConfig, null, { hideSegments: true }),
  label: gettext('Documents'),
  id: 'ib-documents',
  visibilityFn: (hasAccess, _isFeatureEnabled, isIb) => hasAccess('ib.documents.list') && isIb,
};
