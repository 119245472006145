import React, { PureComponent } from 'react';
import { Sidepanel, Input, notifier, Checkbox, Radio, Space } from 'tc-biq-design-system';

import { func, bool, object } from 'prop-types';

import connect from '../../logic/connect';
import overlayActions from '../overlay';

import { gettext } from '../../logic/utilities/languageUtility';
import isFeatureEnabled from '../../logic/filters/is-feature-enabled';

import { getDjangoApi } from '../../logic/services/api-factory';
import { segmentType } from './FilterTypes';
import to from '../../logic/utilities/to';
import AvailableTo from '../../pages/home/settings/customization/ClientProfileLayout/AvailableTo';
import { hasAccess } from '../../logic/services/acl';
import If from '../If';
import FormEl from '../form/Components/FormEl';

const api = getDjangoApi('segments');
const SIDEPANEL_ID = 'FILTER_VIEW_FORM';

const VISIBILITY = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  SHARED: 'shared',
};

const propTypes = {
  closeSidepanel: func.isRequired,
  visible: bool.isRequired,
  selectedSegment: segmentType.isRequired,
  isNew: bool.isRequired,
  changeSegment: func.isRequired,
  refreshOptions: func.isRequired,
  columnsState: object.isRequired,
  tableModifier: object,
};

const defaultProps = {
  tableModifier: {},
};

const text = {
  SAVE_VIEW: gettext('Save View'),
  VIEW_NAME: gettext('View name'),
  CANCEL: gettext('Cancel'),
  PRIVATE: gettext('Private'),
  SHARE_WITH: gettext('Share with'),
  SHARE_WITH_EVERYONE: gettext('Share with everyone'),
  SET_DEFAULT: gettext('Set this view as default for selected teams/members'),
};

const extractVisibleColumnsKeys = columns => columns
  .filter(column => column.visible)
  .map(column => column.key);

const formatRequest = ({ view, selectedSegment, is_public, filters, name, columnsState = [], available_to_teams = [], available_to_users = [] }) => ({
  view,
  ...selectedSegment,
  available_to_teams,
  available_to_users,
  filters,
  is_public,
  fields: extractVisibleColumnsKeys(columnsState),
  name,
});

const initialState = {
  name: '',
  visibility: VISIBILITY.PRIVATE,
  is_default: false,
};

class ViewFormSidepanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.close = this.close.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.save = this.save.bind(this);
    this.isDefaultViewsEnabled = isFeatureEnabled()('DEFAULT_VIEWS') && hasAccess('segments.set_as_team_default.*');
  }

  componentDidUpdate() {
    const { visible } = this.props;
    if (visible === false) return;
    if (_.isEqual(this.state, initialState)) {
      this.setInitialFormData();
    }
  }

  onSuccess({ data }) {
    const { changeSegment, refreshOptions, isNew, tableModifier } = this.props;
    if (isNew) {
      changeSegment(data, tableModifier);
    }

    refreshOptions(tableModifier);
    this.close();
  }

  onError(err) {
    console.log('on error', err);
  }

  onInputChange(event) {
    const { value } = event.target;
    this.setState({ name: value });
  }

  setInitialFormData() {
    const { isNew, selectedSegment } = this.props;

    if (isNew === false) {
      this.setState({
        name: selectedSegment.name,
      });
    }
  }

  setVisibility = (event) => {
    const { value } = event.target;
    this.setState({ visibility: value, is_public: value === VISIBILITY.PUBLIC });
  };

  onUsersChange = (available_to_users) => {
    this.setState({ available_to_users });
  }

  onTeamsChange = (available_to_teams) => {
    this.setState({ available_to_teams });
  }

  setAsDefault(id) {
    const { is_default } = this.state;
    const url = `segments/${id}/set_as_team_default`;
    const request = { set_as_default: is_default };

    getDjangoApi(url)
      .create(request)
      .then(
        () => notifier.success(gettext('New view successfully set as default')),
        () => notifier.error(gettext('Error while setting view as default')));
  }

  handleSetAsDefault = (is_default) => {
    this.setState({
      is_default,
    });
  }

  close() {
    const { closeSidepanel } = this.props;
    closeSidepanel(SIDEPANEL_ID);
    this.setState(initialState);
  }

  async save() {
    const { selectedSegment, isNew, activeFilters, view, columnsState } = this.props; // eslint-disable-line
    const { name, available_to_teams = [], available_to_users = [], is_public, is_default } = this.state;
    if (!name) return null;

    const promise = isNew ? api.create : payload => api.update(selectedSegment.id, payload);
    const payload = formatRequest({
      view,
      selectedSegment,
      filters: activeFilters,
      name,
      columnsState: columnsState.current,
      is_public,
      available_to_teams: available_to_teams.map(team => team.id),
      available_to_users: available_to_users.map(user => user.id),
    });

    const [err, segment] = await to(promise(payload));

    if (err) this.onError(err);
    if (is_default) {
      this.setAsDefault(segment.data.id);
    }
    if (segment) this.onSuccess(segment);
  }

  render() {
    const { visible } = this.props;

    const { name, available_to_users, available_to_teams, visibility, is_default } = this.state;
    return (
      <Sidepanel
        title={text.SAVE_VIEW}
        visible={visible}
        onCloseIconClick={this.close}
        confirmText={text.SAVE_VIEW}
        cancelText={text.CANCEL}
        onCancel={this.close}
        onConfirm={this.save}
        formId={SIDEPANEL_ID}
      >
        <FormEl id={SIDEPANEL_ID}>
          <Input
            placeholder={text.VIEW_NAME}
            label={text.VIEW_NAME}
            value={name}
            onChange={this.onInputChange}
          />
          <Space size={16} />
          <Radio.Group
            name="visibility"
            value={visibility || VISIBILITY.PRIVATE}
            horizontal
            onChange={this.setVisibility}
          >
            <Radio key={VISIBILITY.PRIVATE} value={VISIBILITY.PRIVATE}>
              {text.PRIVATE}
            </Radio>
            <Radio key={VISIBILITY.PUBLIC} value={VISIBILITY.PUBLIC}>
              {text.SHARE_WITH_EVERYONE}
            </Radio>
            <Radio key={VISIBILITY.SHARED} value={VISIBILITY.SHARED}>
              {text.SHARE_WITH}
            </Radio>
          </Radio.Group>
          <If condition={visibility === VISIBILITY.SHARED}>
            <Space size={16} />
            <AvailableTo
              type="users"
              onChange={this.onUsersChange}
              defaultValue={available_to_users || []}
              value={available_to_users}
              disabled={!hasAccess('layout.profile.assign_to_users.*')}
            />
            <Space size={16} />
            <AvailableTo
              type="teams"
              onChange={this.onTeamsChange}
              defaultValue={available_to_teams || []}
              value={available_to_teams}
              disabled={!hasAccess('layout.profile.assign_to_teams.*')}
            />
            <Space size={16} />
            {this.isDefaultViewsEnabled && <Checkbox checked={is_default} onChange={e => this.handleSetAsDefault(e.target.checked)}>{text.SET_DEFAULT}</Checkbox>}

          </If>
        </FormEl>
      </Sidepanel>
    );
  }
}

ViewFormSidepanel.propTypes = propTypes;
ViewFormSidepanel.defaultProps = defaultProps;

const mapStateToProps = ({ overlays, filters }) => {
  const sidepanel = overlays[SIDEPANEL_ID];
  const isNew = sidepanel.parameters ? sidepanel.parameters.isNew : false;
  return {
    activeFilters: filters.activeFilters,
    visible: sidepanel.visible,
    isNew,
  };
};

const mapDispatchToProps = {
  closeSidepanel: overlayActions.close,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewFormSidepanel);
