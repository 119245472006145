import { notifier } from 'tc-biq-design-system';
import { formErrorHandler } from '../../../../components/form/logic/utils';
import { gettext } from '../../../../logic/utilities/languageUtility';

const text = {
  CONDITION_ERROR_MESSAGE: gettext('Condition must exist in order to submit deposit method rule'),
};

// eslint-disable-next-line import/prefer-default-export
export const onRuleError = (errors) => {
  const errorMessages = errors.data;
  const hasFieldErrors = !!(errorMessages.name || errorMessages.method || errorMessages.condition);
  if (errorMessages && !hasFieldErrors) formErrorHandler()(errors);
  if (errorMessages && errors.data.condition) notifier.error(text.CONDITION_ERROR_MESSAGE);
};
