import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import IB_STATE from '../../../../../../../logic/enums/ib-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionTypes from '../types';
import Credit from '../Credit/Credit';

const text = {
  LABEL: gettext('Credit'),
};

const ACTION_ICON = 'Deposit';

export default {
  name: 'ibManualDeposit',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.IB_PAYMENTS,
  hasPermission(state, user, isIb) {
    if (isIb) {
      return !user.is_archived && hasAccess('ib.deposit.create') && state !== IB_STATE.INCOMPLETE;
    }

    return false;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
      key: 'credit',
    };

    sidepanelManager.add(Credit, sidepanelOptions);
  },
};
