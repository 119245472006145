import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import TogglePanel from '../../../../../components/TogglePanel';
import CurrencyExchange from '../../../../../components/CurrencyExchange';
import { gettext } from '../../../../../logic/utilities/languageUtility';

const text = {
  DEBIT_CURRENCY_CONVERSION: gettext('Debit currency conversion'),
};

// eslint-disable-next-line import/prefer-default-export
export const Conversion = ({ amount, checked, conversionData, onConversionDataChange, onToggle, withdrawal, error }) => (
  <TogglePanel
    title={text.DEBIT_CURRENCY_CONVERSION}
    checked={checked}
    onToggle={onToggle}
  >
    {checked && (
      <CurrencyExchange
        amount={Number(amount || 0)}
        userId={_.get(withdrawal, 'user.id')}
        originalCurrency={withdrawal.wallet.currency.symbol}
        convertCurrency={conversionData.convertCurrency}
        convertedAmount={conversionData.convertedAmount}
        exchangeRate={conversionData.exchangeRate}
        onConversionDataChange={onConversionDataChange}
        error={error}
      />
    )}
  </TogglePanel>
);

Conversion.propTypes = {
  checked: bool.isRequired,
  onToggle: func.isRequired,
  amount: number,
  withdrawal: object.isRequired,
  conversionData: object.isRequired,
  onConversionDataChange: func.isRequired,
  error: string,
};

Conversion.defaultProps = {
  amount: 0,
  error: null,
};
