import React from 'react';
import { Button, Popconfirm } from 'tc-biq-design-system';

import { gettext } from '../../../../logic/utilities/languageUtility';
import ActionsCellWrapper from '../../../../components/gridCellRenderers/SingleActionCell';

const text = {
  POP_CANCEL: gettext('Cancel'),
  POP_CONFIRM: gettext('Archive'),
  POP_LABEL: gettext('You are about to archive excluded user'),
  TOOLTIP: gettext('Archive'),
  BUTTON: gettext('Archive'),
};

const actionIcon = {
  archive: (actionHandler, data) => (
    <Popconfirm
      key="archive"
      placement="top"
      icon="Approve"
      label={text.POP_LABEL}
      onConfirm={() => actionHandler(data)}
      buttonLabels={{ cancel: text.POP_CANCEL, confirm: text.POP_CONFIRM }}
    >
      <Button color="ghost" size="extra-small">
        {text.BUTTON}
      </Button>
    </Popconfirm>
  ),
};

const modifier = actions => ({
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    pinned: 'right',
    cellRendererFramework: ActionsCellWrapper(actions, actionIcon),
    width: 120,
  },
});

export default modifier;
