import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionTypes from '../types';
import TradingAccountForm from '../../TradingAccountForm';

const text = {
  LABEL: gettext('Edit trading account'),
};

const ACTION_ICON = 'Edit';

export default {
  name: 'editTradingAccount',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.TRADING_ACCOUNTS,
  hasPermission(state, _user, isIb) {
    const hasStatePermission = [
      USER_STATE.FULL,
      USER_STATE.LIMITED,
      USER_STATE.DEMO,
    ].includes(state);
    return !isIb 
    && hasAccess('user.trading_accounts.update') 
    && hasStatePermission;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
      isNew: false,
      key: 'edit-trading-account',
    };

    sidepanelManager.add(TradingAccountForm, sidepanelOptions);
  },
};
