import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionTypes from '../types';
import CreateTask from './CreateTask';

const text = {
  LABEL: gettext('Create a task'),
};

const ACTION_ICON = 'Tasks';

export default {
  name: 'createTask',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.TOP,
  hasPermission() {
    return hasAccess('tasks.create') || hasAccess('team.profile.tasks.create');
  },
  handler: (_sidepanelManager, _dispatch, user, _isIb, comboxManager) => {
    const comboxOptions = {
      title: text.LABEL,
      id: 'create-task-combox',
      user,
    };

    comboxManager.add(CreateTask, comboxOptions);
  },
};
