import React from 'react';
import { Pill } from 'tc-biq-design-system';
import { object } from 'prop-types';

const propTypes = {
  data: object.isRequired,
};

const TagsCell = ({ data }) => {
  if (!data.tags) return null;
  return (
    <div>
      {data.tags.map(tag => (
        <Pill key={tag}>{tag}</Pill>
      ))}
    </div>
  );
};

TagsCell.propTypes = propTypes;

export default TagsCell;
