import React from 'react';

import { MessageCell, TooltipCell, UsernameCell } from '../../../../components/gridCellRenderers';

// eslint-disable-next-line
const LinkUser = ({ previewUser, previewBiqUser }) => ({ value, data, colDef }) => {
  const field = data[colDef.field];
  if (!field) return <span />;
  const previewAction = field.is_staff ? previewBiqUser : previewUser;
  return UsernameCell(previewAction, { isStaff: field.is_staff })({ value, data, colDef });
};

export default actions => ({
  id: {
    width: 80,
  },
  message_id: {
    pinned: 'left',
    width: 260,
    cellRendererFramework: MessageCell(),
  },
  to_user: {
    cellRendererFramework: LinkUser(actions),
  },
  from_user: {
    cellRendererFramework: LinkUser(actions),
  },
  message_type: {
    cellRendererFramework: TooltipCell,
    width: 260,
    quickFilter: {},
  },
  subject: {
    cellRendererFramework: TooltipCell,
    width: 260,
  },
  destination: {
    cellRendererFramework: TooltipCell,
    width: 260,
  },
  category: {
    cellRendererFramework: TooltipCell,
    width: 260,
    quickFilter: {},
  },
});
