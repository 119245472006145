import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';

export class GenericGateway {
  constructor(name = '') {
    this.name = name;
    this.conversionEnabled = isFeatureEnabled()('CURRENCY_CONVERSION');
  }

  excludedFields() {
    const excluded = ['transaction_acquirer_external_id', 'auto_withdrawal_flow'];

    if (!this.isBank()) excluded.push('acquirer');

    return excluded;
  }

  disabledFields() {
    return [];
  }

  isVisible({ field, visibilityParams = {} }) {
    return !this.excludedFields(visibilityParams)
      .includes(field);
  }

  isDisabled(field, { isRefundFullyUtilized, isPayoutExpired, isRefundExpired, isPayoutFullyUtilized } = {}) {
    if (this.disabledFields().includes(field)) return true;
    if (field === 'payout_amount') return isPayoutFullyUtilized || isPayoutExpired;
    if (field === 'refund_amount') return isRefundFullyUtilized || isRefundExpired;
    return false;
  }

  isBank() {
    return this.name.toLowerCase().startsWith('bank');
  }

  hasDepositMatchingMethod() {
    return false;
  }

  getVisibleFields({ fields, visibilityParams = {} }) {
    const keys = Object.keys(fields);

    return keys.reduce((memo, key) => {
      const isVisible = this.isVisible({ field: key, visibilityParams });
      if (!isVisible) {
        return memo;
      }

      return ({
        ...memo,
        [key]: fields[key],
      });
    }, {});
  }

  canExecute() {
    return true;
  }
}
