import React, { PureComponent } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { bindActionCreators } from 'redux';

import FormActionsFactory from '../logic/actionsFactory';
import If from '../../If';
import renderField from './Field';
import FormSpinner from './FormSpinner';
import './Form.scss';
import FormEl from './FormEl';

const applyModifier = (field, modifier) => {
  if (typeof modifier === 'object') return { ...field, ...modifier };
  if (typeof modifier === 'function') return modifier(field);
  return field;
};

export const fieldsGenerator = (formId, fields, isFieldExcluded, modifiers) => fields.reduce((acc, field) => {
  const { id } = field;
  const modifier = modifiers[id];
  const fieldDef = modifier ? applyModifier(field, modifier) : { ...field };
  const Field = renderField({ formId, fieldKey: id, customOnChange: fieldDef.customOnChange });
  return { ...acc, [id]: isFieldExcluded && isFieldExcluded(id) ? null : <Field key={id} {...fieldDef} /> };
}, {});

export const nestedFieldsGenerator = (formId, fields, nestedFieldId, formModifiers = {}) => {
  const nestedData = fields.find(field => field.id === nestedFieldId);
  if (nestedData) {
    const children = nestedData.children.map((field) => {
      const newId = `${nestedData.id}_${field.id}`;
      return { ...field, id: newId, name: newId };
    });

    return fieldsGenerator(formId, children, false, formModifiers);
  }

  return [];
};

const propTypes = {
  formId: string.isRequired,
  actions: object.isRequired,
  fields: array.isRequired,
  api: object,
  excludeFields: array,
  includeFields: array,
  isLoading: bool,
  customFields: array,
  modifiers: object,
  renderForm: func,
  rerenderFields: bool,
  disableResetOnUnmount: bool,
  renderNested: bool,
  onFormReady: func,
  onSubmit: func,
};

const defaultProps = {
  excludeFields: [],
  includeFields: [],
  isLoading: false,
  api: null,
  customFields: [],
  modifiers: {},
  renderForm: null,
  rerenderFields: false,
  disableResetOnUnmount: false,
  renderNested: false,
  onFormReady: () => {},
  onSubmit: () => null,
};

class Form extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.renderFields = this.renderFields.bind(this);
    this.generateFields = this.generateFields.bind(this);
  }

  componentDidMount() {
    const { fields, api, customFields, onFormReady, renderNested } = this.props;
    if (_.isEmpty(fields)) {
      if (api) {
        this.actions.fetchFields(api, customFields, renderNested).then(() => onFormReady());
      } else {
        this.actions.setFields(customFields);
        onFormReady();
      }
    }

    this.setValidators();
  }

  componentWillUnmount() {
    const { disableResetOnUnmount } = this.props;

    if (!disableResetOnUnmount) {
      this.actions.resetFields();
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { onSubmit } = this.props;

    if (onSubmit) onSubmit();
  }

  isFieldExcluded(id) {
    const { excludeFields, includeFields } = this.props;

    if (excludeFields.includes(id)) {
      return true;
    }

    if (includeFields.length > 0) {
      return !includeFields.includes(id);
    }

    return false;
  }

  generateFields(formId, fields) {
    const { modifiers, rerenderFields } = this.props;
    if ((!this.fields || rerenderFields) && fields.length > 0) {
      this.fields = fieldsGenerator(formId, fields, this.isFieldExcluded.bind(this), modifiers);
    }

    return this.fields;
  }


  renderFields(formId, fields) {
    const { modifiers } = this.props;
    return fields.map((field) => {
      const { id } = field;
      const modifier = modifiers[id];
      const fieldDef = modifier ? applyModifier(field, modifier) : { ...field };
      const Field = renderField({ formId, fieldKey: id, customOnChange: fieldDef.customOnChange });
      return this.isFieldExcluded(id) ? null : <Field key={id} {...fieldDef} />;
    });
  }

  setValidators = () => {
    const { modifiers } = this.props;

    const validators = Object.keys(modifiers).reduce((acc, fieldKey) => {
      if (modifiers[fieldKey].validators) {
        return {
          ...acc,
          [fieldKey]: modifiers[fieldKey].validators,
        };
      }

      return acc;
    }, {});

    if (validators) this.actions.setValidators(validators);
  }

  render() {
    const { fields, isLoading, formId, renderForm } = this.props;
    const generatedFields = renderForm ? this.generateFields(formId, fields) : null;
    return (
      <div className="biq-form">
        <FormEl formId={formId} onSubmit={this.onSubmit}>
          <If condition={isLoading}>{FormSpinner}</If>
          <div className="biq-form__content">{ generatedFields ? renderForm(generatedFields) : this.renderFields(formId, fields)}</div>
        </FormEl>
      </div>
    );
  }
}

const mapStateToProps = form => ({ forms }) => {
  const { fields, isLoading } = forms[form];
  return { fields, isLoading };
};

const mapDispatchToProps = form => dispatch => ({
  actions: bindActionCreators(FormActionsFactory(form), dispatch),
});

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export {
  Form,
  mapStateToProps,
  mapDispatchToProps,
};
