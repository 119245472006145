import React, { Component, Fragment } from 'react';
import { object, bool, array } from 'prop-types';
import { Space, Sidepanel, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';
import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import to from '../../../../../../../logic/utilities/to';

const text = {
  TITLE: gettext('MT4 bonus'),
  SUCCESS: gettext('MT4 bonus successful.'),
  DIRECTION: gettext('Direction'),
  CREDIT: gettext('Credit'),
  DEBIT: gettext('Debit'),
  ERROR_GENERAL: gettext('Something went wrong!'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const FORM_KEY = 'MT4_BONUS_FORM';
const { create, setFieldValue } = FormActionsFactory(FORM_KEY);

const directionChoices = [
  {
    display_name: text.CREDIT,
    value: 'credit',
  }, {
    display_name: text.DEBIT,
    value: 'debit',
  },
];

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_KEY}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  submitInProgress: bool.isRequired,
  isIb: bool,
  formFields: array,
};

const defaultProps = {
  user: null,
  actions: null,
  isIb: false,
  formFields: [],
};

class Mt4Bonus extends Component {
  constructor(props) {
    super(props);

    this.api = user => getDjangoApi(`users/${user.id}/mt4_bonus/`);
  }

  componentDidUpdate(prevProps) {
    this.onFieldsLoaded(prevProps);
  }

  onFieldsLoaded = (prevProps) => {
    const prevFormFields = prevProps.formFields;
    const { formFields, actions } = this.props;
    
    if (_.isEmpty(prevFormFields) && !_.isEmpty(formFields)) {
      actions.setFieldValue({
        id: 'direction',
        value: directionChoices[0],
      });
    }
  }

  onSubmit = async () => {
    const { user, actions } = this.props;
    const [err] = await to(actions.create(this.api(user), this.getRequestPayload));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess = () => {
    this.onClose();
    notifier.success(text.SUCCESS);
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err.text}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  onClose = () => {
    const { sidepanelManager } = this.props;
    sidepanelManager.close();
  }

  getRequestPayload = (values) => {
    const preparedValues = _.reduce(values, (payload, field, key) => ({
      ...payload,
      [key]: typeof field === 'object' ? field.value : field,
    }), {});

    return { ...preparedValues, is_credit: preparedValues.direction === 'credit' };
  }

  getFormConfig = () => {
    const { user } = this.props;
  
    return {
      form: FORM_KEY,
      api: this.api(user),
      excludeFields: ['is_credit'],
      customFields: [
        {
          type: 'select',
          id: 'direction',
          name: 'direction',
          searchable: false,
          clearable: false,
          label: text.DIRECTION,
          options: directionChoices,
          valueKey: 'value',
          labelKey: 'display_name',
          joinValues: true,
        },
      ],
    };
  }

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.getFormConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { sidepanelManager, user, isIb, actions, submitInProgress, ...options } = this.props;
    const Form = this.renderForm();
    return (
      <Sidepanel {...options} footerRender={customFooter(this.onSubmit, this.onClose, submitInProgress)}>
        <Space size={16} />
        <Form 
          formId={FORM_KEY} 
          renderForm={formFields => (
            <Fragment>
              {formFields.trading_account}
              {formFields.amount}
              {formFields.direction}
              {formFields.expiration}
              {formFields.comment}
            </Fragment>
          )} 
        />
        <Space size={16} />
      </Sidepanel>
    );
  }
}
  

Mt4Bonus.propTypes = propTypes;
Mt4Bonus.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      create,
      setFieldValue,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ forms }) => {
  const form = forms[FORM_KEY];
  return {
    submitInProgress: form.submitInProgress,
    formFields: form.fields,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mt4Bonus);
