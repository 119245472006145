import React from 'react';
import { bindActionCreators } from 'redux';
import { object, bool } from 'prop-types';
import { notifier, Space, Sidepanel } from 'tc-biq-design-system';

import userState from '../../../../../../../logic/filters/user-state';
import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import { fetchUser } from '../../../Model';

const text = {
  QUESTION: context => gettext(
    'Are you sure you want to change the state of {{username}} from {{currentState}} to {{newState}}?',
    context,
  ),
  SUCCESS: gettext('User state successfully changed.'),
  ERROR: gettext('Error while changing user state.'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
  FULL: gettext('Full'),
  BLACKLIST: gettext('Blacklist'),
  EXCLUDED: gettext('Excluded'),
  LIMITED: gettext('Limited'),
};

const customFooter = (execute, close) => () => (
  <SidepanelFooter
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  isIb: bool,
};

const defaultProps = {
  user: null,
  actions: null,
  isIb: false,
};

const getNextState = (user) => {
  if (user.state === text.BLACKLIST || user.state === text.EXCLUDED) return userState(user.previous_state);
  if (user.state === text.FULL) return text.LIMITED;
};

const getAction = (state) => {
  switch (state) {
    case text.FULL:
      return 'set_as_limited';
    case text.EXCLUDED:
      return 'remove_from_exclusion';
    case text.BLACKLIST:
      return 'remove_from_blacklist';
  }
};

const ChangeUserState = ({ sidepanelManager, user, isIb, actions, ...options }) => {
  const onClose = () => {
    sidepanelManager.close();
  };

  const onSubmit = () => {
    const api = getDjangoApi(`users/${user.id}/${getAction(user.state)}`);
    
    api.create({}).then(
      () => { 
        notifier.success(text.SUCCESS);
        actions.fetchUser(user);
        sidepanelManager.close();
      }, () => notifier.error(text.ERROR));
  };

  return (
    <Sidepanel {...options} footerRender={customFooter(onSubmit, onClose)}>
      <Space size={16} />
      <div className="black-text">
        {text.QUESTION({
          username: user.username,
          currentState: user.state,
          newState: getNextState(user),
        })}
      </div>
    </Sidepanel>
  );
};

ChangeUserState.propTypes = propTypes;
ChangeUserState.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchUser }, dispatch),
});

export default connect(null, mapDispatchToProps)(ChangeUserState);
