import React from 'react';
import { func, number, oneOfType, string, node } from 'prop-types';

const propTypes = {
  label: string.isRequired,
  value: oneOfType([func, number, string, node]).isRequired,
};

const PanelRow = ({ label, value }) => (
  <div className="panel-body-line">
    <span>{label}</span>
    <span>{typeof value === 'function' ? value() : value}</span>
  </div>
);

PanelRow.propTypes = propTypes;
export default PanelRow;
