import React, { Fragment, PureComponent } from 'react';
import {
  Button,
  Dropdown,
  HyperLink,
  Input,
  notifier,
  Panel,
  Pill,
  Popconfirm,
  Sidepanel,
  Space,
} from 'tc-biq-design-system';
import { find } from 'lodash';
import { bindActionCreators } from 'redux';
import { bool, func, node, number, object, oneOfType, string } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import connect from '../../../../logic/connect';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import overlayActions from '../../../overlay';
import { gettext } from '../../../../logic/utilities/languageUtility';
import Extra from '../../../Extra';
import UserInfo from '../../../UserInfo';
import { currencyFormatter, dateTimeFormatter } from '../../../../logic/utilities/formatters';
import { tradingAccountDisplay } from '../../../../logic/filters/trading-account-display';
import isFeatureEnabled from '../../../../logic/filters/is-feature-enabled';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import to from '../../../../logic/utilities/to';
import { YesNoCell } from '../../../gridCellRenderers';
import withUnmountMethod from '../../../hoc/withUnmountMethod';
import ThirdPartyPayment from './ThirdPartyPayment';
import SecurityCheck from './SecurityCheck';
import WalletTransactions
  from '../../../../pages/home/withdrawal-single/Components/WalletTransactions';
import PanelRow from './PanelRow';
import { hasAccess } from '../../../../logic/services/acl';
import { paymentStatesLabels, paymentStatesValues } from '../../../../logic/enums/payment-states';
import AddExternalIdModal from './AddExternalIdModal';
import If from '../../../If';
import TagsCell from '../../../gridCellRenderers/TagsCell';
import { strToBool } from '../../../../utils/strToBool';
import ApproveAndReject from './ApproveAndReject';
import tpiStateLabel from '../../../../logic/enums/tpi-state-label';
import securityCheckStatus from '../../../../logic/enums/security-check-status';
import appRoutes from '../../../App/Router/appRoutes';
import paymentMethodTypes from '../../../../logic/enums/payment-method-types';

const SIDEPANEL_ID = 'TRANSACTION_SINGLE';
const chargeFeeApi = id => getDjangoApi(`payment_transactions/${id}/charge_fee`);
const env = window.config.environment;

const propTypes = {
  sidepanel: object.isRequired,
  actions: object.isRequired,
  isIb: bool,
};

const defaultProps = {
  isIb: false,
};

const chargeFeeProptypes = {
  onClick: func.isRequired,
  onChange: func.isRequired,
  inputValue: oneOfType([number, string]),
};

const chargeFeeDefaultProps = {
  inputValue: 0,
};

const text = {
  ACCEPT: gettext('ACCEPT'),
  REJECT: gettext('REJECT'),
  EXTRA: gettext('Extra'),
  TITLE: gettext('Trading account'),
  PANEL_TITLE: gettext('Transaction'),
  PANEL_TITLE_CARD: gettext('Card information'),
  PANEL_TITLE_WALLET: gettext('Wallet transactions'),
  DATE: gettext('Date'),
  EXCHANGE_RATE: gettext('Exchange rate'),
  LOCAL_CURRENCY: gettext('Local currency'),
  AMOUNT_IN_LOCAL_CURRENCY: gettext('Amount in local currency'),
  TYPE: gettext('Type'),
  AMOUNT: gettext('Amount'),
  METHOD: gettext('Method'),
  TRADING_ACCOUNT: gettext('Trading account'),
  GATEWAY: gettext('Gateway'),
  GATEWAY_ID: gettext('Transaction gateway ID'),
  TRANSACTION_ID: gettext('Transaction ID'),
  ERROR_MESSAGE: gettext('Error message'),
  ERROR_DESCRIPTION: gettext('Error description'),
  CHALLENGE: gettext('Challenge'),
  TP_EXTERNAL_ID: gettext('TP external ID'),
  TPI_STATE: gettext('TPI state'),
  TPI_ATTEMPTS: gettext('TPI attempts'),
  ACQUIRER: gettext('Acquirer'),
  METHOD_TYPE: gettext('Method type'),
  FINISHED: gettext('Finished'),
  SECURITY_CHECK: gettext('Security check'),
  ID: gettext('ID'),
  VALUE: gettext('Value'),
  CHARGE_FEE: gettext('Charge fee'),
  CHARGE: gettext('Charge'),
  CHARGE_FEE_CONFRIM: gettext('Are you sure you want to charge fee for this transaction?'),
  CHARGE_FEE_SUCCESS: gettext('Fee charged'),
  CHARGE_FEE_ERROR: gettext('Failed to charge fee'),
  BUTTON_LABELS: {
    cancel: gettext('No'),
    confirm: gettext('Yes'),
  },
  APPROVE_SUCCESS: gettext('Transaction approved!'),
  REJECT_SUCCESS: gettext('Transaction rejected!'),
  ERROR_MSG: gettext('Error occured!'),
  CARD_INFORMATION: gettext('Card information'),
  BRAND: gettext('Brand'),
  CARD: gettext('Card'),
  EXPIRY_DATE: gettext('Expiry date'),
  FUNDING: gettext('Funding'),
  CARDHOLDER_NAME: gettext('Cardholder name'),
  CREATED_DATE: gettext('Created date'),
  UPDATED_DATE: gettext('Updated date'),
  COUNTRY: gettext('Country'),
  TRANSACTION_EXTRA: gettext('Transaction extra'),
  CARD_EXTRA: gettext('Card extra'),
  PAYMENT_STATES: {
    SUCCESS: {
      label: paymentStatesLabels.SUCCESS,
      value: paymentStatesValues.SUCCESS,
      type: 'status01',
      icon: 'Checkmark',
    },
    FAILED: {
      label: paymentStatesLabels.FAILED,
      value: paymentStatesValues.FAILED,
      type: 'status04',
      icon: 'Warning',
    },
    REJECTED: {
      label: paymentStatesLabels.REJECTED,
      value: paymentStatesValues.REJECTED,
      type: 'status04',
      icon: 'Close',
    },
    CANCELLED: {
      label: paymentStatesLabels.CANCELLED,
      value: paymentStatesValues.CANCELLED,
      type: 'status04',
      icon: 'Warning',
    },
    DECLINED: {
      label: paymentStatesLabels.DECLINED,
      value: paymentStatesValues.DECLINED,
      type: 'status04',
      icon: 'Warning',
    },
    ACCEPTED: {
      label: paymentStatesLabels.ACCEPTED,
      value: paymentStatesValues.ACCEPTED,
      type: 'status03',
      icon: 'Pending',
    },
    PENDING: {
      label: paymentStatesLabels.PENDING,
      value: paymentStatesValues.PENDING,
      type: 'status03',
      icon: 'Pending',
    },
    CAPTURED: {
      label: paymentStatesLabels.CAPTURED,
      value: paymentStatesValues.CAPTURED,
      type: 'status03',
      icon: 'Pending',
    },
    REFUND: {
      label: paymentStatesLabels.REFUND,
      value: paymentStatesValues.REFUND,
      type: 'status03',
      icon: 'Warning',
    },
    REDIRECTED: {
      label: paymentStatesLabels.REDIRECTED,
      value: paymentStatesValues.REDIRECTED,
      type: 'primary',
      icon: 'Rules',
    },
    PROCESSING: {
      label: paymentStatesLabels.REDIRECTED,
      value: paymentStatesValues.REDIRECTED,
      type: 'primary',
      icon: 'Pending',
    },
    NA: paymentState => ({
      label: paymentState || paymentStatesLabels.NA,
      value: paymentState || paymentStatesValues.NA,
      type: 'neutral',
      icon: '',
    }),
  },
  PENDING_DEPOSIT_NAME: gettext('Pending deposit name'),
  CHANGE_STATE: gettext('Change state'),
  CHANGE_TPI_STATE: gettext('Change tpi state'),
  SUCCESS: gettext('Success'),
  FAILURE: gettext('Failure'),
  REJECTED: gettext('Rejected'),
  CHANGE_STATE_PROMPT: gettext('Are you sure that you want to change the Payment transaction state?'),
  CHANGE_STATE_SUCCESS: gettext('Successfully changed state'),
  CHANGE_TPI_STATE_SUCCESS: gettext('Successfully changed tpi state'),
  CLIENT_INFO: gettext('Clients information'),
  ASSIGNED_TO: gettext('Assigned to'),
  INVITED_BY: gettext('Invited by'),
  LAST_DEPOSIT_DATE: gettext('Last deposit date'),
  FIRST_DEPOSIT_DATE: gettext('First deposit date'),
  TAGS: gettext('Tags'),
  TEAM: gettext('Team'),
  NA: gettext('N/A'),
  RESEND: gettext('Resend'),
  RESEND_SUCCESS: gettext('Transaction resent!'),
};

const CHARGE_FEE_STYLE = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
};
const CHARGE_FEE_INPUT = { marginBottom: '5px', width: '100%' };

const setTypeIcon = (state, isSetIcon) => {
  switch (state) {
    case paymentStatesValues.SUCCESS:
      return isSetIcon ? 'Checkmark' : 'status01';
    case paymentStatesValues.FAILED:
      return isSetIcon ? 'Warning' : 'status04';
    case paymentStatesValues.PENDING:
      return isSetIcon ? 'Pending' : 'status03';
    case paymentStatesValues.PROCESSING:
      return isSetIcon ? 'Pending' : 'primary';
    default:
      return isSetIcon ? 'Close' : 'status04';
  }
};

const ChargeFee = ({ onClick, onChange, inputValue }) => (
  <div style={CHARGE_FEE_STYLE}>
    <Input
      type="number"
      label={text.CHARGE_FEE}
      onChange={e => onChange(e.target.value)}
      style={CHARGE_FEE_INPUT}
      value={inputValue}
    />
    <Space size={12} />
    <Popconfirm
      icon="Approve"
      label={text.CHARGE_FEE_CONFRIM}
      onConfirm={onClick}
      buttonLabels={text.BUTTON_LABELS}
    >
      <Button>{text.CHARGE}</Button>
    </Popconfirm>
  </div>
);

const Action = ({ children, onConfirm }) => (
  <Popconfirm
    buttonLabels={{ cancel: gettext('Cancel'), confirm: gettext('Change') }}
    icon="Approve"
    onConfirm={onConfirm}
    label={text.CHANGE_STATE_PROMPT}
  >
    { children }
  </Popconfirm>
);

Action.propTypes = {
  children: node.isRequired,
  onConfirm: func.isRequired,
};

const renderState = (state, onTpiChange) => {
  const actions = [
    {
      label: text.SUCCESS,
      onClick: () => { onTpiChange({ tpi_state: 100 }); },
    },
    {
      label: text.FAILURE,
      onClick: () => { onTpiChange({ tpi_state: 150 }); },
    },
  ];
  return (
    <>
      <If condition={state === paymentStatesValues.PENDING}>
        <Dropdown
          list={actions}
          customHeader={onToggle => (
            <Button
              color="primary"
              onClick={onToggle}
              size="small"
              icon="CaretDown"
              iconPosition="right"
            >
              {text.CHANGE_TPI_STATE}
            </Button>
          )}
        />
      </If>
      <Pill
        style={{ marginRight: '0' }}
        key="tpistate"
        type={setTypeIcon(state)}
        icon={setTypeIcon(state, true)}
        iconPosition="left"
      >
        {state}
      </Pill>
    </>
  ); 
};

const PendingDepositLink = ({ rule }) => (
  <HyperLink>
    <Link to={generatePath(appRoutes.SETTINGS_DEPOSITS)}> { rule }</Link>
  </HyperLink>
);

PendingDepositLink.propTypes = {
  rule: string.isRequired,
};

const TradingAccountLink = ({ userId, id, displayName }) => (
  <HyperLink>
    <Link to={generatePath(appRoutes.TRADING_ACCOUNT, {
      userId,
      accountId: id,
    })}
    >{displayName}
    </Link>
  </HyperLink>
);

TradingAccountLink.propTypes = { userId: string.isRequired, id: string.isRequired, displayName: string.isRequired };

const InvitedByLink = ({ user }) => {
  if (!user) return text.NA;
  return (
    <HyperLink>
      <If condition={!user.is_staff}>
        <Link to={generatePath(appRoutes.CLIENT_PROFILE, { id: user.id })}>{user.full_name || user.username || text.NA}</Link>
      </If>
      <If condition={user.is_staff}>
        <Link to={generatePath(appRoutes.TEAM_MEMBER, { id: user.id })}>{user.full_name || user.username || text.NA}</Link>
      </If>
    </HyperLink>
  );
};

InvitedByLink.propTypes = { user: object.isRequired };

const AssignedToLink = ({ user }) => {
  if (!user) return text.NA;
  return (
    <InvitedByLink user={user} />
  );
};

AssignedToLink.propTypes = { user: object.isRequired };

const TeamLink = ({ team }) => {
  if (!team) return text.NA;
  return (
    <HyperLink>
      <Link to={generatePath(appRoutes.TEAM, { id: team.id })}>{team.name || text.NA}</Link>
    </HyperLink>
  );
};

TeamLink.propTypes = { team: object.isRequired };


const formatTransactionPanelBodyData = (data, onTpiChange) => {
  const amount = data.value;
  const tpiValue = data.tpi_state ? renderState(data.tpi_state, onTpiChange) : null;
  const recipe = _.get(data, 'description[0]', null);
  const env = window.config.environment;

  const bodyData = [
    { label: text.TRANSACTION_ID, value: data.id },
    { label: text.DATE, value: dateTimeFormatter(data.date) },
    { label: text.TYPE, value: data.type },
    { label: text.AMOUNT, value: currencyFormatter(amount, data.currency.symbol) },
    { label: text.METHOD, value: data.method },
    { label: text.ACQUIRER, value: data.acquirer },
    { label: text.METHOD_TYPE, value: data.method_type || text.NA },
    ...(_.has(data, 'wallet.id') ? { label: text.TRADING_ACCOUNT, value: TradingAccountLink({ displayName: tradingAccountDisplay(data.wallet), id: data.wallet && data.wallet.id, userId: data.user.id }) } : {}),
    { label: text.GATEWAY, value: data.gateway },
    { label: text.GATEWAY_ID, value: data.external_id },
    { label: text.TP_EXTERNAL_ID, value: data.thirdparty_id },
    ...(env === 'AvaTrade' && [{ label: text.CHALLENGE, value: <YesNoCell value={strToBool(data.challenge)} /> }]),
    { label: text.ERROR_MESSAGE, value: data.error_msg || text.NA },
    { label: text.ERROR_DESCRIPTION, value: data.error_desc || text.NA },
    ...(env === 'AvaTrade' && [{ label: text.SECURITY_CHECK, value: data.security_check || text.NA }]),
    { label: text.TPI_STATE, value: tpiValue },
    ...(isFeatureEnabled()('TPI_TRANSACTION_RETRY') && [{ label: text.TPI_ATTEMPTS, value: data.request_attempt_number }]),
    { label: text.FINISHED, value: <YesNoCell value={!data.delayed} /> },
    { label: text.LOCAL_CURRENCY, value: data.original_currency },
    { label: text.AMOUNT_IN_LOCAL_CURRENCY, value: data.original_amount },
    { label: text.EXCHANGE_RATE, value: data.exchange_rate },
  ];

  if (recipe) {
    const { recipe_met } = recipe;
    return [...bodyData, { label: text.PENDING_DEPOSIT_NAME, value: PendingDepositLink({ rule: recipe_met }) }];
  }
  return bodyData;
};

const PanelBody = ({ tradeData, onTpiChange }) => {// eslint-disable-line
  const bodyData = formatTransactionPanelBodyData(tradeData, onTpiChange);
  return (
    <div className="card-panel-body">
      {bodyData
        .filter(line => !!line.value)
        .map((line, index) => <PanelRow label={line.label} value={line.value} key={line.value + index} />)}
    </div>
  );
};

class TransactionSingle extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingApproveTransaction: false,
      isLoadingRejectTransaction: false,
      isLoadingResendTransaction: false,
    };

    this.activate = this.activate.bind(this);
    this.chargeFee = this.chargeFee.bind(this);
    this.onChargeFeeChange = this.onChargeFeeChange.bind(this);
    this.renderTransactionHeaderStatus = this.renderTransactionHeaderStatus.bind(this);
    this.rejectTransaction = this.rejectTransaction.bind(this);
    this.approveTransaction = this.approveTransaction.bind(this);

    this.transactionApi = getDjangoApi('payment_transactions');
    this.cardInfoVisible = isFeatureEnabled()('CARD_INFORMATION');
    this.additionalInfoVisible = env !== 'AvaTrade';
    this.fraudCheckEnabled = isFeatureEnabled()('FRAUD_CHECK');
  }

  onChargeFeeChange(value) {
    const { actions, sidepanel } = this.props;
    actions.updateSidepanel(SIDEPANEL_ID, { ...sidepanel.parameters, chargeFeeAmount: value });
  }

  getPaymentState(payment_state, isIb, success) {
    if (!payment_state && isIb) return success ? paymentStatesLabels.SUCCESS : paymentStatesLabels.NA;

    return payment_state;
  }

  changePaymentState = async (value) => {
    const { sidepanel, actions } = this.props;
    const { parameters } = sidepanel;
    const changeStateApi = getDjangoApi(`payment_transactions/${parameters.id}/update_status`);

    try {
      await changeStateApi.updatePart(null, value);
      const { data } = await this.transactionApi.retrieve(parameters.id);
      notifier.success(text.CHANGE_STATE_SUCCESS);
      actions.updateSidepanel(SIDEPANEL_ID, data);
    } catch (e) {
      notifier.error(text.ERROR_MSG);
    }
  }

  changeTpiState = async (value) => {
    const { sidepanel, actions } = this.props;
    const { parameters } = sidepanel;
    const changeTpiApi = getDjangoApi(`payment_transactions/${parameters.id}/update_status`);

    try {
      await changeTpiApi.updatePart(null, value);
      const { data } = await this.transactionApi.retrieve(parameters.id);
      notifier.success(text.CHANGE_TPI_STATE_SUCCESS);
      actions.updateSidepanel(SIDEPANEL_ID, data);
    } catch (e) {
      notifier.error(text.ERROR_MSG);
    }
  }

  showResend = () => {
    const { sidepanel, isIb } = this.props;
    const { security_check, payment_state, tpi_state } = sidepanel.parameters || {};

    let secCheck = true;
    if (isFeatureEnabled()('FRAUD_CHECK')) {
      secCheck = security_check !== securityCheckStatus.REVIEW;
    }

    return payment_state === paymentStatesValues.SUCCESS && tpi_state === tpiStateLabel.FAILED && secCheck && (
      (!isIb && hasAccess('payment_transactions.resend.*'))
      || (isIb && hasAccess('ib.payment_transactions.resend.*')));
  }

  resend = async () => {
    const { isIb, sidepanel } = this.props;
    const { id } = sidepanel.parameters || {};
    const resendApi = getDjangoApi(`${isIb ? 'ib/' : ''}payment_transactions/${id}/resend`);

    try {
      this.setState({ isLoadingResendTransaction: true });
      await resendApi.create({});
      notifier.success(text.RESEND_SUCCESS);
    } catch (_) {
      notifier.error(text.ERROR_MSG);
    } finally {
      this.setState({ isLoadingResendTransaction: false });
    }
  }

  async rejectTransaction(externalID, id) {
    const { actions } = this.props;
    const rejectTransactionApi = getDjangoApi(`payment_transactions/${id}/reject_pending_tpi`);

    try {
      this.setState({ isLoadingRejectTransaction: true });
      await rejectTransactionApi.create({
        external_id: externalID,
      });
      const { data } = await this.transactionApi.retrieve(id);
      this.setState({ isLoadingRejectTransaction: false, visible: false });
      notifier.success(text.REJECT_SUCCESS);
      actions.updateSidepanel(SIDEPANEL_ID, data);
      this.setState({ visible: false });
    } catch (e) {
      notifier.error(text.ERROR_MSG);
      this.setState({ isLoadingRejectTransaction: false });
    }
  }

  async approveTransaction() {
    const { actions, sidepanel } = this.props;
    const { id } = sidepanel.parameters;
    const approveTransactionApi = getDjangoApi(`payment_transactions/${id}/approve_pending_tpi`);

    try {
      this.setState({ isLoadingApproveTransaction: true });
      await approveTransactionApi.create();
      const { data } = await this.transactionApi.retrieve(id);
      this.setState({ isLoadingApproveTransaction: false });
      notifier.success(text.APPROVE_SUCCESS);
      actions.updateSidepanel(SIDEPANEL_ID, data);
    } catch (e) {
      notifier.error(text.ERROR_MSG);
      this.setState({ isLoadingApproveTransaction: false });
    }
  }

  renderTransactionHeaderStatus = ({ payment_state, tpi_state, is_blocked_to_tpi }) => { // eslint-disable-line
    const {
      isLoadingApproveTransaction,
      isLoadingRejectTransaction,
      isLoadingResendTransaction,
    } = this.state;

    const { sidepanel } = this.props;
    const { parameters } = sidepanel;
    const { gateway } = parameters;

    const isPaymentSuccessful = payment_state === text.PAYMENT_STATES.SUCCESS.value;
    const isIngenico = gateway.toLowerCase() === 'ingenico';
    const disabled = isLoadingApproveTransaction || isLoadingRejectTransaction || isLoadingResendTransaction;
    const buttonSize = 'small';
    const showResend = this.showResend();

    const hasApproveAndRejectAccess = hasAccess('payment_transactions.reject_pending_tpi.*') && hasAccess('payment_transactions.approve_pending_tpi.*');
    const actionsForPendingState = [
      {
        label: text.SUCCESS,
        onClick: () => { this.changePaymentState({ payment_state: 1000 }); },
      },
      {
        label: text.FAILURE,
        onClick: () => { this.changePaymentState({ payment_state: 100 }); },
      },
      {
        label: text.REJECTED,
        onClick: () => { this.changePaymentState({ payment_state: 120 }); },
      },
    ];

    const actionsForRejectedState = [
      {
        label: text.SUCCESS,
        onClick: () => { this.changePaymentState({ payment_state: 1000 }); },
      },
      {
        label: text.FAILURE,
        onClick: () => { this.changePaymentState({ payment_state: 100 }); },
      },
    ];

    const actionsForFailedState = [
      {
        label: text.SUCCESS,
        onClick: () => { this.changePaymentState({ payment_state: 1000 }); },
      },
      {
        label: text.REJECTED,
        onClick: () => { this.changePaymentState({ payment_state: 120 }); },
      },
    ];

    const actionsForSuccessState = [
      {
        label: text.FAILURE,
        onClick: () => { this.changePaymentState({ payment_state: 100 }); },
      },
      {
        label: text.REJECTED,
        onClick: () => { this.changePaymentState({ payment_state: 120 }); },
      },
    ];

    const actions = {
      [text.PAYMENT_STATES.PENDING.value]: actionsForPendingState,
      [text.PAYMENT_STATES.FAILED.value]: actionsForFailedState,
      [text.PAYMENT_STATES.SUCCESS.value]: actionsForSuccessState,
      [text.PAYMENT_STATES.REJECTED.value]: actionsForRejectedState,
    };

    const state = find(text.PAYMENT_STATES, ps => ps.value === payment_state) || text.PAYMENT_STATES.NA(payment_state);

    return (
      <span>
        <If condition={showResend}>
          <Button
            disabled={disabled}
            loading={isLoadingResendTransaction}
            size={buttonSize}
            onClick={this.resend}
          >
            {text.RESEND}
          </Button>
        </If>
        <If condition={isPaymentSuccessful 
          && tpi_state === text.PAYMENT_STATES.PENDING.value 
          && is_blocked_to_tpi
          && hasApproveAndRejectAccess} 
        >
          <ApproveAndReject
            onReject={() => {
              this.setState({ visible: true });
            }}
            disabled={disabled}
            size={buttonSize}
            loading={isLoadingApproveTransaction}
            onApprove={this.approveTransaction}
          />
        </If>
        <If condition={!isIngenico && hasAccess('payment_transactions.update_status.update')}>
          <Dropdown
            list={actions[payment_state]}
            customHeader={onToggle => (
              <Button
                color="primary"
                onClick={onToggle}
                size="small"
                icon="CaretDown"
                iconPosition="right"
              >
                {text.CHANGE_STATE}
              </Button>
            )}
          />
        </If>
        <Pill
          key="panelStatus"
          iconPosition="left"
          icon={state.icon}
          type={state.type}
        >
          { state.label}
        </Pill>
      </span>
    );
  };

  async activate() {
    const { actions } = this.props;
    const id = _.get(this, 'props.sidepanel.parameters.id');
    const { data } = await this.transactionApi.retrieve(id);
    actions.updateSidepanel(SIDEPANEL_ID, data);
  }

  async chargeFee() {
    const { actions, sidepanel } = this.props;
    const { id, chargeFeeAmount } = sidepanel.parameters;
    const [err, response] = await to(chargeFeeApi(id).create({ amount: chargeFeeAmount }));
    if (err) {
      notifier.error(text.CHARGE_FEE_ERROR);
    } else {
      actions.updateSidepanel(SIDEPANEL_ID, { ...sidepanel.parameters, chargeFeeAmount: 0 });
      response.data.success
        ? notifier.success(text.CHARGE_FEE_SUCCESS)
        : notifier.error(text.CHARGE_FEE_ERROR);
    }
  }

  render() {
    const { sidepanel, actions, isIb } = this.props;
    if (!sidepanel.parameters) return null;
    const data = sidepanel.parameters;

    const {
      card,
      logs,
      extra,
      pg_account,
      thirdparty_verified,
      thirdparty,
      fraud_check,
      wallet_transactions,
      success,
      tpi_state,
      payment_state,
      id,
      is_blocked_to_tpi,
      method_type,
    } = data;

    const { visible, isLoadingRejectTransaction } = this.state;
    const paymentState = this.getPaymentState(payment_state, isIb, success);
    const displayThirdparty = !!pg_account;

    return (
      <Sidepanel
        visible={sidepanel.visible}
        title={`${text.PANEL_TITLE} ${data.id}`}
        onCloseIconClick={() => actions.closeSidepanel(SIDEPANEL_ID)}
        size="large"
        hideFooter
      >
        <AddExternalIdModal
          isLoading={isLoadingRejectTransaction}
          onCancel={() => { this.setState({ visible: false }); }}
          onConfirm={this.rejectTransaction}
          visible={visible}
          id={id}
        />
        <UserInfo user={data.user} isIb={isIb} />
        <Panel
          title={text.PANEL_TITLE}
          status={() => this.renderTransactionHeaderStatus({ 
            tpi_state, payment_state: paymentState, id, is_blocked_to_tpi,
          })}
        >
          <PanelBody tradeData={data} onTpiChange={this.changeTpiState} toggleReadOnly={() => this.toggleReadOnly(data.read_only)} />
        </Panel>
        <Space size={12} />

        {logs && !!logs.length && logs.map((log, index) => (
          <Fragment key={index}>
            <Extra rawData={log.data} title={`${log.type} (${dateTimeFormatter(log.created)}) ${text.EXTRA}`} />
            <Space size={12} />
          </Fragment>
        ))}

        <Extra rawData={extra} title={text.TRANSACTION_EXTRA} />
        <Space size={12} />

        {this.additionalInfoVisible && (
          <Fragment>
            <Panel title={text.CLIENT_INFO} style={{ marginTop: 0 }}>
              <div className="card-panel-body">
                <PanelRow label={text.ASSIGNED_TO} value={<AssignedToLink user={data.assigned_to} />} />
                <PanelRow label={text.INVITED_BY} value={<InvitedByLink user={data.invited_by} />} />
                <PanelRow label={text.LAST_DEPOSIT_DATE} value={dateTimeFormatter(data.last_deposit)} />
                <PanelRow label={text.FIRST_DEPOSIT_DATE} value={dateTimeFormatter(data.first_deposit)} />
                <PanelRow label={text.TAGS} value={<TagsCell data={data} />} />
                <PanelRow label={text.TEAM} value={<TeamLink team={data.team} />} />
              </div>
            </Panel>
            <Space size={24} />
          </Fragment>
        )}

        {this.cardInfoVisible && method_type === paymentMethodTypes.CREDIT_CARD && (
          <Fragment>
            <Panel title={text.CARD_INFORMATION} style={{ marginTop: 0 }}>
              <div className="card-panel-body">
                <PanelRow label={text.PAYMENT_STATES.SUCCESS.label} value={<YesNoCell value={card?.success} />} />
                <PanelRow label={text.BRAND} value={card?.brand} />
                <PanelRow label={text.CARD} value={card?.card} />
                <PanelRow label={text.EXPIRY_DATE} value={card ? `${card?.exp_month}/${card?.exp_year.substr(2, 2)}` : <Pill>{text.NA}</Pill>} />
                <PanelRow label={text.FUNDING} value={card?.funding} />
                <PanelRow label={text.CARDHOLDER_NAME} value={card?.cardholder_name} />
                <PanelRow label={text.CREATED_DATE} value={dateTimeFormatter(card?.created_date)} />
                <PanelRow label={text.UPDATED_DATE} value={dateTimeFormatter(card?.updated_date)} />
                <PanelRow label={text.COUNTRY} value={card?.extra?.card_details?.billing_country_code} />
              </div>
            </Panel>

            <Space size={12} />
            <Extra rawData={card?.extra} title={text.CARD_EXTRA} />
            <Space size={12} />
          </Fragment>
        )}

        {displayThirdparty && (
          <Fragment>
            <ThirdPartyPayment
              pgAccount={pg_account}
              thirdPartyVerified={thirdparty_verified}
              thirdParty={thirdparty}
              activate={this.activate}
            />
            <Space size={16} />
          </Fragment>
        )}

        {!_.isEmpty(wallet_transactions) && (
          <Fragment>
            <WalletTransactions transactions={wallet_transactions} />
            <Space size={16} />
          </Fragment>
        )}

        {this.fraudCheckEnabled && !!fraud_check && (
          <Fragment>
            <SecurityCheck transaction={data} activate={this.activate} />
            <Space size={16} />
          </Fragment>
        )}

        {hasAccess('payment_transactions.charge_fee.*') && (
          <Fragment>
            <ChargeFee
              onClick={this.chargeFee}
              onChange={this.onChargeFeeChange}
              inputValue={data.chargeFeeAmount}
            />
            <Space size={48} />
          </Fragment>
        )}
      </Sidepanel>
    );
  }
}

const mapStateToProps = ({ overlays }) => ({
  sidepanel: overlays.TRANSACTION_SINGLE,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      updateSidepanel: overlayActions.update,
    },
    dispatch,
  ),
});

ChargeFee.defaultProps = chargeFeeDefaultProps;
ChargeFee.propTypes = chargeFeeProptypes;
TransactionSingle.propTypes = propTypes;
TransactionSingle.defaultProps = defaultProps;

export default withUnmountMethod(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorBoundary(TransactionSingle)),
);
