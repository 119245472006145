import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';
import { unlockLogin } from './Model';
import userActionTypes from '../types';
import ConfirmationFactory from '../../Confirmation';

const textGenerator = user => ({
  LABEL: gettext('Unlock login'),
  QUESTION: gettext('Are you sure you want to unlock login for {{first_name}} {{last_name}} ?', user),
  SUCCESS: gettext('Login successfully unlocked.'),
  ERROR: gettext('Error while trying to unlock login.'),
});

const ACTION_ICON = 'User';

export default {
  name: 'unlockLogin',
  label: textGenerator().LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, user) {
    return isFeatureEnabled()('LOGIN_LOCKDOWN') 
    && hasAccess('user.unlock_login.*') 
    && user.locked_login;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const text = textGenerator(user);
    const confirmationOptions = {
      apiAction: unlockLogin,
      apiParams: [user],
      text,
      reduxActionName: 'UNLOCK_LOGIN',
    };

    const Confirmation = ConfirmationFactory(confirmationOptions);

    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(Confirmation, sidepanelOptions);
  },
};
