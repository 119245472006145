/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Tabs, Tab, Button } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import { gettext } from '../../../../logic/utilities/languageUtility';
import Page from '../../../../components/Page';
import DepositMethods from './Tabs/DepositMethods/DepositMethods';
import PendingDeposits from './Tabs/PendingDeposits/PendingDeposits';
import WithdrawableAmounts from './Tabs/WithdrawableAmounts';
import If from '../../../../components/If';
import { hasAccess } from '../../../../logic/services/acl';
import DepositExpiry from './Tabs/DepositExpiryPeriod';
import StateRules from '../pending-deposits/StateRules';
import appRoutes from '../../../../components/App/Router/appRoutes';


const pendingDepositsId = 'pending_deposits';
const depositMethodsId = 'deposit_methods';
const withdrawableAmountsId = 'withdrawable_amounts';
const stateRulesId = 'state_rules';
const depositExpiryPeriod = 'deposit_expiry_period';

const text = {
  PAGE_TITLE: gettext('Deposits'),
  PENDING_DEPOSITS: gettext('Pending deposits'),
  DEPOSIT_METHOD_RULES: gettext('Deposit method rules'),
  CREATE_NEW: gettext('Create new'),
  WITHDRAWABLE_AMOUNTS: gettext('Withdrawable amounts'),
  STATE_RULES: gettext('State rules'),
  DEPOSIT_EXPIRY_PERIOD: gettext('Deposit expiry period'),
};

const bread = [{ label: text.PAGE_TITLE, route: appRoutes.SETTINGS_DEPOSITS }];

const pendingDepositsCreateNew = () => (
  <If condition={hasAccess('pending_deposit_rules.create')}>
    <Link to={appRoutes.SETTINGS_PENDING_DEPOSIT_CREATE}>
      <Button startPropagation>{text.CREATE_NEW}</Button>
    </Link>
  </If>
);

const depositMethodsCreateNew = () => (
  <If condition={hasAccess('settings.manage_deposit_methods.create')}>
    <Link to={appRoutes.SETTINGS_DEPOSIT_METHODS_RULES_CREATE}>
      <Button startPropagation>{text.CREATE_NEW}</Button>
    </Link>
  </If>
);

const withdrawableAmountsCreateNew = () => (
  <If condition={hasAccess('settings.manage_withdrawalable_amount_rules.create')}>
    <Link to={generatePath(appRoutes.SETTINGS_WITHDRAWABLE_AMOUNTS_CREATE)}>
      <Button startPropagation>{ text.CREATE_NEW }</Button>
    </Link>
  </If>
);

const stateRulesCreateNew = () => (
  <If condition={hasAccess('deposit_state_rules.create')}>
    <Link to={appRoutes.SETTINGS_STATE_RULES_CREATE}>
      <Button startPropagation>{ text.CREATE_NEW }</Button>
    </Link>
  </If>
);

const depositExpiryPeriodCreateNew = () => (
  <If condition={hasAccess('settings.deposit_expiry_period.create')}>
    <Link to={appRoutes.SETTINGS_DEPOSIT_EXPIRY_CREATE}>
      <Button startPropagation>{ text.CREATE_NEW }</Button>
    </Link>
  </If>
);

class Deposit extends Component {
  constructor(props) {
    super(props);
    this.headerActions = this.headerActions.bind(this);
    this.state = {
      active: pendingDepositsId,
    };
  }

  setActiveTab(active) {
    this.setState({ active });
  }

  headerActions() {
    const { active } = this.state;
    switch (active) {
      case pendingDepositsId: return pendingDepositsCreateNew;
      case depositMethodsId: return depositMethodsCreateNew;
      case depositExpiryPeriod: return depositExpiryPeriodCreateNew;
      case withdrawableAmountsId: return withdrawableAmountsCreateNew;
      case stateRulesId: return stateRulesCreateNew;
    }
  }

  render() {
    return (
      <Page bread={bread} headerActions={this.headerActions()} title={text.PAGE_TITLE}>
        <Tabs>
          <Tab title={text.PENDING_DEPOSITS} onLoad={() => this.setActiveTab(pendingDepositsId)}>
            <PendingDeposits {...this.props} />
          </Tab>
          <Tab title={text.DEPOSIT_METHOD_RULES} onLoad={() => this.setActiveTab(depositMethodsId)}>
            <DepositMethods {...this.props} />
          </Tab>
          <Tab visible={hasAccess('settings.manage_withdrawalable_amount_rules.list')} title={text.WITHDRAWABLE_AMOUNTS} onLoad={() => this.setActiveTab(withdrawableAmountsId)}>
            <WithdrawableAmounts />
          </Tab>
          <Tab visible={hasAccess('deposit_state_rules.list')} title={text.STATE_RULES} onLoad={() => this.setActiveTab(stateRulesId)}>
            <StateRules />
          </Tab>
          <Tab visible={hasAccess('settings.deposit_expiry_period.list')} title={text.DEPOSIT_EXPIRY_PERIOD} onLoad={() => this.setActiveTab(depositExpiryPeriod)}>
            <DepositExpiry />
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

export default {
  component: Deposit,
  aclFn: () => hasAccess('pending_deposit_rules.list') || hasAccess('settings.manage_deposit_methods.list'),
  path: appRoutes.SETTINGS_DEPOSITS,
};
