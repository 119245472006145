import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../logic/services/api-factory';

const initialState = {
  teams: [],
  isLoading: false,
  errors: null,
};

const actionTypes = {
  LOAD_TEAMS_STRUCTURE: 'LOAD_TEAMS_STRUCTURE',
  LOAD_TEAMS_STRUCTURE_PENDING: 'LOAD_TEAMS_STRUCTURE_PENDING',
  LOAD_TEAMS_STRUCTURE_FULFILLED: 'LOAD_TEAMS_STRUCTURE_FULFILLED',
  LOAD_TEAMS_STRUCTURE_FAILED: 'LOAD_TEAMS_STRUCTURE_FAILED',
};

const loadTeams = createAction(actionTypes.LOAD_TEAMS_STRUCTURE, async () => {
  const { data } = await getDjangoApi('teams/visual').list({ ordering: 'name', offset: 0, limit: 1000 });
  return data.results;
});

export {
  loadTeams,
};

export default handleActions(
  {
    [actionTypes.LOAD_TEAMS_STRUCTURE_PENDING]: state => ({ ...state, isLoading: true }),
    [actionTypes.LOAD_TEAMS_STRUCTURE_FULFILLED]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      teams: payload,
    }),
    [actionTypes.LOAD_TEAMS_STRUCTURE_FAILED]: state => ({ ...state, isLoading: true }),
  },
  initialState,
);
