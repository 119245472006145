import React from 'react';
import { func, bool, string } from 'prop-types';
import { Button } from 'tc-biq-design-system';
import { gettext } from '../../../logic/utilities/languageUtility';

const text = {
  CREATE_BUTTON_LABEL: gettext('Create'),
  DISCARD_BUTTON_LABEL: gettext('Discard'),
  UPDATE_BUTTON_LABEL: gettext('Update'),
};

const propTypes = {
  type: string.isRequired,
  onSubmit: func.isRequired,
  onDiscard: func.isRequired,
  submitInProgress: bool.isRequired,
};

const QueryBuilderSubmitSection = (props) => {
  const { type, onSubmit, onDiscard, submitInProgress } = props;
  return (
    <div className="deposit-expiry-period__submit-section">
      <Button
        style={{ float: 'right' }}
        onClick={onSubmit}
        disabled={submitInProgress}
      >
        { type !== 'edit' ? text.CREATE_BUTTON_LABEL : text.UPDATE_BUTTON_LABEL }
      </Button>
      <Button
        style={{ float: 'right' }}
        onClick={onDiscard}
        color="ghost"
      >
        { text.DISCARD_BUTTON_LABEL }
      </Button>
    </div>
  );
};

QueryBuilderSubmitSection.propTypes = propTypes;

export default QueryBuilderSubmitSection;
