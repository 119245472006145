import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { object, array, bool } from 'prop-types';
import { Space, Row, Spinner } from 'tc-biq-design-system';

import connect from '../../../../../../logic/connect';
import { fetchPaymentBreakdown } from './Model';
import PaymentBreakdownAccordion from './PaymentBreakdownAccordion';

import './PaymentBreakdown.scss';

const propTypes = {
  actions: object.isRequired,
  user: object.isRequired,
  payments: array,
  paymentsInProgress: bool,
};

const defaultProps = {
  payments: [],
  paymentsInProgress: false,
};

class PaymentBreakdown extends Component {
  componentDidMount() {
    const { actions, user } = this.props;

    actions.fetchPaymentBreakdown(user);
  }
  
  render() {
    const { payments, paymentsInProgress } = this.props;
    return (
      <div className="biq-payment-breakdown">
        {payments.length > 0 && !paymentsInProgress && payments.map(({ data, trading_account }) => (
          <PaymentBreakdownAccordion 
            key={trading_account.id}
            data={data} 
            tradingAccount={trading_account} 
          />
        ))}
        {paymentsInProgress && (
          <Fragment>
            <Space size={20} />
            <Row style={{ justifyContent: 'center' }}>
              <Spinner />
            </Row>
          </Fragment>
        )}
      </div>
    );
  }
}

PaymentBreakdown.propTypes = propTypes;
PaymentBreakdown.defaultProps = defaultProps;

const mapStateToProps = ({ pages }) => {
  const userPage = pages.USER_SINGLE;
  const { user } = userPage.userDetail;
  return {
    ...userPage.paymentBreakdown,
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchPaymentBreakdown }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentBreakdown);
