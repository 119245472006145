/* eslint-disable react/prop-types */
import React from 'react';
import { Tooltip, Popconfirm, Icon, Toggle, HyperLink } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import hideCols from '../../../../../logic/utilities/hideCols';
import { UsernameCell, ActionsCellWrapper } from '../../../../../components/gridCellRenderers';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const text = {
  DELETE: gettext('Delete'),
  CANCEL: gettext('Cancel'),
  ARE_YOU_SURE: gettext('You are about to delete view'),
};

const actionIcons = {
  remove: (remove, data) => {
    const label = `${text.ARE_YOU_SURE} ${data.name}`;
    return remove ? (
      <Tooltip key="remove" title={text.DELETE}>
        <Popconfirm
          type="destructive"
          icon="Delete"
          label={label}
          onConfirm={() => remove(data)}
          buttonLabels={{
            cancel: text.CANCEL,
            confirm: text.DELETE,
          }}
        >
          <Icon name="Delete" />
        </Popconfirm>
      </Tooltip>
    ) : <span />;
  },
  edit: (edit, data) => (
    edit ? (
      <Link key="edit" to={generatePath(appRoutes.SETTINGS_CUSTOMIZATION_PROFILE, { id: data.id })}>
        <Icon name="Edit" />
      </Link>
    ) : <span />
  ),
  duplicate: (duplicate, data) => (duplicate ? (
    <Icon key="duplicate" name="Duplicate" onClick={() => duplicate(data)} />
  ) : <span />),
};

// eslint-disable-next-line
const ToggleIsActive = onToggle => ({ data }) => {
  // eslint-disable-next-line
  const { is_active } = data;
  return (
    <Toggle checked={is_active} disabled={!onToggle} onChange={() => onToggle && onToggle(data)} />
  );
};

const modifers = ({ remove, edit, duplicate, previewBiqUser, toggle }) => ({
  ...hideCols([
    'id',
  ]),
  is_active: {
    pinned: 'left',
    width: 120,
    cellRendererFramework: ToggleIsActive(toggle),
  },
  available_to_users: {
    width: 300,
    hiddenSortIcon: true,
    cellRendererFramework: ({ data, colDef }) => (!_.isEmpty(data.available_to_users) ? data.available_to_users.map((user, index) => (
      <div key={`users-${index}`} style={{ display: 'inline-block', marginRight: 10 }}>
        {
          UsernameCell(
            previewBiqUser, 
            { isStaff: true },
          )(
            { 
              data: { available_to_users: user },
              colDef, 
            },
          )
        }
      </div>
    )) : <span />),
  },
  available_to_teams: {
    width: 300,
    hiddenSortIcon: true,
    cellRendererFramework: ({ data }) => (!_.isEmpty(data.available_to_teams) ? data.available_to_teams.map((team, index) => (
      <div key={`teams-${index}`} style={{ display: 'inline-block', marginRight: 10 }}>
        <HyperLink>
          <Link to={generatePath(appRoutes.TEAM_MEMBER, { id: team.id })}>{team.name}</Link>{data.available_to_teams[index + 1] && ','}
        </HyperLink>
      </div>
    )) : <span />),
  },
  created_by: {
    cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
  },
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper({ duplicate, edit, remove }, actionIcons),
    pinned: 'right',
    width: 120,
  },
});

export default modifers;
