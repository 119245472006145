import React from 'react';
import forEach from 'lodash/forEach';
import { object, bool } from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { Button, notifier } from 'tc-biq-design-system';
import Page from '../../../../components/Page';
import connect from '../../../../logic/connect';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary/withErrorBoundary';
import overlayActions from '../../../../components/overlay';
import { gettext } from '../../../../logic/utilities/languageUtility';

import { exportData } from './Model';
import notifyMessage from '../../../../logic/utilities/notifyMessage';
import appRoutes from '../../../../components/App/Router/appRoutes';

const pageConfig = {
  apiUrl: 'kyc/export',
  reducerKey: 'KYC_EXPORT',
  tableKey: 'KYC_EXPORT_TABLE',
};

const text = {
  PAGE_LABEL: gettext('Export KYC data'),
  EXPORT: gettext('Export'),
  ERROR: gettext('Something went wrong while attempting to export data, please contact support'),
};

const PAGE_STYLE = { margin: '0', height: 'calc(100vh - 186px)', overflowY: 'auto' };
const bread = [{ label: text.PAGE_LABEL, route: appRoutes.KYC_EXPORT }];

class KYCExport extends React.Component {
  exportData = () => {
    const { actions } = this.props;
    actions.exportData().then((data) => {
      notifier.success(data.value.message);
    }, (err) => {
      notifier.error(text.ERROR);
      forEach(err.data.messages, m => notifyMessage(m));
    });
  }

  headerActions = () => {
    const { submitInProgress } = this.props;
    return (
      <Button disabled={submitInProgress} loading={submitInProgress} onClick={this.exportData}>{text.EXPORT}</Button>
    );
  }

  render() {
    return (
      <Page
        headerActions={this.headerActions}
        bread={bread}
        title={text.PAGE_LABEL}
        style={PAGE_STYLE}
      />
    );
  }
}

KYCExport.propTypes = {
  actions: object.isRequired,
  submitInProgress: bool,
};

KYCExport.defaultProps = {
  submitInProgress: false,
};

const mapStateToProps = ({ pages }) => {
  const { reducerKey } = pageConfig;
  const page = pages[reducerKey];
  return {
    ...page,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
      exportData,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(KYCExport);
