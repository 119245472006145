import React, { Component } from 'react';
import { object, bool } from 'prop-types';
import { Spinner, Button, Row, Col, notifier } from 'tc-biq-design-system';

import AclGrid from '../../../../../components/AclGrid';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import to from '../../../../../logic/utilities/to';
import { getDjangoApi } from '../../../../../logic/services/api-factory';

import './MemberAcls.scss';
import { cleanAcls, hasAccess, parseAcl } from '../../../../../logic/services/acl';

const text = {
  SAVE: gettext('Save changes'),
  RESET: gettext('Reset to team access rights'),
  SUBMIT_SUCCESS: gettext('Access rights saved'),
  ERROR_GENERAL: gettext('Something went wrong'),
};

const teamApi = getDjangoApi('team');

const propTypes = {
  team: object,
  member: object.isRequired,
  options: object,
  actions: object.isRequired,
  optionsIsLoading: bool.isRequired,
};

const defaultProps = {
  team: null,
  options: null,
};

class MemberAcls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      positive_acls: [],
      negative_acls: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.initialLoad();
  }

  componentDidUpdate(prevProps) {
    const oldMemberID = _.get(prevProps, 'member.id');
    const memberID = _.get(this.props, 'member.id');
    if (memberID && memberID !== oldMemberID) {
      this.initialLoad();
    }
  }

  onSubmit = async () => {
    this.setState({ loading: true });
    const { positive_acls, negative_acls } = this.state;
    const memberID = _.get(this.props, 'member.id');
    const aclOptions = this.getAclOptions();

    const [err] = await to(teamApi.updatePart(memberID, {
      positive_acls: cleanAcls(aclOptions, positive_acls),
      negative_acls: cleanAcls(aclOptions, negative_acls),
    }));
    this.setState({ loading: false });
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess = () => {
    notifier.success(text.SUBMIT_SUCCESS);
    const { actions } = this.props;
    const { positive_acls, negative_acls } = this.state;
    actions.updateTeamMember({ positive_acls, negative_acls });
  }

  onError = (payload) => {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  onReset = () => {
    this.setState({
      positive_acls: [],
      negative_acls: [],
    }, async () => {
      await this.onSubmit();
      this.initialLoad();
    });
  }

  onAclChange = (memberAcls) => {
    const { team } = this.props;
    const teamAcls = team.view_acls;
    const negative_acls = teamAcls.filter(acl => !memberAcls.includes(acl));
    const positive_acls = memberAcls.filter(acl => !teamAcls.includes(acl));

    this.setState({
      positive_acls,
      negative_acls,
    });
  }

  initialLoad = () => {
    this.loadAcls();
    this.setDefaultValues();
  }

  getAclOptions = () => {
    const { options } = this.props;
    const fields = _.get(options, 'actions.POST.fields');
    if (fields) {
      return fields.find(field => field.key === 'view_acls').choices.map(acl => acl.value);
    }

    return [];
  }

  getAclValues = () => {
    const { team, member } = this.props;
    const teamAcls = team.view_acls;
    const { negative_acls = [], positive_acls = [] } = member;
    let acls = [...teamAcls];
    const extendedNegativeAcls = negative_acls.reduce((acc, acl) => {
      const { name } = parseAcl(acl);
      return [
        ...acc,
        `${name}.*`,
      ];
    }, negative_acls);

    if (!_.isEmpty(positive_acls)) {
      acls = [...acls, ...positive_acls];
    }

    if (!_.isEmpty(extendedNegativeAcls)) {
      acls = acls.filter(acl => !extendedNegativeAcls.includes(acl));
    }

    return acls;
  }

  setDefaultValues = () => {
    const { member } = this.props;
    const { negative_acls, positive_acls } = member;
    this.setState({ negative_acls, positive_acls });
  }

  loadAcls = () => {
    const teamID = _.get(this.props, 'member.team.id');
    if (teamID) {
      const { actions } = this.props;
      actions.loadTeamOptions();
      actions.loadTeam(teamID);
    }
  }

  render() {
    const { team, optionsIsLoading } = this.props;
    const { loading } = this.state;
    const aclOptions = this.getAclOptions();
    const showLoader = optionsIsLoading;
    const showAcls = team.view_acls && aclOptions.length > 0 && !optionsIsLoading;
    const aclValues = showAcls && this.getAclValues();
    return (
      <div className="member-acls">
        {showAcls && <AclGrid values={aclValues} options={aclOptions} onChange={this.onAclChange} />}
        {showAcls && hasAccess('team.update') && (
          <Row>
            <Col sm="1/5" />
            <Col sm="3/5">
              <div className="member-acls__buttons">
                <Button loading={loading} onClick={this.onReset} color="ghost">{text.RESET}</Button>
                <Button loading={loading} onClick={this.onSubmit}>{text.SAVE}</Button>
              </div>
            </Col>
          </Row>
        )}
        {showLoader && <div className="member-acls__loader"><Spinner /></div>}
      </div>
    );
  }
}

MemberAcls.propTypes = propTypes;
MemberAcls.defaultProps = defaultProps;

export default MemberAcls;
