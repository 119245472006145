import React, { Component } from 'react';
import { Sidepanel, Select } from 'tc-biq-design-system';
import { object, func, array } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import overlayActions from '../../../../../components/overlay';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { SidepanelFooter } from '../../../../../components/common';

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
};

const sidepanelBodyPropTypes = {
  onLanguageChange: func.isRequired,
  selectedLanguage: object,
  languageOptions: array,
};

const sidepanelBodyDefaultProps = {
  selectedLanguage: {},
  languageOptions: [],
};

const text = {
  BUTTON_LABELS: {
    cancel: gettext('Cancel'),
  },
  TITLE: gettext('Preview template'),
  ERROR: gettext('Failed deleting template(s)'),
  NO_CONTENT: gettext('No content is defined'),
  LANGUAGE: gettext('Language'),
};

const SIDEPANEL_ID = 'PREVIEW_EMAIL_TEMPLATE';

const languagesApi = getDjangoApi('emails/marketing/languages');

const customFooter = close => (
  <SidepanelFooter
    close={close}
    buttonLabels={text.BUTTON_LABELS}
  />
);

const SidepanelBody = ({ selectedLanguage, languageOptions, onLanguageChange }) => {
  if (!languageOptions) return <h4>{text.NO_CONTENT}</h4>;
  return (
    <React.Fragment>
      <Select
        clearable={false}
        value={selectedLanguage}
        onChange={onLanguageChange}
        options={languageOptions}
        valueKey="id"
        labelKey="display_name"
      />
      <iframe srcDoc={selectedLanguage.content} title="preview" className="html-preview-modal__iframe" />
    </React.Fragment>
  );
};

class PreviewEmailTempalte extends Component {
  constructor(props) {
    super(props);
    this.actions = { ...props.actions };
    this.onClose = this.onClose.bind(this);
    this.state = {
      selectedLanguage: null,
      languageHash: {},
    };
  }

  componentDidMount() {
    this.loadLanguages();
  }

  componentWillUnmount() {
    this.actions.close(SIDEPANEL_ID);
    this.onLanguageChange(null);
  }

  onLanguageChange = selectedLanguage => this.setState({
    selectedLanguage,
  });

  onClose() {
    this.actions.close(SIDEPANEL_ID);
  }

  getOptions() {
    const { languageHash } = this.state;
    const { sidepanel } = this.props;
    const { parameters } = sidepanel;
    const languages = _.get(parameters, 'languages');
    if (languages) {
      return languages.map(lang => ({ ...lang, display_name: languageHash[lang.language] }));
    }

    return [];
  }

  loadLanguages() {
    languagesApi.list().then(({ data }) => {
      const languageHash = data.reduce((acc, lang) => ({ ...acc, [lang.value]: lang.display_name }), {});
      this.setState({ languageHash });
    });
  }

  render() {
    const { selectedLanguage } = this.state;
    const { sidepanel } = this.props;
    const { visible } = sidepanel;
    const languages = this.getOptions();
    return (
      <Sidepanel
        type="info"
        title={text.TITLE}
        visible={visible}
        onCloseIconClick={() => this.actions.close(SIDEPANEL_ID)}
        footerRender={() => customFooter(this.onClose)}
      >
        {languages && (
          <SidepanelBody
            languageOptions={languages}
            selectedLanguage={selectedLanguage || languages[0]}
            onLanguageChange={this.onLanguageChange}
          />
        )}
      </Sidepanel>
    );
  }
}

PreviewEmailTempalte.propTypes = propTypes;
SidepanelBody.propTypes = sidepanelBodyPropTypes;
SidepanelBody.defaultProps = sidepanelBodyDefaultProps;

const mapStateToProps = ({ overlays }) => ({ sidepanel: overlays[SIDEPANEL_ID] });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ close: overlayActions.close }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(PreviewEmailTempalte));
