
/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import { Space } from 'tc-biq-design-system';
import connect from '../../../logic/connect';
import { gettext } from '../../../logic/utilities/languageUtility';
import GridFactory from '../../../components/grid';
import Page from '../../../components/Page';
import Export from '../../../components/Export';
import { getActiveFilters } from '../../../components/grid/GridUtils';
import Filters from '../../../components/Filters';
import overlayActions from '../../../components/overlay';
import modifier from './modifier';
import If from '../../../components/If';
import { UserPreview, BiqUserPreview } from '../../../components/common/sidepanels';
import { hasAccess } from '../../../logic/services/acl';
import withErrorBoundary from '../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../components/App/Router/appRoutes';
import ClientsAssignSalesManager from '../clients/sidepanels/ClientsAssignSalesManager';
import ClientsAssignRetentionManager from '../clients/sidepanels/ClientsAssignRetentionManager';
import { getAssignRetentionManagerApi, getAssignSalesManagerApi } from '../clients/utils';
import { getStore } from '../../../logic/store';
import MassAssignment, { MASS_ASSIGN_TYPES } from '../../../components/common/sidepanels/bulk/MassAssignment/MassAssignment';
import isFeatureEnabled from '../../../logic/filters/is-feature-enabled';
import MassAssignmentPanel from '../clients/MassAssignmentPanel';

const env = window.config.environment;

const pageConfig = {
  apiUrl: 'reports/active_traders',
  reducerKey: 'ACTIVE_TRADERS',
  tableKey: 'ACTIVE_TRADERS_TABLE',
};

const { GridComponent, actions } = GridFactory(pageConfig);

const text = {
  TITLE: gettext('Active traders'),
  ASSIGN_SALES_MANAGERS_LABEL: gettext('Assign Sales Manager'),
  ASSIGN_RETENTION_MANAGERS_LABEL: gettext('Assign Retention Manager'),
};

const bread = [{ label: text.TITLE, route: appRoutes.ACTIVE_TRADERS }];

const bulkActionsConf = onClick => [
  ...((hasAccess('team.clients_unassign.create') || hasAccess('team.clients_unassign.create')) ? [{
    icon: 'Client',
    label: text.ASSIGN_SALES_MANAGERS_LABEL,
    onClick: data => onClick(data, 'ASSIGN_SALES_MANAGER'),
  }] : []),
  ...((env === 'AvaTrade' && (hasAccess('team.clients_unassign.create') || hasAccess('team.clients_unassign.create'))) ? [{
    icon: 'Client',
    label: text.ASSIGN_RETENTION_MANAGERS_LABEL,
    onClick: data => onClick(data, 'ASSIGN_RETENTION_MANAGER'),
  }] : []),
];

class ActiveTraders extends Component {
  constructor(props) {
    super(props);
    this.headerActions = this.headerActions.bind(this);

    this.tableActions = {
      previewUser: props.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
      previewBiqUser: props.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };

    this.bulkActions = bulkActionsConf(this.bulkAction);
    this.state = {
      selectedUsers: [],
    };
    this.massAssignmentActions = [
      ...(hasAccess('new_bulk.users.assign_sales_manager.create') ? [{
        label: text.ASSIGN_SALES_MANAGERS_LABEL,
        icon: 'Edit',
        onClick: () => getStore().dispatch(overlayActions.open('MASS_ASSIGNMENT', [], null, { type: MASS_ASSIGN_TYPES.ASSIGN_SALES_MANAGER })),
      }] : []),
      ...(hasAccess('new_bulk.users.assign_retention_manager.create')) ? [{
        icon: 'Edit',
        label: text.ASSIGN_RETENTION_MANAGERS_LABEL,
        onClick: () => getStore().dispatch(overlayActions.open('MASS_ASSIGNMENT', [], null, { type: MASS_ASSIGN_TYPES.ASSIGN_MANAGER })),
      }] : [],
    ];
  }

  onRowSelected = (data) => {
    const { ASSIGN_SALES_MANAGER, ASSIGN_RETENTION_MANAGER, actions } = this.props;

    _.forEach({
      ASSIGN_SALES_MANAGER, 
      ASSIGN_RETENTION_MANAGER }, (sidepanel, id) => {
      if (sidepanel.visible) actions.updateSidepanel(id, data);
    });
    this.setState({ selectedUsers: data });
  };

  bulkAction = (data, actionType) => {
    const { actions } = this.props;
    actions.openSidepanel(actionType, data);
  };
  
  headerActions() {
    const { exportUrl } = this.props;
    return (
      <If condition={hasAccess('reports.active_traders.export.*')}>
        <Export url={exportUrl} />
      </If>
    );
  }

  fetchTableData = () => {
    const { gridApi } = this;
    gridApi && gridApi.deselectAll();
    const { table, actions } = this.props;
    return actions.fetchTableData({ ...table.query });
  }

  getGridApi = (api) => {
    this.gridApi = api;
  };

  render() {  
    const { fields, actions, table } = this.props;
    const { selectedUsers } = this.state;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions),
    };
    const assignSalesApi = getAssignSalesManagerApi();
    const assignRetentionApi = getAssignRetentionManagerApi();

    return (
      <Page headerActions={this.headerActions} bread={bread} title={text.TITLE}>
        <Filters {...filterProps} />
        {isFeatureEnabled()('MASS_ASSIGNMENT') && <MassAssignmentPanel actions={this.massAssignmentActions} />}
        <Space size={16} />
        <GridComponent 
          getGridApi={this.getGridApi}
          tableModifier={modifier(this.tableActions)} 
          checkboxSelection={!!this.bulkActions.length || isFeatureEnabled()('MASS_ASSIGNMENT')}
          bulkActions={isFeatureEnabled()('MASS_ASSIGNMENT') ? [] : this.bulkActions}
          onRowSelected={this.onRowSelected}
          {...this.props} 
        />
        <UserPreview />
        <BiqUserPreview />
        <ClientsAssignSalesManager 
          apiAssignSalesManager={assignSalesApi.assignSales}
          apiUnassignSalesManger={assignSalesApi.unAssignSales}
          fetchTableData={this.fetchTableData}
          overlayName="ASSIGN_SALES_MANAGER"
          gridApi={this.gridApi}
        />
        <ClientsAssignRetentionManager
          apiAssignSalesManager={assignRetentionApi.assignSales}
          apiUnassignSalesManger={assignRetentionApi.unAssignSales}
          fetchTableData={this.fetchTableData}
          overlayName="ASSIGN_RETENTION_MANAGER"
          gridApi={this.gridApi} 
        />
        <MassAssignment users={selectedUsers} apiSuffix="activetrader" fetchTableData={this.fetchTableData} />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages, overlays }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
    ASSIGN_SALES_MANAGER: overlays.ASSIGN_SALES_MANAGER,
    ASSIGN_RETENTION_MANAGER: overlays.ASSIGN_RETENTION_MANAGER,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      fetchTableData: actions.fetchTableData,
      openSidepanel: overlayActions.open,
      updateSidepanel: overlayActions.update,
    },
    dispatch,
  ),
});

const ActiveTradersPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(ActiveTraders); 

export default {
  component: ActiveTradersPage,
  path: appRoutes.ACTIVE_TRADERS,
  aclId: 'reports.active_traders.list',
};
