import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { func, number, object } from 'prop-types';

import withFormSidepanel from '../../../../../../components/FormSidepanel';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { formatRequestData } from '../../../../../../components/form/logic/utils';
import { FormActionsFactory } from '../../../../../../components/form';
import connect from '../../../../../../logic/connect';

export const SIDEPANEL_ID = 'TRACKING_LINKS_FORM_SIDEPANEL';
export const FORM_ID = 'TRACKING_LINKS_FORM';

const { setFieldValue } = FormActionsFactory(FORM_ID);

const text = {
  CREATE: gettext('Create tracking link'),
  EDIT: gettext('Update tracking link'),
  SUCCESS: {
    create: gettext('Tracking link successfully created'),
    edit: gettext('Tracking link successfully updated'),
  },
  WHITELABEL: gettext('Whitelabel'),
};

const propTypes = {
  onSuccess: func.isRequired,
  cid: number.isRequired,
  uid: number.isRequired,
  actions: object.isRequired,
};

const loadOptions = (input = '') => getDjangoApi('autocomplete/whitelabel')
  .list({ name: input })
  .then(res => res.data.results);

const customFields = [{
  id: 'whitelabel',
  name: 'whitelabel',
  type: 'select',
  label: text.WHITELABEL,
  placeholder: text.WHITELABEL,
  loadOptions,
  debounceInterval: 500,
  async: true,
  joinValues: true,
  labelKey: 'name',
  valueKey: 'id',
  dataKeys: 'name',
}];

const formModifiers = {
  url: {
    label: gettext('Landing page'),
  },
};

class TrackingLinkSidepanel extends Component {
  constructor(props) {
    super(props);

    this.FormSidepanel = withFormSidepanel(FORM_ID, SIDEPANEL_ID);
  }

  getApi = () => {
    const { cid, uid } = this.props;
    const apiUrl = `team/profile/${uid}/campaigns/${cid}/tracking_links`;
    return getDjangoApi(apiUrl);
  }

  getFormConfig = () => ({
    customFields,
  })

  getRequestPayload = () => (values) => {
    const parsed = formatRequestData(values);
    return { 
      ...parsed,
      hash: values.hash ? values.hash : '',
      whitelabel: values.whitelabel && values.whitelabel.name,
    };
  }

  render() {
    const { FormSidepanel, getApi, getFormConfig, getRequestPayload } = this;
    return (
      <FormSidepanel
        {...this.props}
        options={{
          text,
          getFormConfig,
          getApi,
          formModifiers,
          getRequestPayload,
        }}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setFieldValue,
    },
    dispatch,
  ),
});

TrackingLinkSidepanel.propTypes = propTypes;

export default connect(null, mapDispatchToProps)(TrackingLinkSidepanel);
