import React from 'react';
import { Dropdown, withComboxManager, withSidepanelManager } from 'tc-biq-design-system';
import connect from '../../logic/connect';

import { gettext } from '../../logic/utilities/languageUtility';
import UserActions, { userActionTypes } from '../../pages/home/user-single/react/UserActions';
import { withRouter } from '../hoc/withRouter';

const text = {
  ACTIONS: gettext('Actions'),
};

const mapActionAttributes = actions => actions.map(({ label, icon, handler }) => ({
  label, icon, onClick: handler,
}));

const userTopActionFactory = (name) => {
  const Actions = props => (
    <UserActions type={userActionTypes.TOP} DI={{ ...props }}>
      {(actions) => {
        if (_.isEmpty(actions)) return null;
        const noteAction = actions.filter(a => a.name === name);
        return <Dropdown openLeft title={text.ACTIONS} list={mapActionAttributes(noteAction)} />;
      }}
    </UserActions>
  );
  
  const mapStateToProps = ({ pages }) => {
    const page = pages.USER_SINGLE;
    return {
      user: page.userDetail.user,
    };
  };
  
  return withRouter(
    withComboxManager(
      withSidepanelManager(
        connect(mapStateToProps)(Actions),
      ),
    ),
  );
};

export default userTopActionFactory;
