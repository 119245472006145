import React, { Component } from 'react';
import { DashboardCard, Space } from 'tc-biq-design-system';

import { number, string } from 'prop-types';
import DashboardNumeric from '../../Numeric';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import withFilters from '../../hoc/withFilters';
import { currencyFormatter } from '../../../../../../../../logic/utilities/formatters';

const size = {
  w: 2,
  h: 5,
  minW: 2,
  minH: 5,
  maxW: 3,
  maxH: 5,
  y: 0,
  x: 0,
};

const text = {
  TITLE: gettext('Deposits'),
  DESCRIPTION: 
  <div>
    <div>{gettext('Total deposit amount: FTD + Non-FTD')}</div>
    <div>{gettext('Net deposit amount: Non-FTD - Withdrawals')}</div>
  </div>,
  NET_DEPOSIT_AMOUNT: gettext('Net deposit amount'),
  TOTAL_DEPOSIT_AMOUNT: gettext('Total deposit amount'),
};

const apiNet = getDjangoApi('dashboard/net_deposits');
const apiTotal = getDjangoApi('dashboard/overall_deposits');

function Numeric(props) {
  const { totalDepositsAmount, totalDeposits, title, netDepositAmount } = props;
  return (
    <DashboardNumeric direction="vertical">
      <DashboardNumeric.Title>{title}</DashboardNumeric.Title>
      <DashboardNumeric.Price>{totalDepositsAmount}</DashboardNumeric.Price>
      <DashboardNumeric.Number>{totalDeposits}</DashboardNumeric.Number>
      <Space size={8} />
      <DashboardNumeric.Title>{text.NET_DEPOSIT_AMOUNT}</DashboardNumeric.Title>
      <DashboardNumeric.Price>{netDepositAmount}</DashboardNumeric.Price>
    </DashboardNumeric>
  );
}

Numeric.propTypes = {
  title: string.isRequired,
  totalDepositsAmount: string.isRequired,
  totalDeposits: number.isRequired,
  netDepositAmount: string.isRequired,
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      netDeposits: {},
      totalDeposits: {},
      loading: false,
    };
  }

  onQueryReady = async (query) => {
    this.setState({ loading: true });
    const [totalDeposits, netDeposits] = await Promise.all([
      apiTotal.list(query),
      apiNet.list(query),
    ]);
  
    this.setState({
      netDeposits: netDeposits.data,
      totalDeposits: totalDeposits.data,
      loading: false,
    });
  }

  render() {
    const { 
      netDeposits: { 
        net_deposits, 
        currency, 
      },
      totalDeposits, 
      loading } = this.state;
    
    const netDepositAmount = currencyFormatter(net_deposits, currency);
    const totalDepositsAmount = currencyFormatter(totalDeposits.amount, totalDeposits.currency);
    return (
      <DashboardCard
        title={text.TITLE}
        description={text.DESCRIPTION}
        loading={loading}
      >
        <Numeric
          title={text.TOTAL_DEPOSIT_AMOUNT}
          totalDepositsAmount={totalDepositsAmount}
          totalDeposits={totalDeposits.number}
          netDepositAmount={netDepositAmount}
        />
      </DashboardCard>
    );
  }
}

export default {
  component: withFilters(Dashboard),
  size,
};
