import React from 'react';
import { object, bool } from 'prop-types';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import { Button, Avatar, Space } from 'tc-biq-design-system';
import { gettext } from '../logic/utilities/languageUtility';
import appRoutes from './App/Router/appRoutes';

const propTypes = {
  user: object.isRequired,
  isIb: bool,
};

const defaultProps = {
  isIb: false,
};

const text = {
  VIEW_PROFILE: gettext('View profile'),
};

const UserInfo = ({ user, isIb }) => {
  const route = isIb ? appRoutes.IB_PROFILE : appRoutes.CLIENT_PROFILE;
  return ( 
    <div className="user-section">
      <div className="user-username">
        <Avatar />
        <Space size={8} />
        <span>{user.full_name}</span>
      </div>
      <Link to={generatePath(route, { id: user.id })}>
        <Button startPropagation color="ghost">
          {text.VIEW_PROFILE}
        </Button>
      </Link>
    </div>
  );
};

UserInfo.propTypes = propTypes;
UserInfo.defaultProps = defaultProps;

export default UserInfo;
