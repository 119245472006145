import _, { find, isArray } from 'lodash';

import { gettext } from '../../logic/utilities/languageUtility';
import apiDataType from '../../logic/enums/api-data-type';
import queryOperators from '../../logic/enums/query-operators';
import { fieldInputResolver, inputTypes } from './inputTypeResolver';
import specCharsInUrl from '../../logic/enums/spec-chars-in-url';
/* eslint-enable */

const { BOOLEAN, NESTED_OBJECT, NESTED_RELATION } = apiDataType;

const {
  NOT_ISNULL,
  ISNULL,
  RANGE,
  NOT_RANGE,
  IN,
  NOT_IN,
  IS_EMPTY,
  IS_NOT_EMPTY,
  ICONTAINS,
  ISTARTSWITH,
  IENDSWITH,
} = queryOperators;

const text = {
  DEFAULT_VIEWS: gettext('default views'),
  CREATED_BY_ME: gettext('created by me'),
  SHARED_WITH_ME: gettext('shared with me'),
  NONE: gettext('None'),
};

// Public
export const operatorsWithoutInput = [NOT_ISNULL.value, ISNULL.value, IS_EMPTY.value, IS_NOT_EMPTY.value];
export const alwaysInputOperators = [ICONTAINS.value, ISTARTSWITH.value, IENDSWITH.value];
export const alwaysTrueOperators = [NOT_ISNULL.value, ISNULL.value, IS_EMPTY.value, IS_NOT_EMPTY.value];

export function mapFilters(filters = {}, cb) {
  return Object.keys(filters).map(key => cb(key, filters[key]));
}

export function makeSegmentList({ publicSegments, ownSegments, sharedSegments }, onSegmentClick) {
  const sharedMapped = mapSegmentsForDropdown([...publicSegments, ...sharedSegments], onSegmentClick);
  const ownMapped = mapSegmentsForDropdown(ownSegments, onSegmentClick);
  const defaultMapped = mapSegmentsForDropdown(
    [{ id: 'default', name: gettext('None') }],
    onSegmentClick,
  );
  return [
    ...pushIfExist(defaultMapped, {
      label: text.DEFAULT_VIEWS,
      header: true,
    }),
    ...pushIfExist(ownMapped, {
      label: text.CREATED_BY_ME,
      header: true,
    }),
    ...pushIfExist(sharedMapped, {
      label: text.SHARED_WITH_ME,
      header: true,
    }),
  ];
}

export function getFieldFromFilter(filterKey, fields) {
  const { key, operatorLabel, operator } = parseFilterKey(filterKey);
  const field = find(fields, { key }) || {};
  if (field) {
    return {
      field,
      fieldKey: key,
      operatorLabel,
      operator,
    };
  }
  return {};
}

export function parseFilterKey(filterKey, fixOptionNegation = true) {
  const segments = filterKey.split('__');
  const operator = fixOptionNegation ? fixNegation(segments.pop()) : segments.pop();
  const key = segments.join('__');
  const operatorLabel = getOperatorLabel(operator);

  return {
    operator,
    operatorLabel,
    key,
  };
}

export const fixNegation = operator => (operator.endsWith('!') ? `!${operator.slice(0, -1)}` : operator);

export const revertNegation = operator => (operator.startsWith('!') ? `${operator.substring(1)}!` : operator);

export const isRange = operator => [RANGE.value, NOT_RANGE.value].includes(operator);
export const isMultipleSelect = operator => [IN.value, NOT_IN.value].includes(operator);
export const isAsyncNestedList = field => apiDataType.NESTED_LIST && _.get(field, 'child.list_url');

export const getChoices = field => (field.choices ? field.choices : _.get(field, 'child.choices'));

export const convertValueToApi = value => (_.isString(value) ? value.split(',').join(specCharsInUrl.COMMA) : value);
export const convertValueToDisplay = value => (_.isString(value) 
  ? value.replaceAll(specCharsInUrl.COMMA, ',').replaceAll(specCharsInUrl.ARRAY, ',')
  : value);
export const sanitizeArrayFilter = value => (_.isString(value) ? value.replaceAll(specCharsInUrl.ARRAY, ',') : value);

export function getFilterValue({ value, operator, forApi, field = {} }) {
  const getValueKey = _.get(field, 'filterOptions.getValueKey', item => item.value);

  if (alwaysTrueOperators.includes(operator)) {
    return true;
  }

  if (isMultipleSelect(operator) && forApi && isArray(value)) {
    return value.map(item => convertValueToApi(getValueKey(item))).join(',');
  }

  if (field.type === BOOLEAN) {
    return !!value;
  }

  if (isRange(operator)) {
    if (!forApi) return value ? value.split(',') : [];
    if (forApi) return (value || []).join(',');
  }

  return value;
}

export const genreateFilterKey = (field, operator) => {
  // eslint-disable-next-line prefer-destructuring
  let key = field.key;
  if (
    (field.list_url && field.key_field) 
  || (field.choices && field.type === NESTED_RELATION)
  || (field.type === NESTED_OBJECT 
      && field.key_field 
      && !_.isEmpty(field.choices) 
      && !field.list_url)
  ) key = `${key}__${field.key_field}`;

  return `${key}__${operator}`;
};

export const getFilterValueFromActiveFilters = (field, operator, activeFilters) => {
  if (_.isEmpty(field) || _.isEmpty(operator) || _.isEmpty(activeFilters)) {
    if (isRange(operator)) return [];
    return undefined;
  }

  const filterKey = genreateFilterKey(field, operator);
  const value = activeFilters[filterKey];

  return getFilterValue({
    value,
    operator,
    field,
  });
};

export const isCreatable = (field, operator) => {
  const type = fieldInputResolver(field);
  return (type === inputTypes.TEXT || type === inputTypes.NUMBER) && isMultipleSelect(operator);
};

export function formatValue(value, choices, operator, field) {
  const getValueKey = _.get(field, 'filterOptions.getValueKey', item => item.value);
  if (isMultipleSelect(operator)) {
    if (!value) return value;
    return value.split(',').map(singleValue => choices.find(o => getValueKey(o) === singleValue));
  }
  return choices.find(o => getValueKey(o) === value) || value;
}

export function setFieldValue(choices, value, operator, formatter, field) {
  if (!_.isEmpty(choices) && !_.isObject(value)) return formatter(value, choices, operator, field);
  return value;
}

export const getKeyField = field => (field.key_field || (field.child && field.child.key_field) || 'name');


// Private
function pushIfExist(what = [], header = {}) {
  if (what && what.length > 0) {
    return [...[header], ...what];
  }
  return [];
}

function mapSegmentsForDropdown(segments, onSegmentClick) {
  return segments.map(segment => ({
    id: segment.id,
    label: segment.name,
    onClick: () => onSegmentClick(segment),
  }));
}

function getOperatorLabel(operator) {
  const keys = Object.keys(queryOperators);
  const operatorKey = keys.find(key => queryOperators[key].value === operator);

  if (!operatorKey) return null;

  const { label } = queryOperators[operatorKey];
  return label;
}
