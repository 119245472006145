import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { Banner } from 'tc-biq-design-system';

import { gettext } from '../../../logic/utilities/languageUtility';

const sentryContext = { 
  COMPONENT: 'component',
};

const ERROR_MSG = gettext('Oops, something went wrong. Please reload the page.');

const renderError = () => (
  <Banner close={false} type="error">
    {ERROR_MSG}
  </Banner> 
);

const getDisplayName = WrappedComponent => (
  WrappedComponent.name || WrappedComponent.displayName || 'Component'
);

export const withErrorBoundary = (WrappedComponent) => {
  // eslint-disable-next-line react/prefer-stateless-function
  class ErrorBoundary extends Component {
    render() {
      return (
        <Sentry.ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag(sentryContext.COMPONENT, getDisplayName(WrappedComponent));
          }}
          fallback={renderError}
        >
          <WrappedComponent {...this.props} />
        </Sentry.ErrorBoundary>
      );
    }
  }

  return ErrorBoundary;
};


export default withErrorBoundary;
