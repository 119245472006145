import React, { PureComponent } from 'react';
import { object, string, array, shape, func } from 'prop-types';
import { Input, Button } from 'tc-biq-design-system';

import Page from '../../../../../components/Page';
import getID from '../../../../../logic/utilities/getID';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import QueryBuilder from '../../../../../components/QueryBuilder';
import { refactoredExtractGroup } from '../../../../../logic/services/query-adapter';
import { onRuleError } from '../utils';
import appRoutes from '../../../../../components/App/Router/appRoutes';

import './PendingDepositRule.scss';
import FormEl from '../../../../../components/form/Components/FormEl';


const propTypes = {
  actions: object.isRequired,
  name: string,
  fields: array,
  group: object,
  errors: object,
  match: object.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const defaultProps = {
  name: '',
  fields: [],
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  errors: {
    name: [''],
  },
};

const text = {
  TITLE: {
    create: gettext('Create Pending Deposit Rule'),
    edit: gettext('Edit Pending Deposit Rule'),
  },
  PENDING_DEPOSIT_RULES_CRUMB: gettext('Pending deposit rules'),
  NAME_INPUT_LABEL: gettext('Name'),
  DISCARD_BUTTON_LABEL: gettext('Discard'),
  CREATE_BUTTON_LABEL: gettext('Create'),
  UPDATE_BUTTON_LABEL: gettext('Update'),
  CONDITION_ERROR_MESSAGE: gettext('Condition must exist in order to submit deposit method rule'),
};

class PendingDepositRule extends PureComponent {
  constructor(props) {
    super(props);

    this.actions = props.actions;
    this.type = this.getType();
    this.id = props.match.params.id;

    this.crumbs = [
      { label: text.PENDING_DEPOSIT_RULES_CRUMB, route: appRoutes.SETTINGS_DEPOSITS },
      { label: text.TITLE[this.type], route: '' },
    ];
  }

  componentDidMount() {
    const { getFieldsOptions, getPendingDepositInfo } = this.actions;
    getFieldsOptions().then(() => {
      if (this.type === 'edit') getPendingDepositInfo(this.id);
    });
  }

  componentWillUnmount() {
    this.actions.resetState();
  }

  getType = () => {
    const pathRoutes = getID();

    if (_.includes(pathRoutes, 'add-new')) {
      return pathRoutes[pathRoutes.length - 1] === 'add-new' ? 'create' : 'clone';
    } 
      
    return 'edit';
  }

  redirectToTable = () => {
    const { history } = this.props;
    history.push(appRoutes.SETTINGS_DEPOSITS);
  };

  submit = () => {
    const { name, group } = this.props;
    const { submitDepositRule } = this.actions;
    const condition = refactoredExtractGroup(group);

    const request = {
      name,
      condition,
    };

    submitDepositRule(this.type === 'edit', request, this.id).then(this.redirectToTable, onRuleError);
  };

  render() {
    const { name, group, fields, errors } = this.props;
    const { setRuleName, setRuleCondition } = this.actions;

    return (
      <Page title={text.TITLE[this.type]} bread={this.crumbs}>
        <div className="pending-deposit-rule__container-div">
          <FormEl>
            <Input
              label={text.NAME_INPUT_LABEL}
              placeholder={text.NAME_INPUT_LABEL}
              value={name}
              onChange={setRuleName}
              hasError={!!errors.name[0].length}
              helpText={errors.name[0]}
            />
            <QueryBuilder
              group={group}
              fields={fields}
              onStateUpdate={setRuleCondition}
              className="pending-deposit-rule__query-builder"
            />
            <div className="pending-deposit-rule__submit-section">
              <Button type="submit" onClick={this.submit} style={{ float: 'right' }}>
                {this.type !== 'edit' ? text.CREATE_BUTTON_LABEL : text.UPDATE_BUTTON_LABEL}
              </Button>
              <Button color="ghost" onClick={this.redirectToTable} style={{ float: 'right' }}>
                {text.DISCARD_BUTTON_LABEL}
              </Button>
            </div>
          </FormEl>
        </div>
      </Page>
    );
  }
}

PendingDepositRule.propTypes = propTypes;
PendingDepositRule.defaultProps = defaultProps;

export default PendingDepositRule;
