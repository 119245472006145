import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { Row, Avatar, Space, HyperLink } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import TaskItem from './TaskItem';
import { gettext } from '../../../../logic/utilities/languageUtility';
import { dateTimeFormatter } from '../../../../logic/utilities/formatters';
import appRoutes from '../../../App/Router/appRoutes';

const propTypes = {
  task: object.isRequired,
};

const text = {
  ASSIGNED_TO: gettext('Assigned to'),
  DUE: gettext('Due'),
  CREATED_BY: gettext('Created by'),
  TARGET: gettext('Target'),
  DESCRIPTION: gettext('Descritption'),
};

const getInitials = (fullName) => {
  if (!fullName) return null;
  const name = fullName.split(' ');
  return name.length > 1 ? (name[0].charAt(0) + name[1].charAt(1)).toUpperCase() : name[0];
};

export const TaskComponent = ({ task }) => (
  <div className="preview-task-react">
    <Space size={30} />
    <Row gutter={0}>
      {task.performer ? (
        <TaskItem label={text.ASSIGNED_TO} icon="User" half>
          <Avatar initials={getInitials(task.performer.full_name)} size="regular" />
          <Space size={4} horizontal />
          {task.performer.username}
        </TaskItem>
      ) : null}
      <TaskItem label={text.CREATED_BY} icon="User" half>
        <Avatar initials={getInitials(task.creator.full_name)} size="regular" />
        <Space size={4} horizontal />
        {task.creator.username}
      </TaskItem>
    </Row>
    {task.due_date ? (
      <Fragment>
        <Space size={30} />
        <Row gutter={0}>
          <TaskItem label={text.DUE} icon="Clock">
            {dateTimeFormatter(task.due_date)}
          </TaskItem>
        </Row>
      </Fragment>
    ) : null}
    <Space size={30} />
    {task.target ? (
      <Fragment>
        <Row gutter={0}>
          <TaskItem label={text.TARGET} icon="Client">
            <Avatar initials={getInitials(task.target.full_name)} size="regular" />
            <Space size={4} horizontal />
            <HyperLink>
              <Link to={generatePath(appRoutes.CLIENT_PROFILE, { id: task.target.id })}>{task.target.username}</Link>
            </HyperLink>
          </TaskItem>
        </Row>
        <Space size={30} />
      </Fragment>
    ) : null}
    <Row gutter={0}>
      <TaskItem label={text.DESCRIPTION} icon="Notes">
        {task.description}
      </TaskItem>
    </Row>
  </div>
);

TaskComponent.propTypes = propTypes;

export default TaskComponent;
