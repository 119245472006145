import React from 'react';
import { Detail } from 'tc-biq-design-system';
import { arrayOf, shape, string } from 'prop-types';

import './ListDetail.scss';

const detailsDecoder = {
  entity: string.isRequired,
  value: string.isRequired,
};

const propTypes = {
  details: arrayOf(shape(detailsDecoder)),
};

const defaultProps = {
  details: [],
};

const ListDetail = ({ details }) => (
  <div className="biq-list-detail">
    {details.map((detail, index) => (
      <div key={index} className="biq-list-detail__item">
        <Detail reverse entity={detail.entity} value={detail.value} />
      </div>
    ))}
  </div>
);

ListDetail.propTypes = propTypes;
ListDetail.defaultProps = defaultProps;

export default ListDetail;
