import React, { useEffect } from 'react';
import {
  Route, Switch, useHistory,
} from 'react-router-dom';


import NotFoundPage from '../../../pages/home/404/NotFound';

import AppRoute from './AppRoute';
import appRoutes from './appRoutes';
import appPages from './appPages';
import Authentication from '../Authentication';
import Dashboard from '../../../pages/home/dashboard/Dashboard';

const trackPageView = history => () => {
  if (window.ga) window.ga('send', 'pageview', history.location.pathname);
};

const AppRouter = () => {
  const history = useHistory();

  useEffect(() => {
    trackPageView(history)(); // on load
    history.listen(trackPageView(history)); // on page change 
  }, [history]);

  return (
    <Switch>
      <Route 
        exact 
        path={appRoutes.ROOT} 
        component={() => (
          <Authentication page={Dashboard}>
            <Dashboard.component />
          </Authentication>
        )}
      />
      {appPages.map(page => <AppRoute key={page.path} path={page.path} page={page} />)}
      {/* <Route path="*" component={NotFoundPage} /> */}
      <Route 
        path="*"
        component={() => (
          <Authentication page={Dashboard}>
            <Dashboard.component />
          </Authentication>
        )} 
      />
    </Switch>
  );
};

export default AppRouter;
