import React from 'react';
import { string } from 'prop-types';
import { Icon } from 'tc-biq-design-system';

import './IconCell.scss';

const propTypes = {
  displayName: string,
};

const defaultProps = {
  displayName: '',
};

const IconCell = ({ displayName, ...rest }) => <span className="icon-cell"><Icon size="small" {...rest} />{displayName}</span>;

IconCell.propTypes = propTypes;
IconCell.defaultProps = defaultProps;

export default IconCell;
