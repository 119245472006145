import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../../logic/services/acl';
import PendingDeposits from '../PendingDeposits/PendingDeposits';

export default {
  ...PendingDeposits({ removeDateFilters: true }),
  key: 'pending-deposits-total',
  label: gettext('Pending deposits - total stock'),
  description: gettext('Pending deposits stock - regardless of origination date'),
  isVisible: () => hasAccess('dashboard.pending_deposits.*'),
  size: {
    w: 2,
    h: 5,
    minW: 2,
    minH: 5,
    maxW: 3,
    maxH: 5,
    y: 13,
    x: 0,
  },
};
