import React from 'react';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import { ActionsCellWrapper } from '../../../../../components/gridCellRenderers';
import { actionIcons } from '../../../../../components/grid/GridUtils';
import userState from '../../../../../logic/enums/user-state';
import risk from '../../../../../logic/enums/risk';
import IconCell from '../../../../../components/gridCellRenderers/IconCell';

const stateMappers = {
  [userState.ALERT]: {
    colorName: 'text-primary-600',
    bgColorName: 'bck-primary-100',
    name: 'Reminder',
  },
  [userState.REVIEW]: {
    colorName: 'text-primary-600',
    bgColorName: 'bck-primary-100',
    name: 'View',
  },
  [userState.PENDING]: {
    colorName: 'text-primary-600',
    bgColorName: 'bck-primary-100',
    name: 'Pending',
  },
  [userState.LIMITED]: {
    colorName: 'text-primary-600',
    bgColorName: 'bck-primary-100',
    name: 'Burger',
  },
  [userState.FULL]: {
    colorName: 'text-primary-600',
    bgColorName: 'bck-primary-100',
    name: 'Approve',
  },
};

const riskMappers = {
  [risk.OTHER]: {
    colorName: 'text-primary-600',
    bgColorName: 'bck-primary-100',
    name: 'ArrowRight',
  },
  [risk.BANNED]: {
    colorName: 'text-status04-500',
    bgColorName: 'bck-status04-100',
    name: 'Close',
  },
  [risk.LOW_RISK]: {
    colorName: 'text-status01-600',
    bgColorName: 'bck-status01-100',
    name: 'ArrowDown',
  },
  [risk.MEDIUM_RISK]: {
    colorName: 'text-status03-600',
    bgColorName: 'bck-status03-100',
    name: 'ArrowRight',
  },
  [risk.HIGH_RISK]: {
    colorName: 'text-status04-500',
    bgColorName: 'bck-status04-100',
    name: 'ArrowUp',
  },
};

const renderIcons = mappers => ({ value, column }) => {
  const choices = _.get(column, 'colDef.definition.choices');
  if (choices) {
    const field = choices.find(f => f.value === value);
    const mapper = mappers[value];
    return mapper ? <IconCell displayName={field.display_name} {...mapper} /> : field.display_name;
  }
  
  return value;
};

export default ({ modify, remove }) => {
  const actions = {
    ...(modify && { modify }),
    ...(remove && { remove }),
  };

  return {
    identity: {
      headerName: gettext('Band text'),
    },
    state: {
      headerName: gettext('State'),
      cellRendererFramework: renderIcons(stateMappers),
    },
    risk: {
      cellRendererFramework: renderIcons(riskMappers),
    },
    actions: {
      label: '',
      field: 'actions',
      key: 'actions',
      pinned: 'right',
      width: actions.modify && actions.remove ? 100 : 50,
      hiddenSortIcon: true,
      suppressSizeToFit: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(modify, remove)),
    },
  };
};
