import { combineReducers } from 'redux';

import { reducer as settingsReducer } from './Settings/Model';
import { reducer as emailsTableReducer } from './Model';

const rootReducer = combineReducers({
  settings: settingsReducer,
  emailsTable: emailsTableReducer,
});

export default rootReducer;
