import React from 'react';
import { Icon, Tooltip, Popconfirm } from 'tc-biq-design-system';
import { Link } from 'react-router-dom';

import { ToggleCell, ActionsCellWrapper, UsernameCell } from '../../../../components/gridCellRenderers';
import hideCols from '../../../../logic/utilities/hideCols';
import displayFromChoices from '../../../../logic/utilities/formatters/displayFromChoices';
import text from './text';

const ModifyIcon = (actionHandler, data) => {
  const url = actionHandler(data);

  return (
    <div key="Modify">
      <Link to={url}>
        <Tooltip title={text.MODIFY} placement="top">
          <Icon name="Edit" />
        </Tooltip>
      </Link>
    </div>
  );
};

const ModifyReason = (actionHandler, data) => (
  <div key="Modify" onClick={() => actionHandler(data)}>
    <Tooltip title={text.MODIFY} placement="top">
      <Icon name="Edit" />
    </Tooltip>
  </div>
);

const DeleteIcon = (actionHandler, data) => (
  <Tooltip title={text.DELETE} placement="top">
    <Popconfirm
      type="destructive"
      icon="Delete"
      onConfirm={() => actionHandler(data)}
      label={text.POPCONFIRM_LABEL}
      buttonLabels={{ cancel: text.NO, confirm: text.YES }}
    >
      <Icon name="Delete" />
    </Popconfirm>
  </Tooltip>
);

const CloneIcon = (actionHandler, data) => {
  const url = actionHandler(data);

  return (
    <div key="Clone">
      <Link to={url}>
        <Tooltip title={text.CLONE} placement="top">
          <Icon name="Duplicate" />
        </Tooltip>
      </Link>
    </div>
  );
};

const modifier = previewUser => ({
  ...hideCols(['id', 'condition', 'teams']),
  created_by: {
    cellRendererFramework: UsernameCell(previewUser, { isStaff: true }),
  },
  updated_by: {
    cellRendererFramework: UsernameCell(previewUser, { isStaff: true }),
  },
  modified_by: {
    cellRendererFramework: UsernameCell(previewUser, { isStaff: true }),
  },
  method: {
    valueGetter: displayFromChoices('method'),
  },
  type: {
    valueGetter: displayFromChoices('type'),
  },
});

export const flowModifier = ({ onModify, onDelete, previewBiqUser }) => {
  const actions = {
    ...(onModify && { onModify }),
    ...(onDelete && { onDelete }),
  };

  const actionIcons = {
    ...(onModify && { onModify: ModifyIcon }),
    ...(onDelete && { onDelete: DeleteIcon }),
  };

  return {
    ...modifier(previewBiqUser),
    actions: {
      label: text.ACTIONS,
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons),
      width: 120,
      pinned: 'right',
    },
  };
};

export const methodsModifier = ({ onToggle, onModify, onClone, onDelete, previewBiqUser }) => {
  const actions = {
    ...(onModify && { onModify }),
    ...(onClone && { onClone }),
    ...(onDelete && { onDelete }),
  };

  const actionIcons = {
    ...(onModify && { onModify: ModifyIcon }),
    ...(onClone && { onClone: CloneIcon }),
    ...(onDelete && { onDelete: DeleteIcon }),
  };

  return {
    ...modifier(previewBiqUser),
    ...(onToggle && {
      is_enabled: {
        width: 100,
        headerName: text.STATE,
        pinned: 'left',
        cellRendererFramework: ToggleCell(onToggle),
      },
    }),
    actions: {
      label: text.ACTIONS,
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons),
      width: 120,
      pinned: 'right',
    },
  };
};

export const reasonsModifier = ({ onToggle, onModify, previewBiqUser }) => ({
  ...modifier(previewBiqUser),
  ...(onToggle && {
    is_active: {
      width: 100,
      headerName: text.STATE,
      pinned: 'left',
      cellRendererFramework: ToggleCell(onToggle),
    },
  }),
  ...(onModify && {
    actions: {
      pinned: 'right',
      label: text.ACTIONS,
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(
        { onModify },
        { onModify: ModifyReason },
      ),
      width: 120,
    },
  }),
});
