import React, { Component, Fragment } from 'react';
import { Table, Button, notifier } from 'tc-biq-design-system';
import { object } from 'prop-types';

import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { ActionsCellWrapper } from '../../../../../../../components/gridCellRenderers';
import { actionIcons } from '../../../../../../../components/grid/GridUtils';
import { SIDEPANEL_ID, getLevel } from './CommissionForm/CommissionForm';
import CommissionForm from './CommissionForm';
import to from '../../../../../../../logic/utilities/to';

const api = id => getDjangoApi(`ib/${id}/subib_commission`);

const text = {
  PROVIDER: gettext('Commission provider'),
  TYPE: gettext('Commission type'),
  LEVEL0: gettext('Commission value'),
  LEVEL1: gettext('Sub IB commission Level 1'),
  LEVEL2: gettext('Sub IB commission Level 2'),
  LEVEL3: gettext('Sub IB commission Level 3'),
  ACTIONS: gettext('Actions'),
  ADD: gettext('Add new'),
  SUCCESS_DELETE: gettext('Commission deleted'),
  ERROR_GENERAL: gettext('Something went wrong'),
};

const renderValue = (key, labelKey) => row => (row[key] ? row[key][labelKey] : 0);

const renderLevel = key => (row) => {
  const SSV = 'ssv';
  const percentage = _.get(row, 'type.value') === SSV ? ' %' : '';
  return renderValue(key, 'value')(row) + percentage;
};

const cols = (onEdit, onDelete) => {
  const actions = {
    ...(onEdit && { modify: onEdit }),
    ...(onDelete && { remove: onDelete }),
  };
  return [
    { title: text.PROVIDER, key: 'provider', render: renderValue('provider', 'display_name') },
    { title: text.TYPE, key: 'type', render: renderValue('type', 'display_name') },
    { title: text.LEVEL0, key: 'level0', render: renderLevel('level0') },
    { title: text.LEVEL1, key: 'level1', render: renderLevel('level1') },
    { title: text.LEVEL2, key: 'level2', render: renderLevel('level2') },
    { title: text.LEVEL3, key: 'level3', render: renderLevel('level3') },
    { title: text.ACTIONS, key: 'actions', render: row => ActionsCellWrapper(actions, actionIcons(onEdit, onDelete))({ data: row }) },
  ];
};

const propTypes = {
  user: object.isRequired,
  actions: object.isRequired,
};

class CommissionTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      options: {
        providers: [],
        types: [],
      },
    };
  }

  componentDidMount() {
    this.fetchCommission();
  }

  onEdit = (data) => {
    this.openSidepanel(data);
  }

  onCreate = () => {
    this.openSidepanel();
  }

  onDelete = async (data) => {
    const { user } = this.props;
    const depths = [0, 1, 2, 3];
    const promisses = depths.map((depth) => {
      const { pk } = data[getLevel(depth)];

      return api(user.id).destroy(pk);
    });

    const [err] = await to(Promise.all(promisses));

    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess = () => {
    notifier.success(text.SUCCESS_DELETE);
    this.fetchCommission();
  }

  onError = (payload) => {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  getOptions = (fields, usedSources) => {
    const contentTypeChoices = this.hashFieldChoices(fields, 'content_type');
    const objectChoices = this.hashFieldChoices(fields, 'object_id');
    const sourceChoices = fields.source.choices;

    const providers = Object.keys(objectChoices).map((key) => {
      const item = objectChoices[key];
      const { value } = item;
      const [type] = value.split('.');
 
      return {
        ...item,
        disabled: usedSources.includes(value),
        group: contentTypeChoices[type].display_name,
      };
    });

    return {
      providers,
      types: sourceChoices,
    };
  }

  openSidepanel = (commission) => {
    const { actions } = this.props;
    const { options } = this.state;
    actions.openSidepanel(SIDEPANEL_ID, { commission, options });
  }

  fetchCommission = () => {
    const { user } = this.props;
    const { id } = user;

    Promise.all([
      api(id).retrieve(),
      api(id).options(),
    ]).then(([{ data }, options]) => {
      const fields = _.get(options, 'data.actions.POST.fields', [])
        .reduce((acc, item) => ({ ...acc, [item.key]: item }), {});
      const parsedData = this.parseData(data, fields);
      const usedSources = parsedData.map(row => row.id);
      const fieldOptions = this.getOptions(fields, usedSources);
      
      this.setState({ 
        data: parsedData,
        options: fieldOptions,
      });
    });
  }

  hashFieldChoices = (fields, field) => fields[field].choices
    .reduce((acc, item) => ({ ...acc, [item.value]: item }), {});

  parseData = (data, fields) => {
    const providerChoices = this.hashFieldChoices(fields, 'object_id');
    const sourceChoices = this.hashFieldChoices(fields, 'source');
    
    const hashed = data.filter(({ content_type }) => !!content_type).reduce((acc, item) => {
      const { object_id, depth, source, content_type } = item;
      if (!acc[object_id] && content_type && object_id) {
        const id = `${content_type}.${object_id}`;
        acc[object_id] = {
          id, 
          provider: providerChoices[id], 
          type: sourceChoices[source], 
        };
      }

      return { ...acc, [object_id]: { ...acc[object_id], [`level${depth}`]: { ...item } } };
    }, {});

    return Object.keys(hashed).map(key => hashed[key]);
  }

  render() {
    const { data } = this.state;
    return (
      <Fragment>
        <div className="commission-table__actions">
          <Button onClick={this.onCreate}>{text.ADD}</Button>
        </div>
        <Table
          striped
          fixedHeader={false}
          cols={cols(this.onEdit, this.onDelete)}
          data={data}
        />
        <CommissionForm onSuccess={this.fetchCommission} />
      </Fragment>
    );
  }
}

CommissionTable.propTypes = propTypes;

export default CommissionTable;
