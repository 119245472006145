/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Button, notifier } from 'tc-biq-design-system';

import GridFactory from '../../../components/grid';
import connect from '../../../logic/connect';
import { gettext } from '../../../logic/utilities/languageUtility';
import modifiers from './modifiers';
import Page from '../../../components/Page';
import withUnmountMethod from '../../../components/hoc/withUnmountMethod';
import { getDjangoApi } from '../../../logic/services/api-factory';
import to from '../../../logic/utilities/to';
import overlayActions from '../../../components/overlay';
import { getActiveFilters } from '../../../components/grid/GridUtils';
import Filters from '../../../components/Filters';
import AddNewMember from './sidepanels/AddNewMember';
import If from '../../../components/If';
import { ViewTask, BiqUserPreview } from '../../../components/common/sidepanels';
import { hasAccess } from '../../../logic/services/acl';
import withErrorBoundary from '../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../components/App/Router/appRoutes';

const api = getDjangoApi('team');

const text = {
  TITLE: gettext('Team members'),
  ADD_NEW_MEMBER: gettext('Add new member'),
};

const pageConfig = {
  apiUrl: 'team',
  reducerKey: 'TEAM',
  tableKey: 'TEAM_TABLE',
};

const bread = [{ label: text.TITLE, route: appRoutes.BIQ_USERS }];
const { GridComponent, actions } = GridFactory(pageConfig);

class BiqUsers extends Component {
  constructor(props) {
    super(props);
    this.headerActions = this.headerActions.bind(this);
    this.onSuccessRequest = this.onSuccessRequest.bind(this);
    this.tableActions = {
      ...(hasAccess('team.destroy') && { archive: this.onArchive.bind(this) }),
      preview: props.actions.overlayOpen.bind(this, 'BIQ_USER_PREVIEW'),
    };

    this.onArchive = this.onArchive.bind(this);
  }

  async onArchive({ id }) {
    const [err, res] = await to(api.destroy(id));
    if (err) notifier.error(err.data.messages[0].text);
    if (res) {
      this.onSuccessRequest();
    }
  }

  onSuccessRequest() {
    const { tableQuery, actions } = this.props;
    actions.fetchTableData(tableQuery);
  }

  headerActions() {
    const { actions } = this.props;
    return (
      <If condition={hasAccess('team.create')}>
        <Button onClick={() => actions.overlayOpen('ADD_NEW_MEMBER')}>{text.ADD_NEW_MEMBER}</Button>
      </If>
    );
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const tableModifier = modifiers(this.tableActions);
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      excluded: ['has_profile_layout'],
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier,
    };

    return (
      <Page headerActions={this.headerActions} bread={bread} title={text.TITLE}>
        <Filters {...filterProps} />
        <GridComponent
          singleActions={!!Object.keys(this.tableActions).length}
          tableModifier={tableModifier}
          {...this.props}
        />
        <AddNewMember onSuccessRequest={this.onSuccessRequest} />
        <BiqUserPreview archive={this.onArchive} />
        <ViewTask />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      overlayOpen: overlayActions.open,
      fetchTableData: actions.fetchTableData,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
    },
    dispatch,
  ),
});

const BiqUsersPage = compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(BiqUsers);


export default {
  component: BiqUsersPage,
  aclId: 'team.list',
  path: appRoutes.BIQ_USERS,
};
