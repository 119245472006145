import features from '../logic/constants/features';

const CONFIG = window.config;

/* eslint-disable */
if(_.includes(features, "CPATTERN")){
  console.log('cpatern active');
  setTimeout(function () {
    if (null === window.top.document.getElementById("cpPluginAMC")) {
      var a   = window.top.document.getElementsByTagName("head")[0], b = window.top.document.createElement("script");
      b.type  = "text/javascript";
      b.src   = "https://" + CONFIG.cpattern_subdomain + ".cpattern.com/scripts/CrmMessagePlugin.aspx";
      b.async = !0;
      b.id = 'cpPluginAMC';
      a.appendChild(b);
    }
  }, 1E3);
  (function (a) {
    try {
      "complete" === document.readyState ? a() : document.addEventListener ? document.addEventListener("DOMContentLoaded", a) : document.attachEvent("onreadystatechange", function () {
        "complete" === document.readyState && a()
      })
    }
    catch(err) {
      console.log("Error while loading cpattern script");
    }
  })(void 0);
}
