import { auth0Logout, isEnabled as isAuth0enabled } from './auth0';
import { reset, session } from './session';
import { isForbiddenAcl } from '../filters/is-forbidden-acl';
import { clearCookies, getToken, setCookies } from './cookies';
import { getDjangoApi, updateHttpHeaders } from './api-factory';
import { gettext } from '../utilities/languageUtility';
import isFeatureEnabled from '../filters/is-feature-enabled';

let expires;

export {
  isAuth0enabled,
  loadSession,
  isAuthenticated,
  updateAuthorizationHeader,
  logout,
  loginAuth0,
  login,
  logoutToLogin,
};

/**
 * Just turns endpoints object to an array and action gets converted to an object. Needed for ACL management page
 * @param endpoints
 * @returns {[Object]}
 */
function arrayizeEndpoints(endpoints) {
  const endpointsArray = [];

  _.forOwn(endpoints, (actions, key) => {
    endpointsArray.push({
      name: key,
      actions: actions.map(action => ({
        name: action,
        fullName: `${key}.${action}`,
        access: false,
        positive: false,
        negative: false,
      })),
    });
  });
  return endpointsArray;
}

/**
 * Flattens the objects into one single hash map with left side format {rootKey.subKey} and right side
 * keeps the values of the object.
 * Example: { foo: { bar: value } },... -> { foo.bar: value },...
 * @param endpoints - objects that represents an endpoint with subendpoints. It is always one level deep.
 * @returns {Object}
 */
function flattenEndpoints(endpoints) {
  const flatEndpoints = {};
  for (const root in endpoints) { //eslint-disable-line
    for (const sub in endpoints[root]) {//eslint-disable-line
      const key = `${root}.${endpoints[root][sub]}`;
      flatEndpoints[key] = key;
    }
  }
  return flatEndpoints;
}

// @done
function loadSession() {
  return getDjangoApi('config').list().then((response) => {
    const keys = _.keys(response.data.endpoints);
    if (keys && keys.length) {
      const forbiddenKeys = _.filter(keys, key => isForbiddenAcl(key));

      if (forbiddenKeys && forbiddenKeys.length) {
        forbiddenKeys.forEach((key) => {
          delete response.data.endpoints[key];
        });
      }
    }

    session.endpoints = arrayizeEndpoints(response.data.endpoints);
    session.user = response.data.user;
    session.team = response.data.team;
    session.profile_layout = response.data.profile_layout;
    session.baseCurrency = response.data.base_currency;
    session.whitelabel_segregation = response.data.whitelabel_segregation;
    session.whitelabels = response.data.whitelabels;
    const endpoints = flattenEndpoints(response.data.endpoints);
    session.acl = [];
    for (const key in endpoints) {//eslint-disable-line
      if (endpoints[key] === '' || response.data.acl.indexOf(endpoints[key]) > -1) {
        session.acl.push(key);
      }
    }

    if (isFeatureEnabled()('CPATTERN')) {
      window.cpatternAgentId = response.data.user.id;
    }

    if (session.user && window.ga) {
      window.ga('set', 'userId', session.user.id);
      window.ga('set', 'dimension1', session.user.id);
      window.ga('set', 'dimension2', _.get(session, 'team.name', ''));
      window.ga('set', 'dimension3', session.user.username);
    }
    return { ...session };
  });
}

function bo2Logout() {
  clearCookies();
  localStorage.clear();
  reset();
  updateAuthorizationHeader();
}

function logout(redirectParams) {
  if (window.cpatternAgentId) {
    delete window.cpatternAgentId;
  }
  bo2Logout();
  if (isAuth0enabled) {
    auth0Logout(redirectParams);
  }
}

function loginAuth0(code, api) {
  bo2Logout();
  const request = {
    code,
    redirect_uri: `${window.location.origin}${window.location.pathname}`,
  };

  return api.create(request).then(
    (response) => {
      const now = new Date();

      expires = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
      setCookies(response.data.token, expires);
      updateAuthorizationHeader();
    },
    (error) => {
      throw _.get(error, 'data.non_field_errors[0]') || 'Login failed';
    });
}


function login(username, password, remember) {
  logout();
  return getDjangoApi('login').create({
    username,
    password,
  }).then((response) => {
    const now = new Date();

    if (remember) {
      const expires = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
      setCookies(response.data.token, expires);
    } else {
      setCookies(response.data.token);
    }

    updateAuthorizationHeader();
  });
}

function logoutToLogin(isTtl, redirect) {
  const redirectParams = isTtl ? { error: gettext('You have been logged out due to inactivity.\nPlease log in again.') } : {};
  logout(redirectParams);

  if (!isAuth0enabled) {
    redirect(redirectParams);
    // $state.go("login", redirectParams);
  }
}

function isAuthenticated() {
  return !!getToken();
}

function updateAuthorizationHeader() {
  if (isAuthenticated()) {
    updateHttpHeaders('Authorization', `Token ${getToken()}`);
  } else {
    updateHttpHeaders('Authorization', undefined);
  }
}
