import React, { Component } from 'react';
import { string, func, object } from 'prop-types';

import withFormSidepanel from '../../../../../../components/FormSidepanel';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const apiUrl = 'kyc/user_state_mapping';

const text = {
  CREATE: gettext('Create mapping'),
  EDIT: gettext('Update mapping'),
  SUCCESS: {
    create: gettext('Mapping successfully created'),
    edit: gettext('Mapping successfully updated'),
  },
};

const propTypes = {
  sidepanelId: string.isRequired,
  formId: string.isRequired,
  getFormConfig: func.isRequired,
  getRequestPayload: func.isRequired,
  formModifiers: object.isRequired,
};

class MappingSidepanel extends Component {
  constructor(props) {
    super(props);

    const { sidepanelId, formId } = props;

    this.FormSidepanel = withFormSidepanel(formId, sidepanelId);
  }

  getApi = () => getDjangoApi(apiUrl)


  render() {
    const { FormSidepanel, getApi } = this;
    const { getFormConfig, formModifiers, getRequestPayload, ...rest } = this.props;
    return (
      <FormSidepanel
        {...rest}
        options={{
          getApi,
          text,
          getFormConfig,
          formModifiers,
          getRequestPayload,
        }}
      />
    );
  }
}

MappingSidepanel.propTypes = propTypes;

export default MappingSidepanel;
