
import { bindActionCreators } from 'redux';

import AlertSettings from './AlertSettings';
import connect from '../../../../../logic/connect';
import overlayActions from '../../../../../components/overlay';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ), 
  dispatch,
});

export default connect(null, mapDispatchToProps)(AlertSettings);
