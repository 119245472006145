/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Button, notifier } from 'tc-biq-design-system';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import Page from '../../../../components/Page';
import If from '../../../../components/If';
import overlayActions from '../../../../components/overlay';
import { BiqUserPreview } from '../../../../components/common/sidepanels';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import DeleteEmailEventSidepanel from './Sidepanel/deleteEmailEvent';
import modifiers from './modifiers';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const text = {
  PAGE_TITLE: gettext('Email events'),
  BIQ_BREADCRUMB: gettext('BrokerIQ'),
  ADD_EMAIL_EVENT_BUTTON_LABEL: gettext('Create new'),
  BULK_DELETE_LABEL: gettext('Delete'),
  ERROR: gettext('Failed to delete event'),
  SUCCESS: gettext('Event deleted successfully'),
};

const pageConfig = {
  apiUrl: 'emails/trigger_rules_extended',
  reducerKey: 'EMAIL_EVENTS',
  tableKey: 'EMAIL_EVENTS_TABLE',
};

const bread = [{ label: text.PAGE_TITLE, route: appRoutes.EMAIL_EVENTS }];

const { GridComponent, actions } = GridFactory(pageConfig);

const headerActions = () => (
  <If condition={hasAccess('emails.trigger_rules.create')}>
    <Link to={generatePath(appRoutes.EMAIL_EVENT_CREATE)}>
      <Button startPropagation>{text.ADD_EMAIL_EVENT_BUTTON_LABEL}</Button>
    </Link>
  </If>
);

class EmailEvents extends Component {
  constructor(props) {
    super(props);

    this.actions = props.actions;

    this.bulkActions = [
      ...(hasAccess('emails.trigger_rules.destroy') && {
        icon: 'Delete',
        label: text.BULK_DELETE_LABEL,
        onClick: this.actions.openSidepanel.bind(this, 'DELETE_EMAIL_EVENT'),
      }),
    ];

    this.tableActions = {
      toggle: hasAccess('emails.trigger_rules.update') && this.onToggle.bind(this),
      modify: hasAccess('emails.trigger_rules.update'),
      copy: hasAccess('emails.trigger_rules.create'),
      remove: hasAccess('emails.trigger_rules.destroy') && this.onRemove.bind(this),
      previewUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  onRemove = (data) => {
    const api = getDjangoApi('emails/trigger_rules');
    this.actions.deleteRow(data, api).then(() => {
      notifier.success(text.SUCCESS);
    }, (error) => {
      notifier.error(error.data.detail ? error.data.detail : text.ERROR);
    });
  }

  onToggle(row, newValue) {
    const api = getDjangoApi('emails/trigger_rules');
    const { id } = row;
    const { props } = this;
    props.actions.updateRowData(id, { is_enabled: newValue }, api);
  }

  render() {
    const { fields, actions, table, deleteEventSidepanel } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifiers(this.tableActions),
    };

    return (
      <Page bread={bread} title={text.PAGE_TITLE} headerActions={headerActions}>
        <Filters {...filterProps} />
        <GridComponent
          checkboxSelection={!!this.bulkActions.length}
          singleActions={this.tableActions.modify || this.tableActions.copy}
          bulkActions={this.bulkActions}
          tableModifier={modifiers(this.tableActions)}
          onRowSelected={data => this.actions.updateSidepanel('DELETE_EMAIL_EVENT', data)}
          {...this.props}
        />
        <If condition={deleteEventSidepanel.visible}>
          <DeleteEmailEventSidepanel tableActions={this.actions} />
        </If>
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages, overlays }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
    deleteEventSidepanel: overlays.DELETE_EMAIL_EVENT,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      updateRowData: actions.updateRowData,
      addActionsColumn: actions.addActionsColumn,
      fetchTableData: actions.fetchTableData,
      deleteRow: actions.deleteRow,
      openSidepanel: overlayActions.open,
      updateSidepanel: overlayActions.update,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
    },
    dispatch,
  ),
});

export default compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(EmailEvents);
