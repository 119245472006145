import React, { Component, Fragment } from 'react';
import { Button, Spinner } from 'tc-biq-design-system';
import { object, func, number } from 'prop-types';

import withTable, { GridTab } from '../../../../../components/grid/withTable';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import TrackingLinkSidepanel, { SIDEPANEL_ID } from './TrackingLinkSidepanel';
import modifiers from './modifiers';
import { hasAccess } from '../../../../../logic/services/acl';

const text = {
  ADD_TRACKING: gettext('Add tracking link'),
};

export const reducerKey = 'CAMPAIGNS';

export const tableConfig = ({ uid, cid }) => ({
  reducerKey,
  apiUrl: `team/profile/${uid}/campaigns/${cid}/tracking_links`,
  tableKey: 'TRACKING_LINKS_TABLE',
});

const propTypes = {
  dispatch: func.isRequired,
  table: object.isRequired,
  cid: number.isRequired,
  uid: number.isRequired,
  actions: object.isRequired,
};

class TrackingLinksTable extends Component {
  constructor(props) {
    super(props);

    this.actions = {
      modify: hasAccess('team.profile.campaigns.tracking_links.update') && this.openSidepanel,
    };
  }
  
  gridActions = () => hasAccess('team.profile.campaigns.tracking_links.create') && (
    <Button onClick={() => this.openSidepanel(null)}>{text.ADD_TRACKING}</Button>
  );

  reloadTable = () => {
    const { dispatch, table } = this.props;
    const [, tableActions] = this.Grid;
    dispatch(tableActions.fetchTableData(table.query));
  }

  generateGrid = () => {
    const { cid, uid } = this.props;
    if (!this.Grid && cid && uid) {
      this.Grid = withTable(GridTab, tableConfig, this.gridActions, { withActions: true })({ cid, uid });
    }
    
    return this.Grid;
  }

  openSidepanel = (data) => {
    const { actions } = this.props;
    actions.openSidepanel(SIDEPANEL_ID, data ? { data } : _);
  }

  render() {
    const { cid, uid } = this.props;
    const Grid = this.generateGrid();
    const [Table] = Grid || [];
    return Table ? (
      <Fragment>
        <Table
          singleActions
          height="50vh"
          modifier={modifiers(this.actions, { uid, cid })}
        />
        <TrackingLinkSidepanel onSuccess={this.reloadTable} cid={cid} uid={uid} />
      </Fragment>
    ) : <Spinner />;
  }
}

TrackingLinksTable.propTypes = propTypes;

export default TrackingLinksTable;
