import { createAction } from 'redux-actions';

import ActionTypesFactory from './GridActionTypesFactory';
import { sanitizeFilters } from './GridUtils';

const GridActionsFactory = (table, api) => {
  const {
    CHANGE_PAGE,
    CHANGE_CURSOR,
    ORDERING_CHANGE,
    TABLE_OPTIONS,
    TABLE_DATA,
    UPDATE_PAGE_SIZE,
    UPDATE_FILTER,
    UPDATE_EXPORT_URL,
    ADD_ACTIONS_COLUMN,
    ADD_INFO_COLUMN,
    UPDATE_ROW_DATA,
    DELETE_ROW,
    CHANGE_SEGMENT,
    SET_COLUMNS_VISIBILITY,
    RESET_TABLE,
    CLEAR_DATA,
    UPDATE_CELL,
    UPDATE_MAX_TOTAL,
  } = ActionTypesFactory(table);

  const fetchOptions = createAction(TABLE_OPTIONS, async (modifier) => {
    const optionsResponse = await api.options();
    const options = optionsResponse.data;
    const { fields } = options.actions.GET;
    return { options, fields, modifier };
  });

  const fetchTableData = createAction(TABLE_DATA, async (rawQuery = {}) => {
    const currentTimestamp = new Date().toISOString();
    const query = sanitizeFilters(rawQuery);
    // TODO: pull current query from page reducerz
    const ordering = '-id';

    if (!query.cursor) {
      delete query.cursor;
    }

    const { data, error } = await api.list({ ordering, _current_timestamp: currentTimestamp, ...query });
    return { data, error };
  });

  const updateRowData = createAction(
    UPDATE_ROW_DATA,
    async (id, requestPayload, requestApi = api) => {
      const { data, error } = await requestApi.updatePart(id, requestPayload);
      return { data, error };
    },
  );

  const clearData = createAction(CLEAR_DATA, ({ data = [] }) => ({ data }));

  const deleteRow = createAction(
    DELETE_ROW, 
    async (data, requestApi = api) => requestApi.destroy(data.id).then(() => data),
  );

  const changeSegment = createAction(
    CHANGE_SEGMENT, 
    (segment, modifier) => {
      api.list({ _segment: segment.id, limit: 1 });
      return { ...segment, modifier };
    },
  );

  const updateMaxTotal = createAction(UPDATE_MAX_TOTAL);

  const changeOrdering = createAction(ORDERING_CHANGE);
  const changePageSize = createAction(UPDATE_PAGE_SIZE);
  const changePage = createAction(CHANGE_PAGE);
  const changeCursor = createAction(CHANGE_CURSOR);
  const updateFilter = createAction(UPDATE_FILTER);
  const updateExportUrl = createAction(UPDATE_EXPORT_URL, () => ({ url: api.url }));
  const addActionsColumn = createAction(ADD_ACTIONS_COLUMN);
  const addInfoColumn = createAction(ADD_INFO_COLUMN);
  const setColumnsVisibility = createAction(SET_COLUMNS_VISIBILITY);
  const resetTable = createAction(RESET_TABLE);
  const updateCell = createAction(UPDATE_CELL);

  return {
    fetchOptions,
    fetchTableData,
    changeOrdering,
    changePageSize,
    changePage,
    changeCursor,
    updateFilter,
    updateExportUrl,
    updateRowData,
    addActionsColumn,
    addInfoColumn,
    deleteRow,
    changeSegment,
    setColumnsVisibility,
    resetTable,
    clearData,
    updateCell,
    updateMaxTotal,
  };
};

export default GridActionsFactory;
