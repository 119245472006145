import React, { Component } from 'react';
import { object, arrayOf, func } from 'prop-types';
import { Tabs, Tab, Button, notifier } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';


import Page from '../../../../../components/Page';
import overlayActions from '../../../../../components/overlay';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import connect from '../../../../../logic/connect';
import { fetchRiskLabels, updateRiskLabels } from './Model';
import MainFormula from './MainFormula';
import CountryRisks from './CountryRisks';
import TotalDeposits, { sidepanelID as TotalDepositsSidepanelID } from './TotalDeposits';
import RiskGroupsPerGateway, { sidepanelID as RiskGroupsPerGatewaySidepanelID } from './RiskGroupsPerGateway';
import Onfido, { sidepanelID as OnfidoSidepanelID } from './Onfido';
import Corporate, { sidepanelID as CorporateSidepanelID } from './Corporate';
import to from '../../../../../logic/utilities/to';
import { hasAccess } from '../../../../../logic/services/acl';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';

import './RiskRatingPage.scss';

const { environment } = window.config;

const tabs = {
  MAIN: 0,
  COUNTRY_VALUE: 1,
  TOTAL_DEPOSITS: 2,
  RISK_GROUPS_PER_GATEWAY: 3,
  ONFIDO: 4,
  CORPORATE: 5,
};

const text = {
  TITLE: gettext('Risk rating'),
  LABEL: gettext('Settings'),
  SAVE_CHANGES: gettext('Save changes'),
  MAIN_FORMULA: gettext('Main Formula'),
  COUNTRY_VALUE: gettext('Country Value'),
  TOTAL_DEPOSITS: gettext('Total deposits'),
  RISK_GROUPS_PER_GATEWAY: gettext('Risk Groups per Gateway'),
  ONFIDO: gettext('Screening'),
  CORPORATE: gettext('Corporate'),
  UPDATE_SUCCESS: gettext('Successfully updated'),
  UPDATE_ERROR: gettext('Valid numbers is required'),
  ADD_NEW: gettext('Add new'),
};

const bread = [{ label: text.LABEL, route: appRoutes.RISK_RATING_SYSTEM }];

const propTypes = {
  actions: object.isRequired,
  riskLabels: arrayOf(object).isRequired,
  dispatch: func.isRequired,
};

const mergeRiskLabels = (editedRiskLabels, riskLabels) => {
  const isUndef = v => typeof v === 'undefined'; 
  const mergedRiskLabels = riskLabels.map((rl) => {
    if (editedRiskLabels[rl.id]) {
      return {
        ...rl,
        risk_value_min: 
         isUndef(editedRiskLabels[rl.id].risk_value_min)
           ? rl.risk_value_min : editedRiskLabels[rl.id].risk_value_min,
        risk_value_max: 
          isUndef(editedRiskLabels[rl.id].risk_value_max)
            ? rl.risk_value_max : editedRiskLabels[rl.id].risk_value_max,
      };
    } return rl;
  });
  return mergedRiskLabels;
};

class RiskRatingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabIndex: 0,
      editedRiskLabels: {},
    };
  }

  componentDidMount() {
    const { actions: { fetchRiskLabels } } = this.props;
    fetchRiskLabels();
  }

  onChangeTab = (index) => {
    this.setState({ activeTabIndex: index });
  }


  onChangeFormula = ({ risk_value_min, risk_value_max }, id) => {
    this.setState(prevState => ({
      editedRiskLabels: {
        ...prevState.editedRiskLabels,
        [id]: { risk_value_min, risk_value_max },
      },
    }));
  }

  resetEditedLabels = () => {
    this.setState({ editedRiskLabels: {} });
  }

  updateRiskLabels= async (mergedRiskLabels) => {
    const { actions: { updateRiskLabels } } = this.props;
    const [err] = await to(updateRiskLabels(mergedRiskLabels));

    if (err) {    
      notifier.error(text.UPDATE_ERROR);
    } else {
      notifier.success(text.UPDATE_SUCCESS);
      this.resetEditedLabels();
    }
  }

  renderHeaderActions = (mergedRiskLabels) => {
    const { activeTabIndex } = this.state;
    const { actions: { openSidePanel } } = this.props;
    const getCreateRiskButton = (sidepanelID, hasAccess) => (
      hasAccess && (
      <Button onClick={() => { openSidePanel(sidepanelID); }}> 
        {text.ADD_NEW}
      </Button>
      )
    );

    switch (activeTabIndex) {
      case tabs.MAIN: return (
        hasAccess('risk_labels.settings.create') && (
        <Button onClick={() => { this.updateRiskLabels(mergedRiskLabels); }}> 
          {text.SAVE_CHANGES}
        </Button>
        )
      );
      case tabs.TOTAL_DEPOSITS: return getCreateRiskButton(TotalDepositsSidepanelID, hasAccess('total_deposit_risk_group.settings.create'));
      case tabs.RISK_GROUPS_PER_GATEWAY: return getCreateRiskButton(RiskGroupsPerGatewaySidepanelID, hasAccess('risk_groups.settings.create'));
      case tabs.ONFIDO: return getCreateRiskButton(OnfidoSidepanelID, hasAccess('settings.risk_rating.kyc_result.create'));
      case tabs.CORPORATE: return getCreateRiskButton(CorporateSidepanelID, hasAccess('settings.risk_rating.user_attrs.create'));
      default: return null;
    }
  }

  render() {
    const {
      riskLabels,
      dispatch,
      actions: { openSidePanel, closeSidePanel } } = this.props;
    const { activeTabIndex, editedRiskLabels } = this.state;
    
    const mergedRiskLabels = mergeRiskLabels(editedRiskLabels, riskLabels);
      
    return (
      <Page 
        headerActions={() => this.renderHeaderActions(mergedRiskLabels) || null}
        bread={bread}
        title={text.TITLE}
      >
        <Tabs active={activeTabIndex}>
          <Tab 
            title={text.MAIN_FORMULA}
            index={tabs.MAIN}
            onLoad={() => { this.onChangeTab(0); }}
            visible={hasAccess('risk_labels.settings.list')}
          >
            { !!mergedRiskLabels.length
               && (
               <MainFormula 
                 onChangeFormula={this.onChangeFormula}
                 mergedRiskLabels={mergedRiskLabels}
                 riskLabels={riskLabels}
                 resetEditedLabels={this.resetEditedLabels}
               />
               ) }
          </Tab>
          <Tab 
            title={text.COUNTRY_VALUE} 
            index={tabs.COUNTRY_VALUE}
            onLoad={() => { this.onChangeTab(1); }}
            visible={hasAccess('country_risks.settings.list')}
          >
            <CountryRisks 
              closeSidePanel={closeSidePanel}
              openSidePanel={openSidePanel}
            />
          </Tab>
          <Tab 
            title={text.TOTAL_DEPOSITS}
            index={tabs.TOTAL_DEPOSITS}
            onLoad={() => { this.onChangeTab(2); }}
            visible={hasAccess('total_deposit_risk_group.settings.list')}
          >
            <TotalDeposits 
              closeSidePanel={closeSidePanel}
              openSidePanel={openSidePanel}
              dispatch={dispatch}
            />
          </Tab>
          <Tab
            title={text.RISK_GROUPS_PER_GATEWAY} 
            index={tabs.RISK_GROUPS_PER_GATEWAY}
            onLoad={() => { this.onChangeTab(3); }}
            visible={hasAccess('risk_groups.settings.list')}
          >
            <RiskGroupsPerGateway
              closeSidePanel={closeSidePanel}
              openSidePanel={openSidePanel}
              dispatch={dispatch}
            />
          </Tab>
          <Tab
            title={text.ONFIDO}
            index={tabs.ONFIDO}
            onLoad={() => { this.onChangeTab(4); }}
            visible={hasAccess('settings.risk_rating.kyc_result.list')}
          >
            <Onfido
              closeSidePanel={closeSidePanel}
              openSidePanel={openSidePanel}
              dispatch={dispatch}
            />
          </Tab>
          <Tab 
            title={text.CORPORATE}
            index={tabs.CORPORATE}
            onLoad={() => { this.onChangeTab(5); }}
            visible={hasAccess('settings.risk_rating.user_attrs.list')}
          >
            <Corporate 
              closeSidePanel={closeSidePanel}
              openSidePanel={openSidePanel}
            />
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

RiskRatingPage.propTypes = propTypes;

const mapStateToProps = ({ 
  pages: { RISK_RATING_SYSTEM: { general } } }) => ({
  riskLabels: general.riskLabels,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchRiskLabels,
    updateRiskLabels,
    openSidePanel: overlayActions.open,
    closeSidePanel: overlayActions.close,
  }, dispatch),
  dispatch,
});

const RiskRatingComp = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(RiskRatingPage); 

export default {
  component: RiskRatingComp,
  aclFn: () => hasAccess('risk_labels.settings.list') && environment === 'AvaTrade',
  path: appRoutes.SETTINGS_RISK_RATING,
};
