/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Row, Col } from 'tc-biq-design-system';
import { object, array, bool } from 'prop-types';
import { bindActionCreators } from 'redux';

import UserInfoCard from './UserInfoCard';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import connect from '../../../../../logic/connect';
import session from '../../../../../logic/services/session';
import { fetchDefaultLayout, resetLayout } from '../../../settings/customization/ClientProfileLayout/Model';

const env = window.config.environment;

const translate = {
  PERSONAL_INFO: gettext('Personal Info'),
  PROFILE_DETAILS: gettext('Profile details'),
  ADDRESS: gettext('Address'),
  CONTACT: gettext('Contact'),
  COMPANY: gettext('Company details'),
  OTHER: gettext('Other'),
};

const sectionTitles = {
  address: translate.ADDRESS,
  personal_info: translate.PERSONAL_INFO,
  personal_details: translate.PROFILE_DETAILS,
  other: translate.OTHER,
};

const rejectKeys = ['tax_id', 'company_name', 'tags', 'type', 'nic_fields'];

if (env !== 'AvaTrade') {
  rejectKeys.push('whitelabel', 'docs_approved', 'docs_uploaded');
}

const ibDefaultLayout = [
  {
    title: translate.PROFILE_DETAILS,
    fields: ['username', 'language', 'title', 'date_joined', 'last_seen'],
  },
  {
    title: translate.ADDRESS,
    fields: ['address', 'addr_line_2', 'addr_city', 'country'],
  },
  {
    title: translate.CONTACT,
    fields: ['email', 'phone', 'website', 'skype'],
  },
  {
    title: translate.OTHER,
    fields: [],
  },
];

const propTypes = {
  user: object.isRequired,
  isIb: bool.isRequired,
  fields: array,
  actions: object.isRequired,
};

const defaultProps = {
  fields: [],
};

class UserData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: [],
    };
  }

  componentDidMount() {
    this.getLayout().then(layout => this.setState({ layout }));
  }

  getLayout = () => {
    const { actions } = this.props;
    const { profile_layout } = session;
    const { isIb } = this.props;
    
    if (isIb) return Promise.resolve(this.formatHardcodedLayout(ibDefaultLayout));

    if (profile_layout) return Promise.resolve(this.formatAssignedLayout(profile_layout));

    return actions.fetchDefaultLayout().then(() => {
      const { defaultLayout } = this.props;
      return this.formatDefaultLayout(defaultLayout);
    });
  }

  /**
   * Generates layout based on hardcoded layout
   * 
   * Used for IB only
   * 
   * @param {object} layout 
   */
  formatHardcodedLayout = (layout) => {
    const { fields } = this.props;

    // get all fields from predefined groups
    const usedFields = layout.reduce((acc, section) => ([...acc, ...section.fields]), []);

    if (!_.isEmpty(fields)) {
      // get extra fields by comparing predefined and all user fields
      const extraFields = fields.reduce((acc, field) => {
        if (!usedFields.includes(field.key) && !rejectKeys.includes(field.key)) {
          return [...acc, ...(!usedFields.includes(field.key) && [field.key])];
        }

        return acc;
      }, []);
      
      // assign extra fields to third column
      layout[3].fields = [...layout[3].fields, ...extraFields];

      return layout;
    }
  }

  /**
   * Generates layout based on default layout fetched from the server
   * 
   * @param {object} defaultLayout 
   */
  formatDefaultLayout = (defaultLayout) => {
    const sectionKeys = Object.keys(defaultLayout);
      
    return sectionKeys.map((sectionKey) => {
      const fields = defaultLayout[sectionKey];
      const title = sectionTitles[sectionKey] || sectionKey;
      return {
        title,
        fields: fields.map(field => field.key),
      };
    });
  }

  /**
   * Generates layout based on assigned profile layout
   * 
   * @param {object} profileLayout 
   */
  formatAssignedLayout = (profileLayout) => {
    const isActiveFilter = ({ isActive }) => isActive;

    return profileLayout.filter(isActiveFilter).map(({ title, fields }) => ({
      title,
      fields: fields.filter(isActiveFilter).map(field => field.key),
    }));
  }

  /**
   * This method take prodefined list of fields and compare them with all user fields
   * so we can create list of extra fields and assign them to third column
   */
  generateSections = () => {
    const { fields } = this.props;
    const { layout } = this.state;
    if (_.isEmpty(layout) || _.isEmpty(fields)) return [];
    
    // hash user fields
    const hashedFields = fields.reduce((acc, field) => ({ ...acc, [field.key]: field }), {});

    // use default if label is missing
    const setLabels = (acc, field) => ({
      ...acc,
      ...(hashedFields[field] && { [field]: hashedFields[field].label }),
    });
    
    return layout.map(section => ({
      ...section, 
      labels: section.fields.reduce(setLabels, {}),
    }));
  }

  render() {
    const { user, isIb } = this.props;
    const sections = this.generateSections();
    const countSections = sections.length;
    return (
      <Row className="biq-profile__basic-info__section">
        {sections.map(section => (
          <Col key={section.title} sm={`1/${countSections}`}>
            <UserInfoCard
              user={user}
              isIb={isIb}
              {...section}
            />
          </Col>
        ))}
      </Row>
    );
  }
}

UserData.propTypes = propTypes;
UserData.defaultProps = defaultProps;

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const { defaultLayout } = pages.CLIENT_PROFILE_LAYOUT;
  return {
    user: page.userDetail.user,
    defaultLayout,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    fetchDefaultLayout,
    resetLayout,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserData);
