import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import { refactoredInjectRules, setupFields } from '../../../../../../logic/services/query-adapter';

const actionTypes = {
  GET_DEPOSIT_EXPIRY_OPTIONS: 'GET_DEPOSIT_EXPIRY_OPTIONS',
  GET_DEPOSIT_EXPIRY_OPTIONS_PENDING: 'GET_DEPOSIT_EXPIRY_OPTIONS_PENDING',
  GET_DEPOSIT_EXPIRY_OPTIONS_FULFILLED: 'GET_DEPOSIT_EXPIRY_OPTIONS_FULFILLED',
  SET_DEPOSIT_EXPIRY_SELECTED_METHOD: 'SET_DEPOSIT_EXPIRY_SELECTED_PERIOD',
  SET_DEPOSIT_EXPIRY_SELECTED_ASQUIRER: 'SET_DEPOSIT_EXPIRY_SELECTED_ASQUIRER',
  SET_DEPOSIT_EXPIRY_SELECTED_TYPE: 'SET_DEPOSIT_EXPIRY_SELECTED_TYPE',
  SET_DEPOSIT_EXPIRY_SELECTED_APPLIES_TO: 'SET_DEPOSIT_EXPIRY_APPLIES_TO',
  SET_DEPOSIT_EXPIRY_NAME: 'SET_DEPOSIT_EXPIRY_NAME',
  SET_DEPOSIT_EXPIRY_ENABLE: 'SET_DEPOSIT_EXPIRY_ENABLE',
  SET_DEPOSIT_EXPIRY_PERIOD: 'SET_DEPOSIT_EXPIRY_PERIOD',
  SET_DEPOSIT_EXPIRY_CONDITION: 'SET_DEPOSIT_EXPIRY_CONDITION',
  SUBMIT_DEPOSIT_EXPIRY_PERIOD: 'SUBMIT_DEPOSIT_EXPIRY_PERIOD',
  SUBMIT_DEPOSIT_EXPIRY_PERIOD_PENDING: 'SUBMIT_DEPOSIT_EXPIRY_PERIOD_PENDING',
  SUBMIT_DEPOSIT_EXPIRY_PERIOD_REJECTED: 'SUBMIT_DEPOSIT_EXPIRY_PERIOD_REJECTED',
  SUBMIT_DEPOSIT_EXPIRY_PERIOD_FULFILLED: 'SUBMIT_DEPOSIT_EXPIRY_PERIOD_FULFILLED',
  GET_DEPOSIT_EXPIRY_PERIOD_INFO: 'GET_DEPOSIT_EXPIRY_PERIOD_INFO',
  GET_DEPOSIT_EXPIRY_PERIOD_INFO_PENDING: 'GET_DEPOSIT_EXPIRY_PERIOD_INFO_PENDING',
  GET_DEPOSIT_EXPIRY_PERIOD_INFO_REJECTED: 'GET_DEPOSIT_EXPIRY_PERIOD_INFO_REJECTED',
  GET_DEPOSIT_EXPIRY_PERIOD_INFO_FULFILLED: 'GET_DEPOSIT_EXPIRY_PERIOD_INFO_FULFILLED',
  RESET_DEPOSIT_EXPIRY_PERIOD_PAGE_STATE: 'RESET_DEPOSIT_EXPIRY_PERIOD_PAGE_STATE',
};

const depositExpiryPeriodAPI = getDjangoApi('settings/deposit_expiry_period');

const initialState = {
  submitInProgress: false,
  fields: [],
  methods: [],
  acquirers: [],
  expiryTypes: [],
  appliesTo: [],
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  errors: {
    name: [''],
    method: [''],
    acquirer: [''],
    expiryPeriod: [''],
    expiryType: [''],
    appliesTo: [''],
  },
  selectedMethod: null,
  selectedAcquirer: null,
  selectedExpiryType: null,
  selectedAppliesTo: null,
  expiryPeriod: '',
  name: '',
  expiryEnabled: false,
};

export const getFieldOptions = createAction(
  actionTypes.GET_DEPOSIT_EXPIRY_OPTIONS,
  async () => depositExpiryPeriodAPI.options(),
);

export const submitDeposit = createAction(
  actionTypes.SUBMIT_DEPOSIT_EXPIRY_PERIOD,
  async (isEdit, request, id) => (
    isEdit ? depositExpiryPeriodAPI.updatePart(id, request) : depositExpiryPeriodAPI.create(request)
  ),
);

export const setSelectedMethod = createAction(
  actionTypes.SET_DEPOSIT_EXPIRY_SELECTED_METHOD,
  method => method,
);

export const setSelectedAcquirer = createAction(
  actionTypes.SET_DEPOSIT_EXPIRY_SELECTED_ASQUIRER,
  acquirer => acquirer,
);

export const setExpiryType = createAction(
  actionTypes.SET_DEPOSIT_EXPIRY_SELECTED_TYPE,
  expiryType => expiryType,
);

export const setExpiryName = createAction(
  actionTypes.SET_DEPOSIT_EXPIRY_NAME,
  event => event.target.value,
);

export const setExpiryEnable = createAction(
  actionTypes.SET_DEPOSIT_EXPIRY_ENABLE,
  expiryEnabled => expiryEnabled,
);

export const setAppliesTo = createAction(
  actionTypes.SET_DEPOSIT_EXPIRY_SELECTED_APPLIES_TO,
  appliesTo => appliesTo,
);

export const setExpiryPeriod = createAction(
  actionTypes.SET_DEPOSIT_EXPIRY_PERIOD,
  event => +event.target.value,
);

export const setExpiryCondition = createAction(
  actionTypes.SET_DEPOSIT_EXPIRY_CONDITION,
  group => group,
);

export const getDepositInfo = createAction(
  actionTypes.GET_DEPOSIT_EXPIRY_PERIOD_INFO,
  async id => depositExpiryPeriodAPI.retrieve(id),
);

export const resetState = createAction(
  actionTypes.RESET_DEPOSIT_EXPIRY_PERIOD_PAGE_STATE,
);

const getDepositOptionsHandler = (state, { payload }) => {
  const { data } = payload;
  const { fields } = data.deposit_expiry_period_conditions_spec.fields[0].children;
  const methods = _.find(data.actions.POST.fields, { key: 'method' }).choices;
  const acquirers = _.find(data.actions.POST.fields, { key: 'acquirer' }).choices;
  const expiryTypes = _.find(data.actions.POST.fields, { key: 'expiry_period_type' }).choices;
  const appliesTo = _.find(data.actions.POST.fields, { key: 'applies_to' }).choices;
  const mappedFields = setupFields(fields);

  return {
    ...state,
    fields: [...mappedFields],
    methods: [...methods],
    acquirers: [...acquirers],
    expiryTypes: [...expiryTypes],
    appliesTo: [...appliesTo],
  };
};

const setSelectedMethodHandler = (state, { payload }) => (
  {
    ...state,
    selectedMethod: { ...payload },
    errors: { ...state.errors, method: [''] },
  }
);

const setSelectedAcquirerHandler = (state, { payload }) => (
  {
    ...state,
    selectedAcquirer: { ...payload },
    errors: { ...state.errors, acquirer: [''] },
  }
);

const setSelectedExpiryTypeHandler = (state, { payload }) => (
  {
    ...state,
    selectedExpiryType: { ...payload },
    errors: { ...state.errors, expiryType: [''] },
  }
);

const setSelectedAppliesToHandler = (state, { payload }) => (
  {
    ...state,
    selectedAppliesTo: { ...payload },
    errors: { ...state.errors, appliesTo: [''] },
  }
);

const setExpiryPeriodHandler = (state, { payload }) => (
  {
    ...state,
    expiryPeriod: payload,
    errors: { ...state.errors, expiryPeriod: [''] },
  }
);

const setExpiryEnableHandler = (state, { payload }) => (
  {
    ...state,
    expiryEnabled: payload,
    errors: { ...state.errors, expiryEnabled: [''] },
  }
);

const setExpiryNameHandler = (state, { payload }) => (
  {
    ...state,
    name: payload,
    errors: { ...state.errors, name: [''] },
  }
);

const setExpiryConditionHandler = (state, { payload }) => (
  {
    ...state,
    group: { ...payload },
  }
);

const depositSubmitHandler = state => (
  {
    ...state,
    submitInProgress: false,
  }
);

const errorApiMapping = {
  expiry_period: 'expiryPeriod',
  expiry_type: 'expiryType',
  applies_to: 'appliesTo',
};

const depositSubmitErrorHandler = (state, { payload }) => {
  const errors = {};
  Object.keys(payload.data).forEach((key) => {
    errors[errorApiMapping[key] || key] = payload.data[key];
  });
  
  return {
    ...state,
    errors: { ...state.errors, ...errors },
    submitInProgress: false,
  };
};

const getDepositInfoHandler = (state, { payload }) => {
  const { expiry_conditions, method, name, acquirer, expiry_period_type, applies_to, expiry_period, is_enabled } = payload.data;
  const group = refactoredInjectRules(state.fields, expiry_conditions);
  const selectedMethod = _.find(state.methods, { value: method });
  const selectedAcquirer = _.find(state.acquirers, { value: acquirer });
  const selectedExpiryType = _.find(state.expiryTypes, { value: expiry_period_type });
  const selectedAppliesTo = _.find(state.appliesTo, { value: applies_to });


  return {
    ...state,
    name,
    group,
    selectedMethod,
    selectedAcquirer,
    selectedExpiryType,
    selectedAppliesTo,
    expiryEnabled: is_enabled,
    expiryPeriod: expiry_period || '',
  };
};

const resetStateHandler = () => (
  {
    ...initialState,
  }
);

export const depositExpiryPeriodActions = {
  getFieldOptions,
  setSelectedMethod,
  setSelectedAcquirer,
  setExpiryType,
  setExpiryPeriod,
  setAppliesTo,
  setExpiryName,
  setExpiryEnable,
  setExpiryCondition,
  getDepositInfo,
  submitDeposit,
  resetState,
};

export default handleActions(
  {
    [actionTypes.GET_DEPOSIT_EXPIRY_OPTIONS_PENDING]: state => (
      { ...state, optionsInProgress: true }
    ),
    [actionTypes.GET_DEPOSIT_EXPIRY_OPTIONS_FULFILLED]: getDepositOptionsHandler,
    [actionTypes.SET_DEPOSIT_EXPIRY_SELECTED_METHOD]: setSelectedMethodHandler,
    [actionTypes.SET_DEPOSIT_EXPIRY_SELECTED_ASQUIRER]: setSelectedAcquirerHandler,
    [actionTypes.SET_DEPOSIT_EXPIRY_NAME]: setExpiryNameHandler,
    [actionTypes.SET_DEPOSIT_EXPIRY_CONDITION]: setExpiryConditionHandler,
    [actionTypes.SET_DEPOSIT_EXPIRY_PERIOD]: setExpiryPeriodHandler,
    [actionTypes.SET_DEPOSIT_EXPIRY_SELECTED_TYPE]: setSelectedExpiryTypeHandler,
    [actionTypes.SET_DEPOSIT_EXPIRY_SELECTED_APPLIES_TO]: setSelectedAppliesToHandler,
    [actionTypes.SET_DEPOSIT_EXPIRY_ENABLE]: setExpiryEnableHandler,
    [actionTypes.SUBMIT_DEPOSIT_EXPIRY_PERIOD_PENDING]: state => (
      { ...state, submitInProgress: true }
    ),
    [actionTypes.SUBMIT_DEPOSIT_EXPIRY_PERIOD_FULFILLED]: depositSubmitHandler,
    [actionTypes.SUBMIT_DEPOSIT_EXPIRY_PERIOD_REJECTED]: depositSubmitErrorHandler,
    [actionTypes.GET_DEPOSIT_EXPIRY_PERIOD_INFO_FULFILLED]: getDepositInfoHandler,
    [actionTypes.RESET_DEPOSIT_EXPIRY_PERIOD_PAGE_STATE]: resetStateHandler,
  },
  initialState,
);
