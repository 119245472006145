import React, { PureComponent } from 'react';
import { Accordion, Icon } from 'tc-biq-design-system';
import { object, array, func } from 'prop-types';

import { hasAccess } from '../../../../../../logic/services/acl';
import Presets from './Presets';

const propTypes = {
  doc: object.isRequired,
  documents: array.isRequired,
  updatePreset: func.isRequired,
  editDocument: func.isRequired,
};
class MultiPresetDoc extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleAccordion = () => {
    const { isOpen } = this.state; 
    this.setState({ isOpen: !isOpen });
  }

  render() {
    const {
      doc,
      documents,
      updatePreset,
      editDocument,
    } = this.props;
    const { isOpen } = this.state;
    return (
      <div className="biq-settings-documents__multipreset">
        <Accordion
          title={(
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '12px' }}>{doc.display_name}</span>
              {hasAccess('document_types.update') && hasAccess('document_types.create') && (
              <Icon
                colorName="text-neutral-500"
                name="Edit"
                onClick={e => editDocument(e, { type: 'edit', docId: doc.value })}
              />
              )}
            </span>
    )}
          visible={isOpen}
          onClick={this.toggleAccordion}
        >
          <Presets docId={doc.value} presets={documents} updatePreset={updatePreset} />
        </Accordion>
      </div>
    );
  }
}

MultiPresetDoc.propTypes = propTypes;

export default MultiPresetDoc;
