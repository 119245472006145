import { useEffect, useState } from 'react';
import { getStore } from '../store';

/**
 * Such a way of dispatch actions and retriev values to/from store
 * is much lighter and obvious than mapping wrappers.
 * But, it will works only in functional components
 * I hope couple of options to interact with store better then one
 */

export const useDispatch = () => action => getStore().dispatch(action);
export const useSelector = (selector) => {
  const [value, setValue] = useState(selector(getStore().getState()));

  useEffect(() => {
    const unsubscribe = getStore().subscribe(() => {
      const state = getStore().getState();
      const newValue = selector(state);
      if (value !== newValue) setValue(newValue);
    });
    return unsubscribe;
  }, [selector, value]);

  return value;
};
