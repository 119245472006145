
import { bindActionCreators } from 'redux';

import FormSidepanel from './FormSidepanel';
import { FormActionsFactory } from '../form';
import overlayActions from '../overlay';
import connect from '../../logic/connect';
import withErrorBoundary from '../hoc/withErrorBoundary';

export default (FORM_ID, SIDEPANEL_ID) => {
  const {
    errorFields, create, update, resetFields,
    setRowData, setFieldValue,
  } = FormActionsFactory(FORM_ID);

  const mapStateToProps = ({ overlays, forms }) => {
    const sidepanel = overlays[SIDEPANEL_ID];
    const { fields, submitInProgress, values } = forms[FORM_ID];
    return { sidepanel, fields, values, submitInProgress, FORM_ID, SIDEPANEL_ID };
  };

  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
      {
        closeSidepanel: overlayActions.close,
        errorFields,
        create,
        resetFields,
        setRowData,
        setFieldValue,
        update,
      },
      dispatch,
    ),
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorBoundary(FormSidepanel));
};
