import React from 'react';
import { withRouter } from 'react-router-dom';
import { SidepanelProvider } from 'tc-biq-design-system';

import { gettext } from '../../../../logic/utilities/languageUtility';
import DashboardPage from '../../settings/customization/DashboardLayout/DashboardPage';
import { hasAccess } from '../../../../logic/services/acl';
import appRoutes from '../../../../components/App/Router/appRoutes';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';

const DashboardPageWithRouter = withRouter(DashboardPage);

const text = {
  PAGE_TITLE: gettext('Dashboard'),
};

const bread = [
  { label: text.PAGE_TITLE, route: appRoutes.DASHBOARD },
];

const DashboardLayout = () => (
  <SidepanelProvider>
    <DashboardPageWithRouter
      bread={bread}  
    />
  </SidepanelProvider>
);

export default {
  component: withErrorBoundary(DashboardLayout),
  aclFn: () => hasAccess('team.profile.clients.list') || hasAccess('team.profile.tasks.list') || hasAccess('dashboard.users.*')
  || hasAccess('dashboard.deposits.*') || hasAccess('dashboard.withdrawals.*') || hasAccess('dashboard.net_deposits.*')
  || hasAccess('dashboard.first_deposits.*') || hasAccess('dashboard.active_traders.*'),
  path: appRoutes.DASHBOARD,
};
