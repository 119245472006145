import React from 'react';
import { Tooltip, Icon, Popconfirm } from 'tc-biq-design-system';

import { ToggleCell, ActionsCellWrapper, UsernameCell } from '../../../components/gridCellRenderers';
import hideCols from '../../../logic/utilities/hideCols';
import { gettext } from '../../../logic/utilities/languageUtility';

const text = {
  EDIT: gettext('Edit'),
  DELETE: gettext('Delete'),
  POPCONFIRM_LABEL: gettext('Are you sure you want to delete sales status?'),
  NO: gettext('No'),
  YES: gettext('Yes'),
  ACTIONS: gettext('Actions'),
};

const actionIcons = {
  onEdit: (actionHandler, data) => (
    <div key="Modify" onClick={() => actionHandler(data)}>
      <Tooltip title={text.EDIT} placement="top">
        <Icon name="Edit" />
      </Tooltip>
    </div>
  ),
  onDelete: (actionHandler, data) => (
    <Tooltip title={text.DELETE} placement="top">
      <Popconfirm
        type="destructive"
        icon="Delete"
        onConfirm={() => actionHandler(data)}
        label={text.POPCONFIRM_LABEL}
        buttonLabels={{ cancel: text.NO, confirm: text.YES }}
      >
        <Icon name="Delete" />
      </Popconfirm>
    </Tooltip>
  ),
};

const modifier = ({ onEdit, onDelete, onToggle, previewBiqUser }) => {
  const actions = {
    ...(onEdit && { onEdit }),
    ...(onDelete && { onDelete }),
  };

  return {
    ...hideCols(['id']),
    creator: {
      cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
    },
    ...(onToggle && {
      is_enabled: {
        pinned: 'left',
        cellRendererFramework: ToggleCell(onToggle),
      },
    }),
    actions: {
      pinned: 'right',
      label: text.ACTIONS,
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons),
      width: 120,
    },
    user_count: {
      quickFilter: {},
    },
  };
};

export default modifier;
