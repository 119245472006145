import React from 'react';
import { bindActionCreators } from 'redux';
import { object, bool } from 'prop-types';
import { notifier, Space, Sidepanel } from 'tc-biq-design-system';

import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import { archiveUser } from './Model';
import { formErrorHandler } from '../../../../../../../components/form/logic/utils';

const text = {
  TITLE: gettext('Archive user'),
  QUESTION: gettext('Are you sure you want to archive this user?'),
  SUCCESS: gettext('User successfully archived.'),
  ERROR: gettext('Error archiving user.'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  submitInProgress: bool.isRequired,
  isIb: bool,
};

const defaultProps = {
  user: null,
  actions: null,
  isIb: false,
};

const Archive = ({ sidepanelManager, user, isIb, actions, submitInProgress, ...options }) => {
  const onClose = () => {
    sidepanelManager.close();
  };

  const onSubmit = () => {
    actions.archiveUser(user, isIb).then(() => {
      actions.fetchUser(user, isIb);
      notifier.success(text.SUCCESS);
      sidepanelManager.close();
    }).catch((e) => {
      formErrorHandler(text.ERROR)(e, () => {
        const error = _.get(e, 'data.error');
        notifier.error(error);
      });
    });
  };

  return (
    <Sidepanel {...options} footerRender={customFooter(onSubmit, onClose, submitInProgress)}>
      <Space size={16} />
      <div>{text.QUESTION}</div>
    </Sidepanel>
  );
};

Archive.propTypes = propTypes;
Archive.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      archiveUser,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const action = page.actions.ARCHIVE_USER;
  return {
    submitInProgress: action.submitInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Archive);
