import { gettext } from '../../../../../../logic/utilities/languageUtility';

export default {
  PERSONAL_INFO: gettext('Personal info'),
  PROFILE_DETAILS: gettext('Profile details'),
  ADDRESS: gettext('Address'),
  CONTACT: gettext('Contact'),
  BIRTH_COUNTRY: gettext('Birth country'),
  NATIONALITY: gettext('Nationality'),
  DATE_OF_BIRTH: gettext('Date of birth'),
  LANGUAGE: gettext('Language'),
  USERNAME: gettext('Username'),
  TITLE: gettext('Title'),
  DATE_JOINED: gettext('Date joined'),
  LAST_SEEN: gettext('Last seen'),
  LAST_LOGIN_TIME: gettext('Last login time'),
  LAST_ONLINE_TIME: gettext('Last online time'),
  LAST_INTERACTION_DATE: gettext('Last interaction date'),
  IDENTITY: gettext('Identity'),
  INITIAL_TRADING_PLATFORM: gettext('Initial trading platform'),
  TRADING_CURRENCY: gettext('Trading currency'),
  TRADING_BACKENDS: gettext('Trading backends'),
  STREET: gettext('Street'),
  ADDRESS_2: gettext('Address street 2'),
  ADDRESS_3: gettext('Address street 3'),
  BUILDING: gettext('Building'),
  FLAT: gettext('Flat'),
  POSTCODE: gettext('Postcode'),
  CITY: gettext('City'),
  STATE: gettext('State of residence'),
  COUNTRY: gettext('Country'),
  EMAIL: gettext('Email'),
  PHONE: gettext('Phone'),
  WEBSITE: gettext('Website'),
  SKYPE: gettext('Skype'),
  OTHER_PHONE: gettext('Other phone'),
  OTHER_PHONE2: gettext('Other phone 2'),
  MOTHERS_MAIDEN_NAME: gettext('Mothers Maiden Name'),
  NIF_CODE: gettext('NIF Code'),
  AU_STATE: gettext('AU State'),
  CPR: gettext('CPR'),
  IT_PROVINCE: gettext('Province'),
  TAX_CODE: gettext('Tax code'),
  ZA_PROVINCE: gettext('Province'),
  GENDER: gettext('Gender'),
  ID_CARD: gettext('ID Card'),
  CPF: gettext('CPF'),
  COMPANY: gettext('Company'),
  COMPANY_NAME: gettext('Company name'),
  COMPANY_COUNTRY: gettext('Company country'),
  COMPANY_WEBSITE: gettext('Company website'),
  COMPANY_: gettext('Company name'),
  COMPANY_ADDR_CITY: gettext('Company city'),
  COMPANY_ADDR: gettext('Company address'),
  COMPANY_ADDR_2: gettext('Company address 2'),
  COMPANY_TARGET_REGION: gettext('Company target region'),
  COMPANY_TARGET_TAX_ID: gettext('Company tax id'),
  TAG_DESCRIPTION: gettext('Tag description'),
  REFERRER: gettext('Referrer'),
  JOINT_NAME_PRONOUNCE: gettext('Joint name pronounce'),
  RISK_RATING: gettext('Risk rating'),
  TRADING_SCORE: gettext('Trading score'),
  PRO_TRADER_STATUS: gettext('Professional trader status'),
  LOCKED_LOGIN: gettext('Locked login'),
  LOSS_LIMIT_REACHED: gettext('Loss limit reached'),
  MIDDLE_NAME: gettext('Middle name'),
  NONE: gettext('None'),
  YES: gettext('Yes'),
  NO: gettext('No'),
  NA: gettext('N/A'),
  MAIN_TARGET_REQION: gettext('Main target region'),
  TAX_ID: gettext('Tax ID'),
  MARKETING_AGENT_REL: gettext('Marketing Agent relationship'),
  GENERATE_CLIENT: gettext('Generate client leads'),
  CLIENT_LEADS_ORIGIN: gettext('Clients leads origin'),
  BROKER_REL_OTHER: gettext('IB/Marketing agent relation with other brokers'),
  BROKER_NUM_CLIENTS: gettext('Broker\'s number of existing clients'),
  BROKER_AVERAGE_CLIENTS: gettext('Broker clients monthly average'),
  SPOT_FX: gettext('Spot FX / Metals'),
  BROKER_NAMES: gettext('Broker Names'),
  COMMODITY_FEATURES: gettext('Commodity Futures'),
  FIN_FEATURES: gettext('Financial Futures'),
  OFF_EXCHANGE: gettext('Off Exchange Index Futures'),
  OTC: gettext('OTC Equity CFDs'),
  ACC_MONTH_ONE: gettext('Expected number of accounts first month'),
  ACC_MONTH_TWO: gettext('Expected number of accounts second month'),
  ACC_MONTH_THREE: gettext('Expected number of accounts third month'),
  DEP_MONTH_ONE: gettext('Expected average amount of deposit first month'),
  DEP_MONTH_TWO: gettext('Expected average amount of deposit second month'),
  DEP_MONTH_THREE: gettext('Expected average amount of deposit third month'),
  MARKETING_AGENT_ROLE: gettext('Understanding of being a Marketing Agent'),
  UNPAID_DEFICIT_BALANCE: gettext('Unpaid deficit balance'),
  UNDER_INVESTIGATION: gettext('Investigation or litigation from a customer or a government authority'),
  CLICK_ID: gettext('Click ID'),
  FIRST_IP: gettext('First IP'),
  FIRST_CITY: gettext('First city'),
  FIRST_COUNTRY: gettext('First country'),
  VIEW: gettext('View'),
  HIDE: gettext('Hide'),
};
