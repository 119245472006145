import React, { PureComponent } from 'react';
import { Modal, Input, Button } from 'tc-biq-design-system';
import { bool, func, number } from 'prop-types';
import { gettext } from '../../../../logic/utilities/languageUtility';

const text = {
  title: gettext('Reject pending deposit'),
  label: gettext('External ID'),
};

const defaultProps = {
  visible: false,
  isLoading: false,
};

const propTypes = {
  visible: bool,
  onCancel: func.isRequired,
  onConfirm: func.isRequired,
  isLoading: bool,
  id: number.isRequired,
};

class AddExternalIdModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      externalID: '',
    };
  } 

  render() {
    const { 
      visible,
      onConfirm,
      onCancel,
      isLoading,
      id,
    } = this.props;

    const {
      externalID,
    } = this.state;

    return (  
      <Modal
        title={text.title}
        visible={visible}
        mask
        footerRender={() => (
          <div>
            <Button 
              disabled={isLoading}
              onClick={onCancel}
              color="ghost"
            >Cancel
            </Button>
            <Button
              disabled={isLoading || !externalID}
              loading={isLoading}
              onClick={() => { onConfirm(externalID, id); }}
              color="destructive"
            >Submit
            </Button>
          </div>
        )}
      >
        <Input
          type="number"
          value={externalID}
          onChange={(e) => { this.setState({ externalID: e.target.value }); }}
          label={text.label}
          required
        />
      </Modal>
    );
  }  
}

AddExternalIdModal.propTypes = propTypes;
AddExternalIdModal.defaultProps = defaultProps;

export default AddExternalIdModal;
