import React, { Component } from 'react';
import { arrayOf, string, func, bool } from 'prop-types';
import { Pill, Icon, Select } from 'tc-biq-design-system';

import If from '../If';
import { getDjangoApi } from '../../logic/services/api-factory';
import { gettext } from '../../logic/utilities/languageUtility';

import './style.css';

const text = {
  ADD_TAG: gettext('Add tag'),
};

const propTypes = {
  tags: arrayOf(string).isRequired,
  onSelect: func.isRequired,
  onRemove: func.isRequired,
  disable: bool,
};

const defaultProps = {
  disable: false,
};

const renderTagPills = (tags = [], onIconClick, disable) => (tags.map((tag, index) => {
  const props = {};
  if (!disable) {
    props.icon = 'Close';
    props.onIconClick = () => onIconClick(tag);
  }
  return (
    <span className="tag-pill" key={`tag-pill${index}`}>
      <Pill {...props}>{tag}</Pill>
    </span>
  );
}));

class TagsSelector extends Component {
  constructor(props) {
    super(props);

    this.state = { inlineEditingActive: false };

    this.api = getDjangoApi('tags');

    this.toggleInlineEditing = this.toggleInlineEditing.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  toggleInlineEditing() {
    this.setState(prevState => ({ inlineEditingActive: !prevState.inlineEditingActive }));
  }

  handleInputChange(name) {
    return new Promise(resolve => this.api.list({ name }).then(res => resolve(res.data.results)));
  }

  handleSelect(opt) {
    if (!opt) return false;
    const name = opt.__isNew__ ? opt.label : opt.name;
    const { onSelect, tags } = this.props;
    this.setState(() => ({ inlineEditingActive: false }));

    if (_.includes(tags, name)) return false;
    onSelect(name);
  }

  render() {
    const { inlineEditingActive } = this.state;
    const { tags, onRemove, disable } = this.props;
    return (
      <div className="tag-selector-container">
        { renderTagPills(tags, onRemove, disable) }

        <If condition={!inlineEditingActive && !disable}>
          <span className="add-btn" onClick={this.toggleInlineEditing}>
            <Icon name="Plus" size="small" />
            <span className="add-text">{text.ADD_TAG}</span>
          </span>
        </If>

        <If condition={inlineEditingActive && !disable}>
          <Select
            autoFocus
            onBlur={this.toggleInlineEditing}
            placeholder={text.ADD_TAG}
            type="search"
            creatable
            disable
            async
            debounceInterval={500}
            loadOptions={this.handleInputChange}
            onChange={this.handleSelect}
            className="tag-input"
            valueKey="id"
            labelKey="name"
          />
        </If>
      </div>
    );
  }
}

TagsSelector.propTypes = propTypes;
TagsSelector.defaultProps = defaultProps;
export default TagsSelector;
