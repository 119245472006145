import { useCallback, useEffect, useState } from 'react';
import { string, func, object } from 'prop-types';

import userStateFilter from '../../../../../logic/filters/user-state';
import IbUserStateFilter from '../../../../../logic/filters/ib-state'; 
import actions from './actions';

const propTypes = {
  type: string.isRequired,
  children: func.isRequired,
  DI: object.isRequired,
};

const UserActions = ({ type, children, DI }) => {
  const [filteredActions, setFilteredActions] = useState([]);

  const getActions = useCallback(props => (type) => {
    const { sidepanelManager, comboxManager, dispatch, user, isIb } = props;
    const params = [user.state, true];
    const userState = isIb ? IbUserStateFilter()(...params) : userStateFilter(...params);
    const filteredActions = [];
    const createActionComponent = action => ({ 
      ...action, 
      label: typeof action.label === 'function' ? action.label(userState) : action.label,
      handler: () => action.handler(sidepanelManager, dispatch, user, isIb, comboxManager),
      content: action.content && action.content({ sidepanelManager, user }),
    });
  
    actions.forEach((action) => {
      if (action.type === type) {
        const permission = action.hasPermission(userState, user, isIb);
        if (permission === true) filteredActions.push(createActionComponent(action));
        if (permission.then) {
          permission.then((res) => {
            if (res === true) {
              filteredActions.push(createActionComponent(action));
              setFilteredActions([...filteredActions]);
            }
          });
        }
      }
    });
    setFilteredActions(filteredActions);
  }, []);

  useEffect(() => getActions(DI)(type), []);

  return children(filteredActions);
};

UserActions.propTypes = propTypes;
export default UserActions;
