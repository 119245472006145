import React from 'react';
import { HyperLink } from 'tc-biq-design-system';
import { object } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import { gettext } from '../../logic/utilities/languageUtility';
import appRoutes from '../App/Router/appRoutes';

const text = {
  NA: gettext('N/A'),
};

const propTypes = {
  value: object,
};

const defaultProps = {
  value: {},
};

const TeamCell = ({ value = {} }) => {
  const team = value === null ? { name: text.NA } : value;
  const url = team.id ? generatePath(appRoutes.TEAM, { id: team.id }) : null;

  return (
    <HyperLink>
      <Link to={url}>{team.name}</Link>
    </HyperLink>
  );
};

TeamCell.propTypes = propTypes;
TeamCell.defaultProps = defaultProps;

export default TeamCell;
