import React from 'react';
import { object } from 'prop-types';
import { HyperLink } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import { MultiActionsCell, StateCell, UsernameCell } from '../../../components/gridCellRenderers';
import { gettext } from '../../../logic/utilities/languageUtility';
import deselectCols from '../../../logic/utilities/deselectCols';
import appRoutes from '../../../components/App/Router/appRoutes';


// eslint-disable-next-line
const RouteUsernameCell = ({ value, data, colDef }) => {
  if (value) {
    const { id, username } = data[colDef.field];
    return (
      <HyperLink>
        <Link to={generatePath(appRoutes.CLIENT_PROFILE, { id })}>{username}</Link>
      </HyperLink>
    );
  }
  return null;
};

RouteUsernameCell.propTypes = {
  colDef: object.isRequired,
};

const modifier = (actions, { previewUser, previewBiqUser }) => ({
  ...deselectCols(['target_content_type', 'target_object_id', 'reminder']),
  target: {
    sortable: false,
    cellRendererFramework: UsernameCell(previewUser),
  },
  creator: {
    cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
  },
  performer: {
    sortable: false,
    cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
  },
  state: {
    cellRendererFramework: ({ value }) => StateCell({ value,
      mapper: {
        [gettext('Open')]: 'neutral',
        [gettext('Completed')]: 'status01',
      } }),
  },
  actions: {
    key: 'actions',
    label: '',
    pinned: 'right',
    hiddenSortIcon: true,
    cellRendererFramework: MultiActionsCell(actions),
    width: 40,
  },
});

export default modifier;
