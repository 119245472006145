import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';
import Mt4Bonus from './Mt4Bonus';
import userActionTypes from '../types';

const text = {
  LABEL: gettext('MT4 Bonus'),
};

const ACTION_ICON = 'Deposit';

export default {
  name: 'mt4Bonus',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    const hasStatePermission = [
      USER_STATE.FULL,
    ].includes(state);

    return !isIb 
      && hasStatePermission 
      && isFeatureEnabled()('MT4_BONUS') 
      && hasAccess('user.mt4_bonus.create');
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(Mt4Bonus, sidepanelOptions);
  },
};
