/* eslint-disable react/destructuring-assignment */
import React, { PureComponent, Fragment } from 'react';
import { object, bool, func, array } from 'prop-types';
import { Sidepanel, notifier, InfoBox } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import FormFactory, { FormActionsFactory } from '../../../../../../components/form';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import connect from '../../../../../../logic/connect';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { SidepanelFooter } from '../../../../../../components/common';
import overlayActions from '../../../../../../components/overlay';
import { Field } from '../../../../../../components/form/logic/utils';

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
  submitInProgress: bool.isRequired,
  onSuccess: func.isRequired,
  fields: array.isRequired,
  errors: object.isRequired,
  values: object.isRequired,
};

const text = {
  TITLE: gettext('Create email'),
  EDIT_TITLE: gettext('Edit email'),
  BUTTON_LABELS: {
    confirm: gettext('Create email'),
    cancel: gettext('Discard'),
  },
  EDIT_BUTTON_LABELS: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
  IS_FALLBACK: gettext('Set as fallback (default)'),
  IS_FROM: gettext('Send emails from'),
  IS_REPLY_TO: gettext('Reply to'),
  ERROR: gettext('Error'),
  UPDATE_SUCCESS: gettext('Successfully edited email'),
  UPDATE_ERROR: gettext('Failed to edit email'),
  CREATE_SUCCESS: gettext('Successfully created email'),
  CREATE_ERROR: gettext('Failed to create email'),
};

const defaultSystemApi = getDjangoApi('settings/email_addresses/default');
const emailsPerTypeApi = getDjangoApi('settings/email_addresses');

export const SIDEPANEL_ID = 'SETTINGS_EMAILS_SIDEPANEL';
const { create, update, setFieldValue } = FormActionsFactory(SIDEPANEL_ID);
const formConfig = api => ({
  form: SIDEPANEL_ID,
  api,
  excludeFields: [],
});
const formModifiers = {
  is_fallback: { type: 'toggle', label: text.IS_FALLBACK },
  is_from: { type: 'toggle', label: text.IS_FROM },
  is_reply_to: { type: 'toggle', label: text.IS_REPLY_TO },
};

const fieldsDefaultSystem = formFields => (
  <Fragment>
    {formFields.email}
    {formFields.is_fallback}
    <div className="biq-settings-emails__sidepanel-field-separator" />
    {formFields.is_from}
    <div className="biq-settings-emails__sidepanel-field-separator" />
    {formFields.is_reply_to}
  </Fragment>
);

const fieldsEmailsPerType = ({ languages }) => formFields => (
  <Fragment>
    {formFields.email}
    <Field options={languages}>{formFields.language}</Field>
    {formFields.whitelabel}
    {formFields.is_from}
    <div className="biq-settings-emails__sidepanel-field-separator" />
    {formFields.is_reply_to}
  </Fragment>
);

const customFooter = (execute, close, submitInProgess, isEdit) => (
  <SidepanelFooter
    submitInProgress={submitInProgess}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={isEdit ? text.EDIT_BUTTON_LABELS : text.BUTTON_LABELS}
    formId={SIDEPANEL_ID}
  />
);

const nonFieldErrorHandler = (errors) => {
  if (_.isEmpty(errors) || !errors.non_field_errors) return null;
  const formattedErrorText = errors.non_field_errors[0]
    .replace('is_from', text.IS_FROM)
    .replace('is_reply_to', text.IS_REPLY_TO)
    .replace('reply_to', text.IS_REPLY_TO)
    .replace('from', text.IS_FROM);
  return (
    <InfoBox header={text.ERROR} icon="Close" type="error">
      {formattedErrorText}
    </InfoBox>
  );
};

const formatPayload = (values) => {
  let payload = { is_from: false, is_reply_to: false, ...values };
  if (values.whitelabel) payload = { ...payload, whitelabel: values.whitelabel.name };
  if (values.language) payload = { ...payload, language: _.isString(values.language) ? values.language : values.language.value };
  return payload;
};

class SettingsEmailsSidepanel extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.perType = false;
    this.api = defaultSystemApi;
    this.isEdit = false;
  }

  componentDidUpdate(prevProps) {
    const { sidepanel, fields, values } = this.props;
    const data = _.get(sidepanel, 'parameters.data', {});

    const shouldUpdate = fields.length > 0 && _.isEmpty(values) && _.isEmpty(prevProps.values);
    if (this.isEdit && shouldUpdate) {
      const valueKeys = Object.keys(data);
      valueKeys.forEach((key) => {
        if (key === 'language') return this.setLanguage(data);
        this.actions.setFieldValue({ id: key, value: data[key] });
      });
    }

    if (!this.isEdit && shouldUpdate) {
      this.setLanguage({ id: 'language', value: data?.language });
    }
  }

  setLanguage = (data = {}) => {
    const languages = this.getLanguageOptions();
    if (_.isString(data.language)) {
      const language = _.find(languages, language => language.value === data.language);
      this.actions.setFieldValue({ id: 'language', value: language });
    } else {
      this.actions.setFieldValue({ id: 'language', value: languages?.[0] });
    }
  }

  onClose = () => {
    this.actions.closeSidepanel(SIDEPANEL_ID);
  };

  onSubmit = () => {
    if (this.isEdit) {
      this.update();
    } else {
      this.create();
    }
  };

  getLanguageOptions = () => {
    const { fields } = this.props;
    if (fields.length === 0) return [];
    return _.find(fields, field => field.id === 'language').options;
  }

  update = () => {
    const { onSuccess, sidepanel } = this.props;
    const {
      parameters: { data },
    } = sidepanel;
    this.actions.update(this.api, data.id, formatPayload).then(
      () => {
        notifier.success(text.UPDATE_SUCCESS);
        onSuccess();
        this.actions.closeSidepanel(SIDEPANEL_ID);
      },
      () => {
        notifier.error(text.UPDATE_ERROR);
      },
    );
  };

  create = () => {
    const { onSuccess } = this.props;
    this.actions.create(this.api, formatPayload).then(
      () => {
        notifier.success(text.CREATE_SUCCESS);
        onSuccess();
        this.actions.closeSidepanel(SIDEPANEL_ID);
      },
      () => {
        notifier.error(text.CREATE_ERROR);
      },
    );
  };

  renderForm = () => {
    const { sidepanel, values } = this.props;
    const { parameters } = sidepanel;
    this.perType = parameters && parameters.perType;
    this.api = this.perType ? emailsPerTypeApi : defaultSystemApi;
    this.isEdit = parameters && parameters.edit;
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig(this.api));
    }
    const { FormComponent } = this;
    const formFields = this.perType ? fieldsEmailsPerType({ languages: this.getLanguageOptions(), values }) : fieldsDefaultSystem;
    return <FormComponent formId={SIDEPANEL_ID} modifiers={formModifiers} renderForm={formFields} />;
  };

  render() {
    const { sidepanel, submitInProgress, errors } = this.props;
    if (!sidepanel.visible) return null;
    const { parameters } = sidepanel;
    const isEdit = parameters && parameters.edit;
    return (
      <Sidepanel
        icon="Pen"
        title={isEdit ? text.EDIT_TITLE : text.TITLE}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.onSubmit, this.onClose, submitInProgress, isEdit)}
      >
        {this.renderForm()}
        {nonFieldErrorHandler(errors)}
      </Sidepanel>
    );
  }
}

const mapStateToProps = ({ overlays, forms }) => {
  const sidepanel = overlays[SIDEPANEL_ID];
  const { submitInProgress, fields, errors, values } = forms[SIDEPANEL_ID];
  return { sidepanel, submitInProgress, fields, errors, values };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      create,
      update,
      setFieldValue,
      closeSidepanel: overlayActions.close,
    },
    dispatch,
  ),
});

SettingsEmailsSidepanel.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(SettingsEmailsSidepanel));
