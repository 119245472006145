export default {
  DEPOSIT: 100, // PAYMENT_CATEGORY_DEPOSIT 
  CANCEL_WITHDRAWAL: 110, // PAYMENT_CATEGORY_CANCEL_WITHDRAWAL 
  BONUS: 130, // PAYMENT_CATEGORY_DEPOSIT_BONUS 
  ADJUSTMENT: 150, // PAYMENT_CATEGORY_DEPOSOIT_ADJUSTMENT
  WITHDRAWAL: 200, // PAYMENT_CATEGORY_WITHDRAWAL 
  CANCELLED_BONUS: 220, // PAYMENT_CATEGORY_CANCEL_BONUS 
  CANCELLED_DEPOSIT: 240, // PAYMENT_CATEGORY_CANCELLED_DEPOSIT 
  WITHDRAWAL_ADJUSTMENT: 260, // PAYMENT_CATEGORY_WITHDRAWAL_ADJUSTMENT 
  TRANSFER: 500, // PAYMENT_CATEGORY_TRANSFER 
};
