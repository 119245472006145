/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Button, notifier } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';

import connect from '../../../../logic/connect';
import isFeatureEnabled from '../../../../logic/filters/is-feature-enabled';
import { gettext } from '../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../components/grid';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import If from '../../../../components/If';
import Page from '../../../../components/Page';
import overlayActions from '../../../../components/overlay';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import { BiqUserPreview } from '../../../../components/common/sidepanels';
import modifier from './modifier';
import AddEditCommunicationStatus from './Sidepanel';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const pageConfig = {
  apiUrl: 'communication_statuses',
  reducerKey: 'COMMUNICATION_STATUSES',
  tableKey: 'COMMUNICATION_STATUSES_TABLE',
};

const { GridComponent, actions } = GridFactory(pageConfig);

const text = {
  TITLE: gettext('Communication statuses'),
  DELETE_SUCCESS: gettext('Communication status deleted successfully'),
  DELETE_ERROR: gettext('Error while deleting communication status'),
  CREATE_NEW: gettext('Create new'),
};

const bread = [{ label: text.TITLE, route: appRoutes.SETTINGS_COMMUNICATION_STATUSES }];

class CommunicationStatuses extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.headerActions = this.headerActions.bind(this);

    this.tableActions = {
      onEdit: hasAccess('communication_statuses.update') && this.onEdit.bind(this),
      onDelete: hasAccess('communication_statuses.destroy') && this.onDelete.bind(this),
      previewBiqUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  onEdit(data) {
    this.actions.openSidepanel('ADD_EDIT_COMMUNICATION_STATUS', { type: 'edit', data });
  }

  onDelete(data) {
    this.actions.deleteRow(data)
      .then(() => {
        notifier.success(text.DELETE_SUCCESS);
      }, () => {
        notifier.error(text.DELETE_ERROR);
      });
  }

  headerActions() {
    return (
      <If condition={hasAccess('communication_statuses.create')}>
        <Button onClick={() => this.actions.openSidepanel('ADD_EDIT_COMMUNICATION_STATUS', { type: 'add' })}>
          {text.CREATE_NEW}
        </Button>
      </If>
    );
  }

  render() {  
    const { fields, actions, table, addEditCommunicationStatus } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions),
    };

    return (
      <Page bread={bread} headerActions={this.headerActions} title={text.TITLE}>
        <Filters {...filterProps} />
        <GridComponent
          singleActions={!!(this.tableActions.onEdit || this.tableActions.onDelete)}
          tableModifier={modifier(this.tableActions)}
          {...this.props}
        />
        <If condition={addEditCommunicationStatus.visible}>
          <AddEditCommunicationStatus onSuccess={this.actions.fetchTableData} />
        </If>
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages, overlays }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    addEditCommunicationStatus: overlays.ADD_EDIT_COMMUNICATION_STATUS,
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    updateRowData: actions.updateRowData, 
    deleteRow: actions.deleteRow,
    fetchTableData: actions.fetchTableData,
    openSidepanel: overlayActions.open,
    updateFilter: actions.updateFilter,
    changeSegment: actions.changeSegment,
    fetchOptions: actions.fetchOptions,
  }, 
  dispatch), 
});

const CommunicationStatusesPage = compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(CommunicationStatuses); 

export default {
  component: CommunicationStatusesPage,
  path: appRoutes.SETTINGS_COMMUNICATION_STATUSES,
  aclFn: () => hasAccess('communication_statuses.list') && isFeatureEnabled()('COMMUNICATION_LOG'),
};
