import USER_STATE from '../enums/user-state';
import USER_STATE_LABEL from '../enums/user-state-label';

export default function userState(state, reverse) {
  const searchObject = reverse ? USER_STATE_LABEL : USER_STATE;
  const resultObject = reverse ? USER_STATE : USER_STATE_LABEL;

  for (const key in searchObject) {
    if (searchObject.hasOwnProperty(key) && resultObject.hasOwnProperty(key) && searchObject[key] === state) {
      return resultObject[key];
    }
  }

  return '';
}
