/* eslint-disable react/prop-types */
import React from 'react';
import hideCols from '../../../logic/utilities/hideCols';
import isFeatureEnabled from '../../../logic/filters/is-feature-enabled';
import {
  TagsCell,
  StateCell,
  UsernameCell,
  RouteCell,
  DocsApprovedCell,
  YesNoCell,
  DateTimeCell,
  TradingBackendsCell,
} from '../../../components/gridCellRenderers';
import { hasAccess } from '../../../logic/services/acl';
import deselectCols from '../../../logic/utilities/deselectCols';
import { boolStrToYesNo } from '../../../utils/boolStrToYesNo';
import appRoutes from '../../../components/App/Router/appRoutes';
import queryOperators from '../../../logic/enums/query-operators';
import { gettext } from '../../../logic/utilities/languageUtility';

const getValueKey = (field) => {
  if (field) return field.display_name;

  return null;
};

export default ({ onPreview, onBiqUserPreview }) => ({
  ...deselectCols([
    'id',
    'total_deposits_num',
    'last_deposit_amount',
    'last_traded_amount',
    'registration_complete_date',
  ]),
  ...hideCols([
    // TODO: @pavle it's most likely safe to remove these 5 fields under this TODO, will confirm
    //  with backend devs, found some info in https://tradecrowd.atlassian.net/browse/BIQB-1121
    //  about hiding these fields, looks like it's specific to traders platform which are no longer
    //  clients. Last deposit amount field seems to be returned and has values, maybe that one is
    //  still used somehow.
    
    'ib_link_id',
  ]),
  origin: {
    hide: !isFeatureEnabled()('CLIENT_ORIGIN_FIELD'),
  },
  username: {
    pinned: 'left',
    lockPinned: true,
    lockPosition: true,
    cellRendererFramework: UsernameCell(onPreview, { withOnlineStatus: hasAccess('team.online_auto_update.*') && isFeatureEnabled()('ONLINE_STATUS') }),
  },
  whitelabel: {
    quickFilter: {
      operator: queryOperators.EXACT.value,
    },
  },
  last_action_time: {
    width: 280,
    quickFilter: {
      operator: queryOperators.RANGE.value,
    },
  },
  title: {
    quickFilter: {
      operator: queryOperators.EXACT.value,
    },
  },
  tags: {
    cellRendererFramework: TagsCell,
    quickFilter: {
      operator: queryOperators.IN.value,
    },
  },
  state: {
    cellRendererFramework: StateCell,
    position: 2,
    quickFilter: {},
  },
  sales_status: {
    quickFilter: {
      operator: queryOperators.EXACT.value,
    },
  },
  docs_approved: {
    cellRendererFramework: DocsApprovedCell,
    filterOptions: {
      getValueKey,
    },
  },
  trading_backends: {
    cellRendererFramework: TradingBackendsCell,
    quickFilter: {
      operator: queryOperators.IN.value,
    },
  },
  assigned_to: { 
    cellRendererFramework: UsernameCell(onBiqUserPreview, { isStaff: true }),
    quickFilter: {
      operator: queryOperators.EXACT.value,
    },
  },
  verified_by: { 
    cellRendererFramework: UsernameCell(onBiqUserPreview, { isStaff: true }),
    quickFilter: {
      operator: queryOperators.EXACT.value,
    },
  },
  invited_by: { 
    cellRendererFramework: UsernameCell(onPreview, { isIb: true }),
    quickFilter: {
      operator: queryOperators.EXACT.value,
    },
  },
  manager_team: {
    cellRendererFramework: RouteCell({ href: appRoutes.TEAM }),
    quickFilter: {
      operator: queryOperators.EXACT.value,
    },
  },
  currency: {
    quickFilter: {
      operator: queryOperators.EXACT.value,
    },
  },
  campaign: {
    cellRendererFramework: RouteCell({ href: appRoutes.CAMPAIGN }),
  },
  date_joined: {
    cellRendererFramework: DateTimeCell,
  },
  loss_limit_reached: {
    cellRendererFramework: YesNoCell,
    valueGetter: ({ data }) => (data.loss_limit_reached ? boolStrToYesNo(data.loss_limit_reached) : null),
  },
  documents_check_states_list: {
    quickFilter: {
      operator: queryOperators.IN.value,
    },
    filterOptions: {
      getValueKey,
    },
    cellRendererFramework: ({ data }) => <span>{!_.isEmpty(data.documents_check_states_list) ? data.documents_check_states_list.join(', ') : gettext('N/A')}</span>,
  },
});
