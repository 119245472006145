import React from 'react';
import { object } from 'prop-types';
import { HyperLink } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';
import { includes } from 'lodash';

import entityFilter from '../logic/filters/entity';
import ENTITY_TYPES from '../logic/enums/entity';

const propTypes = {
  data: object,
};

const defaultProps = {
  data: null,
};

const getRouteParams = (entity) => {
  if (entity.type === ENTITY_TYPES.TRADING_ACCOUNT) {
    return { userId: entity.user_id, accountId: entity.id };
  }

  if (entity.type === ENTITY_TYPES.CHAT) {
    return { id: entity.id, channel: 'Chat' };
  }

  if (entity.type === ENTITY_TYPES.MESSAGE) {
    return { id: entity.id, channel: 'Email' };
  }

  if (entity.type === ENTITY_TYPES.SMS) {
    return { id: entity.id, channel: 'SMS' };
  }

  if (entity.type === ENTITY_TYPES.CALL) {
    return { id: entity.id, channel: 'calls' };
  }

  return { id: entity.id };
};

const unlinkedDataTypes = [
  ENTITY_TYPES.BO_USER,
  ENTITY_TYPES.TRADING_BACKEND,
  ENTITY_TYPES.PAYMENT_GATEWAY,
];

const Entity = ({ data }) => {
  if (!data) return <span>N/A</span>;

  if (includes(unlinkedDataTypes, data.type) || (data.type === ENTITY_TYPES.USER && data.display === 'system')) {
    return <span>{data.display}</span>;
  } if (data.type === ENTITY_TYPES.USER_DOCUMENT) {
    return <span>{data.display}</span>;
  } 
  
  const href = entityFilter()(data.type, data.is_staff);
  const params = getRouteParams(data);

  return _.isEmpty(href) ? <span>{data.display}</span> : (
    <HyperLink>
      <Link to={generatePath(href, params)}>{data.display}</Link>
    </HyperLink>
  );
};

Entity.propTypes = propTypes;
Entity.defaultProps = defaultProps;
export default Entity;
