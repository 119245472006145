import React from 'react';
import { string, func, any, bool } from 'prop-types';

import { fieldType } from './FilterTypes';
import { fieldInputResolver, inputTypes } from './inputTypeResolver';
import {
  isRange,
  isMultipleSelect,
  isCreatable,
  alwaysInputOperators,
  operatorsWithoutInput,
  isAsyncNestedList,
  getKeyField,
} from './filterUtils';
import {
  Select,
  MultipleSelect,
  TextInput,
  NumberInput,
  NumberRange,
  DateInput,
  DateRange,
  BooleanInput,
  TimeAgo,
} from './FilterInputs';

const propTypes = {
  field: fieldType,
  operator: string,
  onChange: func.isRequired,
  onEnter: func,
  value: any,
  withPortal: bool,
};

const defaultProps = {
  field: {},
  operator: '',
  value: '',
  withPortal: false,
  onEnter: () => {},
};

const getInputComponent = (field, selectedOperator) => {
  const inputType = fieldInputResolver(field);
  if (operatorsWithoutInput.includes(selectedOperator)) {
    return null;
  }

  if (alwaysInputOperators.includes(selectedOperator)) {
    return TextInput;
  }

  if (selectedOperator === 'minutes_ago') {
    return TimeAgo;
  }
  
  switch (inputType) {
    case inputTypes.SELECT:
      return isMultipleSelect(selectedOperator) ? MultipleSelect : Select;
    case inputTypes.NUMBER:
      if (isRange(selectedOperator)) return NumberRange;
      if (isMultipleSelect(selectedOperator)) return MultipleSelect;
      return NumberInput;
    case inputTypes.CALENDAR:
      return isRange(selectedOperator) ? DateRange : DateInput;
    case inputTypes.BOOLEAN:
      return BooleanInput;
    default:
      return isMultipleSelect(selectedOperator) ? MultipleSelect : TextInput;
  }
};


const FilterInput = ({ field, operator, onChange, onEnter, value, withPortal }) => {
  const Component = getInputComponent(field, operator);
  if (Component === null) return null;
  // TODO @pavle: listURL and queryKey is used in logic/utilities/loadOptions file, figure out
  // if it's correct for country field, and see if this logic can be refactored so these filter
  // fields are simplified
  let additionalProps = {};
  if (Component === Select || Component === MultipleSelect) {
    const choices = field.child?.choices || field.choices;
    additionalProps = {
      labelKey: 'display_name',
      valueKey: 'value',
      listUrl: field.list_url,
      queryKey: field.display_field,
      fieldId: getKeyField(field),
      options: choices,
      creatable: isCreatable(field, operator),
    };
    if (isAsyncNestedList(field)) {
      additionalProps.listUrl = _.get(field, 'child.list_url');
      additionalProps.queryKey = _.get(field, 'child.display_field');
    }
  }
  
  return (
    <Component
      onChange={onChange}
      filterOptions={field.filterOptions || {}}
      value={value}
      withPortal={withPortal}
      onEnter={onEnter}
      {...additionalProps}
    />
  );
};

FilterInput.propTypes = propTypes;
FilterInput.defaultProps = defaultProps;
export default FilterInput;
