import PendingDeposits from './PendingDeposits';
import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../../logic/services/acl';

export default {
  ...PendingDeposits(),
  key: 'pending-deposits',
  label: gettext('Pending deposits'),
  description: gettext('Pending deposits originated in the selected time period'),
  isVisible: () => hasAccess('dashboard.overall_deposits.*'),
};
