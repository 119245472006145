import { createAction, handleActions } from 'redux-actions';
import { handlePayoutValue, transformPayoutData } from './utils';

const initialState = [];

const actionTypes = {
  EDIT_REFUND_PAYOUT_ROW: 'EDIT_REFUND_PAYOUT_ROW',
  SET_REFUND_PAYOUT: 'SET_REFUND_PAYOUT',
  CLEAR_REFUND_PAYOUT: 'CLEAR_REFUND_PAYOUT',
};

export const editRefundPayoutRow = createAction(actionTypes.EDIT_REFUND_PAYOUT_ROW, row => (row));
export const setRefundPayoutTable = createAction(actionTypes.SET_REFUND_PAYOUT, table => table);
export const clearRefundPayoutTable = createAction(actionTypes.CLEAR_REFUND_PAYOUT, table => table);

export default handleActions(
  {
    [actionTypes.EDIT_REFUND_PAYOUT_ROW]: (state, { payload }) => {
      const newRow = transformPayoutData(payload);
      const prevRowValue = _.find(state, row => row.id === newRow.id);
      const row = handlePayoutValue(newRow, prevRowValue);
      const data = _.reject(state, row => row.id === newRow.id).concat(row);

      return data;
    },
    [actionTypes.SET_REFUND_PAYOUT]: (state, { payload }) => payload.map(transformPayoutData),
    [actionTypes.CLEAR_REFUND_PAYOUT]: () => initialState,
  }, initialState);
