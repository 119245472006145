import React, { Component, useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion,
  HyperLink,
  Pill,
  Code,
  Space,
  Dropdown,
  Button, 
  Checkbox,
  notifier,
} from 'tc-biq-design-system';

import { bindActionCreators } from 'redux';
import { 
  object,
  string,
  func,
  number,
  bool,
  array,
  shape,
} from 'prop-types';
import { withRouter } from 'react-router-dom';

import FormPanel from '../../../../../../components/form/Components/FormPanel/FormPanel';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../logic/connect';
import { fetchMimiroInfo, fetchCertificateUrl, patchMonitoringStatus, fetchStatuses, fetchSubstatusesOptions } from './Model';
import If from '../../../../../../components/If';

import './Monitoring.scss';

const text = {
  NO_MATCH: gettext('No match'),
  POTENTIAL_MATCH: gettext('Pontential match'),
  YOU_DO_NOT_HAVE_REPORTS: gettext("You don't have any reports"),
  SHARE_URL: gettext('Share url'),
  SHARE_FULL_REPORT: gettext('Share full report'),
  HIDE_FULL_REPORT: gettext('Hide full report'),
  DOWNLOAD_MEDIA: gettext('Download media'),
  DOWNLOAD_CERTIFICATE: gettext('Download certificate'),
  NO_MEDIA_RESULTS: gettext('No media results'),
  TRUE_POSITIVE: gettext('True positive'),
  FALSE_POSITIVE: gettext('False positive'),
  SUBMIT: gettext('Submit'),
  STATUS_SUCCESS: gettext('Status updated succesfully'),
};

const matchStatusesEnum = {
  NO_MATCH: { value: 'no_match', label: text.NO_MATCH },
  POTENTIAL_MATCH: { value: 'potential_match', label: text.POTENTIAL_MATCH },
};

const monitoringStatusesEnum = {
  TRUE_POSITIVE: 1,
  FALSE_POSITIVE: 2,
  POTENTIAL_MATCH: 3,
};

const DownloadIcon = ({ url, buttonText, onClick }) => (
  <a href={url} download={!!url}>
    <Button onClick={onClick} icon="Download" iconPosition="right">
      { buttonText }
    </Button>
  </a>
);

DownloadIcon.propTypes = {
  url: string,
  buttonText: string.isRequired,
  onClick: func,
};

DownloadIcon.defaultProps = {
  url: string.isRequired,
  onClick: null,
};

const getMediaLinks = (details) => {
  const onClickLink = (e) => {
    e.stopPropagation();
    window.open(e.target.innerHTML);
  };
  
  if (!details) {
    return [];
  }

  const hits = _.get(details, 'data.hits');

  if (Array.isArray(hits) && hits.length) {
    const doc = hits[0] && hits[0].doc;
    const media = doc && doc.media;
    return (media || []).map(({ url }) => ({
      label: url,
      onClick: onClickLink,
    }));
  }

  return [];
};

const MonitoringAccordion = ({
  mimiroInfo,
  onChangeMonitoringStatus,
  details,
  monitoringStatus,
  subStatusesOptions,
}) => {
  const [visible, setVisible] = useState(false);
  const [showFullReport, setShowFullReport] = useState(false);
  const [selectedSubstatuses, setSelectedSubstatuses] = useState([]);
  const onChangeMonitoringSubStatus = useCallback((value) => {
    if (selectedSubstatuses.includes(value)) {
      setSelectedSubstatuses(selectedSubstatuses.filter(s => s !== value));
    } else {
      setSelectedSubstatuses([...selectedSubstatuses, value]);
    }
  }, [selectedSubstatuses]);
  
  const matchStatus = _.get(details, 'data.match_status');
  const updatedAt = _.get(details, 'data.updated_at');
  const shareUrl = _.get(details, 'data.share_url');
  const title = moment(updatedAt).format('D MMM YYYY');
  const status = Object
    .keys(matchStatusesEnum)
    .filter(key => matchStatusesEnum[key].value === matchStatus);
  const mediaLinks = getMediaLinks(details);
  const monitorStatus = _.get(monitoringStatus, 'status');
  const monitorSubstatuses = _.get(monitoringStatus, 'substatuses');

  if (!mimiroInfo) {
    return null;
  }

  useEffect(() => {
    setSelectedSubstatuses(monitorSubstatuses);
  }, [monitorSubstatuses]);

  const customList = useMemo(() => [
    {
      onClick: () => {},
      item: close => (
        <div className="activity-type-filter" key="activity-type-filter">
          <div className="activity-type-filter__body">
            {subStatusesOptions.map(({ display_name, value }) => (
              <div key={display_name} className="activity-type-filter__body__checkbox">
                <Checkbox 
                  name={display_name} 
                  checked={selectedSubstatuses.includes(value)}
                  value={value}
                  onChange={() => onChangeMonitoringSubStatus(value)}
                >
                  {display_name}
                </Checkbox>
              </div>
            ))}
          </div>
          <div className="activity-type-filter__footer">
            <Button
              disabled={_.isEmpty(selectedSubstatuses)}
              onClick={() => {
                onChangeMonitoringStatus(details.id, {
                  status: TRUE_POSITIVE,
                  substatuses: [
                    ...selectedSubstatuses,
                  ],
                });
                close();
              }}
              size="full-width"
            >
              {text.SUBMIT}
            </Button>
          </div>
        </div>
      ),
    },
  ], [subStatusesOptions, selectedSubstatuses]);
   
  const { TRUE_POSITIVE, FALSE_POSITIVE, POTENTIAL_MATCH } = monitoringStatusesEnum;

  return (
    <Accordion
      iconRight={() => (mediaLinks.length ? (
        <Dropdown
          list={mediaLinks}
          title={text.DOWNLOAD_MEDIA}
          openLeft
        />
      ) : text.NO_MEDIA_RESULTS)}
      title={title}
      onClick={() => { setVisible(!visible); }}
      visible={visible}
    >
      <div className="monitoring-form-panel__info">
        <div>
          <Pill type="status02">
            { status && matchStatusesEnum[status] && matchStatusesEnum[status].label }
          </Pill>
          <HyperLink>
            <a href={shareUrl}>{ text.SHARE_URL }</a>
          </HyperLink>
        </div>
        <div className="monitoring-form-panel__positive">
          <If condition={monitorStatus === POTENTIAL_MATCH
             || monitorStatus === TRUE_POSITIVE
             || monitorStatus === FALSE_POSITIVE}
          >
            <Dropdown
              title={text.TRUE_POSITIVE}
              customList={customList}
              customHeader={onToggle => (
                <Button 
                  color={monitorStatus === TRUE_POSITIVE ? 'destructive' : 'ghost'}
                  icon="CaretDown"
                  iconPosition="right"
                  onClick={onToggle}
                >
                  { text.TRUE_POSITIVE }
                </Button>
              )}
              openLeft
            />
            <Button 
              color={monitorStatus === FALSE_POSITIVE ? 'destructive' : 'ghost'}
              onClick={() => onChangeMonitoringStatus(details.id, {
                status: FALSE_POSITIVE,
                substatuses: [],
              })}
              disabled={monitorStatus === FALSE_POSITIVE}
            > 
              { text.FALSE_POSITIVE } 
            </Button>
          </If>
        </div>
      </div>
      <Space size={16} />
      { showFullReport && <Code data={details} /> }
      { showFullReport && <Space size={16} /> }
      <Button 
        style={{ margin: 0 }}
        color="ghost"
        size="full-width"
        onClick={() => { setShowFullReport(!showFullReport); }}
      >
        {
        showFullReport ? text.HIDE_FULL_REPORT : text.SHARE_FULL_REPORT
        }
      </Button>
    </Accordion>
  );
};

MonitoringAccordion.propTypes = {
  mimiroInfo: object,
  onChangeMonitoringStatus: func.isRequired,
  details: object.isRequired,
  monitoringStatus: number.isRequired,
  subStatusesOptions: array,
};

MonitoringAccordion.defaultProps = {
  mimiroInfo: null,
  subStatusesOptions: [],
};

class Monitoring extends Component {
  constructor(props) {
    super(props);
    const { match: { params: { id } } } = props;
    this.id = id;
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.fetchMimiroInfo(this.id);
    actions.fetchCertificateUrl(this.id);
    actions.fetchStatuses(this.id);
    actions.fetchSubstatusesOptions(this.id);
  }

  onChangeMonitoringStatus = (statusId, status) => {
    const { actions } = this.props;
    actions.patchMonitoringStatus(this.id, statusId, status).then(() => {
      notifier.success(text.STATUS_SUCCESS);
      actions.fetchStatuses(this.id);
    });
  };

  render() {
    const { 
      isLoadingMimiroInfo,
      statuses, 
      mimiroInfo,
      certificateUrl, 
      subStatusesOptions,
    } = this.props;

    const { monitoring_status } = (mimiroInfo || {});

    if (!isLoadingMimiroInfo && monitoring_status === -1) {
      return (
        <div className="monitoring-form-panel__no-report">
          <h4>{ text.YOU_DO_NOT_HAVE_REPORTS }</h4> 
        </div>
      );
    }
    
    return (
      <div className="monitoring-form-panel">
        <FormPanel>
          <FormPanel.Section>
            <div className="monitoring-form-panel__cert">
              { certificateUrl 
               && (
               <DownloadIcon 
                 buttonText={text.DOWNLOAD_CERTIFICATE} 
                 url={certificateUrl}
               />
               )}
            </div>
            { certificateUrl && <Space size={16} /> }
            { statuses.length && mimiroInfo && mimiroInfo.details.map((d, index) => (
              <MonitoringAccordion
                details={d}
                key={d.id}
                monitoringStatus={statuses[index]}
                mimiroInfo={mimiroInfo}
                onClickAccordion={this.onClickAccordion}
                toggleFullReportPreview={this.toggleFullReportPreview}
                onChangeMonitoringStatus={this.onChangeMonitoringStatus}
                subStatusesOptions={subStatusesOptions}
              />
            ))}
          </FormPanel.Section>
        </FormPanel>
      </div>
    );
  }
}

Monitoring.propTypes = {
  match: shape({
    params: shape({
      id: string,
    }),
  }).isRequired,
  actions: object.isRequired,
  isLoadingMimiroInfo: bool.isRequired,
  mimiroInfo: object,
  certificateUrl: string,
  statuses: array.isRequired,
  subStatusesOptions: array,
};

Monitoring.defaultProps = {
  mimiroInfo: null,
  certificateUrl: null,
  subStatusesOptions: [],
};

const mapStateToProps = ({ pages }) => ({
  ...pages.USER_SINGLE.monitoring,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchMimiroInfo,
    fetchCertificateUrl,
    patchMonitoringStatus,
    fetchStatuses,
    fetchSubstatusesOptions,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Monitoring));
