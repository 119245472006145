/* eslint react/prop-types: 0 */
import React, { Fragment, useCallback } from 'react';
import { bindActionCreators, compose } from 'redux';
import { generatePath } from 'react-router-dom';

import { notifier } from 'tc-biq-design-system';
import connect from '../../../../../../logic/connect';
import GridFactory from '../../../../../../components/grid';
import overlayActions from '../../../../../../components/overlay';
import { BiqUserPreview } from '../../../../../../components/common/sidepanels';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import modifiers from './modifiers';
import { hasAccess } from '../../../../../../logic/services/acl';
import appRoutes from '../../../../../../components/App/Router/appRoutes';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

const pageConfig = {
  reducerKey: 'DEPOSITS',
  apiUrl: 'settings/deposit_expiry_period',
  tableKey: 'DEPOSIT_EXPIRY_PERIOD',
};

const text = {
  EXPIRY_PERIOD_DELETE_SUCCESS: gettext('Deposit expiry period deleted successfully'),
  EXPIRY_PERIOD_DELETE_ERROR: gettext('Error while deleting deposit expiry period'),
  TOGGLE_SUCCESS_MESSAGE_ON: gettext('Expiry period was successfully enabled'),
  TOGGLE_SUCCESS_MESSAGE_OFF: gettext('Expiry period was successfully disabled'),
  TOGGLE_FAIL_MESSAGE: gettext('Error while switching deposit expiry period'),
};

const { GridComponent, actions } = GridFactory(pageConfig);

const DepositExpiryPeriod = (props) => {
  const { actions } = props;

  const toggleEnable = async ({ id }, is_enabled) => {
    const res = await actions.updateRowData(id, { is_enabled });
    if (res.value) {
      res.value.error ? notifier.error(text.TOGGLE_FAIL_MESSAGE) : notifier.success(is_enabled ? text.TOGGLE_SUCCESS_MESSAGE_ON : text.TOGGLE_SUCCESS_MESSAGE_OFF);
    }
  };

  const onDelete = useCallback(data => actions.deleteRow(data)
    .then(
      () => notifier.success(text.EXPIRY_PERIOD_DELETE_SUCCESS),
      () => notifier.error(text.EXPIRY_PERIOD_DELETE_ERROR),
    ), [actions, notifier, text]);

  const onModify = ({ id }) => generatePath(appRoutes.SETTINGS_DEPOSIT_EXPIRY, { id });

  const onClone = ({ id }) => generatePath(appRoutes.SETTINGS_DEPOSIT_EXPIRY_CLONE, { id });

  const tableActions = {
    toggleEnable: hasAccess('settings.deposit_expiry_period.update') && toggleEnable,
    onDelete: hasAccess('settings.deposit_expiry_period.destroy') && onDelete,
    onModify: hasAccess('settings.deposit_expiry_period.update') && onModify,
    onClone: hasAccess('settings.deposit_expiry_period.create') && onClone,
  };

  return (
    <Fragment>
      <BiqUserPreview />
      <GridComponent singleActions={!!Object.keys(actions).length} tableModifier={modifiers(tableActions)} />
    </Fragment>
  );
};

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchTableData: actions.fetchTableData,
    updateRowData: actions.updateRowData,
    deleteRow: actions.deleteRow,
    updateFilter: actions.updateFilter,
    changeSegment: actions.changeSegment,
    fetchOptions: actions.fetchOptions,
    openSidepanel: overlayActions.open,
  }, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(DepositExpiryPeriod);
