import React from 'react';
import { Select as SelectComponent } from 'tc-biq-design-system';
import { func, string, object, bool } from 'prop-types';

import loadOptions from '../../../logic/utilities/loadOptions';

const propTypes = {
  onChange: func.isRequired,
  listUrl: string,
  queryKey: string,
  fieldId: string,
  filterOptions: object,
  withPortal: bool,
};

const defaultProps = {
  listUrl: '',
  queryKey: '',
  fieldId: 'id',
  filterOptions: {},
  withPortal: false,
};

const Select = ({ onChange, listUrl, queryKey, fieldId, filterOptions, withPortal, ...props }) => {
  const query = filterOptions && filterOptions.query;
  return (
    <SelectComponent
      type="search"
      clearable={false}
      loadOptions={loadOptions(listUrl, queryKey, _, fieldId, query)}
      async={!!listUrl}
      debounceInterval={listUrl ? 500 : 0}
      onChange={selected => onChange(selected)}
      menuPortalTarget={withPortal ? document.body : null}
      {...props}
    />
  );
};

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
