import React, { Component, Fragment } from 'react';
import { Space, Input, Panel, Pill } from 'tc-biq-design-system';
import { object, array } from 'prop-types';
import { bindActionCreators } from 'redux';

import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';
import { fetchNicFields, fetchNicValues } from './Model';
import connect from '../../../../../../../logic/connect';
import './EditMifid.scss';

const FORM_KEY = 'EDIT_MIFID_FORM';

const { setFieldValue, setFields, showLoader } = FormActionsFactory(FORM_KEY);

const text = {
  PRIORITY: (priority) => {
    const labels = {
      1: gettext('First Priority'),
      2: gettext('Second Priority'),
      3: gettext('Third Priority'),
    };
    return labels[priority];
  },
  NIC_CODE: gettext('NIC Code'),
  NATIONALITY: gettext('Nationality'),
};

const propTypes = {
  user: object,
  actions: object,
  nationalityOptions: array,
  nicFields: array,
  nicValues: array,
  formValues: object,
  nicFieldsErrors: array,
};

const defaultProps = {
  user: null,
  actions: null,
  nationalityOptions: [],
  nicFields: [],
  nicValues: [],
  formValues: null,
  nicFieldsErrors: [],
};

class EditMifidContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
    };
  }

  componentDidMount() {
    this.fetchOptions();
  }

  componentDidUpdate(prevProps) {
    this.onNationalityChange(prevProps);
    this.onError(prevProps);
  }

  onNationalityChange = (prevProps) => {
    const { formValues } = this.props;
    const prevNationality = _.get(prevProps, 'formValues.nationality.value');
    const nationality = _.get(formValues, 'nationality.value');

    if (prevNationality !== nationality) {
      this.setNationalityPriorities(nationality);
    }
  }

  onError({ nicFieldsErrors: prevNicFieldsErrors }) {
    const { nicFieldsErrors = [] } = this.props;

    if (_.isEmpty(prevNicFieldsErrors) && !_.isEmpty(nicFieldsErrors)) {
      this.setState({
        errors: nicFieldsErrors.reduce(this.formatError, {}),
      });
    }
  }

  onPriorityChange = (e) => {
    const { value, name } = e.target;
    const { formValues } = this.props;
    const { priorities } = formValues;

    const changedPriorities = priorities.map(field => ({
      ...field,
      ...(name === field.key && { value }),
    }));

    this.setField('priorities', changedPriorities);
  }

  setNationalityPriorities = (nationality) => {
    const { nicValues } = this.props;
    const priorities = this.filterPriorities(nationality);

    const defaultPriorities = this.generateDefaultPriorities(priorities, nicValues, nationality);
    this.setField('priorities', defaultPriorities);
  }

  setField = (id, value) => {
    const { actions } = this.props;
    actions.setFieldValue({
      id,
      value,
    });
  }

  setDefaultNationality = () => {
    const { nicValues, nationalityOptions } = this.props;
    const defaultNationalityId = _.get(nicValues, '[0].nationality');

    if (defaultNationalityId) {
      const defaultNationality = nationalityOptions.find(nationality => nationality.value === defaultNationalityId) || nationalityOptions[0];
      this.setField('nationality', defaultNationality);

      this.setNationalityPriorities(defaultNationalityId);
    }
  }

  formatError = (memo, current) => Object.assign({}, memo, _.mapValues(current, o => o[0]));

  generateDefaultPriorities = (priorities, values, nationality) => {
    const hybridId = field => `${field.priority}-${field.nationality}`;
    const hashedValues = values.reduce((acc, value) => ({ ...acc, [hybridId(value)]: value }), {});

    return priorities.map((field) => {
      const selectedValue = hashedValues[hybridId({ priority: field.priority, nationality })];
      
      return {
        ...field,
        ...(selectedValue && { ...selectedValue }),
      };
    });
  }

  filterPriorities = (selectedNationality) => {
    const { nicFields } = this.props;
    return nicFields.filter(field => (
      selectedNationality === field.nationality
        || (_.includes(field.nationality, selectedNationality) && field.key !== 'national_passport_number_notin')
        || (!_.includes(field['!nationality'], selectedNationality) && field.key === 'national_passport_number_notin')
    )).sort((a, b) => a.priority - b.priority);
  }

  fetchOptions = () => {
    const { actions, user } = this.props;

    actions.showLoader(true);
  
    Promise.all([
      actions.fetchNicFields(user),
      actions.fetchNicValues(user),
    ]).then(() => {
      const { nationalityOptions } = this.props;
      const fields = this.generateFormFields(nationalityOptions);
      actions.setFields(fields);
      this.setDefaultNationality();
      actions.showLoader(false);
    });
  }

  generateFormFields = (nationalityOptions = []) => ([{
    type: 'select',
    id: 'nationality',
    name: 'nationality',
    searchable: true,
    placeholder: text.NATIONALITY,
    label: text.NATIONALITY,
    options: nationalityOptions,
    valueKey: 'value',
    labelKey: 'display_name',
    joinValues: true,
  }, {
    type: 'text',
    id: 'priorities',
    name: 'priorities',
  }]);

  attachErrorToField = (priority) => {
    const { errors } = this.state;
    if (errors.hasOwnProperty(priority.key)) {
      priority.error = errors[priority.key];
    }

    return priority;
  };

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory({ form: FORM_KEY });
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  renderField = ({ nationality, label, priority, kind, key, value, code, error }) => (
    <Panel key={`${nationality}-${priority}`} title={text.PRIORITY(priority)}>
      <div className="edit-mifid__panel-wrapper">
        <Input
          key={`${priority}-${nationality}`}
          label={label}
          name={key}
          type={kind}
          value={value}
          helpText={error}
          hasError={!!error}
          onChange={this.onPriorityChange}
        />
        {code && (
        <div className="edit-mifid__field">
          <label htmlFor="foo" className="edit-mifid__label">{text.NIC_CODE}</label>
          <Pill>{code}</Pill>
        </div>
        )}
      </div>
    </Panel>
  )

  renderPriorities = priorities => priorities.map(this.attachErrorToField).map(this.renderField);

  render() {
    const { formValues } = this.props;
    const { priorities } = formValues;
    const Form = this.renderForm();
    
    return (
      <Fragment>
        <Space size={16} />
        <Form 
          formId={FORM_KEY} 
          renderForm={formFields => (
            <Fragment>
              {formFields.nationality}
              { priorities && this.renderPriorities(priorities)}
            </Fragment>
          )} 
        />
      </Fragment>
    );
  }
}

EditMifidContent.propTypes = propTypes;
EditMifidContent.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchNicFields,
      fetchNicValues,
      setFieldValue,
      setFields,
      showLoader,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages, forms }) => {
  const page = pages.USER_SINGLE;
  const action = page.actions.EDIT_MIFID;
  const form = forms[FORM_KEY];
  const { 
    nicFields, 
    nicValues, 
    nationalityOptions,
  } = action;

  return {
    nicFields,
    nicValues,
    nationalityOptions,
    formValues: form.values,
    nicFieldsErrors: _.get(form, 'errors.nic_fields'),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMifidContent);
