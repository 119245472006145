import { object } from 'prop-types';
import { Pill } from 'tc-biq-design-system';
import React from 'react';

const TradingBackendsCell = ({ data }) => (
  <div>
    {
      data.trading_backends.map(({ name }, index) => (
        <Pill key={index}>{name}</Pill>
      ))
    }
  </div>
);

TradingBackendsCell.propTypes = {
  data: object.isRequired,
};

export default TradingBackendsCell;
