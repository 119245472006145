/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Sidepanel, Space, notifier } from 'tc-biq-design-system';
import { object } from 'prop-types';
import { bindActionCreators } from 'redux';

import connect from '../../../../../logic/connect';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import overlayActions from '../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../components/common';

const propTypes = {
  sidepanel: object.isRequired,
  tableActions: object.isRequired,
};

const text = {
  BUTTON_LABELS: {
    confirm: gettext('Delete'),
    cancel: gettext('Cancel'),
  },
  SUCCESS: gettext('Event(s) deleted successfully'),
  ERROR: gettext('Failed deleting event(s)'),
  SIDEPANEL_BODY_SINGLE: gettext('Are you sure you want to delete this rule?'),
  SIDEPANEL_BODY_MULTIPLE: gettext('Are you sure you want to delete these rules?'),
  SIDEPANEL_TITLE_SINGLE: gettext('Delete sms event'),
  SIDEPANEL_TITLE_MULTIPLE: gettext('Delete sms events'),
};

const SIDEPANEL_ID = 'DELETE_SMS_EVENT';

const customFooter = (execute, close, submitInProgress) => (
  <SidepanelFooter
    execute={execute}
    close={close}
    submitInProgress={submitInProgress}
    confirmColor="destructive"
    buttonLabels={text.BUTTON_LABELS}
  />
);

const SidepanelBody = ({ data }) => ( 
  <div>
    <Space size={16} />
    {data.length < 2 ? text.SIDEPANEL_BODY_SINGLE : text.SIDEPANEL_BODY_MULTIPLE}
    <Space size={16} />
    <ul>
      {data.map(item => (
        <li key={item.id}>{item.name}</li>
      ))}
    </ul>
  </div>
);

class DeleteSmsEventSidepanel extends Component {
  constructor(props) {
    super(props);
    this.actions = { ...props.actions, ...props.tableActions };
    this.execute = this.execute.bind(this);
    this.onClose = this.onClose.bind(this);
    this.api = getDjangoApi('sms/trigger_rules');
    this.submitInProgress = false;
    this.state = { submitInProgress: false };
  }

  componentWillUnmount() {
    this.actions.close(SIDEPANEL_ID);
  }

  onClose(submitSuccess) {
    if (submitSuccess) this.actions.fetchTableData();
    this.actions.close(SIDEPANEL_ID);
  }

  execute() {
    const { sidepanel } = this.props;
    const { parameters: data } = sidepanel;
    if (!data.length) return;
    // TODO: replace this API when https://tradecrowd.atlassian.net/browse/BIQS-3204 is resolved
    const deleteAPI = getDjangoApi(`sms/trigger_rules/${data[0].id}`);
    this.setState({ submitInProgress: true });

    this.actions.deleteRow(data, deleteAPI).then(() => {
      this.setState({ submitInProgress: false });
      notifier.success(text.SUCCESS);
      this.onClose(true);
    }, (error) => {
      this.setState({ submitInProgress: false });
      notifier.error(error.data.detail ? error.data.detail : text.ERROR);
    });
  }

  render() {
    const { submitInProgress } = this.state;
    const { sidepanel } = this.props;
    const { parameters: data, visible } = sidepanel;
    const title = data && data.length > 1 ? text.SIDEPANEL_TITLE_MULTIPLE : text.SIDEPANEL_TITLE_SINGLE;

    return (
      <Sidepanel
        type="error"
        icon="Close"
        title={title}
        visible={visible}
        onCloseIconClick={() => this.actions.close(SIDEPANEL_ID)}
        footerRender={() => customFooter(this.execute, this.onClose, submitInProgress)}
      >
        <SidepanelBody data={data} />
      </Sidepanel>
    );
  }
}

DeleteSmsEventSidepanel.propTypes = propTypes;

const mapStateToProps = ({ overlays }) => ({ sidepanel: overlays[SIDEPANEL_ID] });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ close: overlayActions.close }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(DeleteSmsEventSidepanel));
