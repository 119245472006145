import { PraxisPay } from './praxisPay';
import { Ingenico } from './ingenico';
import { GenericGateway } from './genericGateway';

export const GatewayFactory = (name) => {
  switch (name) {
    case 'praxispay':
      return new PraxisPay(name);
    case 'ingenico':
      return new Ingenico(name);
    default:
      return new GenericGateway(name);
  }
};
