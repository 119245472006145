import React from 'react';
import { Tooltip, Icon } from 'tc-biq-design-system';

import hideCols from '../../../logic/utilities/hideCols';
import { gettext } from '../../../logic/utilities/languageUtility';
import { ToggleCell, ActionsCellWrapper } from '../../../components/gridCellRenderers';
import deselectCols from '../../../logic/utilities/deselectCols';

const text = {
  EDIT: gettext('Edit'),
  CLONE: gettext('Clone'),
};

const modifyIcon = (actionHandler, data) => ( 
  <div key="Modify" onClick={() => actionHandler(data)}>
    <Tooltip title={text.EDIT} placement="top">
      <Icon name="Edit" />
    </Tooltip>
  </div>
);

const CloneIcon = (actionHandler, data) => ( 
  <div key="Clone" onClick={() => actionHandler(data)}>
    <Tooltip title={text.CLONE} placement="top">
      <Icon name="Duplicate" />
    </Tooltip> 
  </div>
);

const modifier = onToggle => ({
  ...hideCols(['id']),
  ...deselectCols(['updated_date', 'created_by']),
  ...(onToggle && {
    is_active: {
      width: 120,
      pinned: 'left',
      cellRendererFramework: ToggleCell(onToggle),
    },
  }),
});

const gatewaysModifier = ({ onModify, onToggleGateways }) => ({
  ...modifier(onToggleGateways),
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper({ onModify }, { onModify: modifyIcon }),
    width: 120,
  },
});

const reasonsModifier = ({ onToggleReasons, onClone, onModifyReasons }) => ({
  ...modifier(onToggleReasons),
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper({ onClone, onModify: onModifyReasons }, { onClone: CloneIcon, onModify: modifyIcon }),
    width: 120,
  },
});

export { gatewaysModifier, reasonsModifier };
