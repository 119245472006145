import React from 'react';

import UserDropdown from './UserDropdown';
import SearchAll from './SearchAll';

const AppHeader = () => (
  <div className="react-app-header">
    <SearchAll />
    
    <UserDropdown />
  </div>
);

export default AppHeader;
