import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../logic/services/api-factory';

const initialState = {
  data: {},
  submitInProgress: false,
};

const actionsTypes = {
  EXPORT: 'EXPORT',
  EXPORT_PENDING: 'EXPORT_PENDING',
  EXPORT_FULFILLED: 'EXPORT_FULFILLED',
};

const api = getDjangoApi('reports/regulation');
export const exportData = createAction(actionsTypes.EXPORT, async () => {
  const response = await api.list();
  return response.data;
});

export default {
  exportData,
};

export const reducer = handleActions({
  [actionsTypes.EXPORT_PENDING]: state => ({ ...state, submitInProgress: true }),
  [actionsTypes.EXPORT_FULFILLED]: (state, { payload }) => ({
    ...state,
    submitInProgress: false,
    data: payload,
  }),
}, initialState);
