import React, { Component, Fragment } from 'react';
import { func } from 'prop-types';
import { notifier } from 'tc-biq-design-system';

import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import modifiers from './modifiers';
import EditTotalRiskSidepanel, { sidepanelID, api } from './EditTotalRiskSidepanel';
import to from '../../../../../../logic/utilities/to';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../logic/services/acl';

const tableRiskConfig = () => ({
  reducerKey: 'RISK_RATING_SYSTEM',
  apiUrl: 'settings/total_deposit_risk_groups/',
  tableKey: 'TOTAL_DEPOSITS_TABLE',
});

const text = {
  deleteSuccessMsg: gettext('Total deposit risk group deleted successfully'),
  deleteErrorMsg: gettext('Error occurred'),
};

const propTypes = {
  openSidePanel: func.isRequired,
  closeSidePanel: func.isRequired,
  dispatch: func.isRequired,
};

class TotalDeposits extends Component {
  constructor(props) {
    super(props);


    this.tableData = withTable(GridTab, tableRiskConfig, null, { withActions: true })();
    const { openSidePanel } = this.props;
    this.actions = {
      modify: hasAccess('total_deposit_risk_group.settings.update') && ((data) => { openSidePanel(sidepanelID, data); }),
      remove: hasAccess('total_deposit_risk_group.settings.destroy') && this.onRemove, 
    };
  }

  onRemove = async ({ id }) => {
    const { dispatch } = this.props;
    const promise = api.destroy(id);

    const [error] = await to(promise);

    if (error) {
      notifier.error(text.deleteErrorMsg);
    } else {
      notifier.success(text.deleteSuccessMsg);
      dispatch(this.tableData[1].fetchTableData());
    }
  }

  render() {
    const [TotalDepositsTable, actions] = this.tableData;
    const { closeSidePanel } = this.props;
    return (
      <Fragment>
        <TotalDepositsTable
          singleActions={!!(this.actions.modify || this.actions.remove)}
          modifier={modifiers(this.actions)}
        />
        <EditTotalRiskSidepanel 
          closeSidePanel={closeSidePanel}
          fetchTableData={actions.fetchTableData}
        />
      </Fragment>
    );
  } 
}

TotalDeposits.propTypes = propTypes;

export default TotalDeposits;
