import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import defaultDashboard from './data/defaultDashboard.json';

const dashboardLayoutApi = () => getDjangoApi('settings/layout/dashboard');
const dashboardLayoutsApi = () => getDjangoApi('dashboard/layouts');
const assignToUsersApi = dashboard => getDjangoApi(`settings/layout/dashboard/${dashboard.id}/assign_to_users`);
const assignToTeamsApi = dashboard => getDjangoApi(`settings/layout/dashboard/${dashboard.id}/assign_to_teams`);


const initialState = {
  dashboard: null,
  dashboards: [],
  selectedUsers: [],
  selectedTeams: [],
  userOptions: [],
  teamOptions: [],
  startDate: moment()
    .subtract(1, 'month')
    .format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  submitAssignInProgress: false,
  submitUpdateInProgress: false,
  submitDeleteInProgress: false,
  fetchDashboardInProgress: false,
  fetchDashboardsInProgress: false,
};

const actionTypes = {
  SUBMIT_DASHBOARD_ASSIGNMENT: 'SUBMIT_DASHBOARD_ASSIGNMENT',
  SUBMIT_DASHBOARD_ASSIGNMENT_PENDING: 'SUBMIT_DASHBOARD_ASSIGNMENT_PENDING',
  SUBMIT_DASHBOARD_ASSIGNMENT_FULFILLED: 'SUBMIT_DASHBOARD_ASSIGNMENT_FULFILLED',
  SUBMIT_DASHBOARD_ASSIGNMENT_REJECTED: 'SUBMIT_DASHBOARD_ASSIGNMENT_REJECTED',

  SUBMIT_LAYOUT_DELETE: 'SUBMIT_LAYOUT_DELETE', 
  SUBMIT_LAYOUT_DELETE_PENGING: 'SUBMIT_LAYOUT_DELETE', 
  SUBMIT_LAYOUT_DELETE_FULFILLED: 'SUBMIT_LAYOUT_DELETE_FULFILLED', 
  SUBMIT_LAYOUT_DELETE_REJECTED: 'SUBMIT_LAYOUT_DELETE_REJECTED', 

  SUBMIT_LAYOUT_UPDATE: 'SUBMIT_LAYOUT_UPDATE', 
  SUBMIT_LAYOUT_UPDATE_PENGING: 'SUBMIT_LAYOUT_UPDATE', 
  SUBMIT_LAYOUT_UPDATE_FULFILLED: 'SUBMIT_LAYOUT_UPDATE_FULFILLED', 
  SUBMIT_LAYOUT_UPDATE_REJECTED: 'SUBMIT_LAYOUT_UPDATE_REJECTED',

  FETCH_DASHBOARD: 'FETCH_DASHBOARD', 
  FETCH_DASHBOARD_PENGING: 'FETCH_DASHBOARD', 
  FETCH_DASHBOARD_FULFILLED: 'FETCH_DASHBOARD_FULFILLED', 
  FETCH_DASHBOARD_REJECTED: 'FETCH_DASHBOARD_REJECTED',

  FETCH_DASHBOARD_LAYOUTS: 'FETCH_DASHBOARD_LAYOUTS', 
  FETCH_DASHBOARD_LAYOUTS_PENGING: 'FETCH_DASHBOARD_LAYOUTS', 
  FETCH_DASHBOARD_LAYOUTS_FULFILLED: 'FETCH_DASHBOARD_LAYOUTS_FULFILLED', 
  FETCH_DASHBOARD_LAYOUTS_REJECTED: 'FETCH_DASHBOARD_LAYOUTS_REJECTED',

  SET_SELECTED_USERS: 'SET_SELECTED_USERS',
  SET_SELECTED_TEAMS: 'SET_SELECTED_TEAMS',
  SET_USER_OPTIONS: 'SET_USER_OPTIONS',
  SET_TEAM_OPTIONS: 'SET_TEAM_OPTIONS',
  SET_FILTER_DATE: 'SET_FILTER_DATE',
  SET_DASHBOARD: 'SET_DASHBOARD',
  SET_DASHBOARDS: 'SET_DASHBOARDS',

  RESET_DASHBOARD: 'RESET_DASHBOARD',
};

export const submitDashboardAssign = createAction(actionTypes.SUBMIT_DASHBOARD_ASSIGNMENT, async (dashboard, users, teams) => {
  const getRequest = (key, arr) => ({
    [key]: arr.map(item => item.id),
  });

  await Promise.all([
    ...(users ? [assignToUsersApi(dashboard).create(getRequest('available_to_users', users))] : []),
    ...(teams ? [assignToTeamsApi(dashboard).create(getRequest('available_to_teams', teams))] : []),
  ]);
});

export const updateDashboard = createAction(actionTypes.SUBMIT_LAYOUT_UPDATE, async (id, dashboard, updatePart = false) => {
  const api = dashboardLayoutApi();
  const { data } = await api[updatePart ? 'updatePart' : 'update'](id, dashboard);
  return { response: data };
});

export const deleteDashboard = createAction(actionTypes.SUBMIT_LAYOUT_DELETE, async (dashboard) => {
  const api = dashboardLayoutApi();
  await api.destroy(dashboard.id);
});

export const fetchDashboard = createAction(actionTypes.FETCH_DASHBOARD, async (id) => {
  const api = dashboardLayoutApi();
  const { data } = await api.retrieve(id);
  return data;
});

export const fetchDashboards = createAction(actionTypes.FETCH_DASHBOARD_LAYOUTS, async () => {
  const api = dashboardLayoutsApi();
  const { data: { results } } = await api.list({ limit: 1000, offset: 0 });
  return [defaultDashboard, ...results];
});

export const setSelectedUsers = createAction(actionTypes.SET_SELECTED_USERS, user => user);
export const setSelectedTeams = createAction(actionTypes.SET_SELECTED_TEAMS, team => team);
export const setUserOptions = createAction(actionTypes.SET_USER_OPTIONS, userOptions => userOptions);
export const setTeamOptions = createAction(actionTypes.SET_TEAM_OPTIONS, teamOptions => teamOptions);
export const setDate = createAction(actionTypes.SET_FILTER_DATE, date => date);
export const setDashboard = createAction(actionTypes.SET_DASHBOARD, dashboard => dashboard);
export const setDashboards = createAction(actionTypes.SET_DASHBOARDS, dashboards => dashboards);
export const resetDashboard = createAction(actionTypes.RESET_DASHBOARD);

export default handleActions(
  {
    [actionTypes.SUBMIT_DASHBOARD_ASSIGNMENT_PENDING]: state => ({ ...state, submitAssignInProgress: true }),
    [actionTypes.SUBMIT_DASHBOARD_ASSIGNMENT_FULFILLED]: state => ({ ...state, submitAssignInProgress: false }),
    [actionTypes.SUBMIT_DASHBOARD_ASSIGNMENT_REJECTED]: state => ({ ...state, submitAssignInProgress: false }),
    
    [actionTypes.SET_SELECTED_USERS]: (state, { payload }) => ({ ...state, selectedUsers: [...payload] }),
    [actionTypes.SET_SELECTED_TEAMS]: (state, { payload }) => ({ ...state, selectedTeams: [...payload] }),
    [actionTypes.SET_USER_OPTIONS]: (state, { payload }) => ({ ...state, userOptions: [...payload] }),
    [actionTypes.SET_TEAM_OPTIONS]: (state, { payload }) => ({ ...state, teamOptions: [...payload] }),
    [actionTypes.SET_FILTER_DATE]: (state, { payload }) => ({ ...state, ...payload }),

    [actionTypes.SUBMIT_LAYOUT_DELETE_PENDING]: state => ({ ...state, submitDeleteInProgress: true }),
    [actionTypes.SUBMIT_LAYOUT_DELETE_FULFILLED]: state => ({ ...state, submitDeleteInProgress: false }),
    [actionTypes.SUBMIT_LAYOUT_DELETE_REJECTED]: state => ({ ...state, submitDeleteInProgress: false }),
    
    [actionTypes.SUBMIT_LAYOUT_UPDATE_PENDING]: state => ({ ...state, submitUpdateInProgress: true }),
    [actionTypes.SUBMIT_LAYOUT_UPDATE_FULFILLED]: state => ({ ...state, submitUpdateInProgress: false }),
    [actionTypes.SUBMIT_LAYOUT_UPDATE_REJECTED]: state => ({ ...state, submitUpdateInProgress: false }),
    
    [actionTypes.SET_DASHBOARD]: (state, { payload }) => ({ ...state, dashboard: payload }),
    [actionTypes.RESET_DASHBOARD]: () => ({ ...initialState }),
    
    [actionTypes.FETCH_DASHBOARD_PENDING]: state => ({ ...state, fetchDashboardInProgress: true }),
    [actionTypes.FETCH_DASHBOARD_FULFILLED]: (state, { payload }) => ({ ...state, fetchDashboardInProgress: false, dashboard: payload }),
    [actionTypes.FETCH_DASHBOARD_REJECTED]: state => ({ ...state, fetchDashboardInProgress: false }),
    
    [actionTypes.FETCH_DASHBOARD_LAYOUTS_PENDING]: state => ({ ...state, fetchDashboardsInProgress: true }),
    [actionTypes.FETCH_DASHBOARD_LAYOUTS_FULFILLED]: (state, { payload }) => ({ ...state, fetchDashboardsInProgress: false, dashboards: payload }),
    [actionTypes.FETCH_DASHBOARD_LAYOUTS_REJECTED]: state => ({ ...state, fetchDashboardsInProgress: false }),
  },
  initialState,
);
