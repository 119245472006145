import appRoutes from '../../../../../../../../components/App/Router/appRoutes';
import UpdateWithdrawableAmount from './UpdateWithdrawableAmount';

export default {
  component: UpdateWithdrawableAmount,
  aclId: 'settings.manage_withdrawalable_amount_rules.update',
  path: appRoutes.SETTINGS_WITHDRAWABLE_AMOUNTS_EDIT,
};

export const UpdateWithdrawableAmountCreate = {
  component: UpdateWithdrawableAmount,
  aclId: 'settings.manage_withdrawalable_amount_rules.create',
  path: appRoutes.SETTINGS_WITHDRAWABLE_AMOUNTS_CREATE,
};
