import React, { Component } from 'react';
import { bool, func, object, arrayOf } from 'prop-types';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';

import SidepanelFooter from '../SidepanelFooter';
import FormFactory, { FormActionsFactory } from '../../../../../../components/form';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../logic/connect';
import withUnmountMethod from '../../../../../../components/hoc/withUnmountMethod';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';

const EDIT_RISK_GROUP_PER_GATEWAY = 'EDIT_RISK_GROUP_PER_GATEWAY';
const sidepanelID = EDIT_RISK_GROUP_PER_GATEWAY;
const formID = EDIT_RISK_GROUP_PER_GATEWAY;
const api = getDjangoApi('settings/risk_groups');

const formConfig = {
  form: formID,
  api,
};

const { 
  update,
  create,
  setRowData,
  resetFields,
  setFields,
  setFieldValue, 
} = FormActionsFactory(formID);

const propTypes = {
  visible: bool.isRequired,
  closeSidePanel: func.isRequired,
  parameters: object,
  submitInProgess: bool,
  actions: object.isRequired,
  values: object.isRequired,
  gatewayChoices: arrayOf(object).isRequired,
  fields: arrayOf(object),
  fetchTableData: func.isRequired,
  dispatch: func.isRequired,
};

const defaultProps = {
  parameters: null,
  submitInProgess: false,
  fields: [],
};

const text = {
  discard: gettext('Discard'),
  save: gettext('Save changes'),
  title: gettext('Risk per gateway'),
  editSuccessMsg: gettext('Risk group edited successfully'),
  createSuccessMsg: gettext('Risk group created successfully'),
};

const modifiers = customOnChange => ({
  gateways: {
    type: 'multiselect',
    multi: true,
    customOnChange,
    id: 'gateways',
    valueKey: 'value',
    labelKey: 'display_name',
  },
});

class EditOnfidoRiskSidepanel extends Component {
  constructor(props) {
    super(props);

    this.modifiers = modifiers(this.customOnChange);
    this.FormComponent = FormFactory(formConfig);
  }

  componentDidUpdate() {
    const { fields, parameters, values } = this.props;
    if (!isEmpty(parameters) && !isEmpty(fields) && isEmpty(values)) {
      this.initFields();
    }
  }

  onSave = async () => {
    const { 
      fetchTableData,
      closeSidePanel,
      values,
      parameters: { id }, 
      dispatch,
      actions: { update } } = this.props;
    
    const promise = update(api, id, () => ({
      ...values,
      gateways: values.gateways.map(({ value }) => value),
    }));

    const [error] = await to(promise);

    if (!error) {
      notifier.success(text.editSuccessMsg);
      closeSidePanel(sidepanelID);
      dispatch(fetchTableData());
    }
  }

  onCreate = async () => {
    const { 
      fetchTableData,
      closeSidePanel,
      values, 
      dispatch,
      actions: { create } } = this.props;

    const promise = create(api, () => ({
      ...values,
      gateways: (values.gateways || []).map(({ value }) => value),
    }));

    const [error] = await to(promise);

    if (!error) {
      notifier.success(text.createSuccessMsg);
      closeSidePanel(sidepanelID);
      dispatch(fetchTableData());
    }
  }

  initFields = () => {
    const { gatewayChoices, parameters, actions: { setFieldValue } } = this.props;
    const { barrier_value, name, risk_value, gateways, min_value, max_value } = parameters;
    setFieldValue({ id: 'name', value: name });
    setFieldValue({ id: 'barrier_value', value: barrier_value });
    setFieldValue({ id: 'risk_value', value: risk_value });
    setFieldValue({ id: 'min_value', value: min_value });
    setFieldValue({ id: 'max_value', value: max_value });
    setFieldValue({ id: 'gateways',
      value: gateways
        .map(gatewayValue => gatewayChoices
          .find(gc => gc.value === gatewayValue)) });
  }

  customOnChange = (gatewayValue) => {
    const { actions } = this.props;
    actions.setFieldValue(gatewayValue);
  }

  closeSidePanel = () => {
    const { closeSidePanel } = this.props;
    closeSidePanel(sidepanelID);
  }

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    } 
    const { FormComponent } = this;
   
    return (
      <FormComponent modifiers={this.modifiers} />
    );
  }
 
  render() {
    const { 
      visible,
      parameters,
      submitInProgess,
    } = this.props;
    const { name } = parameters || {};
    const title = parameters ? `${text.title} (${name})` : text.title; 
    
    return ( 
      <Sidepanel 
        title={title}
        icon="Edit"
        visible={visible}
        onCloseIconClick={this.closeSidePanel}
        footerRender={() => (
          <SidepanelFooter 
            onCancel={this.closeSidePanel}
            onSave={parameters ? this.onSave : this.onCreate}
            submitInProgess={submitInProgess}
          />
        )}
      >
        { this.renderForm() }
      </Sidepanel>
    );
  }
}

EditOnfidoRiskSidepanel.propTypes = propTypes;
EditOnfidoRiskSidepanel.defaultProps = defaultProps;

const mapStateToProps = ({ overlays, forms }) => ({
  ...overlays[sidepanelID],
  ...forms[formID],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    update,
    create,
    setRowData,
    resetFields,
    setFieldValue,
    setFields,
  }, dispatch),
  dispatch,
});

export { sidepanelID, api };
export default withUnmountMethod(
  connect(mapStateToProps, mapDispatchToProps)(EditOnfidoRiskSidepanel),
);
