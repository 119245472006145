import React from 'react';
import { Toggle, Pill, Tooltip, Icon } from 'tc-biq-design-system';
import { func, bool, number } from 'prop-types';
import { UsernameCell, ActionsCellWrapper } from '../../../../../components/gridCellRenderers';
import { hasAccess } from '../../../../../logic/services/acl';
import { gettext } from '../../../../../logic/utilities/languageUtility';

const noop = () => {};

const text = {
  YES: gettext('Yes'),
  NO: gettext('No'),
  EDIT_TITLE: gettext('Edit'),
};

const isEnabledRenderer = ({ is_active, id, toggleIsActive }) => { 
  if (hasAccess('document_rejection_reasons.update')) {
    return <Toggle checked={is_active} onChange={() => { toggleIsActive({ is_active, id }); }} />;
  } 
  return (<Pill type={is_active ? 'status01' : 'status04'}> { is_active ? text.YES : text.NO } </Pill>
  );
};

isEnabledRenderer.propTypes = {
  is_active: bool.isRequired,
  id: number.isRequired,
  toggleIsActive: func.isRequired,
};

const actionIcons = {
  editReason: (actionHandler, { id }) => (
    <Tooltip text={text.EDIT_TITLE}>
      <Icon onClick={() => { actionHandler(id); }} name="Edit" />
    </Tooltip>
  ),
};

const modifier = ({ toggleIsActive, editReason }) => ({
  is_active: {
    cellRendererFramework: ({ data }) => isEnabledRenderer({
      is_active: data.is_active,
      id: data.id,
      toggleIsActive,  
    }),
  },
  created_by: { 
    cellRendererFramework: UsernameCell(noop, { isStaff: true }),
  },
  modified_by: { 
    cellRendererFramework: UsernameCell(noop, { isStaff: true }),
  },
  actions: {
    key: 'actions',
    width: 100,
    pinned: 'right',
    hiddenSortIcon: true,
    cellRendererFramework: ActionsCellWrapper({ editReason }, actionIcons),
  },
});

export default modifier;
