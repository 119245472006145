import { object, string } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';

import { BiqUserPreview, UserPreview } from '../../../../../../components/common/sidepanels';
import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import overlayActions from '../../../../../../components/overlay';
import connect from '../../../../../../logic/connect';
import hideCols from '../../../../../../logic/utilities/hideCols';
import modifier from '../../../../logs/CommunicationHistory/modifier';

const tableModifier = (...args) => {
  const { to_user, ...rest } = modifier(...args);
  return {
    ...hideCols(['to_user']),
    ...rest,
  };
};

const tableConfig = id => ({
  apiUrl: `ib/${id}/messages/`,
  reducerKey: 'USER_SINGLE',
  tableKey: 'IB_COMMUNICATION',
});

const propTypes = {
  actions: object.isRequired,
  id: string.isRequired,
};

class Communication extends Component {
  constructor(props) {
    super(props);

    this.tableActions = {
      previewUser: props.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
      previewBiqUser: props.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };

    this.Table = withTable(GridTab, tableConfig, null, { hideSegments: true })(props.id);
  }

  render() {
    const { Table } = this;
    return (
      <Fragment>
        <Table modifier={tableModifier(this.tableActions)} />
        <UserPreview />
        <BiqUserPreview />
      </Fragment>
    );
  }
}

Communication.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(Communication);
