import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { oneOfType, array, object } from 'prop-types';

import bulkActionsActionFactory from '../../../../../../../components/common/sidepanels/bulk/actions';
import connect from '../../../../../../../logic/connect';
import { updateUser } from '../../../Model';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import SelectSalesStatus from '../SelectStatus';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';

const apiSalesSubStatuses = getDjangoApi('autocomplete/sales_substatus');
const apiSalesSubStatusSubmit = user => getDjangoApi(`users/${user.id}/sales_status`);

const text = {
  SALES_STATUS: gettext('Sales substatus'),
  SUCCESS_MESSAGE: gettext('Sales substatus successfully updated'),
  PERMISSION_MESSAGE: gettext('You don\'t have permission to delete status.'),
};

const { 
  updateData,
  resetSubmit, 
  resetInput,
  submit,
} = bulkActionsActionFactory('CLIENT_EDIT_SALES_SUBSTATUSES');

const propTypes = {
  salesStatus: oneOfType([array, object]),
};

const defaultProps = {
  salesStatus: null,
};

class SalesSubStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
    };
  }

  componentDidUpdate = (prevProps) => {
    const { salesStatus } = this.props;
    if (prevProps.salesStatus !== salesStatus) {
      this.loadOptions().then((options) => {
        this.setState({ options });
      });
    }
  }

  submitRequest = (substatus) => {
    const { salesStatus } = this.props;
    return {
      sales_status: salesStatus && salesStatus.id,
      sales_substatus: substatus && substatus.id,
    };
  }

  loadOptions() {
    const { salesStatus } = this.props;
    const defaultRequest = { is_enabled: true };
    const request = salesStatus ? { ...defaultRequest, parent: salesStatus.id } : defaultRequest;
    return new Promise(resolve => apiSalesSubStatuses
      .list(request)
      .then(res => resolve(res.data.results)),
    );
  }

  render() {
    const { options } = this.state;
    return (
      <SelectSalesStatus
        async={false}
        {...this.props} 
        options={options}
        trans={text}
        salesKey="sales_substatus"
        submitRequest={this.submitRequest}
        apiSalesSubmit={apiSalesSubStatusSubmit}
        apiSales={apiSalesSubStatuses}
      />
    );
  }
}

SalesSubStatus.propTypes = propTypes;
SalesSubStatus.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateData,
      resetSubmit,
      resetInput,
      submit,
      updateUser,
    },
    dispatch,
  ),
});
  
const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const editSalesSubStatuses = page.actions.EDIT_SALES_SUBSTATUSES;
  const editSalesStatuses = page.actions.EDIT_SALES_STATUSES;
  const { user } = page.userDetail;
  return {
    ...editSalesSubStatuses,
    salesStatus: editSalesStatuses.fieldData,
    user,
  };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SalesSubStatus);
