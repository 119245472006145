import { TagsCell, UsernameCell } from '../../../../components/gridCellRenderers';

export default ({ previewUser, previewBiqUser }) => ({
  username: {
    cellRendererFramework: UsernameCell(previewUser, { isIb: true }),
  },
  tags: {
    cellRendererFramework: TagsCell,
  },
  assigned_to: {
    cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
  },
});
