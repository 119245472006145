import React, { Component } from 'react';
import { object } from 'prop-types';
import { Pill } from 'tc-biq-design-system';

import connect from '../../../../../../logic/connect';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import MarketingInfo from '../Marketing/MarketingInfo';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';

const { config } = window;
const { environment } = config;

const text = {
  INVITED_BY: gettext('Invited by'),
  INVITED_BY_LINK: gettext('Invited by link'),
  COMPANY: gettext('Company'),
  WHITELABEL: gettext('Whitelabel'),
};

const propTypes = {
  user: object.isRequired,
};

const invitedByLink = invited_by_link => (
  <span className="text-primary-400">
    <a href={invited_by_link.register_url} target="_blank" rel="noopener noreferrer">{invited_by_link.register_url}</a>
    <span>
      [owner:
      <a href={`settings/team/${invited_by_link.owner.id}`} target="_blank" rel="noopener noreferrer">{invited_by_link.owner.username}</a>
      ]
    </span>
  </span>
);

class IbMarketing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    this.fetchMarketingData();
  }

  fetchMarketingData = () => {
    const { user: { id } } = this.props;

    getDjangoApi(`ib/${id}/marketing_data`).retrieve()
      .then(({ data }) => this.setState({ data }));
  }

  render() {
    const { data: { invited_by, invited_by_link } } = this.state;
    const { user: { whitelabel } } = this.props;
    return (
      <div className="biq-profile__wrapper biq-marketing">
        {invited_by && (
        <MarketingInfo 
          label={text.INVITED_BY} 
          value={invited_by && invited_by[0].username}
          link={invited_by && `ib-system/ib-user/${invited_by[0].id}`}
        />
        )}
        {invited_by_link && (
        <MarketingInfo 
          label={text.INVITED_BY_LINK} 
          value={invitedByLink(invited_by_link)} 
        />
        )}
        {isFeatureEnabled()('WHITELABEL') && whitelabel && (
        <MarketingInfo 
          label={environment === 'ICMCapital' ? text.COMPANY : text.WHITELABEL}
          value={<Pill type="status01">{whitelabel}</Pill>}
        />
        )}
      </div>
    );
  }
}

IbMarketing.propTypes = propTypes;

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  return {
    user: page.userDetail.user,
  };
};

export default connect(mapStateToProps)(IbMarketing);
