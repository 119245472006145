import { handleActions } from 'redux-actions';

import actionTypeFactory from '../actions/actionTypeFactory';

const initialState = {
  fieldData: [],
  options: [],
  submitInProgress: false,
  loadInProgress: false,
  errors: null,
  submitSuccess: false,
};


const bulkActionsReducerFactory = (action) => {
  const {
    UPDATE_DATA,
    RESET_FIELDS,
    SHOULD_REMOVE_TAGS,
    SUBMIT_PENDING,
    SUBMIT_FULFILLED,
    SUBMIT_REJECTED,
    RESET_SUBMIT,
    LOAD_OPTIONS_FULFILLED,
  } = actionTypeFactory(action);
  
  return handleActions(
    {
      [UPDATE_DATA]: (state, { payload }) => ({
        ...state,
        fieldData: payload,
      }),
      [RESET_FIELDS]: () => ({ ...initialState }),
      [SHOULD_REMOVE_TAGS]: state => ({
        ...state,
        removeTags: !state.removeTags,
      }),
      [SUBMIT_PENDING]: state => ({
        ...state,
        submitInProgress: true,
      }),
      [SUBMIT_FULFILLED]: state => ({
        ...state,
        submitInProgress: false,
        submitSuccess: true,
      }),
      [SUBMIT_REJECTED]: (state, { payload }) => ({
        ...state,
        errors: payload.data,
        submitInProgress: false,
      }),
      [RESET_SUBMIT]: state => ({
        ...state,
        submitSuccess: false,
        errors: null,
      }),
      [LOAD_OPTIONS_FULFILLED]: (state, { payload }) => ({
        ...state,
        options: payload,
      }),
    },
    initialState,
  );
};


export default bulkActionsReducerFactory;
