import { ActionsCellWrapper, ToggleCell, UsernameCell, RouteCell } from '../../../../components/gridCellRenderers';
import { actionIcons } from '../../../../components/grid/GridUtils';
import { gettext } from '../../../../logic/utilities/languageUtility';
import hideCols from '../../../../logic/utilities/hideCols';
import CurrencyCell from '../../../../components/gridCellRenderers/CurrencyCell';
import appRoutes from '../../../../components/App/Router/appRoutes';

const LinkToCampaign = () => ({ value, data, colDef }) => {
  const stateParams = { id: data.id };
  const Component = RouteCell({ href: appRoutes.CAMPAIGN, stateParams });
  return Component({ value, data, colDef });
};

export default ({ modify, toggle, previewBiqUser }) => {
  const actions = {
    ...(modify && { modify }),
  };
  return {
    ...hideCols(['id']),
    name: {
      cellRendererFramework: LinkToCampaign(),
    },
    active: {
      width: 80,
      pinned: 'left',
      headerName: gettext('State'),
      cellRendererFramework: cell => ToggleCell(toggle)(cell),
    },
    currency: {
      cellRendererFramework: CurrencyCell,
    },
    user: {
      cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }),
    },
    actions: {
      label: '',
      field: 'actions',
      key: 'actions',
      pinned: 'right',
      width: actions.modify ? 100 : 50,
      hiddenSortIcon: true,
      suppressSizeToFit: true,
      cellRendererFramework: cell => ActionsCellWrapper(actions, actionIcons(modify))(cell),
    },
  };
};
