import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  UNARCHIVE_USER: 'UNARCHIVE_USER',
  UNARCHIVE_USER_FULFILLED: 'UNARCHIVE_USER_FULFILLED',
  UNARCHIVE_USER_PENDING: 'UNARCHIVE_USER_PENDING',
  UNARCHIVE_USER_REJECTED: 'UNARCHIVE_USER_REJECTED',
};

export const unarchiveUser = createAction(actionTypes.UNARCHIVE_USER, async (user, isIb) => {
  const archiveApi = getDjangoApi(`${isIb ? 'ib' : 'users'}/${user.id}/unarchive`);
  
  await archiveApi.create({});
});


const initialState = {
  submitInProgress: false,
  errors: null,
};

const pendingHandler = state => ({ ...state, submitInProgress: true });

const fulfilledHandler = state => ({
  ...state,
  submitInProgress: false,
  error: null,
});

const rejectedHandler = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  errors: payload.data,
});

export default handleActions(
  {
    [actionTypes.UNARCHIVE_USER_PENDING]: pendingHandler,
    [actionTypes.UNARCHIVE_USER_FULFILLED]: fulfilledHandler,
    [actionTypes.UNARCHIVE_USER_REJECTED]: rejectedHandler,
  },
  initialState,
);
