import { withRouter } from 'react-router-dom';

import MainLayout from './MainLayout';
import connect from '../../logic/connect';

const mapStateToProps = ({ session }) => ({ 
  session,
});

export default connect(mapStateToProps)(withRouter(MainLayout));
