import { bindActionCreators } from 'redux';

import EmailNotifications, { FORM_ID } from './EmailNotifications';
import { FormActionsFactory } from '../../../../../components/form';
import connect from '../../../../../logic/connect';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import { loadTeamMember } from '../Model';

const { errorFields, create, update, resetFields, setRowData, setFieldValue } = FormActionsFactory(FORM_ID);

const mapStateToProps = ({ forms }) => {
  const { fields, submitInProgress, values } = forms[FORM_ID];
  return { fields, values, submitInProgress };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      errorFields,
      create,
      resetFields,
      setRowData,
      setFieldValue,
      update,
      loadTeamMember,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(EmailNotifications));
