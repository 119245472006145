import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { object, string, bool, number, oneOfType } from 'prop-types';
import { PieChart, InfoBox, Spinner, Card, Row, Col, Tooltip, HyperLink } from 'tc-biq-design-system';

import connect from '../../../../../../logic/connect';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { currencyFormatter, dateTimeFormatter } from '../../../../../../logic/utilities/formatters';
import { activate } from './Model';
import './TradingStats.scss';
import If from '../../../../../../components/If';

const propTypes = {
  id: string.isRequired,
  fetchInProgress: bool.isRequired,
  data: object.isRequired,
  user: object.isRequired,
  actions: object.isRequired,
  currency: string.isRequired,
};

const text = {
  INFO: gettext('There is no trading stats data for this user.'),
  PROFIT: gettext('Profit'),
  WIN_PROFIT: gettext('Win profit'),
  LOSS_PROFIT: gettext('Loss profit'),
  CLOSED_POSITIONS: gettext('Closed positions'),
  OPEN_POSITIONS: gettext('Open positions'),
  TOTAL_TRADES: gettext('Total trades'),
  TRADES_PER_DAY: gettext('Avg. trades per day'),
  TRADES_PER_WEEK: gettext('Avg. trades per week'),
  AVG_LOSS: gettext('Avg. Loss'),
  AVG_WIN: gettext('Avg. Win'),
  LONG_WON: gettext('Long won'),
  SHORT_WON: gettext('Short won'),
  LONG_WON_VS_SHORT_WON: gettext('Long w. vs. Short w.'),
  LONG_VS_SHORT: gettext('Long vs. Short'),
  WIN_RATIO: gettext('Win Ratio'),
  WIN: gettext('Win'),
  LOSS: gettext('Loss'),
  EVEN: gettext('Even'),
  JOINED: gettext('Joined'),
  FIRST_TRADED: gettext('First Traded'),
  LAST_TRADED: gettext('Last Traded'),
  LAST_ONLINE: gettext('Last Online'),
  USERNAME: gettext('Username'),
  COMPLETED_USER_TRADES: gettext('Completed user trades'),
  CLICK_TO_OPEN: gettext('Click here to open'),
  NA: gettext('N/A'),
};

const CARD_WIDTH_FULL = '100%';
const CARD_WIDTH_25 = '25%';
const CARD_WIDTH_50 = '50%';

const cardStyle = {
  header: {
    backgroundColor: '#F7FAFC',
  },
  content: {
    padding: '14px 30px',
    backgroundColor: '#FFF',
    textAlign: 'center',
  },
};

const chartColors = {
  PRIMARY_ONE: '#006BE6',
  PRIMARY_TWO: '#E3F0FF',
  PRIMARY_THREE: '#E3F0FF',
};

const formatDate = (value) => {
  if (Number.isNaN(Date.parse(value))) return text.NA;
  return (
    <Tooltip
      title={dateTimeFormatter(value, true, true)}
      placement="top"
    >
      <span>{dateTimeFormatter(value, false)}</span>
    </Tooltip>
  );
};

const StatsCard = ({ title, value }) => (
  <Col xs={CARD_WIDTH_FULL} sm={CARD_WIDTH_FULL} md={CARD_WIDTH_FULL} lg={CARD_WIDTH_25}>
    <Card style={cardStyle} title={title}>
      <span className="tc-heading-l text-neutral-900">{value}</span>
    </Card>
  </Col>
);

const DateStatsCard = ({ title, value }) => (
  <StatsCard title={title} value={formatDate(value)} />
);

const LinkCard = ({ title, value, href }) => (
  <StatsCard
    title={title}
    value={(
      <HyperLink>
        <a href={href} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      </HyperLink>
)}
  />
);

const PieChartCard = ({ data, title }) => (
  <Col xs={CARD_WIDTH_FULL} sm={CARD_WIDTH_FULL} md={CARD_WIDTH_FULL} lg={CARD_WIDTH_50}>
    <Card style={cardStyle} title={title}>
      <PieChart
        data={data}
        width={100}
        height={150}
        innerRadius={30}
        outerRadius={40}
        withLegend
      />
    </Card>
  </Col>
);

StatsCard.propTypes = {
  title: string.isRequired,
  value: oneOfType([string, number]).isRequired,
};

DateStatsCard.propTypes = {
  title: string.isRequired,
  value: string.isRequired,
};

LinkCard.propTypes = {
  href: number.isRequired,
  title: string.isRequired,
  value: string.isRequired,
};

PieChartCard.propTypes = {
  data: object.isRequired,
  title: string.isRequired,
};

class TradingStats extends Component {
  componentDidMount() {
    const { actions, id } = this.props;
    actions.activate(id);
  }

  render() {
    const { fetchInProgress, data, currency, user } = this.props;

    const {
      pnl,
      pnl_win,
      pnl_loss,
      closed_positions,
      open_positions,
      ssv,
      trades,
      trades_per_day,
      trades_per_week,
      avg_pnl_loss,
      avg_pnl_win,
      positions_long_win_percent,
      positions_short_win_percent,
      positions_long_percent,
      positions_short_percent,
      positions_even_percent,
      positions_win_percent,
      positions_loss_percent,
      date_joined,
      first_trade_date,
      last_online,
      last_trade_date,
    } = data;

    const longVsShortWon = [
      { key: text.LONG_WON, value: positions_long_win_percent, color: chartColors.PRIMARY_ONE, formatValue: value => `${value}%` },
      { key: text.SHORT_WON, value: positions_short_win_percent, color: chartColors.PRIMARY_TWO, formatValue: value => `${value}%` },
    ];

    const longVsShort = [
      { key: text.LONG_WON, value: positions_long_percent, color: chartColors.PRIMARY_ONE, formatValue: value => `${value}%` },
      { key: text.SHORT_WON, value: positions_short_percent, color: chartColors.PRIMARY_TWO, formatValue: value => `${value}%` },
    ];

    const winRatio = [
      { key: text.WIN, value: positions_win_percent, color: chartColors.PRIMARY_ONE, formatValue: value => `${value}%` },
      { key: text.LOSS, value: positions_loss_percent, color: chartColors.PRIMARY_TWO, formatValue: value => `${value}%` },
      { key: text.EVEN, value: positions_even_percent, color: chartColors.PRIMARY_THREE, formatValue: value => `${value}%` },
    ];

    return (
      <div className="biq-trading-stats">
        <If condition={fetchInProgress}>
          <div className="loader">
            <Spinner size="large" />
          </div>
        </If>
        <If condition={!fetchInProgress && _.isEmpty(data)}>
          <InfoBox>{text.INFO}</InfoBox>
        </If>
        <If condition={!fetchInProgress && !_.isEmpty(data)}>
          <Row>
            <StatsCard title={text.PROFIT} value={currencyFormatter(pnl, currency)} />
            <StatsCard title={text.WIN_PROFIT} value={currencyFormatter(pnl_win, currency)} />
            <StatsCard title={text.LOSS_PROFIT} value={currencyFormatter(pnl_loss, currency)} />
            <StatsCard title={text.CLOSED_POSITIONS} value={closed_positions} />
            <StatsCard title={text.OPEN_POSITIONS} value={open_positions} />
            <StatsCard title={text.TRADES_PER_WEEK} value={trades_per_week} />
            <StatsCard title="SSV" value={currencyFormatter(ssv, currency)} />
            <StatsCard title={text.TOTAL_TRADES} value={trades} />
            <StatsCard title={text.TRADES_PER_DAY} value={trades_per_day} />
            <StatsCard title={text.AVG_LOSS} value={currencyFormatter(avg_pnl_loss, currency)} />
            <StatsCard title={text.AVG_WIN} value={currencyFormatter(avg_pnl_win, currency)} />
            <DateStatsCard title={text.JOINED} value={date_joined} />
            <DateStatsCard title={text.FIRST_TRADED} value={first_trade_date} />
            <DateStatsCard title={text.LAST_TRADED} value={last_trade_date} />
            <DateStatsCard title={text.LAST_ONLINE} value={last_online} />
            <LinkCard title={text.COMPLETED_USER_TRADES} value={text.CLICK_TO_OPEN} href={`trade/closed-pos/${user.id}`} />
          </Row>

          <Row>
            <If condition={(positions_long_win_percent > 0 || positions_short_win_percent > 0)}>
              <PieChartCard data={longVsShortWon} title={text.LONG_WON_VS_SHORT_WON} />
            </If>
            <If condition={(positions_long_percent > 0 || positions_short_percent > 0)}>
              <PieChartCard data={longVsShort} title={text.LONG_VS_SHORT} />
            </If>
            <If condition={(positions_even_percent > 0 || positions_win_percent > 0 || positions_loss_percent > 0)}>
              <PieChartCard data={winRatio} title={text.WIN_RATIO} />
            </If>
          </Row>
        </If>
      </div>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const tradingStats = page.tradingStats;
  const currency = page.userDetail.user.currency.symbol;

  return { ...tradingStats, currency, user: page.userDetail.user };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ activate }, dispatch),
});

TradingStats.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(TradingStats);
