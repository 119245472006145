/* eslint react/prop-types:0 */
import React, { Fragment } from 'react';

import { getActiveFilters } from '../GridUtils';
import Filters from '../../Filters';

const GridTab = (props) => {
  const { GridComponent, fields, actions, table,
    modifier, hideFilters, filterOptions = {}, hideSegments } = props;
  const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
  const filters = getActiveFilters(table);
  const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };

  const filterProps = {
    view: table.options.view,
    segments,
    fields,
    columnsState,
    initialFilters: filters,
    onFilterChange: actions.updateFilter,
    onChangeSegment: actions.changeSegment,
    refreshOptions: actions.fetchOptions,
    tableModifier: modifier,
    hideSegments,
    ...filterOptions,
  };

  return (
    <Fragment>
      {!hideFilters && <Filters {...filterProps} />}
      <GridComponent {...props} hideSegments={hideSegments} tableModifier={modifier || {}} />
    </Fragment>
  );
};

export default GridTab;
