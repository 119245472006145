import React, { useState, useMemo } from 'react';
import { Accordion, Code } from 'tc-biq-design-system';
import moment from 'moment';
import { array } from 'prop-types';
import { gettext } from '../../../../../logic/utilities/languageUtility';

import './ExtraData.scss';

const defaultProps = {
  logs: [],
};

const propTypes = {
  logs: array,
};

const text = {
  EXTRA_DATA: gettext('Extra data'),
};

const ExtraData = ({ logs }) => {
  const [visible, toggleVisible] = useState(false);
  const logsData = useMemo(() => {
    const data = [];
    logs.forEach((log) => {
      if (log.request) data.push({ ...log, data: JSON.parse(log.request), isResponse: false });
      if (log.response) data.push({ ...log, data: JSON.parse(log.response), isResponse: true });
    });
    return data.filter(log => log && log.data).sort((a, b) => (
      // eslint-disable-next-line no-nested-ternary
      a.created < b.created ? -1 : 1),
    );
  }, [logs]);

  if (!logs.length) return null;

  return (
    <Accordion title={text.EXTRA_DATA} visible={visible} onClick={() => toggleVisible(!visible)}>
      {logsData.map((log) => {
        const [isVisible, setIsVisible] = useState(false);

        return (
          <div key={`${log.isResponse ? 'res' : 'req'}_${log.id}`} className="biq-log">
            <span 
              className="biq-log__time tc-paragraph-regular text-neutral-600"
              onClick={() => setIsVisible(!isVisible)}
              style={{ cursor: 'pointer' }}
            >
              {moment(log.created).utc().format('YYYY-MM-DD HH:mm:ss')}
            </span>
            <div className="biq-log__code">
              {isVisible && <Code data={log.data} />}
              {!isVisible && <div style={{ cursor: 'pointer' }} onClick={() => setIsVisible(true)}><pre className="biq-code">{'{...}'}</pre></div>}
            </div>
          </div>
        );
      })}
    </Accordion>
  );
};

ExtraData.defaultProps = defaultProps;
ExtraData.propTypes = propTypes;

export default ExtraData;
