
import { handleActions } from 'redux-actions';

import { SUBMIT_PENDING, SUBMIT_FULFILLED, SUBMIT_REJECTED, RESET_STATE } from './actionTypes';

const initialState = {
  submitInProgress: false,
  submitSuccess: false,
  errors: null,
};

const submitPending = state => ({
  ...state,
  submitInProgress: true,
});

const submitFulfilled = state => ({
  ...state,
  submitInProgress: false,
  submitSuccess: true,
});

const submitRejected = (state, { payload }) => ({
  ...state,
  errors: payload.data,
  submitInProgress: false,
});

export default handleActions({
  [SUBMIT_PENDING]: state => submitPending(state),
  [SUBMIT_FULFILLED]: state => submitFulfilled(state),
  [SUBMIT_REJECTED]: (state, action) => submitRejected(state, action),
  [RESET_STATE]: () => ({ ...initialState }),
}, initialState);
