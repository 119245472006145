import React, { Component } from 'react';
import {
  Card,
  Row, 
  Col,
  Table,
  Input,
  Tooltip,
  Space } from 'tc-biq-design-system';
import { isEmpty } from 'lodash';
import { 
  arrayOf,
  object,
  shape,
  string,
  number,
  func } from 'prop-types';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

import './MainFormula.scss';

const spaceSize = 4;
const inputType = 'number';

const text = {
  RISK_RATING: gettext('Risk rating'),
  COUNTRY_VALUE: gettext('Country value'),
  TOTAL_DEPOSITS_VALUE: gettext('Total deposits value'),
  RISC_GROUP_PER_GATEWAY: gettext('Risk groups per gateway'),
  SCREENING: gettext('Screening'),
  USER_PERMANENT_LINK: gettext('User parameter risk'),
};

const riskLabelRenderer = ({ riskLabel: { label, id } }) => (
  <div className="risk-label">
    <div className={`color-${id}`} />
    <Space size={4 * spaceSize} />
    <div> { label } </div>
  </div>
);

riskLabelRenderer.propTypes = { 
  riskLabel: shape({ label: string, id: number }).isRequired,
};

const ratingNumberRenderer = ({ ratingNumber, id }) => {
  const { 
    risk_value_max,
    risk_value_min,
    onChangeFormula, 
  } = ratingNumber;
  return (
    <div className="risk-rating-number">
      <Input
        onChange={({ target: { value } }) => {
          onChangeFormula({ risk_value_min: value, risk_value_max }, id);
        }}
        value={risk_value_min}
        type={inputType}
      />
      <Space size={spaceSize} />
      <div className="risk-rating-number__delimiter" />
      <Space size={spaceSize} />
      <Input
        onChange={({ target: { value } }) => {
          onChangeFormula({ risk_value_max: value, risk_value_min }, id);
        }}
        value={risk_value_max}
        type={inputType}
      />
    </div>
  );
};

ratingNumberRenderer.propTypes = {
  ratingNumber: shape({
    risk_value_max: number,
    risk_value_min: number,
    onChangeFormula: func, 
  }).isRequired,
  id: number.isRequired,
};

const tableColumns = [
  { title: gettext('RISK LABEL'),
    key: 'riskLabel',
    width: '65%',
    render: riskLabelRenderer,
  },
  { title: gettext('RISK RATING NUMBER'),
    key: 'ratingNumber', 
    width: '35%',
    render: ratingNumberRenderer,
  },
];

const RiskRatingNumberRatioIndicator = ({ riskLabels }) => {
  const data = riskLabels.reduce((acc, riskLabel) => {
    const { label, id } = riskLabel;
    const { risk_value_min, risk_value_max } = riskLabel;
    const { risk_value_min: highMinValue } = riskLabels[riskLabels.length - 1];
    const percents = id === riskLabels.length
      ? 100 - acc.reduce((sum, v) => sum + v.percents, 0)
      // eslint-disable-next-line no-mixed-operators
      : (risk_value_max - risk_value_min) / highMinValue * 100;
    return [...acc, { 
      percents,
      label,
      id, 
    }];
  }, []);

  return (
    <div className="risk-ratio">
      {
      data.map(({ label, id, percents }) => (
        <div key={id} style={{ width: `${percents}%` }}>
          <Tooltip title={label}>
            <div className={`risk-ratio__indicator color-${id}`} />
          </Tooltip>
        </div>
      ),   
      )
    }
    </div>
  );
};

RiskRatingNumberRatioIndicator.propTypes = {
  riskLabels: arrayOf(object).isRequired,
};

const FormulasText = () => (
  <div>
    <strong>{text.RISK_RATING} </strong>
    <span>
      = {text.COUNTRY_VALUE} + {text.TOTAL_DEPOSITS_VALUE} +
      {text.RISC_GROUP_PER_GATEWAY} + {text.SCREENING} + {text.USER_PERMANENT_LINK}
    </span>
  </div>
);

class MainFormula extends Component {
  componentWillUnmount() {
    const { resetEditedLabels } = this.props;
    resetEditedLabels();
  }

  render() {
    const { 
      mergedRiskLabels,
      onChangeFormula,
      riskLabels, 
    } = this.props;

    const data = mergedRiskLabels
      .map(v => ({
        id: v.id,
        riskLabel: { label: v.label, id: v.id },
        ratingNumber: 
        { 
          ...v,
          onChangeFormula,
        },
      }));
  
    return (
      !isEmpty(data) && (
      <React.Fragment>
        <Space size={16} />
        <Row className="risk-labels">
          <Col
            lg="768px"
            className="risk-labels__col"
          >
            <Card>
              <FormulasText />
              <Table cols={tableColumns} data={data} />
              <Space size={32} />
              <RiskRatingNumberRatioIndicator riskLabels={riskLabels} />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
      )
    );
  }
}

MainFormula.propTypes = {
  riskLabels: arrayOf(object).isRequired,
  onChangeFormula: func.isRequired,
  mergedRiskLabels: arrayOf(object).isRequired,
  resetEditedLabels: func.isRequired,
};

export default MainFormula;
