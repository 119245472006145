/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Sidepanel, notifier } from 'tc-biq-design-system';

import { getDjangoApi } from '../../../../../../../../../logic/services/api-factory';
import FormFactory, { FormActionsFactory } from '../../../../../../../../../components/form';
import { gettext } from '../../../../../../../../../logic/utilities/languageUtility';
import { SidepanelFooter } from '../../../../../../../../../components/common';
import to from '../../../../../../../../../logic/utilities/to';
import overlayActions from '../../../../../../../../../components/overlay';
import connect from '../../../../../../../../../logic/connect';


const SIDEPANEL_ID = 'AVA_INTEGRATION_FORM';
const api = getDjangoApi('integration/ava_partners/tags');
const { create, update, setRowData } = FormActionsFactory(SIDEPANEL_ID);

const text = {
  TITLE_EDIT: gettext('Edit tag'),
  EDIT_BUTTON_LABEL: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
  ERROR_EDIT: gettext('Edit tag description failed'),
  SUCCESS_EDIT: gettext('Tag description edited successfully'),
};

const formConfig = {
  form: SIDEPANEL_ID,
  api,
  excludeFields: [
    'tag_id',
    'affiliate_description',
    'publisher_name',
    'sales_view',
    'retention_view',
    'auto_dialer',
    'allowed_bonus',
    'channel',
    'group_type',
  ],
};

const customFooter = (onClose, onConfirm, submitInProgress) => (
  <SidepanelFooter
    execute={onConfirm}
    close={onClose}
    submitInProgress={submitInProgress}
    confirmColor="primary"
    buttonLabels={text.EDIT_BUTTON_LABEL}
  />
);

class AvaIntegrationForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.closeSidepanel = this.closeSidepanel.bind(this);
  }

  componentDidUpdate() {
    const { tag, actions } = this.props;
    if (tag) actions.setRowData(tag);
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.closeOverlay(SIDEPANEL_ID);
  }

  closeSidepanel(refreshTable) {
    const { actions } = this.props;
    actions.closeOverlay(SIDEPANEL_ID);
    if (refreshTable) {
      const { onSuccess } = this.props;
      onSuccess();
    }
  }

  async submit() {
    const { actions, tag } = this.props;
    const ERROR = text.ERROR_EDIT;
    const SUCCESS = text.SUCCESS_EDIT;
    const customFormater = data => ({ description: data.description });
    
    const request = actions.update(api, tag.tag_id, customFormater);

    const [err] = await to(request);
    if (err) {
      notifier.error(ERROR);
    } else {
      this.closeSidepanel(true);
      notifier.success(SUCCESS);
    }
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    const { FormComponent } = this;
    return <FormComponent />;
  }

  render() {
    const { visible, submitInProgress } = this.props;
    const TITLE = text.TITLE_EDIT;
    return (
      <Sidepanel
        type="error"
        icon="Tag"
        title={TITLE}
        onCloseIconClick={() => this.closeSidepanel()}
        visible={visible}
        footerRender={() => customFooter(this.closeSidepanel, this.submit, submitInProgress)}
      >
        {this.renderForm()}
      </Sidepanel>
    );
  }
}

const mapStateToProps = ({ overlays, forms }) => {
  const sidepanel = overlays[SIDEPANEL_ID];
  const { fields, tag } = sidepanel.parameters || {};
  const form = forms[SIDEPANEL_ID];

  return {
    visible: sidepanel.visible,
    fields,
    tag,
    errors: form.errors,
    submitInProgress: form.submitInProgress,
    form: form.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeOverlay: overlayActions.close,
      create,
      update,
      setRowData,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvaIntegrationForm);
