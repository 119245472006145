import React, { Component } from 'react';
import { string, node, func, bool } from 'prop-types';
import { Accordion } from 'tc-biq-design-system';

const propTypes = {
  title: string,
  headerTemplate: func,
  children: node.isRequired,
  isVisible: bool,
  onClick: func,
};

const defaultProps = {
  title: '',
  headerTemplate: null,
  isVisible: null,
  onClick: null,
};

class BiqAccordion extends Component {
  constructor(props) {
    super(props);

    this.state = { visible: false };
  }

  toggleVisibility = () => {
    this.setState(({ visible }) => ({ visible: !visible }));
  }

  render() {
    const { title, children, headerTemplate, isVisible, onClick } = this.props;
    const { visible } = this.state;

    const headerProps = headerTemplate ? { headerTemplate: headerTemplate(visible) } : { title };

    return (
      <Accordion {...headerProps} visible={typeof isVisible === 'boolean' ? isVisible : visible} onClick={onClick || this.toggleVisibility}>
        {children}
      </Accordion>
    );
  }
}

BiqAccordion.propTypes = propTypes;
BiqAccordion.defaultProps = defaultProps;
export default BiqAccordion;
