import React from 'react';
import { string, object } from 'prop-types';
import { Tooltip } from 'tc-biq-design-system';
import If from '../../../../../../components/If';

const propTypes = {
  actionTooltip: string.isRequired,
  value: string.isRequired,
  action: object,
};

const defaultProps = {
  action: null,
};

const UserInfoCardItemValue = ({ actionTooltip, value, action }) => (
  <React.Fragment>
    <span className="m-r-sm">{value}</span>
    <If condition={action}>
      <Tooltip title={actionTooltip || value} placement="top">
        {action}
      </Tooltip>
    </If>
  </React.Fragment>
);

UserInfoCardItemValue.propTypes = propTypes;
UserInfoCardItemValue.defaultProps = defaultProps;
export default UserInfoCardItemValue;
