import React from 'react';
import { bool } from 'prop-types';

import { gettext } from '../../../../../../logic/utilities/languageUtility';

const propTypes = { isVisible: bool.isRequired };
const text = { NO_DATA: gettext('No data') };

const NoData = ({ isVisible }) => {
  if (!isVisible) return null;
  return (
    <div className="center-content">
      <h1>{text.NO_DATA}</h1>
    </div>
  );
};

NoData.propTypes = propTypes;

export default NoData;
