import React, { Component } from 'react';
import { notifier, Sidepanel } from 'tc-biq-design-system';
import { bool, func, object } from 'prop-types';
import { bindActionCreators } from 'redux';
import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import overlayActions from '../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../components/common';
import { formErrorHandler, validateForm } from '../../../../../components/form/logic/utils';
import FormFactory, { FormActionsFactory } from '../../../../../components/form';
import { isRequiredValidator } from '../../../../../components/form/logic/validators';

export const SIDEPANEL_ID = 'ADD_EDIT_EXCHANGE_RATE';
export const FORM_ID = 'ADD_EDIT_EXCHANGE_RATE_FORM';

const propTypes = {
  onSuccess: func.isRequired,
  actions: object.isRequired,
  sidepanel: object.isRequired,
  submitInProgress: bool,
};

const actionTypes = {
  RESET_STATE: 'RESET_STATE',
  SUBMIT: 'SUBMIT',
  SUBMIT_PENDING: 'SUBMIT_PENDING',
  SUBMIT_REJECTED: 'SUBMIT_REJECTED',
  SUBMIT_FULFILLED: 'SUBMIT_FULFILLED',
};

const initialState = {
  submitInProgress: false,
  fields: [],
  errors: {},
};

const modifiers = {
  country: {
    validators: [isRequiredValidator()],
  },
  currency_from: {
    validators: [isRequiredValidator()],
  },
  currency_to: {
    validators: [isRequiredValidator()],
  },
  value: {
    validators: [isRequiredValidator()],
  },
};

const resetState = createAction(actionTypes.RESET_STATE);
const submit = createAction(actionTypes.SUBMIT, async (values, action, api) => (action === 'add' ? api.create(values) : api.update(values.id, values)));

const onError = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  errors: payload.data,
});

const onSuccess = state => ({ ...state, submitInProgress: false, errors: {} });

export const reducer = handleActions({
  [actionTypes.RESET_STATE]: () => ({ ...initialState }),
  [actionTypes.SUBMIT_PENDING]: state => ({ ...state, submitInProgress: true }),
  [actionTypes.SUBMIT_REJECTED]: onError,
  [actionTypes.SUBMIT_FULFILLED]: onSuccess,
}, initialState);

const text = {
  TITLE_CREATE: gettext('Create exchange rate'),
  TITLE_EDIT: gettext('Edit exchange rate'),
  CREATE_BUTTON_LABELS: {
    confirm: gettext('Save exchange rate'),
    cancel: gettext('Discard'),
  },
  EDIT_BUTTON_LABELS: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
  SUCCESS: gettext('Exchange rate successfully saved'),
  ERROR: gettext('Error while saving exchange rate'),
};

const customFooter = (execute, close, submitInProgress, type) => (
  <SidepanelFooter
    execute={execute}
    close={close}
    submitInProgress={submitInProgress}
    confirmColor="primary"
    buttonLabels={type === 'edit' ? text.EDIT_BUTTON_LABELS : text.CREATE_BUTTON_LABELS}
    formId={SIDEPANEL_ID}
  />
);

class AddEditExchangeRate extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.submit = this.submit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.api = getDjangoApi('settings/rates');
  }

  onClose(submitSuccess) {
    const { onSuccess } = this.props;
    this.actions.resetState();
    this.actions.closeSidepanel(SIDEPANEL_ID);
    if (submitSuccess) onSuccess();
  }

  submit() {
    const { sidepanel, values, actions } = this.props;
    const { type } = sidepanel.parameters;

    const isValid = validateForm(actions.validator);
    if (!isValid) return;

    const request = {
      country: values.country?.iso_code,
      currency_from: values.currency_from?.value,
      currency_to: values.currency_to?.value,
      value: values?.value,
      is_percentage: values?.is_percentage,
    };

    this.actions.submit(request, type, this.api)
      .then(() => {
        notifier.success(text.SUCCESS);
        this.onClose(true);
      }, (err) => {
        formErrorHandler(text.ERROR)(err);
      });
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory({ form: FORM_ID, api: this.api });
    }

    const { FormComponent } = this;
    return <FormComponent formId={FORM_ID} modifiers={modifiers} />;
  }

  render() {
    const { sidepanel, state } = this.props;
    const { submitInProgress } = state;
    const { type } = sidepanel.parameters;

    return (
      <Sidepanel
        icon="Pen"
        title={type === 'edit' ? text.TITLE_EDIT : text.TITLE_CREATE}
        visible={sidepanel.visible}
        onCloseIconClick={() => this.onClose()}
        footerRender={() => customFooter(this.submit, this.onClose, submitInProgress, type)}
      >
        {this.renderForm()}
      </Sidepanel>
    );
  }
}

AddEditExchangeRate.propTypes = propTypes;

export const mapStateToProps = ({ overlays, pages, forms }) => ({
  ...forms[FORM_ID],
  state: pages.EXCHANGE_RATES.actions.ADD_EDIT_EXCHANGE_RATE,
  sidepanel: overlays.ADD_EDIT_EXCHANGE_RATE,
});

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...FormActionsFactory(FORM_ID),
      closeSidepanel: overlayActions.close,
      submit,
      resetState,
    },
    dispatch,
  ),
});

export default AddEditExchangeRate;
