import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';

const actionTypes = {
  ACTIVATE: 'ACTIVATE_MARKETING',
  ACTIVATE_PENDING: 'ACTIVATE_MARKETING_PENDING',
  ACTIVATE_FULFILLED: 'ACTIVATE_MARKETING_FULFILLED',
};

export const mailchimpApi = getDjangoApi('integration/mailchimp/user_settings');

export const activate = createAction(
  actionTypes.ACTIVATE, 
  (id) => { 
    const promises = [getDjangoApi(`users/${id}/marketing_data`).list()];
    if (isFeatureEnabled()('MAILCHIMP_SUBSCRIPTION')) promises.push(mailchimpApi.retrieve(id));
    return Promise.all(promises);
  },
);

const initialState = {
  data: {},
  fetchInProgress: false,
  errors: null,
};

const fetchFulfilled = (state, { payload }) => {
  const [marketing, mailchimp] = payload;

  return {
    ...state,
    fetchInProgress: false,
    data: { 
      ...marketing.data, 
      ...(isFeatureEnabled()('MAILCHIMP_SUBSCRIPTION') ? { mailchimpSubscription: mailchimp.data.subscribe } : {}),
    },
  };
};

export default handleActions(
  {
    [actionTypes.ACTIVATE_PENDING]: state => ({ ...state, fetchInProgress: true }),
    [actionTypes.ACTIVATE_REJECTED]: state => ({ ...state, fetchInProgress: false }),
    [actionTypes.ACTIVATE_FULFILLED]: fetchFulfilled,
  },
  initialState,
);
