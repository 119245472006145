import { gettext } from '../utilities/languageUtility';

export default {
  FULL: gettext('Full'),
  LIMITED: gettext('Limited'),
  PENDING: gettext('Pending'),
  REVIEW: gettext('Review'),
  ALERT: gettext('Alert'),
  KYC_CHECK_IN_PROGRESS: gettext('KYC checks in progress'),
  UNSUITABLE_REFER_DEMO: gettext('Unsuitable Demo Refer'),
  UNSUITABLE_DEMO: gettext('Unsuitable Demo'),
  DEMO: gettext('Demo'),
  PENDING_SMS_VERIFICATION: gettext('Pending Sms Verification'),
  PENDING_EMAIL_VERIFICATION: gettext('Pending Email Verification'),
  INCOMPLETE: gettext('Incomplete'),
  EXCLUDED: gettext('Excluded'),
  BLACKLIST: gettext('Blacklist'),
  UNSUITABLE_REFER: gettext('Unsuitable Refer'),
  UNSUITABLE: gettext('Unsuitable'),
  REJECTED: gettext('Rejected'),
  LEAD: gettext('Lead'),
  ARCHIVED: gettext('Archived'),
  REJECTED_IB: gettext('Rejected'),
  INCOMPLETE_IB: gettext('Incomplete'),
  PENDING_IB: gettext('Pending'),
  APPROVED_IB: gettext('Approved'),
};
