/* eslint-disable react/prop-types */
import React from 'react';

import {
  StatusCell,
  OnContentClickCell,
  UsernameCell,
  renderNestedColumnsModifiers,
  StateCell,
} from '../../../../components/gridCellRenderers';
import { currencyFormatter, WalletFormatter } from '../../../../logic/utilities/formatters';

const { environment } = window.config;
const isICMCapital = () => environment === 'ICMCapital';

export default ({ openWithdrawalRequest, previewUser, previewBiqUser, openHistoryWithdrawalRelatedTransaction }, tableData) => ({
  id: {
    cellRendererFramework: OnContentClickCell(openWithdrawalRequest),
    width: 80,
  },
  related_payment_transaction: {
    cellRendererFramework: OnContentClickCell(openHistoryWithdrawalRelatedTransaction),
  },
  user: {
    cellRendererFramework: UsernameCell(previewUser),
    width: 200,
  },
  wallet: {
    cellRendererFramework: WalletFormatter,
    width: 250,
  },
  status: {
    cellRendererFramework: StatusCell,
    width: 280,
    cellStyle: { 'justify-content': 'flex-end' },
  },
  ...renderNestedColumnsModifiers('steps', tableData, StateCell),
  method: { width: 250 },
  amount: {
    cellRendererFramework: ({ value, data }) => (
      <span>{currencyFormatter(value, data.currency.symbol)}</span>
    ),
  },
  executed_amount: {
    cellRendererFramework: ({ value, data }) => (
      <span>{currencyFormatter(value, data.currency.symbol)}</span>
    ),
  },
  assigned_to: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  modified_by: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  created_by: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  executed_by: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  authorized_by: { cellRendererFramework: UsernameCell(previewBiqUser, { isStaff: true }) },
  referral_ids: {
    quickFilter: {},
  },
  address: {
    headerName: isICMCapital() ? 'Wallet Address' : 'Address',
  },
});
