import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { Button, notifier } from 'tc-biq-design-system';
import { object, array, bool } from 'prop-types';

import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import FormFactory, { FormActionsFactory } from '../../../../../../components/form';
import connect from '../../../../../../logic/connect';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import settingsEmailActions from './Model';

const propTypes = {
  actions: object.isRequired,
  data: array.isRequired,
  fields: array.isRequired,
  submitInProgress: bool.isRequired,
};

const FORM_ID = 'SETTINGS_EMAILS_FORM';
const settingsApi = getDjangoApi('settings/email');
const { create, setFields, setFieldValue } = FormActionsFactory(FORM_ID);
const formConfig = {
  form: FORM_ID,
  excludeFields: [],
};

const text = {
  SAVE_CHANGES: gettext('Save changes'),
  SUCCESS: gettext('Email settings successfully updated'),
};

const fieldPropsResolver = data => data.map(item => ({
  type: 'text',
  label: item.description,
  id: item.key,
}));

class Settings extends PureComponent {
  constructor(props) {
    super(props);
    this.actions = props.actions;
  }

  componentDidMount() {
    this.actions.getData();
  }

  componentDidUpdate(prevProps) {
    const { data, fields } = this.props;
    if ((fields.length === 0 && data.length !== 0) || !_.isMatch(prevProps.data, data)) {
      this.actions.setFields(fieldPropsResolver(data));
      data.forEach((item) => {
        const { value, key } = item;
        if (value) {
          this.actions.setFieldValue({ id: key, value });
        }
      });
    }
  }

  submit = () => {
    this.actions.create(settingsApi).then(() => {
      notifier.success(text.SUCCESS);
      this.actions.getData();
    });
  };

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    const { FormComponent } = this;
    return <FormComponent formId={FORM_ID} />;
  };

  render() {
    const { submitInProgress } = this.props;
    return (
      <div className="biq-settings-emails__settings-tab">
        <div className="section">{this.renderForm()}</div>
        <div className="submit">
          <Button 
            formId={FORM_ID}
            type="submit" 
            disabled={submitInProgress} 
            loading={submitInProgress} 
            onClick={this.submit}
          >
            {text.SAVE_CHANGES}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ pages, forms }) => {
  const { settings } = pages.SETTINGS_EMAILS;
  const { fields, submitInProgress } = forms.SETTINGS_EMAILS_FORM;
  const { data, isLoading } = settings;
  return { data, isLoading, fields, submitInProgress };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      create,
      setFields,
      setFieldValue,
      getData: settingsEmailActions.getData,
    },
    dispatch,
  ),
});

Settings.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(Settings));
