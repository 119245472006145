import React from 'react';
import { Tooltip, Icon, Popconfirm } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import { gettext } from '../../../../logic/utilities/languageUtility';
import { UsernameCell } from '../../../../components/gridCellRenderers';
import ActionsCellWrapper from '../../../../components/gridCellRenderers/SingleActionCell';
import appRoutes from '../../../../components/App/Router/appRoutes';

const text = {
  MODIFY_ICON_TOOLTIP: gettext('Modify'),
  DUPLICATE_ICON_TOOLTIP: gettext('Copy'),
  DELETE_ICON_TOOLTIP: gettext('Delete'),
  IS_ENABLED_COLUMN_LABEL: gettext('State'),
  APPLICATION_COLUMN_LABEL: gettext('Template type'),
  NO: gettext('No'),
  YES: gettext('Yes'),
  POPCONFIRM_LABEL: gettext('Are you sure you want to delete?'),
};

const actionIcons = (modify, copy, onDelete) => ({
  ...(modify && {
    modify: (_, { id }) => {
      const url = generatePath(appRoutes.SMS_TEMPLATE, { id });
      return (
        <div key="Modify">
          <Link to={url}>
            <Tooltip title={text.MODIFY_ICON_TOOLTIP} placement="top">
              <Icon name="Edit" />
            </Tooltip>
          </Link>
        </div>
      );
    },
  }),
  ...(copy && {
    copy: (_, { id }) => (
      <div key="Copy">
        <Link to={generatePath(appRoutes.SMS_TEMPLATES_CREATE, { id })}>
          <Tooltip title={text.DUPLICATE_ICON_TOOLTIP} placement="top">
            <Icon name="Duplicate" />
          </Tooltip>
        </Link>
      </div>
    ),
  }),
  ...(onDelete && {
    onDelete: (_, data) => (
      <Tooltip title={text.DELETE_ICON_TOOLTIP} placement="top">
        <Popconfirm
          type="destructive"
          icon="Delete"
          onConfirm={() => onDelete(data)}
          label={text.POPCONFIRM_LABEL}
          buttonLabels={{ cancel: text.NO, confirm: text.YES }}
        >
          <Icon name="Delete" />
        </Popconfirm>
      </Tooltip>
    ),
  }),
});

export default ({ modify, copy, previewUser, onDelete }) => {
  const iconActions = {
    ...(modify && { modify: null }),
    ...(copy && { copy: null }),
    ...(onDelete && { onDelete }),
  };

  return {
    id: {
      width: 100,
    },
    name: {
      width: 300,
    },
    created_by: {
      cellRendererFramework: UsernameCell(previewUser, { isStaff: true }),
    },
    modified_by: {
      cellRendererFramework: UsernameCell(previewUser, { isStaff: true }),
    },
    actions: {
      label: '',
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(iconActions, actionIcons(modify, copy, onDelete)),
      pinned: 'right',
      width: 120,
    },
  };
};
