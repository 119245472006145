import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const actionTypes = {
  ACTIVATE: 'ACTIVATE_ONFIDO_REPORTS',
  ACTIVATE_PENDING: 'ACTIVATE_ONFIDO_REPORTS_PENDING',
  ACTIVATE_REJECTED: 'ACTIVATE_ONFIDO_REPORTS_REJECTED',
  ACTIVATE_FULFILLED: 'ACTIVATE_ONFIDO_REPORTS_FULFILLED',
  REVALIDATE: 'REVALIDATE',
  REVALIDATE_PENDING: 'REVALIDATE_PENDING',
  REVALIDATE_REJECTED: 'REVALIDATE_REJECTED',
  REVALIDATE_FULFILLED: 'REVALIDATE_FULFILLED',
  FETCH_ONE: 'FETCH_ONE',
  FETCH_ONE_FULFILLED: 'FETCH_ONE_FULFILLED',
};

const documentsQuery = {
  check_status__gt: 1,
};

export const documentPropertiesToInject = { 
  type: 'document_type',
  checkStatus: 'check_status',
  documentId: 'id',
};

const fetchCheckResult = ({ id }) => getDjangoApi(`documents/${id}/raw_check_result`).list();

export const activate = createAction(actionTypes.ACTIVATE, async (id) => {
  const { data } = await getDjangoApi(`users/${id}/documents`).list(documentsQuery);
  const { results } = data;
  const reports = await Promise.all(results.map(fetchCheckResult));
  return { reports, documents: results };
});

export const fetchOneReport = createAction(
  actionTypes.FETCH_ONE,
  id => fetchCheckResult({ id }),
  id => ({ id }),
);

export const revalidate = createAction(
  actionTypes.REVALIDATE, 
  id => getDjangoApi(`documents/${id}/start_check`).create({ force_recheck: false }),
  id => ({ id }),
);

const initialState = {
  reports: [],
  fetchInProgress: false,
  checksInProgress: [],
  errors: null,
};

const fetchPending = state => ({ ...state, fetchInProgress: true });

const fetchRejected = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  errors: payload.data,
});


const pullDocumentProps = document => _.reduce(
  documentPropertiesToInject, 
  (documentProps, value, key) => ({ ...documentProps, [key]: document[value] }),
  {},
);

const fetchFulfilled = (state, { payload }) => { 
  const { reports, documents } = payload;

  return {
    ...state,
    fetchInProgress: false,
    reports: reports.map((report, index) => ({ 
      ...report.data, 
      ...pullDocumentProps(documents[index]),
    })),
  };
};

const revalidatePending = (state, { meta }) => ({
  ...state,
  checksInProgress: [...state.checksInProgress, meta.id],
});

const fetchOneFulfilled = (state, { payload, meta }) => {
  const { reports } = state;

  return {
    ...state,
    reports: reports.map(report => (report.documentId === meta.id && payload.data) || report),
  };
};

const revalidateFinish = (state, { meta }) => ({
  ...state,
  checksInProgress: state.checksInProgress.filter(id => id !== meta.id),
});

export default handleActions(
  {
    [actionTypes.ACTIVATE_PENDING]: fetchPending,
    [actionTypes.ACTIVATE_FULFILLED]: fetchFulfilled,
    [actionTypes.ACTIVATE_REJECTED]: fetchRejected,
    [actionTypes.REVALIDATE_PENDING]: revalidatePending,
    [actionTypes.REVALIDATE_REJECTED]: revalidateFinish,
    [actionTypes.REVALIDATE_FULFILLED]: revalidateFinish,
    [actionTypes.FETCH_ONE_FULFILLED]: fetchOneFulfilled,
  },
  initialState,
);
