import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { object, bool, array, string } from 'prop-types';
import { Icon } from 'tc-biq-design-system';

import UserData from './UserData';
import If from '../../../../../components/If';
import connect from '../../../../../logic/connect';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { fetchUserFields } from '../Model';

import './BasicInfo.scss';
import { flattenNestedFields } from '../../../../../logic/services/query-adapter';

const propTypes = {
  actions: object.isRequired,
  user: object,
  fields: array,
  isIb: bool,
  userId: string,
};

const defaultProps = {
  user: null,
  fields: [],
  isIb: false,
  userId: '',
};

const text = {
  BASIC_INFO: gettext('Basic info'),
};

class BasicInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };
  }

  componentDidMount() {
    const { actions, isIb, userId } = this.props;
    actions.fetchUserFields({ id: userId }, isIb);
  }

  toggleDetails = () => {
    this.setState(({ open }) => ({ open: !open }));
  }

  render() {
    const { open } = this.state;
    const { user, isIb, fields } = this.props;
    if (!user || _.isEmpty(fields)) return null;

    return (
      <div className="biq-profile__basic-info">
        <If condition={open}>
          <UserData 
            fields={fields}
            user={user} 
            isIb={isIb} 
          />
        </If>

        <div className="biq-profile__basic-info__toggle">
          <span onClick={this.toggleDetails}>
            {text.BASIC_INFO}

            <span className="biq-profile__basic-info__caret">
              <If condition={open}>
                <Icon name="CaretUp" color="text-primary-500" />
              </If>

              <If condition={!open}>
                <Icon name="CaretDown" color="text-primary-500" />
              </If>
            </span>
          </span>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const fields = flattenNestedFields(page.userDetail.fields, '.', true);
  return {
    user: page.userDetail.user,
    fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUserFields,
    },
    dispatch,
  ),
});

BasicInfo.propTypes = propTypes;
BasicInfo.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
