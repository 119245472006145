import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import IB_STATE from '../../../../../../../logic/enums/ib-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import ChangePassword from './ChangePassword';
import userActionTypes from '../types';

const env = window.config.environment;

const text = {
  LABEL: gettext('Change password'),
  TITLE: user => gettext(`Change password for ${user.first_name} ${user.last_name}`),
};

const ACTION_ICON = 'User';

export default {
  name: 'changePassword',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    if (isIb) {
      return !user.is_archived && hasAccess('ib.change_password.*') && state !== IB_STATE.INCOMPLETE;
    } 
    const hasStatePermission = [
      USER_STATE.FULL,
      USER_STATE.PENDING,
      USER_STATE.LIMITED,
      USER_STATE.REVIEW,
      USER_STATE.DEMO,
    ].includes(state);

    return hasAccess('user.change_password.*') 
      && hasStatePermission
      && env !== 'AvaTrade';
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.TITLE(user),
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(ChangePassword, sidepanelOptions);
  },
};
