import React from 'react';
import { HyperLink, Tooltip } from 'tc-biq-design-system';
import isEmpty from 'lodash/isEmpty';
import { string, object } from 'prop-types';
import moment from 'moment';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

const LinkValue = ({ href }) => (
  <HyperLink>
    <Tooltip placement="bottom" content={<span style={{ width: '200px', overflow: 'hidden', wordBreak: 'break-all', whiteSpace: 'normal' }}>{ href }</span>}>
      <a className="sales-link" href={href}>{ href }</a>
    </Tooltip>
  </HyperLink>
);

LinkValue.propTypes = {
  href: string.isRequired,
};

const tradingExpirience = {
  demo_experience_or_forex_cfd_education: {
    label: gettext('Demo forex expirience'),
  },
  num_of_times_traded_in_forex_cfds: {
    label: gettext('Number of times traded in forex'),
  },
  what_was_the_average_size_of_trades: {
    label: gettext('Average size of trades'),
  },
};

const TradingExpirience = ({ trade }) => ( 
  <ul className="trading-expirience">
    { Object.keys(trade).map(key => <li>{ `${tradingExpirience[key].label}: ${trade[key]}` } </li>) }
  </ul>
);

TradingExpirience.propTypes = {
  trade: object.isRequired,
};

const defaultPrimiteveValue = value => value || '-';


const fieldsEnum = {
  age: {
    label: gettext('Age'),
    render: age => defaultPrimiteveValue(age), 
  },
  income: {
    label: gettext('Income'),
    render: income => defaultPrimiteveValue(income),
  },
  occupation: {
    label: gettext('Occupation'),
    render: occupation => defaultPrimiteveValue(occupation),
  },
  trading_experience: {
    label: gettext('Trading Experience'),
    render: trading_experience => (!isEmpty(trading_experience) ? <TradingExpirience trade={trading_experience} /> : '-'),
  },
  source_of_trading_funds: {
    label: gettext('Source of trading funds'),
    render: source_of_trading_funds => defaultPrimiteveValue(source_of_trading_funds),
  },
  last_deposit_attempt_date: {
    label: gettext('Last deposit attempt'),
    render: last_deposit => (last_deposit ? moment(last_deposit).format('MMMM Do YYYY, h:mm:ss a') : '-'),
  },
  last_deposit_attempt_state: {
    label: gettext('Last deposit attempt state'),
    render: last_deposit_attempt_state => last_deposit_attempt_state || '-',
  },
  last_login: {
    label: gettext('Last login'),
    render: last_login => (last_login ? moment(last_login).format('MMMM Do YYYY, h:mm:ss a') : '-'),
  },
  savings_and_investments: {
    label: gettext('Saving and investment'),
    render: saving_and_investment => defaultPrimiteveValue(saving_and_investment),
  },
  affiliate_description: {
    label: gettext('Affiliate description'),
    render: affiliate_description => defaultPrimiteveValue(affiliate_description),
  },
  main_offer: {
    label: gettext('Main offer'),
    render: main_offer => defaultPrimiteveValue(main_offer),
  },
  referred_from: {
    label: gettext('Referred from'),
    render: referred_from => (referred_from ? <LinkValue href={referred_from} /> : '-'),
  },
  deposit_attempt_count: {
    label: gettext('Deposit attempt count'),
    render: deposit_attempt_count => defaultPrimiteveValue(deposit_attempt_count),
  },
  last_deposit_attempt_amount: { 
    label: gettext('Last deposit attempt amount'),
    render: last_deposit_attempt_amount => defaultPrimiteveValue(last_deposit_attempt_amount),
  },
  tag_description: {
    label: gettext('Tag description'),
    render: tag_description => defaultPrimiteveValue(tag_description),
  },
};

export default fieldsEnum;
