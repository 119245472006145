import appRoutes from '../../components/App/Router/appRoutes';
import ENTITY_TYPE from '../enums/entity';

function entityFilter() {
  return function (entityType, isStaff) {
    switch (entityType) {
      case ENTITY_TYPE.USER:
        return isStaff ? appRoutes.TEAM_MEMBER : appRoutes.CLIENT_PROFILE;
      case ENTITY_TYPE.MESSAGE:
        return appRoutes.LOGS_MESSAGE;
      case ENTITY_TYPE.CHAT:
        return appRoutes.LOGS_MESSAGE;
      case ENTITY_TYPE.SMS:
        return appRoutes.LOGS_MESSAGE;
      case ENTITY_TYPE.CALL:
        return appRoutes.LOGS_MESSAGE;
      case ENTITY_TYPE.PAYMENT_TRANSACTION:
        return appRoutes.PAYMENT_TRANSACTION;
      case ENTITY_TYPE.TRADING_ACCOUNT:
        return appRoutes.TRADING_ACCOUNT;
      default:
        return '';
    }
  };
}

export default entityFilter;
