import React, { PureComponent, Fragment } from 'react';
import { notifier, ComboxFooter } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { func, object, bool } from 'prop-types';

import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import to from '../../../../../../../logic/utilities/to';


const FORM_KEY = 'SEND_SMS_FORM';

const { create } = FormActionsFactory(FORM_KEY);

const text = {
  TEXT: gettext('Text'),
  SEND: gettext('Send'),
  SUCCESS: gettext('SMS successfully sent'),
  ERROR_GENERAL: gettext('Something went wrong!'),
};

const propTypes = {
  closeCombox: func.isRequired,
  submitInProgress: bool.isRequired,
  user: object.isRequired,
  actions: object.isRequired,
};

class SendSMS extends PureComponent {
  constructor(props) {
    super(props);
    this.api = user => getDjangoApi(`users/${user.id}/sms/send_sms`);
  }

  onSubmit = async () => {
    const { user, actions } = this.props;
    const [err] = await to(actions.create(this.api(user), this.getRequestPayload));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    const { closeCombox } = this.props;
    notifier.success(text.SUCCESS);
    closeCombox();
  }

  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  getRequestPayload = ({ body }) => {
    const { user } = this.props;

    return {
      body,
      to_user: user.id,
    };
  }

  getFormConfig() {
    return {
      form: FORM_KEY,
      customFields: [
        {
          id: 'body',
          name: 'body',
          type: 'textarea',
          placeholder: text.TEXT,
        },
      ],
    };
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.getFormConfig());
    }
    const { FormComponent } = this;
    return <FormComponent />;
  }

  render() {
    const { submitInProgress } = this.props;
    return (
      <Fragment>
        {this.renderForm()}
        <ComboxFooter 
          onConfirm={this.onSubmit}
          isLoading={submitInProgress}
          confirmText={text.SEND} 
        />
      </Fragment>
    );
  }
}

SendSMS.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      create,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ forms }) => {
  const form = forms[FORM_KEY];
  return {
    submitInProgress: form.submitInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendSMS);
