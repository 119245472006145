import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const actionTypes = {
  ACTIVATE: 'ACTIVATE_TRADING_STATS',
  ACTIVATE_PENDING: 'ACTIVATE_TRADING_STATS_PENDING',
  ACTIVATE_REJECTED: 'ACTIVATE_TRADING_STATS_REJECTED',
  ACTIVATE_FULFILLED: 'ACTIVATE_TRADING_STATS_FULFILLED',
};

export const activate = createAction(
  actionTypes.ACTIVATE, 
  id => getDjangoApi(`users/${id}/trade_stats`).list(),
);

const initialState = {
  data: {},
  fetchInProgress: false,
  errors: null,
};

const fetchPending = state => ({ ...state, fetchInProgress: true });

const fetchRejected = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  errors: payload.data,
});


const fetchFulfilled = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  data: payload.data,
});

export default handleActions(
  {
    [actionTypes.ACTIVATE_PENDING]: fetchPending,
    [actionTypes.ACTIVATE_FULFILLED]: fetchFulfilled,
    [actionTypes.ACTIVATE_REJECTED]: fetchRejected,
  },
  initialState,
);
