import React, { Component } from 'react';
import { object, bool, func } from 'prop-types';
import { Space, Sidepanel, notifier } from 'tc-biq-design-system';

import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import FormFactory from '../../../../../../../components/form';
import to from '../../../../../../../logic/utilities/to';
import { formErrorHandler } from '../../../../../../../components/form/logic/utils';

export const SIDEPANEL_ID = 'GATEWAY_BANK_FORM';

const text = {
  TITLE_ADD: gettext('Add new acquiring bank'),
  TITLE_EDIT: gettext('Update acquiring bank'),
  NAME: gettext('Name'),
  SUCCESS_MESSAGE: gettext('Bank saved successfully'),
  ERROR_MESSAGE: gettext('Error while saving a bank'),
  button: {
    confirm: gettext('Save'),
    cancel: gettext('Cancel'),
  },
};

const api = gateway => getDjangoApi('payment_gateways')
  .one(gateway.id)
  .all('acquirers');

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.button}
    formId={SIDEPANEL_ID}
  />
);

const propTypes = {
  actions: object,
  sidepanel: object.isRequired,
  submitInProgress: bool,
  onSuccess: func,
};

const defaultProps = {
  actions: null,
  submitInProgress: false,
  onSuccess: null,
};

class Confirmation extends Component {
  onSubmit = async () => {
    const { sidepanel, actions } = this.props;
    const { parameters } = sidepanel;
    const { gateway, bank } = parameters;
    const getPayload = ({ name }) => ({
      name,
    });
    
    const request = this.isEdit()
      ? actions.update(api(gateway), bank.id, getPayload)
      : actions.create(api(gateway), getPayload);

    const [err, res] = await to(request);
    if (err) formErrorHandler(this.ERROR_MESSAGE)(err);
    if (res) this.onSuccess();
  }

  onSuccess = () => {
    const { onSuccess } = this.props;
    if (onSuccess) onSuccess();
    notifier.success(text.SUCCESS_MESSAGE);
    this.onClose();
  }

  onClose = () => {
    const { actions } = this.props;
    actions.closeSidepanel(SIDEPANEL_ID);
    this.FormComponent = null;
  }

  formConfig = () => ({
    form: SIDEPANEL_ID,
    customFields: [
      {
        id: 'name',
        name: 'name',
        label: text.NAME,
        type: 'text',
      },
    ],
  });

  isEdit = () => {
    const { sidepanel } = this.props;
    return !!_.get(sidepanel, 'parameters.bank');
  }

  renderForm = () => {
    if (!this.FormComponent) {
      const { sidepanel, actions } = this.props;
      const { parameters } = sidepanel;
      if (parameters) {
        const { bank } = parameters;
        if (bank) actions.setRowData(bank);
        this.FormComponent = FormFactory(this.formConfig());
      }
    }

    const { FormComponent } = this;
    return FormComponent && <FormComponent formID={SIDEPANEL_ID} />;
  }

  render() {
    const { sidepanel, submitInProgress } = this.props;
    const { visible } = sidepanel || {};
    const title = this.isEdit() ? text.TITLE_EDIT : text.TITLE_ADD;
  
    return (
      <Sidepanel 
        title={title}
        footerRender={customFooter(this.onSubmit, this.onClose, submitInProgress)}
        visible={visible}
        onCloseIconClick={this.onClose}
      >
        <Space size={16} />
        { this.renderForm() }
      </Sidepanel>
    );
  }
}

Confirmation.propTypes = propTypes;
Confirmation.defaultProps = defaultProps;

export default Confirmation;
