import { hasAccess } from '../../../logic/services/acl';
import isFeatureEnabled from '../../../logic/filters/is-feature-enabled';
import Clients from './Clients';
import appRoutes from '../../../components/App/Router/appRoutes';

export default {
  component: Clients.component,
  aclFn() {
    return hasAccess('user.list') && isFeatureEnabled()('table-refactor') && isFeatureEnabled()('OLD_TABLES');
  },
  path: appRoutes.CLIENTS_LIVE,
};
