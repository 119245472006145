import React, { Component, Fragment } from 'react';
import { notifier } from 'tc-biq-design-system';
import { object, func, bool } from 'prop-types';

import { getDjangoApi } from '../../../../logic/services/api-factory';
import { gettext } from '../../../../logic/utilities/languageUtility';
import TagsSelector from '../../../TagsSelector';

const propTypes = {
  user: object.isRequired,
  onUpdate: func.isRequired,
  hideTitle: bool,
  disable: bool,
};

const defaultProps = {
  hideTitle: false,
  disable: false,
};

const text = {
  SUCCESS_MESSAGE: gettext('Tags updated successfully'),
  ERROR_MESSAGE: gettext('Error while updaing tags'),
  TITLE: gettext('Tags'),
};

class UserTags extends Component {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  save(id, tags) {
    const { onUpdate } = this.props;

    getDjangoApi(`users/${id}/tags`)
      .create({ tags })
      .then(() => {
        onUpdate(tags);
        notifier.success(text.SUCCESS_MESSAGE);
      }, () => {
        notifier.error(text.ERROR_MESSAGE);
      });
  }

  handleSelect(tag) {
    const { user } = this.props;
    const tags = [...user.tags, tag];
    this.save(user.id, tags);
  }

  handleRemove(tagToRemove) {
    const { user } = this.props;
    const tags = user.tags.filter(tag => tag !== tagToRemove);
    this.save(user.id, tags);
  }

  render() {
    const { user, hideTitle, disable } = this.props;

    return (
      <Fragment>
        { !hideTitle && <p className="black-text">{text.TITLE}</p> }
        <TagsSelector disable={disable} tags={user.tags} onSelect={this.handleSelect} onRemove={this.handleRemove} />
      </Fragment>
    );
  }
}

UserTags.propTypes = propTypes;
UserTags.defaultProps = defaultProps;
export default UserTags;
