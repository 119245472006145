import React from 'react';
import { Tooltip, Popconfirm, Icon } from 'tc-biq-design-system';
import { arrayMove } from 'react-sortable-hoc';
import moment from 'moment';

import { YesNoCell, DateTimeCell } from '../gridCellRenderers';
import { gettext } from '../../logic/utilities/languageUtility';
import { toStartLocalDateTime } from '../../logic/utilities/formatters/dateTimeFormatter';
import { fieldInputResolver, inputTypes } from '../Filters/inputTypeResolver';
import queryOperators from '../../logic/enums/query-operators';
import QuickFilter from '../Filters/QuickFilter/QuickFilter';
import isFeatureEnabled from '../../logic/filters/is-feature-enabled';
import { isNestedField } from '../../logic/services/query-adapter';
import { sanitizeArrayFilter } from '../Filters/filterUtils';


export const getDefaultPageSize = () => (isFeatureEnabled()('PAGINATION_10') ? 10 : 25);

export const getPageSizes = () => {
  let pageSizes = [25, 50, 100, 200, 300];

  if (isFeatureEnabled()('PAGINATION_10')) {
    pageSizes = [10, ...pageSizes];
  }

  return pageSizes;
};

const handleModelRelations = (column) => {
  const displayField = column.display_field;
  if (!displayField) return {};
  return {
    valueGetter: ({ data }) => {
      const value = data[column.key];
      return value ? value[displayField] : null;
    },
  };
};

const setDefaultRenderer = ({ type }) => {
  const defaults = {
    boolean: YesNoCell,
    datetime: DateTimeCell,
  };

  return {
    cellRendererFramework: defaults[type],
  };
};

const getQuickFilterOperator = (field) => {
  const { type } = field || {};
  if (!type) return [null];
  const input = fieldInputResolver(field);

  if (isNestedField(field)) return [null];
  if (input === inputTypes.BOOLEAN) return [null];
  if (input === inputTypes.SELECT) {
    if (field.list_url) return [null];
    return [queryOperators.EXACT.value]; 
  }

  if (field.key === 'id') return [queryOperators.EXACT.value, { width: 120 }];

  switch (input) {
    case inputTypes.TEXT:
      return [queryOperators.ICONTAINS.value];
    case inputTypes.NUMBER:
      return [queryOperators.RANGE.value, { widht: 160 }];
    case inputTypes.CALENDAR:
      return [queryOperators.RANGE.value, { width: 280 }];
    case inputTypes.BOOLEAN:
      return [queryOperators.EXACT.value];
    default:
      return [queryOperators.CONTAINS.value];
  }
};

export const getQuickFilters = (fields, modifiers = {}) => {
  if (_.isEmpty(fields)) return {};

  return fields.reduce((acc, field) => {
    const modifier = modifiers[field.key];
    let operator = null;

    // eslint-disable-next-line no-param-reassign
    if (modifier && modifier.filterOptions) field.filterOptions = modifier.filterOptions;

    if (modifier && modifier.quickFilter) {
      const { operator: modifierOperator } = modifier.quickFilter;
      operator = modifierOperator;
    }

    if (!operator && (!modifier || (modifier && !modifier.quickFilter))) {
      [operator] = getQuickFilterOperator(field);
    }

    if (operator) {
      return {
        ...acc,
        [field.key]: <QuickFilter field={field} operator={operator} />,
      };
    }

    return acc;
  }, {});
};

const setDefaultQuickFilter = (field) => {
  const [quickFilterOperator, config] = getQuickFilterOperator(field);
  if (!quickFilterOperator) return {};
  return {
    quickFilter: {
      operator: quickFilterOperator,
    },
    ...(config ? { ...config } : {}),
  };
};

export const keepIfVisible = column => column.visible;

export const toFieldDefinition = fields => (column) => {
  const field = _.find(fields, { key: column.key });

  return {
    ...field,
    pinned: column.pinned,
  };
};

export const mapColDefs = (fields, modifier) => fields.map(column => ({
  ...handleModelRelations(column),
  ...setDefaultRenderer(column),
  ...setDefaultQuickFilter(column, modifier),
  definition: column,
  headerName: column.label,
  field: column.key,
  unSortIcon: !column.hiddenSortIcon,
  resizable: true,
  pinned: column.pinned,
  ...modifier[column.key],
}));

export const buildSortQuery = (sortModel, gridApi) => {
  if (sortModel.length === 0) return null;
  const sortBy = sortModel[0];
  const colDef = gridApi.getColumnDef(sortBy.colId);
  const { definition: { type, display_field }, field } = colDef;
  const orderPrefix = sortBy.sort === 'desc' ? '-' : '';
  if ((type === 'nested relation' || type === 'choice') && display_field) {
    return `${orderPrefix}${field}__${display_field}`;
  }
  return `${orderPrefix}${field}`;
};

export const getActiveFilters = (state) => {
  const { query } = state;
  const filters = {};
  Object.keys(query).forEach((queryKey) => {
    if (queryKey.includes('__')) {
      filters[queryKey] = query[queryKey];
    }
  });
  return filters;
};

export const sanitizeFilters = (query = {}) => {
  const newQuery = {};
  const ignore = ['limit', 'offset', 'ordering'];

  Object.keys(query).forEach((key) => {
    const value = query[key];
    newQuery[key] = _.isString(value) && !ignore.includes(key) ? sanitizeArrayFilter(value.replaceAll('+', encodeURIComponent('+'))) : value;
  });

  return newQuery;
};

export const modifyDates = (query = {}) => {
  const newQuery = {};
  const ignore = ['limit', 'offset', 'ordering'];

  Object.keys(query).filter(key => !ignore.includes(key)).forEach((key) => {
    const value = query[key];
    const date = moment(value);
    if (date.isValid()) {
      newQuery[key] = toStartLocalDateTime(date);
    }
  });

  return newQuery;
};

const text = {
  LABEL: gettext('Are you sure you want to delete'),
  CANCEL: gettext('Cancel'),
  CONFIRM: gettext('Delete'),
  EDIT_TOOLTIP: gettext('Edit'),
};

export const actionIcons = (modify, remove) => ({
  ...(modify && { modify: (actionHandler, data) => (
    <Tooltip key="action-modify" title={text.EDIT_TOOLTIP}><Icon className="risk-rating-page__action-icon" name="Edit" onClick={() => actionHandler(data)} /></Tooltip>
  ) }),
  ...(remove && { remove: (actionHandler, data) => (
    <Tooltip key="action-remove" title={text.CONFIRM} placement="top">
      <Popconfirm
        type="destructive"
        icon="Delete"
        label={`${text.LABEL}?`}
        onConfirm={() => actionHandler(data)}
        buttonLabels={{ cancel: text.CANCEL, confirm: text.CONFIRM }}
      >
        <Icon name="Delete" className="risk-rating-page__action-icon" />
      </Popconfirm>
    </Tooltip>
  ),
  }),
});

export const updatePosition = (values, oldIndex, newIndex) => {
  const visibleFields = values.filter(keepIfVisible);
  const draggedField = visibleFields[oldIndex];
  const replacedField = visibleFields[newIndex];
  const oldPosition = values.indexOf(draggedField);
  const newPosition = values.indexOf(replacedField);

  return arrayMove(values, oldPosition, newPosition);
};

/**
 *
 * @param {string | number} id
 * @param {string} name
 * @param {'select' | 'text'} [type]
 * @param {string} [label]
 * @param {string} [placeholder]
 * @param {function} [loadOptions]
 * @param {boolean} [async]
 * @param {boolean} [joinValues]
 * @param {string} [labelKey]
 * @param {string} [valueKey]
 * @return {{async, joinValues, name, id, label, placeholder, type, labelKey, valueKey, loadOptions}}
 */
export const createGridField = (
  id,
  name,
  type,
  label,
  placeholder,
  loadOptions,
  async = false,
  joinValues = false,
  labelKey,
  valueKey,
) => ({ id, name, type, label, placeholder, loadOptions, async, joinValues, labelKey, valueKey });
