import React, { Component, Fragment } from 'react';
import { Button, notifier } from 'tc-biq-design-system';
import { object, bool } from 'prop-types';

import FormFactory from '../../../../../../components/form';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

import './PersonalForm.scss';
import to from '../../../../../../logic/utilities/to';
import ConfirmActionModal from '../../../../../../components/common/modals/ConfirmAction/ConfitmAction';

export const FORM_ID = 'PERSONAL_DATA_FORM';
const api = getDjangoApi('settings/tpi/personal_data_visibility/mt4');

const text = {
  TITLE: gettext('Data that is sent to MT4'),
  SAVE: gettext('Save'),
  RESET: gettext('Reset'),
  ERROR_GENERAL: gettext('Something went wrong!'),
  SUCCESS: gettext('Personal data settings successfully saved'),
  CONFIRM_MESSAGE: gettext('This change will not be applied to personal data of clients in Canada business  group'),
  CONFIRM_TITLE: gettext('Data sending confirmation'),
};

const propTypes = {
  actions: object.isRequired,
  submitInProgress: bool.isRequired,
  values: object.isRequired,
};

class PersonalForm extends Component {
  componentDidUpdate() {
    const { values } = this.props;
    if (_.isEmpty(values)) this.setFormData();
  }

  onSubmit = () => {
    const { actions } = this.props;
    actions.openSidepanel('CONFIRM_ACTION_MODAL');
  }

  onSubmitConfirm = async () => {
    const { actions } = this.props;
    const [err] = await to(actions.update(api));
    err ? this.onError() : this.onSuccess();
  }

  onSuccess() {
    notifier.success(text.SUCCESS);
    this.resetForm();
  }
  
  onError(payload) {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }
  
  setFormData = async () => {
    const { actions } = this.props;
    const { data } = await api.retrieve();
    Object.keys(data).forEach(key => actions.setFieldValue({
      id: key,
      value: data[key],
    }));
    
    return data;
  }
  
  formConfig = () => ({
    form: FORM_ID,
    api,
  })
  
  resetForm = () => {
    const { actions } = this.props;
    this.FormComponent = null;
    actions.resetFields();
    this.forceUpdate();
  }

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.formConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { onSubmitConfirm } = this;
    const { submitInProgress } = this.props;
    const Form = this.renderForm();
    return (
      <Fragment>
        <div className="biq-personal-form">
          <div className="biq-personal-form__wrapper">
            <div className="biq-personal-form__section">
              <p className="tc-heading-l">{text.TITLE}</p>
            </div>
            <div className="biq-personal-form__section">
              <Form />
            </div>
            <div className="biq-personal-form__submit">
              <Button color="guest" loading={submitInProgress} onClick={this.resetForm}>
                {text.RESET}
              </Button>
              <Button loading={submitInProgress} onClick={this.onSubmit}>
                {text.SAVE}
              </Button>
            </div>
          </div>
        </div>
        <ConfirmActionModal onConfirm={onSubmitConfirm} message={text.CONFIRM_MESSAGE} title={text.CONFIRM_TITLE} />
      </Fragment>
    );
  }
}

PersonalForm.propTypes = propTypes;

export default PersonalForm;
