/* eslint-disable */
import moment from 'moment';
import catalog from '../../translations/catalog.json';

export const availableLanguages = ['en', 'zh'];

export let currentLanguage = window && window.localStorage ? localStorage.getItem('tcLanguage') : 'en';

if (!currentLanguage || availableLanguages.indexOf(currentLanguage) === -1) {
  currentLanguage = 'en';
}
export const currentDictionary = catalog[currentLanguage] || {};

moment.locale(currentLanguage);

export function setLanguage(language) {
  localStorage.setItem('tcLanguage', language);
  moment.locale(language);
  location.reload();
}

export function gettext(str, contextParams) {
  let translatedString = currentDictionary[str] || str;
  if (contextParams) {
    for (const context in contextParams) {
      translatedString = translatedString.split(`{{${context}}}`).join(contextParams[context]);
    }
  }
  return translatedString;
}

export default { setLanguage, availableLanguages, gettext };
