import React, { Component } from 'react';
import { Icon, Toggle, notifier, HyperLink } from 'tc-biq-design-system';
import { object, bool, any } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import moment from 'moment';

import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import appRoutes from '../../../../../../../components/App/Router/appRoutes';

const text = {
  TEAM: gettext('Team'),
  WHITELABEL: gettext('Whitelabel'),
  BUSINESS_GROUP: gettext('Business Group'),
  CREATED_BY: gettext('Created by'),
  COMMUNICATION_FLOW: gettext('Communication flow'),
  ALLOW_MANAGER: gettext('Allow manager self-assign'),
  SUCCESS: gettext('Status updated successfully'),
  UPDATED_AT: gettext('Updated at'),
};

const propTypes = {
  actions: object.isRequired,
  flow: object.isRequired,
  api: object.isRequired,
  hasPermission: object.isRequired,
  isAutoDialer: bool,
};

const defaultProps = {
  isAutoDialer: false,
};

const Div = ({ children, ...rest }) => <div {...rest} onClick={e => e.stopPropagation()}>{children}</div>;

Div.propTypes = {
  children: any.isRequired,
};

class SalesStatusesHeader extends Component {
  onFlowToggle = prop => () => {
    const { actions, flow, api } = this.props;
    actions.updateFlow(api, flow.id, { [prop]: !flow[prop] })
      .then(() => notifier.success(text.SUCCESS));
  }

  getDetails = () => {
    const { isAutoDialer, flow, hasPermission } = this.props;

    const {
      team,
      whitelabel,
      bussiness_group,
      created_by,
      allow_manager_self_assign,
      updated_at,
      flow: cFlow,
    } = flow;

    const renderCreatedBy = createdBy => createdBy && createdBy.username && (
      <HyperLink>
        <Link to={generatePath(appRoutes.TEAM_MEMBER, { id: createdBy.id })}>{ createdBy.username }</Link>
      </HyperLink>
    );
    
    const renderTeam = team => team && team.name && (
      <HyperLink>
        <Link href={generatePath(appRoutes.TEAM, { id: team.id })}>{ team.name }</Link>
      </HyperLink>
    );

    if (isAutoDialer) {
      return [
        {
          title: text.CREATED_BY,
          content: renderCreatedBy(created_by),
        },
        {
          title: text.UPDATED_AT,
          content: updated_at && moment(updated_at).format('YYYY-MM-DD HH:MM'),
        },
      ];
    }

    return [
      {
        title: text.TEAM,
        content: renderTeam(team),
      },
      {
        title: text.WHITELABEL,
        content: whitelabel && whitelabel.name,
      },
      {
        title: text.BUSINESS_GROUP,
        content: bussiness_group,
      },
      {
        title: text.CREATED_BY,
        content: renderCreatedBy(created_by),
      },
      {
        title: text.COMMUNICATION_FLOW,
        content: cFlow,
      },
      {
        title: text.ALLOW_MANAGER,
        content: (
          <Toggle
            disabled={!hasPermission.update}
            checked={allow_manager_self_assign}
            onChange={this.onFlowToggle('allow_manager_self_assign')}
          />
        ),
      },
    ];
  }

  render() {
    const {
      flow: {
        is_enabled,
        name,
        id,
      },
      hasPermission,
      isAutoDialer,
    } = this.props;
    const details = this.getDetails();

    return (
      <div className="cf-accordion__header">  
        <div className="header">
          <div className="accordion-arrow"><Icon name="CaretRight" size="extraSmall" /></div>
          <Div className="cf-accordion__header__toggle">
            <Toggle 
              disabled={!hasPermission.update}
              checked={is_enabled} 
              onChange={this.onFlowToggle('is_enabled')}
            />
          </Div>   
          <div className="title">{name}</div>
          <Div className="cf-accordion__header__actions">
            {hasPermission.update && (
              <Link to={generatePath(appRoutes.SETTINGS_SALES_STATUSES_AND_SUBSTATUSES_FORM, {
                tab: isAutoDialer ? 'auto-dialer' : 'communication-flow',
                mode: 'edit',
                id,
              })}
              >
                <Icon size="small" colorName="text-neutral-900" name="Edit" />
              </Link>
            )}
            {hasPermission.create && (
              <Link to={generatePath(appRoutes.SETTINGS_SALES_STATUSES_AND_SUBSTATUSES_FORM, {
                tab: isAutoDialer ? 'auto-dialer' : 'communication-flow',
                mode: 'clone',
                id,
              })}
              >
                <Icon size="small" colorName="text-neutral-900" name="Duplicate" />
              </Link>
            )}
          </Div>
        </div>
        <Div className="cf-accordion__details">
          {details.map(detail => (
            <div key={detail.title} className="cf-accordion__detail">
              <span className="cf-accordion__detail__title tc-micro-regular">{detail.title}</span>
              <span className="cf-accordion__detail__content tc-paragraph-regular">{detail.content}</span>
            </div>
          ))}
        </Div>
      </div>
    );
  }
} 

SalesStatusesHeader.propTypes = propTypes;
SalesStatusesHeader.defaultProps = defaultProps;

export default SalesStatusesHeader;
