import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const commentsApi = getDjangoApi('activity_stream_comments');

const initialState = {
  textValue: '',
  comments: [],
  isLoading: false,
  inProgress: false,
  errors: {},
};

const actionTypes = {
  UPDATE_TEXTAREA: 'UPDATE_TEXTAREA',
  LOAD_COMMENTS: 'LOAD_COMMENTS',
  LOAD_COMMENTS_PENDING: 'LOAD_COMMENTS_PENDING',
  LOAD_COMMENTS_FULFILLED: 'LOAD_COMMENTS_FULFILLED',
  ADD_COMMENT: 'ADD_COMMENT',
  ADD_COMMENT_PENDING: 'ADD_COMMENT_PENDING',
  ADD_COMMENT_FULFILLED: 'ADD_COMMENT_FULFILLED',
  ADD_COMMENT_REJECTED: 'ADD_COMMENT_REJECTED',
};

const updateTextarea = createAction(actionTypes.UPDATE_TEXTAREA);
const addComment = createAction(actionTypes.ADD_COMMENT, requestData => commentsApi.create(requestData));
const loadComments = createAction(actionTypes.LOAD_COMMENTS, async (id) => {
  const response = await commentsApi.list({ activity__exact: id });
  return response.data;
});

export const reducer = handleActions(
  {
    [actionTypes.UPDATE_TEXTAREA]: (state, { payload }) => ({
      ...state,
      textValue: payload,
      errors: {},
    }),
    [actionTypes.ADD_COMMENT_PENDING]: state => ({ ...state, inProgress: true, errors: {} }),
    [actionTypes.ADD_COMMENT_FULFILLED]: state => ({ ...state, inProgress: false }),
    [actionTypes.LOAD_COMMENTS_PENDING]: state => ({ ...state, isLoading: true }),
    [actionTypes.LOAD_COMMENTS_FULFILLED]: (state, { payload }) => {
      const { results } = payload;
      return {
        ...state,
        textValue: '',
        comments: results,
        isLoading: false,
      };
    },
    [actionTypes.ADD_COMMENT_REJECTED]: (state, { payload }) => {
      const { data } = payload;
      return {
        ...state,
        errors: data,
        inProgress: false,
      };
    },
  },
  initialState,
);

export default {
  addComment,
  updateTextarea,
  loadComments,
};
