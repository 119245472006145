import React from 'react';
import { Row, Card } from 'tc-biq-design-system';
import { object } from 'prop-types';

import { gettext } from '../../../../logic/utilities/languageUtility';
import { YesNoCell } from '../../../../components/gridCellRenderers';
import WithdrawalDetail from './WithdrawalDetail';

const propTypes = {
  withdrawal: object.isRequired,
};

const text = {
  WITHDRAWAL_DETAILS: gettext('Withdrawal details'),
  local_name: gettext('Local name'),
  chinese_full_name: gettext('Chinese full name'),
  name_matched: gettext('Name matched'),
  payment_provider: gettext('Payment provider'),
  bank_name: gettext('Bank name'),
  iban_number: gettext('IBAN number'),
  bank_account_number: gettext('Bank account number'),
  bank_address: gettext('Bank address'),
  bank_branch: gettext('Bank branch'),
  local_currency: gettext('Local currency'),
  exchange_rate: gettext('Exchange rate'),
  gateway: gettext('Gateway'),
  BeneficiaryAddress: gettext('Beneficiary Address'),
};

const renderDetails = withdrawalDetails => _.map(withdrawalDetails, (value, key) => {
  if (value === null) return null;
  if (typeof value === 'boolean') return <WithdrawalDetail key={key} entity={text[key]} value={<YesNoCell value={value} />} />;
  if (typeof value === 'object') return <WithdrawalDetail key={key} entity={text[key]} value={value.name} />;
  return <WithdrawalDetail key={key} entity={text[key]} value={value} />;
});

const prepareDetails = (withdrawal) => {
  const data = _.pick(withdrawal, [
    'local_name',
    'chinese_full_name',
    'name_matched',
    'payment_provider',
    'gateway',
    'bank_name',
    'iban_number',
    'bank_account_number',
    'bank_address',
    'bank_branch',
    'local_currency',
    'exchange_rate',
  ]);

  const metaFieldKeys = [
    'BeneficiaryAddress',
  ];
  
  const metaFields = Array.isArray(withdrawal.meta) 
    ? withdrawal.meta
      .filter(m => metaFieldKeys.includes(m.key))
      .reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {})
    : {};

  return { ...data, ...metaFields };
};

const WithdrawalDetails = ({ withdrawal }) => {
  const data = prepareDetails(withdrawal);
  if (_.isEmpty(data)) return null;

  return (
    <div className="m-b">
      <Row>
        <Card title={text.WITHDRAWAL_DETAILS}>
          <div className="biq-pending-withdrawal__card">
            <div className="biq-pending-withdrawal__details">
              {renderDetails(data)}
            </div>
          </div>
        </Card>
      </Row>
    </div>
  );
};

WithdrawalDetails.propTypes = propTypes;
export default WithdrawalDetails;
