import React, { useEffect, useRef, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import PubSub from 'pubsub-js';

const RequestLoader = () => {
  const ref = useRef(null);
  const [counter, setCounter] = useState(0);

  const addRequest = () => setCounter(prevCounter => prevCounter + 1);
  const removeRequest = () => setCounter(prevCounter => prevCounter - 1);

  useEffect(() => {
    PubSub.subscribe('ADD_REQUEST', addRequest);
    PubSub.subscribe('REMOVE_REQUEST', removeRequest);
    return () => {
      PubSub.unsubscribe(addRequest);
      PubSub.unsubscribe(removeRequest);
    };
  }, []);

  useEffect(() => {
    if (counter === 0) {
      ref.current.complete();
    } else {
      ref.current.continuousStart();
    }
  }, [ref, counter]);

  return (
    <div className="request-loader" role="progressbar">
      <LoadingBar color="#006be6" ref={ref} />
    </div>
  );
};

export default RequestLoader;
