import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';
import ChangeTaPassword from './ChangeTaPassword';
import userActionTypes from '../types';

const env = window.config.environment;

const text = {
  LABEL: gettext('Change TA password'),
  TITLE: gettext('Change password for Trading accounts'),
};

const ACTION_ICON = 'User';

export default {
  name: 'changeTaPassword',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, user, isIb) {
    if (isIb) {
      return false;
    }
    const hasStatePermission = [
      USER_STATE.FULL,
      USER_STATE.PENDING,
      USER_STATE.LIMITED,
      USER_STATE.REVIEW,
      USER_STATE.DEMO,
    ].includes(state);

    return isFeatureEnabled()('CHANGE_TA_PASSWORD') 
      && hasStatePermission 
      && hasAccess('user.trading_accounts.change_password.*')
      && env !== 'AvaTrade';
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.TITLE,
      visible: true,
      isIb,
      user,
      sidepanelManager,
    };

    sidepanelManager.add(ChangeTaPassword, sidepanelOptions);
  },
};
