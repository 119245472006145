import { bindActionCreators, compose } from 'redux';

import connect from '../../../../logic/connect';
import isFeatureEnabled from '../../../../logic/filters/is-feature-enabled';
import ComplianceStatuses, { tableConfig } from './ComplianceStatuses';
import overlayActions from '../../../../components/overlay';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import { hasAccess } from '../../../../logic/services/acl';
import appRoutes from '../../../../components/App/Router/appRoutes';

const mapStateToProps = ({ pages, overlays }) => ({
  table: pages[tableConfig().reducerKey].tables[tableConfig().tableKey],
  sidepanel: overlays.COMPLIENCE_STATUS_SIDEPANEL,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
      closeSidepanel: overlayActions.close,
    },
    dispatch,
  ),
  dispatch,
});

const ComplianceStatusesPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(ComplianceStatuses); 

export default {
  component: ComplianceStatusesPage,
  aclFn: () => hasAccess('compliance_status.list') && isFeatureEnabled()('COMPLIANCE_STATUS_ENABLED'),
  path: appRoutes.SETTINGS_COMPLIANCE_STATUSES,
};
