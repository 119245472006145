import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import TeamVisibilityGroupComp from './TeamVisibilityGroup';
import connect from '../../../../../logic/connect';
import { teamClientVisibilityGroupsActions } from './Model';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';
import { hasAccess } from '../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';

const mapStateToProps = ({ pages }) => {
  const page = pages.TEAM_CLIENT_VISIBILITY_GROUPS;
  return { ...page };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...teamClientVisibilityGroupsActions }, dispatch),
});

const TeamVisibilityGroupPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(TeamVisibilityGroupComp); 

export const TeamVisibilityGroup = {
  component: TeamVisibilityGroupPage,
  aclFn: () => hasAccess('object_acl_group.settings.update') && isFeatureEnabled()('CLIENT_VISIBILITY_GROUPS'),
  path: appRoutes.CLIENT_VISIBILITY_GROUP,
};

export const TeamVisibilityGroupCreate = {
  component: TeamVisibilityGroupPage,
  aclFn: () => hasAccess('object_acl_group.settings.create') && isFeatureEnabled()('CLIENT_VISIBILITY_GROUPS'),
  path: appRoutes.CLIENT_VISIBILITY_GROUP_CREATE,
};
