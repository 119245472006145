import { bindActionCreators } from 'redux';

import EditSignupDetailsSidepanel, { FORM_ID, SIDEPANEL_ID } from './EditSignupDetails';

import connect from '../../../../../../../logic/connect';
import { FormActionsFactory } from '../../../../../../../components/form';

const { setFieldValue } = FormActionsFactory(FORM_ID);

const mapStateToProps = ({ forms, overlays }) => {
  const { values } = forms[FORM_ID];
  return {
    values,
    sidepanel: overlays[SIDEPANEL_ID],
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setFieldValue,
    },
    dispatch,
  ),
});

export {
  SIDEPANEL_ID,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSignupDetailsSidepanel);
