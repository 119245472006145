import { createAction, handleActions } from 'redux-actions';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { convertFromMilliseconds } from '../../../../../logic/utilities/timeDuration';
import { refactoredInjectRules, setupFields } from '../../../../../logic/services/query-adapter';

const actionTypes = {
  GET_SMS_EVENT_TRIGGER_RULES_OPTIONS: 'GET_SMS_EVENT_TRIGGER_RULES_OPTIONS',
  GET_SMS_EVENT_TRIGGER_RULES_OPTIONS_FULFILLED: 'GET_SMS_EVENT_TRIGGER_RULES_OPTIONS_FULFILLED',
  GET_SMS_EVENT_FIELD_CHOICES: 'GET_SMS_EVENT_FIELD_CHOICES',
  GET_SMS_EVENT_FIELD_CHOICES_FULFILLED: 'GET_SMS_EVENT_FIELD_CHOICES_FULFILLED',
  GET_SMS_EVENT_INFO: 'GET_SMS_EVENT_INFO',
  GET_SMS_EVENT_INFO_FULFILLED: 'GET_SMS_EVENT_INFO_FULFILLED',
  SET_SMS_EVENT_TRIGGER: 'SET_SMS_EVENT_TRIGGER',
  SET_SMS_EVENT_TRIGGER_FULFILLED: 'SET_SMS_EVENT_TRIGGER_FULFILLED',
  SUBMIT_SMS_EVENT: 'SUBMIT_SMS_EVENT',
  SUBMIT_SMS_EVENT_FULFILLED: 'SUBMIT_SMS_EVENT_FULFILLED',
  SUBMIT_SMS_EVENT_REJECTED: 'SUBMIT_SMS_EVENT_REJECTED',
  SET_SMS_EVENT_NAME: 'SET_SMS_EVENT_NAME',
  SET_SMS_EVENT_HAS_DELAY: 'SET_SMS_EVENT_HAS_DELAY',
  SET_SMS_EVENT_DELAY: 'SET_SMS_EVENT_DELAY',
  SET_SMS_EVENT_DELAY_TYPE: 'SET_SMS_EVENT_DELAY_TYPE',
  SET_IS_SMS_EVENT_ENABLED: 'SET_IS_SMS_EVENT_ENABLED',
  SET_SMS_EVENT_BODY_TEMPLATE: 'SET_SMS_EVENT_BODY_TEMPLATE',
  SET_SMS_EVENT_BODY_TEMPLATE_ERROR: 'SET_SMS_EVENT_BODY_TEMPLATE_ERROR',
  SET_SMS_EVENT_CONDITION: 'SET_SMS_EVENT_CONDITION',
  RESET_SMS_EVENT_STATE: 'RESET_SMS_EVENT_STATE',
};

const initialState = {
  fields: [],
  group: {
    condition: 'AND',
    rules: [],
    identifier: _.uniqueId('qb'),
  },
  condition: {
    condition: 'AND',
    rules: [],
  },
  isEnabled: true,
  name: '',
  hasDelay: false,
  delay: 0,
  delayType: { display_name: gettext('Minutes'), value: 'minutes' },
  bodyTemplate: null,
  bodyTemplateChoices: [],
  eventTrigger: null,
  eventTriggerChoices: [],
  errors: {
    name: [''],
    event: [''],
    template: [''],
  },
};

const smsEventAPI = getDjangoApi('sms/trigger_rules');
const smsEventFieldsApi = getDjangoApi('events/actions/send_sms_user');

const getEventOptionsSuccessHandler = (state, { payload }) => {
  const eventTriggerChoices = _.find(payload.data.actions.POST.fields, { key: 'event' }).choices;

  return {
    ...state,
    eventTriggerChoices,
  };
};

const getEventFieldChoicesSuccessHandler = (state, { payload }) => {
  const bodyTemplateChoices = _.find(payload.data.fields, { key: 'template' }).choices;

  return {
    ...state,
    bodyTemplateChoices,
  };
};

const getEventInfoSuccessHandler = (state, { payload }) => {
  const { name, delay, event, condition, is_enabled, action_params } = payload.data;
  const { template } = action_params;
  const eventTrigger = _.find(state.eventTriggerChoices, { value: event });
  const { fields } = eventTrigger;
  const bodyTemplate = _.find(state.bodyTemplateChoices, { value: template });
  const hasDelay = !!delay;
  let eventDelay = 0;
  if (hasDelay) {
    const { duration } = convertFromMilliseconds();
    eventDelay = duration;
  }

  return {
    ...state,
    name,
    eventTrigger,
    condition,
    fields,
    isEnabled: is_enabled,
    hasDelay,
    delay: eventDelay,
    bodyTemplate,
  };
};

const setEventTriggerSuccessHandler = (state, { payload }) => {
  const { trigger, fields, reset } = payload;
  const mappedFields = setupFields(fields);
  const group = reset
    ? initialState.group
    : refactoredInjectRules(mappedFields, state.condition);

  return {
    ...state,
    group,
    eventTrigger: trigger,
    fields: mappedFields,
    errors: { ...state.errors, event: [''] },
  };
};

const submitEventSuccessHandler = state => (
  {
    ...state,
  }
);

const submitEventRejectionHandler = (state, { payload }) => (
  {
    ...state,
    errors: { ...state.errors, ...payload.data },
  }
);

const setEventNameHandler = (state, { payload }) => (
  {
    ...state,
    name: payload,
    errors: { ...state.errors, name: [''] },
  }
);

const setEventHasDelayHandler = state => (
  {
    ...state,
    hasDelay: !state.hasDelay,
    delay: 0,
  }
);

const setEventDelayHandler = (state, { payload }) => (
  {
    ...state,
    delay: payload,
  }
);

const setEventDelayTypeHandler = (state, { payload }) => (
  {
    ...state,
    delayType: { ...payload },
  }
);

const setIsEventEnabledHandler = (state, { payload }) => (
  {
    ...state,
    isEnabled: payload,
  }
);

const setEventBodyTemplateHandler = (state, { payload }) => (
  {
    ...state,
    bodyTemplate: { ...payload },
    errors: { ...state.errors, template: [''] },
  }
);

const setTemplateErrorHandler = (state, { payload }) => (
  {
    ...state,
    errors: { ...state.errors, template: [payload] },
  }
);

const setEventConditionHandler = (state, { payload }) => (
  {
    ...state,
    group: { ...payload },
  }
);

const resetStateHandler = () => {
  const { group } = initialState;
  group.rules = [];

  return {
    ...initialState,
    group,
  };
};

export const getEventOptions = createAction(
  actionTypes.GET_SMS_EVENT_TRIGGER_RULES_OPTIONS,
  async () => smsEventAPI.options(),
);

export const getEventFieldChoices = createAction(
  actionTypes.GET_SMS_EVENT_FIELD_CHOICES,
  async () => smsEventFieldsApi.list(),
);

export const getEventInfo = createAction(
  actionTypes.GET_SMS_EVENT_INFO,
  async id => smsEventAPI.retrieve(id),
);

export const setEventTrigger = createAction(
  actionTypes.SET_SMS_EVENT_TRIGGER,
  async (trigger, reset) => {
    let fields = [];
    if (trigger) {
      await getDjangoApi(`events/events/${trigger.value}`).list()
        .then((response) => {
          ({ fields } = response.data);
        });
    }
    return {
      fields,
      trigger,
      reset,
    };
  },
);

export const submitEvent = createAction(
  actionTypes.SUBMIT_SMS_EVENT,
  async (isEdit, request, id) => (
    isEdit ? smsEventAPI.updatePart(id, request) : smsEventAPI.create(request)
  ),
);

export const setEventName = createAction(
  actionTypes.SET_SMS_EVENT_NAME,
  event => event.target.value,
);

export const toggleHasDelay = createAction(
  actionTypes.SET_SMS_EVENT_HAS_DELAY,
);

export const setEventDelay = createAction(
  actionTypes.SET_SMS_EVENT_DELAY,
  delay => delay,
);

export const setEventDelayType = createAction(
  actionTypes.SET_SMS_EVENT_DELAY_TYPE,
  delayType => delayType,
);

export const setIsEventEnabled = createAction(
  actionTypes.SET_IS_SMS_EVENT_ENABLED,
  event => event,
);

export const setEventBodyTemplate = createAction(
  actionTypes.SET_SMS_EVENT_BODY_TEMPLATE,
  template => template,
);

export const setTemplateError = createAction(
  actionTypes.SET_SMS_EVENT_BODY_TEMPLATE_ERROR,
  error => error,
);

export const setEventCondition = createAction(
  actionTypes.SET_SMS_EVENT_CONDITION,
  group => group,
);

export const resetState = createAction(
  actionTypes.RESET_SMS_EVENT_STATE,
);

export const smsEventActions = {
  getEventOptions,
  getEventFieldChoices,
  getEventInfo,
  setEventTrigger,
  submitEvent,
  setEventName,
  toggleHasDelay,
  setEventDelay,
  setEventDelayType,
  setIsEventEnabled,
  setEventBodyTemplate,
  setTemplateError,
  setEventCondition,
  resetState,
};

export default handleActions(
  {
    [actionTypes.GET_SMS_EVENT_TRIGGER_RULES_OPTIONS_FULFILLED]: getEventOptionsSuccessHandler,
    [actionTypes.GET_SMS_EVENT_FIELD_CHOICES_FULFILLED]: getEventFieldChoicesSuccessHandler,
    [actionTypes.GET_SMS_EVENT_INFO_FULFILLED]: getEventInfoSuccessHandler,
    [actionTypes.SET_SMS_EVENT_TRIGGER_FULFILLED]: setEventTriggerSuccessHandler,
    [actionTypes.SUBMIT_SMS_EVENT_FULFILLED]: submitEventSuccessHandler,
    [actionTypes.SUBMIT_SMS_EVENT_REJECTED]: submitEventRejectionHandler,
    [actionTypes.SET_SMS_EVENT_NAME]: setEventNameHandler,
    [actionTypes.SET_SMS_EVENT_HAS_DELAY]: setEventHasDelayHandler,
    [actionTypes.SET_SMS_EVENT_DELAY]: setEventDelayHandler,
    [actionTypes.SET_SMS_EVENT_DELAY_TYPE]: setEventDelayTypeHandler,
    [actionTypes.SET_IS_SMS_EVENT_ENABLED]: setIsEventEnabledHandler,
    [actionTypes.SET_SMS_EVENT_BODY_TEMPLATE]: setEventBodyTemplateHandler,
    [actionTypes.SET_SMS_EVENT_BODY_TEMPLATE_ERROR]: setTemplateErrorHandler,
    [actionTypes.SET_SMS_EVENT_CONDITION]: setEventConditionHandler,
    [actionTypes.RESET_SMS_EVENT_STATE]: resetStateHandler,
  },
  initialState,
);
