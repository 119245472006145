/* eslint react/prop-types: 0 */
import { notifier } from 'tc-biq-design-system';
import React, { Component, Fragment } from 'react';
import { bindActionCreators, compose } from 'redux';

import overlayActions from '../../../../../../components/overlay';
import withTable, { GridTab } from '../../../../../../components/grid/withTable';
import { hasAccess } from '../../../../../../logic/services/acl';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import TASK_STATES from '../../../../../../logic/enums/task-state-label';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import modifier from '../../../../tasks/modifier';
import to from '../../../../../../logic/utilities/to';
import connect from '../../../../../../logic/connect';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import { tableConfig } from './table';
import { BiqUserPreview, CreateEditTask, UserPreview, ViewTask } from '../../../../../../components/common/sidepanels';
import userTopActionFactory from '../../../../../../components/UserTopAction';


const text = {
  DELETED: gettext('Task successfully deleted.'),
  COMPLETED: gettext('Task successfully completed.'),
  CREATE_TASK: gettext('Create task'),
  VIEW_ACTION: gettext('Preview task'),
  EDIT_ACTION: gettext('Edit task'),
  CLONE_ACTION: gettext('Clone task'),
  DELETE_ACTION: gettext('Delete task'),
  COMPLETE_ACTION: gettext('Mark as done'),
  DELETE_LABEL: gettext('Are you sure you want to delete task?'),
  COMPLETE_LABEL: gettext('Are you sure you want to complete task?'),
  CANCEL: gettext('No'),
  CONFIRM: gettext('Yes'),
};

const GridActions = userTopActionFactory('createTask');

const api = getDjangoApi('tasks');
class Tasks extends Component {
  constructor(props) {
    super(props);
    const { id } = props;
    this.actions = {
      ...props.actions,
    };
    this.tableActions = {
      previewUser: this.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
      previewBiqUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };

    this.Grid = withTable(GridTab, tableConfig, GridActions, { 
      withActions: true,
      hideSegments: true,
      filterOptions: {
        predefinedFilters: {
          target__auth_user: {
            value: id, 
            operator: 'exact',
          },
        },
      },
    })();
  }

  async onDelete(data) {
    const [err] = await to(api.destroy(data.id));
    if (err) {
      notifier.error(err.data.msg);
    } else {
      notifier.success(text.DELETED);
      this.updateTable();
    }
  }

  async onComplete(data) {
    const apiComplete = api
      .all('mark_as_completed');
    const [err] = await to(apiComplete.create({ ids: [data.id] }));
    if (err) {
      notifier.error(err.data.msg);
    } else {
      notifier.success(text.COMPLETED);
      this.updateTable();
    }
  }

  multiActions = task => [
    {
      action: this.actions.openSidepanel.bind(this, 'VIEW_TASK'),
      label: text.VIEW_ACTION,
      icon: 'View',
    },
    ...((hasAccess('tasks.update') || hasAccess('team.profile.tasks.update')) ? [{
      action: this.actions.openSidepanel.bind(this, 'CREATE_EDIT_TASK'),
      data: {
        type: 'edit',
      },
      label: text.EDIT_ACTION,
      icon: 'Edit',
    }] : []),
    ...((hasAccess('tasks.create') || hasAccess('team.profile.tasks.create')) ? [{
      action: this.actions.openSidepanel.bind(this, 'CREATE_EDIT_TASK'),
      data: {
        type: 'clone',
      },
      label: text.CLONE_ACTION,
      icon: 'Duplicate',
    }] : []),
    ...((hasAccess('tasks.destroy') || hasAccess('team.profile.tasks.destroy')) ? [{
      action: this.onDelete.bind(this),
      label: text.DELETE_ACTION,
      icon: 'Delete',
    }] : []),
    ...(task.state !== TASK_STATES.COMPLETED && (hasAccess('tasks.mark_as_completed.*') || hasAccess('team.profile.tasks.mark_as_completed.*')) ? [{
      action: this.onComplete.bind(this),
      label: text.COMPLETE_ACTION,
      icon: 'Checkmark',
    }] : []),
  ];

  updateTable = () => {
    const { dispatch, table } = this.props;
    const [, actions] = this.Grid;
    dispatch(actions.fetchTableData(table.query));
  }

  render() {
    const [Table] = this.Grid;
    return (
      <Fragment>
        <Table singleActions modifier={modifier(this.multiActions, this.tableActions)} />
        <ViewTask onSuccess={this.updateTable} />
        <CreateEditTask onSuccess={this.updateTable} />
        <UserPreview onUserUpdate={this.updateTable} />
        <BiqUserPreview />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
      closeSidepanel: overlayActions.close,
    },
    dispatch,
  ),
  dispatch,
});

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = tableConfig();
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(Tasks);
