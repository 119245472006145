import { gettext } from '../logic/utilities/languageUtility';

const strToBoolMap = {
  Yes: true,
  No: false,
  [gettext('N/A')]: null,
};

export const strToBool = (str) => {
  if (typeof str === 'string') {
    return strToBoolMap[str];
  }
};
