import React from 'react';
import { Tooltip, Icon, Popconfirm } from 'tc-biq-design-system';
import { generatePath, Link } from 'react-router-dom';

import hideCols from '../../../../logic/utilities/hideCols';
import queryOperators from '../../../../logic/enums/query-operators';

import { gettext } from '../../../../logic/utilities/languageUtility';
import { ToggleCell, ActionsCellWrapper, UsernameCell } from '../../../../components/gridCellRenderers';
import appRoutes from '../../../../components/App/Router/appRoutes';
import { convertFromMilliseconds } from '../../../../logic/utilities/timeDuration';

const text = {
  MODIFY_ICON_TOOLTIP: gettext('Modify'),
  DUPLICATE_ICON_TOOLTIP: gettext('Copy'),
  DELETE_ICON_TOOLTIP: gettext('Delete'),
  NAME_COLUMN_LABEL: gettext('Trigger name'),
  DELAY: gettext('Delay (seconds)'),
  IS_ENABLED_COLUMN_LABEL: gettext('State'),
  CANCEL: gettext('Cancel'),
  ARE_YOU_SURE: gettext('You are about to delete email event'),
  SECONDS: gettext('sec'),
};

// eslint-disable-next-line react/prop-types
const TimeCell = ({ value }) => {
  const { duration, durationType } = convertFromMilliseconds(value);
  return <span>{`${duration} ${duration !== 0 ? durationType.display_name : ''}`}</span>;
};

const actionIcons = (modify, copy, remove) => ({
  ...(modify && {
    modify: (_, { id }) => (
      <div key="Modify">
        <Link to={generatePath(appRoutes.EMAIL_EVENT, {
          id,
        })}
        >
          <Tooltip title={text.MODIFY_ICON_TOOLTIP} placement="top">
            <Icon name="Edit" />
          </Tooltip>
        </Link>
      </div>
    ),
  }),
  ...(copy && {
    copy: (_, { id }) => (
      <div key="Copy">
        <Link to={generatePath(appRoutes.EMAIL_EVENT_CREATE, {
          id,
        })}
        >
          <Tooltip title={text.DUPLICATE_ICON_TOOLTIP} placement="top">
            <Icon name="Duplicate" />
          </Tooltip>
        </Link>
      </div>
    ),
  }),
  ...(remove && {
    remove: (onRemove, data) => {
      const label = `${text.ARE_YOU_SURE} ${data.name}`;
      return (
        <Tooltip key="remove" title={text.DELETE_ICON_TOOLTIP}>
          <Popconfirm
            type="destructive"
            icon="Delete"
            label={label}
            onConfirm={() => onRemove(data)}
            buttonLabels={{
              cancel: text.CANCEL,
              confirm: text.DELETE_ICON_TOOLTIP,
            }}
          >
            <Icon name="Delete" />
          </Popconfirm>
        </Tooltip>
      );
    },
  }),
});

export default ({ toggle, modify, copy, remove, previewUser }) => {
  const actions = {
    ...(modify && { modify: null }),
    ...(copy && { copy: null }),
    ...(remove && { remove }),
  };

  return {
    ...hideCols(['id', 'action', 'action_params', 'code_num', 'condition']),
    created_by: {
      filterOptions: {
        query: {
          is_staff: true,
        },
      },
      quickFilter: {
        operator: queryOperators.EXACT.value,
      },
      cellRendererFramework: UsernameCell(previewUser, { isStaff: true }),
    },
    delay: {
      cellRendererFramework: TimeCell,
      quickFilter: {},
    },
    event: {
      quickFilter: {
        operator: queryOperators.EXACT.value,
      },
    },
    name: {
      headerName: text.NAME_COLUMN_LABEL,
      quickFilter: {
        operator: queryOperators.EXACT.value, 
      },
    },
    base_group: {
      quickFilter: {
        operator: queryOperators.EXACT.value,
      },
    },
    created: {
      width: 280,
      quickFilter: {
        operator: queryOperators.RANGE.value,
      },
    },
    modified: {
      width: 280,
      quickFilter: {
        operator: queryOperators.RANGE.value,
      },
    },
    ...(toggle && {
      is_enabled: {
        position: 0,
        headerName: text.IS_ENABLED_COLUMN_LABEL,
        width: 100,
        pinned: 'left',
        enableFiltering: false,
        cellRendererFramework: ToggleCell(toggle),
      },
    }),
    actions: {
      label: '',
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(modify, copy, remove)),
      pinned: 'right',
      width: 120,
    },
  };
};
