import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { DataGrid, Button } from 'tc-biq-design-system';
import { func, bool, shape, object, number, array, any, string } from 'prop-types';

import connect from '../../logic/connect';
import { gettext } from '../../logic/utilities/languageUtility';
import withErrorBoundary from '../hoc/withErrorBoundary';
import { getDjangoApi } from '../../logic/services/api-factory';
import GridActionsFactory from './GridActionsFactory';
import {
  mapColDefs,
  buildSortQuery,
  keepIfVisible,
  toFieldDefinition,
  updatePosition,
  getQuickFilters,
} from './GridUtils';
import SelectorFactory from '../common/modals/Selector';
import overlayActions from '../overlay';
import If from '../If';

import './Grid.scss';

const MODAL_ID = 'MANAGE_GRID_COLUMNS';
const SelectorModal = SelectorFactory(MODAL_ID);

const text = {
  MANAGE_COLUMNS: gettext('Manage columns'),
  OF: gettext('of'),
};

const tableShape = {
  pagination: shape({
    total: number,
    current: number.isRequired,
    pageSize: number.isRequired,
  }),
  loading: bool.isRequired,
  query: object.isRequired,

};

const propTypes = {
  table: shape(tableShape).isRequired,
  onCellClicked: func,
  tableModifier: object,
  onComponentDidUpdate: func,
  actions: object.isRequired,
  style: object,
  checkboxSelection: bool,
  bulkActions: array,
  singleActions: bool,
  info: bool,
  gridConfig: object,
  onRowSelected: func,
  sizeColumnsToFit: bool,
  columnManagerVisible: bool,
  getGridApi: func,
  options: object,
  GridActions: any,
  hasManageOption: bool,
  showDefaultPagination: bool,
  useCursorPagination: bool,
  isRowSelectable: func,
  getRowStyle: func,
  getRowClass: func,
  pinnedBottomRowData: array,
  suppressCellSelection: bool,
  /**
   * Introduced to avoid multiple
   * options requests in cases
   * where table options are needed
   * before table is mounted into the DOM.
   * In those cases options can be fetched
   * from parent component (e.x. Clients.jsx).
   *
   * TODO: think about better way to handle this scenario.
   */
  preventInternalOptions: bool,
  isDisabled: bool,
  hideSegments: bool,
  maxTotal: number,
  onGridReady: func,
  height: string,
  autoHeight: bool,
  disableQuickFilter: bool,
};

const defaultProps = {
  onCellClicked: null,
  tableModifier: {},
  onComponentDidUpdate: null,
  style: {},
  checkboxSelection: false,
  bulkActions: [],
  singleActions: false,
  info: false,
  gridConfig: {},
  onRowSelected: null,
  sizeColumnsToFit: false,
  columnManagerVisible: false,
  preventInternalOptions: false,
  getGridApi: null,
  options: {},
  GridActions: null,
  hasManageOption: true,
  showDefaultPagination: true,
  useCursorPagination: false,
  getRowStyle: null,
  getRowClass: null,
  isRowSelectable: () => true,
  pinnedBottomRowData: [],
  suppressCellSelection: false,
  isDisabled: false,
  hideSegments: false,
  maxTotal: 10000,
  onGridReady: () => {},
  height: '100%',
  autoHeight: false,
  disableQuickFilter: false,
};

/**
 * Generates GridComponents and Actions
 * @returns {Object} with GridComponents and actions
 */

export default function ({ apiUrl, reducerKey, tableKey }) {
  const api = getDjangoApi(apiUrl);
  const actions = GridActionsFactory(tableKey, api);
  const {
    fetchOptions,
    fetchTableData,
    changeOrdering,
    changePageSize,
    changePage,
    changeCursor,
    updateFilter,
    updateExportUrl,
    addActionsColumn,
    addInfoColumn,
    updateRowData,
    changeSegment,
    setColumnsVisibility,
    resetTable,
    updateMaxTotal,
  } = actions;

  class GridComponent extends Component {
    constructor(props) {
      super(props);
      this.actions = props.actions;
      this.onGridReady = this.onGridReady.bind(this);
      this.onRowSelected = this.onRowSelected.bind(this);
      this.setTableData = _.debounce(this.setTableData.bind(this), 100);
      this.fetchTableData = _.debounce(this.fetchTableData.bind(this), 100);

      this.state = {
        gridReady: false,
      };
      this.gridLoading = false;
    }
    
    componentDidMount() {
      const { tableModifier, singleActions, info,
        preventInternalOptions, actions, maxTotal } = this.props;
      actions.updateMaxTotal(maxTotal);
      if (!preventInternalOptions) this.actions.fetchOptions(tableModifier);
      if (singleActions) this.actions.addActionsColumn(tableModifier.actions);
      if (info) this.actions.addInfoColumn(tableModifier.info);
    }

    componentDidUpdate(prevProps) {
      const { onComponentDidUpdate } = this.props;
      if (onComponentDidUpdate) {
        onComponentDidUpdate(this.props);
      }
      this.setTableData();
      this.fetchTableDataOnQueryChange(prevProps);
      this.updateExportUrl(prevProps);
      this.sizeColumnsToFit();
      this.initialFetchTableDataForDefaultSegment(prevProps);
      this.fetchTableDataOnSegmentChange(prevProps);
    }

    componentWillUnmount() {
      this.actions.resetTable();
    }

    onCellClicked(params) {
      const { onCellClicked } = this.props;
      if (onCellClicked) {
        onCellClicked(params);
      }
    }

    onManageColumnsClick = () => {
      const {
        table,
      } = this.props;

      this.actions.openModal(MODAL_ID, {
        options: table.fields,
        values: table.columnsState.current,
      });
    }

    onGridReady(params) {
      const { getGridApi, onGridReady } = this.props;
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.gridApi.showLoadingOverlay();
      this.gridLoading = true;
      this.setState({
        gridReady: true,
      });
      if (getGridApi) {
        getGridApi(this.gridApi);
      }

      onGridReady();
    }

    onSortChange(data) {
      const sortModel = data.api.getSortModel();
      const ordering = buildSortQuery(sortModel, data.api);
      this.actions.changeOrdering(ordering);
    }

    onColumnMoved = (e) => {
      const { column, toIndex, columnApi } = e;

      if (column) {
        const [firstColumn] = columnApi.getAllDisplayedColumns();
        
        // eslint-disable-next-line no-plusplus
        const newIndex = (firstColumn && firstColumn.colId === 'checkboxGrid') ? toIndex - 1 : toIndex;
        const { colDef: { field: fieldKey }, pinned } = column;
        const { table: { columnsState: { current: fields } } } = this.props;
        const draggedField = fields.find(({ key }) => key === fieldKey);
        draggedField.pinned = pinned;
        const oldIndex = fields.filter(keepIfVisible).indexOf(draggedField);
        const values = updatePosition(fields, oldIndex, newIndex);
        this.actions.setColumnsVisibility(values);
      }
    }

    onRowSelected(data) {
      const { onRowSelected } = this.props;
      if (onRowSelected) {
        onRowSelected(data);
      }
    }

    setTableData() {
      const { table } = this.props;
      const { gridReady } = this.state;
      if (!gridReady) return;

      if (table.loading && !this.gridLoading) {
        this.gridLoading = true;
        this.gridApi.showLoadingOverlay();
        return;
      }

      if (!table.loading) {
        this.gridLoading = false;
        this.gridApi.setRowData(table.data);
        this.gridApi.redrawRows();
      }
    }

    sizeColumnsToFit() {
      const { sizeColumnsToFit } = this.props;

      if (this.gridApi && this.columnApi) {
        const totalColumns = this.columnApi.getAllColumns().filter(col => col.visible).length;
        const smallerThanViewport = totalColumns < 7;

        if (sizeColumnsToFit || smallerThanViewport) {
          this.gridApi.sizeColumnsToFit();
        }
      }
    }

    updateExportUrl(prevProps) {
      if (prevProps.exportUrl) {
        const { table } = this.props;
        const { single, all } = prevProps.exportUrl;

        if (!_.isEqual(table.query, prevProps.table.query) || (!single && !all)) {
          this.actions.updateExportUrl();
        }
      }
    }

    fetchTableDataOnQueryChange(prevProps) {
      const { table } = this.props;
      if (!_.isEqual(table.query, prevProps.table.query)) {
        this.fetchTableData();
      }
    }

    fetchTableDataOnSegmentChange(prevProps) {
      const { table, hideSegments } = this.props;
      const segmentChanged = !_.isEqual(table.selectedSegment, prevProps.table.selectedSegment);
      if (segmentChanged && !hideSegments) {
        this.fetchTableData();
      }
    }

    fetchTableData() {
      const { table } = this.props;
      this.actions.fetchTableData(table.query);
    }

    initialFetchTableDataForDefaultSegment(prevProps) {
      const { table, preventInternalOptions } = this.props;
      const { options } = table;
      const isDefaultSegment = table.selectedSegment.id === 'default';

      if (!preventInternalOptions
        && prevProps.table.options.fromApi !== options.fromApi
        && isDefaultSegment) {
        this.fetchTableData();
      }
    }

    render() {
      const {
        table,
        tableModifier,
        style,
        checkboxSelection,
        bulkActions,
        gridConfig,
        columnManagerVisible,
        hasManageOption,
        GridActions,
        showDefaultPagination,
        pinnedBottomRowData,
        suppressCellSelection,
        useCursorPagination,
        isDisabled,
        isRowSelectable,
        getRowStyle,
        getRowClass,
        height,
        autoHeight,
        disableQuickFilter,
      } = this.props;

      const pagination = {
        ...table.pagination,
        useCursorPagination,
        showTotal: (total, range, realTotal) => (useCursorPagination ? table.pagination.current : `${range[0]} - ${range[1]} ${text.OF} ${realTotal}`),
        pageSizerOpeningDirection: 'up',
        onPageSizeChange: size => this.actions.changePageSize(size),
        onChange: page => this.actions.changePage(page),
        onCursorChange: type => this.actions.changeCursor(type),
        loading: table.loading,
      };

      const segmentFields = table.columnsState.current
        .filter(keepIfVisible)
        .map(toFieldDefinition(table.fields));
      
      const fields = [
        ...segmentFields,
        ...table.customFields,
      ];

      const quickFilters = disableQuickFilter ? {} : getQuickFilters(fields, tableModifier);

      const columnDefs = mapColDefs(fields, tableModifier);

      const gridOptions = {
        enableServerSideSorting: true,
        enableCellTextSelection: true,
        onSortChanged: this.onSortChange.bind(this),
        onCellClicked: this.onCellClicked.bind(this),
        onColumnMoved: _.debounce(this.onColumnMoved.bind(this), 5000),
        reactNext: true,
        ...gridConfig,
        pinnedBottomRowData,
        suppressCellSelection,
        isRowSelectable,
        getRowStyle,
        getRowClass,
        suppressColumnVirtualisation: true,
      };

      return (
        <div className={`biq-grid  ${isDisabled ? 'biq-grid-disabled' : ''} ${autoHeight ? 'biq-grid--auto-height' : ''}`}>
          <div className="biq-grid__header">
            { hasManageOption && (
            <Button
              color="ghost" 
              onClick={this.onManageColumnsClick}
              className="biq-grid__manage"
            >
              { text.MANAGE_COLUMNS }
            </Button>
            )}
            {GridActions && <div className="biq-grid__actions"><GridActions /></div>}
          </div>

          <div style={{ ...style }} className="biq-grid__table">
            <DataGrid
              height={height}
              autoColWidth={false}
              checkboxSelection={checkboxSelection}
              bulkActions={bulkActions}
              onGridReady={this.onGridReady}
              gridOptions={gridOptions}
              columnDefs={columnDefs}
              pagination={showDefaultPagination && pagination}
              onRowSelected={this.onRowSelected}
              getRowStyle={getRowStyle}
              getRowClass={getRowClass}
              showDefaultPagination={showDefaultPagination}
              quickFilters={quickFilters}
            />
          </div>

          <If condition={columnManagerVisible}>
            <SelectorModal onApply={this.actions.setColumnsVisibility} />
          </If>
        </div>
      );
    }
  }

  const mapStateToProps = ({ pages, overlays }) => ({
    table: pages[reducerKey].tables[tableKey],
    columnManagerVisible: overlays[MODAL_ID].visible,
  });

  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
      {
        fetchOptions,
        fetchTableData,
        changeOrdering,
        changePageSize,
        changePage,
        changeCursor,
        updateFilter,
        updateExportUrl,
        addActionsColumn,
        addInfoColumn,
        updateRowData,
        changeSegment,
        setColumnsVisibility,
        resetTable,
        updateMaxTotal,
        openModal: overlayActions.open,
      },
      dispatch,
    ),
  });

  GridComponent.propTypes = propTypes;
  GridComponent.defaultProps = defaultProps;

  const connectWrapper = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorBoundary(GridComponent));

  return {
    GridComponent: connectWrapper,
    actions,
  };
}
