import React, { PureComponent } from 'react';
import { Button, Dropdown } from 'tc-biq-design-system';
import { object, func } from 'prop-types';

import connect from '../../logic/connect';
import { gettext } from '../../logic/utilities/languageUtility';
import overlayActions from '../overlay';
import { hasAccess } from '../../logic/services/acl';

const propTypes = {
  selectedSegment: object,
  openSidepanel: func.isRequired,
};

const defaultProps = {
  selectedSegment: {},
};

const text = {
  SAVE_VIEW: gettext('Save view'),
  CURRENT_VIEW: gettext('Current view'),
  NEW_VIEW: gettext('New view'),
};

const hasCreateSegmentAccess = () => hasAccess('segments.create');
const hasUpdateSegmentAccess = () => hasAccess('segments.update');


const SaveButton = props => (
  <Button color="transparent" icon="Save" {...props}>
    {text.SAVE_VIEW}
  </Button>
);

const customDropdownHeader = toggleHandler => <SaveButton key="saveview-button" onClick={toggleHandler} />;

class SaveViewButton extends PureComponent {
  dropdownList(canCreate, canUpdate) {
    return [
      ...(canUpdate ? [{ label: text.CURRENT_VIEW, onClick: () => this.openSidepanel(false) }] : []),
      ...(canCreate ? [{ label: text.NEW_VIEW, onClick: () => this.openSidepanel(true) }] : []),
    ];
  }

  openSidepanel(isNew) {
    const { openSidepanel } = this.props;
    openSidepanel('FILTER_VIEW_FORM', { isNew });
  }

  render() {
    const { selectedSegment } = this.props;
    const isDefaultSegment = selectedSegment.id === 'default';

    const canUpdate = hasUpdateSegmentAccess() && !isDefaultSegment;
    const canCreate = hasCreateSegmentAccess();

    if (!canCreate && !canUpdate) return <SaveButton disabled />;
    return <Dropdown list={this.dropdownList(canCreate, canUpdate)} customHeader={customDropdownHeader} />;
  }
}

SaveViewButton.propTypes = propTypes;
SaveViewButton.defaultProps = defaultProps;

const mapStateToProps = ({ filters }) => ({
  activeFilters: filters.activeFilters,
});

const mapDispatchToProps = {
  openSidepanel: overlayActions.open,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveViewButton);
