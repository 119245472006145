import React from 'react';
import { shape, string } from 'prop-types';
import { Dropdown } from 'tc-biq-design-system';

import { gettext } from '../logic/utilities/languageUtility';

const propTypes = {
  url: shape({
    single: string,
    all: string,
  }).isRequired,
};

const Export = ({ url: { single, all } }) => {
  const links = [
    { item: key => <a key={key} href={single}><li className="dropdown-list-item">{ gettext('Current page') }</li></a> },
    { item: key => <a key={key} href={all}><li className="dropdown-list-item">{ gettext('All') }</li></a> },
  ];

  return single && all ? <Dropdown openLeft title={gettext('Export')} customList={links} /> : null;
};

Export.propTypes = propTypes;
export default Export;
