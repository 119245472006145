import React from 'react';
import { Spinner } from 'tc-biq-design-system';
import { any, bool, string, oneOf, node, arrayOf } from 'prop-types';
import classnames from 'classnames';

import './FormPanel.scss';

const propTypes = {
  children: any.isRequired,
};

const defaultProps = {
  wide: false,
};

const Section = ({ children, hideBorder }) => (
  <div className={classnames('form-panel__section', { 'form-panel__section--hide-border': hideBorder })}>{children}</div>
);

Section.propTypes = {
  ...propTypes,
  hideBorder: bool,
};

Section.defaultProps = {
  hideBorder: false,
};

const Submit = ({ children }) => (
  <div className="form-panel__submit">{children}</div>
);

Submit.propTypes = propTypes;

const Header = ({ children, title }) => (
  <div className="form-panel__header">
    <div className="form-panel__header__title tc-heading-s">{title}</div>
    {children}
  </div>
);

Header.propTypes = {
  children: oneOf([node, arrayOf(node)]),
  title: string.isRequired,
};

Header.defaultProps = {
  children: null,
};

const SpinnerComponent = () => <div className="form-panel__spinner"><Spinner /></div>;

const FormPanel = ({ children, size, height }) => (
  <div className={classnames(
    'form-panel', 
    `form-panel--size-${size}`, 
    `form-panel--height-${height}`,
  )}
  >
    <div className="form-panel__wrapper">
      {children}
    </div>
  </div>
);

FormPanel.propTypes = {
  ...propTypes,
  size: oneOf(['full', 'wide', 'regular', 'small']),
  height: oneOf(['full', 'page', 'none']),
};
FormPanel.defaultProps = {
  ...defaultProps,
  size: 'regular',
  height: 'page',
};

FormPanel.Section = Section;
FormPanel.Submit = Submit;
FormPanel.Spinner = SpinnerComponent;
FormPanel.Header = Header;

export default FormPanel;
