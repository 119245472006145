import React, { Component, Fragment } from 'react';
import { object, func, bool } from 'prop-types';

import { bindActionCreators } from 'redux';
import connect from '../../../../../logic/connect';
import GridFactory from '../../../../../components/grid';
import modifier from './dmmModifier';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { paymentConfig } from '../../../user-single/react/Sections/Transactions/table';
import { TransactionSingle } from '../../../../../components/common/sidepanels';
import overlayActions from '../../../../../components/overlay';
import { isRefundAndPayoutExpired } from './logic/utils';

const REDUCER_KEY = 'PENDING_WITHDRAWAL';
const TABLE_KEY = 'DEPOSITS_MATCHING_METHOD';

const tableConfig = id => ({
  reducerKey: REDUCER_KEY,
  apiUrl: `pending_withdrawals/${id}/deposits_matching_method`,
  tableKey: TABLE_KEY,
});

const propTypes = {
  withdrawal: object.isRequired,
  table: object.isRequired,
  actions: object.isRequired,
  onDepositSelected: func.isRequired,
  dispatch: func.isRequired,
  gateway: object.isRequired,
  isDisabled: bool,
};

const defaultProps = {
  isDisabled: false,
};

const ENV = window.config.environment;

const getRowClass = ({ data }) => ((isRefundAndPayoutExpired(data)) ? 'biq-grid__row--disabled' : '');


class DepositsMatchingMethod extends Component {
  constructor({ withdrawal }) {
    super();
    this.isAvaTrade = ENV === 'AvaTrade';
    const { id } = withdrawal;
    this.Grid = GridFactory(tableConfig(id));
    this.paymentActions = {
      openTransaction: this.openSingleTransaction.bind(this),
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { actions } = this.Grid;
    dispatch(actions.fetchOptions(modifier(this.paymentActions)));
  }

  shouldComponentUpdate(nextProps) {
    const prevValues = _.get(this.props, 'form.values', {});
    const nextValues = _.get(nextProps, 'form.values', {});
    const { isDisabled } = this.props;

    const isGatewayChanged = (!_.isEqual(prevValues.gateway, nextValues.gateway) && nextValues.gateway);
    const isMethodChanged = (!_.isEqual(prevValues.method, nextValues.method) && nextValues.method);

    return isGatewayChanged || isMethodChanged || isDisabled !== nextProps.isDisabled;
  }

  componentDidUpdate(prevProps) {
    const prevValues = _.get(prevProps, 'form.values');
    const values = _.get(this, 'props.form.values');
    const { gateway } = this.props;

    const { withdrawal, dispatch } = this.props;
    const { actions } = this.Grid;
    const methodId = values.method && values.method.value;

    if ((prevValues.gateway && values.gateway) || (prevValues.method && values.method)) {
      dispatch(actions.clearData({ data: [] }));
    }

    const query = {
      user: withdrawal.user.id,
      method: methodId,
      ...(values.gateway && { gateway: values.gateway.value }),
      currency: withdrawal.currency.symbol,
    };

    if (gateway.hasDepositMatchingMethod()) {
      this.updateTable(query);
    }
      
    if (gateway.hasDepositMatchingMethod()) {
      dispatch(actions.updateFilter(query));
    } else {
      dispatch(actions.clearData({ data: [] }));
    }
  }

  getCardNumber(meta) {
    const cards = ['CcLastThreeDigits', 'CreditCardNumber', 'DebitCardNumber'].map(key => _.find(meta, { key }));
    const matchingCard = _.find(cards, card => !!card);

    if (matchingCard && matchingCard.value) {
      return matchingCard.value.startsWith('*') ? matchingCard.value.substring(1) : matchingCard.value;
    }

    return '';
  }

  updateTable = (query) => {
    const { dispatch } = this.props;
    const { actions } = this.Grid;
    dispatch(actions.fetchTableData(query));
  }

  async openSingleTransaction(data) {
    const { actions, withdrawal } = this.props;
    const response = await getDjangoApi(paymentConfig(withdrawal.user.id).apiUrl).retrieve(data.id);
    actions.openSidepanel('TRANSACTION_SINGLE', response.data);
  }

  render() {
    const { onDepositSelected } = this.props;
    const { GridComponent } = this.Grid;
    return (
      <Fragment>
        <GridComponent
          checkboxSelection
          info
          onRowSelected={onDepositSelected}
          height="350px"
          preventInternalOptions
          tableModifier={modifier(this.paymentActions)}
          useCursorPagination
          hasManageOption={false}
          getRowClass={getRowClass}
          disableQuickFilter
          {...this.props}
        />
        <TransactionSingle />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ pages, forms }) => {
  const page = pages[REDUCER_KEY];
  const table = page.tables[TABLE_KEY];
  return { 
    table, 
    form: forms.EXECUTE_WITHDRAWAL,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

DepositsMatchingMethod.propTypes = propTypes;
DepositsMatchingMethod.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(DepositsMatchingMethod);
