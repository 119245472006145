import React, { Component } from 'react';
import { DashboardCard, DashboardNumeric } from 'tc-biq-design-system';

import { gettext } from '../../../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../../../logic/services/api-factory';
import withFilters from '../../hoc/withFilters';

const size = {
  w: 2,
  h: 5,
  minW: 2,
  minH: 5,
  maxW: 3,
  maxH: 5,
  y: 0,
  x: 0,
};

const text = {
  TITLE: gettext('Active traders'),
  ACTIVE_TRADERS: gettext('Active traders'),
  DESCRIPTION:
  <div>
    <div>{gettext('Active Traders')}</div>
  </div>,
};

const activeTraders = getDjangoApi('dashboard/active_traders');

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTraders: 0,
      loading: false,
    };
  }

  onQueryReady = async (query) => {
    this.setState({ loading: true });
    const { data } = await activeTraders.list(query);

    this.setState({ activeTraders: data.active_traders, loading: false });
  }

  render() {
    const { activeTraders, loading } = this.state;

    return (
      <DashboardCard title={text.TITLE} description={text.DESCRIPTION} loading={loading}>
        <DashboardNumeric>
          <DashboardNumeric.Price>{activeTraders}</DashboardNumeric.Price>
        </DashboardNumeric>
      </DashboardCard>
    );
  }
}

export default {
  component: withFilters(Dashboard),
  size,
};
