import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionTypes from '../types';
import MaskPersonalDetails from './MaskPersonalDetails';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';

const text = {
  LABEL: gettext('Mask personal details'),
};

const ACTION_ICON = 'ViewDisabled';

export default {
  name: 'maskPersonalDetails',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.DROPDOWN,
  hasPermission(state, _user, isIb) {
    if (isIb) return false;
    const hasStatePermission = state >= USER_STATE.PENDING;
    return hasAccess('user.mask_personal_data.*')
    && hasStatePermission
    && isFeatureEnabled()('MASK_PERSONAL_DETAILS');
  },
  handler: (sidepanelManager, _dispatch, user, isIb, routerState) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
      routerState,
      isNew: true,
      key: 'mask-personal-details',
    };

    sidepanelManager.add(MaskPersonalDetails, sidepanelOptions);
  },
};
