import { object } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import appRoutes from '../../../../components/App/Router/appRoutes';
import { UserPreview } from '../../../../components/common/sidepanels';
import Export from '../../../../components/Export';
import withTable, { GridTab } from '../../../../components/grid/withTable';
import If from '../../../../components/If';
import overlayActions from '../../../../components/overlay';
import Page from '../../../../components/Page';
import { hasAccess } from '../../../../logic/services/acl';
import { gettext } from '../../../../logic/utilities/languageUtility';
import modifiers from './modifiers';

const tableConfig = () => ({
  reducerKey: 'PAYMENT_BONUSES',
  apiUrl: 'bonuses',
  tableKey: 'PAYMENT_BONUSES_TABLE',
});

const text = {
  TITLE: gettext('Bonus'),
};

const bread = [{ label: text.TITLE, route: appRoutes.PAYMENT_BONUSES }];

const propTypes = {
  actions: object.isRequired,
  exportUrl: object.isRequired,
};

class Bonuses extends Component {
  constructor(props) {
    super(props);
    this.Grid = withTable(GridTab, tableConfig)();
    this.modifiers = modifiers(props.actions.openSidepanel.bind(this, 'USER_PREVIEW'));
  }

  headerActions = () => {
    const { exportUrl } = this.props;
    return (
      <If condition={hasAccess('audit_logs.export.*')}>
        <Export url={exportUrl} />
      </If>
    );
  }

  render() {
    const { Grid } = this;
    
    return (
      <Page title={text.TITLE} bread={bread} headerActions={this.headerActions}>
        <Grid modifier={this.modifiers} />;
        <UserPreview />
      </Page>
    );
  }
}

Bonuses.propTypes = propTypes;

const mapStateToProps = ({ pages }) => { 
  const { reducerKey, tableKey } = tableConfig();
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    exportUrl: table.exportUrl,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bonuses);
