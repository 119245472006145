import React, { Component } from 'react';
import { string, object } from 'prop-types';
import { Icon, Code } from 'tc-biq-design-system';

import BiqAccordion from '../../../../../../components/BiqAccordion';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

const normalizeTitle = title => [title]
  .map(s => s.replace(/_/g, ' '))
  .map(s => (s.substring(0, 1).toUpperCase() + s.substring(1)))[0];

const states = {
  clear: {
    icon: 'Approve',
    color: 'text-status01-400',
  },
  consider: {
    icon: 'Warning',
    color: 'text-status03-400',
  },
  unidentified: {
    icon: 'Reject',
    color: 'text-status04-400',
  },
};

// eslint-disable-next-line
const CheckCategory = ({ result, category }) => {
  const state = states[result] || states.consider;

  return (
    <li className="m-b">
      <span className="biq-onfido-reports__breakdown-icon">
        <Icon name={state.icon} colorName={state.color} />
      </span>
      <span className="biq-onfido-reports__breakdown-title tc-paragraph-regular text-neutral-900">{normalizeTitle(category)}</span>
    </li>
  );
};

const propTypes = {
  name: string.isRequired,
  check: object.isRequired,
};

const showFullReportStyle = {
  cursor: 'pointer',
};

const text = {
  SHOW_FULL_REPORT: gettext('Show full report'),
  HIDE_FULL_REPORT: gettext('Hide full report'),
};

class ReportAccordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFullReport: false,
    };
  }

  toggleReport = () => {
    this.setState(({ showFullReport }) => ({ showFullReport: !showFullReport }));
  }

  render() {
    const { showFullReport } = this.state;
    const { name, check } = this.props;
    const { result, breakdown } = check;
    const state = states[result] || states.consider;

    const Title = (
      <span>
        <span className="biq-onfido-reports__breakdown-icon">
          <Icon name={state.icon} colorName={state.color} />
        </span>
        <span className="text-neutral-900 biq-onfido-reports__breakdown-title">{normalizeTitle(name)}</span>
      </span>
    );

    return (
      <BiqAccordion title={Title} iconRight>
        <ul className="biq-onfido-reports__breakdown-results">
          {_.map(breakdown, (value, key) => (
            <CheckCategory key={key} result={value.result} category={key} />
          ))}
        </ul>

        <div className="biq-onfido-reports__full-response m-b">
          {showFullReport && <span className="text-primary-400" style={showFullReportStyle} onClick={this.toggleReport}>{text.HIDE_FULL_REPORT}</span>}
          {!showFullReport && <span className="text-primary-400" style={showFullReportStyle} onClick={this.toggleReport}>{text.SHOW_FULL_REPORT}</span>}
        </div>
        {showFullReport && <Code data={this.props} />}
      </BiqAccordion>
    );
  }
}

ReportAccordion.propTypes = propTypes;
export default ReportAccordion;
