import { gettext } from '../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../logic/services/api-factory';

export const sidepanelName = 'EDIT_COMPLIANCE_STATUSES';
export const text = {
  TITLE: gettext('Edit Compliance Statuses'),
};
export const autocompleteApi = getDjangoApi('autocomplete/compliance_status');
export const bulkApi = getDjangoApi('compliance_status/bulk_set');
export const getPayload = fieldData => user => ({
  user: user.id,
  compliance_status: fieldData ? fieldData.name : null,
});
export const getError = (errors) => {
  const hasError = errors !== null;
  const helpText = hasError ? errors[0].compliance_status[0] : '';

  return {
    hasError,
    helpText,
  };
};
