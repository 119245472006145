import React from 'react';
import { bindActionCreators } from 'redux';
import { object, bool, number } from 'prop-types';
import { notifier, Space, Sidepanel } from 'tc-biq-design-system';

import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import { approveUser } from './Model';

const text = {
  TITLE: gettext('Approve user'),
  QUESTION: gettext('Are you sure you want to approve this user?'),
  SUCCESS: gettext('User successfully approved.'),
  ERROR: gettext('Error approving user.'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  submitInProgress: bool.isRequired,
  toState: number,
};

const defaultProps = {
  user: null,
  actions: null,
  toState: -1,
};

const ApproveTo = ({ sidepanelManager, user, actions, submitInProgress, toState, ...options }) => {
  const onClose = () => {
    sidepanelManager.close();
  };

  const onSubmit = () => {
    actions.approveUser(user, toState).then(() => {
      actions.fetchUser(user, false);
      notifier.success(text.SUCCESS);
      sidepanelManager.close();
    }).catch(() => {
      notifier.error(text.ERROR);
    });
  };

  return (
    <Sidepanel {...options} title={text.TITLE} footerRender={customFooter(onSubmit, onClose, submitInProgress)}>
      <Space size={16} />
      <div>{text.QUESTION}</div>
    </Sidepanel>
  );
};

ApproveTo.propTypes = propTypes;
ApproveTo.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      approveUser,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const action = page.actions.APPROVE_USER;
  return {
    submitInProgress: action.submitInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveTo);
