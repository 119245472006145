import React, { PureComponent, Fragment } from 'react';
import { Pagination } from 'tc-biq-design-system';
import { func, number } from 'prop-types';

import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import { getDefaultPageSize } from '../../../../../../components/grid/GridUtils';

const propTypes = {
  total: number,
  onUpdate: func.isRequired,
};

const defaultProps = {
  total: 0,
};

class WithPagination extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        limit: getDefaultPageSize(),
        offset: 0,
      },
      current: 1,
    };
  }

  componentDidMount() {
    const { query } = this.state;
    const { onUpdate } = this.props;
    onUpdate(query);
  }

  changePage = (pageValues) => {
    const { query } = this.state;
    const { onUpdate } = this.props;
    const updatedQuery = {
      ...query,
      offset: (pageValues - 1) * query.limit, 
    };
    this.setState({ query: updatedQuery, current: pageValues }, () => onUpdate(query));
  };

  changePageSize = (limit) => {
    const { query } = this.state;
    const { onUpdate } = this.props;
    this.setState({ query: { ...query, limit } }, () => onUpdate(query));
  }

  render() {
    const { children, total } = this.props; // eslint-disable-line
    const { query, current } = this.state;
    return (
      <Fragment>
        {children}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Pagination
            {...Pagination.defaultProps}
            pageSize={query.limit}
            onChange={this.changePage}
            onPageSizeChange={this.changePageSize}
            current={current}
            total={total}
          />
        </div>
      </Fragment>
    );
  }
}

WithPagination.propTypes = propTypes;
WithPagination.defaultProps = defaultProps;

export default withErrorBoundary(WithPagination);
