import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { Sidepanel, Space, notifier } from 'tc-biq-design-system';
import { object, array, bool } from 'prop-types';

import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { SidepanelFooter } from '../../../../../../../components/common';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import to from '../../../../../../../logic/utilities/to';
import FormFactory, { FormActionsFactory } from '../../../../../../../components/form';
import connect from '../../../../../../../logic/connect';
import getFieldModifier, { shouldToggleVisibility } from '../modifiers';
import { fetchUser } from '../../../Model';
import hiddenMask from '../../../../../../../logic/utilities/formatters/hiddenMask';

const FORM_KEY = 'EDIT_USER_FORM';

const { update, setFieldValue } = FormActionsFactory(FORM_KEY);

const text = {
  SUCCESS: gettext('User successfully edited'),
  ERROR_GENERAL: gettext('Something went wrong!'),
  BUTTON_LABELS: {
    confirm: gettext('Save'),
    cancel: gettext('Cancel'),
  },
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_KEY}
  />
);

const propTypes = {
  user: object.isRequired,
  isIb: bool.isRequired,
  fields: array.isRequired,
  submitInProgress: bool.isRequired,
  actions: object.isRequired,
  formFields: array,
  sidepanelManager: object.isRequired,
};

const defaultProps = {
  formFields: [],
};

class EditDetailsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPhoneVisible: false,
    };

    this.userApi = (user, isIb) => getDjangoApi(`${isIb ? 'ib' : 'users'}/${user.id}`);
  }

  componentDidUpdate(prevProps) {
    const prevFormFields = prevProps.formFields;
    const { formFields } = this.props;
    if (prevFormFields !== formFields && _.isEmpty(prevFormFields)) {
      this.setDefaultValues();
    }
  }

  onSubmit = async () => {
    const { actions, user, isIb } = this.props;
    const getRequestPayload = (d, editedFields) => {
      const prepareData = fields => _.reduce(fields, (acc, value, key) => ({
        ...acc,
        [key]: value && value.display_name ? value.display_name : value,
      }), {});
      const updatedUser = { ...user, ...prepareData(editedFields) };
      const pullFieldValue = (values, field, key) => {
        let value = field;
        const fieldModifier = getFieldModifier(key);
        if (!fieldModifier.isVisible(updatedUser, isIb)) return { ...values };

        if (typeof field === 'object') {
          value = field.value ? field.value : field.iso_code;
        }

        const newValues = _.set(values, key, value);

        return { ...newValues };
      };

      const payload = _.reduce(editedFields, pullFieldValue, {});
      return payload;
    };
    
    const [err] = await to(actions.update(this.userApi(user, isIb), '', getRequestPayload, true));
    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess = () => {
    const { actions, user, isIb } = this.props;
    notifier.success(text.SUCCESS);
    actions.fetchUser(user, isIb);
    this.onClose();
  }

  onError = (payload) => {
    const nonFieldErrors = _.get(payload, 'data.non_field_errors'); 
    if (nonFieldErrors) notifier.error(nonFieldErrors.map(err => <span>{err}</span>));
    const errorData = _.get(payload, 'data'); 
    if (!errorData) notifier.error(text.ERROR_GENERAL);
  }

  onClose = () => {
    const { sidepanelManager } = this.props;
    sidepanelManager.close();
  }

  setDefaultValues = () => {
    const { fields, formFields, actions, user } = this.props;
  
    formFields.forEach((field) => {
      if (!fields.includes(field.id)) return;

      const primaryValue = _.get(user, field.id);
      let value = primaryValue;

      if (field.type === 'select' && field.options) {
        value = field.options.find(option => option.display_name === primaryValue);
      }


      if (['phone', 'telephone', 'other_phone', 'other_phone2'].includes(field.id)) {
        actions.setFieldValue({
          id: field.id,
          value: shouldToggleVisibility() ? hiddenMask(value) : value,
        });
      } else if (value !== '' && value !== null) {
        actions.setFieldValue({
          id: field.id,
          value,
        });
      }
    });
  }

  getFormModifiers = () => {
    const { fields } = this.props;
    const { isPhoneVisible } = this.state;

    return fields.reduce((acc, key) => {
      const modifier = getFieldModifier(key);
      const label = modifier.displayName(key);
      const actionComponent = modifier.actionComponent(key, { toggleVisibility: this.toggleVisibility, isPhoneVisible, field: key });
      return {
        ...acc,
        [key]: { label, placeholder: label, actionComponent },
      };
    }, {});
  }

  getFormConfig = () => {
    const { user, fields, isIb } = this.props;
    return {
      form: FORM_KEY,
      api: this.userApi(user, isIb),
      includeFields: fields,
    };
  }

  getPhoneNumbers = async () => {
    const { user } = this.props;
    const phoneNumberApi = getDjangoApi(`users/${user.id}/phone_numbers`);
    return phoneNumberApi.list();
  };

  toggleVisibility = async () => {
    const { actions } = this.props;

    const { data } = await this.getPhoneNumbers();
    this.setState(prevState => ({ isPhoneVisible: !prevState.isPhoneVisible }), () => {
      const { isPhoneVisible } = this.state;
      Object.keys(data).forEach((key) => {
        actions.setFieldValue({
          id: key,
          value: isPhoneVisible ? data[key] : hiddenMask(data[key]),
        });
      });
    });
  };

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.getFormConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const { user, fields, sidepanelManager, submitInProgress, ...options } = this.props;
    const Form = this.renderForm();
    const formModifiers = this.getFormModifiers();
    return (
      <Sidepanel
        {...options}
        footerRender={customFooter(this.onSubmit, this.onClose, submitInProgress)}
      >
        <Space size={16} />
        <Form formId={FORM_KEY} modifiers={formModifiers} />
        <Space size={16} />
      </Sidepanel>
    );
  }
} 

EditDetailsForm.propTypes = propTypes;
EditDetailsForm.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      update,
      setFieldValue,
      fetchUser,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ forms }) => {
  const form = forms[FORM_KEY];
  return {
    formFields: form.fields,
    submitInProgress: form.submitInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDetailsForm);
