import apiDataType from '../../logic/enums/api-data-type';

const {
  INTEGER,
  FLOAT,
  DECIMAL,
  FIELD,
  CHOICE,
  NESTED_OBJECT,
  NESTED_RELATION,
  NESTED_LIST,
  DATE,
  DATETIME,
  BOOLEAN,
} = apiDataType;

export const inputTypes = {
  NUMBER: 'number',
  TEXT: 'text',
  SELECT: 'select',
  CALENDAR: 'calendar',
  BOOLEAN: 'boolean',
};

const isText = field => !(
  _.has(field, 'choices') 
  || _.has(field, 'child.choices')
) && !(_.has(field, 'list_url')
  || _.has(field, 'child.list_url'));

export function fieldInputResolver(field) {
  switch (field.type) {
    case INTEGER:
    case FLOAT:
    case DECIMAL:
      return inputTypes.NUMBER;
    case DATE:
    case DATETIME:
      return inputTypes.CALENDAR;
    case NESTED_LIST:
    case CHOICE:
    case NESTED_OBJECT:
    case NESTED_RELATION:
    case FIELD:
      return isText(field) ? inputTypes.TEXT : inputTypes.SELECT;
    case BOOLEAN:
      return inputTypes.BOOLEAN;
    default:
      return inputTypes.TEXT;
  }
}
