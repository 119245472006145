import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { object, bool } from 'prop-types';

import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import AssignManager from '../../../../../../../components/AssignManager';

const ENV = window.config.environment;

const MANAGER_PROPERTY = ENV === 'AvaTrade' ? 'sales_manager' : 'assigned_to';

const propTypes = {
  user: object,
  isIb: bool,
  actions: object.isRequired,
};

const defaultProps = {
  user: null,
  isIb: false,
};

const text = {
  SALES_MANGER: gettext('Sales Manager'),
  GROUP_USERS: gettext('Users'),
  GROUP_TEAMS: gettext('Teams'),
  GROUP_DEFAULT: gettext('Default'),
  NONE: gettext('None'),
  SUCCESS_MESSAGE: gettext('Sales manager successfully updated'),
};

class SalesManagerController extends Component {
  onSuccess = () => {
    const { user, isIb, actions } = this.props;
    actions.fetchUser(user, isIb);
  }

  render() {
    return (
      <AssignManager
        {...this.props} 
        text={text} 
        managerProperty={MANAGER_PROPERTY} 
        onSuccess={this.onSuccess} 
      />
    );
  }
}

SalesManagerController.propTypes = propTypes;
SalesManagerController.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const { user } = page.userDetail;

  return {
    user,
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SalesManagerController);
