export function formatNetDeposits(netDeposits, currencyFormatter) {
  if (!netDeposits) return null;
  const { currency, net_deposits } = netDeposits;
  return currencyFormatter(net_deposits, currency);
}

export function chartFormatterMonth(data, key) {
  if (!data) return [];
  const formattedData = data[key].map(record => ({
    month: moment(record.x, 'YYYY-MM-DD').format('MMM YYYY'),
    [key]: record.y,
  }));
  const months = _.uniqBy(formattedData, 'month').map(item => item.month);
  const results = [];
  months.forEach((month) => {
    const test = formattedData
      .filter(item => item.month === month)
      .reduce((previousValue, currentvalue) => ({
        month,
        [key]: previousValue[key] + currentvalue[key],
      }));

    results.push(test);
  });
  return results;
}

export function clientsChartFormatter(data) {
  if (!data) return [];
  const users = [...data];
  users.all_users = data.all_users.sort(
    (userCurrent, userNext) => moment(userCurrent.x, 'YYYY-MM-DD') - moment(userNext.x, 'YYYY-MM-DD'),
  );

  return users.all_users.map((user) => {
    const temp = {
      day: user.x,
      all_users: user.y,
    };
    data.full_users.forEach((fullUser) => {
      if (user.x === fullUser.x) {
        temp.full_users = fullUser.y;
      }
    });
    return temp;
  });
}

const hexToRGB = (hex, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const pieChartFormater = (data, color) => {
  if (_.isEmpty(data)) return [];
  const keys = Object.keys(data);
  const colorStep = 1 / keys.length;
  return keys.map((key, index) => ({
    key,
    color: hexToRGB(color, (index + 1) * colorStep),
    value: data[key],
  }));
};

export const getValues = widgets => widgets.map(({ key }) => ({ key, visible: false }));

export const getSize = (size, axis) => [...Array(size)].map((p, i) => i + axis);

export const mapValues = (values, layout) => (_.isEmpty(layout) ? values : values.map(value => ({ ...value, visible: !!layout.find(l => l.i === value.key) })));

export const parseLayout = (values, layout, hashedWidgets) => values.reduce((acc, value) => {
  if (!value.visible) return acc;

  const exist = !_.isEmpty(layout) && layout.find(l => l.i === value.key);

  if (!exist) {
    const widget = hashedWidgets[value.key];
    return { ...acc, newWidgets: [...acc.newWidgets, widget] };
  }

  return { ...acc, cleanLayout: [...acc.cleanLayout, exist] };
}, { cleanLayout: [], newWidgets: [] });


export const buildMatrix = layout => layout.reduce((acc, { x, y, w, h }) => {
  const xSize = getSize(w, x);
  const ySize = getSize(h, y);

  xSize.forEach(((xValue) => {
    const oldYValues = acc[xValue];
    acc[xValue] = oldYValues ? [...oldYValues, ...ySize] : [...ySize];
  }));
  
  return acc;
}, {});
