import React, { Component } from 'react';
import { Sidepanel, Space, notifier } from 'tc-biq-design-system';
import { func, bool, object } from 'prop-types';

import { gettext } from '../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import { ClientsList, SidepanelFooter } from '../../../../../components/common';

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
  fetchTableData: func.isRequired,
  submitInProgress: bool,
  submitSuccess: bool,
  isIb: bool,
};

const defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  isIb: false,
};

const text = {
  TITLE: gettext('Archive Clients'),
  SUBMIT_SUCCESS: gettext('Clients archived'),
  SELECTED_USERS: gettext('Selected users'),
  BUTTON_LABELS: {
    confirm: gettext('Archive'),
    cancel: gettext('Discard changes'),
  },
};

const formatRequestData = data => data.map(user => ({
  user: user.id,
}));

const customFooter = (execute, close, submitInProgess) => (
  <SidepanelFooter 
    submitInProgress={submitInProgess}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="destructive"
    buttonLabels={text.BUTTON_LABELS}
  />
);

class ClientsArchive extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.apiArchiveBulk = getDjangoApi('users/bulk_archive');
    this.submit = this.submit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidUpdate() {
    const { submitSuccess, fetchTableData } = this.props;
    if (submitSuccess) {
      notifier.success(text.SUBMIT_SUCCESS);
      fetchTableData();
      this.onClose();
    }
  }

  onClose() {
    this.actions.closeSidepanel('ARCHIVE_CLIENTS');
    this.actions.resetState();
  }

  submit() {
    const { sidepanel: { parameters } } = this.props;
    const { submit } = this.actions;
    const requestData = formatRequestData(parameters);
    
    submit(this.apiArchiveBulk, requestData);
  }

  render() {
    const { sidepanel, submitInProgress, isIb } = this.props;
    const { visible, parameters } = sidepanel;

    return (
      <Sidepanel
        icon="Delete"
        type="info"
        title={text.TITLE}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.submit, this.onClose, submitInProgress)}
      >
        <Space size={16} />
        {visible && !_.isEmpty(parameters) ? (
          <h4>{`${text.SELECTED_USERS} (${parameters.length})`}</h4>
        ) : null}
        {visible && !_.isEmpty(parameters) ? (
          <ClientsList clients={parameters} hideUserState isIb={isIb} />
        ) : null}
      </Sidepanel>
    );
  }
}

ClientsArchive.defaultProps = defaultProps;
ClientsArchive.propTypes = propTypes;

export default ClientsArchive;
