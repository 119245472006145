import Documents from './Documents';
import { tableConfig } from './table';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import withTable from '../../../../../../components/grid/withTable';

export default {
  Component: withTable(Documents, tableConfig, null, { hideSegments: true }),
  label: gettext('Documents'),
  id: 'documents',
  visibilityFn: (hasAccess, _isFeatureEnabled, isIb) => hasAccess('user.uploaded_documents.list') && !isIb,
};
