import appRoutes from '../../../../../../../components/App/Router/appRoutes';
import UpdateStateRule from './UpdateStateRule';

export default {
  component: UpdateStateRule,
  aclId: 'deposit_state_rules.update',
  path: appRoutes.SETTINGS_STATE_RULES,
};

export const StateRuleCreate = {
  component: UpdateStateRule,
  aclId: 'deposit_state_rules.create',
  path: appRoutes.SETTINGS_STATE_RULES_CREATE,
};
