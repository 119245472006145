import { hasAccess } from '../../../../../logic/services/acl';
import AutomaticFees from './AutomaticFees';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';
import appRoutes from '../../../../../components/App/Router/appRoutes';


export default {
  component: AutomaticFees,
  aclFn: () => hasAccess('settings.automatic_fees.list') && isFeatureEnabled()('AUTOMATIC_FEE'),
  path: appRoutes.SETTINGS_AUTOMATIC_FEES,
};
