import * as Sentry from '@sentry/react';
import session from '../logic/services/session';
import config from '../logic/constants/configuration';

const CONFIG = window.config;
if (CONFIG.sentry_enabled === 'True') {
  console.log('sentry active');
  Sentry.init({
    dsn: CONFIG.sentry_dsn,
    release: config.version,
    integrations: [new Sentry.Integrations.GlobalHandlers({
      onunhandledrejection: false,
    })],
    autoSessionTracking: CONFIG.sentry_tracking === 'True',
    beforeSend(event) {
      return {
        ...event,
        user: { ...event.user, id: _.get(session, 'user.id') },
      };
    },
  });
}
