import React, { Component, Fragment } from 'react';
import { object } from 'prop-types';
import { Table, Space } from 'tc-biq-design-system';

import connect from '../../../../../../logic/connect';
import getFieldModifier from '../../BasicInfo/UserInfoCard/modifiers';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

const env = window.config.environment;

const overviewFields = [
  'signup_data.marketing_relationship',
  'signup_data.client_list_generated',
  'signup_data.clients_list_origin',
  'signup_data.broker_relation_other',
  'signup_data.broker_names',
  'signup_data.broker_number_of_clients',
  'signup_data.broker_clients_monthly_average',
  'signup_data.spot_fx',
  'signup_data.commodity_futures',
  'signup_data.financial_futures',
  'signup_data.off_exchange_index',
  'signup_data.otc_equity_cfds',
  'signup_data.accounts_month_one',
  'signup_data.accounts_month_two',
  'signup_data.accounts_month_three',
  'signup_data.deposits_month_one',
  'signup_data.deposits_month_two',
  'signup_data.deposits_month_three',
  'signup_data.marketing_agent_role',
  'signup_data.unpaid_deficit_balance',
  'signup_data.under_investigation',
  'clickid',
  'first_visit.ip_address',
  'first_visit.geo_data.city',
  'first_visit.geo_data.country_name',
  'media_source',
];

if (env !== 'ICMCapital') {
  overviewFields.push(...[
    'company.company_name',
    'company.website',
    'company.country',
    'company.addr_city',
    'company.address',
    'company.addr_line_2',
    'company.target_region',
    'company.tax_id',
  ]);
}

const isIb = true;

const text = {
  TITLE: gettext('Register info'),
};

const propTypes = {
  user: object.isRequired,
};

class Overview extends Component {
  generateTableData = () => {
    const { user } = this.props;
    return overviewFields.reduce((acc, key) => {
      const modifier = getFieldModifier(key);
      const value = _.get(user, key);
      return modifier.isVisible(user, isIb, value) ? [...acc, {
        id: key,
        label: modifier.displayName(key),
        value: modifier.displayValue(value),
      }] : acc;
    }, []);
  }

  render() {
    const tableData = this.generateTableData();
    return (
      <Fragment>
        <Space size={10} />
        <Table
          striped
          cols={[
            { title: text.TITLE, key: 'label' },
            { title: '', key: 'value' },
          ]}
          data={tableData}
        />
      </Fragment>
    );
  }
}

Overview.propTypes = propTypes;

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  return {
    user: page.userDetail.user,
  };
};

export default connect(mapStateToProps)(Overview);
