import React from 'react';
import { string, number, func, any } from 'prop-types';
import { Input } from 'tc-biq-design-system';

const propTypes = {
  id: string.isRequired,
  onChange: func.isRequired,
  customOnChange: func,
  index: number,
  actionComponent: any,
};

const defaultProps = {
  customOnChange: null,
  index: null,
  actionComponent: null,
};

const TextField = (props) => {
  const { onChange, id, customOnChange, index, actionComponent } = props;
  
  return (
    <div className="biq-form__field">
      <Input 
        {...props}
        onChange={e => (customOnChange || onChange)({ value: e.target.value, id }, index)}
      />
      {actionComponent}
    </div>
  );
};

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;

export default TextField;
