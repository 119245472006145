import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Modal, notifier, Upload, Button } from 'tc-biq-design-system';
import { bool, object, func } from 'prop-types';
import { isUndefined } from 'lodash';

import connect from '../../../../../logic/connect';
import overlayActions from '../../../../../components/overlay';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import Uploader from '../../../../../logic/services/uploader';
import If from '../../../../../components/If';

const text = {
  TITLE: gettext('Import CSV'),
  FIRST_NAME: gettext('First name'),
  LAST_NAME: gettext('Last name'),
  LIMIT: gettext('The limit is 1500 rows'),
  NAME_OF_COLUMNS: gettext('Name of the columns should be: first_name, last_name, date_of_birth'),
  DATE_FORMAT: gettext('Format of the date should be: YYYY-MM-DD'),
  CANCEL: gettext('Cancel'),
  UPLOAD: gettext('Upload'),
  UPLOADING: gettext('Uploading'),
  UPLOAD_SUCCESS: gettext('CSV successfully uploaded'),
  FAILED_UPLOAD: gettext('Failed to upload CSV.'),
};

const uploadText = {
  initial: {
    title: gettext('Click here or drop csv file to upload'),
    subtitle: gettext('Drag and drop files or click to browse'),
  },
  loading: {
    title: gettext('Uploading file'),
    subtitle: gettext('This may take a second, please do not leave the page'),
  },
};

const propTypes = {
  visible: bool.isRequired,
  actions: object.isRequired,
  onSuccess: func.isRequired,
  blacklist: object,
};

const defaultProps = {
  blacklist: {},
};

const initialState = {
  uploaderStatus: 'initial',
  form: { file: null },
};

class ImportCsv extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.uploader = new Uploader('kyc/blacklist/import_');
    this.modalFooter = this.modalFooter.bind(this);
    this.upload = this.upload.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  onDrop(files) {
    const { form } = this.state;
    this.setState({
      form: { ...form, file: files[0] },
    });
  }

  onSuccess() {
    const { onSuccess } = this.props;
    notifier.success(text.UPLOAD_SUCCESS);
    this.close();
    onSuccess();
  }

  onError() {
    this.setState(initialState);
    notifier.error(text.FAILED_UPLOAD);
  }

  close() {
    const { actions } = this.props;
    actions.closeOverlay('KYC_BLACKLIST_IMPORT_CSV');
    this.setState(initialState);
  }

  async upload() {
    this.setState({ uploaderStatus: 'loading' });
    const { form } = this.state;
    const response = await this.uploader.upload(form);
    response.ok ? this.onSuccess() : this.onError(response);
  }

  modalFooter() {
    const { uploaderStatus } = this.state;
    const uploading = uploaderStatus === 'loading';
    const { UPLOAD, UPLOADING, CANCEL } = text;
    return (
      <div>
        <If condition={!uploading}>
          <Button onClick={() => this.close()} color="ghost">
            {CANCEL}
          </Button>
        </If>
        <Button
          icon="Upload"
          color="confirmation"
          onClick={this.upload}
          loading={uploading}
          disabled={uploading}
        >
          {uploading ? UPLOADING : UPLOAD}
        </Button>
      </div>
    );
  }

  render() {
    const { visible } = this.props;
    const { TITLE, LIMIT, NAME_OF_COLUMNS, DATE_FORMAT } = text;
    const { uploaderStatus, form } = this.state;
    const { file } = form;
    const hasFile = file && !isUndefined(file.name);
    const modalProps = {
      title: TITLE,
      icon: 'Upload',
      visible,
      footerRender: this.modalFooter,
    };

    return (
      <Modal {...modalProps}>
        <p className="tc-paragraph-regular">
          {LIMIT}
          <br />
          {NAME_OF_COLUMNS}
          <br />
          {DATE_FORMAT}
        </p>
        <If condition={!hasFile}>
          <Upload localization={uploadText} status={uploaderStatus} onDrop={this.onDrop} />
        </If>
        <If condition={hasFile}>
          <p className="tc-paragraph-regular">{file && file.name}</p>
        </If>
      </Modal>
    );
  }
}

const mapStateToProps = ({ overlays }) => ({
  visible: overlays.KYC_BLACKLIST_IMPORT_CSV.visible,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeOverlay: overlayActions.close,
    },
    dispatch,
  ),
});

ImportCsv.propTypes = propTypes;
ImportCsv.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImportCsv);
