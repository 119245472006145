import appRoutes from '../../../../../components/App/Router/appRoutes';
import DashboardLayoutPage from './DashboardLayout';

export const DashboardLayout = {
  component: DashboardLayoutPage,
  aclFn: () => true,
  path: appRoutes.SETTINGS_CUSTOMIZATION_DASHBOARD,
};

export const DashboardLayoutCreate = {
  component: DashboardLayoutPage,
  aclFn: () => true,
  path: appRoutes.SETTINGS_CUSTOMIZATION_DASHBOARD_CREATE,
};
