import React from 'react';
import { any } from 'prop-types';
import { Radio } from 'tc-biq-design-system';

const propTypes = {
  actionComponent: any,
};

const defaultProps = {
  actionComponent: null,
};

const RadioField = (props) => {
  const { actionComponent, ...rest } = props;
  return (
    <div className="biq-form__field">
      <Radio {...rest} />
      {actionComponent}
    </div>
  );
};

RadioField.propTypes = propTypes;
RadioField.defaultProps = defaultProps;
export default RadioField;
