import React, { Component } from 'react';
import { bool, func, object, arrayOf } from 'prop-types';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';

import SidepanelFooter from '../SidepanelFooter';
import FormFactory, { FormActionsFactory } from '../../../../../../components/form';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../logic/connect';
import withUnmountMethod from '../../../../../../components/hoc/withUnmountMethod';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';

const EDIT_ONFIDO_RISK = 'EDIT_ONFIDO_RISK';
const sidepanelID = EDIT_ONFIDO_RISK;
const formID = EDIT_ONFIDO_RISK;
const api = getDjangoApi('settings/risk_rating/kyc_result');

const formConfig = {
  form: formID,
  api,
  excludeFields: [
    'id',
    'gateway',
  ],
};

const { setFieldValue, update, create, setRowData, resetFields } = FormActionsFactory(formID);

const propTypes = {
  visible: bool.isRequired,
  closeSidePanel: func.isRequired,
  parameters: object,
  submitInProgess: bool,
  actions: object.isRequired,
  fields: arrayOf(object),
  values: object,
  fetchTableData: func.isRequired,
  dispatch: func.isRequired,
};

const defaultProps = {
  parameters: null,
  submitInProgess: false,
  fields: [],
  values: {},
};

const text = {
  TITLE: gettext('Onfido risk'),
  EDIT_SUCCESS_MSG: gettext('Risk rating edited successfully'),
  CREATE_SUCCESS_MSG: gettext('Risk rating added successfully'),
};

class EditOnfidoRiskSidepanel extends Component {
  constructor(props) {
    super(props);

    this.FormComponent = null;
  }
  

  componentDidUpdate() {
    const { actions: { setFieldValue }, parameters, fields, values } = this.props;
    if (parameters && !isEmpty(fields) && isEmpty(values)) {
      const { options } = fields.find(({ id }) => id === 'parameter');
      setFieldValue({ id: 'risk_value', value: parameters.risk_value });
      setFieldValue({ id: 'parameter',
        value: options.find(opt => opt.value === parameters.parameter) });
    }
  }

  onSave = async () => {
    const { 
      values,
      actions: { update },
      parameters: { id },
      fetchTableData,
      dispatch, 
    } = this.props;
     
    const promise = update(api, id, values);

    const [error] = await to(promise);
  
    if (!error) {
      notifier.success(text.EDIT_SUCCESS_MSG);
      this.closeSidePanel();
      dispatch(fetchTableData());
    }
  }

  onCreate = async () => {
    const { 
      values,
      actions: { create },
      fetchTableData,
      dispatch } = this.props;
    
    const promise = create(api, {
      ...values, 
      parameter: values.parameter.value });

    const [error] = await to(promise);
  
    if (!error) {
      notifier.success(text.CREATE_SUCCESS_MSG);
      this.closeSidePanel();
      dispatch(fetchTableData());
    }
  }

  closeSidePanel = () => {
    const { closeSidePanel } = this.props;
    closeSidePanel(sidepanelID);
  }

  renderForm = () => {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    } 
    const { FormComponent } = this;
    return <FormComponent />;
  }

  render() {
    const { visible, submitInProgess, parameters } = this.props;
    const { parameter } = parameters || {};
    const title = parameters ? `${text.TITLE} (${parameter})` : text.TITLE;

    return (
      <Sidepanel 
        title={title}
        icon="Edit"
        visible={visible}
        onCloseIconClick={this.closeSidePanel}
        footerRender={() => (
          <SidepanelFooter 
            onCancel={this.closeSidePanel}
            onSave={parameters ? this.onSave : this.onCreate}
            submitInProgess={submitInProgess}
          />
        )} 
      >
        { this.renderForm() }
      </Sidepanel>
    );
  }
}

EditOnfidoRiskSidepanel.propTypes = propTypes;
EditOnfidoRiskSidepanel.defaultProps = defaultProps;

const mapStateToProps = ({ overlays, forms }) => ({
  ...overlays[sidepanelID],
  ...forms[formID],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    update,
    setRowData,
    resetFields,
    create,
    setFieldValue,
  }, dispatch),
  dispatch,
});

export { sidepanelID, api };
export default
withUnmountMethod(connect(mapStateToProps, mapDispatchToProps)(EditOnfidoRiskSidepanel));
