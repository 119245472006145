import React, { Component } from 'react';
import { array, string } from 'prop-types';
import connect from '../../../../../../../logic/connect';

export default function withFilters(WrappedComponent, { removeDateFilters } = {}) {
  const propTypes = {
    startDate: string.isRequired,
    endDate: string.isRequired,
    selectedUsers: array.isRequired,
    selectedTeams: array.isRequired,
  };

  class FilterComponent extends Component {
    componentDidMount() {
      this.prepareQuery();
    }
  
    componentDidUpdate(prevProps) {
      const startDateChanged = this.isChanged(prevProps, 'startDate');
      const endDateChanged = this.isChanged(prevProps, 'endDate');
      const selectedUsersChanged = this.isChanged(prevProps, 'selectedUsers');
      const selectedTeamsChanged = this.isChanged(prevProps, 'selectedTeams');
      
      if (startDateChanged
        || endDateChanged
        || selectedTeamsChanged
        || selectedUsersChanged) this.onFilterChange();
    }
  
    onFilterChange = () => {
      this.prepareQuery();
    }
  
    prepareQuery = async () => {
      const { startDate, endDate, selectedUsers, selectedTeams } = this.props;
      let query = [];

      if (!removeDateFilters) {
        query = [{
          start_date: startDate,
          end_date: endDate,
        }];
      }
  
      if (selectedUsers) query = [...query, ...selectedUsers.map(({ id }) => ({ user: id }))];
      if (selectedTeams) query = [...query, ...selectedTeams.map(({ id }) => ({ team: id }))];

      this.wrappedComponent.onQueryReady(query);
    }

    // eslint-disable-next-line react/destructuring-assignment
    isChanged = (prevProps, key) => prevProps[key] !== this.props[key]

    render() {
      return (
        <WrappedComponent 
          ref={(instance) => { this.wrappedComponent = instance; }} 
          {...this.props} 
        />
      );
    }
  }

  FilterComponent.propTypes = propTypes;

  const mapStateToProps = ({ pages }) => {
    const { startDate, endDate, selectedUsers, selectedTeams } = pages.DASHBOARD_LAYOUT;
    return {
      startDate,
      endDate,
      selectedUsers,
      selectedTeams,
    };
  };

  return connect(mapStateToProps, null)(FilterComponent); 
}
