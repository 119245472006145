import React, { Component } from 'react';
import { Button } from 'tc-biq-design-system';
import { array, func, object } from 'prop-types';

const propTypes = {
  statuses: array,
  onSelect: func,
  selected: object,
};

const defaultProps = {
  statuses: [],
  onSelect: null,
  selected: {},
};

class SelectStatus extends Component {
  onClick = (status) => {
    const { onSelect } = this.props;
    
    onSelect(status);
  }

  render() {
    const { statuses, selected } = this.props;
    const selectedStatus = selected || {};
    
    return statuses.map(status => (
      <Button 
        color={selectedStatus.id === status.id ? 'primary' : 'ghost'} 
        key={status.id} 
        onClick={() => this.onClick(status)}
      >
        { status.name }
      </Button>
    ));
  }
}

SelectStatus.propTypes = propTypes;
SelectStatus.defaultProps = defaultProps;

export default SelectStatus;
