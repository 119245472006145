import React from 'react';
import { arrayOf, number, object } from 'prop-types';
import { Pill } from 'tc-biq-design-system';
import hideCols from '../../../../../../logic/utilities/hideCols';
import { ActionsCellWrapper } from '../../../../../../components/gridCellRenderers';
import { actionIcons } from '../../../../../../components/grid/GridUtils';

const gatewaysRenderer = ({ value, gatewayChoices }) => (
  <div>
    {
    value.map((id) => {
      const { display_name } = gatewayChoices.find(gc => gc.value === id);
      return (
        <Pill key={display_name}>{ display_name } </Pill>
      );
    })
    }
  </div>
);

gatewaysRenderer.propTypes = {
  value: arrayOf(number),
  gatewayChoices: arrayOf(object),
};

gatewaysRenderer.defaultProps = {
  value: [],
  gatewayChoices: [],
};

export default ({ modify, remove, gatewayChoices }) => {
  const actions = {
    ...(modify && { modify }),
    ...(remove && { remove }),
  };
  
  return {
    ...hideCols(['id']),
    gateways: {
      cellRendererFramework: ({ value }) => gatewaysRenderer({ value, gatewayChoices }),
    },
    actions: {
      label: '',
      field: 'actions',
      key: 'actions',
      pinned: 'right',
      width: actions.modify && actions.remove ? 100 : 50,
      hiddenSortIcon: true,
      suppressSizeToFit: true,
      cellRendererFramework: ActionsCellWrapper(actions, actionIcons(modify, remove)),
    },
  };
};
