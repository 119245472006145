import React from 'react';
import { string, shape, bool, func } from 'prop-types';
import { find } from 'lodash';
import { Checkbox } from 'tc-biq-design-system';
import connect from '../../../../logic/connect';

// Dynamic label and key
const valueShape = shape({
  key: string.isRequired,
  visible: bool.isRequired,
});

const propTypes = {
  label: string.isRequired,
  value: valueShape.isRequired,
  onChange: func.isRequired,
};

const style = {
  container: {
    marginBottom: '10px',
  },
  label: {
    marginLeft: '7px',
  },
};

const VisibilityCheckbox = ({ label, value, onChange }) => {
  const key = _.get(value, 'key');
  const visible = _.get(value, 'visible');
  
  return (
    <div style={style.container}>
      <Checkbox
        id={key}
        checked={visible}
        onChange={() => onChange(key)}
      >
        <span style={style.label}>{label}</span>
      </Checkbox>
    </div>
  );
};

VisibilityCheckbox.propTypes = propTypes;

const mapStateToProps = (modalId, key) => ({ overlays }) => {
  const { parameters } = overlays[modalId];
  const value = find(parameters.values, { key });
  return { value };
};

const VisibilityCheckboxFactory = ({ modalId, key }) => connect(
  mapStateToProps(modalId, key), 
)(VisibilityCheckbox);

export default VisibilityCheckboxFactory;
