import React from 'react';
import { MultiActions } from 'tc-biq-design-system';

const formatActions = (actions, rowData) => actions.map(({ action, data, label, icon }) => ({
  action,
  data: { ...rowData, ...data },
  label,
  icon,
  iconColorName: 'text-primary-1000',
}));

// eslint-disable-next-line
const MultiActionsCell = actions => ({ data }) => (
  <MultiActions actions={formatActions(actions(data), data)} />
);

export default MultiActionsCell;
