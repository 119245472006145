import React from 'react';
import { bindActionCreators } from 'redux';
import { object } from 'prop-types';

import { FormActionsFactory } from '../../../../../../../../components/form';
import connect from '../../../../../../../../logic/connect';
import SendNoteForm from '../../../../../../../../components/common/SendNoteForm/SendNoteForm';


const FORM_KEY = 'SEND_NOTE_COMMUNICATION_FLOW_FORM';

const { create, resetFields } = FormActionsFactory(FORM_KEY);

const propTypes = {
  actions: object.isRequired,
};

const SendNote = ({ actions, ...rest }) => <SendNoteForm {...rest} disableResetOnUnmount shouldResetValuesOnSuccess={false} showLabel createNote={actions.create} formKey={FORM_KEY} />;

SendNote.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      create,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages, forms }) => {
  const form = forms[FORM_KEY];
  const page = pages.USER_SINGLE;
  return {
    submitInProgress: form.submitInProgress,
    user: page.userDetail.user,
  };
};

export { resetFields as resetNotesForm };
export default connect(mapStateToProps, mapDispatchToProps)(SendNote);
