import React from 'react';
import { Tooltip, Icon, Popconfirm } from 'tc-biq-design-system';

import { ActionsCellWrapper, OnContentClickCell } from '../../../../../../components/gridCellRenderers';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import If from '../../../../../../components/If';

const text = {
  NO: gettext('No'),
  YES: gettext('Yes'),
  ACTIONS: gettext('Actions'),
  TITLE: gettext("You're about to Cancel a Bonus"),
};

const actionIcons = cancelBonus => ({
  ...(cancelBonus && {
    cancelBonus: (actionHandler, data) => (
      <If condition={data.state !== 'Cancelled'}>
        <div style={{ marginRight: '3em' }}>
          <Tooltip title={text.TITLE} key="cancel" placement="top">
            <Popconfirm
              type="destructive"
              icon="Delete"
              onConfirm={() => actionHandler(data)}
              label={gettext(`${gettext('Bonus ID')}:${data.id}, ${gettext('Amount')}:${data.amount}$`)}
              buttonLabels={{ cancel: text.NO, confirm: text.YES }}
            >
              <Icon name="Close" />
            </Popconfirm>
          </Tooltip>
        </div>
      </If>
    ),
  }),
});

export default ({ cancelBonus, openTradingAccount }) => {
  const action = {
    ...(cancelBonus && { cancelBonus }),
  };

  return {
    reason: {
      // eslint-disable-next-line react/prop-types
      cellRendererFramework: ({ value }) => <span>{value}</span>,
    },
    actions: {
      label: text.ACTIONS,
      key: 'actions',
      hiddenSortIcon: true,
      cellRendererFramework: ActionsCellWrapper(action, actionIcons(cancelBonus)),
      width: 120,
    },
    wallet: {
      key: 'wallet',
      width: 250,
      cellRendererFramework: OnContentClickCell(openTradingAccount),
    },
  };
};
