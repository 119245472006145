import React from 'react';

import { object, bool, array } from 'prop-types';
import { notifier, Space, Sidepanel } from 'tc-biq-design-system';

import { SidepanelFooter } from '../../../../../../components/common';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

const buttonText = {
  confirm: gettext('Confirm'),
  cancel: gettext('Cancel'),
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={buttonText}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  submitInProgress: bool.isRequired,
  isIb: bool,
  apiParams: array.isRequired,
  text: object.isRequired,
};

const defaultProps = {
  user: null,
  actions: null,
  isIb: false,
};

const Confirmation = ({ 
  sidepanelManager, 
  user, 
  isIb, 
  actions, 
  apiParams, 
  text, 
  submitInProgress, 
  ...options }) => {
  const onClose = () => {
    sidepanelManager.close();
  };

  const onSubmit = () => {
    actions.apiAction(...apiParams).then(() => {
      actions.fetchUser(user, isIb);
      notifier.success(text.SUCCESS);
      sidepanelManager.close();
    }).catch(() => {
      notifier.error(text.ERROR);
    });
  };

  return (
    <Sidepanel {...options} footerRender={customFooter(onSubmit, onClose, submitInProgress)}>
      <Space size={16} />
      <div>{text.QUESTION}</div>
    </Sidepanel>
  );
};

Confirmation.propTypes = propTypes;
Confirmation.defaultProps = defaultProps;

export default Confirmation;
