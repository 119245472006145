import { bindActionCreators } from 'redux';
import TeamsStructure from './TeamsStructure';
import connect from '../../../../logic/connect';

import { loadTeams } from './Model';

const mapStateToProps = ({ pages }) => ({
  ...pages.TEAMS.teamsStructure,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loadTeams,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsStructure);
