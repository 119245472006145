import React, { Component } from 'react';
import { Sidepanel, TextArea, Avatar, Space, notifier, HyperLink } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';
import { object, bool, array, string, shape } from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import connect from '../../../../../../logic/connect';
import overlayActions from '../../../../../../components/overlay';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import { SidepanelFooter } from '../../../../../../components/common';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import session from '../../../../../../logic/services/session';
import activityActions from '../../Model/activityActions';
import addCommentActions from './Model';
import { formatDateGroupLabel } from '../../ActivityBody/ActivityBody';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

import './AddComment.scss';

const SIDEPANEL_ID = 'ADD_COMMENT';

const propTypes = {
  actions: object.isRequired,
  visible: bool.isRequired,
  isLoading: bool.isRequired,
  inProgress: bool.isRequired,
  comments: array.isRequired,
  textValue: string.isRequired,
  parameters: object,
  errors: object.isRequired,
};

const defaultProps = {
  parameters: null,
};

const text = {
  ENTER_BODY: gettext('Add your comment...'),
  BUTTON_LABELS: {
    confirm: gettext('Leave a comment'),
  },
  ERROR: gettext('Failed to comment activity'),
  SUCCESS: gettext('Succesfully commented activity'),
  YOU: gettext('You'),
};

const customFooter = (execute, submitInProgess) => (
  <SidepanelFooter
    submitInProgress={submitInProgess}
    execute={execute}
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

const formatDate = (dateString) => {
  const formattedDate = moment(dateString).format('DD MM YYYY');
  const formattedTime = moment(dateString).format('HH:MM');
  return `${formatDateGroupLabel(formattedDate)}, at ${formattedTime}`;
};

const ActivityInfo = ({ activityData }) => {
  const { published, title } = activityData;
  const shortTitle = title.length > 40 ? `${title.slice(0, 40)} ...` : title;
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Avatar size="regular" />
      <Space size={8} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          justifyContent: 'center',
          width: '80%',
        }}
      >
        <div style={{ width: 'max-content' }} className="tc-paragraph-strong text-neutral-900">
          {shortTitle}
        </div>
        <div className="tc-paragraph-regular text-neutral-600">{formatDate(published)}</div>
      </div>
    </div>
  );
};

ActivityInfo.propTypes = {
  activityData: shape({
    published: string.isRequired,
    title: string.isRequired,
  }).isRequired,
};

const SingleComment = ({ comment }) => (
  <div className="biq-activity-stream-add-comments__comment">
    <div className="user-info">
      <Avatar />
      <Space size={8} />
      <div className="details">
        <HyperLink>
          <Link to={generatePath(appRoutes.TEAM_MEMBER, { id: comment.user })}>
            {session.user.id === comment.user ? text.YOU : comment.username}
          </Link>
        </HyperLink>
        <span className="tc-paragraph-regular text-neutral-600">
          {formatDate(comment.timestamp)}
        </span>
      </div>
    </div>
    <div className="comment-box">{comment.text}</div>
  </div>
);

SingleComment.propTypes = {
  comment: object.isRequired,
};

const Comments = ({ comments }) => (
  <div className="biq-activity-stream-add-comments">
    {comments.map(data => (
      <SingleComment key={data.timestamp} comment={data} />
    ))}
  </div>
);

Comments.propTypes = {
  comments: array.isRequired,
};

class AddComment extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.title = '';
  }

  componentDidUpdate(prevProps) {
    const { parameters, comments } = this.props;
    if (!parameters) return;
    const { activity } = parameters;
    if (
      !prevProps.parameters
      || activity.id !== prevProps.parameters.activity.id
      || prevProps.comments.length < comments.length
    ) {
      this.actions.loadComments(activity.id);
      this.title = <ActivityInfo activityData={activity} />;
    }
  }

  addComment = async () => {
    const { textValue } = this.props;
    const {
      parameters: { activity },
    } = this.props;
    const requestData = {
      activity: activity.id,
      user: session.user.id,
      text: textValue,
    };
    this.actions.addComment(requestData).then(
      () => {
        notifier.success(text.SUCCESS);
        this.actions.loadComments(activity.id);
      },
      () => {
        notifier.error(text.ERROR);
      },
    );
  };

  close = () => {
    this.actions.close(SIDEPANEL_ID);
    this.actions.loadActivity();
  };

  render() {
    const { visible, parameters, isLoading, inProgress, comments, textValue, errors } = this.props;
    if (!visible || !parameters) return null;
    return (
      <Sidepanel
        title={this.title}
        visible={visible}
        onCloseIconClick={this.close}
        footerRender={() => customFooter(this.addComment, inProgress)}
      >
        <Comments comments={comments} isLoading={isLoading} />
        <TextArea
          name="comment"
          value={textValue}
          onChange={e => this.actions.updateTextarea(e.target.value)}
          placeholder={text.ENTER_BODY}
          hasError={!_.isEmpty(errors)}
          helpText={errors.text && errors.text[0] ? errors.text[0] : ''}
        />
      </Sidepanel>
    );
  }
}

AddComment.defaultProps = defaultProps;
AddComment.propTypes = propTypes;

const mapStateToProps = ({ overlays, pages }) => {
  const { visible, parameters } = overlays[SIDEPANEL_ID];
  const {
    addComment: { isLoading, inProgress, textValue, comments, errors },
  } = pages.ACTIVITY_STREAM;
  return {
    visible,
    parameters,
    isLoading,
    inProgress,
    textValue,
    comments,
    errors,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      open: overlayActions.open,
      close: overlayActions.close,
      update: overlayActions.update,
      loadActivity: activityActions.loadActivity,
      updateTextarea: addCommentActions.updateTextarea,
      loadComments: addCommentActions.loadComments,
      addComment: addCommentActions.addComment,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(AddComment));
