import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { object, func } from 'prop-types';
import { Sidepanel, Input } from 'tc-biq-design-system';

import { gettext } from '../../../../../../logic/utilities/languageUtility';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import connect from '../../../../../../logic/connect';
import overlayActions from '../../../../../../components/overlay';
import { SidepanelFooter } from '../../../../../../components/common';

export const SIDEPANEL_ID = 'EDIT_LAYOUT_SECTION_NAME_SIDEPANEL';

const text = {
  NAME: gettext('Name'),
  TITLE: gettext('Edit section'),
  BUTTON_LABELS: {
    confirm: gettext('Save changes'),
    cancel: gettext('Discard changes'),
  },
};

const propTypes = {
  actions: object.isRequired,
  sidepanel: object.isRequired,
  onSuccess: func.isRequired,
};

const customFooter = (execute, close) => (
  <SidepanelFooter
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

class EditSectionName extends PureComponent {
  onSubmit = () => {
    const { onSuccess, sidepanel } = this.props;
    const { parameters: { sectionIndex, name } } = sidepanel;
    onSuccess && onSuccess(sectionIndex, name);
    this.onClose();
  }

  onClose = () => {
    const { actions } = this.props;
    actions.closeSidepanel(SIDEPANEL_ID);
  };

  onChange = (e) => {
    const name = e.target.value;
    const { actions, sidepanel: { parameters } } = this.props;
    actions.updateSidepanel(SIDEPANEL_ID, { ...parameters, name });
  }

  render() {
    const { sidepanel } = this.props;
    if (!sidepanel.visible) return null;
    const { parameters } = sidepanel;
    return (
      <Sidepanel
        icon="Pen"
        title={text.TITLE}
        visible={sidepanel.visible}
        onCloseIconClick={this.onClose}
        footerRender={() => customFooter(this.onSubmit, this.onClose)}
      >
        <Input 
          label={text.NAME} 
          value={parameters.name}
          onChange={this.onChange}
        />
      </Sidepanel>
    );
  }
}

EditSectionName.propTypes = propTypes;

const mapStateToProps = ({ overlays }) => {
  const sidepanel = overlays[SIDEPANEL_ID];
  return { sidepanel };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSidepanel: overlayActions.close,
      updateSidepanel: overlayActions.update,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(EditSectionName));
