/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Sidepanel, notifier } from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import connect from '../../../../logic/connect';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import FormFactory, { FormActionsFactory } from '../../../../components/form';
import overlayActions from '../../../../components/overlay';
import { gettext } from '../../../../logic/utilities/languageUtility';
import to from '../../../../logic/utilities/to';
import { SidepanelFooter } from '../../../../components/common';

const SIDEPANEL_ID = 'ADD_NEW_MEMBER';
const api = getDjangoApi('team');
const { create, resetFields } = FormActionsFactory(SIDEPANEL_ID);

const text = {
  BUTTON_LABELS: {
    confirm: gettext('Send invite'),
  },
  TITLE: gettext('Invite new BackOffice user'),
  INVITED_SUCCESS: gettext('Team member successfully invited'),
};

const customFooter = (execute, close, submitInProgress) => (
  <SidepanelFooter 
    execute={execute} 
    close={close} 
    submitInProgress={submitInProgress} 
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={SIDEPANEL_ID}
  />
);

const formConfig = {
  form: SIDEPANEL_ID,
  api,
  excludeFields: ['is_active', 'is_staff'],
};

class AddNewMember extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.close = this.close.bind(this);
  }
  
  componentWillUnmount() {
    this.close();
  }

  onSuccess() {
    const { onSuccessRequest } = this.props;
    onSuccessRequest();
    this.close();
    notifier.success(text.INVITED_SUCCESS);
  }

  getRequestPayload(values) {
    return _.reduce(values, (payload, field, key) => {
      if (key === 'object_acl_rules') return { ...payload, object_acl_rules: [field.value] };
      return {
        ...payload,
        [key]: typeof field === 'object' ? field.value : field,
      };
    }, {});
  }

  async submit() {
    const { actions } = this.props;
    const [err] = await to(actions.create(api, this.getRequestPayload));
    err ? this.showError(err) : this.onSuccess();
  }

  showError({ data }) {
    if (data.non_field_errors) {
      notifier.error(data.non_field_errors.map(err => <span>{err}</span>));
    }
  }

  close() {
    const { actions } = this.props;
    actions.closeOverlay(SIDEPANEL_ID);
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(formConfig);
    }
    const { FormComponent } = this;
    return <FormComponent formId={SIDEPANEL_ID} />;
  }

  render() {
    const { visible, submitInProgress } = this.props;
    return (
      <Sidepanel
        visible={visible}
        title={text.TITLE}
        icon="Client"
        onCloseIconClick={() => this.close()}
        footerRender={() => customFooter(this.submit, this.close, submitInProgress)}
      >
        {this.renderForm()}
      </Sidepanel>
    );
  }
}

const mapStateToProps = ({ overlays, forms }) => {
  const sidepanel = overlays[SIDEPANEL_ID];
  const { fields, submitInProgress } = forms[SIDEPANEL_ID];

  return {
    visible: sidepanel.visible,
    form: fields,
    submitInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeOverlay: overlayActions.close,
      create,
      resetFields,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddNewMember);
