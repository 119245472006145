import { bindActionCreators } from 'redux';

import SettingsForm, { FORM_ID } from './SettingsForm';
import { FormActionsFactory } from '../../../../../components/form';
import connect from '../../../../../logic/connect';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';

const { errorFields, create, update, resetFields, setRowData, setFieldValue } = FormActionsFactory(FORM_ID);

const mapStateToProps = ({ forms, pages }) => {
  const { fields, submitInProgress, values } = forms[FORM_ID];
  const { profile } = pages.TEAM_PROFILE;
  return { fields, values, submitInProgress, ...profile };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      errorFields,
      create,
      resetFields,
      setRowData,
      setFieldValue,
      update,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(SettingsForm));
