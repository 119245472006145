import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import USER_STATE from '../../../../../../../logic/enums/user-state';
import IB_STATE from '../../../../../../../logic/enums/ib-state';
import { hasAccess } from '../../../../../../../logic/services/acl';
import userActionTypes from '../types';
import Credit from './Credit';

const text = {
  LABEL: gettext('Credit'),
};

// @TODO missing archive icon
const ACTION_ICON = 'Deposit';

export default {
  name: 'manualDeposit',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.TRANSACTIONS,
  hasPermission(state, user, isIb) {
    if (isIb) {
      return !user.is_archived && hasAccess('ib.deposit.create') && state !== IB_STATE.INCOMPLETE;
    } 
    const hasStatePermission = [
      USER_STATE.FULL,
      USER_STATE.LIMITED,
      USER_STATE.REVIEW,
    ].includes(state);

    return hasAccess('user.deposit.create') && hasStatePermission;
  },
  handler: (sidepanelManager, _dispatch, user, isIb) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      type: 'info',
      title: text.LABEL,
      visible: true,
      isIb,
      user,
      sidepanelManager,
      key: 'credit',
    };

    sidepanelManager.add(Credit, sidepanelOptions);
  },
};
