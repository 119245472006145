import hideCols from '../../../../../../logic/utilities/hideCols';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

const env = window.config.environment;

const text = {
  WALLET: gettext('Wallet'),
  SOURCE: gettext('Source'),
};

const internalModifier = {
  ...hideCols(env !== 'AvaTrade' ? ['comment', 'wallet_content_type', 'wallet_object_id', 'source_content_type', 'source_object_id', 'uploaded_by', 'upload_name', 'upload_date'] : []),
  wallet_repr: {
    headerName: text.WALLET,
  },
  source_repr: {
    headerName: text.SOURCE,
  },
};

export default internalModifier;
