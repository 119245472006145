import React from 'react';
import { bindActionCreators } from 'redux';

import connect from '../../../logic/connect';
import GridFactory from '..';

const withTable = (Component, tableConfig, GridActions, options) => (id) => {
  const config = tableConfig(id);
  const { GridComponent, actions } = GridFactory(config);
  const { hideFilters, withActions, filterOptions, hideSegments, gridConfig } = options || {};

  const WithGridComponent = props => (
    <Component 
      {...props}
      gridConfig={gridConfig}
      GridComponent={GridComponent}
      GridActions={GridActions} 
      hideFilters={hideFilters}
      hideSegments={hideSegments}
      filterOptions={filterOptions}
    />
  );

  const mapStateToProps = ({ pages }) => { 
    const { reducerKey, tableKey } = config;
    const page = pages[reducerKey];
    const table = page.tables[tableKey];
    return { 
      exportUrl: page.tables[tableKey].exportUrl,
      table,  
      fields: table.fields,
    };
  };

  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ ...actions }, dispatch),
  });

  const TableComponent = connect(mapStateToProps, mapDispatchToProps)(WithGridComponent);

  if (!withActions) {
    return TableComponent;
  }
  
  return [TableComponent, actions];
};

export default withTable;
