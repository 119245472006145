import { ToggleCell, BackendsCell } from '../../../../../../components/gridCellRenderers';



export default ({ onActiveToggle }) => ({
  active: {
    width: 80,
    sortValue: -99,
    cellRendererFramework: ToggleCell(onActiveToggle),
  },
  backend: {
    width: 320,
    cellRendererFramework: BackendsCell,
  },
});
