import Login from '../../../pages/login/Login';
import ResetPassword from '../../../pages/reset-password/ResetPassword';
import Forbidden from '../../../pages/home/403/Forbidden';
import Dashboard from '../../../pages/home/dashboard/Dashboard';
import Tasks from '../../../pages/home/tasks/Tasks';
import LiveClients from '../../../pages/home/clients/LiveClients';
import DemoClients from '../../../pages/home/clients/DemoClients';
import Clients from '../../../pages/home/clients/Clients';
import ClientProfile from '../../../pages/home/user-single/react/index';
import TradingAccount from '../../../pages/home/trading-account/TradingAccount';
import TradingAccounts from '../../../pages/home/trading-accounts/TradingAccounts';
import PaymentTransactions from '../../../pages/home/payments/Payments';
import TransactionSingle from '../../../pages/home/transaction-single/TransactionSingle';
import InternalTransactions from '../../../pages/home/payments/InternalTransactions';
import PendingWithdrawals from '../../../pages/home/payments/PendingWithdrawals';
import WithdrawalHistory from '../../../pages/home/payments/WithdrawalHistory';
import PaymentBonuses from '../../../pages/home/payments/Bonuses';
import OpenPositions from '../../../pages/home/trade/OpenPositions';
import ClosedPositions from '../../../pages/home/trade/ClosedPositions';
import ClosedUserPositions from '../../../pages/home/trade/ClosedUserPositions';
import AllPositions from '../../../pages/home/trade/AllPositions';
import Trades from '../../../pages/home/trade/Trades';
import Instruments from '../../../pages/home/trade/Instruments';
import CommunicationHistory from '../../../pages/home/logs/CommunicationHistory';
import Audit from '../../../pages/home/logs/Audit';
import IbList from '../../../pages/home/ib-system/IbList';
import IbPaymentHistory from '../../../pages/home/ib-system/IbPaymentHistory';
import IbPendingWithdrawals from '../../../pages/home/ib-system/IbPendingWithdrawals';
import IbClientProfile from '../../../pages/home/user-single/react/IbClientProfile';
import ActiveTraders from '../../../pages/home/reports-active-traders/ActiveTraders';
import SalesOverview from '../../../pages/home/sales-overview/SalesOverview';
import EmailEvents from '../../../pages/home/notification-events/EmailEvents';
import { EmailEventCreate, EmailEvent } from '../../../pages/home/notification-events/events-add-edit/EmailEvent';
import EmailTemplates from '../../../pages/home/notification-templates/EmailTemplates';
import { EmailTemplateCreate, EmailTemplate } from '../../../pages/home/notification-templates/EmailTemplateUpdate';
import SmsEvents from '../../../pages/home/notification-events/SmsEvents';
import { SmsEventCreate, SmsEvent } from '../../../pages/home/notification-events/events-add-edit/SmsEvent';
import SmsTemplates from '../../../pages/home/notification-templates/SmsTemplates';
import { SmsTemplateCreate, SmsTemplate } from '../../../pages/home/notification-templates/SmsTemplateUpdate';
import Campaigns from '../../../pages/home/campaigns/Campaigns';
import CampaignSingle from '../../../pages/home/campaign-single/CampaignSingle';
import TrackingLinkSingle from '../../../pages/home/tracking-link-single/TrackingLinkSingle';
import KYCExport from '../../../pages/home/kyc/KYCExport';
import KYCHistory from '../../../pages/home/kyc/KYCHistory';
import KYCCheckLog from '../../../pages/home/kyc/KYCCheckLog';
import DocumentCheckLog from '../../../pages/home/kyc/DocumentCheckLog';
import UserStateMapping from '../../../pages/home/kyc-user-state-mapping/UserStateMapping';
import KycBlacklist from '../../../pages/home/kyc/KYCBlacklist';
import Unsuitable from '../../../pages/home/kyc/Unsuitable';
import Exclusion from '../../../pages/home/kyc/Exclusion';
import BiqUsers from '../../../pages/home/biq-users/BiqUsers';
import TeamProfile from '../../../pages/home/team-profile/TeamProfile';
import Teams from '../../../pages/home/teams/Teams';
import Team from '../../../pages/home/team/Team';
import ClientVisibilityGroups from '../../../pages/home/client-visibility-groups/ClientVisibilityGroups';
import { TeamVisibilityGroup, TeamVisibilityGroupCreate } from '../../../pages/home/client-visibility-groups/modify/TeamVisibilityGroup';
import General from '../../../pages/home/settings/general/General';
import Customization from '../../../pages/home/settings/customization/Customization';
import ClientProfileLayouts from '../../../pages/home/settings/customization/ClientProfileLayouts';
import { ClientProfileLayoutCreate, ClientProfileLayout } from '../../../pages/home/settings/customization/ClientProfileLayout';
import DashboardLayouts from '../../../pages/home/settings/customization/DashboardLayouts';
import { DashboardLayoutCreate, DashboardLayout } from '../../../pages/home/settings/customization/DashboardLayout';
import Documents from '../../../pages/home/settings/documents/Documents';
import Bonus from '../../../pages/home/settings/bonus/react/Bonus';
import { BonusCampaignCreate, BonusCampaign } from '../../../pages/home/settings/bonus/react/CampaignForm';
import { BonusCancellationRules, BonusCancellationRulesCreate } from '../../../pages/home/settings/bonus/react/CancellationRules';
import Segments from '../../../pages/home/settings/segments/Segments';
import Emails from '../../../pages/home/settings/email/Emails';
import CommunicationStatuses from '../../../pages/home/settings/communication-statuses/CommunicationStatuses';
import SalesStatuses from '../../../pages/home/sales-statuses/SalesStatuses';
import BlacklistReasons from '../../../pages/home/blacklist-reasons/BlacklistReasons';
import PaymentTransactionTypes from '../../../pages/home/payment-transaction-types/PaymentTransactionTypes';
import Deposit from '../../../pages/home/settings/deposits/Deposit';
import PendingDepositRule, { PendingDepositRuleCreate } from '../../../pages/home/settings/deposits/PendingDepositRule';
import DepositMethodRule, { DepositMethodRuleCreate } from '../../../pages/home/settings/deposits/DepositMethodRule';
import UpdateWithdrawableAmount, { UpdateWithdrawableAmountCreate } from '../../../pages/home/settings/deposits/Tabs/WithdrawableAmounts/UpdateWithdrawableAmount/react';
import UpdateStateRule, { StateRuleCreate } from '../../../pages/home/settings/pending-deposits/StateRules/UpdateStateRule/react';
import EditDepositExpiry, { CloneDepositExpiry, CreateDepositExpiry } from '../../../pages/home/settings/deposits/EditDepositExpiry/react';
import Withdrawals from '../../../pages/home/settings/withdrawals/Withdrawals';
import WithdrawalFlowStep, { WithdrawalFlowStepCreate } from '../../../pages/home/settings/withdrawals/step-add-edit/WithdrawalFlowStep';
import WithdrawalMethodRule, { WithdrawalMethodRuleClone, WithdrawalMethodRuleCreate } from '../../../pages/home/settings/deposit-withdrawal-method-rule/WithdrawalMethodRule';
import PaymentMethods from '../../../pages/home/settings/payment-methods/PaymentMethods';
import PaymentGateways from '../../../pages/home/settings/payment-gateways/PaymentGateways';
import TradingPlatform from '../../../pages/home/settings/trading-platform/TradingPlatform';
import Integrations from '../../../pages/home/settings/integrations/react/Integrations';
import AvaIntegration from '../../../pages/home/settings/integrations/react/Integrations/pages/AvaIntegration';
import MailchimpIntegration from '../../../pages/home/settings/integrations/react/Integrations/pages/MailchimpIntegration';
import EmailAlerts from '../../../pages/home/email-alerts-bo-users/EmailAlerts';
import CountriesTable from '../../../pages/home/countries-table/CountriesTable';
import TermsAndConditions from '../../../pages/home/settings/terms-and-conditions/TermsAndConditions';
import ActivityStreamPage from '../../../pages/home/activity-stream/ActivityStreamPage';
import ComplianceStatuses from '../../../pages/home/compliance-statuses/ComplianceStatuses';
import LogsMessage from '../../../pages/home/logs-message/LogsMessage';
import PendingWithdrawal from '../../../pages/home/withdrawal-single/PendingWithdrawal';
import AutomaticFees from '../../../pages/home/settings/automatic-fee/react';
import PasswordPage from '../../../pages/home/settings/passwords/react';
import Join from '../../../pages/join/Join';
import SalesStatusesAndSubstatuses from '../../../pages/home/settings/sales-statuses-and-substatuses/SalesStatusesAndSubstatuses';
import SalesStatusForm from '../../../pages/home/settings/sales-statuses-and-substatuses/SalesStatusesAndSubstatuses/SalesStatusForm';
import RiskRatingPage from '../../../pages/home/settings/risk-rating/react/RiskRatingPage';
import Auth from '../../../pages/auth/Auth';
import ExchangeRates from '../../../pages/home/settings/exchange-rates/ExchangeRates';

export default [
  Forbidden,
  Login,
  ResetPassword,
  Auth,
  Join,
  Dashboard,
  Tasks,
  ActivityStreamPage,
  LiveClients,
  DemoClients,
  Clients,
  ClientProfile,
  TradingAccount,
  TradingAccounts,
  TransactionSingle,
  PaymentTransactions,
  InternalTransactions,
  PendingWithdrawal,
  PendingWithdrawals,
  WithdrawalHistory,
  PaymentBonuses,
  OpenPositions,
  ClosedUserPositions,
  ClosedPositions,
  AllPositions,
  Trades,
  Instruments,
  LogsMessage,
  CommunicationHistory,
  Audit,
  IbList,
  IbPaymentHistory,
  IbPendingWithdrawals,
  IbClientProfile,
  ActiveTraders,
  SalesOverview,
  EmailEventCreate,
  EmailEvent,
  EmailEvents,
  EmailTemplateCreate,
  EmailTemplate,
  EmailTemplates,
  SmsEventCreate,
  SmsEvent,
  SmsEvents,
  SmsTemplateCreate,
  SmsTemplate,
  SmsTemplates,
  CampaignSingle,
  Campaigns,
  TrackingLinkSingle,
  KYCExport,
  KYCHistory,
  KYCCheckLog,
  DocumentCheckLog,
  UserStateMapping,
  KycBlacklist,
  Unsuitable,
  Exclusion,
  BiqUsers,
  TeamProfile,
  Team,
  Teams,
  TeamVisibilityGroupCreate,
  TeamVisibilityGroup,
  ClientVisibilityGroups,
  General,
  ClientProfileLayoutCreate,
  ClientProfileLayout,
  ClientProfileLayouts,
  DashboardLayoutCreate,
  DashboardLayout,
  DashboardLayouts,
  Customization,
  Documents,
  BonusCampaignCreate,
  BonusCampaign,
  BonusCancellationRules,
  BonusCancellationRulesCreate,
  Bonus,
  Segments,
  Emails,
  CommunicationStatuses,
  AutomaticFees,
  SalesStatuses,
  BlacklistReasons,
  ComplianceStatuses,
  PaymentTransactionTypes,
  PendingDepositRuleCreate,
  PendingDepositRule,
  DepositMethodRuleCreate,
  DepositMethodRule,
  UpdateWithdrawableAmountCreate,
  UpdateWithdrawableAmount,
  StateRuleCreate,
  UpdateStateRule,
  CreateDepositExpiry,
  CloneDepositExpiry,
  EditDepositExpiry,
  Deposit,
  WithdrawalFlowStepCreate,
  WithdrawalFlowStep,
  WithdrawalMethodRuleCreate,
  WithdrawalMethodRuleClone,
  WithdrawalMethodRule,
  Withdrawals,
  PaymentMethods,
  PaymentGateways,
  TradingPlatform,
  AvaIntegration,
  MailchimpIntegration,
  Integrations,
  EmailAlerts,
  CountriesTable,
  TermsAndConditions,
  PasswordPage,
  SalesStatusForm,
  SalesStatusesAndSubstatuses,
  RiskRatingPage,
  ExchangeRates,
];
