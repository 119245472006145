/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Button, notifier, Tabs, Tab, Popconfirm } from 'tc-biq-design-system';
import { generatePath } from 'react-router-dom';

import connect from '../../../logic/connect';
import { gettext } from '../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../logic/services/api-factory';
import to from '../../../logic/utilities/to';
import GridFactory from '../../../components/grid';
import withUnmountMethod from '../../../components/hoc/withUnmountMethod';
import Page from '../../../components/Page';
import overlayActions from '../../../components/overlay';
import { getActiveFilters } from '../../../components/grid/GridUtils';
import Filters from '../../../components/Filters';
import modifier from './modifier';
import AddEditTeam from './Sidepanel/AddEditTeam';
import If from '../../../components/If';
import { hasAccess } from '../../../logic/services/acl';
import TeamStructure from './TeamsStructure';
import { loadTeams } from './TeamsStructure/Model';
import withErrorBoundary from '../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../components/App/Router/appRoutes';

const pageConfig = {
  apiUrl: 'teams',
  reducerKey: 'TEAMS',
  tableKey: 'TEAMS_TABLE',
};

const { GridComponent, actions } = GridFactory(pageConfig);


const tabs = {
  LIST: 'list',
  STRUCTURE: 'structure',
};

const text = {
  TITLE: gettext('Teams'),
  CREATE_NEW: gettext('Create new'),
  DELETE_SUCCESS: gettext('Team successfully deleted'),
  DELETE_ERROR: gettext('Error while deleting team'),
  TEAMS: gettext('Teams'),
  TEAMS_STRUCTURE: gettext('Teams structure'),
  TEAMS_UPDATE_SUCCESS: gettext('Team data updated successfully'),
  TEAMS_UPDATE_ERROR: gettext('Error while updating team data'),
  SAVE_DATA: gettext('Save teams data'),
  ARE_YOU_SURE: gettext('Are you sure you want to update teams sales and retention data?'),
  CANCEL: gettext('Cancel'),
  CONFIRM: gettext('Confirm'),
};

const bread = [{ label: text.TITLE, route: appRoutes.TEAMS }];
class Teams extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.api = getDjangoApi(pageConfig.apiUrl);
    this.updateTeamsDataApi = getDjangoApi('teams').all('bulk');

    this.headerActions = this.headerActions.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
    
    this.tableActions = {
      ...(hasAccess('teams.update') && { onAccessRightsUpdate: this.onAccessRightsUpdate }),
      ...(hasAccess('teams.update') && { onModify: this.onModify.bind(this) }),
      ...(hasAccess('teams.create') && { onClone: this.onClone.bind(this) }),
      ...(hasAccess('teams.destroy') && { onDelete: this.onDelete }),
      onCheckRetentionSales: this.onCheckRetentionSales.bind(this),
    };

    this.tableModifier = modifier(this.tableActions);

    this.state = {
      tab: tabs.LIST,
      changedRows: {},
      loadingSaveChangedRows: false,
    };

    this.oldRows = {};
  }

  onAccessRightsUpdate(id) {
    return generatePath(appRoutes.TEAM, {
      id,
      section: 'access-rights',
    });
  }

  onModify(data) {
    this.actions.openSidepanel('ADD_EDIT_TEAM', { type: 'edit', data });
  }

  onClone(data) {
    this.actions.openSidepanel('ADD_EDIT_TEAM', { type: 'clone', data });
  }

  onCheckRetentionSales(field, data, node, event) {
    const { checked } = event.target;
    const { changedRows } = this.state;

    const updatedRow = {
      ...data,
      [field]: checked,
    };

    const oldRow = this.oldRows[updatedRow.id];
    if (!!oldRow && _.isEqual(oldRow, updatedRow)) {
      delete changedRows[updatedRow.id];
    } else {
      changedRows[updatedRow.id] = updatedRow;
    }

    this.setState({
      changedRows,
    }, () => node.setDataValue(field, checked));

    if (!this.oldRows[data.id]) {
      this.oldRows = {
        ...this.oldRows,
        [data.id]: { ...data },
      };
    }
  }

  onDelete = async ({ id }) => {
    const [error] = await to(this.api.destroy(id));

    if (error) {
      notifier.error(text.DELETE_ERROR);
    } else {
      notifier.success(text.DELETE_SUCCESS);
      this.refreshTable();
    }
  }

  setTab = tab => this.setState({ tab });

  setListTab = () => this.setTab(tabs.LIST);

  setStructureTab = () => this.setTab(tabs.STRUCTURE);

  saveTeamsData = async () => {
    const { changedRows } = this.state;
    const data = Object.values(changedRows).map(({ id, sales, retention }) => ({ id, sales, retention }));
    this.setState({ loadingSaveChangedRows: true });
    const [error] = await to(this.updateTeamsDataApi.update(null, data));
    this.setState({ 
      loadingSaveChangedRows: false,
      changedRows: {}, 
    });

    this.oldRows = {};

    if (error) {
      notifier.error(text.TEAMS_UPDATE_ERROR);
    } else {
      notifier.success(text.TEAMS_UPDATE_SUCCESS);
    }
  }

  headerActions() {
    const { changedRows, loadingSaveChangedRows, tab } = this.state;
    return (
      <Fragment>
        {!_.isEmpty(changedRows) && tab === tabs.LIST && (
          <Popconfirm
            type="primary"
            icon="Info"
            label={text.ARE_YOU_SURE}
            onConfirm={this.saveTeamsData}
            buttonLabels={{
              cancel: text.CANCEL,
              confirm: text.CONFIRM,
            }}
          >
            <Button 
              loading={loadingSaveChangedRows}
            >
              {text.SAVE_DATA}
            </Button>
          </Popconfirm>
        )}
        <If condition={hasAccess('teams.create')}>
          <Button onClick={() => this.actions.openSidepanel('ADD_EDIT_TEAM', { type: 'add' })}>
            {text.CREATE_NEW}
          </Button>
        </If>
      </Fragment>
    );
  }

  refreshTable() {
    const { table } = this.props;
    const { tab } = this.state;
    if (tab === tabs.LIST) {
      this.actions.fetchTableData(table.query);
    } else {
      this.actions.loadTeams();
    }
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifier(this.tableActions),
    };
    
    return (
      <Page bread={bread} title={text.TITLE} headerActions={this.headerActions}>
        <Tabs>
          <Tab
            visible
            onLoad={this.setListTab}
            title={text.TEAMS}
          >
            <Filters {...filterProps} />
            <GridComponent
              singleActions={!!Object.keys(this.tableActions).length}
              tableModifier={this.tableModifier}
            />
          </Tab>
          <Tab
            visible={hasAccess('teams.visual.list')}
            onLoad={this.setStructureTab}
            title={text.TEAMS_STRUCTURE}
          >
            <TeamStructure />
          </Tab>
        </Tabs>
        <AddEditTeam onSuccessRequest={this.refreshTable} />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      fetchTableData: actions.fetchTableData,
      openSidepanel: overlayActions.open,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      loadTeams,
    },
    dispatch,
  ),
});

const TeamsPage = compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(Teams);

export default {
  component: TeamsPage,
  aclId: 'teams.list',
  path: appRoutes.TEAMS,
};
