import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import documentTypes from '../../../../../../logic/enums/document-types';

const actionTypes = {
  ACTIVATE: 'ACTIVATE_PROTRADER',
  ACTIVATE_PENDING: 'ACTIVATE_PROTRADER_PENDING',
  ACTIVATE_REJECTED: 'ACTIVATE_PROTRADER_REJECTED',
  ACTIVATE_FULFILLED: 'ACTIVATE_PROTRADER_FULFILLED',
  RESET: 'RESET',
};

const activateProtrader = (protraderApi, id) => {
  const { FINANCIAL_SECTOR, TRADING_ACTIVITY, PORTFOLIO } = documentTypes;
  const documentsApi = getDjangoApi(`users/${id}/documents`);
  const documentTypesApi = getDjangoApi('document_types');

  const documentsQuery = {
    usage__in: `${FINANCIAL_SECTOR},${TRADING_ACTIVITY},${PORTFOLIO}`,
  };

  return Promise.all([
    protraderApi.options(),
    protraderApi.list(),
    documentsApi.list(documentsQuery),
    documentTypesApi.options(),
  ]);
};

export const activate = createAction(
  actionTypes.ACTIVATE,
  activateProtrader,
);

export const resetData = createAction(actionTypes.RESET);

const initialState = {
  data: {},
  fetchInProgress: false,
};

const setupData = ({ options, data, documents, documentTypes }) => {
  const { fields } = options.actions.GET;

  const states = _.find(fields, { key: 'state' }).choices;
  const state = _.find(states, { display_name: data.state });

  const financialSectorStates = _.find(fields, { key: 'finsec_state' }).choices;
  const financialSectorState = _.find(financialSectorStates, { value: data.finsec_state });

  const portfolioStates = _.find(fields, { key: 'portfolio_state' }).choices;
  const portfolioState = _.find(portfolioStates, { value: data.portfolio_state });

  const activityStates = _.find(fields, { key: 'tradeact_state' }).choices;
  const activityState = _.find(activityStates, { value: data.tradeact_state });

  const usages = _.find(documentTypes.actions.GET.fields, { key: 'usage' });

  return {
    ...data,
    state,
    financialSectorState,
    portfolioState,
    activityState,
    documents: documents.results,
    documentUsages: (usages && usages.choices) || [],
  };
};

const onActivateFulfilled = (state, { payload }) => { 
  const [
    { data: options }, 
    { data }, 
    { data: documents }, 
    { data: documentTypes },
  ] = payload;

  return {
    ...state, 
    fetchInProgress: false,
    data: setupData({ options, data, documents, documentTypes }),
  };
}; 


export default handleActions(
  {
    [actionTypes.ACTIVATE_PENDING]: state => ({ ...state, fetchInProgress: true }),
    [actionTypes.ACTIVATE_REJECTED]: state => ({ ...state, fetchInProgress: false }),
    [actionTypes.ACTIVATE_FULFILLED]: onActivateFulfilled,
    [actionTypes.RESET]: () => ({ ...initialState }),
  },
  initialState,
);
