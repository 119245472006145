import React, { Component, Fragment } from 'react';
import { InfoBox, notifier, Select, Sidepanel, Space } from 'tc-biq-design-system';
import { array, bool, func, object } from 'prop-types';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import to from '../../../../../../logic/utilities/to';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { tradingAccountDisplay } from '../../../../../../logic/filters/trading-account-display';
import FormFactory from '../../../../../../components/form';
import { SidepanelFooter } from '../../../../../../components/common';
import { nestedFieldsGenerator } from '../../../../../../components/form/Components/Form';
import { getTableActions } from '../../Sections/TradingAccounts/table';
import userStateLabel from '../../../../../../logic/enums/user-state-label';
import { cleanEmptyValues, Field, formErrorHandler } from '../../../../../../components/form/logic/utils';

const text = {
  TITLE: user => gettext('Edit trading accounts for {{first_name}} {{last_name}}', user),
  SUCCESS: gettext('Trading account changed.'),
  SUCCESS_CREATE: gettext('Added new trading account for user.'),
  CHOOSE_ACCOUNT: gettext('Choose account'),
  CHOOSE_GROUP: gettext('Choose group'),
  SERVICE: gettext('Service'),
  ACCOUNT_TYPE: gettext('Account type'),
  LEVERAGES: gettext('Leverages'),
  LABEL: gettext('Trading Account Type'),
  TRADING_ACCOUNT_ID: gettext('Trading Account Id'),
  CANNOT_EDIT: gettext('This account cannot be edited'),
  GENERAL_ERROR: gettext('Something went wrong!'),
  CANNOT_CREATE: gettext('Sorry, you can\'t create more accounts for this trading platform'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const FORM_KEY = 'TRADING_ACCOUNT_FORM';

const formModifiers = {
  external_id: {
    placeholder: text.TRADING_ACCOUNT_ID,
    label: text.TRADING_ACCOUNT_ID,
  },
};

const customFooter = (execute, close, submitInProgress, disableSubmit) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    disableSubmit={disableSubmit}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_KEY}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  formFields: array,
  formErrors: object,
  formValues: object,
  submitInProgress: bool.isRequired,
  accounts: array.isRequired,
  backends: array.isRequired,
  leverages: array.isRequired,
  labels: array.isRequired,
  isNew: bool.isRequired,
  dispatch: func.isRequired,
  trandingAccounts: array,
};

const defaultProps = {
  user: null,
  actions: null,
  formFields: [],
  formErrors: null,
  formValues: null,
  trandingAccounts: [],
};

class TradingAccountForm extends Component {
  constructor(props) {
    super(props);

    this.actions = props.actions;
    this.api = user => getDjangoApi(`users/${user.id}/trading_accounts`);
    this.isAvaTrade = window.config.environment === 'AvaTrade';
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onBackendChange = this.onBackendChange.bind(this);
    this.onAccountChange = this.onAccountChange.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
    this.onLeverageChange = this.onLeverageChange.bind(this);
    this.onLabelChange = this.onLabelChange.bind(this);
    this.onAccountTypeChange = this.onAccountTypeChange.bind(this);
    this.getRequestPayload = this.getRequestPayload.bind(this);
    this.isAccountEditable = true;

    this.state = {
      groups: [],
      accounts: [],
      account_types: [],
      isAccountVisible: false,
      isBackendVisible: false,
      isCurrencyVisible: false,
      isGroupVisible: false,
      isLeverageVisible: false,
      isLabelVisible: false,
      isAccountTypeVisible: false,
      isExternalIdVisible: false,
      isSubmitDisabled: false,
    };
  }

  componentDidUpdate(prevProps) {
    this.fetchOptions(prevProps);
    this.generateMt4Fields();
    this.onCurrencyChange(prevProps);
  }

  async onSubmit() {
    const { user, formValues, isNew } = this.props;
    const accountId = _.get(formValues, 'trading_account.value');
    const action = isNew ? this.actions.create : this.actions.update;
    const params = [this.api(user), ...(!isNew ? [accountId] : []), this.getRequestPayload];
    const [err] = await to(action(...params));

    err ? this.onError(err) : this.onSuccess();
  }

  onSuccess() {
    const { isNew } = this.props;
    notifier.success(isNew ? text.SUCCESS_CREATE : text.SUCCESS);
    this.reloadTradingAccountsTable();
    this.onClose();
  }

  onError(payload) {
    formErrorHandler()(payload);
  }

  onClose() {
    const { sidepanelManager } = this.props;
    sidepanelManager.close();
  }

  onBackendChange(backend) {
    this.setBackend(backend);
  }

  onGroupChange(group) {
    this.setGroup(group);
  }

  onAccountChange(account) {
    this.setAccount(account);
  }

  onCurrencyChange(prevProps) {
    const prevCurrency = _.get(prevProps, 'formValues.currency');
    const currency = _.get(this.props, 'formValues.currency');

    if (!_.isEqual(prevCurrency, currency)) {
      const backend = this.getBackend();
      this.updateGroup(backend);
    }
  }

  onAccountTypeChange(accountType) {
    this.setAccountType(accountType);
  }

  onLeverageChange(leverage) {
    this.setLeverage(leverage);
  }

  onLabelChange(label) {
    this.setLabel(label);
  }

  onChangeErrors(prevProps) {
    const prevFormErrors = prevProps.formErrors;
    const { formErrors } = this.props;

    if (_.isEmpty(prevFormErrors) && !_.isEmpty(formErrors)) {
      const newErrors = _.reduce(formErrors, (acc, value, key) => {
        const isObject = value
          && typeof value === 'object'
          && value.constructor === Object;
        if (isObject) {
          const children = _.reduce(value, (childrenAcc, childrenValue, childrenKey) => ({
            ...childrenAcc,
            [`${key}_${childrenKey}`]: childrenValue,
          }), {});
          return { ...acc, [key]: value, ...children };
        }

        return { ...acc, [key]: value };
      }, {});

      this.actions.setFieldsErrors(newErrors);
    }
  }

  getRequestPayload(rawValues) {
    const {
      formFields,
      isNew,
    } = this.props;
    const values = cleanEmptyValues(rawValues);
    const {
      isLeverageVisible,
      isLabelVisible,
      isMt4Visible,
    } = this.state;
    const MANDATORY_KEYS = ['currency', 'backend'];
    const nestedFieldKeys = formFields
      .filter(field => !!field.children)
      .map(field => field.id)
      .filter(key => !MANDATORY_KEYS.includes(key));
    const getNestedKey = fieldKey => nestedFieldKeys.find(key => fieldKey.startsWith(key));
    const getFieldValue = field => (!!field && typeof field === 'object' ? field.value : field);
    const groupId = _.get(values, 'group.id');
    const externalId = values.external_id;
    const request = {
      backend: !isNew ? values.trading_account.backend.id : values.backend.backend.id,
      currency: _.get(values, 'currency.value'),
      group: values.group ? values.group.id : undefined,
      read_only: values.read_only ? values.read_only : undefined,
      account_type: values.account_type ? values.account_type.value : undefined,
    };

    if (groupId) request.group = groupId;
    if (externalId) request.external_id = externalId;

    Object.keys(values).forEach((key) => {
      const field = values[key];
      const parentKey = getNestedKey(key);
      if (parentKey) {
        const fieldKey = key.replace(`${parentKey}_`, '');
        request[parentKey] = { ...request[parentKey], [fieldKey]: getFieldValue(field) };
      }
    });

    if (isLeverageVisible) {
      request.leverage = values.leverage.id;
    }
    if (isLabelVisible && values.label) {
      request.label = values.label.name;
    }

    if (!isMt4Visible) {
      delete request.mt4_options;
    } else {
      request.read_only = values.read_only;
    }

    this.removeNonAvaFields(MANDATORY_KEYS, request);

    return request;
  }

  setAccount(account) {
    const { isNew, backends } = this.props;
    const defaultCurrency = account.currency.symbol;
    const defaultBackend = backends.find(backend => account.backend.id === backend.backend.id);
    const defaultGroup = account.group;
    const defaultLeverageId = account.leverage;
    const defaultLabelId = account.label;
    const isAccountVisible = !isNew;
    this.isAccountEditable = this.getIsAccountEditable(defaultBackend, account);

    this.actions.setFieldValue({
      id: 'trading_account',
      value: account,
    });

    this.setState({
      isAccountVisible,
    });

    this.setState({ isSubmitDisabled: !this.isAccountEditable });

    this.updateCurrency(defaultBackend, defaultCurrency);
    this.updateGroup(defaultBackend, defaultGroup);
    this.updateLeverage(defaultBackend, defaultLeverageId, account);
    this.updateLabels(defaultBackend, defaultLabelId, account);
    this.updateMt4(defaultBackend, account);
  }

  getIsAccountEditable(defaultBackend, account) {
    return (!!defaultBackend && account.backend.type !== 'cloudtrade') || this.isAvaTrade;
  }

  getBackend() {
    const { isNew, backends, formValues } = this.props;
    const { backend } = formValues || {};
    const tradingAccountBackendId = _.get(formValues, 'trading_account.backend.id');

    return isNew ? backend : backends.find(b => tradingAccountBackendId === b.backend.id);
  }

  setBackend(backend) {
    const { isNew } = this.props;
    this.actions.resetValues();
    this.actions.setFieldValue({
      id: 'backend',
      value: backend,
    });

    this.setState({ isBackendVisible: isNew });
    this.updateCurrency(backend);
    this.updateAccountType(backend);
    this.updateGroup(backend);
    this.updateLeverage(backend);
    this.updateExternalId(backend);
    this.updateMt4(backend);
    this.checkBackendLimit(backend);
  }

  setGroup(group) {
    this.actions.setFieldValue({
      id: 'group',
      value: group || null,
    });
  }

  setAccountType(accountType) {
    this.actions.setFieldValue({
      id: 'account_type',
      value: accountType,
    });
  }

  setLeverage(leverage) {
    this.actions.setFieldValue({
      id: 'leverage',
      value: leverage,
    });
  }

  setLabel(label) {
    this.actions.setFieldValue({
      id: 'label',
      value: label,
    });
  }

  setCurrency(currency) {
    this.actions.setFieldValue({
      id: 'currency',
      value: currency,
    });
  }

  setField(id, value) {
    this.actions.setFieldValue({
      id,
      value,
    });
  }

  getFormConfig() {
    const { user } = this.props;
    const generateDummyField = name => ({
      id: name,
      name,
      type: 'text',
    });
    return {
      form: FORM_KEY,
      api: this.api(user),
      ...(this.isAvaTrade && { includeFields: ['label', 'trading_account'] }),
      customFields: [
        generateDummyField('trading_account'),
      ],
    };
  }

  getBackends = () => {
    const { backends, user } = this.props;

    if (user.state === userStateLabel.DEMO) {
      return backends.filter(({ backend }) => backend.mode === userStateLabel.DEMO);
    }

    return backends;
  }

  canCreate = (selectedBackend = {}) => {
    const { trandingAccounts } = this.props;
    const selectedBackendId = _.get(selectedBackend, 'backend.id');
    const selectedBackendLimit = _.get(selectedBackend, 'backend.accounts_per_user_limit');
    const count = trandingAccounts
      .filter(({ backend }) => backend.id === selectedBackendId)
      .length;
    return parseInt(selectedBackendLimit, 10) === 0
      || count < selectedBackendLimit;
  }

  checkBackendLimit = (backend) => {
    const { isNew } = this.props;
    if (isNew && !this.canCreate(backend)) {
      this.setState({ isSubmitDisabled: true });
      this.actions.setFieldsErrors({ backend: text.CANNOT_CREATE });
    } else {
      this.setState({ isSubmitDisabled: false });
      this.actions.setFieldsErrors({});
    }
  }

  reloadTradingAccountsTable = () => {
    const { user, dispatch } = this.props;
    const actions = getTableActions(user.id);
    dispatch(actions.fetchTableData());
  }

  removeNonAvaFields(backendMandatoryFields, request) {
    if (this.isAvaTrade) {
      const { includeFields = [] } = this.getFormConfig();
      const avaFields = [...includeFields, ...backendMandatoryFields];

      Object.keys(request).forEach((key) => {
        if (avaFields.length && !avaFields.includes(key)) {
          delete request[key];
        }
      });
    }
  }

  fetchOptions(prevProps) {
    const prevFormFields = prevProps.formFields;
    const { formFields, isNew } = this.props;

    if (_.isEmpty(prevFormFields) && !_.isEmpty(formFields)) {
      this.actions.showLoader(true);
      const { user } = this.props;
      Promise.all([
        !this.isAvaTrade && this.actions.fetchTradingBackends(user),
        this.actions.fetchTradingAccounts(user),
      ]).then(() => {
        const { accounts } = this.props;
        const accountOptions = this.prepareAccounts(accounts);

        this.setState({
          accounts: accountOptions,
        });

        if (isNew) {
          const { backends } = this.props;
          this.setBackend(backends[0]);
        } else {
          this.setAccount(accountOptions[0]);
        }
      }).finally(() => this.actions.showLoader(false));
    }
  }

  updateGroup(backend, defaultGroup) {
    const { formValues } = this.props;
    const { currency } = formValues;
    const defaultGroupId = _.get(defaultGroup, 'id');
    const groupHasType = backend => backend.backend.type === 'ctrader' || backend.backend.type === 'dx_trade';
    const groups = backend ? backend.groups.filter(group => groupHasType(backend) || group.currency === currency.value) : [defaultGroup];

    this.setState({
      groups,
    });

    const selectedGroup = (defaultGroupId && groups.find(group => group.id === defaultGroupId))
      || (!_.isEmpty(groups) && groups[0]);
    this.setGroup(selectedGroup);

    this.setState({
      isGroupVisible: this.isAccountEditable,
    });
  }

  updateAccountType(backend) {
    const account_types = backend.account_types.map(value => ({
      value,
      display_name: value,
    }));

    this.setState({
      account_types,
      isAccountTypeVisible: !['mt4', 'xmt4'].includes(backend.backend.type) && backend.backend.platform_type !== 'MT4',
    });

    if (!_.isEmpty(account_types)) this.setAccountType(account_types[0]);
  }

  updateLeverage(backend, defaultLeverageId, account) {
    this.actions.fetchLeverages(backend || account).then(() => {
      const { leverages } = this.props;
      let selectedLeverage = leverages.find(leverage => leverage.id === defaultLeverageId);

      const isLeverageVisible = leverages.length > 0;
      if (isLeverageVisible && !selectedLeverage) selectedLeverage = leverages[0];

      this.setState({
        isLeverageVisible,
      });
      this.setLeverage(selectedLeverage);
    });
  }

  updateLabels(backend, defaultLabelId, account) {
    if (!this.isAvaTrade) return;
    this.actions.fetchLabels(backend || account).then(() => {
      const { labels } = this.props;
      let selectedLabel = labels.find(label => label.id === defaultLabelId);

      const isLabelVisible = labels.length > 0;
      if (isLabelVisible && !selectedLabel) selectedLabel = null;

      this.setState({
        isLabelVisible,
      });
      this.setLabel(selectedLabel);
    });
  }

  updateExternalId(backend) {
    const backendMode = backend.backend.mode;
    const backendPlatformType = backend.backend.platform_type;
    const isExternalIdVisible = backendMode !== 'Demo'
      && (backendPlatformType === 'MT4' || backendPlatformType === 'MT5' || backendPlatformType === 'DXTrade')
      && isFeatureEnabled()('TRADING_ACCOUNT_NUMBER');
    this.setState({ isExternalIdVisible });
  }

  updateCurrency(backend, defaultCurrency) {
    const { formFields } = this.props;
    const currency = formFields.find(field => field.id === 'currency');
    const currencyOptions = currency.options;
    const selectedCurrency = currencyOptions.find(curr => curr.value === defaultCurrency);

    this.setCurrency(selectedCurrency || currencyOptions[0]);
    this.setState({
      isCurrencyVisible: this.isAccountEditable,
    });
  }

  updateMt4(backend, account) {
    const selectedBackend = backend || account;
    const isMt4Visible = (selectedBackend.backend.type === 'mt4_server' || selectedBackend.backend.type === 'mt4' || selectedBackend.backend.type === 'mt5' || selectedBackend.backend.type === 'mt5_server');

    this.setState({ isMt4Visible });

    if (!account) return;
    const mt4Fields = {
      mt4_options_agent: _.get(account, 'mt4_options.agent'),
      mt4_options_enable_otp: _.get(account, 'mt4_options.enable_otp'),
      mt4_options_enable_change_password: _.get(account, 'mt4_options.enable_change_password'),
      mt4_options_send_reports: _.get(account, 'mt4_options.send_reports'),
      read_only: _.get(account, 'read_only'),
    };

    Object.keys(mt4Fields).forEach(key => this.setField(key, mt4Fields[key]));
  }

  prepareAccounts(accounts) {
    return accounts.map(account => ({
      ...account,
      display_name: tradingAccountDisplay(account),
      value: account.id,
    }));
  }

  generateMt4Fields() {
    if (_.isEmpty(this.mt4Fields)) {
      const { formFields } = this.props;
      const MT4_OPTIONS = 'mt4_options';
      this.mt4Fields = nestedFieldsGenerator(FORM_KEY, formFields, MT4_OPTIONS);
    }
  }

  renderForm() {
    if (!this.FormComponent) {
      this.FormComponent = FormFactory(this.getFormConfig());
    }
    const { FormComponent } = this;
    return FormComponent;
  }

  render() {
    const {
      sidepanelManager,
      user,
      actions,
      submitInProgress,
      formValues,
      leverages,
      labels,
      isNew,
      formErrors,
      ...options
    } = this.props;

    const {
      groups,
      accounts,
      account_types,
      isAccountVisible,
      isBackendVisible,
      isCurrencyVisible,
      isGroupVisible,
      isLeverageVisible,
      isMt4Visible,
      isAccountTypeVisible,
      isExternalIdVisible,
      isLabelVisible,
      isSubmitDisabled,
    } = this.state;

    const {
      trading_account,
      group,
      leverage,
      label,
      backend,
      account_type,
    } = formValues;

    const backends = this.getBackends();
    const Form = this.renderForm();
    return (
      <Sidepanel
        {...options}
        footerRender={customFooter(
          this.onSubmit,
          this.onClose,
          submitInProgress,
          isSubmitDisabled,
        )}
      >
        <Space size={16} />
        <Form
          formId={FORM_KEY}
          modifiers={formModifiers}
          renderForm={formFields => (
            <Fragment>
              {isBackendVisible && backend && formFields.backend && (
                <Select
                  clearable={false}
                  label={text.SERVICE}
                  labelKey="display_name"
                  valueKey="value"
                  value={backend}
                  options={backends}
                  onChange={this.onBackendChange}
                  hasError={!!formErrors.backend}
                  helpText={formErrors.backend}
                />
              )}
              {isAccountVisible && trading_account && formFields.trading_account && (
                <Select
                  clearable={false}
                  label={text.CHOOSE_ACCOUNT}
                  labelKey="display_name"
                  valueKey="value"
                  value={trading_account}
                  options={accounts}
                  onChange={this.onAccountChange}
                />
              )}
              <Space size={12} />
              {formFields.currency && <Field disabled={!isCurrencyVisible}>{formFields.currency}</Field>}
              {isAccountTypeVisible && account_type && formFields.account_type && (
                <Fragment>
                  <Select
                    clearable={false}
                    label={text.ACCOUNT_TYPE}
                    labelKey="display_name"
                    valueKey="value"
                    value={account_type}
                    options={account_types}
                    onChange={this.onAccountTypeChange}
                  />
                  <Space size={12} />
                </Fragment>
              )}
              {formFields.group && group && (
                <Fragment>
                  <Select
                    clearable={false}
                    label={text.CHOOSE_GROUP}
                    labelKey="name"
                    valueKey="id"
                    value={group}
                    options={groups}
                    onChange={this.onGroupChange}
                    disabled={!isGroupVisible}
                  />
                  <Space size={12} />
                </Fragment>
              )}

              {isLeverageVisible && leverage && formFields.leverage && (
                <Fragment>
                  <Select
                    clearable={false}
                    label={text.LEVERAGES}
                    labelKey="value"
                    valueKey="id"
                    value={leverage}
                    options={leverages}
                    onChange={this.onLeverageChange}
                  />
                  <Space size={12} />
                </Fragment>
              )}

              {isLabelVisible && this.isAvaTrade && formFields.label && (
                <Fragment>
                  <Select
                    clearable
                    label={text.LABEL}
                    labelKey="display_name"
                    valueKey="value"
                    value={label}
                    options={labels}
                    onChange={this.onLabelChange}
                  />
                  <Space size={12} />
                </Fragment>
              )}
              {isExternalIdVisible && !this.isAvaTrade && formFields.external_id}
              {!_.isEmpty(this.mt4Fields) && isMt4Visible && !this.isAvaTrade && (
                <Fragment>
                  {this.mt4Fields.mt4_options_agent}
                  {this.mt4Fields.mt4_options_enable_change_password}
                  {this.mt4Fields.mt4_options_enable_otp}
                  {this.mt4Fields.mt4_options_send_reports}
                </Fragment>
              )}

              {formFields.read_only && (
                <Fragment>
                  {formFields.read_only}
                </Fragment>
              )}

              {!this.isAccountEditable && <InfoBox>{text.CANNOT_EDIT}</InfoBox>}
            </Fragment>
          )}
        />
        <Space size={16} />
      </Sidepanel>
    );
  }
}

TradingAccountForm.propTypes = propTypes;
TradingAccountForm.defaultProps = defaultProps;

export default TradingAccountForm;
