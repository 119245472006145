import React from 'react';

import { object, func, string, bool } from 'prop-types';
import { Space, Sidepanel } from 'tc-biq-design-system';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { SidepanelFooter } from '../../../../../../../components/common';

const defaultText = {
  button: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const customFooter = (execute, close, submitInProgress, text) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.button}
  />
);

const propTypes = {
  actions: object,
  onSubmit: func.isRequired,
  sidepanel: object.isRequired,
  reducerKey: string.isRequired,
  text: object.isRequired,
  submitInProgress: bool,
};

const defaultProps = {
  actions: null,
  submitInProgress: false,
};

const Confirmation = ({ actions, onSubmit, sidepanel, reducerKey, text, submitInProgress }) => {
  const { visible, parameters } = sidepanel || {};
  const onCloseSidepanel = () => actions.closeSidepanel(reducerKey);
  const onSubmitSidepanel = () => {
    onSubmit(parameters);
    onCloseSidepanel();
  };
  
  const trans = { ...defaultText, ...text };
  return (
    <Sidepanel 
      title={trans.TITLE}
      footerRender={customFooter(onSubmitSidepanel, onCloseSidepanel, submitInProgress, trans)}
      visible={visible}
      onCloseIconClick={onCloseSidepanel}
    >
      <Space size={16} />
      <div>{trans.QUESTION}</div>
    </Sidepanel>
  );
};

Confirmation.propTypes = propTypes;
Confirmation.defaultProps = defaultProps;

export default Confirmation;
