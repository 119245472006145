import features from '../logic/constants/features';

const CONFIG = window.config;

if (window.top.document.getElementById('hotjar') === null && _.includes(features, 'HOTJAR') && CONFIG.hotjar_id) {
  console.log('hotjar active');
  const a = window.top.document.getElementsByTagName('head')[0];
  const b = window.top.document.createElement('script');
  b.type = 'text/javascript';
  b.id = 'hotjar';
  const code = '(function(h,o,t,j,a,r){\n'
        + 'h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n'
        + `h._hjSettings={hjid:${CONFIG.hotjar_id},hjsv:6};\n`
        + "a=o.getElementsByTagName('head')[0];\n"
        + "r=o.createElement('script');r.async=1;\n"
        + 'r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n'
        + 'a.appendChild(r);\n'
    + "})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";
  b.appendChild(document.createTextNode(code));
  a.appendChild(b);
}
