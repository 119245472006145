import React, { Component, Fragment } from 'react';
import { Select, Icon, Button } from 'tc-biq-design-system';
import { string, array, func, bool } from 'prop-types';
import Condition from '../Condition';
import ConditionConnector from '../HelperComponents/ConditionConnector';
import { gettext } from '../../../logic/utilities/languageUtility';

import './ConditionGroup.scss';

const propTypes = {
  condition: string.isRequired,
  rules: array.isRequired,
  fields: array,
  renderRules: func.isRequired,
  addRule: func.isRequired,
  updateState: func.isRequired,
  identifier: string.isRequired,
  removeRule: func.isRequired,
  initial: bool,
};

const defaultProps = {
  fields: [],
  initial: false,
};

const text = {
  AND_CONDITION: gettext('All conditions'),
  OR_CONDITION: gettext('At least one condition'),
  REMOVE_ALL: gettext('Remove all'),
  REMOVE_GROUP: gettext('Remove group'),
  ADD_SUBGROUP: gettext('Add subgroup'),
  ADD_CONDITION: gettext('Add condition'),
  CONDITION_PLACEHOLDER: gettext('Click Add condition button to start creating your query'),
  MATCH: gettext('Match'),
};

const conditionOptions = [
  { label: text.AND_CONDITION, value: 'AND' },
  { label: text.OR_CONDITION, value: 'OR' },
];

class ConditionGroup extends Component {
  onValueChange = (condition) => {
    const { updateState, identifier } = this.props;
    updateState({ condition: condition.value }, identifier);
  };

  // Check whether component's own condition type rules were changed and then cause rerender. If
  // it's the group that changed it should be a different component instance that handles the
  // rerender. Pay attention to condition change, I don't think anything else matters.
  // TODO @pavle: Leaving this for later on when we have enough time
  // shouldComponentUpdate(nextProps, nextState, nextContext) {
  //
  // }

  render() {
    const {
      fields,
      rules,
      renderRules,
      addRule,
      condition,
      updateState,
      identifier,
      removeRule,
      initial,
    } = this.props;

    const conditionComponents = rules.map((rule, index) => (
      <Fragment key={`${rule.identifier || `rule-${index}`}`}>
        {!!rule.field
        && (
          <Condition
            identifier={rule.identifier}
            fields={fields}
            fieldToQuery={rule.field}
            operator={rule.operator}
            filterData={rule.value}
            updateState={updateState}
            removeRule={removeRule}
          />
        )}
        {rule.hasOwnProperty('rules') && renderRules(fields, rule)}
        {index !== rules.length - 1 && <ConditionConnector displayValue={condition} />}
      </Fragment>
    ));

    const onAddConditionGroup = () => addRule('group', identifier);
    const onAddCondition = () => addRule('condition', identifier);
    const removeConditions = () => removeRule(identifier);
    const selectedCondition = conditionOptions.find(({ value }) => value === condition);
    return (
      <Fragment>
        <div className="condition-group__background">
          <div className="condition-group__conjuction">

            <p className="condition-group__match">{text.MATCH}</p>
            <div className="condition-group__conjunction-select">
              <Select
                onChange={this.onValueChange}
                value={selectedCondition}
                options={conditionOptions}
                clearable={false}
                valueKey="value"
                arrowRenderer={() => <Icon name="CaretDown" size="small" />}
              />
            </div>
          </div>

          <div className="condition-group__add-remove-section">
            <div className="condition-group__remove-condition" onClick={removeConditions}>
              <Icon name="Delete" size="small" colorName="text-neutral-900" />
              <span className="condition-group__remove-text">{initial ? text.REMOVE_ALL : text.REMOVE_GROUP}</span>
            </div>
            <Button
              size="small"
              style={{ float: 'right', marginTop: '12px' }}
              color="ghost"
              onClick={onAddConditionGroup}
            >
              <Icon name="Plus" size="extraSmall" />
              <span style={{ paddingLeft: '3px' }}>{text.ADD_SUBGROUP}</span>
            </Button>
            <Button size="small" style={{ float: 'right', marginTop: '12px' }} onClick={onAddCondition}>
              <Icon name="Plus" size="extraSmall" />
              <span style={{ paddingLeft: '3px' }}>{text.ADD_CONDITION}</span>
            </Button>
          </div>
        </div>

        <ul className="condition-group__line-background">
          {conditionComponents}
          {!rules.length && <div className="condition__placeholder">{text.CONDITION_PLACEHOLDER}</div>}
        </ul>
      </Fragment>
    );
  }
}

ConditionGroup.propTypes = propTypes;
ConditionGroup.defaultProps = defaultProps;

export default ConditionGroup;
