import React, { Component } from 'react';
import { object } from 'prop-types';
import { PieChart, Space, DashboardCard } from 'tc-biq-design-system';

import withFilters from '../hoc/withFilters';
import { pieChartFormater } from '../utils/dashboard';

const size = { 
  w: 4, 
  h: 5, 
  minW: 4, 
  maxW: 6, 
  minH: 5, 
  maxH: 5, 
};

const propTypes = {
  text: object.isRequired,
  api: object.isRequired,
};

class AssignedClients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false,
    };
  }

  onQueryReady = async (query) => {
    const { api } = this.props;
    this.setState({ loading: true });
    const { data } = await api.list(query);
    
    this.setState({
      data: pieChartFormater(data, '#005FCC'),
      loading: false,
    });
  }

  render() {
    const { text } = this.props;
    const { data, loading } = this.state;
    
    return (
      <DashboardCard
        title={text.TITLE}
        description={text.TITLE}
        loading={loading}
      >
        <Space size={8} />
        <PieChart
          data={data}
          width={200}
          height={140}
          innerRadius={40}
          outerRadius={60}
          withLegend
        />
      </DashboardCard>
    );
  }
}

AssignedClients.propTypes = propTypes;

export default {
  component: withFilters(AssignedClients),
  size,
};
