import appRoutes from '../../../../components/App/Router/appRoutes';
import SmsTemplateUpdate from './SmsTemplateUpdate';

export const SmsTemplate = {
  component: SmsTemplateUpdate,
  path: appRoutes.SMS_TEMPLATE,
  aclId: 'sms.templates.update',
};


export const SmsTemplateCreate = {
  component: SmsTemplateUpdate,
  path: appRoutes.SMS_TEMPLATES_CREATE,
  aclId: 'sms.templates.create',
};
