import { gettext } from "../utilities/languageUtility";

export const paymentStatesLabels  = {
  ACCEPTED: gettext('Accepted'),
  CANCELLED: gettext('Cancelled'),
  CAPTURED: gettext('Captured'),
  DECLINED: gettext('Declined'),
  FAILED: gettext('Failed'),
  PENDING: gettext('Pending'),
  PROCESSING: gettext('Processing'),
  REDIRECTED: gettext('Redirected'),
  REFUND: gettext('Refund'),
  REJECTED: gettext('Rejected'),
  SUCCESS: gettext('Success'),
  NA: gettext('N/A'),
};

export const paymentStatesValues = {
  ACCEPTED: 'Accepted',
  CANCELLED: 'Cancelled',
  CAPTURED: 'Captured',
  DECLINED: 'Declined',
  FAILED:  'Failed',
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  REDIRECTED: 'Redirected',
  REFUND: 'Refund',
  REJECTED: 'Rejected',
  SUCCESS: 'Success',
  NA: 'N/A'
};
