import React from 'react';
import { string, array, oneOfType, number, node } from 'prop-types';
import { Space, Chart, DashboardCard } from 'tc-biq-design-system';

import './GraphCard.scss';

const propTypes = {
  title: string.isRequired,
  description: string,
  data: array,
  value: oneOfType([string, number]),
  header: node,
};

const defaultProps = {
  description: '',
  data: [],
  value: null,
  header: null,
};

const GraphCard = ({ title, description, data, value, header }) => (
  <div className="graph-card">
    <DashboardCard
      title={title}
      description={description || title}
    >
      <Space size={10} />
      {header}
      <Space size={10} />
      <Chart
        xAxisKey="x"
        height={220}
        interval="preserveStart"
        bars={[
          { key: 'y', label: title, color: 'status01-400' },
        ]}
        data={data}
        valueRenderer={(key, value) => `${key}: ${value}`}
        tickFormatter={value => `${value}`}
        stacked
      />
      {value && <div className="graph-card__title tc-heading-m">{`${value}`}</div>}
    </DashboardCard>
  </div>
);

GraphCard.propTypes = propTypes;
GraphCard.defaultProps = defaultProps;

export default GraphCard;
