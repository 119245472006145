import { GenericGateway } from './genericGateway';

export class PraxisPay extends GenericGateway {
  constructor(params) {
    super(params);
    this.conversionEnabled = false;
  }

  excludedFields({ isAutomaticWithdrawal, is_automatic_withdrawal_allowed }) {
    const excluded = ['acquirer', 'external_id', 'transaction_external_id'];

    if (isAutomaticWithdrawal) excluded.push('transaction_acquirer_external_id');
    if (!is_automatic_withdrawal_allowed) excluded.push('auto_withdrawal_flow');

    return excluded;
  }

  disabledFields() {
    return [];
  }

  hasDepositMatchingMethod() {
    return true;
  }

  canExecute({ referralTransactions, isCreditCardSelected, isAutomaticWithdrawal, is_automatic_withdrawal_allowed }) {
    if (isCreditCardSelected) {
      return Boolean(!!referralTransactions.length || (is_automatic_withdrawal_allowed && !isAutomaticWithdrawal));
    }
    return true;
  }
}
