import React, { Fragment } from 'react';
import { array, number, string, object, shape } from 'prop-types';
import {
  ActivityDateGroup,
  ActivityEvent,
  HyperLink,
  Pill,
  MessageBox,
} from 'tc-biq-design-system';
import { bindActionCreators } from 'redux';

import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import connect from '../../../../../../logic/connect';
import overlayActions from '../../../../../../components/overlay';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import session from '../../../../../../logic/services/session';
import ActionLinkObject from './ActionLinkObject';

const SIDEPANEL_ID = 'ADD_COMMENT';
const propTypes = {
  actions: object.isRequired,
  dateGroup: string.isRequired,
  index: number.isRequired,
  activities: array.isRequired,
  highlightNewCount: number.isRequired,
  data: array.isRequired,
};

const text = {
  MANUALLY_LOGGED: gettext('Manually logged'),
  MESSAGE_BOX_ACTION_LABELS: {
    expand: gettext('Expand'),
    collapse: gettext('Collapse'),
  },
  YOU: gettext('You'),
};

const HyperLinkObject = ({ object }) => {
  if (!object || !object.url) return '';
  const username = session.user.id === object.id ? text.YOU : object.displayName;
  return (
    <HyperLink>
      <a href={object.url.replace('/BO2/', '')}>{username}</a>
    </HyperLink>
  );
};

HyperLinkObject.propTypes = {
  object: shape({
    url: object.isRequired,
    displayName: string.isRequired,
  }).isRequired,
};


const LinkObject = ({ object }) => (['auth_user', 'auth_bo_user'].includes(_.get(object, 'objectType')) 
  ? <ActionLinkObject activityTypeObject={object} targetId={object && object.id} /> 
  : <HyperLinkObject object={object} />);

LinkObject.propTypes = {
  object: shape({
    id: string.isRequired,
  }).isRequired,
};

export const formatTitle = (activityEvent) => {
  const { actor, verb, _object, preposition, target } = activityEvent;
  return (
    <span>
      <LinkObject object={actor} />
      {` ${verb} `}
      {(!!target || !!actor) && <ActionLinkObject activityTypeObject={_object} targetId={target && target.id} />}
      {` ${preposition || ''} `}
      {target?.url ? <LinkObject object={target} /> : target?.displayName }
    </span>
  );
};

const manualStatus = id => () => <Pill key={id} type="status02">{text.MANUALLY_LOGGED}</Pill>;
const getInitialIndex = (activities, id) => activities.findIndex(activity => activity.id === id);

const DateGroup = ({ dateGroup, index, activities, highlightNewCount, data, actions }) => {
  const isFirst = index === 0;
  return (
    <Fragment>
      <ActivityDateGroup text={dateGroup} isFirstNode={isFirst} />
      {!!activities.length
        && activities.map((activity, eventIndex) => {
          const { published, id, content, icon } = activity;
          const manuallyLogged = content && content.manual ? manualStatus(id) : false;
          const date = `${dateGroup}, at ${moment(published).format('HH:mm')}`;
          const initialIndex = getInitialIndex(data, id);
          return (
            <ActivityEvent
              key={id}
              date={date}
              manuallyLogged={manuallyLogged || null}
              iconConfig={icon || {}}
              title={formatTitle(activity)}
              comments={content.comments}
              isFirstNode={isFirst && eventIndex === 0}
              highlight={highlightNewCount > initialIndex}
              addComment={() => actions.openSidepanel(SIDEPANEL_ID, { activity })}
            >
              {content.object_content && typeof content.object_content === 'string' ? (
                <MessageBox
                  key={`${id}-message-box`}
                  text={`${content.object_content}`}
                  actionLabels={text.MESSAGE_BOX_ACTION_LABELS}
                />
              ) : (
                <div key={`${id}-message-box`} />
              )}
            </ActivityEvent>
          );
        })}
    </Fragment>
  );
};

DateGroup.propTypes = propTypes;

const mapStateToProps = ({ pages: { ACTIVITY_STREAM } }) => {
  const {
    activities: { highlightNewCount, data },
  } = ACTIVITY_STREAM;
  return { highlightNewCount, data };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundary(DateGroup));
