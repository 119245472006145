import React, { Component, Fragment } from 'react';
import { array, func } from 'prop-types';
import { Select, Space } from 'tc-biq-design-system';

import FormEl from '../../../../../../../../components/form/Components/FormEl';
import Backend from './Backend';

import './SelectBackend.scss';

export const FORM_ID = 'IB_CHANGE_BE_FORM';

const propTypes = {
  backends: array,
  onBackendChange: func,
};

const defaultProps = {
  backends: [],
  onBackendChange: null,
};

class SelectBackend extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  onChange(option, backend, currency) {
    const { backends } = this.props;
    const { onBackendChange } = this.props;
 
    const updateGroup = group => ({
      ...group,
      selected: option && group.id === option.id,
    });

    const updateCurrency = (oldCurrency) => {
      if (oldCurrency.name === currency.name) {
        return {
          ...oldCurrency,
          groups: oldCurrency.groups.map(updateGroup),
        };
      }
      return oldCurrency;
    };

    const updateBackend = backendModifier => (oldBackend) => {
      if (oldBackend.id === backend.id) {
        return {
          ...oldBackend,
          ...backendModifier(oldBackend),
        };
      }
      return oldBackend;
    };

    const backendModifier = oldBackend => (currency 
      ? ({ currencies: oldBackend.currencies.map(updateCurrency) }) 
      : ({ groups: oldBackend.groups.map(updateGroup) }));

    const newBackends = backends.map(updateBackend(backendModifier));

    onBackendChange(newBackends);
  }

  onClose(disabledBackend) {
    const { onBackendChange, backends } = this.props;

    onBackendChange(backends.map(backend => ({ 
      ...backend, 
      ...(backend.id === disabledBackend.id && { selected: false }) })));
  }

  onSelect(enabledBackend) {
    const { onBackendChange, backends } = this.props;
    
    onBackendChange(backends.map(backend => ({ 
      ...backend, 
      selected: backend.selected || backend.id === enabledBackend.id })));
  }

  displayGroup = (backends, backend, index) => {
    if (index === 0 || backends[index - 1].group !== backend.group) return <h3 className="tc-heading-s">{backend.group}</h3>;
    return null;
  }

  render() {
    const { backends } = this.props;
    const activeBackends = _.sortBy(backends.filter(backend => backend.selected), 'group');
    const disabledBackends = _.sortBy(backends.filter(backend => !backend.selected), 'group');
    return (
      <FormEl formId={FORM_ID}>
        {disabledBackends.length > 0 && (
          <Fragment>
            <Space size={16} />
            <Select
              group
              onChange={this.onSelect}
              options={disabledBackends}
              labelKey="name"
            />
          </Fragment>
        )}
        {activeBackends.map((backend, index) => (
          <div className="biq-select-backend">
            {this.displayGroup(activeBackends, backend, index)}
            <Backend
              key={backend.id}
              backend={backend} 
              onChange={this.onChange} 
              onClose={this.onClose} 
            />
          </div>
        ))}
        <Space size={16} />
      </FormEl>
    );
  }
}

SelectBackend.propTypes = propTypes;
SelectBackend.defaultProps = defaultProps;

export default SelectBackend;
