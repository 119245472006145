import React from 'react';
import { InfoBox } from 'tc-biq-design-system';

import { gettext } from '../../../../logic/utilities/languageUtility';

const text = {
  TITLE: gettext('You don\'t have permissions to access this page.'),
};

const Forbidden = () => (
  <div className="biq-page__notification">
    <InfoBox type="warning">{text.TITLE}</InfoBox>
  </div>
);

export default Forbidden;
