import appRoutes from '../../../../../../components/App/Router/appRoutes';
import EditDepositExpiry from './EditDepositExpiry';

export default {
  component: EditDepositExpiry,
  aclId: 'settings.deposit_expiry_period.update',
  path: appRoutes.SETTINGS_DEPOSIT_EXPIRY,
};

export const CreateDepositExpiry = {
  component: EditDepositExpiry,
  aclId: 'settings.deposit_expiry_period.create',
  path: appRoutes.SETTINGS_DEPOSIT_EXPIRY_CREATE,
};

export const CloneDepositExpiry = {
  component: EditDepositExpiry,
  aclId: 'settings.deposit_expiry_period.create',
  path: appRoutes.SETTINGS_DEPOSIT_EXPIRY_CLONE,
};
