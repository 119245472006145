import React, { PureComponent, Fragment } from 'react';
import { Pill } from 'tc-biq-design-system';
import { object, func, bool } from 'prop-types';

import UserTags from '../../../../../components/common/sidepanels/UserPreview/UserTags';
import { StateCell } from '../../../../../components/gridCellRenderers';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import isFeatureEnabled from '../../../../../logic/filters/is-feature-enabled';
import UserAssignments from './UserAssignments';
import HeaderActions from './Actions';
import ListDetail from './ListDetail';
import Header from '../../../../../components/UserHeader';
import { TAB_ID as POSSIBLE_DUPLICATES_TAB } from '../Sections/PossibleDuplicates';
import { hasAccess } from '../../../../../logic/services/acl';


const text = {
  POSSIBLE_DUPLICATE: gettext('Possible duplicate'),
  ONLINE: gettext('Online'),
};

const propTypes = {
  user: object,
  onTagsUpdate: func.isRequired,
  isIb: bool,
  changeActiveTab: func,
};

const defaultProps = {
  user: null,
  isIb: false,
  changeActiveTab: null,
};

class UserHeader extends PureComponent {
  getSubTitle = () => {
    const { user } = this.props;
    const subTitle = [user.username];
    if (user.addr_country) subTitle.push(user.addr_country.country_name);

    return subTitle.join(', ');
  }

  render() {
    const { user, onTagsUpdate, isIb, changeActiveTab } = this.props;
    
    return (
      <Header>
        {user && (
          <Fragment>
            <Header.Avatar />
            <Header.Profile>
              <Header.Name>{`${user.first_name} ${user.last_name}`}</Header.Name>
              <Header.States>
                {user.state && <StateCell value={user.state} />}
                {user.whitelabel && <Pill type="neutral">{user.whitelabel}</Pill>}
                {user.possible_duplicate && isFeatureEnabled()('RECORD_DUPLICATE_USERS') && (
                  <Pill 
                    icon="Client" 
                    iconPosition="left" 
                    type="primary"
                    onClick={() => changeActiveTab(POSSIBLE_DUPLICATES_TAB)}
                  >
                    {text.POSSIBLE_DUPLICATE}
                  </Pill>
                )}
                {!_.isEmpty(user.online_status) && user.online_status.map(status => (
                  <Pill key={status} type="status01">{`${text.ONLINE} ${status}`}</Pill>
                ))}
              </Header.States>
              <Header.Subtitle>
                {this.getSubTitle()}
              </Header.Subtitle>
              <UserTags hideTitle onUpdate={onTagsUpdate} user={user} disable={!hasAccess('user.tags.create')} />
              <ListDetail details={user.finance} />
            </Header.Profile>
            <div style={{ flex: 1 }}>
              <Header.Actions>
                <HeaderActions isIb={isIb} />
              </Header.Actions>
              <UserAssignments isIb={isIb} />
            </div>
          </Fragment>
        )}
      </Header>
    );
  }
}

UserHeader.propTypes = propTypes;
UserHeader.defaultProps = defaultProps;

export default UserHeader;
