import React from 'react';
import { object, func, bool } from 'prop-types';
import { ListItem, Icon, Space } from 'tc-biq-design-system';

import './TaskRenderer.scss';
import { dateTimeFormatter } from '../../../logic/utilities/formatters';

const propTypes = {
  task: object.isRequired,
  viewTask: func.isRequired,
  completeTask: func.isRequired,
  hidePerformer: bool,
};

const defaultProps = {
  hidePerformer: false,
};

export const renderPerformer = (name) => {
  if (!name) return null;
  return (
    <span className="task-renderer__performer">
      <Icon name="User" size="extraSmall" />
      <span style={{ position: 'relative', left: '5px' }}>{name}</span>
    </span>
  );
};

export const renderDueDate = (date) => {
  if (!date) return null;
  return (
    <span className="task-renderer__date">
      <Icon name="Clock" size="extraSmall" />
      <span style={{ position: 'relative', left: '5px' }}>{date}</span>
    </span>
  );
};

export const isCompleted = state => (state === 'Completed' ? 'text-status01-400' : null);

const TaskRenderer = ({ task, viewTask, completeTask, hidePerformer }) => {
  const dueDate = task.due_date ? dateTimeFormatter(task.due_date) : null;
  
  return (
    <ListItem key={task.id}>
      <div className="task-renderer">
        <div className="task-renderer__icon" onClick={() => completeTask(task)}>
          <Icon name="Approve" colorName={isCompleted(task.state)} />
        </div>
        <Space horizontal size={11} />
        <div className="task-renderer__content black-text" onClick={() => viewTask(task)}>
          <div>{task.name}</div>
          <div className="task-renderer__content__bottom tc-micro-regular">
            {renderDueDate(dueDate)}
            {!hidePerformer ? renderPerformer(task.performer.username) : null}
          </div>
        </div>
      </div>
    </ListItem>
  );
};

TaskRenderer.propTypes = propTypes;
TaskRenderer.defaultProps = defaultProps;
export default TaskRenderer;
