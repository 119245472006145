/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { notifier } from 'tc-biq-design-system';

import connect from '../../../../logic/connect';
import { gettext } from '../../../../logic/utilities/languageUtility';
import Page from '../../../../components/Page';
import withUnmountMethod from '../../../../components/hoc/withUnmountMethod';
import overlayActions from '../../../../components/overlay';
import GridFactory from '../../../../components/grid';
import Export from '../../../../components/Export';
import { getActiveFilters } from '../../../../components/grid/GridUtils';
import Filters from '../../../../components/Filters';
import { getDjangoApi } from '../../../../logic/services/api-factory';
import modifier from './modifier';
import ExecuteWithdrawalSidepanel from './sidepanel/executeWitdrawal';
import { RejectWithdrawal, WithdrawalRequest, TransactionSingle, UserPreview, BiqUserPreview } from '../../../../components/common/sidepanels';
import { getWithdrawalDetails, isMethodVisible } from '../../../../components/common/sidepanels/WithdrawalRequest';
import { hasAccess } from '../../../../logic/services/acl';
import withErrorBoundary from '../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../components/App/Router/appRoutes';

const pageConfig = {
  apiUrl: 'pending_withdrawals',
  reducerKey: 'PENDING_WITHDRAWALS',
  tableKey: 'PENDING_WITHDRAWALS_TABLE',
};

const text = {
  APPROVE_ERROR: gettext('Can\'t approve this withdrawal'),
  TITLE: 'Pending Withdrawals',
};

const bread = [{ label: text.TITLE, route: appRoutes.PENDING_WITHDRAWALS }];
const { GridComponent, actions } = GridFactory(pageConfig);
const transactionApi = getDjangoApi('payment_transactions');
const CONFIG = window.config;

class PendingWithdrawals extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.headerActions = this.headerActions.bind(this);
    
    this.openWithdrawalRequest = this.openWithdrawalRequest.bind(this);
    this.openSingleTransaction = this.openSingleTransaction.bind(this);

    this.tableActions = {
      ...(hasAccess('withdrawals.requests.execute.*')
        && { approve: this.execute.bind(this) }),
      ...(hasAccess('withdrawals.requests.reject.*')
        && { reject: this.actions.openSidepanel.bind(this, 'REJECT_WITHDRAWAL') }),
    };

    this.previewActions = {
      previewUser: this.actions.openSidepanel.bind(this, 'USER_PREVIEW'),
      previewBiqUser: this.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }
  
  componentDidMount() {
    const { actions } = this.props;
    actions.fetchTableData(); 
  }

  onSuccessRequest = () => {
    const { table } = this.props;
    this.actions.fetchTableData(table.query);
  }

  routeToSingleWithdrawalPage(id, { tab }) {
    const { history } = this.props;
    history.push(appRoutes.PENDING_WITHDRAWAL, { id, tab });
  }

  execute(data) {
    if (CONFIG.environment === 'AvaTrade') {
      const approveStepApi = getDjangoApi(`pending_withdrawals/${data.id}/approve_details`);
      approveStepApi.list().then((res) => {
        const step = res.data.results.find(s => s.can_be_approved && s.status === 1);

        if (step) {
          getDjangoApi(`pending_withdrawals/${data.id}/approve_details/${step.id}/approve/`).create({})
            .then(() => this.routeToSingleWithdrawalPage(data.id, { tab: 'execute-withdrawal' }))
            .catch(err => notifier.error(err.data.message));
        } else {
          notifier.error(text.APPROVE_ERROR);
        }
      });
    } else {
      this.actions.openSidepanel('EXECUTE_WITHDRAWAL', data);
    }
  }

  async openWithdrawalRequest(data) {
    if (CONFIG.environment === 'AvaTrade') {
      this.routeToSingleWithdrawalPage(data.id, { tab: 'withdrawal-details' });
    } else {
      const { actions } = this.props;
      const response = await getDjangoApi(pageConfig.apiUrl).retrieve(data.id);
      let sidepanelData = response.data;

      if (sidepanelData.payment_gateway_account) {
        const { method, id } = sidepanelData.payment_gateway_account;

        if (isMethodVisible(method)) {
          const detailsResponse = await getWithdrawalDetails(method).retrieve(id);
          sidepanelData = { ...sidepanelData, preferredMethods: detailsResponse.data };
        }
      }

      actions.openSidepanel('WITHDRAWAL_REQUEST', sidepanelData);
    }
  }

  async openSingleTransaction(id) {
    const { actions } = this.props;
    const response = await transactionApi.retrieve(id);
    actions.openSidepanel('TRANSACTION_SINGLE', response.data);
  }


  headerActions() {
    const { exportUrl } = this.props;
    return hasAccess('withdrawals.requests.export.*') && <Export url={exportUrl} />;
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const tableModifier = modifier(this.tableActions, this.openWithdrawalRequest, this.previewActions, table.data);

    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier,
    };

    return (
      <Page bread={bread} title={text.TITLE} headerActions={this.headerActions}>
        <Filters {...filterProps} />
        <GridComponent
          tableModifier={tableModifier}
          singleActions={!!Object.keys(this.tableActions).length}
          {...this.props}
        />
        <ExecuteWithdrawalSidepanel onSuccess={this.onSuccessRequest} />
        <RejectWithdrawal onSuccess={this.onSuccessRequest} />
        <WithdrawalRequest openTransactionSingle={this.openSingleTransaction} />
        <TransactionSingle />
        <UserPreview />
        <BiqUserPreview />
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return { 
    exportUrl: table.exportUrl,
    table,
    fields: table.fields,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePageSize: actions.changePageSize,
      toggleSidepanel: actions.toggleSidepanel,
      addActionsColumn: actions.addActionsColumn,
      setSidepanelData: actions.setSidepanelData,
      fetchTableData: actions.fetchTableData,
      openSidepanel: overlayActions.open,
      closeSidepanel: overlayActions.close,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
    },
    dispatch,
  ),
});

export default compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(PendingWithdrawals);
