import { notifier } from 'tc-biq-design-system';
import API_MESSAGE_SEVERITY from '../enums/api-message-severity';

export default function notifyMessage(message) {
  switch (message.severity) {
    case API_MESSAGE_SEVERITY.DEBUG:
    case API_MESSAGE_SEVERITY.INFO:
      notifier.info(message.text);
      break;
    case API_MESSAGE_SEVERITY.SUCCESS:
      notifier.success(message.text);
      break;
    case API_MESSAGE_SEVERITY.WARNING:
      notifier.warning(message.text);
      break;
    case API_MESSAGE_SEVERITY.ERROR:
      notifier.error(message.text);
      break;
  }
}
