import React, { Component } from 'react';
import { func, number, object } from 'prop-types';

import withFormSidepanel from '../../../../../../../components/FormSidepanel';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';
import { formatRequestData } from '../../../../../../../components/form/logic/utils';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';

export const SIDEPANEL_ID = 'EDIT_SIGNUP_DATA_FORM_SIDEPANEL';
export const FORM_ID = 'EDIT_SIGNUP_DATA_FORM';

const { environment } = window.config;

const text = {
  EDIT: gettext('Edit signup data'),
  SUCCESS: {
    edit: gettext('Signup data successfully updated'),
  },
  BUTTON_LABELS: {
    edit: {
      confirm: gettext('Save'),
      cancel: gettext('Cancel'),
    },
  },
};

const propTypes = {
  onSuccess: func.isRequired,
  uid: number.isRequired,
  actions: object.isRequired,
  values: object.isRequired,
};

const sections = ['Default'];
if (environment === 'AvaTrade') sections.push('ReferAFriend');

class EditSignupDetailsSidepanel extends Component {
  constructor(props) {
    super(props);

    this.FormSidepanel = withFormSidepanel(FORM_ID, SIDEPANEL_ID);
    this.actions = props.actions;
    this.state = {
      customFields: [],
    };
  }

  componentDidMount() {
    this.setFields();
  }

  onFormReady = () => {
    const { customFields } = this.state;

    customFields.forEach(({ id, defaultValue }) => this.actions.setFieldValue({
      id,
      value: defaultValue,
    }));
  }

  getApi = () => {
    const { uid } = this.props;
    const apiUrl = `users/${uid}/signup_data`;
    return getDjangoApi(apiUrl);
  }

  getFormConfig = () => {
    const { customFields } = this.state;
    return {
      customFields,
    };
  }

  getRequestPayload = () => (values) => {
    const data = formatRequestData(values);
    const { customFields } = this.state;

    this.setState({
      customFields: customFields.map(field => ({ ...field, defaultValue: data[field.id] })),
    });
    
    return {
      data,
    };
  }

  setFields = async () => {
    const response = await this.getApi().retrieve();
    const data = _.get(response, 'data.results[0].data');
    if (data) {
      const customFields = Object.keys(data).reduce((acc, section) => {
        if (sections.includes(section)) {
          const fields = Object.keys(data[section]).map(field => ({
            id: `${section}:${field}`,
            name: `${section}:${field}`,
            type: 'text',
            label: field,
            placeholder: field,
            defaultValue: data[section][field],
          }));

          return [...acc, ...fields];
        }

        return acc;
      }, []);

      this.setState({ customFields });
    }
  }

  render() {
    const { FormSidepanel, getApi, getFormConfig, getRequestPayload } = this;
    const { customFields } = this.state;
    
    return customFields ? (
      <FormSidepanel
        {...this.props}
        options={{
          text,
          getFormConfig,
          getApi,
          getRequestPayload,
          onlyCustomFields: true,
        }}
        isEdit
        onFormReady={this.onFormReady}
      />
    ) : null;
  }
}

EditSignupDetailsSidepanel.propTypes = propTypes;

export default EditSignupDetailsSidepanel;
