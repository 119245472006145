import { createAction, handleActions } from 'redux-actions';

import { getDjangoApi } from '../../../../../../logic/services/api-factory';

const actionTypes = {
  ACTIVATE: 'ACTIVATE_AUDIT_SINGLE',
  ACTIVATE_PENDING: 'ACTIVATE_AUDIT_SINGLE_PENDING',
  ACTIVATE_FULFILLED: 'ACTIVATE_AUDIT_SINGLE_FULFILLED',
};

export const activate = createAction(
  actionTypes.ACTIVATE, 
  id => getDjangoApi(`audit_logs/${id}`).list(),
);

const initialState = {
  data: [],
  fetchInProgress: false,
  errors: null,
};

const fetchFulfilled = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  data: payload.data,
});

export default handleActions(
  {
    [actionTypes.ACTIVATE_PENDING]: state => ({ ...state, fetchInProgress: true }),
    [actionTypes.ACTIVATE_REJECTED]: state => ({ ...state, fetchInProgress: false }),
    [actionTypes.ACTIVATE_FULFILLED]: fetchFulfilled,
  },
  initialState,
);
