/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import {
  Tabs,
  Tab,
  Button,
  Pill,
  Table,
  Space,
  Icon,
  Popconfirm,
  notifier,
} from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';

import Page from '../../../../../components/Page';
import withUnmountMethod from '../../../../../components/hoc/withUnmountMethod';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import emailsActions from './Model';
import './Emails.scss';
import Settings from './Settings';
import WithPagination from './WithPagination';
import connect from '../../../../../logic/connect';
import { getDjangoApi } from '../../../../../logic/services/api-factory';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import overlayActions from '../../../../../components/overlay';
import SettingEmailsSidepanel, { SIDEPANEL_ID } from './sidepanels/SettingsEmailsSidepanel';
import { hasAccess } from '../../../../../logic/services/acl';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const { environment } = window.config;

const text = {
  PAGE_TITLE: gettext('Emails'),
  SETTINGS: gettext('Settings'),
  DEFAULT: gettext('Default system'),
  PER_WHITELABEL: gettext('Emails per whitelabel/language'),
  CREATE_NEW: gettext('Create new'),
  NAME: gettext('Name'),
  FALLBACK: gettext('Fallback'),
  WHITELABEL: gettext('Whitelabel'),
  IS_FROM: gettext('Is from'),
  IS_REPLAY: gettext('Is reply to'),
  LANGUAGE: gettext('Language'),
  CREATED: gettext('Created'),
  YES: gettext('Yes'),
  NO: gettext('No'),
  POPCONFIRM_LABELS: {
    confirm: gettext('Delete'),
    cancel: gettext('Cancel'),
  },
  DELETE_LABEL: gettext('Are you sure you want to delete this email?'),
  DELETE_ERROR: gettext('Error while deleting email'),
  DELETE_SUCCESS: gettext('Successfully deleted email'),
};

const bread = [{ label: text.PAGE_TITLE, route: appRoutes.SETTINGS_EMAIL }];
const defaultSystemApi = getDjangoApi('settings/email_addresses/default');
const perLanguagelApi = getDjangoApi('settings/email_addresses');

const renderPill = key => ({ [key]: status }) => (
  <Pill type={status ? 'status01' : 'status04'} big>
    {status ? text.YES : text.NO}
  </Pill>
);
const formatCreated = record => moment.utc(record.created).format('YYYY-MM-DD HH:mm:ss');

const defaultSystemColumns = actions => [
  { title: text.NAME, key: 'email' },
  { title: text.FALLBACK, key: 'is_fallback', render: renderPill('is_fallback') },
  { title: text.IS_FROM, key: 'is_from', render: renderPill('is_from') },
  { title: text.IS_REPLAY, key: 'is_reply_to', render: renderPill('is_reply_to') },
  { title: text.CREATED, key: 'created', render: formatCreated },
  { title: '', key: 'actions', render: actions, width: '100px' },
];

const perLanguageColumns = actions => [
  { title: text.NAME, key: 'email' },
  { title: text.IS_FROM, key: 'is_from', render: renderPill('is_from') },
  { title: text.IS_REPLAY, key: 'is_reply_to', render: renderPill('is_reply_to') },
  { title: text.WHITELABEL, key: 'whitelabel', render: record => record.whitelabel.name },
  { title: text.LANGUAGE, key: 'language' },
  { title: text.CREATED, key: 'created', render: formatCreated },
  { title: '', key: 'actions', render: actions, width: '100px' },
];

const CreateNew = ({ onClick }) => <Button onClick={onClick}>{text.CREATE_NEW}</Button>;

const displayDefaultSystem = environment === 'AvaTrade' 
  || environment === 'ICMCapital' 
  || environment === 'CSAustralia';

const displayPerWhitelabel = environment === 'AvaTrade' 
  || environment === 'ICMCapital' 
  || environment === 'CSAustralia' 
  || environment === 'CoreSpreads';

class Emails extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;
    this.state = { active: 'settings' };
  }

  getHeaderActions = () => {
    const { active } = this.state;
    if (active === 'per_language' || active === 'default_system') return () => <CreateNew onClick={this.openSidepanel} />;
    return () => null;
  };

  setActiveTab(active) {
    const { query } = this.props;
    this.setState({ active }, () => this.fetchData(query[active]));
  }

  openSidepanel = async () => {
    const res = await perLanguagelApi.options();

    const fields = _.get(res, 'data.actions.POST.fields') || [];
    const languages = fields.find(({ key }) => key === 'language').choices;
    const options = { languages };
    const { active } = this.state;
    if (active === 'per_language') {
      this.actions.openSidepanel(SIDEPANEL_ID, { perType: true, ...options });
    } else {
      this.actions.openSidepanel(SIDEPANEL_ID, options);
    }
  };

  fetchData = (query) => {
    const { active } = this.state;
    if (active === 'default_system') {
      this.actions.loadData(defaultSystemApi, query, active);
    }
    if (active === 'per_language') {
      this.actions.loadData(perLanguagelApi, query, active);
    }
  };

  editEmail = (record) => {
    const { active } = this.state;
    if (active === 'per_language') {
      this.actions.openSidepanel(SIDEPANEL_ID, { perType: true, data: record, edit: true });
    } else {
      this.actions.openSidepanel(SIDEPANEL_ID, { data: record, edit: true });
    }
  };

  deleteEmail = (record) => {
    const { active } = this.state;
    const { query } = this.props;
    const api = active === 'per_language' ? perLanguagelApi : defaultSystemApi;
    api.destroy(record.id).then(
      () => {
        notifier.success(text.DELETE_SUCCESS);
        this.fetchData(query[active]);
      },
      () => {
        notifier.error(text.DELETE_ERROR);
      },
    );
  };

  tableActions = (record) => {
    const deleteEmail = () => this.deleteEmail(record);
    const editEmail = () => this.editEmail(record);
    return (
      <div className="biq-settings-emails__table-tab__table-actions">
        <Icon colorName="text-neutral-900" name="Edit" size="regular" onClick={editEmail} />
        <Popconfirm
          type="destructive"
          placement="bottom"
          icon="Delete"
          label={text.DELETE_LABEL}
          onConfirm={deleteEmail}
          buttonLabels={text.POPCONFIRM_LABELS}
        >
          <Icon colorName="text-neutral-900" name="Delete" size="regular" />
        </Popconfirm>
      </div>
    );
  };

  render() {
    const { data, count } = this.props;
    const { default_system, per_language } = data;
    return (
      <Page
        bread={bread}
        headerActions={this.getHeaderActions()}
        title={text.PAGE_TITLE}
        style={{}}
      >
        <div className="biq-settings-emails">
          <Tabs>
            <Tab 
              title={text.SETTINGS} 
              onLoad={() => this.setActiveTab('settings')}
              visible={hasAccess('settings.email.list')}
            >
              <Settings />
            </Tab>

            <Tab 
              title={text.DEFAULT} 
              onLoad={() => this.setActiveTab('default_system')}
              visible={hasAccess('settings.email_addresses.default.list') && displayDefaultSystem}
            >
              <div className="biq-settings-emails__table-tab">
                <Space size={24} />
                <WithPagination onUpdate={this.fetchData} total={count}>
                  <Table 
                    striped 
                    cols={defaultSystemColumns(this.tableActions)} 
                    data={default_system}
                  />
                </WithPagination>
              </div>
            </Tab>
            
            <Tab 
              title={text.PER_WHITELABEL} 
              onLoad={() => this.setActiveTab('per_language')}
              visible={hasAccess('settings.email_addresses.list') && displayPerWhitelabel}
            >
              <div className="biq-settings-emails__table-tab">
                <Space size={24} />
                <WithPagination onUpdate={this.fetchData} total={count}>
                  <Table 
                    striped 
                    cols={perLanguageColumns(this.tableActions)}
                    data={per_language}
                  />
                </WithPagination>
              </div>
            </Tab> 
          </Tabs>
          <SettingEmailsSidepanel onSuccess={this.fetchData} />
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { query, data, count } = pages.SETTINGS_EMAILS.emailsTable;
  return { query, data, count };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loadData: emailsActions.loadData,
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),
});

export default compose(
  withUnmountMethod,
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(Emails);
