/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Button, notifier } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';
import { Link } from 'react-router-dom';

import connect from '../../../../../logic/connect';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../../components/grid';
import { getActiveFilters } from '../../../../../components/grid/GridUtils';
import Page from '../../../../../components/Page';
import Filters from '../../../../../components/Filters';
import { BiqUserPreview } from '../../../../../components/common/sidepanels';
import overlayActions from '../../../../../components/overlay';
import modifiers from './modifiers';
import to from '../../../../../logic/utilities/to';
import { updateLayout, deleteLayout } from '../ClientProfileLayout/Model';
import DuplicateLayoutSidepanel, { SIDEPANEL_ID } from './sidepanels/DuplicateLayout';
import { hasAccess } from '../../../../../logic/services/acl';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';

const text = {
  CUSTOMIZATION_PAGE_TITLE: gettext('Customization'),
  PAGE_TITLE: gettext('Client profile layouts'),
  CREATE_LAYOUT: gettext('Create layout'),
  GENERAL_ERROR: gettext('Something went wrong'),
};
const bread = [
  { label: text.CUSTOMIZATION_PAGE_TITLE, route: appRoutes.SETTINGS_CUSTOMIZATION },
  { label: text.PAGE_TITLE, route: appRoutes.SETTINGS_CUSTOMIZATION_PROFILES },
];

const pageConfig = {
  apiUrl: 'settings/layout/profile',
  reducerKey: 'CUSTOMIZATION',
  tableKey: 'CLIENT_PROFILE_LAYOUTS_TABLE',
};

const { GridComponent, actions } = GridFactory(pageConfig);

const createLayout = () => hasAccess('layout.profile.create') && (
  <Link to={appRoutes.SETTINGS_CUSTOMIZATION_PROFILE_CREATE}>
    <Button startPropagation>{text.CREATE_LAYOUT}</Button>
  </Link>
);

class ClientProfileLayouts extends Component {
  constructor(props) {
    super(props);
    this.tableActions = {
      remove: hasAccess('layout.profile.destroy') && this.onRemove.bind(this),
      edit: hasAccess('layout.profile.update'),
      duplicate: hasAccess('layout.profile.create') && this.onDuplicate.bind(this),
      toggle: hasAccess('layout.profile.update') && this.onToggleIsActive.bind(this),
      previewBiqUser: props.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.resetTable();
  }

  onToggleIsActive = async ({ id, is_active }) => {
    const { actions } = this.props;
    const [err] = await to(actions.updateLayout({ id, is_active: !is_active }));
    err ? this.onError(err) : this.onSuccess();
  }

  onRemove = async ({ id }) => {
    const { actions } = this.props;
    const [err] = await to(actions.deleteLayout({ id }));
    err ? this.onError(err) : this.onSuccess();
  }

  onDuplicate = (layout) => {
    const { actions } = this.props;
    actions.openSidepanel(SIDEPANEL_ID, layout);
  }

  onSuccess = () => {
    const { actions } = this.props;
    actions.fetchTableData();
  }

  onError({ data }) {
    if (data) {
      const { non_field_errors, messages } = data;
      if (non_field_errors) {
        notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
      } else if (messages) {
        notifier.error(data.messages.map(err => <span>{err.text}</span>));
      } else {
        notifier.error(text.GENERAL_ERROR);
      }
    } else {
      notifier.error(text.GENERAL_ERROR);
    }
  }

  render() {
    const { fields, actions, table } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };
    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      tableModifier: modifiers(this.tableActions),
    };

    return (
      <Page bread={bread} title={text.PAGE_TITLE} headerActions={createLayout}>
        <Filters {...filterProps} />
        <GridComponent
          singleActions
          tableModifier={modifiers(this.tableActions)}
          {...this.props}
        />
        <BiqUserPreview />
        <DuplicateLayoutSidepanel onSuccess={this.onSuccess} />
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchTableData: actions.fetchTableData,
      resetTable: actions.resetTable,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      openSidepanel: overlayActions.open,
      updateLayout,
      deleteLayout,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    fields: table.fields,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(ClientProfileLayouts);
