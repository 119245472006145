import React from 'react';
import { string, node } from 'prop-types';
import { Detail } from 'tc-biq-design-system';

const propTypes = {
  entity: string.isRequired,
  value: node,
};

const defaultProps = {
  value: undefined,
};

const WithdrawalDetail = ({ entity, value }) => (
  <Detail 
    entity={entity} 
    value={<span className="tc-paragraph-regular" style={{ overflowWrap: 'anywhere' }}>{value || '  '}</span>} 
    reverse 
  />
);

WithdrawalDetail.propTypes = propTypes;
WithdrawalDetail.defaultProps = defaultProps;
export default WithdrawalDetail;
