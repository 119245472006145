import React from 'react';
import { bindActionCreators } from 'redux';
import { object, bool } from 'prop-types';
import { notifier, Space, Sidepanel } from 'tc-biq-design-system';

import { SidepanelFooter } from '../../../../../../../components/common';
import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import connect from '../../../../../../../logic/connect';
import { fetchUser } from '../../../Model';
import { addUserToBlacklist } from './Model';

const text = {
  ACTION_LABEL: gettext('Add to Blacklist'),
  QUESTION: context => gettext(
    'Are you sure you want to change the state of {{username}} from {{currentState}} to Blacklist?',
    context,
  ),
  SUCCESS: gettext('User state successfully changed'),
  ERROR: gettext('Error while updating user state'),
  BUTTON_LABELS: {
    confirm: gettext('Confirm'),
    cancel: gettext('Cancel'),
  },
};

const customFooter = (execute, close, submitInProgress) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

const propTypes = {
  sidepanelManager: object.isRequired,
  user: object,
  actions: object,
  submitInProgress: bool.isRequired,
};

const defaultProps = {
  user: null,
  actions: null,
};

const AddToBlacklist = ({ sidepanelManager, user, actions, submitInProgress, ...options }) => {
  const onClose = () => {
    sidepanelManager.close();
  };

  const onSubmit = () => {
    actions.addUserToBlacklist(user).then(() => {
      actions.fetchUser(user, false);
      notifier.success(text.SUCCESS);
      sidepanelManager.close();
    }).catch(() => {
      notifier.error(text.ERROR);
    });
  };

  const questionText = text.QUESTION({
    username: user.username,
    currentState: user.state,
  });

  return (
    <Sidepanel {...options} footerRender={customFooter(onSubmit, onClose, submitInProgress)}>
      <Space size={16} />
      <div>{questionText}</div>
    </Sidepanel>
  );
};

AddToBlacklist.propTypes = propTypes;
AddToBlacklist.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      addUserToBlacklist,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  const action = page.actions.ADD_USER_TO_BLACKLIST;
  return {
    submitInProgress: action.submitInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToBlacklist);
