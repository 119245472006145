import React from 'react';
import { Tooltip, Icon, Popconfirm } from 'tc-biq-design-system';

import { ActionsCellWrapper } from '../../../../../../components/gridCellRenderers';
import { gettext } from '../../../../../../logic/utilities/languageUtility';

const text = {
  EDIT: gettext('Edit'),
  DELETE: gettext('Delete'),
  CANCEL: gettext('Cancel'),
  ARE_YOU_SURE: name => gettext('You\'re about to delete platform name {{name}}?', { name }),
};

const editIcon = (actionHandler, data) => (
  <div key="edit" onClick={() => actionHandler(data)}>
    <Tooltip title={text.EDIT} placement="top">
      <Icon name="Edit" />
    </Tooltip>
  </div>
);

const deleteIcon = (actionHandler, data) => (
  <div key="delete">
    <Tooltip title={text.DELETE} placement="top">
      <Popconfirm
        type="destructive"
        icon="Close"
        label={text.ARE_YOU_SURE(data.name)}
        onConfirm={() => actionHandler(data)}
        buttonLabels={{
          cancel: text.CANCEL,
          confirm: text.DELETE,
        }}
      >
        <Icon name="Delete" />
      </Popconfirm>
    </Tooltip>
  </div>
);

export default ({ onEdit, onDelete }) => ({
  actions: {
    label: '',
    key: 'actions',
    hiddenSortIcon: true,
    pinned: 'right',
    cellRendererFramework: ActionsCellWrapper({ ...(onEdit && { onEdit }), ...(onDelete && { onDelete }) }, { onEdit: editIcon, onDelete: deleteIcon }),
    width: 120,
  },
});
