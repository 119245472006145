import React, { Component } from 'react';
import { isEqual } from 'lodash';
import { func, object, array } from 'prop-types';

import connect from '../../logic/connect';
import { getFieldFromFilter, mapFilters, getFilterValue, isMultipleSelect, isAsyncNestedList, getKeyField } from './filterUtils';
import FilterDropdown from './FilterDropdown';
import Filter from './Filter';
import { fieldsType } from './FilterTypes';

import {
  setVisibleFilter,
  resetFilterStore,
  setActiveField,
  setOperator,
  setValue,
  setActiveFilter,
  setAutocompleteChoice,
} from './FilterRedux';
import { getDjangoApi } from '../../logic/services/api-factory';


const propTypes = {
  onFilterChange: func.isRequired,
  setActiveFilter: func.isRequired,
  activeFilters: object,
  autocompleteChoices: array,
  fields: fieldsType.isRequired,
  predefinedFilters: object,
  resetFilterStore: func.isRequired,
  setActiveField: func.isRequired,
  setOperator: func.isRequired,
  setValue: func.isRequired,
  setVisibleFilter: func.isRequired,
  setAutocompleteChoice: func.isRequired,
};

const defaultProps = {
  activeFilters: {},
  autocompleteChoices: [],
  predefinedFilters: {},
};

class ActiveFilters extends Component {
  constructor(props) {
    super(props);
    this.onCloseIconClick = this.onCloseIconClick.bind(this);
    this.setFilterKey = this.setFilterKey.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { onFilterChange, activeFilters } = this.props;
    if (!isEqual(prevProps.activeFilters, activeFilters)) {
      this.setAutocomplete(activeFilters);
      onFilterChange(activeFilters);
    }
  }

  componentWillUnmount() {
    const { props } = this;
    props.resetFilterStore();
  }

  onCloseIconClick(key) {
    const { setActiveFilter } = this.props;
    setActiveFilter({ key });
  }

  onFilterClick(filterKey, value, field) {
    const { setFilterKey, props } = this;
    setFilterKey(filterKey);
    props.setActiveField(field);
    const { operator } = getFieldFromFilter(filterKey, props.fields);
    props.setOperator(operator);
    const filterValue = getFilterValue({ forApi: false, operator, value, field });
    props.setValue(filterValue);
  }

  setFilterKey(filterKey) {
    const { props } = this;
    props.setVisibleFilter(filterKey);
  }

  setAutocomplete = (activeFilters) => {
    const { fields, setAutocompleteChoice: setAutocompleteChoiceAction } = this.props;
    const promisses = [];

    mapFilters(activeFilters, (filterKey, filterValue) => {
      const { field, operator } = getFieldFromFilter(filterKey, fields);

      const selectedField = isAsyncNestedList(field) ? field.child : field;

      if (selectedField.list_url) {
        const filterQuery = _.get(selectedField, 'filterOptions.query') || {};
        const keyField = getKeyField(selectedField);
        promisses.push(getDjangoApi(selectedField.list_url).list({
          [`${keyField}__in`]: filterValue,
          ...filterQuery,
        }).then(({ data }) => {
          const value = data.results.map(item => ({
            display_name: item[selectedField.display_field],
            value: item[keyField],
          }));

          const isMutiple = isMultipleSelect(operator);

          return { isMultipleSelect: isMutiple, value: isMutiple ? value : value[0] };
        }));
      }
    });

    Promise.all(promisses)
      .then(options => options.forEach(({ 
        value, isMultipleSelect }) => setAutocompleteChoiceAction({ value, isMultipleSelect })));
  }

  render() {
    const { activeFilters, fields, autocompleteChoices, predefinedFilters } = this.props;
    return mapFilters(activeFilters, (filterKey, filterValue) => {
      const { field, fieldKey, operatorLabel, operator } = getFieldFromFilter(filterKey, fields);
      const isAsync = isAsyncNestedList(field) || (!field.choices && field.list_url);
      const filterProps = {
        operatorLabel,
        operator,
        field: isAsync ? { ...field, choices: autocompleteChoices } : field,
        onFilterClick: this.onFilterClick,
        onCloseIconClick: this.onCloseIconClick,
        filterKey,
        filterValue,
        hide: predefinedFilters.hasOwnProperty(fieldKey),
      };
      return (
        <span id={filterKey} key={filterKey}>
          <Filter {...filterProps} />
          <FilterDropdown label={field.label} filterKey={filterKey} />
        </span>
      );
    });
  }
}

ActiveFilters.propTypes = propTypes;
ActiveFilters.defaultProps = defaultProps;

const mapStateToProps = ({ filters }) => ({
  activeFilters: filters.activeFilters,
  autocompleteChoices: filters.autocompleteChoices,
});

const mapDispatchToProps = {
  setVisibleFilter,
  resetFilterStore,
  setActiveField,
  setOperator,
  setValue,
  setActiveFilter,
  setAutocompleteChoice,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActiveFilters);
