import React, { Component } from 'react';
import { func, object, bool } from 'prop-types';
import { Accordion, Code, Pill, Icon, Spinner, Dropdown } from 'tc-biq-design-system';

import kycRunningStates from '../../../../../../logic/enums/kyc-running-state';
import kycStates from '../../../../../../logic/enums/kyc-state';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import kycService from '../../../../../../logic/services/kyc-validation';
import If from '../../../../../../components/If';
import { hasAccess } from '../../../../../../logic/services/acl';

const runningStatePillTypes = {
  [kycRunningStates.INITIALIZED]: 'primary',
  [kycRunningStates.EXECUTING]: 'primary',
  [kycRunningStates.PENDING_RESPONSE]: 'status02',
  [kycRunningStates.FINISHED]: 'status01',
  [kycRunningStates.ERROR]: 'status04',
  [kycRunningStates.MANUALLY_VALIDATED]: 'brand',
  [kycRunningStates.MANUALLY_FAILED]: 'status04',
};

const stepStatePillTypes = {
  [kycStates.PASS]: 'status01',
  [kycStates.PENDING]: 'status02',
  [kycStates.FAIL]: 'status04',
};

const propTypes = {
  revalidate: func,
  validationInProgress: bool,
  kycCheck: object,
  kycContinueValidation: func,
  kycMarkAsFailed: func,
};

const defaultProps = {
  validationInProgress: false,
  kycCheck: {},
  revalidate: null,
  kycContinueValidation: null,
  kycMarkAsFailed: null,
};

const text = {
  REVALIDATE: gettext('Revalidate'),
  RESOLVE: gettext('Resolve'),
};

const formatTitle = (title) => {
  if (title.toUpperCase() === 'GBG') return title.toUpperCase();
  return title.charAt(0).toUpperCase() + title.substr(1).toLowerCase();
};

class KycAccordion extends Component {
  constructor(props) {
    super(props);

    this.kycService = kycService();

    this.state = {
      visible: false,
      stepState: this.kycService
        .getStepState(
          _.get(props, 'kycCheck.runningState.value'),
          _.get(props, 'kycCheck.profileState.value'),
          _.get(props, 'kycCheck.gateway.display_name'),
        ),
    };

    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.handleRevalidateClick = this.handleRevalidateClick.bind(this);
    this.handleContinueValidationClick = this.handleContinueValidationClick.bind(this);
    this.handleMarkAsFailedClick = this.handleMarkAsFailedClick.bind(this);
  }

  handleRevalidateClick(e) {
    e.stopPropagation();
    const { kycCheck, validationInProgress, revalidate } = this.props;
    if (validationInProgress) return;
    revalidate(kycCheck.id);
  }

  handleContinueValidationClick(e) {
    e.stopPropagation();
    const { kycCheck, kycContinueValidation } = this.props;
    kycContinueValidation(kycCheck.id);
  }

  handleMarkAsFailedClick(e) {
    e.stopPropagation();
    const { kycCheck, kycMarkAsFailed } = this.props;
    kycMarkAsFailed(kycCheck.id);
  }

  toggleVisibility() {
    this.setState(({ visible }) => ({ visible: !visible }));
  }

  showResolveButtons() {
    const { kycCheck } = this.props;
    const {
      gateway,
      runningState,
      profileState,
    } = kycCheck;
    
    return (hasAccess('user.kyc.continue_validation.*') || hasAccess('user.kyc.mark_failed.*')) 
      && this.kycService.hasStepFailed(runningState.value, profileState.value, gateway.display_name);
  }

  render() {
    const { visible, stepState } = this.state;
    const { kycCheck, validationInProgress, revalidate } = this.props;

    const {
      id,
      gateway,
      runningState,
      profileState,
      communication,
    } = kycCheck;

    const Resolve = () => {
      const options = [
        { item: () => <a onClick={this.handleContinueValidationClick}><li className="dropdown-list-item">{ gettext('Continue validation') }</li></a> },
        { item: () => <a onClick={this.handleMarkAsFailedClick}><li className="dropdown-list-item">{ gettext('Mark as failed') }</li></a> },
      ];
      return <Dropdown openLeft title={text.RESOLVE} customList={options} />;
    };

    const HeaderTemplate = (
      <div key={id} className="biq-kyc__header" onClick={this.toggleVisibility}>
        <span className="biq-kyc__title">{formatTitle(gateway.display_name)}</span>
        <span className="biq-kyc__state-pill">
          <Pill type={runningStatePillTypes[runningState.value]}>{runningState.display_name}</Pill>
        </span>
        <span className="biq-kyc__state-pill">
          <Pill type={stepStatePillTypes[stepState]}>{profileState.display_name}</Pill>
        </span>
        {revalidate && (
          <span className="biq-kyc__revalidate text-primary-500" onClick={this.handleRevalidateClick}>
            <span className="biq-kyc__revalidate-icon">
              <If condition={!validationInProgress}>
                <Icon name="Pending" colorName="text-primary-500" />
              </If>

              <If condition={validationInProgress}>
                <Spinner />
              </If>
            </span>
            {text.REVALIDATE}

            <span className="biq-kyc__caret-icon">
              <If condition={!visible}>
                <Icon name="CaretRight" />
              </If>

              <If condition={visible}>
                <Icon name="CaretDown" />
              </If>
            </span>
          </span>
        )}
        {this.showResolveButtons()
          && (
          <div className="biq-kyc__button-dropdown">
            <Resolve />
          </div>
          )}
      </div>
    );

    return (
      <Accordion headerTemplate={HeaderTemplate} visible={visible}>
        <Code>
          {JSON.stringify(communication, undefined, 2)}
        </Code> 
      </Accordion>
    );
  }
}

KycAccordion.propTypes = propTypes;
KycAccordion.defaultProps = defaultProps;
export default KycAccordion;
