import React from 'react';

import modifiers from './modifiers';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import { formatRequestData } from '../../../../../components/form/logic/utils';
import withMappingTable from '../MappingTable';

export const SIDEPANEL_ID = 'USER_STATE_ONFIDO_FORM_SIDEPANEL';
const FORM_ID = 'USER_STATE_ONFIDO_FORM';

const gateway = 'onfido';
const tableKey = 'ONFIDO';
const filterOptions = {
  excluded: ['gateway'],
  predefinedFilters: {
    gateway: {
      value: gateway,
      operator: 'exact',
    },
  },
};

const getFormConfig = () => ({
  excludeFields: ['whitelabel', 'gateway'],
});

const text = {
  IDENTITY: gettext('Band text'),
  STATE: gettext('State'),
};

const formModifiers = {
  identity: {
    label: text.IDENTITY,
    placeholder: text.IDENTITY,
  },
  state: {
    label: text.STATE,
    placeholder: text.STATE,
  },
};

const getRequestPayload = () => (values) => {
  const payload = formatRequestData(values);
  return { ...payload, gateway };
};

const Table = withMappingTable(tableKey);

const OnfidoTab = () => (
  <Table
    gateway={gateway}
    tableKey={tableKey}
    filterOptions={filterOptions}
    tableModifiers={modifiers}
    sidepanelId={SIDEPANEL_ID}
    formId={FORM_ID}
    getFormConfig={getFormConfig}
    formModifiers={formModifiers}
    getRequestPayload={getRequestPayload}
  />
);

export default OnfidoTab;
