import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { object, string } from 'prop-types';
import { Toggle, Pill, notifier } from 'tc-biq-design-system';

import connect from '../../../../../../logic/connect';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import If from '../../../../../../components/If';
import overlayActions from '../../../../../../components/overlay';
import { activate, mailchimpApi } from './Model';
import { updateUser } from '../../Model';
import MarketingInfo from './MarketingInfo';
import './Marketing.scss';

const { config } = window;
const { environment } = config;

const propTypes = {
  actions: object.isRequired,
  id: string.isRequired,
  data: object.isRequired,
  user: object.isRequired,
};

const text = {
  REFERRER: gettext('Referrer'),
  CLICK_ID: gettext('Click ID'),
  UTM_CAMPAIGN: gettext('UTM Campaign'),
  UTM_MEDIUM: gettext('UTM Medium'),
  UTM_SOURCE: gettext('UTM Source'),
  INVITED_BY: gettext('Invited by'),
  INVITED_BY_LINK: gettext('Invited by link'),
  CXD: gettext('CXD'),
  AFF_ID: gettext('Aff ID'),
  TAG_ID: gettext('Tag ID'),
  AFFILIATE_DESCRIPTION: gettext('Affiliate description'),
  PUBLISHER_NAME: gettext('Publisher name'),
  GROUP_TYPE: gettext('Group type'),
  SALES_VIEW: gettext('Sales view'),
  RETENTION_VIEW: gettext('Retention view'),
  ALLOWED_BONUS: gettext('Allowed bonus for tag'),
  CHANNEL: gettext('Channel'),
  AUTO_DIALER: gettext('Auto dialer'),
  PROMO_CODE: gettext('Promo code'),
  SMS_NOTIFICATIONS: gettext('SMS Notifications'),
  SMS_TOGGLE_SUCCESS: gettext('SMS notifications updated successfully'),
  SMS_TOGGLE_ERROR: gettext('Error while updating SMS notifications'),
  WHITELABEL: gettext('Whitelabel'),
  COMPANY: gettext('Company'),
  PROMOTIONAL_EMAILS: gettext('Promotional emails'),
  MAILCHIMP: gettext('Mailchimp'),
  MAILCHIMP_SUCCESS: gettext('Mailchimp subscription updated successfully'),
  MAILCHIMP_ERROR: gettext('Error while updating mailchimp subscription'),
  REFER_FRIENDS_AGENT_TYPE: gettext('Refer Friends Agent Type'),
  REFER_FRIENDS_AGENT_USER: gettext('Refer Friends Agent User'),
};

class Marketing extends Component {
  constructor(props) {
    super(props);
    this.actions = props.actions;

    this.usersApi = getDjangoApi('users');
  }

  componentDidMount() {
    const { id } = this.props;
    this.actions.activate(id);
  }

  toggleSmsNotifications = (sms_notifications) => {
    const { id } = this.props;
    this.actions.updateUser({ sms_notifications });

    this.usersApi.updatePart(id, { sms_notifications }) 
      .then(() => {
        notifier.success(text.SMS_TOGGLE_SUCCESS);
      }, () => {
        notifier.error(text.SMS_TOGGLE_ERROR);
        this.actions.setUser({ sms_notifications: !sms_notifications });
      });
  }

  toggleMailchimpSubscription = (subscribe) => {
    const { id } = this.props;

    mailchimpApi.update(id, { subscribe })
      .then(() => {
        notifier.success(text.MAILCHIMP_SUCCESS);
        this.actions.activate(id);
      }, () => {
        notifier.error(text.MAILCHIMP_ERROR);
      });
  }

  render() {
    const { data, user } = this.props;

    const {
      clickid,
      zcomTags,
      invited_by_link,
      utm_tags,
      mailchimpSubscription,
      cxd,
      aff_id
    } = data;

    const utmTags = (utm_tags && JSON.parse(utm_tags)) || {};

    const {
      referrer,
      ava_partners_tag_tag_id,
      ava_partners_tag_affiliate_description,
      ava_partners_tag_publisher_name,
      ava_partners_tag_group_type,
      ava_partners_tag_sales_view,
      ava_partners_tag_retention_view,
      ava_partners_tag_allowed_bonus,
      ava_partners_tag_channel,
      ava_partners_tag_auto_dialer,
      refer_friends_agent_type,
      refer_friends_agent,
      promo_code,
      sms_notifications,
      whitelabel,
    } = user;

    const invitedByLink = invited_by_link && (
      <span className="text-primary-400">
        <a href={invited_by_link.register_url} target="_blank" rel="noopener noreferrer">{invited_by_link.register_url}</a>
        <span>
          [owner:
          <a href={`ib-system/ib-user/${invited_by_link.owner.id}`} target="_blank" rel="noopener noreferrer">{invited_by_link.owner.username}</a>
          ]
        </span>
      </span>
    );

    return (
      <div className="biq-profile__wrapper biq-marketing">
        <If condition={environment === 'AvaTrade' || environment === 'CoreSpreads'}>
          <MarketingInfo label={text.REFERRER} value={referrer} link={referrer} />
        </If>

        <If condition={environment === 'CoreSpreads'}>
          {_.map(utmTags, (value, key) => <MarketingInfo label={key} value={value} />)}
        </If>
        
        <If condition={environment === 'Zcom' || environment === 'CoreSpreads' || environment === 'FinancialSpreads'}>
          <MarketingInfo label={text.CLICK_ID} value={clickid} />
        </If>

        <If condition={environment === 'Zcom' && !!zcomTags}>
          <MarketingInfo label="AID" value={zcomTags && zcomTags.aid} />
          <MarketingInfo label="CID" value={zcomTags && zcomTags.cid} />
          <MarketingInfo label={text.UTM_CAMPAIGN} value={zcomTags && zcomTags.utm_campaign} />
          <MarketingInfo label={text.UTM_MEDIUM} value={zcomTags && zcomTags.utm_medium} />
          <MarketingInfo label={text.UTM_SOURCE} value={zcomTags && zcomTags.utm_source} />
        </If>

        <If condition={environment !== 'AvaTrade'}>
          <MarketingInfo label={text.INVITED_BY} value={invited_by_link && invited_by_link.owner.username} link={invited_by_link && `ib-system/ib-user/${invited_by_link.owner.id}`} />
          <MarketingInfo label={text.INVITED_BY_LINK} value={invitedByLink} />
          <MarketingInfo label={text.CXD} value={cxd} />
          <MarketingInfo label={text.AFF_ID} value={aff_id} />
        </If>

        <If condition={isFeatureEnabled()('AVA_PARTNERS')}>
          <MarketingInfo label={text.TAG_ID} value={ava_partners_tag_tag_id} />
          <MarketingInfo label={text.AFFILIATE_DESCRIPTION} value={ava_partners_tag_affiliate_description} />
          <MarketingInfo label={text.PUBLISHER_NAME} value={ava_partners_tag_publisher_name} />
          <MarketingInfo label={text.GROUP_TYPE} value={ava_partners_tag_group_type} />
          <MarketingInfo label={text.SALES_VIEW} value={ava_partners_tag_sales_view} />
          <MarketingInfo label={text.RETENTION_VIEW} value={ava_partners_tag_retention_view} />
          <MarketingInfo label={text.REFER_FRIENDS_AGENT_TYPE} value={refer_friends_agent_type} />
          <MarketingInfo label={text.REFER_FRIENDS_AGENT_USER} value={refer_friends_agent && refer_friends_agent.username} />
          <MarketingInfo label={text.ALLOWED_BONUS} value={ava_partners_tag_allowed_bonus} />
          <MarketingInfo label={text.CHANNEL} value={ava_partners_tag_channel} />
          <MarketingInfo label={text.AUTO_DIALER} value={ava_partners_tag_auto_dialer} />
        </If>

        <If condition={environment === 'AvaTrade'}>
          <MarketingInfo label={text.PROMO_CODE} value={promo_code} />
          <MarketingInfo label={text.SMS_NOTIFICATIONS} value={<Toggle checked={sms_notifications} onClick={this.toggleSmsNotifications} />} />
        </If>

        <If condition={isFeatureEnabled()('MAILCHIMP_SUBSCRIPTION')}>
          <MarketingInfo 
            label={(<span>{text.PROMOTIONAL_EMAILS} ({text.MAILCHIMP})</span>)} 
            value={<Toggle checked={mailchimpSubscription} onClick={this.toggleMailchimpSubscription} />} 
          />
        </If>

        <If condition={(environment === 'AvaTrade' || isFeatureEnabled()('WHITELABEL')) && !!whitelabel}>
          <MarketingInfo label={environment === 'ICMCapital' ? text.COMPANY : text.WHITELABEL} value={<Pill type="status01">{whitelabel}</Pill>} />
        </If>
      </div>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const { USER_SINGLE } = pages;
  const { marketing, userDetail } = USER_SINGLE;

  return {
    ...marketing,
    user: userDetail.user,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ 
    activate, 
    openSidepanel: overlayActions.open,
    updateUser,
  }, dispatch),
});

Marketing.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(Marketing);
