import { createAction, handleActions } from 'redux-actions';
import { getDjangoApi } from '../../../../../../../logic/services/api-factory';

const actionTypes = {
  FETCH_COMMUNICATION_WIZARD: 'FETCH_COMMUNICATION_WIZARD',
  FETCH_COMMUNICATION_WIZARD_PENDING: 'FETCH_COMMUNICATION_WIZARD_PENDING',
  FETCH_COMMUNICATION_WIZARD_REJECTED: 'FETCH_COMMUNICATION_WIZARD_REJECTED',
  FETCH_COMMUNICATION_WIZARD_FULFILLED: 'FETCH_COMMUNICATION_WIZARD_FULFILLED',
  SUBMIT_COMMUNICATION_WIZARD: 'SUBMIT_COMMUNICATION_WIZARD',
  SUBMIT_COMMUNICATION_WIZARD_PENDING: 'SUBMIT_COMMUNICATION_WIZARD_PENDING',
  SUBMIT_COMMUNICATION_WIZARD_REJECTED: 'SUBMIT_COMMUNICATION_WIZARD_REJECTED',
  SUBMIT_COMMUNICATION_WIZARD_FULFILLED: 'SUBMIT_COMMUNICATION_WIZARD_FULFILLED',
  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
  SET_NEXT_STEPS: 'SET_NEXT_STEPS',
  GO_STEP_BACK: 'GO_STEP_BACK',
  FINISH_WIZARD: 'FINISH_WIZARD',
  RESET_WIZARD: 'RESET_WIZARD ',
  INIT_WIZARD: 'INIT_WIZARD',
};

const api = user => getDjangoApi(`users/${user.id}/communication_wizard`);

export const fetchCommunicationWizard = createAction(actionTypes.FETCH_COMMUNICATION_WIZARD, async (user) => {
  const { data } = await api(user).retrieve();

  return data;
});

export const submitCommunicationWizard = createAction(actionTypes.SUBMIT_COMMUNICATION_WIZARD, async (user, wizard) => {
  const { data } = await api(user).create(wizard);
  return data;
});

export const setActiveStep = createAction(actionTypes.SET_ACTIVE_STEP, activeStep => activeStep, (_activeStep, update) => ({ update }));
export const setNextSteps = createAction(actionTypes.SET_NEXT_STEPS, nextSteps => nextSteps);
export const goStepBack = createAction(actionTypes.GO_STEP_BACK);
export const finishWizard = createAction(actionTypes.FINISH_WIZARD);
export const resetWizard = createAction(actionTypes.RESET_WIZARD);

// Reset wizard from client page when use change the flow
export const initWizard = createAction(actionTypes.INIT_WIZARD);

const initialState = {
  wizard: {},
  finished: false,
  previousSteps: [],
  nextSteps: [],
  activeStep: null,
  fetchInProgress: false,
  errors: null,
  submitInProgress: false,
  submitErrors: null,
};

const fetchPending = state => ({ ...state, fetchInProgress: true });

const fetchRejected = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  errors: payload.data,
});

const fetchFulfilled = (state, { payload }) => ({
  ...state,
  fetchInProgress: false,
  wizard: payload,
});

const submitPending = state => ({ ...state, submitInProgress: true });

const submitRejected = (state, { payload }) => ({
  ...state,
  submitInProgress: false,
  submitErrors: payload.data,
});

const submitFulfilled = state => ({
  ...state,
  submitInProgress: false,
});

const setActiveStepHandler = (state, { payload, meta }) => ({
  ...state,
  activeStep: payload,
  ...(!meta.update && state.activeStep && { previousSteps: [...state.previousSteps, state.activeStep] }),
});

const setNextStepsHandler = (state, { payload }) => ({
  ...state,
  nextSteps: payload,
});

const goStepBackHandler = (state) => {
  const previousSteps = [...state.previousSteps];
  const lastItem = previousSteps.pop();
  return ({
    ...state,
    activeStep: { ...lastItem },
    previousSteps: [...previousSteps],
  });
};

const finishWizardHandler = state => ({ ...state, finished: true });
const resetWizardHandler = ({ wizard, previousSteps }) => ({
  ...initialState,
  wizard,
  activeStep: previousSteps[0],
});
const initWizardHandler = ({ wizard }) => ({
  ...initialState,
  wizard,
});

export default handleActions(
  {
    [actionTypes.FETCH_COMMUNICATION_WIZARD_PENDING]: fetchPending,
    [actionTypes.FETCH_COMMUNICATION_WIZARD_FULFILLED]: fetchFulfilled,
    [actionTypes.FETCH_COMMUNICATION_WIZARD_REJECTED]: fetchRejected,
    [actionTypes.SUBMIT_COMMUNICATION_WIZARD_PENDING]: submitPending,
    [actionTypes.SUBMIT_COMMUNICATION_WIZARD_FULFILLED]: submitFulfilled,
    [actionTypes.SUBMIT_COMMUNICATION_WIZARD_REJECTED]: submitRejected,
    [actionTypes.SET_ACTIVE_STEP]: setActiveStepHandler,
    [actionTypes.SET_NEXT_STEPS]: setNextStepsHandler,
    [actionTypes.GO_STEP_BACK]: goStepBackHandler,
    [actionTypes.RESET_WIZARD]: resetWizardHandler,
    [actionTypes.FINISH_WIZARD]: finishWizardHandler,
    [actionTypes.INIT_WIZARD]: initWizardHandler,
  },
  initialState,
);
