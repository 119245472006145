/* eslint-disable react/prop-types */
import React from 'react';

const WalletFormatter = ({ value }) => {
  if (!value) return null;
  const { backend } = value;
  if (!backend) return null;
  const id = backend.type === 'cloudtrade' ? value.external_user_id : value.external_id;
  return <span>{`${id}, ${backend.name}`}</span>;
};

export default WalletFormatter;
