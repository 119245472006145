/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react';
import { withSidepanelManager } from 'tc-biq-design-system';

import connect from '../../../../../../logic/connect';
import { withRouter } from '../../../../../../components/hoc/withRouter';
import { getActiveFilters } from '../../../../../../components/grid/GridUtils';
import Filters from '../../../../../../components/Filters';


class TransactionsGrid extends Component {
  componentDidMount() {
    const { predefinedFilter, table, actions, modifier } = this.props;
    const customQuery = predefinedFilter || {};
    actions.fetchOptions(modifier);
    actions.fetchTableData({ ...table.query, ...customQuery });
  }

  render() {
    const { GridComponent, fields, actions, table, modifier, hideSegments } = this.props;
    const { selectedSegment, ownSegments, sharedSegments, publicSegments, columnsState } = table;
    const filters = getActiveFilters(table);
    const segments = { selectedSegment, ownSegments, sharedSegments, publicSegments };

    const filterProps = {
      view: table.options.view,
      segments,
      fields,
      columnsState,
      initialFilters: filters,
      onFilterChange: actions.updateFilter,
      onChangeSegment: actions.changeSegment,
      refreshOptions: actions.fetchOptions,
      hideSegments,
      tableModifier: modifier,
    };

    if (!table.options.fromApi) return null;
    return (
      <Fragment>
        <Filters {...filterProps} />
        <GridComponent preventInternalOption {...this.props} tableModifier={modifier || {}} />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  return {
    user: page.userDetail.user,
  };
};

export default 
withSidepanelManager(
  withRouter(
    connect(mapStateToProps)(TransactionsGrid),
  ),
);
