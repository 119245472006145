import React, { Component } from 'react';
import { Row, Col, Input, Button } from 'tc-biq-design-system';
import { array, func } from 'prop-types';

import { gettext } from '../../logic/utilities/languageUtility';
import { statuses } from './AclCard/AccordionHeader/HeaderCheckbox/HeaderCheckbox';
import AclCard from './AclCard';

import './AclGrid.scss';
import { parseAcl } from '../../logic/services/acl';

const text = {
  COLLAPSE_ALL: gettext('Collapse All'),
  EXPAND_ALL: gettext('Expand All'),
};

const propTypes = {
  values: array,
  options: array,
  onChange: func.isRequired,
};

const defaultProps = {
  values: [],
  options: [],
};

class AclGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      grid: null,
      openAll: true,
      searchTerm: '',
    };

    this.search = _.debounce(this.searchAcls.bind(this), 300, { leading: true });
  }

  UNSAFE_componentWillMount() {
    const { options, values } = this.props;
    const grid = this.parseGrid(options, values);
    this.setState({ grid });
  }

  onCategoryToggle = (category, data) => {
    this.setState(({ grid }) => ({
      grid: { ...grid, [category]: data },
    }));
  }

  onSearchChange = (e) => {
    const searchTerm = e.target.value;
    this.setState({
      searchTerm,
    }, () => this.search(searchTerm));
  }

  onAllToggle = () => {
    this.setState(({ grid, openAll }) => ({
      grid: Object.keys(grid).reduce((acc, category) => ({ ...acc, [category]: { ...grid[category], visible: !openAll } }), {}),
      openAll: !openAll,
    }));
  }

  onAclChange = (key, value) => {
    this.updateAcl(key, value);
  }

  onCategorySelect = (category, status) => {
    const { grid } = this.state;
    const checked = status === statuses.CHECKED;

    const newGrid = { ...grid };
    newGrid[category] = {
      ...newGrid[category],
      names: Object.keys(grid[category].names).reduce((acc, name) => ({
        ...acc,
        [name]: {
          ...Object.keys(grid[category].names[name]).reduce((actions, action) => ({ ...actions, [action]: checked }), {}),
          visible: grid[category].names[name].visible,
        },
      }), {}),
    };

    this.setState({
      grid: newGrid,
    }, this.onValuesChange);
  }

  onValuesChange = () => {
    const { onChange } = this.props;
    const { grid } = this.state;
    const values = [];
    Object.keys(grid).forEach((category) => {
      const { names } = grid[category];
      Object.keys(names).forEach((name) => {
        const actions = names[name];
        const allActions = Object.keys(actions);
        allActions.forEach((actionName) => {
          if (actionName !== 'visible' && actions[actionName]) {
            values.push(`${name}.${actionName}`);
          }
        });
      });
    });

    onChange(values);
  }

  updateAcl = (key, value) => {
    const { grid } = this.state;
    const { category, name, action } = parseAcl(key);

    const newGrid = { 
      ...grid, 
      [category]: {
        ...grid[category],
        names: {
          ...grid[category].names,
          [name]: this.updateAction(grid[category].names[name], action, value),
        },
      },
    };

    this.setState({
      grid: newGrid,
    }, this.onValuesChange);
  }

  updateAction = (actions = {}, action, value) => {
    const allActionNames = Object.keys(actions)
      .filter(actionName => actionName !== 'visible');
    if (action !== '*') {
      const updatedActions = {
        ...actions,
        [action]: value,
      };

      if (value === false) {
        updatedActions['*'] = false;
      }

      const allPositive = allActionNames
        .filter(actionName => actionName !== '*')
        .every(actionName => updatedActions[actionName]);

      if (allPositive) {
        updatedActions['*'] = true;
      }

      return updatedActions;
    }
    const updatedActions = allActionNames
      .reduce((acc, actionName) => ({
        ...acc,
        [actionName]: value,
      }), {});

    return {
      ...actions,
      ...updatedActions,
    };
  };

  searchAcls = (searchTerm) => {
    const { grid } = this.state;

    const newGrid = Object.keys(grid).reduce((acc, category) => ({
      ...acc,
      [category]: {
        ...grid[category],
        names: Object.keys(grid[category].names).reduce((nacc, name) => ({
          ...nacc,
          [name]: {
            ...grid[category].names[name],
            visible: name.includes(searchTerm),
          },
        }), {}),
      },
    }), {});

    this.setState({
      grid: newGrid,
    });
  }

  parseGrid = (options, values) => options.sort().reduce((acc, acl) => {
    const parsedAcl = parseAcl(acl);
    const { name } = parsedAcl;
    const category = acc[parsedAcl.category];
    const actions = category && category.names && category.names[name];
    return {
      ...acc,
      [parsedAcl.category]: {
        visible: true,
        names: {
          ...(category && category.names),
          [name]: {
            ...(actions && actions),
            visible: true,
            [parsedAcl.action]: values.includes(`${name}.${parsedAcl.action}`),
          },
        },
      } };
  }, {}) 

  render() {
    const { grid, openAll, searchTerm } = this.state;
    
    return (
      <Row>
        <Col sm="1/5" />
        <Col sm="3/5">
          <div className="acl-grid__header">
            <div className="acl-grid__search">
              <Input
                icon="Search"
                iconPosition="left"
                value={searchTerm}
                onChange={this.onSearchChange}
              />
            </div>
            <Button onClick={this.onAllToggle} color="ghost" icon={openAll ? 'CaretDown' : 'CaretRight'}>
              {openAll ? text.COLLAPSE_ALL : text.EXPAND_ALL}
            </Button>
          </div>
          {grid && Object.keys(grid).map(category => (
            <AclCard 
              key={category}
              category={category}
              data={grid[category]}
              onToggle={this.onCategoryToggle}
              onSelect={this.onCategorySelect}
              onChange={this.onAclChange}
            />
          ))}
        </Col>
      </Row>
    );
  }
}

AclGrid.propTypes = propTypes;
AclGrid.defaultProps = defaultProps;

export default AclGrid;
