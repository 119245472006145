import React, { Component, Fragment } from 'react';
import { Tabs, Tab, Button, notifier, Dropdown, withSidepanelManager } from 'tc-biq-design-system';
import { string, object, func, shape } from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import connect from '../../../../../../logic/connect';
import { gettext } from '../../../../../../logic/utilities/languageUtility';
import { getDjangoApi } from '../../../../../../logic/services/api-factory';
import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import { hasAccess } from '../../../../../../logic/services/acl';
import overlayActions from '../../../../../../components/overlay';
import withTable from '../../../../../../components/grid/withTable';
import TransactionsGrid from './TransactionsGrid';
import paymentsModifier from './paymentsModifier';
import bonusModifier from './bonusModifier';
import internalModifier from './internalModifier';
import {
  paymentConfig,
  internalConfig,
  insuranceConfig,
  bonusConfig,
  getTableActions,
} from './table';
import If from '../../../../../../components/If';
import UserActions, { userActionTypes } from '../../UserActions';
import { SIDEPANEL_ID } from '../../../../../../components/common/sidepanels/TradingAccount';
import { TradingAccount } from '../../../../../../components/common/sidepanels';

export const acls = {
  payments: 'user.payment_transactions.list',
  internal: 'user.internal_transactions.list',
  bonus: 'user.bonuses.list',
};

const exportAcls = {
  [paymentConfig().tableKey]: 'user.payment_transactions.export.*',
  [internalConfig().tableKey]: 'user.internal_transactions.export.*',
  [bonusConfig().tableKey]: 'user.bonuses.export.*',
};

const mapActionAttributes = actions => actions.map(({ label, icon, handler }) => ({
  label, icon, onClick: handler,
}));

const propTypes = {
  match: shape({
    params: shape({
      id: string,
    }),
  }).isRequired,
  dispatch: func.isRequired,
  id: string.isRequired,
  actions: object.isRequired,
  tables: object.isRequired,
  user: object.isRequired,
  sidepanelManager: object.isRequired,
};

const exportButtonStyleAdjustment = { marginTop: '4px' };

const text = {
  PAYMENTS: gettext('PAYMENTS'),
  INTERNAL: gettext('INTERNAL'),
  INSURANCE: gettext('INSURANCE'),
  BONUS: gettext('BONUS'),
  EXPORT_ALL: gettext('Export all'),
  BONUS_CANCEL_SUCCESSFULLY: gettext('Bonus cancelled successfully'),
  BONUS_CANCEL_FAILED: gettext('Failed to cancel bonus'),
  ACTIONS: gettext('Actions'),
};

class Transactions extends Component {
  constructor(props) {
    super(props);

    const { match: { params: { id } } } = props;

    this.userId = id;

    this.Payment = withTable(TransactionsGrid, paymentConfig, this.tableActions(paymentConfig().tableKey), { hideSegments: true })(props.id);
    this.Internal = withTable(TransactionsGrid, internalConfig, this.tableActions(internalConfig().tableKey), { hideSegments: true })(props.id);
    this.Insurance = withTable(TransactionsGrid, insuranceConfig, this.tableActions(insuranceConfig().tableKey, false), { hideSegments: true })(props.id);
    this.Bonus = withTable(TransactionsGrid, bonusConfig, this.tableActions(bonusConfig().tableKey, false), { hideSegments: true })(props.id);

    this.isInsuranceEnabled = isFeatureEnabled()('INSURANCE_TRANSACTIONS');

    this.internalTableFilter = this.isInsuranceEnabled ? {
      'reason__name__icontains!': 'due to Insurance',
    } : {};

    this.insuranceTableFilter = {
      reason__name__icontains: 'due to Insurance',
    };

    this.paymentActions = {
      openTransaction: this.openSingleTransaction.bind(this),
    };

    this.bonusActions = {
      cancelBonus: hasAccess('bonuses.campaigns.update') && this.cancelBonus.bind(this),
      openTradingAccount: this.openTradingAccountSidepanel.bind(this),
    };
  }

  tableActions = (key, displayExport = true) => () => {
    const { tables, user, dispatch, sidepanelManager } = this.props;
    const table = tables[key];
    const all = _.get(table, 'exportUrl.all');
    const exportVisible = hasAccess(exportAcls[key]);
    return (
      <Fragment>
        {displayExport && exportVisible && <a href={all}><Button style={exportButtonStyleAdjustment}>{text.EXPORT_ALL}</Button></a>}
        <If condition={user.state === 'Full' || user.state === 'Limited'}>
          <UserActions type={userActionTypes.TRANSACTIONS} DI={{ sidepanelManager, dispatch, user }}>
            {actions => <Dropdown openLeft title={text.ACTIONS} list={mapActionAttributes(actions)} />}
          </UserActions>
        </If>
      </Fragment>
    );
  }


  updateTable = () => {
    const { dispatch, id } = this.props;
    const actions = getTableActions(bonusConfig, id);
    dispatch(actions.fetchTableData());
  }

  cancelBonus(data) {
    const cancelBonusApi = getDjangoApi(`users/${this.userId}/bonuses/${data.id}/set_state`, 'user.bonuses.set_state.create');
    cancelBonusApi.create({ state: 50 }).then(() => {
      notifier.success(text.BONUS_CANCEL_SUCCESSFULLY);
      this.updateTable();
    }, () => {
      notifier.error(text.BONUS_CANCEL_FAILED);
    });
  }

  async openSingleTransaction(data) {
    const { actions, id } = this.props;
    const response = await getDjangoApi(paymentConfig(id).apiUrl).retrieve(data.id);
    actions.openSidepanel('TRANSACTION_SINGLE', response.data);
  }

  async openTradingAccountSidepanel(data) {
    const { actions } = this.props;
    const response = await getDjangoApi(`users/${data.user.id}/trading_accounts/${data.trading_account_id}`).list();
    actions.openSidepanel(SIDEPANEL_ID, response.data);
  }

  render() {
    const { Payment, Internal, Insurance, Bonus, internalTableFilter, insuranceTableFilter } = this;

    return (
      <Tabs>
        <Tab visible={hasAccess(acls.payments)} title={text.PAYMENTS}>
          <Payment modifier={paymentsModifier(this.paymentActions)} />
        </Tab>

        <Tab visible={hasAccess(acls.internal)} title={text.INTERNAL}>
          <Internal modifier={internalModifier} predefinedFilter={internalTableFilter} />
        </Tab>

        <Tab visible={this.isInsuranceEnabled} title={text.INSURANCE}>
          <Insurance predefinedFilter={insuranceTableFilter} />
        </Tab>

        <Tab visible={hasAccess(acls.bonus)} title={text.BONUS}>
          <Bonus modifier={bonusModifier(this.bonusActions)} />
          <TradingAccount />
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = ({ pages }) => {
  const page = pages.USER_SINGLE;
  return {
    user: page.userDetail.user,
    tables: page.tables,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSidepanel: overlayActions.open,
    },
    dispatch,
  ),  
  dispatch,
});

Transactions.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(withSidepanelManager(withRouter(Transactions)));
