import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import isFeatureEnabled from '../../../../../../logic/filters/is-feature-enabled';
import SalesStatusForm, { FORM_ID } from './SalesStatusForm';
import connect from '../../../../../../logic/connect';
import { FormActionsFactory } from '../../../../../../components/form';
import withErrorBoundary from '../../../../../../components/hoc/withErrorBoundary';
import { hasAccess } from '../../../../../../logic/services/acl';
import appRoutes from '../../../../../../components/App/Router/appRoutes';

const { create, update, resetFields, setRowData, setFieldValue } = FormActionsFactory(FORM_ID);

const mapStateToProps = ({ forms }) => ({ ...forms[FORM_ID] });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    create, 
    update,
    resetFields,
    setRowData,
    setFieldValue,
  }, dispatch),
});

const SalesStatusFormComp = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
  withRouter,
)(SalesStatusForm);

export default {
  component: SalesStatusFormComp,
  aclFn: () => hasAccess('communication_flow.list') && isFeatureEnabled()('COMMUNICATION_FLOW'),
  path: appRoutes.SETTINGS_SALES_STATUSES_AND_SUBSTATUSES_FORM,
};
