import USER_STATE from '../../../../../../logic/enums/user-state';

import addToExclusionList from './AddToExclusionList';
import addToBlacklist from './AddToBlacklist';
import archive from './Archive';
import unarchive from './Unarchive';
import assignToIb from './AssignToIb';
import getApproveToActon from './ApproveTo';
import approveIb from './ApproveIb';
import ibChangeBackends from './IbChangeBackends';
import ibCredit from './IbCredit';
import ibDebit from './IbDebit';
import bonus from './Bonus';
import mt4Bonus from './Mt4Bonus';
import changeUserState from './ChangeUserState';
import sendSMS from './SendSMS';
import sendNote from './SendNote';
import sendEmail from './SendEmail';
import internalTransfer from './InternalTransfer';
import externalTransfer from './ExternalTransfers';
import credit from './Credit';
import debit from './Debit';
import loginAsUser from './LoginAsUser';
import changePassword from './ChangePassword';
import resetPassword from './ResetPassword';
import changeTaPassword from './ChangeTaPassword';
import moveToPending from './MoveToPending';
import pushVerification from './PushVerification';
import unlockLogin from './UnlockLogin';
import reject from './Reject';
import editTradingAccount from './EditTradingAccount';
import editMifid from './EditMifid';
import newTradingAccount from './NewTradingAccount';
import createLog from './CreateLog';
import createTask from './CreateTask';
import communicationFlow from './CommunicationFlow';
import maskPersonalDetails from './MaskPersonalDetails';

export default [
  communicationFlow,
  addToBlacklist,
  addToExclusionList,
  changeUserState,
  archive,
  unarchive,
  assignToIb,
  getApproveToActon(USER_STATE.FULL),
  approveIb,
  ibChangeBackends,
  ibCredit,
  ibDebit,
  bonus,
  mt4Bonus,
  loginAsUser,
  sendSMS,
  sendEmail,
  createTask,
  sendNote,
  createLog,
  internalTransfer,
  externalTransfer,
  credit,
  debit,
  changePassword,
  changeTaPassword,
  resetPassword,
  moveToPending,
  pushVerification,
  unlockLogin,
  reject,
  editTradingAccount,
  editMifid,
  newTradingAccount,
  maskPersonalDetails,
];
