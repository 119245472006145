/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { Button, notifier, Space } from 'tc-biq-design-system';
import { bindActionCreators, compose } from 'redux';

import connect from '../../../../../logic/connect';
import { gettext } from '../../../../../logic/utilities/languageUtility';
import GridFactory from '../../../../../components/grid';
import Page from '../../../../../components/Page';
import { BiqUserPreview } from '../../../../../components/common/sidepanels';
import overlayActions from '../../../../../components/overlay';
import modifiers from './modifiers';
import DashboardLayoutForm, { SIDEPANEL_ID } from './sidepanels/DashboardLayoutForm'; 
import to from '../../../../../logic/utilities/to';
import { deleteDashboard } from '../DashboardLayout/DashboardPage/Model';
import { hasAccess } from '../../../../../logic/services/acl';
import withErrorBoundary from '../../../../../components/hoc/withErrorBoundary';
import appRoutes from '../../../../../components/App/Router/appRoutes';
  
const text = {
  CUSTOMIZATION_PAGE_TITLE: gettext('Customization'),
  PAGE_TITLE: gettext('Dashboard settings'),
  CREATE_NEW: gettext('Create new'),
  GENERAL_ERROR: gettext('Something went wrong'),
  REMOVE_SUCCESS: gettext('Dashboard successfully removed'),
};
const bread = [
  { label: text.CUSTOMIZATION_PAGE_TITLE, route: appRoutes.SETTINGS_CUSTOMIZATION },
  { label: text.PAGE_TITLE, route: appRoutes.SETTINGS_CUSTOMIZATION_DASHBOARDS },
];

const pageConfig = {
  apiUrl: 'settings/layout/dashboard',
  reducerKey: 'CUSTOMIZATION',
  tableKey: 'DASHBOARD_SETTINGS_TABLE',
};

const { GridComponent, actions } = GridFactory(pageConfig);

const createLayout = onCreate => () => hasAccess('layout.dashboard.create') && (
  <Button onClick={onCreate}>{text.CREATE_NEW}</Button>
);

class DashboardSettings extends Component {
  constructor(props) {
    super(props);
    this.tableActions = {
      remove: hasAccess('layout.dashboard.destroy') && this.onRemove.bind(this),
      edit: hasAccess('layout.dashboard.update') && this.onEdit.bind(this),
      previewBiqUser: props.actions.openSidepanel.bind(this, 'BIQ_USER_PREVIEW'),
    };
  }

  onRemove = async ({ id }) => {
    const { actions } = this.props;
    const [err] = await to(actions.deleteDashboard({ id }));
    err ? this.onError(err) : this.onRemoveSuccess();
  }

  onCreate = async () => {
    const { actions } = this.props;
    actions.openSidepanel(SIDEPANEL_ID);
  }

  onEdit = async (dashboard) => {
    const { actions } = this.props;
    actions.openSidepanel(SIDEPANEL_ID, dashboard);
  }

  onRemoveSuccess = () => {
    notifier.success(text.REMOVE_SUCCESS);
    this.onSuccess();
  }

  onSuccess = () => {
    const { actions } = this.props;
    actions.fetchTableData();
  }

  onError({ data }) {
    if (data) {
      const { non_field_errors, messages } = data;
      if (non_field_errors) {
        notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
      } else if (messages) {
        notifier.error(data.messages.map(err => <span>{err.text}</span>));
      } else {
        notifier.error(text.GENERAL_ERROR);
      }
    } else {
      notifier.error(text.GENERAL_ERROR);
    }
  }

  render() {
    return (
      <Page bread={bread} title={text.PAGE_TITLE} headerActions={createLayout(this.onCreate)}>
        <Space size={16} />
        <GridComponent
          singleActions
          tableModifier={modifiers(this.tableActions)}
          {...this.props}
        />
        <BiqUserPreview />
        <DashboardLayoutForm onSuccess={this.onSuccess} />
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchTableData: actions.fetchTableData,
      updateFilter: actions.updateFilter,
      changeSegment: actions.changeSegment,
      fetchOptions: actions.fetchOptions,
      openSidepanel: overlayActions.open,
      deleteDashboard,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ pages }) => {
  const { reducerKey, tableKey } = pageConfig;
  const page = pages[reducerKey];
  const table = page.tables[tableKey];
  return {
    table,
    fields: table.fields,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorBoundary,
)(DashboardSettings); 
