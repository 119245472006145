import { gettext } from '../../../../../../../logic/utilities/languageUtility';
import { hasAccess } from '../../../../../../../logic/services/acl';
import isFeatureEnabled from '../../../../../../../logic/filters/is-feature-enabled';
import userActionTypes from '../types';
import CreateLog from './CreateLog';

const text = {
  LABEL: gettext('Create a log'),
};

const ACTION_ICON = 'Logs';

export default {
  name: 'createLog',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: userActionTypes.TOP,
  hasPermission() {
    return (hasAccess('user.chat.create') || hasAccess('user.messages.create')
          || hasAccess('user.sms.create') || hasAccess('user.calls.create'))
          && isFeatureEnabled()('COMMUNICATION_LOG') && !isFeatureEnabled()('COMMUNICATION_FLOW');
  },
  handler: (_sidepanelManager, _dispatch, user, isIb, comboxManager) => {
    const comboxOptions = {
      title: text.LABEL,
      id: 'create-log-combox',
      user,
      isIb,
    };

    comboxManager.add(CreateLog, comboxOptions);
  },
};
