import appRoutes from '../../../../../components/App/Router/appRoutes';
import SmsEventPage from './SmsEvent';

export const SmsEvent = {
  component: SmsEventPage,
  path: appRoutes.SMS_EVENT,
  aclId: 'sms.trigger_rules.update',
};

export const SmsEventCreate = {
  component: SmsEventPage,
  path: appRoutes.SMS_EVENT_CREATE,
  aclId: 'sms.trigger_rules.create',
};
